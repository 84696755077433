import React, { useState, useEffect } from "react";
import { LoadingDot1, LoadingDot2, LoadingDot3 } from "icons";
import styled from "styled-components";

const DotsWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  margin-top: 0 !important;
`;

const LoadingDots = () => {
  const [order, setOrder] = useState<number>(0);

  const debounce = (callable: any, time: number) => {
    let timeout: any;
    if (order > 2) {
      setOrder(0);
    }

    return function () {
      const functionCall = () => callable();

      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    };
  };

  const debouncedIteration = debounce(() => {
    setOrder(order + 1);
  }, 500);

  useEffect(() => {
    debouncedIteration();
  }, [debouncedIteration]);

  const renderDot = () => {
    switch (order) {
      case 0:
        return <LoadingDot1 />;
      case 1:
        return <LoadingDot2 />;
      case 2:
        return <LoadingDot3 />;
      default:
        return;
    }
  };

  return <DotsWrapper>{renderDot()}</DotsWrapper>;
};

export default LoadingDots;
