import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/questionnaires/answerHistory";
import { httpStatus } from "configs/httpStatus";
import { fetchAnswerHistoryList } from "services/questionnaires";
import { handleSuccessAction, handleErrorAction } from "./answerHistoryActions";
import { history } from "../../configureStore";
import {
  IAnswerHistoryData,
  IAnswerHistoryResponse,
} from "models/questionnaires/answerHistory";

function* answerHistoryListSaga({ payload }: { payload: IAnswerHistoryData }) {
  try {
    const response: IAnswerHistoryResponse = yield call(
      fetchAnswerHistoryList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccessAction({
          ...response,
          result: {
            total: 0,
            total_answered: 0,
            total_no_answered: 0,
            total_record: 0,
            histories: [],
          },
        })
      );
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
      history.push("/login");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onAnswerHistoryListWatcher() {
  yield takeLatest(
    ActionType.ANSWER_HISTORY_LIST as any,
    answerHistoryListSaga
  );
}

export default [fork(onAnswerHistoryListWatcher)];
