import * as React from "react";

function PopUpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 13.5L8 8"
        stroke="#FB4E4E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 8H8"
        stroke="#FB4E4E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12V8"
        stroke="#FB4E4E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4386 1.01904H10.0526C9.74985 1.01904 9.50439 1.26664 9.50439 1.57206V5.99623C9.50439 6.30166 9.74985 6.54925 10.0526 6.54925H14.4386C14.7414 6.54925 14.9869 6.30166 14.9869 5.99623V1.57206C14.9869 1.26664 14.7414 1.01904 14.4386 1.01904Z"
        stroke="#FB4E4E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8409 10.3254V13.0131C12.8409 13.6214 12.3475 14.1192 11.7444 14.1192H2.97247C2.3694 14.1192 1.87598 13.6214 1.87598 13.0131V4.16479C1.87598 3.55647 2.3694 3.05875 2.97247 3.05875H5.9933"
        stroke="#FB4E4E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PopUpIcon;
