import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  HealthCheckQuestionResponse,
  HealthCheckQuestionParams,
} from "models/bodyTemperature/healthCheckQuestions";
import { httpStatus } from "configs/httpStatus";
import { fetchHealthCheckQuestions } from "services/bodyTemperature";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./healthCheckQuestionsActions";

function* healthCheckQuestionsSaga({
  payload,
}: {
  payload: HealthCheckQuestionParams;
}) {
  try {
    const response: HealthCheckQuestionResponse = yield call(
      fetchHealthCheckQuestions,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetHealthCheckQuestionsWatcher() {
  yield takeLatest(
    ActionType.HEALTH_CHECK_QUESTION as any,
    healthCheckQuestionsSaga
  );
}

export default [fork(onGetHealthCheckQuestionsWatcher)];
