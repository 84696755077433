import {
  ActionType,
  IExchangeListResponse,
  IExchangeListPayload,
} from "models/consultationRequest/exchangeList";

export const getExchangeListAction = (payload: IExchangeListPayload) => {
  return {
    type: ActionType.EXCHANGE_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.EXCHANGE_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IExchangeListResponse) => {
  return {
    type: ActionType.EXCHANGE_LIST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.EXCHANGE_LIST_RESET,
  };
};
