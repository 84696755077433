import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IGetActivityFilterListResponse,
} from "models/messagemanagement/activityFilterList";
import { httpStatus } from "configs/httpStatus";
import { fetchActivitiesFilterList } from "services/messagemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./activitiesFilterListActions";

function* activitiesFilterListSaga() {
  try {
    const response: IGetActivityFilterListResponse = yield call(
      fetchActivitiesFilterList
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetActivityFilterListWatcher() {
  yield takeLatest(
    ActionType.ACTIVITY_FILTER_LIST as any,
    activitiesFilterListSaga
  );
}

export default [fork(onGetActivityFilterListWatcher)];
