import { IResponse } from "models";

export enum ActionType {
  MENTAL_CHECK_SCORING = "action/MENTAL_CHECK_SCORING",
  MENTAL_CHECK_SCORING_SUCCESS = "action/MENTAL_CHECK_SCORING_SUCCESS",
  MENTAL_CHECK_SCORING_ERROR = "action/MENTAL_CHECK_SCORING_ERROR",
  MENTAL_CHECK_SCORING_RESET = "action/MENTAL_CHECK_SCORING_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

interface IProlems {
  emotional_disturbance: boolean;
  attention_care: boolean;
  normal: boolean;
  question_name: string;
}

export interface IMentalCheckScoringPayload {
  mental_check_type_id: string;
  mental_check_request_id: string;
  patient_id: string;
}

export interface IMentalCheckScoring {
  user_information?: {
    name: string;
    garde_class: string;
    age: string;
    gender: string;
    mental_health_observation_judgment_result: string;
  };
  item?: {
    feelings: string;
    concentrations: string;
    behaviour: string;
    relationship: string;
    total: string;
  };
  feelings_mood_problem?: IProlems[];
  concentrations_problem?: IProlems[];
  behavioral_problem?: IProlems[];
  relations_problem?: IProlems[];
}

export interface IMentalCheckScoringResponse extends IResponse {
  result: IMentalCheckScoring;
}
