import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  CSVDownloadUserResponse,
  ActionType,
  IUserListDownloadData,
} from "models/csvdownloaduser";
import { httpStatus } from "configs/httpStatus";
import { downloadCSVUser } from "services/userList";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./csvdownloaduserActions";

function* csvDownloadUserSaga({ payload }: { payload: IUserListDownloadData }) {
  try {
    const response: CSVDownloadUserResponse = yield call(
      downloadCSVUser,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDownloadCSVUserListWatcher() {
  yield takeLatest(ActionType.CSV_DOWNLOAD_USER as any, csvDownloadUserSaga);
}

export default [fork(onDownloadCSVUserListWatcher)];
