import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType, GradeListResponse } from "models/mentalHealth/gradeList";
import { httpStatus } from "configs/httpStatus";
import { getGradeList } from "services/mentalHealth";
import { handleSuccesAction, handleErrorAction } from "./gradeListActions";

function* gradeListSaga() {
  try {
    const response: GradeListResponse = yield call(getGradeList);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetGradeListWatcher() {
  yield takeLatest(ActionType.GRADE_LIST as any, gradeListSaga);
}

export default [fork(onGetGradeListWatcher)];
