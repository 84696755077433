import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";

import { Input, Select } from "components";

import { ButtonStyled, WrapperSettings } from "./ClassManagement.style";
import { useDispatch, useSelector } from "react-redux";
import {
  ClassTypeActionCreators,
  SchoolTypeActionCreators,
} from "redux/rootActions";
import { schoolTypeResponse } from "redux/classmanagement/schooltype/schoolTypeStates";
import { classTypeResponse } from "redux/classmanagement/classtype/classTypeStates";
import { IOption } from "components/select/Select";
import { localStorageHelper } from "utils";

const { useBreakpoint } = Grid;

const ClassNumberIsValid = "class-number-invalid";
const schema = yup.object().shape({
  schoolClassification: yup.string().required("select-school-classification"),
  namePrinciple: yup.string().required("select-name-principle"),
  numberOfClasses: yup
    .number()
    .positive(ClassNumberIsValid)
    .integer(ClassNumberIsValid)
    .max(26, ClassNumberIsValid)
    .required("enter-class-number"),
});

interface INamePrincipe {
  handlePrincipleSetting: (data: { [x: string]: any }) => void;
}

const NamePrincipleSetting: React.FC<INamePrincipe> = ({
  handlePrincipleSetting,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });
  const screens = useBreakpoint();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const dispatch = useDispatch();
  const schoolType = useSelector(schoolTypeResponse);
  const classType = useSelector(classTypeResponse);
  const [schoolOptions, setSchoolOptions] = useState<IOption[]>([]);
  const [classNamePrincipleOptions, setClassNamePrincipleOptions] = useState<
    IOption[]
  >([]);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(SchoolTypeActionCreators.schoolTypeAction({ section_type: 1 }));
    dispatch(ClassTypeActionCreators.classTypeAction({ section_type: 2 }));
  }, []);

  useEffect(() => {
    if (schoolType && schoolType.result) {
      let schoolTypeClone = [];
      if (currentLanguage === "ja") {
        schoolTypeClone = schoolType.result.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            value: item.id,
          };
        });
        setSchoolOptions(schoolTypeClone);
      } else {
        schoolTypeClone = schoolType.result.map((item: any) => {
          return {
            id: item.id,
            name: t(item.name),
            value: item.id,
          };
        });
        setSchoolOptions(schoolTypeClone);
      }
    }

    if (classType && classType.result) {
      let classTypeClone = [];
      if (currentLanguage === "ja") {
        classTypeClone = classType.result.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            value: item.id,
          };
        });
        setClassNamePrincipleOptions(classTypeClone);
      } else {
        classTypeClone = classType.result.map((item: any) => {
          return {
            id: item.id,
            name: t(item.name),
            value: item.id,
          };
        });
        setClassNamePrincipleOptions(classTypeClone);
      }
    }
  }, [schoolType, classType]);

  const labelInput = screens.xl ? (
    <div
      style={{
        position: "absolute",
        top: -13,
        width: 57,
      }}
    >
      <div style={{ width: 103 }}>{t("each-grade")}</div>
      <div style={{ width: 130 }}>{t("class-number")}</div>
    </div>
  ) : (
    <>{t("class-number")}</>
  );
  return (
    <WrapperSettings onSubmit={handleSubmit(handlePrincipleSetting)}>
      <Controller
        control={control}
        name="schoolClassification"
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Select
            label={t("school-classification")}
            placeholder="選択してください"
            width="156px"
            marginForm="0 32px 16px 0"
            fsLabel={14}
            fwLabel={500}
            lhLabel="20px"
            options={schoolOptions}
            onChange={onChange}
            error={t(error?.message)}
          />
        )}
      />
      <Controller
        control={control}
        name="namePrinciple"
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Select
            label={t("name-principle")}
            placeholder="選択してください"
            width="156px"
            marginForm="0 32px 16px 0"
            fsLabel={14}
            fwLabel={500}
            lhLabel="20px"
            options={classNamePrincipleOptions}
            onChange={onChange}
            error={t(error?.message)}
          />
        )}
      />
      <Controller
        control={control}
        name="numberOfClasses"
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Input
            type="number"
            marginForm="0 32px 16px 0"
            label={labelInput}
            height={31}
            width={48}
            fsLabel={14}
            fwLabel={500}
            lhLabel="16px"
            onChange={onChange}
            error={t(error?.message)}
          />
        )}
      />
      <ButtonStyled
        type="submit"
        name={t("create")}
        background="#2AC769"
        color="#FFFFFF"
        padding="3px 16px"
        border="none"
        bdr="6px"
        fontSize={16}
        fontWeight={700}
        lineHeight="16px"
      />
    </WrapperSettings>
  );
};

export default NamePrincipleSetting;
