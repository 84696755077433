import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { Button } from "components";
import {
  ConfirmModalStyled,
  TextConfirm,
  WrapperBtnConfirmModal,
} from "./Setting.style";

const DeleteModal = ({
  isVisible,
  handleOk,
  handleCancel,
  deleteItem,
}: any) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "setting" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "account",
  });

  return (
    <ConfirmModalStyled
      type="confirm"
      footer={null}
      visible={isVisible}
      onCancel={handleCancel}
    >
      <div style={{ textAlign: "center" }}>
        <TextConfirm>
          <Trans i18nKey="setting.delete-notification">
            <strong>
              「
              {{
                notify: `${deleteItem.time} ${deleteItem.notification_time}`,
              }}
              」
            </strong>
            の通知を削除します。
          </Trans>
        </TextConfirm>

        <TextConfirm>{t3("is-ok")}</TextConfirm>
        <WrapperBtnConfirmModal>
          <Button
            name={t1("cancel")}
            background="#E0E0E0"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            onClick={handleCancel}
          />
          <Button
            name={t2("yes")}
            background="#FB4E4E"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            onClick={handleOk}
          />
        </WrapperBtnConfirmModal>
      </div>
    </ConfirmModalStyled>
  );
};

export default DeleteModal;
