import { IResponse } from "models";

export enum ActionType {
  // create activity/group for school
  CREATE_ACTIVITY = "action/CREATE_ACTIVITY",
  CREATE_ACTIVITY_SUCCESS = "action/CREATE_ACTIVITY_SUCCESS",
  CREATE_ACTIVITY_ERROR = "action/CREATE_ACTIVITY_ERROR",
  CREATE_ACTIVITY_RESET = "action/CREATE_ACTIVITY_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICreateActivityData {
  activity: {
    name: string;
    parent_id?: number;
    is_required?: boolean;
  };
  locale?: string;
}

export interface ICreateActivityResponse extends IResponse {
  result: ICreateActivity;
}

export interface ICreateActivity {
  id: number;
  name: string;
}
