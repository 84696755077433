import * as React from "react";

function SelectIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="19"
      viewBox="0 0 8 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.13626 1.48046C3.52215 0.81904 4.47785 0.819039 4.86374 1.48046L6.58667 4.43357C6.97561 5.10022 6.49475 5.9375 5.72292 5.9375H2.27708C1.50525 5.9375 1.02439 5.10022 1.41333 4.43357L3.13626 1.48046Z"
        fill="#828282"
      />
      <path
        d="M4.86374 17.5195C4.47785 18.181 3.52215 18.181 3.13626 17.5195L1.41333 14.5664C1.02439 13.8998 1.50525 13.0625 2.27708 13.0625L5.72292 13.0625C6.49475 13.0625 6.97561 13.8998 6.58667 14.5664L4.86374 17.5195Z"
        fill="#828282"
      />
    </svg>
  );
}

export default SelectIcon;
