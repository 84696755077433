import { put, takeLatest, fork, call } from "redux-saga/effects";
import { httpStatus } from "configs/httpStatus";
import { putConfirmStudentList } from "services/classchangemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./putConfirmStudentClassChangeActions";
import {
  ActionType,
  IPutConfirmStudentData,
} from "models/classchangemanagement/putConfirmStudentClassChange";
import { IResponse } from "models";

function* putConfirmStudentClassChangeSaga({
  payload,
}: {
  payload: IPutConfirmStudentData;
}) {
  try {
    const response: IResponse = yield call(putConfirmStudentList, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onPutConfirmStudentClassChangeWatcher() {
  yield takeLatest(
    ActionType.PUT_STUDENT_CLASS_CHANGE as any,
    putConfirmStudentClassChangeSaga
  );
}

export default [fork(onPutConfirmStudentClassChangeWatcher)];
