import {
  Action,
  ActionType,
  IClassListResponse,
} from "models/classchangemanagement/getClassList";
import createReducer from "../../createReducer";

export interface ClassListEndReducerType {
  loading: boolean;
  error?: string;
  classListResponse?: IClassListResponse;
}

const defaultState: ClassListEndReducerType = {
  loading: false,
  error: undefined,
  classListResponse: undefined,
};

export const getClassListReducer = createReducer<ClassListEndReducerType>(
  defaultState,
  {
    [ActionType.GET_CLASS_LIST_END](state: ClassListEndReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_CLASS_LIST_END_ERROR](
      state: ClassListEndReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_CLASS_LIST_END_SUCCESS](
      state: ClassListEndReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        classListResponse: action.payload,
      };
    },
  }
);
