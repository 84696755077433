import {
  ActionType,
  ISendNotificationConsultationResponse,
  ISendNotificationConsultationPayload,
} from "models/consultationRequest/sendNotification";

export const sendNotificationAction = (
  payload: ISendNotificationConsultationPayload
) => {
  return {
    type: ActionType.SEND_NOTIFICATION_CONSULTATION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SEND_NOTIFICATION_CONSULTATION_ERROR,
    payload,
  };
};

export const handleSuccesAction = (
  payload: ISendNotificationConsultationResponse
) => {
  return {
    type: ActionType.SEND_NOTIFICATION_CONSULTATION_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.SEND_NOTIFICATION_CONSULTATION_RESET,
  };
};
