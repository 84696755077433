import {
  DownloadQrCodeData,
  ActionType,
  DownloadQrCodeResponse,
} from "../../models/downloadQrCode";

export const downloadQrcodeAction = (payload: DownloadQrCodeData) => {
  return {
    type: ActionType.DOWNLOAD_QR_CODE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DOWNLOAD_QR_CODE_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: DownloadQrCodeResponse) => {
  return {
    type: ActionType.DOWNLOAD_QR_CODE_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.DOWNLOAD_QR_CODE_RESET_DATA,
  };
};
