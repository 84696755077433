import {
  ActionType,
  Action,
  IIndividualAnswerResponse,
} from "models/questionnaires/individualAnswer";
import createReducer from "../../createReducer";

export interface IndividualAnswerReducerType {
  loading: boolean;
  error?: string;
  individualAnswerResponse?: IIndividualAnswerResponse;
}

const defaultState: IndividualAnswerReducerType = {
  loading: false,
  error: undefined,
  individualAnswerResponse: undefined,
};

export const individualAnswerReducer =
  createReducer<IndividualAnswerReducerType>(defaultState, {
    [ActionType.GET_INDIVIDUAL_ANSWER](state: IndividualAnswerReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_INDIVIDUAL_ANSWER_ERROR](
      state: IndividualAnswerReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        individualAnswerResponse: undefined,
      };
    },

    [ActionType.GET_INDIVIDUAL_ANSWER_SUCCESS](
      state: IndividualAnswerReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        individualAnswerResponse: action.payload,
      };
    },
  });
