export enum ActionType {
  // delete class for school
  DELETE_CLASS = "action/DELETE_CLASS",
  DELETE_CLASS_SUCCESS = "action/DELETE_CLASS_SUCCESS",
  DELETE_CLASS_ERROR = "action/DELETE_CLASS_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDeleteClassData {
  id: number;
}
