import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  TemperatureAmountsResponse,
  ActionType,
  TemperatureAmountsPayload,
} from "models/dashboard/temperatureAmounts";
import { httpStatus } from "configs/httpStatus";
import { fetchTemperatureAmountsChart } from "services/dashboard";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./temperatureAmountsActions";

function* getTemperatureAmountsSaga({
  payload,
}: {
  payload: TemperatureAmountsPayload;
}) {
  try {
    const response: TemperatureAmountsResponse = yield call(
      fetchTemperatureAmountsChart,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetTemperatureAmountsWatcher() {
  yield takeLatest(
    ActionType.GET_TEMPERATURE_AMOUNTS_CHART as any,
    getTemperatureAmountsSaga
  );
}

export default [fork(onGetTemperatureAmountsWatcher)];
