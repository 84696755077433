import * as React from "react";

function DownArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.46967 16.6302C6.76256 16.9231 7.23744 16.9231 7.53033 16.6302L12.3033 11.8573C12.5962 11.5644 12.5962 11.0895 12.3033 10.7966C12.0104 10.5037 11.5355 10.5037 11.2426 10.7966L7 15.0392L2.75736 10.7966C2.46447 10.5037 1.98959 10.5037 1.6967 10.7966C1.40381 11.0895 1.40381 11.5644 1.6967 11.8573L6.46967 16.6302ZM19 2.1499C19.4142 2.1499 19.75 1.81412 19.75 1.3999C19.75 0.985689 19.4142 0.649902 19 0.649902V2.1499ZM7.75 16.0999V3.3999H6.25V16.0999H7.75ZM9 2.1499H19V0.649902H9V2.1499ZM7.75 3.3999C7.75 2.70955 8.30964 2.1499 9 2.1499V0.649902C7.48122 0.649902 6.25 1.88112 6.25 3.3999H7.75Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}

export default DownArrow;
