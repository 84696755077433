import * as React from "react";

function DownloadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7614 8.29412L10.5761 13.7489C10.2579 14.0837 9.74206 14.0837 9.42386 13.7489L4.23864 8.29413C3.92045 7.95939 3.92045 7.41668 4.23864 7.08194C4.55683 6.74721 5.07272 6.74721 5.39092 7.08194L9.18522 11.0735L9.18522 1.85714C9.18522 1.38376 9.55001 1 10 1C10.45 1 10.8148 1.38376 10.8148 1.85714L10.8148 11.0735L14.6091 7.08194C14.9273 6.74721 15.4432 6.74721 15.7614 7.08194C16.0795 7.41668 16.0795 7.95939 15.7614 8.29412Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 17C4 16.4477 4.38376 16 4.85714 16H15.1429C15.6162 16 16 16.4477 16 17C16 17.5523 15.6162 18 15.1429 18H4.85714C4.38376 18 4 17.5523 4 17Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default DownloadIcon;
