import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button } from "components";
import {
  TooltipErrorMessage,
  AddInputStyled,
  CheckboxStyled,
} from "./ActivityManagement.style";

interface IAddInput {
  handleAddColumn: (data: any) => void;
}

const schema = yup.object().shape({
  newActivity: yup
    .string()
    .max(30, "max-text")
    .test(
      "empty-check",
      "enter-activity-name",
      (text: any) => text.trim() !== ""
    ),
});

const AddInput = ({ handleAddColumn }: IAddInput) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });
  const inputRef = useRef<any>(null);
  const { handleSubmit, control, watch, setValue } = useForm<{
    newActivity: string;
    check: boolean;
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      newActivity: "",
      check: false,
    },
  });
  const watchNewActivity = watch("newActivity");

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);
  return (
    <form onSubmit={handleSubmit(handleAddColumn)} style={{ display: "flex" }}>
      <div>
        <div style={{ display: "flex" }}>
          <Controller
            control={control}
            name="newActivity"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <div style={{ position: "relative" }}>
                <AddInputStyled
                  ref={inputRef}
                  spellCheck={false}
                  onChange={onChange}
                />
                {error && (
                  <TooltipErrorMessage>
                    <div style={{ position: "relative" }}>
                      <div>{t(error?.message)}</div>
                    </div>
                  </TooltipErrorMessage>
                )}
              </div>
            )}
          />
          <Button
            type="submit"
            background="#2AC769"
            color="#fff"
            margin="0 0 0 8px"
            name={t("create")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            disabled={!watchNewActivity}
          />
        </div>
        <Controller
          control={control}
          name="check"
          render={({ field: { onChange } }) => (
            <CheckboxStyled
              onChange={(e: any) => setValue("check", e.target.checked)}
            >
              {t("check-chose-description")}
            </CheckboxStyled>
          )}
        />
      </div>
    </form>
  );
};

export default AddInput;
