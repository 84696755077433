import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "./components";

import TemperatureSetting from "./TemperatureSetting";
import GeneralSetting from "./GeneralSetting";
import MessageSetting from "./MessageSetting";
import DeseaseOutbreakSetting from "./DeseaseOutbreakSetting";
import ConsultationRequestSetting from "./ConsultationRequestSetting";
import UserRegistrationSetting from "./UserRegistrationSetting";

const Setting: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "layout" });

  const [page, setPage] = useState("general");

  const allPage: any = {
    general: (
      <div>
        <GeneralSetting />
        <DeseaseOutbreakSetting />
        <UserRegistrationSetting />
      </div>
    ),
    temperature: <TemperatureSetting />,
    consultation: <ConsultationRequestSetting />,
  };

  return (
    <Box title={t("setting-label")} page={page} setPage={setPage}>
      {/* <GeneralSetting />
      <TemperatureSetting /> */}
      {/* <MessageSetting /> */}
      {/* <DeseaseOutbreakSetting />
      <ConsultationRequestSetting /> */}
      {allPage[page]}
    </Box>
  );
};

export default Setting;
