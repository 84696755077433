import { IResponse } from "models";

export enum ActionType {
  UPDATE_POSITION_COLUMNS = "action/UPDATE_POSITION_COLUMNS",
  UPDATE_POSITION_COLUMNS_SUCCESS = "action/UPDATE_POSITION_COLUMNS_SUCCESS",
  UPDATE_POSITION_COLUMNS_ERROR = "action/UPDATE_POSITION_COLUMNS_ERROR",
  UPDATE_POSITION_COLUMNS_RESET = "action/UPDATE_POSITION_COLUMNS_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

interface IColumn {
  id?: number;
  column_name: string;
  position: number;
}

export interface UpdatePositionColumnPayload {
  user_list_column_positions_attributes: IColumn[];
}

export interface UpdatePositionColumnResponse extends IResponse {
  result?: any;
}
