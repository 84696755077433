import * as React from "react";

function CloseRoundIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="16" height="16" rx="8" fill="white" />
      <g clipPath="url(#clip0_1029_11308)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7903 6.22207C12.0699 5.94251 12.0699 5.48924 11.7903 5.20967C11.5108 4.93011 11.0575 4.93011 10.7779 5.20967L8.49996 7.4876L6.22209 5.20975C5.94252 4.93019 5.48925 4.93019 5.20969 5.20976C4.93012 5.48932 4.93012 5.94259 5.20969 6.22215L7.48756 8.5L5.20968 10.7779C4.93011 11.0574 4.93011 11.5107 5.20968 11.7903C5.48924 12.0698 5.94251 12.0698 6.22208 11.7903L8.49996 9.5124L10.7779 11.7903C11.0575 12.0699 11.5108 12.0699 11.7903 11.7903C12.0699 11.5108 12.0699 11.0575 11.7903 10.7779L9.51237 8.5L11.7903 6.22207Z"
          fill="#FB4E4E"
        />
      </g>
      <rect x="0.5" y="0.5" width="16" height="16" rx="8" stroke="#FB4E4E" />
      <defs>
        <clipPath id="clip0_1029_11308">
          <rect width="9" height="9" fill="white" transform="translate(4 3)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseRoundIcon;
