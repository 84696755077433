import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { updateTemperatureSetting } from "services/setting";
import {
  ActionType,
  IUpdateTemperatureResponse,
  UpdateTemperatureRequest,
} from "models/setting/updateTemperature";

import {
  handleSuccesAction,
  handleErrorAction,
} from "./updateTemperatureActions";

function* updateTemperatureSettingSaga({
  payload,
}: {
  payload: UpdateTemperatureRequest;
}) {
  try {
    const response: IUpdateTemperatureResponse = yield call(
      updateTemperatureSetting,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateTemperatureSettingWatcher() {
  yield takeLatest(
    ActionType.UPDATE_TEMPERATURE_SETTING as any,
    updateTemperatureSettingSaga
  );
}

export default [fork(onUpdateTemperatureSettingWatcher)];
