import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DataPatientDetailResponse,
  DataPatientDetailRequest,
} from "models/bodyTemperature/patientDetail";
import { httpStatus } from "configs/httpStatus";
import { fetchPatientDetailData } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./patientDetailActions";

function* getDataPatientDetailSaga({
  payload,
}: {
  payload: DataPatientDetailRequest;
}) {
  try {
    const response: DataPatientDetailResponse = yield call(
      fetchPatientDetailData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDataPatientDetailWatcher() {
  yield takeLatest(
    ActionType.DATA_PATIENT_DETAIL as any,
    getDataPatientDetailSaga
  );
}

export default [fork(onGetDataPatientDetailWatcher)];
