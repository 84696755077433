import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/activitymanagement/createActivity";
import { httpStatus } from "configs/httpStatus";
import { createActivity } from "services/activitymanagement";
import { handleSuccesAction, handleErrorAction } from "./createActivityActions";
import {
  ICreateActivityData,
  ICreateActivityResponse,
} from "models/activitymanagement/createActivity";

function* createActivitySaga({ payload }: { payload: ICreateActivityData }) {
  try {
    const response: ICreateActivityResponse = yield call(
      createActivity,
      payload
    );
    if (
      response.status === httpStatus.StatusCreated ||
      response.status === httpStatus.StatusOK
    ) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCreateActivityWatcher() {
  yield takeLatest(ActionType.CREATE_ACTIVITY as any, createActivitySaga);
}

export default [fork(onCreateActivityWatcher)];
