import {
  Action,
  ActionType,
  TimeSlotDataResponse,
} from "models/vaccine/timeSlot";
import createReducer from "../../createReducer";

export interface TimeSlotReducerType {
  loading: boolean;
  error?: string;
  timeSlotResponse?: TimeSlotDataResponse;
}

const defaultState: TimeSlotReducerType = {
  loading: false,
  error: undefined,
  timeSlotResponse: undefined,
};

export const timeSlotReducer = createReducer<TimeSlotReducerType>(
  defaultState,
  {
    [ActionType.TIME_SLOT](state: TimeSlotReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.TIME_SLOT_ERROR](
      state: TimeSlotReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.TIME_SLOT_SUCCESS](
      state: TimeSlotReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        timeSlotResponse: action.payload,
      };
    },
  }
);
