import {
  ActionType,
  IMessageHistoryData,
  IMessageHistoryResponse,
} from "../../../models/messagemanagement/messageHistoryList";

export const messageHistoryListAction = (payload: IMessageHistoryData) => {
  return {
    type: ActionType.MESSAGE_HISTORY_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MESSAGE_HISTORY_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IMessageHistoryResponse) => {
  return {
    type: ActionType.MESSAGE_HISTORY_LIST_SUCCESS,
    payload,
  };
};
export const handleResetHistoryListAction = () => {
  return {
    type: ActionType.RESET_MESSAGE_HISTORY_LIST,
  };
};
