import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  DeleteMentalCheckData,
  IDeleteMentalCheckResponse,
} from "models/mentalHealth/deleteMentalCheck";
import { httpStatus } from "configs/httpStatus";
import { deleteMentalCheck } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./deleteMentalCheckActions";

function* deleteMentalCheckSaga({
  payload,
}: {
  payload: DeleteMentalCheckData;
}) {
  try {
    const response: IDeleteMentalCheckResponse = yield call(
      deleteMentalCheck,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDeleteMentalCheckWatcher() {
  yield takeLatest(
    ActionType.DELETE_MENTAL_CHECK as any,
    deleteMentalCheckSaga
  );
}

export default [fork(onDeleteMentalCheckWatcher)];
