import { IResponse } from "models";

export enum ActionType {
  GET_FEVER_CHART = "action/GET_FEVER_CHART",
  GET_FEVER_CHART_SUCCESS = "action/GET_FEVER_CHART_SUCCESS",
  GET_FEVER_CHART_ERROR = "action/GET_FEVER_CHART_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface FeverPayload {
  aggregate_date_to: string;
}

export interface FeverResponse extends IResponse {
  fevers: any;
}
