import {
  QuestionnairesDetailResponse,
  ActionType,
} from "models/questionnaires/questionnairesDetail";

export const getQuestionnaireDetailAction = (payload: { id: number }) => {
  return {
    type: ActionType.GET_QUESTIONNAIRES_DETAIL,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_QUESTIONNAIRES_DETAIL_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: QuestionnairesDetailResponse) => {
  return {
    type: ActionType.GET_QUESTIONNAIRES_DETAIL_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.GET_QUESTIONNAIRES_DETAIL_RESET,
  };
};
