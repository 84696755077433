import { put, takeLatest, fork, call } from "redux-saga/effects";

import { ActionType, EditAccountResponse } from "models/account/editAccount";
import { editAccount } from "services/account";

import { handleSuccesAction, handleErrorAction } from "./editAccountActions";

function* editAccountSaga({ payload }: any) {
  try {
    const response: EditAccountResponse = yield call(editAccount, payload);
    yield put(handleSuccesAction(response));
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onEditAccountWatcher() {
  yield takeLatest(ActionType.EDIT_ACCOUNT as any, editAccountSaga);
}

export default [fork(onEditAccountWatcher)];
