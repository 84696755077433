import { IResponse } from "models";

export enum ActionType {
  GET_PROGRESS_CHART = "action/GET_PROGRESS_CHART",
  GET_PROGRESS_CHART_SUCCESS = "action/GET_PROGRESS_CHART_SUCCESS",
  GET_PROGRESS_CHART_ERROR = "action/GET_PROGRESS_CHART_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ProgressPayload {
  aggregate_date: string;
}

export interface ProgressResponse extends IResponse {
  progress: any;
}
