import React, { useState, useEffect } from "react";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { localStorageHelper } from "utils";
import { Pagination } from "components";

import { answerHistoryActionCreators } from "redux/rootActions";
import { IAnswerHistoryData } from "models/questionnaires/answerHistory";
import {
  selectIsLoading,
  answerHistoryListDataResponse,
} from "redux/questionnaires/answerHistory/answerHistoryStates";

import { CheckboxStyled } from "screens/account/Account.style";
import {
  ConfirmStatusModalStyled,
  ComfirmStatusTitle,
  FilterContainer,
  ConfirmPaginationBottom,
  ConfirmTableStyled,
} from "./Questions.style";

const { useBreakpoint } = Grid;

interface IConfirmStatusModal {
  id: number;
  isVisible: boolean;
  handleCancel: (visible: boolean) => void;
}

interface IStatusFilter {
  read: boolean;
  unread: boolean;
}

const ConfirmStatusModal: React.FC<IConfirmStatusModal> = ({
  id,
  isVisible,
  handleCancel,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "questions",
  });
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const answerHistoryDatas = useSelector(answerHistoryListDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const [statusFilter, setStatusFilter] = useState<IStatusFilter>({
    read: false,
    unread: false,
  });
  const [input, setInput] = useState<IAnswerHistoryData>({
    limit: 100,
    page: 1,
    locale: currentLanguage,
    is_answered: "",
    id: id,
  });
  const [history, setHistory] = useState<any>({
    list: [],
    total: 0,
    totalAnswered: 0,
    totalRecords: 0,
    totalNoAnswered: 0,
  });

  const paginationHeight =
    history.totalRecords > input.limit ? 0 : screens.xs ? 109 : 53;
  const overflowHeight = screens.xs
    ? `${window.innerHeight - 447 + paginationHeight}px`
    : `${window.innerHeight - 297 + paginationHeight}px`;

  const columns = [
    {
      title: t1("full-name"),
      dataIndex: "full_name",
      key: "full_name",
      width: 125,
      fixed: window.innerWidth < 311 ? "" : "left",
    },
    {
      title: t1("grade"),
      dataIndex: "classes_name",
      key: "classes_name",
      width: 160,
    },
    {
      title: t1("attendance"),
      dataIndex: "roll_number",
      key: "roll_number",
      width: 128,
      render: (text: string) => {
        if (!text) {
          return "-";
        }
        return text;
      },
    },
    {
      title: t2("answer-status"),
      dataIndex: "is_answered",
      key: "is_answered",
      render: (text: string) => {
        if (text || text === null) {
          return t2("answered");
        }
        return (
          <div style={{ fontWeight: "bold", color: "#FB4E4E" }}>
            {t2("unanswered")}
          </div>
        );
      },
    },
  ];

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  useEffect(() => {
    dispatch(
      answerHistoryActionCreators.answerHistoryListAction({
        ...input,
      })
    );
  }, [input.page, input.is_answered]);

  useEffect(() => {
    let isRead: string | boolean = "";

    if (statusFilter.read && !statusFilter.unread) {
      isRead = true;
    } else if (!statusFilter.read && statusFilter.unread) {
      isRead = false;
    }

    setInput({
      ...input,
      is_answered: isRead,
      page: 1,
    });
  }, [statusFilter]);

  useEffect(() => {
    if (answerHistoryDatas && Object.keys(answerHistoryDatas?.result).length) {
      const {
        total,
        total_answered,
        total_no_answered,
        total_record,
        histories,
      } = answerHistoryDatas?.result;

      setHistory({
        list: histories,
        total,
        totalAnswered: total_answered,
        totalRecords: total_record,
        totalNoAnswered: total_no_answered,
      });
    }

    return () => {
      dispatch(answerHistoryActionCreators.handleResetHistoryListAction());
    };
  }, [answerHistoryDatas]);

  useEffect(() => {
    setHistory({
      list: [],
      total: 0,
      totalAnswered: 0,
      totalRecords: 0,
      totalNoAnswered: 0,
    });
  }, []);

  const ShowPagination = () => {
    return history.totalRecords > input.limit ? (
      <Pagination
        current={input.page}
        onChange={handleChangePage}
        pageSize={input.limit}
        total={history.totalRecords}
        showSizeChanger={false}
        showLessItems={!screens.xl}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      <ConfirmStatusModalStyled
        type="search"
        title={
          <ComfirmStatusTitle>
            <div className="title">{t2("confirm-answer-status")}</div>
            <div className="status">
              {t2("sent")}：{history.total}
              {t2("item")} / {t2("answered")}：{history.totalAnswered}
              {t2("item")} / {t2("unanswered")}：{history.totalNoAnswered}
              {t2("item")}
            </div>
          </ComfirmStatusTitle>
        }
        visible={isVisible}
        onCancel={() => handleCancel(false)}
      >
        <FilterContainer>
          <div className="filter">
            <div className="title">{t2("search")}</div>
            <div className="item">
              <CheckboxStyled
                checked={statusFilter.read}
                onChange={() =>
                  setStatusFilter({
                    ...statusFilter,
                    read: !statusFilter.read,
                  })
                }
              />
              <div>{t2("answered")}</div>
            </div>
            <div className="item">
              <CheckboxStyled
                checked={statusFilter.unread}
                onChange={() =>
                  setStatusFilter({
                    ...statusFilter,
                    unread: !statusFilter.unread,
                  })
                }
              />
              <div>{t2("unanswered")}</div>
            </div>
          </div>
          <ShowPagination />
        </FilterContainer>
        <ConfirmTableStyled
          dataSource={history.list}
          columns={columns}
          scroll={{ x: "max-content", y: overflowHeight }}
          loading={isLoading}
        />
        <ConfirmPaginationBottom>
          <ShowPagination />
        </ConfirmPaginationBottom>
      </ConfirmStatusModalStyled>
    </>
  );
};

export default ConfirmStatusModal;
