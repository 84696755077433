import { IResponse } from "models";
import { ActionType } from "../../../models/classchangemanagement/uploadCSV";

export const uploadCSVClassChangeAction = (payload: any) => {
  return {
    type: ActionType.UPLOAD_CSV_CLASS_CHANGE,
    payload,
  };
};

export const handleErrorAction = (payload: any) => {
  return {
    type: ActionType.UPLOAD_CSV_CLASS_CHANGE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.UPLOAD_CSV_CLASS_CHANGE_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.UPLOAD_CSV_CLASS_CHANGE_RESET,
  };
};
