import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  DeseaseOutbreakResponse,
  ActionType,
  DeseaseOutbreakPayload,
} from "models/dashboard/deseaseOutbreak";
import { httpStatus } from "configs/httpStatus";
import { fetchDeseaseOutbreakChart } from "services/dashboard";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./deseaseOutbreakActions";

function* getDeseaseOutbreakSaga({
  payload,
}: {
  payload: DeseaseOutbreakPayload;
}) {
  try {
    const response: DeseaseOutbreakResponse = yield call(
      fetchDeseaseOutbreakChart,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDeseaseOutbreakWatcher() {
  yield takeLatest(
    ActionType.GET_DESEASE_OUTBREAK_CHART as any,
    getDeseaseOutbreakSaga
  );
}

export default [fork(onGetDeseaseOutbreakWatcher)];
