import * as React from "react";

function FaceBadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="#C6DA4A" />
      <path
        d="M5.4689 7.75199C5.28804 7.35409 5.28804 6.92003 5.4689 6.52214C5.79444 5.87104 6.69874 5.47315 7.42218 5.76253C7.5307 5.7987 7.63922 5.87104 7.71156 5.94339C7.82008 6.0519 7.92859 6.23276 8.00094 6.34128C8.21797 6.77534 8.29031 7.39027 8.00094 7.78816C7.42218 8.62011 5.9753 8.72863 5.4689 7.75199Z"
        fill="white"
      />
      <path
        d="M11.9438 7.75199C11.7629 7.35409 11.7629 6.92003 11.9438 6.52214C12.2693 5.87104 13.1736 5.47315 13.897 5.76253C14.0056 5.7987 14.1141 5.87104 14.1864 5.94339C14.2949 6.0519 14.4034 6.23276 14.4758 6.34128C14.6928 6.77534 14.7652 7.39027 14.4758 7.78816C13.8609 8.62011 12.3778 8.72863 11.9438 7.75199Z"
        fill="white"
      />
      <path
        d="M6.30076 15.2034C6.22842 15.2034 6.15608 15.2034 6.08373 15.1672C5.8667 15.0587 5.79436 14.8055 5.93904 14.5885C6.44545 13.6842 7.71147 13.1054 9.33921 13.0331C10.7861 12.9608 12.7756 13.3225 13.6075 14.5523C13.7522 14.7332 13.6799 14.9864 13.499 15.1311C13.3181 15.2758 13.0649 15.2034 12.9202 15.0226C12.4138 14.2629 10.967 13.7927 9.37538 13.8651C8.07319 13.9374 7.0242 14.3353 6.66248 14.9864C6.59014 15.1311 6.44545 15.2034 6.30076 15.2034Z"
        fill="white"
      />
    </svg>
  );
}

export default FaceBadIcon;
