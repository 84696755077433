import { IResponse } from "models";

export enum ActionType {
  SEND_NOTIFICATION_CONSULTATION = "action/SEND_NOTIFICATION_CONSULTATION",
  SEND_NOTIFICATION_CONSULTATION_SUCCESS = "action/SEND_NOTIFICATION_CONSULTATION_SUCCESS",
  SEND_NOTIFICATION_CONSULTATION_ERROR = "action/SEND_NOTIFICATION_CONSULTATION_ERROR",
  SEND_NOTIFICATION_CONSULTATION_RESET = "action/SEND_NOTIFICATION_CONSULTATION_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ISendNotificationConsultationPayload {
  id: number;
  request_type: number;
}

export interface ISendNotificationConsultationResponse extends IResponse {}
