import React, { useState, useEffect } from "react";
import { Grid, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { SendCompleteIcon } from "icons";
import { localStorageHelper } from "utils";
import { Pagination, Button } from "components";
import { httpStatus } from "configs/httpStatus";

import {
  GetReadHistoryActionCreators,
  pushNotificationActionCreators,
} from "redux/rootActions";
import { IReadHistoryData } from "models/messagemanagement/messageReadHistory";
import {
  selectIsLoading,
  readHistoryListDataResponse,
} from "redux/messagemanagement/readHistory/readHistoryListStates";
import {
  selectIsNotificationSent,
  pushNotificationResponse,
} from "redux/messagemanagement/pushNotification/pushNotificationStates";

import { CheckboxStyled } from "screens/account/Account.style";
import {
  ConfirmStatusModalStyled,
  ComfirmStatusTitle,
  FilterContainer,
  ConfirmPaginationBottom,
  ConfirmTableStyled,
  ConfirmContainer,
  TextConfirm,
  ButtonContainer,
} from "./MessageDelivery.style";

const { useBreakpoint } = Grid;

interface IConfirmStatusModal {
  isVisible: boolean;
  handleCancel: (visible: boolean) => void;
  messageInfor: any;
  messageContent?: string;
}

interface IStatusFilter {
  read: boolean;
  unread: boolean;
}

const ConfirmStatusModal: React.FC<IConfirmStatusModal> = ({
  isVisible,
  handleCancel,
  messageInfor,
  messageContent,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const readHistoryDatas = useSelector(readHistoryListDataResponse);
  const isLoading = useSelector(selectIsLoading);
  const pushNotification = useSelector(pushNotificationResponse);
  const notificationSent = useSelector(selectIsNotificationSent);

  const [statusFilter, setStatusFilter] = useState<IStatusFilter>({
    read: false,
    unread: false,
  });
  const [input, setInput] = useState<IReadHistoryData>({
    limit: 100,
    page: 1,
    locale: currentLanguage,
    is_read: "",
    id: messageInfor.id,
  });
  const [history, setHistory] = useState<any>({
    list: [],
    total: 0,
    totalRead: 0,
    totalRecords: 0,
    totalUnread: 0,
  });
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);
  const isDisabled = history.list.some((item: any) => item.is_read === false);

  const paginationHeight =
    history.totalRecords > input.limit ? 0 : screens.xs ? 109 : 53;
  const overflowHeight = screens.xs
    ? `${window.innerHeight - 447 + paginationHeight}px`
    : `${window.innerHeight - 297 + paginationHeight}px`;

  const columns = [
    {
      title: t1("full-name"),
      dataIndex: "full_name",
      key: "full_name",
      width: 125,
      fixed: window.innerWidth < 311 ? "" : "left",
    },
    {
      title: t1("grade"),
      dataIndex: "classes_name",
      key: "classes_name",
      width: 160,
    },
    {
      title: t1("attendance"),
      dataIndex: "roll_number",
      key: "roll_number",
      width: 128,
      render: (text: string) => {
        if (!text) {
          return "-";
        }
        return text;
      },
    },
    {
      title: t("open-status"),
      dataIndex: "is_read",
      key: "is_read",
      render: (text: string, record: any) => {
        if (record.is_read || record.is_read === null) {
          return t("read");
        }
        return (
          <div style={{ fontWeight: "bold", color: "#FB4E4E" }}>
            {t("unread")}
          </div>
        );
      },
    },
  ];

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  useEffect(() => {
    if (pushNotification?.status === httpStatus.StatusOK) {
      dispatch(
        GetReadHistoryActionCreators.readHistoryListAction({
          ...input,
        })
      );
    }
  }, [pushNotification]);

  useEffect(() => {
    dispatch(
      GetReadHistoryActionCreators.readHistoryListAction({
        ...input,
      })
    );
  }, [input.page, input.is_read]);

  useEffect(() => {
    let isRead: string | boolean = "";

    if (statusFilter.read && !statusFilter.unread) {
      isRead = true;
    } else if (!statusFilter.read && statusFilter.unread) {
      isRead = false;
    }

    setInput({
      ...input,
      is_read: isRead,
      page: 1,
    });
  }, [statusFilter]);

  useEffect(() => {
    if (readHistoryDatas && Object.keys(readHistoryDatas?.result).length) {
      const { histories, total, total_read, total_record, total_unread } =
        readHistoryDatas?.result;

      setHistory({
        list: histories,
        total,
        totalRead: total_read,
        totalRecords: total_record,
        totalUnread: total_unread,
      });
    }

    return () => {
      dispatch(GetReadHistoryActionCreators.handleResetHistoryListAction());
    };
  }, [readHistoryDatas]);

  useEffect(() => {
    if (notificationSent) {
      notification.success({
        placement: "bottomRight",
        message: t("reminder-sent-non-read"),
        icon: <SendCompleteIcon />,
      });
      dispatch(pushNotificationActionCreators.handleResetAction());
    }
  }, [notificationSent]);

  const ShowPagination = () => {
    return history.totalRecords > input.limit ? (
      <Pagination
        current={input.page}
        onChange={handleChangePage}
        pageSize={input.limit}
        total={history.totalRecords}
        showSizeChanger={false}
        showLessItems={!screens.xl}
      />
    ) : (
      <></>
    );
  };

  const ConfirmModal = () => (
    <ConfirmContainer>
      <TextConfirm>
        <div>{t("send-to-unread-messages")}</div>
        <div>{t("are-you-ok")}</div>
      </TextConfirm>
      <ButtonContainer>
        <Button
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 24px 13px"
          name={t1("cancel")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          onClick={() => setIsVisibleConfirm(false)}
        />
        <Button
          background="#2AC769"
          color="#FFFFFF"
          padding="11px 48px 13px"
          name={t("yes")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          onClick={() => {
            setIsVisibleConfirm(false);
            dispatch(
              pushNotificationActionCreators.pushNotificationAction({
                id: messageInfor.id,
                title: messageInfor.subject,
                description: messageContent,
              })
            );
          }}
        />
      </ButtonContainer>
    </ConfirmContainer>
  );

  return (
    <>
      <ConfirmStatusModalStyled
        type="search"
        title={
          <ComfirmStatusTitle>
            {t("confirm-open-status")}
            <div className="status">
              {t("sent")}：{history.total}
              {t("item")} / {t("read")}：{history.totalRead}
              {t("item")} / {t("unread")}：{history.totalUnread}
              {t("item")}
            </div>
            <Button
              onClick={() => setIsVisibleConfirm(true)}
              background="#2AC769"
              color="#fff"
              name={t("send-to-non-readers")}
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              padding="8px"
              disabled={!isDisabled}
            />
          </ComfirmStatusTitle>
        }
        visible={isVisible}
        onCancel={() => handleCancel(false)}
      >
        <FilterContainer>
          <div className="filter">
            <div className="title">{t("search")}</div>
            <div className="item">
              <CheckboxStyled
                checked={statusFilter.read}
                onChange={() =>
                  setStatusFilter({
                    ...statusFilter,
                    read: !statusFilter.read,
                  })
                }
              />
              <div>{t("read")}</div>
            </div>
            <div className="item">
              <CheckboxStyled
                checked={statusFilter.unread}
                onChange={() =>
                  setStatusFilter({
                    ...statusFilter,
                    unread: !statusFilter.unread,
                  })
                }
              />
              <div>{t("unread")}</div>
            </div>
          </div>
          <ShowPagination />
        </FilterContainer>
        <ConfirmTableStyled
          dataSource={history.list}
          columns={columns}
          scroll={{ x: "max-content", y: overflowHeight }}
          loading={isLoading}
        />
        <ConfirmPaginationBottom>
          <ShowPagination />
        </ConfirmPaginationBottom>
        {isVisibleConfirm && <ConfirmModal />}
      </ConfirmStatusModalStyled>
    </>
  );
};

export default ConfirmStatusModal;
