export enum ActionType {
  // update class for school
  UPDATE_CLASS = "action/UPDATE_CLASS",
  UPDATE_CLASS_SUCCESS = "action/UPDATE_CLASS_SUCCESS",
  UPDATE_CLASS_ERROR = "action/UPDATE_CLASS_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateClassData {
  class: {
    name: string;
    id: number;
  };
}
