import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IDeleteQuestionnairesPayload,
  ActionType,
  DeleteQuestionnairesResponse,
} from "models/questionnaires/deleteQuestionnaire";
import { httpStatus } from "configs/httpStatus";
import { deleteQuestionnaire } from "services/questionnaires";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./deleteQuestionnairesActions";

function* deleteQuestionnariesSaga({
  payload,
}: {
  payload: IDeleteQuestionnairesPayload;
}) {
  try {
    const response: DeleteQuestionnairesResponse = yield call(
      deleteQuestionnaire,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onDeleteQuestionnariesSagaSubmitWatcher() {
  yield takeLatest(
    ActionType.DELETE_QUESTIONNAIRES as any,
    deleteQuestionnariesSaga
  );
}

export default [fork(onDeleteQuestionnariesSagaSubmitWatcher)];
