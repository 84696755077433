import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  AttendanceFilterData,
  ActionType,
  AttendanceFilterListResponse,
} from "models/messagemanagement/attendanceFilterList";
import { httpStatus } from "configs/httpStatus";
import { fetchAttendanceFilterList } from "services/messagemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./attendanceFilterListActions";

function* attendanceListFilterSaga({
  payload,
}: {
  payload: AttendanceFilterData;
}) {
  try {
    const response: AttendanceFilterListResponse = yield call(
      fetchAttendanceFilterList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetAttendanceListFilterWatcher() {
  yield takeLatest(
    ActionType.ATTENDANCE_FILTER_LIST as any,
    attendanceListFilterSaga
  );
}

export default [fork(onGetAttendanceListFilterWatcher)];
