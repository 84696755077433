import * as React from "react";

function MoreIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="8"
        fill="white"
        stroke={props.fill}
        strokeWidth="2"
      />
      <circle cx="7.71429" cy="12.0001" r="1.71429" fill={props.fill} />
      <circle cx="11.9999" cy="12.0001" r="1.71429" fill={props.fill} />
      <circle cx="16.2858" cy="12.0001" r="1.71429" fill={props.fill} />
    </svg>
  );
}

export default MoreIcon;
