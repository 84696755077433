import { IResponse } from "models";

export enum ActionType {
  DELETE_ACCOUNT = "action/DELETE_ACCOUNT",
  DELETE_ACCOUNT_SUCCESS = "action/DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_ERROR = "action/DELETE_ACCOUNT_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DeleteAccountResponse extends IResponse {}
