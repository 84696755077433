import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  // get activity list for search/filter
  ACTIVITY_FILTER_LIST = "action/ACTIVITY_FILTER_LIST",
  ACTIVITY_FILTER_SUCCESS = "action/ACTIVITY_FILTER_LIST_SUCCESS",
  ACTIVITY_FILTER_ERROR = "action/ACTIVITY_FILTER_LIST_SCHOOL_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGetActivityFilterListResponse extends IResponse {
  result: IOption[];
}
