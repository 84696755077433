import {
  ActionType,
  SubDepartmentListResponse,
  SubDepartmentListData,
} from "../../models/subdepartmentList";

export const subDepartmentListAction = (payload: SubDepartmentListData) => {
  return {
    type: ActionType.SUB_DEPARTMENT_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SUB_DEPARTMENT_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: SubDepartmentListResponse) => {
  return {
    type: ActionType.SUB_DEPARTMENT_LIST_SUCCESS,
    payload,
  };
};
