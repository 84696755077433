import * as React from "react";

function EndIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0462 7.05123C12.3234 6.82588 12.666 6.69869 13.0215 6.68926H21.1492V11.6244C21.1503 12.9329 21.6648 14.1873 22.5796 15.112C23.0451 15.5664 23.5952 15.9227 24.198 16.1599C24.8008 16.3971 25.4442 16.5107 26.0908 16.4939H30.9674V26.0517L34.2185 29.3418V14.8488C34.2015 14.6977 34.1688 14.5488 34.1209 14.4046V14.2566C34.037 14.0908 33.9333 13.9361 33.8121 13.7959L24.0589 3.92557C23.9217 3.80115 23.7686 3.69601 23.6037 3.61301H23.4574C23.2791 3.46692 23.0662 3.37054 22.8397 3.33337H13.0865C11.8814 3.34513 10.7232 3.80806 9.83546 4.63294L12.0462 7.05123ZM24.4002 9.00885L28.6104 13.2695H26.0908C25.8769 13.2708 25.6648 13.2293 25.4667 13.1474C25.2687 13.0655 25.0886 12.9449 24.9367 12.7925C24.778 12.631 24.6542 12.4381 24.573 12.2257C24.4919 12.0133 24.4552 11.7861 24.4653 11.5587L24.4002 9.00885Z"
        fill={props.fill}
      />
      <path
        d="M5.63218 3.33348L3.33333 5.63232L34.3678 36.6667L36.6667 34.3678L5.63218 3.33348Z"
        fill={props.fill}
      />
      <path
        d="M25.1804 26.871H15.4272C15.2122 26.8719 14.9994 26.9154 14.8013 26.9992C14.6032 27.0829 14.4237 27.205 14.2731 27.3586C13.9728 27.6614 13.8035 28.0701 13.8017 28.4965C13.8005 28.7104 13.8415 28.9225 13.9223 29.1206C14.0032 29.3186 14.1225 29.4988 14.2731 29.6507C14.425 29.8013 14.6051 29.9205 14.8032 30.0014C15.0012 30.0823 15.2133 30.1233 15.4272 30.122H25.1804C25.3944 30.1233 25.6064 30.0823 25.8045 30.0014C26.0025 29.9205 26.1827 29.8013 26.3345 29.6507C26.4852 29.4988 26.6044 29.3186 26.6853 29.1206C26.7662 28.9225 26.8072 28.7104 26.806 28.4965C26.8005 28.307 26.762 28.12 26.6922 27.9438L25.7331 26.9848C25.5569 26.9149 25.3699 26.8764 25.1804 26.871Z"
        fill={props.fill}
      />
      <path
        d="M14.2731 16.6465C14.425 16.7971 14.6051 16.9163 14.8032 16.9972C15.0012 17.0781 15.2133 17.1191 15.4272 17.1179H15.8661L13.8668 15.1348C13.8576 15.2538 13.8576 15.3733 13.8668 15.4923C13.8467 15.9153 13.9925 16.3294 14.2731 16.6465Z"
        fill={props.fill}
      />
      <path
        d="M29.8508 30.6505V31.0833C29.8521 31.2943 29.8102 31.5034 29.7275 31.6987C29.6447 31.894 29.5229 32.0717 29.3688 32.2214C29.2135 32.37 29.0294 32.4875 28.8269 32.5673C28.6244 32.647 28.4076 32.6875 28.1889 32.6862H11.57C11.3513 32.6875 11.1345 32.647 10.932 32.5673C10.7295 32.4875 10.5454 32.37 10.3901 32.2214C10.2361 32.0717 10.1142 31.894 10.0315 31.6987C9.94879 31.5034 9.90688 31.2943 9.90815 31.0833V11.4158L6.58437 8.20996C6.58437 8.33819 6.58437 8.46641 6.58437 8.59464V31.0352C6.58554 32.3101 7.11158 33.5325 8.04684 34.4334C8.98094 35.3355 10.2482 35.8428 11.57 35.8439H28.1889C29.5107 35.8428 30.778 35.3355 31.7121 34.4334C32.0713 34.0874 32.3689 33.6866 32.5929 33.2472L29.8508 30.6505Z"
        fill={props.fill}
      />
      <path
        d="M14.2731 20.8565C13.9728 21.1593 13.8035 21.568 13.8017 21.9944C13.8005 22.2084 13.8415 22.4204 13.9223 22.6185C14.0032 22.8165 14.1225 22.9967 14.2731 23.1486C14.425 23.2992 14.6051 23.4184 14.8032 23.4993C15.0012 23.5802 15.2133 23.6212 15.4272 23.6199H22.3682L19.1172 20.3689H15.4272C15.2122 20.3698 14.9994 20.4134 14.8013 20.4971C14.6032 20.5808 14.4237 20.7029 14.2731 20.8565V20.8565Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default EndIcon;
