import { ActionType } from "models/messagemanagement/sendMessages";
import { IResponse } from "models";

export const sendMessageAction = (payload: any) => {
  return {
    type: ActionType.SEND_MESSAGE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SEND_MESSAGE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.SEND_MESSAGE_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.RESET_SEND_MESSAGE_DATA,
  };
};
