import {
  ActionType,
  IMentalCheckDetailsResponse,
  MentalCheckDetailsData,
} from "models/mentalHealth/mentalCheckDetails";

export const mentalCheckDetailsAction = (payload: MentalCheckDetailsData) => {
  return {
    type: ActionType.MENTAL_CHECK_DETAILS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MENTAL_CHECK_DETAILS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IMentalCheckDetailsResponse) => {
  return {
    type: ActionType.MENTAL_CHECK_DETAILS_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.MENTAL_CHECK_DETAILS_RESET_DATA,
  };
};
