import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  FilterStudentData,
  ActionType,
  GetStudentListResponse,
} from "models/messagemanagement/getStudentList";
import { httpStatus } from "configs/httpStatus";
import { fetchStudentList } from "services/messagemanagement";
import { handleSuccesAction, handleErrorAction } from "./getStudentListActions";

function* getStudentListSaga({ payload }: { payload: FilterStudentData }) {
  try {
    const response: GetStudentListResponse = yield call(
      fetchStudentList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({
          ...response,
          result: { user_detail: [], user_with_temperature: [] },
        })
      );
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetStudentListWatcher() {
  yield takeLatest(ActionType.GET_STUDENT_LIST as any, getStudentListSaga);
}

export default [fork(onGetStudentListWatcher)];
