import {
  Action,
  ActionType,
  IClassListResponse,
} from "models/mentalHealth/classList";
import createReducer from "redux/createReducer";

export interface MentalClassListReducerType {
  loading: boolean;
  error?: string;
  classListResponse?: IClassListResponse;
}

const defaultState: MentalClassListReducerType = {
  loading: false,
  error: undefined,
  classListResponse: undefined,
};

export const mentalClassListReducer = createReducer<MentalClassListReducerType>(
  defaultState,
  {
    [ActionType.MENTAL_CLASS_LIST](state: MentalClassListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MENTAL_CLASS_LIST_ERROR](
      state: MentalClassListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        classListResponse: undefined,
      };
    },

    [ActionType.MENTAL_CLASS_LIST_SUCCESS](
      state: MentalClassListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        classListResponse: action.payload,
      };
    },

    [ActionType.MENTAL_CLASS_LIST_RESET_DATA](
      state: MentalClassListReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        classListResponse: undefined,
      };
    },
  }
);
