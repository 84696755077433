import {
  ActionType,
  ActivitiesListResponse,
  ActivitiesListData,
} from "../../models/activities";

export const activitiesListAction = (payload: ActivitiesListData) => {
  return {
    type: ActionType.ACTIVITIES_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.ACTIVITIES_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ActivitiesListResponse) => {
  return {
    type: ActionType.ACTIVITIES_LIST_SUCCESS,
    payload,
  };
};
