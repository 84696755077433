import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IUpdateQuestionnairesPayload,
  ActionType,
  UpdateQuestionnairesResponse,
} from "models/questionnaires/updateQuestionnaires";
import { httpStatus } from "configs/httpStatus";
import { updateQuestionnaire } from "services/questionnaires/index";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./updateQuestionnairesActions";
import { history } from "../../configureStore";

function* updateQuestionnaireSaga({
  payload,
}: {
  payload: IUpdateQuestionnairesPayload;
}) {
  try {
    const response: UpdateQuestionnairesResponse = yield call(
      updateQuestionnaire,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
      history.push("/login");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateQuestionnaireWatcher() {
  yield takeLatest(
    ActionType.UPDATE_QUESTIONNAIRES as any,
    updateQuestionnaireSaga
  );
}

export default [fork(onUpdateQuestionnaireWatcher)];
