import { useEffect, RefObject } from "react";

export const useScroll = (
  ref: RefObject<any>,
  handler: any,
  position: number
) => {
  useEffect(() => {
    const listener = () => {
      if (!ref.current) return;
      const { y } = ref.current.getBoundingClientRect();
      if (y <= position) {
        handler(true);
      }
      if (y > position) {
        handler(false);
      }
    };
    document.addEventListener("scroll", listener);
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [ref, handler]);
};
