import { createApiCall2, MethodType, URI } from "../Api";
import { ActivitiesListData } from "models/activities";

export const fetchActivites = (payload: ActivitiesListData) => {
  return createApiCall2(
    MethodType.GET,
    URI.USERS.GET_ACTIVITY_GROUP_LIST,
    payload
  );
};
