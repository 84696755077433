import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IAddRequestPayload,
  ActionType,
  IAddRequestResponse,
} from "models/consultationRequest/addRequest";
import { httpStatus } from "configs/httpStatus";
import { handleAddRequest } from "services/consultationRequest";
import { handleSuccesAction, handleErrorAction } from "./addRequestActions";

function* addRequestSaga({ payload }: { payload: IAddRequestPayload }) {
  try {
    const response: IAddRequestResponse = yield call(handleAddRequest, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onAddRequestWatcher() {
  yield takeLatest(ActionType.ADD_REQUEST as any, addRequestSaga);
}

export default [fork(onAddRequestWatcher)];
