import { put, takeLatest, fork, call } from "redux-saga/effects";

import { authTokenKey } from "services/Api";
import { setCookie, localStorageHelper, getDefaultScreen } from "utils";
import { LoginData, ActionType, LoginResponse } from "models/authentication";
import { IGetSettingDataResponse } from "models/setting/settingData";
import { httpStatus } from "configs/httpStatus";
import { fetchLogin } from "services/auth";
import { fetchDataSetting } from "services/setting";

import { handleSuccesAction, handleErrorAction } from "./authenticationActions";
import { history } from "../configureStore";

function* loginSaga({ payload }: { payload: LoginData }) {
  try {
    const response: LoginResponse = yield call(fetchLogin, payload);

    if (response.status === httpStatus.StatusOK) {
      const token = response.result.auth_token;

      setCookie(authTokenKey, token);
      localStorageHelper.setItem(
        "company_name",
        response.result.name + "管理画面"
      );
      localStorageHelper.setItem("role", payload.user.role);
      localStorageHelper.setItem("id", response.result.id);

      if (payload.user.role !== 3) {
        const response2: IGetSettingDataResponse = yield call(fetchDataSetting);
        const { screen_displays, temperature_managerment_info } =
          response2.result;
        const screen = screen_displays.find(
          (sc) => sc.id === temperature_managerment_info.screen_display_id
        );
        history.push(getDefaultScreen(screen));
      } else {
        history.push("/messages");
      }

      yield put(handleSuccesAction(token));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onLoginSubmitWatcher() {
  yield takeLatest(ActionType.LOGIN_USER as any, loginSaga);
}

export default [fork(onLoginSubmitWatcher)];
