import { RootState } from "../../rootReducer";

export const selectIsDeletingMentalCheck = (state: RootState) =>
  state.deleteMentalCheck.loading;
export const selectIsDeleted = (state: RootState) =>
  state.deleteMentalCheck.deleted;
export const selectDeleteErrorMessage = (state: RootState) =>
  state.deleteMentalCheck.error;
export const deleteMentalCheckDataResponse = (state: RootState) =>
  state.deleteMentalCheck.deleteMentalCheckResponse;
