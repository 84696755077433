import * as React from "react";

const SvgComponent = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    {...props}
    width={40}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x={1.5}
      y={1.5}
      width={37}
      height={27}
      rx={6.5}
      fill="#fff"
      stroke={props.color}
      strokeWidth={3}
    />
    <path
      d="M15 8h17M15 15h17M15 22h17M8 8h2M8 15h2M8 22h2"
      stroke={props.color}
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgComponent;
