import {
  ActionType,
  AttendanceStatusPayload,
  AttendanceStatusResponse,
} from "models/dashboard/attendanceStatus";

export const getAttendanceStatusAction = (payload: AttendanceStatusPayload) => {
  return {
    type: ActionType.GET_ATTENDANCE_STATUS_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_ATTENDANCE_STATUS_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: AttendanceStatusResponse) => {
  return {
    type: ActionType.GET_ATTENDANCE_STATUS_CHART_SUCCESS,
    payload,
  };
};
