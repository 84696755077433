import {
  Action,
  ActionType,
  IMentalCheckListResponse,
} from "models/mentalHealth/checkList";
import createReducer from "redux/createReducer";

export interface MentalCheckListReducerType {
  loading: boolean;
  error?: string;
  mentalCheckListResponse?: IMentalCheckListResponse;
}

const defaultState: MentalCheckListReducerType = {
  loading: false,
  error: undefined,
  mentalCheckListResponse: undefined,
};

export const mentalCheckListReducer = createReducer<MentalCheckListReducerType>(
  defaultState,
  {
    [ActionType.MENTAL_CHECK_LIST](state: MentalCheckListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MENTAL_CHECK_LIST_ERROR](
      state: MentalCheckListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        mentalCheckListResponse: undefined,
      };
    },

    [ActionType.MENTAL_CHECK_LIST_SUCCESS](
      state: MentalCheckListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        mentalCheckListResponse: action.payload,
      };
    },

    [ActionType.MENTAL_CHECK_LIST_RESET_DATA](
      state: MentalCheckListReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
  }
);
