import { ActionType, IDeleteVenuesResponse } from "models/vaccine/deleteVenues";

export const deleteVenuesAction = (payload: { id: number }) => {
  return {
    type: ActionType.VACCINE_DELETE_VENUES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.VACCINE_DELETE_VENUES_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IDeleteVenuesResponse) => {
  return {
    type: ActionType.VACCINE_DELETE_VENUES_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.VACCINE_DELETE_VENUES_RESET,
  };
};
