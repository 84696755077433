import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  // get student list
  GET_STUDENT_LIST = "action/GET_STUDENT_LIST",
  GET_STUDENT_LIST_SUCCESS = "action/GET_STUDENT_LIST_SUCCESS",
  GET_STUDENT_LIST_ERROR = "action/GET_STUDENT_LIST_ERROR",
  RESET_STUDENT_LIST = "action/RESET_STUDENT_LIST",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface FilterStudentData {
  department_filter?: any;
  group_filter?: string;
  full_name_filter?: string;
  temperature_filter?: any;
  symptons_filter?: string;
  presence_status_filter?: string;
  am_pm_filter?: string;
  family_illness?: string;
  pool_filter?: string;
  page: number;
  per_page: number;
  marathon_filter?: string;
  date: Date | any;
}

export interface GetStudentListResponse extends IResponse {
  result: GetStudentListResult;
  total_record: number;
  next_page: number;
  current_page: number;
}

export interface GetStudentListResult {
  user_with_temperature: GetStudentList[];
  user_detail: IOption[];
}

export interface GetStudentList {
  key: number;
  full_name: string;
  roll_number?: string;
  class_name?: string;
  activities?: string;
  temperature?: string;
  symptoms?: string;
  pool_answer?: string;
  attendance?: string;
  attendance_comment?: string;
  time?: string;
  family_illness?: string;
  marathon?: string;
}
