import {
  Action,
  ActionType,
  PrefectureResponse,
} from "models/dashboard/prefecture";
import createReducer from "../../createReducer";

export interface PrefectureReducerType {
  loading: boolean;
  error?: string;
  prefectureResponse?: PrefectureResponse;
}

const defaultState: PrefectureReducerType = {
  loading: false,
  error: undefined,
  prefectureResponse: undefined,
};

export const getPrefectureReducer = createReducer<PrefectureReducerType>(
  defaultState,
  {
    [ActionType.GET_PREFECTURE](state: PrefectureReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_PREFECTURE_ERROR](
      state: PrefectureReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_PREFECTURE_SUCCESS](
      state: PrefectureReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        prefectureResponse: action.payload,
      };
    },
  }
);
