import {
  Action,
  ActionType,
  ProgressResponse,
} from "models/dashboard/progress";
import createReducer from "../../createReducer";

export interface ProgressReducerType {
  loading: boolean;
  error?: string;
  progressResponse?: ProgressResponse;
}

const defaultState: ProgressReducerType = {
  loading: false,
  error: undefined,
  progressResponse: undefined,
};

export const getProgressReducer = createReducer<ProgressReducerType>(
  defaultState,
  {
    [ActionType.GET_PROGRESS_CHART](state: ProgressReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_PROGRESS_CHART_ERROR](
      state: ProgressReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_PROGRESS_CHART_SUCCESS](
      state: ProgressReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        progressResponse: action.payload,
      };
    },
  }
);
