import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  BodyTemperatureSettingFormResponse,
} from "models/bodyTemperature/settingForm";
import { httpStatus } from "configs/httpStatus";
import { fetchSettingFormData } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./settingFormActions";

function* getTemperatureBodySettingFormDataSaga({
  payload,
}: {
  payload: { locale: string };
}) {
  try {
    const response: BodyTemperatureSettingFormResponse = yield call(
      fetchSettingFormData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetBodyTemperatureSettingFormWatcher() {
  yield takeLatest(
    ActionType.BODY_TEMPERATURE_SETTING_FORM_DATA as any,
    getTemperatureBodySettingFormDataSaga
  );
}

export default [fork(onGetBodyTemperatureSettingFormWatcher)];
