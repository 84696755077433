import * as React from "react";

function ResetIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0375 11.0247L16 5.9995L13.7536 6.19157C13.7536 6.17236 13.7519 6.15316 13.7502 6.13395C13.3233 2.63657 10.3781 0 6.90091 0C3.095 0 0 3.13944 0 7C0 10.8606 3.095 14 6.90091 14C8.02324 14 9.13868 13.7206 10.125 13.1898C10.6707 12.8965 10.879 12.2103 10.5915 11.655C10.3023 11.1015 9.62582 10.8902 9.07843 11.1818C8.40366 11.5433 7.67208 11.7284 6.90091 11.7284C4.32921 11.7284 2.23776 9.60688 2.23776 6.99825C2.23776 4.38962 4.32921 2.26989 6.90091 2.26989C9.24196 2.26989 11.2232 4.03517 11.5228 6.38189L9.29016 6.57221L13.0358 11.0264L13.0375 11.0247Z"
        fill="white"
      />
    </svg>
  );
}

export default ResetIcon;
