import * as React from "react";

function TemperatureYellowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.08474 2.25208H0V2.80475H1.08474V2.25208Z" fill="#3B3B3B" />
      <path d="M1.08474 4.84399H0V5.39667H1.08474V4.84399Z" fill="#3B3B3B" />
      <path d="M1.08474 7.43335H0V7.98603H1.08474V7.43335Z" fill="#3B3B3B" />
      <path
        d="M4.06774 14.4801C5.41568 14.4801 6.50841 13.3666 6.50841 11.993C6.50841 10.6195 5.41568 9.50598 4.06774 9.50598C2.7198 9.50598 1.62708 10.6195 1.62708 11.993C1.62708 13.3666 2.7198 14.4801 4.06774 14.4801Z"
        fill="#EBC300"
      />
      <path
        d="M4.7457 4.84399H3.38977V9.85401H4.7457V4.84399Z"
        fill="#EBC300"
      />
      <path
        d="M4.06781 16C3.02515 15.9993 2.0254 15.5769 1.28813 14.8256C0.550857 14.0743 0.136344 13.0556 0.135625 11.9931C0.134609 11.3599 0.281671 10.7356 0.564632 10.1719C0.847592 9.60823 1.25831 9.12135 1.76273 8.75164V2.34888C1.76345 1.72614 2.00654 1.12912 2.43867 0.68878C2.8708 0.248439 3.45668 0.000731341 4.06781 0C4.67871 0.00145959 5.26418 0.249397 5.69616 0.689581C6.12813 1.12977 6.37144 1.72637 6.37288 2.34888V8.75164C6.87698 9.12167 7.28745 9.60862 7.57038 10.1722C7.8533 10.7359 8.00056 11.36 7.99999 11.9931C7.99855 13.0553 7.58381 14.0737 6.84669 14.8248C6.10957 15.5759 5.11024 15.9985 4.06781 16ZM4.06781 0.829016C3.67223 0.829016 3.29285 0.989144 3.01314 1.27417C2.73343 1.5592 2.57629 1.94579 2.57629 2.34888V9.19655L2.38917 9.31814C1.94751 9.60437 1.58395 9.99963 1.33221 10.4673C1.08048 10.9349 0.948724 11.4597 0.94918 11.9931C0.949898 12.8357 1.27869 13.6436 1.86339 14.2394C2.44809 14.8352 3.24092 15.1703 4.06781 15.171C4.8947 15.1703 5.68752 14.8352 6.27222 14.2394C6.85692 13.6436 7.18571 12.8357 7.18643 11.9931C7.18639 11.4598 7.05442 10.9352 6.80273 10.4676C6.55104 10.0001 6.18775 9.60474 5.74644 9.31814L5.55932 9.19655V2.34888C5.56004 2.14869 5.522 1.95033 5.4474 1.76514C5.3728 1.57995 5.26309 1.41156 5.12456 1.26962C4.98602 1.12769 4.82137 1.01497 4.64004 0.937947C4.45871 0.86092 4.26426 0.821088 4.06781 0.820726V0.829016Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}

export default TemperatureYellowIcon;
