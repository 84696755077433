import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  DownloadQrCodeData,
  ActionType,
  DownloadQrCodeResponse,
} from "../../models/downloadQrCode";
import { httpStatus } from "configs/httpStatus";
import { downloadQrCode } from "services/qrcode";
import { handleSuccessAction, handleErrorAction } from "./qrcodeActions";

// forgot password
function* downloadQrCodeSaga({ payload }: { payload: DownloadQrCodeData }) {
  try {
    const response: DownloadQrCodeResponse = yield call(
      downloadQrCode,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDownloadQrcodeSubmitWatcher() {
  yield takeLatest(ActionType.DOWNLOAD_QR_CODE as any, downloadQrCodeSaga);
}

export default [fork(onDownloadQrcodeSubmitWatcher)];
