import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  MentalCheckDetailsData,
  ActionType,
  IMentalCheckDetailsResponse,
} from "models/mentalHealth/mentalCheckDetails";
import { httpStatus } from "configs/httpStatus";
import { getMentalCheckDetails } from "services/mentalHealth";
import { handleSuccesAction, handleErrorAction } from "./checkDetailsActions";

function* mentalCheckDetailsSaga({
  payload,
}: {
  payload: MentalCheckDetailsData;
}) {
  try {
    const response: IMentalCheckDetailsResponse = yield call(
      getMentalCheckDetails,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetMentalCheckDetailsWatcher() {
  yield takeLatest(
    ActionType.MENTAL_CHECK_DETAILS as any,
    mentalCheckDetailsSaga
  );
}

export default [fork(onGetMentalCheckDetailsWatcher)];
