import {
  ActionType,
  IClassTypeData,
  IClassTypeResponse,
} from "../../../models/classmanagement/classType";

export const classTypeAction = (payload: IClassTypeData) => {
  return {
    type: ActionType.GET_CLASS_TYPE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_CLASS_TYPE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IClassTypeResponse) => {
  return {
    type: ActionType.GET_CLASS_TYPE_SUCCESS,
    payload,
  };
};
