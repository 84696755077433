import {
  Action,
  ActionType,
  DataGroupClassResponse,
} from "models/bodyTemperature/groupClass";
import createReducer from "../../createReducer";

export interface DataGroupClassReducerType {
  loading: boolean;
  error?: string;
  dataGroupClassResponse?: DataGroupClassResponse;
}

const defaultState: DataGroupClassReducerType = {
  loading: false,
  error: undefined,
  dataGroupClassResponse: undefined,
};

export const dataGroupClassReducer = createReducer<DataGroupClassReducerType>(
  defaultState,
  {
    [ActionType.DATA_GROUP_CLASS](state: DataGroupClassReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DATA_GROUP_CLASS_ERROR](
      state: DataGroupClassReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        dataGroupClassResponse: undefined,
      };
    },

    [ActionType.DATA_GROUP_CLASS_SUCCESS](
      state: DataGroupClassReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        dataGroupClassResponse: action.payload,
      };
    },
  }
);
