import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/messagemanagement/sendMessages";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { postSendMessage } from "services/messagemanagement";
import { handleSuccesAction, handleErrorAction } from "./sendMessageActions";

function* sendMessageSaga({ payload }: { payload: any }) {
  try {
    const response: IResponse = yield call(postSendMessage, payload);
    if (
      response.status === httpStatus.StatusCreated ||
      response.status === httpStatus.StatusOK
    ) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSendMessageWatcher() {
  yield takeLatest(ActionType.SEND_MESSAGE as any, sendMessageSaga);
}

export default [fork(onSendMessageWatcher)];
