import {
  ActionType,
  DeleteAccountResponse,
} from "models/account/deleteAccount";

export const deleteAccountAction = (payload: any) => {
  return {
    type: ActionType.DELETE_ACCOUNT,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_ACCOUNT_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DeleteAccountResponse) => {
  return {
    type: ActionType.DELETE_ACCOUNT_SUCCESS,
    payload,
  };
};
