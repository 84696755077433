import React, { useEffect, useRef, useState } from "react";
import { Col } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon, DownloadIcon, InformationIcon, NoDataIcon } from "icons";
import { generateImg } from "utils";
import { Loading, Select } from "components";
import { IOption } from "components/select/Select";
import { PrefectureItem } from "models/dashboard/prefecture";
import { CityItem } from "models/dashboard/cities";
import { DeseaseOutbreakItem } from "models/dashboard/deseaseOutbreak";
import {
  deseaseOutbreakDataResponse,
  selectIsLoading,
} from "redux/dashboard/deseaseOutbreak/deseaseOutbreakStates";
import { prefectureDataResponse } from "redux/dashboard/prefecture/prefectureStates";
import { getCitiesDataResponse } from "redux/dashboard/cities/citiesStates";
import { settingDataResponse } from "redux/setting/settingData/settingDataStates";
import {
  DeseaseOutbreakActionCreators,
  GetPrefectureActionCreators,
  GetCitiesActionCreators,
} from "redux/rootActions";
import { IChart } from "../BodyTemperatureResult";

import {
  WrapperCloseIcon,
  BoxHeader,
  BoxTitle,
  LightDivider,
  BoxContent,
  WrapperLegendLineChart,
  WrapperChart,
  ContainerChart,
  DeseaseOutbreakChartContainer,
  CheckboxStyled2,
  Dot,
  CheckCountry,
  WrapperTooltipRight,
  ChartTooltipRightStyled,
  WrapperLoadingChart,
  WrapperNoData,
} from "../Dashboard.style";
import e from "express";

interface TooltipItem {
  dot: any;
  label: string;
}

interface LineChartProps {
  data: any;
  checkedList: boolean[];
}

const initialCheckedList: boolean[] = [];
for (let i = 0; i <= 32; i++) {
  initialCheckedList.push(true);
}

const chartItems = [
  {
    id: 6,
    label: "new-corona",
    color: "#2AC769",
  },
  {
    id: 0,
    label: "influenza",
    color: "#2A98C7",
  },
  {
    id: 1,
    label: "cold",
    color: "#C72AB7",
  },
  {
    id: 2,
    label: "norovirus",
    color: "#C72A46",
  },
  {
    id: 3,
    label: "rotavirus",
    color: "#C4C72A",
  },
  {
    id: 4,
    label: "adenovirus",
    color: "#2AC7BE",
  },
  {
    id: 5,
    label: "respiratory-syncytial-virus",
    color: "#8B2AC7",
  },
  {
    id: 7,
    label: "strep-throat",
    color: "#C77F2A",
  },
  {
    id: 8,
    label: "whooping-cough",
    color: "#9EC72A",
  },
  {
    id: 9,
    label: "measles",
    color: "#2A3AC7",
  },
  {
    id: 10,
    label: "otafuku",
    color: "#2AC769",
  },
  {
    id: 11,
    label: "rubella",
    color: "#2A98C7",
  },
  {
    id: 12,
    label: "chickenpox",
    color: "#C72AB7",
  },
  {
    id: 13,
    label: "pharyngoconjunctival-fever",
    color: "#C72A46",
  },
  {
    id: 14,
    label: "tuberculosis",
    color: "#C4C72A",
  },
  {
    id: 15,
    label: "meningitis",
    color: "#2AC7BE",
  },
  {
    id: 16,
    label: "cholera",
    color: "#8B2AC7",
  },
  {
    id: 17,
    label: "dysentery",
    color: "#C77F2A",
  },
  {
    id: 18,
    label: "ecoli-infection",
    color: "#9EC72A",
  },
  {
    id: 19,
    label: "typhus",
    color: "#2A3AC7",
  },
  {
    id: 20,
    label: "conjunctivitis",
    color: "#2AC769",
  },
  {
    id: 21,
    label: "hepatitis",
    color: "#2A98C7",
  },
  {
    id: 22,
    label: "hand-foot-mouth-disease",
    color: "#C72AB7",
  },
  {
    id: 23,
    label: "erythema",
    color: "#C72A46",
  },
  {
    id: 24,
    label: "herpangina",
    color: "#C4C72A",
  },
  {
    id: 25,
    label: "mycoplasma",
    color: "#2AC7BE",
  },
  {
    id: 26,
    label: "lice",
    color: "#8B2AC7",
  },
  {
    id: 27,
    label: "water-wart",
    color: "#C77F2A",
  },
  {
    id: 28,
    label: "tohibi",
    color: "#9EC72A",
  },
  {
    id: 29,
    label: "heatstroke",
    color: "#2A3AC7",
  },
  {
    id: 30,
    label: "summer-cold",
    color: "#2AC769",
  },
  {
    id: 31,
    label: "vomiting",
    color: "#2A98C7",
  },
  {
    id: 32,
    label: "diarrhea",
    color: "#C72AB7",
  },
];

const returnItem = (name: string) => {
  let item: TooltipItem = {
    dot: <></>,
    label: "",
  };
  switch (name) {
    case "7":
      item = {
        dot: <Dot style={{ background: "#2AC769" }} />,
        label: "new-corona",
      };
      break;
    case "1":
      item = {
        dot: <Dot style={{ background: "#2A98C7" }} />,
        label: "influenza",
      };
      break;
    case "2":
      item = {
        dot: <Dot style={{ background: "#C72AB7" }} />,
        label: "cold",
      };
      break;
    case "3":
      item = {
        dot: <Dot style={{ background: "#C72A46" }} />,
        label: "norovirus",
      };
      break;
    case "4":
      item = {
        dot: <Dot style={{ background: "#C4C72A" }} />,
        label: "rotavirus",
      };
      break;
    case "5":
      item = {
        dot: <Dot style={{ background: "#2AC7BE" }} />,
        label: "adenovirus",
      };
      break;
    case "6":
      item = {
        dot: <Dot style={{ background: "#8B2AC7" }} />,
        label: "respiratory-syncytial-virus",
      };
      break;
    case "8":
      item = {
        dot: <Dot style={{ background: "#C77F2A" }} />,
        label: "strep-throat",
      };
      break;
    case "9":
      item = {
        dot: <Dot style={{ background: "#9EC72A" }} />,
        label: "whooping-cough",
      };
      break;
    case "10":
      item = {
        dot: <Dot style={{ background: "#2A3AC7" }} />,
        label: "measles",
      };
      break;
    case "11":
      item = {
        dot: <Dot style={{ background: "#2AC769" }} />,
        label: "otafuku",
      };
      break;
    case "12":
      item = {
        dot: <Dot style={{ background: "#2A98C7" }} />,
        label: "rubella",
      };
      break;
    case "13":
      item = {
        dot: <Dot style={{ background: "#C72AB7" }} />,
        label: "chickenpox",
      };
      break;
    case "14":
      item = {
        dot: <Dot style={{ background: "#C72A46" }} />,
        label: "pharyngoconjunctival-fever",
      };
      break;
    case "15":
      item = {
        dot: <Dot style={{ background: "#C4C72A" }} />,
        label: "tuberculosis",
      };
      break;
    case "16":
      item = {
        dot: <Dot style={{ background: "#2AC7BE" }} />,
        label: "meningitis",
      };
      break;
    case "17":
      item = {
        dot: <Dot style={{ background: "#8B2AC7" }} />,
        label: "cholera",
      };
      break;
    case "18":
      item = {
        dot: <Dot style={{ background: "#C77F2A" }} />,
        label: "dysentery",
      };
      break;
    case "19":
      item = {
        dot: <Dot style={{ background: "#9EC72A" }} />,
        label: "ecoli-infection",
      };
      break;
    case "20":
      item = {
        dot: <Dot style={{ background: "#2A3AC7" }} />,
        label: "typhus",
      };
      break;
    case "21":
      item = {
        dot: <Dot style={{ background: "#2AC769" }} />,
        label: "conjunctivitis",
      };
      break;
    case "22":
      item = {
        dot: <Dot style={{ background: "#2A98C7" }} />,
        label: "hepatitis",
      };
      break;
    case "23":
      item = {
        dot: <Dot style={{ background: "#C72AB7" }} />,
        label: "hand-foot-mouth-disease",
      };
      break;
    case "24":
      item = {
        dot: <Dot style={{ background: "#C72A46" }} />,
        label: "erythema",
      };
      break;
    case "25":
      item = {
        dot: <Dot style={{ background: "#C4C72A" }} />,
        label: "herpangina",
      };
      break;
    case "26":
      item = {
        dot: <Dot style={{ background: "#2AC7BE" }} />,
        label: "mycoplasma",
      };
      break;
    case "27":
      item = {
        dot: <Dot style={{ background: "#8B2AC7" }} />,
        label: "lice",
      };
      break;
    case "28":
      item = {
        dot: <Dot style={{ background: "#C77F2A" }} />,
        label: "water-wart",
      };
      break;
    case "29":
      item = {
        dot: <Dot style={{ background: "#9EC72A" }} />,
        label: "tohibi",
      };
      break;
    case "30":
      item = {
        dot: <Dot style={{ background: "#2A3AC7" }} />,
        label: "heatstroke",
      };
      break;
    case "31":
      item = {
        dot: <Dot style={{ background: "#2AC769" }} />,
        label: "summer-cold",
      };
      break;
    case "32":
      item = {
        dot: <Dot style={{ background: "#2A98C7" }} />,
        label: "vomiting",
      };
      break;
    case "33":
      item = {
        dot: <Dot style={{ background: "#C72AB7" }} />,
        label: "diarrhea",
      };
      break;
  }

  return item;
};

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={0} dy={18} fill="#3B3B3B">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "dashboard",
  });
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={3} fill="#3B3B3B" textAnchor="end">
        {payload.value} {t("people")}
      </text>
    </g>
  );
};

const LineChartCustom = ({ data, checkedList }: LineChartProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "dashboard",
  });

  const renderTooltipContent = (props: any) => {
    const { payload, label } = props;

    return (
      <div
        className="customized-tooltip-content"
        style={{
          padding:
            payload && payload.length && payload.length < 8
              ? "5px 6px 6px"
              : "5px 0px 6px 6px",
        }}
      >
        <p className="label">{label}</p>
        {payload && payload.length && (
          <div className="list">
            {payload
              .sort((a: any, b: any) => b.value - a.value)
              .map((entry: any, index: any) => (
                <div key={`item-${index}`} className="tooltip-item-wrapper">
                  <div className="item-left">
                    {returnItem(entry.name)?.dot}
                    <span>{t1(returnItem(entry.name)?.label)}</span>
                  </div>
                  <div className="item-right">
                    <span>
                      {entry.value}
                      {t("people")}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  };
  return (
    <ContainerChart>
      <ResponsiveContainer width="100%" height={178}>
        <LineChart
          margin={{
            top: 10,
            right: 20,
            left: 0,
            bottom: 0,
          }}
          data={data}
        >
          <CartesianGrid vertical={false} strokeDasharray="2 2" />
          <XAxis
            dy={4}
            tickLine={false}
            dataKey="date"
            minTickGap={-100}
            tick={<CustomizedXAxisTick />}
            stroke="#CCCCCC"
          />
          <YAxis
            type="number"
            tickLine={false}
            interval={0}
            minTickGap={1}
            allowDecimals={false}
            width={55}
            tick={<CustomizedYAxisTick />}
            stroke="#CCCCCC"
          />
          <Line
            dataKey="7"
            hide={!checkedList[6]}
            stroke="#2AC769"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2AC769",
              strokeWidth: 1,
              r: 4,
              fill: "#2AC769",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="1"
            hide={!checkedList[0]}
            stroke="#2A98C7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2A98C7",
              strokeWidth: 1,
              r: 4,
              fill: "#2A98C7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="2"
            hide={!checkedList[1]}
            stroke="#C72AB7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C72AB7",
              strokeWidth: 1,
              r: 4,
              fill: "#C72AB7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="3"
            hide={!checkedList[2]}
            stroke="#C72A46"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C72A46",
              strokeWidth: 1,
              r: 4,
              fill: "#C72A46",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="4"
            hide={!checkedList[3]}
            stroke="#C4C72A"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C4C72A",
              strokeWidth: 1,
              r: 4,
              fill: "#C4C72A",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="5"
            hide={!checkedList[4]}
            stroke="#2AC7BE"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2AC7BE",
              strokeWidth: 1,
              r: 4,
              fill: "#2AC7BE",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="6"
            hide={!checkedList[5]}
            stroke="#8B2AC7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#8B2AC7",
              strokeWidth: 1,
              r: 4,
              fill: "#8B2AC7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="8"
            hide={!checkedList[7]}
            stroke="#C77F2A"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C77F2A",
              strokeWidth: 1,
              r: 4,
              fill: "#C77F2A",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="9"
            hide={!checkedList[8]}
            stroke="#9EC72A"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#9EC72A",
              strokeWidth: 1,
              r: 4,
              fill: "#9EC72A",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="10"
            hide={!checkedList[9]}
            stroke="#2A3AC7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2A3AC7",
              strokeWidth: 1,
              r: 4,
              fill: "#2A3AC7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="11"
            hide={!checkedList[10]}
            stroke="#2AC769"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2AC769",
              strokeWidth: 1,
              r: 4,
              fill: "#2AC769",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="12"
            hide={!checkedList[11]}
            stroke="#2A98C7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2A98C7",
              strokeWidth: 1,
              r: 4,
              fill: "#2A98C7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="13"
            hide={!checkedList[12]}
            stroke="#C72AB7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C72AB7",
              strokeWidth: 1,
              r: 4,
              fill: "#C72AB7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="14"
            hide={!checkedList[13]}
            stroke="#C72A46"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C72A46",
              strokeWidth: 1,
              r: 4,
              fill: "#C72A46",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="15"
            hide={!checkedList[14]}
            stroke="#C4C72A"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C4C72A",
              strokeWidth: 1,
              r: 4,
              fill: "#C4C72A",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="16"
            hide={!checkedList[15]}
            stroke="#2AC7BE"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2AC7BE",
              strokeWidth: 1,
              r: 4,
              fill: "#2AC7BE",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="17"
            hide={!checkedList[16]}
            stroke="#8B2AC7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#8B2AC7",
              strokeWidth: 1,
              r: 4,
              fill: "#8B2AC7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="18"
            hide={!checkedList[17]}
            stroke="#C77F2A"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C77F2A",
              strokeWidth: 1,
              r: 4,
              fill: "#C77F2A",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="19"
            hide={!checkedList[18]}
            stroke="#9EC72A"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#9EC72A",
              strokeWidth: 1,
              r: 4,
              fill: "#9EC72A",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="20"
            hide={!checkedList[19]}
            stroke="#2A3AC7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2A3AC7",
              strokeWidth: 1,
              r: 4,
              fill: "#2A3AC7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="21"
            hide={!checkedList[20]}
            stroke="#2AC769"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2AC769",
              strokeWidth: 1,
              r: 4,
              fill: "#2AC769",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="22"
            hide={!checkedList[21]}
            stroke="#2A98C7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2A98C7",
              strokeWidth: 1,
              r: 4,
              fill: "#2A98C7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="23"
            hide={!checkedList[22]}
            stroke="#C72AB7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C72AB7",
              strokeWidth: 1,
              r: 4,
              fill: "#C72AB7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="24"
            hide={!checkedList[23]}
            stroke="#C72A46"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C72A46",
              strokeWidth: 1,
              r: 4,
              fill: "#C72A46",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="25"
            hide={!checkedList[24]}
            stroke="#C4C72A"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C4C72A",
              strokeWidth: 1,
              r: 4,
              fill: "#C4C72A",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="26"
            hide={!checkedList[25]}
            stroke="#2AC7BE"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2AC7BE",
              strokeWidth: 1,
              r: 4,
              fill: "#2AC7BE",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="27"
            hide={!checkedList[26]}
            stroke="#8B2AC7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#8B2AC7",
              strokeWidth: 1,
              r: 4,
              fill: "#8B2AC7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="28"
            hide={!checkedList[27]}
            stroke="#C77F2A"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C77F2A",
              strokeWidth: 1,
              r: 4,
              fill: "#C77F2A",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="29"
            hide={!checkedList[28]}
            stroke="#9EC72A"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#9EC72A",
              strokeWidth: 1,
              r: 4,
              fill: "#9EC72A",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="30"
            hide={!checkedList[29]}
            stroke="#2A3AC7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2A3AC7",
              strokeWidth: 1,
              r: 4,
              fill: "#2A3AC7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="31"
            hide={!checkedList[30]}
            stroke="#2AC769"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2AC769",
              strokeWidth: 1,
              r: 4,
              fill: "#2AC769",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="32"
            hide={!checkedList[31]}
            stroke="#2A98C7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#2A98C7",
              strokeWidth: 1,
              r: 4,
              fill: "#2A98C7",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="33"
            hide={!checkedList[32]}
            stroke="#C72AB7"
            strokeDasharray="3 3"
            isAnimationActive={true}
            dot={{
              stroke: "#C72AB7",
              strokeWidth: 1,
              r: 4,
              fill: "#C72AB7",
              strokeDasharray: "",
            }}
          />
          <Tooltip
            wrapperStyle={{ pointerEvents: "auto" }}
            content={renderTooltipContent}
          />
        </LineChart>
      </ResponsiveContainer>
    </ContainerChart>
  );
};

const DeseaseOutbreak: React.FC<IChart> = ({ handleCloseChart }) => {
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "dashboard",
  });

  const dispatch = useDispatch();
  const deseaseOutbreakData = useSelector(deseaseOutbreakDataResponse);
  const prefectureData = useSelector(prefectureDataResponse);
  const citiesData = useSelector(getCitiesDataResponse);
  const settingData = useSelector(settingDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const imgRef = useRef<any>();
  const [data, setData] = useState<any>([]);
  const [checkedList, setCheckedList] = useState<boolean[]>(initialCheckedList);
  const [prefectureOptions, setPrefectureOptions] = useState<IOption[]>([]);
  const [citiesOptions, setCitiesOptions] = useState<IOption[]>([]);
  const [selectedPrefecture, setSelectedPrefecture] = useState<any>(undefined);
  const [selectedCity, setSelectedCity] = useState<any>(undefined);
  const [checkCountry, setCheckCountry] = useState<boolean>(false);
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);

  const onChangeCheckbox = (index: any) => {
    const tempCheckedList = [...checkedList];
    tempCheckedList[index] = !tempCheckedList[index];
    setCheckedList(tempCheckedList);
  };

  const handleConvertDate = (date: string) => {
    return moment(new Date(date)).format("M/D");
  };

  useEffect(() => {
    if (
      settingData &&
      Object.keys(settingData.result).length &&
      settingData.result.city_info
    ) {
      const { city_info } = settingData.result;
      setSelectedCity(city_info.id);
      setSelectedPrefecture(city_info.prefecture_id);
    }
  }, [settingData]);

  useEffect(() => {
    if (checkCountry) {
      dispatch(
        DeseaseOutbreakActionCreators.getDeseaseOutbreakAction({
          date: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
          id: 1,
          category: "country",
        })
      );
    } else {
      if (selectedCity) {
        dispatch(
          DeseaseOutbreakActionCreators.getDeseaseOutbreakAction({
            date: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
            id: selectedCity,
            category: "city",
          })
        );
      }
      if (selectedPrefecture && !selectedCity) {
        dispatch(
          DeseaseOutbreakActionCreators.getDeseaseOutbreakAction({
            date: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
            id: selectedPrefecture,
            category: "prefecture",
          })
        );
      }
    }
  }, [checkCountry]);

  useEffect(() => {
    if (selectedPrefecture && !selectedCity && !checkCountry) {
      dispatch(
        DeseaseOutbreakActionCreators.getDeseaseOutbreakAction({
          date: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
          id: selectedPrefecture,
          category: "prefecture",
        })
      );
    }
  }, [selectedPrefecture]);

  useEffect(() => {
    if (selectedPrefecture && selectedCity && !checkCountry) {
      dispatch(
        DeseaseOutbreakActionCreators.getDeseaseOutbreakAction({
          date: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
          id: selectedCity,
          category: "city",
        })
      );
    }
  }, [selectedCity]);

  useEffect(() => {
    if (deseaseOutbreakData && Object.keys(deseaseOutbreakData).length) {
      const tempCheckList: boolean[] = [];
      setData(
        deseaseOutbreakData.result.map((item: DeseaseOutbreakItem) => {
          return {
            date: handleConvertDate(item.date),
            7: item[7],
            1: item[1],
            2: item[2],
            3: item[3],
            4: item[4],
            5: item[5],
            6: item[6],
            8: item[8],
            9: item[9],
            10: item[10],
            11: item[11],
            12: item[12],
            13: item[13],
            14: item[14],
            15: item[15],
            16: item[16],
            17: item[17],
            18: item[18],
            19: item[19],
            20: item[20],
            21: item[21],
            22: item[22],
            23: item[23],
            24: item[24],
            25: item[25],
            26: item[26],
            27: item[27],
            28: item[28],
            29: item[29],
            30: item[30],
            31: item[31],
            32: item[32],
            33: item[33],
          };
        })
      );
      for (let i = 0; i <= 32; i++) {
        if (
          deseaseOutbreakData.result.every((item: any) => item[i + 1] === 0)
        ) {
          tempCheckList.push(false);
        } else {
          tempCheckList.push(true);
        }
      }

      setCheckedList(tempCheckList);
    }
  }, [deseaseOutbreakData]);

  useEffect(() => {
    if (selectedPrefecture) {
      dispatch(
        GetCitiesActionCreators.getCitiesAction({
          prefecture_id: selectedPrefecture,
        })
      );
    }
  }, [selectedPrefecture]);

  useEffect(() => {
    dispatch(
      GetPrefectureActionCreators.getPrefectureAction({
        country_id: 1,
      })
    );
  }, []);

  useEffect(() => {
    if (prefectureData) {
      const tempPrefectures: IOption[] = [];
      prefectureData?.result?.map((item: PrefectureItem) =>
        tempPrefectures.push({
          id: item.id,
          name: item.local_name,
          value: item.id,
        })
      );
      setPrefectureOptions(tempPrefectures);
    }
  }, [prefectureData]);

  useEffect(() => {
    if (citiesData) {
      const tempCities: IOption[] = [];
      citiesData?.result?.map((item: CityItem) =>
        tempCities.push({
          id: item.id,
          name: item.local_name,
          value: item.id,
        })
      );
      setCitiesOptions(tempCities);
    }
  }, [citiesData]);

  const InfoIcon = () => (
    <div style={{ position: "relative", height: 24, top: -1, left: -4 }}>
      <InformationIcon
        onMouseOver={() => setIsShowTooltip(true)}
        onMouseOut={() => setIsShowTooltip(false)}
        width={30}
        height={30}
      />
      {isShowTooltip && (
        <WrapperTooltipRight style={{ top: -20, right: -4 }}>
          <ChartTooltipRightStyled>{t1("graph-info")}</ChartTooltipRightStyled>
        </WrapperTooltipRight>
      )}
    </div>
  );

  return (
    <DeseaseOutbreakChartContainer ref={imgRef}>
      <WrapperCloseIcon>
        <CloseIcon
          fill="#2F8CAE"
          height="14px"
          width="14px"
          onClick={() => handleCloseChart("0")}
        />
      </WrapperCloseIcon>
      <BoxHeader style={{ alignItems: "flex-end" }}>
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <BoxTitle>{t1("desease-outbreak")}</BoxTitle>
          <InfoIcon />
        </div>
        <div style={{ display: "flex", gap: 8, alignItems: "flex-end" }}>
          <CheckCountry>
            <CheckboxStyled2
              checked={checkCountry}
              onChange={() => setCheckCountry(!checkCountry)}
            />
            <div>全国</div>
          </CheckCountry>
          <Select
            width="100px"
            value={selectedPrefecture}
            options={prefectureOptions}
            onChange={(e: any) => {
              setSelectedCity(undefined);
              setSelectedPrefecture(e);
            }}
            placeholder="選択してください。"
            style={{
              background: "#FFFFFF",
            }}
            disabled={checkCountry}
          />
          <Select
            width="137px"
            value={selectedCity}
            options={citiesOptions}
            onChange={(e: any) => setSelectedCity(e)}
            placeholder="選択してください。"
            style={{
              marginRight: 4,
              background: "#FFFFFF",
            }}
            disabled={checkCountry}
          />
          <DownloadIcon
            fill="#3B3B3B"
            style={{ marginTop: 4, cursor: "pointer" }}
            onClick={(event: any) => {
              if (!event.detail || event.detail == 1) {
                generateImg("deseaseOutbreak", imgRef.current);
              }
            }}
          />
        </div>
      </BoxHeader>
      <LightDivider />

      {isLoading ? (
        <WrapperLoadingChart>
          <div className="container">
            <Loading width={70} height={70} />
          </div>
        </WrapperLoadingChart>
      ) : data.length > 0 ? (
        <BoxContent>
          <WrapperChart>
            <ContainerChart>
              <div className="icon-descriptions">
                {chartItems && (
                  <div className="icon-descriptions-outbreak">
                    {chartItems.map((item: any) => (
                      <div
                        className="description"
                        onClick={() => onChangeCheckbox(item.id)}
                        key={item.id}
                      >
                        <CheckboxStyled2 checked={checkedList[item.id]} />
                        <WrapperLegendLineChart>
                          <Dot
                            style={{
                              background: item.color,
                              marginTop: 0,
                              marginLeft: 1,
                            }}
                          />
                          <span
                            style={{
                              width: 6,
                              fontSize: 12,
                              lineHeight: "12px",
                              margin: "-14px 2px 0",
                              color: item.color,
                            }}
                          >
                            ..
                          </span>
                          <p
                            style={{
                              marginLeft: 0,
                              fontSize: 12,
                              lineHeight: "12px",
                            }}
                          >
                            {t1(item.label)}
                          </p>
                        </WrapperLegendLineChart>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </ContainerChart>
            <LineChartCustom data={data} checkedList={checkedList} />
          </WrapperChart>
        </BoxContent>
      ) : (
        <WrapperNoData>
          <NoDataIcon width={140} height={140} />
        </WrapperNoData>
      )}
    </DeseaseOutbreakChartContainer>
  );
};

export default DeseaseOutbreak;
