import {
  Action,
  ActionType,
  IQuestionHistoryResponse,
} from "models/questionnaires/sendQuestionHistory";
import createReducer from "../../createReducer";

export interface QuestionHistoryReducerType {
  loading: boolean;
  error?: string;
  questionHistoryResponse?: IQuestionHistoryResponse;
}

const defaultState: QuestionHistoryReducerType = {
  loading: false,
  error: undefined,
  questionHistoryResponse: undefined,
};

export const questionHistoryReducer = createReducer<QuestionHistoryReducerType>(
  defaultState,
  {
    [ActionType.SEND_QUESTION_HISTORY_LIST](state: QuestionHistoryReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.SEND_QUESTION_HISTORY_LIST_ERROR](
      state: QuestionHistoryReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.SEND_QUESTION_HISTORY_LIST_SUCCESS](
      state: QuestionHistoryReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        questionHistoryResponse: action.payload,
      };
    },

    [ActionType.RESET_SEND_QUESTION_HISTORY_LIST](
      state: QuestionHistoryReducerType
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        questionHistoryResponse: undefined,
      };
    },
  }
);
