import * as React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 17h17v3H4l-4-3Z" fill="#084D24" />
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.757 0H3a1 1 0 0 0-1 1v19a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V5.243L13.757 0Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.757 0H3a1 1 0 0 0-1 1v19a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V5.243L13.757 0Z"
      fill="#F2F2F2"
    />
    <path
      d="m13.757 0 .707-.707L14.172-1h-.415v1ZM19 5.243h1v-.415l-.293-.292-.707.707ZM3 1h10.757v-2H3v2Zm0 0v-2a2 2 0 0 0-2 2h2Zm0 19V1H1v19h2Zm0 0H1a2 2 0 0 0 2 2v-2Zm15 0H3v2h15v-2Zm0 0v2a2 2 0 0 0 2-2h-2Zm0-14.757V20h2V5.243h-2Zm1.707-.707L14.464-.707 13.05.707l5.243 5.243 1.414-1.414Z"
      fill="#0B6731"
      mask="url(#a)"
    />
    <path
      d="M8.534 6.856 9.76 4.5h.318L8.464 7.247l-.15.256.152.255 1.65 2.742H9.73L8.455 8.02l-.262-.51.34-.654Zm-.97 1.165L6.297 10.5H5.89l1.676-2.754.151-.25-.142-.254L6.04 4.5h.44l1.162 2.347.258.52-.335.654Z"
      stroke="#0B6731"
    />
    <path fill="#0B6731" d="M0 12h17v5H0z" />
    <path
      d="M13.5 1 18 5.5h-3c-1 0-1.5-.5-1.5-1.5V1ZM5.162 15.543V16H3.648v-.457h1.514ZM3.84 13.156V16h-.586v-2.844h.586Zm1.125 1.159v.445H3.648v-.445h1.317Zm.195-1.159v.46H3.648v-.46H5.16Zm1.026 0 .535.992.536-.992h.67l-.827 1.41L7.948 16h-.676l-.55-1.012L6.17 16h-.68l.85-1.434-.829-1.41h.674Zm3.855 1.9h.584a1.058 1.058 0 0 1-.159.51.943.943 0 0 1-.388.348 1.316 1.316 0 0 1-.598.125c-.182 0-.346-.031-.49-.094a1.043 1.043 0 0 1-.371-.273 1.218 1.218 0 0 1-.233-.43 1.859 1.859 0 0 1-.08-.564v-.198c0-.208.028-.396.082-.564.056-.17.136-.313.239-.432.104-.118.228-.21.373-.273.144-.064.306-.096.484-.096.237 0 .437.043.6.13a.94.94 0 0 1 .38.355c.092.15.146.322.165.515h-.586a.754.754 0 0 0-.069-.29.388.388 0 0 0-.178-.182.665.665 0 0 0-.312-.063.565.565 0 0 0-.256.055.475.475 0 0 0-.183.166.82.82 0 0 0-.112.281 1.947 1.947 0 0 0-.035.395v.2c0 .148.011.277.033.39.022.11.056.204.102.28a.472.472 0 0 0 .18.173.584.584 0 0 0 .267.056c.122 0 .223-.02.303-.058.08-.04.14-.097.181-.174a.68.68 0 0 0 .077-.287Zm3.077.487V16h-1.514v-.457h1.514Zm-1.323-2.387V16h-.586v-2.844h.586Zm1.125 1.159v.445h-1.316v-.445h1.316Zm.196-1.159v.46h-1.512v-.46h1.512Zm2.372 2.387V16h-1.432v-.457h1.432Zm-1.243-2.387V16h-.586v-2.844h.586Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
