import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  ProgressBarResponse,
} from "models/bodyTemperature/progressBar";
import { httpStatus } from "configs/httpStatus";
import { fetchProgressBarData } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./progressBarActions";

function* getDataProgressBarSaga({ payload }: { payload: { date: string } }) {
  try {
    const response: ProgressBarResponse = yield call(
      fetchProgressBarData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDataProgressBarWatcher() {
  yield takeLatest(ActionType.PROGRESS_BAR as any, getDataProgressBarSaga);
}

export default [fork(onGetDataProgressBarWatcher)];
