import {
  ActionType,
  IClassListData,
} from "models/classchangemanagement/getClassList";
import { IResponse } from "models";

export const getClassListAction = (payload: IClassListData) => {
  return {
    type: ActionType.GET_CLASS_LIST_END,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_CLASS_LIST_END_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.GET_CLASS_LIST_END_SUCCESS,
    payload,
  };
};
