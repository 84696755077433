import {
  Action,
  ActionType,
  IRecordVaccineListResponse,
} from "models/vaccine/records";
import createReducer from "../../createReducer";

export interface RecordListReducerType {
  loading: boolean;
  error?: string;
  recordListResponse?: IRecordVaccineListResponse;
}

const defaultState: RecordListReducerType = {
  loading: false,
  error: undefined,
  recordListResponse: undefined,
};

export const recordListReducer = createReducer<RecordListReducerType>(
  defaultState,
  {
    [ActionType.VACCINE_RECORD_LIST](state: RecordListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.VACCINE_RECORD_ERROR](
      state: RecordListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        recordListResponse: [],
      };
    },

    [ActionType.VACCINE_RECORD_SUCCESS](
      state: RecordListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        recordListResponse: action.payload,
      };
    },
  }
);
