import * as React from "react";

function DropdownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 5L2.3843e-07 2.22545e-07L8 -4.76837e-07L4 5Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default DropdownIcon;
