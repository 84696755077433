import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  UserAttendanceData,
  UserAttendanceResponse,
} from "models/userAttendance";
import { httpStatus } from "configs/httpStatus";
import { attendanceUser } from "services/userList";
import { handleSuccesAction, handleErrorAction } from "./userAttendanceActions";

function* userAttendanceSaga({ payload }: { payload: UserAttendanceData }) {
  try {
    const response: UserAttendanceResponse = yield call(
      attendanceUser,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUserAttendanceWatcher() {
  yield takeLatest(ActionType.USER_ATTENDANCE as any, userAttendanceSaga);
}

export default [fork(onUserAttendanceWatcher)];
