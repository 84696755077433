import {
  ActionType,
  IApproveAbsencePayload,
  IApproveAbsenceResponse,
} from "models/bodyTemperature/approveAbsence";

export const approveAbsenceAction = (payload: IApproveAbsencePayload) => {
  return {
    type: ActionType.APPROVE_ABSENCE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.APPROVE_ABSENCE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IApproveAbsenceResponse) => {
  return {
    type: ActionType.APPROVE_ABSENCE_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.APPROVE_ABSENCE_RESET,
  };
};
