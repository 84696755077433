import { put, takeLatest, fork, call } from "redux-saga/effects";
import { UpdateUserData, ActionType } from "models/updateuser";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { updateUser } from "services/userList";
import { handleSuccesAction, handleErrorAction } from "./updateuserActions";

function* updateUserSaga({ payload }: { payload: UpdateUserData }) {
  try {
    const response: IResponse = yield call(updateUser, payload);
    if (
      response.status === httpStatus.StatusOK ||
      response.status === httpStatus.StatusUnprocessableEntity
    ) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateUserListWatcher() {
  yield takeLatest(ActionType.UPDATE_USER as any, updateUserSaga);
}

export default [fork(onUpdateUserListWatcher)];
