import {
  ActionType,
  DataGroupClassResponse,
} from "models/bodyTemperature/groupClass";

export const getDataGroupClassAction = (payload: { locale: string }) => {
  return {
    type: ActionType.DATA_GROUP_CLASS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DATA_GROUP_CLASS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DataGroupClassResponse) => {
  return {
    type: ActionType.DATA_GROUP_CLASS_SUCCESS,
    payload,
  };
};
