import { IResponse } from "models";
import {
  ActionType,
  IPutConfirmStudentData,
} from "../../../models/classchangemanagement/putConfirmStudentClassChange";

export const putConfirmStudentClassChangeAction = (
  payload: IPutConfirmStudentData
) => {
  return {
    type: ActionType.PUT_STUDENT_CLASS_CHANGE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.PUT_STUDENT_CLASS_CHANGE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.PUT_STUDENT_CLASS_CHANGE_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.PUT_STUDENT_CLASS_CHANGE_RESET,
  };
};
