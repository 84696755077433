import { IResponse } from "models";

export enum ActionType {
  // student list data for class change
  STUDENT_LIST_CLASS_CHANGE = "action/STUDENT_LIST_CLASS_CHANGE",
  STUDENT_LIST_CLASS_CHANGE_SUCCESS = "action/STUDENT_LIST_CLASS_CHANGE_SUCCESS",
  STUDENT_LIST_CLASS_CHANGE_ERROR = "action/STUDENT_LIST_CLASS_CHANGE_ERROR",
  STUDENT_LIST_CLASS_CHANGE_RESET = "action/STUDENT_LIST_CLASS_CHANGE_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IStudentListClassChangeData {
  class_id: number;
  page: number;
  per_page: number;
  locale?: string;
}

export interface IStudentListClassChangeResponse extends IResponse {
  result: IStudentListClassChangeItem[];
  total_record: number;
  next_page: number;
  current_page: number;
}

export interface IStudentListClassChangeItem {
  id: number;
  full_name: string;
  current_class: string;
  current_roll_number: number;
  new_class_id: number;
  new_roll_number: number;
  last_updated_at: string;
}
