import { ActionType, CreateUserData } from "models/createuserlist";
import { IResponse } from "models";

export const createUserAction = (payload: CreateUserData) => {
  return {
    type: ActionType.CREATE_USER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_USER_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.CREATE_USER_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.RESET_CREATE_USER,
  };
};
