import {
  ActionType,
  PrefectureResponse,
  GetPrefecturePayload,
} from "models/dashboard/prefecture";

export const getPrefectureAction = (payload: GetPrefecturePayload) => {
  return {
    type: ActionType.GET_PREFECTURE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_PREFECTURE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: PrefectureResponse) => {
  return {
    type: ActionType.GET_PREFECTURE_SUCCESS,
    payload,
  };
};
