import {
  ActionType,
  IUpdateActivityData,
  IUpdateActivityResponse,
} from "../../../models/activitymanagement/updateActivity";

export const updateActivityAction = (payload: IUpdateActivityData) => {
  return {
    type: ActionType.UPDATE_ACTIVITY,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_ACTIVITY_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IUpdateActivityResponse) => {
  return {
    type: ActionType.UPDATE_ACTIVITY_SUCCESS,
    payload,
  };
};
