import {
  ActionType,
  MentalCheckRequestsResponse,
  MentalCheckRequestsData,
} from "models/mentalHealth/mentalCheckRequests";

export const mentalCheckRequestsAction = (payload: MentalCheckRequestsData) => {
  return {
    type: ActionType.MENTAL_CHECK_REQUEST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MENTAL_CHECK_REQUEST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: MentalCheckRequestsResponse) => {
  return {
    type: ActionType.MENTAL_CHECK_REQUEST_SUCCESS,
    payload,
  };
};
