import * as React from "react";

function XIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73041 1.57124C9.08986 1.21179 9.08986 0.629023 8.73041 0.269581C8.37097 -0.0898605 7.78819 -0.0898605 7.42875 0.269581L4.49998 3.19832L1.57132 0.269685C1.21188 -0.0897568 0.629102 -0.0897568 0.269657 0.269685C-0.0897883 0.629127 -0.0897882 1.2119 0.269657 1.57134L3.19832 4.49997L0.269584 7.42868C-0.0898612 7.78812 -0.0898612 8.37089 0.269584 8.73033C0.629029 9.08978 1.2118 9.08978 1.57125 8.73033L4.49998 5.80163L7.42882 8.73044C7.78826 9.08988 8.37104 9.08988 8.73049 8.73044C9.08993 8.37099 9.08993 7.78822 8.73049 7.42878L5.80165 4.49997L8.73041 1.57124Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default XIcon;
