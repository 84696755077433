import axios, { AxiosRequestConfig } from "axios";
import { appconf } from "app/appconfig";

const pass = () => {
  return 0;
};

const Platform = {
  OS: "web",
};

const axios_req = axios.request;

let _patient_id = 0;

export function set_patientId(id: number) {
  _patient_id = id;
}

let debug_log: Array<any> = [];
let send_request = false;

function exec_debug_send() {
  send_request = false;
  const log_content = debug_log;
  debug_log = [];
  if (appconf.print_request) {
    console.log("send debug log:", log_content);
  }

  if (!appconf.url_ulog || !appconf.send_debug_info) return;

  const args: AxiosRequestConfig = {
    method: "post",
    url: `${appconf.url_ulog}/organization/${Platform.OS}/${appconf.mode_name}/${_patient_id}/neterr`,
    headers: {
      "Content-Type": "application/json",
    },
    data: log_content,
  };
  axios_req(args)
    .then((r) => {
      return;
    })
    .catch((r) => {
      if (appconf.print_request) {
        console.log("send debug log error:", r);
      }
    });
}

function debug_send() {
  if (!send_request) {
    send_request = true;
    setTimeout(exec_debug_send, 1000 * 5);
  }
}

export function debug_send_log(url: string, error: any) {
  try {
    delete error.request._performanceLogger;
  } catch (e) {
    pass();
  }
  debug_log.push({ url, error });
  debug_send();
}
