import {
  Action,
  ActionType,
  IGetHealthCheckSettingResponse,
} from "models/setting/getHealthCheckSetting";
import createReducer from "../../createReducer";

export interface GetHealthCheckSettingReducerType {
  loading: boolean;
  error?: string;
  getHealthCheckSettingResponse?: IGetHealthCheckSettingResponse;
}

const defaultState: GetHealthCheckSettingReducerType = {
  loading: false,
  error: undefined,
  getHealthCheckSettingResponse: undefined,
};

export const getHealthCheckSettingReducer =
  createReducer<GetHealthCheckSettingReducerType>(defaultState, {
    [ActionType.GET_HEALTH_CHECK_SETTING](
      state: GetHealthCheckSettingReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_HEALTH_CHECK_SETTING_ERROR](
      state: GetHealthCheckSettingReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_HEALTH_CHECK_SETTING_SUCCESS](
      state: GetHealthCheckSettingReducerType,
      action: Action<IGetHealthCheckSettingResponse>
    ) {
      return {
        ...state,
        loading: false,
        getHealthCheckSettingResponse: action.payload,
      };
    },
  });
