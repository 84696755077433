import { Action, ActionType } from "models/classmanagement/createBulkClasses";
import { IResponse } from "models";
import createReducer from "../../createReducer";

export interface CreateBulkClassReducerType {
  loading: boolean;
  error?: string;
  createBulkClassResponse?: IResponse;
}

const defaultState: CreateBulkClassReducerType = {
  loading: false,
  error: undefined,
  createBulkClassResponse: undefined,
};

export const createBulkClassReducer = createReducer<CreateBulkClassReducerType>(
  defaultState,
  {
    [ActionType.CREATE_BULK_CLASSES](state: CreateBulkClassReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_BULK_CLASSES_ERROR](
      state: CreateBulkClassReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CREATE_BULK_CLASSES_SUCCESS](
      state: CreateBulkClassReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createBulkClassResponse: action.payload,
      };
    },
  }
);
