import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/messagemanagement/pushNotification";
import { httpStatus } from "configs/httpStatus";
import { pushNotification } from "services/messagemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./pushNotificationActions";
import {
  IPushNotificationResponse,
  IPushNotificationPayload,
} from "models/messagemanagement/pushNotification";

function* pushNotificationSaga({
  payload,
}: {
  payload: IPushNotificationPayload;
}) {
  try {
    const response: IPushNotificationResponse = yield call(
      pushNotification,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({
          ...response,
          result: { content: "", user_information: [], message_documents: [] },
        })
      );
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onPushNotificationWatcher() {
  yield takeLatest(ActionType.PUSH_NOTIFICATION as any, pushNotificationSaga);
}

export default [fork(onPushNotificationWatcher)];
