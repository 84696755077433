import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  // get class list filter
  GET_CLASS_LIST_FILTER = "action/GET_CLASS_LIST_FILTER",
  GET_CLASS_LIST_FILTER_SUCCESS = "action/GET_CLASS_LIST_FILTER_SUCCESS",
  GET_CLASS_LIST_FILTER_ERROR = "action/GET_CLASS_LIST_FILTER_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGetClassFilterResponse extends IResponse {
  result: IOption[];
}
