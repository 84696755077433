import * as React from "react";

function BarChartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0628 8.14648H12.9378C13.192 8.14648 13.4357 8.24745 13.6155 8.42717C13.7952 8.6069 13.8962 8.85065 13.8962 9.10482V21.5632H9.10449V9.10482C9.10449 8.85065 9.20546 8.6069 9.38518 8.42717C9.5649 8.24745 9.80866 8.14648 10.0628 8.14648V8.14648Z"
        fill="white"
        stroke={props.fill}
        strokeMiterlimit="10"
      />
      <path
        d="M3.35433 15.8125H6.22933C6.4835 15.8125 6.72725 15.9135 6.90697 16.0932C7.0867 16.2729 7.18766 16.5167 7.18766 16.7708V21.5625H2.396V16.7708C2.396 16.5167 2.49696 16.2729 2.67669 16.0932C2.85641 15.9135 3.10016 15.8125 3.35433 15.8125V15.8125Z"
        fill="white"
        stroke={props.fill}
        strokeMiterlimit="10"
      />
      <path
        d="M16.7708 2.39648H19.6458C19.9 2.39648 20.1438 2.49745 20.3235 2.67717C20.5032 2.8569 20.6042 3.10065 20.6042 3.35482V21.5632H15.8125V3.35482C15.8125 3.10065 15.9135 2.8569 16.0932 2.67717C16.2729 2.49745 16.5167 2.39648 16.7708 2.39648Z"
        fill="white"
        stroke={props.fill}
        strokeMiterlimit="10"
      />
      <path
        d="M0.958496 21.5625H22.0418"
        stroke={props.fill}
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default BarChartIcon;
