import { IResponse } from "models";

export enum ActionType {
  GET_DESEASE_OUTBREAK_CHART = "action/GET_DESEASE_OUTBREAK_CHART",
  GET_DESEASE_OUTBREAK_CHART_SUCCESS = "action/GET_DESEASE_OUTBREAK_CHART_SUCCESS",
  GET_DESEASE_OUTBREAK_CHART_ERROR = "action/GET_DESEASE_OUTBREAK_CHART_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DeseaseOutbreakPayload {
  date: string;
  id?: number;
  category?: string;
}

export interface DeseaseOutbreakItem {
  date: string;
  category: string;
  time_id: number;
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  7: number;
  8: number;
  9: number;
  10: number;
  11: number;
  12: number;
  13: number;
  14: number;
  15: number;
  16: number;
  17: number;
  18: number;
  19: number;
  20: number;
  21: number;
  22: number;
  23: number;
  24: number;
  25: number;
  26: number;
  27: number;
  28: number;
  29: number;
  30: number;
  31: number;
  32: number;
  33: number;
}

export interface DeseaseOutbreakResponse extends IResponse {
  // result: DeseaseOutbreakItem[];
  result: any;
}
