import { ForgotPasswordData, ActionType } from "../../models/forgotPassword";

export const forgotPasswordUserAction = (payload: ForgotPasswordData) => {
  return {
    type: ActionType.FORGOT_PASSWORD,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.FORGOT_PASSWORD_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: string) => {
  return {
    type: ActionType.FORGOT_PASSWORD_SUCCESS,
    payload,
  };
};
