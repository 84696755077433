import {
  Action,
  ActionType,
  DataDestinationResponse,
} from "models/bodyTemperature/destinations";
import createReducer from "../../createReducer";

export interface DataDestinationReducerType {
  loading: boolean;
  error?: string;
  dataDestinationResponse?: DataDestinationResponse;
}

const defaultState: DataDestinationReducerType = {
  loading: false,
  error: undefined,
  dataDestinationResponse: undefined,
};

export const dataDestinationReducer = createReducer<DataDestinationReducerType>(
  defaultState,
  {
    [ActionType.DATA_DESTINATION](state: DataDestinationReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DATA_DESTINATION_ERROR](
      state: DataDestinationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        dataDestinationResponse: undefined,
      };
    },

    [ActionType.DATA_DESTINATION_SUCCESS](
      state: DataDestinationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        dataDestinationResponse: action.payload,
      };
    },
  }
);
