import {
  Action,
  ActionType,
  DataFeverPercentageResponse,
} from "models/bodyTemperature/feverPercentage";
import createReducer from "../../createReducer";

export interface DataFeverPercentageReducerType {
  loading: boolean;
  error?: string;
  dataFeverPercentageResponse?: DataFeverPercentageResponse;
}

const defaultState: DataFeverPercentageReducerType = {
  loading: false,
  error: undefined,
  dataFeverPercentageResponse: undefined,
};

export const dataFeverPercentageReducer =
  createReducer<DataFeverPercentageReducerType>(defaultState, {
    [ActionType.DATA_FEVER_PERCENTAGE](state: DataFeverPercentageReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DATA_FEVER_PERCENTAGE_ERROR](
      state: DataFeverPercentageReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DATA_FEVER_PERCENTAGE_SUCCESS](
      state: DataFeverPercentageReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        dataFeverPercentageResponse: action.payload,
      };
    },
  });
