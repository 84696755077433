import { IResponse } from "models";

export enum ActionType {
  GET_CITIES = "action/GET_CITIES",
  GET_CITIES_SUCCESS = "action/GET_CITIES_SUCCESS",
  GET_CITIES_ERROR = "action/GET_CITIES_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface GetCitiesPayload {
  prefecture_id: number;
}

export interface CityItem {
  id: number;
  prefecture_id: number;
  local_name: string;
  global_name: string;
  created_at: string;
  updated_at: string;
}

export interface GetCitiesResponse extends IResponse {
  result: CityItem[];
}
