import { RootState } from "../../rootReducer";

export const selectIsSendingQuestionnaire = (state: RootState) =>
  state.sendQuestionnaire.loading;
export const selectIsSaved = (state: RootState) =>
  state.sendQuestionnaire.saved;
export const selectSaveErrorMessage = (state: RootState) =>
  state.sendQuestionnaire.error;
export const SaveQuestionnaireDataResponse = (state: RootState) =>
  state.sendQuestionnaire.sendQuestionnaireResponse;
