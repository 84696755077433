import { IResponse } from "models";

export enum ActionType {
  GET_HEALTH_CHECK_SETTING = "action/GET_HEALTH_CHECK_SETTING",
  GET_HEALTH_CHECK_SETTING_SUCCESS = "action/GET_HEALTH_CHECK_SETTING_SUCCESS",
  GET_HEALTH_CHECK_SETTING_ERROR = "action/GET_HEALTH_CHECK_SETTING_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGetHealthCheckSettingResponse extends IResponse {
  all_absent_reasons: any;
  health_check_setting: any;
  operation_setting: any;
  usable_absent_reasons: any;
}
