import { appconf_base } from "./appconf_base";

export enum ReleaseChannel {
  PRODUCTION = "production",
  DEVELOPMENT = "development",
}

const release_channel = ReleaseChannel.PRODUCTION;

export const appconf = {
  ...appconf_base,
  mode_name: "rel",
  release_channel: release_channel,
  print_request: false,
};
