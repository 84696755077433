import { ActionType } from "models/setting/settingData";

export const getSettingDataAction = () => {
  return {
    type: ActionType.GET_SETTING_DATA,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_SETTING_DATA_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: any) => {
  return {
    type: ActionType.GET_SETTING_DATA_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.GET_SETTING_DATA_RESET,
  };
};
