import {
  Action,
  ActionType,
  IClassTypeResponse,
} from "models/classmanagement/classType";
import createReducer from "../../createReducer";

export interface ClassTypeReducerType {
  loading: boolean;
  error?: string;
  classTypeResponse?: IClassTypeResponse;
}

const defaultState: ClassTypeReducerType = {
  loading: false,
  error: undefined,
  classTypeResponse: undefined,
};

export const classTypeReducer = createReducer<ClassTypeReducerType>(
  defaultState,
  {
    [ActionType.GET_CLASS_TYPE](state: ClassTypeReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_CLASS_TYPE_ERROR](
      state: ClassTypeReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_CLASS_TYPE_SUCCESS](
      state: ClassTypeReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        classTypeResponse: action.payload,
      };
    },
  }
);
