import { put, takeLatest, fork, call } from "redux-saga/effects";

import { ActionType, DataChartsResponse } from "models/bodyTemperature/charts";
import { httpStatus } from "configs/httpStatus";
import { fetchDataCharts } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./chartActions";

function* getDataChartsSaga({ payload }: { payload: { date: string } }) {
  try {
    const response: DataChartsResponse = yield call(fetchDataCharts, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDataChartsWatcher() {
  yield takeLatest(ActionType.DATA_CHARTS as any, getDataChartsSaga);
}

export default [fork(onGetDataChartsWatcher)];
