import * as React from "react";

function MinusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.58325 8.41663H11.0833C11.238 8.41663 11.3863 8.47808 11.4957 8.58748C11.6051 8.69688 11.6666 8.84525 11.6666 8.99996C11.6666 9.15467 11.6051 9.30304 11.4957 9.41244C11.3863 9.52183 11.238 9.58329 11.0833 9.58329H7.58325H6.41658H2.91659C2.76188 9.58329 2.6135 9.52183 2.50411 9.41244C2.39471 9.30304 2.33325 9.15467 2.33325 8.99996C2.33325 8.84525 2.39471 8.69688 2.50411 8.58748C2.6135 8.47808 2.76188 8.41663 2.91659 8.41663H6.41658H7.58325Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default MinusIcon;
