import * as React from "react";

function LineChartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.43164 11.0591L8.63619 18.5885L14.3635 5.41211L19.6135 13.4121"
        stroke={props.fill}
        strokeLinejoin="bevel"
        strokeDasharray="1 1"
      />
      <path
        d="M15.7728 5.88235C15.7728 6.63917 15.1486 7.26469 14.3637 7.26469C13.5788 7.26469 12.9546 6.63917 12.9546 5.88235C12.9546 5.12553 13.5788 4.5 14.3637 4.5C15.1486 4.5 15.7728 5.12553 15.7728 5.88235Z"
        fill="white"
        stroke={props.fill}
      />
      <path
        d="M21.5003 14.353C21.5003 15.1099 20.8761 15.7354 20.0912 15.7354C19.3063 15.7354 18.6821 15.1099 18.6821 14.353C18.6821 13.5962 19.3063 12.9707 20.0912 12.9707C20.8761 12.9707 21.5003 13.5962 21.5003 14.353Z"
        fill="white"
        stroke={props.fill}
      />
      <path
        d="M10.0452 18.1177C10.0452 18.8745 9.42104 19.5 8.63614 19.5C7.85125 19.5 7.22705 18.8745 7.22705 18.1177C7.22705 17.3609 7.85125 16.7354 8.63614 16.7354C9.42104 16.7354 10.0452 17.3609 10.0452 18.1177Z"
        fill="white"
        stroke={props.fill}
      />
      <path
        d="M4.31819 11.5298C4.31819 12.2866 3.69399 12.9122 2.90909 12.9122C2.1242 12.9122 1.5 12.2866 1.5 11.5298C1.5 10.773 2.1242 10.1475 2.90909 10.1475C3.69399 10.1475 4.31819 10.773 4.31819 11.5298Z"
        fill="white"
        stroke={props.fill}
      />
    </svg>
  );
}

export default LineChartIcon;
