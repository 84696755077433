import {
  ActionType,
  ISchoolTypeData,
  ISchoolTypeResponse,
} from "../../../models/classmanagement/schoolType";

export const schoolTypeAction = (payload: ISchoolTypeData) => {
  return {
    type: ActionType.GET_SCHOOL_TYPE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_SCHOOL_TYPE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ISchoolTypeResponse) => {
  return {
    type: ActionType.GET_SCHOOL_TYPE_SUCCESS,
    payload,
  };
};
