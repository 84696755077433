import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Grid, notification } from "antd";
import moment from "moment";

import { Button, Select } from "components";
import { ConfirmFailIcon, ConfirmDeliveryIcon, SendCompleteIcon } from "icons";
import {
  handleGetTimeByTimeZone,
  localStorageHelper,
  handleTimeOptions,
} from "utils";

import {
  GetMessageHistoryListFilterActionCreators,
  SendMessageActionCreators,
  UpdateMessageActionCreators,
  DeleteMessageActionCreators,
} from "redux/rootActions";
import { selectIsLoading } from "redux/messagemanagement/sendmessage/sendMessageStates";
import { deleteMessageDataResponse } from "redux/messagemanagement/deleteMessage/deleteMessageStates";
import { updateMessageDataResponse } from "redux/messagemanagement/updateMessage/updateMessageStates";
import { httpStatus } from "configs/httpStatus";

import {
  Text,
  ConfirmContainer,
  TextConfirm2,
  ButtonContainer,
  ConfirmModalStyled,
  ConfirmBtnStyled,
  FailText,
  SendMessageModalStyled,
  Flex,
  DeleteMessage,
  WrapperDateAndTime,
  WrapperDateAndTimeContainer,
  WrapperDateAndTimeContent,
  ButtonStyled,
} from "./MessageDelivery.style";
import { DatePicker2 } from "./components";
import { format, timeValueDefaults } from "./constants";

const { useBreakpoint } = Grid;

interface ISendMessageModal {
  isVisible: boolean;
  handleCancel: () => void;
  individualInfor: any;
  params: any;
  messageID: number;
}

const schema = yup.object().shape({
  message_title: yup.string().trim().required("message-title-required"),
  message: yup.string().required("message-required"),
});

const UpdateMessage: React.FC<ISendMessageModal> = ({
  isVisible,
  handleCancel,
  individualInfor,
  params,
  messageID,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "mental-health",
  });
  const { handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const screens = useBreakpoint();

  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const currentTimeZone = localStorageHelper.getItem("currentTimeZone");

  const dispatch = useDispatch();
  const sendMessageLoading = useSelector(selectIsLoading);
  const deleteMessageData = useSelector(deleteMessageDataResponse);
  const updateMessageData = useSelector(updateMessageDataResponse);

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isVisibleNotification, setIsVisibleNotification] = useState({
    type: "",
    status: false,
  });
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [timeOptions, setTimeOptions] = useState<any>([]);
  const [timeValue, setTimeValue] = useState<string>();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(SendMessageActionCreators.handleResetDataAction());
  }, []);

  const handleGetHour = (date: any) => {
    return moment(
      handleGetTimeByTimeZone("YYYY/MM/DD HH:mm", currentTimeZone, date)
    ).hour();
  };

  const handleTime = () => {
    const hour: any = handleGetHour(new Date()) + 1;
    const timeOptions = handleTimeOptions(hour);

    return {
      timeOptions,
      hour,
    };
  };

  useEffect(() => {
    if (Object.keys(individualInfor).length) {
      if (individualInfor.startDate) {
        const startDateConverted = handleGetTimeByTimeZone(
          format,
          currentTimeZone,
          individualInfor.startDate
        );
        const currentDate = handleGetTimeByTimeZone(
          format,
          currentTimeZone,
          new Date()
        );

        const hour = handleGetHour(individualInfor.startDate);

        if (currentDate === startDateConverted) {
          setTimeOptions(handleTimeOptions(hour));
        } else {
          setTimeOptions(timeValueDefaults);
        }

        setStartDate(new Date(startDateConverted));
        setTimeValue(`${hour}:00`);
      }
    }
  }, [individualInfor]);

  const onSendMessageAPI = () => {
    const formData = new FormData();
    const userIDs = individualInfor.address.map((ad: any) => ad.id);

    formData.append("title", individualInfor.subject);
    formData.append("description", individualInfor.content);
    formData.append("patient_id", userIDs.join(",") || "");
    formData.append("is_send_all", false as any);

    const start = moment(startDate).format(format) + " " + timeValue;
    formData.append("is_send_immediately", false as any);
    formData.append("start_time", start);

    dispatch(
      UpdateMessageActionCreators.updateMessageAction({
        id: messageID,
        param: formData,
      })
    );
  };

  const handleCancelSendMessageModal = () => {
    dispatch(UpdateMessageActionCreators.handleResetAction());
    handleCancel();
    dispatch(
      GetMessageHistoryListFilterActionCreators.messageHistoryListAction({
        ...params,
        page: 1,
      })
    );
  };

  const handleConfirmMessage = () => {
    setIsVisibleNotification({
      ...isVisibleNotification,
      type: "success",
      status: true,
    });
  };

  const handleFailMessage = () => {
    setIsVisibleNotification({
      ...isVisibleNotification,
      type: "fail",
      status: true,
    });
  };

  const handleCancelNotication = () => {
    setIsVisibleNotification({ type: "", status: false });
    handleCancelSendMessageModal();
  };

  const handleCancelConfirmMessage = () => {
    setShowConfirm(false);
  };

  useEffect(() => {
    if (updateMessageData) {
      if (updateMessageData.status === httpStatus.StatusOK) {
        handleConfirmMessage();
      } else {
        handleFailMessage();
      }
    }
  }, [updateMessageData]);

  useEffect(() => {
    if (deleteMessageData && deleteMessageData.status === httpStatus.StatusOK) {
      setIsShowDeleteModal(false);
      handleCancel();
      dispatch(DeleteMessageActionCreators.handleResetAction());
      dispatch(
        GetMessageHistoryListFilterActionCreators.messageHistoryListAction({
          ...params,
          page: 1,
        })
      );
      notification.success({
        message: t2("deleted"),
        description: "",
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
    }
  }, [deleteMessageData]);

  const handleDeleteMessage = () => {
    dispatch(
      DeleteMessageActionCreators.deleteMessageAction({
        id: messageID,
      })
    );
  };

  const SuccessModal = () => (
    <ConfirmModalStyled
      type="form"
      title=""
      visible={isVisibleNotification.status}
      onCancel={handleCancelNotication}
    >
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <ConfirmDeliveryIcon
          style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" }}
        />

        <Text
          size={36}
          lnHeight="36px"
          weight={700}
          color="#2ac769"
          mg="12px 0px 24px"
        >
          {t("delivery-reservation-completed")}
        </Text>
        <Text size={16} lnHeight="24px" weight={400}>
          {t("delivery-reservation-has-completed")}
        </Text>
        <Text size={16} lnHeight="24px" weight={400} mg="0px 0px 24px">
          {t("close-window")}
        </Text>
        <ConfirmBtnStyled
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 96px 13px"
          name={t("close-up")}
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          onClick={handleCancelNotication}
        />
      </div>
    </ConfirmModalStyled>
  );

  const FailureModal = () => (
    <ConfirmModalStyled
      type="form"
      title=""
      visible={isVisibleNotification.status}
      onCancel={handleCancelNotication}
    >
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <ConfirmFailIcon />
        <Text
          size={36}
          lnHeight="36px"
          weight={700}
          color="#fb4e4e"
          mg="20px 0px 24px"
        >
          {t("transmission-failure")}
        </Text>
        <FailText size={16} lnHeight="24px" weight={400}>
          {t("failed-to-send-the-message")}
        </FailText>
        <FailText size={16} lnHeight="24px" weight={400} mg="0px 0px 24px">
          {t("close-window-contact-support")}
        </FailText>
        <ConfirmBtnStyled
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 96px 13px"
          name={t("close-up")}
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          onClick={handleCancelNotication}
        />
      </div>
    </ConfirmModalStyled>
  );

  const renderNotificationModal = () => {
    if (isVisibleNotification.type === "success") {
      return <SuccessModal />;
    }
    return <FailureModal />;
  };

  const ConfirmModal = () => {
    return (
      <ConfirmContainer>
        <TextConfirm2>
          <div className="text-color">
            {moment(startDate).format("YYYY/MM/DD")}
            {currentLanguage !== "ja" && (
              <span style={{ marginLeft: 5, color: "#333333" }}>at</span>
            )}
            <span style={{ marginLeft: 5 }}>{timeValue}</span>
            {currentLanguage === "ja" && (
              <span style={{ color: "#333333" }}>で</span>
            )}
          </div>
          <div>{t("schedule-message")}</div>
          <div>{t("are-you-ok")}</div>
        </TextConfirm2>
        <ButtonContainer>
          <Button
            background="#E0E0E0"
            color="#FFFFFF"
            padding="11px 24px 13px"
            name={t1("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            onClick={handleCancelConfirmMessage}
          />
          <Button
            background="#2AC769"
            color="#FFFFFF"
            padding="11px 48px 13px"
            name={t("yes")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            disabled={sendMessageLoading}
            onClick={onSendMessageAPI}
          />
        </ButtonContainer>
      </ConfirmContainer>
    );
  };

  const DeleteMessageModal = () => {
    return (
      <ConfirmContainer>
        <TextConfirm2>
          <div>{t("delete-message-noti")}</div>
          <div>{t("are-you-ok")}</div>
        </TextConfirm2>
        <ButtonContainer>
          <Button
            background="#E0E0E0"
            color="#FFFFFF"
            padding="11px 24px 13px"
            name={t1("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            onClick={() => setIsShowDeleteModal(false)}
          />
          <Button
            background="#FB2121"
            color="#FFFFFF"
            padding="11px 48px 13px"
            name={t("yes")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            disabled={sendMessageLoading}
            onClick={handleDeleteMessage}
          />
        </ButtonContainer>
      </ConfirmContainer>
    );
  };

  const onChangeTime = (value: any) => {
    setTimeValue(value);
  };

  const renderSendMessageModal = () => (
    <SendMessageModalStyled
      type="form"
      title={t("confirm-change-delivery-reservation")}
      visible={isVisible}
      onCancel={() => {
        handleCancelSendMessageModal();
      }}
    >
      {showConfirm && <ConfirmModal />}
      {isShowDeleteModal && <DeleteMessageModal />}

      <form onSubmit={handleSubmit(() => setShowConfirm(true))}>
        <div>
          <WrapperDateAndTimeContainer>
            <WrapperDateAndTimeContent>
              <WrapperDateAndTime>
                <div>{t("delivery-date")}</div>
                <Flex>
                  <DatePicker2
                    onChange={(date: any) => {
                      const currentTimeZone =
                        localStorageHelper.getItem("currentTimeZone");
                      const selectedDate = handleGetTimeByTimeZone(
                        format,
                        currentTimeZone,
                        date
                      );
                      const startDateConverted = handleGetTimeByTimeZone(
                        format,
                        currentTimeZone,
                        individualInfor.startDate
                      );
                      const currentDate = handleGetTimeByTimeZone(
                        format,
                        currentTimeZone,
                        new Date()
                      );

                      if (selectedDate === currentDate) {
                        const { timeOptions, hour } = handleTime();
                        if (startDateConverted === selectedDate) {
                          const hour = handleGetHour(individualInfor.startDate);
                          setTimeValue(`${hour}:00`);
                          setTimeOptions(handleTimeOptions(hour));
                        } else {
                          setTimeOptions(timeOptions);
                          setTimeValue(`${hour}:00`);
                        }
                      } else {
                        setTimeOptions(timeValueDefaults);
                        if (startDateConverted === selectedDate) {
                          const hour = handleGetHour(individualInfor.startDate);
                          setTimeValue(`${hour}:00`);
                        } else {
                          setTimeValue("00:00");
                        }
                      }
                      setStartDate(date);
                    }}
                    selected={startDate}
                    iconPosition="right"
                    minDate={new Date()}
                    placeholderText="YYYY/MM/DD"
                  />
                </Flex>
              </WrapperDateAndTime>
              <WrapperDateAndTime>
                <div>{t("delivery-time")}</div>
                <Select
                  options={timeOptions}
                  onChange={onChangeTime}
                  value={timeValue}
                  width={screens.xs ? "207px" : "105px"}
                />
              </WrapperDateAndTime>
            </WrapperDateAndTimeContent>
            <ButtonStyled
              onClick={() => setShowConfirm(true)}
              type="submit"
              name={t("save-date-time")}
              background={"#2AC769"}
              color="#FFFFFF"
              border="none"
              fontSize={16}
              fontWeight={700}
              padding="7px 24px"
            />
          </WrapperDateAndTimeContainer>
          <DeleteMessage onClick={() => setIsShowDeleteModal(true)}>
            {t("delete-message")}
          </DeleteMessage>
        </div>
      </form>
    </SendMessageModalStyled>
  );

  return (
    <>
      {isVisibleNotification.status
        ? renderNotificationModal()
        : renderSendMessageModal()}
    </>
  );
};

export default UpdateMessage;
