import { ActionType, TodaySchedulessResponse } from "models/vaccine/schedules";

export const getTodaySchedulesAction = (payload: any) => {
  return {
    type: ActionType.TODAY_SCHEDULES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.TODAY_SCHEDULES_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: TodaySchedulessResponse) => {
  return {
    type: ActionType.TODAY_SCHEDULES_SUCCESS,
    payload,
  };
};
