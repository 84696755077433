import {
  ActionType,
  TemperatureFilterData,
  IGetTemperatureFilterResponse,
} from "../../../models/messagemanagement/temperatureFilterList";

export const temperatureFilterListAction = (payload: TemperatureFilterData) => {
  return {
    type: ActionType.GET_TEMPERATURE_LIST_FILTER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_TEMPERATURE_LIST_FILTER_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IGetTemperatureFilterResponse) => {
  return {
    type: ActionType.GET_TEMPERATURE_LIST_FILTER_SUCCESS,
    payload,
  };
};
