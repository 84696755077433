import { IResponse } from "models";

export enum ActionType {
  UPDATE_CHART_ORDER = "action/UPDATE_CHART_ORDER",
  UPDATE_CHART_ORDER_SUCCESS = "action/UPDATE_CHART_ORDER_SUCCESS",
  UPDATE_CHART_ORDER_ERROR = "action/UPDATE_CHART_ORDER_ERROR",
  UPDATE_CHART_ORDER_RESET = "action/UPDATE_CHART_ORDER_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateChartOrderPayload {
  dashboard_chart_order: string;
}

export interface IUpdateChartOrderResponse extends IResponse {
  result: any;
}
