import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/messagemanagement/messageHistoryList";
import { httpStatus } from "configs/httpStatus";
import { fetchHistoryList } from "services/messagemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./messageHistoryListActions";
import {
  IMessageHistoryData,
  IMessageHistoryResponse,
} from "models/messagemanagement/messageHistoryList";

function* messageHistoryListSaga({
  payload,
}: {
  payload: IMessageHistoryData;
}) {
  try {
    const response: IMessageHistoryResponse = yield call(
      fetchHistoryList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({
          ...response,
          result: {
            total: 0,
            message_histories: [],
          },
        })
      );
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onMessageHistoryListWatcher() {
  yield takeLatest(
    ActionType.MESSAGE_HISTORY_LIST as any,
    messageHistoryListSaga
  );
}

export default [fork(onMessageHistoryListWatcher)];
