import * as React from "react";

function ConfirmDeliveryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="188"
      height="188"
      viewBox="0 0 188 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_23169_43051)">
        <rect
          x="11"
          y="7"
          width="166"
          height="166"
          rx="83"
          fill="white"
          stroke="#2AC769"
          strokeWidth="14"
        />
        <circle cx="94" cy="90" r="9" fill="#2AC769" />
        <path
          d="M94 31L94 65"
          stroke="#2AC769"
          strokeWidth="12"
          strokeLinecap="round"
        />
        <path
          d="M119 90L141 90"
          stroke="#2AC769"
          strokeWidth="12"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_23169_43051"
          x="0"
          y="0"
          width="188"
          height="188"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_23169_43051"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_23169_43051"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default ConfirmDeliveryIcon;
