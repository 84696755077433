import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IAnswerInfoPayload,
  ActionType,
  AnswerInfoResponse,
} from "models/questionnaires/answerInfo";
import { httpStatus } from "configs/httpStatus";
import { fetchAnswerInfo } from "services/questionnaires";
import { handleSuccessAction, handleErrorAction } from "./answerInfoActions";

function* getAnswerInfoSaga({ payload }: { payload: IAnswerInfoPayload }) {
  try {
    const response: AnswerInfoResponse = yield call(fetchAnswerInfo, payload);

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetAnswerInfoSagaSubmitWatcher() {
  yield takeLatest(ActionType.GET_ANSWER_INFO as any, getAnswerInfoSaga);
}

export default [fork(onGetAnswerInfoSagaSubmitWatcher)];
