import {
  SendNotificationData,
  ActionType,
} from "models/mentalHealth/sendNotification";
import { IResponse } from "models";

export const sendNotificationAction = (payload: SendNotificationData) => {
  return {
    type: ActionType.SEND_NOTIFICATION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SEND_NOTIFICATION_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.SEND_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.RESET_SEND_NOTIFICATION,
  };
};
