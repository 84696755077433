import {
  Action,
  ActionType,
  IMessageHistoryResponse,
} from "models/messagemanagement/messageHistoryList";
import createReducer from "../../createReducer";

export interface MessageHistoryListReducerType {
  loading: boolean;
  error?: string;
  messageHistoryListResponse?: IMessageHistoryResponse;
}

const defaultState: MessageHistoryListReducerType = {
  loading: false,
  error: undefined,
  messageHistoryListResponse: undefined,
};

export const messageHistoryListReducer =
  createReducer<MessageHistoryListReducerType>(defaultState, {
    [ActionType.MESSAGE_HISTORY_LIST](state: MessageHistoryListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MESSAGE_HISTORY_LIST_ERROR](
      state: MessageHistoryListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.MESSAGE_HISTORY_LIST_SUCCESS](
      state: MessageHistoryListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        messageHistoryListResponse: action.payload,
      };
    },
  });
