import { Checkbox } from "antd";
import styled from "styled-components";
import { theme } from "theme/theme";
import { Select, Table, Modal } from "components";

export const AttendanceLabel = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  padding: 16px 0px 16px;
  width: 100%;
  border-bottom: 2px solid #cccccc;
  ${theme.media.tablet} {
    line-height: 16px;
    padding: 8px 0px 16px;
  }
`;

export const TaskWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 8px 8px 0px 0px;
`;

export const TaskWrapperLeft = styled.div`
  display: flex;
  gap: 8px;
  button {
    height: 23px;
  }
`;

export const TaskWrapperRight = styled.div`
  button {
    display: none;
  }
  ${theme.media.tablet} {
    button {
      display: unset;
    }
    > svg {
      display: none;
    }
  }
`;

export const PaginationWrapper = styled.div`
  margin-bottom: 16px;
  text-align: center;
  ${theme.media.tablet} {
    display: flex;
    justify-content: end;
    text-align: unset;
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 24px;
  ${theme.media.laptopL} {
    margin-bottom: 32px;
  }
`;

export const RowCellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 17px;
  svg {
    cursor: pointer;
  }
`;

export const DataRow = styled.div`
  display: flex;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  align-self: center;
  p {
    flex: 1;
    padding: 10px 0px 13px 8px;
    border-bottom: 1px dashed #cccccc;
  }
  span {
    flex: 1.2;
    padding: 10px 0px 13px 8px;
    border-right: 2px solid #cccccc;
    border-bottom: 1px dashed #cccccc;
  }
  :last-child {
    margin-bottom: 16px;
  }
  ${theme.media.mobileM} {
    p {
      flex: 2;
    }
    span {
      flex: 1.6;
    }
  }
  ${theme.media.tablet} {
    p {
      flex: 4;
      padding: 10px 0px 13px 16px;
    }
    span {
      flex: 1;
      padding: 10px 16px 13px 8px;
    }
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  p {
    background: #f2f2f2;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 3px 4px 4px;
    margin: 0;
    margin: 0px 4px 4px 0px;
    width: max-content;
  }
`;

export const TextLink = styled.div(({ theme }) => ({
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.regular,
  color: theme.colors.textLink,
  textDecoration: "underline",
  cursor: "pointer",
  marginLeft: 17,
}));

export const Text = styled.div(({ theme }) => ({
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.regular,
  color: theme.colors.titleText,
  marginLeft: 17,
}));

export const SelectStyled = styled(Select)`
  .ant-select-selector {
    align-items: unset;
  }
  .ant-select-selection-overflow {
    padding: 8px 0;
  }
  .ant-select-selection-overflow-item {
    align-self: unset;
  }
  .ant-select-selection-placeholder {
    top: 20px;
  }
`;

export const SearchWrapper = styled.div`
  ${theme.media.tablet} {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const SearchWrapperLeft = styled.div`
  margin-bottom: 24px;
  input {
    width: 100%;
    margin-bottom: 8px;
  }
  ${theme.media.tablet} {
    display: flex;
    input {
      width: 220px;
      margin-right: 16px;
    }
  }
  ${theme.media.laptopL} {
    input {
      width: 304px;
    }
  }
`;

export const WrapperSelectAndBtn = styled.div`
  display: flex;
  .ant-select {
    width: 175px;
    margin-right: 16px;
  }
  .ant-select-selector {
    width: 175px;
  }
  ${theme.media.mobileM} {
    .ant-select {
      width: 231px;
    }
    .ant-select-selector {
      width: 231px;
    }
  }
  ${theme.media.tablet} {
    .ant-select {
      width: 165px;
    }
    .ant-select-selector {
      width: 165px;
    }
    button {
      height: 31px;
    }
  }
`;

export const WrapperHeaderBtn = styled.div`
  position: absolute;
  right: 73px;
  top: 22px;
  button {
    display: none;
  }
  ${theme.media.tablet} {
    button {
      display: unset;
    }
  }
`;

export const WrapperCSVAction = styled.div`
  display: flex;
  input {
    width: 190px;
    margin-right: 16px;
  }
  ${theme.media.mobileM} {
    input {
      width: 231px;
    }
  }
  ${theme.media.tablet} {
    input {
      width: 592px;
    }
  }
`;

export const WrapperBtnForm = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  border-top: 0;
  button {
    font-size: ${theme.sizes.sm};
    width: 148px;
  }
  ${theme.media.tablet} {
    padding: 32px 0px 24px;
  }
`;

export const TableStyled = styled(Table)`
  .ant-table-thead > tr > th:first-child {
    text-align: center;
  }
  .ant-table-thead > tr > th:nth-child(8) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-thead > tr > th:nth-child(7) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-tbody > tr > td:first-child {
    padding-left: 16px;
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: right;
  }
`;

export const WrapperBtnUpdatingForm = styled.div`
  padding: 24px 0px;
  button {
    width: 148px;
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      order: 1;
    }
    div {
      order: 2;
    }
  }
`;

export const DeleteButton = styled.p`
  color: ${theme.colors.important};
  text-decoration: underline;
  font-size: ${theme.sizes.xs};
  line-height: 12px;
`;

export const GroupBtn = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  button {
    width: 148px;
  }
  ${theme.media.tablet} {
    justify-content: unset;
    margin-bottom: 0px;
    button {
      width: 128px;
    }
    button:first-child {
      margin-right: 16px;
    }
  }
`;

export const TextConfirm = styled.div`
  font-size: 22px;
  font-weight: ${theme.fontWeight.bold};
  line-height: 40px;
  color: ${theme.colors.text.primary};
`;

export const WrapperBtnConfirmModal = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  button {
    width: 151px;
    padding: 7px 0;
  }
  button:first-child {
    margin-right: 16px;
  }
  ${theme.media.tablet} {
    display: block;
    justify-content: unset;
    button {
      width: 128px;
      padding: 7px 0;
    }
    button:first-child {
      margin-right: 40px;
    }
  }
  ${theme.media.laptopL} {
  }
`;

export const ConfirmModalStyled = styled(Modal)`
  width: calc(100vw - 4px) !important;
  .ant-modal-body {
    padding: 32px 25px;
  }
  ${theme.media.tablet} {
    width: 532px !important;
  }
  ${theme.media.laptopL} {
    .ant-modal-body {
      padding: 32px;
    }
  }
`;

export const WrapperPermistion = styled.div`
  p {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    line-height: 18px;
    margin-bottom: 8px;
  }
  & > div {
    background: ${theme.colors.background};
    padding: 16px;
    border-radius: 12px;
  }
`;

export const WrapperEditForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  & > div {
    margin-bottom: 16px;
  }
  button {
    margin-top: 16px;
    height: 31px;
  }
  ${theme.media.tablet} {
    flex-direction: row;
    margin-bottom: 16px;
    & > div {
      margin-right: 24px;
    }
    input {
      width: 280px;
    }
    button {
      margin-left: 8px;
      margin-top: 19px;
    }
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.border};
  height: 38px;
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.bold};
  padding-left: 10px;
  background: ${theme.colors.white};
`;

export const WrapperItem = styled.div`
  min-width: 200px;
  max-width: 350px;
`;

export const WrapperTreeView = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  ${theme.media.tablet} {
    flex-flow: row wrap;
    gap: 32px 24px;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  align-items: center;
  outline: none;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.colors.border};
    }
  }
  .ant-checkbox {
    top: unset;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 21px;
    border: 2px solid ${theme.colors.border};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button}!important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.border};
  }

  div {
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
`;

export const WrapperRegistrationForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  button {
    height: 31px;
  }
  ${theme.media.tablet} {
    flex-direction: row;
    margin-bottom: 16px;
    & > div {
      margin-right: 24px;
    }
    input {
      width: 280px;
    }
    button {
      margin-left: 8px;
      margin-top: 19px;
    }
  }
`;

export const WrapperInputs = styled.div`
  & > div {
    margin-bottom: 24px;
  }

  ${theme.media.tablet} {
    display: flex;
    flex-wrap: wrap;
    gap: 0 24px;
    width: 584px;
  }
`;

export const Label = styled.div`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.sizes.xl};
  line-height: 20px;
  color: ${theme.colors.titleText};
  margin-bottom: 16px;
`;

export const Important = styled.span`
  color: ${theme.colors.important};
`;
