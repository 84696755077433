import {
  Action,
  ActionType,
  ReservationsResponse,
} from "models/vaccine/reservations";
import createReducer from "../../createReducer";

export interface ReservationsReducerType {
  loading: boolean;
  error?: string;
  reservationsResponse?: ReservationsResponse;
}

const defaultState: ReservationsReducerType = {
  loading: false,
  error: undefined,
  reservationsResponse: undefined,
};

export const reservationsReducer = createReducer<ReservationsReducerType>(
  defaultState,
  {
    [ActionType.RESERVATIONS](state: ReservationsReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.RESERVATIONS_ERROR](
      state: ReservationsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.RESERVATIONS_SUCCESS](
      state: ReservationsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        reservationsResponse: action.payload,
      };
    },
  }
);
