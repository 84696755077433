export enum ActionType {
  // creatte user
  SEND_NOTIFICATION = "action/SEND_NOTIFICATION",
  SEND_NOTIFICATION_SUCCESS = "action/SEND_NOTIFICATION_SUCCESS",
  SEND_NOTIFICATION_ERROR = "action/SEND_NOTIFICATION_ERROR",
  RESET_SEND_NOTIFICATION = "action/RESET_SEND_NOTIFICATION",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface SendNotificationData {
  title: string;
  description: string;
  patient_id?: string;
  is_send_all: boolean;
  mental_check_request_id: number;
  is_remainder?: boolean;
}
