import { httpStatus } from "./httpStatus";
import axios from "axios";
import { isLoggedIn, getCookie, removeCookie } from "utils";

export const authTokenKey = process.env.REACT_APP_ACCESS_TOKEN_KEY!;

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status: number) => {
    return (
      status >= httpStatus.StatusOK &&
      status < httpStatus.StatusHTTPVersionNotSupported
    );
  },
  timeout: 600 * 1000,
};
const httpClient = axios.create(config);

httpClient.interceptors.request.use(
  (config: any) => {
    if (isLoggedIn()) {
      config.headers["X-USER-TOKEN"] = getCookie(authTokenKey);
      config.headers["Access-Control-Allow-Origin"] = "http://localhost:3000";
      config.headers["Access-Control-Allow-Credentials"] = "true";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    if (response.status === httpStatus.StatusUnauthorized && isLoggedIn()) {
      removeCookie(authTokenKey);
      window.localStorage.clear();
      window.location.replace("/login");
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default httpClient;
