import * as React from "react";

function ConfirmSuccessIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="180"
      height="180"
      viewBox="0 0 180 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="7"
        y="7"
        width="166"
        height="166"
        rx="83"
        fill="white"
        stroke="#2AC769"
        strokeWidth="14"
      />
      <path
        d="M143.503 54.2861C142.782 53.5617 141.924 52.9868 140.979 52.5944C140.034 52.202 139.02 52 137.996 52C136.973 52 135.959 52.202 135.014 52.5944C134.069 52.9868 133.211 53.5617 132.49 54.2861L74.7085 111.943L50.4326 87.6743C49.684 86.9537 48.8003 86.387 47.8319 86.0068C46.8635 85.6265 45.8295 85.44 44.7888 85.4579C43.7481 85.4759 42.7212 85.6979 41.7666 86.1113C40.812 86.5248 39.9485 87.1215 39.2253 87.8675C38.5022 88.6135 37.9336 89.4941 37.552 90.4591C37.1703 91.4241 36.9832 92.4545 37.0012 93.4915C37.0192 94.5286 37.242 95.5519 37.6569 96.5032C38.0718 97.4544 38.6706 98.3149 39.4192 99.0355L69.2018 128.714C69.9228 129.438 70.7806 130.013 71.7258 130.406C72.6709 130.798 73.6846 131 74.7085 131C75.7324 131 76.7461 130.798 77.6912 130.406C78.6363 130.013 79.4941 129.438 80.2152 128.714L143.503 65.6474C144.29 64.9237 144.919 64.0453 145.348 63.0676C145.778 62.0899 146 61.0341 146 59.9668C146 58.8994 145.778 57.8436 145.348 56.8659C144.919 55.8883 144.29 55.0099 143.503 54.2861V54.2861Z"
        fill="#2AC769"
      />
    </svg>
  );
}

export default ConfirmSuccessIcon;
