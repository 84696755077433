import React, { useEffect } from "react";
import styled from "styled-components";
import { Col, Row, Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { jsPDF } from "jspdf";

import { Button, Box } from "components";
import { theme } from "theme/theme";
import { DownloadIcon } from "icons";
import { DownloadQrCodeData } from "models/downloadQrCode";
import { DownloadQrcodeActionCreators } from "redux/rootActions";
import {
  selectIsLoading,
  getDownloadQrcodeSuccessResponse,
} from "redux/qrcode/qrcodeStates";
import { mplusFont, udgothicFont } from "utils";

const { useBreakpoint } = Grid;

const WrapperButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0px;
  span {
    background: ${theme.colors.white};
    padding: 11px 29px 12px;
    color: ${theme.colors.important};
    font-size: 24px;
    font-weight: bold;
    line-height: 16px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.important};
  }
  ${theme.media.tablet} {
    span {
      font-size: 32px;
      padding: 17px 36px 19px;
    }
  }
`;

const Wrapper = styled.div`
  ${theme.media.tablet} {
    button {
      width: 215px;
    }
  }
  ${theme.media.laptopL} {
    padding-left: 32px;
    button {
      width: 100%;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  ${theme.media.desktop} {
    height: 601px;
    width: 423px;
  }
  ${theme.media.desktopL} {
    height: 100%;
    width: 100%;
  }
`;

const QRCode: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "qr-code" });
  const { t: t1 }: any = useTranslation("translation", { keyPrefix: "layout" });
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const downloadQrcodeSuccessResponse = useSelector(
    getDownloadQrcodeSuccessResponse
  );

  const onSubmit = () => {
    const params: DownloadQrCodeData = {
      //出力部数の入力フォームを実装していたが、必要無くなったため該当箇所削除済み。
      //出力部数は１として固定値をセット
      number_of_qrcodes: 1,
    };
    dispatch(DownloadQrcodeActionCreators.downloadQrcodeAction(params));
  };

  // const getDate = (date: any) => {
  //   const year = date.getFullYear();
  //   const month = date.getMonth() + 1;
  //   const day = date.getDate();
  //   return `${year}年${month}月${day}日`;
  // };

  useEffect(() => {
    if (downloadQrcodeSuccessResponse && downloadQrcodeSuccessResponse.result) {
      dispatch(DownloadQrcodeActionCreators.handleResetDataAction());
      const pdf = new jsPDF("p", "px", "a4");
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      const { image_1, image_2, qrcodes, company_name } =
        downloadQrcodeSuccessResponse.result;

      for (const [idx, qrcode] of qrcodes.entries() as any) {
        const pageMargin = 12;

        pdf.addImage(
          image_1,
          "png",
          pageMargin,
          pageMargin,
          width - 2 * pageMargin,
          height - 2 * pageMargin,
          "",
          "FAST"
        );

        pdf.addPage("p");
        pdf.addImage(
          image_2,
          "png",
          pageMargin,
          pageMargin,
          width - 2 * pageMargin,
          height - 11 * pageMargin,
          "",
          "FAST"
        );

        pdf.setDrawColor(178, 177, 185);
        pdf.addFileToVFS("BIZUDGothic-Regular-normal.ttf", udgothicFont);
        pdf.addFont(
          "BIZUDGothic-Regular-normal.ttf",
          "BIZUDGothic-Regular",
          "normal"
        );
        pdf.setFont("BIZUDGothic-Regular", "normal");

        const y = height - 9 * pageMargin;
        const boxHeight = 7.5 * pageMargin;
        const qrcodeHeight = 7 * pageMargin;
        pdf.setTextColor(128, 128, 128);
        pdf.roundedRect(pageMargin, y, width - 2 * pageMargin, boxHeight, 2, 2);
        pdf.addImage(
          qrcode.qr_code_img,
          "png",
          1.1 * pageMargin,
          y + 0.1 * pageMargin,
          qrcodeHeight,
          qrcodeHeight
        );
        pdf.setFontSize(16);
        pdf.text(
          company_name,
          (width -
            qrcodeHeight -
            1.1 * pageMargin -
            (pdf.getStringUnitWidth(company_name) * pdf.getFontSize()) /
              pdf.internal.scaleFactor) /
            2 +
            qrcodeHeight +
            1.1 * pageMargin,
          y + 0.1 * pageMargin + 0.2 * qrcodeHeight
        );
        pdf.setFontSize(14);
        pdf.text(
          "← 児童・生徒のアカウント追加の際に読み込んで下さい。",
          3 * pageMargin + qrcodeHeight,
          y + 0.1 * pageMargin + 0.5 * qrcodeHeight
        );
        pdf.setFontSize(10);
        pdf.text(
          "※ ごきょうだいのアカウント追加の場合も、1枚の二次元バーコードでご登録いただけます。",
          2 * pageMargin + qrcodeHeight,
          y + 0.1 * pageMargin + 0.7 * qrcodeHeight
        );
        pdf.setFontSize(11);
        pdf.text(
          `IDでご登録の場合はこちら「${qrcode.qr_code}」`,
          2 * pageMargin + qrcodeHeight,
          y + 0.1 * pageMargin + 0.9 * qrcodeHeight
        );
        if (idx < qrcodes.length - 1) {
          pdf.addPage("p");
        }
      }
      pdf.save(company_name + "_registration_flyer");
    }
  }, [downloadQrcodeSuccessResponse]);

  return (
    <Box title={t1("qr-code-label")} padding={screens.xl ? "32px" : "16px"}>
      <Row>
        <Col xs={24} md={24} xl={19} order={screens.xl ? 0 : 2}>
          <div style={{ position: "relative" }}>
            <Row gutter={screens.md ? 16 : 6}>
              <Col span={12}>
                <Image src="/images/qr-code-1.png" />
              </Col>
              <Col span={12}>
                <Image src="/images/qr-code-2.png" />
              </Col>
            </Row>
            <WrapperButton>
              <span>{t("qr-code-name-btn")}</span>
            </WrapperButton>
          </div>
        </Col>
        <Col xs={24} md={24} xl={5} style={{ marginBottom: 40 }}>
          <Wrapper
            style={{ paddingLeft: screens.lg ? 8 : screens.xl ? 32 : 0 }}
          >
            <Button
              onClick={onSubmit}
              background="#2AC769"
              color="#fff"
              padding="11px 0px"
              name={
                <span style={{ marginLeft: 10 }}>
                  {t("qr-code-btn-of-input")}
                </span>
              }
              border="none"
              bdr="6px"
              width="100%"
              fontSize={16}
              fontWeight={700}
              lineHeight="16px"
              disabled={isLoading}
              icon={
                <DownloadIcon
                  width="20px"
                  height="20px"
                  fill="currentColor"
                  style={{
                    position: "absolute",
                    left: 12,
                    top: 10,
                    marginRight: 4,
                  }}
                />
              }
            />
          </Wrapper>
        </Col>
      </Row>
    </Box>
  );
};

export default QRCode;
