import {
  Action,
  ActionType,
  DeleteSlotResponse,
} from "models/vaccine/schedules";
import createReducer from "../../createReducer";

export interface DeleteSlotReducerType {
  loading: boolean;
  error?: any;
  deleted: boolean;
  deleteSlotResponse?: DeleteSlotResponse;
}

const defaultState: DeleteSlotReducerType = {
  loading: false,
  error: null,
  deleted: false,
  deleteSlotResponse: undefined,
};

export const deleteSlotReducer = createReducer<DeleteSlotReducerType>(
  defaultState,
  {
    [ActionType.DELETE_SLOT](state: DeleteSlotReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_SLOT_ERROR](
      state: DeleteSlotReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        deleted: false,
      };
    },

    [ActionType.DELETE_SLOT_SUCCESS](
      state: DeleteSlotReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleted: true,
        deleteSlotResponse: action.payload,
      };
    },

    [ActionType.DELETE_SLOT_RESET](state: DeleteSlotReducerType) {
      return {
        ...state,
        loading: false,
        deleted: false,
        error: null,
        deleteSlotResponse: undefined,
      };
    },
  }
);
