import * as React from "react";

function JuniorHighEngIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_25526_97311)">
        <path
          d="M26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13Z"
          fill="#2A98C7"
        />
        <path
          d="M12.9999 20.2517C11.2179 20.2517 9.43589 19.5497 8.13989 18.3077L9.66989 16.4717C10.6239 17.3537 11.8839 17.9477 13.0539 17.9477C14.4399 17.9477 15.1779 17.3537 15.1779 16.4177C15.1779 15.4277 14.3679 15.1217 13.1799 14.6177L11.3979 13.8617C10.0479 13.3037 8.69789 12.2237 8.69789 10.2797C8.69789 8.10173 10.6059 6.42773 13.3059 6.42773C14.8539 6.42773 16.4019 7.03973 17.4819 8.13773L16.1319 9.81173C15.2859 9.10973 14.4039 8.71373 13.3059 8.71373C12.1359 8.71373 11.3979 9.23573 11.3979 10.1177C11.3979 11.0717 12.3339 11.4137 13.4679 11.8637L15.2139 12.6017C16.8339 13.2677 17.8959 14.2937 17.8959 16.2197C17.8959 18.3977 16.0779 20.2517 12.9999 20.2517Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_25526_97311">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default JuniorHighEngIcon;
