import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IGetSchoolClassResponse,
} from "models/classmanagement/classList";
import { httpStatus } from "configs/httpStatus";
import { fetchSchoolClasses } from "services/schoolclasses";
import { handleSuccesAction, handleErrorAction } from "./classesListActions";

function* schoolClassesListSaga() {
  try {
    const response: IGetSchoolClassResponse = yield call(fetchSchoolClasses);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetSchoolClassesListWatcher() {
  yield takeLatest(
    ActionType.GET_CLASS_LIST_SCHOOL as any,
    schoolClassesListSaga
  );
}

export default [fork(onGetSchoolClassesListWatcher)];
