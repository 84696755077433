import {
  ActionType,
  IQuestionHistoryDetailData,
  IQuestionHistoryDetailResponse,
} from "models/questionnaires/questionHistoryDetail";

export const questionHistoryDetailAction = (
  payload: IQuestionHistoryDetailData
) => {
  return {
    type: ActionType.QUESTION_HISTORY_DETAIL,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.QUESTION_HISTORY_DETAIL_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IQuestionHistoryDetailResponse) => {
  return {
    type: ActionType.QUESTION_HISTORY_DETAIL_SUCCESS,
    payload,
  };
};
export const handleResetHistoryDetailAction = () => {
  return {
    type: ActionType.RESET_QUESTION_HISTORY_DETAIL,
  };
};
