import {
  ActionType,
  DetailSymtomPayload,
  DetailSymtomResponse,
} from "models/dashboard/detailSymtom";

export const getDetailSymtomAction = (payload: DetailSymtomPayload) => {
  return {
    type: ActionType.GET_DETAIL_SYMTOM_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_DETAIL_SYMTOM_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DetailSymtomResponse) => {
  return {
    type: ActionType.GET_DETAIL_SYMTOM_CHART_SUCCESS,
    payload,
  };
};
