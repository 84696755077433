import { IResponse } from "models";

export enum ActionType {
  // forgotpassword
  FORGOT_PASSWORD = "action/FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS = "action/FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR = "action/FORGOT_PASSWORD_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ForgotPasswordData {
  locale: string;
  user: {
    email: string | null;
    role: number | null;
  };
}

export interface ForgotPasswordResponse extends IResponse {
  message: string;
}
