import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { Button, TextArea, Input } from "components";
import { CloseIcon, ConfirmSuccessIcon, ConfirmFailIcon } from "icons";
import {
  sendNotificationActionCreators,
  GetLastNotificationTimeActionCreators,
} from "redux/rootActions";
import {
  sendNotificationDataResponse,
  selectErrorMessage,
  selectIsLoading,
} from "redux/mentalHealth/sendNotification/sendNotificationStates";
import { httpStatus } from "configs/httpStatus";

import {
  Text,
  ConfirmContainer,
  TextConfirm,
  ButtonContainer,
  SectionContainer,
  Label,
  BadgeContainer,
  ConfirmModalStyled,
  ConfirmBtnStyled,
  FailText,
  WrapperSendMessageBtn,
  SendMessageModalStyled,
} from "screens/messageDelivery/MessageDelivery.style";
import { TagsContainer, WrapperBtnForm } from "./MentalHealth.style";

interface IConfirmInterviewModal {
  isVisible: boolean;
  handleCancel: () => void;
  checkBoxList: any;
  isCheckAll: boolean;
  selectedRequest: number;
  setCheckBoxList: any;
}

const schema = yup.object().shape({
  message_title: yup.string().trim().required("message-title-required"),
  message: yup.string().required("message-required"),
});

const ConfirmInterviewModal: React.FC<IConfirmInterviewModal> = ({
  isVisible,
  handleCancel,
  checkBoxList,
  isCheckAll,
  selectedRequest,
  setCheckBoxList,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "mental-health",
  });

  const { handleSubmit, control, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const sendNotificationData = useSelector(sendNotificationDataResponse);
  const sendNotificationError = useSelector(selectErrorMessage);
  const isLoading = useSelector(selectIsLoading);

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isVisibleNotification, setIsVisibleNotification] = useState({
    type: "",
    status: false,
  });

  const watchmessageTitle = watch("message_title");
  const watchmessage = watch("message");

  const handleCancelConfirmMessage = () => {
    setShowConfirm(false);
  };

  const handleCancelSendMessageModal = () => {
    handleCancel();
  };

  const handleConfirmMessage = () => {
    setIsVisibleNotification({
      ...isVisibleNotification,
      type: "success",
      status: true,
    });
  };

  const handleFailMessage = () => {
    setIsVisibleNotification({
      ...isVisibleNotification,
      type: "fail",
      status: true,
    });
  };

  const handleCancelNotication = () => {
    setIsVisibleNotification({ type: "", status: false });
    dispatch(sendNotificationActionCreators.handleResetDataAction());
    dispatch(
      GetLastNotificationTimeActionCreators.getLastNotificationTimeAction({
        mental_check_request_id: selectedRequest,
      })
    );
    handleCancelSendMessageModal();
  };

  useEffect(() => {
    setValue("message_title", "こころの健康観察を実施してください。");
    setValue(
      "message",
      "今現在「こころの健康観察タイトル」を実施されていない方にお送りしております。再度のご連絡になりますが、このメッセージをタップして、「こころの健康観察タイトル」を実施してください。"
    );
  }, []);

  useEffect(() => {
    if (
      sendNotificationData &&
      sendNotificationData.status === httpStatus.StatusOK
    ) {
      handleConfirmMessage();
    } else if (sendNotificationError) {
      handleFailMessage();
    } else {
      handleCancelConfirmMessage();
    }
  }, [sendNotificationData, sendNotificationError]);

  const SuccessModal = () => (
    <ConfirmModalStyled
      type="form"
      title=""
      visible={isVisibleNotification.status}
      onCancel={handleCancelNotication}
    >
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <ConfirmSuccessIcon />
        <Text
          size={36}
          lnHeight="36px"
          weight={700}
          color="#2ac769"
          mg="20px 0px 24px"
        >
          {t("send-completely")}
        </Text>
        <Text size={16} lnHeight="24px" weight={400}>
          {t("the-transmission-completed")}
        </Text>
        <Text size={16} lnHeight="24px" weight={400} mg="0px 0px 24px">
          {t("close-window")}
        </Text>
        <ConfirmBtnStyled
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 96px 13px"
          name={t("close-up")}
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          onClick={handleCancelNotication}
        />
      </div>
    </ConfirmModalStyled>
  );

  const FailureModal = () => (
    <ConfirmModalStyled
      type="form"
      title=""
      visible={false}
      onCancel={handleCancelNotication}
    >
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <ConfirmFailIcon />
        <Text
          size={36}
          lnHeight="36px"
          weight={700}
          color="#fb4e4e"
          mg="20px 0px 24px"
        >
          {t("transmission-failure")}
        </Text>
        <FailText size={16} lnHeight="24px" weight={400}>
          {t("failed-to-send-the-message")}
        </FailText>
        <FailText size={16} lnHeight="24px" weight={400} mg="0px 0px 24px">
          {t("close-window-contact-support")}
        </FailText>
        <ConfirmBtnStyled
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 96px 13px"
          name={t("close-up")}
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          onClick={handleCancelNotication}
        />
      </div>
    </ConfirmModalStyled>
  );

  const renderNotificationModal = () => {
    if (isVisibleNotification.type === "success") {
      return <SuccessModal />;
    }
    return <FailureModal />;
  };

  const onSendMessageAPI = () => {
    const params = {
      title: watchmessageTitle,
      description: watchmessage,
      mental_check_request_id: selectedRequest,
      is_send_all: isCheckAll,
      patient_id: checkBoxList
        ?.map((uif: any) => {
          return uif.patient_id;
        })
        .join(","),
      is_remainder: true,
    };
    dispatch(sendNotificationActionCreators.sendNotificationAction(params));
  };

  const ConfirmModal = () => (
    <ConfirmContainer>
      <TextConfirm>
        {t2("send-interview-notice")}
        <br></br>
        {t("are-you-ok")}
      </TextConfirm>
      <ButtonContainer>
        <Button
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 24px 13px"
          name={t1("cancel")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          onClick={handleCancelConfirmMessage}
        />
        <Button
          background="#2AC769"
          color="#FFFFFF"
          padding="11px 48px 13px"
          name={t("yes")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          disabled={isLoading}
          onClick={onSendMessageAPI}
        />
      </ButtonContainer>
    </ConfirmContainer>
  );

  const renderSendMessageModal = () => (
    <SendMessageModalStyled
      type="form"
      title={t2("interview-confirmation-notice")}
      visible={isVisible}
      onCancel={() => {
        // setIsDisabled(false);
        handleCancelSendMessageModal();
      }}
    >
      {showConfirm && <ConfirmModal />}
      <form onSubmit={handleSubmit(() => setShowConfirm(true))}>
        <SectionContainer>
          <Label>{t("address")}</Label>
          <TagsContainer>
            <BadgeContainer>
              {checkBoxList?.map((uif: any) => {
                return (
                  <div key={uif.patient_id}>
                    <p>{uif.full_name}</p>
                    <CloseIcon
                      fill="#3B3B3B"
                      onClick={() => {
                        setCheckBoxList(
                          checkBoxList.filter(
                            (item: any) => item.patient_id !== uif.patient_id
                          )
                        );
                      }}
                    />
                  </div>
                );
              })}
            </BadgeContainer>
          </TagsContainer>
        </SectionContainer>
        <Controller
          control={control}
          name="message_title"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              label={
                <Label>
                  {t("subject")}
                  <span style={{ color: "#EB5757", marginLeft: 5 }}>*</span>
                </Label>
              }
              height={54}
              fw={700}
              fsLabel={16}
              fwLabel={700}
              marginForm="0 0 16px"
              lhLabel="22px"
              bdr={12}
              value={value}
              onChange={onChange}
              error={t(error?.message)}
            />
          )}
        />
        <Controller
          control={control}
          name="message"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextArea
              onChange={onChange}
              error={t(error?.message)}
              height="248px"
              fsLabel={16}
              fwLabel={700}
              lhLabel="22px"
              marginForm="0 0 16px"
              label={
                <Label>
                  {t("message")}
                  <span style={{ color: "#EB5757", marginLeft: 5 }}>*</span>
                </Label>
              }
              value={value}
            />
          )}
        />

        <WrapperSendMessageBtn>
          <Button
            background="#2AC769"
            color="#FFFFFF"
            name={t("send")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="23px"
            disabled={!checkBoxList.length}
          />
        </WrapperSendMessageBtn>
        <WrapperBtnForm>
          <Button
            background="#E0E0E0"
            color="#FFFFFF"
            name={t1("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            onClick={() => {
              handleCancelSendMessageModal();
            }}
          />
        </WrapperBtnForm>
      </form>
    </SendMessageModalStyled>
  );

  return (
    <>
      {isVisibleNotification.status
        ? renderNotificationModal()
        : renderSendMessageModal()}
    </>
  );
};

export default ConfirmInterviewModal;
