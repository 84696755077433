import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { notification, Radio, Tooltip } from "antd";

import { Button, Select } from "components";
import { DeleteIcon, PlusIcon, SendCompleteIcon, InformationIcon } from "icons";
import { httpStatus } from "configs/httpStatus";
import { uuid } from "utils";

import { getHealthCheckSettingResponse } from "redux/setting/getHealthCheckSetting/getHealthCheckSettingStates";
import { setHealthCheckSettingResponse } from "redux/setting/healthCheckSetting/healthCheckSettingStates";
import {
  SetHealthCheckSettingActionCreators,
  GetHealthCheckSettingActionCreators,
} from "redux/rootActions";

import {
  WrapperTemperatureSection,
  WrapperTitle,
  Label,
  FormTitle,
  NotificationSettingContainer,
  ItemAdditionalSelectionContainer,
  EmailSettingContainer,
  WrapperAddBtn,
  FlexContainer,
  MessageContainer,
  MessageTitle,
  MessageList,
  MessageListItem,
  CheckItem,
  ReminderSelect,
  CheckboxStyled,
  ReminderFlex,
  ItemAdditionalSelection,
  LastCheckboxStyled,
  TableStyled,
  TimeSelect,
  HourWrap,
  NoData,
  TooltipRightStyled,
  WrapperCheckbox,
  CheckItem2,
  WrapperInforIcon,
  WrapperDisease,
  DiseaseContainer,
  OperatingTimeContainer,
  Container,
  AudioWrapper,
} from "./Setting.style";
import DeleteModal from "./DeleteModal";
import { dayTimeOptions, hourOptions, minutesOptions } from "./constant";
import { CheckboxGroup, Input } from "./components";

const timeValue: any = {};
let j = 1;
for (let i = 13; i <= 24; i++) {
  if (i === 24) {
    timeValue[i] = "00";
  } else {
    if (j >= 10) {
      timeValue[i] = `${j++}`;
    } else {
      timeValue[i] = `0${j++}`;
    }
  }
}

const hourOptions2: any = [];

for (let i = 0; i <= 23; i++) {
  if (i <= 9) {
    hourOptions2.push({
      id: i,
      value: `0${i}`,
      name: `0${i}`,
    });
  } else {
    hourOptions2.push({
      id: i,
      value: i,
      name: `${i}`,
    });
  }
}

const minuteOptions2: any = [];

for (let i = 0; i <= 11; i++) {
  if (i <= 1) {
    minuteOptions2.push({
      id: i,
      value: `0${i * 5}`,
      name: `0${i * 5}`,
    });
  } else {
    minuteOptions2.push({
      id: i,
      value: i * 5,
      name: i * 5,
    });
  }
}

const TemperatureSetting = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "setting" });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t4 }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t5 }: any = useTranslation("translation", {
    keyPrefix: "questions",
  });

  const dispatch = useDispatch();
  const getHealthCheckSetting = useSelector(getHealthCheckSettingResponse);
  const setHealthCheckSetting = useSelector(setHealthCheckSettingResponse);

  const { handleSubmit, control, setValue, watch } = useForm();

  const watchUseRemider = watch("useReminder");
  const watchStartHour = watch("startHour");
  const watchActiveOperationHours = watch("activeOperationHours");
  const watchDiseaseGroup = watch("diseaseGroup");

  const disabledStyle: any =
    watchDiseaseGroup === "heartHealth"
      ? {
          opacity: 0.4,
          pointerEvents: "none",
        }
      : {};
  const disabledStyle1: any = !watchActiveOperationHours
    ? {
        opacity: 0.4,
        pointerEvents: "none",
      }
    : {};

  const [isVisible, setIsVisible] = useState(false);
  const [dataTable, setDataTable] = useState<any>([]);
  const [notifyID, setNotifyID] = useState<any>();
  const [deleteItem, setDeleteItem] = useState<any>();
  const [endHourOptions, setEndHourOptions] = useState([]);
  const [allDisease, setAllDisease] = useState([]);

  const handleStartTimeAndEndTime = (startTime: any, endTime: any) => {
    if (!startTime && !endTime) {
      setValue("startHour", "07");
      setValue("startMinute", "00");
      setValue("endHour", "17");
      setValue("endMinute", "00");
    } else {
      const [startHour, startMinute] = startTime.split(":");

      setValue("startHour", startHour);
      setValue("startMinute", startMinute);

      const hourOptionsArr: any = [];

      for (let i = 1; i <= 23; i++) {
        if (i <= 9) {
          hourOptionsArr.push({
            id: i,
            value: `0${i}`,
            name: `0${i}`,
            disabled: i <= startHour ? true : false,
          });
        } else {
          hourOptionsArr.push({
            id: i,
            value: `${i}`,
            name: `${i}`,
            disabled: i <= startHour ? true : false,
          });
        }
      }
      setEndHourOptions(hourOptionsArr);

      const [endHour, endMinute] = endTime.split(":");

      setValue("endHour", endHour);
      setValue("endMinute", endMinute);
    }
  };

  useEffect(() => {
    if (watchStartHour) {
      const hourOptionsArr: any = [];

      for (let i = 1; i <= 23; i++) {
        if (i <= 9) {
          hourOptionsArr.push({
            id: i,
            value: `0${i}`,
            name: `0${i}`,
            disabled: i <= watchStartHour ? true : false,
          });
        } else {
          hourOptionsArr.push({
            id: i,
            value: `${i}`,
            name: `${i}`,
            disabled: i <= watchStartHour ? true : false,
          });
        }
      }
      setEndHourOptions(hourOptionsArr);
    }
  }, [watchStartHour]);

  useEffect(() => {
    if (watchUseRemider) {
      setValue("reminderInterval", 15);
    } else {
      setValue("reminderInterval", undefined);
    }
  }, [watchUseRemider]);

  useEffect(() => {
    if (
      getHealthCheckSetting &&
      getHealthCheckSetting.status === httpStatus.StatusOK
    ) {
      const {
        operation_setting,
        health_check_setting,
        usable_absent_reasons,
        all_absent_reasons,
      } = getHealthCheckSetting;

      const convertNotificationData =
        health_check_setting.notifications.temperature_notification_times.map(
          (notification: any) => {
            const [hour, minute] = notification.split(":");
            const newHour = hour > 12 ? timeValue[hour] : hour;
            let time = "AM";
            let time_label = t("am");
            if (hour >= "00" && hour <= "03") {
              time_label = t("evening");
            }
            if (hour >= "12" && hour <= "16") {
              time = "PM";
              time_label = t("pm");
            }
            if (hour >= "17" && hour <= "23") {
              time = "PM";
              time_label = t("evening");
            }

            const notificationTime = `${newHour}:${minute}`;
            return {
              id: uuid(),
              time_label,
              time,
              notification_time: notificationTime,
            };
          }
        );

      setDataTable(convertNotificationData);

      const {
        can_use_health_check,
        can_use_heart_and_health,
        operation_start_at,
        operation_finish_at,
        can_set_active_operation_hours,
      } = operation_setting;

      let disease = "";

      if (can_use_health_check) {
        disease = "healthCheck";
      }

      if (can_use_heart_and_health) {
        disease = "heartHealth";
      }

      if (can_use_health_check && can_use_heart_and_health) {
        disease = "healthCheck";
      }

      setValue("diseaseGroup", disease);
      setValue("activeOperationHours", can_set_active_operation_hours);
      handleStartTimeAndEndTime(operation_start_at, operation_finish_at);

      const {
        notification_messages,
        remind_send_interval,
        is_remind,
        is_fever_alert,
        is_attendance_confirmation,
      } = health_check_setting.notifications;

      if (notification_messages) {
        setValue("am", notification_messages.am);
        setValue("night", notification_messages.night);
        setValue("pm", notification_messages.pm);
      }

      setValue("reminderInterval", remind_send_interval);
      setValue("useReminder", is_remind);
      setValue("emailSending", is_fever_alert);
      setValue("attendanceConfirm", is_attendance_confirmation);

      const {
        is_health_check,
        is_temperature,
        is_attendance,
        is_family_health_check,
        is_pool,
        is_marathon,
        is_action_check,
        is_mental_check,
        is_attendance_stop_period,
        is_influenza_type,
        is_temperature_on_sick,
        can_use_absent_children_club,
        absent_type,
        is_breakfast,
        can_use_breakfast_comment,
      } = health_check_setting;

      setValue("covidClose", absent_type.covid_close);
      setValue("covidFamilyClose", absent_type.covid_family_close);
      setValue("strongContact", absent_type.mourning);
      setValue("familyContact", absent_type.other);
      setValue("online", absent_type.online);
      setValue("learcation", absent_type.learcation);

      setValue("healthCheck", is_health_check);
      setValue("attendance", is_attendance);
      setValue("pool", is_pool);
      setValue("marathon", is_marathon);
      setValue("mentalCheck", is_mental_check);
      setValue("actionCheck", is_action_check);
      setValue("familyIllness", is_family_health_check);
      setValue("temperatureShow", is_temperature);
      setValue("attendanceStopPeriod", is_attendance_stop_period);
      setValue("influenzaType", is_influenza_type);
      setValue("temperatureSick", is_temperature_on_sick);
      setValue("useAbsentClub", can_use_absent_children_club);
      setValue("breakfast", is_breakfast);
      setValue("breakfastComment", can_use_breakfast_comment);

      const sickGroupValue: any = [];
      const disabledSickGroupValue: any = [];
      const sickGroupArr = usable_absent_reasons.sick_absent_reason;

      sickGroupArr.map((item: any) => {
        if (item.id <= 5) {
          disabledSickGroupValue.push(item.id);
        } else {
          sickGroupValue.push(item.id);
        }
      });

      setValue("sickGroup", sickGroupValue);
      setValue("disabledSickGroup", disabledSickGroupValue);
      setAllDisease(all_absent_reasons.sick_absent_reason);
    }
  }, [getHealthCheckSetting]);

  useEffect(() => {
    if (
      setHealthCheckSetting &&
      setHealthCheckSetting?.status === httpStatus.StatusCreated
    ) {
      notification.success({
        message: t("temperature-setting-apply"),
        description: "",
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      dispatch(SetHealthCheckSettingActionCreators.handleResetAction());
      dispatch(
        GetHealthCheckSettingActionCreators.getHealthCheckSettingAction()
      );
    }
  }, [setHealthCheckSetting]);

  const timeOptions = [
    {
      id: 0,
      value: 5,
      name: t("5m"),
    },
    {
      id: 1,
      value: 15,
      name: t("15m"),
    },
    {
      id: 2,
      value: 30,
      name: t("30m"),
    },
    {
      id: 3,
      value: 45,
      name: t("45m"),
    },
    {
      id: 4,
      value: 60,
      name: t("60m"),
    },
  ];

  const onChangeTime = (record: any, idxRecord: number) => (value: string) => {
    setDataTable(
      dataTable.map((item: any, idx: number) => {
        if (idxRecord === idx) {
          return {
            ...item,
            time_label: value === "PM" ? t("pm") : t("evening"),
            notification_time: "00:00",
            time: value,
          };
        }
        return item;
      })
    );
  };

  const onChangeHour = (record: any, idxRecord: number) => (value: string) => {
    const [, minute] = record.notification_time.split(":");
    const notificationTime = `${value}:${minute}`;

    setDataTable(
      dataTable.map((item: any, idx: number) => {
        if (idxRecord === idx) {
          if (record.time === "AM" && value >= "00" && value <= "03") {
            return {
              ...item,
              notification_time: notificationTime,
              time_label: t("evening"),
            };
          }
          if (record.time === "AM") {
            return {
              ...item,
              notification_time: notificationTime,
              time_label: t("am"),
            };
          }
          if (record.time === "PM" && value === "00") {
            return {
              ...item,
              notification_time: notificationTime,
              time_label: t("pm"),
            };
          }
          if (record.time === "PM" && value >= "00" && value <= "04") {
            return {
              ...item,
              notification_time: notificationTime,
              time_label: t("pm"),
            };
          }
          if (record.time === "PM" && value >= "05" && value <= "11") {
            return {
              ...item,
              notification_time: notificationTime,
              time_label: t("evening"),
            };
          }
          return {
            ...item,
            notification_time: notificationTime,
            time_label: t("pm"),
          };
        }
        return item;
      })
    );
  };

  const onChangeMinute =
    (record: any, idxRecord: number) => (value: string) => {
      const [hour] = record.notification_time.split(":");
      const notificationTime = `${hour}:${value}`;
      setDataTable(
        dataTable.map((item: any, idx: number) => {
          if (idxRecord === idx) {
            return {
              ...item,
              notification_time: notificationTime,
            };
          }
          return item;
        })
      );
    };

  const columns = [
    {
      title: t("delivery-time"),
      dataIndex: "notification_time",
      key: "notification_time",
      render: (text: any, record: any, idx: any) => {
        const [hour, minute] = text.split(":");
        return (
          <TimeSelect>
            <Select
              placeholder={"AM"}
              options={dayTimeOptions}
              onChange={onChangeTime(record, idx)}
              value={record.time}
              background="#ffffff"
            />
            <HourWrap>
              <Select
                placeholder={"08"}
                options={hourOptions}
                onChange={onChangeHour(record, idx)}
                value={hour}
                background="#ffffff"
              />
            </HourWrap>
            <Select
              placeholder={"00"}
              options={minutesOptions}
              onChange={onChangeMinute(record, idx)}
              value={minute}
              background="#ffffff"
            />
          </TimeSelect>
        );
      },
    },
    {
      title: t("classification"),
      dataIndex: "time_label",
      key: "time_label",
      width: 270,
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (text: any, record: any) => {
        return (
          <DeleteIcon
            fill="#FB4E4E"
            onClick={() => {
              setIsVisible(true);
              setNotifyID(record.id);
              setDeleteItem(record);
            }}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
  ];

  const handleAddNotify = () => {
    setDataTable((prevState: any) => [
      ...prevState,
      {
        id: uuid(),
        notification_time: "00:00",
        time_label: t("evening"),
      },
    ]);
  };

  const onSubmit = (data: any) => {
    const newData = dataTable
      .map((item: any) => {
        if (item?.time === "PM") {
          const [hour, minute] = item.notification_time.split(":");
          let newHour: any = Number(hour) + 12;
          if (newHour === 24) {
            newHour = "00";
          }
          const notificationTime = `${newHour}:${minute}`;
          return {
            ...item,
            notification_time: notificationTime,
          };
        }
        return item;
      })
      .map((item: any) => {
        return item.notification_time;
      });

    const startTime = `${data.startHour}:${data.startMinute}`;
    const endTime = `${data.endHour}:${data.endMinute}`;

    const selectedDisease = [...data.sickGroup, ...data.disabledSickGroup];
    const convertedDisease = allDisease.filter((item: any) =>
      selectedDisease.includes(item.id)
    );

    const {
      sick,
      early,
      tardy,
      holiday,
      sick_reason,
      mourning_reason,
      other_reason,
    } = getHealthCheckSetting?.health_check_setting?.absent_type;

    const params = {
      operation_setting: {
        can_use_health_check:
          data.diseaseGroup === "healthCheck" ? true : false,
        can_use_heart_and_health:
          data.diseaseGroup === "heartHealth" ? true : false,
        can_set_active_operation_hours: data.activeOperationHours,
        operation_start_at: data.activeOperationHours ? startTime : "",
        operation_finish_at: data.activeOperationHours ? endTime : "",
      },
      health_check_setting: {
        is_breakfast: data.breakfast,
        can_use_breakfast_comment: data.breakfastComment,
        is_health_check: data.healthCheck,
        is_temperature: data.temperatureShow,
        is_attendance: data.attendance,
        is_family_health_check: data.familyIllness,
        is_pool: data.pool,
        is_marathon: data.marathon,
        is_action_check: data.actionCheck,
        is_mental_check: data.mentalCheck,
        is_influenza_type: data.influenzaType,
        is_attendance_stop_period: data.attendanceStopPeriod,
        is_temperature_on_sick: data.temperatureSick,
        can_use_absent_children_club: data.useAbsentClub,
        absent_type: {
          influenza: false,
          covid_positive: false,
          covid_close: data.covidClose,
          covid_family_close: data.covidFamilyClose,
          mourning: data.strongContact,
          other: data.familyContact,
          online: data.online,
          sick: sick,
          early: early,
          tardy: tardy,
          holiday: holiday,
          sick_reason: sick_reason,
          mourning_reason: mourning_reason,
          other_reason: other_reason,
          learcation: data.learcation,
        },
        notifications: {
          is_remind: data.useReminder,
          remind_send_interval: data.reminderInterval,
          is_fever_alert: data.emailSending,
          is_attendance_confirmation: data.attendanceConfirm,
          temperature_notification_times: newData,
          notification_messages: {
            am: data.am,
            pm: data.pm,
            night: data.night,
          },
        },
      },
      usable_absent_reasons: {
        sick_absent_reason: convertedDisease,
        mourning_absent_reason: [],
        other_absent_reason: [],
      },
    };

    dispatch(
      SetHealthCheckSettingActionCreators.setHealthCheckSettingAction(params)
    );
  };

  const handleCancelDelete = () => {
    setIsVisible(false);
    setDeleteItem(undefined);
  };

  const InfoIcon = (data: any) => (
    <WrapperInforIcon>
      <Tooltip
        placement="right"
        title={
          <TooltipRightStyled style={data.pd ? { padding: "8px" } : {}}>
            <img src={data.url} />
          </TooltipRightStyled>
        }
      >
        <InformationIcon offset="4" />
      </Tooltip>
    </WrapperInforIcon>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <WrapperTemperatureSection>
        <WrapperTitle style={{ padding: "0 0 0 8px" }}>
          <FormTitle style={{ marginRight: 16 }}>
            {t("health-observation-setting")}
          </FormTitle>
          <Controller
            control={control}
            name="diseaseGroup"
            render={({ field: { onChange, value } }) => (
              <Radio.Group onChange={onChange} value={value}>
                <AudioWrapper>
                  <Radio value="healthCheck">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {t("enable-health-monitoring")}
                      <InfoIcon url="images/health-check.png" pd />
                    </div>
                  </Radio>
                  <Radio value="heartHealth">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {t("enable-mental-physical-monitoring")}
                      <InfoIcon url="images/heart-health.png" pd />
                    </div>
                  </Radio>
                </AudioWrapper>
              </Radio.Group>
            )}
          />
        </WrapperTitle>
        <OperatingTimeContainer>
          <Controller
            control={control}
            name="activeOperationHours"
            render={({ field: { onChange, value } }) => (
              <WrapperCheckbox>
                <LastCheckboxStyled checked={value} onChange={onChange}>
                  <CheckItem>{t("set-operating-time")}</CheckItem>
                </LastCheckboxStyled>
              </WrapperCheckbox>
            )}
          />
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              ...disabledStyle1,
            }}
          >
            <Controller
              control={control}
              name="startHour"
              render={({ field: { onChange, value } }) => (
                <Select
                  options={hourOptions2}
                  onChange={onChange}
                  value={value}
                  background="#ffffff"
                  width="60px"
                  height="30px"
                />
              )}
            />

            <Controller
              control={control}
              name="startMinute"
              render={({ field: { onChange, value } }) => (
                <Select
                  options={minuteOptions2}
                  onChange={onChange}
                  value={value}
                  background="#ffffff"
                  width="60px"
                  height="30px"
                />
              )}
            />
            <div>~</div>
            <Controller
              control={control}
              name="endHour"
              render={({ field: { onChange, value } }) => (
                <Select
                  options={endHourOptions}
                  onChange={onChange}
                  value={value}
                  background="#ffffff"
                  width="60px"
                  height="30px"
                />
              )}
            />
            <Controller
              control={control}
              name="endMinute"
              render={({ field: { onChange, value } }) => (
                <Select
                  options={minuteOptions2}
                  onChange={onChange}
                  value={value}
                  background="#ffffff"
                  width="60px"
                  height="30px"
                />
              )}
            />
          </div>
        </OperatingTimeContainer>

        <ItemAdditionalSelectionContainer>
          <Label>{t("item-additional-selection")}</Label>
          <ItemAdditionalSelection>
            <Controller
              control={control}
              name="healthCheck"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("physical-condition")}</CheckItem>
                  </LastCheckboxStyled>
                  <InfoIcon url="images/health-check-1.png" />
                </WrapperCheckbox>
              )}
            />
            <Controller
              control={control}
              name="temperatureShow"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox style={disabledStyle}>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t4("temperature-management-label")}</CheckItem>
                  </LastCheckboxStyled>
                  <InfoIcon url="images/img-temperature-check.png" />
                </WrapperCheckbox>
              )}
            />
            <Controller
              control={control}
              name="attendance"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox style={disabledStyle}>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("attendance-notification")}</CheckItem>
                  </LastCheckboxStyled>
                  <InfoIcon url="images/img-attendance.png" />
                </WrapperCheckbox>
              )}
            />
            <Controller
              control={control}
              name="familyIllness"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox style={disabledStyle}>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("report-illness-family")}</CheckItem>
                  </LastCheckboxStyled>
                  <InfoIcon url="images/img-family-illness.png" />
                </WrapperCheckbox>
              )}
            />
            <Controller
              control={control}
              name="pool"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox style={disabledStyle}>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("pool-medical")}</CheckItem>
                  </LastCheckboxStyled>
                  <InfoIcon url="images/img-pool.png" />
                </WrapperCheckbox>
              )}
            />
            <Controller
              control={control}
              name="marathon"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox style={disabledStyle}>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("marathon-medical")}</CheckItem>
                  </LastCheckboxStyled>
                  <InfoIcon url="images/img-marathon.png" />
                </WrapperCheckbox>
              )}
            />
            <Controller
              control={control}
              name="actionCheck"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox style={disabledStyle}>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("action-check")}</CheckItem>
                  </LastCheckboxStyled>
                  <InfoIcon url="images/img-action-check.png" />
                </WrapperCheckbox>
              )}
            />

            <Controller
              control={control}
              name="mentalCheck"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("mental-check-setting")}</CheckItem>
                  </LastCheckboxStyled>
                  <InfoIcon url="images/img-mental-check.png" />
                </WrapperCheckbox>
              )}
            />

            <Controller
              control={control}
              name="breakfast"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("breakfast")}</CheckItem>
                  </LastCheckboxStyled>
                  <InfoIcon url="images/img-breakfast.png" />
                </WrapperCheckbox>
              )}
            />
          </ItemAdditionalSelection>
        </ItemAdditionalSelectionContainer>

        <NotificationSettingContainer>
          <WrapperAddBtn>
            <Label>{t("temperature-check-setting")}</Label>
            <Button
              icon={<PlusIcon width="12px" height="12px" fill="currentColor" />}
              name={t("add-push-notifications")}
              background="#2AC769"
              type="button"
              color="#FFFFFF"
              border="none"
              fontSize={14}
              fontWeight={500}
              lineHeight="16px"
              padding="4px 7px"
              onClick={handleAddNotify}
            />
          </WrapperAddBtn>
          <TableStyled
            dataSource={dataTable}
            columns={columns}
            scroll={{ x: "max-content" }}
          />
          {!dataTable.length && <NoData>{t("no-data-message")}</NoData>}
          <MessageContainer>
            <MessageTitle>{t("message-title")}</MessageTitle>
            <MessageList>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 12,
                }}
              >
                <MessageListItem>・{t("morning-notification")}</MessageListItem>
                <Controller
                  control={control}
                  name="am"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      fs={14}
                      fwLabel={400}
                      height={31}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 12,
                }}
              >
                <MessageListItem>
                  ・{t("after-noon-notification")}
                </MessageListItem>
                <Controller
                  control={control}
                  name="pm"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      onChange={onChange}
                      value={value}
                      fs={14}
                      fwLabel={400}
                      height={31}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <MessageListItem>・{t("evening-notification")}</MessageListItem>
                <Controller
                  control={control}
                  name="night"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      fs={14}
                      fwLabel={400}
                      height={31}
                    />
                  )}
                />
              </div>
            </MessageList>
          </MessageContainer>
        </NotificationSettingContainer>

        <NotificationSettingContainer>
          <Label>{t("notification-reminder-setting")}</Label>
          <FlexContainer>
            <Controller
              control={control}
              name="useReminder"
              render={({ field: { onChange, value } }) => (
                <CheckboxStyled checked={value} onChange={onChange}>
                  <CheckItem2>{t("use-reminders")}</CheckItem2>
                </CheckboxStyled>
              )}
            />

            <ReminderFlex>
              <ReminderSelect>{t("reminder-interval")}</ReminderSelect>
              <Controller
                control={control}
                name="reminderInterval"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    options={timeOptions}
                    onChange={onChange}
                    width="70px"
                  />
                )}
              />
            </ReminderFlex>
          </FlexContainer>
        </NotificationSettingContainer>

        <div style={disabledStyle}>
          <Container>
            <Label>{t("body-temperature-unwell")}</Label>
            <Controller
              control={control}
              name="temperatureSick"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("indicate")}</CheckItem>
                  </LastCheckboxStyled>
                </WrapperCheckbox>
              )}
            />
          </Container>

          <Container>
            <Label>{t("add-attendance-type")}</Label>
            <Controller
              control={control}
              name="useAbsentClub"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("attendance-message")}</CheckItem>
                  </LastCheckboxStyled>
                </WrapperCheckbox>
              )}
            />
          </Container>

          <ItemAdditionalSelectionContainer>
            <Label>{t("add-absent-type")}</Label>
            <ItemAdditionalSelection>
              <Controller
                control={control}
                name="covidClose"
                render={({ field: { onChange, value } }) => (
                  <WrapperCheckbox>
                    <LastCheckboxStyled checked={value} onChange={onChange}>
                      <CheckItem>{t("strong-contacts")}</CheckItem>
                    </LastCheckboxStyled>
                  </WrapperCheckbox>
                )}
              />
              <Controller
                control={control}
                name="covidFamilyClose"
                render={({ field: { onChange, value } }) => (
                  <WrapperCheckbox>
                    <LastCheckboxStyled checked={value} onChange={onChange}>
                      <CheckItem>{t("family-contact")}</CheckItem>
                    </LastCheckboxStyled>
                  </WrapperCheckbox>
                )}
              />
              <Controller
                control={control}
                name="strongContact"
                render={({ field: { onChange, value } }) => (
                  <WrapperCheckbox>
                    <LastCheckboxStyled checked={value} onChange={onChange}>
                      <CheckItem>{t("mourning")}</CheckItem>
                    </LastCheckboxStyled>
                  </WrapperCheckbox>
                )}
              />
              <Controller
                control={control}
                name="familyContact"
                render={({ field: { onChange, value } }) => (
                  <WrapperCheckbox>
                    <LastCheckboxStyled checked={value} onChange={onChange}>
                      <CheckItem>{t5("question-other")}</CheckItem>
                    </LastCheckboxStyled>
                  </WrapperCheckbox>
                )}
              />
              <Controller
                control={control}
                name="online"
                render={({ field: { onChange, value } }) => (
                  <WrapperCheckbox>
                    <LastCheckboxStyled checked={value} onChange={onChange}>
                      <CheckItem>{t3("online")}</CheckItem>
                    </LastCheckboxStyled>
                  </WrapperCheckbox>
                )}
              />
              <Controller
                control={control}
                name="learcation"
                render={({ field: { onChange, value } }) => (
                  <WrapperCheckbox>
                    <LastCheckboxStyled checked={value} onChange={onChange}>
                      <CheckItem>{t("learcation")}</CheckItem>
                    </LastCheckboxStyled>
                  </WrapperCheckbox>
                )}
              />
            </ItemAdditionalSelection>
          </ItemAdditionalSelectionContainer>

          <Container>
            <Label>{t("suspension-period")}</Label>
            <Controller
              control={control}
              name="attendanceStopPeriod"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("use")}</CheckItem>
                  </LastCheckboxStyled>
                </WrapperCheckbox>
              )}
            />
          </Container>

          <DiseaseContainer>
            <Label>{t("selection-of-disease")}</Label>
            <WrapperDisease>
              <Controller
                control={control}
                name="disabledSickGroup"
                render={({ field: { onChange, value } }) => (
                  <CheckboxGroup
                    list={[
                      {
                        key: 1,
                        value: 1,
                        name: t("cold"),
                      },
                      {
                        key: 2,
                        value: 2,
                        name: t2("influenza"),
                      },
                      {
                        key: 3,
                        value: 3,
                        name: t("new-coronavirus-infectious"),
                      },
                      {
                        key: 4,
                        value: 4,
                        name: t("gastroenteritis"),
                      },
                      {
                        key: 5,
                        value: 5,
                        name: t("respiratory-syncytial-virus-infection"),
                      },
                    ]}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </WrapperDisease>
            <ItemAdditionalSelection>
              <Controller
                control={control}
                name="sickGroup"
                render={({ field: { onChange, value } }) => (
                  <CheckboxGroup
                    list={[
                      {
                        key: 6,
                        value: 6,
                        name: t("streptococcal"),
                      },
                      {
                        key: 7,
                        value: 7,
                        name: t("whooping-cough"),
                      },
                      {
                        key: 8,
                        value: 8,
                        name: t("measles"),
                      },
                      {
                        key: 9,
                        value: 9,
                        name: t("mumps"),
                      },
                      {
                        key: 10,
                        value: 10,
                        name: t("rubella"),
                      },
                      {
                        key: 11,
                        value: 11,
                        name: t("water-fever"),
                      },
                      {
                        key: 12,
                        value: 12,
                        name: t("pharyngeal-conjunctival-fever"),
                      },
                      {
                        key: 13,
                        value: 13,
                        name: t("tuberculosis"),
                      },
                      {
                        key: 14,
                        value: 14,
                        name: t("meningococcal-meningitis"),
                      },
                      {
                        key: 15,
                        value: 15,
                        name: t("cholera"),
                      },
                      {
                        key: 16,
                        value: 16,
                        name: t("shigellosis"),
                      },
                      {
                        key: 17,
                        value: 17,
                        name: t("enterohemorrhagic"),
                      },
                      {
                        key: 18,
                        value: 18,
                        name: t("typhus"),
                      },
                      {
                        key: 19,
                        value: 19,
                        name: t("adeno"),
                      },
                      {
                        key: 20,
                        value: 20,
                        name: t("acute-hemorrhagic-conjunctivitis"),
                      },
                      {
                        key: 21,
                        value: 21,
                        name: t("viral-hepatitis"),
                      },
                      {
                        key: 22,
                        value: 22,
                        name: t("hand-foot-and-mouth-disease"),
                      },
                      {
                        key: 23,
                        value: 23,
                        name: t("apple-disease"),
                      },
                      {
                        key: 24,
                        value: 24,
                        name: t("herpangina"),
                      },
                      {
                        key: 25,
                        value: 25,
                        name: t("mycoplasma-infection"),
                      },
                      {
                        key: 26,
                        value: 26,
                        name: t("head-lice"),
                      },
                      {
                        key: 27,
                        value: 27,
                        name: t("water-wart"),
                      },
                      {
                        key: 28,
                        value: 28,
                        name: t("tobihi"),
                      },
                    ]}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </ItemAdditionalSelection>
          </DiseaseContainer>

          <Container>
            <Label>{t("influenza-type")}</Label>
            <Controller
              control={control}
              name="influenzaType"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("use")}</CheckItem>
                  </LastCheckboxStyled>
                </WrapperCheckbox>
              )}
            />
          </Container>

          <Container>
            <Label>{t("absentee-confirmation-message-sending-function")}</Label>
            <Controller
              control={control}
              name="attendanceConfirm"
              render={({ field: { onChange, value } }) => (
                <WrapperCheckbox>
                  <LastCheckboxStyled checked={value} onChange={onChange}>
                    <CheckItem>{t("use")}</CheckItem>
                  </LastCheckboxStyled>
                </WrapperCheckbox>
              )}
            />
          </Container>

          <EmailSettingContainer>
            <Label>{t("alert-email-sending")}</Label>
            <Controller
              control={control}
              name="emailSending"
              render={({ field: { onChange, value } }) => (
                <LastCheckboxStyled checked={value} onChange={onChange}>
                  <CheckItem>{t("send")}</CheckItem>
                </LastCheckboxStyled>
              )}
            />
          </EmailSettingContainer>
        </div>
        <EmailSettingContainer>
          <Label>{t("use-breakfast-comment")}</Label>
          <Controller
            control={control}
            name="breakfastComment"
            render={({ field: { onChange, value } }) => (
              <LastCheckboxStyled checked={value} onChange={onChange}>
                <CheckItem>{t("indicate")}</CheckItem>
              </LastCheckboxStyled>
            )}
          />
        </EmailSettingContainer>

        <Button
          type="submit"
          name={t("keep")}
          background="#2AC769"
          color="#FFFFFF"
          border="none"
          fontSize={16}
          fontWeight={700}
          lineHeight="23px"
          padding="4px 16px"
          margin="0px"
        />

        {isVisible && (
          <DeleteModal
            isVisible={isVisible}
            handleOk={() => {
              const newDataTable = dataTable.filter(
                (item: any) => item.id !== notifyID
              );

              setDataTable(newDataTable);
              setIsVisible(false);
            }}
            handleCancel={() => handleCancelDelete()}
            deleteItem={deleteItem}
          />
        )}
      </WrapperTemperatureSection>
    </form>
  );
};

export default TemperatureSetting;
