import { ActionType } from "models/setting/getHealthCheckSetting";

export const getHealthCheckSettingAction = () => {
  return {
    type: ActionType.GET_HEALTH_CHECK_SETTING,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_HEALTH_CHECK_SETTING_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: any) => {
  return {
    type: ActionType.GET_HEALTH_CHECK_SETTING_SUCCESS,
    payload,
  };
};
