import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  GetTimeSlotData,
  ActionType,
  TimeSlotDataResponse,
} from "models/vaccine/timeSlot";
import { httpStatus } from "configs/httpStatus";
import { fetchTimeSlotData } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./timeSlotActions";

function* slotsSaga({ payload }: { payload?: GetTimeSlotData }) {
  try {
    const response: TimeSlotDataResponse = yield call(
      fetchTimeSlotData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetSlotsWatcher() {
  yield takeLatest(ActionType.TIME_SLOT as any, slotsSaga);
}

export default [fork(onGetSlotsWatcher)];
