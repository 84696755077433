import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  TemperatureAvgResponse,
  ActionType,
  TemperatureAvgPayload,
} from "models/dashboard/temperatureAvg";
import { httpStatus } from "configs/httpStatus";
import { fetchTemperatureAvgChart } from "services/dashboard";
import { handleSuccesAction, handleErrorAction } from "./temperatureAvgActions";

function* getTemperatureAvgSaga({
  payload,
}: {
  payload: TemperatureAvgPayload;
}) {
  try {
    const response: TemperatureAvgResponse = yield call(
      fetchTemperatureAvgChart,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetTemperatureAvgWatcher() {
  yield takeLatest(
    ActionType.GET_TEMPERATURE_AVG_CHART as any,
    getTemperatureAvgSaga
  );
}

export default [fork(onGetTemperatureAvgWatcher)];
