import * as React from "react";

function MenuIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
        fill="#CCCCCC"
      />
      <path d="M6 7H34" stroke="white" strokeWidth="2" />
      <path d="M6 13H34" stroke="white" strokeWidth="2" />
      <path d="M6 19H34" stroke="white" strokeWidth="2" />
      <path
        d="M5.64922 35H6.65722V30.128C6.65722 29.372 6.57322 28.304 6.52522 27.536H6.57322L7.26922 29.54L8.93722 34.112H9.68122L11.3492 29.54L12.0452 27.536H12.0932C12.0332 28.304 11.9492 29.372 11.9492 30.128V35H12.9932V26.192H11.6492L9.96922 30.908C9.76522 31.52 9.57322 32.132 9.36922 32.744H9.30922C9.09322 32.132 8.88922 31.52 8.68522 30.908L6.99322 26.192H5.64922V35ZM14.3875 35H19.5955V34.052H15.5035V30.848H18.8395V29.9H15.5035V27.14H19.4635V26.192H14.3875V35ZM20.4422 35H21.4982V30.38C21.4982 29.456 21.4142 28.52 21.3662 27.632H21.4142L22.3622 29.444L25.5662 35H26.7062V26.192H25.6502V30.776C25.6502 31.688 25.7342 32.672 25.7942 33.56H25.7342L24.7982 31.748L21.5942 26.192H20.4422V35ZM31.2223 35.156C33.0223 35.156 34.3903 34.196 34.3903 31.364V26.192H33.3223V31.4C33.3223 33.524 32.3983 34.184 31.2223 34.184C30.0823 34.184 29.1823 33.524 29.1823 31.4V26.192H28.0663V31.364C28.0663 34.196 29.4343 35.156 31.2223 35.156Z"
        fill="white"
      />
    </svg>
  );
}

export default MenuIcon;
