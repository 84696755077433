import {
  ActionType,
  IPushNotificationPayload,
  IPushNotificationResponse,
} from "models/messagemanagement/pushNotification";

export const pushNotificationAction = (payload: IPushNotificationPayload) => {
  return {
    type: ActionType.PUSH_NOTIFICATION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.PUSH_NOTIFICATION_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IPushNotificationResponse) => {
  return {
    type: ActionType.PUSH_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.PUSH_NOTIFICATION_RESET,
  };
};
