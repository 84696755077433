import React from "react";
import { Table } from "antd";
import styled from "styled-components";

const TableStyled = styled(Table)(({ theme, rowSelection, dataSource }) => ({
  ".ant-table-thead": {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
  },
  ".ant-table-thead > tr > th": {
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.sizes.md,
    lineHeight: "18px",
    color: theme.colors.text.primary,
    padding: "17px 12px 17px",
    background: "#FBFBFB",
    "&:before": {
      width: "2px!important",
      backgroundColor: `${theme.colors.background}!important`,
    },
  },
  ".ant-table-thead > tr > th:first-child": {
    background: "#FBFBFB",
    "&:before": {
      height: rowSelection ? "" : "100%!important",
    },
  },
  ".ant-table-thead > tr > th:nth-child(2)": {
    "&:before": {
      height: rowSelection ? "100%!important" : "",
    },
  },
  ".ant-table-tbody": {
    display: dataSource?.length ? "contents" : "none",
  },
  ".ant-table-tbody > tr > td": {
    fontSize: theme.sizes.md,
    fontWeight: theme.fontWeight.regular,
    lineHeight: "24px",
    padding: "9px 11px",
  },
  ".ant-table-tbody > tr > td:first-child": {
    borderRight: rowSelection ? "" : `2px solid ${theme.colors.background}`,
  },
  ".ant-table-tbody > tr > td:nth-child(2)": {
    borderRight: rowSelection ? `2px solid ${theme.colors.background}` : "",
  },
  ".ant-table-tbody > tr:nth-child(even) td": {
    background: theme.colors.white,
  },
  ".ant-table-tbody > tr:nth-child(odd) td": {
    background: "#f3f8fc",
  },
  ".ant-table-column-sorter-up, .ant-table-column-sorter-down": {
    fontSize: theme.sizes.sm,
  },
  ".ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active": {
    color: theme.colors.important,
  },
  "&:hover": {
    ".ant-checkbox-inner": {
      borderColor: theme.colors.border,
    },
  },
  ".ant-checkbox-inner": {
    width: 20,
    height: 20,
    border: `2px solid ${theme.colors.border}`,
  },
  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.colors.button,
    borderColor: `${theme.colors.button}!important`,
  },
  ".ant-checkbox-checked::after": {
    border: "none",
  },
  ".ant-checkbox-input:focus + .ant-checkbox-inner": {
    borderColor: theme.colors.border,
  },
  ".ant-table-cell-fix-left": {
    background: "transparent",
  },
  ".ant-table-body": {
    "::-webkit-scrollbar": {
      width: 16,
      height: 8,
    },
    "::-webkit-scrollbar-track": {
      margin: "16px 0px 13px",
      borderLeft: "4px solid #FFFFFF",
      borderRight: "4px solid #FFFFFF",
      background: "#F2F2F2",
      borderRadius: 7,
    },
    "::-webkit-scrollbar-thumb": {
      background: "#BDBDBD",
      borderRadius: 7,
      borderLeft: "4px solid #FFFFFF",
      borderRight: "4px solid #FFFFFF",
    },
  },
}));

const ActionSection = styled.div(({ theme }) => ({
  background: theme.colors.background,
  borderRadius: "8px 8px 0px 0px",
}));

const TableCustom = ({ actionSection, subSection, ...rest }: any) => {
  return (
    <>
      {actionSection && <ActionSection>{actionSection}</ActionSection>}
      {subSection && <div>{subSection}</div>}
      <TableStyled {...rest} pagination={false} showSorterTooltip={false} />
    </>
  );
};

export default TableCustom;
