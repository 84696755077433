import {
  ActionType,
  DepartmentListResponse,
  DepartmentListData,
} from "../../models/departmentList";

export const departmentListAction = (payload: DepartmentListData) => {
  return {
    type: ActionType.DEPARTMENT_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DEPARTMENT_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DepartmentListResponse) => {
  return {
    type: ActionType.DEPARTMENT_LIST_SUCCESS,
    payload,
  };
};
