import {
  Action,
  ActionType,
  IDeleteVenuesResponse,
} from "models/vaccine/deleteVenues";
import createReducer from "../../../createReducer";

export interface DeleteVenuesReducerType {
  loading: boolean;
  error?: string;
  deleteVenuesResponse?: IDeleteVenuesResponse;
}

const defaultState: DeleteVenuesReducerType = {
  loading: false,
  error: undefined,
  deleteVenuesResponse: undefined,
};

export const deleteVenuesReducer = createReducer<DeleteVenuesReducerType>(
  defaultState,
  {
    [ActionType.VACCINE_DELETE_VENUES](state: DeleteVenuesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.VACCINE_DELETE_VENUES_ERROR](
      state: DeleteVenuesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.VACCINE_DELETE_VENUES_SUCCESS](
      state: DeleteVenuesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteVenuesResponse: action.payload,
      };
    },

    [ActionType.VACCINE_DELETE_VENUES_RESET](state: DeleteVenuesReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteVenuesResponse: undefined,
      };
    },
  }
);
