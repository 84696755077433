import React from "react";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";

import { Select, Input, TextArea, Button } from "components";
import {
  CopyIcon,
  TrashIcon,
  PlusIcon,
  DescriptiveIcon,
  RadioIcon,
  CheckboxIcon,
  MultiLineIcon,
  PullDownIcon,
  CircleNoBackgroundIcon,
  XIcon,
  RectangleIcon,
} from "icons";
import { uuid } from "utils";
import { WrapperErrorMessage, ErrorMessage } from "theme/CommonStyle";

import {
  IQuestionnaire,
  QuestionnairesQuestion,
} from "models/questionnaires/questionnairesDetail";

import {
  TextAreaStyled,
  WrapperIconItemQuestion,
  WrapperPlus,
  WrapperIconSelect,
  WrapperAdd,
  WrapperTitleInputTextarea,
  WrapperQuestion,
  QuestionBodyLeft,
  QuestionBodyRight,
  WrapperItem,
  QuestionDraftBody,
  QuestionDraftTitle,
  WrapperDraft,
} from "./Questions.style";
import { CheckboxStyled } from "../userList/UserList.style";

const { useBreakpoint } = Grid;

const iconList: any = {
  radio: <CircleNoBackgroundIcon fill="#CCCCCC" />,
  checkbox: <RectangleIcon fill="#CCCCCC" />,
};

interface IAnswer {
  id: number;
  answer_text: string;
  _destroy?: boolean;
  is_other?: boolean;
}

export interface IQuestionnaires {
  answerType: string;
  answerTitle: string;
  answerFreetext: string;
  isRequired: boolean;
  isFreeText: boolean;
  answersArr: IAnswer[];
}

interface IQuestion {
  question: QuestionnairesQuestion;
  index: number;
  setQuestionnaires: React.Dispatch<React.SetStateAction<IQuestionnaire>>;
  error: any;
  setError: any;
}

interface IItemByType {
  type: string;
  idxQuestion: number;
  setQuestionnaires: React.Dispatch<React.SetStateAction<IQuestionnaire>>;
  answer: IAnswer;
  error: any;
  setError: any;
}

const ItemByType: React.FC<IItemByType> = ({
  type,
  idxQuestion,
  setQuestionnaires,
  answer,
  error,
  setError,
}) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });

  const handleOnChangeAnswer =
    (answerID: number, idx: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuestionnaires((prevState) => ({
        ...prevState,
        questionnaire_questions: (prevState.questionnaire_questions || []).map(
          (question, index) => {
            if (idx === index) {
              return {
                ...question,
                questionnaire_answers: question.questionnaire_answers.map(
                  (a: any) => {
                    if (a.id === answerID) {
                      return {
                        ...a,
                        answer_text: e.target.value,
                      };
                    }
                    return a;
                  }
                ),
              };
            }
            return question;
          }
        ),
      }));

      if (error) {
        setError((prevState: any) => ({
          ...prevState,
          questionnaire_questions: prevState.questionnaire_questions.map(
            (question: any, index: number) => {
              if (idx === index) {
                return {
                  ...question,
                  questionnaire_answers: question.questionnaire_answers.map(
                    (a: any) => {
                      if (a.id === error.id) {
                        return {
                          ...a,
                          is_active: true,
                          answer_text: e.target.value.length
                            ? ""
                            : t("enter-answer"),
                        };
                      }
                      return a;
                    }
                  ),
                };
              }
              return question;
            }
          ),
        }));
      }
    };

  const handleDelete = (answerID: number, idxQues: number, errorID: any) => {
    setQuestionnaires((prevState) => ({
      ...prevState,
      questionnaire_questions: (prevState.questionnaire_questions || []).map(
        (question, index) => {
          if (idxQues === index) {
            return {
              ...question,
              questionnaire_answers: question.questionnaire_answers.map((a) => {
                if (a.id === answerID) {
                  return {
                    ...a,
                    _destroy: true,
                  };
                }
                return a;
              }),
            };
          }
          return question;
        }
      ),
    }));

    setError((prevState: any) => ({
      ...prevState,
      questionnaire_questions: (prevState.questionnaire_questions || []).map(
        (question: any, index: number) => {
          if (idxQues === index) {
            return {
              ...question,
              questionnaire_answers: question.questionnaire_answers.filter(
                (ans: any) => ans.id !== errorID
              ),
            };
          }
          return question;
        }
      ),
    }));
  };

  const handleRenderIcons = (type: string) => {
    if (type === "pulldown") return "";

    return iconList[type];
  };

  return (
    <WrapperItem>
      {handleRenderIcons(type)}
      <Input
        value={!answer?._destroy ? answer.answer_text : ""}
        height={31}
        fsLabel={16}
        onChange={handleOnChangeAnswer(answer.id, idxQuestion)}
        disabled={answer.is_other}
        error={error?.is_active && error?.answer_text ? error?.answer_text : ""}
      />
      <XIcon
        onClick={() => {
          handleDelete(answer.id, idxQuestion, error?.id);
        }}
        fill="#FB4E4E"
      />
    </WrapperItem>
  );
};

const Question: React.FC<IQuestion> = ({
  question,
  index,
  setQuestionnaires,
  error,
  setError,
}) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });

  const screens = useBreakpoint();

  const optionQuestions = [
    {
      id: 0,
      value: "text",
      name: (
        <WrapperIconSelect>
          <DescriptiveIcon fill="#cccccc" />
          <span>{t("descriptive-formula")}</span>
        </WrapperIconSelect>
      ),
    },
    {
      id: 1,
      value: "multiLine",
      name: (
        <WrapperIconSelect>
          <MultiLineIcon fill="#cccccc" />
          <span>{t("multi-line-descriptive-expression")}</span>
        </WrapperIconSelect>
      ),
    },
    {
      id: 2,
      value: "radio",
      name: (
        <WrapperIconSelect>
          <RadioIcon fill="#cccccc" />
          <span>{t("radio-button")}</span>
        </WrapperIconSelect>
      ),
    },
    {
      id: 3,
      value: "checkbox",
      name: (
        <WrapperIconSelect>
          <CheckboxIcon fill="#cccccc" />
          <span>{t("checkbox")}</span>
        </WrapperIconSelect>
      ),
    },
    {
      id: 4,
      value: "pulldown",
      name: (
        <WrapperIconSelect>
          <PullDownIcon fill="#cccccc" />
          <span>{t("pull-down")}</span>
        </WrapperIconSelect>
      ),
    },
  ];

  const handleSortAnswerQuestion = (questionArr?: any) => {
    const clone = [...questionArr];
    return clone.sort(
      (a: any, b: any) => Number(a.is_other) - Number(b.is_other)
    );
  };

  const handleAddChoices = (index: number, other?: string) => {
    setQuestionnaires((prevState: any) => ({
      ...prevState,
      questionnaire_questions: prevState.questionnaire_questions.map(
        (question: any, idx: number) => {
          if (idx === index) {
            if (other) {
              return {
                ...question,
                questionnaire_answers: handleSortAnswerQuestion([
                  ...question.questionnaire_answers,
                  {
                    id: uuid(),
                    answer_text: t("question-other"),
                    is_other: true,
                  },
                ]),
              };
            }
            return {
              ...question,
              questionnaire_answers: handleSortAnswerQuestion([
                ...question.questionnaire_answers,
                {
                  id: uuid(),
                  answer_text: "",
                  is_other: false,
                },
              ]),
            };
          }
          return question;
        }
      ),
    }));

    setError((prevState: any) => ({
      ...prevState,
      questionnaire_questions: prevState.questionnaire_questions.map(
        (question: any, idx: number) => {
          if (idx === index) {
            if (other) {
              return {
                ...question,
                questionnaire_answers: [
                  ...question.questionnaire_answers,
                  {
                    id: uuid(),
                    is_active: false,
                    answer_text: "",
                    is_other: true,
                  },
                ],
              };
            }
            return {
              ...question,
              questionnaire_answers: [
                ...question.questionnaire_answers,
                {
                  id: uuid(),
                  is_active: false,
                  answer_text: t("enter-answer"),
                  is_other: false,
                },
              ],
            };
          }
          return question;
        }
      ),
    }));
  };

  const handleOnChange =
    (index: number, name: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuestionnaires((prevState: any) => ({
        ...prevState,
        questionnaire_questions: prevState.questionnaire_questions.map(
          (question: any, idx: number) => {
            if (idx === index) {
              return {
                ...question,
                [name]: e.target.value,
              };
            }
            return question;
          }
        ),
      }));

      setError((prevState: any) => ({
        ...prevState,
        questionnaire_questions: prevState.questionnaire_questions.map(
          (question: any, idx: number) => {
            if (idx === index) {
              return {
                ...question,
                [name]: e.target.value.length ? "" : t("enter-answer"),
              };
            }
            return question;
          }
        ),
      }));
    };

  const handleOnChangeQuestionType = (index: number) => (value: string) => {
    setQuestionnaires((prevState: any) => ({
      ...prevState,
      questionnaire_questions: prevState.questionnaire_questions.map(
        (question: any, idx: number) => {
          if (idx === index) {
            return {
              ...question,
              answer_type: value,
              questionnaire_answers: question.questionnaire_answers.map(
                (a: any) => {
                  return {
                    ...a,
                    _destroy: true,
                  };
                }
              ),
            };
          }
          return question;
        }
      ),
    }));

    setError((prevState: any) => ({
      ...prevState,
      questionnaire_questions: prevState.questionnaire_questions.map(
        (question: any, idx: number) => {
          if (idx === index) {
            return {
              ...question,
              answer_type: value ? "" : t("enter-answer"),
              questionnaire_answers: [],
            };
          }
          return question;
        }
      ),
    }));
  };

  const handleCheckbox = (index: number, type: number) => (e: any) => {
    const text = type === 1 ? "is_required" : "is_free_text";

    setQuestionnaires((prevState) => ({
      ...prevState,
      questionnaire_questions: (prevState.questionnaire_questions || []).map(
        (question, idx) => {
          if (idx === index) {
            if (text === "is_free_text" && !e.target.checked) {
              return {
                ...question,
                question_summary: "",
                [text]: e.target.checked,
              };
            }
            return {
              ...question,
              [text]: e.target.checked,
            };
          }
          return question;
        }
      ),
    }));
  };

  const handleCopyQuestion = (
    item: QuestionnairesQuestion,
    idxQuestion: number
  ) => {
    setQuestionnaires((prevState: any) => ({
      ...prevState,
      questionnaire_questions: [
        ...prevState.questionnaire_questions,
        {
          id: uuid(),
          answer_type: item.answer_type,
          question_content: item.question_content,
          question_summary: item.question_summary,
          is_required: item.is_required,
          is_free_text: item.is_free_text,
          questionnaire_answers: item.questionnaire_answers,
          copy_idx:
            "-copy" +
            (prevState.questionnaire_questions.filter((i: any) => i.copy_idx)
              .length +
              1),
        },
      ],
    }));
    setError((prevState: any) => ({
      ...prevState,
      questionnaire_questions: [
        ...prevState.questionnaire_questions,
        {
          id: uuid(),
          questionnaire_answers:
            prevState.questionnaire_questions[idxQuestion]
              .questionnaire_answers,
        },
      ],
    }));
  };

  const handleDeleteQuestion = (item: QuestionnairesQuestion) => {
    setQuestionnaires((prevState) => ({
      ...prevState,
      questionnaire_questions: (prevState.questionnaire_questions || []).map(
        (question) => {
          if (question.id === item.id) {
            return {
              ...question,
              _destroy: true,
            };
          }
          return question;
        }
      ),
    }));
  };

  const handleCheckIsOtherExist = (arr: any, key: string) => {
    if (!arr.length) return false;

    const clone = handleSortAnswerQuestion(arr);
    const lastItem = clone[clone.length - 1];

    return lastItem[key];
  };

  const handleRenderItem = (answerType: string) => {
    return (
      <React.Fragment>
        {question.questionnaire_answers.length > 0 &&
          question.questionnaire_answers
            .filter((item) => !item._destroy)
            .map((answer, idx) => {
              return (
                <ItemByType
                  type={answerType}
                  key={answer.id}
                  idxQuestion={index}
                  answer={answer}
                  setQuestionnaires={setQuestionnaires}
                  error={error?.questionnaire_answers[idx]}
                  setError={setError}
                />
              );
            })}

        {question.answer_type && !error?.questionnaire_answers.length && (
          <div
            style={{
              color: "#FB4E4E",
              fontSize: 16,
            }}
          >
            {t("please-add-choice")}
          </div>
        )}

        {answerType && (
          <WrapperAdd>
            <div
              onClick={() => {
                handleAddChoices(index);
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <PlusIcon
                fill="#2AC769"
                style={{
                  width: "16px",
                  height: "22px",
                  marginRight: "5px",
                }}
              />
              <span className="span-green">{t("add-choices")}</span>
            </div>
            {answerType !== "pulldown" && (
              <div>
                <span className="span-or">{t("or")}</span>
                <span
                  className={
                    !handleCheckIsOtherExist(
                      question.questionnaire_answers,
                      "is_other"
                    ) ||
                    handleCheckIsOtherExist(
                      question.questionnaire_answers,
                      "_destroy"
                    )
                      ? "span-green"
                      : "disabled"
                  }
                  onClick={() => {
                    if (
                      !handleCheckIsOtherExist(
                        question.questionnaire_answers,
                        "is_other"
                      ) ||
                      handleCheckIsOtherExist(
                        question.questionnaire_answers,
                        "_destroy"
                      )
                    ) {
                      handleAddChoices(index, "other");
                    }
                  }}
                >
                  {t("added-other")}
                </span>
              </div>
            )}
          </WrapperAdd>
        )}
      </React.Fragment>
    );
  };

  return (
    <WrapperQuestion>
      <QuestionDraftTitle>
        {t("question") + `${index + 1}` + (question?.copy_idx || "")}
      </QuestionDraftTitle>

      <QuestionDraftBody>
        <QuestionBodyLeft>
          <Input
            name="hello"
            value={question?.question_content}
            height={40}
            fsLabel={16}
            placeholder={t("please-select-type")}
            onChange={handleOnChange(index, "question_content")}
            marginForm="0px 0px 24px"
            error={error?.question_content || ""}
          />
          {question.is_free_text && (
            <TextArea
              value={question.question_summary}
              height="80px"
              fsLabel={16}
              marginForm="0px 0px 18px"
              onChange={handleOnChange(index, "question_summary")}
              error={error?.question_summary || ""}
            />
          )}
          <Select
            onChange={handleOnChangeQuestionType(index)}
            options={optionQuestions}
            height="40px"
            width={screens.xs ? "100%" : "214px"}
            marginForm="0px 0px 24px"
            placeholder={t1("please-select")}
            value={question.answer_type}
            error={error?.answer_type || ""}
          />

          {question.answer_type == "text" && (
            <>
              <Input
                value={
                  !question.questionnaire_answers[0]?._destroy
                    ? question.questionnaire_answers[0]?.answer_text
                    : ""
                }
                height={31}
                fsLabel={16}
                marginForm="0 0 8px"
                disabled
              />
              <WrapperTitleInputTextarea>
                {t("text-description-formula")}
              </WrapperTitleInputTextarea>
            </>
          )}

          {question.answer_type == "multiLine" && (
            <>
              <TextArea
                value={
                  !question.questionnaire_answers[0]?._destroy
                    ? question.questionnaire_answers[0]?.answer_text
                    : ""
                }
                height={"120px"}
                fsLabel={16}
                disabled
              />
              <WrapperTitleInputTextarea>
                {t("text-description-formula-textarea")}
              </WrapperTitleInputTextarea>
            </>
          )}

          {question.answer_type !== "text" &&
            question.answer_type !== "multiLine" &&
            handleRenderItem(question.answer_type)}
        </QuestionBodyLeft>

        <QuestionBodyRight>
          <CheckboxStyled
            checked={question.is_required}
            onChange={handleCheckbox(index, 1)}
          >
            <p className="p-title-question">{t("make-it-mandatory")}</p>
          </CheckboxStyled>

          <CheckboxStyled
            checked={question.is_free_text}
            onChange={handleCheckbox(index, 3)}
          >
            <p className="p-title-question">{t("enter-a-description")}</p>
          </CheckboxStyled>

          <WrapperIconItemQuestion
            onClick={() => {
              handleCopyQuestion(question, index);
            }}
          >
            <CopyIcon fill="#3B3B3B" style={{ width: "24px" }} />
            <div>{t("copy-item")}</div>
          </WrapperIconItemQuestion>

          <WrapperIconItemQuestion
            style={screens.xs ? { marginLeft: 37 } : {}}
            onClick={() => {
              handleDeleteQuestion(question);
            }}
          >
            <TrashIcon
              fill="#FB4E4E"
              style={{
                marginRight: "8px",
              }}
            />
            <div style={{ color: "#FB4E4E" }}>{t2("delete")}</div>
          </WrapperIconItemQuestion>
        </QuestionBodyRight>
      </QuestionDraftBody>
    </WrapperQuestion>
  );
};

const QuestionDraft = ({
  questionnaires,
  setQuestionnaires,
  handleSaveDraft,
  isLoading,
  error,
  setError,
  status,
}: {
  questionnaires: IQuestionnaire;
  setQuestionnaires: React.Dispatch<React.SetStateAction<IQuestionnaire>>;
  handleSaveDraft: () => void;
  isLoading: boolean;
  status: string;
  error: any;
  setError: React.Dispatch<
    React.SetStateAction<{
      title: string;
      explanation: string;
      questions: string;
    }>
  >;
}) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });

  const createQuestion = () => {
    setQuestionnaires((prevState: any) => ({
      ...prevState,
      questionnaire_questions: [
        ...(prevState.questionnaire_questions || []),
        {
          id: uuid(),
          answer_type: "",
          question_content: "",
          question_summary: "",
          is_required: false,
          is_free_text: false,
          questionnaire_answers: [],
        },
      ],
    }));
    setError((prevState: any) => ({
      ...prevState,
      questions: "",
      questionnaire_questions: [
        ...prevState.questionnaire_questions,
        {
          id: uuid(),
          questionnaire_answers: [],
        },
      ],
    }));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setQuestionnaires((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setError((prevState: any) => ({
      ...prevState,
      [name]: "",
    }));
  };

  return (
    <React.Fragment>
      <WrapperDraft>
        <Input
          name="title"
          value={questionnaires?.title || ""}
          label={
            <div style={{ fontWeight: 700 }}>{t("questionnaire-name")}</div>
          }
          height={31}
          fsLabel={16}
          marginForm="0px 0px 24px"
          onChange={handleOnChange}
          error={error?.title ? t(error?.title) : ""}
        />
        <TextAreaStyled
          name="explanation"
          value={questionnaires?.explanation || ""}
          label={
            <div style={{ fontWeight: 700 }}>
              {t("questionnaire-explanation")}
            </div>
          }
          placeholder={t("please-enter-a-description-of-the-survey")}
          marginForm="0px 0px 18px"
          fsLabel={16}
          lhLabel="24px"
          bdr="6px"
          onChange={handleOnChange}
          error={error?.explanation ? t(error?.explanation) : ""}
        />
        <div>
          <Button
            type="submit"
            name={t(status !== "create" ? "overwrite-save" : "save-survey")}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            padding="7px 24px"
            disabled={isLoading}
            onClick={() => {
              handleSaveDraft();
            }}
          />
        </div>
      </WrapperDraft>

      {(questionnaires?.questionnaire_questions || []).map(
        (question, index) => {
          if (question._destroy) return;
          return (
            <Question
              key={index}
              index={index}
              question={question}
              setQuestionnaires={setQuestionnaires}
              error={error.questionnaire_questions[index]}
              setError={setError}
            />
          );
        }
      )}

      <WrapperPlus>
        <div onClick={createQuestion} style={{ position: "relative" }}>
          <PlusIcon style={{ width: "28px", height: "28px" }} fill="#ffffff" />
          {error?.questions && (
            <WrapperErrorMessage>
              <ErrorMessage style={{ width: "max-content" }}>
                {t(error?.questions)}
              </ErrorMessage>
            </WrapperErrorMessage>
          )}
        </div>
      </WrapperPlus>
    </React.Fragment>
  );
};

export default QuestionDraft;
