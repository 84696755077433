import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType, IDeleteVenuesResponse } from "models/vaccine/deleteVenues";
import { httpStatus } from "configs/httpStatus";
import { deleteVenues } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./deleteVenuesActions";

function* deleteVenuesSaga({ payload }: { payload: { id: number } }) {
  try {
    const response: IDeleteVenuesResponse = yield call(deleteVenues, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDeleteVenuesWatcher() {
  yield takeLatest(ActionType.VACCINE_DELETE_VENUES as any, deleteVenuesSaga);
}

export default [fork(onDeleteVenuesWatcher)];
