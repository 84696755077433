import React, { useState, useEffect } from "react";
import { Grid, notification } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { BackIcon } from "icons";
import { Button, ScrollToTop } from "components";
import { localStorageHelper, handleConvertDateYear } from "utils";
import ConfirmModal from "screens/changeStudentAffiliation/ConfirmModal";

import { Details } from "models/mentalHealth/mentalCheckDetails";
import {
  MentalCheckDetailsActionCreators,
  GradeListActionCreators,
} from "redux/rootActions";
import { mentalCheckDetailsDataResponse } from "redux/mentalHealth/checkDetails/checkDetailsStates";

import {
  ItemsContainer,
  LeftItem,
  TextTitle,
  TitleWrapper,
  Dot,
  MentalHealthStatus,
  DetailsTitleWrapper,
  Tooltip4AlertMobile,
  TitleContainerCustom,
  Tooltip4AlertTabletDesktop,
} from "./MentalHealth.style";
import { MentalHealthEdit } from "./MentalHealthEdit";

const { useBreakpoint } = Grid;

interface IMentalHealthDetails {
  id: any;
  handleCancel: () => void;
}

interface IMentalHealthStyles {
  alert: string;
  background: string;
  color: string;
  borderColor: string;
  status: string;
}

const MentalHealthDetails: React.FC<IMentalHealthDetails> = ({
  id,
  handleCancel,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "mental-health",
  });
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const mentalCheckDetailsData = useSelector(mentalCheckDetailsDataResponse);
  const [editing, setEditing] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [detailsData, setDetailsData] = useState<Details>({
    color_code: "",
    grade: "",
    test_name: "",
    status: "",
    examination_period: "",
    mental_check_type_id: 0,
    id: id,
  });
  const [isVisibleTooltip, setIsVisibleTooltip] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [stressStyles, setMentalHealthStyles] = useState<IMentalHealthStyles>({
    alert: "",
    background: "",
    color: "",
    borderColor: "",
    status: "",
  });
  const displayCondition = detailsData.status === "実施待ち";

  const handleToggleTooltip = () => {
    setIsVisibleTooltip(!isVisibleTooltip);
  };

  const handleBackIcon = () => {
    if (editing) {
      setShowModal(true);
    } else {
      handleCancel();
    }
  };

  const returnAlertStyles = (status: any) => {
    switch (status) {
      case "実施完了":
        setMentalHealthStyles({
          alert: t("mental-health-observation-completed"),
          background: "#F2F2F2",
          color: "#525252",
          borderColor: "#666666",
          status: "completed",
        });
        break;
      case "実施待ち":
        setMentalHealthStyles({
          alert:
            handleConvertDateYear(
              moment(startDate).format("YYYY-MM-DD"),
              currentLanguage
            ) + t("mental-health-observation-will-be-implemented"),
          background: "rgba(235, 195, 0, 0.15)",
          color: "#997F00",
          borderColor: "#EBC300",
          status: "waiting-implementation",
        });
        break;
      case "実施中":
        setMentalHealthStyles({
          alert: t("mental-health-observation-in-progress"),
          background: "rgba(42, 199, 105, 0.15)",
          color: "#1C8345",
          borderColor: "#2AC769",
          status: "in-progress",
        });
        break;
    }
  };

  useEffect(() => {
    if (mentalCheckDetailsData && mentalCheckDetailsData?.result) {
      const {
        test_name,
        grade,
        examination_period,
        status,
        color_code,
        mental_check_type_id,
        id,
      } = mentalCheckDetailsData?.result;
      setDetailsData({
        color_code: color_code,
        grade: grade,
        test_name: test_name,
        status: status,
        examination_period: examination_period,
        mental_check_type_id: mental_check_type_id,
        id: id,
      });
      const examinationPeriod: string[] = examination_period.split("〜");
      if (examinationPeriod.length) {
        setStartDate(moment(examinationPeriod[0]).toDate());
      }
    }
  }, [mentalCheckDetailsData]);

  useEffect(() => {
    if (id) {
      dispatch(
        MentalCheckDetailsActionCreators.mentalCheckDetailsAction({
          id: id,
          app_language_id: currentLanguage === "ja" ? 2 : 1,
        })
      );
    }
  }, [id]);

  useEffect(() => {
    if (detailsData) {
      returnAlertStyles(detailsData.status);
    }
  }, [detailsData]);

  useEffect(() => {
    if (editing) {
      dispatch(GradeListActionCreators.gradeListAction());
    }
  }, [editing]);

  useEffect(() => {
    setEditing(false);
    setShowModal(false);
    dispatch(MentalCheckDetailsActionCreators.handleResetDataAction());
  }, []);

  return (
    <>
      <ScrollToTop />
      <DetailsTitleWrapper
        style={{
          marginBottom: editing ? 24 : 16,
        }}
      >
        <div
          style={{
            fontSize: 16,
            lineHeight: "23px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 8,
          }}
        >
          <BackIcon style={{ cursor: "pointer" }} onClick={handleBackIcon} />
          {t("implementation-details")}
        </div>
        {!editing &&
          (screens.md ? (
            <div
              className="alert"
              style={{
                border: `2px solid ${stressStyles.borderColor}`,
                background: stressStyles.background,
                color: stressStyles.color,
              }}
            >
              <div>{stressStyles.alert}</div>
            </div>
          ) : (
            <div style={{ position: "relative" }}>
              {isVisibleTooltip && (
                <Tooltip4AlertMobile>
                  <div>{stressStyles.alert}</div>
                </Tooltip4AlertMobile>
              )}
              <MentalHealthStatus
                style={{
                  background: "#FFFFFF",
                }}
                onClick={handleToggleTooltip}
              >
                <Dot
                  style={{
                    background: stressStyles.borderColor,
                    width: "14px",
                    height: "14px",
                  }}
                />
                <div>{t(stressStyles.status)}</div>
              </MentalHealthStatus>
            </div>
          ))}
      </DetailsTitleWrapper>
      <div
        style={{
          padding: "16px 16px 32px",
          background: "#FFFFFF",
          borderRadius: 8,
        }}
      >
        {editing ? (
          <MentalHealthEdit
            detailsData={detailsData}
            handleCancel={handleCancel}
            setEdit={setEditing}
          />
        ) : (
          <>
            <TitleContainerCustom
              style={{
                paddingBottom: 16,
                marginBottom: 0,
                borderBottom: "1px solid #F2F2F2",
              }}
            >
              <TitleWrapper>
                <TextTitle>{detailsData.test_name}</TextTitle>
                {screens.md && (
                  <div style={{ position: "relative" }}>
                    {isVisibleTooltip && (
                      <Tooltip4AlertTabletDesktop>
                        <div>{stressStyles.alert}</div>
                      </Tooltip4AlertTabletDesktop>
                    )}
                    <MentalHealthStatus onClick={handleToggleTooltip}>
                      <Dot
                        style={{
                          background: stressStyles.borderColor,
                          width: "14px",
                          height: "14px",
                        }}
                      />
                      <div>{t(stressStyles.status)}</div>
                    </MentalHealthStatus>
                  </div>
                )}
              </TitleWrapper>
              {screens.md && displayCondition && (
                <Button
                  color="#FFFFFF"
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="16px"
                  background={"#2F8CAE"}
                  padding="11px 48px 13px"
                  name={t("edit")}
                  bdr="8px"
                  border="none"
                  onClick={() => setEditing(true)}
                />
              )}
            </TitleContainerCustom>
            <ItemsContainer>
              <LeftItem>{t("implementation-grade")}</LeftItem>
              <div
                style={{
                  flex: 1,
                  background: "#F2F2F2",
                  borderRadius: "6px",
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: "16px",
                  padding: 12,
                }}
              >
                {detailsData.grade}
              </div>
            </ItemsContainer>
            <ItemsContainer>
              <LeftItem>{t("examination-period")}</LeftItem>
              <div
                style={{
                  flex: 1,
                  background: "#F2F2F2",
                  borderRadius: "6px",
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: "16px",
                  padding: 12,
                }}
              >
                {detailsData.examination_period}
              </div>
            </ItemsContainer>

            {!screens.md && displayCondition && (
              <div style={{ display: "flex" }}>
                <Button
                  color="#FFFFFF"
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="16px"
                  background={"#2F8CAE"}
                  padding="11px 48px 13px"
                  name={t("edit")}
                  bdr="8px"
                  border="none"
                  onClick={() => setEditing(true)}
                  margin="48px auto 0px"
                />
              </div>
            )}
          </>
        )}
      </div>
      {showModal && (
        <ConfirmModal
          isVisible={showModal}
          handleCancel={() => setShowModal(false)}
          handleOk={() => {
            setEditing(false);
            setShowModal(false);
            dispatch(
              MentalCheckDetailsActionCreators.mentalCheckDetailsAction({
                id: detailsData.id,
                app_language_id: currentLanguage === "ja" ? 2 : 1,
              })
            );
          }}
        >
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "20px",
            }}
          >
            {t("confirm-leaving")}
          </div>
        </ConfirmModal>
      )}
    </>
  );
};

export default MentalHealthDetails;
