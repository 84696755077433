import {
  Action,
  ActionType,
  IUpdateDeseaseResponse,
} from "models/setting/updateDeseaseOutbreak";
import createReducer from "../../createReducer";

export interface UpdateDeseaseSettingReducerType {
  loading: boolean;
  error?: string;
  updateDeseaseSettingResponse?: IUpdateDeseaseResponse;
}

const defaultState: UpdateDeseaseSettingReducerType = {
  loading: false,
  error: undefined,
  updateDeseaseSettingResponse: undefined,
};

export const updateDeseaseSettingReducer =
  createReducer<UpdateDeseaseSettingReducerType>(defaultState, {
    [ActionType.UPDATE_DESEASE_SETTING](
      state: UpdateDeseaseSettingReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_DESEASE_SETTING_ERROR](
      state: UpdateDeseaseSettingReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_DESEASE_SETTING_SUCCESS](
      state: UpdateDeseaseSettingReducerType,
      action: Action<IUpdateDeseaseResponse>
    ) {
      return {
        ...state,
        loading: false,
        updateDeseaseSettingResponse: action.payload,
      };
    },

    [ActionType.RESET_DESEASE_SETTING](state: UpdateDeseaseSettingReducerType) {
      return {
        ...state,
        loading: false,
        error: undefined,
        updateDeseaseSettingResponse: {},
      };
    },
  });
