import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  UpdateMentalCheckData,
  ActionType,
} from "models/mentalHealth/updateMentalCheck";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { updateMentalCheck } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./updateMentalCheckActions";

function* updateMentalCheckSaga({
  payload,
}: {
  payload: UpdateMentalCheckData;
}) {
  try {
    const response: IResponse = yield call(updateMentalCheck, payload);

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateMentalCheckWatcher() {
  yield takeLatest(
    ActionType.UPDATE_MENTAL_CHECK as any,
    updateMentalCheckSaga
  );
}

export default [fork(onUpdateMentalCheckWatcher)];
