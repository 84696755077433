import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/questionnaires/sendQuestionHistory";
import { httpStatus } from "configs/httpStatus";
import { fetchHistoryList } from "services/questionnaires";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./sendQuestionHistoryActions";
import {
  IQuestionHistoryData,
  IQuestionHistoryResponse,
} from "models/questionnaires/sendQuestionHistory";

function* sendQuestionHistoryListSaga({
  payload,
}: {
  payload: IQuestionHistoryData;
}) {
  try {
    const response: IQuestionHistoryResponse = yield call(
      fetchHistoryList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction(undefined as any));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSendQuestionHistoryListWatcher() {
  yield takeLatest(
    ActionType.SEND_QUESTION_HISTORY_LIST as any,
    sendQuestionHistoryListSaga
  );
}

export default [fork(onSendQuestionHistoryListWatcher)];
