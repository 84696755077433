import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { saveTemporaryStudentList } from "services/classchangemanagement";
import {
  ActionType,
  ISaveTemporaryClassChangeRequest,
} from "models/classchangemanagement/saveTemporary";
import { IResponse } from "models";

import { handleSuccesAction, handleErrorAction } from "./saveTemporaryActions";

function* saveTemporaryStudentClassChangeSaga({
  payload,
}: {
  payload: ISaveTemporaryClassChangeRequest;
}) {
  try {
    const response: IResponse = yield call(saveTemporaryStudentList, payload);
    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnprocessableEntity) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSaveTemporaryStudentClassChangeWatcher() {
  yield takeLatest(
    ActionType.SAVE_TEMPORARY_CLASS_CHANGE as any,
    saveTemporaryStudentClassChangeSaga
  );
}

export default [fork(onSaveTemporaryStudentClassChangeWatcher)];
