import {
  Action,
  ActionType,
  IMessageHistoryDetailResponse,
} from "models/messagemanagement/messageHistoryDetail";
import createReducer from "../../createReducer";

export interface MessageHistoryDetailReducerType {
  loading: boolean;
  error?: string;
  messageHistoryDetailResponse?: IMessageHistoryDetailResponse;
}

const defaultState: MessageHistoryDetailReducerType = {
  loading: false,
  error: undefined,
  messageHistoryDetailResponse: undefined,
};

export const messageHistoryDetailReducer =
  createReducer<MessageHistoryDetailReducerType>(defaultState, {
    [ActionType.MESSAGE_HISTORY_DETAIL](
      state: MessageHistoryDetailReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MESSAGE_HISTORY_DETAIL_ERROR](
      state: MessageHistoryDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.MESSAGE_HISTORY_DETAIL_SUCCESS](
      state: MessageHistoryDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        messageHistoryDetailResponse: action.payload,
      };
    },

    [ActionType.RESET_MESSAGE_HISTORY_DETAIL](
      state: MessageHistoryDetailReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        messageHistoryDetailResponse: [],
      };
    },
  });
