import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType, IRecordVaccineListResponse } from "models/vaccine/records";
import { httpStatus } from "configs/httpStatus";
import { fetchRecordList } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./recordsActions";

function* recordListSaga({ payload }: { payload: any }) {
  try {
    const response: IRecordVaccineListResponse = yield call(
      fetchRecordList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetRecordListWatcher() {
  yield takeLatest(ActionType.VACCINE_RECORD_LIST as any, recordListSaga);
}

export default [fork(onGetRecordListWatcher)];
