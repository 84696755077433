import * as React from "react";

function FaceNotAttempIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="#F2F2F2" />
      <path
        d="M5.4689 7.76668C5.28804 7.36599 5.28804 6.92886 5.4689 6.52817C5.79444 5.87248 6.69874 5.47179 7.42218 5.7632C7.5307 5.79963 7.63922 5.87248 7.71156 5.94534C7.82008 6.05462 7.92859 6.23675 8.00094 6.34603C8.21797 6.78316 8.29031 7.40241 8.00094 7.80311C7.42218 8.64093 5.9753 8.75021 5.4689 7.76668Z"
        fill="white"
      />
      <path
        d="M11.9438 7.76668C11.7629 7.36599 11.7629 6.92886 11.9438 6.52817C12.2693 5.87248 13.1736 5.47179 13.897 5.7632C14.0056 5.79963 14.1141 5.87248 14.1864 5.94534C14.2949 6.05462 14.4034 6.23675 14.4758 6.34603C14.6928 6.78316 14.7652 7.40241 14.4758 7.80311C13.8609 8.64093 12.3778 8.75021 11.9438 7.76668Z"
        fill="white"
      />
      <path
        d="M13.499 14.0686H6.5178C6.30077 14.0686 6.08374 13.8864 6.08374 13.6315C6.08374 13.3765 6.2646 13.1943 6.5178 13.1943H13.4628C13.6799 13.1943 13.8969 13.3765 13.8969 13.6315C13.8969 13.8864 13.716 14.0686 13.499 14.0686Z"
        fill="white"
      />
    </svg>
  );
}

export default FaceNotAttempIcon;
