import {
  IQuestionnaireResponseDetailPayload,
  ActionType,
  IQuestionnaireResponseDetailResponse,
} from "models/questionnaires/questionnaireResponseDetail";

export const getQuestionnaireResponseDetailAction = (
  payload: IQuestionnaireResponseDetailPayload
) => {
  return {
    type: ActionType.GET_QUESTIONNAIRE_RESPONSE_DETAIL,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_QUESTIONNAIRE_RESPONSE_DETAIL_ERROR,
    payload,
  };
};

export const handleSuccessAction = (
  payload: IQuestionnaireResponseDetailResponse
) => {
  return {
    type: ActionType.GET_QUESTIONNAIRE_RESPONSE_DETAIL_SUCCESS,
    payload,
  };
};
