import * as React from "react";

function FaceVeryBadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="#D95C4B" />
      <path
        d="M5.90831 8.80446C5.73959 8.77072 5.70585 8.66949 5.84082 8.53452C5.70585 8.46704 5.67211 8.36581 5.80708 8.26458C5.67211 8.16336 5.67211 8.06213 5.84082 7.99465C5.73959 7.85968 5.77334 7.79219 5.90831 7.72471C5.87456 7.52226 5.97579 7.35354 6.21198 7.3198C6.27947 7.11735 6.41444 7.01612 6.65063 7.04987C6.7856 6.84741 6.95431 6.81367 7.15677 6.94864C7.35922 6.81367 7.52793 6.84741 7.6629 7.01612C7.76413 6.9149 7.76413 6.9149 7.73038 7.04987C7.83161 6.94864 7.86535 6.94864 7.79787 7.08361C7.8991 6.98238 7.93284 7.01612 7.86535 7.11735C7.96658 7.01612 7.96658 7.04986 7.8991 7.15109C8.00032 7.08361 8.03406 7.11735 7.96658 7.21858C8.10155 7.15109 8.10155 7.18483 8.03406 7.3198C8.16903 7.25232 8.16903 7.28606 8.10155 7.42103C8.23652 7.38729 8.23652 7.42103 8.13529 7.48851C8.304 7.52226 8.33774 7.65722 8.23652 7.79219C8.40523 7.89342 8.40523 7.99465 8.27026 8.12962C8.40523 8.26458 8.40523 8.36581 8.23652 8.46704C8.33774 8.63575 8.27026 8.73698 8.10155 8.77072C8.06781 9.04066 7.8991 9.20937 7.59542 9.20937C7.4267 9.44556 7.19051 9.4793 6.95431 9.37808C6.71812 9.51305 6.48192 9.4793 6.34695 9.24311C6.11076 9.27685 5.90831 9.10814 5.90831 8.80446Z"
        fill="white"
      />
      <path
        d="M11.9144 8.80446C11.7457 8.77072 11.712 8.66949 11.8469 8.53452C11.712 8.46704 11.6782 8.36581 11.8132 8.26458C11.6782 8.16336 11.6782 8.06213 11.8469 7.99465C11.7457 7.85968 11.7794 7.79219 11.9144 7.72471C11.8807 7.52226 11.9819 7.35354 12.2181 7.3198C12.2856 7.11735 12.4205 7.01612 12.6567 7.04987C12.7917 6.84741 12.9604 6.81367 13.1629 6.94864C13.3653 6.81367 13.534 6.84741 13.669 7.01612C13.7702 6.9149 13.7702 6.9149 13.7365 7.04987C13.8377 6.94864 13.8715 6.94864 13.804 7.08361C13.9052 6.98238 13.9389 7.01612 13.8715 7.11735C13.9727 7.01612 13.9727 7.04986 13.9052 7.15109C14.0064 7.08361 14.0402 7.11735 13.9727 7.21858C14.1077 7.15109 14.1077 7.18483 14.0402 7.3198C14.1751 7.25232 14.1751 7.28606 14.1077 7.42103C14.2426 7.38729 14.2426 7.42103 14.1414 7.48851C14.3101 7.52226 14.3438 7.65722 14.2426 7.79219C14.4113 7.89342 14.4113 7.99465 14.2764 8.12962C14.4113 8.26458 14.4113 8.36581 14.2426 8.46704C14.3438 8.63575 14.3101 8.73698 14.1077 8.77072C14.0739 9.04066 13.9052 9.20937 13.6015 9.20937C13.4328 9.44556 13.1966 9.4793 12.9604 9.37808C12.6905 9.51305 12.4543 9.4793 12.3193 9.24311C12.0831 9.27685 11.9144 9.10814 11.9144 8.80446Z"
        fill="white"
      />
      <path
        d="M6.88701 15.4179C6.85327 15.4179 6.81953 15.4179 6.78578 15.4179C6.58333 15.3504 6.44836 15.148 6.51585 14.9455C6.7183 14.2032 7.12321 13.1572 7.89928 12.5498C8.77657 11.875 9.82258 11.6388 10.8348 11.875C12.1171 12.1787 13.2643 13.2584 13.9729 14.8106C14.0741 15.013 13.9729 15.2492 13.7704 15.3167C13.568 15.4179 13.3318 15.3167 13.2643 15.1142C12.6569 13.7646 11.7121 12.8535 10.6661 12.5836C9.89007 12.3811 9.08025 12.5836 8.40541 13.1235C7.93302 13.4946 7.52811 14.2032 7.29192 15.1142C7.22443 15.283 7.05572 15.4179 6.88701 15.4179Z"
        fill="white"
      />
      <path
        d="M5.06493 6.94862C4.72751 6.94862 4.42383 6.88114 4.18763 6.74617C3.98518 6.64494 3.91769 6.40875 4.01892 6.2063C4.12015 6.00384 4.35634 5.93636 4.55879 6.03758C5.13241 6.34126 6.88701 5.83513 7.22443 5.22777C7.32566 5.02532 7.56185 4.95783 7.76431 5.0928C7.96676 5.19403 8.03424 5.43022 7.89927 5.63268C7.46062 6.37501 6.11094 6.94862 5.06493 6.94862Z"
        fill="white"
      />
      <path
        d="M15.0862 6.81364C13.8714 6.81364 12.5555 6.20628 12.2856 5.53144C12.2181 5.32899 12.2856 5.09279 12.488 5.02531C12.6905 4.95782 12.9267 5.02531 12.9941 5.22776C13.1629 5.66641 14.6475 6.27377 15.6935 5.97009C15.896 5.9026 16.1322 6.03757 16.1659 6.24003C16.1997 6.44248 16.0984 6.67868 15.896 6.71242C15.6598 6.7799 15.3898 6.81364 15.0862 6.81364Z"
        fill="white"
      />
      <path
        d="M4.45745 11.4026C4.08629 11.8412 3.58016 12.0437 3.17525 11.74C2.80409 11.4363 2.87157 10.829 3.31022 10.4241C3.71513 10.0192 4.32249 9.88419 4.92985 9.78296C4.99733 9.78296 5.06481 9.8167 5.03107 9.88419C4.76113 10.4915 4.76113 10.9977 4.45745 11.4026Z"
        fill="white"
      />
      <path
        d="M15.6937 10.6602C15.9974 11.0314 16.5373 11.2001 16.841 10.9639C17.1446 10.694 17.0772 10.1878 16.706 9.85042C16.3686 9.513 15.6937 9.37803 15.39 9.31055C15.3226 9.31055 15.2888 9.31055 15.2888 9.37803C15.4913 9.91791 15.4238 10.3228 15.6937 10.6602Z"
        fill="white"
      />
      <path
        d="M18.0894 12.3474C18.3256 12.7186 18.663 12.8873 19.0005 12.6848C19.3716 12.4824 19.4391 11.9425 19.1692 11.5713C18.8655 11.2339 18.2581 11.0652 17.8195 10.964C17.752 10.964 17.7183 10.964 17.7183 11.0315C17.8532 11.5713 17.8532 12.01 18.0894 12.3474Z"
        fill="white"
      />
      <path
        d="M16.6046 14.1694C16.7395 14.6755 17.0432 14.9455 17.4819 14.878C17.9543 14.7768 18.2242 14.2369 18.0218 13.7307C17.8193 13.2246 17.2457 12.8535 16.7733 12.6173C16.7058 12.5835 16.6721 12.5835 16.6383 12.651C16.6383 13.2584 16.4696 13.7307 16.6046 14.1694Z"
        fill="white"
      />
    </svg>
  );
}

export default FaceVeryBadIcon;
