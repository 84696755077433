import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  PDFDownloadTypeInfoResponse,
  ActionType,
  ITypeInfoDownloadData,
} from "models/mentalHealth/pdfDownloadTypeInfo";
import { httpStatus } from "configs/httpStatus";
import { downloadPDFTypeInfo } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./downloadTypePDFActions";

function* pdfDownloadTypeInfoSaga({
  payload,
}: {
  payload: ITypeInfoDownloadData;
}) {
  try {
    const response: PDFDownloadTypeInfoResponse = yield call(
      downloadPDFTypeInfo,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDownloadPDFTypeInfoWatcher() {
  yield takeLatest(
    ActionType.PDF_DOWNLOAD_TYPE_INFO as any,
    pdfDownloadTypeInfoSaga
  );
}

export default [fork(onDownloadPDFTypeInfoWatcher)];
