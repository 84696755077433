import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IQuestionnairesPayload,
  ActionType,
  QuestionnairesResponse,
} from "models/questionnaires/questionnairesByType";
import { httpStatus } from "configs/httpStatus";
import { fetchQuestionnaries } from "services/questionnaires";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./questionnairesByTypeActions";

function* getQuestionnariesSaga({
  payload,
}: {
  payload: IQuestionnairesPayload;
}) {
  try {
    const response: QuestionnairesResponse = yield call(
      fetchQuestionnaries,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetQuestionnariesSagaSubmitWatcher() {
  yield takeLatest(ActionType.GET_QUESTIONNAIRES as any, getQuestionnariesSaga);
}

export default [fork(onGetQuestionnariesSagaSubmitWatcher)];
