import {
  ScheduleData,
  ActionType,
  UpdateScheduleResponse,
} from "models/vaccine/schedules";

export const updateScheduleAction = (payload: ScheduleData) => {
  return {
    type: ActionType.UPDATE_SCHEDULE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_SCHEDULE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: UpdateScheduleResponse) => {
  return {
    type: ActionType.UPDATE_SCHEDULE_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.UPDATE_SCHEDULE_RESET,
  };
};
