import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IExchangeListPayload,
  ActionType,
  IExchangeListResponse,
} from "models/consultationRequest/exchangeList";
import { httpStatus } from "configs/httpStatus";
import { fetchExchangeList } from "services/consultationRequest";
import { handleSuccesAction, handleErrorAction } from "./exchangeListActions";

function* exchangeListSaga({ payload }: { payload: IExchangeListPayload }) {
  try {
    const response: IExchangeListResponse = yield call(
      fetchExchangeList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetExchangeListWatcher() {
  yield takeLatest(ActionType.EXCHANGE_LIST as any, exchangeListSaga);
}

export default [fork(onGetExchangeListWatcher)];
