import {
  ActionType,
  ICSVDownloadStudentListResponse,
  ICSVDownloadStudentListData,
} from "../../../models/classchangemanagement/getCSVStudentListData";

export const getDownloadCSVStudentListAction = (
  payload: ICSVDownloadStudentListData
) => {
  return {
    type: ActionType.GET_CSV_DOWNLOAD_STUDENT_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_CSV_DOWNLOAD_STUDENT_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (
  payload: ICSVDownloadStudentListResponse
) => {
  return {
    type: ActionType.GET_CSV_DOWNLOAD_STUDENT_LIST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.GET_CSV_DOWNLOAD_STUDENT_LIST_RESET,
  };
};
