import {
  Action,
  ActionType,
  IMentalCheckTypeListResponse,
} from "models/mentalHealth/typeList";
import createReducer from "redux/createReducer";

export interface TypeListReducerType {
  loading: boolean;
  error?: string;
  typeListResponse?: IMentalCheckTypeListResponse;
}

const defaultState: TypeListReducerType = {
  loading: false,
  error: undefined,
  typeListResponse: undefined,
};

export const typeListReducer = createReducer<TypeListReducerType>(
  defaultState,
  {
    [ActionType.MENTAL_CHECK_TYPE_LIST](state: TypeListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MENTAL_CHECK_TYPE_LIST_ERROR](
      state: TypeListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        typeListResponse: undefined,
      };
    },

    [ActionType.MENTAL_CHECK_TYPE_LIST_SUCCESS](
      state: TypeListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        typeListResponse: action.payload,
      };
    },

    [ActionType.MENTAL_CHECK_TYPE_LIST_RESET_DATA](state: TypeListReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
  }
);
