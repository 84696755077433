import {
  Action,
  ActionType,
  ProgressBarResponse,
} from "models/bodyTemperature/progressBar";
import createReducer from "redux/createReducer";

export interface DataProgressBarReducerType {
  loading: boolean;
  error?: string;
  dataProgressBarResponse?: ProgressBarResponse;
}

const defaultState: DataProgressBarReducerType = {
  loading: false,
  error: undefined,
  dataProgressBarResponse: undefined,
};

export const dataProgressBarReducer = createReducer<DataProgressBarReducerType>(
  defaultState,
  {
    [ActionType.PROGRESS_BAR](state: DataProgressBarReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.PROGRESS_BAR_ERROR](
      state: DataProgressBarReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.PROGRESS_BAR_SUCCESS](
      state: DataProgressBarReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        dataProgressBarResponse: action.payload,
      };
    },
  }
);
