import {
  ActionType,
  AbsencePayload,
  AbsenceResponse,
} from "models/dashboard/absence";

export const getAbsenceAction = (payload: AbsencePayload) => {
  return {
    type: ActionType.GET_ABSENCE_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_ABSENCE_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: AbsenceResponse) => {
  return {
    type: ActionType.GET_ABSENCE_CHART_SUCCESS,
    payload,
  };
};
