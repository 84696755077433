import { IResponse } from "models";
import { IDepartment, IActivites } from "./departmentList";

export enum ActionType {
  ACCOUNT_REGISTRATION_LIST = "action/ACCOUNT_REGISTRATION_LIST",
  ACCOUNT_REGISTRATION_LIST_SUCCESS = "action/ACCOUNT_REGISTRATION_LIST_SUCCESS",
  ACCOUNT_REGISTRATION_LIST_ERROR = "action/ACCOUNT_REGISTRATION_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface AccountRegistrationListResponse extends IResponse {
  result: {
    selectable_departments: IDepartment[];
    selectable_sub_departments: IActivites[];
  };
}
