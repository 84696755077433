import * as React from "react";

function BackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(-7,-7)">
        <circle cx="21" cy="21" r="14" fill="white" />
      </g>
      <path
        d="M23.0321 15.0009H15.9066C15.6676 15.0056 15.4397 15.1026 15.2707 15.2716C15.1017 15.4407 15.0046 15.6686 15 15.9077C15 16.1482 15.0955 16.3788 15.2655 16.5488C15.4355 16.7189 15.6661 16.8144 15.9066 16.8144H23.0321C23.461 16.8006 23.888 16.8771 24.2855 17.0389C24.683 17.2007 25.0421 17.4442 25.3396 17.7536C25.637 18.0631 25.8661 18.4315 26.0121 18.8352C26.158 19.2388 26.2176 19.6686 26.1869 20.0968C26.203 20.946 25.9207 21.7739 25.3891 22.4362C25.0954 22.7687 24.7318 23.0322 24.3243 23.2078C23.9168 23.3834 23.4755 23.4667 23.0321 23.4517H19.4058L21.6178 21.2393C21.7885 21.0685 21.8845 20.837 21.8845 20.5955C21.8845 20.354 21.7885 20.1224 21.6178 19.9517C21.4471 19.781 21.2156 19.6851 20.9742 19.6851C20.7328 19.6851 20.5013 19.781 20.3305 19.9517L16.5774 23.7056C16.4121 23.8825 16.3213 24.1163 16.3236 24.3584C16.3222 24.595 16.4132 24.8228 16.5774 24.9932L20.3305 28.7471C20.5035 28.9206 20.7383 29.0185 20.9833 29.0191C21.2224 29.0157 21.4505 28.9179 21.6178 28.7471C21.7851 28.5745 21.8787 28.3436 21.8787 28.1033C21.8787 27.8629 21.7851 27.632 21.6178 27.4595L19.4058 25.2652H23.0321C23.7378 25.2826 24.4386 25.1437 25.0844 24.8584C25.7301 24.5731 26.3047 24.1484 26.7671 23.6149C27.5795 22.624 28.0161 21.3781 28 20.0968C28.0299 19.4304 27.9228 18.7649 27.6854 18.1416C27.4479 17.5182 27.0852 16.9502 26.6196 16.4726C26.154 15.995 25.5954 15.618 24.9784 15.3648C24.3613 15.1117 23.6989 14.9879 23.0321 15.0009Z"
        fill="#2F8CAE"
        transform="translate(-7,-7)"
      />
    </svg>
  );
}

export default BackIcon;
