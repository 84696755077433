import styled from "styled-components";
import { Upload } from "antd";

import { theme } from "theme/theme";
import { Button, Modal } from "components";
import { RemoveFileIcon, UploadIcon } from "icons";
import ConfirmModal from "screens/changeStudentAffiliation/ConfirmModal";

const { Dragger } = Upload;

export const WrapperUpload = styled.div`
  position: relative;
  margin-top: 24px;
  width: 100%;
  padding-bottom: 32px;
  ${theme.media.tablet} {
    padding-bottom: 0;
    margin-right: 16px;
    margin-top: 0;
    width: 100%;
    .ant-upload.ant-upload-select {
      width: 100%;
    }
  }
`;

export const UploadFileBtn = styled(Button)`
  font-size: 13px;
  padding: 10px 32px 11px 50px;
  text-align: left;
  width: 100%;
  svg path {
    fill: ${theme.colors.white};
  }
  span {
    margin-right: 0 !important;
  }
  ${theme.media.tablet} {
    font-size: 12px;
    padding: 9px 8px 11px 38px;
  }
  ${theme.media.laptopL} {
    font-size: 16px;
    padding: 9px 24px 11px 42px;
  }
  ${theme.media.desktop} {
    padding: 9px 13px 11px 42px;
  }
`;

export const DraggerStyled = styled(Dragger)`
  height: 362px !important;
  overflow: auto;
  background: #f2f2f2 !important;
  border: unset !important;
  border-radius: 12px !important;
  padding: 3px 16px;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 16px 0px 13px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }

  .ant-upload-drag-container {
    display: unset !important;
  }
  .ant-upload {
    padding: 0 !important;
  }
  .ant-upload-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  ${theme.media.tablet} {
  }
  ${theme.media.laptopL} {
  }
`;

export const WrapperFile = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  /* margin-bottom: 4px;
  margin-top: 10px; */
  div {
    margin-left: 7px;
    margin-right: 4px;
    font-size: ${theme.sizes.sm};
    font-weight: ${theme.fontWeight.medium};
    line-height: 21px;
    color: ${theme.colors.text.primary};
    text-align: left;
  }
  svg:last-child {
    /* margin-bottom: -2px; */
  }
`;

export const PrimaryText = styled.div`
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.icon};
  line-height: 20px;
`;

export const SecondaryText = styled(PrimaryText)`
  font-weight: ${theme.fontWeight.regular};
  font-size: 13.5px;
  line-height: 20px;
  ${theme.media.tablet} {
    font-size: 14px;
  }
`;

export const ThirdText = styled(PrimaryText)`
  font-weight: ${theme.fontWeight.regular};
  font-size: 13.5px;
  line-height: 20px;
  ${theme.media.tablet} {
    font-size: 14px;
    padding: 0 34px;
  }
  ${theme.media.laptopL} {
    font-size: 14px;
    padding: 0;
  }
`;

export const WrapperDecription = styled.div`
  text-align: center;
  svg {
    margin-bottom: 0px;
  }
  ${theme.media.mobileM} {
    svg {
      margin-bottom: 0px;
    }
  }
  ${theme.media.tablet} {
    svg {
      margin-bottom: 0px;
    }
  }
  ${theme.media.laptop} {
    svg {
      margin-bottom: 0px;
    }
  }
  ${theme.media.laptopL} {
    svg {
    }
  }
`;

export const RemoveFileIconStyled = styled(RemoveFileIcon)`
  /* position: absolute;
  top: -6px;
  right: -9px; */
`;

export const UpdatingBtn = styled(Button)`
  height: 40px;
  ${theme.media.tablet} {
    height: max-content;
  }
  ${theme.media.laptopL} {
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 20px 16px 24px;
  }
  .ant-modal-footer {
    padding: 0px 16px 20px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 24px 24px 16px 24px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 144px;
    padding: 3px;
  }
  button:last-child {
    width: 151px;
  }
  ${theme.media.tablet} {
    justify-content: flex-end;
    button:last-child {
      margin-left: 16px;
    }
    button {
      width: 112px !important;
    }
  }
`;

export const WrapperFileUpload = styled.div`
  /* text-align: right;
  ${theme.media.tablet} {
    display: flex;
  } */
`;

export const UploadIconStyled = styled(UploadIcon)`
  position: absolute;
  top: 10px;
  left: 16px;
  margin-right: 3px;
  ${theme.media.tablet} {
    top: 9px;
    left: 9px;
  }
`;

export const WrapperUploadedResult = styled.div`
  background: ${theme.colors.white};
  padding: 3px 5px 8px;
  border-radius: 6px;
`;

export const Result = styled.div`
  margin-bottom: 2px;
  text-align: left;
  line-height: 16px;
  font-size: ${theme.sizes.xs};
  font-weight: ${theme.fontWeight.bold};

  span:first-child {
    color: ${theme.colors.text.primary};
  }
  span:nth-of-type(3) {
    color: ${theme.colors.textLink};
  }
  span:last-child {
    color: ${theme.colors.error};
  }
`;

export const WrapperUploadedError = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 87px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    border-left: 1px solid ${theme.colors.white};
    border-right: 1px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 1px solid ${theme.colors.white};
    border-right: 1px solid ${theme.colors.white};
  }
  div {
    background: ${theme.colors.error};
    padding: 4px 8px 5px;
    color: ${theme.colors.white};
    font-size: ${theme.sizes.xs};
    line-height: 16px;
    text-align: left;
  }
  ${theme.media.tablet} {
    height: 96px;
  }
  ${theme.media.laptopL} {
    height: 80px;
  }
`;

export const WrapperUpdatingBtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  .link-wrapper {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    align-items: center;
    .text-link {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #2f8cae;
      text-decoration: underline;
      cursor: pointer;
    }
    svg {
      cursor: pointer;
    }
  }
  ${theme.media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  ${theme.media.laptopL} {
  }
`;

export const WrapperProgressBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 12px;
  z-index: 1000;
  .ant-progress {
    height: 16px;
    margin-bottom: 32px;
    .ant-progress-outer {
      height: 100%;
      margin-right: 0;
      padding-right: 0;
      position: relative;
      .ant-progress-inner {
        position: absolute;
        left: 0;
      }
    }
  }
`;

export const ContainerProgressBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  p {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    color: ${theme.colors.white};
    margin-bottom: 16px;
  }
  .ant-progress-line {
    width: 660px;
  }
  .ant-progress-text {
    display: none;
  }
  .ant-progress-inner {
    border-radius: 8px;
  }
  .ant-progress-bg {
    height: 16px !important;
  }
`;

export const WrapperButtonFooter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  button {
    width: 50%;
  }
  ${theme.media.tablet} {
    button {
      width: 128px;
    }
  }
`;

export const HeaderTitleWrapper = styled.div`
  min-height: 46px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: #3b3b3b;
  }
  .error {
    border-radius: 6px;
    border: 1px solid #fb2121;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 8px 8px 6px;
    width: 100%;
    color: #fb2121;
  }
  ${theme.media.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .error {
      max-width: 400px;
    }
  }
`;

export const ResizeWrapper = styled.div`
  min-height: 473px;
  resize: vertical;
  overflow: auto;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #00000014 inset;
  border: 1px solid #e0e0e0;
  font-family: Noto Sans JP;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  .bold {
    font-weight: 700;
  }
  .error {
    font-weight: 500;
    color: #fb2121;
  }
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 9px 0px 9px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    height: 8px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }
  ${theme.media.tablet} {
    min-height: 398px;
    padding: 12px 12px 6px;
  }
`;

export const ConfirmCancelModal = styled(ConfirmModal)`
  .ant-modal-body {
    padding: 20px 16px 20px;
  }
  .ant-modal-footer {
    padding: 0px 16px 16px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 20px 24px 12px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
  ${theme.media.desktop} {
    .ant-modal-body {
      padding: 18px 24px 14px;
    }
  }
`;
