import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  WrapperDateRangePickerFirst,
  WrapperDateRangePickerSecond,
  Text,
  CalendarIconStyled,
} from "./DateRangePicker.style";

registerLocale("ja", ja);
const dateFormat = "yyyy/MM/dd";
const CustomHeaderWrapper = (dateFormat: string) => {
  return function CustomHeader({
    monthDate,
    decreaseMonth,
    increaseMonth,
  }: any) {
    return (
      <>
        <button
          aria-label="Previous Month"
          className={
            "react-datepicker__navigation react-datepicker__navigation--previous"
          }
          onClick={decreaseMonth}
        >
          <span
            className={
              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
            }
          >
            {"<"}
          </span>
        </button>
        <div className="react-datepicker__current-month">
          {format(monthDate, dateFormat)}
        </div>
        <button
          aria-label="Next Month"
          className={
            "react-datepicker__navigation react-datepicker__navigation--next"
          }
          onClick={increaseMonth}
        >
          <span
            className={
              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
            }
          >
            {">"}
          </span>
        </button>
      </>
    );
  };
};

const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  highlightDates,
  locale = "ja",
  renderDayContents,
  maxDate = true,
  minDate = false,
}: any) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });
  return (
    <div style={{ display: "inline-flex" }}>
      <WrapperDateRangePickerFirst>
        <div style={{ display: "flex", position: "relative" }}>
          <DatePicker
            dateFormat={dateFormat}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            renderCustomHeader={CustomHeaderWrapper(
              t("datepicker-header-format")
            )}
            highlightDates={highlightDates}
            locale={locale}
            maxDate={maxDate ? new Date() : endDate}
            renderDayContents={renderDayContents}
            placeholderText={t1("unspecified")}
            minDate={minDate ? moment().toDate() : null}
          />

          <CalendarIconStyled />
        </div>
      </WrapperDateRangePickerFirst>
      <Text>~</Text>
      <WrapperDateRangePickerSecond>
        <div style={{ display: "flex", position: "relative" }}>
          <DatePicker
            dateFormat={dateFormat}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            renderCustomHeader={CustomHeaderWrapper(
              t("datepicker-header-format")
            )}
            highlightDates={highlightDates}
            locale={locale}
            maxDate={maxDate ? new Date() : undefined}
            renderDayContents={renderDayContents}
            placeholderText={t1("unspecified")}
          />
          <CalendarIconStyled />
        </div>
      </WrapperDateRangePickerSecond>
    </div>
  );
};

export default DateRangePicker;
