import {
  Action,
  ActionType,
  UpdatePositionColumnResponse,
} from "models/updatePositionColumn";
import createReducer from "../createReducer";

export interface UpdatePositionColumnReducerType {
  loading: boolean;
  error?: string;
  updatePositionColumnResponse?: UpdatePositionColumnResponse;
}

const defaultState: UpdatePositionColumnReducerType = {
  loading: false,
  error: undefined,
  updatePositionColumnResponse: undefined,
};

export const updatePositionColumnReducer =
  createReducer<UpdatePositionColumnReducerType>(defaultState, {
    [ActionType.UPDATE_POSITION_COLUMNS](
      state: UpdatePositionColumnReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_POSITION_COLUMNS_ERROR](
      state: UpdatePositionColumnReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        updatePositionColumnResponse: [],
      };
    },

    [ActionType.UPDATE_POSITION_COLUMNS_SUCCESS](
      state: UpdatePositionColumnReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        updatePositionColumnResponse: action.payload,
      };
    },

    [ActionType.UPDATE_POSITION_COLUMNS_RESET](
      state: UpdatePositionColumnReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        updatePositionColumnResponse: undefined,
      };
    },
  });
