import {
  ActionType,
  CSVDownloadTypeInfoResponse,
  ITypeInfoDownloadData,
} from "models/mentalHealth/csvDownloadTypeInfo";

export const csvDownloadTypeInfoAction = (payload: ITypeInfoDownloadData) => {
  return {
    type: ActionType.CSV_DOWNLOAD_TYPE_INFO,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CSV_DOWNLOAD_TYPE_INFO_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: CSVDownloadTypeInfoResponse) => {
  return {
    type: ActionType.CSV_DOWNLOAD_TYPE_INFO_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.CSV_DOWNLOAD_TYPE_INFO_RESET_DATA,
  };
};
