import {
  Action,
  ActionType,
  CSVDownloadTypeInfoResponse,
} from "models/mentalHealth/csvDownloadTypeInfo";
import createReducer from "redux/createReducer";

export interface CSVDownloadTypeInfoReducerType {
  loading: boolean;
  error?: string;
  csvDownloadTypeInfoResponse?: CSVDownloadTypeInfoResponse;
}

const defaultState: CSVDownloadTypeInfoReducerType = {
  loading: false,
  error: undefined,
  csvDownloadTypeInfoResponse: undefined,
};

export const csvDownloadTypeInfoReducer =
  createReducer<CSVDownloadTypeInfoReducerType>(defaultState, {
    [ActionType.CSV_DOWNLOAD_TYPE_INFO](state: CSVDownloadTypeInfoReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CSV_DOWNLOAD_TYPE_INFO_ERROR](
      state: CSVDownloadTypeInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CSV_DOWNLOAD_TYPE_INFO_SUCCESS](
      state: CSVDownloadTypeInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        csvDownloadTypeInfoResponse: action.payload,
      };
    },

    [ActionType.CSV_DOWNLOAD_TYPE_INFO_RESET_DATA](
      state: CSVDownloadTypeInfoReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        csvDownloadTypeInfoResponse: [],
      };
    },
  });
