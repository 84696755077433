import {
  ActionType,
  UpdatePositionColumnResponse,
  UpdatePositionColumnPayload,
} from "models/updatePositionColumn";

export const updatePositionColumnAction = (
  payload: UpdatePositionColumnPayload
) => {
  return {
    type: ActionType.UPDATE_POSITION_COLUMNS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_POSITION_COLUMNS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: UpdatePositionColumnResponse) => {
  return {
    type: ActionType.UPDATE_POSITION_COLUMNS_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.UPDATE_POSITION_COLUMNS_RESET,
  };
};
