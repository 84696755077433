import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { updateMessageSetting } from "services/setting";
import {
  ActionType,
  IUpdateMessageResponse,
} from "models/setting/updateMessage";

import { handleSuccessAction, handleErrorAction } from "./updateMessageActions";

function* updateMessageSettingSaga({ payload }: { payload: any }) {
  try {
    const response: IUpdateMessageResponse = yield call(
      updateMessageSetting,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateMessageSettingWatcher() {
  yield takeLatest(
    ActionType.UPDATE_MESSAGE_SETTING as any,
    updateMessageSettingSaga
  );
}

export default [fork(onUpdateMessageSettingWatcher)];
