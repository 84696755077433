import { Action, ActionType } from "models/forgotPassword";
import createReducer from "../createReducer";

export interface ForgotPasswordReducerType {
  email: string;
  loading: boolean;
  error?: string;
}

const defaultState: ForgotPasswordReducerType = {
  email: "",
  loading: false,
  error: undefined,
};

export const forgotPasswordReducer = createReducer<ForgotPasswordReducerType>(
  defaultState,
  {
    [ActionType.FORGOT_PASSWORD](state: ForgotPasswordReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.FORGOT_PASSWORD_ERROR](
      state: ForgotPasswordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.FORGOT_PASSWORD_SUCCESS](
      state: ForgotPasswordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  }
);
