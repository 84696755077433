import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  HealthCheckProgressResponse,
  ActionType,
  HealthCheckProgressPayload,
} from "models/dashboard/healthCheckProgress";
import { httpStatus } from "configs/httpStatus";
import { fetchHealthCheckProgressChart } from "services/dashboard";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./healthCheckProgressActions";

function* getHealthCheckProgressSaga({
  payload,
}: {
  payload: HealthCheckProgressPayload;
}) {
  try {
    const response: HealthCheckProgressResponse = yield call(
      fetchHealthCheckProgressChart,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetHealthCheckProgressWatcher() {
  yield takeLatest(
    ActionType.GET_HEALTH_CHECK_PROGRESS_CHART as any,
    getHealthCheckProgressSaga
  );
}

export default [fork(onGetHealthCheckProgressWatcher)];
