import {
  ActionType,
  IMentalCheckListResponse,
  CheckListData,
} from "models/mentalHealth/checkList";

export const checkListAction = (payload: CheckListData) => {
  return {
    type: ActionType.MENTAL_CHECK_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MENTAL_CHECK_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IMentalCheckListResponse) => {
  return {
    type: ActionType.MENTAL_CHECK_LIST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.MENTAL_CHECK_LIST_RESET_DATA,
  };
};
