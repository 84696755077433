import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  MentalCheckRequestsData,
  ActionType,
  MentalCheckRequestsResponse,
} from "models/mentalHealth/mentalCheckRequests";
import { httpStatus } from "configs/httpStatus";
import { getMentalCheckRequests } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./mentalCheckRequestsActions";

function* checkRequestsSaga({ payload }: { payload: MentalCheckRequestsData }) {
  try {
    const response: MentalCheckRequestsResponse = yield call(
      getMentalCheckRequests,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetCheckRequestsWatcher() {
  yield takeLatest(ActionType.MENTAL_CHECK_REQUEST as any, checkRequestsSaga);
}

export default [fork(onGetCheckRequestsWatcher)];
