import React, { useState, useEffect, Fragment, useRef } from "react";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import { Button, Box, Pagination, Select } from "components";
import { EditIcon, CloseRoundIcon } from "icons";
import { generateDynamicRecords, localStorageHelper } from "utils";
import { IOption } from "components/select/Select";
import { httpStatus } from "configs/httpStatus";

import {
  AccountListActionCreators,
  DeleteAccountActionCreators,
  AccountDepartmentListActionCreators,
  ClassListFilterActionCreators,
} from "redux/rootActions";
import {
  accountListDataResponse,
  selectIsLoading,
} from "redux/account/accountList/accountListStates";
import { deleteAccountDataResponse } from "redux/account/deleteAccount/deleteAccountStates";
import { classListFilterDataResponse } from "redux/messagemanagement/classfilterlist/classFilterListStates";

import {
  PaginationWrapper,
  ContentWrapper,
  SearchWrapper,
  SearchWrapperLeft,
  WrapperSelectAndBtn,
  TableStyled,
  TextConfirm,
  WrapperBtnConfirmModal,
  ConfirmModalStyled,
} from "./Account.style";
import AccountEdit from "./AccountEdit";

const { useBreakpoint } = Grid;

const AccountList: React.FC = () => {
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const { t }: any = useTranslation("translation", { keyPrefix: "account" });
  const { t: t1 }: any = useTranslation("translation", { keyPrefix: "layout" });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const { handleSubmit, control, watch } = useForm();

  const accountList = useSelector(accountListDataResponse);
  const deleteAccountData = useSelector(deleteAccountDataResponse);
  const classListData = useSelector(classListFilterDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const classListOptions: any = [
    ...[{ id: -1, value: "", name: t("select-affiliation") }],
    ...(classListData?.result || []),
  ];
  const watchGrade = watch("grade");

  const scrollAbleRef = useRef<any>(undefined);
  const [isVisible, setIsVisible] = useState(false);
  const [accountID, setAccountID] = useState();
  const [input, setInput] = useState({
    limit: 50,
    page: 1,
  });
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [oldGrade, setOldGrade] = useState("");

  const totalRecord = accountList?.result.total || 0;

  const handleShowDeleteModal = () => {
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const columns = [
    {
      title: t("display-name"),
      dataIndex: "display_name",
      key: "display_name",
      width: 175,
      fixed: window.innerWidth < 576 ? "" : "left",
      render: (text: any, record: any) => (
        <div style={{ display: "flex" }}>
          <div style={{ marginTop: 3 }}>
            <EditIcon
              onClick={() => {
                setIsEdit(true);
                setAccountID(record.id);
              }}
              fill="currentColor"
              style={{ cursor: "pointer", marginRight: 26 }}
              width="16px"
              height="16px"
            />
          </div>
          <div
            style={{
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            {text}
          </div>
        </div>
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      width: 246,
    },
    {
      title: t2("belongs"),
      dataIndex: "department",
      key: "department",
    },
    {
      title: t3("activities"),
      dataIndex: "sub_department",
      key: "sub_department",
    },
    {
      title: "",
      render: (text: any, record: any) => (
        <CloseRoundIcon
          onClick={() => {
            handleShowDeleteModal();
            setAccountID(record.id);
          }}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  const onSubmit = (data: { [x: string]: any }) => {
    setOldGrade(data.grade);

    if (oldGrade !== data.grade) {
      setInput((prevState: any) => ({ ...prevState, page: 1 }));
      return;
    }

    const params = {
      // search: data.name || "",
      c_department_id: data.grade || "",
      locale: currentLanguage,
      ...input,
    };

    dispatch(AccountListActionCreators.getAccountListAction(params));
  };

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));

    if (
      scrollAbleRef.current &&
      window.scrollY >= scrollAbleRef.current.offsetTop
    ) {
      window.scrollTo(0, 0);
    }
  };

  const handleOnChangeSizeChange = (limit: number) => {
    setInput((prevState: any) => ({ ...prevState, limit, page: 1 }));
  };

  useEffect(() => {
    dispatch(ClassListFilterActionCreators.classFilterListAction());
  }, []);

  useEffect(() => {
    dispatch(
      AccountListActionCreators.getAccountListAction({
        ...input,
        c_department_id: watchGrade,
      })
    );
  }, [input.page, input.limit]);

  useEffect(() => {
    if (deleteAccountData && deleteAccountData.status === httpStatus.StatusOK) {
      setIsVisible(false);
      dispatch(
        AccountListActionCreators.getAccountListAction({
          ...input,
        })
      );
    }
  }, [deleteAccountData]);

  useEffect(() => {
    if (accountList && Object.keys(accountList?.result).length) {
      let recordOptionsClone: IOption[] = [];
      recordOptionsClone = generateDynamicRecords(
        accountList?.result.total,
        t3
      );
      setRecordOptions([...recordOptionsClone]);
    }
  }, [accountList]);

  useEffect(() => {
    if (isEdit) {
      dispatch(
        AccountDepartmentListActionCreators.getDepartmentListAction({
          company_manager_id: accountID,
        })
      );
    }
  }, [isEdit]);

  const ShowPagination = () => {
    return totalRecord > input.limit ? (
      <PaginationWrapper>
        <Pagination
          current={input.page}
          onChange={handleChangePage}
          pageSize={input.limit}
          total={accountList?.result.total}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </PaginationWrapper>
    ) : (
      <Fragment></Fragment>
    );
  };

  const handleDeleteAccount = () => {
    dispatch(
      DeleteAccountActionCreators.deleteAccountAction({
        company_manager_id: accountID,
      })
    );
  };

  const DeleteModal = () => (
    <ConfirmModalStyled
      type="confirm"
      footer={null}
      visible={isVisible}
      onCancel={handleCancel}
    >
      <div style={{ textAlign: "center" }}>
        <TextConfirm>{t("delete-account")}</TextConfirm>
        <TextConfirm>{t("is-ok")}</TextConfirm>
        <WrapperBtnConfirmModal>
          <Button
            name={t3("cancel")}
            background="#E0E0E0"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            onClick={handleCancel}
          />
          <Button
            name={t2("yes")}
            background="#FB4E4E"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            onClick={handleDeleteAccount}
          />
        </WrapperBtnConfirmModal>
      </div>
    </ConfirmModalStyled>
  );

  return (
    <>
      {isEdit ? (
        <AccountEdit setIsEdit={setIsEdit} />
      ) : (
        <Box
          title={t1("account-list-label")}
          subTitle={`${t("account-number")} ${totalRecord}`}
          padding="24px 16px 8px"
          action={
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {t3("record")}
              </div>
              <Select
                defaultValue={50}
                options={recordOptions}
                onChange={handleOnChangeSizeChange}
              />
            </div>
          }
        >
          <SearchWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <SearchWrapperLeft>
                <WrapperSelectAndBtn>
                  <Controller
                    control={control}
                    name="grade"
                    render={({ field: { onChange } }) => (
                      <Select
                        placeholder={t("select-affiliation")}
                        onChange={onChange}
                        options={classListOptions}
                      />
                    )}
                  />

                  <Button
                    type="submit"
                    name={t3("search")}
                    background="#2AC769"
                    color="#FFFFFF"
                    border="none"
                    fontSize={16}
                    fontWeight={700}
                    padding="1px 16px"
                  />
                </WrapperSelectAndBtn>
              </SearchWrapperLeft>
            </form>
            <ShowPagination />
          </SearchWrapper>

          <ContentWrapper ref={scrollAbleRef}>
            <TableStyled
              dataSource={accountList?.result.company_managers || []}
              columns={columns}
              scroll={{ x: "max-content" }}
              loading={isLoading}
            />
          </ContentWrapper>
          <ShowPagination />
          {isVisible && <DeleteModal />}
        </Box>
      )}
    </>
  );
};

export default AccountList;
