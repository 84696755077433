import {
  Action,
  ActionType,
  DataChartsResponse,
} from "models/bodyTemperature/charts";
import createReducer from "../../createReducer";

export interface DataChartsReducerType {
  loading: boolean;
  error?: string;
  dataChartsResponse?: DataChartsResponse;
}

const defaultState: DataChartsReducerType = {
  loading: false,
  error: undefined,
  dataChartsResponse: undefined,
};

export const dataChartsReducer = createReducer<DataChartsReducerType>(
  defaultState,
  {
    [ActionType.DATA_CHARTS](state: DataChartsReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DATA_CHARTS_ERROR](
      state: DataChartsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        dataChartsResponse: undefined,
      };
    },

    [ActionType.DATA_CHARTS_SUCCESS](
      state: DataChartsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        dataChartsResponse: action.payload,
      };
    },
  }
);
