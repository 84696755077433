import { IResponse } from "models";

export enum ActionType {
  SEND_QUESTIONNAIRE = "action/SEND_QUESTIONNAIRE",
  SEND_QUESTIONNAIRE_SUCCESS = "action/SEND_QUESTIONNAIRE_SUCCESS",
  SEND_QUESTIONNAIRE_ERROR = "action/SEND_QUESTIONNAIRE_ERROR",
  SEND_QUESTIONNAIRE_RESET = "action/SEND_QUESTIONNAIRE_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ISendQuestionnaireData {
  patient_id?: string | number[];
  is_send_all: boolean;
  questionnaire_id: number;
  is_send_immediately: boolean;
  start_time?: any;
  end_time?: any;
}

export interface SendQuestionnaireResponse extends IResponse {
  result: any;
}
