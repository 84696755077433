import {
  IMentalCheckScoringPayload,
  ActionType,
  IMentalCheckScoringResponse,
} from "models/mentalHealth/scoringInformation";

export const getMentalCheckScoringAction = (
  payload: IMentalCheckScoringPayload
) => {
  return {
    type: ActionType.MENTAL_CHECK_SCORING,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MENTAL_CHECK_SCORING_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IMentalCheckScoringResponse) => {
  return {
    type: ActionType.MENTAL_CHECK_SCORING_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.MENTAL_CHECK_SCORING_RESET,
  };
};
