import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Pagination, Select, Input } from "components";
import { WindowIcon } from "icons";
import { generateDynamicRecords, uuid } from "utils";

import {
  GetStudentActionCreators,
  GetDataStatisticsActionCreators,
} from "redux/rootActions";
import {
  GetStudentList,
  GetStudentListResponse,
} from "models/messagemanagement/getStudentList";
import {
  // selectIsLoading,
  getStudentListSuccessResponse,
} from "redux/messagemanagement/getstudentlist/getStudentListStates";
import {
  dataStatisticsResponse,
  selectIsLoading,
} from "redux/bodyTemperature/statistics/statisticsStates";
import { IOption } from "components/select/Select";

import {
  WrapperTable,
  TopTable,
  WrapperTextLinkBold,
  TextLinkBold,
  SendMessageBtn,
  ContentTable,
  TableStyled,
  WrapperSizeChange,
  WrapperPagination,
  WrapperActionTable,
  Message,
  WrapperMessage,
  NoDataMessage,
  WrapperActionSection,
  WrapperPaginationBottom,
} from "screens/messageDelivery/MessageDelivery.style";
import { FormStatus } from "./QuestionsTransmission";

type RowSelection = {
  selectedRowKeys: string[] | number[];
  onSelect: any;
  onSelectAll: any;
};
interface IStudentList {
  columns: any;
  showModal: (formStatus: FormStatus) => void;
  rowSelection?: RowSelection;
  selectedRows: GetStudentList[];
  allStudentsDataResponse?: GetStudentListResponse;
  onPageChange: any;
  onPerPageItemChange: any;
  setRowsSelected: any;
  handleSelectCurrentPage: any;
  isSelectAll: boolean;
  handleDeselectAllRecords: any;
  filterActions: any;
  setFilterActions: any;
  isSelectCurrentPage: boolean;
  currentPageData: any;
  total: number;
}

const StudentList: React.FC<IStudentList> = (props) => {
  const {
    columns,
    showModal,
    rowSelection,
    selectedRows,
    allStudentsDataResponse,
    onPageChange,
    onPerPageItemChange,
    setRowsSelected,
    isSelectAll,
    handleDeselectAllRecords,
    filterActions,
    setFilterActions,
    isSelectCurrentPage,
    handleSelectCurrentPage,
    currentPageData,
    total,
  } = props;
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "questions",
  });
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const studentListDataResponse = useSelector(getStudentListSuccessResponse);

  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [debouncedValue, setDebouncedValue] = useState<any>("");
  const [firstRender, setFirstRender] = useState("1");
  // const heightTable =
  //   allStudentsDataResponse && allStudentsDataResponse?.total_record > 0
  //     ? {}
  //     : { height: 900 };
  const heightTable = total && total > 0 ? {} : { height: 900 };

  const handleDeselectAll = () => {
    handleDeselectAllRecords();
    setRowsSelected([]);
  };

  const handleOnChangeName = (e: any) => {
    setFilterActions((prevState: any) => ({
      ...prevState,
      patient_name: e.target.value,
    }));
    setFirstRender(uuid());
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterActions.patient_name);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [filterActions.patient_name]);

  useEffect(() => {
    if (firstRender !== "1") {
      dispatch(
        GetDataStatisticsActionCreators.getStatisticsAction(filterActions)
      );
    }
  }, [debouncedValue]);

  useEffect(() => {
    let recordOptionsClone: IOption[] = [];
    recordOptionsClone = generateDynamicRecords(total, t1);
    setRecordOptions([...recordOptionsClone]);
  }, [total]);

  const ShowPagination = () => {
    return (
      <WrapperPagination>
        <Pagination
          current={filterActions.page}
          onChange={onPageChange}
          pageSize={filterActions.per_page}
          // total={allStudentsDataResponse?.total_record}
          total={total}
          showSizeChanger={false}
        />
      </WrapperPagination>
    );
  };

  const SubSection = () => {
    if (!selectedRows.length) return <></>;
    if (isSelectCurrentPage) {
      return (
        <WrapperMessage>
          <Message>
            <div
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              <Trans
                i18nKey="message-delivery.all-selected-message"
                count={selectedRows.length || 0}
              >
                このページのユーザー
                <strong>{selectedRows.length || 0}</strong>
                件が選択されています。
              </Trans>
            </div>
            <a onClick={handleDeselectAll}>{t("deselect")}</a>
          </Message>
        </WrapperMessage>
      );
    }
    return (
      <WrapperMessage>
        <Message>
          <div
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            <Trans
              i18nKey="message-delivery.selected-user"
              count={selectedRows.length || 0}
            >
              <strong>{selectedRows.length || 0}</strong>
              件のユーザーが選択されています。
            </Trans>
          </div>
          {(!isSelectAll || total > 50) && (
            <a onClick={handleSelectCurrentPage}>
              <Trans
                i18nKey="message-delivery.users-on-a-page"
                count={currentPageData.length || 0}
              >
                このページの
                <strong>{currentPageData.length || 0}</strong>
                件のユーザーのみを選択
              </Trans>
            </a>
          )}
        </Message>
      </WrapperMessage>
    );
  };

  return (
    <WrapperTable>
      <TopTable>
        <WrapperTextLinkBold>
          <TextLinkBold onClick={() => showModal("searchCondition")}>
            {t("search-condition")}
          </TextLinkBold>
          <WindowIcon fill="#3B3B3B" />
        </WrapperTextLinkBold>
        <SendMessageBtn
          background="#2AC769"
          color="#fff"
          name={t2("send-confirmation")}
          border="none"
          fontWeight={700}
          fontSize={16}
          bdr="6px"
          onClick={() => showModal("confirmTransmission")}
          disabled={!selectedRows.length}
        />
      </TopTable>
      <ContentTable>
        <div
          style={{
            display: "flex",
            lineHeight: "18px",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          <div style={{ fontSize: 18, color: "#3B3B3B" }}>
            {t("search-applicable")}
            <span style={{ fontWeight: 700 }}>{total || 0}</span>
            {t("people")}
          </div>
        </div>
        <WrapperActionSection>
          <span style={{ paddingTop: 5, fontSize: 16 }}>
            {t("search-by-name")}
          </span>
          <Input
            height={28}
            value={filterActions?.patient_name}
            onChange={handleOnChangeName}
          />
        </WrapperActionSection>
        <WrapperActionTable>
          <WrapperSizeChange>
            <div style={{ fontWeight: 500, fontSize: 14, marginRight: 5 }}>
              {t1("record")}
            </div>
            <Select
              defaultValue={50}
              options={recordOptions}
              onChange={onPerPageItemChange}
            />
          </WrapperSizeChange>
          {total > filterActions.per_page && <ShowPagination />}
        </WrapperActionTable>
        <SubSection />
        <div style={{ ...heightTable }}>
          <TableStyled
            rowSelection={rowSelection}
            columns={columns}
            dataSource={currentPageData}
            scroll={total > 0 ? { x: "max-content" } : {}}
            loading={isLoading}
          />
          {!currentPageData.length && (
            <>
              {studentListDataResponse ? (
                <NoDataMessage>{t("no-search-condition")}</NoDataMessage>
              ) : (
                <NoDataMessage>{t("no-data-message")}</NoDataMessage>
              )}
            </>
          )}
        </div>
        {total > filterActions.per_page && (
          <WrapperPaginationBottom>
            <ShowPagination />
          </WrapperPaginationBottom>
        )}
      </ContentTable>
    </WrapperTable>
  );
};

export default StudentList;
