import { put, takeLatest, fork, call } from "redux-saga/effects";
import { httpStatus } from "configs/httpStatus";
import { fetchStudentList } from "services/classchangemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./getStudentListClassChangeActions";
import {
  ActionType,
  IStudentListClassChangeData,
  IStudentListClassChangeResponse,
} from "models/classchangemanagement/studentListClassChange";

function* getStudentListClassChangeSaga({
  payload,
}: {
  payload: IStudentListClassChangeData;
}) {
  try {
    const response: IStudentListClassChangeResponse = yield call(
      fetchStudentList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetStudentListClassChangeWatcher() {
  yield takeLatest(
    ActionType.STUDENT_LIST_CLASS_CHANGE as any,
    getStudentListClassChangeSaga
  );
}

export default [fork(onGetStudentListClassChangeWatcher)];
