import { IResponse } from "models";

export enum ActionType {
  GET_QUESTIONNAIRES = "action/GET_QUESTIONNAIRES",
  GET_QUESTIONNAIRES_SUCCESS = "action/GET_QUESTIONNAIRES_SUCCESS",
  GET_QUESTIONNAIRES_ERROR = "action/GET_QUESTIONNAIRES_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IQuestionnaires {
  id: number;
  title: string;
  total_patient: number;
  total_patient_answered: number;
  get_response_rate: number;
  explanation: string;
  start_time: string;
  end_time: any;
  is_draft: boolean;
  created_at: string;
  updated_at: string;
}

export interface IQuestionnairesPayload {
  is_end_of_answer?: boolean;
}

export interface QuestionnairesResponse extends IResponse {
  result: {
    total: number;
    total_draft: number;
    total_inprogress: number;
    total_end_of_response: number;
    total_record: number;
    total_before_answer: number;
    questionnaires: IQuestionnaires[];
  };
}
