import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "../../../models/classmanagement/deleteClass";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { deleteClassesType } from "services/schoolclasses";
import { handleSuccesAction, handleErrorAction } from "./deleteClassActions";
import { IDeleteClassData } from "models/classmanagement/deleteClass";

function* deleteClassSaga({ payload }: { payload: IDeleteClassData }) {
  try {
    const response: IResponse = yield call(deleteClassesType, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDeleteClassWatcher() {
  yield takeLatest(ActionType.DELETE_CLASS as any, deleteClassSaga);
}

export default [fork(onDeleteClassWatcher)];
