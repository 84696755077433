import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";

import { Box, Button, Select, Pagination } from "components";
import { NextIcon, SendCompleteIcon } from "icons";
import {
  generateDynamicRecords,
  localStorageHelper,
  handleGetTimeByTimeZone,
  convertRawDataToOptions,
} from "utils";
import { IOption } from "components/select/Select";

import {
  IConfirmStudentListData,
  IConfirmStudentListItem,
} from "models/classchangemanagement/confirmStudentListClassChange";
import {
  confirmStudentListResponse,
  selectIsLoading as isLoadingConfirmStudentList,
} from "redux/classchangemanagement/getConfirmStudentData/getConfirmStudentListStates";
import {
  ClassListActionCreators,
  GetConfirmStudentListActionCreators,
  PutConfirmStudentActionCreators,
} from "redux/rootActions";
import {
  putStudentClassChangeResponse,
  selectIsLoading,
  selectIsSaved,
} from "redux/classchangemanagement/putConfirmStudentData/putConfirmStudentClassChangeStates";
import { classListDataResponse } from "redux/classlist/classListStates";
import { IPutConfirmStudentData } from "models/classchangemanagement/putConfirmStudentClassChange";

import {
  WrapperTable,
  MiddleTable,
  WrapperSizeChange,
  WrapperPaginationTop,
  WrapperIcon,
  WrapperPaginationBottom,
  SubTableStyled,
  MessageConfirm,
  TextLink,
  NoDataMessage,
  SelectWrapper,
  WrapperLeftAction,
  LabelSelect,
  NotificationMessage,
} from "./ChangeStudentAffiliation.style";
import ConfirmModal from "./ConfirmModal";

const ReflectionChanges = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });
  const { t: t2 } = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t3 } = useTranslation("translation", {
    keyPrefix: "account",
  });
  const { t: t4 } = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });

  const dispatch = useDispatch();
  const studentListResponse = useSelector(confirmStudentListResponse);
  const submitConfirmResponse = useSelector(putStudentClassChangeResponse);
  const isLoading = useSelector(selectIsLoading);
  const isLoadingTable = useSelector(isLoadingConfirmStudentList);
  const isSaved = useSelector(selectIsSaved);
  const classListResponse = useSelector(classListDataResponse);
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [savingChanges, setSavingChanges] = useState<boolean>(isLoading);
  const [confirmStudentList, setConfirmStudentList] = useState<
    IConfirmStudentListItem[]
  >([]);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [input, setInput] = useState<{
    page: number;
    per_page: number;
    current_class_id?: number;
    locale?: string;
  }>({
    page: 1,
    per_page: 50,
  });
  const [classListOptions, setClassListOptions] = useState<IOption[]>([]);

  useEffect(() => {
    dispatch(
      ClassListActionCreators.classListAction({ locale: currentLanguage })
    );
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setSavingChanges(false);
      dispatch(
        GetConfirmStudentListActionCreators.getConfirmStudentListAction({
          locale: currentLanguage,
          page: 1,
          per_page: 50,
        })
      );
    }
  }, [isLoading]);

  useEffect(() => {
    if (classListResponse && classListResponse?.result) {
      const classOptionsClone = convertRawDataToOptions(
        classListResponse?.result
      );
      setClassListOptions([
        ...[
          {
            id: 0,
            value: "",
            name: t4("all"),
          },
        ],
        ...classOptionsClone.filter((item) => item.id),
      ]);
    }
  }, [classListResponse]);

  useEffect(() => {
    if (isSaved) {
      const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
      setSavingChanges(false);
      notification.open({
        message: handleGetTimeByTimeZone("YYYY/MM/DD HH:mm", currentTimeZone),
        description: t1("reflected-changes"),
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      dispatch(PutConfirmStudentActionCreators.handleResetDataAction());
    }
  }, [isSaved]);

  useEffect(() => {
    dispatch(GetConfirmStudentListActionCreators.handleResetDataAction());
    dispatch(PutConfirmStudentActionCreators.handleResetDataAction());
  }, []);

  useEffect(() => {
    studentListResponse
      ? setConfirmStudentList(studentListResponse?.result)
      : setConfirmStudentList([]);
    studentListResponse
      ? setTotalRecord(studentListResponse?.total_record)
      : setTotalRecord(0);

    let recordOptionsClone: IOption[] = [];
    if (studentListResponse && studentListResponse.total_record > 0) {
      recordOptionsClone = generateDynamicRecords(
        studentListResponse.total_record,
        t
      );
    } else {
      recordOptionsClone = generateDynamicRecords(0, t);
    }

    setRecordOptions([...recordOptionsClone]);
  }, [studentListResponse]);

  useEffect(() => {
    const studentListParams: IConfirmStudentListData = {
      ...input,
      locale: currentLanguage,
    };
    dispatch(
      GetConfirmStudentListActionCreators.getConfirmStudentListAction(
        studentListParams
      )
    );
    setIsVisible(false);
  }, [input.page, input.per_page, submitConfirmResponse]);

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  const handleOnChangeSizeChange = (per_page: number) => {
    setInput((prevState: any) => ({ ...prevState, per_page, page: 1 }));
  };

  const handleOnChangeClass = (value: any) => {
    dispatch(
      GetConfirmStudentListActionCreators.getConfirmStudentListAction({
        ...input,
        locale: currentLanguage,
        current_class_id: value,
      })
    );
    setInput((prev: any) => ({
      ...prev,
      current_class_id: value,
    }));
  };

  const RenderPagination = () => {
    return totalRecord > input.per_page ? (
      <Pagination
        current={input.page}
        onChange={handleChangePage}
        pageSize={input.per_page}
        total={studentListResponse?.total_record}
        showSizeChanger={false}
      />
    ) : (
      <Fragment></Fragment>
    );
  };

  const columns = [
    {
      title: t("full-name"),
      dataIndex: "full_name",
      key: "displayName",
      width: 191,
      fixed: window.innerWidth < 576 ? "" : "left",
    },
    {
      title: () => {
        return (
          <div>
            {t1("current")}
            <br /> {t("grade")}
          </div>
        );
      },
      dataIndex: "old_class",
      key: "current_class",
      width: 128,
    },
    {
      title: () => {
        return (
          <div>
            {t1("current")}
            <br /> {t("attendance")}
          </div>
        );
      },
      dataIndex: "current_roll_number",
      key: "current_attendance_number",
      width: 116,
    },
    {
      title: () => {
        return (
          <div
            style={{
              position: "relative",
              color: "#2F8CAE",
              fontWeight: "bold",
            }}
          >
            <WrapperIcon>
              <NextIcon fill={"#2F8CAE"} />
            </WrapperIcon>
            <p>
              {t1("after-change")}
              <br /> {t("grade")}
            </p>
          </div>
        );
      },
      dataIndex: "new_class",
      key: "change_class",
      width: 148,
      render: (text: any) => <TextLink>{text}</TextLink>,
    },
    {
      title: () => {
        return (
          <TextLink>
            {t1("after-change")}
            <br /> {t("attendance")}
          </TextLink>
        );
      },
      dataIndex: "new_roll_number",
      key: "affer_attendance_number",
      width: 96,
      render: (text: any) => <TextLink>{text}</TextLink>,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
    },
  ];

  const handleOpen = () => {
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleOk = () => {
    const params: IPutConfirmStudentData = {
      locale: currentLanguage,
      current_class_id: input?.current_class_id,
    };
    dispatch(
      PutConfirmStudentActionCreators.putConfirmStudentClassChangeAction(params)
    );
    setIsVisible(false);
    setSavingChanges(true);
    setConfirmStudentList([]);
  };

  return (
    <Box title={t2("reflect-change-label")} padding="16px 16px 32px">
      <MessageConfirm>
        {!savingChanges ? (
          <>
            <WrapperLeftAction>
              <Button
                name={t1("reflect-the-changes")}
                padding="3.5px 16px 4.5px"
                background="#2AC769"
                bdr="6px"
                border="none"
                color="#FFFFFF"
                fontSize={16}
                lineHeight="23px"
                fontWeight={700}
                disabled={
                  !(confirmStudentList && confirmStudentList.length > 0)
                }
                onClick={handleOpen}
              />
              {confirmStudentList && confirmStudentList.length > 0 ? (
                <div>
                  <div>{t1("changes-will-be-reflected")}</div>
                  <div>{t3("is-ok")}</div>
                </div>
              ) : (
                <></>
              )}
            </WrapperLeftAction>

            <SelectWrapper>
              <LabelSelect>{t1("select-current-grade")}：</LabelSelect>
              <Select
                options={classListOptions}
                onChange={handleOnChangeClass}
                placeholder={t("please-select")}
                width="156px"
              />
            </SelectWrapper>
          </>
        ) : (
          <NotificationMessage>{t1("reflecting-changes")}</NotificationMessage>
        )}
      </MessageConfirm>
      <WrapperTable>
        <MiddleTable>
          <WrapperSizeChange
            className={
              !(confirmStudentList && confirmStudentList.length > 0)
                ? "margin-mobile"
                : ""
            }
          >
            <div style={{ fontWeight: 500 }}>{t("record")}</div>
            <Select
              defaultValue={50}
              options={recordOptions}
              onChange={handleOnChangeSizeChange}
            />
          </WrapperSizeChange>
          <WrapperPaginationTop>
            <RenderPagination />
          </WrapperPaginationTop>
        </MiddleTable>
        <SubTableStyled
          columns={columns}
          dataSource={confirmStudentList}
          scroll={{ x: "max-content" }}
          loading={isLoadingTable}
        />
        {!confirmStudentList && (
          <NoDataMessage>{t1("no-data-reflection-list")}</NoDataMessage>
        )}
        {confirmStudentList && confirmStudentList.length === 0 && (
          <NoDataMessage>{t1("no-data-reflection-list")}</NoDataMessage>
        )}
        {totalRecord > input.per_page && (
          <WrapperPaginationBottom>
            <RenderPagination />
          </WrapperPaginationBottom>
        )}
      </WrapperTable>
      {isVisible && (
        <ConfirmModal
          isVisible={isVisible}
          handleCancel={handleCancel}
          handleOk={handleOk}
        >
          <div>{t1("reflect-confirm-message")}</div>
        </ConfirmModal>
      )}
    </Box>
  );
};

export default ReflectionChanges;
