import React, { useState, useEffect, useRef } from "react";
import { Grid } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box } from "components";
import { AttentionIcon } from "icons";

import {
  GetDataChartsActionCreators,
  GetDataFeverPercentageCreators,
  GetDataProgressBarCreators,
} from "redux/rootActions";
import { dataFeverPercentageResponse } from "redux/bodyTemperature/feverPercentage/feverPercentageStates";
import { dataProgressBarResponse } from "redux/bodyTemperature/progressBar/progressBarStates";

import { settingDataResponse } from "redux/setting/settingData/settingDataStates";

import BodyTemperatureResult from "./BodyTemperatureResult";
import {
  WrapperBodyTemperature,
  Alert,
  AlertMobile,
  Tooltip4AlertMobile,
} from "./Dashboard.style";

const { useBreakpoint } = Grid;

const Dashboard = () => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const dataFeverPercentage = useSelector(dataFeverPercentageResponse);
  const settingData = useSelector(settingDataResponse);
  const dataProgressBar = useSelector(dataProgressBarResponse);

  const firstRender: any = useRef(false);
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);

  const handleToggleTooltip = () => {
    setIsVisibleTooltip(!isVisibleTooltip);
  };
  const showWarning = (temperature: number) => {
    if (!temperature) {
      return "";
    }
    const Message = () => {
      return (
        <Trans i18nKey="body-temperature.warning-first">
          {{ percent: temperature }}
        </Trans>
      );
    };
    return (
      <React.Fragment>
        {screens.md ? (
          <Alert background={temperature >= 5 ? "#FB2121" : "#EBC300"}>
            <AttentionIcon style={{ marginRight: 8 }} />
            <Message />
          </Alert>
        ) : (
          <React.Fragment>
            {isVisibleTooltip && (
              <Tooltip4AlertMobile>
                <Message />
              </Tooltip4AlertMobile>
            )}
            <AlertMobile
              background={temperature >= 5 ? "#FB2121" : "#EBC300"}
              onClick={handleToggleTooltip}
            >
              <AttentionIcon style={{ marginRight: 8 }} />
            </AlertMobile>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (
      settingData &&
      Object.keys(settingData.result).length &&
      !firstRender.current
    ) {
      firstRender.current = true;
      const { app_languages, temperature_managerment_info } =
        settingData?.result;
      app_languages.forEach((language: any) => {
        if (language.id === temperature_managerment_info.app_language_id) {
          const currentDate = moment(new Date()).format("YYYY/MM/DD");

          dispatch(
            GetDataChartsActionCreators.getDataChartsAction({
              date: currentDate,
            })
          );
          dispatch(
            GetDataFeverPercentageCreators.getDataFeverPercentage({
              date: currentDate,
            })
          );
          dispatch(
            GetDataProgressBarCreators.getDataProgressBar({
              date: currentDate,
            })
          );
        }
      });
    }
  }, [settingData]);

  return (
    <WrapperBodyTemperature>
      {dataFeverPercentage?.result >= 1 &&
        showWarning(dataFeverPercentage?.result)}
      <Box
        title={t("dashboard-label")}
        subTitle={`${t1("total-number-of-people")}${
          dataProgressBar?.result.progress_bar_data.total_patients || 0
        }${t2("people")}`}
        padding="18px 16px 8px"
      >
        <BodyTemperatureResult />
      </Box>
    </WrapperBodyTemperature>
  );
};

export default Dashboard;
