import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getHealthCheckSettingResponse } from "redux/setting/getHealthCheckSetting/getHealthCheckSettingStates";

export const headers: any = {
  date: "日付",
  class_name: "学年・クラス",
  roll_number: "出席番号",
  full_name: "氏名",
  temperature: "体温",
  health_check: "体調",
  disorder_comment: "症状コメント",
  disorder_list: "症状",
  attendance: "出席状況",
  attendance_comment: "出席状況コメント",
  family_health_check: "家族の体調不良",
  family_health_check_comment: "家族の体調に関するコメント",
  pool_join: "プール",
  pool_join_comment: "プールに関するコメント",
  marathon_question: "マラソン",
  marathon_question_comment: "マラソンに関するコメント",
  small_mental_check: "こころのチェック",
  small_mental_check_comment: "こころのチェックコメント",
  created_at: "登録時刻",
  school_name: "学校名",
  patient_id: "生徒ID",
  absent_reason: "欠席理由",
  pool_comment: "プールコメント",
  breakfast: "朝食",
  breakfast_comment: "朝食フリーコメント",
};

export const temperatureOptions = [
  {
    id: 1,
    name: "すべて",
    value: "all",
  },
  {
    id: 2,
    name: "未チェック",
    value: "1",
  },
  {
    id: 3,
    name: "熱なし(36.9度以下)",
    value: "2",
  },
  {
    id: 4,
    name: "微熱あり(37.0度~37.4度)",
    value: "3",
  },
  {
    id: 5,
    name: "発熱あり(37.5度以上)",
    value: "4 ",
  },
];

export const AllDataForFilters = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t3 } = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });

  const getHealthCheckSetting = useSelector(getHealthCheckSettingResponse);

  const pastDateOptions = [
    {
      id: 1,
      name: t("one-day"),
      value: "1",
    },
    {
      id: 2,
      name: t("seven-day"),
      value: "7",
    },
    {
      id: 3,
      name: t("fourteen-day"),
      value: "14",
    },
    {
      id: 3,
      name: t("thirty-day"),
      value: "30",
    },
  ];

  const measurementTimeForFilter = [
    {
      id: 1,
      name: t("uncheck"),
      value: "not_answered",
    },
    {
      id: 2,
      name: t3("AM"),
      value: "am",
    },
    {
      id: 3,
      name: t3("PM"),
      value: "pm",
    },
  ];
  const symptomsForFilter = [
    {
      id: 1,
      name: t("uncheck"),
      value: "-1",
    },
    {
      id: 2,
      name: t3("no-symptoms"),
      value: "136",
    },
    {
      id: 3,
      name: t3("with-symptoms"),
      value: "137",
    },
  ];
  const temperatureForFilter = [
    {
      id: 1,
      name: t("uncheck"),
      value: "1",
    },
    {
      id: 2,
      name: t("no-heat"),
      value: "2",
    },
    {
      id: 3,
      name: t("slight-fever"),
      value: "3",
    },
    {
      id: 4,
      name: t("top-fever"),
      value: "4",
    },
  ];
  const temperatureForFilter2 = [
    {
      id: 1,
      name: t("uncheck"),
      value: "not_answered",
    },
    {
      id: 2,
      name: t("no-heat"),
      value: "normal",
    },
    {
      id: 3,
      name: t("slight-fever"),
      value: "slight_fever",
    },
    {
      id: 4,
      name: t("top-fever"),
      value: "high_fever",
    },
  ];
  const familyIllnessForFilter = [
    {
      id: 1,
      name: t3("family-illness-yes"),
      value: "161",
    },
    {
      id: 2,
      name: t3("family-illness-no"),
      value: "162",
    },
  ];

  const smallCheckForFilter = [
    {
      id: 1,
      name: t("very-good"),
      value: "183",
    },
    {
      id: 2,
      name: t("good"),
      value: "184",
    },
    {
      id: 3,
      name: t("as-usual"),
      value: "185",
    },
    {
      id: 4,
      name: t("bad"),
      value: "186",
    },
    {
      id: 5,
      name: t("very-bad"),
      value: "187",
    },
  ];

  const filterOptions = [
    {
      id: 5,
      name: t("measurement-time"),
      value: "measurementTime",
    },
  ];

  const expandOptions: any = {
    is_family_health_check: {
      id: 7,
      name: t3("family-illness"),
      value: "familyIllness",
    },
    is_pool: {
      id: 8,
      name: t3("pool"),
      value: "pool_join",
    },
    is_marathon: {
      id: 9,
      name: t("marathon"),
      value: "marathon",
    },
    // is_mental_check: {
    //   id: 10,
    //   name: t("mental-check"),
    //   value: "smallCheck",
    // },
  };

  const marathonForFilter = [
    {
      id: 1,
      name: t("join"),
      value: "181",
    },
    {
      id: 2,
      name: t("do-not-participate"),
      value: "182",
    },
  ];
  const poolForFilter = [
    {
      id: 1,
      name: t("join"),
      value: "176",
    },
    {
      id: 2,
      name: t("do-not-participate"),
      value: "177",
    },
  ];

  const listCheckbox = [
    {
      key: 1,
      id: 1,
      name: t("date-condition"),
      value: "date",
      isShow: true,
    },
    {
      id: 2,
      key: 2,
      name: t("class-condition"),
      value: "class_name",
      isShow: true,
    },
    {
      id: 3,
      key: 3,
      name: t("attendance-no"),
      value: "roll_number",
      isShow: true,
    },
    {
      id: 3.5,
      key: 3.5,
      name: t("name-condition"),
      value: "full_name",
      isShow: true,
    },
    {
      id: 4,
      key: 4,
      name: t("body-temperature"),
      value: "temperature",
      isShow:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health,
    },
    {
      id: 5,
      key: 5,
      name: t("physical-condition"),
      value: "health_check",
      isShow: true,
    },
    {
      id: 6,
      key: 6,
      name: t("attendance-condition"),
      value: "attendance",
      isShow:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health,
    },
    {
      id: 7,
      key: 7,
      name: t("family-illness"),
      value: "family_health_check",
      isShow:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health,
    },
    {
      id: 8,
      key: 8,
      name: t3("pool"),
      value: "pool_join",
      isShow:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health,
    },
    {
      id: 9,
      key: 9,
      name: t3("marathon"),
      value: "marathon_question",
      isShow:
        !getHealthCheckSetting?.operation_setting?.can_use_heart_and_health,
    },
    {
      id: 10,
      key: 10,
      name: t("small-check"),
      value: "small_mental_check",
      isShow: true,
    },
    {
      id: 11,
      key: 11,
      name: t("time-of-measurement"),
      value: "created_at",
      isShow: true,
    },
    {
      id: 12,
      key: 12,
      name: t("breakfast"),
      value: "breakfast",
      isShow: true,
    },
    {
      id: 13,
      key: 13,
      name: t("breakfast_comment"),
      value: "breakfast_comment",
      isShow: getHealthCheckSetting?.health_check_setting?.is_breakfast,
    },
  ].filter((item: any) => item.isShow);

  return {
    measurementTimeForFilter,
    filterOptions,
    expandOptions,
    familyIllnessForFilter,
    temperatureForFilter,
    symptomsForFilter,
    marathonForFilter,
    poolForFilter,
    listCheckbox,
    pastDateOptions,
    smallCheckForFilter,
    temperatureForFilter2,
  };
};
