import {
  Action,
  ActionType,
  ILastNotificationTimeResponse,
} from "models/mentalHealth/lastNotificationTime";
import createReducer from "redux/createReducer";

export interface LastNotificationTimeReducerType {
  loading: boolean;
  error?: string;
  lastSendNotificationTimeResponse?: ILastNotificationTimeResponse;
}

const defaultState: LastNotificationTimeReducerType = {
  loading: false,
  error: undefined,
  lastSendNotificationTimeResponse: undefined,
};

export const lastNotificationTimeReducer =
  createReducer<LastNotificationTimeReducerType>(defaultState, {
    [ActionType.LAST_SEND_NOTIFICATION_TIME](
      state: LastNotificationTimeReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.LAST_SEND_NOTIFICATION_TIME_ERROR](
      state: LastNotificationTimeReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        lastSendNotificationTimeResponse: undefined,
      };
    },

    [ActionType.LAST_SEND_NOTIFICATION_TIME_SUCCESS](
      state: LastNotificationTimeReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        lastSendNotificationTimeResponse: action.payload,
      };
    },

    [ActionType.LAST_SEND_NOTIFICATION_TIME_RESET](
      state: LastNotificationTimeReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        lastSendNotificationTimeResponse: undefined,
      };
    },
  });
