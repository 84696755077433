import {
  IDeleteQuestionnairesPayload,
  ActionType,
  DeleteQuestionnairesResponse,
} from "models/questionnaires/deleteQuestionnaire";

export const deleteQuestionnairesAction = (
  payload: IDeleteQuestionnairesPayload
) => {
  return {
    type: ActionType.DELETE_QUESTIONNAIRES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_QUESTIONNAIRES_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: DeleteQuestionnairesResponse) => {
  return {
    type: ActionType.DELETE_QUESTIONNAIRES_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.DELETE_QUESTIONNAIRES_SUCCESS,
  };
};
