import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  PrefectureResponse,
  ActionType,
  GetPrefecturePayload,
} from "models/dashboard/prefecture";
import { httpStatus } from "configs/httpStatus";
import { fetchPrefecture } from "services/dashboard";
import { handleSuccesAction, handleErrorAction } from "./prefectureActions";

function* getPrefectureSaga({ payload }: { payload: GetPrefecturePayload }) {
  try {
    const response: PrefectureResponse = yield call(fetchPrefecture, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetPrefectureWatcher() {
  yield takeLatest(ActionType.GET_PREFECTURE as any, getPrefectureSaga);
}

export default [fork(onGetPrefectureWatcher)];
