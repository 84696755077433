import {
  Action,
  ActionType,
  IQuestionHistoryDetailResponse,
} from "models/questionnaires/questionHistoryDetail";
import createReducer from "../../createReducer";

export interface QuestionHistoryDetailReducerType {
  loading: boolean;
  error?: string;
  questionHistoryDetailResponse?: IQuestionHistoryDetailResponse;
}

const defaultState: QuestionHistoryDetailReducerType = {
  loading: false,
  error: undefined,
  questionHistoryDetailResponse: undefined,
};

export const questionHistoryDetailReducer =
  createReducer<QuestionHistoryDetailReducerType>(defaultState, {
    [ActionType.QUESTION_HISTORY_DETAIL](
      state: QuestionHistoryDetailReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.QUESTION_HISTORY_DETAIL_ERROR](
      state: QuestionHistoryDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.QUESTION_HISTORY_DETAIL_SUCCESS](
      state: QuestionHistoryDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        questionHistoryDetailResponse: action.payload,
      };
    },

    [ActionType.RESET_QUESTION_HISTORY_DETAIL](
      state: QuestionHistoryDetailReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        questionHistoryDetailResponse: [],
      };
    },
  });
