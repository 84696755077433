import { put, takeLatest, fork, call } from "redux-saga/effects";
import { httpStatus } from "configs/httpStatus";
import { uploadCSVStudentDataList } from "services/classchangemanagement";
import { handleSuccesAction, handleErrorAction } from "./uploadCSVActions";
import { ActionType } from "models/classchangemanagement/uploadCSV";
import { IResponse } from "models";

function* uploadCSVClassChangeSaga({ payload }: { payload: any }) {
  try {
    const response: IResponse = yield call(uploadCSVStudentDataList, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response));
    }
  } catch (error) {
    yield put(handleErrorAction({ message: "error" }));
  }
}
function* onUploadCSVStudentClassChangeWatcher() {
  yield takeLatest(
    ActionType.UPLOAD_CSV_CLASS_CHANGE as any,
    uploadCSVClassChangeSaga
  );
}

export default [fork(onUploadCSVStudentClassChangeWatcher)];
