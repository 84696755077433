import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IGetActivityListResponse,
} from "models/activitymanagement/activityList";
import { httpStatus } from "configs/httpStatus";
import { fetchActivitesList } from "services/activitymanagement";
import { handleSuccesAction, handleErrorAction } from "./activitiesListActions";

function* activitiesListSaga() {
  try {
    const response: IGetActivityListResponse = yield call(fetchActivitesList);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetActivityListWatcher() {
  yield takeLatest(ActionType.ACTIVITY_LIST_SCHOOL as any, activitiesListSaga);
}

export default [fork(onGetActivityListWatcher)];
