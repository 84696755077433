import React from "react";
import { PopUpIcon } from "icons";
import styled from "styled-components";
import { theme } from "theme/theme";
import { Text } from "./index";

const WrapperLinking = styled.div`
  background: ${theme.colors.white};
  cursor: pointer;
  border: 1px solid #cccccc;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 6px 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  svg {
    min-width: 16px;
  }
  ${theme.media.desktop} {
    gap: 4px;
  }
`;

type GoToAdminProps = {
  url: string;
  text: string;
};

const GoToAdmin = ({ url, text }: GoToAdminProps) => {
  return (
    <WrapperLinking
      onClick={() => {
        window.open(url, "_blank");
      }}
    >
      <Text size={14} weight={700} lnHeight="15px" color="#FB4E4E">
        {text}
      </Text>
      <PopUpIcon width={16} />
    </WrapperLinking>
  );
};

export default GoToAdmin;
