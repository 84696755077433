import {
  ActionType,
  ICancelQuestionnairePayload,
  ICancelQuestionnaireResponse,
} from "models/questionnaires/cancelQuestionnaire";

export const cancelQuestionnairesAction = (
  payload: ICancelQuestionnairePayload
) => {
  return {
    type: ActionType.CANCEL_QUESTIONNAIRE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CANCEL_QUESTIONNAIRE_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: ICancelQuestionnaireResponse) => {
  return {
    type: ActionType.CANCEL_QUESTIONNAIRE_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CANCEL_QUESTIONNAIRE_RESET,
  };
};
