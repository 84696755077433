import styled from "styled-components";
import { Collapse, Table, Modal } from "components";
import { theme } from "theme/theme";

export const WrapperClassSelect = styled.div`
  margin-bottom: 34px;
  p {
    margin-bottom: 8px;
  }
  > div {
    display: flex;
    justify-content: space-between;
  }
  button {
    margin-left: 16px;
  }
  ${theme.media.tablet} {
    > div {
      justify-content: unset;
    }
  }
`;

export const CollapseStyled = styled(Collapse)`
  position: relative;
  margin-bottom: 24px;
  .ant-collapse-header {
    width: fit-content;
  }
  .ant-collapse-header svg {
    top: 35% !important;
    right: -74px !important;
  }
  ${theme.media.tablet} {
    .ant-collapse-header svg {
      right: -32px !important;
    }
  }
`;

export const WrapperUpdating = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${theme.colors.background};
  position: relative;
  padding: 16px;
  border-radius: 12px;
  ${theme.media.laptopL} {
    width: 959px;
  }
`;

export const WrapperProgressBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  .ant-progress {
    height: 16px;
    margin-bottom: 32px;
    .ant-progress-outer {
      height: 100%;
      margin-right: 0;
      padding-right: 0;
      position: relative;
      .ant-progress-inner {
        position: absolute;
        left: 0;
      }
    }
  }
`;

export const ContainerProgressBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  p {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    color: ${theme.colors.white};
    margin-bottom: 16px;
  }
  .ant-progress-line {
    width: 660px;
  }
  .ant-progress-text {
    display: none;
  }
  .ant-progress-inner {
    border-radius: 8px;
  }
  .ant-progress-bg {
    height: 16px !important;
  }
`;

export const CSVUpdating = styled.div`
  p {
    font-size: 13.5px;
    color: ${theme.colors.text.primary};
    margin-bottom: 20px;
    line-height: 20px;
  }
  div {
    font-size: ${theme.sizes.sm};
    line-height: 20px;
    color: ${theme.colors.important};
    text-align: center;
    margin-top: 16px;
    text-decoration: underline;
    cursor: pointer;
  }
  a {
    color: ${theme.colors.important};
  }
  button {
    width: 100%;
    padding: 7px 20px 12px 14px;
  }
  ${theme.media.tablet} {
    border-right: 2px solid ${theme.colors.white};
    padding-right: 16px;
    width: 33%;
    button {
      padding: 7px 9px 12px 10px;
    }
  }
  div.download-all-tooltip {
    color: ${theme.colors.text.primary};
    font-size: ${theme.sizes.sm};
    line-height: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.regular};
    padding: 4px 6px 7px;
    border-radius: 8px;
    background: ${theme.colors.white};
    position: absolute;
    left: 0;
    right: 0;
    top: -53px;
    margin: auto;
    text-decoration: none;
    width: 170px;
    border: 2px solid ${theme.colors.border};
  }
  div.download-all-tooltip::before {
    content: "";
    position: absolute;
    top: calc(100% + 2px);
    left: 50%;
    margin-left: -4px;
    border-width: 8px 4px 4px 4px;
    border-style: solid;
    border-color: ${theme.colors.border} transparent transparent transparent;
  }
  div.download-all-tooltip::after {
    content: "";
    position: absolute;
    top: calc(100% - 1px);
    z-index: 2;
    left: 50%;
    margin-left: -4px;
    border-width: 8px 4px 4px 4px;
    border-style: solid;
    border-color: ${theme.colors.white} transparent transparent transparent;
  }
`;

export const FileUpdating = styled.div`
  ${theme.media.tablet} {
    padding-left: 17px;
    width: 67%;
  }
  ${theme.media.desktop} {
    padding-left: 20px;
  }
`;

export const WrapperTable = styled.div`
  margin-top: 28px;
  .margin-mobile {
    margin-top: -8px;
  }
  ${theme.media.tablet} {
    .margin-mobile {
      margin-top: 0px;
    }
  }
  ${theme.media.desktop} {
    margin-top: 32px;
  }
`;

export const TopTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 57px;
  > div {
    font-size: ${theme.sizes.lg};
    font-weight: ${theme.fontWeight.bold};
    line-height: 26px;
    color: ${theme.colors.text.primary};
  }
  ${theme.media.tablet} {
    margin-bottom: 24px;
    padding-bottom: 0;
  }
`;

export const MiddleTable = styled.div`
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  ${theme.media.laptopL} {
    margin-bottom: 16px;
  }
`;

export const WrapperSizeChange = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const WrapperPaginationTop = styled.div`
  text-align: center;
  margin: 24px 0 16px;
  ${theme.media.tablet} {
    text-align: right;
    margin: 0;
  }
`;

export const WrapperPaginationBottom = styled.div`
  text-align: center;
  margin: 24px 0;
  ${theme.media.tablet} {
    text-align: right;
  }
  ${theme.media.laptopL} {
    margin: 16px 0 24px;
  }
`;

export const WrapperIcon = styled.div`
  position: absolute;
  left: -53px;
  top: 3px;
  ${theme.media.tablet} {
    top: 2px;
  }
`;

export const TableStyled = styled(Table)`
  .ant-table-thead > tr > th:nth-child(3):before {
    content: unset !important;
  }
  .ant-table-thead > tr > th:nth-child(4) {
    padding-left: 31px;
    padding-bottom: 8px;
  }
  .ant-table-thead > tr > th {
    padding: 16px 16px 46px;
  }
  .ant-table-thead > tr > th:nth-child(1) {
    padding-bottom: 46px;
  }
  .ant-table-thead > tr > th:last-child {
    padding-bottom: 46px;
  }
  .ant-table-thead > tr > th:first-child:before {
    top: 50% !important;
  }
  .ant-table-thead > tr > th:before {
    top: 34% !important;
    height: 2.4em !important;
  }
  .ant-table-tbody > tr > td {
    padding-left: 16px;
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    border-right: 4px solid ${theme.colors.background};
  }
  .ant-table-tbody > tr > td:nth-child(4) {
    padding-left: 10px;
  }
  .ant-table-thead > tr > th:nth-child(6):before {
    content: unset !important;
  }
`;

export const SubTableStyled = styled(Table)`
  .ant-table-thead > tr > th:nth-child(3):before {
    content: unset !important;
  }
  .ant-table-thead > tr > th:nth-child(4) {
    padding-left: 31px;
  }
  .ant-table-thead > tr > th {
    padding: 18px 16px;
  }
  .ant-table-thead > tr > th:last-child {
    padding-bottom: 46px;
  }
  .ant-table-thead > tr > th:first-child:before {
    top: 50% !important;
  }
  .ant-table-thead > tr > th:before {
    height: 2.4em !important;
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    border-right: 4px solid ${theme.colors.background};
  }
  .ant-table-tbody > tr > td {
    padding-left: 16px;
  }
  .ant-table-tbody > tr > td:nth-child(4) {
    padding-left: 33px;
  }
  .ant-table-thead > tr > th:nth-child(6):before {
    content: unset !important;
  }
`;

export const WrapperBtn = styled.div`
  margin-bottom: 24px;
  button {
    width: 100%;
    padding: 11px 0 13px;
  }
  position: sticky;
  top: 133px;
  z-index: 999;
  > div {
    width: 343px;
    position: absolute;
    right: -16px;
    padding: 16px;
    background: ${theme.colors.white};
    top: -73px;
    border-radius: 0px 0px 8px 8px;
  }
  ${theme.media.tablet} {
    > div {
      width: unset;
    }
    button {
      width: 160px;
    }
  }
`;

export const WrapperAction = styled.div`
  position: relative;
  button {
    position: absolute;
    top: 20px;
    right: 0;
  }
  > div:first-child {
    margin-bottom: 24px;
  }
  ${theme.media.tablet} {
    display: flex;
    button {
      position: unset;
      margin-left: 16px;
      height: 31px;
      margin-top: 20px;
    }
    > div:first-child {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }
`;

export const TextLink = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.md};
  line-height: 18px;
  color: ${theme.colors.textLink};
`;

export const MessageConfirm = styled.div`
  margin-top: 8px;
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 20px 16px 24px;
  }
  .ant-modal-footer {
    padding: 0px 16px 20px;
    border: unset;
  }
  div {
    line-height: 16px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 24px 24px 32px 24px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

export const FailModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 16px;
  }
  .ant-modal-footer {
    padding: 0px 16px 20px;
    border: unset;
  }
  div {
    line-height: 16px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 16px 24px 5px 16px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 144px;
    line-height: 23px;
    padding: 3.5px 15px 4.5px;
  }
  button:last-child {
    width: 151px;
  }
  ${theme.media.tablet} {
    justify-content: flex-end;
    button:last-child {
      margin-left: 16px;
    }
    button {
      width: 112px !important;
    }
  }
`;

export const WrapperBtnOKFooter = styled.div`
  button {
    width: 100%;
    line-height: 23px;
    padding: 3.5px 15px 4.5px;
  }

  ${theme.media.tablet} {
    button {
      width: 112px !important;
    }
  }
`;

export const NoDataMessage = styled.div`
  margin-top: 40px;
  margin-left: 16px;
  margin-bottom: 24px;
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.regular};
  line-height: 18px;
`;

export const WrapperErrorMessage = styled.div`
  display: flex;
`;

export const WrapText = styled.div`
  padding-left: 16px;
  ${theme.media.tablet} {
    span:first-child {
      margin-right: 10px;
    }
  }
`;

export const ConfirmModalStyled = styled(Modal)`
  width: calc(100vw - 4px) !important;
  .ant-modal-body {
    padding: 32px 25px;
  }
  ${theme.media.tablet} {
    width: 532px !important;
  }
  ${theme.media.laptopL} {
    .ant-modal-body {
      padding: 32px;
    }
  }
`;

export const TextConfirm = styled.div`
  font-size: 22px;
  font-weight: ${theme.fontWeight.bold};
  line-height: 40px;
  color: ${theme.colors.text.primary};
`;

export const WrapperBtnConfirmModal = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  button {
    width: 151px;
    padding: 7px 0;
  }
  button:first-child {
    margin-right: 16px;
  }
  ${theme.media.tablet} {
    display: block;
    justify-content: unset;
    button {
      width: 128px;
      padding: 7px 0;
    }
    button:first-child {
      margin-right: 40px;
    }
  }
  ${theme.media.laptopL} {
  }
`;

export const WapperError = styled.div`
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #fb2121;
    border: 1px solid #fb2121;
    box-sizing: border-box;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    padding: 6px 8px;
    text-align: center;
    position: absolute;
    z-index: 99;
    top: 45px;
    min-width: 260px;
    background: #fff;
    ::before {
      content: "";
      position: absolute;
      top: -4px;
      left: 17px;
      width: 7px;
      height: 10px;
      background: #ffffff;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: 1;
    }
    ::after {
      content: "";
      position: absolute;
      top: -5px;
      left: 18px;
      height: 7px;
      width: 7px;
      background: #fb2121;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .last-p {
    top: -25px;
  }
  .last-p::after {
    bottom: -5px;
    top: auto;
  }
  .last-p::before {
    bottom: -4px;
    left: 19px;
    top: auto;
  }
`;

export const WrapperTooltipRight = styled.div`
  position: absolute;
  top: -13px;
  right: 0;
`;

export const TooltipRightStyled = styled.div`
  position: absolute;
  display: flex;
  width: 158px;
  background: ${theme.colors.white};
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.regular};
  line-height: 16px;
  padding: 7px 8px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.border};
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: 19px;
    left: -4px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 19px;
    left: -2px;
    width: 6px;
    height: 7px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ant-select-selector {
    background-color: #ffffff !important;
  }
  ${theme.media.tablet} {
    height: 31px;
  }
`;

export const WrapperLeftAction = styled.div`
  display: flex;
  flex-direction: column;
  > button {
    order: 2;
    margin-bottom: 32px;
    height: 31px;
  }
  > div {
    order: 1;
    margin-bottom: 12px;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    line-height: 23px;
    color: ${theme.colors.important};
  }
  ${theme.media.tablet} {
    flex-direction: row;
    > button {
      order: 1;
      width: 144px;
      margin-right: 8px;
      margin-bottom: 0px;
    }
    > div {
      order: 2;
      margin-bottom: 0px;
    }
  }
  ${theme.media.laptopL} {
    > div {
      display: flex;
      align-items: center;
    }
  }
`;

export const LabelSelect = styled.div`
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.medium};
  margin-right: 5;
  line-height: 14px;
`;

export const NotificationMessage = styled.div`
  font-size: ${theme.sizes.lg};
  line-height: 26px;
  font-weight: ${theme.fontWeight.regular};
  color: #3b3b3b;
  margin-bottom: -21px;
  margin-top: -8px;
  width: 100%;
  text-align: left;
  ${theme.media.tablet} {
    margin-bottom: 5px;
    margin-top: 0;
  }
`;
