import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ICompleteRequestPayload,
  ActionType,
  ICompleteRequestResponse,
} from "models/consultationRequest/completeRequest";
import { httpStatus } from "configs/httpStatus";
import { postCompleteRequest } from "services/consultationRequest";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./completeRequestActions";

function* completeRequestSaga({
  payload,
}: {
  payload: ICompleteRequestPayload;
}) {
  try {
    const response: ICompleteRequestResponse = yield call(
      postCompleteRequest,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCompleteRequestWatcher() {
  yield takeLatest(ActionType.COMPLETE_REQUEST as any, completeRequestSaga);
}

export default [fork(onCompleteRequestWatcher)];
