import React, { useState } from "react";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Button } from "components";
import { CheckedIcon, EndIcon, CopyIcon, SendIcon, EditIcon } from "icons";
import {
  handleGetTimeByTimeZone,
  localStorageHelper,
  handleCondition,
} from "utils";

import {
  QuestionnairesDetailActionCreators,
  DuplicateQuestionActionCreators,
  DoneQuestionActionCreators,
} from "redux/rootActions";
import { IQuestionnaires } from "models/questionnaires/questionnairesByType";
import { selectIsLoading } from "redux/questionnaires/duplicateQuestion/duplicateQuestionStates";

import {
  WrapperContent,
  Card,
  Tag,
  GroupButton,
  DivIcon,
  Message,
  CardLabel,
  CardContent,
  CardInfo,
  CardTitle,
  WrapperTopContent,
  Description,
} from "./Questions.style";
import { ScreenState } from "./Questions";
import { ConfirmModal } from "./components";
import { TextConfirm } from "screens/messageDelivery/MessageDelivery.style";
import { format, formatDateTime } from "./const";

const { useBreakpoint } = Grid;

interface IRecentlyUsedForms {
  setScreen: React.Dispatch<React.SetStateAction<ScreenState>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  data: IQuestionnaires[];
}

const RecentlyUsedForms: React.FC<IRecentlyUsedForms> = ({
  setScreen,
  setStatus,
  data,
}) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const isLoadingDuplicateQuestions = useSelector(selectIsLoading);

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number>(0);

  const handleViewQuestionDetail = (id: number) => {
    dispatch(
      QuestionnairesDetailActionCreators.getQuestionnaireDetailAction({ id })
    );
    setScreen("create");
  };

  const handleEndSurvey = () => {
    setIsVisibleModal(false);
    dispatch(
      DoneQuestionActionCreators.doneQuestionAction({
        id: itemId,
      })
    );
  };

  const handleShowTime = (startDate: string, endDate: string) => {
    if ((!startDate && !endDate) || !startDate) return "-";

    if (startDate && !endDate) {
      return (
        handleGetTimeByTimeZone(formatDateTime, currentTimeZone, startDate) +
        "~"
      );
    }

    return (
      handleGetTimeByTimeZone(formatDateTime, currentTimeZone, startDate) +
      " ~ " +
      handleGetTimeByTimeZone(formatDateTime, currentTimeZone, endDate)
    );
  };

  const TagStatus = ({ item }: any) => {
    if (!item.start_time) {
      return <Tag background="#EBC300">{t("draft")}</Tag>;
    }

    if (handleCondition("th1", item.start_time, item.end_time)) {
      return <Tag background="#2AC769">{t("before-answer")}</Tag>;
    }

    if (handleCondition("th2", item.start_time, item.end_time)) {
      return <Tag background="#83BCE2">{t("answering")}</Tag>;
    }

    return <Tag background="#CCCCCC">{t("end-of-answer")}</Tag>;
  };

  const ActionGroup = ({ item }: any) => {
    if (!item?.start_time) {
      return (
        <>
          <DivIcon
            onClick={() => {
              handleViewQuestionDetail(item.id);
              setStatus("update");
            }}
          >
            <EditIcon fill="#2F8CAE" />
            <p>{t1("edit")}</p>
          </DivIcon>
          <DivIcon
            onClick={() => {
              setScreen("transmission");
              dispatch(
                QuestionnairesDetailActionCreators.getQuestionnaireDetailAction(
                  { id: item.id }
                )
              );
            }}
          >
            <SendIcon fill="#2F8CAE" />
            <p>{t("sent")}</p>
          </DivIcon>
        </>
      );
    }

    if (handleCondition("th1", item.start_time, item.end_time)) {
      return (
        <DivIcon
          onClick={() => {
            handleViewQuestionDetail(item.id);
            setStatus("view-detail");
          }}
        >
          <CheckedIcon fill="#2F8CAE" />
          <p>{t("confirmation")}</p>
        </DivIcon>
      );
    }

    return (
      <>
        <DivIcon
          onClick={() => {
            handleViewQuestionDetail(item.id);
            setStatus("view-detail");
          }}
        >
          <CheckedIcon fill="#2F8CAE" />
          <p>{t("confirmation")}</p>
        </DivIcon>
        <DivIcon
          onClick={() => {
            setIsVisibleModal(true);
            setItemId(item.id);
          }}
        >
          <EndIcon fill="#2F8CAE" />
          <p>{t("end")}</p>
        </DivIcon>
      </>
    );
  };

  return (
    <WrapperContent>
      <WrapperTopContent>
        <Button
          background="#2AC769"
          color="#fff"
          padding="11px 24px"
          name={t("create-a-survey")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          onClick={() => {
            setScreen("create");
            setStatus("create");
          }}
        />
        <div>{t("recently-used-form")}</div>
      </WrapperTopContent>

      {data.length > 0 &&
        data.map((item) => {
          return (
            <Card key={item.id}>
              <CardLabel>
                <TagStatus item={item} />
                <div>
                  {t("number-of-answers")}
                  <span style={{ color: "#83BCE2" }}>
                    {item.total_patient_answered}/{item.total_patient}
                  </span>
                </div>
              </CardLabel>
              <CardContent>
                <CardInfo>
                  <CardTitle>{item.title}</CardTitle>
                  <Description>
                    {screens.xs ? (
                      <>
                        <div>
                          {t("created-date")}:
                          {moment(item.created_at).format(format)}
                        </div>
                        <div>
                          {t("last-revision-date")}:
                          {moment(item.updated_at).format(format)}
                        </div>
                      </>
                    ) : (
                      <div>
                        {t("created-date")}:
                        {moment(item.created_at).format(format)}｜
                        {t("last-revision-date")}:
                        {moment(item.updated_at).format(format)}｜{t("time")}:
                        {handleShowTime(item.start_time, item.end_time)}
                      </div>
                    )}
                  </Description>
                </CardInfo>

                <GroupButton>
                  <ActionGroup item={item} />
                  <DivIcon
                    onClick={() => {
                      if (!isLoadingDuplicateQuestions) {
                        dispatch(
                          DuplicateQuestionActionCreators.duplicateQuestionAction(
                            { id: item.id }
                          )
                        );
                      }
                    }}
                  >
                    <CopyIcon fill="#2F8CAE" />
                    <p>{t("copy")}</p>
                  </DivIcon>
                  {/* <DivIcon
                    style={{ marginRight: "0px" }}
                    onClick={() => {
                      dispatch(
                        DeleteQuestionnaireActionCreators.deleteQuestionnairesAction(
                          { id: item.id }
                        )
                      );
                    }}
                  >
                    <TrashIcon fill="#FB4E4E" />
                    <p style={{ color: "#FB4E4E" }}>{t1("delete")}</p>
                  </DivIcon> */}
                </GroupButton>
              </CardContent>
            </Card>
          );
        })}

      {!data.length && (
        <Message style={{ paddingBottom: "200px" }}>
          {t("click-the-create-survey")}
        </Message>
      )}
      {isVisibleModal && (
        <ConfirmModal
          isVisible={isVisibleModal}
          close={() => {
            setIsVisibleModal(false);
          }}
          ok={handleEndSurvey}
        >
          <TextConfirm>
            {t("confirm-close-draft")}
            <br></br>
            {t2("are-you-sure")}
          </TextConfirm>
        </ConfirmModal>
      )}
    </WrapperContent>
  );
};

export default RecentlyUsedForms;
