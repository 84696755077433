import { IResponse } from "models";

export enum ActionType {
  DELETE_QUESTIONNAIRES = "action/DELETE_QUESTIONNAIRES",
  DELETE_QUESTIONNAIRES_SUCCESS = "action/DELETE_QUESTIONNAIRES_SUCCESS",
  DELETE_QUESTIONNAIRES_ERROR = "action/DELETE_QUESTIONNAIRES_ERROR",
  DELETE_QUESTIONNAIRES_RESET = "action/DELETE_QUESTIONNAIRES_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDeleteQuestionnairesPayload {
  id: number;
}

export interface DeleteQuestionnairesResponse extends IResponse {}
