import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  QuestionnairesDetailResponse,
} from "models/questionnaires/questionnairesDetail";
import { httpStatus } from "configs/httpStatus";
import { fetchQuestionnariesDetail } from "services/questionnaires";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./questionnairesDetailActions";

function* getQuestionnariesDetailSaga({
  payload,
}: {
  payload: { id: number };
}) {
  try {
    const response: QuestionnairesDetailResponse = yield call(
      fetchQuestionnariesDetail,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetQuestionnariesDetailSagaSubmitWatcher() {
  yield takeLatest(
    ActionType.GET_QUESTIONNAIRES_DETAIL as any,
    getQuestionnariesDetailSaga
  );
}

export default [fork(onGetQuestionnariesDetailSagaSubmitWatcher)];
