import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/classmanagement/updateClass";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { updateSchoolClass } from "services/schoolclasses";
import { handleSuccesAction, handleErrorAction } from "./updateClassActions";
import { IUpdateClassData } from "models/classmanagement/updateClass";

function* updateClassSaga({ payload }: { payload: IUpdateClassData }) {
  try {
    const response: IResponse = yield call(updateSchoolClass, payload);
    if (
      response.status === httpStatus.StatusCreated ||
      response.status === httpStatus.StatusOK
    ) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateClassWatcher() {
  yield takeLatest(ActionType.UPDATE_CLASS as any, updateClassSaga);
}

export default [fork(onUpdateClassWatcher)];
