import { Action, ActionType } from "models/mentalHealth/updateMentalCheck";
import { IResponse } from "models";

import createReducer from "redux/createReducer";

export interface UpdateMentalCheckReducerType {
  loading: boolean;
  error?: any;
  saved: boolean;
  updateMentalCheckResponse?: IResponse;
}

const defaultState: UpdateMentalCheckReducerType = {
  loading: false,
  error: null,
  saved: false,
  updateMentalCheckResponse: undefined,
};

export const updateMentalCheckReducer =
  createReducer<UpdateMentalCheckReducerType>(defaultState, {
    [ActionType.UPDATE_MENTAL_CHECK](state: UpdateMentalCheckReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_MENTAL_CHECK_ERROR](
      state: UpdateMentalCheckReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_MENTAL_CHECK_SUCCESS](
      state: UpdateMentalCheckReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: true,
        updateMentalCheckResponse: action.payload,
      };
    },

    [ActionType.RESET_UPDATE_MENTAL_CHECK](
      state: UpdateMentalCheckReducerType
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        updateMentalCheckResponse: undefined,
      };
    },
  });
