import {
  Action,
  ActionType,
  PDFDownloadTypeInfoResponse,
} from "models/mentalHealth/pdfDownloadTypeInfo";
import createReducer from "redux/createReducer";

export interface PDFDownloadTypeInfoReducerType {
  loading: boolean;
  error?: string;
  pdfDownloadTypeInfoResponse?: PDFDownloadTypeInfoResponse;
}

const defaultState: PDFDownloadTypeInfoReducerType = {
  loading: false,
  error: undefined,
  pdfDownloadTypeInfoResponse: undefined,
};

export const pdfDownloadTypeInfoReducer =
  createReducer<PDFDownloadTypeInfoReducerType>(defaultState, {
    [ActionType.PDF_DOWNLOAD_TYPE_INFO](state: PDFDownloadTypeInfoReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.PDF_DOWNLOAD_TYPE_INFO_ERROR](
      state: PDFDownloadTypeInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.PDF_DOWNLOAD_TYPE_INFO_SUCCESS](
      state: PDFDownloadTypeInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        pdfDownloadTypeInfoResponse: action.payload,
      };
    },

    [ActionType.PDF_DOWNLOAD_TYPE_INFO_RESET_DATA](
      state: PDFDownloadTypeInfoReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        pdfDownloadTypeInfoResponse: [],
      };
    },
  });
