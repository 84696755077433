import { Action, ActionType, VenuesListResponse } from "models/vaccine/venues";
import createReducer from "../../createReducer";

export interface VenuesReducerType {
  loading: boolean;
  error?: string;
  venuesResponse?: VenuesListResponse;
}

const defaultState: VenuesReducerType = {
  loading: false,
  error: undefined,
  venuesResponse: undefined,
};

export const venuesReducer = createReducer<VenuesReducerType>(defaultState, {
  [ActionType.VENUES_LIST](state: VenuesReducerType) {
    return {
      ...state,
      loading: true,
    };
  },

  [ActionType.VENUES_LIST_ERROR](
    state: VenuesReducerType,
    action: Action<number>
  ) {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  },

  [ActionType.VENUES_LIST_SUCCESS](
    state: VenuesReducerType,
    action: Action<number>
  ) {
    return {
      ...state,
      loading: false,
      error: null,
      venuesResponse: action.payload,
    };
  },
});
