import {
  ActionType,
  DepartmentListResponse,
} from "models/account/departmentList";

export const getDepartmentListAction = (payload: any) => {
  return {
    type: ActionType.ACCOUNT_DEPARTMENT_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.ACCOUNT_DEPARTMENT_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DepartmentListResponse) => {
  return {
    type: ActionType.ACCOUNT_DEPARTMENT_LIST_SUCCESS,
    payload,
  };
};
