import { IResponse } from "models";

export enum ActionType {
  ANSWER_HISTORY_LIST = "action/ANSWER_HISTORY_LIST",
  ANSWER_HISTORY_LIST_SUCCESS = "action/ANSWER_HISTORY_LIST_SUCCESS",
  ANSWER_HISTORY_LIST_ERROR = "action/ANSWER_HISTORY_LIST_ERROR",
  RESET_ANSWER_HISTORY_LIST = "action/RESET_ANSWER_HISTORY_LIST",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IAnswerHistoryData {
  id: number;
  is_answered: string | boolean;
  locale: string;
  page: number;
  limit: number;
}

export interface IAnswerHistory {
  id: number;
  full_name: string;
  is_answered: boolean;
  roll_number: number;
  classes_name: string;
}

export interface IAnswerHistoryResponse extends IResponse {
  result: {
    total: number;
    total_answered: number;
    total_no_answered: number;
    total_record: number;
    histories: IAnswerHistory[];
  };
}
