import * as React from "react";

function LoadingDot2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      xmlns="http://www.w3.org/2000/svg"
      stroke="transparent"
      {...props}
    >
      <rect width="37" height="37" fill="transparent" />
      <circle cx="19" cy="19" r="4" fill="white" />
      <circle cx="19" cy="19" r="4" fill="#83BCE2" fillOpacity="0.5" />
      <circle cx="7" cy="19" r="4" fill="#2F8CAE" />
      <circle cx="31" cy="19" r="4" fill="#83BCE2" />
    </svg>
  );
}

export default LoadingDot2;
