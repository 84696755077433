import * as React from "react";

function HouseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6399 16.7601H13.6399C13.3747 16.7601 13.1203 16.6547 12.9328 16.4672C12.7452 16.2796 12.6399 16.0253 12.6399 15.7601C12.6399 15.4949 12.7452 15.2405 12.9328 15.0529C13.1203 14.8654 13.3747 14.7601 13.6399 14.7601H14.6399C14.9051 14.7601 15.1595 14.8654 15.347 15.0529C15.5345 15.2405 15.6399 15.4949 15.6399 15.7601C15.6399 16.0253 15.5345 16.2796 15.347 16.4672C15.1595 16.6547 14.9051 16.7601 14.6399 16.7601Z"
        fill={props.fill}
      />
      <path
        d="M18.3101 16.7601H17.3101C17.0448 16.7601 16.7905 16.6547 16.603 16.4672C16.4154 16.2796 16.3101 16.0253 16.3101 15.7601C16.3101 15.4949 16.4154 15.2405 16.603 15.0529C16.7905 14.8654 17.0448 14.7601 17.3101 14.7601H18.3101C18.5753 14.7601 18.8296 14.8654 19.0172 15.0529C19.2047 15.2405 19.3101 15.4949 19.3101 15.7601C19.3101 16.0253 19.2047 16.2796 19.0172 16.4672C18.8296 16.6547 18.5753 16.7601 18.3101 16.7601Z"
        fill={props.fill}
      />
      <path
        d="M17.96 12.79H15.96C15.8274 12.79 15.7002 12.7374 15.6064 12.6436C15.5126 12.5498 15.46 12.4226 15.46 12.29C15.46 12.1574 15.5126 12.0302 15.6064 11.9365C15.7002 11.8427 15.8274 11.79 15.96 11.79H17.96C18.0926 11.79 18.2198 11.8427 18.3135 11.9365C18.4073 12.0302 18.46 12.1574 18.46 12.29C18.4574 12.4218 18.4039 12.5475 18.3107 12.6407C18.2175 12.734 18.0918 12.7875 17.96 12.79Z"
        fill={props.fill}
      />
      <path
        d="M15.96 12.67C15.8274 12.67 15.7002 12.6174 15.6064 12.5236C15.5126 12.4299 15.46 12.3027 15.46 12.17V10.17C15.46 10.0374 15.5126 9.91024 15.6064 9.81647C15.7002 9.7227 15.8274 9.67004 15.96 9.67004C16.0918 9.6726 16.2175 9.72612 16.3107 9.81934C16.4039 9.91255 16.4574 10.0382 16.46 10.17V12.17C16.46 12.3027 16.4073 12.4299 16.3135 12.5236C16.2198 12.6174 16.0926 12.67 15.96 12.67Z"
        fill={props.fill}
      />
      <path
        d="M25.09 24.0001H25V16.23C25.0014 16.0399 24.9485 15.8533 24.8475 15.6921C24.7466 15.5309 24.6017 15.4018 24.43 15.3201L21.96 14.15V10.5701C21.9593 10.4114 21.9208 10.2552 21.8478 10.1144C21.7747 9.97354 21.6692 9.85209 21.54 9.76007L16.54 6.19006C16.3697 6.06721 16.165 6.0011 15.955 6.0011C15.745 6.0011 15.5403 6.06721 15.37 6.19006L10.37 9.76007C10.2426 9.85316 10.139 9.97507 10.0678 10.1158C9.99653 10.2566 9.95959 10.4123 9.96 10.5701V14.1901L7.57 15.3201C7.39829 15.4018 7.25346 15.5309 7.1525 15.6921C7.05155 15.8533 6.99865 16.0399 7.00001 16.23V24.0001H6.91001C6.66069 24.0226 6.42889 24.1378 6.2604 24.3229C6.09191 24.5081 5.99899 24.7497 6.00001 25.0001C5.99899 25.2504 6.09191 25.492 6.2604 25.6771C6.42889 25.8623 6.66069 25.9775 6.91001 26.0001H25.09C25.3393 25.9775 25.5711 25.8623 25.7396 25.6771C25.9081 25.492 26.001 25.2504 26 25.0001C26.001 24.7497 25.9081 24.5081 25.7396 24.3229C25.5711 24.1378 25.3393 24.0226 25.09 24.0001ZM23 16.86V24.0001H22V16.3701L23 16.86ZM16 8.23004L20 11.09V24.0001H19V19.61C19 19.3448 18.8947 19.0905 18.7071 18.903C18.5196 18.7154 18.2652 18.61 18 18.61H14C13.7348 18.61 13.4804 18.7154 13.2929 18.903C13.1054 19.0905 13 19.3448 13 19.61V24.0001H12V11.09L16 8.23004ZM17 24.0001H15V20.61H17V24.0001ZM9.00001 16.86L10 16.41V24.0001H9.00001V16.86Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default HouseIcon;
