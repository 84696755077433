import React from "react";
import { useTranslation } from "react-i18next";
import { ModalProps } from "antd";

import { CloseIcon } from "icons";
import { Button } from "components";
import { ModalStyled } from "./MentalHealth.style";

interface IConfirmModal extends ModalProps {
  handleCancel?: () => void;
  handleOk?: () => void;
  isVisible?: boolean;
  title: string;
  buttonName: string;
}

const ConfirmCreateModal: React.FC<IConfirmModal> = ({
  handleCancel,
  handleOk,
  isVisible,
  children,
  title,
  buttonName,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  return (
    <ModalStyled
      closeIcon={<CloseIcon fill="#3B3B3B" onClick={handleCancel} />}
      title={title}
      footer={
        <>
          <Button
            onClick={handleCancel}
            background="#E0E0E0"
            color="#fff"
            name={t("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            padding="12px 24px"
          />

          <Button
            onClick={handleOk}
            background="#2AC769"
            color="#fff"
            name={buttonName}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            padding="12px 32px"
          />
        </>
      }
      visible={isVisible}
    >
      {children}
    </ModalStyled>
  );
};

export default ConfirmCreateModal;
