import * as React from "react";

function CommentIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6818 1H5.31818C2.93332 1 1 3.08934 1 5.66667V10.3333C1 12.9107 2.93332 15 5.31818 15H15.6818C18.0667 15 20 12.9107 20 10.3333V5.66667C20 3.08934 18.0667 1 15.6818 1Z"
        fill="white"
        stroke={props.fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6 6H12"
        stroke={props.fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6 10H12"
        stroke={props.fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15 10H16"
        stroke={props.fill}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CommentIcon;
