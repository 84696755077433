import * as React from "react";

function AttentionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.87 25.9292H24.13L24.9929 14.97L25.1527 10.1953H19.8473L20.0071 14.97L20.87 25.9292ZM22.5 34.4531C24.098 34.4531 25.3125 33.2995 25.3125 31.6332C25.3125 29.9348 24.098 28.7812 22.5 28.7812C20.902 28.7812 19.6875 29.9348 19.6875 31.6332C19.6875 33.2995 20.87 34.4531 22.5 34.4531Z"
        fill="white"
      />
      <circle cx="22.5" cy="22.5" r="20.5" stroke="white" strokeWidth="4" />
    </svg>
  );
}

export default AttentionIcon;
