import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IMentalCheckTotalPayload,
  ActionType,
  IMentalCheckTotalResponse,
} from "models/mentalHealth/mentalHealthTotal";
import { httpStatus } from "configs/httpStatus";
import { getMentalCheckTotalInfo } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./mentalHealthTotalActions";

function* getMentalCheckTotalInformationSaga({
  payload,
}: {
  payload: IMentalCheckTotalPayload;
}) {
  try {
    const response: IMentalCheckTotalResponse = yield call(
      getMentalCheckTotalInfo,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetMentalCheckTotalInformationWatcher() {
  yield takeLatest(
    ActionType.MENTAL_CHECK_TOTAL as any,
    getMentalCheckTotalInformationSaga
  );
}

export default [fork(onGetMentalCheckTotalInformationWatcher)];
