import { IResponse } from "../index";

export enum ActionType {
  SEARCH_CONDITION = "action/SEARCH_CONDITION",
  SEARCH_CONDITION_SUCCESS = "action/SEARCH_CONDITION_SUCCESS",
  SEARCH_CONDITION_ERROR = "action/SEARCH_CONDITION_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ISearchConditionResponse extends IResponse {
  result: {
    is_pool_active?: boolean;
    is_marathon_active?: boolean;
    is_family_illness_active?: boolean;
    is_attendance_active?: boolean;
  };
}
