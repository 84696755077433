import React from "react";
import styled from "styled-components";
import { TreeSelect, TreeSelectProps } from "antd";
import { SmallCloseIcon } from "icons";
import { WrapperErrorMessage, ErrorMessage } from "theme/CommonStyle";
import { theme } from "theme/theme";

interface ITreeSelect extends TreeSelectProps<any> {
  label?: string;
  error?: React.ReactNode;
}

const FormControl = styled.div`
  position: relative;
  margin: 0 0 22px 0;
`;

const Label = styled.div`
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.regular};
  line-height: 12px;
  margin-bottom: 8px;
`;

const TreeSelectStyled = styled(TreeSelect)`
  .ant-select-selector {
    display: block;
    border-radius: 6px !important;
    border: 1px solid ${theme.colors.textFieldBackground}!important;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
    padding: 6px 12px;
  }
  .ant-select-selection-placeholder {
    top: 18px;
    font-size: ${theme.sizes.sm};
  }
  .ant-select-selection-item {
    font-size: ${theme.sizes.xs};
    line-height: 12px;
    color: ${theme.colors.text.primary};
    border-radius: 4px;
    height: 19px;
    padding: 3px 4px 4px 4px;
    border: none;
  }
`;

const WrapperTreeSelect = styled.div`
  .ant-select-tree-title {
    font-weight: bold;
  }
`;

const TreeSelectCustom: React.FC<ITreeSelect> = ({
  label,
  error,
  children,
  ...rest
}) => {
  return (
    <FormControl>
      {label && <Label>{label}</Label>}
      <WrapperTreeSelect>
        <TreeSelectStyled
          style={{ width: "100%" }}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          allowClear
          multiple
          treeDefaultExpandAll
          showSearch={false}
          removeIcon={<SmallCloseIcon />}
          {...rest}
        >
          {children}
        </TreeSelectStyled>
      </WrapperTreeSelect>

      {error && (
        <WrapperErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
        </WrapperErrorMessage>
      )}
    </FormControl>
  );
};

export default TreeSelectCustom;
