import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  CSVDownloadAllQuestionResponse,
  ActionType,
  IAllQuestionDownloadData,
} from "models/questionnaires/downloadCSVAllQuestions";
import { httpStatus } from "configs/httpStatus";
import { downloadCSVAllQuestions } from "services/questionnaires";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./downloadCSVAllQuestionsActions";

function* downloadCSVAllQuestionsSaga({
  payload,
}: {
  payload: IAllQuestionDownloadData;
}) {
  try {
    const response: CSVDownloadAllQuestionResponse = yield call(
      downloadCSVAllQuestions,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDownloadCSVAllQuestionsWatcher() {
  yield takeLatest(
    ActionType.CSV_DOWNLOAD_ALL_QUESTION as any,
    downloadCSVAllQuestionsSaga
  );
}

export default [fork(onDownloadCSVAllQuestionsWatcher)];
