import {
  Action,
  ActionType,
  IUpdateMessageResponse,
} from "models/setting/updateMessage";
import createReducer from "../../createReducer";

export interface UpdateMessageSettingReducerType {
  loading: boolean;
  error?: string;
  updateMessageSettingResponse?: IUpdateMessageResponse;
}

const defaultState: UpdateMessageSettingReducerType = {
  loading: false,
  error: undefined,
  updateMessageSettingResponse: undefined,
};

export const updateMessageSettingReducer =
  createReducer<UpdateMessageSettingReducerType>(defaultState, {
    [ActionType.UPDATE_MESSAGE_SETTING](
      state: UpdateMessageSettingReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_MESSAGE_SETTING_ERROR](
      state: UpdateMessageSettingReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_MESSAGE_SETTING_SUCCESS](
      state: UpdateMessageSettingReducerType,
      action: Action<IUpdateMessageResponse>
    ) {
      return {
        ...state,
        loading: false,
        updateMessageSettingResponse: action.payload,
      };
    },

    [ActionType.RESET_MESSAGE_SETTING](state: UpdateMessageSettingReducerType) {
      return {
        ...state,
        loading: false,
        error: undefined,
        updateMessageSettingResponse: {},
      };
    },
  });
