import { IResponse } from "models";

export enum ActionType {
  ACCOUNT_LIST = "action/ACCOUNT_LIST",
  ACCOUNT_LIST_SUCCESS = "action/ACCOUNT_LIST_SUCCESS",
  ACCOUNT_LIST_ERROR = "action/ACCOUNT_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

interface IManager {
  id: number;
  email: string;
  department: string;
}

export interface AccountListResponse extends IResponse {
  result: {
    company_managers: IManager[];
    total: number;
  };
}
