import * as React from "react";

const SvgComponent = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={19}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M0 17h17v3H4l-4-3Z" fill="#D32D2D" fillOpacity={0.86} />
      <mask
        id="b"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={0}
        width={17}
        height={21}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.757 0H3a1 1 0 0 0-1 1v19a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V5.243L13.757 0Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.757 0H3a1 1 0 0 0-1 1v19a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V5.243L13.757 0Z"
          fill="#F2F2F2"
        />
        <path
          d="m13.757 0 .707-.707L14.172-1h-.415v1ZM19 5.243h1v-.415l-.293-.292-.707.707ZM3 1h10.757v-2H3v2Zm0 0v-2a2 2 0 0 0-2 2h2Zm0 19V1H1v19h2Zm0 0H1a2 2 0 0 0 2 2v-2Zm15 0H3v2h15v-2Zm0 0v2a2 2 0 0 0 2-2h-2Zm0-14.757V20h2V5.243h-2Zm1.707-.707L14.464-.707 13.05.707l5.243 5.243 1.414-1.414Z"
          fill="#FF2116"
        />
        <path d="M13.5 1 18 5.5h-3c-1 0-1.5-.5-1.5-1.5V1Z" fill="#fff" />
        <path
          d="M12.565 7.541c-.336-.019-1.144.025-1.952.167-.468-.436-.873-.984-1.173-1.437C10.403 3.724 9.793 3 8.972 3c-.645 0-.967.626-.895 1.317.036.348.356 1.08.75 1.774-.241.585-.673 1.575-1.101 2.291-.502.14-.932.296-1.241.428-1.538.658-1.662 1.537-1.342 1.91.608.707 1.903.096 3.202-2.042.89-.233 2.06-.542 2.165-.542.012 0 .03.003.048.009.649.526 1.415 1.128 2.045 1.208.912.115 1.431-.494 1.395-.872-.035-.378-.276-.876-1.433-.94ZM6.807 9.847c-.376.411-.984.806-1.199.675-.215-.132-.25-.51.233-.939.483-.427 1.291-.65 1.413-.691.143-.05.179 0 .179.066s-.25.477-.626.89Zm1.646-5.53c-.036-.412.053-.806.429-.856.375-.05.554.346.411.987-.143.643-.215 1.07-.286 1.12-.072.05-.143-.115-.143-.115-.143-.264-.376-.725-.411-1.136Zm.143 3.868c.196-.297.697-1.497.697-1.497.107.18.948 1.102.948 1.102s-1.288.263-1.645.395Zm3.898.526c-.536-.065-1.448-.658-1.448-.658-.125-.05 1-.19 1.431-.165.555.034.752.248.752.461 0 .214-.197.428-.735.362Z"
          fill="#FF2116"
        />
      </g>
    </g>
    <path d="M17 12H0v5h17v-5Z" fill="#FF2116" />
    <path
      d="M5.107 14.83h-.724v-.457h.724a.593.593 0 0 0 .274-.055.35.35 0 0 0 .154-.156.573.573 0 0 0 0-.461.395.395 0 0 0-.154-.176.507.507 0 0 0-.274-.066h-.521v2.385H4V13h1.107c.223 0 .414.04.573.121a.865.865 0 0 1 .494.813.874.874 0 0 1-.127.476.825.825 0 0 1-.367.31c-.16.074-.35.11-.573.11Zm2.544 1.014h-.619l.004-.457h.615a.695.695 0 0 0 .389-.104.641.641 0 0 0 .238-.306c.055-.135.082-.297.082-.487v-.138c0-.146-.015-.274-.047-.385a.765.765 0 0 0-.134-.28.564.564 0 0 0-.221-.17.73.73 0 0 0-.301-.058H7.02V13h.637c.19 0 .364.033.522.098a1.176 1.176 0 0 1 .68.705c.063.165.095.35.095.552v.135c0 .202-.032.386-.096.553a1.188 1.188 0 0 1-.68.705 1.403 1.403 0 0 1-.527.096ZM7.364 13v2.844h-.586V13h.586Zm2.802 0v2.844H9.58V13h.586Zm1.132 1.215v.457h-1.293v-.457h1.293ZM11.435 13v.459h-1.43V13h1.43Z"
      fill="#fff"
    />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h19v21H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
