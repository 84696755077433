import {
  ActionType,
  IConsultationListResponse,
  IConsultationListPayload,
} from "models/consultationRequest/consultationList";

export const getConsultationListAction = (
  payload: IConsultationListPayload
) => {
  return {
    type: ActionType.CONSULTATION_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CONSULTATION_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IConsultationListResponse) => {
  return {
    type: ActionType.CONSULTATION_LIST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.CONSULTATION_LIST_RESET,
  };
};
