import { createApiCall2, MethodType, URI } from "../Api";
import { CheckListData } from "models/mentalHealth/checkList";
import { MentalCheckDetailsData } from "models/mentalHealth/mentalCheckDetails";
import { NewMentalCheckData } from "models/mentalHealth/newMentalCheck";
import { UpdateMentalCheckData } from "models/mentalHealth/updateMentalCheck";
import { DeleteMentalCheckData } from "models/mentalHealth/deleteMentalCheck";
import { MentalCheckRequestsData } from "models/mentalHealth/mentalCheckRequests";
import { TypeInfoData } from "models/mentalHealth/typeInformation";
import { SendNotificationData } from "models/mentalHealth/sendNotification";
import { ITypeInfoDownloadData } from "models/mentalHealth/csvDownloadTypeInfo";
import { IMentalCheckScoringPayload } from "models/mentalHealth/scoringInformation";
import { IMentalCheckTotalPayload } from "models/mentalHealth/mentalHealthTotal";
import { ILastNotificationTimePayload } from "models/mentalHealth/lastNotificationTime";
import { ClassListData } from "models/mentalHealth/classList";
import { IMentalCheckScoringDownloadPayload } from "models/mentalHealth/pdfDownloadScoringInfo";
import { SendInterviewRequestPayload } from "models/mentalHealth/sendInterviewRequest";

export const fetchMentalCheckList = (payload: CheckListData) => {
  return createApiCall2(MethodType.GET, URI.MENTAL_HEALTH.CHECK_LIST, payload);
};

export const getMentalCheckDetails = (payload: MentalCheckDetailsData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.MENTAL_CHECK_DETAILS,
    payload
  );
};

export const getGradeList = () => {
  return createApiCall2(MethodType.GET, URI.MENTAL_HEALTH.GRADE_LIST);
};

export const saveNewMentalCheck = (payload: NewMentalCheckData) => {
  return createApiCall2(
    MethodType.POST,
    URI.MENTAL_HEALTH.NEW_MENTAL_CHECK,
    payload
  );
};

export const updateMentalCheck = (payload: UpdateMentalCheckData) => {
  return createApiCall2(
    MethodType.PUT,
    URI.MENTAL_HEALTH.UPDATE_MENTAL_CHECK,
    payload
  );
};

export const deleteMentalCheck = (payload: DeleteMentalCheckData) => {
  return createApiCall2(
    MethodType.DELETE,
    URI.MENTAL_HEALTH.DELETE_MENTAL_CHECK,
    payload
  );
};

export const getMentalCheckRequests = (payload: MentalCheckRequestsData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.GET_CHECK_REQUESTS,
    payload
  );
};

export const getMentalCheckTypeInfoRequests = (payload: TypeInfoData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.TYPE_INFORMATION,
    payload
  );
};

export const sendNotification = (payload: SendNotificationData) => {
  return createApiCall2(
    MethodType.POST,
    URI.MENTAL_HEALTH.SEND_NOTIFICATION,
    payload
  );
};

export const getMentalCheckScoringInformation = (
  payload: IMentalCheckScoringPayload
) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.GET_SCORING_INFORMATION,
    payload
  );
};

export const downloadCSVTypeInfo = (payload: ITypeInfoDownloadData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.DOWNLOAD_TYPE_INFO_CSV,
    payload
  );
};

export const downloadPDFTypeInfo = (payload: ITypeInfoDownloadData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.DOWNLOAD_TYPE_INFO_PDF,
    payload
  );
};

export const getMentalCheckTotalInfo = (payload: IMentalCheckTotalPayload) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.GET_MENTAL_CHECK_TOTAL_INFO,
    payload
  );
};

export const getMentalCheckTypeInfo = () => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.GET_MENTAL_CHECK_TYPE
  );
};

export const getLastNotificationTime = (
  payload: ILastNotificationTimePayload
) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.GET_LAST_NOTIFICATION_TIME,
    payload
  );
};

export const getClassList = (payload: ClassListData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.GET_CLASS_LIST,
    payload
  );
};

export const downloadPDFScoringInfo = (
  payload: IMentalCheckScoringDownloadPayload
) => {
  return createApiCall2(
    MethodType.GET,
    URI.MENTAL_HEALTH.DOWNLOAD_SCORING_INFO,
    payload
  );
};

export const sendInterviewRequest = (payload: SendInterviewRequestPayload) => {
  return createApiCall2(
    MethodType.PUT,
    URI.MENTAL_HEALTH.SEND_INTERVIEW_REQUEST,
    payload
  );
};
