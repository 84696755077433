import styled from "styled-components";
import { Table, Modal } from "components";
import { theme } from "theme/theme";

export const WrapperProgressBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  .ant-progress {
    height: 16px;
    margin-bottom: 32px;
    .ant-progress-outer {
      height: 100%;
      margin-right: 0;
      padding-right: 0;
      position: relative;
      .ant-progress-inner {
        position: absolute;
        left: 0;
      }
    }
  }
`;

export const ContainerProgressBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  p {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    color: ${theme.colors.white};
    margin-bottom: 16px;
  }
  .ant-progress-line {
    width: 660px;
  }
  .ant-progress-text {
    display: none;
  }
  .ant-progress-inner {
    border-radius: 8px;
  }
  .ant-progress-bg {
    height: 16px !important;
  }
`;

export const FileUpdating = styled.div`
  ${theme.media.tablet} {
    padding-left: 17px;
    width: 67%;
  }
  ${theme.media.desktop} {
    padding-left: 20px;
  }
`;

export const SubTableStyled = styled(Table)`
  .ant-table-thead > tr > th:nth-child(3):before {
    content: unset !important;
  }
  .ant-table-thead > tr > th:nth-child(4) {
    padding-left: 31px;
  }
  .ant-table-thead > tr > th {
    padding: 18px 16px;
  }
  .ant-table-thead > tr > th:last-child {
    padding-bottom: 46px;
  }
  .ant-table-thead > tr > th:first-child:before {
    top: 50% !important;
  }
  .ant-table-thead > tr > th:before {
    height: 2.4em !important;
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    border-right: 4px solid ${theme.colors.background};
  }
  .ant-table-tbody > tr > td {
    padding-left: 16px;
  }
  .ant-table-tbody > tr > td:nth-child(4) {
    padding-left: 33px;
  }
  .ant-table-thead > tr > th:nth-child(6):before {
    content: unset !important;
  }
`;

export const WrapperAction = styled.div`
  position: relative;
  button {
    position: absolute;
    top: 20px;
    right: 0;
  }
  > div:first-child {
    margin-bottom: 24px;
  }
  ${theme.media.tablet} {
    display: flex;
    button {
      position: unset;
      margin-left: 16px;
      height: 31px;
      margin-top: 20px;
    }
    > div:first-child {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }
`;

export const MessageConfirm = styled.div`
  margin-top: 8px;
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 20px 16px 24px;
  }
  .ant-modal-footer {
    padding: 0px 16px 20px;
    border: unset;
  }
  div {
    line-height: 16px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 24px 24px 32px 24px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

export const FailModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 16px;
  }
  .ant-modal-footer {
    padding: 0px 16px 20px;
    border: unset;
  }
  div {
    line-height: 16px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 16px 24px 5px 16px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 144px;
    line-height: 23px;
    padding: 3.5px 15px 4.5px;
  }
  button:last-child {
    width: 151px;
  }
  ${theme.media.tablet} {
    justify-content: flex-end;
    button:last-child {
      margin-left: 16px;
    }
    button {
      width: 112px !important;
    }
  }
`;

export const WrapperBtnOKFooter = styled.div`
  button {
    width: 100%;
    line-height: 23px;
    padding: 3.5px 15px 4.5px;
  }

  ${theme.media.tablet} {
    button {
      width: 112px !important;
    }
  }
`;

export const WrapperErrorMessage = styled.div`
  display: flex;
`;

export const WrapText = styled.div`
  padding-left: 16px;
  ${theme.media.tablet} {
    span:first-child {
      margin-right: 10px;
    }
  }
`;

export const ConfirmModalStyled = styled(Modal)`
  width: calc(100vw - 4px) !important;
  .ant-modal-body {
    padding: 32px 25px;
  }
  ${theme.media.tablet} {
    width: 532px !important;
  }
  ${theme.media.laptopL} {
    .ant-modal-body {
      padding: 32px;
    }
  }
`;

export const TextConfirm = styled.div`
  font-size: 22px;
  font-weight: ${theme.fontWeight.bold};
  line-height: 40px;
  color: ${theme.colors.text.primary};
`;

export const WrapperBtnConfirmModal = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  button {
    width: 151px;
    padding: 7px 0;
  }
  button:first-child {
    margin-right: 16px;
  }
  ${theme.media.tablet} {
    display: block;
    justify-content: unset;
    button {
      width: 128px;
      padding: 7px 0;
    }
    button:first-child {
      margin-right: 40px;
    }
  }
  ${theme.media.laptopL} {
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ant-select-selector {
    background-color: #ffffff !important;
  }
  ${theme.media.tablet} {
    height: 31px;
  }
`;

export const WrapperLeftAction = styled.div`
  display: flex;
  flex-direction: column;
  > button {
    order: 2;
    margin-bottom: 32px;
    height: 31px;
  }
  > div {
    order: 1;
    margin-bottom: 12px;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    line-height: 23px;
    color: ${theme.colors.important};
  }
  ${theme.media.tablet} {
    flex-direction: row;
    > button {
      order: 1;
      width: 144px;
      margin-right: 8px;
      margin-bottom: 0px;
    }
    > div {
      order: 2;
      margin-bottom: 0px;
    }
  }
  ${theme.media.laptopL} {
    > div {
      display: flex;
      align-items: center;
    }
  }
`;

export const LabelSelect = styled.div`
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.medium};
  margin-right: 5;
  line-height: 14px;
`;

export const NotificationMessage = styled.div`
  font-size: ${theme.sizes.lg};
  line-height: 26px;
  font-weight: ${theme.fontWeight.regular};
  color: #3b3b3b;
  margin-bottom: -21px;
  margin-top: -8px;
  width: 100%;
  text-align: left;
  ${theme.media.tablet} {
    margin-bottom: 5px;
    margin-top: 0;
  }
`;
