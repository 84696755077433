import {
  Action,
  ActionType,
  MentalCheckRequestsResponse,
} from "models/mentalHealth/mentalCheckRequests";
import createReducer from "redux/createReducer";

export interface MentalCheckRequestsReducerType {
  loading: boolean;
  error?: string;
  mentalCheckRequestsResponse?: MentalCheckRequestsResponse;
}

const defaultState: MentalCheckRequestsReducerType = {
  loading: false,
  error: undefined,
  mentalCheckRequestsResponse: undefined,
};

export const mentalCheckRequestsReducer =
  createReducer<MentalCheckRequestsReducerType>(defaultState, {
    [ActionType.MENTAL_CHECK_REQUEST](state: MentalCheckRequestsReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MENTAL_CHECK_REQUEST_ERROR](
      state: MentalCheckRequestsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        mentalCheckRequestsResponse: undefined,
      };
    },

    [ActionType.MENTAL_CHECK_REQUEST_SUCCESS](
      state: MentalCheckRequestsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        mentalCheckRequestsResponse: action.payload,
      };
    },
  });
