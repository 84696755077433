import { IResponse } from "models";

export enum ActionType {
  GET_ANSWER_INFO = "action/GET_ANSWER_INFO",
  GET_ANSWER_INFO_SUCCESS = "action/GET_ANSWER_INFO_SUCCESS",
  GET_ANSWER_INFO_ERROR = "action/GET_ANSWER_INFO_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IAnswerInfoPayload {
  id?: number;
}

export interface IPatient {
  patient_id: string;
  full_name: string;
}

export interface IQuestionChart {
  question_position: number;
  question_content: string;
  answers: string[];
  answers_text: string[];
  answer_type: string;
  answers_percent: number[];
}

interface IDepartment {
  name: string;
  count_answered: number;
  total_patient_per_derpartment: number;
}

export interface IAnswerByDepartment {
  parent_id: string;
  parent_name: string;
  departments: IDepartment[];
  total_answered: number;
  total_patient: number;
}

export interface AnswerInfoResponse extends IResponse {
  result: {
    total_patient: number;
    total_patient_answered: number;
    total_patient_not_answered: number;
    patients_not_answered: IPatient[];
    patients_answered: IPatient[];
    questions_chart: IQuestionChart[];
    answered_by_departments: IAnswerByDepartment[];
  };
}
