import { IResponse } from "models";

export enum ActionType {
  // download PDF file TypeInfo list
  PDF_DOWNLOAD_TYPE_INFO = "action/PDF_DOWNLOAD_TYPE_INFO",
  PDF_DOWNLOAD_TYPE_INFO_SUCCESS = "action/PDF_DOWNLOAD_TYPE_INFO_SUCCESS",
  PDF_DOWNLOAD_TYPE_INFO_ERROR = "action/PDF_DOWNLOAD_TYPE_INFO_ERROR",
  PDF_DOWNLOAD_TYPE_INFO_RESET_DATA = "action/PDF_DOWNLOAD_TYPE_INFO_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface PDFDownloadTypeInfoResponse extends IResponse {
  result?: IPDFDownloadResult;
}

export interface IPDFDownloadResult {
  data: IPDFDownloadTypeInfoItem[];
  headers: IHeaderPDFDownloadTypeInfoItem[];
}
export interface ITypeInfoDownloadData {
  mental_check_type_id: number;
  mental_check_request_id: number;
  grade: number;
  refine_criteria_type: number[];
}

export interface IHeaderPDFDownloadTypeInfoItem {
  label: string;
  key: string;
}

export interface IPDFDownloadTypeInfoItem {
  id: number;
  name: string;
  grade: string;
  date_of_implementation: string;
  status: string;
  mental_physical_status: string;
  mood: string;
  concentration: string;
  behaviour: string;
  relation_ship: string;
  gender: string;
}
