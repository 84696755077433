import { Action, ActionType, FeverResponse } from "models/dashboard/fever";
import createReducer from "../../createReducer";

export interface FeverReducerType {
  loading: boolean;
  error?: string;
  feverResponse?: FeverResponse;
}

const defaultState: FeverReducerType = {
  loading: false,
  error: undefined,
  feverResponse: undefined,
};

export const getFeverReducer = createReducer<FeverReducerType>(defaultState, {
  [ActionType.GET_FEVER_CHART](state: FeverReducerType) {
    return {
      ...state,
      loading: true,
    };
  },

  [ActionType.GET_FEVER_CHART_ERROR](
    state: FeverReducerType,
    action: Action<number>
  ) {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  },

  [ActionType.GET_FEVER_CHART_SUCCESS](
    state: FeverReducerType,
    action: Action<number>
  ) {
    return {
      ...state,
      loading: false,
      error: null,
      feverResponse: action.payload,
    };
  },
});
