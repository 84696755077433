import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType, IGraphDataResponse } from "models/vaccine/graph";
import { httpStatus } from "configs/httpStatus";
import { fetchRecordGraph } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./graphActions";

function* getGraphDataSaga({ payload }: { payload: { date: string } }) {
  try {
    const response: IGraphDataResponse = yield call(fetchRecordGraph, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetGraphDataWatcher() {
  yield takeLatest(ActionType.VACCINE_RECORD_GRAPH as any, getGraphDataSaga);
}

export default [fork(onGetGraphDataWatcher)];
