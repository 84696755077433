import React, { useEffect, useState, useCallback } from "react";
import { Row, Col } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

import { Select, Button, ConfirmModal } from "components";
import { IOption } from "components/select/Select";
import { convertRawDataToOptions2, localStorageHelper } from "utils";

import { dataChildDepartmentResponse } from "redux/bodyTemperature/childDepartment/childDepartmentStates";
import { dataPatientChildDepartmentResponse } from "redux/bodyTemperature/patientChildDepartment/patientChildStates";
import { dataDetailFormResponse } from "redux/bodyTemperature/detailForm/detailFormStates";
import { searchConditionDataResponse } from "redux/messagemanagement/searchCondition/searchConditionStates";
import { healthCheckQuestionsResponse } from "redux/bodyTemperature/healthCheckQuestions/healthCheckQuestionsState";
import { getHealthCheckSettingResponse } from "redux/setting/getHealthCheckSetting/getHealthCheckSettingStates";
import {
  GetDataChildDepartmentCreators,
  GetDataPatientChildDepartmentCreators,
  CreatePatientProfileCreators,
} from "redux/rootActions";

import {
  WrapperAdditionContent,
  WrapperBtnForm,
  BodyTemperatureHeader,
  AdditionalModalStyled,
  TextAreaStyled,
  WrapperBodyTemperatureHeader,
} from "./BodyTemperature.style";
import { IModal } from "./SearchTerms";

const defaultSchema = {
  physicalCondition: yup.string().required("please-enter"),
};

const day: {
  [x: string]: string;
} = {
  Mon: "月",
  Tue: "火",
  Wed: "水",
  Thu: "木",
  Fri: "金",
  Sat: "土",
  Sun: "日",
};

const AdditionalModal: React.FC<IModal & { selectedDate: Date }> = ({
  isVisible,
  handleCancel,
  patientInfo,
  selectedDate,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });

  const date = patientInfo?.time || selectedDate;

  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const aroundWidth = currentLanguage === "en" ? "70px" : "40px";

  const dispatch = useDispatch();
  const dataChildDepartment = useSelector(dataChildDepartmentResponse);
  const dataPatientChildDepartment = useSelector(
    dataPatientChildDepartmentResponse
  );
  const dataDetailForm = useSelector(dataDetailFormResponse);
  const healthCheckQuestionsData = useSelector(healthCheckQuestionsResponse);
  const dataSearchCondition = useSelector(searchConditionDataResponse);
  const getHealthCheckSetting = useSelector(getHealthCheckSettingResponse);

  const [isVisibleCFM, setIsVisibleCFM] = useState(false);

  const handleSchema = () => {
    let schema: any = { ...defaultSchema };
    if (dataSearchCondition && Object.keys(dataSearchCondition.result).length) {
      if (dataSearchCondition.result.is_family_illness_active) {
        schema = {
          ...schema,
          family: yup.string().required("please-enter"),
        };
      }
      if (dataSearchCondition.result.is_attendance_active) {
        schema = {
          ...schema,
          attend: yup.string().required("please-enter"),
        };
      }
    }
    return schema;
  };

  const { handleSubmit, control, watch } = useForm();

  const [options, setOptions] = useState<IOption[][]>([]);
  const [destinationOptions, setDestinationOptions] = useState<IOption[]>([]);
  const [destinationValue, setDestinationValue] = useState<any>();
  const [valueSelectClass, setValueSelectClass] = useState<any>(null);
  const [childDepartmentOptions, setChildDepartmentOptions] = useState<
    IOption[]
  >([]);
  const [patientChildDepartmentOptions, setPatientChildDepartmentOptions] =
    useState<IOption[]>([]);
  const [patientChildDepartmentValue, setPatientChildDepartmentValue] =
    useState<any>();
  const [submitedData, setSubmitedData] = useState<any>({});

  const watchConditionAnswer = watch("physicalCondition");
  const watchFamilyAnswer = watch("family");
  const watchFamilyCommentAnswer = watch("familyComment");
  const watchAttend = watch("attend");
  const watchTemperature = watch("temperature");
  const watchTime = watch("time");
  const watchMalfunction = watch("malfunction");
  const watchMalfunctionComment = watch("malfunctionComment");

  const handleDisabledBtn = () => {
    const conditions =
      watchConditionAnswer &&
      destinationValue &&
      valueSelectClass &&
      patientChildDepartmentValue;
    if (dataSearchCondition && Object.keys(dataSearchCondition.result).length) {
      if (
        getHealthCheckSetting?.health_check_setting?.is_attendance &&
        dataSearchCondition.result.is_family_illness_active
      ) {
        if (conditions && watchAttend) {
          return false;
        } else {
          return true;
        }
      }
      if (getHealthCheckSetting?.health_check_setting?.is_attendance) {
        if (conditions && watchAttend) {
          return false;
        } else {
          return true;
        }
      }
      if (getHealthCheckSetting?.health_check_setting?.is_temperature) {
        if (conditions && watchTemperature && watchTime) {
          return false;
        } else {
          return true;
        }
      }
      // if (dataSearchCondition.result.is_family_illness_active) {
      //   if (conditions && watchFamilyAnswer) {
      //     if (watchFamilyAnswer === 162) {
      //       return false;
      //     }
      //     if (watchFamilyAnswer === 161 && watchFamilyCommentAnswer) {
      //       return false;
      //     }
      //   } else {
      //     return true;
      //   }
      // }
    }
    if (conditions) {
      return false;
    }
    return true;
  };

  // const handleCheckFamily = () => {
  //   if (watchFamilyAnswer === 162) {
  //     return false;
  //   }
  //   if (watchFamilyAnswer === 161 && watchFamilyCommentAnswer) {
  //     return false;
  //   }
  //   return true;
  // };

  const handleCheckPhysical = useCallback(() => {
    if (watchConditionAnswer === 136) {
      return false;
    }
    if (
      watchConditionAnswer === 137 &&
      ((watchMalfunction && watchMalfunction.length > 0) ||
        watchMalfunctionComment)
    ) {
      return false;
    }
    return true;
  }, [watchConditionAnswer, watchMalfunction, watchMalfunctionComment]);

  useEffect(() => {
    if (
      dataDetailForm &&
      dataDetailForm?.result &&
      Object.keys(patientInfo).length
    ) {
      const designation = dataDetailForm.result?.patient_designations.filter(
        (destination: any) =>
          destination[1] === Number(patientInfo.parent_department_id)
      );
      if (designation.length) {
        setDestinationValue(designation[0][1]);
        const param = {
          dept_id: designation[0][1],
        };
        dispatch(GetDataChildDepartmentCreators.getDataChildDepartment(param));
      }
    }
  }, [dataDetailForm]);

  useEffect(() => {
    if (dataDetailForm && dataDetailForm?.result) {
      const { patient_designations } = dataDetailForm?.result;
      setDestinationOptions(convertRawDataToOptions2(patient_designations));
    }
  }, [dataDetailForm]);

  useEffect(() => {
    if (healthCheckQuestionsData && healthCheckQuestionsData?.questions) {
      const optionsClone: IOption[][] = healthCheckQuestionsData?.questions.map(
        (item: any) => {
          return item.question.options.map((option: any) => {
            return {
              id: option.id,
              value: option.id,
              name: option.answer_text,
            };
          });
        }
      );
      setOptions(optionsClone);
    }
  }, [healthCheckQuestionsData]);

  useEffect(() => {
    if (dataChildDepartment && dataChildDepartment?.result) {
      const dataChildDepartmentClone = convertRawDataToOptions2(
        dataChildDepartment.result
      );
      setChildDepartmentOptions(dataChildDepartmentClone);
      if (Object.keys(patientInfo).length) {
        const childDepartment = dataChildDepartment.result.filter(
          (child: any) => child[1] === Number(patientInfo.department_id)
        );
        if (childDepartment.length) {
          const param = {
            sub_dept_id: childDepartment[0][1],
          };
          setValueSelectClass(childDepartment[0][1]);
          dispatch(
            GetDataPatientChildDepartmentCreators.getDataPatientChildDepartment(
              param
            )
          );
        }
      }
    }
  }, [dataChildDepartment]);

  useEffect(() => {
    if (dataPatientChildDepartment && dataPatientChildDepartment?.result) {
      const dataPatientChildDepartmentClone = convertRawDataToOptions2(
        dataPatientChildDepartment.result
      );
      setPatientChildDepartmentOptions(dataPatientChildDepartmentClone);
      if (Object.keys(patientInfo).length) {
        const patientChildDepartment = dataPatientChildDepartment.result.filter(
          (patientChild: any) => patientChild[1] === patientInfo.patient_id
        );
        if (patientChildDepartment.length) {
          setPatientChildDepartmentValue(patientChildDepartment[0][1]);
        }
      }
    }
  }, [dataPatientChildDepartment]);

  const returnOptions = (key: number) => {
    if (!options.length) return [];

    const optionClone =
      options[
        healthCheckQuestionsData?.questions.findIndex(
          (item: any) => item.question_number === key
        )
      ];

    if (key === 6) {
      return (optionClone || []).sort((a: any, b: any) => b.id - a.id);
    }

    return optionClone;
  };

  const handleDestination = (e: number) => {
    const param = {
      dept_id: e,
    };
    if (e) {
      setValueSelectClass(null);
      setPatientChildDepartmentValue(null);
      setChildDepartmentOptions([]);
      setPatientChildDepartmentOptions([]);
    }
    dispatch(GetDataChildDepartmentCreators.getDataChildDepartment(param));
    setDestinationValue(e);
  };

  const handleChildDepartment = (e: number) => {
    const param = {
      sub_dept_id: e,
    };
    if (e) {
      setValueSelectClass(e);
      setPatientChildDepartmentValue(null);
      setPatientChildDepartmentOptions([]);
    }
    dispatch(
      GetDataPatientChildDepartmentCreators.getDataPatientChildDepartment(param)
    );
  };

  const handlePatientChildDepartment = (e: number) => {
    setPatientChildDepartmentValue(e);
  };

  const handleIsCurrentDate = () => {
    const format = "YYYY-MM-DD";
    const currentDate = moment(new Date()).format(format);
    const newDate = moment(date).format(format);

    return currentDate === newDate;
  };

  const handleCheckMalfunctionValue = (value: any) => {
    return typeof value !== "undefined" && value.length;
  };

  const handleAddNewBodyTemperature = (data?: any) => {
    let cloneData: any;
    if (data && Object.keys(data).length) {
      cloneData = data;
    } else {
      cloneData = submitedData;
    }

    const tempAnswers: {
      additional_comment: string;
      answer_id: number[];
      question_id: number;
      question_number: number;
    }[] = [];
    const arr: string[] = [
      "temperature",
      "time",
      "physicalCondition",
      "malfunction",
      "attend",
    ];
    const arrComment: any = {
      malfunction: "malfunctionComment",
      attend: "attendComment",
    };

    arr.forEach((item, idx) => {
      if (item === "malfunction") {
        const isMalfunctionValue = handleCheckMalfunctionValue(cloneData[item]);
        if (isMalfunctionValue || cloneData["malfunctionComment"]) {
          tempAnswers.push({
            additional_comment: cloneData[arrComment[item]] || "",
            answer_id: isMalfunctionValue ? cloneData[item] : [],
            question_id: idx + 1,
            question_number: idx + 1,
          });
        }
      } else if (item === "attend") {
        if (getHealthCheckSetting?.health_check_setting?.is_attendance) {
          tempAnswers.push({
            additional_comment: cloneData[arrComment[item]] || "",
            answer_id: [Number(cloneData[item])],
            question_id: idx + 1,
            question_number: idx + 1,
          });
        }
      } else if (item === "family") {
        tempAnswers.push({
          additional_comment: cloneData[arrComment[item]] || "",
          answer_id: [Number(cloneData[item])],
          question_id: idx + 1,
          question_number: idx + 1,
        });
      } else if (item === "temperature" || item === "time") {
        if (getHealthCheckSetting?.health_check_setting?.is_temperature) {
          tempAnswers.push({
            additional_comment: "",
            answer_id: [Number(cloneData[item])],
            question_id: idx + 1,
            question_number: idx + 1,
          });
        }
      } else {
        tempAnswers.push({
          additional_comment: "",
          answer_id: [Number(cloneData[item])],
          question_id: idx + 1,
          question_number: idx + 1,
        });
      }
    });

    const params = {
      patient_id: patientChildDepartmentValue,
      date: moment(date).format("YYYY-MM-DD"),
      temp_answers_attributes: tempAnswers,
    };
    dispatch(CreatePatientProfileCreators.createPatientProfile(params));
  };

  const onSubmit = (data: { [x: string]: any }) => {
    if (handleIsCurrentDate()) {
      handleAddNewBodyTemperature(data);
    } else {
      setSubmitedData(data);
      setIsVisibleCFM(true);
    }
  };

  const handleDate = (date: Date) => {
    if (currentLanguage === "ja") {
      const jaDay = `（${day[moment(date).format("ddd")]}）`;
      return moment(date).format("YYYY年M月D日") + jaDay;
    }
    return moment(date).format("MM/DD/YYYY");
  };

  const handleShowDateNotification = () => {
    if (handleIsCurrentDate()) return "";
    return <p>{t2("date-notification", { date: handleDate(date) })}</p>;
  };

  return (
    <AdditionalModalStyled
      title={t2("title-add-modal")}
      type="form"
      visible={isVisible}
      onCancel={handleCancel}
    >
      <div id="area" style={{ position: "relative" }} />
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col xs={12} md={8} xl={8}>
          <Select
            value={destinationValue}
            label={t1("school-year")}
            placeholder={t1("please-select")}
            fsLabel={16}
            lhLabel="24px"
            options={destinationOptions}
            onChange={handleDestination}
            getPopupContainer={() => document.getElementById("area")}
          />
        </Col>
        <Col xs={12} md={8} xl={8}>
          <Select
            label={t1("class")}
            placeholder={t1("please-select")}
            fsLabel={16}
            lhLabel="24px"
            options={childDepartmentOptions}
            onChange={handleChildDepartment}
            value={valueSelectClass}
            getPopupContainer={() => document.getElementById("area")}
          />
        </Col>
        <Col xs={24} md={8} xl={8}>
          <Select
            label={t2("student-name")}
            placeholder={t1("please-select")}
            fsLabel={16}
            lhLabel="24px"
            options={patientChildDepartmentOptions}
            onChange={handlePatientChildDepartment}
            value={patientChildDepartmentValue}
            getPopupContainer={() => document.getElementById("area")}
          />
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrapperAdditionContent>
          {/* <WrapperBodyTemperatureHeader>
            <BodyTemperatureHeader>
              {t3("temperature-management-label")}
            </BodyTemperatureHeader>
            {handleShowDateNotification()}
          </WrapperBodyTemperatureHeader> */}
          <Row gutter={[16, 16]}>
            {getHealthCheckSetting?.health_check_setting?.is_temperature && (
              <>
                <Col xs={24} md={14} xl={14}>
                  <Controller
                    control={control}
                    name="temperature"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <Select
                        label={t2("body-temperature-selection")}
                        placeholder={t1("please-select")}
                        fsLabel={16}
                        lhLabel="24px"
                        options={returnOptions(1)}
                        onChange={onChange}
                        error={t2(error?.message)}
                        getPopupContainer={() =>
                          document.getElementById("area")
                        }
                      />
                    )}
                  />
                </Col>
                <Col xs={24} md={10} xl={10}>
                  <Row>
                    <div style={{ width: `calc(100% - ${aroundWidth})` }}>
                      <Controller
                        control={control}
                        name="time"
                        render={({
                          field: { onChange },
                          fieldState: { error },
                        }) => (
                          <Select
                            label={t2("measured-time")}
                            placeholder={t1("please-select")}
                            fsLabel={16}
                            lhLabel="24px"
                            options={returnOptions(2)}
                            onChange={onChange}
                            error={t2(error?.message)}
                            getPopupContainer={() =>
                              document.getElementById("area")
                            }
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{ marginTop: 35, paddingLeft: 8, fontSize: 16 }}
                    >
                      {t2("around")}
                    </div>
                  </Row>
                </Col>
              </>
            )}
            {getHealthCheckSetting?.health_check_setting?.is_attendance && (
              <>
                <Col span={24}>
                  <Controller
                    control={control}
                    name="attend"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <Select
                        label={
                          <WrapperBodyTemperatureHeader>
                            <div>{t2("attend-today")}</div>
                            {handleShowDateNotification()}
                          </WrapperBodyTemperatureHeader>
                        }
                        placeholder={t1("please-select")}
                        fsLabel={16}
                        lhLabel="24px"
                        options={returnOptions(5)}
                        onChange={onChange}
                        error={t2(error?.message)}
                        getPopupContainer={() =>
                          document.getElementById("area")
                        }
                      />
                    )}
                  />
                </Col>
                <Col span={24}>
                  <Controller
                    control={control}
                    name="attendComment"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <TextAreaStyled
                        label={t2("complain-attend-description")}
                        fsLabel={16}
                        lhLabel="24px"
                        bdr="6px"
                        onChange={onChange}
                        error={t2(error?.message)}
                      />
                    )}
                  />
                </Col>
              </>
            )}
            <Col span={24}>
              <Controller
                control={control}
                name="physicalCondition"
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <Select
                    label={t2("your-physical-condition-description")}
                    placeholder={t1("please-select")}
                    fsLabel={16}
                    lhLabel="24px"
                    options={returnOptions(3)}
                    onChange={onChange}
                    error={t2(error?.message)}
                    getPopupContainer={() => document.getElementById("area")}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                control={control}
                name="malfunction"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    mode="multiple"
                    label={t2("details-malfunction-description")}
                    placeholder={t1("please-select")}
                    fsLabel={16}
                    lhLabel="24px"
                    disabled={watchConditionAnswer !== 137}
                    options={returnOptions(4)}
                    onChange={onChange}
                    value={value}
                    error={t2(error?.message)}
                    getPopupContainer={() => document.getElementById("area")}
                    showSearch={false}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                control={control}
                name="malfunctionComment"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextAreaStyled
                    label={t2("complain-upset-description")}
                    fsLabel={16}
                    lhLabel="24px"
                    bdr="6px"
                    disabled={watchConditionAnswer !== 137}
                    onChange={onChange}
                    value={value}
                    error={t2(error?.message)}
                  />
                )}
              />
            </Col>
            {/* {dataSearchCondition?.result.is_family_illness_active && (
              <>
                <Col span={24}>
                  <Controller
                    control={control}
                    name="family"
                    render={({
                      field: { onChange },
                      fieldState: { error },
                    }) => (
                      <Select
                        label={t2("family-health-check")}
                        placeholder={t1("please-select")}
                        fsLabel={16}
                        lhLabel="24px"
                        options={returnOptions(6)}
                        onChange={onChange}
                        error={t2(error?.message)}
                        getPopupContainer={() =>
                          document.getElementById("area")
                        }
                      />
                    )}
                  />
                </Col>
                <Col span={24}>
                  <Controller
                    control={control}
                    name="familyComment"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextAreaStyled
                        label={t2("family-health-check-comment")}
                        fsLabel={16}
                        lhLabel="24px"
                        bdr="6px"
                        disabled={watchFamilyAnswer !== 161}
                        onChange={onChange}
                        value={value}
                        error={t2(error?.message)}
                      />
                    )}
                  />
                </Col>
              </>
            )} */}
          </Row>
        </WrapperAdditionContent>
        <WrapperBtnForm>
          <Button
            background="#E0E0E0"
            color="#FFFFFF"
            name={t1("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            onClick={handleCancel}
          />
          <Button
            background="#2AC769"
            color="#FFFFFF"
            name={t1("register")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            disabled={handleDisabledBtn() || handleCheckPhysical()}
          />
        </WrapperBtnForm>
      </form>
      {isVisibleCFM && !handleIsCurrentDate() && (
        <ConfirmModal
          isVisible={isVisibleCFM}
          close={() => {
            setIsVisibleCFM(false);
            setSubmitedData({});
          }}
          ok={() => handleAddNewBodyTemperature()}
        >
          <Trans
            i18nKey="body-temperature.date-notification-confirm"
            date={handleDate(date)}
          >
            <strong>{{ date: handleDate(date) }}</strong>
            の体温を追加しようとしています。
          </Trans>
          <div>{t("are-you-ok")}</div>
        </ConfirmModal>
      )}
    </AdditionalModalStyled>
  );
};

export default AdditionalModal;
