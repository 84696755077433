import { IOption } from "components/select/Select";

export const generateDynamicRecords = (
  recordTotal = 0,
  t: any,
  options = [50, 100, 150, 200]
) => {
  const recordOptions: IOption[] = [];
  const sizeChangeList = [...options];
  let sizeChange = 0;

  if (!recordTotal) {
    recordOptions.push({
      id: 1,
      name: t(`${sizeChangeList[0]}-cases`),
      value: sizeChangeList[0],
    });
    return recordOptions;
  }

  const lastItem = sizeChangeList[sizeChangeList.length - 1];
  if (recordTotal > lastItem) {
    sizeChange = lastItem;
  } else {
    sizeChangeList.reduce((prev, cur) => {
      if (recordTotal > prev && recordTotal < cur) {
        sizeChange = cur;
      }
      return cur;
    }, 0);
  }

  const newSizeChangeList = sizeChangeList.slice(
    0,
    sizeChangeList.indexOf(sizeChange) + 1
  );
  newSizeChangeList.forEach((record, index) => {
    recordOptions.push({
      id: index + 1,
      name: t(`${record}-cases`),
      value: record,
    });
  });

  return recordOptions;
};
