import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IGetTemperatureFilterResponse,
  TemperatureFilterData,
} from "models/messagemanagement/temperatureFilterList";
import { httpStatus } from "configs/httpStatus";
import { fetchTemperatureFilterList } from "services/messagemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./temperatureFilterListActions";

function* temperatureListFilterSaga({
  payload,
}: {
  payload: TemperatureFilterData;
}) {
  try {
    const response: IGetTemperatureFilterResponse = yield call(
      fetchTemperatureFilterList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetTemperatureFilterListWatcher() {
  yield takeLatest(
    ActionType.GET_TEMPERATURE_LIST_FILTER as any,
    temperatureListFilterSaga
  );
}

export default [fork(onGetTemperatureFilterListWatcher)];
