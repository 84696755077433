import * as React from "react";

function SuicideWarningIconRed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="#FB4E4E" />
      <path
        d="M11.9571 3L11.496 11.4756H8.55764L8.09651 3H11.9571ZM8 15.0509C8 14.4973 8.18588 14.036 8.55764 13.6669C8.9294 13.2901 9.41198 13.1018 10.0054 13.1018C10.5987 13.1018 11.0777 13.2901 11.4424 13.6669C11.8141 14.036 12 14.4973 12 15.0509C12 15.6045 11.8141 16.0697 11.4424 16.4464C11.0777 16.8155 10.5987 17 10.0054 17C9.41198 17 8.9294 16.8155 8.55764 16.4464C8.18588 16.0697 8 15.6045 8 15.0509Z"
        fill="white"
      />
    </svg>
  );
}

export default SuicideWarningIconRed;
