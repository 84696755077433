import { Action, ActionType } from "models/messagemanagement/updateMessage";
import { IResponse } from "models";
import createReducer from "../../createReducer";

export interface UpdateMessageReducerType {
  loading: boolean;
  error?: string;
  updateMessageResponse?: IResponse;
}

const defaultState: UpdateMessageReducerType = {
  loading: false,
  error: undefined,
  updateMessageResponse: undefined,
};

export const updateMessageReducer = createReducer<UpdateMessageReducerType>(
  defaultState,
  {
    [ActionType.UPDATE_MESSAGE](state: UpdateMessageReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_MESSAGE_ERROR](
      state: UpdateMessageReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_MESSAGE_SUCCESS](
      state: UpdateMessageReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        updateMessageResponse: action.payload,
      };
    },

    [ActionType.RESET_UPDATE_MESSAGE](state: UpdateMessageReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        updateMessageResponse: undefined,
      };
    },
  }
);
