import {
  Action,
  ActionType,
  IGetTemperatureFilterResponse,
} from "models/messagemanagement/temperatureFilterList";
import createReducer from "../../createReducer";

export interface GetTemperatureListFilterReducerType {
  loading: boolean;
  error?: string;
  temperatureFilterListResponse?: IGetTemperatureFilterResponse;
}

const defaultState: GetTemperatureListFilterReducerType = {
  loading: false,
  error: undefined,
  temperatureFilterListResponse: undefined,
};

export const temperatureListFilterReducer =
  createReducer<GetTemperatureListFilterReducerType>(defaultState, {
    [ActionType.GET_TEMPERATURE_LIST_FILTER](
      state: GetTemperatureListFilterReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_TEMPERATURE_LIST_FILTER_ERROR](
      state: GetTemperatureListFilterReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_TEMPERATURE_LIST_FILTER_SUCCESS](
      state: GetTemperatureListFilterReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        temperatureFilterListResponse: action.payload,
      };
    },
  });
