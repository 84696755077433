import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ISaveConsultationSettingsPayload,
  ActionType,
  ISaveConsultationSettingsResponse,
} from "models/consultationRequest/saveConsultationSettings";
import { httpStatus } from "configs/httpStatus";
import { updateInsertSettings } from "services/consultationRequest";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./saveConsultationSettingsActions";

function* saveConsultationSettingsSaga({
  payload,
}: {
  payload: ISaveConsultationSettingsPayload;
}) {
  try {
    const response: ISaveConsultationSettingsResponse = yield call(
      updateInsertSettings,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSaveConsultationSettingsWatcher() {
  yield takeLatest(
    ActionType.SAVE_CONSULTATION_SETTINGS as any,
    saveConsultationSettingsSaga
  );
}

export default [fork(onSaveConsultationSettingsWatcher)];
