import { IResponse } from "models";

export enum ActionType {
  GET_ATTENDANCE_STATUS_CHART = "action/GET_ATTENDANCE_STATUS_CHART",
  GET_ATTENDANCE_STATUS_CHART_SUCCESS = "action/GET_ATTENDANCE_STATUS_CHART_SUCCESS",
  GET_ATTENDANCE_STATUS_CHART_ERROR = "action/GET_ATTENDANCE_STATUS_CHART_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface AttendanceStatusPayload {
  aggregate_date_to: string;
}

export interface AttendanceStatusResponse extends IResponse {
  attendance_status: any;
}
