import * as React from "react";

function TemperatureBlueIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.08475 2.25208H0V3.31874H1.08475V2.25208Z" fill="#3B3B3B" />
      <path d="M1.08475 4.84399H0V5.91066H1.08475V4.84399Z" fill="#3B3B3B" />
      <path d="M1.08475 7.43335H0V8.50002H1.08475V7.43335Z" fill="#3B3B3B" />
      <path
        d="M4.06776 14.4801C5.41571 14.4801 6.50844 13.3666 6.50844 11.993C6.50844 10.6195 5.41571 9.50598 4.06776 9.50598C2.71981 9.50598 1.62708 10.6195 1.62708 11.993C1.62708 13.3666 2.71981 14.4801 4.06776 14.4801Z"
        fill="#83BCE2"
      />
      <path
        d="M4.74583 7.43335H3.38989V9.85131H4.74583V7.43335Z"
        fill="#83BCE2"
      />
      <path
        d="M4.06784 16C3.02517 15.9993 2.02541 15.5769 1.28814 14.8256C0.55086 14.0743 0.136344 13.0556 0.135625 11.9931C0.134609 11.3599 0.281676 10.7356 0.564639 10.1719C0.847602 9.60823 1.25831 9.12135 1.76275 8.75164V2.34888C1.76347 1.72614 2.00655 1.12912 2.43869 0.68878C2.87082 0.248439 3.45671 0.000731341 4.06784 0C4.67874 0.00145959 5.26422 0.249397 5.6962 0.689581C6.12818 1.12977 6.37149 1.72637 6.37293 2.34888V8.75164C6.87704 9.12167 7.28751 9.60862 7.57044 10.1722C7.85336 10.7359 8.00062 11.36 8.00005 11.9931C7.99861 13.0553 7.58387 14.0737 6.84674 14.8248C6.10962 15.5759 5.11028 15.9985 4.06784 16ZM4.06784 0.829016C3.67226 0.829016 3.29288 0.989144 3.01316 1.27417C2.73345 1.5592 2.57631 1.94579 2.57631 2.34888V9.19655L2.38919 9.31814C1.94752 9.60437 1.58395 9.99963 1.33221 10.4673C1.08048 10.9349 0.94873 11.4597 0.949186 11.9931C0.949905 12.8357 1.2787 13.6436 1.86341 14.2394C2.44811 14.8352 3.24094 15.1703 4.06784 15.171C4.89473 15.1703 5.68756 14.8352 6.27227 14.2394C6.85697 13.6436 7.18577 12.8357 7.18649 11.9931C7.18644 11.4598 7.05447 10.9352 6.80278 10.4676C6.55109 10.0001 6.1878 9.60474 5.74649 9.31814L5.55937 9.19655V2.34888C5.56008 2.14869 5.52205 1.95033 5.44744 1.76514C5.37284 1.57995 5.26313 1.41156 5.1246 1.26962C4.98606 1.12769 4.82141 1.01497 4.64008 0.937947C4.45875 0.86092 4.26429 0.821088 4.06784 0.820726V0.829016Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}

export default TemperatureBlueIcon;
