import { ActionType } from "models/setting/deleteNotification";

export const deleteNotificationAction = (payload: {
  company_user_send_notification_id: number;
}) => {
  return {
    type: ActionType.DELETE_NOTIFICATION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_NOTIFICATION_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: any) => {
  return {
    type: ActionType.DELETE_NOTIFICATION_SUCCESS,
    payload,
  };
};
