import * as React from "react";

function InformationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_7796_115894)">
        <path
          d="M23 12C23 16.9706 18.9706 21 14 21C9.02944 21 5 16.9706 5 12C5 7.02944 9.02944 3 14 3C18.9706 3 23 7.02944 23 12Z"
          fill="white"
          stroke="#3B3B3B"
          strokeWidth="2"
        />
        <path
          d="M15.8792 16.0389C15.9582 16.1993 15.9986 16.3762 15.9969 16.5551C16.0042 16.6168 15.9985 16.6793 15.98 16.7386C15.9616 16.7979 15.9309 16.8527 15.8899 16.8992C15.7776 16.978 15.6403 17.0124 15.5044 16.996H12.142C12.0614 16.8242 12.0176 16.6374 12.0135 16.4476C12.0072 16.3892 12.0136 16.3302 12.032 16.2745C12.0505 16.2189 12.0807 16.1679 12.1206 16.125C12.2108 16.0605 12.3208 16.0301 12.4311 16.0389H13.0094C13.0094 15.4367 13.0094 14.7916 13.0094 14.1141C13.0094 13.0388 13.0094 12.3936 13.0094 12.0495C13.0075 11.7976 12.9677 11.5475 12.8916 11.3075C12.8751 11.2406 12.8433 11.1786 12.7987 11.1263C12.7541 11.074 12.6979 11.0329 12.6346 11.0064C12.4675 10.985 12.2984 10.985 12.1313 11.0064C12.0456 10.8676 12.0011 10.7072 12.0028 10.544C11.991 10.4383 12.0168 10.3317 12.0755 10.2432C12.1343 10.1547 12.2223 10.0898 12.324 10.0601C12.7585 9.98212 13.2003 9.95331 13.6411 9.9741C13.9776 9.9602 14.3146 9.97818 14.6477 10.0279C14.8298 10.0279 14.9368 10.1354 14.9582 10.2429C14.9724 10.3967 14.9724 10.5515 14.9582 10.7053V14.1679C14.9582 14.8453 14.9582 15.4906 14.9582 16.0927L15.8792 16.0389ZM12.8594 8.75897C12.7635 8.67343 12.6885 8.56683 12.6402 8.44748C12.5919 8.32813 12.5717 8.19922 12.581 8.07074C12.5781 7.92485 12.6084 7.7802 12.6696 7.64787C12.7309 7.51554 12.8214 7.39902 12.9344 7.30723C13.2286 7.09172 13.5881 6.98535 13.9517 7.00617C14.2899 6.9744 14.6282 7.066 14.9047 7.26424C14.9962 7.35094 15.0683 7.4562 15.1163 7.57298C15.1643 7.68977 15.1871 7.81543 15.1831 7.94171C15.1878 8.08418 15.1604 8.2259 15.1029 8.35625C15.0455 8.48659 14.9594 8.6022 14.8512 8.69445C14.5417 8.92052 14.1617 9.02735 13.7804 8.99551C13.4552 9.02854 13.1289 8.94475 12.8594 8.75897V8.75897Z"
          fill="#3B3B3B"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7796_115894"
          x="-2"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={props.offset} />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7796_115894"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7796_115894"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default InformationIcon;
