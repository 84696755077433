import React from "react";
import { useTranslation } from "react-i18next";

import { CloseIcon, EditIcon, TrashIcon } from "icons";
import { IChildrenData } from "models/activitymanagement/activityList";

import {
  WrapperDropdown,
  WrapperCloseIcon,
  Text,
} from "./ActivityManagement.style";

interface IEditDeleteDropDown {
  item: IChildrenData;
  handleEdit: (props: IChildrenData) => void;
  handleConfirmleDeleteItem: (props: IChildrenData) => void;
  handleCloseMenuDropdown: (props: IChildrenData) => void;
}

const EditDeleteDropdown: React.FC<IEditDeleteDropDown> = ({
  item,
  handleEdit,
  handleConfirmleDeleteItem,
  handleCloseMenuDropdown,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });

  return (
    <WrapperDropdown>
      <div
        style={{ display: "flex", cursor: "pointer" }}
        onClick={() => {
          handleEdit(item);
        }}
      >
        <EditIcon fill="#2F8CAE" />
        <Text style={{ color: "#2F8CAE" }}>{t("edit")}</Text>
      </div>

      {item.delete_flag !== true && (
        <div
          style={{ display: "flex", marginTop: 11, cursor: "pointer" }}
          onClick={() => {
            handleConfirmleDeleteItem(item);
          }}
        >
          <TrashIcon fill="#FB4E4E" />
          <Text style={{ color: "#FB4E4E" }}>{t("delete")}</Text>
        </div>
      )}

      <WrapperCloseIcon
        onClick={() => {
          handleCloseMenuDropdown(item);
        }}
      >
        <CloseIcon fill="#2F8CAE" height="14px" width="14px" />
      </WrapperCloseIcon>
    </WrapperDropdown>
  );
};

export default EditDeleteDropdown;
