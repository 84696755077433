import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ILastNotificationTimePayload,
  ActionType,
  ILastNotificationTimeResponse,
} from "models/mentalHealth/lastNotificationTime";
import { httpStatus } from "configs/httpStatus";
import { getLastNotificationTime } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./lastNotificationTimeActions";

function* lastNotificationTimeSaga({
  payload,
}: {
  payload: ILastNotificationTimePayload;
}) {
  try {
    const response: ILastNotificationTimeResponse = yield call(
      getLastNotificationTime,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetLastNotificationTimeWatcher() {
  yield takeLatest(
    ActionType.LAST_SEND_NOTIFICATION_TIME as any,
    lastNotificationTimeSaga
  );
}

export default [fork(onGetLastNotificationTimeWatcher)];
