export enum ActionType {
  // creatte user
  CREATE_USER = "action/CREATE_USER",
  CREATE_USER_SUCCESS = "action/CREATE_USER_SUCCESS",
  CREATE_USER_ERROR = "action/CREATE_USER_ERROR",
  RESET_CREATE_USER = "action/RESET_CREATE_USER",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface CreateUserData {
  user: {
    last_name: string;
    first_name: string;
    furi_first_name: string;
    furi_last_name: string;
    gender: number;
    date_of_birth: string;
    dept_id: number | null;
    sub_dept_id: number | null;
    company_sub_department_id: number[];
    roll_number?: number;
  };
  locale: string | null;
}
