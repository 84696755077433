import { UpdateTemperatureRequest } from "models/setting/updateTemperature";
import { IUpdateGeneralResponse } from "models/setting/updateGeneral";
import { IUpdateMessagePayload } from "models/setting/updateMessage";
import { IUpdateDeseasePayload } from "models/setting/updateDeseaseOutbreak";
import { IUpdateUserRegistrationPayload } from "models/setting/updateUserRegistration";
import { IHealthCheckSettingPayload } from "models/setting/healthCheckSetting";
import { createApiCall2, MethodType, URI, createApiCall } from "../Api";
import { localStorageHelper } from "utils";
import { IUpdateChartOrderPayload } from "models/setting/updateChartOrder";

export const fetchDataSetting = () => {
  return createApiCall2(MethodType.GET, URI.SETTINGS.GET_DATA_SETTING);
};

export const updateTemperatureSetting = (payload: UpdateTemperatureRequest) => {
  return createApiCall2(
    MethodType.POST,
    URI.SETTINGS.UPDATE_TEMPERATURE,
    payload
  );
};

export const updateGeneralSetting = (payload: IUpdateGeneralResponse) => {
  return createApiCall2(MethodType.POST, URI.SETTINGS.UPDATE_GENERAL, payload);
};

export const deleteNotificationSetting = (payload: {
  company_user_send_notification_id: number;
}) => {
  return createApiCall2(
    MethodType.DELETE,
    URI.SETTINGS.DELETE_NOTIFICATION_SETTING,
    payload
  );
};

export const updateMessageSetting = (payload: IUpdateMessagePayload) => {
  return createApiCall2(MethodType.POST, URI.SETTINGS.UPDATE_MESSAGE, payload);
};

export const setHealthCheckSetting = (payload: IHealthCheckSettingPayload) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/health_check_questions/settings`;
  return createApiCall(MethodType.POST, url, payload);
};

export const getHealthCheckSetting = () => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/health_check_questions/settings`;
  return createApiCall(MethodType.GET, url);
};

export const updateDeseaseSetting = (payload: IUpdateDeseasePayload) => {
  return createApiCall2(MethodType.POST, URI.SETTINGS.UPDATE_DESEASE, payload);
};

export const updateChartOrder = (payload: IUpdateChartOrderPayload) => {
  return createApiCall2(
    MethodType.POST,
    URI.SETTINGS.UPDATE_CHART_ORDER,
    payload
  );
};

export const updateUserRegistrationSetting = (
  payload: IUpdateUserRegistrationPayload
) => {
  return createApiCall2(
    MethodType.POST,
    URI.SETTINGS.UPDATE_USER_REGISTRATION,
    payload
  );
};
