import React from "react";
import styled from "styled-components";
import { Button, Modal } from "components";
import { useTranslation } from "react-i18next";

import { theme } from "theme/theme";

const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 20px 16px 24px;
  }
  .ant-modal-footer {
    padding: 0px 16px 20px;
    border: unset;
  }
  div {
    line-height: 16px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 24px 24px 32px 24px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 144px;
    line-height: 23px;
    padding: 3.5px 15px 4.5px;
  }
  button:last-child {
    width: 151px;
  }
  ${theme.media.tablet} {
    justify-content: flex-end;
    button:last-child {
      margin-left: 16px;
    }
    button {
      width: 112px !important;
    }
  }
`;

interface IConfirmModal {
  isVisible: boolean;
  close: () => void;
  ok: () => void;
  children: React.ReactNode;
}

const ConfirmModal: React.FC<IConfirmModal> = ({
  isVisible,
  close,
  ok,
  children,
}) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });

  return (
    <ModalStyled
      type="confirm"
      footer={
        <WrapperBtnFooter>
          <Button
            onClick={close}
            background="#E0E0E0"
            color="#fff"
            name={t("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />

          <Button
            onClick={ok}
            background="#2AC769"
            color="#fff"
            name="OK"
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />
        </WrapperBtnFooter>
      }
      visible={isVisible}
    >
      {children}
    </ModalStyled>
  );
};

export default ConfirmModal;
