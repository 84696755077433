import { createApiCall2, MethodType, URI } from "../Api";
import { ReservationData } from "models/vaccine/reservations";
import {
  TodaySchedulesData,
  ScheduleData,
  DeleteSlotData,
} from "models/vaccine/schedules";
import { VenueData } from "models/vaccine/venues";
import { GetSlotsData } from "models/vaccine/slots";
import { GetTimeSlotData } from "models/vaccine/timeSlot";

export const fetchRecordList = (payload: any) => {
  return createApiCall2(MethodType.GET, URI.VACCINE.GET_RECORD_LIST, payload);
};

export const fetchRecordGraph = (payload: { date: string }) => {
  return createApiCall2(MethodType.GET, URI.VACCINE.GET_RECORD_GRAPH, payload);
};

export const fetchVenuesList = () => {
  return createApiCall2(MethodType.GET, URI.VACCINE.ACTION_VENUES);
};

export const createVenues = (payload: any) => {
  return createApiCall2(MethodType.POST, URI.VACCINE.ACTION_VENUES, payload);
};

export const updateVenues = (payload: { id: number; value: any }) => {
  return createApiCall2(
    MethodType.PUT,
    `${URI.VACCINE.ACTION_VENUES}/${payload.id}`,
    payload.value
  );
};

export const deleteVenues = (payload: { id: number }) => {
  return createApiCall2(
    MethodType.DELETE,
    `${URI.VACCINE.ACTION_VENUES}/${payload.id}`
  );
};

export const fetchReservations = (payload: ReservationData) => {
  return createApiCall2(
    MethodType.GET,
    URI.VACCINE.RESERVATIONS.GET_RESERVATIONS,
    payload
  );
};

export const fetchTodaySchedules = (payload: TodaySchedulesData) => {
  return createApiCall2(
    MethodType.GET,
    URI.VACCINE.RESERVATIONS.TODAY_SCHEDULES,
    payload
  );
};

export const saveSchedule = (payload: ScheduleData) => {
  return createApiCall2(
    MethodType.POST,
    URI.VACCINE.RESERVATIONS.SAVE_SCHEDULE_SECTIONS,
    payload
  );
};

export const updateSchedule = (payload: ScheduleData) => {
  return createApiCall2(
    MethodType.POST,
    URI.VACCINE.RESERVATIONS.UPDATE_SCHEDULE_SECTIONS,
    payload
  );
};

export const fetchVenues = (payload?: VenueData) => {
  return createApiCall2(
    MethodType.GET,
    URI.VACCINE.RESERVATIONS.GET_VENUES,
    payload
  );
};

export const fetchScheduleFilter = (payload?: any) => {
  return createApiCall2(
    MethodType.GET,
    URI.VACCINE.RESERVATIONS.GET_SCHEDULE_FILTER,
    payload
  );
};

export const fetchSlotsData = (payload?: GetSlotsData) => {
  return createApiCall2(
    MethodType.GET,
    URI.VACCINE.RESERVATIONS.GET_ADD_SLOT,
    payload
  );
};

export const fetchTimeSlotData = (payload?: GetTimeSlotData) => {
  return createApiCall2(
    MethodType.GET,
    URI.VACCINE.RESERVATIONS.GET_TIME_SLOT,
    payload
  );
};

export const deleteSlot = (payload?: DeleteSlotData) => {
  return createApiCall2(
    MethodType.DELETE,
    URI.VACCINE.RESERVATIONS.DELETE_SLOT,
    payload
  );
};
