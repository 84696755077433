import * as React from "react";

function ConfirmFailIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="180"
      height="180"
      viewBox="0 0 180 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="7"
        y="7"
        width="166"
        height="166"
        rx="83"
        fill="white"
        stroke="#FB4E4E"
        strokeWidth="14"
      />
      <path
        d="M61.9561 62.0439L117.868 117.956"
        stroke="#FB4E4E"
        strokeWidth="17"
        strokeLinecap="round"
      />
      <path
        d="M61.9561 117.956L117.868 62.0441"
        stroke="#FB4E4E"
        strokeWidth="17"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default ConfirmFailIcon;
