import React from "react";
import { Grid } from "antd";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { WrapperTooltipPieChart } from "../Questions.style";

const { useBreakpoint } = Grid;

const PieChartWithCustomizedLabel = ({ data, colors }: any) => {
  const screens = useBreakpoint();
  const size = screens.md ? 240 : 180;

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <WrapperTooltipPieChart>
          <div>{payload[0].name}</div>
          <div>{`値: ${payload[0].value}%`}</div>
        </WrapperTooltipPieChart>
      );
    }

    return null;
  };

  return (
    <PieChart width={size} height={size}>
      <Tooltip content={<CustomTooltip />} />
      <Pie
        data={data}
        labelLine={false}
        outerRadius={120}
        fill="#8884d8"
        dataKey="percent"
      >
        {data.map((entry: any, index: number) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default PieChartWithCustomizedLabel;
