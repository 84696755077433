import {
  ICreateQuestionnairesPayload,
  ActionType,
  CreateQuestionnairesResponse,
} from "models/questionnaires/createQuestionnaires";

export const createQuestionnairesAction = (
  payload: ICreateQuestionnairesPayload
) => {
  return {
    type: ActionType.CREATE_QUESTIONNAIRES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_QUESTIONNAIRES_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: CreateQuestionnairesResponse) => {
  return {
    type: ActionType.CREATE_QUESTIONNAIRES_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_QUESTIONNAIRES_RESET,
  };
};
