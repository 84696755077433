import {
  ISendQuestionnaireData,
  ActionType,
  SendQuestionnaireResponse,
} from "models/questionnaires/sendQuestionnaires";

export const sendQuestionnaireAction = (payload: any) => {
  return {
    type: ActionType.SEND_QUESTIONNAIRE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SEND_QUESTIONNAIRE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: SendQuestionnaireResponse) => {
  return {
    type: ActionType.SEND_QUESTIONNAIRE_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.SEND_QUESTIONNAIRE_RESET,
  };
};
