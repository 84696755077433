import { Action, ActionType, IGraphDataResponse } from "models/vaccine/graph";
import createReducer from "../../createReducer";

export interface GraphDataReducerType {
  loading: boolean;
  error?: string;
  graphDataResponse?: IGraphDataResponse;
}

const defaultState: GraphDataReducerType = {
  loading: false,
  error: undefined,
  graphDataResponse: undefined,
};

export const graphDataReducer = createReducer<GraphDataReducerType>(
  defaultState,
  {
    [ActionType.VACCINE_RECORD_GRAPH](state: GraphDataReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.VACCINE_RECORD_GRAPH_ERROR](
      state: GraphDataReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        graphDataResponse: [],
      };
    },

    [ActionType.VACCINE_RECORD_GRAPH_SUCCESS](
      state: GraphDataReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        graphDataResponse: action.payload,
      };
    },
  }
);
