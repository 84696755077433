import { IResponse } from "models";

export enum ActionType {
  GET_HEALTH_CHECK_PROGRESS_CHART = "action/GET_HEALTH_CHECK_PROGRESS_CHART",
  GET_HEALTH_CHECK_PROGRESS_CHART_SUCCESS = "action/GET_HEALTH_CHECK_PROGRESS_CHART_SUCCESS",
  GET_HEALTH_CHECK_PROGRESS_CHART_ERROR = "action/GET_HEALTH_CHECK_PROGRESS_CHART_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface HealthCheckProgressPayload {
  aggregate_date: string;
}

export interface HealthCheckProgressResponse extends IResponse {
  progress: any;
}
