export enum ActionType {
  CLEAR_TEMP_REGISTERS = "action/CLEAR_TEMP_REGISTERS",
  CLEAR_TEMP_REGISTERS_SUCCESS = "action/CLEAR_TEMP_REGISTERS_SUCCESS",
  CLEAR_TEMP_REGISTERS_ERROR = "action/CLEAR_TEMP_REGISTERS_ERROR",
  CLEAR_TEMP_REGISTERS_RESET = "action/CLEAR_TEMP_REGISTERS_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IClearTempRegisterData {
  id?: number;
  locale?: string;
}
