import {
  ActionType,
  IStudentListClassChangeResponse,
  IStudentListClassChangeData,
} from "../../../models/classchangemanagement/studentListClassChange";

export const getStudentListClassChangeAction = (
  payload: IStudentListClassChangeData
) => {
  return {
    type: ActionType.STUDENT_LIST_CLASS_CHANGE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.STUDENT_LIST_CLASS_CHANGE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (
  payload: IStudentListClassChangeResponse
) => {
  return {
    type: ActionType.STUDENT_LIST_CLASS_CHANGE_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.STUDENT_LIST_CLASS_CHANGE_RESET,
  };
};
