import { IResponse } from "models";

export enum ActionType {
  // user attendance
  USER_ATTENDANCE = "action/USER_ATTENDANCE",
  USER_ATTENDANCE_SUCCESS = "action/USER_ATTENDANCE_SUCCESS",
  USER_ATTENDANCE_ERROR = "action/USER_ATTENDANCE_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface UserAttendanceData {
  patient_id: number;
  start_date?: string;
  end_date?: string;
  locale: string;
}

export interface UserAttendanceResponse extends IResponse {
  result: UserAttendance[];
}

export interface UserAttendance {
  date: string;
  attendance_status: string;
}
