import { IResponse } from "models";

export enum ActionType {
  CREATE_PATIENT_PROFILE = "action/CREATE_PATIENT_PROFILE",
  CREATE_PATIENT_PROFILE_SUCCESS = "action/CREATE_PATIENT_PROFILE_SUCCESS",
  CREATE_PATIENT_PROFILE_ERROR = "action/CREATE_PATIENT_PROFILE_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface PatientProfileResponse extends IResponse {
  result: any;
}

interface ITempAnswer {
  additional_comment: string;
  answer_id: number[];
  question_id: number;
  question_number: number;
}

export interface CreatePatientProfileRequestData {
  patient_id?: number;
  temp_answers_attributes: ITempAnswer[];
}
