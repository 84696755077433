import { IResponse } from "models";

export enum ActionType {
  // get activity group list
  ACTIVITIES_LIST = "action/ACTIVITIES_LIST",
  ACTIVITIES_LIST_SUCCESS = "action/ACTIVITIES_LIST_SUCCESS",
  ACTIVITIES_LIST_ERROR = "action/ACTIVITIES_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ActivitiesListData {
  locale: string | null;
}

export interface ActivitiesListResponse extends IResponse {
  result: ActivitesResultData;
}

export interface ActivitesResultData {
  id: number;
  compulsory_group: string | number | any;
  group_list: IGroupListOption[];
}

export interface IGroupListOption {
  id: number;
  text: string;
  children: IGroupListOption[];
}
