import {
  Action,
  ActionType,
  IConsultationListResponse,
} from "models/consultationRequest/consultationList";
import createReducer from "redux/createReducer";

export interface ConsultationListReducerType {
  loading: boolean;
  error?: string;
  consultationListResponse?: IConsultationListResponse;
}

const defaultState: ConsultationListReducerType = {
  loading: false,
  error: undefined,
  consultationListResponse: undefined,
};

export const consultationListReducer =
  createReducer<ConsultationListReducerType>(defaultState, {
    [ActionType.CONSULTATION_LIST](state: ConsultationListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CONSULTATION_LIST_ERROR](
      state: ConsultationListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        consultationListResponse: undefined,
      };
    },

    [ActionType.CONSULTATION_LIST_SUCCESS](
      state: ConsultationListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        consultationListResponse: action.payload,
      };
    },

    [ActionType.CONSULTATION_LIST_RESET](state: ConsultationListReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
  });
