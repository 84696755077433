import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/messagemanagement/messageReadHistory";
import { httpStatus } from "configs/httpStatus";
import { fetchReadHistoryList } from "services/messagemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./readHistoryListActions";
import { history } from "../../configureStore";
import {
  IReadHistoryData,
  IReadHistoryResponse,
} from "models/messagemanagement/messageReadHistory";

function* readHistoryListSaga({ payload }: { payload: IReadHistoryData }) {
  try {
    const response: IReadHistoryResponse = yield call(
      fetchReadHistoryList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({
          ...response,
          result: {
            total: 0,
            total_read: 0,
            total_unread: 0,
            total_record: 0,
            histories: [],
          },
        })
      );
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
      history.push("/login");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onReadHistoryListWatcher() {
  yield takeLatest(ActionType.READ_HISTORY_LIST as any, readHistoryListSaga);
}

export default [fork(onReadHistoryListWatcher)];
