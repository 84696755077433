import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  // get user list
  CLASS_LIST = "action/CLASS_LIST",
  CLASS_LIST_SUCCESS = "action/CLASS_LIST_SUCCESS",
  CLASS_LIST_ERROR = "action/CLASS_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ClassListData {
  locale: string | null;
}

export interface ClassListResponse extends IResponse {
  result?: IOption[];
}
