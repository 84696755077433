import { ActionType, ReservationsResponse } from "models/vaccine/reservations";

export const getReservationsAction = (payload: any) => {
  return {
    type: ActionType.RESERVATIONS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.RESERVATIONS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ReservationsResponse) => {
  return {
    type: ActionType.RESERVATIONS_SUCCESS,
    payload,
  };
};
