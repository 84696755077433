import {
  ActionType,
  IConsultationSettingsResponse,
} from "models/consultationRequest/consultationSettings";

export const getConsultationSettingsAction = () => {
  return {
    type: ActionType.CONSULTATION_SETTINGS,
  };
};

export const handleErrorAction = (payload: any) => {
  return {
    type: ActionType.CONSULTATION_SETTINGS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IConsultationSettingsResponse) => {
  return {
    type: ActionType.CONSULTATION_SETTINGS_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.CONSULTATION_SETTINGS_RESET,
  };
};
