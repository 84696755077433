import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ScheduleData,
  ActionType,
  UpdateScheduleResponse,
} from "models/vaccine/schedules";
import { httpStatus } from "configs/httpStatus";
import { updateSchedule } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./updateScheduleActions";

function* updateScheduleSaga({ payload }: { payload: ScheduleData }) {
  try {
    const response: UpdateScheduleResponse = yield call(
      updateSchedule,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSaveScheduleWatcher() {
  yield takeLatest(ActionType.UPDATE_SCHEDULE as any, updateScheduleSaga);
}

export default [fork(onSaveScheduleWatcher)];
