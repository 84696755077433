import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  ScheduleFilterDatasResponse,
} from "models/vaccine/schedules";
import { httpStatus } from "configs/httpStatus";
import { fetchScheduleFilter } from "services/vaccine/index";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./getScheduleFilterActions";
import { history } from "../../configureStore";

function* scheduleFilterSaga({ payload }: any) {
  try {
    const response: ScheduleFilterDatasResponse = yield call(
      fetchScheduleFilter,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({
          ...response,
          result: {
            total: 0,
            schedule_sections: [],
          },
        })
      );
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
      history.push("/login");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onScheduleFilterWatcher() {
  yield takeLatest(ActionType.SCHEDULE_FILTER as any, scheduleFilterSaga);
}

export default [fork(onScheduleFilterWatcher)];
