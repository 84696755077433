import { IResponse } from "models";

export enum ActionType {
  MENTAL_CLASS_LIST = "action/MENTAL_CLASS_LIST",
  MENTAL_CLASS_LIST_SUCCESS = "action/MENTAL_CLASS_LIST_SUCCESS",
  MENTAL_CLASS_LIST_ERROR = "action/MENTAL_CLASS_LIST_ERROR",
  MENTAL_CLASS_LIST_RESET_DATA = "action/MENTAL_CLASS_LIST_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ClassListData {
  parent_class_id: any;
}

export interface IClass {
  id: number;
  class_name: string;
}

export interface IClassListResponse extends IResponse {
  result?: IClass[];
}
