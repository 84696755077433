import React from "react";

import styled, { keyframes } from "styled-components";
import { LoadingIcon } from "icons";

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    12.5% {
        transform: rotate(45deg);
    }
    25% {
        transform: rotate(90deg);
    }
    37.5% {
        transform: rotate(135deg);
    }
    50% {
        transform: rotate(180deg);
    }
    62.5% {
        transform: rotate(225deg);
    }
    75% {
        transform: rotate(270deg);
    }
    87.50% {
        transform: rotate(315deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Loader = styled.div`
  svg {
    animation: ${spin} 2s linear infinite;
  }
`;

const Loading: React.FC<any> = (rest) => {
  return (
    <Loader>
      <LoadingIcon {...rest} />
    </Loader>
  );
};

export default Loading;
