import React from "react";
import { Radio, Space } from "antd";

interface IRadio {
  row?: boolean;
  onChange?: (e: any) => void;
  list?: any[];
  defaultValue?: string[];
  value?: any;
}
interface IRadioItem {
  value?: string | number;
  key?: number;
  name?: string;
}
const RadioCustom: React.FC<IRadio> = ({
  row,
  list,
  onChange,
  defaultValue,
  value,
}) => {
  const styles = row
    ? { display: "flex", flexFlow: "row wrap", gap: "25px 28px" }
    : "";
  const spaceItem = row ? {} : { marginBottom: 12 };
  return (
    <Radio.Group
      style={{ width: "100%" }}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
    >
      <Space direction="vertical">
        <div style={{ ...styles }}>
          {(list || []).map((item: IRadioItem) => (
            <div key={item.key} style={{ ...spaceItem }}>
              <Radio value={item.value}>{item.name}</Radio>
            </div>
          ))}
        </div>
      </Space>
    </Radio.Group>
  );
};

export default RadioCustom;
