import * as AuthenticationActions from "redux/authentication/authenticationActions";
import * as ForgotPasswordActions from "redux/forgotpassword/forgotpasswordActions";
import * as ResetPasswordActions from "redux/resetpassword/resetpasswordActions";
import * as UserListActions from "redux/userlist/userListActions";
import * as ClassListActions from "redux/classlist/classListActions";
import * as DepartmentListActions from "redux/department/departmentListActions";
import * as SubDepartmentListActions from "redux/subdepartment/subdepartmentListActions";
import * as ActivitiesListActions from "redux/activitieslist/activitiesListActions";
import * as CreateUserActions from "redux/createuserlist/createuserActions";
import * as UpateUserActions from "redux/updateuser/updateuserActions";
import * as DeleteUserActions from "redux/deleteuser/deleteuserActions";
import * as csvDownloadActions from "redux/csvdownloaduser/csvdownloaduserActions";
import * as userAttendanceActions from "redux/userattendance/userAttendanceActions";
import * as SchoolClassListActions from "redux/classmanagement/getclasses/classesListActions";
import * as CreateClassActions from "redux/classmanagement/createclasses/createClassActions";
import * as UpdateClassActions from "redux/classmanagement/updateclasses/updateClassActions";
import * as SchoolTypeActions from "redux/classmanagement/schooltype/schoolTypeActions";
import * as ClassTypeActions from "redux/classmanagement/classtype/classTypeActions";
import * as CreateBulkClassActions from "redux/classmanagement/createbulkclasses/createBulkClassActions";
import * as DeleteClassActions from "redux/classmanagement/deleteclass/deleteClassActions";
import * as ActivityListActions from "redux/activitymanagement/getactivities/activitiesListActions";
import * as CreateActivityActions from "redux/activitymanagement/createactivity/createActivityActions";
import * as UpdateActivityActions from "redux/activitymanagement/updateactivity/updateActivityActions";
import * as DeleteActivityActions from "redux/activitymanagement/deleteactivity/deleteActivityActions";
import * as ActivitiesListFilterActions from "redux/messagemanagement/activitiesfilterlist/activitiesFilterListActions";
import * as ClassListFilterActions from "redux/messagemanagement/classfilterlist/classFilterListActions";
import * as GetStudentListActions from "redux/messagemanagement/getstudentlist/getStudentListActions";
import * as GetDataChartsActions from "redux/bodyTemperature/charts/chartActions";
import * as GetDataStatisticsActions from "redux/bodyTemperature/statistics/statisticsActions";
import * as GetDataDestinationActions from "redux/bodyTemperature/destinations/destinationActions";
import * as GetDataGroupClassActions from "redux/bodyTemperature/groupClass/groupClassActions";
import * as GetDataChildDepartmentActions from "redux/bodyTemperature/childDepartment/childDepartmentActions";
import * as GetDataPatientChildDepartmentActions from "redux/bodyTemperature/patientChildDepartment/patientChildActions";
import * as CreatePatientProfileActions from "redux/bodyTemperature/createPatientProfile/patientProfileActions";
import * as GetDataDetailFormActions from "redux/bodyTemperature/detailForm/detailFormActions";
import * as GetDataPatientDetailActions from "redux/bodyTemperature/patientDetail/patientDetailActions";
import * as GetDataFeverPercentageActions from "redux/bodyTemperature/feverPercentage/feverPercentageActions";
import * as GetDataBodyTemperatureSettingFormActions from "redux/bodyTemperature/settingForm/settingFormActions";
import * as GetDataBodyTemperatureFileActions from "redux/bodyTemperature/file/fileActions";
import * as GetDataProgressBarActions from "redux/bodyTemperature/progressBar/progressBarActions";
import * as ApproveAbsenceActions from "redux/bodyTemperature/approveAbsence/approveAbsenceActions";
import * as GetStudentListClassChangeActions from "redux/classchangemanagement/getStudentData/getStudentListClassChangeActions";
import * as GetConfirmStudentListActions from "redux/classchangemanagement/getConfirmStudentData/getConfirmStudentListActions";
import * as ClearTempRegisterActions from "redux/classchangemanagement/clearTempRegisterData/clearTempRegisterActions";
import * as PutConfirmStudentClassChangeActions from "redux/classchangemanagement/putConfirmStudentData/putConfirmStudentClassChangeActions";
import * as GetTemperatureListFilterActions from "redux/messagemanagement/temperaturefilterlist/temperatureFilterListActions";
import * as GetMessageHistoryListActions from "redux/messagemanagement/messagehistory/messageHistoryListActions";
import * as GetMessageHistoryDetailActions from "redux/messagemanagement/messagehistorydetail/messageHistoryDetailActions";
import * as GetAttendanceFilterListActions from "redux/messagemanagement/attendancefilterlist/attendanceFilterListActions";
import * as SendMessageActions from "redux/messagemanagement/sendmessage/sendMessageActions";
import * as GetDownloadCSVStudentListActions from "redux/classchangemanagement/getCSVDownloadStudentList/getDownloadCSVStudentListActions";
import * as uploadCSVStudentListActions from "redux/classchangemanagement/uploadCSV/uploadCSVActions";
import * as saveTemporaryStudentListActions from "redux/classchangemanagement/saveTemporary/saveTemporaryActions";
import * as DownloadQrcodeActions from "redux/qrcode/qrcodeActions";
import * as searchConditionActions from "redux/messagemanagement/searchCondition/searchConditionActions";
import * as accountListActions from "redux/account/accountList/accountListActions";
import * as deleteAccountActions from "redux/account/deleteAccount/deleteAccountActions";
import * as departmentListActions from "redux/account/departmentList/departmentListActions";
import * as editAccountActions from "redux/account/editAccount/editAccountActions";
import * as accountRegistrationListActions from "redux/account/registrationList/registrationListActions";
import * as createAccountActions from "redux/account/createAccount/createAccountActions";
import * as settingDataActions from "redux/setting/settingData/settingDataActions";
import * as updateTemperatureSettingActions from "redux/setting/updateTemperature/updateTemperatureActions";
import * as updateGeneralSettingActions from "redux/setting/updateGeneral/updateGeneralActions";
import * as deleteNotificationActions from "redux/setting/deleteNotification/deleteNotificationActions";
import * as updateMessageActions from "redux/setting/updateMessage/updateMessageActions";
import * as getClassListActions from "redux/classchangemanagement/getClassList/getClassListActions";
import * as readHistoryListActions from "redux/messagemanagement/readHistory/readHistoryListActions";
import * as recordListActions from "redux/vaccine/records/recordsActions";
import * as vaccineGraphDataActions from "redux/vaccine/graph/graphActions";
import * as createVenuesActions from "redux/vaccine/venues/createVenues/createVenuesActions";
import * as venuesListActions from "redux/vaccine/venues/list/listActions";
import * as deleteVenuesActions from "redux/vaccine/venues/deleteVenues/deleteVenuesActions";
import * as updateVenuesActions from "redux/vaccine/venues/updateVenues/updateVenuesActions";
import * as reservationsActions from "redux/vaccine/reservations/reservationsActions";
import * as todaySchedulesActions from "redux/vaccine/todaySchedules/todaySchedulesActions";
import * as saveScheduleActions from "redux/vaccine/saveSchedule/saveScheduleActions";
import * as updateScheduleActions from "redux/vaccine/updateSchedule/updateScheduleActions";
import * as venuesActions from "redux/vaccine/venues/venuesActions";
import * as slotsActions from "redux/vaccine/slots/slotsActions";
import * as deleteSlotActions from "redux/vaccine/deleteSlot/deleteSlotActions";
import * as getScheduleFilterActions from "redux/vaccine/getScheduleFilter/getScheduleFilterActions";
import * as getTimeSlotActions from "redux/vaccine/timeSlot/timeSlotActions";
import * as checkListActions from "redux/mentalHealth/checkList/checkListActions";
import * as checkDetailsActions from "redux/mentalHealth/checkDetails/checkDetailsActions";
import * as gradeListActions from "redux/mentalHealth/gradesList/gradeListActions";
import * as newMentalCheckActions from "redux/mentalHealth/newMentalCheck/newMentalCheckActions";
import * as updateMentalCheckActions from "redux/mentalHealth/updateMentalCheck/updateMentalCheckActions";
import * as deleteMentalCheckActions from "redux/mentalHealth/deleteMentalCheck/deleteMentalCheckActions";
import * as getQuestionnairesActions from "redux/questionnaires/questionnairesByType/questionnairesByTypeActions";
import * as getQuestionnairesDetailActions from "redux/questionnaires/questionnairesDetail/questionnairesDetailActions";
import * as createQuestionnairesActions from "redux/questionnaires/createQuestionnaires/createQuestionnairesActions";
import * as getAnswerInfoActions from "redux/questionnaires/answerInfo/answerInfoActions";
import * as getIndividualAnswerActions from "redux/questionnaires/individualAnswer/individualAnswerActions";
import * as getQuestionResponseDetailActions from "redux/questionnaires/questionResponseDetail/questionResponseDetailActions";
import * as duplicateQuestionActions from "redux/questionnaires/duplicateQuestion/duplicateQuestionActions";
import * as doneQuestionActions from "redux/questionnaires/doneQuestion/doneQuestionActions";
import * as deleteQuestionnaireActions from "redux/questionnaires/deleteQuestionnaires/deleteQuestionnairesActions";
import * as updateQuestionnairesActions from "redux/questionnaires/updateQuestionnaires/updateQuestionnairesActions";
import * as sendQuestionnaireActions from "redux/questionnaires/sendQuestionnaire/sendQuestionnaireActions";
import * as sendQuestionHistoryActions from "redux/questionnaires/sendQuestionHistory/sendQuestionHistoryActions";
import * as questionHistoryDetailActions from "redux/questionnaires/questionHistoryDetail/questionHistoryDetailActions";
import * as answerHistoryActions from "redux/questionnaires/answerHistory/answerHistoryActions";
import * as mentalCheckRequestsActions from "redux/mentalHealth/mentalCheckRequests/mentalCheckRequestsActions";
import * as typeInformationActions from "redux/mentalHealth/typeInformation/typeInformationActions";
import * as sendNotificationActions from "redux/mentalHealth/sendNotification/sendNotificationActions";
import * as downloadTypeCSVActions from "redux/mentalHealth/downloadTypeCSV/downloadTypeCSVActions";
import * as downloadTypePDFActions from "redux/mentalHealth/downloadTypePDF/downloadTypePDFActions";
import * as scoringInformationActions from "redux/mentalHealth/scoringInformation/scoringInformationActions";
import * as mentalHealthTotalActions from "redux/mentalHealth/mentalHealthTotal/mentalHealthTotalActions";
import * as typeListActions from "redux/mentalHealth/typeList/typeListActions";
import * as lastNotificationTimeActions from "redux/mentalHealth/lastNotificationTime/lastNotificationTimeActions";
import * as classListActions from "redux/mentalHealth/classList/classListActions";
import * as downloadScoringPDFActions from "redux/mentalHealth/downloadScoringPDF/downloadScoringPDFActions";
import * as pushNotificationActions from "redux/messagemanagement/pushNotification/pushNotificationActions";
import * as sendInterviewRequestActions from "redux/mentalHealth/sendInterviewRequest/sendInterviewRequestActions";
import * as downloadCSVQuestionActions from "redux/questionnaires/downloadCSVQuestion/downloadCSVQuestionActions";
import * as downloadCSVAllQuestionsActions from "redux/questionnaires/downloadCSVAllQuestions/downloadCSVAllQuestionsActions";
import * as remindPushNotificationActions from "redux/questionnaires/remindPushNotification/remindPushNotificationActions";
import * as cancelQuestionnaireActions from "redux/questionnaires/cancelQuestionnaire/cancelQuestionnaireActions";
import * as temperatureAvgActions from "redux/dashboard/temperatureAvg/temperatureAvgActions";
import * as detailSymtomActions from "redux/dashboard/detailSymtom/detailSymtomActions";
import * as absenceActions from "redux/dashboard/absence/absenceActions";
import * as feverActions from "redux/dashboard/fever/feverActions";
import * as symtomActions from "redux/dashboard/symtom/symtomActions";
import * as temperatureAmountsActions from "redux/dashboard/temperatureAmounts/temperatureAmountsActions";
import * as progressActions from "redux/dashboard/progress/progressActions";
import * as faceScaleActions from "redux/dashboard/faceScale/faceScaleActions";
import * as healthCheckQuestionsActions from "redux/bodyTemperature/healthCheckQuestions/healthCheckQuestionsActions";
import * as healthCheckAnswersActions from "redux/bodyTemperature/healthCheckAnswers/healthCheckAnswersActions";
import * as sendHealthCheckAnswersActions from "redux/bodyTemperature/sendHealthCheckAnswers/sendHealthCheckAnswersActions";
import * as setHealthCheckSettingActions from "redux/setting/healthCheckSetting/healthCheckSettingActions";
import * as getHealthCheckSettingActions from "redux/setting/getHealthCheckSetting/getHealthCheckSettingActions";
import * as updatePositionColumnActions from "redux/updatePositionColumn/updatePositionColumnActions";
import * as healthCheckProgressActions from "redux/dashboard/healthCheckProgress/healthCheckProgressActions";
import * as attendanceStatusActions from "redux/dashboard/attendanceStatus/attendanceStatusActions";
import * as deseaseOutbreakActions from "redux/dashboard/deseaseOutbreak/deseaseOutbreakActions";
import * as prefectureActions from "redux/dashboard/prefecture/prefectureActions";
import * as citiesActions from "redux/dashboard/cities/citiesActions";
import * as updateDeseaseActions from "redux/setting/updateDesease/updateDeseaseActions";
import * as exchangeListActions from "redux/consultationRequest/exchangeList/exchangeListActions";
import * as consultationListActions from "redux/consultationRequest/consultationList/consultationListActions";
import * as completeRequestActions from "redux/consultationRequest/completeRequest/completeRequestActions";
import * as addRequestActions from "redux/consultationRequest/addRequest/addRequestActions";
import * as consultationSettingsActions from "redux/consultationRequest/consultationSettings/consultationSettingsActions";
import * as saveConsultationSettingsActions from "redux/consultationRequest/saveConsultationSettings/saveConsultationSettingsActions";
import * as updateChartOrderActions from "redux/setting/updateChartOrder/updateChartOrderActions";
import * as updateMessageDeliveryActions from "redux/messagemanagement/updateMessage/updateMessageActions";
import * as deleteMessageDeliveryActions from "redux/messagemanagement/deleteMessage/deleteMessageActions";
import * as updateUserRegistrationActions from "redux/setting/updateUserRegistration/updateUserRegistrationActions";
import * as sendNotificationConsultationActions from "redux/consultationRequest/sendNotification/sendNotificationActions";

export const ActionCreators = Object.assign({}, { ...AuthenticationActions });
export const ForgotPasswordActionCreators = Object.assign(
  {},
  { ...ForgotPasswordActions }
);
export const ResetPasswordActionCreators = Object.assign(
  {},
  { ...ResetPasswordActions }
);
export const UserListActionCreators = Object.assign({}, { ...UserListActions });
export const ClassListActionCreators = Object.assign(
  {},
  { ...ClassListActions }
);
export const DepartmentListActionCreators = Object.assign(
  {},
  { ...DepartmentListActions }
);
export const SubDepartmentListActionCreators = Object.assign(
  {},
  { ...SubDepartmentListActions }
);
export const ActivitiesListActionCreators = Object.assign(
  {},
  { ...ActivitiesListActions }
);
export const CreateUserActionCreators = Object.assign(
  {},
  { ...CreateUserActions }
);
export const UpdateUserActionCreators = Object.assign(
  {},
  { ...UpateUserActions }
);
export const DeleteUserActionCreators = Object.assign(
  {},
  { ...DeleteUserActions }
);
export const CSVDownloadActionCreators = Object.assign(
  {},
  { ...csvDownloadActions }
);
export const UserAttendanceActionCreators = Object.assign(
  {},
  { ...userAttendanceActions }
);
export const SchoolClassListActionCreators = Object.assign(
  {},
  { ...SchoolClassListActions }
);
export const CreateClassActionCreators = Object.assign(
  {},
  { ...CreateClassActions }
);
export const UpdateClassActionCreators = Object.assign(
  {},
  { ...UpdateClassActions }
);
export const SchoolTypeActionCreators = Object.assign(
  {},
  { ...SchoolTypeActions }
);
export const ClassTypeActionCreators = Object.assign(
  {},
  { ...ClassTypeActions }
);
export const CreateBulkClassActionCreators = Object.assign(
  {},
  { ...CreateBulkClassActions }
);
export const DeleteClassActionCreators = Object.assign(
  {},
  { ...DeleteClassActions }
);
export const ActivityListActionCreators = Object.assign(
  {},
  { ...ActivityListActions }
);
export const CreateActivityActionCreators = Object.assign(
  {},
  { ...CreateActivityActions }
);
export const UpdateActivityActionCreators = Object.assign(
  {},
  { ...UpdateActivityActions }
);
export const DeleteActivityActionCreators = Object.assign(
  {},
  { ...DeleteActivityActions }
);
export const ActivitiesListFilterActionCreators = Object.assign(
  {},
  { ...ActivitiesListFilterActions }
);
export const ClassListFilterActionCreators = Object.assign(
  {},
  { ...ClassListFilterActions }
);
export const GetStudentActionCreators = Object.assign(
  {},
  { ...GetStudentListActions }
);
export const GetDataChartsActionCreators = Object.assign(
  {},
  { ...GetDataChartsActions }
);
export const GetDataStatisticsActionCreators = Object.assign(
  {},
  { ...GetDataStatisticsActions }
);
export const GetDataDestinationActionCreators = Object.assign(
  {},
  { ...GetDataDestinationActions }
);
export const GetDataGroupClassCreators = Object.assign(
  {},
  { ...GetDataGroupClassActions }
);
export const GetDataChildDepartmentCreators = Object.assign(
  {},
  { ...GetDataChildDepartmentActions }
);
export const GetDataPatientChildDepartmentCreators = Object.assign(
  {},
  { ...GetDataPatientChildDepartmentActions }
);
export const CreatePatientProfileCreators = Object.assign(
  {},
  { ...CreatePatientProfileActions }
);
export const GetDataDetailFormCreators = Object.assign(
  {},
  { ...GetDataDetailFormActions }
);
export const GetDataPatientDetailCreators = Object.assign(
  {},
  { ...GetDataPatientDetailActions }
);
export const GetDataFeverPercentageCreators = Object.assign(
  {},
  { ...GetDataFeverPercentageActions }
);
export const GetDataBodyTemperatureSettingFormCreators = Object.assign(
  {},
  { ...GetDataBodyTemperatureSettingFormActions }
);
export const GetDataBodyTemperatureFileCreators = Object.assign(
  {},
  { ...GetDataBodyTemperatureFileActions }
);
export const GetDataProgressBarCreators = Object.assign(
  {},
  { ...GetDataProgressBarActions }
);
export const ApproveAbsenceCreators = Object.assign(
  {},
  { ...ApproveAbsenceActions }
);
export const GetStudentClassChangeActionCreators = Object.assign(
  {},
  { ...GetStudentListClassChangeActions }
);
export const GetConfirmStudentListActionCreators = Object.assign(
  {},
  { ...GetConfirmStudentListActions }
);
export const ClearTempRegisterActionCreators = Object.assign(
  {},
  { ...ClearTempRegisterActions }
);
export const PutConfirmStudentActionCreators = Object.assign(
  {},
  { ...PutConfirmStudentClassChangeActions }
);
export const GetTemperatureListFilterActionCreators = Object.assign(
  {},
  { ...GetTemperatureListFilterActions }
);
export const GetMessageHistoryListFilterActionCreators = Object.assign(
  {},
  { ...GetMessageHistoryListActions }
);
export const GetAttendanceListFilterActionCreators = Object.assign(
  {},
  { ...GetAttendanceFilterListActions }
);
export const GetMessageHistoryDetailActionCreators = Object.assign(
  {},
  { ...GetMessageHistoryDetailActions }
);
export const SendMessageActionCreators = Object.assign(
  {},
  { ...SendMessageActions }
);
export const GetDownloadCSVStudentListActionCreators = Object.assign(
  {},
  { ...GetDownloadCSVStudentListActions }
);
export const UploadCSVStudentListActionCreators = Object.assign(
  {},
  { ...uploadCSVStudentListActions }
);
export const SaveTemporaryStudentListActionCreators = Object.assign(
  {},
  { ...saveTemporaryStudentListActions }
);
export const DownloadQrcodeActionCreators = Object.assign(
  {},
  {
    ...DownloadQrcodeActions,
  }
);
export const SearchConditionActionCreators = Object.assign(
  {},
  {
    ...searchConditionActions,
  }
);
export const AccountListActionCreators = Object.assign(
  {},
  {
    ...accountListActions,
  }
);
export const DeleteAccountActionCreators = Object.assign(
  {},
  {
    ...deleteAccountActions,
  }
);
export const AccountDepartmentListActionCreators = Object.assign(
  {},
  {
    ...departmentListActions,
  }
);
export const EditAccountActionCreators = Object.assign(
  {},
  {
    ...editAccountActions,
  }
);
export const AccountRegistrationListActionCreators = Object.assign(
  {},
  {
    ...accountRegistrationListActions,
  }
);
export const CreateAccountActionCreators = Object.assign(
  {},
  {
    ...createAccountActions,
  }
);
export const SettingDataActionCreators = Object.assign(
  {},
  {
    ...settingDataActions,
  }
);
export const UpdateTemperatureSettingActionCreators = Object.assign(
  {},
  {
    ...updateTemperatureSettingActions,
  }
);
export const UpdateGeneralSettingActionCreators = Object.assign(
  {},
  {
    ...updateGeneralSettingActions,
  }
);
export const UpdateMessageSettingActionCreators = Object.assign(
  {},
  {
    ...updateMessageActions,
  }
);
export const DeleteNotificationActionCreators = Object.assign(
  {},
  {
    ...deleteNotificationActions,
  }
);
export const GetClassListActionCreators = Object.assign(
  {},
  {
    ...getClassListActions,
  }
);
export const GetReadHistoryActionCreators = Object.assign(
  {},
  {
    ...readHistoryListActions,
  }
);
export const VaccineRecordListActionCreators = Object.assign(
  {},
  {
    ...recordListActions,
  }
);
export const VaccineGraphDataActionCreators = Object.assign(
  {},
  {
    ...vaccineGraphDataActions,
  }
);
export const CreateVenuesActionCreators = Object.assign(
  {},
  {
    ...createVenuesActions,
  }
);
export const VenuesListActionCreators = Object.assign(
  {},
  {
    ...venuesListActions,
  }
);
export const DeleteVenuesActionCreators = Object.assign(
  {},
  {
    ...deleteVenuesActions,
  }
);
export const UpdateVenuesActionCreators = Object.assign(
  {},
  {
    ...updateVenuesActions,
  }
);
export const ReservationsActionCreators = Object.assign(
  {},
  {
    ...reservationsActions,
  }
);
export const TodaySchedulesActionCreators = Object.assign(
  {},
  {
    ...todaySchedulesActions,
  }
);
export const SaveScheduleActionCreators = Object.assign(
  {},
  {
    ...saveScheduleActions,
  }
);
export const UpdateScheduleActionCreators = Object.assign(
  {},
  {
    ...updateScheduleActions,
  }
);
export const GetVenuesActionCreators = Object.assign(
  {},
  {
    ...venuesActions,
  }
);
export const GetSlotsDataActionCreators = Object.assign(
  {},
  {
    ...slotsActions,
  }
);
export const DeleteSlotActionCreators = Object.assign(
  {},
  {
    ...deleteSlotActions,
  }
);
export const ScheduleFilterActionCreators = Object.assign(
  {},
  {
    ...getScheduleFilterActions,
  }
);
export const TimeSlotActionCreators = Object.assign(
  {},
  {
    ...getTimeSlotActions,
  }
);
export const MentalCheckListActionCreators = Object.assign(
  {},
  {
    ...checkListActions,
  }
);
export const MentalCheckDetailsActionCreators = Object.assign(
  {},
  {
    ...checkDetailsActions,
  }
);
export const GradeListActionCreators = Object.assign(
  {},
  {
    ...gradeListActions,
  }
);
export const NewMentalCheckActionCreators = Object.assign(
  {},
  {
    ...newMentalCheckActions,
  }
);
export const UpdateMentalCheckActionCreators = Object.assign(
  {},
  {
    ...updateMentalCheckActions,
  }
);
export const DeleteMentalCheckActionCreators = Object.assign(
  {},
  {
    ...deleteMentalCheckActions,
  }
);
export const QuestionnairesActionCreators = Object.assign(
  {},
  {
    ...getQuestionnairesActions,
  }
);
export const QuestionnairesDetailActionCreators = Object.assign(
  {},
  {
    ...getQuestionnairesDetailActions,
  }
);
export const CreateQuestionnairesActionCreators = Object.assign(
  {},
  {
    ...createQuestionnairesActions,
  }
);
export const AnswerInfoActionCreators = Object.assign(
  {},
  {
    ...getAnswerInfoActions,
  }
);
export const IndividualAnswerActionCreators = Object.assign(
  {},
  {
    ...getIndividualAnswerActions,
  }
);
export const QuestionResponseDetailActionCreators = Object.assign(
  {},
  {
    ...getQuestionResponseDetailActions,
  }
);
export const DuplicateQuestionActionCreators = Object.assign(
  {},
  {
    ...duplicateQuestionActions,
  }
);
export const DoneQuestionActionCreators = Object.assign(
  {},
  {
    ...doneQuestionActions,
  }
);
export const DeleteQuestionnaireActionCreators = Object.assign(
  {},
  {
    ...deleteQuestionnaireActions,
  }
);
export const UpdateQuestionnaireActionCreators = Object.assign(
  {},
  {
    ...updateQuestionnairesActions,
  }
);
export const SendQuestionnaireActionCreators = Object.assign(
  {},
  {
    ...sendQuestionnaireActions,
  }
);
export const questionHistoryActionCreators = Object.assign(
  {},
  {
    ...sendQuestionHistoryActions,
  }
);
export const questionHistoryDetailActionCreators = Object.assign(
  {},
  {
    ...questionHistoryDetailActions,
  }
);
export const answerHistoryActionCreators = Object.assign(
  {},
  {
    ...answerHistoryActions,
  }
);
export const mentalCheckRequestsActionCreators = Object.assign(
  {},
  {
    ...mentalCheckRequestsActions,
  }
);
export const mentalCheckTypeInfoActionCreators = Object.assign(
  {},
  {
    ...typeInformationActions,
  }
);
export const sendNotificationActionCreators = Object.assign(
  {},
  {
    ...sendNotificationActions,
  }
);
export const downloadTypeInfoCSVActionCreators = Object.assign(
  {},
  {
    ...downloadTypeCSVActions,
  }
);
export const downloadTypeInfoPDFActionCreators = Object.assign(
  {},
  {
    ...downloadTypePDFActions,
  }
);
export const GetScoringInformationActionCreators = Object.assign(
  {},
  {
    ...scoringInformationActions,
  }
);
export const GetMentalHealthTotalActionCreators = Object.assign(
  {},
  {
    ...mentalHealthTotalActions,
  }
);
export const GetMentalCheckTypeListActionCreators = Object.assign(
  {},
  {
    ...typeListActions,
  }
);
export const GetLastNotificationTimeActionCreators = Object.assign(
  {},
  {
    ...lastNotificationTimeActions,
  }
);
export const GetMentalClassListActionCreators = Object.assign(
  {},
  {
    ...classListActions,
  }
);
export const DownloadScoringPDFActionCreators = Object.assign(
  {},
  {
    ...downloadScoringPDFActions,
  }
);
export const pushNotificationActionCreators = Object.assign(
  {},
  {
    ...pushNotificationActions,
  }
);
export const sendInterviewRequestActionCreators = Object.assign(
  {},
  {
    ...sendInterviewRequestActions,
  }
);
export const DownloadCSVQuestionActionCreators = Object.assign(
  {},
  {
    ...downloadCSVQuestionActions,
  }
);
export const DownloadCSVAllQuestionsActionCreators = Object.assign(
  {},
  {
    ...downloadCSVAllQuestionsActions,
  }
);
export const RemindPushNotificationActionCreators = Object.assign(
  {},
  {
    ...remindPushNotificationActions,
  }
);
export const CancelQuestionnaireActionCreators = Object.assign(
  {},
  {
    ...cancelQuestionnaireActions,
  }
);
export const TemperatureAvgActionCreators = Object.assign(
  {},
  {
    ...temperatureAvgActions,
  }
);
export const DetailSymtomActionCreators = Object.assign(
  {},
  {
    ...detailSymtomActions,
  }
);
export const AbsenceActionCreators = Object.assign(
  {},
  {
    ...absenceActions,
  }
);
export const FeverActionCreators = Object.assign(
  {},
  {
    ...feverActions,
  }
);
export const SymtomActionCreators = Object.assign(
  {},
  {
    ...symtomActions,
  }
);
export const TemperatureAmountsActionCreators = Object.assign(
  {},
  {
    ...temperatureAmountsActions,
  }
);
export const ProgressActionCreators = Object.assign(
  {},
  {
    ...progressActions,
  }
);
export const FaceScaleActionCreators = Object.assign(
  {},
  {
    ...faceScaleActions,
  }
);
export const GetHealthCheckQuestionsActionCreators = Object.assign(
  {},
  {
    ...healthCheckQuestionsActions,
  }
);
export const GetHealthCheckAnswersActionCreators = Object.assign(
  {},
  {
    ...healthCheckAnswersActions,
  }
);
export const SendHealthCheckAnswersActionCreators = Object.assign(
  {},
  {
    ...sendHealthCheckAnswersActions,
  }
);
export const SetHealthCheckSettingActionCreators = Object.assign(
  {},
  {
    ...setHealthCheckSettingActions,
  }
);
export const GetHealthCheckSettingActionCreators = Object.assign(
  {},
  {
    ...getHealthCheckSettingActions,
  }
);
export const UpdatePositionColumnActionCreators = Object.assign(
  {},
  {
    ...updatePositionColumnActions,
  }
);
export const HealthCheckProgressActionCreators = Object.assign(
  {},
  {
    ...healthCheckProgressActions,
  }
);
export const AttendanceStatusActionCreators = Object.assign(
  {},
  {
    ...attendanceStatusActions,
  }
);
export const DeseaseOutbreakActionCreators = Object.assign(
  {},
  {
    ...deseaseOutbreakActions,
  }
);
export const GetPrefectureActionCreators = Object.assign(
  {},
  {
    ...prefectureActions,
  }
);
export const GetCitiesActionCreators = Object.assign(
  {},
  {
    ...citiesActions,
  }
);
export const UpdateDeseaseSettingActionCreators = Object.assign(
  {},
  {
    ...updateDeseaseActions,
  }
);
export const GetExchangeListActionCreators = Object.assign(
  {},
  {
    ...exchangeListActions,
  }
);
export const GetConsultationListActionCreators = Object.assign(
  {},
  {
    ...consultationListActions,
  }
);
export const CompleteRequestActionCreators = Object.assign(
  {},
  {
    ...completeRequestActions,
  }
);
export const AddRequestActionCreators = Object.assign(
  {},
  {
    ...addRequestActions,
  }
);
export const GetConsultationsSettingsActionCreators = Object.assign(
  {},
  {
    ...consultationSettingsActions,
  }
);
export const SaveConsultationsSettingsActionCreators = Object.assign(
  {},
  {
    ...saveConsultationSettingsActions,
  }
);
export const UpdateChartOrderActionCreators = Object.assign(
  {},
  {
    ...updateChartOrderActions,
  }
);
export const UpdateMessageActionCreators = Object.assign(
  {},
  {
    ...updateMessageDeliveryActions,
  }
);
export const DeleteMessageActionCreators = Object.assign(
  {},
  {
    ...deleteMessageDeliveryActions,
  }
);
export const UpdateUserRegistrationActionCreators = Object.assign(
  {},
  {
    ...updateUserRegistrationActions,
  }
);
export const SendNotificationConsultationActionCreators = Object.assign(
  {},
  {
    ...sendNotificationConsultationActions,
  }
);
