import * as React from "react";

function TemperatureRedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.08475 2.25928H0V3.32594H1.08475V2.25928Z" fill="#3B3B3B" />
      <path d="M1.08475 4.84973H0V5.9164H1.08475V4.84973Z" fill="#3B3B3B" />
      <path d="M1.08475 7.43774H0V8.50441H1.08475V7.43774Z" fill="#3B3B3B" />
      <path
        d="M4.06776 14.4809C5.41571 14.4809 6.50844 13.368 6.50844 11.9952C6.50844 10.6223 5.41571 9.5094 4.06776 9.5094C2.71981 9.5094 1.62708 10.6223 1.62708 11.9952C1.62708 13.368 2.71981 14.4809 4.06776 14.4809Z"
        fill="#FB4E4E"
      />
      <path
        d="M4.7457 2.25928H3.38977V9.85465H4.7457V2.25928Z"
        fill="#FB4E4E"
      />
      <path
        d="M4.06784 16C3.02517 15.9993 2.02541 15.5771 1.28814 14.8262C0.55086 14.0753 0.136344 13.0571 0.135625 11.9952C0.134609 11.3623 0.281672 10.7384 0.564635 10.175C0.847598 9.61154 1.25831 9.12491 1.76275 8.75539V2.35595C1.76203 2.04705 1.82111 1.74104 1.9366 1.45542C2.05209 1.1698 2.22172 0.910159 2.43581 0.691353C2.64989 0.472546 2.90423 0.298865 3.18426 0.180238C3.4643 0.0616101 3.76454 0.000362125 4.06784 0C4.67874 0.00145883 5.26422 0.249268 5.6962 0.689224C6.12818 1.12918 6.37149 1.72547 6.37293 2.34766V8.74711C6.87704 9.11695 7.28751 9.60364 7.57044 10.167C7.85336 10.7303 8.00062 11.3541 8.00005 11.9869C8.00077 13.05 7.58698 14.0699 6.84962 14.8225C6.11227 15.575 5.11169 15.9985 4.06784 16ZM4.06784 0.836872C3.67226 0.836872 3.29288 0.996918 3.01316 1.2818C2.73345 1.56668 2.57631 1.95306 2.57631 2.35595V9.20007L2.38919 9.3216C1.94752 9.60768 1.58396 10.0027 1.33222 10.4701C1.08049 10.9375 0.94873 11.4621 0.949186 11.9952C0.949905 12.8373 1.2787 13.6448 1.86341 14.2403C2.44811 14.8358 3.24094 15.1707 4.06784 15.1714C4.89473 15.1707 5.68756 14.8358 6.27227 14.2403C6.85697 13.6448 7.18577 12.8373 7.18649 11.9952C7.18644 11.4622 7.05448 10.9378 6.80279 10.4705C6.5511 10.0032 6.1878 9.60806 5.74649 9.3216L5.55937 9.20007V2.35595C5.56008 2.15586 5.52205 1.9576 5.44744 1.77251C5.37284 1.58741 5.26313 1.41912 5.1246 1.27725C4.98606 1.13539 4.82141 1.02273 4.64008 0.945747C4.45875 0.86876 4.26429 0.828948 4.06784 0.828586V0.836872Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}

export default TemperatureRedIcon;
