import {
  Action,
  ActionType,
} from "models/classchangemanagement/clearTempRegisterClassChange";
import { IResponse } from "models";
import createReducer from "../../createReducer";

export interface ClearTempRegisterReducerType {
  loading: boolean;
  error?: string;
  clearTempRegisterResponse?: IResponse;
}

const defaultState: ClearTempRegisterReducerType = {
  loading: false,
  error: undefined,
  clearTempRegisterResponse: undefined,
};

export const clearTempRegisterReducer =
  createReducer<ClearTempRegisterReducerType>(defaultState, {
    [ActionType.CLEAR_TEMP_REGISTERS](state: ClearTempRegisterReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CLEAR_TEMP_REGISTERS_ERROR](
      state: ClearTempRegisterReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CLEAR_TEMP_REGISTERS_SUCCESS](
      state: ClearTempRegisterReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        confirmStudentListResponse: action.payload,
      };
    },

    [ActionType.CLEAR_TEMP_REGISTERS_RESET](
      state: ClearTempRegisterReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        confirmStudentListResponse: [],
      };
    },
  });
