import {
  Action,
  ActionType,
  DetailSymtomResponse,
} from "models/dashboard/detailSymtom";
import createReducer from "../../createReducer";

export interface DetailSymtomReducerType {
  loading: boolean;
  error?: string;
  detailSymtomResponse?: DetailSymtomResponse;
}

const defaultState: DetailSymtomReducerType = {
  loading: false,
  error: undefined,
  detailSymtomResponse: undefined,
};

export const getDetailSymtomReducer = createReducer<DetailSymtomReducerType>(
  defaultState,
  {
    [ActionType.GET_DETAIL_SYMTOM_CHART](state: DetailSymtomReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_DETAIL_SYMTOM_CHART_ERROR](
      state: DetailSymtomReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_DETAIL_SYMTOM_CHART_SUCCESS](
      state: DetailSymtomReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        detailSymtomResponse: action.payload,
      };
    },
  }
);
