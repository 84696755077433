import { IResponse } from "models";

export enum ActionType {
  DELETE_NOTIFICATION = "action/DELETE_NOTIFICATION",
  DELETE_NOTIFICATION_SUCCESS = "action/DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_ERROR = "action/DELETE_NOTIFICATION_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDeleteNotificationResponse extends IResponse {}
