import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import { DownloadIcon } from "icons";
import { Modal, Select, Button, Checkbox, Checkbox1 } from "components";
import {
  generateCSV,
  mplusFont,
  convertDate,
  convertFilename,
  udgothicFont,
  localStorageHelper,
  handleGetTimeByTimeZone,
} from "utils";
import { IOption } from "components/select/Select";
import { useGetAttendanceOptions } from "shared-hooks";

import { dataGroupClassResponse } from "redux/bodyTemperature/groupClass/groupClassStates";
import { bodyTemperatureSettingFormResponse } from "redux/bodyTemperature/settingForm/settingFormStates";
import { searchConditionDataResponse } from "redux/messagemanagement/searchCondition/searchConditionStates";
import {
  bodyTemperatureFileResponse,
  selectIsLoading,
} from "redux/bodyTemperature/file/fileStates";
import { GetDataBodyTemperatureFileCreators } from "redux/rootActions";

import {
  WrapperBtnWithIcon,
  WrapperSelectStyled,
  WrapperCheckbox,
  CheckboxStyled3,
  WrapperItem,
  ItemContainer,
  CheckboxStyled4,
} from "./BodyTemperature.style";
import { IModal } from "./SearchTerms";
import { headers, AllDataForFilters } from "./constants";
import { DatePicker, Tooltip } from "./components";
import { getHealthCheckSettingResponse } from "redux/setting/getHealthCheckSetting/getHealthCheckSettingStates";

const schema = yup.object().shape({
  pastDate: yup.string().trim().required("please-select"),
});

const obj: any = {
  is_attendance: "attendance",
  is_family_health_check: "family_health_check",
  is_marathon: "marathon_question",
  is_pool: "pool_join",
  is_temperature: "temperature",
  is_mental_check: "small_mental_check",
  is_health_check: "health_check",
  is_breakfast: "breakfast",
  can_use_breakfast_comment: "breakfast_comment",
};

const selectedValueDefault = {
  activities: [],
  attendance: [],
  health_check: [],
};

const checkAllDefault = {
  activities: false,
  attendance: false,
  health_check: false,
};

const displayValueDefault = {
  activities: undefined,
  attendance: undefined,
  health_check: undefined,
};

const subKey = [
  "disorder_list",
  "disorder_comment",
  "attendance_comment",
  "family_health_check_comment",
  "pool_join_comment",
  "marathon_question_comment",
  "parent_department_name",
  "department_name",
  "patient_id",
  "absent_reason",
  "absent_reason_comment",
  "influenza_type",
  "attendance_stop_period",
  "pool_disorder_comment",
];

const defaultChecklist = [
  "date",
  "class_name",
  "roll_number",
  "health_check",
  "small_mental_check",
  "created_at",
  "full_name",
  "breakfast",
  "breakfast_comment",
];

const extraChecklist = [
  "temperature",
  "attendance",
  "family_health_check",
  "pool_join",
  "marathon_question",
];

const ConditionSettingModal: React.FC<IModal> = ({
  isVisible,
  handleCancel,
  fileType,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });

  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const dispatch = useDispatch();
  const { attendanceOptions: attendances } = useGetAttendanceOptions();

  const dataGroupClass = useSelector(dataGroupClassResponse);
  const isLoading = useSelector(selectIsLoading);
  // const dataSearchCondition = useSelector(searchConditionDataResponse);
  const bodyTemperatureFile = useSelector(bodyTemperatureFileResponse);
  const bodyTemperatureSettingForm = useSelector(
    bodyTemperatureSettingFormResponse
  );
  const getHealthCheckSetting = useSelector(getHealthCheckSettingResponse);

  const { handleSubmit, control, setValue, watch }: any = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const grade = watch("grade");
  const bodyTemperature = watch("bodyTemperature");
  const attendance = watch("attendance");
  const belongs = watch("belongs");
  const pastDate = watch("pastDate");

  const { pastDateOptions, listCheckbox, symptomsForFilter } =
    AllDataForFilters();

  const allOption: IOption[] = [
    {
      id: 0,
      name: t2("all"),
      value: "all",
    },
  ];

  const temperatureOptionsClone: any = [...allOption, ...symptomsForFilter];

  const [isDisabled, setIsDisabled] = useState(false);
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [attendanceOptions, setAttendanceOptions] = useState<any>([]);
  const [temperatureOptions, setTemperatureOptions] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [checkList, setCheckList] = useState<string[]>(
    getHealthCheckSetting?.operation_setting?.can_use_heart_and_health
      ? defaultChecklist
      : [...defaultChecklist, ...extraChecklist]
  );
  const [downloadCheckList, setDownloadCheckList] = useState<string[]>([]);

  const ignoreKey = [
    "absent_type",
    "notifications",
    "can_use_absent_children_club",
    "is_attendance_stop_period",
    "is_influenza_type",
    "is_temperature_on_sick",
  ];

  const [selectedValue, setSelectedValue] = useState<any>(selectedValueDefault);
  const [checkAll, setCheckAll] = useState<any>(checkAllDefault);
  const [displayValue, setDisplayValue] = useState<any>(displayValueDefault);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [isCheckGradeAll, setIsCheckGradeAll] = useState(false);

  useEffect(() => {
    if (attendances && attendances.length > 0) {
      setAttendanceOptions([...allOption, ...attendances]);
    }
  }, [attendances]);

  const handleCheckBoxList = () => {
    for (const key in getHealthCheckSetting?.health_check_setting) {
      if (
        !ignoreKey.includes(key) &&
        !getHealthCheckSetting?.health_check_setting[key]
      ) {
        const indexOfObject = listCheckbox.findIndex((cb: any) => {
          return cb.value === obj[key];
        });
        if (indexOfObject > -1) listCheckbox.splice(indexOfObject, 1);
      }
    }
    return listCheckbox;
  };

  const handleOnChange = (value: string[]) => {
    setCheckList(value);
  };

  const param: any = {
    activities: "sub_department_id",
    attendance: "attendance",
    health_check: "health_check",
  };

  const handleGetCheckedItemValue = (
    arr: any[],
    checkedArr: number[],
    itemType: string
  ) => {
    arr.forEach((item) => {
      if (item.checked) {
        checkedArr.push(item.id);
      } else {
        if (item[itemType].length) {
          handleGetCheckedItemValue(item[itemType], checkedArr, itemType);
        }
      }
    });
  };

  const handleGetSubDepartmentIds = (
    arr: any[],
    checkedArr: number[],
    itemType: string
  ) => {
    arr.forEach((item) => {
      if (item[itemType].length) {
        item[itemType].map((item: any) => {
          if (item.checked) {
            checkedArr.push(item.id);
          }
        });
      }
    });
  };

  const onSubmit = (data: { [x: string]: any }) => {
    let params: any = {
      aggregate_date: convertDate(startDate),
      back_date_number: data.pastDate || "",
      locale: currentLanguage,
    };

    if (!isCheckGradeAll) {
      const checkedArr: number[] = [];
      handleGetSubDepartmentIds(treeData, checkedArr, "sub_departments");

      params = {
        ...params,
        department_id: checkedArr,
        app_language_id: currentLanguage,
      };
    }

    for (const property in checkAll) {
      if (!checkAll[property]) {
        params = {
          ...params,
          [param[property]]: selectedValue[property].map(
            (item: any) => item.value
          ),
        };
      }
    }

    setDownloadCheckList(checkList);
    dispatch(
      GetDataBodyTemperatureFileCreators.getBodyTemperatureFileData(params)
    );
  };

  const handleFilterFileData = (originalData: any, newCheckList: string[]) => {
    return originalData.map((item: any) => {
      let obj = {};

      for (const key in item) {
        if (newCheckList.includes(key)) {
          obj = {
            ...obj,
            [key]: item[key],
          };
        }
      }

      if (newCheckList.includes("date")) {
        obj = {
          ...obj,
          date: item.created_at
            ? moment(item.created_at).format("YYYY/MM/DD")
            : "",
        };
      }

      if (newCheckList.includes("created_at")) {
        const currentTimeZone = localStorageHelper.getItem("currentTimeZone");

        obj = {
          ...obj,
          created_at: item.created_at
            ? handleGetTimeByTimeZone("HH:mm", currentTimeZone, item.created_at)
            : "",
        };
      }

      return obj;
    });
  };

  const handleOrderKey = (arr: string[], order: number, name: string) => {
    const indexOfObject = arr.findIndex((item: any) => {
      return item === name;
    });

    if (indexOfObject > -1) {
      if (indexOfObject != order) {
        arr.splice(indexOfObject, 1);
        arr.splice(order, 0, name);
      }
    }
  };

  const returnMentalCheckValue = (val: string): string => {
    switch (val) {
      case "very_good":
        return t("very-good");
      case "good":
        return t("good");
      case "usual":
        return t("as-usual");
      case "bad":
        return t("bad");
      case "very_bad":
        return t("very-bad");
      default:
        return "";
    }
  };

  const handleFileData = (type?: string) => {
    const list = bodyTemperatureFile?.answers;
    const newCheckList = [...downloadCheckList];

    for (const key in getHealthCheckSetting?.health_check_setting) {
      if (
        !ignoreKey.includes(key) &&
        !getHealthCheckSetting?.health_check_setting[key]
      ) {
        const indexOfObject = newCheckList.findIndex((value: any) => {
          return value === obj[key];
        });
        if (indexOfObject > -1) newCheckList.splice(indexOfObject, 1);
        if (key === "is_breakfast") {
          const breakfastCommentIndex = newCheckList.findIndex((value: any) => {
            return value === obj["can_use_breakfast_comment"];
          });
          if (breakfastCommentIndex > -1)
            newCheckList.splice(breakfastCommentIndex, 1);
        }
      }
      if (
        !ignoreKey.includes(key) &&
        getHealthCheckSetting?.health_check_setting[key]
      ) {
        if (key === "is_breakfast") {
          const indexOfBreakfast = newCheckList.findIndex((value: any) => {
            return value === obj[key];
          });
          if (indexOfBreakfast === -1) {
            const breakfastCommentIndex = newCheckList.findIndex(
              (value: any) => {
                return value === obj["can_use_breakfast_comment"];
              }
            );
            if (breakfastCommentIndex > -1)
              newCheckList.splice(breakfastCommentIndex, 1);
          }
        }
      }
    }

    handleOrderKey(newCheckList, 0, "date");
    handleOrderKey(newCheckList, 1, "full_name");
    handleOrderKey(newCheckList, 5, "health_check");
    handleOrderKey(newCheckList, 6, "attendance");
    handleOrderKey(newCheckList, 7, "family_health_check");
    handleOrderKey(newCheckList, 8, "pool_join");
    handleOrderKey(newCheckList, 9, "marathon_question");
    handleOrderKey(newCheckList, 10, "small_mental_check");
    handleOrderKey(newCheckList, 11, "breakfast");
    handleOrderKey(newCheckList, 12, "breakfast_comment");

    const indexOfObject = newCheckList.findIndex((item: any) => {
      return item === "created_at";
    });

    if (indexOfObject > -1 && indexOfObject != newCheckList.length - 1) {
      newCheckList.splice(indexOfObject, 1);
      newCheckList.splice(newCheckList.length, 0, "created_at");
    }

    if (type === "csv") {
      const dateIndex = newCheckList.findIndex((item: string) => {
        return item === "date";
      });

      if (dateIndex > -1) {
        newCheckList.splice(1, 0, "school_name");
        newCheckList.splice(2, 0, "patient_id");
      } else {
        newCheckList.splice(0, 0, "school_name");
        newCheckList.splice(1, 0, "patient_id");
      }

      const healthCheckIndex = newCheckList.findIndex((item: string) => {
        return item === "health_check";
      });

      if (healthCheckIndex > -1) {
        newCheckList.splice(healthCheckIndex + 1, 0, "disorder_list");
      }

      if (healthCheckIndex > -1) {
        newCheckList.splice(healthCheckIndex + 2, 0, "disorder_comment");
      }

      const attendanceIndex = newCheckList.findIndex((item: string) => {
        return item === "attendance";
      });

      if (attendanceIndex > -1) {
        newCheckList.splice(attendanceIndex + 1, 0, "absent_reason");
      }

      if (attendanceIndex > -1) {
        newCheckList.splice(attendanceIndex + 2, 0, "attendance_comment");
      }

      const familyHealthCheckIndex = newCheckList.findIndex((item: string) => {
        return item === "family_health_check";
      });

      if (familyHealthCheckIndex > -1) {
        newCheckList.splice(
          familyHealthCheckIndex + 1,
          0,
          "family_health_check_comment"
        );
      }

      const poolIndex = newCheckList.findIndex((item: string) => {
        return item === "pool_join";
      });

      if (poolIndex > -1) {
        newCheckList.splice(poolIndex + 1, 0, "pool_comment");
      }

      const marathonIndex = newCheckList.findIndex((item: string) => {
        return item === "marathon_question";
      });

      if (marathonIndex > -1) {
        newCheckList.splice(marathonIndex + 1, 0, "marathon_question_comment");
      }

      const smallMentalCheckIndex = newCheckList.findIndex((item: string) => {
        return item === "small_mental_check";
      });

      if (smallMentalCheckIndex > -1) {
        newCheckList.splice(
          smallMentalCheckIndex + 1,
          0,
          "small_mental_check_comment"
        );
      }
    }

    const fileData = handleFilterFileData(list, [...newCheckList, ...subKey]);

    return {
      fileData,
      labels: newCheckList,
    };
  };

  const handleDownLoadCSV = () => {
    const csvDataSorted: any = [];
    const { fileData, labels } = handleFileData("csv");

    const newHeaders = labels.map((item) => {
      return {
        label: headers[item],
      };
    });

    fileData.forEach((item: any) => {
      const results: any = [];
      labels.forEach((key: string) => {
        if (key === "school_name") {
          const schoolName = localStorageHelper.getItem("company_name");
          let schoolNameConverted = "";

          if (schoolName) {
            schoolNameConverted = schoolName.slice(0, schoolName.length - 4);
          }

          results.push(schoolNameConverted);
        } else if (
          key === "disorder_list" &&
          item["disorder_list"] &&
          item["disorder_list"].length
        ) {
          let cm = "";
          item["disorder_list"].forEach((symptom: string) => {
            if (!symptom) return;
            cm += "\n" + symptom;
          });
          results.push(cm);
        } else if (key === "attendance_comment") {
          let cm = item["attendance_comment"] ? item["attendance_comment"] : "";
          if (item["absent_reason_comment"]) {
            cm += item["attendance_comment"]
              ? "\n" + item["absent_reason_comment"]
              : "" + item["absent_reason_comment"];
          }
          results.push(cm);
        } else if (key === "pool_comment" && item["pool_comment"]) {
          let cm = "";
          if (item["pool_disorder_comment"]) {
            cm += "\n・" + item["pool_disorder_comment"];
          }
          if (item["pool_join_comment"]) {
            cm += "\n・" + item["pool_join_comment"];
          }
          results.push(cm);
        } else if (
          key === "marathon_question_comment" &&
          item["marathon_question_comment"]
        ) {
          const cm = "\n・" + item["marathon_question_comment"];
          results.push(cm);
        } else if (key === "class_name") {
          const cm = item["parent_department_name"] + item["department_name"];
          results.push(cm);
        } else if (key === "small_mental_check" && item[key]) {
          const cm = returnMentalCheckValue(item[key]);
          results.push(cm);
        } else {
          results.push(item[key]);
        }
      });
      csvDataSorted.push(results);
    });

    const filename = `${convertFilename("temperature", new Date())}.csv`;
    generateCSV(filename, newHeaders as any, csvDataSorted);
  };

  const handleDownloadPDF = () => {
    const sortedPDFData: any = [];
    const classList: string[] = [];
    const { fileData, labels } = handleFileData();
    const newHeaders = labels.map((item) => headers[item]);

    fileData.forEach((item: any) => {
      const department =
        item["parent_department_name"] + item["department_name"];
      if (department && !classList.includes(department)) {
        classList.push(department);
      }
      const results: any = [];
      labels.forEach((key: string) => {
        if (key === "health_check" && item["health_check"]) {
          if (
            item["health_check"] === "良い" ||
            item["health_check"] === "Tốt" ||
            item["health_check"] === "Good"
          ) {
            return results.push(item["health_check"]);
          } else {
            let cm =
              item["health_check"] +
              "\n" +
              `${
                item["disorder_comment"]
                  ? "・" + item["disorder_comment"] + "\n"
                  : ""
              }`;
            if (item["disorder_list"] && item["disorder_list"].length) {
              item["disorder_list"].forEach(
                (symptom: string, index: number) => {
                  if (!symptom) return;
                  cm += index > 0 ? "\n・" + symptom : "・" + symptom;
                }
              );
            }
            results.push(cm);
          }
        } else if (key === "attendance" && item["attendance"]) {
          let cm = item["attendance"];
          if (item["attendance_comment"]) {
            cm += "\n・" + item["attendance_comment"];
          }
          if (item["absent_reason"]) {
            cm += "\n・" + item["absent_reason"];
          }
          if (item["absent_reason_comment"]) {
            cm += "\n・" + item["absent_reason_comment"];
          }
          if (item["influenza_type"]) {
            cm += "\n・" + item["influenza_type"];
          }
          if (item["attendance_stop_period"]) {
            cm += "\n・" + t("attendance-suspension-date");
            cm +=
              "\n" +
              moment(item["attendance_stop_period"]).format("YYYY年MM月DD日");
          }
          results.push(cm);
        } else if (
          key === "family_health_check" &&
          item["family_health_check"]
        ) {
          let cm = item["family_health_check"];
          if (item["family_health_check_comment"]) {
            cm += "\n・" + item["family_health_check_comment"];
          }
          results.push(cm);
        } else if (key === "pool_join" && item["pool_join"]) {
          let cm = item["pool_join"];
          if (item["pool_join_comment"]) {
            cm += "\n・" + item["pool_join_comment"];
          }
          results.push(cm);
        } else if (key === "marathon_question" && item["marathon_question"]) {
          let cm = item["marathon_question"];
          if (item["marathon_question_comment"]) {
            cm += "\n・" + item["marathon_question_comment"];
          }
          results.push(cm);
        } else if (key === "class_name") {
          const cm = item["parent_department_name"] + item["department_name"];
          results.push(cm);
        } else if (key === "small_mental_check" && item[key]) {
          let cm = returnMentalCheckValue(item[key]);
          if (item["small_mental_check_comment"]) {
            cm += "\n・" + item["small_mental_check_comment"];
          }
          results.push(cm);
        } else {
          results.push(item[key]);
        }
      });
      sortedPDFData.push(results);
    });

    const data: any[] = [];
    sortedPDFData.forEach((item: any) => {
      const results = [];
      for (const key in item) {
        results.push(item[key]);
      }
      data.push(results);
    });

    let obj: any = {};
    classList.forEach((item: any) => {
      obj = {
        ...obj,
        [item]: data.filter((item1: any) => item1.includes(item)),
      };
    });

    const doc = new jsPDF("l", "mm", "a4");

    doc.addFileToVFS("BIZUDGothic-Regular-normal.ttf", udgothicFont);
    doc.addFont(
      "BIZUDGothic-Regular-normal.ttf",
      "BIZUDGothic-Regular",
      "normal"
    );
    doc.setFont("BIZUDGothic-Regular", "normal");

    const width: any = {
      date: 25,
      class_name: 15,
      roll_number: 20,
      temperature: 15,
      health_check: 30,
      attendance: 30,
      family_health_check: 30,
      marathon_question: 20,
      pool_join: 20,
      small_mental_check: 25,
      created_at: 20,
      full_name: 35,
      breakfast: 20,
      breakfast_comment: 25,
    };

    let styles: any = {};
    let tableWidth = 0;
    let idx = 0;
    labels.forEach((item: string) => {
      if (width[item]) {
        tableWidth += width[item];
        styles = {
          ...styles,
          [idx++]: {
            cellWidth: width[item],
          },
        };
      }
    });

    const pageWidth = doc.internal.pageSize.width;
    const margin = (pageWidth - tableWidth) / 2;

    if (classList.length) {
      for (const property in obj) {
        autoTable(doc, {
          head: [[...newHeaders]],
          body: obj[property],
          headStyles: {
            font: "BIZUDGothic-Regular",
          },
          bodyStyles: {
            font: "BIZUDGothic-Regular",
          },
          columnStyles: styles,
          margin: { left: margin, right: margin },
        });
        if (property !== classList.slice(-1)[0]) {
          doc.addPage();
        }
      }
    } else {
      autoTable(doc, {
        head: [[...newHeaders]],
        body: data,
        headStyles: {
          font: "BIZUDGothic-Regular",
        },
        bodyStyles: {
          font: "BIZUDGothic-Regular",
        },
        columnStyles: styles,
        margin: { left: margin, right: margin },
      });
    }

    const filename = `${convertFilename("temperature", new Date())}.pdf`;
    doc.save(filename);
  };

  const handleData2Options = (data: any) => {
    return data.map((item: any) => {
      return {
        id: item[1],
        value: item[1],
        name: item[0],
      };
    });
  };

  const handleOnChangeMultipleValue =
    (name: string, option: any) => (value: IOption[]) => {
      setSelectedValue((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));

      if (checkAll) {
        setCheckAll((prevState: any) => ({
          ...prevState,
          [name]: false,
        }));
      }
      if (value.length === option.length) {
        setCheckAll((prevState: any) => ({
          ...prevState,
          [name]: true,
        }));
        setDisplayValue((prevState: any) => ({
          ...prevState,
          [name]: t2("all"),
        }));
      }
      if (value.length) {
        const temp: any = value.map((value: any) => {
          return value.name;
        });
        setDisplayValue((prevState: any) => ({
          ...prevState,
          [name]: temp.join("/"),
        }));
      } else {
        setDisplayValue((prevState: any) => ({
          ...prevState,
          [name]: undefined,
        }));
      }
    };

  const onCheckAllChange = (name: string, option: any) => (e: any) => {
    const { checked } = e.target;
    setDisplayValue((prevState: any) => ({
      ...prevState,
      [name]: checked ? t2("all") : undefined,
    }));
    setSelectedValue((prevState: any) => ({
      ...prevState,
      [name]: checked ? option : [],
    }));
    setCheckAll((prevState: any) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    if (
      bodyTemperatureSettingForm &&
      Object.keys(bodyTemperatureSettingForm?.result).length
    ) {
      const {
        patient_group_for_csv_export,
        patient_designations_for_csv_export,
      } = bodyTemperatureSettingForm?.result;
      const dataGroupClone = handleData2Options(patient_group_for_csv_export);

      patient_designations_for_csv_export.splice(0, 1);
      setTreeData(patient_designations_for_csv_export);

      dataGroupClone.splice(0, 1);
      attendanceOptions.splice(0, 1);
      temperatureOptionsClone.splice(0, 1);

      setGroupOptions(dataGroupClone);
      setTemperatureOptions(temperatureOptionsClone);
    }
  }, [dataGroupClass, bodyTemperatureSettingForm]);

  useEffect(() => {
    if (bodyTemperatureFile && bodyTemperatureFile?.answers.length) {
      if (fileType === "csv") {
        handleDownLoadCSV();
      } else {
        handleDownloadPDF();
      }
      dispatch(GetDataBodyTemperatureFileCreators.handleResetAction());
      setDownloadCheckList([]);
    }
  }, [bodyTemperatureFile]);

  const handleResetForm = () => {
    setValue("grade", undefined);
    setValue("bodyTemperature", undefined);
    setValue("belongs", undefined);
    setValue("attendance", undefined);
  };

  const handleCheckedItem = (item: any, itemType: string, e: any) => {
    item.checked = e;
    if (item[itemType].length) {
      item[itemType].map((newItem: any) => {
        handleCheckedItem(newItem, itemType, item.checked);
      });
    }
  };

  const handleCheckGradeAll = (condition: boolean) => {
    treeData.forEach((item: any) => {
      handleCheckedItem(item, "sub_departments", condition);
    });
    setTreeData([...treeData]);
  };

  useEffect(() => {
    if (!pastDate) return;
    if (pastDate !== "1") {
      handleResetForm();
      setCheckAll(checkAllDefault);
      setDisplayValue(displayValueDefault);
      setSelectedValue(selectedValueDefault);

      handleCheckGradeAll(false);
    } else {
      if (!grade) {
        handleCheckGradeAll(true);
        setCheckAll({
          activities: true,
          health_check: true,
          attendance: true,
        });
        setDisplayValue({
          activities: t2("all"),
          health_check: t2("all"),
          attendance: t2("all"),
        });
        setSelectedValue({
          activities: groupOptions,
          health_check: temperatureOptions,
          attendance: attendanceOptions,
        });
      }
      if (!bodyTemperature)
        setValue("bodyTemperature", temperatureOptions[0]?.value);
      if (!belongs) setValue("belongs", groupOptions[0]?.value);
      if (!attendance) setValue("attendance", attendanceOptions[0]?.value);
    }
    setIsDisabled(true);
  }, [pastDate]);

  const optionsDefault = [
    {
      key: 2,
      value: displayValue.attendance,
      label: t("attendance-condition"),
      options: attendanceOptions,
      onChangeCheckAll: onCheckAllChange("attendance", attendanceOptions),
      checkAll: checkAll.attendance,
      valueCheckbox: selectedValue.attendance,
      onChange: handleOnChangeMultipleValue("attendance", attendanceOptions),
    },
    {
      key: 3,
      value: displayValue.activities,
      label: t2("belongs"),
      options: groupOptions,
      onChangeCheckAll: onCheckAllChange("activities", groupOptions),
      checkAll: checkAll.activities,
      valueCheckbox: selectedValue.activities,
      onChange: handleOnChangeMultipleValue("activities", groupOptions),
    },
    {
      key: 4,
      value: displayValue.health_check,
      label: t("physical-condition"),
      options: temperatureOptions,
      onChangeCheckAll: onCheckAllChange("health_check", temperatureOptions),
      checkAll: checkAll.health_check,
      valueCheckbox: selectedValue.health_check,
      onChange: handleOnChangeMultipleValue("health_check", temperatureOptions),
    },
  ];

  const handleGetValue = (tree: any, arr: any) => {
    tree.map((item: any) => {
      if (item.checked) {
        arr.push(item.name);
      }
      if (item.sub_departments.length) {
        handleGetValue(item.sub_departments, arr);
      }
    });
  };

  const getIdParent = () => {
    const idParentList = treeData.map((item: any) => {
      return item.id;
    });

    const checkedArr: number[] = [];
    handleGetCheckedItemValue(treeData, checkedArr, "sub_departments");
    const arrID: any = [];

    checkedArr.map((item: any) => {
      if (idParentList.includes(item)) {
        arrID.push(true);
      } else {
        arrID.push(false);
      }
    });

    const check = arrID.some((item: any) => !item);
    return !check && checkedArr.length === idParentList.length;
  };

  useEffect(() => {
    setIsCheckGradeAll(getIdParent());
  }, [treeData]);

  const handleValueOption = () => {
    const arr: any = [];
    handleGetValue(treeData, arr);
    if (getIdParent()) return t2("all");
    if (arr.length > 1) return t("multiple-selection");
    return arr[0];
  };

  const onChangeCheckbox = (item: any, itemType: string) => (e: any) => {
    handleCheckedItem(item, itemType, e.target.checked);
    if (itemType === "sub_departments") {
      setTreeData([...treeData]);
    } else {
      // setTreeData2([...treeData2]);
    }
  };

  const onChangeCheckGradeAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckGradeAll(checked);
    handleCheckGradeAll(checked);
  };

  const CompItem = ({
    item,
    itemType,
    children,
  }: {
    item: any;
    itemType: string;
    children: any;
  }) => {
    const { name, checked } = item;

    const handleCheckParent = (item: any, checked: boolean) => {
      item.checked = checked;
      if (itemType === "sub_departments") {
        setTreeData([...treeData]);
      } else {
        // setTreeData2([...treeData2]);
      }
    };

    useEffect(() => {
      if (item[itemType].length > 0) {
        if (
          !item[itemType].some(
            (d: any) => d.checked === false || d.checked === undefined
          )
        ) {
          if (!item.checked) {
            handleCheckParent(item, true);
          }
        } else {
          if (item.checked) {
            handleCheckParent(item, false);
          }
        }
      }
    }, [item[itemType]]);

    return (
      <>
        <ItemContainer>
          <CheckboxStyled4
            checked={checked}
            onChange={onChangeCheckbox(item, itemType)}
          >
            <Tooltip placement="topLeft" title={name}>
              <div style={{ marginLeft: 10 }}>{name}</div>
            </Tooltip>
          </CheckboxStyled4>
        </ItemContainer>
        {children}
      </>
    );
  };

  const Item = ({ item, itemType }: { item: any; itemType: string }) => {
    return (
      <CompItem item={item} itemType={itemType}>
        {item[itemType].length > 0 &&
          item[itemType].map((i: any) => (
            <div style={{ marginLeft: 32 }} key={i.id}>
              <CompItem item={i} itemType={itemType}>
                {i[itemType].map((a: any) => (
                  <div style={{ marginLeft: 32 }} key={a.id}>
                    <Item item={a} itemType={itemType} />
                  </div>
                ))}
              </CompItem>
            </div>
          ))}
      </CompItem>
    );
  };

  return (
    <Modal
      title={t("output-condition-setting")}
      type="form"
      visible={isVisible}
      onCancel={handleCancel}
    >
      <div id="area" style={{ position: "relative" }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[8, 8]} style={{ marginBottom: 25 }}>
          <Col xs={24} md={10} xl={10}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontSize: 16,
                  color: "#3B3B3B",
                  marginRight: 13,
                  width: 40,
                }}
              >
                {t("date")}
              </div>
              <DatePicker
                onChange={(date) => setStartDate(date)}
                iconPosition="right"
                selected={startDate}
              />
            </div>
          </Col>
          <Col xs={24} md={14} xl={14}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontSize: 16,
                  color: "#3B3B3B",
                  width: 60,
                  marginRight: 10,
                }}
              >
                {t("past")}
              </div>
              <WrapperSelectStyled>
                <Controller
                  control={control}
                  name="pastDate"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Select
                      placeholder={t1("please-select-a-period")}
                      options={pastDateOptions}
                      onChange={onChange}
                      error={t(error?.message)}
                      getPopupContainer={() => document.getElementById("area")}
                    />
                  )}
                />
              </WrapperSelectStyled>
            </div>
          </Col>
        </Row>
        <Row
          gutter={[
            { xs: 16, md: 16 },
            { xs: 24, md: 24 },
          ]}
        >
          <Col xs={24} md={12} xl={12}>
            <Select
              value={handleValueOption()}
              placeholder={t1("please-select")}
              label={t1("grade")}
              fsLabel={16}
              getPopupContainer={() => document.getElementById("area")}
              dropdownRender={() => (
                <div
                  style={{
                    padding: "7px 10px 5px",
                    maxHeight: "300px",
                    overflowX: "auto",
                  }}
                >
                  <ItemContainer>
                    <CheckboxStyled4
                      checked={isCheckGradeAll}
                      onChange={onChangeCheckGradeAll}
                    >
                      <div style={{ marginLeft: 10 }}>{t2("all")}</div>
                    </CheckboxStyled4>
                  </ItemContainer>
                  {treeData.map((item: any) => {
                    return (
                      <WrapperItem key={item.id}>
                        <Item item={item} itemType="sub_departments" />
                      </WrapperItem>
                    );
                  })}
                </div>
              )}
            />
          </Col>
          {optionsDefault.map((item: any) => {
            return (
              <Col xs={24} md={12} xl={12} key={item.key}>
                <Select
                  value={item.value}
                  placeholder={t1("please-select")}
                  label={item.label}
                  fsLabel={16}
                  options={item.options}
                  getPopupContainer={() => document.getElementById("area")}
                  dropdownRender={() => (
                    <div
                      style={{
                        padding: "7px 10px 5px",
                        maxHeight: "300px",
                        overflowX: "auto",
                      }}
                    >
                      <CheckboxStyled3
                        onChange={item.onChangeCheckAll}
                        checked={item.checkAll}
                        style={{ marginBottom: 18 }}
                      >
                        {t2("all")}
                      </CheckboxStyled3>
                      <Checkbox
                        value={item.valueCheckbox}
                        list={item.options}
                        onChange={item.onChange}
                      />
                    </div>
                  )}
                />
              </Col>
            );
          })}
          <Col span={24}>
            <div style={{ fontSize: 16, color: "#3B3B3B", marginBottom: 8 }}>
              {t("output-item")}
            </div>
            <div
              style={{
                background: "#F2F2F2",
                borderRadius: 8,
                padding: 16,
              }}
            >
              <WrapperCheckbox>
                <Checkbox1
                  list={handleCheckBoxList()}
                  value={checkList}
                  row
                  onChange={handleOnChange}
                />
              </WrapperCheckbox>
            </div>
          </Col>
        </Row>
        <WrapperBtnWithIcon>
          <Button
            background="#E0E0E0"
            color="#FFFFFF"
            name={t1("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            onClick={handleCancel}
          />
          <Button
            background="#2AC769"
            color="#FFFFFF"
            name={t("download")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            disabled={isLoading || !isDisabled}
            icon={
              <DownloadIcon
                width="20px"
                height="20px"
                fill="#FFFFFF"
                style={{ marginTop: 2, position: "absolute", left: 12, top: 8 }}
              />
            }
          />
        </WrapperBtnWithIcon>
      </form>
    </Modal>
  );
};

export default ConditionSettingModal;
