import { IResponse } from "models";

export enum ActionType {
  MENTAL_CHECK_TYPE_LIST = "action/MENTAL_CHECK_TYPE_LIST",
  MENTAL_CHECK_TYPE_LIST_SUCCESS = "action/MENTAL_CHECK_TYPE_LIST_SUCCESS",
  MENTAL_CHECK_TYPE_LIST_ERROR = "action/MENTAL_CHECK_TYPE_LIST_ERROR",
  MENTAL_CHECK_TYPE_LIST_RESET_DATA = "action/MENTAL_CHECK_TYPE_LIST_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IMentalCheckTypeListResponse extends IResponse {
  total_record: number;
  current_page: number;
  next_page: number;
  result?: IMentalCheckType[];
}

export interface IMentalCheckType {
  id: number;
  name: string;
}
