import { IResponse } from "models";

export enum ActionType {
  ADD_REQUEST = "action/ADD_REQUEST",
  ADD_REQUEST_SUCCESS = "action/ADD_REQUEST_SUCCESS",
  ADD_REQUEST_ERROR = "action/ADD_REQUEST_ERROR",
  ADD_REQUEST_RESET = "action/ADD_REQUEST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IAddRequestPayload {
  password: string;
}

export interface IAddRequestResponse extends IResponse {}
