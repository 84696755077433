import {
  IMentalCheckTotalPayload,
  ActionType,
  IMentalCheckTotalResponse,
} from "models/mentalHealth/mentalHealthTotal";

export const getMentalCheckTotalAction = (
  payload: IMentalCheckTotalPayload
) => {
  return {
    type: ActionType.MENTAL_CHECK_TOTAL,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MENTAL_CHECK_TOTAL_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IMentalCheckTotalResponse) => {
  return {
    type: ActionType.MENTAL_CHECK_TOTAL_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.MENTAL_CHECK_TOTAL_RESET,
  };
};
