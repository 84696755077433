import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { Button, ScrollToTop } from "components";
import { DotIcon, DownloadIcon, BackIcon, ConfirmFailIcon } from "icons";
import { mplusFont } from "utils";

import {
  GetScoringInformationActionCreators,
  DownloadScoringPDFActionCreators,
} from "redux/rootActions";
import {
  selectErrorMessage,
  scoringInformationResponse,
} from "redux/mentalHealth/scoringInformation/scoringInformationStates";
import { pdfDownloadErrorMessage } from "redux/mentalHealth/downloadScoringPDF/downloadScoringPDFStates";

import {
  TextTitle,
  TableWrapper,
  FirstTable,
  TableContainer,
  NoteText,
  MentalHealthSituationTitleWrapper,
  MentalHealthSituationContentWrapper,
  TableStatisticsWrapper,
  TitleContainerDetails,
} from "./MentalHealth.style";

interface IMentalHealthSituationDetails {
  handleCancel: () => void;
}

const MentalHealthSituationDetails: React.FC<IMentalHealthSituationDetails> = ({
  handleCancel,
}) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "mental-health",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const scoringInformation = useSelector(scoringInformationResponse);
  const getScoringInfoError = useSelector(selectErrorMessage);
  const downloadScoringInfoError = useSelector(pdfDownloadErrorMessage);

  const [detailInformation, setDetailInformation] = useState<any>({});

  const urls: any = {
    "/elementary-25-questions": t3("implemented-status-25-elementary"),
    "/elementary-13-questions": t3("implemented-status-13-elementary"),
    "/junior-25-questions": t3("implemented-status-25-junior"),
    "/junior-13-questions": t3("implemented-status-13-junior"),
  };

  const fields: any = {
    feelings_mood_problem: t2("feeling-mood-problem"),
    concentrations_problem: t2("concentration-problem"),
    behavioral_problem: t2("behavioral-problem"),
    relations_problem: t2("relationship-problem"),
  };

  const descriptions: any = {
    feelings_mood_problem: t2("feeling-description"),
    concentrations_problem: t2("concentration-description"),
    behavioral_problem: t2("behavioral-description"),
    relations_problem: t2("relationship-description"),
  };

  const handleScoringInfoDownloadPDF = async () => {
    const headStyles: any = {
      fillColor: "#F2F2F2",
      fontStyle: "bold",
    };
    const styles: any = {
      font: "mplus",
      lineColor: "#CCCCCC",
      lineWidth: 1,
      fontSize: 10,
      textColor: "#3B3B3B",
      halign: "left",
    };
    const margin: any = {
      left: 10,
      right: 10,
    };
    const pdf = new jsPDF("p", "px", "a4");
    pdf.addFileToVFS("mplus.ttf", mplusFont);
    pdf.addFont("mplus.ttf", "mplus", "normal");
    pdf.setFont("mplus", "normal");
    pdf.text(t2("scoring-information"), 10, 30);
    pdf.setFontSize(14);

    autoTable(pdf, {
      head: [
        [
          {
            content: t2("user-information"),
            colSpan: 4,
            styles: {
              fontStyle: "bold",
              cellPadding: 8,
            },
          },
        ],
      ],
      body: [
        [
          `${t2("name")}${detailInformation?.user_information[0]?.name}`,
          `${t("grade")} : ${
            detailInformation?.user_information[0]?.garde_class
          }`,
          `${t2("age")}${
            detailInformation?.user_information[0]?.age?.split("歳")[0]
          }`,
          `${t("gender")} : ${detailInformation?.user_information[0]?.gender}`,
        ],
      ],
      headStyles: headStyles,
      styles: styles,
      columnStyles: {
        0: {
          cellPadding: {
            left: 8,
            top: 5,
            bottom: 5,
            right: 8,
          },
        },
        1: {
          cellPadding: {
            left: 8,
            top: 5,
            bottom: 5,
          },
        },
        2: {
          cellPadding: {
            left: 8,
            top: 5,
            bottom: 5,
          },
        },
        3: {
          cellPadding: {
            left: 8,
            top: 5,
            bottom: 5,
          },
        },
      },
      margin: {
        top: 40,
        ...margin,
      },
      theme: "plain",
    });

    const arr = [
      "feelings_mood_problem",
      "concentrations_problem",
      "behavioral_problem",
      "relations_problem",
    ];

    arr.forEach((name: string) => {
      if (detailInformation[name]) {
        const bodyData = detailInformation[name].map((item: any) => {
          const emotionalDisturbance = item?.emotional_disturbance ? "●" : "";
          const attentionCare = item?.attention_care ? "●" : "";
          const normal = item?.normal ? "●" : "";
          return [
            item?.question_name,
            emotionalDisturbance,
            attentionCare,
            normal,
          ];
        });

        autoTable(pdf, {
          head: [
            [
              {
                content: fields[name],
                styles: { fontStyle: "bold", cellPadding: 8 },
              },
              {
                content: t2("applies"),
                styles: { fontStyle: "bold", halign: "center", cellPadding: 8 },
              },
              {
                content: t2("well-applies"),
                styles: { fontStyle: "bold", halign: "center", cellPadding: 8 },
              },
              {
                content: t2("does-not-apply"),
                styles: { fontStyle: "bold", halign: "center", cellPadding: 8 },
              },
            ],
          ],
          body: [[descriptions[name], "", "", ""], ...bodyData],
          headStyles: headStyles,
          styles: styles,
          columnStyles: {
            0: {
              cellWidth: 200,
              cellPadding: {
                left: 8,
                top: 5,
                bottom: 5,
              },
            },
            1: {
              halign: "center",
            },
            2: {
              halign: "center",
            },
            3: {
              halign: "center",
            },
          },
          margin: {
            ...margin,
          },
          theme: "plain",
        });
      }
    });

    if (detailInformation?.user_information) {
      pdf.save(`${detailInformation?.user_information[0]?.name}-採点情報`);
    }
  };

  useEffect(() => {
    if (scoringInformation && Object.keys(scoringInformation.result).length) {
      setDetailInformation(scoringInformation.result);
    }
  }, [scoringInformation]);

  useEffect(() => {
    if (getScoringInfoError) {
      notification.error({
        placement: "bottomRight",
        message: getScoringInfoError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(GetScoringInformationActionCreators.handleResetAction());
    }
  }, [getScoringInfoError]);

  useEffect(() => {
    if (downloadScoringInfoError) {
      notification.error({
        placement: "bottomRight",
        message: downloadScoringInfoError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(DownloadScoringPDFActionCreators.handleResetDataAction());
    }
  }, [downloadScoringInfoError]);

  useEffect(() => {
    dispatch(GetScoringInformationActionCreators.handleResetAction());
    dispatch(DownloadScoringPDFActionCreators.handleResetDataAction());
  }, []);

  const Table = ({ name }: any) => {
    return (
      <TableStatisticsWrapper>
        <TableContainer>
          <div className="header line">
            <div>{fields[name]}</div>
            <div>
              <div className="cell">
                {t2("applies")}
                <p>(2)</p>
              </div>
              <div className="cell">
                {t2("well-applies")}
                <p>(1)</p>
              </div>
              <div className="cell">
                {t2("does-not-apply")}
                <p>(0)</p>
              </div>
            </div>
          </div>
          <div className="evaluation">
            <div>
              <div>{descriptions[name]}</div>
            </div>
            <div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div></div>
          </div>
          {detailInformation[name] &&
            detailInformation[name].map((item: any, index: any) => (
              <div className="rating" key={index}>
                <div>{item?.question_name}</div>
                <div>
                  <div>{item?.emotional_disturbance && <DotIcon />}</div>
                  <div>{item?.attention_care && <DotIcon />}</div>
                  <div>{item?.normal && <DotIcon />}</div>
                </div>
              </div>
            ))}
        </TableContainer>
      </TableStatisticsWrapper>
    );
  };

  return (
    <>
      <ScrollToTop />
      <MentalHealthSituationTitleWrapper>
        <BackIcon
          className="back"
          onClick={() => {
            dispatch(GetScoringInformationActionCreators.handleResetAction());
            handleCancel();
          }}
        />
        <div
          style={{
            fontSize: 16,
            lineHeight: "23px",
            fontWeight: "bold",
            marginLeft: 2,
          }}
        >
          {urls[location.pathname]}
        </div>
      </MentalHealthSituationTitleWrapper>
      <MentalHealthSituationContentWrapper>
        <Button
          icon={
            <DownloadIcon
              width="14px"
              height="14px"
              fill="currentColor"
              style={{ position: "absolute", left: 4, top: 4 }}
            />
          }
          name={t("pdf-output")}
          background="#2AC769"
          color="#FFFFFF"
          border="none"
          fontSize={12}
          lineHeight="17px"
          fontWeight={700}
          padding="3px 10px 3px 16px"
          onClick={handleScoringInfoDownloadPDF}
        />
        <TitleContainerDetails
          style={{
            marginBottom: 24,
          }}
        >
          <TextTitle>{t2("scoring-information")}</TextTitle>
        </TitleContainerDetails>
        <TableWrapper>
          <FirstTable>
            <div className="first-line">{t2("user-information")}</div>
            <div className="second-line">
              <div>
                {t2("name")}
                <span style={{ fontWeight: 500 }}>
                  {detailInformation?.user_information &&
                    detailInformation?.user_information[0]?.name}
                </span>
              </div>
              <div>
                {t2("age")}{" "}
                <span style={{ fontWeight: 500 }}>
                  {detailInformation?.user_information &&
                    detailInformation?.user_information[0]?.age?.split("歳")[0]}
                  {t2("ages")}
                </span>
              </div>
            </div>
            <div className="third-line">
              <div>
                {t("grade")} :{" "}
                <span style={{ fontWeight: 500 }}>
                  {detailInformation?.user_information &&
                    detailInformation?.user_information[0]?.garde_class}
                </span>
              </div>
              <div>
                {t("gender")} :
                <span style={{ fontWeight: 500 }}>
                  {detailInformation?.user_information &&
                    detailInformation?.user_information[0]?.gender}
                </span>
              </div>
            </div>
          </FirstTable>
        </TableWrapper>
        {detailInformation?.feelings_mood_problem && (
          <Table name="feelings_mood_problem" />
        )}
        {detailInformation?.concentrations_problem && (
          <Table name="concentrations_problem" />
        )}
        {detailInformation?.behavioral_problem && (
          <Table name="behavioral_problem" />
        )}
        {detailInformation?.relations_problem && (
          <Table name="relations_problem" />
        )}
      </MentalHealthSituationContentWrapper>
    </>
  );
};

export default MentalHealthSituationDetails;
