import React, { useEffect, useRef, useState } from "react";
import { Col } from "antd";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  Area,
} from "recharts";
import { useTranslation } from "react-i18next";
import { Loading } from "components";

import { FaceScaleActionCreators } from "redux/rootActions";
import {
  temperatureAmountsDataResponse,
  selectIsLoading,
} from "redux/dashboard/temperatureAmounts/temperatureAmountsStates";
import { faceScaleDataResponse } from "redux/dashboard/faceScale/faceScaleStates";

import { CloseIcon, DownloadIcon, NoDataIcon } from "icons";
import { generateImg } from "utils";

import {
  BoxItem,
  BoxHeader,
  BoxTitle,
  LightDivider,
  BoxContent,
  WrapperLegendLineChart,
  Dot,
  WrapperChart,
  ContainerChart,
  CheckboxStyled2,
  WrapperLoadingChart,
  WrapperCloseIcon,
  WrapperNoData,
} from "../Dashboard.style";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

interface TooltipItem {
  dot: any;
  label: string;
}

interface BarChartProps {
  data: any;
  //   checkedList: boolean[];
}

const chartItems = [
  {
    id: 0,
    label: "not-attempt",
    color: "#F2F2F2",
  },
  {
    id: 1,
    label: "very-good",
    color: "#97c5db",
  },
  {
    id: 2,
    label: "good",
    color: "#97dbdb",
  },
  {
    id: 3,
    label: "as-usual",
    color: "#97dbb6",
  },
  {
    id: 4,
    label: "bad",
    color: "#dbe791",
  },
  {
    id: 5,
    label: "very-bad",
    color: "#e79c91",
  },
];

const returnItem = (name: string) => {
  let item: TooltipItem = {
    dot: <></>,
    label: "",
  };
  switch (name) {
    case "veryGood":
      item = {
        dot: <Dot style={{ background: "#97c5db" }} />,
        label: "very-good",
      };
      break;
    case "good":
      item = {
        dot: <Dot style={{ background: "#97dbdb" }} />,
        label: "good",
      };
      break;
    case "asUsual":
      item = {
        dot: <Dot style={{ background: "#97dbb6" }} />,
        label: "as-usual",
      };
      break;
    case "bad":
      item = {
        dot: <Dot style={{ background: "#dbe791" }} />,
        label: "bad",
      };
      break;
    case "veryBad":
      item = {
        dot: <Dot style={{ background: "#e79c91" }} />,
        label: "very-bad",
      };
      break;
    case "notAttempt":
      item = {
        dot: <Dot style={{ background: "#F2F2F2" }} />,
        label: "not-attempt",
      };
      break;
  }

  return item;
};

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={0} dy={18} fill="#3B3B3B">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={3} fill="#3B3B3B" textAnchor="end">
        {payload.value} {t("people")}
      </text>
    </g>
  );
};

const BarChartCustom = ({ data }: BarChartProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });

  const renderTooltipContent = (props: any) => {
    const { payload, label } = props;

    const filterPayload = (items: any[]) => {
      if (!items || (items && !items.length)) {
        return [];
      } else {
        // push notAttemp to top
        return [items[items.length - 1], ...items.slice(0, items.length - 1)];
      }
    };

    return (
      <div className="customized-tooltip-content">
        <p className="label">{label}</p>
        {payload && (
          <div className="list">
            {filterPayload(payload).map((entry: any, index: any) => (
              <div key={`item-${index}`} className="tooltip-item-wrapper">
                <div className="item-left">
                  {returnItem(entry.name)?.dot}
                  <span>{t(returnItem(entry.name)?.label)}</span>
                </div>
                <div className="item-right">
                  <span>
                    {entry.value}
                    {t("people")}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <ContainerChart>
      <ResponsiveContainer width="100%" height={178}>
        <AreaChart
          margin={{
            top: 10,
            right: 20,
            left: 0,
            bottom: 0,
          }}
          data={data}
        >
          <CartesianGrid strokeDasharray="2 2" stroke="#CCCCCC" />
          <XAxis
            dy={4}
            tickLine={false}
            dataKey="date"
            minTickGap={-100}
            tick={<CustomizedXAxisTick />}
            stroke="#CCCCCC"
          />
          <YAxis
            type="number"
            tickLine={false}
            interval={0}
            width={55}
            tick={<CustomizedYAxisTick />}
            stroke="#CCCCCC"
            minTickGap={200}
          />
          {/* {checkedList[0] && ( */}
          <Area
            type="monotone"
            dataKey="veryGood"
            stackId="noHeat"
            fill="#97c5db"
            stroke="none"
          />
          <Area
            type="monotone"
            dataKey="good"
            stackId="noHeat"
            fill="#97dbdb"
            stroke="none"
          />
          <Area
            type="monotone"
            dataKey="asUsual"
            stackId="noHeat"
            fill="#97dbb6"
            stroke="none"
          />
          <Area
            type="monotone"
            dataKey="bad"
            stackId="noHeat"
            fill="#dbe791"
            stroke="none"
          />
          <Area
            type="monotone"
            dataKey="veryBad"
            stackId="noHeat"
            fill="#e79c91"
            stroke="none"
          />
          <Area
            type="monotone"
            dataKey="notAttempt"
            stackId="noHeat"
            fill="#F2F2F2"
            stroke="none"
          />
          {/* )} */}
          {/* {checkedList[1] && ( */}
          {/* )} */}
          {/* {checkedList[2] && ( */}
          {/* )} */}
          {/* {checkedList[3] && ( */}
          {/* )} */}
          <Tooltip content={renderTooltipContent} />
        </AreaChart>
      </ResponsiveContainer>
    </ContainerChart>
  );
};

const MoodChange = ({ handleCloseChart }: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "dashboard",
  });

  const dispatch = useDispatch();
  const faceScaleData = useSelector(faceScaleDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const imgRef = useRef<any>();
  //   const [checkedList, setCheckedList] = useState<boolean[]>([
  //     true,
  //     true,
  //     true,
  //     true,
  //   ]);
  const [data, setData] = useState([]);

  //   const onChangeCheckbox = (index: any) => {
  //     const tempCheckedList = [...checkedList];
  //     tempCheckedList[index] = !tempCheckedList[index];
  //     setCheckedList(tempCheckedList);
  //   };
  const handleConvertDate = (date: string) => {
    return moment(new Date(date)).format("M/D");
  };

  useEffect(() => {
    dispatch(
      FaceScaleActionCreators.getFaceScaleAction({
        aggregate_date_to: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
  }, []);

  useEffect(() => {
    if (faceScaleData && Object.keys(faceScaleData).length) {
      setData(
        faceScaleData.amounts.map((item: any) => {
          return {
            date: handleConvertDate(item.aggregate_date),
            good: item.good,
            veryGood: item.very_good,
            asUsual: item.usual,
            notAttempt: item.not_answered,
            bad: item.bad,
            veryBad: item.very_bad,
          };
        })
      );
    }
  }, [faceScaleData]);

  return (
    // <Col xs={24} md={24} xl={12} >
    <BoxItem ref={imgRef}>
      <WrapperCloseIcon>
        <CloseIcon
          fill="#2F8CAE"
          height="14px"
          width="14px"
          onClick={() => handleCloseChart("10")}
        />
      </WrapperCloseIcon>
      <BoxHeader>
        <BoxTitle>{t1("mood-change-label")}</BoxTitle>
        <DownloadIcon
          fill="#3B3B3B"
          style={{ marginTop: 4, cursor: "pointer" }}
          onClick={(event: any) => {
            if (!event.detail || event.detail == 1) {
              generateImg("fever", imgRef.current);
            }
          }}
        />
      </BoxHeader>
      <LightDivider />
      {isLoading ? (
        <WrapperLoadingChart>
          <div className="container">
            <Loading width={70} height={70} />
          </div>
        </WrapperLoadingChart>
      ) : data.length > 0 ? (
        <BoxContent>
          <WrapperChart>
            <ContainerChart>
              <div className="icon-descriptions">
                {chartItems && (
                  <div
                    className="icon-descriptions-stacked"
                    style={{ display: "flex", height: "max-content" }}
                  >
                    {chartItems.map((item: any) => (
                      <div className="description" key={item.id}>
                        {/* <CheckboxStyled2
                            checked={checkedList[item.id]}
                            onChange={() => onChangeCheckbox(item.id)}
                          /> */}
                        <WrapperLegendLineChart style={{ marginRight: 16 }}>
                          <Dot
                            style={{ background: item.color, marginTop: 0 }}
                          />
                          <p>{t(item.label)}</p>
                        </WrapperLegendLineChart>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </ContainerChart>
            <BarChartCustom data={data} />
          </WrapperChart>
        </BoxContent>
      ) : (
        <WrapperNoData>
          <NoDataIcon width={140} height={140} />
        </WrapperNoData>
      )}
    </BoxItem>
    // </Col>
  );
};

export default MoodChange;
