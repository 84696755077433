import {
  Action,
  ActionType,
  AccountRegistrationListResponse,
} from "models/account/registrationList";
import createReducer from "../../createReducer";

export interface RegistrationListReducerType {
  loading: boolean;
  error?: string;
  registrationListResponse?: AccountRegistrationListResponse;
}

const defaultState: RegistrationListReducerType = {
  loading: false,
  error: undefined,
  registrationListResponse: undefined,
};

export const registrationListReducer =
  createReducer<RegistrationListReducerType>(defaultState, {
    [ActionType.ACCOUNT_REGISTRATION_LIST](state: RegistrationListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.ACCOUNT_REGISTRATION_LIST_ERROR](
      state: RegistrationListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.ACCOUNT_REGISTRATION_LIST_SUCCESS](
      state: RegistrationListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        registrationListResponse: action.payload,
      };
    },
  });
