import { IResponse } from "models";

export enum ActionType {
  SAVE_TEMPORARY_CLASS_CHANGE = "action/SAVE_TEMPORARY_CLASS_CHANGE",
  SAVE_TEMPORARY_CLASS_CHANGE_SUCCESS = "action/SAVE_TEMPORARY_CLASS_CHANGE_SUCCESS",
  SAVE_TEMPORARY_CLASS_CHANGE_ERROR = "action/SAVE_TEMPORARY_CLASS_CHANGE_ERROR",
  SAVE_TEMPORARY_CLASS_CHANGE_RESET = "action/SAVE_TEMPORARY_CLASS_CHANGE_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

interface IStudentList {
  patient_id: number;
  new_roll_number: number;
  new_class_id: number;
}
export interface ISaveTemporaryClassChangeRequest {
  student_list: IStudentList[];
}

export interface ISaveTemporaryClassChangeResponse extends IResponse {}
