import { IResponse } from "models";

export enum ActionType {
  VENUES_LIST = "action/VENUES_LIST",
  VENUES_LIST_SUCCESS = "action/VENUES_LIST_SUCCESS",
  VENUES_LIST_ERROR = "action/VENUES_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface VenueData {
  locale?: string | null;
}

interface IVenue {
  id: number;
  name: string;
  venue_code: string;
}

export interface VenuesListResponse extends IResponse {
  result: {
    venues: IVenue[];
    total: number;
  };
}
