import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  DetailSymtomResponse,
  ActionType,
  DetailSymtomPayload,
} from "models/dashboard/detailSymtom";
import { httpStatus } from "configs/httpStatus";
import { fetchDetailSymtomChart } from "services/dashboard";
import { handleSuccesAction, handleErrorAction } from "./detailSymtomActions";

function* getDetailSymtomSaga({ payload }: { payload: DetailSymtomPayload }) {
  try {
    const response: DetailSymtomResponse = yield call(
      fetchDetailSymtomChart,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDetailSymtomWatcher() {
  yield takeLatest(
    ActionType.GET_DETAIL_SYMTOM_CHART as any,
    getDetailSymtomSaga
  );
}

export default [fork(onGetDetailSymtomWatcher)];
