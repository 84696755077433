import { IResponse } from "models";

export enum ActionType {
  TODAY_SCHEDULES = "action/TODAY_SCHEDULES",
  TODAY_SCHEDULES_SUCCESS = "action/TODAY_SCHEDULES_SUCCESS",
  TODAY_SCHEDULES_ERROR = "action/TODAY_SCHEDULES_ERROR",
  SAVE_SCHEDULE = "action/SAVE_SCHEDULE",
  SAVE_SCHEDULE_SUCCESS = "action/SAVE_SCHEDULE_SUCCESS",
  SAVE_SCHEDULE_ERROR = "action/SAVE_SCHEDULE_ERROR",
  SAVE_SCHEDULE_RESET = "action/SAVE_SCHEDULE_RESET",
  UPDATE_SCHEDULE = "action/UPDATE_SCHEDULE",
  UPDATE_SCHEDULE_SUCCESS = "action/UPDATE_SCHEDULE_SUCCESS",
  UPDATE_SCHEDULE_RESET = "action/UPDATE_SCHEDULE_RESET",
  UPDATE_SCHEDULE_ERROR = "action/UPDATE_SCHEDULE_ERROR",
  DELETE_SLOT = "action/DELETE_SLOT",
  DELETE_SLOT_SUCCESS = "action/DELETE_SLOT_SUCCESS",
  DELETE_SLOT_ERROR = "action/DELETE_SLOT_ERROR",
  DELETE_SLOT_RESET = "action/DELETE_SLOT_RESET",
  SCHEDULE_FILTER = "action/SCHEDULE_FILTER",
  SCHEDULE_FILTER_SUCCESS = "action/SCHEDULE_FILTER_SUCCESS",
  SCHEDULE_FILTER_ERROR = "action/SCHEDULE_FILTER_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface TodaySchedulesData {
  start: string;
  locale?: string;
}

export interface ScheduleData {
  id: any;
  locale?: string;
  schedule_section: {
    start_end_date: string;
    title: string;
    block_status: boolean;
    venue_id: number;
    max_slot_size: number;
    start_time_hour: string;
    start_time_minute: string;
    end_time_hour: string;
    end_time_minute: string;
  };
}

export interface ISchedules {
  id: number;
  venue_name: string;
  start_time: string;
  end_time: string;
  max_slot_size: number;
  created_at: string;
  updated_at: string;
  title: string;
  block_status: boolean;
  count: number;
}

export interface IScheduleFilter {
  full_name: string;
  venue_name: string;
  start_time: string;
  end_time: string;
  created_at: string;
  vaccination_frequency: number;
}

export interface DeleteSlotData {
  id: number;
  locale?: string;
}

export interface TodaySchedulessResponse extends IResponse {
  result: ISchedules[];
}

export interface SaveScheduleResponse extends IResponse {
  result: any;
}

export interface UpdateScheduleResponse extends IResponse {
  result: any;
}

export interface DeleteSlotResponse extends IResponse {
  result: any;
}

export interface ScheduleFilterDatasResponse extends IResponse {
  result: {
    total: number;
    schedule_sections: IScheduleFilter[];
  };
}
