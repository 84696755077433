import React, { useState, useEffect } from "react";
import { Radio, notification } from "antd";
import type { RadioChangeEvent } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { subDays } from "date-fns";

import {
  Button,
  Box,
  Input,
  DateRangePicker,
  Select,
  ScrollToTop,
} from "components";
import { CloseIcon, SendCompleteIcon, ConfirmFailIcon } from "icons";
import {
  localStorageHelper,
  handleConvertDateYear,
  handleConvertMonthYear,
} from "utils";
import { IOption } from "components/select/Select";
import ConfirmModal from "screens/changeStudentAffiliation/ConfirmModal";

import { NewMentalCheckData } from "models/mentalHealth/newMentalCheck";
import {
  GradeListActionCreators,
  NewMentalCheckActionCreators,
} from "redux/rootActions";
import { gradeListDataResponse } from "redux/mentalHealth/gradesList/gradeListStates";
import {
  selectIsSaved,
  selectIsSavingMentalCheck,
  selectSaveErrorMessage,
  NewMentalCheckDataResponse,
} from "redux/mentalHealth/newMentalCheck/newMentalCheckStates";

import {
  AudioContainer,
  LeftItem,
  RightItem,
  TextTitle,
  AudioWrapper,
  TitleContainerClose,
  DateRangeItem,
  ButtonsContainer,
  ItemsContainerNumber,
  NewMentalCheckNoti,
} from "./MentalHealth.style";
import ConfirmCreateModal from "./ConfirmCreateModal";

interface INewMentalHealthCheck {
  handleCancel: () => void;
}

interface IDepartment {
  dept_id: number;
  dept_name: string;
}

const NewMentalHealthCheck: React.FC<INewMentalHealthCheck> = ({
  handleCancel,
}) => {
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "mental-health",
  });
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const dispatch = useDispatch();
  const GradeDatas = useSelector(gradeListDataResponse);
  const NewMentalCheckSaved = useSelector(selectIsSaved);
  const savingNewMentalCheck = useSelector(selectIsSavingMentalCheck);
  const NewMentalCheckResponse = useSelector(NewMentalCheckDataResponse);
  const NewMentalCheckError = useSelector(selectSaveErrorMessage);
  const [examinationPassed, setExaminationPassed] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<number>(1);
  const [selectValue, setSelectValue] = useState<any>();
  const [gradeOptions, setGradeOptions] = useState<IOption[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment().add(1, "M").toDate());
  const [testName, setTestName] = useState<string>(
    `${handleConvertMonthYear(
      moment(startDate).format("YYYY-MM-DD"),
      currentLanguage
    )}${t1("mental-health-placeholder")}`
  );
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [isVisibleModalLeave, setIsVisibleModalLeave] =
    useState<boolean>(false);
  const [error, setError] = useState({
    status: "",
    error: "",
  });
  const [inProgress, setInProgress] = useState<boolean>(true);

  const onChangeRadio = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const onChangeTestName = (e: any) => {
    setTestName(e.target.value);
    if (error.status === "test-name") {
      setError({
        status: "",
        error: "",
      });
    }
  };

  const onChangeSelect = (value: any) => {
    setSelectValue(value);
    if (error.status === "select") {
      setError({
        status: "",
        error: "",
      });
    }
  };

  const onCreateTest = () => {
    const newMentalCheckParams: NewMentalCheckData = {
      mental_check_type_id: radioValue,
      test_name: testName,
      start_time: moment(startDate).toString(),
      end_time: moment(endDate).toString(),
      grade: selectValue,
      is_temp_save: "false",
    };
    dispatch(
      NewMentalCheckActionCreators.newMentalCheckAction(newMentalCheckParams)
    );
  };

  const handleOpenModal = () => {
    if (!testName) {
      setError({
        status: "test-name",
        error: "enter-test-name",
      });
      return;
    }
    if (!selectValue) {
      setError({
        status: "select",
        error: "please-select-grade",
      });
      return;
    }
    if (examinationPassed) {
      setError({
        status: "passed",
        error: "exam-period-passed",
      });
      return;
    }
    if (moment(startDate).diff(moment(new Date())) > 0) {
      setInProgress(false);
    } else {
      setInProgress(true);
    }
    setIsVisibleModal(true);
  };

  const handleConfirm = () => {
    onCreateTest();
    setIsVisibleModal(false);
  };

  const handleSaveDraft = () => {
    if (!testName) {
      setError({
        status: "test-name",
        error: "enter-test-name",
      });
      return;
    }
    if (!selectValue) {
      setError({
        status: "select",
        error: "please-select-grade",
      });
      return;
    }
    if (examinationPassed) {
      setError({
        status: "passed",
        error: "exam-period-passed",
      });
      return;
    }

    const newMentalCheckParams: NewMentalCheckData = {
      mental_check_type_id: radioValue,
      test_name: testName,
      start_time: moment(startDate).toString(),
      end_time: moment(endDate).toString(),
      grade: selectValue,
      is_temp_save: "true",
    };
    dispatch(
      NewMentalCheckActionCreators.newMentalCheckAction(newMentalCheckParams)
    );
  };

  useEffect(() => {
    if (moment(endDate).diff(moment(subDays(new Date(), 1))) < 0) {
      setExaminationPassed(true);
    } else {
      setExaminationPassed(false);
      setError({
        status: "",
        error: "",
      });
    }
  }, [endDate]);

  useEffect(() => {
    if (NewMentalCheckSaved) {
      notification.success({
        placement: "bottomRight",
        message: t1("created"),
        icon: <SendCompleteIcon />,
      });
      dispatch(NewMentalCheckActionCreators.handleResetDataAction());
      handleCancel();
    }
  }, [NewMentalCheckSaved]);

  useEffect(() => {
    if (NewMentalCheckError) {
      notification.error({
        placement: "bottomRight",
        message: NewMentalCheckError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(NewMentalCheckActionCreators.handleResetDataAction());
    }
  }, [NewMentalCheckError]);

  useEffect(() => {
    if (GradeDatas) {
      const tempGrade: IOption[] = [];
      GradeDatas?.result?.map((item: IDepartment) =>
        tempGrade.push({
          id: item.dept_id,
          name: item.dept_name,
          value: item.dept_id,
        })
      );
      setGradeOptions(tempGrade);
    }
  }, [GradeDatas]);

  useEffect(() => {
    dispatch(GradeListActionCreators.gradeListAction());
    dispatch(NewMentalCheckActionCreators.handleResetDataAction());
    setGradeOptions([]);
    setError({
      status: "",
      error: "",
    });
    setIsVisibleModal(false);
  }, []);

  return (
    <>
      <ScrollToTop />
      <Box
        title={t1("mental-health-observation-management")}
        padding="16px 16px 32px"
      >
        <TitleContainerClose
          style={{
            paddingBottom: 24,
            marginTop: 8,
            marginBottom: 0,
            borderBottom: "1px solid #F2F2F2",
          }}
        >
          <TextTitle>{t1("new-mental-health-observation")}</TextTitle>
          <CloseIcon
            fill="#3B3B3B"
            style={{
              marginRight: 8,
              cursor: "pointer",
            }}
            onClick={() => setIsVisibleModalLeave(true)}
          />
        </TitleContainerClose>
        <ItemsContainerNumber
          style={{
            paddingTop: 22,
            paddingBottom: 22,
          }}
        >
          <LeftItem>{t1("action-item-number")}</LeftItem>
          <Radio.Group onChange={onChangeRadio} value={radioValue}>
            <AudioWrapper>
              <Radio value={1}>{t1("lmc-25-elementary")}</Radio>
              <Radio value={2}>{t1("lmc-13-elementary")}</Radio>
              <Radio value={3}>{t1("lmc-25-junior")}</Radio>
              <Radio value={4}>{t1("lmc-13-junior")}</Radio>
            </AudioWrapper>
          </Radio.Group>
        </ItemsContainerNumber>
        <AudioContainer>
          <LeftItem>{t1("test-name")}</LeftItem>
          <RightItem>
            <Input
              height={40}
              value={testName}
              onChange={onChangeTestName}
              placeholder={t1("test-name-placeholder")}
            />
            {error.status === "test-name" && error.error && (
              <div className="red-text">{t1(error.error)}</div>
            )}
          </RightItem>
        </AudioContainer>
        <AudioContainer>
          <LeftItem>{t1("implemented-grade-selection")}</LeftItem>
          <DateRangeItem>
            <Select
              placeholder={t1("select-grade")}
              width="124px"
              value={selectValue}
              options={gradeOptions}
              onChange={onChangeSelect}
            />
            {error.status === "select" && error.error && (
              <div className="red-text">{t1(error.error)}</div>
            )}
          </DateRangeItem>
        </AudioContainer>
        <AudioContainer>
          <LeftItem>{t1("examination-period")}</LeftItem>
          <DateRangeItem>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              locale={currentLanguage}
              maxDate={false}
              minDate={true}
            />
            {error.status === "passed" && error.error && (
              <div className="red-text">{t1(error.error)}</div>
            )}
          </DateRangeItem>
        </AudioContainer>

        <ButtonsContainer>
          <Button
            color="#FFFFFF"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            background={"#3B3B3B"}
            padding="13px 24px 11px"
            name={t1("save-as-draft")}
            bdr="6px"
            border="none"
            onClick={handleSaveDraft}
            disabled={!testName || !selectValue || savingNewMentalCheck}
          />
          <Button
            color="#FFFFFF"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            background={"#2AC769"}
            padding="13px 24px 11px"
            name={t1("confirm-create-observation")}
            bdr="6px"
            border="none"
            onClick={handleOpenModal}
            disabled={!testName || !selectValue || savingNewMentalCheck}
          />
        </ButtonsContainer>
        {isVisibleModal && (
          <ConfirmCreateModal
            isVisible={isVisibleModal}
            handleCancel={() => setIsVisibleModal(false)}
            handleOk={handleConfirm}
            title={
              inProgress
                ? t1("confirm-implementation")
                : t1("confirm-reservation")
            }
            buttonName={inProgress ? t1("implement") : t1("make-reservation")}
          >
            <div>
              {inProgress ? (
                <NewMentalCheckNoti>
                  <span>
                    {
                      gradeOptions.find((item: any) => item.id === selectValue)
                        ?.name
                    }
                    {t1("life")}
                  </span>
                  {t1("notification")}
                  <div>{t1("cannot-be-changed")}</div>
                </NewMentalCheckNoti>
              ) : (
                <NewMentalCheckNoti>
                  <span>
                    {handleConvertDateYear(
                      moment(startDate).format("YYYY-MM-DD"),
                      currentLanguage
                    )}
                  </span>
                  {t1("to")}
                  <span>
                    {
                      gradeOptions.find((item: any) => item.id === selectValue)
                        ?.name
                    }
                    {t1("life")}
                  </span>
                  {t1("we-will-notify")}
                  <div>{t1("reservation-question")}</div>
                </NewMentalCheckNoti>
              )}
            </div>
          </ConfirmCreateModal>
        )}
        {isVisibleModalLeave && (
          <ConfirmModal
            isVisible={isVisibleModalLeave}
            handleCancel={() => setIsVisibleModalLeave(false)}
            handleOk={handleCancel}
          >
            <div
              style={{
                fontWeight: 500,
                fontSize: 14,
                lineHeight: "20px",
              }}
            >
              {t1("confirm-leaving")}
            </div>
          </ConfirmModal>
        )}
      </Box>
    </>
  );
};

export default NewMentalHealthCheck;
