import {
  DuplicateQuestionResponse,
  ActionType,
  IDuplicateQuestionPayload,
} from "models/questionnaires/duplicateQuestion";

export const duplicateQuestionAction = (payload: IDuplicateQuestionPayload) => {
  return {
    type: ActionType.DUPLICATE_QUESTION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DUPLICATE_QUESTION_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: DuplicateQuestionResponse) => {
  return {
    type: ActionType.DUPLICATE_QUESTION_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.DUPLICATE_QUESTION_RESET,
  };
};
