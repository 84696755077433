import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  DepartmentListData,
  ActionType,
  DepartmentListResponse,
} from "models/departmentList";
import { httpStatus } from "configs/httpStatus";
import { fetchDepartmentList } from "services/departmentList";
import { handleSuccesAction, handleErrorAction } from "./departmentListActions";

function* departmentListSaga({ payload }: { payload: DepartmentListData }) {
  try {
    const response: DepartmentListResponse = yield call(
      fetchDepartmentList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDepartmentListWatcher() {
  yield takeLatest(ActionType.DEPARTMENT_LIST as any, departmentListSaga);
}

export default [fork(onGetDepartmentListWatcher)];
