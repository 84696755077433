import {
  Action,
  ActionType,
  EditAccountResponse,
} from "models/account/editAccount";
import createReducer from "../../createReducer";

export interface EditAccountReducerType {
  loading: boolean;
  error?: string;
  editAccountResponse?: EditAccountResponse;
}

const defaultState: EditAccountReducerType = {
  loading: false,
  error: undefined,
  editAccountResponse: undefined,
};

export const editAccountReducer = createReducer<EditAccountReducerType>(
  defaultState,
  {
    [ActionType.EDIT_ACCOUNT](state: EditAccountReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.EDIT_ACCOUNT_ERROR](
      state: EditAccountReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.EDIT_ACCOUNT_SUCCESS](
      state: EditAccountReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        editAccountResponse: action.payload,
      };
    },

    [ActionType.RESET_EDIT_ACCOUNT](state: EditAccountReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        editAccountResponse: {},
      };
    },
  }
);
