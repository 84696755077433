interface IRawData {
  id: number;
  name: string;
}

export const convertRawDataToOptions = (rawData: IRawData[]) => {
  return rawData.map((item) => {
    return {
      id: item.id,
      value: item.id,
      name: item.name,
    };
  });
};

export const convertRawDataToOptions2 = (rawData: any[]) => {
  return rawData.map((item) => {
    return {
      id: item[1],
      value: item[1],
      name: item[0],
    };
  });
};
