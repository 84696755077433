import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Grid, Tooltip } from "antd";
import { useDispatch } from "react-redux";

import { Button, Select, Pagination } from "components";
import { CopyIcon } from "icons";
import { generateDynamicRecords } from "utils";

import { IQuestionnaires } from "models/questionnaires/questionnairesByType";
import {
  DuplicateQuestionActionCreators,
  QuestionnairesDetailActionCreators,
} from "redux/rootActions";

import {
  WrapperContent,
  WrapperSizeChange,
  NoDataMessage,
  TableStyled,
  TitleTable,
  WrapperFields,
  WrapperTopContent,
  WrapperActionContent,
  WrapperPagination,
} from "./Questions.style";
import { ScreenState } from "./Questions";
import { format } from "./const";

const { useBreakpoint } = Grid;

interface IQuestionList {
  setScreen: React.Dispatch<React.SetStateAction<ScreenState>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  data: IQuestionnaires[];
  total: number;
  setInput: React.Dispatch<React.SetStateAction<any>>;
  input: any;
}

const QuestionList: React.FC<IQuestionList> = ({
  setScreen,
  setStatus,
  data,
  total,
  input,
  setInput,
}) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });

  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const handleViewQuestionDetail = (id: number) => {
    dispatch(
      QuestionnairesDetailActionCreators.getQuestionnaireDetailAction({ id })
    );
    setScreen("create");
  };

  const returnRates = (rate: number) => {
    if (rate % 1 != 0) {
      return rate.toFixed(2);
    }
    return rate;
  };

  const columns = [
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      fixed: window.innerWidth < 576 ? "" : "left",
      width: 208,
      render: (text: string, record: any) => {
        return (
          <WrapperFields>
            <div>
              <CopyIcon
                style={{ width: "20px", marginTop: "0px" }}
                fill="#666666"
                onClick={() => {
                  dispatch(
                    DuplicateQuestionActionCreators.duplicateQuestionAction({
                      id: record.id,
                    })
                  );
                }}
              />
            </div>
            <Tooltip
              placement="bottomLeft"
              title={text}
              overlayStyle={{ maxWidth: "250px" }}
            >
              <TitleTable
                onClick={() => {
                  handleViewQuestionDetail(record.id);
                  setStatus("view-detail");
                }}
              >
                {text}
              </TitleTable>
            </Tooltip>
          </WrapperFields>
        );
      },
    },
    {
      title: t("answer-rate"),
      dataIndex: "total_patient_answered",
      key: "total_patient_answered",
      width: 110,
      render: (text: any, record: any) => {
        return (
          <div>
            {record.get_response_rate
              ? returnRates(parseInt(record.get_response_rate))
              : 0}
            %
          </div>
        );
      },
    },
    {
      title: t("implementation-period"),
      width: 228,
      render: (text: any, record: any) => {
        return (
          <div>
            {moment(record.start_time).format(format)}~
            {moment(record.end_time).format(format)}
          </div>
        );
      },
    },
    {
      title: t("created-date"),
      dataIndex: "created_at",
      key: "created_at",
      width: 128,
      render: (text: string) => {
        return <div>{moment(text).format(format)}</div>;
      },
    },
    {
      title: t("modification-date"),
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text: string) => {
        return <div>{moment(text).format(format)}</div>;
      },
    },
    // {
    //   title: "",
    //   width: 300,
    //   render: (text: any, record: any) => (
    //     <CloseRoundIcon
    //       onClick={() => {
    //         // dispatch(
    //         //   DeleteQuestionnaireActionCreators.deleteQuestionnairesAction({
    //         //     id: record.id,
    //         //   })
    //         // );
    //       }}
    //       style={{ cursor: "pointer" }}
    //     />
    //   ),
    // },
  ];

  const handleSizeChange = (sizeChange: number) => {
    setInput((prevState: any) => ({
      ...prevState,
      limit: sizeChange,
      page: 1,
    }));
  };

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  const ShowPagination = () => {
    return total > input.limit ? (
      <WrapperPagination>
        <Pagination
          current={input.page}
          onChange={handleChangePage}
          pageSize={input.limit}
          total={total}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </WrapperPagination>
    ) : (
      <Fragment></Fragment>
    );
  };

  return (
    <WrapperContent>
      <WrapperTopContent>
        <Button
          background="#2AC769"
          color="#fff"
          padding="11px 24px"
          name={t("create-a-survey")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          onClick={() => {
            setScreen("create");
            setStatus("create");
          }}
        />
        <div>{t("latest-used-form")}</div>
      </WrapperTopContent>

      <WrapperActionContent>
        <WrapperSizeChange>
          <div style={{ fontWeight: 500 }}>{t2("record")}</div>
          <Select
            defaultValue={25}
            options={generateDynamicRecords(total, t2, [25, 50, 100, 200])}
            onChange={handleSizeChange}
          />
        </WrapperSizeChange>
        <ShowPagination />
      </WrapperActionContent>

      <div>
        <TableStyled
          columns={columns}
          dataSource={data}
          scroll={!data.length ? {} : { x: "max-content" }}
          loading={false}
        />
        {!data.length && (
          <NoDataMessage style={{ paddingBottom: "200px" }}>
            {t3("no-data-reflection-list")}
          </NoDataMessage>
        )}
      </div>
      <ShowPagination />
    </WrapperContent>
  );
};

export default QuestionList;
