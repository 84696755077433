import {
  ActionType,
  AttendanceFilterListResponse,
  AttendanceFilterData,
} from "../../../models/messagemanagement/attendanceFilterList";

export const attendanceFilterListAction = (payload: AttendanceFilterData) => {
  return {
    type: ActionType.ATTENDANCE_FILTER_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.ATTENDANCE_FILTER_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: AttendanceFilterListResponse) => {
  return {
    type: ActionType.ATTENDANCE_FILTER_LIST_SUCCESS,
    payload,
  };
};
