import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { registerLocale } from "react-datepicker";
import { Grid, Col, Row } from "antd";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { Button, Select, Box, Pagination, DatePicker } from "components";
import { DownloadIcon, MoreIcon } from "icons";
import {
  localStorageHelper,
  handleConvertDay,
  generateDynamicRecords,
  generateCSV,
  mplusFont,
} from "utils";
import ja from "date-fns/locale/ja";
import { IOption } from "components/select/Select";

import {
  ScheduleFilterActionCreators,
  GetSlotsDataActionCreators,
  TimeSlotActionCreators,
} from "redux/rootActions";
import { slotsResponse } from "redux/vaccine/slots/slotsStates";
import { scheduleFilterResponse } from "redux/vaccine/getScheduleFilter/getScheduleFilterStates";
import { timeSlotResponse } from "redux/vaccine/timeSlot/timeSlotStates";
import { IScheduleFilter } from "models/vaccine/schedules";

import {
  WrapperSelectGroup,
  SelectFirst,
  WrapperDatePicker,
  SelectSecond,
  SelectThird,
  GroupRightButton,
  WrapperGroupButton,
  ButtonStyled,
  TableStyled,
  WapperFilterReservation,
  DivSelect,
  PaginationWrapper,
  TableActionsWrapper,
} from "./Vaccination.style";

registerLocale("ja", ja);
const { useBreakpoint } = Grid;

interface IVenues {
  id: number;
  value: number;
  name: string;
}

const ReservationList: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const allOption = [
    {
      id: -1,
      value: "",
      name: t3("all-select"),
    },
  ];
  const slotsData: any = useSelector(slotsResponse);
  const timeSlotData: any = useSelector(timeSlotResponse);
  const scheduleFilters = useSelector(scheduleFilterResponse);

  const [startDateFilter, setStartDateFilter] = useState<Date>();
  const [venueFilter, setVenueFilter] = useState<number>();
  const [timeFilter, setTimeFilter] = useState<string>("");
  const [vaccineDoseFilter, setVaccineDoseFilter] = useState<any>();
  const [isGroupBtnMobile, setIsGroupBtnMobile] = useState(false);
  const [timeSlot, setTimeSlotOptions] = useState<IOption[]>([...allOption]);
  const [venueOptions, setVenueOptions] = useState<IVenues[]>();
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [filters, setFilters] = useState({
    page: 1,
    sizeChange: 50,
  });
  const [dataSource, setDataSource] = useState<IScheduleFilter[]>([]);

  const innoculations = [
    ...allOption,
    {
      id: 1,
      value: "1",
      name: "1",
      label: "1",
    },
    {
      id: 2,
      value: "2",
      name: "2",
      label: "2",
    },
    {
      id: 3,
      value: "3",
      name: "3",
      label: "3",
    },
    {
      id: 4,
      value: "4",
      name: "4",
      label: "4",
    },
  ];

  useEffect(() => {
    dispatch(GetSlotsDataActionCreators.getSlotsAction());
    dispatch(ScheduleFilterActionCreators.getScheduleFilterAction({}));
  }, []);

  useEffect(() => {
    if (scheduleFilters && Object.keys(scheduleFilters?.result).length) {
      const { total, schedule_sections } = scheduleFilters?.result;
      setTotalRecords(total);
      setDataSource(schedule_sections);
      let recordOptionsClone: IOption[] = [];
      recordOptionsClone = generateDynamicRecords(total, t);
      setRecordOptions([...recordOptionsClone]);
    }
  }, [scheduleFilters]);

  useEffect(() => {
    if (slotsData) {
      setVenueOptions([
        ...allOption,
        ...slotsData.result.venue_options.map((item: any, index: number) => {
          return {
            id: index,
            value: item[1],
            name: item[0],
          };
        }),
      ]);
    }
  }, [slotsData]);

  useEffect(() => {
    if (timeSlotData && startDateFilter) {
      setTimeSlotOptions([
        ...allOption,
        ...timeSlotData.result.schedule_sections.map(
          (item: any, index: number) => {
            return {
              id: index,
              value: item,
              name: item,
            };
          }
        ),
      ]);
    }
  }, [timeSlotData]);

  const getScheduleFilters = useCallback(() => {
    const params: any = {
      page: filters.page,
      per_page: filters.sizeChange,
      locale: currentLanguage,
      venue: venueFilter,
      slot: startDateFilter ? moment(startDateFilter).format("YYYY-MM-DD") : "",
      role: "",
      time: timeFilter,
      vaccine_dose: vaccineDoseFilter,
    };
    dispatch(ScheduleFilterActionCreators.getScheduleFilterAction(params));
    setIsSearching(false);
  }, [filters]);

  useEffect(() => {
    if (isSearching) {
      getScheduleFilters();
    }
  }, [getScheduleFilters]);

  const handleFilter = () => {
    const params = {
      ...filters,
      venue: venueFilter,
      slot: startDateFilter ? moment(startDateFilter).format("YYYY-MM-DD") : "",
      role: "",
      time: timeFilter,
      vaccine_dose: vaccineDoseFilter,
    };
    dispatch(ScheduleFilterActionCreators.getScheduleFilterAction(params));
    setFilters(params);
  };

  const returnDate = (start: string, end?: string) => {
    if (end) {
      return `${moment(start).format("YYYY")}年${handleConvertDay(
        moment(start).format("YYYY-MM-DD"),
        currentLanguage
      )} ${moment(start).format("HH:mm")}〜${moment(end).format("HH:mm")}`;
    }
    return `${handleConvertDay(
      moment(start).format("YYYY-MM-DD"),
      currentLanguage
    )}${moment(start).format("HH:mm")}`;
  };

  const handleChangePage = (page: number) => {
    setFilters((prevState: any) => ({ ...prevState, page }));
    setIsSearching(true);
  };

  const handleOnChangeSizeChange = (sizeChange: number) => {
    setFilters((prevState: any) => ({ ...prevState, sizeChange, page: 1 }));
    setIsSearching(true);
  };

  const ShowPagination = () => {
    return totalRecords > filters.sizeChange ? (
      <PaginationWrapper>
        <Pagination
          current={filters.page}
          onChange={handleChangePage}
          pageSize={filters.sizeChange}
          total={scheduleFilters?.result.total}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </PaginationWrapper>
    ) : (
      <React.Fragment></React.Fragment>
    );
  };

  const columns = [
    {
      title: t3("full-name"),
      dataIndex: "full_name",
      key: "full_name",
      width: 187,
      render: (text: any) => {
        if (!text || text === "") {
          return <div>-</div>;
        }
        return text;
      },
    },
    {
      title: t3("venue-label"),
      dataIndex: "venue_name",
      key: "venue_name",
      width: 128,
      render: (text: any) => {
        if (!text || text === "") {
          return <div>-</div>;
        }
        return text;
      },
    },
    {
      title: t3("start-time"),
      dataIndex: "start_time",
      key: "start_time",
      width: 199,
      render: (text: any, record: any) => {
        if (!text || text === "") {
          return <div>-</div>;
        }
        return returnDate(record.start_time, record.end_time);
      },
    },
    {
      title: t3("created-at"),
      dataIndex: "created_at",
      key: "created_at",
      width: 144,
      render: (text: any, record: any) => {
        if (!text || text === "") {
          return <div>-</div>;
        }
        return returnDate(record.created_at);
      },
    },
    {
      title: t3("vaccination-frequency"),
      dataIndex: "vaccination_frequency",
      key: "vaccination_frequency",
      render: (text: any) => {
        if (!text || text === "") {
          return <div>-</div>;
        }
        return text;
      },
    },
  ];

  const handleShowGroupBtnMobile = () => {
    setIsGroupBtnMobile(!isGroupBtnMobile);
  };

  const handleFileData = () => {
    return dataSource.map((item) => {
      return {
        full_name: item.full_name,
        venue_name: item.venue_name,
        schedule: returnDate(item.start_time, item.end_time),
        created_at: returnDate(item.created_at),
        vaccination_frequency: item.vaccination_frequency,
      };
    });
  };

  const handleGenerateCSVFile = () => {
    const fileData = handleFileData();
    const currentDate = moment(new Date()).format("YYYYMMDD");
    const fileName = `inoculation reservation list_${currentDate}.csv`;
    const headers = [
      { label: "氏名", key: "full_name" },
      { label: "会場", key: "venue_name" },
      { label: "日程", key: "schedule" },
      { label: "予約日時", key: "created_at" },
      { label: "接種回数", key: "vaccination_frequency" },
    ];
    generateCSV(fileName, headers, fileData);
  };

  const handleGeneratePDFFile = () => {
    const currentDate = moment(new Date()).format("YYYYMMDD");
    const fileName = `inoculation reservation list_${currentDate}.pdf`;
    const headers = ["氏名", "会場", "日程", "予約日時", "接種回数"];
    const fileData = handleFileData();

    const data: any[] = [];
    fileData.forEach((item: any) => {
      const results = [];
      for (const key in item) {
        results.push(item[key]);
      }
      data.push(results);
    });

    const doc = new jsPDF();
    doc.addFileToVFS("mplus.ttf", mplusFont);
    doc.addFont("mplus.ttf", "mplus", "normal");
    doc.setFont("mplus", "normal");
    const myTable = {
      head: [[...headers]],
      body: data,
      headStyles: {
        font: "mplus",
      },
      bodyStyles: {
        font: "mplus",
      },
      margin: { top: 15, left: 8, right: 8 },
    };

    autoTable(doc, myTable);
    doc.save(fileName);
  };

  const GroupButton = () => (
    <>
      <ButtonStyled
        color="#FFFFFF"
        fontSize={12}
        fontWeight={700}
        lineHeight="16px"
        background="#2AC769"
        padding="2px 8px 1px 16px"
        name={t2("download-csv")}
        bdr="4px"
        border="1px solid #2AC769"
        onClick={handleGenerateCSVFile}
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="#FFFFFF"
            style={{ position: "absolute", left: 5, top: 4 }}
          />
        }
      />
      <ButtonStyled
        color="#FFFFFF"
        fontSize={12}
        fontWeight={700}
        lineHeight="16px"
        background="#2AC769"
        padding="2px 8px 1px 16px"
        name={t2("download-pdf")}
        bdr="4px"
        border="1px solid #2AC769"
        onClick={handleGeneratePDFFile}
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="#FFFFFF"
            style={{ position: "absolute", left: 5, top: 4 }}
          />
        }
      />
    </>
  );

  const ActionSection = () => {
    return (
      <TableActionsWrapper>
        <GroupRightButton style={{ display: "flex" }}>
          <GroupButton />
          <div style={{ position: "relative", height: 24 }}>
            <MoreIcon onClick={handleShowGroupBtnMobile} />
            {isGroupBtnMobile && (
              <WrapperGroupButton>
                <GroupButton />
              </WrapperGroupButton>
            )}
          </div>
        </GroupRightButton>
      </TableActionsWrapper>
    );
  };

  return (
    <>
      <Box title={t1("list-of-reservations-label")} padding={"16px"}>
        <WrapperSelectGroup>
          <SelectFirst
            label={t3("venue-label")}
            fsLabel={16}
            lhLabel={"16px"}
            placeholder={t3("all-select")}
            options={venueOptions}
            marginForm={"5px 0px 0px 0px"}
            onChange={(value: number) => setVenueFilter(value)}
          />
          <WrapperDatePicker>
            <p
              style={{
                margin: "5px 0px 8px",
                fontSize: "16px",
                lineHeight: "16px",
              }}
            >
              {t3("reservation-date")}
            </p>
            <DatePicker
              locale="ja"
              selected={startDateFilter}
              placeholderText={t3("unspecified")}
              onChange={(date: Date) => {
                setStartDateFilter(date);
                setTimeFilter("");
                dispatch(
                  TimeSlotActionCreators.getTimeSlotAction({
                    selected_slot: date,
                  })
                );
              }}
            />
          </WrapperDatePicker>
          <SelectSecond
            value={timeFilter}
            label={t3("time")}
            fsLabel={16}
            lhLabel={"16px"}
            placeholder={t3("all-select")}
            options={timeSlot}
            marginForm={"5px 0px 0px 0px"}
            onChange={(value: any) => setTimeFilter(value)}
          />
          <SelectThird
            label={t3("number-of-inoculations")}
            fsLabel={16}
            lhLabel={"16px"}
            placeholder={t("please-select")}
            options={innoculations}
            marginForm={"5px 0px 0px 0px"}
            onChange={(value: any) => setVaccineDoseFilter(value)}
          />
          <WapperFilterReservation>
            <Button
              background={"#2AC769"}
              color={"#fff"}
              name={t2("narrow-down")}
              border={"none"}
              bdr={"6px"}
              fontSize={16}
              lineHeight={"16px"}
              onClick={handleFilter}
            />
          </WapperFilterReservation>
        </WrapperSelectGroup>

        <Row>
          <Col span={6} xs={24} md={6} lg={6}>
            <DivSelect>
              <p style={{ marginTop: "5px" }}>{t("record")}</p>
              <Select
                defaultValue={50}
                options={recordOptions}
                marginForm={"0px 0px 0px 0px"}
                onChange={handleOnChangeSizeChange}
              />
            </DivSelect>
          </Col>
          <Col span={18} xs={24} md={18} lg={18}>
            <ShowPagination />
          </Col>
        </Row>
        <TableStyled
          dataSource={dataSource}
          columns={columns}
          actionSection={<ActionSection />}
          scroll={{ x: "max-content", y: 710 }}
        />
        <ShowPagination />
      </Box>
    </>
  );
};

export default ReservationList;
