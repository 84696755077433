import { Action, ActionType } from "models/classmanagement/updateClass";
import { IResponse } from "models";
import createReducer from "../../createReducer";

export interface UpdateClassReducerType {
  loading: boolean;
  error?: string;
  updateClassResponse?: IResponse;
}

const defaultState: UpdateClassReducerType = {
  loading: false,
  error: undefined,
  updateClassResponse: undefined,
};

export const updateClassReducer = createReducer<UpdateClassReducerType>(
  defaultState,
  {
    [ActionType.UPDATE_CLASS](state: UpdateClassReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_CLASS_ERROR](
      state: UpdateClassReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_CLASS_SUCCESS](
      state: UpdateClassReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        updateClassResponse: action.payload,
      };
    },
  }
);
