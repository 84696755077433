import { put, takeLatest, fork, call } from "redux-saga/effects";

import { ActionType, AccountListResponse } from "models/account/accountList";
import { httpStatus } from "configs/httpStatus";
import { fetchDataList } from "services/account";

import { handleSuccesAction, handleErrorAction } from "./accountListActions";

function* getAccountListSaga({ payload }: any) {
  try {
    const response: AccountListResponse = yield call(fetchDataList, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetAccountListWatcher() {
  yield takeLatest(ActionType.ACCOUNT_LIST as any, getAccountListSaga);
}

export default [fork(onGetAccountListWatcher)];
