import {
  ActionType,
  DataPatientDetailResponse,
  DataPatientDetailRequest,
} from "models/bodyTemperature/patientDetail";

export const getDataPatientDetail = (payload: DataPatientDetailRequest) => {
  return {
    type: ActionType.DATA_PATIENT_DETAIL,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DATA_PATIENT_DETAIL_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DataPatientDetailResponse) => {
  return {
    type: ActionType.DATA_PATIENT_DETAIL_SUCCESS,
    payload,
  };
};
