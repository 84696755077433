import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/classmanagement/createClass";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { createSchoolClasses } from "services/schoolclasses";
import { handleSuccesAction, handleErrorAction } from "./createClassActions";
import { ICreateClassData } from "models/classmanagement/createClass";

function* createClassSaga({ payload }: { payload: ICreateClassData }) {
  try {
    const response: IResponse = yield call(createSchoolClasses, payload);
    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCreateClassWatcher() {
  yield takeLatest(ActionType.CREATE_CLASS as any, createClassSaga);
}

export default [fork(onCreateClassWatcher)];
