import {
  Action,
  ActionType,
  IHealthCheckSettingResponse,
} from "models/setting/healthCheckSetting";
import createReducer from "../../createReducer";

export interface SetHealthCheckSettingReducerType {
  loading: boolean;
  error?: string;
  setHealthCheckSettingResponse?: IHealthCheckSettingResponse;
}

const defaultState: SetHealthCheckSettingReducerType = {
  loading: false,
  error: undefined,
  setHealthCheckSettingResponse: undefined,
};

export const setHealthCheckSettingReducer =
  createReducer<SetHealthCheckSettingReducerType>(defaultState, {
    [ActionType.HEALTH_CHECK_SETTING](state: SetHealthCheckSettingReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.HEALTH_CHECK_SETTING_ERROR](
      state: SetHealthCheckSettingReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.HEALTH_CHECK_SETTING_SUCCESS](
      state: SetHealthCheckSettingReducerType,
      action: Action<IHealthCheckSettingResponse>
    ) {
      return {
        ...state,
        loading: false,
        setHealthCheckSettingResponse: action.payload,
      };
    },

    [ActionType.HEALTH_CHECK_SETTING_RESET](
      state: SetHealthCheckSettingReducerType
    ) {
      return {
        ...state,
        loading: false,
        setHealthCheckSettingResponse: undefined,
      };
    },
  });
