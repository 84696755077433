import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ClassListData,
  ActionType,
  ClassListResponse,
} from "../../models/classList";
import { httpStatus } from "configs/httpStatus";
import { fetchClassList } from "services/classList";
import { handleSuccesAction, handleErrorAction } from "./classListActions";

function* classListSaga({ payload }: { payload: ClassListData }) {
  try {
    const response: ClassListResponse = yield call(fetchClassList, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetClassListWatcher() {
  yield takeLatest(ActionType.CLASS_LIST as any, classListSaga);
}

export default [fork(onGetClassListWatcher)];
