import React from "react";
import { Collapse, CollapseProps } from "antd";
import styled from "styled-components";

import { CarrotIcon } from "icons";

const CollapseStyled = styled(Collapse)(({ theme }) => ({
  backgroundColor: "unset",
  border: "unset",
  ".ant-collapse-header": {
    fontSize: theme.sizes.lg,
    fontWeight: theme.fontWeight.bold,
    lineHeight: "26px!important",
    color: `${theme.colors.text.primary}!important`,
    display: "flex",
    alignItems: "center",
    padding: "0!important",
  },
  ".ant-collapse-item": {
    borderBottom: "none",
  },
  ".ant-collapse-arrow": {
    order: 2,
    marginRight: 0,
    marginLeft: 22,
  },
  ".ant-collapse-icon-position-right .ant-collapse-header": {
    color: "red",
  },
  ".ant-collapse-content-box": {
    padding: 0,
    paddingTop: "20px!important",
  },
  ".ant-collapse-header-text": {
    width: "100%",
  },
}));

interface ICollapse extends CollapseProps {
  colorIcon?: string;
  header?: React.ReactNode;
}

const CollapseCustom: React.FC<ICollapse> = ({
  header,
  colorIcon = "#3B3B3B",
  children,
  ...rest
}) => {
  return (
    <CollapseStyled
      bordered={false}
      expandIcon={({ isActive }) => (
        <CarrotIcon
          style={{ transform: isActive ? "rotate(-180deg)" : "rotate(0)" }}
          fill={colorIcon}
        />
      )}
      className="site-collapse-custom-collapse"
      {...rest}
    >
      {children}
    </CollapseStyled>
  );
};

export default CollapseCustom;
