import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DeleteAccountResponse,
} from "models/account/deleteAccount";
import { httpStatus } from "configs/httpStatus";
import { deleteAccount } from "services/account";

import { handleSuccesAction, handleErrorAction } from "./deleteAccountActions";

function* deleteAccountSaga({ payload }: any) {
  try {
    const response: DeleteAccountResponse = yield call(deleteAccount, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDeleteAccountWatcher() {
  yield takeLatest(ActionType.DELETE_ACCOUNT as any, deleteAccountSaga);
}

export default [fork(onDeleteAccountWatcher)];
