import {
  Action,
  ActionType,
  ICompleteRequestResponse,
} from "models/consultationRequest/completeRequest";
import createReducer from "redux/createReducer";

export interface CompleteRequestReducerType {
  loading: boolean;
  error?: string;
  completeRequestResponse?: ICompleteRequestResponse;
}

const defaultState: CompleteRequestReducerType = {
  loading: false,
  error: undefined,
  completeRequestResponse: undefined,
};

export const completeRequestReducer = createReducer<CompleteRequestReducerType>(
  defaultState,
  {
    [ActionType.COMPLETE_REQUEST](state: CompleteRequestReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.COMPLETE_REQUEST_ERROR](
      state: CompleteRequestReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        completeRequestResponse: undefined,
      };
    },

    [ActionType.COMPLETE_REQUEST_SUCCESS](
      state: CompleteRequestReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        completeRequestResponse: action.payload,
      };
    },

    [ActionType.COMPLETE_REQUEST_RESET](state: CompleteRequestReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
  }
);
