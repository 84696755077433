import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { Select, Collapse, Button } from "components";
import {
  convertFilename,
  generateCSV,
  localStorageHelper,
  handleGetTimeByTimeZone,
  udgothicFont,
} from "utils";
import {
  MoreIcon,
  PieChartIcon,
  BarChartIcon,
  LineChartIcon,
  NoDataIcon,
  DownloadIcon,
  SendCompleteIcon,
  ConfirmFailIcon,
} from "icons";

import { TextConfirm } from "screens/messageDelivery/MessageDelivery.style";
import {
  IPatient,
  IAnswerByDepartment,
} from "models/questionnaires/answerInfo";
import {
  DownloadCSVAllQuestionsActionCreators,
  DownloadCSVQuestionActionCreators,
  RemindPushNotificationActionCreators,
} from "redux/rootActions";
import { answerInfoDataResponse } from "redux/questionnaires/answerInfo/answerInfoStates";
import {
  IQuestionnaire,
  QuestionnairesQuestion,
} from "models/questionnaires/questionnairesDetail";
import { downloadCSVAllQuestionsResponse } from "redux/questionnaires/downloadCSVAllQuestions/downloadCSVAllQuestionsStates";
import { downloadCSVQuestionResponse } from "redux/questionnaires/downloadCSVQuestion/downloadCSVQuestionStates";
import {
  selectIsSentReminder,
  remindPushNotificationErrorMessage,
} from "redux/questionnaires/remindPushNotification/remindPushNotificationStates";

import PieChart from "./charts/PieChart";
import PieChartWithCustomizedLabel from "./charts/PieChartWithCustomizedLabel";
import BarChart from "./charts/BarChart";
import LineChart from "./charts/LineChart";
import {
  WrapperCollapse,
  WrapperDropdown,
  WrapperChartContent,
  WrapperIconSelect,
  WrapperPieChart,
  WrapperSurveyChart,
  WrapperAnswer,
  WrapperTag,
  TagStyled,
  WrapperQuestion,
  WrapperSurvey,
  WrapperContentChart,
  WrapperOtherBreakdown,
  QuestionAnswerTitle,
  WrapperAnswerList,
  AnswersWrapper,
} from "./Questions.style";
import { formatDateTime } from "./const";
import { ConfirmModal } from "./components";

const colorTextCenter = "#2A98C7";
const COLORS = ["#2F8CAE", "#E0E0E0"];

interface IDataChart {
  name: string;
  value: number;
  percent: number;
}

interface IDdisplayDropdown {
  visible: boolean;
  id: string;
}

interface IAnswerTab {
  questionnaires: IQuestionnaire;
  status: string;
}

interface IQuestionChart {
  question_content: string;
  question_position: number;
  dataChart: IDataChart[];
  answer_text: string[];
  answer_type: string;
  colors: string[];
  index?: any;
}

interface IData {
  totalPatient: number;
  totalPatientAnswered: number;
  totalPatientNotAnswered: number;
  patientAnswered: IPatient[];
  patientNotAnswered: IPatient[];
  questionsChart: IQuestionChart[];
  answeredByDepartment: IAnswerByDepartment[];
}

interface IDowndloadButton {
  idx: number;
  questionContent: string;
}

const AnswerTab = ({ questionnaires, status }: IAnswerTab) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
  const dispatch = useDispatch();

  const answerInfoResponse = useSelector(answerInfoDataResponse);
  const downloadCSVAllQuestionsDataResponse = useSelector(
    downloadCSVAllQuestionsResponse
  );
  const downloadCSVQuestionDataResponse = useSelector(
    downloadCSVQuestionResponse
  );
  const notificationSent = useSelector(selectIsSentReminder);
  const remindNotificationError = useSelector(
    remindPushNotificationErrorMessage
  );

  const [chartsType, setChartsType] = useState<any>({});
  const [displayDropdown, setDisplayDropdown] = useState<IDdisplayDropdown>({
    visible: false,
    id: "",
  });
  const [data, setData] = useState<IData>({
    totalPatient: 0,
    totalPatientAnswered: 0,
    totalPatientNotAnswered: 0,
    patientAnswered: [],
    patientNotAnswered: [],
    questionsChart: [],
    answeredByDepartment: [],
  });
  const [questionName, setQuestionName] = useState<string>("");
  const [isVisible, setIsVisible] = useState(false);

  const questionTypes = questionnaires?.questionnaire_questions?.map(
    (item: QuestionnairesQuestion) => item.answer_type
  );
  const questionIds = questionnaires?.questionnaire_questions?.map(
    (item: QuestionnairesQuestion) => item.id
  );
  const hdrs: any = {
    class_name: t2("school-year"),
    roll_number: t2("attendance"),
    full_name: t("respondent-name"),
  };

  const returnItemType = (index: number) => {
    if (questionTypes) {
      switch (questionTypes[index]) {
        case "text":
          return questionTypes[index];
          break;
        case "multiLine":
          return questionTypes[index];
          break;
        default:
          return "bar";
      }
    }
  };

  const handleNumberPercentage = (num: number) => {
    if (!num || num === 100 || num.toString().length <= 2) {
      return `${num}.0`;
    }
    return num.toString().match(/^-?\d+(?:\.\d{0,1})?/);
  };

  const patientAnswerPercent = handleNumberPercentage(
    (data.totalPatientAnswered / data.totalPatient) * 100
  );

  const handleCondition = () => {
    const measurement = "hours";
    const now = handleGetTimeByTimeZone(
      formatDateTime,
      currentTimeZone,
      new Date()
    );
    const start = handleGetTimeByTimeZone(
      formatDateTime,
      currentTimeZone,
      questionnaires.start_time
    );
    const end = handleGetTimeByTimeZone(
      formatDateTime,
      currentTimeZone,
      questionnaires.end_time
    );

    return (
      (moment(now).diff(start, measurement) >= 0 &&
        moment(now).diff(end, measurement) < 0) ||
      (moment(now).diff(start, measurement) >= 0 && !questionnaires.end_time)
    );
  };

  const optionCharts = (ansType: string) => {
    const defaultOption = [
      {
        id: 1,
        value: "bar",
        name: (
          <WrapperIconSelect>
            <BarChartIcon fill="#3B3B3B" />
            <span>{t("bar-graph")}</span>
          </WrapperIconSelect>
        ),
      },
      {
        id: 2,
        value: "line",
        name: (
          <WrapperIconSelect>
            <LineChartIcon fill="#3B3B3B" />
            <span>{t("line-graph")}</span>
          </WrapperIconSelect>
        ),
      },
    ];
    if (ansType === "radio") {
      defaultOption.unshift({
        id: 0,
        value: "pie",
        name: (
          <WrapperIconSelect>
            <PieChartIcon />
            <span>{t("pie-chart")}</span>
          </WrapperIconSelect>
        ),
      });
    }
    return defaultOption;
  };

  useEffect(() => {
    setDisplayDropdown({
      visible: false,
      id: "",
    });
  }, []);

  const handleGenerateRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  const otherMultipleLanguageList = ["other", "その他"];

  function checkNumber(x: any) {
    if (!(typeof x == "number") && isNaN(x)) {
      return 0;
    }
    if (Number.isInteger(x)) {
      return Number(x);
    }

    return Number(Number.parseFloat(x).toFixed(0));
  }

  useEffect(() => {
    if (
      downloadCSVAllQuestionsDataResponse &&
      downloadCSVAllQuestionsDataResponse.result
    ) {
      if (questionnaires.title) {
        generateCSV(
          `${convertFilename(questionnaires.title, new Date())}.csv`,
          downloadCSVAllQuestionsDataResponse?.result?.header?.map(
            (item: string, index: any) => ({
              label: hdrs[item] || item,
              key: index,
            })
          ),
          downloadCSVAllQuestionsDataResponse.result.data
        );
      }

      dispatch(
        DownloadCSVAllQuestionsActionCreators.handleResetDownloadCSVQuestionAction()
      );
    }
  }, [downloadCSVAllQuestionsDataResponse]);

  useEffect(() => {
    if (
      downloadCSVQuestionDataResponse &&
      downloadCSVQuestionDataResponse.result
    ) {
      if (questionName) {
        generateCSV(
          `${convertFilename(questionName, new Date())}.csv`,
          downloadCSVQuestionDataResponse?.result?.header?.map(
            (item: string, index: any) => ({
              label: hdrs[item] || item,
              key: index,
            })
          ),
          downloadCSVQuestionDataResponse.result.data
        );
      }

      dispatch(
        DownloadCSVQuestionActionCreators.handleResetDownloadCSVQuestionAction()
      );
    }
  }, [downloadCSVQuestionDataResponse]);

  useEffect(() => {
    if (answerInfoResponse && Object.keys(answerInfoResponse.result).length) {
      const {
        total_patient,
        total_patient_answered,
        total_patient_not_answered,
        questions_chart,
        patients_answered,
        patients_not_answered,
        answered_by_departments,
      } = answerInfoResponse.result;

      let typeOfCharts: any = {};

      const dataCharts = questions_chart.map((question, idx) => {
        const dataChart: any = [];
        typeOfCharts = {
          ...typeOfCharts,
          [idx]: returnItemType(idx),
        };
        const colors: any = [];
        if (question.answers.length) {
          const isOther = question.answers.findIndex(
            (item: string) => item === t("question-other")
          );

          if (isOther > -1) {
            const otherName = question.answers.splice(isOther, 1)[0];
            const otherValue = question.answers_percent.splice(isOther, 1)[0];
            question.answers.push(otherName);
            question.answers_percent.push(otherValue);
          }

          question.answers.forEach((ans, idx) => {
            if (!otherMultipleLanguageList.includes(ans)) {
              colors.push(handleGenerateRandomColor());
            } else {
              colors.push("#CCCCCC");
            }
            dataChart.push({
              name: ans,
              value: checkNumber(question.answers_percent[idx]),
              percent: checkNumber(question.answers_percent[idx]),
            });
          });
        }

        return {
          question_content: question.question_content,
          question_position: question.question_position,
          answer_text: question.answers_text,
          answer_type: question.answer_type,
          dataChart,
          colors,
        };
      });

      setChartsType(typeOfCharts);
      setData({
        totalPatient: total_patient,
        totalPatientAnswered: total_patient_answered,
        totalPatientNotAnswered: total_patient_not_answered,
        questionsChart: dataCharts,
        patientAnswered: patients_answered,
        patientNotAnswered: patients_not_answered,
        answeredByDepartment: answered_by_departments,
      });
    }
  }, [answerInfoResponse]);

  useEffect(() => {
    if (status === "create") {
      setData({
        totalPatient: 0,
        totalPatientAnswered: 0,
        totalPatientNotAnswered: 0,
        patientAnswered: [],
        patientNotAnswered: [],
        questionsChart: [],
        answeredByDepartment: [],
      });
    }
  }, [status]);

  useEffect(() => {
    if (notificationSent) {
      notification.success({
        placement: "bottomRight",
        message: t("reminder-sent-non-answered"),
        icon: <SendCompleteIcon />,
      });
      dispatch(RemindPushNotificationActionCreators.handleResetAction());
      setIsVisible(false);
    }
  }, [notificationSent]);

  useEffect(() => {
    if (remindNotificationError) {
      notification.error({
        placement: "bottomRight",
        message: remindNotificationError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(RemindPushNotificationActionCreators.handleResetAction());
    }
  }, [remindNotificationError]);

  const DropDown = ({ data }: any) => {
    return (
      <WrapperDropdown>
        {data.map((item: any, index: any) => (
          <div
            key={index}
            style={{ display: "flex", justifyContent: "space-between", gap: 8 }}
          >
            <p>{item.name}</p>
            <p>
              {item.count_answered}/{item.total_patient_per_derpartment}
            </p>
          </div>
        ))}
      </WrapperDropdown>
    );
  };

  const handleOnChangeChart = (questionPosition: number) => (value: string) => {
    setChartsType((prevState: any) => ({
      ...prevState,
      [questionPosition]: value,
    }));
  };

  const PieChartItem = ({ dataChart, colors }: any) => {
    return (
      <WrapperPieChart>
        <div>
          <PieChartWithCustomizedLabel data={dataChart} colors={colors} />
        </div>
        <div className="pie-chart-right">
          {dataChart.map((item: any, idx: number) => {
            return (
              <div className="p-green" key={idx}>
                <span
                  className="icon-circle"
                  style={{ background: colors[idx] }}
                ></span>
                <p className="span-text">
                  {item.name} {item.percent}%
                </p>
              </div>
            );
          })}
        </div>
      </WrapperPieChart>
    );
  };

  const QuestionItem = React.forwardRef(
    (
      {
        type,
        dataChart,
        answerText,
        colors,
      }: {
        type: string;
        dataChart: IDataChart[];
        answerText: string[];
        colors: string[];
      },
      ref
    ) => {
      const chartData = dataChart.map((item: IDataChart) => ({
        name: item.name,
        value: item.value,
        percent: item.percent,
      }));

      const chartList: any = {
        line: <LineChart data={chartData} color="#2AC769" unit="%" />,
        pie: <PieChartItem dataChart={chartData} colors={colors} />,
        bar: <BarChart data={chartData} colors={colors} unit="%" />,
      };

      return (
        <WrapperContentChart>
          <div
            ref={ref as any}
            style={
              answerText.length > 0
                ? { width: "calc(100% - 168px)" }
                : { width: "100%" }
            }
          >
            {chartList[type]}
          </div>
          {answerText.length > 0 && (
            <WrapperOtherBreakdown>
              <p className="title-chart-gragh">{t("other-breakdown")}</p>
              <div className="content-chart-gragh">
                {answerText.map((ans: any, idx) => (
                  <div key={idx}>{ans}</div>
                ))}
              </div>
            </WrapperOtherBreakdown>
          )}
        </WrapperContentChart>
      );
    }
  );

  QuestionItem.displayName = "QuestionItem";

  const DownloadCSVButton = ({ idx, questionContent }: IDowndloadButton) => (
    <Button
      icon={
        <DownloadIcon
          width="14px"
          height="14px"
          fill="currentColor"
          style={{ position: "absolute", left: 4, top: 4 }}
        />
      }
      name={t1("download-csv")}
      background="#2AC769"
      color="#FFFFFF"
      border="none"
      fontSize={12}
      lineHeight="17px"
      fontWeight={700}
      padding="3px 8px 3px 14px"
      width="77px"
      onClick={() => {
        dispatch(
          DownloadCSVQuestionActionCreators.downloadCSVQuestionAction({
            id: questionIds && questionIds[idx],
          })
        );
        setQuestionName(questionContent);
      }}
    />
  );

  const Question = ({
    question_position,
    question_content,
    dataChart,
    answer_text,
    answer_type,
    colors,
    index,
  }: IQuestionChart) => {
    const imgRef = useRef<any>();

    const handleAddPage = (arr: string[], doc: any) => {
      doc.addPage("p");

      let nextPage = false;
      let stop = false;
      let y = 0;
      const height = doc.internal.pageSize.getHeight();

      arr.forEach((text: string, idx: number) => {
        if (y <= height) {
          y += 20;
          doc.setFontSize(12);
          doc.setTextColor(59, 59, 59);
          doc.text(`・${text}`, 12, y);
        } else {
          if (!stop) {
            arr.splice(0, idx);
          }
          nextPage = true;
          stop = true;
        }
      });

      if (arr.length && nextPage) handleAddPage(arr, doc);
    };

    const handleShowAnswerText = (aswText: string[], doc: any) => {
      const arr = [...aswText];
      const x = 12;
      const height = doc.internal.pageSize.getHeight();

      doc.setFontSize(12);
      doc.setTextColor(59, 59, 59);
      doc.text(t("other-breakdown"), x, 300);

      let y = 300;
      let nextPage = false;
      let stop = false;

      arr.forEach((text: string, idx: number) => {
        if (y <= height) {
          y += 20;
          doc.setFontSize(12);
          doc.setTextColor(59, 59, 59);
          doc.text(`・${text}`, x, y);
        } else {
          if (!stop) {
            arr.splice(0, idx);
          }
          nextPage = true;
          stop = true;
        }
      });

      if (arr.length && nextPage) handleAddPage(arr, doc);
    };

    return (
      <WrapperQuestion>
        <QuestionAnswerTitle>
          <div>{t("question") + question_position}</div>
          <DownloadIcon
            width="18px"
            height="18px"
            fill="#FFFFFF"
            onClick={async () => {
              const doc = new jsPDF("p", "px", "a4");
              const width = doc.internal.pageSize.getWidth();
              const pageMargin = 12;
              const x = 12;
              const y = 17;
              const heightChart: any = {
                pie: 200,
                bar: 220,
                line: 180,
              };

              const canvas = await html2canvas(imgRef.current);
              const data = canvas.toDataURL("image/png", 0.5);

              doc.addFileToVFS("BIZUDGothic-Regular-normal.ttf", udgothicFont);
              doc.addFont(
                "BIZUDGothic-Regular-normal.ttf",
                "BIZUDGothic-Regular",
                "normal"
              );
              doc.setFont("BIZUDGothic-Regular", "normal");

              doc.setFontSize(14);
              doc.setFillColor(47, 140, 174);
              doc.rect(0, 0, width, 25, "F");
              doc.setTextColor(255, 255, 255);
              doc.text(`${t("question") + question_position}`, x, y);

              doc.setFontSize(12);
              doc.setTextColor(59, 59, 59);
              doc.text(question_content, x, y + 25);

              doc.addImage(
                data,
                "png",
                pageMargin,
                50,
                width - 2 * pageMargin,
                heightChart[chartsType[index]]
              );

              if (answer_text.length) handleShowAnswerText(answer_text, doc);

              doc.save("the_questionaires");
            }}
          />
        </QuestionAnswerTitle>
        <WrapperChartContent>
          <div className="display-flex">
            <div className="title-bold">
              <div>{question_content}</div>
              {(chartsType[index] === "text" ||
                chartsType[index] === "multiLine") && (
                <DownloadCSVButton
                  idx={index}
                  questionContent={question_content}
                />
              )}
            </div>
            {chartsType[index] !== "text" &&
              chartsType[index] !== "multiLine" && (
                <div className="select-chart">
                  <DownloadCSVButton
                    idx={index}
                    questionContent={question_content}
                  />
                  <Select
                    value={chartsType[index]}
                    onChange={handleOnChangeChart(index)}
                    options={optionCharts(answer_type)}
                    width="124px"
                    defaultValue="line"
                    height={"31px"}
                  />
                </div>
              )}
          </div>

          {chartsType[index] !== "text" && chartsType[index] !== "multiLine" ? (
            <QuestionItem
              ref={imgRef}
              type={chartsType[index]}
              dataChart={dataChart}
              answerText={answer_text}
              colors={colors}
            />
          ) : (
            <AnswersWrapper>
              {answer_text.length ? (
                answer_text.map((answer: string, index) => (
                  <div key={index} className="answer">
                    {answer}
                  </div>
                ))
              ) : (
                <div className="answer"></div>
              )}
            </AnswersWrapper>
          )}
        </WrapperChartContent>
      </WrapperQuestion>
    );
  };

  return (
    <React.Fragment>
      <WrapperAnswer>
        <div
          style={{
            display: "flex",
            gap: 16,
          }}
        >
          {data.totalPatientAnswered}
          {t("person-answered")}
          <Button
            icon={
              <DownloadIcon
                width="14px"
                height="14px"
                fill="currentColor"
                style={{ position: "absolute", left: 4, top: 4 }}
              />
            }
            name={t1("download-csv")}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={12}
            lineHeight="17px"
            fontWeight={700}
            padding="3px 8px 3px 14px"
            onClick={() =>
              dispatch(
                DownloadCSVAllQuestionsActionCreators.downloadCSVAllQuestionsAction(
                  {
                    id: questionnaires.id,
                  }
                )
              )
            }
          />
        </div>
        <WrapperCollapse>
          <Collapse
            header={t("answer-status")}
            expandIconPosition="right"
            defaultActiveKey={["1"]}
          >
            <WrapperSurvey>
              <WrapperSurveyChart>
                <p>{t("overall-survey")}</p>
                {status === "create" ? (
                  <NoDataIcon />
                ) : (
                  <>
                    <PieChart
                      data={[
                        {
                          value: Number(patientAnswerPercent),
                        },
                        {
                          value: 100 - Number(patientAnswerPercent),
                        },
                      ]}
                      colors={COLORS}
                      textCenter={Number(patientAnswerPercent)}
                      colorTextCenter={colorTextCenter}
                      textSmall="%"
                    />
                    <p>
                      <span>
                        {t("answer")}：{data.totalPatientAnswered}
                      </span>{" "}
                      / {t("not-answered")}
                      {data.totalPatient}
                    </p>
                  </>
                )}
              </WrapperSurveyChart>

              <WrapperTag>
                {data.answeredByDepartment.map((answer) => (
                  <TagStyled key={answer.parent_id}>
                    <span>{answer.parent_name}</span>
                    <span>
                      {answer.total_answered}/{answer.total_patient}
                    </span>
                    <MoreIcon
                      fill="#3B3B3B"
                      onClick={() =>
                        setDisplayDropdown({
                          visible: !displayDropdown.visible,
                          id: answer.parent_id,
                        })
                      }
                    />
                    {displayDropdown.visible &&
                      displayDropdown.id === answer.parent_id && (
                        <DropDown data={answer.departments} />
                      )}
                  </TagStyled>
                ))}
              </WrapperTag>
            </WrapperSurvey>

            <WrapperAnswerList>
              <div className="answer-wrapper">
                <p>
                  {data.totalPatientNotAnswered}
                  {t("person-not-answered")}
                </p>
                <Button
                  background="#2AC769"
                  color="#fff"
                  padding="8px"
                  name={t("send-reminder")}
                  border="none"
                  bdr="6px"
                  fontSize={14}
                  fontWeight={700}
                  lineHeight="14px"
                  disabled={!data.totalPatientNotAnswered || !handleCondition()}
                  onClick={() => setIsVisible(true)}
                />
              </div>
              {data.totalPatientNotAnswered > 0 && (
                <div>
                  {data.patientNotAnswered.map((patient) => (
                    <div key={patient.patient_id}>{patient.full_name}</div>
                  ))}
                </div>
              )}
              {data.totalPatientAnswered > 0 && (
                <>
                  <p>{t("list-of-respondents")}</p>
                  <div>
                    {data.patientAnswered.map((patient) => (
                      <div key={patient.patient_id}>{patient.full_name}</div>
                    ))}
                  </div>
                </>
              )}
            </WrapperAnswerList>
          </Collapse>
        </WrapperCollapse>
      </WrapperAnswer>

      {data.questionsChart
        .sort(
          (quest1, quest2) =>
            quest1.question_position - quest2.question_position
        )
        .map((question, idx: any) => {
          return (
            <Question
              key={question.question_position}
              {...question}
              index={idx}
            />
          );
        })}

      {isVisible && (
        <ConfirmModal
          background="#2AC769"
          isVisible={isVisible}
          close={() => {
            setIsVisible(false);
          }}
          ok={() => {
            dispatch(
              RemindPushNotificationActionCreators.remindPushNotificationAction(
                {
                  id: questionnaires?.id,
                }
              )
            );
          }}
        >
          <TextConfirm>
            {t("send-reminder-who-not-done")}
            <br></br>
            {t2("are-you-sure")}
          </TextConfirm>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default AnswerTab;
