import { RootState } from "../../rootReducer";

export const selectIsUpdatingSchedule = (state: RootState) =>
  state.updateSchedule.loading;
export const selectIsUpdateSaved = (state: RootState) =>
  state.updateSchedule.saved;
export const selectUpdateErrorMessage = (state: RootState) =>
  state.updateSchedule.error;
export const updateScheduleData = (state: RootState) =>
  state.updateSchedule.updateScheduleResponse;
