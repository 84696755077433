import { IResponse } from "models";

export enum ActionType {
  BODY_TEMPERATURE_FILE_DATA = "action/BODY_TEMPERATURE_FILE_DATA",
  BODY_TEMPERATURE_FILE_DATA_SUCCESS = "action/BODY_TEMPERATURE_FILE_DATA_SUCCESS",
  BODY_TEMPERATURE_FILE_DATA_ERROR = "action/BODY_TEMPERATURE_FILE_DATA_ERROR",
  BODY_TEMPERATURE_FILE_DATA_RESET = "action/BODY_TEMPERATURE_FILE_DATA_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface BodyTemperatureFileResponse extends IResponse {
  answers: any;
}
