import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ICancelQuestionnairePayload,
  ActionType,
  ICancelQuestionnaireResponse,
} from "models/questionnaires/cancelQuestionnaire";
import { httpStatus } from "configs/httpStatus";
import { cancelQuestionnaire } from "services/questionnaires";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./cancelQuestionnaireActions";

function* cancelQuestionnaireSaga({
  payload,
}: {
  payload: ICancelQuestionnairePayload;
}) {
  try {
    const response: ICancelQuestionnaireResponse = yield call(
      cancelQuestionnaire,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onCancelQuestionnaireSagaSubmitWatcher() {
  yield takeLatest(
    ActionType.CANCEL_QUESTIONNAIRE as any,
    cancelQuestionnaireSaga
  );
}

export default [fork(onCancelQuestionnaireSagaSubmitWatcher)];
