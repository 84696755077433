import React from "react";

import { downloadFunc, fileTypeIcon, ResourceType } from "./ResourcesDownload";
import {
  GalleryItems,
  TextLink,
  CheckboxStyled,
  GalleryContent,
  GalleryCaption,
} from "./ResourcesDownload.style";

type GalleryViewProps = {
  resources: ResourceType[];
  selectedResources: ResourceType[];
  onChangeSelected: (_: React.Key[], selectedResources: ResourceType[]) => void;
};

const GalleryView: React.FC<GalleryViewProps> = ({
  resources,
  selectedResources,
  onChangeSelected,
}) => {
  const galleryItem = (resource: ResourceType) => {
    return (
      <GalleryContent
        style={{
          backgroundImage: `url(https://drive.google.com/thumbnail?sz=w485-h700&id=${resource.id})`,
        }}
      >
        <CheckboxStyled
          style={{ position: "absolute", top: 5, left: 5 }}
          id="check"
          checked={
            selectedResources.filter((it) => it.id === resource.id).length > 0
          }
          onChange={(e) => {
            if (e.target.checked) {
              onChangeSelected([], [...selectedResources, resource]);
            } else {
              onChangeSelected(
                [],
                selectedResources.filter((val) => val.key !== resource.key)
              );
            }
          }}
        />
        <GalleryCaption>
          {fileTypeIcon(resource.mimeType)}
          <TextLink
            style={{ width: "188px" }}
            onClick={() => {
              downloadFunc(resource.downloadLink);
            }}
          >
            {resource.name}
          </TextLink>
        </GalleryCaption>
      </GalleryContent>
    );
  };

  return (
    <>
      <GalleryItems>
        {resources.map((resource) => galleryItem(resource))}
      </GalleryItems>
    </>
  );
};
export default GalleryView;
