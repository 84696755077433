import React, { useEffect, useState, useRef } from "react";
import { Tooltip, Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { Box } from "components";
import {
  CommentIcon,
  TemperatureBlueIcon,
  TemperatureYellowIcon,
  TemperatureRedIcon,
} from "icons";
import { convertDate, localStorageHelper } from "utils";
import { IOption } from "components/select/Select";
import { useGetAttendanceOptions } from "shared-hooks";

import { classListFilterDataResponse } from "redux/messagemanagement/classfilterlist/classFilterListStates";
import { getStudentListSuccessResponse } from "redux/messagemanagement/getstudentlist/getStudentListStates";
import { searchConditionDataResponse } from "redux/messagemanagement/searchCondition/searchConditionStates";
import { temperatureListFilterDataResponse } from "redux/messagemanagement/temperaturefilterlist/temperatureFilterListStates";
// import { attendanceFilterListResponse } from "redux/messagemanagement/attendancefilterlist/attendanceFilterListStates";
import {
  FilterStudentData,
  GetStudentList,
} from "models/messagemanagement/getStudentList";
import {
  GetDataStatisticsActionCreators,
  GetStudentActionCreators,
  SearchConditionActionCreators,
} from "redux/rootActions";

// import {
//   optionFamilyIllness,
//   optionPool,
//   optionRegistrationTime,
//   optionsMarathon,
//   optionSymptoms,
// } from "./constants";
import {
  SearchConditionModalStyled,
  Wrapper,
  WrapperFields,
} from "./MessageDelivery.style";
import StudentList from "./StudentList";
import SearchCondition from "./SearchCondition";
import SendMessageModal from "./SendMessageModal";
import { BodyTemperatureData } from "models/bodyTemperature/statistics";
import { AllDataForFilters } from "screens/bodyTemperature/constants";
import { dataStatisticsResponse } from "redux/bodyTemperature/statistics/statisticsStates";
const { useBreakpoint } = Grid;

export type FormStatus = "sendMessage" | "searchCondition" | "";
const format = "YYYY-MM-DD";

function MessageDelivery() {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const classFilterlistdata = useSelector(classListFilterDataResponse);
  // const studentListDataResponse = useSelector(getStudentListSuccessResponse);
  const searchConditionDataApi = useSelector(searchConditionDataResponse);
  const temperatureFilterlistdata = useSelector(
    temperatureListFilterDataResponse
  );
  const dataStatistics = useSelector(dataStatisticsResponse);

  // const attendanceFilterlistdata = useSelector(attendanceFilterListResponse);

  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const isJapanLanguage = currentLanguage === "ja";
  const { attendanceOptions } = useGetAttendanceOptions();

  const scrollAbleRef = useRef<any>(undefined);
  const [isVisible, setIsVisible] = useState(false);
  const [formStatus, setFormStatus] = useState<FormStatus>();
  const [selectedRows, setSelectedRows] = useState<GetStudentList[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [allUser, setAllUser] = useState<any[]>([]);
  const [currentPageData, setCurrentPageData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isSelectCurrentPage, setIsSelectCurrentPage] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSendAll, setIsSendAll] = useState(false);
  const { measurementTimeForFilter, familyIllnessForFilter } =
    AllDataForFilters();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [filters, setFilters] = useState<any>({
    page: 1,
    per_page: 50,
    app_language_id: currentLanguage,
    aggregate_date: moment(new Date()).format("YYYY/MM/DD"),
  });
  const [filterActions, setFilterActions] = useState<any>({
    page: 1,
    per_page: 50,
    app_language_id: currentLanguage,
    aggregate_date: moment(new Date()).format("YYYY/MM/DD"),
  });
  const [datas, setDatas] = useState<any>({
    pools: [],
    attendances: [],
    symptoms: [],
    bodyTemperatures: [],
    times: [],
    familyIllness: [],
    marathons: [],
    activities: [],
    grades: [],
  });

  const handleSendToAllUser = (value: boolean) => {
    setIsSendAll(value);
  };

  const showModal = (formStatus: FormStatus) => {
    setFormStatus(formStatus);
    setIsVisible(true);
  };

  const handleCancel = () => {
    setFormStatus("");
    setIsVisible(false);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setIsSelectAll(false);
    setIsSendAll(false);
    setIsSelectCurrentPage(false);
  };

  const removeEmptyValues = (object: any) => {
    const keys = Object.keys(object);
    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];
      const value = object[key];
      if (value === null || value === undefined || value === "") {
        delete object[key];
      }
    }
  };

  const renderTemperature = (temperature: any) => {
    return temperature.toFixed(1);
  };

  const initialColumns = [
    {
      title: t1("full-name"),
      dataIndex: "full_name",
      key: "full_name",
      fixed: window.innerWidth < 576 ? "" : "left",
      width: 150,
      render: (text: string, record: any) => {
        return (
          <WrapperFields>
            <div
              onClick={() => {
                showModal("sendMessage");
                setSelectedRows([
                  {
                    id: record.patient_id,
                    name: record.full_name,
                  },
                ] as any);
                setSelectedRowKeys([record.patient_id]);
              }}
            >
              <CommentIcon fill="#B7B7B7" />
            </div>
            <div style={{ marginLeft: 12 }}>{text}</div>
          </WrapperFields>
        );
      },
    },
    {
      title: t1("grade"),
      dataIndex: "department_name",
      key: "department_name",
      width: 98,
      render: (text: any, record: any) => {
        return (
          <div>
            {record.parent_department_name}
            {text}
          </div>
        );
      },
    },
    {
      title: t1("attendance"),
      dataIndex: "roll_number",
      key: "roll_number",
      width: isJapanLanguage ? 82 : 130,
      render: (text: string) => {
        if (!text) {
          return (
            <div
              style={{
                overflow: "hidden",
                width: 65,
              }}
            >
              -
            </div>
          );
        }
        return (
          <div
            style={{
              overflow: "hidden",
              width: 65,
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: t1("activities"),
      dataIndex: "sub_departments",
      key: "sub_departments",
      render: (text: any) => {
        if (!text) return;
        return (
          <div>
            {text
              .map((sd: any) => {
                return sd.name;
              })
              .join(",")}
          </div>
        );
      },
    },
    {
      title: t("body-temperature"),
      dataIndex: "temperature",
      key: "temperature",
      width: isJapanLanguage ? 100 : 140,
      render: (text: any, record: any) => {
        if (!record.temperature) {
          return <div>-</div>;
        }
        const temperature = Number(record.temperature);
        if (temperature >= 37.5) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <TemperatureRedIcon />
              <div>
                <span
                  style={{ marginLeft: 5, color: "#FB4E4E", fontWeight: 700 }}
                >
                  {renderTemperature(temperature)}°C
                </span>
              </div>
            </div>
          );
        }
        if (temperature >= 37 && temperature < 37.5) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <TemperatureYellowIcon />
              <div>
                <span
                  style={{ marginLeft: 5, color: "#E89806", fontWeight: 700 }}
                >
                  {renderTemperature(temperature)}°C
                </span>
              </div>
            </div>
          );
        }
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <TemperatureBlueIcon />
            <div>
              <span style={{ marginLeft: 5 }}>
                {renderTemperature(temperature)}°C
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  const expandColumns: any = {
    symptoms: {
      title: t("symptoms"),
      dataIndex: "symptoms",
      key: "symptoms",
      width: isJapanLanguage ? 100 : 130,
    },
    attendance: {
      title: t("attendance-status"),
      dataIndex: "attendance",
      key: "attendance",
      width: 150,

      render: (text: any) => {
        if (text === "sick") {
          return (
            <WrapperFields>
              <div style={{ fontSize: 16, color: "#FB4E4E", marginRight: 3 }}>
                {text}
              </div>
              <Tooltip
                placement="bottomRight"
                title="病院に行くのでお休みします"
              >
                <span>
                  <CommentIcon fill="#2F8CAE" />
                </span>
              </Tooltip>
            </WrapperFields>
          );
        }
        return <div>{text}</div>;
      },
    },
    time: {
      title: t("registration-time"),
      dataIndex: "time",
      key: "time",
      width: isJapanLanguage ? 80 : 140,
    },
    family_illness: {
      title: () => {
        return (
          <div>
            {t("family-of")}
            <br /> {t("illness")}
          </div>
        );
      },
      dataIndex: "family_illness",
      key: "family_illness",
      width: 106,
    },
    pool_answer: {
      title: t("pool"),
      dataIndex: "pool_answer",
      key: "pool_answer",
      width: 130,
    },
    marathon: {
      title: t("marathon"),
      dataIndex: "marathon",
      key: "marathon",
      width: 130,
    },
  };

  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    setCurrentPageData([]);
    dispatch(GetDataStatisticsActionCreators.handleResetAction());
    dispatch(GetStudentActionCreators.handleResetDataAction());
    dispatch(SearchConditionActionCreators.searchConditionAction());
  }, []);

  useEffect(() => {
    if (!dataStatistics) {
      setCurrentPageData([]);
      setAllUser([]);
      setTotal(0);
    }
    if (dataStatistics && Object.keys(dataStatistics).length > 0) {
      setAllUser(
        dataStatistics.all_patients.map((i: any) => {
          return {
            id: i.id,
            name: i.full_name,
          };
        })
      );
      setCurrentPageData(
        dataStatistics.answers.map((i: any) => {
          return {
            ...i,
            key: i.patient_id,
          };
        })
      );
      setTotal(dataStatistics.total_number);
    }
  }, [dataStatistics]);

  const handleSelectCurrentPage = () => {
    const rowKeys = currentPageData.map((item: any) => {
      return item.patient_id;
    });
    const rows = currentPageData.map((item: any) => {
      return {
        id: item.patient_id,
        name: item.full_name,
      };
    });
    setSelectedRows(rows as any);
    setSelectedRowKeys(rowKeys);
    setIsSelectCurrentPage(true);
  };

  const onSelectAllRecords = (selected: boolean) => {
    if (selected) {
      const rowKeys = allUser.map((item: any) => {
        return Number(item.id);
      });
      setSelectedRowKeys(rowKeys);
      setSelectedRows(allUser as any);
      setIsSelectAll(true);
    } else {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setIsSelectCurrentPage(false);
    }
  };

  const handleDeselectAllRecords = () => {
    setSelectedRowKeys([]);
    setIsSelectCurrentPage(false);
    setIsSelectAll(false);
  };

  const onSelectRecord = (record: any, selected: any) => {
    let selectedRowsClone: any = [...selectedRows];
    let selectedRowKeysClone = [...selectedRowKeys];

    if (selected) {
      selectedRowsClone.push({ id: record.patient_id, name: record.full_name });
      selectedRowKeysClone.push(record.patient_id);
    }

    if (!selected && selectedRows.length) {
      selectedRowsClone = selectedRows.filter(
        (row: any) => row.id !== record.patient_id
      );
      selectedRowKeysClone = selectedRowKeys.filter(
        (key: any) => key !== record.patient_id
      );
    }
    if (selectedRowsClone.length === allUser.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
    setSelectedRows(selectedRowsClone);
    setSelectedRowKeys(selectedRowKeysClone);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onSelectAll: onSelectAllRecords,
    onSelect: onSelectRecord,
  };

  const onPageChange = (page: number) => {
    setFilterActions((prevFilter: any) => ({
      ...prevFilter,
      page,
    }));
    const params: BodyTemperatureData = {
      ...filterActions,
      page,
    };
    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(params));
    if (
      scrollAbleRef.current &&
      window.scrollY >= scrollAbleRef.current.offsetTop
    ) {
      window.scrollTo(0, 0);
    }
  };

  const onPerPageItemChange = (per_page: number) => {
    setFilterActions((prevFilter: any) => ({
      ...prevFilter,
      per_page,
      page: 1,
    }));

    const params: BodyTemperatureData = {
      ...filterActions,
      per_page,
      page: 1,
    };
    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(params));
  };

  const handleRemoveDestination = (id: number) => {
    setSelectedRows(selectedRows.filter((row: any) => row.id !== id));
  };

  const handleCheckExistedColumn = (columns: any, keyNewColumn: string) => {
    return columns.some((column: any) => {
      return column.key === keyNewColumn;
    });
  };

  const onSubmit = (data: any) => {
    const columnsOfContent: any = [];

    const isBodyTemperatureExisted = handleCheckExistedColumn(
      columns,
      "temperature"
    );

    if (
      !isBodyTemperatureExisted &&
      datas.bodyTemperatures &&
      datas.bodyTemperatures.length > 0
    ) {
      columnsOfContent.push(expandColumns["temperature"]);
    }

    const selectedColumns = Object.keys(data);

    selectedColumns.forEach((item: any) => {
      const isExisted = handleCheckExistedColumn(columns, item);
      if (!isExisted && data[item]) {
        columnsOfContent.push(expandColumns[item]);
      }
    });

    setColumns([...columns, ...columnsOfContent]);

    const class_ids =
      datas.grades.length === classFilterlistdata?.result.length
        ? ""
        : datas.grades.map((a: any) => a.id);

    const activities_ids = datas.activities
      ? datas.activities.map((a: any) => a.id)
      : "";

    const param: BodyTemperatureData = {
      ...filters,
      aggregate_date: convertDate(startDate),
      department_id: class_ids,
      sub_department_id: activities_ids,
      temperature: datas.bodyTemperatures,
      health_check: datas.symptoms,
      checked_at:
        datas?.times && datas?.times.length < measurementTimeForFilter.length
          ? datas?.times
          : "",
      attendance: datas.attendances,
      family_health:
        datas?.familyIllness &&
        datas?.familyIllness.length < familyIllnessForFilter.length
          ? datas?.familyIllness.join(",")
          : "",
      marathon: datas?.marathons,
      pool_join: datas?.pools,
    };

    removeEmptyValues(param);
    setFilters(param);
    setFilterActions(param);
    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(param));
    handleCancel();
  };

  const renderSearchCondition = () => (
    <SearchCondition
      showModal={showModal}
      onSubmit={onSubmit}
      handleSendToAllUser={handleSendToAllUser}
      datas={datas}
      setDatas={setDatas}
      bodyTemperatures={temperatureFilterlistdata?.result}
      searchConditionDataApi={searchConditionDataApi?.result}
      attendanceOptions={attendanceOptions}
    />
  );

  const renderForm = () => {
    switch (formStatus) {
      case "sendMessage":
        return (
          <SendMessageModal
            isVisible={isVisible}
            isSendAll={isSendAll}
            selectedRowKeys={selectedRowKeys}
            selectedRows={selectedRows}
            handleCancel={handleCancel}
            handleRemoveDestination={handleRemoveDestination}
          />
        );
      case "searchCondition":
        return (
          <SearchConditionModalStyled
            type="form"
            title={t("search-condition-setting")}
            visible={isVisible}
            onCancel={handleCancel}
          >
            {renderSearchCondition()}
          </SearchConditionModalStyled>
        );
      default:
        return;
    }
  };

  return (
    <Box title={t2("message-delivery-label")} padding="0px 16px 0px">
      {renderForm()}
      <Wrapper>
        {screens.xl && renderSearchCondition()}

        <StudentList
          isSelectCurrentPage={isSelectCurrentPage}
          isSelectAll={isSelectAll}
          columns={columns}
          showModal={showModal}
          rowSelection={rowSelection}
          handleSelectCurrentPage={handleSelectCurrentPage}
          handleDeselectAllRecords={handleDeselectAllRecords}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onPageChange={onPageChange}
          onPerPageItemChange={onPerPageItemChange}
          filterActions={filterActions}
          setFilterActions={setFilterActions}
          currentPageData={currentPageData}
          total={total}
          scrollAbleRef={scrollAbleRef}
        />
      </Wrapper>
    </Box>
  );
}

export default MessageDelivery;
