import {
  ActionType,
  IUpdateDeseasePayload,
} from "models/setting/updateDeseaseOutbreak";

export const updateDeseaseAction = (payload: IUpdateDeseasePayload) => {
  return {
    type: ActionType.UPDATE_DESEASE_SETTING,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_DESEASE_SETTING_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: any) => {
  return {
    type: ActionType.UPDATE_DESEASE_SETTING_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.RESET_DESEASE_SETTING,
  };
};
