import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components";
import { ModalStyled, WrapperBtnFooter } from "./UserList.style";

interface IDeleteModal {
  handleCancel?: () => void;
  handleOk?: () => void;
  isVisible?: boolean;
}

const ConfirmModal: React.FC<IDeleteModal> = ({
  handleCancel,
  handleOk,
  isVisible,
  children,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });

  return (
    <ModalStyled
      type="confirm"
      footer={
        <WrapperBtnFooter>
          <Button
            onClick={handleCancel}
            background="#E0E0E0"
            color="#fff"
            name={t("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />

          <Button
            onClick={handleOk}
            background="#FB4E4E"
            color="#fff"
            name={t1("yes")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />
        </WrapperBtnFooter>
      }
      visible={isVisible}
    >
      {children}
    </ModalStyled>
  );
};

export default ConfirmModal;
