import * as React from "react";

function LoadingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width || 118}
      height={props.height || 118}
      viewBox="0 0 118 118"
      xmlns="http://www.w3.org/2000/svg"
      stroke="transparent"
      {...props}
    >
      <circle opacity="0.2" cx="59" cy="11" r="11" fill="#83BCE2" />
      <circle opacity="0.6" cx="59" cy="107" r="11" fill="#83BCE2" />
      <circle
        opacity="0.4"
        cx="107"
        cy="59"
        r="11"
        transform="rotate(90 107 59)"
        fill="#83BCE2"
      />
      <circle
        opacity="0.8"
        cx="11"
        cy="59"
        r="11"
        transform="rotate(90 11 59)"
        fill="#83BCE2"
      />
      <circle
        opacity="0.3"
        cx="92.9414"
        cy="25.0588"
        r="11"
        transform="rotate(45 92.9414 25.0588)"
        fill="#83BCE2"
      />
      <circle
        opacity="0.7"
        cx="25.0586"
        cy="92.9411"
        r="11"
        transform="rotate(45 25.0586 92.9411)"
        fill="#83BCE2"
      />
      <circle
        opacity="0.5"
        cx="92.9407"
        cy="92.9409"
        r="11"
        transform="rotate(135 92.9407 92.9409)"
        fill="#83BCE2"
      />
      <circle
        cx="25.0589"
        cy="25.0591"
        r="11"
        transform="rotate(135 25.0589 25.0591)"
        fill="#83BCE2"
      />
    </svg>
  );
}

export default LoadingIcon;
