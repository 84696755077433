import * as React from "react";

function DotIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.8 7.92C0.8 11.904 4.016 15.12 8 15.12C11.984 15.12 15.2 11.904 15.2 7.92C15.2 3.936 11.984 0.719999 8 0.719999C4.016 0.719999 0.8 3.936 0.8 7.92Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}

export default DotIcon;
