import { IResponse } from "models";

export enum ActionType {
  VACCINE_UPDATE_VENUES = "action/VACCINE_UPDATE_VENUES",
  VACCINE_UPDATE_VENUES_SUCCESS = "action/VACCINE_UPDATE_VENUES_SUCCESS",
  VACCINE_UPDATE_VENUES_ERROR = "action/VACCINE_UPDATE_VENUES_ERROR",
  VACCINE_UPDATE_VENUES_RESET = "action/VACCINE_UPDATE_VENUES_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateVenuesResponse extends IResponse {
  result: any;
}
