import {
  Action,
  ActionType,
  IMentalCheckScoringResponse,
} from "models/mentalHealth/scoringInformation";

import createReducer from "redux/createReducer";

export interface ScoringInformationReducerType {
  loading: boolean;
  error?: any;
  scoringInformationResponse?: IMentalCheckScoringResponse;
}

const defaultState: ScoringInformationReducerType = {
  loading: false,
  error: null,
  scoringInformationResponse: undefined,
};

export const scoringInformationReducer =
  createReducer<ScoringInformationReducerType>(defaultState, {
    [ActionType.MENTAL_CHECK_SCORING](state: ScoringInformationReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MENTAL_CHECK_SCORING_ERROR](
      state: ScoringInformationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.MENTAL_CHECK_SCORING_SUCCESS](
      state: ScoringInformationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        scoringInformationResponse: action.payload,
      };
    },

    [ActionType.MENTAL_CHECK_SCORING_RESET](
      state: ScoringInformationReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        scoringInformationResponse: undefined,
      };
    },
  });
