import { createApiCall2, MethodType, URI } from "../Api";
import { DownloadQrCodeData } from "models/downloadQrCode";

export const downloadQrCode = (payload: DownloadQrCodeData) => {
  return createApiCall2(
    MethodType.GET,
    URI.QR_CODE.GET_DOWNLOAD_QR_CODE,
    payload
  );
};
