import React, { useEffect, useState, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { Box, DateRangePicker, Select, Button, Pagination } from "components";
import { DownloadIcon, MoreIcon } from "icons";
import {
  generateDynamicRecords,
  localStorageHelper,
  convertRawDataToOptions2,
  generateCSV,
  mplusFont,
} from "utils";
import { IOption } from "components/select/Select";

import {
  VaccineRecordListActionCreators,
  GetDataGroupClassCreators,
  VaccineGraphDataActionCreators,
} from "redux/rootActions";
import { recordListDataResponse } from "redux/vaccine/records/recordsStates";
import { graphDataResponse } from "redux/vaccine/graph/graphStates";
import { dataGroupClassResponse } from "redux/bodyTemperature/groupClass/groupClassStates";

import { Fever, InoculationChart } from "./charts";
import {
  CollapseStyled,
  WrapperCharts,
  WrapperSearchCondition,
  GroupSelect,
  TableStyled,
  TopTable,
  WrapperSizeChange,
  WrapperPaginationTop,
  WapperBtn,
  GroupRightButton,
  WrapperGroupButton,
  ButtonStyled,
  SelectStyled,
  PaginationWrapper,
} from "./Vaccination.style";

const { useBreakpoint } = Grid;

const VaccinationRecord = () => {
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const recordListData = useSelector(recordListDataResponse);
  const dataGroupClass = useSelector(dataGroupClassResponse);
  const graphData = useSelector(graphDataResponse);

  const { handleSubmit, control } = useForm();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [isGroupBtnMobile, setIsGroupBtnMobile] = useState(false);
  const [vaccineRecords, setVaccineRecords] = useState<any>({
    total: 0,
    list: [],
  });
  const [filters, setFilters] = useState<any>({
    page: 1,
    limit: 25,
  });
  const [groupClassOptions, setGroupClassOptions] = useState<IOption[]>([]);
  const [manufacturerOptions, setManufacturerOptions] = useState<IOption[]>([]);
  const [chartData, setChartData] = useState<any>({
    lineChart: {},
    firstPieChart: {},
    secondPieChart: {},
    thirdPieChart: {},
  });
  const vaccineEvidenceOptions: any = [
    {
      id: 1,
      value: "",
      name: t2("all-select"),
    },
    {
      id: 2,
      value: true,
      name: t1("yes-table"),
    },
    {
      id: 3,
      value: false,
      name: t1("none-table"),
    },
  ];

  const vaccineFrequencyOptions = [
    {
      id: 1,
      value: "",
      name: t2("all-select"),
    },
    {
      id: 2,
      value: 1,
      name: t2("first-time"),
    },
    {
      id: 3,
      value: 2,
      name: t2("second-time"),
    },
    {
      id: 4,
      value: 3,
      name: t2("third-time"),
    },
  ];

  const columns = [
    {
      title: t("full-name"),
      dataIndex: "patient_name",
      key: "patient_name",
      width: 140,
    },
    {
      title: t("other-name-type"),
      dataIndex: "patient_furi_name",
      key: "",
      width: 155,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: () => {
        return (
          <div>
            {t("school-year")}・
            <br /> {t("class")}
          </div>
        );
      },
      dataIndex: "department_name",
      key: "department_name",
      width: 94,
    },
    {
      title: t2("inoculation-date-and-time"),
      dataIndex: "vaccination_date",
      key: "vaccination_date",
      width: 150,
      render: (text: string) =>
        moment(text).local().format("YYYY-MM-DD  HH:mm"),
    },
    {
      title: t2("producer"),
      dataIndex: "manufacturer",
      key: "manufacturer",
      width: 110,
      render: (text: string, record: any) => (
        <div>{record?.manufacturer?.manufacturer_name_text}</div>
      ),
    },
    {
      title: () => {
        return (
          <div>
            {t("inoculation")}
            <br /> {t("registration-form")}
          </div>
        );
      },
      dataIndex: "vaccination_evidence_existed",
      key: "vaccination_evidence_existed",
      width: 142,
      render: (text: boolean) =>
        text ? <div>{t1("yes-table")}</div> : <div>{t1("none-table")}</div>,
    },
    {
      title: t2("number-of-inoculations"),
      width: 110,
      dataIndex: "vaccination_frequency",
      key: "vaccination_frequency",
      render: (text: string) => {
        if (!text || text === "") {
          return <div>-</div>;
        }
        return (
          <div>
            {text}
            {t2("times")}
          </div>
        );
      },
    },
  ];

  const handleShowGroupBtnMobile = () => {
    setIsGroupBtnMobile(!isGroupBtnMobile);
  };

  const handleChangePage = (page: number) => {
    setFilters((prevState: any) => ({ ...prevState, page }));
  };

  const onSubmit = (data: { [x: string]: any }) => {
    const params = {
      ...filters,
      patient_c_department_id_eq: data.grade,
      manufacturer_id_eq: data.manufacturer,
      vaccination_date_gteq: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : "",
      vaccination_date_lt: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      vaccination_evidence_file_name_present: data.vaccination,
      vaccination_frequency_eq: data.number_inoculations,
    };
    setFilters(params);
    dispatch(VaccineRecordListActionCreators.recordListAction(params));
  };

  useEffect(() => {
    dispatch(VaccineRecordListActionCreators.recordListAction(filters));
  }, [filters.limit, filters.page]);

  useEffect(() => {
    let recordOptionsClone: IOption[] = [];
    recordOptionsClone = generateDynamicRecords(vaccineRecords.total, t);
    setRecordOptions([...recordOptionsClone]);
  }, []);

  useEffect(() => {
    if (dataGroupClass && Object.keys(dataGroupClass).length) {
      const dataGroupClassClone = convertRawDataToOptions2(
        dataGroupClass?.result
      );
      setGroupClassOptions([
        {
          id: 1,
          value: "",
          name: t2("all-select"),
        },
        ...dataGroupClassClone,
      ]);
    }
  }, [dataGroupClass]);

  useEffect(() => {
    const currentDate = moment(new Date()).format("YYYY/MM/DD");
    dispatch(
      GetDataGroupClassCreators.getDataGroupClassAction({
        locale: currentLanguage,
      })
    );
    dispatch(
      VaccineGraphDataActionCreators.getGraphDataAction({
        date: currentDate,
      })
    );
    dispatch(
      VaccineRecordListActionCreators.recordListAction({
        limit: 25,
        page: 1,
        date: currentDate,
      })
    );
  }, []);

  useEffect(() => {
    if (recordListData && Object.keys(recordListData?.result).length) {
      const { total, vaccination_records, manufacturers } =
        recordListData?.result;
      setVaccineRecords({
        total,
        list: vaccination_records,
      });
      const tempManufacturerList: IOption[] = [];
      manufacturers.map((item, idx) => {
        tempManufacturerList.push({
          id: idx,
          value: item?.manufacturer_id,
          name: item?.manufacturer_name_text,
        });
      });
      setManufacturerOptions([
        {
          id: -1,
          value: "",
          name: "すべて",
        },
        ...tempManufacturerList,
      ]);
    }
  }, [recordListData]);

  useEffect(() => {
    if (graphData && Object.keys(graphData?.result).length) {
      const {
        number_patient_1st_graph_percent,
        number_patient_2nd_graph_percent,
        number_patient_3rd_graph_percent,
        number_patient_1st_pie,
        number_patient_2nd_pie,
        number_patient_3rd_pie,
      } = graphData?.result;

      const colors: any = {
        1: ["#2AC769", "#E0E0E0"],
        2: ["#2A98C7", "#E0E0E0"],
        3: ["#C72AB7", "#E0E0E0"],
      };
      const [firstPieChartData, secondPieChartData, thirdPieChartData] = [
        number_patient_1st_pie,
        number_patient_2nd_pie,
        number_patient_3rd_pie,
      ].map((pie: any, idx: number) => {
        return {
          percent: [
            {
              value: pie.inoculated_percent,
            },
            {
              value: pie.not_implemented_percent,
            },
          ],
          total: pie.total,
          implemented: pie.inoculated_number,
          notImplemented: pie.not_implemented_number,
          center: {
            attempt: idx + 1,
            percentage: pie.inoculated_percent,
          },
          color: colors[idx + 1],
        };
      });

      const pastDate = moment(new Date()).subtract(7, "days");
      const lineChartData = [];
      for (let i = 0; i <= 6; i++) {
        lineChartData.push({
          date: moment(pastDate)
            .add(i + 1, "days")
            .format("M/D"),
          thirdTime: number_patient_3rd_graph_percent
            ? number_patient_3rd_graph_percent[i]
            : 0,
          secondTime: number_patient_2nd_graph_percent
            ? number_patient_2nd_graph_percent[i]
            : 0,
          firstTime: number_patient_1st_graph_percent
            ? number_patient_1st_graph_percent[i]
            : 0,
        });
      }

      setChartData({
        lineChart: {
          lineChartData,
          total: firstPieChartData.total,
          inoculated:
            firstPieChartData.implemented +
            secondPieChartData.implemented +
            thirdPieChartData.implemented,
        },
        firstPieChart: firstPieChartData,
        secondPieChart: secondPieChartData,
        thirdPieChart: thirdPieChartData,
      });
    }
  }, [graphData]);

  const handleFileData = () => {
    return vaccineRecords.list.map((item: any) => {
      return {
        full_name: item.patient_name,
        other_name: item.patient_furi_name,
        class: item.department_name,
        vaccination_date: moment(item.vaccination_date)
          .local()
          .format("YYYY-MM-DD  HH:mm"),
        manufacturer: item.manufacturer?.manufacturer_name_text,
        vaccination_evidence_existed: item.vaccination_evidence_existed
          ? t1("yes-table")
          : t1("none-table"),
        vaccination_frequency: item.vaccination_frequency + "回目",
      };
    });
  };

  const handleGenerateCSVFile = () => {
    const fileData = handleFileData();
    const currentDate = moment(new Date()).format("YYYYMMDD");
    const fileName = `vaccination records_${currentDate}.csv`;
    const headers = [
      { label: t("full-name"), key: "full_name" },
      { label: t("other-name-type"), key: "other_name" },
      { label: t("grade"), key: "class" },
      { label: t2("inoculation-date-and-time"), key: "vaccination_date" },
      { label: t2("producer"), key: "manufacturer" },
      {
        label: `${t("inoculation")}${t("registration-form")}`,
        key: "vaccination_evidence_existed",
      },
      { label: t2("number-of-inoculations"), key: "vaccination_frequency" },
    ];
    generateCSV(fileName, headers, fileData);
  };

  const handleGeneratePDFFile = () => {
    const currentDate = moment(new Date()).format("YYYYMMDD");
    const fileName = `vaccination records_${currentDate}.pdf`;
    const headers = [
      t("full-name"),
      t("other-name-type"),
      t("grade"),
      t2("inoculation-date-and-time"),
      t2("producer"),
      `${t("inoculation")} ${t("registration-form")}`,
      t2("number-of-inoculations"),
    ];
    const fileData = handleFileData();

    const data: any[] = [];
    fileData.forEach((item: any) => {
      const results = [];
      for (const key in item) {
        results.push(item[key]);
      }
      data.push(results);
    });

    const doc = new jsPDF();
    doc.addFileToVFS("mplus.ttf", mplusFont);
    doc.addFont("mplus.ttf", "mplus", "normal");
    doc.setFont("mplus", "normal");
    const myTable = {
      head: [[...headers]],
      body: data,
      headStyles: {
        font: "mplus",
      },
      bodyStyles: {
        font: "mplus",
      },
      margin: { top: 15, left: 8, right: 8 },
    };

    autoTable(doc, myTable);
    doc.save(fileName);
  };

  const GroupButton = () => (
    <>
      <ButtonStyled
        color="#FFFFFF"
        fontSize={12}
        fontWeight={700}
        lineHeight="16px"
        background="#2AC769"
        padding="2px 8px 1px 16px"
        name={t1("download-csv")}
        bdr="4px"
        border="1px solid #2AC769"
        onClick={handleGenerateCSVFile}
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="#FFFFFF"
            style={{ position: "absolute", left: 5, top: 4 }}
          />
        }
      />
      <ButtonStyled
        color="#FFFFFF"
        fontSize={12}
        fontWeight={700}
        lineHeight="16px"
        background="#2AC769"
        padding="2px 8px 1px 16px"
        name={t1("download-pdf")}
        bdr="4px"
        border="1px solid #2AC769"
        onClick={handleGeneratePDFFile}
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="#FFFFFF"
            style={{ position: "absolute", left: 5, top: 4 }}
          />
        }
      />
    </>
  );

  const ActionSection = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end", padding: 8 }}>
        <GroupRightButton style={{ display: "flex" }}>
          <GroupButton />
          <div style={{ position: "relative", height: 24 }}>
            <MoreIcon onClick={handleShowGroupBtnMobile} />
            {isGroupBtnMobile && (
              <WrapperGroupButton>
                <GroupButton />
              </WrapperGroupButton>
            )}
          </div>
        </GroupRightButton>
      </div>
    );
  };

  const RenderPagination = () => {
    return vaccineRecords.total > filters.limit ? (
      <PaginationWrapper>
        <Pagination
          current={filters.page}
          onChange={handleChangePage}
          pageSize={filters.limit}
          total={vaccineRecords.total}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </PaginationWrapper>
    ) : (
      <Fragment></Fragment>
    );
  };

  return (
    <Box title={t2("vaccination-record")} padding="18px 16px 8px">
      <CollapseStyled
        header={t2("rate-graph-title")}
        expandIconPosition="right"
        defaultActiveKey={["1"]}
      >
        <WrapperCharts>
          {Object.keys(chartData.lineChart).length > 0 && (
            <Fever data={chartData.lineChart} />
          )}
          {Object.keys(chartData.thirdPieChart).length > 0 && (
            <InoculationChart data={chartData.thirdPieChart} attempt={3} />
          )}
          {Object.keys(chartData.secondPieChart).length > 0 && (
            <InoculationChart data={chartData.secondPieChart} attempt={2} />
          )}
          {Object.keys(chartData.firstPieChart).length > 0 && (
            <InoculationChart data={chartData.firstPieChart} attempt={1} />
          )}
        </WrapperCharts>
      </CollapseStyled>
      <CollapseStyled
        header={t1("user-refined-search-condition-setting")}
        expandIconPosition="right"
        defaultActiveKey={["1"]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <WrapperSearchCondition>
            <div>
              <div>{t2("inoculation-date-and-time")}</div>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
              <GroupSelect>
                <Controller
                  control={control}
                  name="manufacturer"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <SelectStyled
                      label={t2("producer")}
                      placeholder={t("please-select")}
                      onChange={onChange}
                      error={error?.message}
                      options={manufacturerOptions}
                      height="31px"
                      width="100%"
                      fs={16}
                      fsLabel={16}
                      fwLabel={500}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="grade"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <SelectStyled
                      label={t("grade")}
                      placeholder={t("please-select")}
                      onChange={onChange}
                      error={error?.message}
                      options={groupClassOptions}
                      height="31px"
                      width="100%"
                      fs={16}
                      fsLabel={16}
                      fwLabel={500}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="vaccination"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <SelectStyled
                      label={t2("with-or-without-vaccination")}
                      placeholder={t("please-select")}
                      onChange={onChange}
                      error={error?.message}
                      options={vaccineEvidenceOptions}
                      height="31px"
                      width="100%"
                      fs={16}
                      fsLabel={16}
                      fwLabel={500}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="number_inoculations"
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <SelectStyled
                      label={t2("number-of-inoculations")}
                      placeholder={t("please-select")}
                      onChange={onChange}
                      error={error?.message}
                      options={vaccineFrequencyOptions}
                      height="31px"
                      width="100%"
                      fs={16}
                      fsLabel={16}
                      fwLabel={500}
                    />
                  )}
                />
              </GroupSelect>
            </div>
            <WapperBtn>
              <Button
                color="#FFFFFF"
                fontSize={16}
                fontWeight={700}
                lineHeight="16px"
                background="#2AC769"
                name={t("search")}
                bdr="6px"
                border="1px solid #2AC769"
              />
            </WapperBtn>
          </WrapperSearchCondition>
        </form>
      </CollapseStyled>
      <TopTable>
        <WrapperSizeChange>
          <div style={{ fontWeight: 500 }}>{t("record")}</div>
          <Select defaultValue={50} width="74px" options={recordOptions} />
        </WrapperSizeChange>
        <WrapperPaginationTop>
          <RenderPagination />
        </WrapperPaginationTop>
      </TopTable>
      <TableStyled
        dataSource={vaccineRecords.list}
        columns={columns}
        actionSection={<ActionSection />}
        scroll={vaccineRecords.list.length ? { x: "max-content", y: 710 } : {}}
      />
      <div style={{ marginTop: "16px" }}>
        <WrapperPaginationTop>
          <RenderPagination />
        </WrapperPaginationTop>
      </div>
    </Box>
  );
};

export default VaccinationRecord;
