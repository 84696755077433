import React, { useEffect, useState } from "react";
import { Grid, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Select, Box } from "components";
import { NoDataIcon, ConfirmFailIcon } from "icons";
import { localStorageHelper } from "utils";
import { IOption } from "components/select/Select";

import { IRequest } from "models/mentalHealth/mentalCheckRequests";
import {
  IMentalCheckTotalPayload,
  IMentalCheckTotal,
} from "models/mentalHealth/mentalHealthTotal";
import {
  mentalCheckRequestsActionCreators,
  GetMentalHealthTotalActionCreators,
  GetMentalClassListActionCreators,
} from "redux/rootActions";
import { mentalCheckRequestsDataResponse } from "redux/mentalHealth/mentalCheckRequests/mentalCheckRequestsStates";
import {
  selectErrorMessage,
  mentalCheckTotalResponse,
} from "redux/mentalHealth/mentalHealthTotal/mentalHealthTotalStates";
import { mentalClassListDataResponse } from "redux/mentalHealth/classList/classListStates";

import {
  WapperMentalHealthTotal,
  PieChartColorScheme,
  TotalBadge,
  PieChartWrapperTotal,
  PieChartWrapperSymptom,
  SymptomTitle,
  WrapperPieChartGroup,
  PieChartsGroupWrapper,
  PieChartWrapperThird,
  SymptomTitleThird,
  PieChartColorSchemeSecond,
  PieChartColorSchemeThird,
} from "./MentalHealth.style";
import PieChart from "./charts/PieChart";

interface IDepartment {
  id: number;
  class_name: string;
}

interface RequestOptions extends IOption {
  department_id: number;
}

const colorTextCenter = "#2A98C7";
const colorTextCenter2 = "#EBC300";
const COLORS = ["#2F8CAE", "#E0E0E0"];
const COLORS2 = ["#EBC300", "#E0E0E0"];

const { useBreakpoint } = Grid;

const MentalHealthTotal: React.FC = () => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "mental-health",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const ClassDatas = useSelector(mentalClassListDataResponse);
  const MentalCheckRequestsData = useSelector(mentalCheckRequestsDataResponse);
  const MentalCheckTotalData = useSelector(mentalCheckTotalResponse);
  const MentalCheckTotalError = useSelector(selectErrorMessage);

  const [classOptions, setClassOptions] = useState<IOption[]>([]);
  const [requestOptions, setRequestOptions] = useState<RequestOptions[]>([]);
  const [selectValue, setSelectValue] = useState<any>();
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [mentalCheckTotal, setMentalCheckTotal] = useState<IMentalCheckTotal>({
    developmental_characteristics_pai_chart: {
      behavioral_problems: {
        total_precentage: 0,
        total_request: 0,
        total_response: 0,
      },
      concentration_problem: {
        total_precentage: 0,
        total_request: 0,
        total_response: 0,
      },
      relationship_problems: {
        total_precentage: 0,
        total_request: 0,
        total_response: 0,
      },
    },
    feelings_and_moods_pie_chart: {
      total_precentage: 0,
      total_request: 0,
      total_response: 0,
    },
    response_status: {
      total_request_sent: 0,
      total_resonse_recieved: 0,
    },
  });

  const returnTotalRate = () => {
    if (
      mentalCheckTotal.response_status?.total_resonse_recieved &&
      mentalCheckTotal.response_status?.total_request_sent
    ) {
      const rate =
        mentalCheckTotal.response_status?.total_resonse_recieved /
        mentalCheckTotal.response_status?.total_request_sent;
      if (rate === 0 || rate === 1) {
        return Number(rate * 100);
      }
      return Number(rate * 100).toFixed(2);
    } else return Number(0);
  };

  const onChangeSelect = (value: any) => {
    setSelectValue(value);
  };

  const onChangeSelectRequest = (value: any) => {
    setSelectedRequest(value);
  };

  const getMentalCheckTotalInfo = () => {
    const paramsMentalCheckTotal: IMentalCheckTotalPayload = {
      mental_check_request_id: selectedRequest,
      child_grade_id: selectValue || "",
    };
    dispatch(
      GetMentalHealthTotalActionCreators.getMentalCheckTotalAction(
        paramsMentalCheckTotal
      )
    );
  };

  useEffect(() => {
    if (ClassDatas) {
      const tempGrade: IOption[] = [{ id: -1, value: "", name: t1("all") }];
      ClassDatas?.result?.map((item: IDepartment) =>
        tempGrade.push({
          id: item.id,
          name: item.class_name,
          value: item.id,
        })
      );
      setClassOptions(tempGrade);
    }
  }, [ClassDatas]);

  useEffect(() => {
    if (MentalCheckRequestsData) {
      const tempRequests: RequestOptions[] = [];
      MentalCheckRequestsData?.result?.map((item: IRequest) =>
        tempRequests.push({
          id: item.mental_check_request_id,
          name: item.mental_check_request_name,
          value: item.mental_check_request_id,
          department_id: item.c_department_id,
        })
      );
      setRequestOptions(tempRequests);
    }
  }, [MentalCheckRequestsData]);

  useEffect(() => {
    if (selectedRequest) {
      getMentalCheckTotalInfo();
    }
  }, [selectedRequest, selectValue]);

  useEffect(() => {
    if (selectedRequest) {
      setSelectValue(undefined);
      const department_id = requestOptions.find(
        (item: any) => item.id === selectedRequest
      )?.department_id;
      dispatch(
        GetMentalClassListActionCreators.classListAction({
          parent_class_id: department_id,
        })
      );
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (MentalCheckTotalData) {
      setMentalCheckTotal(MentalCheckTotalData?.result);
    }
  }, [MentalCheckTotalData]);

  useEffect(() => {
    if (MentalCheckTotalError) {
      notification.error({
        placement: "bottomRight",
        message: MentalCheckTotalError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(GetMentalHealthTotalActionCreators.handleResetAction());
      setMentalCheckTotal({
        developmental_characteristics_pai_chart: {
          behavioral_problems: {
            total_precentage: 0,
            total_request: 0,
            total_response: 0,
          },
          concentration_problem: {
            total_precentage: 0,
            total_request: 0,
            total_response: 0,
          },
          relationship_problems: {
            total_precentage: 0,
            total_request: 0,
            total_response: 0,
          },
        },
        feelings_and_moods_pie_chart: {
          total_precentage: 0,
          total_request: 0,
          total_response: 0,
        },
        response_status: {
          total_request_sent: 0,
          total_resonse_recieved: 0,
        },
      });
    }
  }, [MentalCheckTotalError]);

  useEffect(() => {
    dispatch(
      mentalCheckRequestsActionCreators.mentalCheckRequestsAction({
        mental_check_type_id: "",
      })
    );
  }, []);

  useEffect(() => {
    setSelectValue(undefined);
    setSelectedRequest(undefined);
    setClassOptions([]);
    setRequestOptions([]);
    setMentalCheckTotal({
      developmental_characteristics_pai_chart: {
        behavioral_problems: {
          total_precentage: 0,
          total_request: 0,
          total_response: 0,
        },
        concentration_problem: {
          total_precentage: 0,
          total_request: 0,
          total_response: 0,
        },
        relationship_problems: {
          total_precentage: 0,
          total_request: 0,
          total_response: 0,
        },
      },
      feelings_and_moods_pie_chart: {
        total_precentage: 0,
        total_request: 0,
        total_response: 0,
      },
      response_status: {
        total_request_sent: 0,
        total_resonse_recieved: 0,
      },
    });
  }, []);

  useEffect(() => {
    dispatch(GetMentalHealthTotalActionCreators.handleResetAction());
  }, []);

  return (
    <Box title={t("mental-health-summary")} padding="24px 16px 32px">
      <WapperMentalHealthTotal>
        <div className="header-health-total">
          <div
            style={{
              fontSize: 18,
              lineHeight: "18px",
              fontWeight: 700,
            }}
          >
            {t("selection-of-examination")}
          </div>
          <Select
            placeholder={t("select-test")}
            width={screens.md ? "278px" : "100%"}
            value={selectedRequest}
            options={requestOptions}
            onChange={onChangeSelectRequest}
          />
          <Select
            placeholder={t("select-class")}
            width={screens.md ? "140" : "100%"}
            value={selectValue}
            options={classOptions}
            onChange={onChangeSelect}
          />
        </div>
        <div className="content-health-total">
          <div className="left">
            <SymptomTitle>
              {t("response-status")}{" "}
              {mentalCheckTotal.response_status?.total_resonse_recieved}/
              {mentalCheckTotal.response_status?.total_request_sent}
            </SymptomTitle>
            <PieChartWrapperTotal>
              {MentalCheckTotalData?.result.response_status ? (
                <PieChart
                  data={[
                    {
                      value: Number(returnTotalRate()),
                    },
                    {
                      value: 100 - Number(returnTotalRate()),
                    },
                  ]}
                  colors={COLORS}
                  textCenter={returnTotalRate()}
                  colorTextCenter={colorTextCenter}
                  amount={
                    mentalCheckTotal.response_status?.total_resonse_recieved
                  }
                  total={mentalCheckTotal.response_status?.total_request_sent}
                  textSmall="%"
                />
              ) : (
                <NoDataIcon width={140} height={140} />
              )}
              <TotalBadge>
                {t("overall-action")}
                {mentalCheckTotal.response_status?.total_resonse_recieved}/
                {mentalCheckTotal.response_status?.total_request_sent}
              </TotalBadge>
              <PieChartColorScheme>
                <div className="item">
                  <div
                    className="square"
                    style={{ background: "#2F8CAE" }}
                  ></div>
                  <div>{t("answered")}</div>
                </div>
                <div className="item">
                  <div className="square"></div>
                  <div>{t("unanswered")}</div>
                </div>
              </PieChartColorScheme>
            </PieChartWrapperTotal>
          </div>
          <WrapperPieChartGroup>
            {MentalCheckTotalData &&
              MentalCheckTotalData?.result
                ?.developmental_characteristics_pai_chart && (
                <div className="first">
                  <div className="title">
                    {t("developmental-characteristics")}
                  </div>
                  <PieChartsGroupWrapper>
                    <PieChartWrapperSymptom>
                      <div className="label">{t("concentration-problem")}</div>
                      {MentalCheckTotalData?.result
                        .developmental_characteristics_pai_chart
                        .concentration_problem ? (
                        <PieChart
                          data={[
                            {
                              value: Number(
                                mentalCheckTotal
                                  .developmental_characteristics_pai_chart
                                  ?.concentration_problem.total_precentage
                              ),
                            },
                            {
                              value:
                                100 -
                                Number(
                                  mentalCheckTotal
                                    .developmental_characteristics_pai_chart
                                    ?.concentration_problem.total_precentage
                                ),
                            },
                          ]}
                          colors={COLORS2}
                          textCenter={
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.concentration_problem?.total_precentage === 0 ||
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.concentration_problem?.total_precentage === 100
                              ? Number(
                                  mentalCheckTotal
                                    .developmental_characteristics_pai_chart
                                    ?.concentration_problem?.total_precentage
                                )
                              : Number(
                                  mentalCheckTotal
                                    .developmental_characteristics_pai_chart
                                    ?.concentration_problem?.total_precentage
                                ).toFixed(2)
                          }
                          colorTextCenter={
                            mentalCheckTotal
                              ?.developmental_characteristics_pai_chart
                              ?.concentration_problem.total_response &&
                            mentalCheckTotal
                              ?.developmental_characteristics_pai_chart
                              ?.concentration_problem.total_precentage >= 4
                              ? colorTextCenter2
                              : colorTextCenter
                          }
                          amount={
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.concentration_problem.total_request
                          }
                          total={
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.concentration_problem.total_response
                          }
                          textSmall="%"
                        />
                      ) : (
                        <NoDataIcon width={140} height={140} />
                      )}
                      <div className="badge">
                        {t("overall-action")}
                        {
                          mentalCheckTotal
                            .developmental_characteristics_pai_chart
                            ?.concentration_problem.total_request
                        }
                        /
                        {
                          mentalCheckTotal
                            .developmental_characteristics_pai_chart
                            ?.concentration_problem.total_response
                        }
                      </div>
                      <PieChartColorSchemeSecond>
                        <div className="item">
                          <div
                            className="square"
                            style={{ background: "#EBC300" }}
                          ></div>
                          <div>{t("be-careful")}</div>
                        </div>
                      </PieChartColorSchemeSecond>
                    </PieChartWrapperSymptom>
                    <PieChartWrapperSymptom>
                      <div className="label">{t("relationship-problem")}</div>
                      {MentalCheckTotalData?.result
                        .developmental_characteristics_pai_chart
                        .relationship_problems ? (
                        <PieChart
                          data={[
                            {
                              value: Number(
                                mentalCheckTotal
                                  .developmental_characteristics_pai_chart
                                  ?.relationship_problems.total_precentage
                              ),
                            },
                            {
                              value:
                                100 -
                                Number(
                                  mentalCheckTotal
                                    .developmental_characteristics_pai_chart
                                    ?.relationship_problems.total_precentage
                                ),
                            },
                          ]}
                          colors={COLORS2}
                          textCenter={
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.relationship_problems?.total_precentage === 0 ||
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.relationship_problems?.total_precentage === 100
                              ? Number(
                                  mentalCheckTotal
                                    .developmental_characteristics_pai_chart
                                    ?.relationship_problems?.total_precentage
                                )
                              : Number(
                                  mentalCheckTotal
                                    .developmental_characteristics_pai_chart
                                    ?.relationship_problems?.total_precentage
                                ).toFixed(2)
                          }
                          colorTextCenter={
                            mentalCheckTotal
                              ?.developmental_characteristics_pai_chart
                              ?.relationship_problems.total_response &&
                            mentalCheckTotal
                              ?.developmental_characteristics_pai_chart
                              ?.relationship_problems.total_precentage >= 4
                              ? colorTextCenter2
                              : colorTextCenter
                          }
                          amount={
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.relationship_problems.total_request
                          }
                          total={
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.relationship_problems.total_response
                          }
                          textSmall="%"
                        />
                      ) : (
                        <NoDataIcon width={140} height={140} />
                      )}
                      <div className="badge">
                        {t("overall-action")}
                        {
                          mentalCheckTotal
                            .developmental_characteristics_pai_chart
                            ?.relationship_problems.total_request
                        }
                        /
                        {
                          mentalCheckTotal
                            .developmental_characteristics_pai_chart
                            ?.relationship_problems.total_response
                        }
                      </div>
                      <PieChartColorSchemeSecond>
                        <div className="item">
                          <div
                            className="square"
                            style={{ background: "#EBC300" }}
                          ></div>
                          <div>{t("be-careful")}</div>
                        </div>
                      </PieChartColorSchemeSecond>
                    </PieChartWrapperSymptom>
                    <PieChartWrapperSymptom>
                      <div className="label">{t("behavioral-problem")}</div>
                      {MentalCheckTotalData?.result
                        .developmental_characteristics_pai_chart
                        .behavioral_problems ? (
                        <PieChart
                          data={[
                            {
                              value: Number(
                                mentalCheckTotal
                                  .developmental_characteristics_pai_chart
                                  ?.behavioral_problems.total_precentage
                              ),
                            },
                            {
                              value:
                                100 -
                                Number(
                                  mentalCheckTotal
                                    .developmental_characteristics_pai_chart
                                    ?.behavioral_problems.total_precentage
                                ),
                            },
                          ]}
                          colors={COLORS2}
                          textCenter={
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.behavioral_problems?.total_precentage === 0 ||
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.behavioral_problems?.total_precentage === 100
                              ? Number(
                                  mentalCheckTotal
                                    .developmental_characteristics_pai_chart
                                    ?.behavioral_problems?.total_precentage
                                )
                              : Number(
                                  mentalCheckTotal
                                    .developmental_characteristics_pai_chart
                                    ?.behavioral_problems?.total_precentage
                                ).toFixed(2)
                          }
                          colorTextCenter={
                            mentalCheckTotal
                              ?.developmental_characteristics_pai_chart
                              ?.behavioral_problems.total_response &&
                            mentalCheckTotal
                              ?.developmental_characteristics_pai_chart
                              ?.behavioral_problems.total_precentage >= 4
                              ? colorTextCenter2
                              : colorTextCenter
                          }
                          amount={
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.behavioral_problems.total_request
                          }
                          total={
                            mentalCheckTotal
                              .developmental_characteristics_pai_chart
                              ?.behavioral_problems.total_response
                          }
                          textSmall="%"
                        />
                      ) : (
                        <NoDataIcon width={140} height={140} />
                      )}
                      <div className="badge">
                        {t("overall-action")}
                        {
                          mentalCheckTotal
                            .developmental_characteristics_pai_chart
                            ?.behavioral_problems.total_request
                        }
                        /
                        {
                          mentalCheckTotal
                            .developmental_characteristics_pai_chart
                            ?.behavioral_problems.total_response
                        }
                      </div>
                      <PieChartColorSchemeSecond>
                        <div className="item">
                          <div
                            className="square"
                            style={{ background: "#EBC300" }}
                          ></div>
                          <div>{t("be-careful")}</div>
                        </div>
                      </PieChartColorSchemeSecond>
                    </PieChartWrapperSymptom>
                  </PieChartsGroupWrapper>
                </div>
              )}
            <div className="second">
              <SymptomTitleThird>{t("feel-mood-chart")}</SymptomTitleThird>
              <PieChartWrapperThird>
                {MentalCheckTotalData?.result.feelings_and_moods_pie_chart ? (
                  <PieChart
                    data={[
                      {
                        value: Number(
                          mentalCheckTotal.feelings_and_moods_pie_chart
                            ?.total_precentage
                        ),
                      },
                      {
                        value:
                          100 -
                          Number(
                            mentalCheckTotal.feelings_and_moods_pie_chart
                              ?.total_precentage
                          ),
                      },
                    ]}
                    colors={COLORS2}
                    textCenter={
                      mentalCheckTotal.feelings_and_moods_pie_chart
                        ?.total_precentage === 0 ||
                      mentalCheckTotal.feelings_and_moods_pie_chart
                        ?.total_precentage === 100
                        ? Number(
                            mentalCheckTotal.feelings_and_moods_pie_chart
                              ?.total_precentage
                          )
                        : Number(
                            mentalCheckTotal.feelings_and_moods_pie_chart
                              ?.total_precentage
                          ).toFixed(2)
                    }
                    colorTextCenter={
                      mentalCheckTotal?.feelings_and_moods_pie_chart
                        ?.total_response &&
                      mentalCheckTotal?.feelings_and_moods_pie_chart
                        ?.total_precentage >= 4
                        ? colorTextCenter2
                        : colorTextCenter
                    }
                    amount={
                      mentalCheckTotal.feelings_and_moods_pie_chart
                        ?.total_request
                    }
                    total={
                      mentalCheckTotal.feelings_and_moods_pie_chart
                        ?.total_response
                    }
                    textSmall="%"
                  />
                ) : (
                  <NoDataIcon width={140} height={140} />
                )}
                <TotalBadge>
                  {t("overall-action")}
                  {mentalCheckTotal.feelings_and_moods_pie_chart?.total_request}
                  /
                  {
                    mentalCheckTotal.feelings_and_moods_pie_chart
                      ?.total_response
                  }
                </TotalBadge>
                <PieChartColorSchemeThird>
                  <div className="item">
                    <div
                      className="square"
                      style={{ background: "#EBC300" }}
                    ></div>
                    <div>{t("be-careful")}</div>
                  </div>
                </PieChartColorSchemeThird>
              </PieChartWrapperThird>
            </div>
          </WrapperPieChartGroup>
        </div>
      </WapperMentalHealthTotal>
    </Box>
  );
};

export default MentalHealthTotal;
