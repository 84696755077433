import {
  ActionType,
  IQuestionDownloadData,
  CSVDownloadQuestionResponse,
} from "models/questionnaires/downloadCSVQuestion";

export const downloadCSVQuestionAction = (payload: IQuestionDownloadData) => {
  return {
    type: ActionType.CSV_DOWNLOAD_QUESTION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CSV_DOWNLOAD_QUESTION_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: CSVDownloadQuestionResponse) => {
  return {
    type: ActionType.CSV_DOWNLOAD_QUESTION_SUCCESS,
    payload,
  };
};
export const handleResetDownloadCSVQuestionAction = () => {
  return {
    type: ActionType.CSV_DOWNLOAD_QUESTION_RESET_DATA,
  };
};
