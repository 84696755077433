import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  VenueData,
  ActionType,
  VenuesListResponse,
} from "models/vaccine/venues";
import { httpStatus } from "configs/httpStatus";
import { fetchVenues } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./venuesActions";

function* venuesSaga({ payload }: { payload?: VenueData }) {
  try {
    const response: VenuesListResponse = yield call(fetchVenues, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetVenuesWatcher() {
  yield takeLatest(ActionType.VENUES_LIST as any, venuesSaga);
}

export default [fork(onGetVenuesWatcher)];
