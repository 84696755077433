import { IResponse } from "models";

export enum ActionType {
  UPDATE_TEMPERATURE_SETTING = "action/UPDATE_TEMPERATURE_SETTING",
  UPDATE_TEMPERATURE_SETTING_SUCCESS = "action/UPDATE_TEMPERATURE_SETTING_SUCCESS",
  UPDATE_TEMPERATURE_SETTING_ERROR = "action/UPDATE_TEMPERATURE_SETTING_ERROR",
  RESET_TEMPERATURE_SETTING = "action/RESET_TEMPERATURE_SETTING",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateTemperatureResponse extends IResponse {}

export interface UpdateTemperatureRequest {
  company_user_send_notifications_attributes: any[];
  resend_temperature_notification: boolean;
  resend_notification_time: number;
  // attendence_setting: boolean;
  // family_condition_setting: boolean;
  // pool_setting: boolean;
  is_alert_notify: boolean;
  // is_marathon: boolean;
  // is_small_check: boolean;
  // is_action_check: boolean;
  // is_covid_question_close: boolean;
  // is_covid_question_family_close: boolean;
  // is_covid_question_positive: boolean;
  // is_influenza_queston: boolean;
  // is_online_question: boolean;
  // temperature_show: boolean;
  is_approve_absence: boolean;
}
