import React, { useState, useEffect } from "react";
import { Tooltip, notification } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";

import { Input, Button, Box, Loading2 } from "components";
import { httpStatus } from "configs/httpStatus";
import { SendCompleteIcon } from "icons";
import { departmentListDataResponse } from "redux/account/departmentList/departmentListStates";
import { editAccountDataResponse } from "redux/account/editAccount/editAccountStates";
import { IDepartment, IActivites } from "models/account/departmentList";
import {
  EditAccountActionCreators,
  AccountListActionCreators,
} from "redux/rootActions";
import { selectIsLoading } from "redux/account/departmentList/departmentListStates";

import {
  WrapperPermistion,
  WrapperEditForm,
  WrapperItem,
  ItemContainer,
  WrapperTreeView,
  CheckboxStyled,
  Label,
} from "./Account.style";

const AccountEdit = ({ setIsEdit }: any) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "account" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const dispatch = useDispatch();
  const departmentListData = useSelector(departmentListDataResponse);
  const editAccountData = useSelector(editAccountDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const [treeData, setTreeData] = useState<IDepartment[]>([]);
  const [treeData2, setTreeData2] = useState<IActivites[]>([]);

  const { handleSubmit, control, setValue } = useForm();

  const handleCheckedItem = (item: any, itemType: string, e: any) => {
    item.checked = e;
    if (item[itemType].length) {
      item[itemType].map((newItem: any) => {
        handleCheckedItem(newItem, itemType, item.checked);
      });
    }
  };

  const handleGetCheckedItemValue = (
    arr: any[],
    checkedArr: number[],
    itemType: string
  ) => {
    arr.forEach((item) => {
      if (item.checked) {
        checkedArr.push(item.id);
      } else {
        if (item[itemType].length) {
          handleGetCheckedItemValue(item[itemType], checkedArr, itemType);
        }
      }
    });
  };

  const onChangeCheckbox =
    (item: IDepartment, itemType: string) => (e: any) => {
      handleCheckedItem(item, itemType, e.target.checked);
      if (itemType === "sub_departments") {
        setTreeData([...treeData]);
      } else {
        setTreeData2([...treeData2]);
      }
    };

  const handleEditAccount = async (data: any) => {
    const checkedArr: number[] = [];
    const checkedArr2: number[] = [];
    handleGetCheckedItemValue(treeData, checkedArr, "sub_departments");
    handleGetCheckedItemValue(treeData2, checkedArr2, "sub_activities");
    dispatch(
      EditAccountActionCreators.editAccountAction({
        display_name: data.displayName,
        company_manager_id: departmentListData?.result.company_manager.id,
        company_manager_c_departments: {
          c_department_ids: checkedArr,
        },
        company_manager_company_sub_departments: {
          company_sub_department_ids: checkedArr2,
        },
      })
    );
  };

  const handleAddCheckBoxToItem = (arr: IDepartment[]) => {
    const newArr = [...arr];
    const { checked_c_department_ids }: any = departmentListData?.result;

    newArr.forEach((item) => {
      if (checked_c_department_ids.includes(item.id)) {
        item.checked = true;
        if (item.sub_departments.length) {
          item.sub_departments.map((item) => {
            handleCheckedItem(item, "sub_departments", true);
          });
        }
      }
      if (item.sub_departments.length) {
        handleAddCheckBoxToItem(item.sub_departments);
      }
    });
  };

  const handleAddCheckBoxToItem2 = (arr: IActivites[]) => {
    const newArr = [...arr];
    const { checked_company_sub_department_ids }: any =
      departmentListData?.result;

    newArr.forEach((item) => {
      if (checked_company_sub_department_ids.includes(item.id)) {
        item.checked = true;
        if (item.sub_activities.length) {
          item.sub_activities.map((item) => {
            handleCheckedItem(item, "sub_activities", true);
          });
        }
      }
      if (item.sub_activities.length) {
        handleAddCheckBoxToItem2(item.sub_activities);
      }
    });
  };

  useEffect(() => {
    if (editAccountData && editAccountData.status === httpStatus.StatusOK) {
      notification.open({
        message: Moment(new Date()).format("YYYY/MM/DD HH:mm"),
        description: t("edit-complete"),
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      setIsEdit(false);
      dispatch(EditAccountActionCreators.handleResetAction());
      dispatch(
        AccountListActionCreators.getAccountListAction({
          limit: 25,
          page: 1,
        })
      );
    }
  }, [editAccountData]);

  useEffect(() => {
    if (departmentListData && Object.keys(departmentListData.result).length) {
      const {
        company_manager,
        selectable_departments,
        selectable_sub_departments,
      } = departmentListData.result;
      const { display_name, email } = company_manager;

      handleAddCheckBoxToItem(selectable_departments);
      setTreeData(selectable_departments);
      handleAddCheckBoxToItem2(selectable_sub_departments);
      setTreeData2(selectable_sub_departments);
      setValue("displayName", display_name);
      setValue("email", email);
    }
  }, [departmentListData]);

  const CompItem = ({
    item,
    itemType,
    children,
  }: {
    item: any;
    itemType: string;
    children: any;
  }) => {
    const { name, checked } = item;

    const handleCheckParent = (item: IDepartment, checked: boolean) => {
      item.checked = checked;
      if (itemType === "sub_departments") {
        setTreeData([...treeData]);
      } else {
        setTreeData2([...treeData2]);
      }
    };

    useEffect(() => {
      if (item[itemType].length > 0) {
        if (
          !item[itemType].some(
            (d: any) => d.checked === false || d.checked === undefined
          )
        ) {
          if (!item.checked) {
            handleCheckParent(item, true);
          }
        } else {
          if (item.checked) {
            handleCheckParent(item, false);
          }
        }
      }
    }, [item[itemType]]);

    return (
      <>
        <ItemContainer>
          <CheckboxStyled
            checked={checked}
            onChange={onChangeCheckbox(item, itemType)}
          >
            <Tooltip placement="topLeft" title={name}>
              <div style={{ marginLeft: 10 }}>{name}</div>
            </Tooltip>
          </CheckboxStyled>
        </ItemContainer>
        {children}
      </>
    );
  };

  const Item = ({ item, itemType }: { item: any; itemType: string }) => {
    return (
      <CompItem item={item} itemType={itemType}>
        {item[itemType].length > 0 &&
          item[itemType].map((i: any) => (
            <div style={{ marginLeft: 32 }} key={i.id}>
              <CompItem item={i} itemType={itemType}>
                {i[itemType].map((a: any) => (
                  <div style={{ marginLeft: 32 }} key={a.id}>
                    <Item item={a} itemType={itemType} />
                  </div>
                ))}
              </CompItem>
            </div>
          ))}
      </CompItem>
    );
  };

  return (
    <Box title={t("account-edit")} padding="24px 16px 16px 16px">
      {isLoading ? (
        <Loading2 width={70} height={70} />
      ) : (
        <React.Fragment>
          <WrapperEditForm onSubmit={handleSubmit(handleEditAccount)}>
            <Controller
              control={control}
              name="displayName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  value={value}
                  label={t("display-name")}
                  height={31}
                  fsLabel={16}
                  onChange={onChange}
                  error={t(error?.message)}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  value={value}
                  label={t("email-address")}
                  type="email"
                  height={31}
                  fsLabel={16}
                  onChange={onChange}
                  error={t(error?.message)}
                  disabled
                />
              )}
            />
            <Button
              name={t("update")}
              background="#2AC769"
              color="#FFFFFF"
              border="none"
              fontSize={16}
              fontWeight={700}
              width="128px"
            />
          </WrapperEditForm>
          <WrapperPermistion>
            <p>{t("view-and-edit-description")}</p>
            <div style={{ marginBottom: 24 }}>
              <Label>{t1("grade")}</Label>
              <WrapperTreeView>
                {treeData.map((item) => {
                  return (
                    <WrapperItem key={item.id}>
                      <Item item={item} itemType="sub_departments" />
                    </WrapperItem>
                  );
                })}
              </WrapperTreeView>
            </div>

            <div>
              <Label>{t1("activities")}</Label>
              <WrapperTreeView>
                {treeData2.map((item) => {
                  return (
                    <WrapperItem key={item.id}>
                      <Item item={item} itemType="sub_activities" />
                    </WrapperItem>
                  );
                })}
              </WrapperTreeView>
            </div>
          </WrapperPermistion>
        </React.Fragment>
      )}
    </Box>
  );
};

export default AccountEdit;
