import {
  Action,
  ActionType,
  SubDepartmentListResponse,
} from "models/subdepartmentList";
import createReducer from "../createReducer";

export interface SubDepartmentListReducerType {
  loading: boolean;
  error?: string;
  subDepartmentListResponse?: SubDepartmentListResponse;
}

const defaultState: SubDepartmentListReducerType = {
  loading: false,
  error: undefined,
  subDepartmentListResponse: undefined,
};

export const subDepartmentListReducer =
  createReducer<SubDepartmentListReducerType>(defaultState, {
    [ActionType.SUB_DEPARTMENT_LIST](state: SubDepartmentListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.SUB_DEPARTMENT_LIST_ERROR](
      state: SubDepartmentListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        subDepartmentListResponse: [],
      };
    },

    [ActionType.SUB_DEPARTMENT_LIST_SUCCESS](
      state: SubDepartmentListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        subDepartmentListResponse: action.payload,
      };
    },
  });
