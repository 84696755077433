import { IResponse } from "models";

export enum ActionType {
  MENTAL_CHECK_DETAILS = "action/MENTAL_CHECK_DETAILS",
  MENTAL_CHECK_DETAILS_SUCCESS = "action/MENTAL_CHECK_DETAILS_SUCCESS",
  MENTAL_CHECK_DETAILS_ERROR = "action/MENTAL_CHECK_DETAILS_ERROR",
  MENTAL_CHECK_DETAILS_RESET_DATA = "action/MENTAL_CHECK_DETAILS_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface MentalCheckDetailsData {
  id?: number;
  app_language_id: number;
}

export interface IMentalCheckDetailsResponse extends IResponse {
  result?: Details;
}

export interface Details {
  id?: number;
  test_name: string;
  mental_check_type_id: number;
  grade: string;
  examination_period: string;
  status: string;
  color_code: string;
}
