import {
  Action,
  ActionType,
  IUpdateUserRegistrationResponse,
} from "models/setting/updateUserRegistration";
import createReducer from "../../createReducer";

export interface UpdateUserRegistrationSettingReducerType {
  loading: boolean;
  error?: string;
  updateUserRegistrationSettingResponse?: IUpdateUserRegistrationResponse;
}

const defaultState: UpdateUserRegistrationSettingReducerType = {
  loading: false,
  error: undefined,
  updateUserRegistrationSettingResponse: undefined,
};

export const updateUserRegistrationSettingReducer =
  createReducer<UpdateUserRegistrationSettingReducerType>(defaultState, {
    [ActionType.UPDATE_USER_REGISTRATION_SETTING](
      state: UpdateUserRegistrationSettingReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_USER_REGISTRATION_SETTING_ERROR](
      state: UpdateUserRegistrationSettingReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_USER_REGISTRATION_SETTING_SUCCESS](
      state: UpdateUserRegistrationSettingReducerType,
      action: Action<IUpdateUserRegistrationResponse>
    ) {
      return {
        ...state,
        loading: false,
        updateUserRegistrationSettingResponse: action.payload,
      };
    },

    [ActionType.RESET_USER_REGISTRATION_SETTING](
      state: UpdateUserRegistrationSettingReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: undefined,
        updateUserRegistrationSettingResponse: undefined,
      };
    },
  });
