import { IConfirmStudentListData } from "models/classchangemanagement/confirmStudentListClassChange";
import { IClearTempRegisterData } from "models/classchangemanagement/clearTempRegisterClassChange";
import { ICSVDownloadStudentListData } from "models/classchangemanagement/getCSVStudentListData";
import { ISaveTemporaryClassChangeRequest } from "models/classchangemanagement/saveTemporary";
import { IPutConfirmStudentData } from "models/classchangemanagement/putConfirmStudentClassChange";
import { IStudentListClassChangeData } from "models/classchangemanagement/studentListClassChange";
import { IClassListData } from "models/classchangemanagement/getClassList";
import { createApiCall2, MethodType, URI } from "../Api";

export const fetchStudentList = (payload: IStudentListClassChangeData) => {
  return createApiCall2(
    MethodType.GET,
    URI.CLASS_CHANGE_MANAGEMENT.GET_STUDENT_LIST,
    payload
  );
};

export const fetchConfirmStudentList = (payload: IConfirmStudentListData) => {
  return createApiCall2(
    MethodType.GET,
    URI.CLASS_CHANGE_MANAGEMENT.GET_CONFIRM_STUDENT_LIST,
    payload
  );
};

export const fetchClearTempRegister = (payload: IClearTempRegisterData) => {
  return createApiCall2(
    MethodType.DELETE,
    URI.CLASS_CHANGE_MANAGEMENT.CLEAR_TEMP_REGISTERS,
    payload
  );
};

export const putConfirmStudentList = (payload: IPutConfirmStudentData) => {
  return createApiCall2(
    MethodType.PUT,
    URI.CLASS_CHANGE_MANAGEMENT.PUT_UPDATE_ALL_STUDENT_LIST,
    payload
  );
};

export const fetchCSVStudentDataList = (
  payload: ICSVDownloadStudentListData
) => {
  return createApiCall2(
    MethodType.GET,
    URI.CLASS_CHANGE_MANAGEMENT.GET_DOWNLOAD_CSV,
    payload
  );
};

export const uploadCSVStudentDataList = (payload: any) => {
  return createApiCall2(
    MethodType.POST,
    URI.CLASS_CHANGE_MANAGEMENT.UPLOAD_CSV,
    payload
  );
};

export const saveTemporaryStudentList = (
  payload: ISaveTemporaryClassChangeRequest
) => {
  return createApiCall2(
    MethodType.POST,
    URI.CLASS_CHANGE_MANAGEMENT.SAVE_TEMPORARY,
    payload
  );
};

export const fetchClassList = (payload: IClassListData) => {
  return createApiCall2(
    MethodType.GET,
    URI.CLASS_CHANGE_MANAGEMENT.CLASS_LIST,
    payload
  );
};
