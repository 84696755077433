import { appio } from "app/appio";
import httpClient2 from "configs/httpClient2";
import { localStorageHelper } from "utils";

export const authTokenKey = process.env.REACT_APP_ACCESS_TOKEN_KEY!;

export enum MethodType {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

const id = localStorageHelper.getItem("id");

export const URI = {
  AUTH: {
    LOGIN: "/web_api_v1/users/login",
    FORGOTPASSWORD: "/web_api_v1/users/forgot_password",
    RESETPASSWORD: "/web_api_v1/users/password_reset",
  },
  USERS: {
    GETUSERLIST: "/web_api_v1/school/users",
    GETCLASSLIST: "/web_api_v1/school/classes/list_for_search",
    GETDEPARTMENTLIST: "/web_api_v1/school/users/department_list",
    GET_ACTIVITY_GROUP_LIST: "/web_api_v1/school/users/group_list",
    CREATE_USER: "/web_api_v1/school/users",
    DELETE_USER: "/web_api_v1/school/users/delete",
    CSV_DOWNLOAD_USER: "/web_api_v1/school/users/csv_download",
    USER_ATTENDANCE: "/web_api_v1/school/users/user_attendance",
    UPDATE_POSITION_COLUMNS:
      "/web_api_v1/school/settings/change_user_list_column_position",
  },
  CLASS_MANAGEMENT: {
    GET_CLASSES: "/web_api_v1/school/classes",
    GET_SCHOOL_TYPE: "/web_api_v1/school/type_infos",
    CREATE_BULK_CLASSES: "/web_api_v1/school/classes/bulk_create",
    DELETE_CLASS: "/web_api_v1/school/classes/delete",
  },
  ACTIVITY_MANAGEMENT: {
    GET_ACTIVITY: "/web_api_v1/school/activities",
    CREATE_ACTIVITY: "/web_api_v1/school/activities",
    UPDATE_ACTIVITY: "/web_api_v1/school/activities",
    DELETE_ACTIVITY: "/web_api_v1/school/activities/delete",
  },
  MESSAGE_MANAGEMENT: {
    GET_ACTIVITY_FILTER: "/web_api_v1/school/send_messages/activity_list",
    GET_CLASS_FILTER: "/web_api_v1/school/send_messages/class_list",
    GET_TEMPERATURE_FILTER: "/web_api_v1/school/send_messages/temperature_list",
    GET_ATTENDANCE_FILTER: "/web_api_v1/school/send_messages/attendance",
    GET_STUDENT_LIST: "/web_api_v1/school/send_messages",
    GET_MESSAGE_HISTORY_LIST: "/web_api_v1/school/send_messages/histories",
    GET_MESSAGE_HISTORY_DETAIL:
      "/web_api_v1/school/send_messages/history_detail",
    SEND_MESSAGE: "/web_api_v1/school/send_messages",
    SEARCH_CONDITION:
      "/web_api_v1/school/send_messages/search_condition_status",
    GET_READ_HISTORY_LIST: "/web_api_v1/school/send_messages/read_history",
    PUSH_NOTIFICATION:
      "/web_api_v1/school/send_messages/remind_push_notification",
    UPDATE_MESSAGE: (id: number) => `web_api_v1/school/send_messages/${id}`,
    DELETE_MESSAGE: (id: number) =>
      `web_api_v1/school/send_messages/${id}/delete`,
  },
  CLASS_CHANGE_MANAGEMENT: {
    GET_STUDENT_LIST: "/web_api_v1/school/classes/change",
    GET_CONFIRM_STUDENT_LIST: "/web_api_v1/school/classes/change_list",
    PUT_UPDATE_ALL_STUDENT_LIST: "/web_api_v1/school/classes/update_all",
    GET_DOWNLOAD_CSV: "/web_api_v1/school/classes/csv_download",
    UPLOAD_CSV: "/web_api_v1/school/classes/csv_upload",
    SAVE_TEMPORARY: "/web_api_v1/school/classes/temporary_save",
    CLEAR_TEMP_REGISTERS: "/web_api_v1/school/classes/clear_temp_registers",
    CLASS_LIST: "/web_api_v1/school/classes/list_for_search_end",
  },
  QR_CODE: {
    GET_DOWNLOAD_QR_CODE: "/web_api_v1/school/users/download_qr_code",
  },
  BODY_TEMPERATURE: {
    GET_DATA_CHARTS: "/web_api_v1/school/body_temperatures/graph_detail",
    // GET_DATA_BODY_TEMPERATURE: `/v10/company_admin/${id}/daily_answers`,
    GET_DATA_ACTIVITY:
      "/web_api_v1/school/body_temperatures/get_patients_groups",
    GET_DATA_CLASS:
      "/web_api_v1/school/body_temperatures/get_patients_designations",
    GET_DATA_CHILD_DEPARTMENT:
      "/web_api_v1/school/body_temperatures/get_child_departments",
    GET_DATA_PATIENT_CHILD_DEPARTMENT:
      "/web_api_v1/school/body_temperatures/get_patients_for_child_departments",
    CREATE_PATIENT_BODY_TEMPERATURE_CHECK:
      "/web_api_v1/school/body_temperatures/submit_temperatures",
    GET_DATA_DETAIL_FORM:
      "/web_api_v1/school/body_temperatures/patient_temperature_form",
    GET_DATA_PATIENT_DETAIL:
      "/web_api_v1/school/body_temperatures/temperature_detail",
    GET_DATA_FEVER_PERCENTAGE:
      "/web_api_v1/school/body_temperatures/get_percent",
    GET_DATA_SETTING_FORM:
      "/web_api_v1/school/body_temperatures/csv_pdf_filter_form",
    // GET_DATA_FILE: `v10/company_admin/${id}/daily_answers/for_download`,
    GET_PROGESS_BAR_DATA:
      "/web_api_v1/school/body_temperatures/get_progress_bar_data",
    APPROVE_ABSENCE: (patient_id: number) =>
      `/v10/company_admin/${id}/daily_answers/${patient_id}`,
    // GET_HEALTH_CHECK_QUESTIONS: `/v10/company_admin/${id}/health_check_questions`,
    GET_HEALTH_CHECK_ANSWER: (patient_id: number) =>
      `/v10/company_admin/${id}/health_check_answers/${patient_id}`,
    POST_HEALTH_CHECK_ANSWER: (patient_id: number) =>
      `/v10/company_admin/${id}/health_check_answers/${patient_id}`,
  },
  ACCOUNT: {
    GET_DATA_LIST: "/web_api_v1/school/company_managers",
    DELETE_ACCOUNT: "/web_api_v1/school/company_managers/delete",
    GET_DEPARTMENT_LIST: "/web_api_v1/school/company_managers/department_edit",
    EDIT_ACCOUNT: "/web_api_v1/school/company_managers/department_update",
    REGISTRATION_LIST: "/web_api_v1/school/company_managers/registration",
    CREATE_ACCOUNT:
      "/web_api_v1/school/company_managers/create_company_manager",
  },
  SETTINGS: {
    GET_DATA_SETTING: "/web_api_v1/school/settings",
    UPDATE_TEMPERATURE:
      "/web_api_v1/school/settings/update_temperature_managerment",
    UPDATE_GENERAL: "/web_api_v1/school/settings/update_general",
    DELETE_NOTIFICATION_SETTING:
      "/web_api_v1/school/settings/destroy_company_user_send_notification",
    UPDATE_MESSAGE:
      "/web_api_v1/school/settings/upsert_message_reminder_setting",
    UPDATE_DESEASE:
      "/web_api_v1/school/settings/update_is_disease_outbreak_graph_display_for_patients",
    UPDATE_CHART_ORDER:
      "/web_api_v1/school/settings/update_dashboard_chart_order",
    UPDATE_USER_REGISTRATION:
      "/web_api_v1/school/settings/update_dashboard_is_display_user_id",
  },
  VACCINE: {
    GET_RECORD_LIST: "/web_api_v1/school/vaccination_records",
    GET_RECORD_GRAPH: "/web_api_v1/school/vaccination_records/graph_detail",
    ACTION_VENUES: "/web_api_v1/school/venues",
    RESERVATIONS: {
      GET_RESERVATIONS: "/web_api_v1/school/vaccine_reservations",
      GET_VENUES: "/web_api_v1/school/vaccine_reservations/venues",
      TODAY_SCHEDULES:
        "/web_api_v1/school/vaccine_reservations/get_today_schedules",
      SAVE_SCHEDULE_SECTIONS:
        "/web_api_v1/school/vaccine_reservations/save_schedule_sections",
      UPDATE_SCHEDULE_SECTIONS:
        "/web_api_v1/school/vaccine_reservations/update_schedule_sections",
      GET_ADD_SLOT: "/web_api_v1/school/vaccine_reservations/add_slot",
      DELETE_SLOT: "/web_api_v1/school/vaccine_reservations/delete_slot",
      GET_SCHEDULE_FILTER:
        "/web_api_v1/school/vaccine_reservations/schedule_filter",
      GET_TIME_SLOT: "/web_api_v1/school/vaccine_reservations/select_slot",
    },
  },
  MENTAL_HEALTH: {
    CHECK_LIST: "/web_api_v1/school/mental_checks",
    MENTAL_CHECK_DETAILS: "/web_api_v1/school/mental_checks/status_detail",
    GRADE_LIST: "/web_api_v1/school/mental_checks/mental_check_grade_list",
    NEW_MENTAL_CHECK: "/web_api_v1/school/mental_checks/mental_check_request",
    UPDATE_MENTAL_CHECK:
      "/web_api_v1/school/mental_checks/mental_check_request_update",
    DELETE_MENTAL_CHECK: "/web_api_v1/school/mental_checks/delete",
    GET_CHECK_REQUESTS:
      "/web_api_v1/school/mental_checks/mental_check_request_detail",
    TYPE_INFORMATION: "/web_api_v1/school/mental_checks/type_information",
    SEND_NOTIFICATION: "/web_api_v1/school/mental_checks/send_notification",
    DOWNLOAD_TYPE_INFO_CSV:
      "/web_api_v1/school/mental_checks/type_information_download_csv",
    DOWNLOAD_TYPE_INFO_PDF:
      "/web_api_v1/school/mental_checks/type_information_download_pdf",
    GET_SCORING_INFORMATION:
      "/web_api_v1/school/mental_checks/mental_check_scoring_information",
    GET_MENTAL_CHECK_TOTAL_INFO: "/web_api_v1/school/mental_checks/pie_chart",
    GET_MENTAL_CHECK_TYPE:
      "/web_api_v1/school/mental_checks/mental_check_type_list",
    GET_LAST_NOTIFICATION_TIME:
      "/web_api_v1/school/mental_checks/last_reminder_notification",
    GET_CLASS_LIST: "/web_api_v1/school/mental_checks/grade_with_class_list",
    DOWNLOAD_SCORING_INFO:
      "/web_api_v1/school/mental_checks/mental_check_scoring_information_download_pdf",
    SEND_INTERVIEW_REQUEST:
      "/web_api_v1/school/mental_checks/send_interview_request",
  },
  QUESTION: {
    QUESTIONNAIRES: "/web_api_v1/school/questionnaires",
    GET_ANSWER_INFO: "/web_api_v1/school/questionnaires/answer_info",
    GET_INDIVIDUAL_ANSWER: "/web_api_v1/school/questionnaires/invidiual_answer",
    GET_QUESTIONNAIRE_RESPONSE_DETAIL:
      "/web_api_v1/school/questionnaires/get_questionaire_response_details",
    DUPLICATE_QUESTION: "/web_api_v1/school/questionnaires/duplicate",
    DONE_QUESTION: "/web_api_v1/school/questionnaires/done",
    SEND_QUESTIONNAIRES: "/web_api_v1/school/send_questionnaires",
    SEND_HISTORIES: "/web_api_v1/school/send_questionnaires/histories",
    SEND_HISTORY_DETAIL:
      "/web_api_v1/school/send_questionnaires/history_detail",
    ANSWER_HISTORY: "/web_api_v1/school/send_questionnaires/read_history",
    DOWNLOAD_CSV_QUESTION: "/web_api_v1/school/questionnaires/csv_question",
    DOWNLOAD_CSV_ALL_QUESTIONS: "/web_api_v1/school/questionnaires/csv_all",
    REMIND_PUSH_NOTIFICATION:
      "/web_api_v1/school/send_questionnaires/remind_push_notification",
    CANCEL_QUESTIONNAIRE: "/web_api_v1/school/questionnaires/cancel",
  },
  CONSULTATION_REQUEST: {
    EXCHANGE_LIST: "/web_api_v1/school/consultation_requests/exchange_list",
    CONSULTATION_LIST:
      "/web_api_v1/school/consultation_requests/consultion_list",
    COMPLETE_REQUEST:
      "/web_api_v1/school/consultation_requests/complete_request",
    ADD_REQUEST:
      "/web_api_v1/school/consultation_requests/add_request_for_company_manager",
    GET_SETTINGS: "/web_api_v1/school/consultation_request_settings",
    UPDATE_INSERT_SETTINGS:
      "/web_api_v1/school/consultation_request_settings/upsert",
    SEND_NOTIFICATION:
      "/web_api_v1/school/consultation_requests/send_notification",
  },
};

const getMethod = async (url: string, params: any) => {
  return appio.get(url, { params });
};

const postMethod = async (url: string, params: any) => {
  return appio.post(url, params);
};

const putMethod = async (url: string, params: any) => {
  return appio.put(url, params);
};

const deleteMethod = async (url: string, params: any) => {
  return appio.delete(url, { params });
};

const getMethod2 = async (url: string, params: any) => {
  return httpClient2.get(url, { params });
};

const postMethod2 = async (url: string, params: any) => {
  return httpClient2.post(url, params);
};

const putMethod2 = async (url: string, params: any) => {
  return httpClient2.put(url, params);
};

const deleteMethod2 = async (url: string, params: any) => {
  return httpClient2.delete(url, { params });
};

const methods: any = {
  GET: (url: string, params: any) => getMethod(url, params),
  POST: (url: string, params: any) => postMethod(url, params),
  PUT: (url: string, params: any) => putMethod(url, params),
  DELETE: (url: string, params: any) => deleteMethod(url, params),
};

const methods2: any = {
  GET: (url: string, params: any) => getMethod2(url, params),
  POST: (url: string, params: any) => postMethod2(url, params),
  PUT: (url: string, params: any) => putMethod2(url, params),
  DELETE: (url: string, params: any) => deleteMethod2(url, params),
};

export const createApiCall2 = async (
  method: string,
  url: string,
  params = {}
) => {
  const { data } = await methods[method](url, params);
  return data;
};

export const createApiCall = async (
  method: string,
  url: string,
  params = {}
) => {
  const { data } = await methods2[method](url, params);
  return data;
};
