import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { updateUserRegistrationSetting } from "services/setting";
import {
  ActionType,
  IUpdateUserRegistrationPayload,
  IUpdateUserRegistrationResponse,
} from "models/setting/updateUserRegistration";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./updateUserRegistrationActions";

function* updateUserRegistrationSettingSaga({
  payload,
}: {
  payload: IUpdateUserRegistrationPayload;
}) {
  try {
    const response: IUpdateUserRegistrationResponse = yield call(
      updateUserRegistrationSetting,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateUserRegistrationSettingWatcher() {
  yield takeLatest(
    ActionType.UPDATE_USER_REGISTRATION_SETTING as any,
    updateUserRegistrationSettingSaga
  );
}

export default [fork(onUpdateUserRegistrationSettingWatcher)];
