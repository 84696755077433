import {
  ActionType,
  DataDestinationResponse,
} from "models/bodyTemperature/destinations";

export const getDataDestinationAction = (payload: { locale: string }) => {
  return {
    type: ActionType.DATA_DESTINATION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DATA_DESTINATION_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DataDestinationResponse) => {
  return {
    type: ActionType.DATA_DESTINATION_SUCCESS,
    payload,
  };
};
