import { IResponse } from "models";
import {
  Action,
  ActionType,
} from "models/classchangemanagement/putConfirmStudentClassChange";
import createReducer from "../../createReducer";

export interface PutConfirmStudentClassChangeReducerType {
  loading: boolean;
  saved: boolean;
  error?: string;
  putConfirmStudentClassChangeResponse?: IResponse;
}

const defaultState: PutConfirmStudentClassChangeReducerType = {
  loading: false,
  saved: false,
  error: undefined,
  putConfirmStudentClassChangeResponse: undefined,
};

export const putConfirmStudentClassChangeReducer =
  createReducer<PutConfirmStudentClassChangeReducerType>(defaultState, {
    [ActionType.PUT_STUDENT_CLASS_CHANGE](
      state: PutConfirmStudentClassChangeReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.PUT_STUDENT_CLASS_CHANGE_ERROR](
      state: PutConfirmStudentClassChangeReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      };
    },

    [ActionType.PUT_STUDENT_CLASS_CHANGE_SUCCESS](
      state: PutConfirmStudentClassChangeReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: true,
        error: null,
        putConfirmStudentClassChangeResponse: action.payload,
      };
    },

    [ActionType.PUT_STUDENT_CLASS_CHANGE_RESET](
      state: PutConfirmStudentClassChangeReducerType
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        putConfirmStudentClassChangeResponse: [],
      };
    },
  });
