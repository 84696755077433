import { IResponse } from "models";

export enum ActionType {
  APPROVE_ABSENCE = "action/APPROVE_ABSENCE",
  APPROVE_ABSENCE_SUCCESS = "action/APPROVE_ABSENCE_SUCCESS",
  APPROVE_ABSENCE_ERROR = "action/APPROVE_ABSENCE_ERROR",
  APPROVE_ABSENCE_RESET = "action/APPROVE_ABSENCE_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IApproveAbsencePayload {
  id: number;
}

export interface IApproveAbsenceResponse extends IResponse {
  result: any;
}
