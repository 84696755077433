import {
  ActionType,
  ISearchConditionResponse,
} from "../../../models/messagemanagement/searchCondition";

export const searchConditionAction = () => {
  return {
    type: ActionType.SEARCH_CONDITION,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SEARCH_CONDITION_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ISearchConditionResponse) => {
  return {
    type: ActionType.SEARCH_CONDITION_SUCCESS,
    payload,
  };
};
