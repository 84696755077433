import {
  Action,
  ActionType,
  DeleteAccountResponse,
} from "models/account/deleteAccount";
import createReducer from "../../createReducer";

export interface DeleteAccountReducerType {
  loading: boolean;
  error?: string;
  deleteAccountResponse?: DeleteAccountResponse;
}

const defaultState: DeleteAccountReducerType = {
  loading: false,
  error: undefined,
  deleteAccountResponse: undefined,
};

export const deleteAccountReducer = createReducer<DeleteAccountReducerType>(
  defaultState,
  {
    [ActionType.DELETE_ACCOUNT](state: DeleteAccountReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_ACCOUNT_ERROR](
      state: DeleteAccountReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DELETE_ACCOUNT_SUCCESS](
      state: DeleteAccountReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteAccountResponse: action.payload,
      };
    },
  }
);
