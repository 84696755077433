import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  FaceScalePayload,
  ActionType,
  FaceScaleResponse,
} from "models/dashboard/faceScale";
import { httpStatus } from "configs/httpStatus";
import { fetchFaceScaleChart } from "services/dashboard";
import { handleSuccesAction, handleErrorAction } from "./faceScaleActions";

function* getFaceScaleSaga({ payload }: { payload: FaceScalePayload }) {
  try {
    const response: FaceScaleResponse = yield call(
      fetchFaceScaleChart,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetFaceScaleWatcher() {
  yield takeLatest(ActionType.GET_FACE_SCALE_CHART as any, getFaceScaleSaga);
}

export default [fork(onGetFaceScaleWatcher)];
