import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { LinkStyled } from "theme/CommonStyle";

const Message = styled.p(({ theme }) => ({
  fontSize: theme.sizes.sm,
  fontWeight: theme.fontWeight.medium,
  color: theme.colors.text.primary,
  lineHeight: "20px",
  marginBottom: 24,
}));

const WrapperLink = styled.div(({ theme }) => ({
  textAlign: "center",
  a: {
    ...LinkStyled(theme),
  },
}));

const ResetPasswordNotification: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "auth" });

  return (
    <>
      <Message>{t("reset-password-notification")}</Message>
      <WrapperLink>
        <Link to="/login">{t("to-login")}</Link>
      </WrapperLink>
    </>
  );
};

export default ResetPasswordNotification;
