import { IResponse } from "models";

export enum ActionType {
  ACTIVITY_LIST_SCHOOL = "action/ACTIVITY_LIST_SCHOOL",
  ACTIVITY_LIST_SCHOOL_SUCCESS = "action/ACTIVITY_LIST_SCHOOL_SUCCESS",
  ACTIVITY_LIST_SCHOOL_ERROR = "action/ACTIVITY_LIST_SCHOOL_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGetActivityListResponse extends IResponse {
  result: IActivityList[];
}

export interface IActivityList {
  id: any;
  name: string;
  is_required: boolean;
  sub_activities: IChildrenData[];
  delete_flag?: boolean;
  level?: string;
}

export interface IChildrenData {
  name: string;
  id: any;
  sub_activities: IChildrenData[];
  delete_flag?: boolean;
  is_required: boolean;
}
