import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  // get school class types
  GET_SCHOOL_TYPE = "action/GET_SCHOOL_TYPE",
  GET_SCHOOL_TYPE_SUCCESS = "action/GET_SCHOOL_TYPE_SUCCESS",
  GET_SCHOOL_TYPE_ERROR = "action/GET_SCHOOL_TYPE_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ISchoolTypeData {
  section_type: number;
}

export interface ISchoolTypeResponse extends IResponse {
  result: IOption[];
}
