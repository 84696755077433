import React, { useEffect, useRef, useState } from "react";
import { Upload, notification } from "antd";
import { useTranslation } from "react-i18next";

import httpClient from "configs/httpClient";
import { httpStatus } from "configs/httpStatus";
import { UploadIcon, PDFIcon, SendCompleteIcon, ConfirmFailIcon } from "icons";
import { Loading } from "components";
import {
  WrapperUpload,
  UploadFileBtn,
  DraggerStyled,
  WrapperFile,
  PrimaryText,
  SecondaryText,
  WrapperDecription,
  RemoveFileIconStyled,
  UpdatingBtn,
  WrapperFileUpload,
  UploadIconStyled,
  WrapperUploadedResult,
  Result,
  WrapperUploadedError,
  ThirdText,
  WrapperUpdatingBtn,
  SplashStyled,
  ContainerProgressBar,
  WrapperProgressBar,
} from "./FileUploader.style";
import ConfirmModal from "../../ConfirmModal";
import { useHistory } from "react-router-dom";

const FileUploader = ({ fileCSV, setFileCSV, handleOnChangeFile }: any) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });

  const isFileCSV = Object.keys(fileCSV).length > 0;
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isShowModal, changeModalVisibility] = useState<boolean>(false);
  const [target, setTargetLocation] = useState<any>("");
  const [uploadResult, setUploadResult] = useState<any>({});
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
  const history = useHistory();
  const unblockHandle = useRef<any>();

  useEffect(() => {
    setIsShowLoader(false);
  }, []);

  const handleRemoveFile = () => {
    setFileCSV({});
    setIsVisible(false);
    setUploadResult({});
    setDownloadInfo({
      progress: 0,
      completed: false,
      total: 0,
      loaded: 0,
    });
  };
  function handleShowModal() {
    changeModalVisibility(true);
  }
  function onCancel() {
    changeModalVisibility(false);
  }

  function handleConfirm() {
    if (unblockHandle) {
      unblockHandle.current();
    }
    history.push(target);
  }

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleResponse = (data: {
    status: number;
    result: any;
    message: string;
  }) => {
    const { status, result, message } = data;
    if (status === httpStatus.StatusOK) {
      setDownloadInfo((info) => ({
        ...info,
        progress: 0,
        completed: true,
      }));
      setIsShowLoader(false);
      setFileCSV({});
      setUploadResult({});
      notification.success({
        placement: "bottomRight",
        message: t1("temporarily-saved"),
        description: t1("saved-data-can-confirm"),
        icon: <SendCompleteIcon />,
      });
    } else {
      setIsShowLoader(false);
      setUploadResult(result || {});
      setFileCSV({});
      setDownloadInfo({
        total: 0,
        loaded: 0,
        progress: 0,
        completed: false,
      });

      if (status >= httpStatus.StatusInternalServerError) {
        notification.open({
          placement: "bottomRight",
          message: "",
          icon: <ConfirmFailIcon height="53px" width="53px" />,
          description:
            message === "これは無効なCSVフォーマットです。" ? (
              <div style={{ marginRight: 20 }}>
                <div>{t1("error-upload-1")}</div>
                <div>{t1("error-upload-2")}</div>
              </div>
            ) : (
              message
            ),
        });
      }
    }
  };

  const handleUploadFile = async () => {
    setIsShowLoader(true);

    const options = {
      onDownloadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };

    const formData = new FormData();
    let file = fileCSV.file;
    file = new File(
      [file.slice(0, file.size, "application/octet-stream")],
      file.name,
      { type: "application/octet-stream" }
    );
    formData.append("file", file);
    const { data } = await httpClient.post(
      "web_api_v1/school/classes/csv_upload",
      formData,
      {
        ...options,
      }
    );

    handleResponse(data);
  };

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation: any) => {
      if (isFileCSV) {
        handleShowModal();
        setTargetLocation(targetLocation);
        return false;
      }
    });
    return function () {
      unblockHandle.current.current && unblockHandle.current.current();
    };
  }, [fileCSV]);

  return (
    <WrapperFileUpload>
      {isShowLoader && (
        <WrapperProgressBar>
          <ContainerProgressBar>
            <Loading />
          </ContainerProgressBar>
        </WrapperProgressBar>
      )}
      <WrapperUpload>
        <DraggerStyled
          onChange={handleOnChangeFile}
          openFileDialogOnClick={false}
          beforeUpload={(file: any) => {
            return false;
          }}
        >
          {!isFileCSV && (
            <WrapperDecription>
              <UploadIcon />
              <div style={{ position: "relative", top: -4 }}>
                <PrimaryText>{t("file-upload-csv")}</PrimaryText>
                <SecondaryText>{t1("file-here")}</SecondaryText>
                <ThirdText>{t("drag-and-drop")}</ThirdText>
              </div>
            </WrapperDecription>
          )}
          {isFileCSV && (
            <WrapperFile>
              <PDFIcon fill="#FB4E4E" />
              <div>{fileCSV.name}</div>
              <RemoveFileIconStyled
                onClick={() => {
                  handleOpenModal();
                }}
              />
            </WrapperFile>
          )}
        </DraggerStyled>
        <Upload
          accept=".csv"
          onChange={handleOnChangeFile}
          beforeUpload={(file: any) => {
            return false;
          }}
        >
          <UploadFileBtn
            background="#2F8CAE"
            color="#fff"
            name={t("select-a-file-and-upload")}
            border="none"
            bdr="6px"
            fontWeight={700}
            lineHeight="20px"
            icon={<UploadIconStyled width="22px" height="22px" />}
            disabled={isFileCSV}
          />
        </Upload>
      </WrapperUpload>
      <WrapperUpdatingBtn>
        <UpdatingBtn
          disabled={!isFileCSV}
          name={t1("screen-update")}
          background="#2AC769"
          bdr="6px"
          border="none"
          color="#FFFFFF"
          fontSize={16}
          lineHeight="23px"
          fontWeight={700}
          onClick={handleUploadFile}
        />
        {Object.keys(uploadResult).length > 0 && (
          <WrapperUploadedResult>
            <Result>
              <span>全件：{uploadResult.total_records}件</span>{" "}
              <SplashStyled>/</SplashStyled>
              <span>成功：{uploadResult.success_records}件</span>{" "}
              <SplashStyled>/</SplashStyled>
              <span>失敗：{uploadResult.failed_records}件</span>
            </Result>
            <WrapperUploadedError>
              {uploadResult.errors.map((error: string, idx: number) => (
                <div key={idx}>{error}</div>
              ))}
            </WrapperUploadedError>
          </WrapperUploadedResult>
        )}
      </WrapperUpdatingBtn>
      {isVisible && (
        <ConfirmModal
          isVisible={isVisible}
          handleCancel={handleCancel}
          handleOk={handleRemoveFile}
        >
          <div>{t1("delete-upload-file-message")}</div>
        </ConfirmModal>
      )}
      {isShowModal && (
        <ConfirmModal
          isVisible={isShowModal}
          handleCancel={onCancel}
          handleOk={handleConfirm}
        >
          <div>{t1("leave-page-before-save")}</div>
        </ConfirmModal>
      )}
    </WrapperFileUpload>
  );
};

export default FileUploader;
