import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { Grid, notification } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import { subDays, addDays } from "date-fns";
import ja from "date-fns/locale/ja";

import { Input, Button, Select, Box, TextArea } from "components";
import { CircleDelete, SendCompleteIcon, ConfirmFailIcon } from "icons";
import { localStorageHelper, handleConvertDay } from "utils";
import { DatePickerTwoMonths } from "./components";
import { ISchedules, ScheduleData } from "models/vaccine/schedules";
import {
  ReservationsActionCreators,
  TodaySchedulesActionCreators,
  SaveScheduleActionCreators,
  UpdateScheduleActionCreators,
  GetSlotsDataActionCreators,
  DeleteSlotActionCreators,
} from "redux/rootActions";
import { reservationsResponse } from "redux/vaccine/reservations/reservationStates";
import { todaySchedulesResponse } from "redux/vaccine/todaySchedules/todaySchedulesStates";
import { slotsResponse } from "redux/vaccine/slots/slotsStates";
import {
  selectSlotErrorMessage,
  selectIsSlotDeleted,
} from "redux/vaccine/deleteSlot/deleteSlotStates";
import {
  selectSaveErrorMessage,
  selectIsSaved,
} from "redux/vaccine/saveSchedule/saveScheduleStates";
import {
  selectUpdateErrorMessage,
  selectIsUpdateSaved,
} from "redux/vaccine/updateSchedule/updateScheduleStates";
import {
  CheckboxStyled,
  WapperBtnItem,
  WapperBlockItem,
  DivBlockItem,
  WapperFilter,
  WrapCheckboxFilter,
  WapperCheckboxItem,
  GroupBtnFilter,
  DivInput,
  WrapperContent,
  DivContent,
  WapperSelectTimeForItem,
  WrapperAction,
  FirstBlock,
  SecondBlock,
  FixSelect,
  FirstInputStyled,
  SecondBlockTop,
  SecondBlockBottom,
  WapperDatePicker,
} from "./Vaccination.style";
import ConfirmModal from "screens/userList/ConfirmModal";

registerLocale("ja", ja);

interface CustomScheduleDatas extends ISchedules {
  is_edit?: boolean;
  start_hour?: string;
  start_minute?: string;
  end_hour?: string;
  end_minute?: string;
  venue_id?: number;
}

interface IVenues {
  id: number;
  value: number;
  name: string;
}

type FormValues = {
  schedules: CustomScheduleDatas[];
};

const schema = yup.object().shape({
  title: yup.string().trim().required("title-required"),
  max_slot_size: yup.string().trim().required("max-slot-required"),
  start_time_hour: yup
    .string()
    .required("start-time-required")
    .typeError("start-time-required"),
  start_time_minute: yup
    .string()
    .required("start-time-required")
    .typeError("start-time-required"),
  end_time_hour: yup
    .string()
    .required("start-time-required")
    .typeError("start-time-required"),
  end_time_minute: yup
    .string()
    .required("start-time-required")
    .typeError("start-time-required"),
  venue_id: yup.string().required("venue-required"),
});

const { useBreakpoint } = Grid;

const VaccinationAppointment: React.FC = () => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });

  const dispatch = useDispatch();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const reservationsData = useSelector(reservationsResponse);
  const scheduleCreated = useSelector(selectIsSaved);
  const scheduleUpdated = useSelector(selectIsUpdateSaved);
  const slotDeleted = useSelector(selectIsSlotDeleted);
  const todaySchedulesData = useSelector(todaySchedulesResponse);
  const saveScheduleError = useSelector(selectSaveErrorMessage);
  const updateScheduleError = useSelector(selectUpdateErrorMessage);
  const deleteSlotError = useSelector(selectSlotErrorMessage);
  const slotsData = useSelector(slotsResponse);

  const [startDate, setStartDate] = useState(new Date());
  const [monthDate, setMonthDate] = useState(new Date());
  const [hourOptions, setHourOptions] = useState<any>();
  const [minuteOptions, setMinuteOptions] = useState<any>();
  const [venueOptions, setVenueOptions] = useState<IVenues[]>();
  const [scheduleDatas, setScheduleDatas] = useState<CustomScheduleDatas[]>([]);
  const [startTimeError, setStartTimeError] = React.useState<boolean>(false);
  const [reservationHighlights, setReservationHighlights] = useState<any>();

  const { control, reset } = useForm<FormValues>({
    defaultValues: {
      schedules: scheduleDatas,
    },
  });
  const {
    handleSubmit,
    control: control1,
    reset: reset1,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      start_end_date: undefined,
      title: "",
      start_time_hour: undefined,
      start_time_minute: undefined,
      end_time_hour: undefined,
      end_time_minute: undefined,
      block_status: false,
      venue_id: undefined,
      max_slot_size: "",
    },
    resolver: yupResolver(schema),
  });
  const { fields, update } = useFieldArray({
    control,
    name: "schedules",
  });

  const screens = useBreakpoint();

  useEffect(() => {
    if (
      errors.start_time_hour ||
      errors.start_time_minute ||
      errors.end_time_hour ||
      errors.end_time_minute
    ) {
      setStartTimeError(true);
    } else {
      setStartTimeError(false);
    }
  }, [
    errors.start_time_hour,
    errors.start_time_minute,
    errors.end_time_hour,
    errors.end_time_minute,
  ]);

  useEffect(() => {
    dispatch(SaveScheduleActionCreators.handleResetDataAction());
    dispatch(UpdateScheduleActionCreators.handleResetDataAction());
    dispatch(DeleteSlotActionCreators.handleResetAction());
    dispatch(GetSlotsDataActionCreators.getSlotsAction());
  }, []);

  useEffect(() => {
    if (scheduleUpdated || scheduleCreated || slotDeleted) {
      dispatch(
        TodaySchedulesActionCreators.getTodaySchedulesAction({
          selected_date: startDate,
        })
      );
      dispatch(
        ReservationsActionCreators.getReservationsAction({
          start: startDate,
        })
      );
      dispatch(SaveScheduleActionCreators.handleResetDataAction());
      dispatch(UpdateScheduleActionCreators.handleResetDataAction());
      dispatch(DeleteSlotActionCreators.handleResetAction());
      reset1();
    }
  }, [scheduleUpdated, scheduleCreated, slotDeleted]);

  useEffect(() => {
    if (reservationsData) {
      setReservationHighlights([
        ...reservationsData.result.schedule_section_json.this_month,
        ...reservationsData.result.schedule_section_json.next_month,
      ]);
    }
  }, [reservationsData]);

  useEffect(() => {
    dispatch(
      ReservationsActionCreators.getReservationsAction({
        start: startDate,
      })
    );
    dispatch(
      TodaySchedulesActionCreators.getTodaySchedulesAction({
        selected_date: startDate,
      })
    );
  }, [startDate]);

  useEffect(() => {
    dispatch(
      ReservationsActionCreators.getReservationsAction({
        start: monthDate,
      })
    );
    dispatch(
      TodaySchedulesActionCreators.getTodaySchedulesAction({
        selected_date: monthDate,
      })
    );
  }, [monthDate]);

  useEffect(() => {
    if (slotsData && Object.keys(slotsData).length) {
      setHourOptions(
        slotsData?.result.hours_array.map((item, index: number) => {
          return {
            id: index,
            value: item,
            name: item,
          };
        })
      );
      setMinuteOptions(
        slotsData?.result.minutes_array.map((item, index: number) => {
          return {
            id: index,
            value: item,
            name: item,
          };
        })
      );
      setVenueOptions(
        slotsData?.result.venue_options.map((item: any, index: number) => {
          return {
            id: index,
            value: item[1],
            name: item[0],
          };
        })
      );
    }
  }, [slotsData]);

  useEffect(() => {
    if (scheduleCreated) {
      notification.success({
        placement: "bottomRight",
        message: t("schedule-create"),
        icon: <SendCompleteIcon />,
      });
    }
  }, [scheduleCreated]);

  useEffect(() => {
    if (scheduleUpdated) {
      notification.success({
        placement: "bottomRight",
        message: t("schedule-update"),
        icon: <SendCompleteIcon />,
      });
    }
  }, [scheduleUpdated]);

  useEffect(() => {
    if (slotDeleted) {
      notification.success({
        placement: "bottomRight",
        message: t("schedule-delete"),
        icon: <SendCompleteIcon />,
      });
    }
  }, [slotDeleted]);

  useEffect(() => {
    if (saveScheduleError) {
      notification.error({
        placement: "bottomRight",
        message: saveScheduleError[Object.keys(saveScheduleError)[0]][0]
          ? saveScheduleError[Object.keys(saveScheduleError)[0]][0]
          : "Error",
        icon: <ConfirmFailIcon width={53} height={53} />,
        duration: 20,
      });
      dispatch(SaveScheduleActionCreators.handleResetDataAction());
      reset1();
    }
  }, [saveScheduleError]);

  useEffect(() => {
    if (deleteSlotError) {
      notification.error({
        placement: "bottomRight",
        message: deleteSlotError[Object.keys(deleteSlotError)[0]][0]
          ? deleteSlotError[Object.keys(deleteSlotError)[0]][0]
          : "Error",
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(DeleteSlotActionCreators.handleResetAction());
    }
  }, [deleteSlotError]);

  useEffect(() => {
    if (updateScheduleError) {
      if (
        updateScheduleError[Object.keys(updateScheduleError)[0]][0] ===
        "は数値で入力してください"
      ) {
        notification.error({
          placement: "bottomRight",
          message: t("updated-error"),
          icon: <ConfirmFailIcon width={53} height={53} />,
        });
      } else {
        notification.error({
          placement: "bottomRight",
          message: updateScheduleError[Object.keys(updateScheduleError)[0]][0]
            ? updateScheduleError[Object.keys(updateScheduleError)[0]][0]
            : "Error",
          icon: <ConfirmFailIcon width={53} height={53} />,
        });
      }

      dispatch(UpdateScheduleActionCreators.handleResetDataAction());
      reset();
    }
  }, [updateScheduleError]);

  const returnTime = (time: string, format?: string) => {
    if (format) {
      return moment(time).format(format).toString();
    }
    return moment(time).toString();
  };

  const returnVenue = (name: string) => {
    return venueOptions?.find((item) => item.name === name)?.value;
  };

  useEffect(() => {
    const newScheduleDatas: CustomScheduleDatas[] = [];
    if (todaySchedulesData && todaySchedulesData?.result) {
      todaySchedulesData?.result.map((item) => {
        newScheduleDatas.push({
          id: item.id,
          is_edit: false,
          title: item.title,
          venue_name: item.venue_name,
          venue_id: returnVenue(item.venue_name),
          start_time: returnTime(item.start_time, "HH:mm"),
          end_time: returnTime(item.end_time, "HH:mm"),
          start_hour: returnTime(item.start_time, "HH:mm").split(":")[0],
          start_minute: returnTime(item.start_time, "HH:mm").split(":")[1],
          end_hour: returnTime(item.end_time, "HH:mm").split(":")[0],
          end_minute: returnTime(item.end_time, "HH:mm").split(":")[1],
          max_slot_size: item.max_slot_size,
          created_at: returnTime(item.created_at, "YYYY-MM-DD hh:mm"),
          updated_at: returnTime(item.updated_at, "YYYY-MM-DD hh:mm"),
          block_status: item.block_status,
          count: item.count,
        });
      });

      setScheduleDatas(newScheduleDatas);
    } else {
      setScheduleDatas([]);
    }
  }, [todaySchedulesData]);

  const BlockItem = ({ datas, idx, update, control }: any) => {
    const item = useWatch({ control, name: `schedules.${idx}` });
    const [isVisible, setIsVisible] = useState(false);
    let color = "#2AC769";
    if (datas.status == 2) {
      color = "#EBC300";
    } else if (datas.status == 3) {
      color = "#FB4E4E";
    }

    const onDelete = () => {
      if (item) {
        dispatch(
          DeleteSlotActionCreators.deleteSlotAction({
            id: item.id,
          })
        );
        setIsVisible(false);
      }
    };

    return (
      <DivBlockItem>
        <p
          style={{ position: "absolute", right: "-10px", top: "-10px" }}
          onClick={() => {
            setIsVisible(true);
          }}
        >
          <a>
            <CircleDelete />
          </a>
        </p>
        <div
          style={{
            color: color,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 24px 0px",
          }}
          className="item-left item-left-block"
        >
          <p>
            {item.count} {t("the-number-of-people")} / {item.max_slot_size}{" "}
            {t("the-number-of-people")}
          </p>
        </div>

        <WrapperContent className="wrapper-block">
          <DivContent>{item.title}</DivContent>
          <div
            style={{
              fontSize: "18px",
              lineHeight: "18px",
              display: "flex",
            }}
            className="content-location"
          >
            <p>
              {t("venue-label")}：<b>{item.venue_name}</b>
            </p>
          </div>
          <p style={{ fontSize: "18px", lineHeight: "18px" }}>
            {t("time")}：
            <b>
              {item.start_hour}:{item.start_minute}〜{item.end_hour}:
              {item.end_minute}
            </b>
          </p>
        </WrapperContent>
        <WapperBtnItem>
          <div className="div-btn">
            <Button
              className="single-btn"
              background={"#2AC769"}
              color={"#fff"}
              padding={"7px 40px 9px"}
              name={t("edit")}
              border={"none"}
              bdr={"6px"}
              width={"112px"}
              fontSize={16}
              lineHeight={"16px"}
              onClick={() =>
                update(idx, {
                  ...item,
                  is_edit: !item.is_edit,
                })
              }
            />
          </div>
        </WapperBtnItem>
        {isVisible && (
          <ConfirmModal
            isVisible={isVisible}
            handleOk={onDelete}
            handleCancel={() => {
              setIsVisible(false);
            }}
          >
            <Trans i18nKey="user-list.delete-user-description">
              「{item.title}」を削除します。
            </Trans>
            <div>{t1("are-you-sure")}</div>
          </ConfirmModal>
        )}
      </DivBlockItem>
    );
  };

  const onSubmit = (data: any) => {
    const params: ScheduleData = {
      id: 142,
      locale: currentLanguage,
      schedule_section: {
        start_end_date: moment(startDate).format("YYYY-MM-DD"),
        title: data.title,
        start_time_hour: data.start_time_hour,
        start_time_minute: data.start_time_minute,
        end_time_hour: data.end_time_hour,
        end_time_minute: data.end_time_minute,
        block_status: data.block_status,
        venue_id: data.venue_id,
        max_slot_size: data.max_slot_size,
      },
    };
    dispatch(SaveScheduleActionCreators.saveScheduleAction(params));
  };

  useEffect(() => {
    reset({ schedules: scheduleDatas });
  }, [scheduleDatas]);

  const BlockItemEdit = ({ datas, idx, control, update }: any) => {
    const item = useWatch({ control, name: `schedules.${idx}` });
    const [schedule, setSchedule] = useState(item);
    let color = "#2AC769";
    if (datas.status == 2) {
      color = "#EBC300";
    } else if (datas.status == 3) {
      color = "#FB4E4E";
    }

    const onUpdateSchedule = (data: any) => {
      const params: ScheduleData = {
        id: data.id,
        locale: currentLanguage,
        schedule_section: {
          start_end_date: moment(startDate).format("YYYY-MM-DD"),
          title: data.title,
          start_time_hour: data.start_time_hour || item.start_hour,
          start_time_minute: data.start_time_minute || item.start_minute,
          end_time_hour: data.end_time_hour || item.end_hour,
          end_time_minute: data.end_time_minute || item.end_minute,
          block_status: data.block_status,
          venue_id: parseInt(data.venue_id) || item.venue_id,
          max_slot_size: data.max_slot_size,
        },
      };
      dispatch(UpdateScheduleActionCreators.updateScheduleAction(params));
    };

    return (
      <DivBlockItem>
        <div
          style={{
            color: color,
          }}
          className="item-left"
        >
          <div>
            <p>
              {datas.count}
              {t("the-number-of-people")} /
            </p>
            <p style={{ display: "flex" }}>
              <Input
                lhLabel={"16px"}
                fsLabel={14}
                bdr={6}
                height={31}
                width={138}
                fs={16}
                defaultValue={item.max_slot_size}
                onChange={(event: any) =>
                  setSchedule({
                    ...schedule,
                    max_slot_size: event.target.value,
                  })
                }
              />
              <span
                style={{
                  fontSize: "16px",
                  lineHeight: "16px",
                  padding: "6px 0px 0px 4px",
                }}
              >
                {t("the-number-of-people")}
              </span>
            </p>
          </div>
        </div>
        <WapperCheckboxItem>
          <WrapperContent>
            <CheckboxStyled
              checked={schedule.block_status}
              onChange={() =>
                setSchedule({
                  ...schedule,
                  block_status: !schedule.block_status,
                })
              }
            >
              {t("stop-reservation-or-cancel")}
            </CheckboxStyled>
            <DivInput>
              <Input
                fsLabel={14}
                bdr={6}
                defaultValue={item.title}
                onChange={(event: any) =>
                  setSchedule({
                    ...schedule,
                    title: event.target.value,
                  })
                }
              />
              <TextArea defaultValue={item.title} />
            </DivInput>
            <div style={{ fontSize: "18px" }}>
              <WapperSelectTimeForItem>
                <div
                  style={{
                    fontSize: "18px",
                    display: "flex",
                  }}
                  className="div-left-item"
                >
                  <span> 会場：</span>
                  <p>
                    <Select
                      placeholder="選択してください"
                      options={venueOptions}
                      width={"138px"}
                      marginForm={"0px"}
                      defaultValue={item.venue_name}
                      onChange={(value: any) => {
                        setSchedule({
                          ...schedule,
                          venue_id: value,
                        });
                      }}
                    />
                  </p>
                </div>
                <div className="div-right-item">
                  <div>
                    <span style={{ paddingTop: "3px" }}>時間：</span>
                  </div>
                  <div className="bor-div-time">
                    <div className="dleft">
                      <div
                        style={{
                          float: "left",
                          marginRight: "2px",
                          width: "65px",
                        }}
                      >
                        <Select
                          options={hourOptions}
                          width={"65px"}
                          placeholder="00"
                          defaultValue={item.start_hour}
                          onChange={(value: any) =>
                            setSchedule({
                              ...schedule,
                              start_time_hour: value,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          float: "left",
                          marginLeft: "2px",
                          width: "65px",
                        }}
                      >
                        <Select
                          options={minuteOptions}
                          width={"65px"}
                          placeholder="00"
                          defaultValue={item.start_minute}
                          onChange={(value: any) =>
                            setSchedule({
                              ...schedule,
                              start_time_minute: value,
                            })
                          }
                        />
                      </div>
                      <span style={{ padding: "0px 5px" }}>〜</span>
                    </div>
                    <div className="dright">
                      <div
                        style={{
                          float: "left",
                          marginRight: "2px",
                          width: "65px",
                        }}
                      >
                        <Select
                          options={hourOptions}
                          width={"65px"}
                          placeholder="00"
                          defaultValue={item.end_hour}
                          onChange={(value: any) =>
                            setSchedule({
                              ...schedule,
                              end_time_hour: value,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          float: "left",
                          marginLeft: "2px",
                          width: "65px",
                        }}
                      >
                        <Select
                          options={minuteOptions}
                          width={"65px"}
                          placeholder="00"
                          defaultValue={item.end_minute}
                          onChange={(value: any) => {
                            setSchedule({
                              ...schedule,
                              end_time_minute: value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </WapperSelectTimeForItem>
            </div>
          </WrapperContent>
          <WapperBtnItem>
            <div className="div-btn">
              <Button
                className="btn-order-left"
                background={"#2AC769"}
                color={"#fff"}
                padding={"7px 40px 9px"}
                name={"保存"}
                border={"none"}
                bdr={"6px"}
                width={"112px"}
                margin={"10px 0px 0px 0px"}
                fontSize={16}
                lineHeight={"16px"}
                onClick={() => {
                  update(idx, schedule);
                  onUpdateSchedule(schedule);
                }}
              />
              <Button
                className="btn-order-right"
                background={"#E0E0E0"}
                color={"#fff"}
                padding={"6px 16px 10px"}
                name={"キャンセル"}
                border={"none"}
                bdr={"6px"}
                width={"112px"}
                fontSize={16}
                lineHeight={"16px"}
                onClick={() =>
                  update(idx, {
                    ...item,
                    is_edit: !item.is_edit,
                  })
                }
              />
            </div>
          </WapperBtnItem>
        </WapperCheckboxItem>
      </DivBlockItem>
    );
  };

  return (
    <Box
      title={t3("inoculation-reservation-label")}
      padding={screens.md ? "16px" : "8px"}
    >
      <WapperDatePicker>
        <div className="div-date-picker-desktop">
          <DatePickerTwoMonths
            locale={currentLanguage}
            startDate={startDate}
            onChange={(date: Date) => setStartDate(date)}
            datas={reservationHighlights || []}
            monthsShown={2}
            setMonthDate={setMonthDate}
          />
        </div>
        <div className="div-date-picker-mobile">
          <DatePickerTwoMonths
            locale={currentLanguage}
            startDate={startDate}
            onChange={(date: Date) => setStartDate(date)}
            datas={reservationHighlights || []}
            monthsShown={1}
            setMonthDate={setMonthDate}
          />
        </div>
      </WapperDatePicker>
      <WapperFilter>
        <div style={{ position: "relative" }}>
          <button
            className="btn-previous"
            onClick={() => setStartDate(subDays(new Date(startDate), 1))}
          >
            <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"></span>
          </button>
          <p className="pTittle">
            {handleConvertDay(
              moment(startDate).format("YYYY-MM-DD"),
              currentLanguage
            ) +
              "（" +
              t(moment(startDate).format("ddd")) +
              "）"}
          </p>
          <button
            className="btn-next"
            onClick={() => setStartDate(addDays(new Date(startDate), 1))}
          >
            <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"></span>
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <WrapperAction>
            <FirstBlock>
              <WrapCheckboxFilter>
                <Controller
                  control={control1}
                  name="block_status"
                  render={({ field: { value, onChange } }) => (
                    <CheckboxStyled checked={value} onChange={onChange}>
                      {t("stop-reservation-or-cancel")}
                    </CheckboxStyled>
                  )}
                />
              </WrapCheckboxFilter>

              <Controller
                control={control1}
                name="title"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    fsLabel={16}
                    lhLabel={"16px"}
                    label={t("reservation-title")}
                    bdr={6}
                    height={32}
                    onChange={onChange}
                    value={value}
                    error={t(error?.message)}
                  />
                )}
              />
            </FirstBlock>

            <SecondBlock>
              <SecondBlockTop>
                <Controller
                  control={control1}
                  name="max_slot_size"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FirstInputStyled
                      fsLabel={16}
                      lhLabel={"16px"}
                      label={t("applicants-number")}
                      bdr={6}
                      height={31}
                      onChange={onChange}
                      value={value}
                      error={t(error?.message)}
                      type={"number"}
                    />
                  )}
                />
                <Controller
                  control={control1}
                  name="venue_id"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      label={t("venue-label")}
                      fsLabel={16}
                      lhLabel={"16px"}
                      placeholder={t1("please-select")}
                      options={venueOptions}
                      width={"168px"}
                      onChange={onChange}
                      value={value}
                      error={t(error?.message)}
                    />
                  )}
                />
              </SecondBlockTop>

              <SecondBlockBottom>
                <div>
                  <Controller
                    control={control1}
                    name="start_time_hour"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        options={hourOptions}
                        placeholder="00"
                        width={"65px"}
                        label={t("start")}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />

                  <FixSelect>
                    <Controller
                      control={control1}
                      name="start_time_minute"
                      render={({ field: { value, onChange } }) => (
                        <Select
                          options={minuteOptions}
                          placeholder="00"
                          width={"65px"}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </FixSelect>
                  <div style={{ marginTop: 25 }}>〜</div>
                </div>

                <div style={{ display: "flex", gap: 8 }}>
                  <Controller
                    control={control1}
                    name="end_time_hour"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        options={hourOptions}
                        placeholder="00"
                        width={"65px"}
                        label={t("end-time")}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <FixSelect>
                    <Controller
                      control={control1}
                      name="end_time_minute"
                      render={({ field: { value, onChange } }) => (
                        <Select
                          options={minuteOptions}
                          placeholder="00"
                          width={"65px"}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </FixSelect>
                </div>
                {startTimeError && (
                  <div className="start-time-error">
                    {t("start-time-required")}
                  </div>
                )}
              </SecondBlockBottom>
            </SecondBlock>

            <GroupBtnFilter>
              <Button
                background={"#E0E0E0"}
                color={"#fff"}
                padding={"7px 15px 9px"}
                name={t("reset")}
                border={"none"}
                bdr={"6px"}
                fontSize={16}
                lineHeight={"16px"}
                onClick={() => reset1()}
              />
              <Button
                type="submit"
                background={"#2AC769"}
                color={"#fff"}
                padding={"7px 15px 9px"}
                name={t("keep")}
                border={"none"}
                bdr={"6px"}
                fontSize={16}
                lineHeight={"16px"}
              />
            </GroupBtnFilter>
          </WrapperAction>
        </form>
      </WapperFilter>
      {fields && (
        <div style={{ marginTop: "32px", padding: "0px 8px 0px" }}>
          <WapperBlockItem>
            {fields.map((field, index) => {
              if (field.is_edit) {
                return (
                  <BlockItemEdit
                    key={field.id}
                    datas={field}
                    idx={index}
                    update={update}
                    control={control}
                  />
                );
              } else {
                return (
                  <BlockItem
                    key={field.id}
                    datas={field}
                    idx={index}
                    update={update}
                    control={control}
                  />
                );
              }
            })}
          </WapperBlockItem>
        </div>
      )}
    </Box>
  );
};

export default VaccinationAppointment;
