import {
  Action,
  ActionType,
  CSVDownloadQuestionResponse,
} from "models/questionnaires/downloadCSVQuestion";
import createReducer from "redux/createReducer";

export interface DownloadCSVQuestionReducerType {
  loading: boolean;
  error?: string;
  downloadCSVQuestionResponse?: CSVDownloadQuestionResponse;
}

const defaultState: DownloadCSVQuestionReducerType = {
  loading: false,
  error: undefined,
  downloadCSVQuestionResponse: undefined,
};

export const downloadCSVQuestionReducer =
  createReducer<DownloadCSVQuestionReducerType>(defaultState, {
    [ActionType.CSV_DOWNLOAD_QUESTION](state: DownloadCSVQuestionReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CSV_DOWNLOAD_QUESTION_ERROR](
      state: DownloadCSVQuestionReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CSV_DOWNLOAD_QUESTION_SUCCESS](
      state: DownloadCSVQuestionReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        downloadCSVQuestionResponse: action.payload,
      };
    },

    [ActionType.CSV_DOWNLOAD_QUESTION_RESET_DATA](
      state: DownloadCSVQuestionReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        downloadCSVQuestionResponse: [],
      };
    },
  });
