import { appconf } from "app/appconfig";
import { debug_send_log } from "./appdbg";
import httpClient from "configs/httpClient";

function retry_exec(
  [method, s_tick, url, params]: [string, number, string, any],
  retry_interval: Array<number>,
  resolve: (r: any) => void,
  reject: (r: any) => void,
  fn: () => Promise<void>
) {
  function rexec(
    retry_interval: Array<number>,
    resolve: (r: any) => void,
    reject: (r: any) => void,
    fn: () => Promise<void>
  ) {
    let retry_count = 0;
    fn()
      .then((r) => {
        if (appconf.print_request) {
          console.log(
            retry_count,
            (Date.now() - s_tick) / 1000.0,
            method,
            url,
            params
          );
        }
        resolve(r);
      })
      .catch((r) => {
        if (retry_interval.length <= 0) {
          debug_send_log(url, r);
          reject(r);
        } else {
          const interval = retry_interval.shift();
          retry_count++;
          //console.log("interval:",interval);
          setTimeout(() => {
            rexec(retry_interval, resolve, reject, fn);
          }, interval);
        }
      });
  }
  rexec(retry_interval, resolve, reject, fn);
}

export const appio = {
  get: (url: string, params: any) => {
    return new Promise((resolve, reject) => {
      retry_exec(
        ["get", Date.now(), url, params],
        [...appconf.retry_interval],
        resolve,
        reject,
        () => {
          return httpClient.get(url, params);
        }
      );
    });
  },
  post: (url: string, params: any) => {
    return new Promise((resolve, reject) => {
      retry_exec(
        ["post", Date.now(), url, params],
        [...appconf.retry_interval],
        resolve,
        reject,
        () => {
          return httpClient.post(url, params);
        }
      );
    });
  },
  put: (url: string, params: any) => {
    return new Promise((resolve, reject) => {
      retry_exec(
        ["put", Date.now(), url, params],
        [...appconf.retry_interval],
        resolve,
        reject,
        () => {
          return httpClient.put(url, params);
        }
      );
    });
  },
  delete: (url: string, params: any) => {
    return new Promise((resolve, reject) => {
      retry_exec(
        ["delete", Date.now(), url, params],
        [...appconf.retry_interval],
        resolve,
        reject,
        () => {
          return httpClient.delete(url, params);
        }
      );
    });
  },
};
