import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { Input, Button, Box } from "components";
import { httpStatus } from "configs/httpStatus";

import {
  VenuesListActionCreators,
  CreateVenuesActionCreators,
  DeleteVenuesActionCreators,
} from "redux/rootActions";
import { venuesListResponse } from "redux/vaccine/venues/list/listStates";
import { createVenuesResponse } from "redux/vaccine/venues/createVenues/createVenuesStates";
import { updateVenuesResponse } from "redux/vaccine/venues/updateVenues/updateVenuesStates";
import { deleteVenuesResponse } from "redux/vaccine/venues/deleteVenues/deleteVenuesStates";
import { IVenue } from "models/vaccine/venuesList";

import { WrapperInput, WrapperContentSetting } from "./Vaccination.style";
import VenueItem from "./VenueItem";
import { notification } from "antd";
import { CircleDelete, SendCompleteIcon, ConfirmFailIcon } from "icons";

const schema = yup.object().shape({
  venueCode: yup
    .string()
    .required("venue-code-required")
    .min(8, "venue-code-short"),
  venueName: yup.string().required("venue-name-required").max(30, "max-text"),
});

const VenueSetting = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "layout" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });

  const dispatch = useDispatch();
  const venuesListData = useSelector(venuesListResponse);
  const createVenuesData = useSelector(createVenuesResponse);
  const updateVenuesData = useSelector(updateVenuesResponse);
  const deleteVenuesData = useSelector(deleteVenuesResponse);

  const [venueList, setVenueList] = useState<IVenue[]>([
    {
      id: 1,
      name: "string",
      venue_code: "venuecode1",
    },
  ]);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: { [x: string]: any }) => {
    const params = {
      venue: {
        name: data.venueName,
        venue_code: data.venueCode,
      },
    };
    dispatch(CreateVenuesActionCreators.createVenuesAction(params));
  };

  const handleGetVenueList = () => {
    dispatch(VenuesListActionCreators.venueListAction());
  };

  useEffect(() => {
    handleGetVenueList();
  }, []);

  useEffect(() => {
    if (venuesListData?.result && Object.keys(venuesListData?.result).length) {
      setVenueList(venuesListData?.result?.venues);
    }
  }, [venuesListData]);

  useEffect(() => {
    if (
      createVenuesData &&
      Object.keys(createVenuesData).length &&
      createVenuesData.status === httpStatus.StatusOK
    ) {
      notification.success({
        placement: "bottomRight",
        message: t1("create-venue"),
        icon: <SendCompleteIcon />,
      });
      handleGetVenueList();
      dispatch(CreateVenuesActionCreators.handleResetAction());
    }
  }, [createVenuesData]);

  useEffect(() => {
    if (
      updateVenuesData &&
      Object.keys(updateVenuesData).length &&
      updateVenuesData.status === httpStatus.StatusOK
    ) {
      notification.success({
        placement: "bottomRight",
        message: t1("update-venue"),
        icon: <SendCompleteIcon />,
      });
      handleGetVenueList();
    }
  }, [updateVenuesData]);

  useEffect(() => {
    if (
      deleteVenuesData &&
      Object.keys(deleteVenuesData).length &&
      deleteVenuesData.status === httpStatus.StatusOK
    ) {
      notification.success({
        placement: "bottomRight",
        message: t1("delete-venue"),
        icon: <SendCompleteIcon />,
      });
      handleGetVenueList();
      dispatch(DeleteVenuesActionCreators.handleResetAction());
    }
  }, [deleteVenuesData]);

  useEffect(() => {
    if (
      (createVenuesData &&
        Object.keys(createVenuesData).length &&
        createVenuesData.status !== httpStatus.StatusOK) ||
      (updateVenuesData &&
        Object.keys(updateVenuesData).length &&
        updateVenuesData.status !== httpStatus.StatusOK) ||
      (deleteVenuesData &&
        Object.keys(deleteVenuesData).length &&
        deleteVenuesData.status !== httpStatus.StatusOK)
    ) {
      notification.error({
        placement: "bottomRight",
        message: "Error",
      });
    }
  }, [createVenuesData, updateVenuesData, deleteVenuesData]);

  return (
    <Box title={t("venue-setting-label")} padding="16px 16px 24px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrapperInput>
          <Controller
            control={control}
            name="venueCode"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <Input
                fsLabel={16}
                lhLabel="16px"
                label={t1("venue-code")}
                bdr={8}
                height={32}
                onChange={onChange}
                error={t1(error?.message)}
              />
            )}
          />
          <Controller
            control={control}
            name="venueName"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <Input
                fsLabel={16}
                lhLabel="16px"
                label={t1("venue-name")}
                bdr={8}
                height={32}
                onChange={onChange}
                error={t1(error?.message)}
              />
            )}
          />
          <Button
            background="#2AC769"
            color="#FFFFFF"
            padding="9px 24px 7px"
            fontSize={16}
            lineHeight="16px"
            name={t1("venue-addition")}
            border="none"
            bdr="6px"
            width="112px"
          />
        </WrapperInput>
      </form>
      <WrapperContentSetting>
        {venueList.map((venue: any) => {
          return <VenueItem key={venue.id} venue={venue} />;
        })}
      </WrapperContentSetting>
    </Box>
  );
};

export default VenueSetting;
