import { RootState } from "../../rootReducer";

export const selectIsLoading = (state: RootState) =>
  state.sendInterviewRequest.loading;
export const selectIsSaved = (state: RootState) =>
  state.sendInterviewRequest.saved;
export const selectSendErrorMessage = (state: RootState) =>
  state.sendInterviewRequest.error;
export const sendInterviewRequestDataResponse = (state: RootState) =>
  state.sendInterviewRequest.sendInterviewRequestResponse;
