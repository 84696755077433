import { IResponse } from "models";

export enum ActionType {
  VACCINE_VENUES_LIST = "action/VACCINE_VENUES_LIST",
  VACCINE_VENUES_LIST_SUCCESS = "action/VACCINE_VENUES_LIST_SUCCESS",
  VACCINE_VENUES_LIST_ERROR = "action/VACCINE_VENUES_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IVenuesListResponse extends IResponse {
  result: {
    total: number;
    venues: IVenue[];
  };
}

export interface IVenue {
  id: number;
  name: string;
  venue_code: string;
}
