import {
  Action,
  ActionType,
  IAnswerHistoryResponse,
} from "models/questionnaires/answerHistory";
import createReducer from "../../createReducer";

export interface AnswerHistoryListReducerType {
  loading: boolean;
  error?: string;
  answerHistoryListResponse?: IAnswerHistoryResponse;
}

const defaultState: AnswerHistoryListReducerType = {
  loading: false,
  error: undefined,
  answerHistoryListResponse: undefined,
};

export const answerHistoryListReducer =
  createReducer<AnswerHistoryListReducerType>(defaultState, {
    [ActionType.ANSWER_HISTORY_LIST](state: AnswerHistoryListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.ANSWER_HISTORY_LIST_ERROR](
      state: AnswerHistoryListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.ANSWER_HISTORY_LIST_SUCCESS](
      state: AnswerHistoryListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        answerHistoryListResponse: action.payload,
      };
    },
  });
