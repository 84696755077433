import * as React from "react";

function LogoutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="#F2F2F2" />
      <path
        d="M17 13H28C28.5523 13 29 13.4477 29 14V27C29 27.5523 28.5523 28 28 28H17C16.4477 28 16 27.5523 16 27V14C16 13.4477 16.4477 13 17 13Z"
        fill="white"
        stroke="#2F8CAE"
      />
      <path
        d="M14.8166 15.613C14.6671 15.4907 14.4605 15.4654 14.2859 15.5482C14.1113 15.6309 14 15.8068 14 16V17.6617H9C8.72386 17.6617 8.5 17.8856 8.5 18.1617V22.8383C8.5 23.1144 8.72386 23.3383 9 23.3383H14V25C14 25.1932 14.1113 25.3691 14.2859 25.4518C14.4605 25.5346 14.6671 25.5093 14.8166 25.387L20.3166 20.887C20.4327 20.792 20.5 20.65 20.5 20.5C20.5 20.35 20.4327 20.208 20.3166 20.113L14.8166 15.613Z"
        fill="#2F8CAE"
        stroke="white"
        strokeLinejoin="round"
      />
      <path
        d="M22 11.3115C22 10.6514 22.6282 10.1724 23.2647 10.3472L30.2647 12.2687C30.699 12.388 31 12.7827 31 13.2331V27.7669C31 28.2173 30.699 28.612 30.2647 28.7313L23.2647 30.6528C22.6282 30.8276 22 30.3486 22 29.6885V11.3115Z"
        fill="#2F8CAE"
      />
      <ellipse cx="23.5" cy="20" rx="0.5" ry="1" fill="white" />
    </svg>
  );
}

export default LogoutIcon;
