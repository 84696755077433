import { IResponse } from "models";

export enum ActionType {
  // get student list data in CSV format to download it.
  GET_CSV_DOWNLOAD_STUDENT_LIST = "action/GET_CSV_DOWNLOAD_STUDENT_LIST",
  GET_CSV_DOWNLOAD_STUDENT_LIST_SUCCESS = "action/GET_CSV_DOWNLOAD_STUDENT_LIST_SUCCESS",
  GET_CSV_DOWNLOAD_STUDENT_LIST_ERROR = "action/GET_CSV_DOWNLOAD_STUDENT_LIST_ERROR",
  GET_CSV_DOWNLOAD_STUDENT_LIST_RESET = "action/GET_CSV_DOWNLOAD_STUDENT_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICSVDownloadStudentListData {
  class_id?: number;
  locale?: string;
}

export interface ICSVDownloadStudentListResponse extends IResponse {
  result: ICSVDownloadResult;
}

export interface ICSVDownloadResult {
  data: ICSVDownloadStudentItem[];
  headers: IHeaderCSVDownloadStudentItem[];
}

export interface IHeaderCSVDownloadStudentItem {
  label: string;
  key: string;
}

export interface ICSVDownloadStudentItem {
  id: number;
  name: string;
  current_grade: string;
  current_class: string;
  current_roll_number: number;
  new_grade: string;
  new_class: string;
  new_roll_number: number;
}
