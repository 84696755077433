import { IResponse } from "models";

export enum ActionType {
  GET_SYMTOM_CHART = "action/GET_SYMTOM_CHART",
  GET_SYMTOM_CHART_SUCCESS = "action/GET_SYMTOM_CHART_SUCCESS",
  GET_SYMTOM_CHART_ERROR = "action/GET_SYMTOM_CHART_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface SymtomPayload {
  aggregate_date_to: string;
}

export interface SymtomResponse extends IResponse {
  amounts: any;
}
