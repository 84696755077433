import styled from "styled-components";
import { theme } from "theme/theme";
import { Input, Button, Modal } from "components";
import { DropdownIcon, PlusCircleIcon, DeleteIcon } from "icons";
import { Checkbox } from "antd";

export const AddButton = styled.div`
  display: flex;
  border: 1px solid #1ab759;
  border-radius: 4px;
  width: fit-content;
  :hover {
    cursor: pointer;
  }
  input {
    width: 135px;
  }
  button {
    width: 64px;
  }
  ${theme.media.tablet} {
    max-width: 404px;
    input {
      width: 223px;
    }
  }
`;
export const WrapperItem = styled.div`
  min-width: 200px;
  max-width: 350px;
`;

export const WrapperDropdown = styled.div`
  position: absolute;
  background: #fff;
  border: 1px solid ${theme.colors.border};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  left: -10px;
  top: 27px;
  padding: 14px;
  z-index: 99;
  min-width: 84px;
  &::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 18px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 17px;
    width: 6px;
    height: 10px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const WrapperCloseIcon = styled.div`
  position: absolute;
  background: ${theme.colors.white};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: -9px;
  right: -10px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);
  font-size: ${theme.sizes.sm};
  padding-left: 2px;
  cursor: pointer;
`;

export const Text = styled.div`
  font-size: 14px;
  margin-left: 7px;
`;

export const Wrapper = styled.div`
  padding: 16px;
  ${theme.media.tablet} {
    padding: 24px;
  }
`;

export const ItemName = styled.div`
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.text.primary};
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const DropdownIconStyled = styled(DropdownIcon)`
  left: 15px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  height: 20px;
`;

export const DropdownIconOpenStyled = styled(DropdownIconStyled)`
  transform: rotate(-90deg);
`;

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.border};
  height: 38px;
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.bold};
  padding-left: 14px;
  padding-right: 70px;
`;

export const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 7px;
  svg {
    cursor: pointer;
  }
`;

export const PlusCircleIconStyled = styled(PlusCircleIcon)`
  margin-left: 4px;
`;

export const SaveBtnStyled = styled(Button)`
  padding: 3px 5px;
  min-width: 64px;
  width: 64px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 16px;
  height: 31px;
  ${theme.media.tablet} {
    margin-top: 26px;
  }
`;

export const WrapperSettings = styled.form`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  p {
    height: 30px;
    bottom: unset;
    margin-top: 2px;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  ${theme.media.tablet} {
    display: flex;
    input {
      margin: 4px 32px 2px 0;
    }
  }
  ${theme.media.laptopL} {
    input {
      margin: 20px 32px 2px 0;
    }
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 20px 16px 24px;
  }
  .ant-modal-footer {
    padding: 0px 16px 20px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 24px 24px 16px 24px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  button {
    width: 144px;
    padding: 3px;
  }
  button:last-child {
    width: 151px;
  }
  ${theme.media.tablet} {
    justify-content: flex-end;
    button:last-child {
      margin-left: 16px;
    }
    button {
      width: 112px !important;
    }
  }
`;

export const AddText = styled.div`
  font-size: 14px;
  line-height: 16px;
  min-width: 75px;
`;

export const InputStyled = styled(Input)`
  width: 100%;
`;

export const Form = styled.form`
  margin: 8px 0px;
  display: flex;
  flex-wrap: wrap;
`;

export const WrapperTreeView = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  ${theme.media.tablet} {
    flex-flow: row wrap;
    gap: 32px 77px;
  }
  ${theme.media.desktop} {
    gap: 32px;
  }
`;

export const TooltipErrorMessage = styled.div`
  position: absolute;
  background: ${theme.colors.white};
  color: ${theme.colors.error};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.regular};
  line-height: 16px;
  padding: 8px;
  z-index: 100;
  border-radius: 8px;
  border: 1px solid ${theme.colors.error};
  top: 37px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 18px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.error};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 17px;
    width: 6px;
    height: 10px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
  alignItems: "unset",
  outline: "none",
  fontSize: theme.sizes.xs,
  marginTop: 9,
  "&:hover": {
    ".ant-checkbox-inner": {
      borderColor: theme.colors.border,
    },
  },
  ".ant-checkbox": {
    top: "unset",
  },
  ".ant-checkbox-inner": {
    width: 15,
    height: 15,
    border: `2px solid ${theme.colors.border}`,
    top: 1,
  },
  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.colors.button,
    borderColor: `${theme.colors.button}!important`,
  },
  ".ant-checkbox-checked::after": {
    border: "none",
  },
  ".ant-checkbox-input:focus + .ant-checkbox-inner": {
    borderColor: theme.colors.border,
  },
  ".ant-checkbox + span": {
    paddingLeft: 5,
  },
}));

export const FormStyled = styled.form`
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
  ${theme.media.tablet} {
    width: 295px;
  }
`;

export const PlusIconStyled = styled(DeleteIcon)`
  transform: rotate(0deg);
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  transform: rotate(0deg);
  width: 18px;
  height: 18px;
  margin-left: 7px;
  margin-right: 3px;
`;

export const AddInputStyled = styled.input`
  height: 31px;
  border-radius: 8px;
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  padding: 0px 12px;
  :focus {
    border: 1px solid ${theme.colors.outline};
  }
  outline: none;
  box-shadow: inset 0px 2px 4px rgb(0 0 0 / 9%);
  border: 1px solid ${theme.colors.border};
`;
