import { IResponse } from "models";

export enum ActionType {
  UPDATE_GENERAL_SETTING = "action/UPDATE_GENERAL_SETTING",
  UPDATE_GENERAL_SETTING_SUCCESS = "action/UPDATE_GENERAL_SETTING_SUCCESS",
  UPDATE_GENERAL_SETTING_ERROR = "action/UPDATE_GENERAL_SETTING_ERROR",
  RESET_GENERAL_SETTING = "action/RESET_GENERAL_SETTING",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateGeneralResponse extends IResponse {}

export interface UpdateGeneralRequest {
  current_password?: string;
  new_password?: string;
  confirm_new_password?: string;
  app_language_id?: number;
  time_zone_id?: number;
  locale?: string;
}
