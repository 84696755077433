import { IResponse } from "models";

export enum ActionType {
  CREATE_ACCOUNT = "action/CREATE_ACCOUNT",
  CREATE_ACCOUNT_SUCCESS = "action/CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_ERROR = "action/CREATE_ACCOUNT_ERROR",
  RESET_CREATE_ACCOUNT = "action/RESET_CREATE_ACCOUNT",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface CreateAccountResponse extends IResponse {}
