import { Tabs } from "antd";
import styled from "styled-components";

import { theme } from "theme/theme";
import { Table, TextArea, Button, Modal, Select } from "components";
import { ConfirmModal } from "./components";

interface IHeaderCollapse {
  background?: string;
}

export const WrapperTab = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 4px;
  ${theme.media.tablet} {
    justify-content: end;
    margin-right: 20px;
  }
`;

export const WrapperContent = styled.div`
  background: ${theme.colors.white};
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px;
`;

export const WrapperTopContent = styled.div`
  margin-bottom: 16px;
  button {
    width: 100%;
  }
  div {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.lg};
    line-height: 18px;
    color: #3b3b3b;
    margin-top: 32px;
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: initial;
      order: 2;
    }
    div {
      margin-top: 0px;
    }
  }
`;

export const WrapperActionContent = styled.div`
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  color: rgb(59, 59, 59);
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.md};
  line-height: 23px;
  margin-bottom: 16px;
  margin-right: 4px;
`;

export const Message = styled.p`
  font-weight: 500;
  font-size: ${theme.sizes.lg};
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #3b3b3b;
`;

export const ItemTab = styled.div`
  margin: 0px 4px;
  float: left;
  :hover {
    cursor: pointer;
  }
  div {
    padding: 0px 12px;
    border-radius: 12px 12px 0px 0px;
    height: 44px;
    line-height: 44px;
    margin-top: 4px;
    background: #fbfbfb;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.lg};
    text-align: center;
    color: #666666;
  }
  .tab-active {
    background: #fff;
    margin-top: 0px;
    height: 48px !important;
    line-height: 48px !important;
    color: #3b3b3b;
  }
`;

export const Card = styled.div`
  border: 1px solid ${theme.colors.border};
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 0px 16px 16px;
  margin-bottom: 24px;
  ${theme.media.tablet} {
    padding: 0 16px 16px 24px;
  }
`;

export const CardLabel = styled.div`
  display: flex;
  align-items: center;
  div:last-child {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    color: ${theme.colors.text.primary};
    margin-left: 16px;
  }
`;

export const CardContent = styled.div`
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const CardInfo = styled.div`
  ${theme.media.tablet} {
    width: 380px;
  }
  ${theme.media.laptop} {
    width: 600px;
  }
  ${theme.media.desktop} {
    width: 700px;
  }
`;

export const CardTitle = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.lg};
  line-height: 22px;
  color: #3b3b3b;
  margin: 16px 0px 10px 0px;
  overflow-wrap: break-word;
`;

export const Tag = styled.div<{ background: string }>`
  padding: 5px 16px;
  color: #fff;
  display: inline-block;
  border-radius: 0px 0px 4px 4px;
  background: ${(props) => props.background};
`;

export const Description = styled.div`
  div {
    font-weight: 400;
    font-size: ${theme.sizes.md};
    line-height: 22px;
    color: #666666;
  }
  ${theme.media.tablet} {
    display: flex;
  }
`;

export const DivIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fbfbfb;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  width: 85px;
  height: 85px;
  padding-top: 10px;
  svg {
    width: 40px;
    height: 33px;
  }
  p {
    width: 100%;
    overflow: hidden;
    color: #2f8cae;
  }
  :hover {
    cursor: pointer;
  }

  ${theme.media.tablet} {
    margin-bottom: 16px;
    width: 72px;
    height: 72px;
  }
`;

export const WrapperSizeChange = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const GroupButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
  margin-top: 16px;
  ${theme.media.tablet} {
    margin-top: 0px;
    justify-content: end;
    align-items: center;
  }
`;

export const SubTitle = styled.div`
  ${theme.media.mobileM} {
    margin-bottom: 16px;
    line-height: 18px;
  }
  ${theme.media.tablet} {
    margin-bottom: 0px;
    line-height: 44px;
  }
`;

export const WrapperPagination = styled.div`
  text-align: center;
  ${theme.media.mobileS} {
    width: 100%;
  }
  ${theme.media.tablet} {
    text-align: right;
    width: auto;
  }
`;

export const NoDataMessage = styled.div`
  margin-top: 40px;
  margin-left: 16px;
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.sizes.md};
  line-height: 18px;
`;

export const TitleTable = styled.div`
  margin-left: 12px;
  text-decoration-line: underline;
  color: #2f8cae;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    cursor: pointer;
  }
`;

export const TableStyled = styled(Table)`
  .ant-table-thead > tr > th:first-child {
    padding-left: 40px;
  }
  .ant-table-thead > tr > th:nth-child(8) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-thead > tr > th:nth-child(7) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: left;
  }
`;

export const WrapperFields = styled.div`
  display: flex;
  svg {
    margin-top: 4px;
    cursor: pointer;
  }
`;

export const RadioStyled = styled.div`
  margin-top: 24px;
  .ant-radio {
    top: 4px;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #cccccc;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #cccccc;
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border-width: 2px;
  }
  .ant-radio-inner::after {
    width: 18px;
    height: 18px;
    margin-top: -4px;
    margin-left: -4px;
    background-color: #2ac769;
  }
`;

export const WrapperRedirect = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .bor-backicon:hover {
    cursor: pointer;
  }
  p {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    line-height: 23px;
    margin-right: 16px;
  }
  .bor-backicon {
    background: ${theme.colors.white};
    padding: 8px;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    margin-right: 8px;
  }
`;

export const WrapperAction = styled.div`
  border-radius: 8px;
  background: ${theme.colors.white};
  padding: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  text-align: right;
`;

export const WrapperSelect = styled.div`
  width: 100%;
  ${theme.media.tablet} {
    width: initial;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const WrapperTabContent = styled(Tabs)`
  margin-top: 24px;
  .ant-tabs-top {
    max-width: 800px;
    margin: auto;
  }
  .ant-tabs {
    width: 100%;
    overflow: initial !important;
  }
  .ant-tabs-nav {
    margin-bottom: 0px !important;
  }
  .ant-tabs-nav-wrap,
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    display: block !important;
  }
  .ant-tabs-nav-list {
    display: none !important;
  }
  .ant-tabs-content-holder .ant-tabs-nav-list {
    display: table !important;
    margin: auto;
  }
  .ant-tabs-content-holder .ant-tabs-nav-list .ant-tabs-tab {
    border-radius: 12px 12px 0px 0px !important;
    border: none !important;
    margin: 0px 4px;
    font-size: ${theme.sizes.lg};
    padding: 13px 16px 12px;
  }
  .ant-tabs-tab-active {
    padding: 16px 16px 14px !important;
    background: #2f8cae !important;
    span {
      color: #fff !important;
    }
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }
  .ant-tabs-tab-btn span {
    font-weight: ${theme.fontWeight.bold};
  }
`;

export const TabsStyled = styled(Tabs)`
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  .ant-tabs-tab {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.lg};
    line-height: 18px;
  }
`;

export const QuestionBody = styled.div`
  background: ${theme.colors.white};
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 24px 16px;
`;

export const QuestionDraftBody = styled(QuestionBody)`
  .div-right {
    width: 24px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    transform: rotate(-180deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-select-selection-item {
    line-height: 0px !important;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    padding: 24px 32px 24px 32px;
  }
`;

export const QuestionAnswerBody = styled(QuestionBody)`
  .select-chart {
    display: flex;
    justify-content: end;
  }
  .display-flex {
    margin-bottom: 8px;
  }
  .ant-select-selection-item {
    display: flex !important;
  }
  .title-bold {
    color: #3b3b3b;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    line-height: 16px;
  }
  ${theme.media.tablet} {
    padding: 16px 32px 24px;
    .display-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
    }
    .select-chart {
      display: block;
    }
  }
`;

export const QuestionIndividualAnswerBody = styled(QuestionBody)`
  > div:first-child {
    color: ${theme.colors.text.primary};
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    line-height: 22px;
    margin-bottom: 24px;
  }

  ${theme.media.tablet} {
    padding: 24px 32px;
  }
`;

export const QuestionBodyLeft = styled.div`
  .ant-select-arrow {
    top: calc(50% - 4px);
  }
  ${theme.media.tablet} {
    width: 550px;
  }
`;

export const QuestionBodyRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 48px;
  background: #fbfbfb;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 18px;
  .p-title-question {
    font-weight: ${theme.fontWeight.bold};
    font-size: 12px;
    color: #3b3b3b;
    line-height: 22px;
  }
  ${theme.media.tablet} {
    width: 146px;
    flex-direction: column;
  }
`;

export const QuestionTitle = styled.div`
  background: ${theme.colors.textLink};
  border-radius: 8px 8px 0px 0px;
  color: ${theme.colors.white};
  width: calc(100% - 32px);
  padding: 10px 12px;
  margin: auto;
  ${theme.media.tablet} {
    width: calc(100% - 64px);
  }
  ${theme.media.laptop} {
    width: calc(100% - 48px);
  }
`;

export const QuestionDraftTitle = styled(QuestionTitle)`
  ${theme.media.tablet} {
    width: calc(100% - 64px);
  }
`;

export const QuestionAnswerTitle = styled(QuestionTitle)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    cursor: pointer;
  }
  ${theme.media.tablet} {
    width: calc(100% - 48px);
  }
`;

// export const ItemQuestion = styled.div`
//   margin-top: 24px;
//   font-size: ${theme.sizes.md};
// `;

export const WrapperQuestion = styled.div`
  margin-top: 24px;
  font-size: ${theme.sizes.md};
`;

export const WrapperIconItemQuestion = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  div {
    font-size: ${theme.sizes.xs};
    line-height: 12px;
    font-weight: ${theme.fontWeight.bold};
  }
  :hover {
    cursor: pointer;
  }
`;

export const WrapperPlus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  padding-bottom: 60px;
  > div {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #2ac769;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      cursor: pointer;
    }
  }
`;

export const WrapperImpressions = styled.div`
  max-height: 228px;
  overflow-y: auto;
  background: ${theme.colors.white};
  margin-top: 24px;
  div {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.xs};
    line-height: 16px;
    color: ${theme.colors.text.primary};
    padding: 9px 12px;
    background: ${theme.colors.background};
    margin-bottom: 4px;
  }
  div:last-child {
    margin-bottom: 0px;
  }
`;

export const WrapperItemChartGragh = styled.div`
  .title-chart-gragh {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.xs};
    line-height: 12px;
    color: #3b3b3b;
    margin-bottom: 8px;
  }
  ${theme.media.mobileS} {
    .title-chart-gragh {
      margin-top: 16px;
    }
  }
  ${theme.media.tablet} {
    .title-chart-gragh {
      margin-top: 0px;
    }
  }
  .content-chart-gragh {
    height: 228px;
    overflow-y: auto;
    background: ${theme.colors.background};
    border-radius: 8px;
    padding: 8px;
    div {
      margin: 5px 0px;
      font-size: ${theme.sizes.xs};
      line-height: 22px;
    }
  }
`;

export const WrapperIconSelect = styled.div`
  display: flex;
  line-height: 32px;
  svg {
    margin-top: 3px;
    width: 24px;
    height: 24px;
  }
  span {
    padding-left: 8px;
  }
`;

export const WrapperItemRadio = styled.div`
  margin: 16px 0px;
  .x-icon:hover {
    cursor: pointer;
  }
  .x-icon {
    margin: 12px 0px 0px 8px;
  }
  ${theme.media.mobileM} {
    .div-bor {
      width: 8px;
      height: 25px;
      border-left: 2px solid #e0e0e0;
      border-bottom: 2px solid #e0e0e0;
      position: absolute;
      top: 0px;
      left: 35px;
    }
    .div-bor-active {
      border-left: 2px solid #2ac769;
      border-bottom: 2px solid #2ac769;
    }
    .select-change {
      margin-top: 8px;
      margin-left: 19px;
    }
  }
  ${theme.media.tablet} {
    .select-change {
      margin-top: 0px;
      margin-left: 0px;
    }
  }
`;

export const WrapperAdd = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
  cursor: pointer;
  .span-plus {
    color: #2ac769;
  }
  .span-green {
    text-decoration-line: underline;
    color: #2ac769;
    padding: 0px;
  }
  .span-or {
    padding: 0px 4px;
  }
  .disabled {
    color: ${theme.colors.icon};
    text-decoration-line: underline;
    padding: 0px 6px;
  }
  span {
    font-size: ${theme.sizes.md};
    line-height: 16px;
  }
  ${theme.media.tablet} {
    margin-bottom: 0;
    .span-green {
      padding: 0px 6px;
    }
    .span-or {
      padding: 0px;
    }
  }
`;

export const WrapperTitleInputTextarea = styled.div`
  font-size: ${theme.sizes.sm};
  line-height: 14px;
  color: #b7b7b7;
`;

export const WrapperChartContent = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 24px 16px;
  ${theme.media.mobileS} {
    .select-chart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        height: 23px;
        width: max-content;
      }
    }
    .display-flex {
      margin-bottom: 8px;
    }
    .title-bold {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 8px;
      line-height: 23px;
      button {
        height: 23px;
        min-width: 77px;
      }
    }
  }
  ${theme.media.tablet} {
    padding: 24px;
    .display-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
    }
    .select-chart {
      display: flex;
      gap: 16px;
    }
    .title-bold {
      gap: 0px;
      margin-bottom: 0px;
      line-height: 16px;
      align-items: center;
    }
  }
  .ant-select-selection-item {
    display: flex !important;
  }
  .title-bold {
    color: #3b3b3b;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    width: 100%;
  }
`;

export const ContentTab = styled.div`
  border-top: 8px solid ${theme.colors.textLink};
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;

export const WrapperDraft = styled(ContentTab)`
  padding: 16px;

  button {
    width: 100%;
  }

  ${theme.media.tablet} {
    padding: 16px 32px 24px;

    button {
      width: initial;
    }

    > div:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const WrapperQuestionDetail = styled(ContentTab)`
  padding: 16px;

  ${theme.media.tablet} {
    padding: 16px 32px 24px;
  }
`;

export const WrapperIndividualAnswer = styled(ContentTab)`
  padding: 24px 16px;

  ${theme.media.tablet} {
    padding: 24px 16px 16px;
  }
`;

export const TextAreaStyled = styled(TextArea)`
  height: 120px;
  padding: 4px 4px 12px 12px;
`;

export const WrapperSearching = styled.div`
  margin-bottom: 16px;
  div {
    display: flex;
    justify-content: end;
    margin-top: 8px;
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: end;
    div {
      display: block;
      margin-top: 0px;
      margin-left: 16px;
    }
  }
`;

export const WrapperCircleCarrot = styled.div`
  margin-top: 24px;
  display: flex;
  /*justify-content: space-between;*/
  justify-content: center;

  .student-name {
    background: ${theme.colors.white};
    border-radius: 4px;
    padding: 6px 10px;
    margin-bottom: 16px;
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.lg};
    line-height: 18px;
    color: ${theme.colors.text.primary};
  }

  div {
    margin-top: 3px;
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    text-align: center;
    color: ${theme.colors.text.secondary};
  }
`;

export const WrapperDropdown = styled.div`
  position: absolute;
  background: #fff;
  border: 1px solid ${theme.colors.border};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  top: 30px;
  right: 0px;
  padding: 8px;
  z-index: 1;
  min-width: 84px;
  &::after {
    content: "";
    position: absolute;
    top: -5px;
    right: 12px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    right: 14px;
    width: 6px;
    height: 10px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
  div {
    color: #666666;
    p {
      width: max-content;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
    }
  }
  ${theme.media.tablet} {
    left: 126px;
    &::after {
      left: 9px;
    }
    &::before {
      left: 8px;
    }
  }
`;

export const WrapperPieChart = styled.div`
  display: flex;
  .pie-chart-right {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    margin-left: 20px;
    margin-right: 16px;
    width: 100%;
    height: fit-content;
  }
  .pie-chart-right .icon-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #dedede;
  }
  .pie-chart-right .span-text {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    color: #3b3b3b;
    padding-top: 3px;
    padding-left: 6px;
    max-width: 128px;
  }
  .pie-chart-right div {
    display: flex;
  }
`;

export const WrapperSurveyChart = styled.div`
  width: 265px;
  margin: 0 auto;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 12px;
  p {
    text-align: center;
    color: ${theme.colors.text.secondary};
  }
  p:first-child {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.lg};
    line-height: 18px;
    margin-bottom: 14px;
  }
  p:last-child {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.sm};
    line-height: 14px;
    span {
      color: #2f8cae;
    }
  }
  > div {
    margin-bottom: 14px;
  }
  ${theme.media.tablet} {
    width: 196px;
    margin: 0 0 20px 20px;
    p:first-child {
      margin-bottom: 12px;
    }
  }
`;

export const WrapperCollapse = styled.div`
  border-radius: 8px;

  .ant-collapse-header {
    padding-right: 30px !important;
    font-size: ${theme.sizes.md};
    width: auto;
    display: inline-table !important;
  }
  .ant-collapse-borderless > .ant-collapse-item:last-child {
    width: 100%;
  }
  .ant-collapse-content {
    margin-top: 14px;
    width: 100%;
  }
  .ant-collapse-content-box {
    padding-top: 0px !important;
  }
  .ant-collapse-arrow {
    top: 30% !important;
    right: 0px !important;
  }
  .div-bor-item-search {
    background: #f2f2f2;
    border-radius: 8px;
    overflow-x: auto;
    padding: 6px;
  }
`;

export const WrapperChartText = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
`;

export const ContainerChartText = styled.div`
  transform: rotateY(180deg);
  text-align: center;
  span {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    padding-left: 4px;
  }
  div:first-child {
    font-weight: ${theme.fontWeight.bold};
    font-size: 44px;
    line-height: 44px;
  }
  div:last-child {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.xs};
    line-height: 16px;
    margin-top: 5px;
  }
  .text-big {
    font-weight: ${theme.fontWeight.bold};
    font-size: 44px;
  }
  .text-small {
    font-weight: 400;
    font-size: ${theme.sizes.md};
    line-height: 16px;
    color: #3b3b3b;
  }
`;

export const WrapperChart = styled.div`
  overflow: scroll;
  width: 293px;
  ${theme.media.tablet} {
    width: 100%;
    overflow: unset;
  }
`;

export const ContainerChart = styled.div`
  height: auto;
  width: 770px;
  font-size: 12px;
  line-height: 12px;
  .recharts-tooltip-cursor {
    fill: transparent !important;
    stroke: transparent !important;
  }
  ${theme.media.tablet} {
    width: 100%;
  }
  > .recharts-line-dot {
    fill: #2ac769;
    r: 5 !important;
  }
`;

export const TopSearchCondition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: none;
    font-size: ${theme.sizes.lg};
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.text.primary};
  }
  ${theme.media.laptopL} {
    margin-bottom: 34px;
    div {
      display: block;
    }
  }
`;

export const Question = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: ${theme.sizes.md};
  color: ${theme.colors.text.primary};
  font-weight: ${theme.fontWeight.regular};
  line-height: 22px;
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0;
  }
  .question {
    font-weight: ${theme.fontWeight.bold};
    margin-right: 4px;
    min-width: 58px;
  }
  ${theme.media.tablet} {
    align-items: center;
  }
`;

export const TagsContainer = styled.div`
  width: 100%;
  height: 350px;
  max-height: 350px;
  padding: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 16px 0px 13px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }
`;

export const ContentSearchCondition = styled.div`
  padding-bottom: 24px;
  ${theme.media.tablet} {
    padding-bottom: 24px;
  }
  ${theme.media.laptopL} {
    padding-top: 9px;
    padding-bottom: 0;
    margin-bottom: 32px;
    border-bottom: unset;
  }
  .div-select .ant-select-selection-item-remove {
    display: none;
  }
`;

export const SendAllStudentBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SearchButton = styled(Button)`
  height: 40px;
  font-size: ${theme.sizes.md};
  margin-bottom: 32px;
`;

export const SearchButtonContainer = styled.div`
  border-bottom: 1px solid ${theme.colors.border};
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
`;

export const TextDateCollapse = styled.div`
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  line-height: 18px;
  font-weight: ${theme.fontWeight.regular};
  margin: 0px;
  margin-right: 8px;
  ${theme.media.tablet} {
    width: 147px;
  }
`;

export const AnswerStatusBadge = styled.div`
  width: 60px;
  font-size: ${theme.sizes.xs};
  line-height: 14px;
  text-align: center;
  color: ${theme.colors.white};
  border-radius: 4px;
  height: max-content;
  order: 2;
  ${theme.media.tablet} {
    order: 1;
    margin-right: 16px;
  }
`;

export const TextTitleCollapse = styled.div`
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  line-height: 18px;
  font-weight: ${theme.fontWeight.bold};
  ${theme.media.tablet} {
    width: 377px;
  }
`;

export const WrapperHeaderCollapse = styled.div<IHeaderCollapse>(
  ({ background }) => ({
    display: "flex",
    flexWrap: "wrap",
    background: background,
    padding: "8px 12px 35px 16px",
    width: "100%",
    "@media (min-width: 768px)": {
      flexWrap: "unset",
      padding: "11px 12px 12px 16px",
    },
  })
);

export const WrapperTextDate = styled.div`
  display: flex;
  width: max-content;
  justify-content: space-between;
  order: 1;
  ${theme.media.tablet} {
    order: 2;
    justify-content: unset;
    width: unset;
    svg {
      position: absolute;
      left: 176px;
    }
  }
`;

export const WrapperHistoryLeft = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  ${theme.media.tablet} {
    align-items: flex-start;
    margin-bottom: unset;
    width: unset;
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 11px;
  > div {
    width: 234px;
  }
  svg {
    cursor: pointer;
  }
  svg:first-child {
    margin-right: 10px;
  }
  svg:last-child {
    position: absolute;
    right: 17px;
  }
  ${theme.media.tablet} {
    > div {
      width: 100%;
    }
    svg:last-child {
      right: -17px;
    }
  }
`;

export const WrapperAnswer = styled(ContentTab)`
  padding: 16px 8px 24px;

  > div:first-child {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.xxl};
    line-height: 24px;
    color: ${theme.colors.textLink};
    margin-bottom: 18px;
  }

  ${theme.media.tablet} {
    padding: 16px 32px 24px;
  }
`;

export const WrapperTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  margin-bottom: 24px;
  margin-left: 16px;
  ${theme.media.tablet} {
    height: fit-content;
    gap: 16px;
  }
`;

export const TagStyled = styled.div`
  display: flex;
  position: relative;
  width: 159px;
  height: 28px;
  background: ${theme.colors.white};
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1px 5px 1px 8px;
  justify-content: space-between;
  align-items: center;
  svg:hover {
    cursor: pointer;
  }
  span {
    font-size: ${theme.sizes.md};
    line-height: 15px;
    color: ${theme.colors.text.primary};
  }
  ${theme.media.tablet} {
  }
`;

export const WrapperList = styled.div`
  p {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    margin-bottom: 8px;
  }
  p:first-child {
    color: ${theme.colors.textLink};
  }
  p:last-child {
    color: ${theme.colors.text.primary};
  }
  > div:not(.answer-wrapper) {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    background: ${theme.colors.background};
    border-radius: 8px;
    overflow-x: auto;
    padding: 8px;
    margin-bottom: 24px;
  }
  > div:last-child {
    margin-bottom: 0;
  }
  > div > div {
    height: 19px;
    font-size: ${theme.sizes.xs};
    font-weight: ${theme.fontWeight.regular};
    line-height: 12px;
    background: ${theme.colors.white};
    border-radius: 4px;
    padding: 3px 4px;
  }
  ${theme.media.tablet} {
    p {
      margin-bottom: 0px;
    }
  }
`;

export const WrapperAnswerList = styled(WrapperList)`
  .answer-wrapper {
    margin-bottom: 8px;
    button {
      width: 100%;
    }
  }
  > div:not(.answer-wrapper) {
    max-height: 150px;
  }
  ${theme.media.tablet} {
    .answer-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      button {
        width: max-content;
      }
    }
  }
`;

export const WrapperIndividualAnswerList = styled(WrapperList)`
  > div {
    max-height: 215px;
  }
  > div > div {
    cursor: pointer;
    white-space: pre;
  }
`;

export const WrapperSurvey = styled.div`
  ${theme.media.tablet} {
    display: flex;
  }
`;

export const WrapperContentChart = styled.div`
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const WrapperOtherBreakdown = styled.div`
  .title-chart-gragh {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.xs};
    line-height: 12px;
    color: #3b3b3b;
    margin-bottom: 8px;
    margin-top: 16px;
  }
  .content-chart-gragh {
    height: 228px;
    overflow-y: auto;
    background: ${theme.colors.background};
    border-radius: 8px;
    padding: 8px;
    div {
      margin: 5px 0px;
      font-size: ${theme.sizes.xs};
      line-height: 22px;
    }
  }
  ${theme.media.tablet} {
    width: 168px;
    .title-chart-gragh {
      margin-top: 0px;
    }
  }
`;

export const Label = styled.div`
  font-size: ${theme.sizes.lg};
  line-height: 18px;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.icon};
  margin-bottom: 16px;
`;

export const QuestionName = styled.div`
  font-size: ${theme.sizes.lg};
  line-height: 24px;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.text.primary};
  margin-bottom: 32px;
`;

export const QuestionExplanation = styled.div`
  font-size: ${theme.sizes.md};
  line-height: 24px;
  color: ${theme.colors.text.primary};
`;

export const HistoryTitleWrapper = styled.div`
  display: flex;
  justify-content: initial;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
  .back {
    border-radius: 50%;
    border: 1px solid #fbfbfb;
    box-shadow: 1px 1px 8px rgb(0 0 0 / 12%);
    cursor: pointer;
  }
  ${theme.media.tablet} {
    margin-bottom: 21px;
  }
  ${theme.media.desktop} {
  }
`;

export const HistoryContainer = styled.div`
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  padding: 24px 16px 32px;
  ${theme.media.tablet} {
    padding: 24px 24px 32px;
  }
`;

export const HistoryTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 23px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #f2f2f2;
  > div:first-child {
    order: 2;
  }
  .title {
    font-weight: 400;
    font-size: 13px;
    line-height: ${theme.sizes.sm};
    margin-bottom: 24px;
    order: 1;
  }
  ${theme.media.tablet} {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 24px 24px 17px;
    align-items: center;
    .title {
      margin-bottom: 0;
      order: 2;
    }
    > div:first-child {
      order: 1;
    }
  }
  ${theme.media.desktop} {
    padding-top: 16px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
  gap: 32px;
  ${theme.media.tablet} {
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
  }
`;

export const PaginationBottom = styled.div`
  width: max-content;
  margin-top: 32px;
  ${theme.media.tablet} {
    margin-left: auto;
    margin-top: 16px;
  }
`;

export const ConfirmPaginationBottom = styled.div`
  width: 100%;
  margin-top: 24px;
  ${theme.media.tablet} {
    margin-left: auto;
    margin-top: 8px;
    padding-right: 16px;
    width: max-content;
  }
`;

export const WrapperChangeSize = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const WrapperTextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: max-content;
  align-items: flex-start;
  .content {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.sm};
    line-height: 18px;
    order: 2;
    width: 100%;
  }
  .badge {
    width: 100%;
    color: ${theme.colors.white};
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.xs};
    line-height: ${theme.sizes.xs};
    padding: 4px 8px;
    background: #2f8cae;
    border-radius: 6px;
    text-align: center;
    order: 1;
    height: 20px;
  }
  ${theme.media.tablet} {
    margin-right: 68px;
    gap: 32px;
    flex-direction: row;
    .content {
      flex: 1;
      width: unset;
      order: 1;
      margin-top: 2px;
    }
    .badge {
      width: unset;
      order: 2;
    }
  }
  ${theme.media.desktop} {
  }
`;

export const ConfirmStatusModalStyled = styled(Modal)`
  padding: 8px 0px;
  top: 0;
  .ant-modal-body {
    padding: 16px 16px 32px;
    height: max-content;
  }
  .ant-modal-header {
    padding: 16px;
  }
  .ant-modal-close {
    top: 0;
    right: -5px;
  }
  ${theme.media.tablet} {
    padding: 24px 0px;
    .ant-modal-body {
      padding: 16px 24px;
    }
    .ant-modal-header {
      padding: 24px 24px;
    }
    .ant-modal-close {
      top: 11px;
      right: 5px;
    }
  }
`;

export const ComfirmStatusTitle = styled.div`
  .title {
    width: 100%;
  }
  .status {
    padding: 4px 0px 3px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: ${theme.colors.white};
    background: ${theme.colors.text.secondary};
    border-radius: 9px;
    margin-top: 16px;
    width: 100%;
  }
  ${theme.media.tablet} {
    display: flex;
    align-items: center;
    gap: 24px;
    .status {
      padding: 4px 8px 3px;
      margin-top: 0;
      width: max-content;
      max-width: 259px;
    }
    .title {
      width: max-content;
      max-width: 132px;
    }
  }
`;

export const ConfirmTableStyled = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 26px 32px 27px;
  }
  .ant-table-thead > tr > th:first-child {
    padding: 26px 24px 27px;
  }
`;

export const FilterContainer = styled.div`
  margin-bottom: 24px;
  .filter {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 32px;
    .title {
      color: #666666;
      margin-right: 16px;
    }
    .item {
      display: flex;
      gap: 4px;
      align-items: center;
      .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        border: 2px solid ${theme.colors.border};
      }
      .ant-checkbox-inner::after {
        left: 20%;
      }
    }
    .item:last-child {
      margin-left: 20px;
    }
  }
  ${theme.media.tablet} {
    gap: 0;
    margin-bottom: 8px;
    padding: 0px 16px 0px 24px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    .filter {
      margin-bottom: 0;
      width: unset;
    }
    button {
      display: none;
    }
  }
`;

export const TextDateCollapseHistory = styled.div`
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  line-height: 18px;
  font-weight: ${theme.fontWeight.regular};
  margin: 3px 16px 0px 0px;
  order: 1;
  ${theme.media.tablet} {
    order: 1;
    width: 147px;
    margin: 2px 24px 0px 0px;
  }
`;

export const WrapperDateStatus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .badge {
    width: 60px;
    font-size: ${theme.sizes.xs};
    line-height: 12px;
    text-align: center;
    color: ${theme.colors.white};
    height: max-content;
    order: 2;
  }
  ${theme.media.tablet} {
    width: unset;
    justify-content: flex-start;
    gap: 16px;
    .badge {
      order: 1;
    }
  }
`;

export const WrapperHeaderCollapseHistory = styled.div<IHeaderCollapse>(
  ({ background }) => ({
    display: "flex",
    flexWrap: "wrap",
    background: background,
    padding: "8px 12px 35px 16px",
    width: "100%",
    "@media (min-width: 768px)": {
      flexWrap: "unset",
      padding: "14px 16px",
    },
  })
);

export const DescriptionStyled = styled.div`
  padding: 12px;
  border: 1px solid ${theme.colors.border};
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  background: ${theme.colors.white};
  font-size: ${theme.sizes.md};
  line-height: 16px;
  color: ${theme.colors.text.primary};
  margin-left: 24px;
  margin-top: 5px;
`;

export const DescriptionStyled2 = styled.div`
  font-size: ${theme.sizes.md};
  line-height: 24px;
  color: ${theme.colors.text.primary};
  margin-bottom: 24px;
`;

export const AnswersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 242px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 16px 0px 13px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }
  .answer {
    width: 100%;
    font-size: ${theme.sizes.xs};
    line-height: ${theme.sizes.sm};
    font-weight: ${theme.fontWeight.regular};
    color: ${theme.colors.text.primary};
    padding: 12px 12px 11px;
    height: max-content;
    min-height: 37px;
    background: #f2f2f2;
  }
`;

export const WrapperTabs = styled.div`
  ${theme.media.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

export const WrapperTooltipPieChart = styled.div`
  color: blue;
  border: 1px solid ${theme.colors.border};
  padding: 4px;
  background: #fff;
  border-radius: 4px;
  font-size: 10px;
  max-width: 110px;
  work-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-weight: ${theme.fontWeight.regular};
  div:first-child {
    color: ${theme.colors.text.primary};
    line-height: 14px;
  }
  div:last-child {
    color: ${theme.colors.textLink};
    line-height: 14px;
  }
`;

export const Text = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.colors.textLink};
  text-decoration-line: underline;
  cursor: pointer;
  ${theme.media.tablet} {
    text-align: left;
  }
`;

export const WrapperSelectPeriod = styled.div`
  background: ${theme.colors.background};
  border-radius: 8px;
  padding: 16px;
  margin-left: 24px;
  button {
    width: 100%;
  }
  ${theme.media.tablet} {
    padding: 12px 16px;
    width: fit-content;
    button {
      width: 318px;
    }
  }
`;

export const Line = styled.div`
  border: 1px solid #cccccc;
  margin: 8px 0;
  ${theme.media.tablet} {
    width: 452px;
  }
`;

export const SendMessageModalStyled = styled(Modal)`
  .ant-modal-header {
    background: #fbfbfb;
    padding: 16px;
  }
  .ant-modal-body {
    background: #fbfbfb;
    padding: 16px;
    border-radius: 12px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 16px 24px 20px;
    }
    .ant-modal-header {
      padding: 23px 24px;
    }
  }
`;

export const Text1 = styled.p(({ theme }) => ({
  color: theme.colors.text.primary,
  fontSize: 24,
  fontWeight: 400,
  lineHeight: "16px",
  alignSelf: "center",
  margin: "0 8px",
}));

export const Tag2 = styled.div<{ background: string }>`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.sm};
  line-height: 14px;
  color: ${theme.colors.white};
  padding: 4px 16px;
  border-radius: 4px;
  min-width: 74px;
  background: ${(props) => props.background};
`;

export const SpecialText = styled.span`
  color: ${theme.colors.textLink};
  text-decoration-line: underline;
  padding-left: 8px;
`;

export const WrapperActionMobile = styled.div`
  background: ${theme.colors.white};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);

  .period-text {
    margin-bottom: 16px;
    line-height: 16px;
    font-size: ${theme.sizes.md};
  }
`;

export const WrapperHours = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const WrapperPeriod = styled.div`
  ${theme.media.tablet} {
    display: flex;
    gap: 8px;
  }
`;

export const WrapperSendImmediately = styled.div`
  .ant-radio-group {
    margin-bottom: 32px;
  }
  button {
    width: 100%;
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 128px;
    }
    .ant-radio-group {
      margin-bottom: 0;
    }
  }
`;

export const SelectStyled = styled(Select)`
  .ant-select-selection-item {
    text-align: left;
  }
`;
