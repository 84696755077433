import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { notification } from "antd";

import {
  getDefaultScreen,
  isLoggedIn,
  localStorageHelper,
  removeCookie,
  setCookie,
} from "utils";
import { IRoute } from "routes/configs";

import { settingDataResponse } from "redux/setting/settingData/settingDataStates";
import { authTokenKey } from "services/Api";

export interface IRestrictedRouteProps extends IRoute {
  isPrivate?: boolean;
}

const RestrictedRoute: React.FC<IRestrictedRouteProps> = ({
  children,
  restricted = false,
  isPrivate,
  layout: Layout,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();
  const accountRole = localStorageHelper.getItem("role");

  const settingData = useSelector(settingDataResponse);

  useEffect(() => {
    notification.destroy();
  }, [location]);

  const redirect = useCallback(() => {
    const convertPathname = "/" + window.location.href.split("/")[3];
    const currentPathName = window.location.pathname;
    const publicURL = [
      "/login",
      "/reset-password",
      "/reset-password-notification",
      "/forgot-password",
      "/forgot-password-notification",
    ];
    const educationURL = ["/messages", "/message-list", "/change-password"];
    const role = ["1", "2"];
    const restrictedUrl = [];

    if (settingData && Object.keys(settingData.result).length) {
      if (!settingData?.result?.mental_check_infor?.is_big_check) {
        const mentalCheckURL = [
          "/mental-health-management",
          "/elementary-25-questions",
          "/elementary-13-questions",
          "/junior-25-questions",
          "/junior-13-questions",
          "/mental-health-total",
        ];
        restrictedUrl.push(...mentalCheckURL);
      }

      if (
        !settingData?.result?.temperature_managerment_info
          ?.is_consultation_request
      ) {
        const consultationRequestsUrl = ["/consultations-list"];
        restrictedUrl.push(...consultationRequestsUrl);
      }

      if (
        !settingData?.result?.temperature_managerment_info?.is_create_user_sso
      ) {
        const consultationRequestsUrl = ["/import-sso-users"];
        restrictedUrl.push(...consultationRequestsUrl);
      }

      if (!settingData?.result?.questionnaire_info?.is_questionnaire) {
        const questionnaireURL = ["/questions-list", "/history-list"];
        restrictedUrl.push(...questionnaireURL);
      }

      if (!settingData?.result?.vaccine_info?.is_vaccine) {
        const vaccineUrl = [
          "/vaccination-record",
          "/vaccination-appointment",
          "/reservation-list",
          "/venue-setting",
        ];
        restrictedUrl.push(...vaccineUrl);
      }
    }

    const params = new URLSearchParams(window.location.search);
    const auth_token = params.get("auth_token");

    if (auth_token) {
      removeCookie(authTokenKey);
      window.localStorage.clear();
    }

    if (isPrivate && !isLoggedIn()) {
      if (publicURL.includes(currentPathName)) {
        const name = params.get("name");
        const id = params.get("id");

        if (auth_token) {
          setCookie(authTokenKey, auth_token);
          localStorageHelper.setItem("company_name", name + "管理画面");
          localStorageHelper.setItem("role", 1);
          localStorageHelper.setItem("id", id);
          history.replace("/dashboard");
        } else {
          history.replace(convertPathname);
        }
      } else {
        history.replace("/login");
      }
    }

    if (restricted && isLoggedIn()) {
      let screen;

      if (settingData && Object.keys(settingData).length) {
        const { screen_displays, temperature_managerment_info } =
          settingData?.result;

        screen = (screen_displays || []).find(
          (sc: any) => sc.id === temperature_managerment_info.screen_display_id
        );
      }

      if (
        currentPathName === "/" ||
        publicURL.includes(currentPathName) ||
        (role.includes(accountRole) && educationURL.includes(currentPathName))
      ) {
        history.replace(getDefaultScreen(screen));
      }

      if (restrictedUrl.length && restrictedUrl.includes(currentPathName)) {
        history.replace(getDefaultScreen(screen));
      }
    }
  }, [isPrivate, history, restricted, settingData]);

  useLayoutEffect(() => {
    redirect();
  }, [redirect]);

  const Component = Layout ? <Layout>{children}</Layout> : children;

  return <Route {...props}>{Component}</Route>;
};

export default RestrictedRoute;
