import { Action, ActionType } from "models/mentalHealth/sendNotification";
import { IResponse } from "models";

import createReducer from "redux/createReducer";

export interface SendNotificationReducerType {
  loading: boolean;
  error?: any;
  saved: boolean;
  sendNotificationResponse?: IResponse;
}

const defaultState: SendNotificationReducerType = {
  loading: false,
  error: null,
  saved: false,
  sendNotificationResponse: undefined,
};

export const sendNotificationReducer =
  createReducer<SendNotificationReducerType>(defaultState, {
    [ActionType.SEND_NOTIFICATION](state: SendNotificationReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.SEND_NOTIFICATION_ERROR](
      state: SendNotificationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      };
    },

    [ActionType.SEND_NOTIFICATION_SUCCESS](
      state: SendNotificationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: true,
        sendNotificationResponse: action.payload,
      };
    },

    [ActionType.RESET_SEND_NOTIFICATION](state: SendNotificationReducerType) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        sendNotificationResponse: undefined,
      };
    },
  });
