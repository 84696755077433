import {
  ActionType,
  IReadHistoryData,
  IReadHistoryResponse,
} from "models/messagemanagement/messageReadHistory";

export const readHistoryListAction = (payload: IReadHistoryData) => {
  return {
    type: ActionType.READ_HISTORY_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.READ_HISTORY_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IReadHistoryResponse) => {
  return {
    type: ActionType.READ_HISTORY_LIST_SUCCESS,
    payload,
  };
};

export const handleResetHistoryListAction = () => {
  return {
    type: ActionType.RESET_READ_HISTORY_LIST,
  };
};
