import React, { useState, useEffect } from "react";
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import moment from "moment";
import { subDays, subMonths } from "date-fns";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import {
  WrapperDateRangePicker,
  WrapperCalendarDay,
} from "./DatePickerTwoMonths.style";

registerLocale("ja", ja);

interface IData {
  date: string;
  count: number;
  sum: number;
}

interface TwoMonthDatepickerProps {
  datas: IData[];
  setMonthDate: (monthDate: Date) => void;
}

const DateRangePickerTwoMonths: React.FC<
  ReactDatePickerProps & TwoMonthDatepickerProps
> = ({ startDate, onChange, locale, monthsShown, datas, setMonthDate }) => {
  const [customHighlightedDates, setCustomHighlightedDates] = useState<
    { [x: string]: Date[] }[]
  >([]);
  const subdayNumber = monthsShown === 1 ? 1 : 2;

  const returnHighlightDate = (dates: IData[]) => {
    let highlightWithRanges: { [x: string]: Date[] }[] = [];
    const firstHighlights: Date[] = [];
    const secondHighlights: Date[] = [];
    const thirdHighlights: Date[] = [];
    const fourthHighlights: Date[] = [];
    dates.forEach((date) => {
      if (date.count >= 0 && date.sum) {
        if (moment(date.date).diff(moment(subDays(new Date(), 1))) < 0) {
          firstHighlights.push(new Date(date.date));
        } else {
          const ratio = date.count / date.sum;
          if (ratio === 1) {
            fourthHighlights.push(new Date(date.date));
          } else if (ratio < 1 && ratio >= 0.81) {
            thirdHighlights.push(new Date(date.date));
          } else {
            secondHighlights.push(new Date(date.date));
          }
        }
        highlightWithRanges = [
          {
            "react-datepicker__day--highlighted-appointment-past":
              firstHighlights,
          },
          {
            "react-datepicker__day--highlighted-appointment-less-80":
              secondHighlights,
          },
          {
            "react-datepicker__day--highlighted-appointment-less-100":
              thirdHighlights,
          },
          {
            "react-datepicker__day--highlighted-appointment-full":
              fourthHighlights,
          },
        ];
      }
      setCustomHighlightedDates(highlightWithRanges);
    });
  };

  const CustomHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
  }: ReactDatePickerCustomHeaderProps) => {
    return (
      <>
        <button
          aria-label="Previous Month"
          className={
            "react-datepicker__navigation react-datepicker__navigation--previous"
          }
          onClick={() => {
            decreaseMonth();
            setMonthDate(subMonths(monthDate, subdayNumber));
          }}
        >
          <span
            className={
              "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
            }
          >
            {"<"}
          </span>
        </button>
        <div className="react-datepicker__current-month">
          {monthDate.getFullYear()}年{monthDate.getMonth() + 1}月
        </div>
        <button
          aria-label="Next Month"
          className={
            "react-datepicker__navigation react-datepicker__navigation--next"
          }
          onClick={() => {
            increaseMonth();
            setMonthDate(monthDate);
          }}
        >
          <span
            className={
              "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
            }
          >
            {">"}
          </span>
        </button>
      </>
    );
  };

  const { t }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });

  const getCount = (date: Date) => {
    if (!datas) return "";

    let count;
    let sum;
    const compareDate: string = moment(date).format("YYYY-MM-DD");
    datas.forEach((item) => {
      if (item.date === compareDate && item.count !== item.sum) {
        count = item.count;
        sum = item.sum;
      }
    });

    if (!count && !sum && moment(date).diff(moment(subDays(new Date(), 1))) < 0)
      return "";
    return (
      <p>
        {t("reservation")}
        {count}
        {t("count")}
      </p>
    );
  };

  const compareDate = (date: Date) => {
    const format = "YYYY-MM-DD";
    const newDate = moment(date).format(format);
    const currentDate = moment(new Date()).format(format);

    if (moment(date).isSame(currentDate)) return true;

    if (newDate > currentDate) return true;

    return false;
  };

  const renderDayContents = (day: number, date: Date) => {
    return (
      <WrapperCalendarDay>
        <span
          className="date-active"
          style={
            compareDate(date) ? { color: "#333333" } : { color: "#CCCCCC" }
          }
        >
          {day}
        </span>
        {getCount(date)}
      </WrapperCalendarDay>
    );
  };

  useEffect(() => {
    if (datas.length > 0 || !customHighlightedDates) {
      returnHighlightDate(datas);
    }
  }, [datas]);

  return (
    <WrapperDateRangePicker style={{ display: "inline-flex" }}>
      <DatePicker
        locale={locale}
        renderCustomHeader={CustomHeader}
        selected={startDate}
        onChange={onChange}
        renderDayContents={renderDayContents}
        monthsShown={monthsShown}
        highlightDates={customHighlightedDates}
        inline
      />
    </WrapperDateRangePicker>
  );
};

export default DateRangePickerTwoMonths;
