import {
  ActionType,
  SymtomPayload,
  SymtomResponse,
} from "models/dashboard/symtom";

export const getSymtomAction = (payload: SymtomPayload) => {
  return {
    type: ActionType.GET_SYMTOM_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_SYMTOM_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: SymtomResponse) => {
  return {
    type: ActionType.GET_SYMTOM_CHART_SUCCESS,
    payload,
  };
};
