import {
  Action,
  ActionType,
  IUpdateGeneralResponse,
} from "models/setting/updateGeneral";
import createReducer from "../../createReducer";

export interface UpdateGeneralSettingReducerType {
  loading: boolean;
  error?: string;
  updateGeneralSettingResponse?: IUpdateGeneralResponse;
}

const defaultState: UpdateGeneralSettingReducerType = {
  loading: false,
  error: undefined,
  updateGeneralSettingResponse: undefined,
};

export const updateGeneralSettingReducer =
  createReducer<UpdateGeneralSettingReducerType>(defaultState, {
    [ActionType.UPDATE_GENERAL_SETTING](
      state: UpdateGeneralSettingReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_GENERAL_SETTING_ERROR](
      state: UpdateGeneralSettingReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_GENERAL_SETTING_SUCCESS](
      state: UpdateGeneralSettingReducerType,
      action: Action<IUpdateGeneralResponse>
    ) {
      return {
        ...state,
        loading: false,
        updateGeneralSettingResponse: action.payload,
      };
    },

    [ActionType.RESET_GENERAL_SETTING](state: UpdateGeneralSettingReducerType) {
      return {
        ...state,
        loading: false,
        error: undefined,
        updateGeneralSettingResponse: {},
      };
    },
  });
