import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  SendHealthCheckAnswersResponse,
  SendHealthCheckAnswersParams,
} from "models/bodyTemperature/sendHealthCheckAnswers";
import { httpStatus } from "configs/httpStatus";
import { postHealthCheckAnswer } from "services/bodyTemperature";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./sendHealthCheckAnswersActions";

function* sendHealthCheckAnswersSaga({
  payload,
}: {
  payload: SendHealthCheckAnswersParams;
}) {
  try {
    const response: SendHealthCheckAnswersResponse = yield call(
      postHealthCheckAnswer,
      payload
    );
    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSendHealthCheckAnswersWatcher() {
  yield takeLatest(
    ActionType.SEND_HEALTH_CHECK_ANSWER as any,
    sendHealthCheckAnswersSaga
  );
}

export default [fork(onSendHealthCheckAnswersWatcher)];
