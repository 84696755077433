import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  // attendance list
  ATTENDANCE_FILTER_LIST = "action/ATTENDANCE_FILTER_LIST",
  ATTENDANCE_FILTER_LIST_SUCCESS = "action/ATTENDANCE_FILTER_LIST_SUCCESS",
  ATTENDANCE_FILTER_LIST_ERROR = "action/ATTENDANCE_FILTER_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface AttendanceFilterData {
  locale: string | null;
}

export interface AttendanceFilterListResponse extends IResponse {
  result?: IOption[];
}
