import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { MoreIcon } from "icons";
import { localStorageHelper } from "utils";
import { ImportantStyled } from "screens/userList/UserList.style";

import {
  CreateActivityActionCreators,
  DeleteActivityActionCreators,
  UpdateActivityActionCreators,
} from "redux/rootActions";
import { IUpdateActivityData } from "models/activitymanagement/updateActivity";
import { IDeleteActivityData } from "models/activitymanagement/deleteActivity";
import {
  IActivityList,
  IChildrenData,
} from "models/activitymanagement/activityList";
import { ICreateActivityData } from "models/activitymanagement/createActivity";

import EditDeleteDropdown from "./EditDeleteDropdown";
import DeleteModal from "./DeleteModal";
import {
  WrapperItem,
  ItemName,
  DropdownIconStyled,
  ItemWrapper,
  BtnGroup,
  PlusCircleIconStyled,
  SaveBtnStyled,
  InputStyled,
  Form,
  WrapperTreeView,
  TooltipErrorMessage,
  DropdownIconOpenStyled,
  CheckboxStyled,
  DeleteIconStyled,
} from "./ActivityManagement.style";

interface ITreeView {
  treeData: IActivityList[];
  setTreeData: React.Dispatch<React.SetStateAction<IActivityList[]>>;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .max(30, "max-text")
    .test(
      "empty-check",
      "enter-activity-name",
      (text: any) => text.trim() !== ""
    ),
});

const TreeView: React.FC<ITreeView> = ({ treeData, setTreeData }) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });
  const dispatch = useDispatch();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const [displayAdds, setDisplayAdds] = useState<any>({});
  const [isShowBtnDelete, setIsShowBtnDelete] = useState<any>({});
  const [displayEditDeletes, setDisplayEditDeletes] = useState<any>({});
  const [idEdit, setIdEdit] = useState("");
  const [idDelete, setIdDelete] = useState<any>("");

  const [expandItem, setExpandItem] = useState<any>({});
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isChildren, setIsChildren] = useState(false);
  const { handleSubmit, control, setValue, reset } = useForm<{
    name: string;
    check: boolean;
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      check: false,
    },
  });

  const handleShowMenuDropdown = (id: string, value = true) => {
    setDisplayEditDeletes({ [id]: value });
  };

  const handleCloseMenuDropdown = (props: IChildrenData) => {
    setDisplayAdds({});
    handleShowMenuDropdown(props.id, false);
  };

  const handleExpandItem = (id: string, value: boolean) => {
    setExpandItem({ ...expandItem, [id]: value });
  };

  const handleShowAddInput = (id: string, value = true) => {
    setDisplayAdds({ [id]: value });
    setValue("name", "");
    setValue("check", false);
    setIdEdit("");
  };

  const handleAddNewItem =
    (item: any) => (data: { name: string; check: boolean }) => {
      const parentId = isNaN(item.id) ? item.id.split("-")[1] : item.id;

      if (idEdit == "") {
        const params: ICreateActivityData = {
          activity: {
            name: data.name,
            is_required: data.check,
            parent_id: parentId,
          },
          locale: currentLanguage,
        };
        dispatch(CreateActivityActionCreators.createActivityAction(params));
      } else {
        item.name = data.name;
        item.is_required = data.check;
        const updateParams: IUpdateActivityData = {
          activity: {
            name: data.name,
            is_required: data.check,
            id: parentId,
          },
          locale: currentLanguage,
        };
        dispatch(
          UpdateActivityActionCreators.updateActivityAction(updateParams)
        );
      }
      reset();
      setTreeData(treeData);
      setIdEdit("");
      setDisplayAdds({});
      setIsShowBtnDelete({});
    };

  const handleEdit = (props: IChildrenData) => {
    const { id, name, is_required } = props;
    handleShowMenuDropdown(id, false);
    setDisplayAdds({});
    setIsShowBtnDelete({});
    setValue("name", name);
    setValue("check", is_required);
    setIdEdit(id);
  };

  const handleConfirmleDeleteItem = (props: any) => {
    const childrenOfItem = props?.sub_activities || props?.groupIds;
    if (childrenOfItem.length) {
      setIsChildren(true);
    } else {
      setIsChildren(false);
    }
    setIsVisibleDeleteModal(true);
    setIdDelete(props.id);
  };

  const handleCancel = () => {
    setIsVisibleDeleteModal(false);
    setDisplayEditDeletes({});
  };

  const handleDeleteChildrenItem = (items: IChildrenData[], id: string) => {
    items.forEach((item, index) => {
      if (item.id == id) {
        items.splice(index, 1);
      }
      if (item.sub_activities.length) {
        handleDeleteChildrenItem(item.sub_activities, id);
      }
    });
  };

  const handleDelete = () => {
    handleDeleteChildrenItem(treeData, idDelete);
    const params: IDeleteActivityData = {
      id: idDelete,
    };
    dispatch(DeleteActivityActionCreators.deleteActivityAction(params));
    setTreeData(treeData.length ? treeData : []);
    setIdDelete("");
    setIsVisibleDeleteModal(false);
  };

  const ItemInput = ({
    item,
    type = "add",
    firstLayer,
  }: {
    item: any;
    type?: string;
    firstLayer?: boolean;
  }) => {
    return (
      <Form onSubmit={handleSubmit(handleAddNewItem(item))}>
        <div style={{ display: "flex" }}>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div style={{ position: "relative", marginRight: 8 }}>
                <InputStyled
                  value={value}
                  height={31}
                  fs={15}
                  fwLabel={500}
                  onChange={(e: any) => {
                    onChange(e.target.value);
                  }}
                />
                {error?.message && (
                  <TooltipErrorMessage>
                    <div style={{ position: "relative" }}>
                      <div>{t(error?.message)}</div>
                    </div>
                  </TooltipErrorMessage>
                )}
              </div>
            )}
          />
          <SaveBtnStyled
            type="submit"
            background="#2AC769"
            color="#fff"
            name={type !== "add" ? t("keep") : t("create")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />
        </div>
        {type === "edit" && firstLayer && (
          <Controller
            control={control}
            name="check"
            render={({ field: { onChange, value } }) => (
              <CheckboxStyled checked={value} onChange={onChange}>
                {t("check-chose-description")}
              </CheckboxStyled>
            )}
          />
        )}
      </Form>
    );
  };

  const CompItem = ({ item, children, firstLayer }: any) => {
    const { name, id, is_required } = item;

    if (id == idEdit) {
      return (
        <>
          <ItemInput item={item} firstLayer={firstLayer} type="edit" />
          {children}
        </>
      );
    }

    return (
      <>
        <ItemWrapper>
          {!!item?.sub_activities.length && (
            <>
              {expandItem[id] ? (
                <DropdownIconOpenStyled
                  fill="#B7B7B7"
                  onClick={() => {
                    handleExpandItem(id, false);
                  }}
                />
              ) : (
                <DropdownIconStyled
                  fill="#B7B7B7"
                  onClick={() => {
                    handleExpandItem(id, true);
                  }}
                />
              )}
            </>
          )}

          <Tooltip
            placement="topLeft"
            title={
              <div>
                {name}
                {is_required && firstLayer && (
                  <ImportantStyled>*</ImportantStyled>
                )}
              </div>
            }
          >
            <ItemName
              style={item?.sub_activities.length ? { marginLeft: 20 } : {}}
            >
              {name}
              {is_required && firstLayer && (
                <ImportantStyled>*</ImportantStyled>
              )}
            </ItemName>
          </Tooltip>
          <BtnGroup>
            <div style={{ position: "relative", height: 24 }}>
              <MoreIcon
                fill="#B7B7B7"
                onClick={() => {
                  handleShowMenuDropdown(id, true);
                }}
              />
              {displayEditDeletes[id] && (
                <EditDeleteDropdown
                  item={item}
                  handleEdit={handleEdit}
                  handleConfirmleDeleteItem={handleConfirmleDeleteItem}
                  handleCloseMenuDropdown={handleCloseMenuDropdown}
                />
              )}
            </div>
            {isShowBtnDelete[id] && (
              <DeleteIconStyled
                fill="#FB4E4E"
                onClick={() => {
                  setIsShowBtnDelete({ [id]: false });
                  setDisplayAdds({});
                }}
              />
            )}
            {!isShowBtnDelete[id] && item?.level && (
              <PlusCircleIconStyled
                fill="#2AC769"
                onClick={() => {
                  handleShowAddInput(id);
                  setIsShowBtnDelete({ [id]: true });
                }}
              />
            )}
          </BtnGroup>
        </ItemWrapper>
        {children}
      </>
    );
  };

  const Item = ({ item }: any) => {
    const { id } = item;
    return (
      <WrapperItem>
        <CompItem item={item} firstLayer>
          <div style={{ marginLeft: 32 }}>
            {!expandItem[id] && (
              <>
                {item.sub_activities.length > 0 &&
                  item.sub_activities.map((i: IChildrenData) => (
                    <div style={{ marginLeft: 32 }} key={i.id}>
                      <CompItem item={i}>
                        {!expandItem[i.id] && (
                          <>
                            {i.sub_activities.map(
                              (a: IChildrenData, idx: any) => (
                                <div style={{ marginLeft: 32 }} key={a.id}>
                                  <Item item={a} index={idx} />
                                </div>
                              )
                            )}
                          </>
                        )}
                        {displayAdds[i.id] && <ItemInput item={i} />}
                      </CompItem>
                    </div>
                  ))}
              </>
            )}
            {displayAdds[id] && <ItemInput item={item} />}
          </div>
        </CompItem>
      </WrapperItem>
    );
  };

  return (
    <>
      <WrapperTreeView>
        {treeData.map((item) => {
          return <Item item={item} key={item.id} />;
        })}
      </WrapperTreeView>

      {isVisibleDeleteModal && (
        <DeleteModal
          isVisibleDeleteModal={isVisibleDeleteModal}
          isChildren={isChildren}
          handleCancel={handleCancel}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default TreeView;
