import {
  DoneQuestionResponse,
  ActionType,
  IDoneQuestionPayload,
} from "models/questionnaires/doneQuestion";

export const doneQuestionAction = (payload: IDoneQuestionPayload) => {
  return {
    type: ActionType.DONE_QUESTION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DONE_QUESTION_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: DoneQuestionResponse) => {
  return {
    type: ActionType.DONE_QUESTION_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.DONE_QUESTION_RESET,
  };
};
