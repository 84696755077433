import * as React from "react";

function ResourcesDownloadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="-5 -5 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.215 8.59h.851a.85.85 0 0 0 .592-.234.843.843 0 0 0 .249-.589.824.824 0 0 0-.25-.578.81.81 0 0 0-.59-.244h-.852a.826.826 0 0 0-.592.244.824.824 0 0 0-.25.578c.003.22.092.432.25.59a.85.85 0 0 0 .592.233Zm0 1.655a.825.825 0 0 0-.592.244.824.824 0 0 0-.25.578c.003.22.092.432.25.59a.85.85 0 0 0 .592.233h5.066a.85.85 0 0 0 .592-.234.812.812 0 0 0 .185-.903.79.79 0 0 0-.185-.264.81.81 0 0 0-.592-.244H7.215Zm9.073-3.046-5.066-4.945a.716.716 0 0 0-.228-.163h-.083A1.127 1.127 0 0 0 10.63 2H5.532a2.54 2.54 0 0 0-.968.189c-.307.126-.585.31-.818.542A2.45 2.45 0 0 0 3 4.478v11.555a2.45 2.45 0 0 0 .747 1.746c.234.23.512.413.819.537.307.123.635.186.967.184h8.43c.333.001.663-.062.971-.186.308-.123.588-.305.825-.535a2.48 2.48 0 0 0 .737-1.746V7.778a.716.716 0 0 0-.208-.59v.01ZM11.44 4.813l2.18 2.142h-1.34a.882.882 0 0 1-.601-.244.813.813 0 0 1-.239-.578v-1.32Zm3.374 11.169a.824.824 0 0 1-.25.579.871.871 0 0 1-.601.243h-8.43a.882.882 0 0 1-.602-.243.813.813 0 0 1-.239-.58V4.428a.813.813 0 0 1 .515-.761.847.847 0 0 1 .326-.062h4.215v2.478a2.45 2.45 0 0 0 .747 1.746c.234.23.512.413.819.537.306.124.635.186.967.184h2.533v7.433Zm-2.533-2.478H7.215a.825.825 0 0 0-.592.244.824.824 0 0 0-.25.579c.003.22.092.431.25.589a.85.85 0 0 0 .592.233h5.066a.85.85 0 0 0 .592-.233.81.81 0 0 0 .185-.904.79.79 0 0 0-.185-.264.814.814 0 0 0-.592-.203v-.04Z"
        fill={props.fill}
      />
      <path
        d="M7 19.575c.003.642.262 1.257.72 1.71A2.4 2.4 0 0 0 9.44 22h8.12a2.458 2.458 0 0 0 1.73-.716c.452-.456.707-1.07.71-1.71v-8.08a.892.892 0 0 0-.2-.566C19.64 10.78 17.89 9 17.89 9v2.345s.14.11.34.299c.2.188.14.288.14.666v7.265a.812.812 0 0 1-.24.566.833.833 0 0 1-.58.239H9.43a.843.843 0 0 1-.58-.239.802.802 0 0 1-.23-.566H7Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default ResourcesDownloadIcon;
