import React, { useEffect, useState } from "react";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Modal, DateRangePicker } from "components";
import { DownloadIcon } from "icons";
import {
  AttendanceLabel,
  DataRow,
  WrapperBodyTop,
  AttendanceDownloadBtn,
  DataNotFound,
} from "./UserList.style";
import { IModal } from "./UserList.modal";

import { userAttendanceSuccessResponse } from "redux/userattendance/userAttendanceStates";
import { UserAttendance, UserAttendanceData } from "models/userAttendance";
import { UserAttendanceActionCreators } from "redux/rootActions";
import { localStorageHelper, mplusFont } from "utils";

const { useBreakpoint } = Grid;
const ENFormat = "YYYY-MM-DD";
const JAFormat = "YYYY年MM月DD日";

const AttendanceModal = ({
  visible,
  handleCancel,
  selectedUserItem,
}: IModal) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const attendancelist = useSelector(userAttendanceSuccessResponse);
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const today = new Date();
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15)
  );
  const [endDate, setEndDate] = useState(today);
  const [userAttendance, setUserAttendance] = useState<UserAttendance[]>();

  const isBtnDisabled =
    userAttendance && userAttendance.length > 0 ? false : true;

  useEffect(() => {
    if (selectedUserItem) {
      const params: UserAttendanceData = {
        locale: currentLanguage,
        patient_id: selectedUserItem?.id,
        start_date: Moment(startDate, ENFormat).format(ENFormat),
        end_date: Moment(endDate, ENFormat).format(ENFormat),
      };
      dispatch(UserAttendanceActionCreators.userAttendanceAction(params));
    }
  }, [selectedUserItem, startDate, endDate]);

  useEffect(() => {
    attendancelist && attendancelist.result
      ? setUserAttendance(attendancelist.result)
      : setUserAttendance([]);
  }, [attendancelist]);

  const handleDate = (date: string) => {
    const format = currentLanguage == "ja" ? JAFormat : ENFormat;
    return Moment(date).format(format);
  };

  const downloadPDF = () => {
    if (attendancelist && attendancelist.result.length > 0) {
      const data = [];
      for (const key in attendancelist.result) {
        const temp = [
          handleDate(attendancelist.result[key].date),
          attendancelist.result[key].attendance_status,
        ];
        data.push(temp);
      }
      const doc = new jsPDF();
      doc.addFileToVFS("mplus.ttf", mplusFont);
      doc.addFont("mplus.ttf", "mplus", "normal");
      doc.setFont("mplus", "normal");

      const myTable = {
        head: [[t("date"), t("attended")]],
        body: data,
        headStyles: {
          font: "mplus",
        },
        bodyStyles: {
          font: "mplus",
        },
        margin: { top: 25 },
      };

      doc.text(t("attendance-data"), 105, 15, { align: "center" });
      autoTable(doc, myTable);
      const filename = selectedUserItem?.last_name + "_attendance.pdf";
      doc.save(filename);
    }
  };

  const AttendedDates =
    userAttendance && userAttendance.length > 0
      ? userAttendance.map((attendance) => Moment(attendance.date).toDate())
      : [];

  const HeaderModal = () => {
    return (
      <div>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          highlightDates={AttendedDates}
        />
      </div>
    );
  };

  const renderUsername = () => {
    if (selectedUserItem) {
      return (
        selectedUserItem.last_name +
        " " +
        selectedUserItem.first_name +
        "さんの"
      );
    }
  };

  return (
    <Modal
      type="search"
      title={screens.md ? <HeaderModal /> : <div></div>}
      visible={visible}
      onCancel={handleCancel}
    >
      <div>
        {!screens.md && <HeaderModal />}
        <WrapperBodyTop>
          <AttendanceLabel>
            {renderUsername() +
              Moment(startDate, ENFormat).format(JAFormat) +
              "〜" +
              Moment(endDate, ENFormat).format(JAFormat) +
              "までの出欠表"}
          </AttendanceLabel>
          <AttendanceDownloadBtn
            icon={
              <DownloadIcon
                width="14px"
                height="14px"
                fill="currentColor"
                style={{ position: "absolute", left: 4, top: 5 }}
              />
            }
            name={t("pdf-output")}
            disabled={isBtnDisabled}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={12}
            fontWeight={700}
            bdr="4px"
            onClick={downloadPDF}
            padding="3px 8px 1px 15px"
          />
        </WrapperBodyTop>
        {userAttendance && userAttendance.length > 0 ? (
          userAttendance.map((attendance, key) => {
            return (
              <DataRow key={key}>
                <span>{handleDate(attendance.date)}</span>
                <p>{attendance.attendance_status}</p>
              </DataRow>
            );
          })
        ) : (
          <DataNotFound>
            {Moment(startDate, ENFormat).format(JAFormat) +
              "〜" +
              Moment(endDate, ENFormat).format(JAFormat) +
              t("attendance-not-found")}
          </DataNotFound>
        )}
      </div>
    </Modal>
  );
};

export default AttendanceModal;
