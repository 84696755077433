import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box } from "components";

import {
  GetDataStatisticsActionCreators,
  GetDataGroupClassCreators,
  GetDataDestinationActionCreators,
  SearchConditionActionCreators,
} from "redux/rootActions";
import { dataProgressBarResponse } from "redux/bodyTemperature/progressBar/progressBarStates";
import { dataStatisticsResponse } from "redux/bodyTemperature/statistics/statisticsStates";
import { settingDataResponse } from "redux/setting/settingData/settingDataStates";

import SearchTerms from "./SearchTerms";
import { WrapperBodyTemperature } from "./BodyTemperature.style";

const BodyTemperature = () => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });

  const dispatch = useDispatch();
  const settingData = useSelector(settingDataResponse);
  const dataProgressBar = useSelector(dataProgressBarResponse);
  const dataStatistics = useSelector(dataStatisticsResponse);

  const firstRender: any = useRef(false);

  useEffect(() => {
    if (
      settingData &&
      Object.keys(settingData.result).length &&
      !firstRender.current
    ) {
      firstRender.current = true;
      const { app_languages, temperature_managerment_info } =
        settingData?.result;
      app_languages.forEach((language: any) => {
        if (language.id === temperature_managerment_info.app_language_id) {
          const currentLanguage = language.language_code;
          const currentDate = moment(new Date()).format("YYYY/MM/DD");
          dispatch(
            GetDataStatisticsActionCreators.getStatisticsAction({
              per_page: 150,
              page: 1,
              aggregate_date: currentDate,
              app_language_id: currentLanguage,
            })
          );
          dispatch(
            GetDataGroupClassCreators.getDataGroupClassAction({
              locale: currentLanguage,
            })
          );
          dispatch(
            GetDataDestinationActionCreators.getDataDestinationAction({
              locale: currentLanguage,
            })
          );

          dispatch(SearchConditionActionCreators.searchConditionAction());
        }
      });
    }
  }, [settingData]);

  return (
    <WrapperBodyTemperature>
      <Box
        title={t("health-observation-label")}
        subTitle={`${t1("total-number-of-people")}${
          dataStatistics?.all_member_amount || 0
        }${t2("people")}`}
        padding="18px 16px 8px"
      >
        <SearchTerms />
      </Box>
    </WrapperBodyTemperature>
  );
};

export default BodyTemperature;
