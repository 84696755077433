import {
  Action,
  ActionType,
  DataChildDepartmentResponse,
} from "models/bodyTemperature/childDepartment";
import createReducer from "../../createReducer";

export interface DataChildDepartmentReducerType {
  loading: boolean;
  error?: string;
  dataChildDepartmentResponse?: DataChildDepartmentResponse;
}

const defaultState: DataChildDepartmentReducerType = {
  loading: false,
  error: undefined,
  dataChildDepartmentResponse: undefined,
};

export const dataChildDepartmentReducer =
  createReducer<DataChildDepartmentReducerType>(defaultState, {
    [ActionType.DATA_CHILD_DEPARTMENT](state: DataChildDepartmentReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DATA_CHILD_DEPARTMENT_ERROR](
      state: DataChildDepartmentReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DATA_CHILD_DEPARTMENT_SUCCESS](
      state: DataChildDepartmentReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        dataChildDepartmentResponse: action.payload,
      };
    },
  });
