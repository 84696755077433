import { IResponse } from "models";

export enum ActionType {
  DATA_CHILD_DEPARTMENT = "action/DATA_CHILD_DEPARTMENT",
  DATA_CHILD_DEPARTMENT_SUCCESS = "action/DATA_CHILD_DEPARTMENT_SUCCESS",
  DATA_CHILD_DEPARTMENT_ERROR = "action/DATA_CHILD_DEPARTMENT_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DataChildDepartmentResponse extends IResponse {
  result: any;
}
