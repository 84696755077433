import { IResponse } from "models";

export enum ActionType {
  PUSH_NOTIFICATION = "action/PUSH_NOTIFICATION",
  PUSH_NOTIFICATION_SUCCESS = "action/PUSH_NOTIFICATION_SUCCESS",
  PUSH_NOTIFICATION_ERROR = "action/PUSH_NOTIFICATION_ERROR",
  PUSH_NOTIFICATION_RESET = "action/PUSH_NOTIFICATION_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IPushNotificationPayload {
  id: number;
  title: string;
  description?: string;
}

export interface IPushNotificationResponse extends IResponse {
  result: any;
}
