import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IMentalCheckTypeListResponse,
} from "models/mentalHealth/typeList";
import { httpStatus } from "configs/httpStatus";
import { getMentalCheckTypeInfo } from "services/mentalHealth";
import { handleSuccesAction, handleErrorAction } from "./typeListActions";

function* typeListSaga() {
  try {
    const response: IMentalCheckTypeListResponse = yield call(
      getMentalCheckTypeInfo
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetTypeListWatcher() {
  yield takeLatest(ActionType.MENTAL_CHECK_TYPE_LIST as any, typeListSaga);
}

export default [fork(onGetTypeListWatcher)];
