import {
  Action,
  ActionType,
  CSVDownloadAllQuestionResponse,
} from "models/questionnaires/downloadCSVAllQuestions";
import createReducer from "redux/createReducer";

export interface DownloadCSVAllQuestionsReducerType {
  loading: boolean;
  error?: string;
  downloadCSVAllQuestionsResponse?: CSVDownloadAllQuestionResponse;
}

const defaultState: DownloadCSVAllQuestionsReducerType = {
  loading: false,
  error: undefined,
  downloadCSVAllQuestionsResponse: undefined,
};

export const downloadCSVAllQuestionsReducer =
  createReducer<DownloadCSVAllQuestionsReducerType>(defaultState, {
    [ActionType.CSV_DOWNLOAD_ALL_QUESTION](
      state: DownloadCSVAllQuestionsReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CSV_DOWNLOAD_ALL_QUESTION_ERROR](
      state: DownloadCSVAllQuestionsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CSV_DOWNLOAD_ALL_QUESTION_SUCCESS](
      state: DownloadCSVAllQuestionsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        downloadCSVAllQuestionsResponse: action.payload,
      };
    },

    [ActionType.CSV_DOWNLOAD_ALL_QUESTION_RESET_DATA](
      state: DownloadCSVAllQuestionsReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        downloadCSVAllQuestionsResponse: [],
      };
    },
  });
