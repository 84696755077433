import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IDuplicateQuestionPayload,
  DuplicateQuestionResponse,
} from "models/questionnaires/duplicateQuestion";
import { httpStatus } from "configs/httpStatus";
import { duplicateQuestion } from "services/questionnaires";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./duplicateQuestionActions";

function* duplicateQuestionSaga({
  payload,
}: {
  payload: IDuplicateQuestionPayload;
}) {
  try {
    const response: DuplicateQuestionResponse = yield call(
      duplicateQuestion,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onDuplicateQuestionSagaSubmitWatcher() {
  yield takeLatest(ActionType.DUPLICATE_QUESTION as any, duplicateQuestionSaga);
}

export default [fork(onDuplicateQuestionSagaSubmitWatcher)];
