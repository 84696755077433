import {
  ActionType,
  IAnswerHistoryData,
  IAnswerHistoryResponse,
} from "models/questionnaires/answerHistory";

export const answerHistoryListAction = (payload: IAnswerHistoryData) => {
  return {
    type: ActionType.ANSWER_HISTORY_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.ANSWER_HISTORY_LIST_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: IAnswerHistoryResponse) => {
  return {
    type: ActionType.ANSWER_HISTORY_LIST_SUCCESS,
    payload,
  };
};

export const handleResetHistoryListAction = () => {
  return {
    type: ActionType.RESET_ANSWER_HISTORY_LIST,
  };
};
