import React from "react";
import { useTranslation } from "react-i18next";

import {
  BoxItem4Chart,
  BoxContent4Chart,
  WrapperChartInfor,
  ChartInforTop,
  ChartInforBottom,
  VerticalLine,
  ContainerChartInforTop,
  WrapperPieChart,
} from "../Vaccination.style";
import PieChart from "./PieChart";

const InoculationChart = ({ data }: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "vaccination",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });

  return (
    <WrapperPieChart>
      <BoxItem4Chart>
        <BoxContent4Chart>
          <PieChart
            data={data.percent}
            dataCenter={data.center}
            colors={data.color}
          />
          <WrapperChartInfor>
            <ChartInforTop>
              <ContainerChartInforTop>
                <div>{t("not-implemented")}</div>
                <div style={{ color: "#B7B7B7" }}>
                  {data.notImplemented}
                  {t1("people")}（{data.percent[1].value}
                  ％）
                </div>
              </ContainerChartInforTop>
              <VerticalLine />
              <ContainerChartInforTop>
                <div>{t("implemented")}</div>
                <div style={{ color: `${data.color[0]}` }}>
                  {data.implemented}
                  {t1("people")}（{data.percent[0].value}％）
                </div>
              </ContainerChartInforTop>
            </ChartInforTop>
            <ChartInforBottom>
              [{t("all")} {data.implemented}/{data.total}]
            </ChartInforBottom>
          </WrapperChartInfor>
        </BoxContent4Chart>
      </BoxItem4Chart>
    </WrapperPieChart>
  );
};

export default InoculationChart;
