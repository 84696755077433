import { Action, ActionType } from "models/deleteuser";
import { IResponse } from "models";
import createReducer from "../createReducer";

export interface DeleteUserReducerType {
  loading: boolean;
  error?: string;
  deleteUserResponse?: IResponse;
}

const defaultState: DeleteUserReducerType = {
  loading: false,
  error: undefined,
  deleteUserResponse: undefined,
};

export const deleteUserReducer = createReducer<DeleteUserReducerType>(
  defaultState,
  {
    [ActionType.DELETE_USER](state: DeleteUserReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_USER_ERROR](
      state: DeleteUserReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DELETE_USER_SUCCESS](
      state: DeleteUserReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteUserResponse: action.payload,
      };
    },
  }
);
