import { IResponse } from "../../../models";
import {
  ActionType,
  IDeleteActivityData,
} from "models/activitymanagement/deleteActivity";

export const deleteActivityAction = (payload: IDeleteActivityData) => {
  return {
    type: ActionType.DELETE_ACTIVITY,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_ACTIVITY_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.DELETE_ACTIVITY_SUCCESS,
    payload,
  };
};
