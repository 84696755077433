import { IResponse } from "models";

export enum ActionType {
  GET_QUESTIONNAIRE_RESPONSE_DETAIL = "action/GET_QUESTIONNAIRE_RESPONSE_DETAIL",
  GET_QUESTIONNAIRE_RESPONSE_DETAIL_SUCCESS = "action/GET_QUESTIONNAIRE_RESPONSE_DETAIL_SUCCESS",
  GET_QUESTIONNAIRE_RESPONSE_DETAIL_ERROR = "action/GET_QUESTIONNAIRE_RESPONSE_DETAIL_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IQuestionnaireResponseDetailPayload {
  id?: string;
}

export interface IQuestionnaireResponseDetailResponse extends IResponse {
  result: {
    questionaire_response_details: any[];
  };
}
