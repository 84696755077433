import { Action, ActionType } from "models/createuserlist";
import { IResponse } from "models";
import createReducer from "../createReducer";

export interface CreateUserReducerType {
  loading: boolean;
  error?: string;
  createUserResponse?: IResponse;
}

const defaultState: CreateUserReducerType = {
  loading: false,
  error: undefined,
  createUserResponse: undefined,
};

export const createUserReducer = createReducer<CreateUserReducerType>(
  defaultState,
  {
    [ActionType.CREATE_USER](state: CreateUserReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_USER_ERROR](
      state: CreateUserReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CREATE_USER_SUCCESS](
      state: CreateUserReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createUserResponse: action.payload,
      };
    },

    [ActionType.RESET_CREATE_USER](state: CreateUserReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        createUserResponse: undefined,
      };
    },
  }
);
