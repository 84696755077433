import { IResponse } from "models";

export enum ActionType {
  DATA_PATIENT_DETAIL = "action/DATA_PATIENT_DETAIL",
  DATA_PATIENT_DETAIL_SUCCESS = "action/DATA_PATIENT_DETAIL_SUCCESS",
  DATA_PATIENT_DETAIL_ERROR = "action/DATA_PATIENT_DETAIL_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DataPatientDetailResponse extends IResponse {
  result: any;
}

export interface DataPatientDetailRequest {
  patient_id?: number;
  date?: any;
  locale?: string;
}
