import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  // get school class types
  GET_CLASS_TYPE = "action/GET_CLASS_TYPE",
  GET_CLASS_TYPE_SUCCESS = "action/GET_CLASS_TYPE_SUCCESS",
  GET_CLASS_TYPE_ERROR = "action/GET_CLASS_TYPE_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IClassTypeData {
  section_type: number;
}

export interface IClassTypeResponse extends IResponse {
  result: IOption[];
}
