import {
  ActionType,
  IMessageHistoryDetailData,
  IMessageHistoryDetailResponse,
} from "models/messagemanagement/messageHistoryDetail";

export const messageHistoryListAction = (
  payload: IMessageHistoryDetailData
) => {
  return {
    type: ActionType.MESSAGE_HISTORY_DETAIL,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MESSAGE_HISTORY_DETAIL_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IMessageHistoryDetailResponse) => {
  return {
    type: ActionType.MESSAGE_HISTORY_DETAIL_SUCCESS,
    payload,
  };
};
export const handleResetHistoryDetailAction = () => {
  return {
    type: ActionType.RESET_MESSAGE_HISTORY_DETAIL,
  };
};
