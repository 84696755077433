import { IResponse } from "models";

export enum ActionType {
  DELETE_MENTAL_CHECK = "action/DELETE_MENTAL_CHECK",
  DELETE_MENTAL_CHECK_SUCCESS = "action/DELETE_MENTAL_CHECK_SUCCESS",
  DELETE_MENTAL_CHECK_ERROR = "action/DELETE_MENTAL_CHECK_ERROR",
  RESET_DELETE_MENTAL_CHECK = "action/RESET_DELETE_MENTAL_CHECK",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDeleteMentalCheckResponse extends IResponse {
  result: any;
}

export interface DeleteMentalCheckData {
  id?: number;
}
