import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  GetCitiesResponse,
  ActionType,
  GetCitiesPayload,
} from "models/dashboard/cities";
import { httpStatus } from "configs/httpStatus";
import { fetchCities } from "services/dashboard";
import { handleSuccesAction, handleErrorAction } from "./citiesActions";

function* getCitiesSaga({ payload }: { payload: GetCitiesPayload }) {
  try {
    const response: GetCitiesResponse = yield call(fetchCities, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetCitiesWatcher() {
  yield takeLatest(ActionType.GET_CITIES as any, getCitiesSaga);
}

export default [fork(onGetCitiesWatcher)];
