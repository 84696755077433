import { IResponse } from "models";

export enum ActionType {
  // confirm student list data for class change
  CONFIRM_STUDENT_LIST = "action/CONFIRM_STUDENT_LIST",
  CONFIRM_STUDENT_LIST_SUCCESS = "action/CONFIRM_STUDENT_LIST_SUCCESS",
  CONFIRM_STUDENT_LIST_ERROR = "action/CONFIRM_STUDENT_LIST_ERROR",
  CONFIRM_STUDENT_LIST_RESET = "action/CONFIRM_STUDENT_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IConfirmStudentListData {
  current_class_id?: number;
  new_class_id?: number;
  page: number;
  per_page: number;
  locale?: string;
}

export interface IConfirmStudentListResponse extends IResponse {
  result: IConfirmStudentListItem[];
  total_record: number;
  next_page: number;
  current_page: number;
}

export interface IConfirmStudentListItem {
  full_name: string;
  old_class: string;
  new_class: string;
  current_roll_number: number;
  new_roll_number: number;
  updated_at: string;
}
