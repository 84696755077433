export enum ActionType {
  CREATE_CLASS = "action/CREATE_CLASS",
  CREATE_CLASS_SUCCESS = "action/CREATE_CLASS_SUCCESS",
  CREATE_CLASS_ERROR = "action/CREATE_CLASS_ERROR",
  CREATE_CLASS_RESET = "action/CREATE_CLASS_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICreateClassData {
  class: {
    name: string;
    parent_id?: number;
  };
}
