import React, { useEffect, useRef, useState } from "react";
import { Col, Row, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Button, Checkbox1 } from "components";
import {
  UpdateChartOrderActionCreators,
  SettingDataActionCreators,
} from "redux/rootActions";
import { dataChartsResponse } from "redux/bodyTemperature/charts/chartStates";
import { settingDataResponse } from "redux/setting/settingData/settingDataStates";
import { updateChartOrderResponse } from "redux/setting/updateChartOrder/updateChartOrderStates";
import { WrapperSelect, SelectStyled } from "./Dashboard.style";
import {
  Fever,
  FeverWith2Level,
  FeverWith3Level,
  SymtomDetails,
  AverageBodyTemperature,
  Absence,
  Symptom,
  MoodChange,
  Statistical,
  HealthCheckProgress,
  AttendanceStatus,
  DeseaseOutbreak,
} from "./charts";
import { httpStatus } from "configs/httpStatus";
import { SendCompleteIcon } from "icons";

export interface IChart {
  data?: any;
  avgTemp?: any;
  handleCloseChart: (chartName: string) => void;
}

const MovableItem = ({ name, index, moveCardHandler, children }: any) => {
  const ref = useRef<any>(null);
  const [, drop] = useDrop({
    accept: "Our first type",
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset: any = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCardHandler(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "Our first type",
    item: { index },
    end: (item, monitor) => {
      const dropResult: any = monitor.getDropResult();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  const transform = isDragging ? "rotate(2deg)" : "rotate(0deg)";
  drag(drop(ref));

  if (name) {
    return (
      <Col
        xs={24}
        ref={ref}
        className="movable-item"
        style={{
          opacity,
          transform,
          transition: "all 0.5s ease-in-out",
        }}
      >
        {children}
      </Col>
    );
  }

  return (
    <Col
      xs={24}
      md={24}
      xl={12}
      ref={ref}
      className="movable-item"
      style={{
        opacity,
        transform,
        transition: "all 0.5s ease-in-out",
      }}
    >
      {children}
    </Col>
  );
};

const BodyTemperatureResult = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "dashboard",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "setting",
  });

  const dispatch = useDispatch();
  const dataCharts = useSelector(dataChartsResponse);
  const settingData = useSelector(settingDataResponse);
  const updateChartOrderData = useSelector(updateChartOrderResponse);

  const [feverData, setFeverData] = useState<any>([]);
  const [selectedCharts, setSelectedCharts] = useState<string[]>([]);
  const [charts, setCharts] = useState<any>([]);
  const [originalCharts, setOriginalCharts] = useState<any>([]);

  useEffect(() => {
    if (
      updateChartOrderData &&
      updateChartOrderData.status === httpStatus.StatusOK
    ) {
      notification.success({
        placement: "bottomRight",
        message: t2("update-success"),
        icon: <SendCompleteIcon />,
      });
      dispatch(SettingDataActionCreators.getSettingDataAction());
      dispatch(UpdateChartOrderActionCreators.handleResetAction());
    }
  }, [updateChartOrderData]);

  const handleCloseChart = (chartName: string) => {
    const coppiedState = [...selectedCharts];
    coppiedState.splice(coppiedState.indexOf(chartName), 1);
    setSelectedCharts(coppiedState);
  };

  const defaultChart: any = {
    0: {
      key: 0,
      id: 0,
      name: "test",
      com: <DeseaseOutbreak handleCloseChart={handleCloseChart} />,
      value: "0",
    },
    1: {
      key: 1,
      id: 1,
      com: <AttendanceStatus handleCloseChart={handleCloseChart} />,
      value: "1",
    },
    2: {
      key: 2,
      id: 2,
      com: <Absence handleCloseChart={handleCloseChart} />,
      value: "2",
    },
    3: {
      key: 3,
      id: 3,
      com: <HealthCheckProgress handleCloseChart={handleCloseChart} />,
      value: "3",
    },
    4: {
      key: 4,
      id: 4,
      com: <Statistical handleCloseChart={handleCloseChart} />,
      value: "4",
    },
    5: {
      key: 5,
      id: 5,
      com: <Fever data={feverData} handleCloseChart={handleCloseChart} />,
      value: "5",
    },
    6: {
      key: 6,
      id: 6,
      com: <AverageBodyTemperature handleCloseChart={handleCloseChart} />,
      value: "6",
    },
    7: {
      key: 7,
      id: 7,
      com: <FeverWith2Level handleCloseChart={handleCloseChart} />,
      value: "7",
    },
    8: {
      key: 8,
      id: 8,
      com: <FeverWith3Level handleCloseChart={handleCloseChart} />,
      value: "8",
    },
    9: {
      key: 9,
      id: 9,
      com: <Symptom handleCloseChart={handleCloseChart} />,
      value: "9",
    },
    10: {
      key: 10,
      id: 10,
      com: <MoodChange handleCloseChart={handleCloseChart} />,
      value: "10",
    },
    11: {
      key: 11,
      id: 11,
      com: <SymtomDetails handleCloseChart={handleCloseChart} />,
      value: "11",
    },
  };

  const [chartList, setChartList] = useState([
    {
      name: t2("desease-outbreak"),
      key: 0,
      id: 0,
      value: "0",
    },
    {
      name: t2("attendance-status-label"),
      key: 1,
      id: 1,
      value: "1",
    },
    {
      name: t2("absence-label"),
      key: 2,
      id: 2,
      value: "2",
    },
    {
      name: t2("number-people-attend-physical-check"),
      key: 3,
      id: 3,
      value: "3",
    },
    {
      name: t("number-people-attend-body-temperature-check"),
      key: 4,
      id: 4,
      value: "4",
    },
    {
      name: t("check-attend-status"),
      key: 5,
      id: 5,
      value: "5",
    },
    {
      name: t("average-body-temperature-7-days"),
      key: 6,
      id: 6,
      value: "6",
    },
    {
      name: t2("fever-2-level-label"),
      key: 7,
      id: 7,
      value: "7",
    },
    {
      name: t2("fever-3-level-label"),
      key: 8,
      id: 8,
      value: "8",
    },
    {
      name: t2("symptom-label"),
      key: 9,
      id: 9,
      value: "9",
    },
    {
      name: t2("mood-change-label"),
      key: 10,
      id: 10,
      value: "10",
    },
    {
      name: t("symtoms-details"),
      key: 11,
      id: 11,
      value: "11",
    },
  ]);

  useEffect(() => {
    if (selectedCharts.length > 0) {
      const chartListByOrder = selectedCharts.map((item: any) => {
        return defaultChart[item];
      });
      setCharts(chartListByOrder);
      setOriginalCharts(chartListByOrder);
    }
  }, [selectedCharts]);

  const moveCardHandler = (dragIndex: any, hoverIndex: any) => {
    const dragItem = charts[dragIndex];

    if (dragItem) {
      setCharts((prevState: any) => {
        const coppiedStateArray = [...charts];
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

        if (selectedCharts.length !== coppiedStateArray.length) {
          return prevState.filter(
            (val: any, index: any) => originalCharts.indexOf(val) === index
          );
        } else {
          return coppiedStateArray.filter(
            (val: any, index: any) => coppiedStateArray.indexOf(val) === index
          );
        }
      });
    }
  };

  const handleOnChangeCharts = (value: string[]) => {
    setSelectedCharts(value);
  };

  const handleConvertDate = (date: string) => {
    return moment(new Date(date)).format("M/D");
  };

  const handleData4FeverChart = (
    temperature_single_heat: number[],
    temperature_high_heat: number[],
    temperature_no_heat: number[],
    temperature_not_attempt: number[],
    days_array: string[]
  ) => {
    const feverDataArr = [];
    for (let i = 0; i <= 6; i++) {
      feverDataArr.push({
        date: handleConvertDate(days_array[i]),
        lowFever: temperature_single_heat[i],
        fever: temperature_high_heat[i],
        noHeat: temperature_no_heat[i],
        notAttempt: temperature_not_attempt[i],
      });
    }
    setFeverData(feverDataArr);
  };

  useEffect(() => {
    if (dataCharts && Object.keys(dataCharts?.result).length) {
      const {
        temperature_single_heat,
        temperature_high_heat,
        temperature_no_heat,
        temperature_not_attempt,
        days_array,
      } = dataCharts?.result;

      handleData4FeverChart(
        temperature_single_heat,
        temperature_high_heat,
        temperature_no_heat,
        temperature_not_attempt,
        days_array
      );
    }
  }, [dataCharts]);

  useEffect(() => {
    if (settingData && Object.keys(settingData.result).length) {
      let cloneSelectedCharts: any = [];
      const coppiedChartList: any = [...chartList];
      const isDeseaseOutbreak =
        settingData.result.is_disease_outbreak_graph_display;
      const chartOrder =
        settingData.result.temperature_managerment_info.dashboard_chart_order;
      const defaultSelectedCharts = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
      ];

      if (!chartOrder) {
        cloneSelectedCharts = isDeseaseOutbreak
          ? ["0", ...defaultSelectedCharts]
          : [...defaultSelectedCharts];

        dispatch(
          UpdateChartOrderActionCreators.updateChartOrderAction({
            dashboard_chart_order: cloneSelectedCharts.join(),
          })
        );
      } else {
        const newArr = chartOrder.split(",");
        cloneSelectedCharts = [...newArr];

        if (!isDeseaseOutbreak) {
          if (newArr.includes("0")) {
            newArr.splice(newArr.indexOf("0"), 1);
          }
          coppiedChartList.splice(0, 1);
        }
      }

      setSelectedCharts(cloneSelectedCharts);
      setChartList(coppiedChartList);
    }
  }, [settingData]);

  return (
    <React.Fragment>
      <WrapperSelect>
        <SelectStyled
          placeholder={t("add-graph")}
          fs={14}
          fsLabel={12}
          dropdownRender={() => (
            <div style={{ padding: "12px 10px 0px" }}>
              <Checkbox1
                list={chartList}
                onChange={handleOnChangeCharts}
                value={selectedCharts}
              />
            </div>
          )}
        />
        <Button
          name={t3("keep")}
          background="#2F8CAE"
          color="#FFFFFF"
          border="none"
          fontSize={16}
          fontWeight={700}
          padding="0px 6px"
          onClick={() => {
            const chartOrder = charts
              .map((chart: any) => {
                return chart.value;
              })
              .filter((item: any) => selectedCharts.includes(item))
              .join();

            dispatch(
              UpdateChartOrderActionCreators.updateChartOrderAction({
                dashboard_chart_order: chartOrder,
              })
            );
          }}
        />
      </WrapperSelect>
      <DndProvider backend={HTML5Backend}>
        <Row
          gutter={[16, 16]}
          style={{
            marginBottom: 16,
          }}
        >
          {charts.map((item: any, index: number) => {
            return (
              <MovableItem
                key={item.id}
                setCharts={setCharts}
                index={index}
                name={item.name}
                moveCardHandler={moveCardHandler}
                originalCharts={originalCharts}
              >
                {item.com}
              </MovableItem>
            );
          })}
        </Row>
      </DndProvider>
    </React.Fragment>
  );
};

export default BodyTemperatureResult;
