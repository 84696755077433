import { IResponse } from "models";

export enum ActionType {
  UPDATE_DESEASE_SETTING = "action/UPDATE_DESEASE_SETTING",
  UPDATE_DESEASE_SETTING_SUCCESS = "action/UPDATE_DESEASE_SETTING_SUCCESS",
  UPDATE_DESEASE_SETTING_ERROR = "action/UPDATE_DESEASE_SETTING_ERROR",
  RESET_DESEASE_SETTING = "action/RESET_DESEASE_SETTING",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateDeseaseResponse extends IResponse {}

export interface IUpdateDeseasePayload {
  is_disease_outbreak_graph_display_for_patients: boolean;
}
