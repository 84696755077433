import { ActionType, ICreateVenuesResponse } from "models/vaccine/createVenues";

export const createVenuesAction = (payload: any) => {
  return {
    type: ActionType.VACCINE_CREATE_VENUES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.VACCINE_CREATE_VENUES_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ICreateVenuesResponse) => {
  return {
    type: ActionType.VACCINE_CREATE_VENUES_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.VACCINE_CREATE_VENUES_SUCCESS,
  };
};
