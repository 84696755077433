import React from "react";
import { Grid } from "antd";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { Trans, useTranslation } from "react-i18next";

import { NoDataIcon } from "icons";
import { WrapperChartText, ContainerPieChartText } from "../MentalHealth.style";

const { useBreakpoint } = Grid;

const PieChartCustom = ({
  data,
  colors,
  textCenter,
  colorTextCenter,
  textSmall,
  amount,
  total,
}: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const screens = useBreakpoint();
  const size = 140;
  const innerRadius = 46;
  const outerRadius = 70;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        transform: "rotateY(180deg)",
      }}
    >
      <div
        style={{
          width: size,
          height: size,
          transform: "rotate(-90deg) scaleY(-1)",
        }}
      >
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              fill="#8884d8"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              dataKey="value"
            >
              {data.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <WrapperChartText>
        <ContainerPieChartText>
          <div>
            <span className="text-big" style={{ color: colorTextCenter }}>
              {textCenter}
            </span>
            <span className="text-small">{textSmall}</span>
          </div>
          <div>
            <span
              className="text-small"
              style={{ color: colorTextCenter, fontSize: 12 }}
            >
              {amount}
            </span>
            /{total}
          </div>
        </ContainerPieChartText>
      </WrapperChartText>
    </div>
  );
};

export default PieChartCustom;
