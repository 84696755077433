import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IConsultationSettingsResponse,
} from "models/consultationRequest/consultationSettings";
import { httpStatus } from "configs/httpStatus";
import { fetchConsultationSettings } from "services/consultationRequest";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./consultationSettingsActions";

function* consultationSettingsSaga() {
  try {
    const response: IConsultationSettingsResponse = yield call(
      fetchConsultationSettings
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleErrorAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetConsultationSettingsWatcher() {
  yield takeLatest(
    ActionType.CONSULTATION_SETTINGS as any,
    consultationSettingsSaga
  );
}

export default [fork(onGetConsultationSettingsWatcher)];
