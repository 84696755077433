import { IResponse } from "models";

export enum ActionType {
  // update activity/group for school
  UPDATE_ACTIVITY = "action/UPDATE_ACTIVITY",
  UPDATE_ACTIVITY_SUCCESS = "action/UPDATE_ACTIVITY_SUCCESS",
  UPDATE_ACTIVITY_ERROR = "action/UPDATE_ACTIVITY_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateActivityData {
  activity: {
    name: string;
    id: number;
    is_required?: boolean;
  };
  locale?: string;
}

export interface IUpdateActivityResponse extends IResponse {
  result: IUpdateActivity;
}

export interface IUpdateActivity {
  id: number;
  name: string;
}
