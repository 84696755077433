import {
  ActionType,
  Action,
  AnswerInfoResponse,
} from "models/questionnaires/answerInfo";
import createReducer from "../../createReducer";

export interface AnswerInfoReducerType {
  loading: boolean;
  error?: string;
  answerInfoResponse?: AnswerInfoResponse;
}

const defaultState: AnswerInfoReducerType = {
  loading: false,
  error: undefined,
  answerInfoResponse: undefined,
};

export const answerInfoReducer = createReducer<AnswerInfoReducerType>(
  defaultState,
  {
    [ActionType.GET_ANSWER_INFO](state: AnswerInfoReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_ANSWER_INFO_ERROR](
      state: AnswerInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        answerInfoResponse: undefined,
      };
    },

    [ActionType.GET_ANSWER_INFO_SUCCESS](
      state: AnswerInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        answerInfoResponse: action.payload,
      };
    },
  }
);
