import {
  ActionType,
  CSVDownloadUserResponse,
  IUserListDownloadData,
} from "models/csvdownloaduser";

export const csvDownloadUserAction = (payload: IUserListDownloadData) => {
  return {
    type: ActionType.CSV_DOWNLOAD_USER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CSV_DOWNLOAD_USER_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: CSVDownloadUserResponse) => {
  return {
    type: ActionType.CSV_DOWNLOAD_USER_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.CSV_DOWNLOAD_RESET_DATA,
  };
};
