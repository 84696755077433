import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IGetClassFilterResponse,
} from "models/messagemanagement/classFilterList";
import { httpStatus } from "configs/httpStatus";
import { fetchClassFilterList } from "services/messagemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./classFilterListActions";

function* classListFilterSaga() {
  try {
    const response: IGetClassFilterResponse = yield call(fetchClassFilterList);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetClassFilterListWatcher() {
  yield takeLatest(
    ActionType.GET_CLASS_LIST_FILTER as any,
    classListFilterSaga
  );
}

export default [fork(onGetClassFilterListWatcher)];
