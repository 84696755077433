import React, { useEffect, useState, useRef } from "react";
import { Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon, DownloadIcon, NoDataIcon } from "icons";
import { generateImg, uuid } from "utils";

import { DetailSymtomActionCreators } from "redux/rootActions";
import {
  detailSymtomDataResponse,
  selectIsLoading,
} from "redux/dashboard/detailSymtom/detailSymtomStates";

import {
  WrapperCloseIcon,
  BoxHeader,
  BoxTitle,
  LightDivider4SymtomsDetailChart,
  BoxContent,
  TopChart,
  MiddleChart,
  BottomChart,
  WrapperMiddleChart,
  WrapperSymtoms,
  SymtomStyled,
  WrapperTooltipLeft,
  WrapperTooltipRight,
  TooltipLeftStyled,
  TooltipRightStyled,
  WrapperChart,
  ContainerSymtomDetailChart,
  BoxItemStyled,
  WrapperLoadingChart,
  WrapperNoData,
} from "../Dashboard.style";
import { IChart } from "../BodyTemperatureResult";
import moment from "moment";
import { Loading } from "components";

interface IItemActivated {
  [key: string]: boolean;
}

const SymtomDetails: React.FC<IChart> = ({ handleCloseChart }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });

  const overlayEl = useRef<HTMLDivElement>(null);
  const imgRef = useRef<any>();
  const detailSymtomData = useSelector(detailSymtomDataResponse);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();

  const [visibleTooltip, setVisibleTooltip] = useState<IItemActivated>({});
  const [patients, setPatients] = useState<string[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [symtoms, setSymtoms] = useState([]);

  const handleToggleTooltip = (id: string, value: boolean) => {
    setVisibleTooltip({ [id]: value });
  };

  const handleConvertDate = (date: string) => {
    return moment(new Date(date)).format("M/D");
  };

  useEffect(() => {
    dispatch(
      DetailSymtomActionCreators.getDetailSymtomAction({
        aggregate_date_to: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
  }, []);

  useEffect(() => {
    if (detailSymtomData && Object.keys(detailSymtomData).length) {
      const patients: string[] = [];
      const date: string[] = [];
      const symtoms: any = [];

      detailSymtomData.amount.forEach((item: any) => {
        patients.push(item.total_amount);
        date.push(handleConvertDate(item.aggregate_date));
        if (item.amounts.length) {
          symtoms.push({
            symtomsDetail: item.amounts.map((am: any) => {
              return {
                id: uuid(),
                symptomName: am.name,
                patient: am.amount,
              };
            }),
          });
        } else {
          symtoms.push({
            symtomsDetail: [],
          });
        }
      });

      setPatients(patients);
      setDates(date);
      setSymtoms(symtoms);
    }
  }, [detailSymtomData]);

  useEffect(() => {
    if (overlayEl.current) {
      const node = overlayEl.current as any;
      node.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [symtoms]);

  return (
    // <Col xs={24} md={24} xl={12} >
    <BoxItemStyled ref={imgRef}>
      <WrapperCloseIcon>
        <CloseIcon
          fill="#2F8CAE"
          height="14px"
          width="14px"
          onClick={() => handleCloseChart("11")}
        />
      </WrapperCloseIcon>
      <BoxHeader>
        <BoxTitle>{t("symtoms-details")}</BoxTitle>
        <DownloadIcon
          fill="#3B3B3B"
          style={{ marginTop: 4, cursor: "pointer", marginRight: 16 }}
          onClick={(event: any) => {
            if (!event.detail || event.detail == 1) {
              generateImg("symtoms-detail", imgRef.current);
            }
          }}
        />
      </BoxHeader>
      <LightDivider4SymtomsDetailChart />
      {!isLoading ? (
        dates.length ? (
          <BoxContent>
            <WrapperChart>
              <ContainerSymtomDetailChart>
                <TopChart>
                  {patients.length &&
                    patients.map((patient, index) => {
                      return (
                        <div key={index}>
                          {patient}
                          {t("people")}
                        </div>
                      );
                    })}
                </TopChart>
                <WrapperMiddleChart>
                  <MiddleChart>
                    {symtoms.length &&
                      symtoms.map((symtom: any, index) => {
                        return (
                          <WrapperSymtoms key={index}>
                            {symtom?.symtomsDetail
                              .sort((a: any, b: any) => b.patient - a.patient)
                              .map((detail: any, index1: any) => {
                                return (
                                  <div
                                    style={{ position: "relative" }}
                                    key={index1}
                                  >
                                    <SymtomStyled
                                      onMouseOver={() =>
                                        handleToggleTooltip(detail.id, true)
                                      }
                                      onMouseOut={() =>
                                        handleToggleTooltip(detail.id, false)
                                      }
                                    >
                                      {detail.symptomName}
                                    </SymtomStyled>
                                    {visibleTooltip[detail.id] && (
                                      <>
                                        {index === 5 || index === 6 ? (
                                          <WrapperTooltipLeft>
                                            <TooltipLeftStyled>
                                              {detail.symptomName}
                                              <span
                                                style={{
                                                  height: 5,
                                                  margin: "-3px 1px 0 1px",
                                                }}
                                              >
                                                ...
                                              </span>
                                              {detail.patient}
                                              <span>{t("people")}</span>
                                            </TooltipLeftStyled>
                                          </WrapperTooltipLeft>
                                        ) : (
                                          <WrapperTooltipRight>
                                            <TooltipRightStyled>
                                              {detail.symptomName}
                                              <span
                                                style={{
                                                  height: 5,
                                                  margin: "-3px 1px 0 1px",
                                                }}
                                              >
                                                ...
                                              </span>
                                              {detail.patient}
                                              <span>{t("people")}</span>
                                            </TooltipRightStyled>
                                          </WrapperTooltipRight>
                                        )}
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                          </WrapperSymtoms>
                        );
                      })}
                    <p ref={overlayEl}></p>
                  </MiddleChart>
                </WrapperMiddleChart>
                <div style={{ paddingRight: 16 }}>
                  <BottomChart>
                    {dates.length &&
                      dates.map((date, index) => {
                        return <div key={index}>{date}</div>;
                      })}
                  </BottomChart>
                </div>
              </ContainerSymtomDetailChart>
            </WrapperChart>
          </BoxContent>
        ) : (
          <WrapperNoData>
            <NoDataIcon width={140} height={140} />
          </WrapperNoData>
        )
      ) : (
        <WrapperLoadingChart>
          <div className="container">
            <Loading width={70} height={70} />
          </div>
        </WrapperLoadingChart>
      )}
    </BoxItemStyled>
    // </Col>
  );
};

export default SymtomDetails;
