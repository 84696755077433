import { IResponse } from "models";

export enum ActionType {
  GET_TEMPERATURE_AVG_CHART = "action/TEMPERATURE_AVG_CHART",
  GET_TEMPERATURE_AVG_CHART_SUCCESS = "action/GET_TEMPERATURE_AVG_CHART_SUCCESS",
  GET_TEMPERATURE_AVG_CHART_ERROR = "action/GET_TEMPERATURE_AVG_CHART_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface TemperatureAvgPayload {
  aggregate_date_to: string;
}

export interface TemperatureAvgResponse extends IResponse {
  averages: any;
}
