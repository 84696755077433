import * as React from "react";

function LogoMobile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="17"
      viewBox="0 0 32 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg "
      {...props}
    >
      <path
        d="M23.8569 0C21.594 0 19.5585 0.961592 18.0802 2.50489L12.3945 8.5L18.0802 14.4951C19.5585 16.0384 21.594 17 23.8455 17C28.3372 17 31.9874 13.1892 31.9874 8.5C31.9988 3.81075 28.3485 0 23.8569 0Z"
        fill="url(#paint0_linear_364_13117)"
      />
      <path
        d="M13.8162 14.4951L8.13052 8.5L13.8162 2.50489C13.8276 2.49302 13.8503 2.46927 13.8617 2.4574C12.3948 0.937849 10.3707 0 8.1419 0C3.65021 0 0 3.81075 0 8.5C0 13.1892 3.65021 17 8.1419 17C10.3707 17 12.3948 16.0622 13.8617 14.5426C13.8503 14.5307 13.8276 14.507 13.8162 14.4951Z"
        fill="url(#paint1_linear_364_13117)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_364_13117"
          x1="18.3748"
          y1="2.4653"
          x2="30.3133"
          y2="15.0737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#83BCE2" />
          <stop offset="0.4343" stopColor="#6EB0D5" />
          <stop offset="0.7054" stopColor="#54A1C5" />
          <stop offset="1" stopColor="#2F8CAE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_364_13117"
          x1="13.8617"
          y1="17"
          x2="7.07309"
          y2="7.03229"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8381B" />
          <stop offset="1" stopColor="#EC5A42" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LogoMobile;
