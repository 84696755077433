import {
  ActionType,
  TemperatureAmountsPayload,
  TemperatureAmountsResponse,
} from "models/dashboard/temperatureAmounts";

export const getTemperatureAmountsAction = (
  payload: TemperatureAmountsPayload
) => {
  return {
    type: ActionType.GET_TEMPERATURE_AMOUNTS_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_TEMPERATURE_AMOUNTS_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: TemperatureAmountsResponse) => {
  return {
    type: ActionType.GET_TEMPERATURE_AMOUNTS_CHART_SUCCESS,
    payload,
  };
};
