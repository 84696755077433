import {
  RemindPushNotificationResponse,
  ActionType,
  IRemindPushNotificationPayload,
} from "models/questionnaires/remindPushNotification";

export const remindPushNotificationAction = (
  payload: IRemindPushNotificationPayload
) => {
  return {
    type: ActionType.REMIND_PUSH_NOTIFICATION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.REMIND_PUSH_NOTIFICATION_ERROR,
    payload,
  };
};

export const handleSuccessAction = (
  payload: RemindPushNotificationResponse
) => {
  return {
    type: ActionType.REMIND_PUSH_NOTIFICATION_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.REMIND_PUSH_NOTIFICATION_RESET,
  };
};
