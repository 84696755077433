import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { getHealthCheckSetting } from "services/setting";
import {
  ActionType,
  IGetHealthCheckSettingResponse,
} from "models/setting/getHealthCheckSetting";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./getHealthCheckSettingActions";

function* getHealthCheckSettingSaga() {
  try {
    const response: IGetHealthCheckSettingResponse = yield call(
      getHealthCheckSetting
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetHealthCheckSettingWatcher() {
  yield takeLatest(
    ActionType.GET_HEALTH_CHECK_SETTING as any,
    getHealthCheckSettingSaga
  );
}

export default [fork(onGetHealthCheckSettingWatcher)];
