import * as React from "react";

function QRCodeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="qrcode-svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 6.00001H9C8.20462 6.00071 7.44207 6.31724 6.88 6.88001C6.31723 7.44208 6.0007 8.20463 6 9.00001V23C6.0007 23.7954 6.31723 24.5579 6.88 25.12C7.44207 25.6828 8.20462 25.9993 9 26H23C23.3955 26.001 23.7873 25.9237 24.1529 25.7727C24.5184 25.6217 24.8505 25.3999 25.13 25.12C25.6891 24.5563 26.002 23.794 26 23V9.00001C26.002 8.20606 25.6891 7.44372 25.13 6.88001C24.8505 6.60016 24.5184 6.37833 24.1529 6.2273C23.7873 6.07628 23.3955 5.99904 23 6.00001ZM24 23C24.0008 23.1316 23.9755 23.2621 23.9258 23.3839C23.876 23.5058 23.8027 23.6166 23.71 23.71C23.6166 23.8027 23.5057 23.876 23.3839 23.9258C23.2621 23.9756 23.1316 24.0008 23 24H9C8.73766 23.9989 8.48625 23.8948 8.29999 23.71C8.2055 23.6174 8.13032 23.507 8.07883 23.3851C8.02733 23.2632 8.00055 23.1323 8 23V9.00001C8.00055 8.8677 8.02733 8.73681 8.07883 8.61494C8.13032 8.49306 8.2055 8.3826 8.29999 8.28999C8.48625 8.10524 8.73766 8.00111 9 8.00001H23C23.1316 7.99925 23.2621 8.02446 23.3839 8.07423C23.5057 8.12399 23.6166 8.19731 23.71 8.28999C23.8027 8.38343 23.876 8.49426 23.9258 8.6161C23.9755 8.73794 24.0008 8.8684 24 9.00001V23Z"
        fill={props.fill}
      />
      <path
        d="M14 10H10V14H14V10Z"
        className="stroke"
        stroke="#3B3B3B"
        strokeMiterlimit="10"
      />
      <path
        d="M14 18H10V22H14V18Z"
        className="stroke"
        stroke="#3B3B3B"
        strokeMiterlimit="10"
      />
      <path
        d="M22 10H18V14H22V10Z"
        className="stroke"
        stroke="#3B3B3B"
        strokeMiterlimit="10"
      />
      <path d="M13 11H11V13H13V11Z" fill={props.fill} />
      <path d="M13 19H11V21H13V19Z" fill={props.fill} />
      <path d="M21 11H19V13H21V11Z" fill={props.fill} />
    </svg>
  );
}

export default QRCodeIcon;
