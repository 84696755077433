import {
  ActionType,
  Action,
  QuestionnairesResponse,
} from "models/questionnaires/questionnairesByType";
import createReducer from "../../createReducer";

export interface QuestionnairesReducerType {
  loading: boolean;
  error?: string;
  questionnairesResponse?: QuestionnairesResponse;
}

const defaultState: QuestionnairesReducerType = {
  loading: false,
  error: undefined,
  questionnairesResponse: undefined,
};

export const questionnairesReducer = createReducer<QuestionnairesReducerType>(
  defaultState,
  {
    [ActionType.GET_QUESTIONNAIRES](state: QuestionnairesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_QUESTIONNAIRES_ERROR](
      state: QuestionnairesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        questionnairesResponse: undefined,
      };
    },

    [ActionType.GET_QUESTIONNAIRES_SUCCESS](
      state: QuestionnairesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        questionnairesResponse: action.payload,
      };
    },
  }
);
