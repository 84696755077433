import {
  Action,
  ActionType,
  TodaySchedulessResponse,
} from "models/vaccine/schedules";
import createReducer from "../../createReducer";

export interface TodaySchedulesReducerType {
  loading: boolean;
  error?: string;
  todaySchedulesResponse?: TodaySchedulessResponse;
}

const defaultState: TodaySchedulesReducerType = {
  loading: false,
  error: undefined,
  todaySchedulesResponse: undefined,
};

export const todaySchedulesReducer = createReducer<TodaySchedulesReducerType>(
  defaultState,
  {
    [ActionType.TODAY_SCHEDULES](state: TodaySchedulesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.TODAY_SCHEDULES_ERROR](
      state: TodaySchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.TODAY_SCHEDULES_SUCCESS](
      state: TodaySchedulesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        todaySchedulesResponse: action.payload,
      };
    },
  }
);
