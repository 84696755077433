import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { updateGeneralSetting } from "services/setting";
import {
  ActionType,
  IUpdateGeneralResponse,
} from "models/setting/updateGeneral";

import { handleSuccesAction, handleErrorAction } from "./updateGeneralActions";

function* updateGeneralSettingSaga({ payload }: { payload: any }) {
  try {
    const response: IUpdateGeneralResponse = yield call(
      updateGeneralSetting,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateGeneralSettingWatcher() {
  yield takeLatest(
    ActionType.UPDATE_GENERAL_SETTING as any,
    updateGeneralSettingSaga
  );
}

export default [fork(onUpdateGeneralSettingWatcher)];
