import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Area,
  LabelList,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  healthCheckAnswersResponse,
  selectErrorMessage,
} from "redux/bodyTemperature/healthCheckAnswers/healthCheckAnswersStates";
import { searchConditionDataResponse } from "redux/messagemanagement/searchCondition/searchConditionStates";
import { GetHealthCheckAnswersActionCreators } from "redux/rootActions";
import { getHealthCheckSettingResponse } from "redux/setting/getHealthCheckSetting/getHealthCheckSettingStates";
import {
  localStorageHelper,
  handleConvertDay,
  handleGetTimeByTimeZone,
} from "utils";

import { DatePicker } from "./components";
import {
  WrapperTemperatureInfo,
  WrapperTemperatureItem,
  MediumDivider,
  DetailModalStyled,
  TitleDetailModal,
  WrapperDatePickerDetail,
  WrapperChart,
  WrapperFaceDetail,
} from "./BodyTemperature.style";
import { IModal } from "./SearchTerms";
import {
  FaceBadIcon,
  FaceGoodIcon,
  FaceNormalIcon,
  FaceVeryBadIcon,
  FaceVeryGoodIcon,
  FaceNotAttempIcon,
} from "icons";

interface IData {
  date: string;
  value: number;
  highFever: number;
  lowFever: number;
  normal: number;
}

const AreaValue = {
  highFever: 41.5,
  lowFever: 37.5,
  normal: 37,
};

const CustomizedLabel = (props: any) => {
  const { x, y, stroke, value } = props;
  return (
    <text
      x={x - 2}
      y={y - 5}
      dy={-4}
      fill={stroke}
      fontSize={10}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-30} y={0} dy={18} fill="#3B3B3B">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-17} y={5} fill="#3B3B3B">
        {payload.value}
      </text>
    </g>
  );
};

const TemperatureChart = ({ data }: { data: IData[] }) => {
  return (
    <div style={{ width: 670, height: "auto", position: "relative" }}>
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 20,
          }}
        >
          <Area dataKey="highFever" stroke="#fbe1e1" fill="#fbe1e1" />
          <Area dataKey="lowFever" stroke="#f9f3d5" fill="#f9f3d5" />
          <Area dataKey="normal" stroke="#dcf3e5" fill="#dcf3e5" />
          <CartesianGrid vertical={false} stroke="#F2F2F2" />
          <XAxis
            dataKey="date"
            tickLine={false}
            dy={10}
            tick={<CustomizedXAxisTick />}
            stroke="#CCCCCC"
          />
          <YAxis
            type="number"
            interval={0}
            domain={[34, 41]}
            ticks={[34, 35, 36, 37, 38, 39, 40, 41]}
            tickLine={false}
            width={30}
            tick={<CustomizedYAxisTick />}
            stroke="#CCCCCC"
          />

          <Line
            connectNulls
            isAnimationActive={false}
            dataKey="value"
            stroke="#2F8CAE"
            strokeDasharray="5 5"
            dot={{
              stroke: "white",
              strokeWidth: 1.5,
              r: 5.5,
              fill: "#2F8CAE",
              strokeDasharray: "",
            }}
          >
            <LabelList content={<CustomizedLabel />} />
          </Line>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const BodyTemperatureDetailModal: React.FC<IModal> = ({
  isVisible,
  handleCancel,
  patientInfo,
}) => {
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "layout",
  });

  const dispatch = useDispatch();
  // const dataSearchCondition = useSelector(searchConditionDataResponse);
  const healthCheckAnswers = useSelector(healthCheckAnswersResponse);
  const healthCheckAnswersError = useSelector(selectErrorMessage);
  const getHealthCheckSetting = useSelector(getHealthCheckSettingResponse);

  const [startDate, setStartDate] = useState<any>(new Date());
  const [chartData, setChartData] = useState<IData[]>([]);
  const [graphData, setGraphData] = useState({
    smallCheckData: [],
    days: [],
  });
  const [temperatureList, setTemperatureList] = useState([]);

  const questionNumberList = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

  const status: any = {
    very_good: <FaceVeryGoodIcon width={40} height={40} />,
    good: <FaceGoodIcon width={40} height={40} />,
    usual: <FaceNormalIcon width={40} height={40} />,
    bad: <FaceBadIcon width={40} height={40} />,
    very_bad: <FaceVeryBadIcon width={40} height={40} />,
    not_attemp: <FaceNotAttempIcon width={40} height={40} />,
  };

  const TitleCustom = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <WrapperDatePickerDetail>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              dispatch(
                GetHealthCheckAnswersActionCreators.getHealthCheckAnswersAction(
                  {
                    patient_id: patientInfo?.patient_id,
                    payload: {
                      created_at_to: moment(date).format("YYYY-MM-DD"),
                      app_language_id: currentLanguage,
                    },
                  }
                )
              );
            }}
            iconPosition="left"
          />
        </WrapperDatePickerDetail>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              marginLeft: 4,
              fontWeight: 400,
              fontSize: 14,
              minWidth: 42,
              marginRight: 2,
            }}
          >
            {t("for-up-to")}
          </span>
          <TitleDetailModal>
            <span className="patient-name">
              {patientInfo?.full_name}
              <span style={{ fontWeight: 400 }}>{t("of")}</span>
            </span>
            <span style={{ fontWeight: 400, fontSize: 14 }}>
              {t("contact-details")}
            </span>
          </TitleDetailModal>
        </div>
      </div>
    );
  };

  const isActionCheck = (questionNumber: number) => {
    return (
      !getHealthCheckSetting?.health_check_setting?.is_action_check &&
      questionNumberList.includes(questionNumber)
    );
  };

  const handleDate = (date: string) => {
    const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
    const format =
      currentLanguage == "ja" ? "YYYY年MM月DD日 HH:mm" : "YYYY-MM-DD HH:mm";

    return handleGetTimeByTimeZone(format, currentTimeZone, date);
  };

  const handleDateForMental = (date: string) => {
    const format = currentLanguage == "ja" ? "MM月DD日" : "MM-DD";
    return moment(date).format(format);
  };

  const returnFacescaleValue = (data: any[]) => {
    if (data[1]?.mental_type) {
      return data[1].mental_type;
    } else if (!data[1]?.mental_type && data[0]?.mental_type) {
      return data[0].mental_type;
    } else {
      return "not_attemp";
    }
  };

  useEffect(() => {
    if (healthCheckAnswers && healthCheckAnswers?.answers) {
      const day_array: any = [];
      // const facescale_array: any = [];
      // const checkFaceScales =
      //   healthCheckAnswers?.facescales &&
      //   Object(healthCheckAnswers.facescales) &&
      //   Object.keys(healthCheckAnswers.facescales).length > 0
      //     ? true
      //     : false;

      for (let i = 0; i <= 6; i++) {
        day_array.push(
          `${moment(startDate)
            .subtract(6 - i, "days")
            .format("YYYY-MM-DD")}`
        );
      }

      const convertedGraphData = [];
      for (let i = 0; i <= 6; i++) {
        convertedGraphData.push({
          date: handleConvertDay(day_array[i], currentLanguage),
          value: healthCheckAnswers?.temperature[i],
          ...AreaValue,
        });
      }
      setChartData(convertedGraphData);

      // if (checkFaceScales && day_array.length > 0) {
      //   for (let i = 0; i <= 6; i++) {
      //     if (
      //       healthCheckAnswers.facescales[day_array[i]] &&
      //       healthCheckAnswers.facescales[day_array[i]].length
      //     ) {
      //       facescale_array.push(
      //         returnFacescaleValue(healthCheckAnswers.facescales[day_array[i]])
      //       );
      //     } else {
      //       facescale_array.push("not_attemp");
      //     }
      //   }
      // }

      setGraphData({
        smallCheckData: healthCheckAnswers?.facescales,
        // smallCheckData: facescale_array,
        days: day_array,
      });
      setTemperatureList(healthCheckAnswers?.answers);
    }
  }, [healthCheckAnswers]);

  useEffect(() => {
    if (healthCheckAnswersError) {
      const day_array: any = [];
      const facescale_array: any = [];
      const convertedGraphData: any = [];

      for (let i = 0; i <= 6; i++) {
        day_array.push(
          `${moment(startDate)
            .subtract(6 - i, "days")
            .format("YYYY-MM-DD")}`
        );
        facescale_array.push("not_attemp");
        convertedGraphData.push({
          date: handleConvertDay(
            `${moment(startDate)
              .subtract(6 - i, "days")
              .format("YYYY-MM-DD")}`,
            currentLanguage
          ),
          ...AreaValue,
        });
      }

      setChartData(convertedGraphData);
      setGraphData({
        smallCheckData: facescale_array,
        days: day_array,
      });
    }
  }, [healthCheckAnswersError, startDate]);

  return (
    <DetailModalStyled
      title={<TitleCustom />}
      type="search"
      visible={isVisible}
      onCancel={handleCancel}
    >
      {getHealthCheckSetting?.health_check_setting?.is_temperature && (
        <WrapperChart>
          <TemperatureChart data={chartData} />
        </WrapperChart>
      )}
      {getHealthCheckSetting?.health_check_setting?.is_mental_check && (
        <div style={{ overflowX: "auto", width: "100%" }}>
          <WrapperFaceDetail style={{ borderBottom: "1px solid #CCCCCC" }}>
            {graphData.smallCheckData.map((item: any, key: number) => (
              <div key={key}>{status[item] || status["not_attemp"]}</div>
            ))}
          </WrapperFaceDetail>
          <WrapperFaceDetail style={{ margin: "5px 0px 16px" }}>
            {graphData.days.map((item: any, key: number) => (
              <p key={key}>{handleDateForMental(item)}</p>
            ))}
          </WrapperFaceDetail>
        </div>
      )}
      {temperatureList?.length > 0 &&
        temperatureList.map((item: any) => (
          <WrapperTemperatureInfo key={item.id}>
            <div style={{ color: "#1A2F62", fontWeight: 700 }}>
              {t("contact-information")} - {handleDate(item.created_at)}
            </div>
            <MediumDivider />
            {item.answers.map((option: any) => {
              if (isActionCheck(option.question_number)) return "";

              return (
                <WrapperTemperatureItem key={option.question_number}>
                  <div>{option.question_text}</div>
                  <p>
                    {Array.isArray(option?.value)
                      ? option?.value.join("、")
                      : option?.value}
                  </p>
                  {option.comment && <p>{option.comment}</p>}
                </WrapperTemperatureItem>
              );
            })}
          </WrapperTemperatureInfo>
        ))}
      {healthCheckAnswersError && (
        <div
          style={{
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "20px",
            margin: getHealthCheckSetting?.health_check_setting?.is_mental_check
              ? "16px 0px"
              : "0px 0px 16px",
          }}
        >
          {t("no-data-to-display")}
        </div>
      )}
    </DetailModalStyled>
  );
};

export default BodyTemperatureDetailModal;
