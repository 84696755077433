import * as React from "react";

function PieChartIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5034 12.4997L2.35742 10.1575C2.87933 7.85842 4.16874 5.80583 6.01311 4.33812C7.85748 2.87041 10.1467 2.0752 12.5034 2.08356V12.4997Z"
        fill="white"
        stroke="#3B3B3B"
        strokeMiterlimit="10"
      />
      <path
        d="M12.5028 12.4994L13.5912 22.8594C12.2313 23.0025 10.8564 22.8762 9.54523 22.4877C8.23405 22.0993 7.01219 21.4562 5.94946 20.5953C4.88673 19.7343 4.00395 18.6724 3.3515 17.4701C2.69905 16.2679 2.28971 14.9488 2.14689 13.5883C2.01578 12.4411 2.08684 11.2798 2.35682 10.1572L12.5028 12.4994Z"
        fill="#E0E0E0"
        stroke="#3B3B3B"
        strokeMiterlimit="10"
      />
      <path
        d="M12.5029 12.4995L22.4069 9.27979C23.2602 11.9073 23.0352 14.7664 21.7814 17.228C20.5277 19.6896 18.3478 21.5522 15.7214 22.406C15.0298 22.6333 14.3156 22.7849 13.5913 22.8581L12.5029 12.4995Z"
        fill="#B7B7B7"
        stroke="#3B3B3B"
        strokeMiterlimit="10"
      />
      <path
        d="M12.5029 12.4997V2.0835C17.1494 2.0835 20.9709 4.86043 22.4069 9.28131L12.5029 12.4997Z"
        fill="#3B3B3B"
        stroke="#3B3B3B"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default PieChartIcon;
