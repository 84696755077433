import {
  ActionType,
  ClassListResponse,
  ClassListData,
} from "../../models/classList";

export const classListAction = (payload: ClassListData) => {
  return {
    type: ActionType.CLASS_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CLASS_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ClassListResponse) => {
  return {
    type: ActionType.CLASS_LIST_SUCCESS,
    payload,
  };
};
