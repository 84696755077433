import styled from "styled-components";
import { Checkbox } from "antd";

import { Collapse, Table, Button, Select, Input } from "components";
import { theme } from "theme/theme";

export const CollapseStyled = styled(Collapse)`
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  .ant-collapse-header {
    width: 273px;
  }
  .ant-collapse-header svg {
    top: 40% !important;
    right: -32px !important;
  }
  ${theme.media.tablet} {
    .ant-collapse-header {
      width: fit-content;
    }
  }
  ${theme.media.laptopL} {
    margin-bottom: 32px;
  }
`;

export const BoxItem = styled.div`
  background: #fbfbfb;
  border-radius: 8px;
  border: 1px solid ${theme.colors.background};
  padding: 16px 16px 24px;
  position: relative;
  margin-bottom: 16px;
  min-height: 363px;
`;

export const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
  alignItems: "unset",
  outline: "none",
  fontSize: "16px",
  lineHeight: "16px",
  margin: "10px 0px 16px",
  "&:hover": {
    ".ant-checkbox-inner": {
      borderColor: theme.colors.border,
    },
  },
  ".ant-checkbox": {
    top: "unset",
  },
  ".ant-checkbox-inner": {
    width: 20,
    height: 20,
    top: 1,
  },
  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.colors.button,
    borderColor: `${theme.colors.button}!important`,
  },
  ".ant-checkbox-checked::after": {
    border: "none",
  },
  ".ant-checkbox-input:focus + .ant-checkbox-inner": {
    borderColor: theme.colors.border,
  },
  ".ant-checkbox + span": {
    paddingLeft: 5,
    alignSelf: "center",
  },
}));

export const BoxItem4Chart = styled(BoxItem)`
  padding: 16px 16px 20px;
`;

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BoxTitle = styled.div(({ theme }) => ({
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.bold,
  lineHeight: "24px",
  color: theme.colors.text.primary,
}));

export const BoxContent = styled.div`
  & > div {
    margin-bottom: 16px;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const LightDivider = styled.div(({ theme }) => ({
  height: 1,
  width: "100%",
  background: theme.colors.background,
  margin: "7px 0 6px",
}));

export const WrapperLegendLineChart = styled.div`
  display: flex;
  align-item: center;
  span {
    height: 5px;
    margin: -10px 4px 0;
  }
  p {
    font-size: ${theme.sizes.xs};
    line-height: 12px;
  }
`;

export const WrapperChartFever = styled.div`
  width: 100%;
  ${theme.media.desktop} {
    width: 74%;
  }
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 1px;
`;

export const GreenDot = styled(Dot)`
  background: ${theme.colors.button};
`;

export const BlueDot = styled(Dot)`
  background: #2a98c7;
`;

export const PurpleDot = styled(Dot)`
  background: #c72ab7;
`;

export const ButtonDownload = styled.a`
  color: #ffffff !important;
  background: #2ac769;
  font-size: 12px;
  font-weight: bold;
  padding: 6px 15px;
  border-radius: 6px;
  :hover {
    cursor: pointer;
  }
`;

export const WrapperCharts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  ${theme.media.tablet} {
    gap: 16px 13px;
  }
`;

export const BoxContent4Chart = styled.div``;

export const WrapperChartText = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
`;

export const ContainerChartText = styled.div`
  transform: rotateY(180deg);
  text-align: center;
  span {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.sm};
    line-height: 16px;
  }
  div:first-child {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.md};
    line-height: 23px;
  }
  div:last-child {
    font-weight: ${theme.fontWeight.regular};
    font-size: 32px;
    line-height: 37px;
  }
`;

export const WrapperChartInfor = styled.div`
  text-align: center;
  margin-top: 5px;
`;

export const ChartInforTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const ChartInforBottom = styled.div`
  text-align: center;
  margin-top: 15px;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.sm};
  line-height: 14px;
`;

export const VerticalLine = styled.div`
  height: 56px;
  width: 1px;
  background: ${theme.colors.border};
`;

export const ContainerChartInforTop = styled.div`
  div:first-child {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.xs};
    line-height: 17px;
    color: ${theme.colors.text.primary};
    margin-bottom: 5px;
  }
  div:last-child {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.xs};
    line-height: 17px;
  }
`;

export const WrapperSearchCondition = styled.div`
  ${theme.media.tablet} {
    display: flex;
    margin-bottom: 32px;
  }
  ${theme.media.laptopL} {
    > div {
      margin-right: 24px;
    }
  }
`;

export const WapperBtn = styled.div`
  display: flex;
  justify-content: end;
  padding: 24px 0 32px;
  button {
    padding: 10px 57px 12px;
  }
  ${theme.media.tablet} {
    padding-left: 16px;
    border-left: 1px solid ${theme.colors.textFieldBackground};
    align-items: center;
    button {
      padding: 13px 24px 10px;
      min-width: 82px;
    }
  }
`;
export const WapperInputTwo = styled.div`
  ${theme.media.mobileM} {
    padding: 0px 0px 0px 8px;
  }
  ${theme.media.tablet} {
    padding: 0px 8px;
  }
`;
export const WapperInput = styled.div`
  ${theme.media.mobileM} {
    padding: 0px 8px 0px 0px;
  }
  ${theme.media.tablet} {
    padding: 0px 8px;
  }
`;
export const GroupSelect = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 13px;
  ${theme.media.tablet} {
    gap: 16px 24px;
  }
`;

export const TableStyled = styled(Table)`
  .ant-table-thead > tr > th:first-child {
    text-align: left;
  }
  .ant-table-thead > tr > th:last-child > div {
    justify-content: flex-start;
    .ant-table-column-title {
      flex: initial;
      width: max-content;
      margin-right: 8px;
    }
  }
  .ant-table-thead > tr > th:nth-child(8) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-thead > tr > th:nth-child(7) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-content {
    overflow-x: auto;
  }
`;

export const WrapperActionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`;

export const TopTable = styled.div`
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  ${theme.media.laptopL} {
    margin-bottom: 16px;
  }
`;

export const WrapperSizeChange = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TableActionsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  ${theme.media.tablet} {
    margin-top: 16px;
  }
`;

export const WrapperPaginationTop = styled.div`
  text-align: center;
  margin: 24px 0;
  ${theme.media.tablet} {
    text-align: right;
    margin: 0;
  }
`;

export const WrapperPaginationBottom = styled.div`
  text-align: center;
  margin: 24px 0;
  ${theme.media.tablet} {
    text-align: right;
  }
  ${theme.media.laptopL} {
    margin: 16px 0 24px;
  }
`;

export const WrapperPieChart = styled.div`
  min-width: 100%;
  ${theme.media.tablet} {
    min-width: 49%;
  }
  ${theme.media.desktop} {
    min-width: 24%;
  }
`;

export const GroupRightButton = styled.div`
  > button {
    display: none;
  }
  > button:first-child {
    margin-right: 8px;
  }
  ${theme.media.tablet} {
    display: flex;
    button {
      display: block;
    }
    > div {
      display: none;
    }
  }
`;

export const WrapperGroupButton = styled.div`
  position: absolute;
  z-index: 100;
  width: max-content;
  right: 0;
  background: ${theme.colors.white};
  padding: 8px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border: 1px solid ${theme.colors.border};
  button:first-child {
    margin-bottom: 12px;
  }
  &::after {
    content: "";
    position: absolute;
    top: -5px;
    right: 7px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    right: 9px;
    width: 6px;
    height: 10px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  height: 23px;
`;

export const SelectStyled = styled(Select)`
  .ant-select-selector {
    width: 148px !important;
  }
  ${theme.media.tablet} {
    .ant-select-selector {
      width: 170px !important;
    }
  }
  ${theme.media.laptopL} {
    .ant-select-selector {
      width: 156px !important;
    }
  }
`;

export const WrapperChart = styled.div`
  overflow: scroll;
  width: 293px;
  ${theme.media.tablet} {
    width: 100%;
    overflow: unset;
  }
`;

export const ContainerChart = styled.div`
  height: auto;
  width: 770px;
  font-size: 12px;
  line-height: 12px;
  ${theme.media.tablet} {
    width: 100%;
  }
`;

export const WrapperSelectGroup = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const SelectFirst = styled(Select)`
  .ant-select-selector {
    width: 147px !important;
  }
  ${theme.media.tablet} {
    .ant-select-selector {
      width: 96px !important;
    }
  }
`;

export const SelectSecond = styled(Select)`
  .ant-select-selector {
    width: 147px !important;
  }
  ${theme.media.tablet} {
    .ant-select-selector {
      width: 96px !important;
    }
  }
`;

export const SelectThird = styled(Select)`
  .ant-select-selector {
    width: 147px !important;
  }
  .ant-select-selection-placeholder {
    padding-right: 8px !important;
  }
  ${theme.media.tablet} {
    .ant-select-selector {
      width: 156px !important;
    }
  }
  ${theme.media.desktop} {
    .ant-select-selector {
      width: 72px !important;
    }
  }
`;

export const WrapperDatePicker = styled.div`
  width: 147px;
  > div {
    margin: 0;
  }
  ${theme.media.tablet} {
    width: 130px !important;
  }
`;

export const WapperBtnItem = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  justify-content: flex-end;
  margin-bottom: 8px;
  flex-direction: row;
  .div-btn {
    width: 100%;
    text-align: end;
    display: flex;
    flex-direction: row;
    gap: 16px;
    button {
      width: 50%;
      margin: 0;
    }
    ${theme.media.tablet} {
      width: max-content;
      button {
        width: unset;
      }
    }
    ${theme.media.desktop} {
      flex-direction: column;
    }
    .single-btn {
      margin: 0px auto;
      width: 112px;
      ${theme.media.tablet} {
        width: unset;
        margin-top: 8px;
        margin-right: 8px;
      }
      ${theme.media.desktop} {
        margin-top: unset;
        margin-right: unset;
      }
    }
    .btn-order-left {
      order: 2;
      ${theme.media.desktop} {
        order: 1;
      }
    }
    .btn-order-right {
      order: 1;
      ${theme.media.desktop} {
        order: 2;
      }
    }
  }
  ${theme.media.desktop} {
    flex-direction: row;
    margin-bottom: unset;
    margin-right: 8px;
  }
`;

export const WapperBlockItem = styled.div`
  margin-top: 15px;
`;
export const DivBlockItem = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  :last-child {
    margin-bottom: 8px;
  }
  .item-left {
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    background: #f2f2f2;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 110px;
    width: 100%;
    input {
      color: #3b3b3b !important;
      text-align: end;
    }
    ${theme.media.tablet} {
      min-height: 136px;
      max-width: 206px;
    }
    ${theme.media.desktop} {
      padding: 22px 24px 22px;
      height: auto;
    }
  }
  ${theme.media.tablet} {
    gap: 16px;
    flex-direction: row;
  }
  .item-left-block {
    min-height: 110px;
    ${theme.media.tablet} {
      min-height: 98px;
      min-height: unset;
      height: auto;
    }
  }
  .wrapper-block {
    ${theme.media.tablet} {
      margin-right: 20px;
    }
    ${theme.media.desktop} {
      margin-right: unset;
    }
  }
`;

export const WapperFilter = styled.div`
  background: #f2f2f2;
  margin-top: 24px;
  border-radius: 8px;
  padding: 8px;
  .btn-previous,
  .btn-next {
    position: absolute;
    top: 5px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
  .btn-previous {
    left: 5px;
  }
  .btn-next {
    left: auto;
    right: 5px;
  }
  .btn-previous span {
    top: -10px;
    left: -1px;
  }
  .btn-next span {
    top: -10px;
    right: -1px;
    left: auto;
  }
  .react-datepicker__navigation-icon::before {
    top: 7px;
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    border-color: #2f8cae;
  }
  .react-datepicker__navigation-icon::before {
    top: 7px;
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    border-color: #2f8cae;
  }
  .pTittle {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #2f8cae;
  }
`;

export const WrapCheckboxFilter = styled.div`
  .ant-checkbox + span {
    line-height: 16px !important;
    font-size: 16px !important;
    padding-right: 0;
  }

  ${theme.media.desktop} {
    margin-top: 2px;
    margin-bottom: 39px;
    .ant-checkbox-wrapper {
      margin: 0px;
    }
  }
`;

export const WapperCheckboxItem = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .ant-checkbox-group {
    > div > div {
      height: max-content;
      .ant-checkbox-wrapper {
        align-items: center;
        span {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
  ${theme.media.mobileM} {
    margin-top: 16px;
  }
  ${theme.media.tablet} {
    margin-top: auto;
    margin-right: 8px;
    gap: 24px;
  }
  ${theme.media.desktop} {
    margin-right: unset;
    flex-direction: row;
    gap: 32px;
  }
`;

export const GroupBtnFilter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 8px;
  button {
    width: 139px;
  }
  ${theme.media.tablet} {
    justify-content: end;
    margin-bottom: unset;
    button {
      width: 96px;
    }
    button:first-child {
      margin-right: 16px;
    }
  }
  ${theme.media.desktop} {
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0px;
  }
`;
export const DivInput = styled.div`
  width: 100%;
  margin: 0px 0px 24px 0px;
  font-weight: bold;
  font-size: 18px;
  ${theme.media.mobileM} {
    input {
      display: none;
    }
    textarea {
      display: block;
      height: 82px;
      padding: 8px 12px;
      line-height: 22px;
    }
  }
  ${theme.media.tablet} {
    margin: 0px 0px 16px 0px;
    input {
      height: 32px;
      display: block;
      width: 100%;
    }
    textarea {
      display: none;
    }
  }
  ${theme.media.desktop} {
    input {
      width: 100%;
      max-width: 738px;
    }
    margin: 0px 0px 16px 0px;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .content-location {
    margin-bottom: 24px;
  }
  > p {
    margin-bottom: 32px;
  }
  ${theme.media.tablet} {
    .content-location {
      margin-bottom: 16px;
    }
    > p {
      margin-bottom: 6px;
    }
  }
  ${theme.media.desktop} {
    margin-right: unset;
  }
`;

export const DivContent = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  ${theme.media.mobileM} {
    margin: 24px 0px;
  }
  ${theme.media.tablet} {
    margin: 4px 0px 16px;
  }
`;

export const WapperSelectTimeForItem = styled.div`
  ${theme.media.mobileM} {
    padding-left: 0px;
    .div-right-item {
      display: flex;
      margin: 24px 0px 32px 0px;
    }
    .dleft,
    .dright {
      width: 100%;
    }
    .dright {
      margin-top: 10px;
      overflow: hidden;
    }
  }
  ${theme.media.tablet} {
    .bor-div-time {
      display: flex;
    }
    .dleft,
    .dright {
      display: flex;
      width: auto;
    }
    .dright {
      margin: auto;
      margin-top: 0px;
    }
    .div-right-item {
      padding-top: 16px;
      margin: 0;
    }
  }
  ${theme.media.laptop} {
    .ant-select-selection-item {
      padding-right: 18px;
    }
    .div-right-item {
      padding-top: 5px;
      margin: 0px 0px 0px 16px;
    }
    display: flex;
  }
  ${theme.media.desktop} {
    .div-right-item {
      padding-top: 0px;
      margin: 0px 0px 0px 16px;
    }
  }
`;

export const WrapperAction = styled.div`
  background: ${theme.colors.white};
  border-radius: 8px;
  padding: 8px;
  margin-top: 10px;
  ${theme.media.tablet} {
    padding: 16px;
  }
  ${theme.media.desktop} {
    display: flex;
    column-gap: 32px;
  }
`;

export const FirstBlock = styled.div`
  margin-bottom: 24px;
  ${theme.media.tablet} {
    display: flex;
    column-gap: 32px;
    margin-bottom: 20px;
    > div:first-child {
      min-width: 340px;
      > label {
        margin: 2px 0px;
      }
    }
    > div:last-child {
      width: 100%;
    }
  }
  ${theme.media.desktop} {
    flex-direction: column;
    width: 39%;
    margin-bottom: 0;
  }
`;

export const SecondBlock = styled.div`
  ${theme.media.tablet} {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }
  ${theme.media.laptopL} {
    column-gap: 50px;
  }
  ${theme.media.desktop} {
    flex-direction: column;
    row-gap: 13px;
    align-items: start;
  }
`;

export const FixSelect = styled.div`
  > div {
    margin-top: 20px;
  }
`;

export const FirstInputStyled = styled(Input)`
  width: 165px;
  ${theme.media.laptopL} {
    width: 138px;
  }
`;

export const SecondBlockTop = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-bottom: 24px;
  ${theme.media.tablet} {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 0px;
  }
`;

export const SecondBlockBottom = styled.div`
  position: relative;
  > div:first-child {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
  }
  ${theme.media.tablet} {
    display: flex;
    column-gap: 8px;
    > div:first-child {
      margin-bottom: 0px;
    }
  }
  .start-time-error {
    position: absolute;
    bottom: -42px;
    left: 0;
    display: block;
    background: #ffffff;
    color: #fb2121;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 9px 8px;
    z-index: 100;
    border-radius: 8px;
    border: 1px solid #fb2121;
    transform-origin: 50% 89.9943px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  }
  .start-time-error::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 18px;
    height: 7px;
    width: 7px;
    background: #fb2121;
    transform: rotate(45deg);
  }
  .start-time-error::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 17px;
    width: 6px;
    height: 10px;
    background: #ffffff;
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const WapperDatePicker = styled.div`
  ${theme.media.mobileM} {
    .div-date-picker-mobile {
      display: block;
    }
    .div-date-picker-desktop {
      display: none;
    }
  }
  ${theme.media.laptop} {
    .div-date-picker-mobile {
      display: none;
    }
    .div-date-picker-desktop {
      display: block;
    }
  }
  ${theme.media.desktop} {
    .div-date-picker-desktop {
      display: block;
      > div {
        height: max-content;
        border-radius: 8px;
        > div {
          height: max-content;
        }
      }
    }
  }
`;

export const WapperFilterReservation = styled.div`
  width: 100%;
  text-align: right;
  margin: 8px 0 16px;
  button {
    padding: 8px 41px 8px;
  }
  ${theme.media.tablet} {
    width: unset;
    margin: 28px 0 24px;
    button {
      padding: 8px 24px 8px;
    }
  }
`;

export const DivSelect = styled.div`
  display: flex;
  ${theme.media.mobileM} {
    justify-content: end;
  }
  ${theme.media.tablet} {
    justify-content: left;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  justify-content: initial;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 8px;
  button {
    margin-top: 8px;
  }
  > div {
    width: 100%;
  }
  ${theme.media.tablet} {
    padding: 24px 18px 24px 24px;
    justify-content: space-between;
    gap: 0px;
    > div {
      width: 38.5%;
    }
    button {
      margin-top: 0px;
      margin-left: 8px;
    }
  }
  ${theme.media.laptopL} {
    justify-content: flex-start;
    gap: 24px;
    > div {
      width: unset;
    }
    input {
      width: 320px;
    }
  }
`;

export const WrapperContentSetting = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 24px;
  ${theme.media.desktop} {
    padding-bottom: 8px;
  }
`;

export const WrapperItem = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 16px 16px 8px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  svg {
    position: absolute;
    cursor: pointer;
    right: -12px;
    top: -12px;
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    :nth-child(even) {
      margin-left: 8px;
    }
  }
  ${theme.media.desktop} {
    width: calc(50% - 17px);
  }
`;

export const WrapperItemBottom = styled(WrapperItem)`
  padding: 16px 8px;
  ${theme.media.tablet} {
    padding: 16px;
  }
  > form {
    display: flex;
    flex-direction: column;
    ${theme.media.tablet} {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const HorizontalInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: initial;
  gap: 8px;
  width: 100%;
  > div {
    width: 100%;
  }
  ${theme.media.tablet} {
    width: 386px;
    > div {
      display: flex;
      align-items: center;
    }
    > div:last-child {
      padding-left: 32px;
    }
  }
  ${theme.media.desktop} {
    > div {
      width: 100%;
      display: flex;
    }
    button {
      display: none;
    }
    input {
      width: 290px;
    }
  }
`;

export const ItemLeft = styled.div`
  width: 100%;
  display: flex;
`;

export const QrWrapper = styled.div`
  width: 88px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-right: 16px;
  ${theme.media.tablet} {
    button {
      position: absolute;
      right: 16px;
      top: 37%;
    }
  }
`;

export const InfoWrapper = styled.div`
  flex: auto;
  margin-top: 4px;
  max-width: 183px;
  p {
    color: #666666;
    margin: 0px;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      font-weight: bold;
    }
  }
  button {
    margin-top: 4px;
    > span > svg {
      left: 18px;
    }
  }
  ${theme.media.tablet} {
    max-width: unset;
    div {
      width: fit-content;
    }
  }
  ${theme.media.desktop} {
    button {
      margin-top: 0px;
      > span > svg {
        left: 4px;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  button {
    height: 32px;
  }
  ${theme.media.tablet} {
    flex-direction: column;
    margin-top: 0;
  }
`;

export const PaginationWrapper = styled.div`
  margin-bottom: 16px;
  text-align: center;
  ${theme.media.tablet} {
    display: flex;
    justify-content: end;
    text-align: unset;
  }
`;
