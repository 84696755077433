import { IResponse } from "models";

export enum ActionType {
  // message history listing
  MESSAGE_HISTORY_LIST = "action/MESSAGE_HISTORY_LIST",
  MESSAGE_HISTORY_LIST_SUCCESS = "action/MESSAGE_HISTORY_LIST_SUCCESS",
  MESSAGE_HISTORY_LIST_ERROR = "action/MESSAGE_HISTORY_LIST_ERROR",
  RESET_MESSAGE_HISTORY_LIST = "action/RESET_MESSAGE_HISTORY_LIST",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IMessageHistoryData {
  from_date?: string;
  to_date?: string;
  locale?: string;
  page?: number;
  limit?: number;
}

export interface IMessageHistoryResponse extends IResponse {
  result: {
    total: number;
    message_histories: IMessageHistory[];
  };
}

export interface IMessageHistory {
  id: number;
  date: string;
  subject: string;
  is_doc_available: boolean;
  start_time: string;
  created_at: string;
  status: number;
}
