import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IQuestionnaireResponseDetailResponse,
  IQuestionnaireResponseDetailPayload,
} from "models/questionnaires/questionnaireResponseDetail";
import { httpStatus } from "configs/httpStatus";
import { fetchQuestionnaireResponseDetail } from "services/questionnaires";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./questionResponseDetailActions";

function* getQuestionnariesResponseDetailSaga({
  payload,
}: {
  payload: IQuestionnaireResponseDetailPayload;
}) {
  try {
    const response: IQuestionnaireResponseDetailResponse = yield call(
      fetchQuestionnaireResponseDetail,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetQuestionnariesResponseDetailSagaSubmitWatcher() {
  yield takeLatest(
    ActionType.GET_QUESTIONNAIRE_RESPONSE_DETAIL as any,
    getQuestionnariesResponseDetailSaga
  );
}

export default [fork(onGetQuestionnariesResponseDetailSagaSubmitWatcher)];
