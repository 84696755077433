import moment from "moment";
import { localStorageHelper } from "./localstorage";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const convertDate = (date: Date) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  return yyyy + "/" + mm + "/" + dd;
};

export const handleConvertDay = (date: string, currentLang: string) => {
  const dateArr = date.split("-");
  if (currentLang == "ja") {
    return `${dateArr[1]}月${dateArr[2]}日`;
  }
  return dateArr[2] + ` ` + monthNames[Number(dateArr[1]) - 1];
};

export const handleConvertDate = (date: string) => {
  const dateArr = date.split("-");
  return `${dateArr[1]}/${dateArr[2]}`;
};

export const handleGetTimeByTimeZone = (
  format: string,
  hours: number,
  date?: any
) => {
  return moment(date || new Date())
    .utc()
    .add(hours || 9, "hours")
    .format(format);
};

export const handleConvertDateYear = (date: string, currentLang: string) => {
  const dateArr = date.split("-");
  if (currentLang == "ja") {
    return `${dateArr[0]}年${dateArr[1]}月${dateArr[2]}日`;
  }
  return (
    dateArr[2] + ` ` + monthNames[Number(dateArr[1]) - 1] + ` ` + dateArr[0]
  );
};

export const handleConvertMonthYear = (date: string, currentLang: string) => {
  const dateArr = date.split("-");
  if (currentLang == "ja") {
    return `${dateArr[0]}年${dateArr[1]}月`;
  }
  return dateArr[2] + ` ` + monthNames[Number(dateArr[1]) - 1];
};

export const handleCondition = (
  con?: string,
  startTime?: string,
  endTime?: string
) => {
  const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
  const measurement = "hours";
  const formatDateTime = "YYYY/MM/DD HH:mm";
  const formatDateTime2 = "YYYY/MM/DD";

  const now = handleGetTimeByTimeZone(
    formatDateTime,
    currentTimeZone,
    new Date()
  );
  const start = handleGetTimeByTimeZone(
    formatDateTime,
    currentTimeZone,
    startTime
  );
  const end = handleGetTimeByTimeZone(formatDateTime, currentTimeZone, endTime);

  //now < endDate, startDate
  if (con === "th1") {
    const nowDate = handleGetTimeByTimeZone(
      formatDateTime2,
      currentTimeZone,
      new Date()
    );
    const startDate = handleGetTimeByTimeZone(
      formatDateTime2,
      currentTimeZone,
      startTime
    );
    const endDate = handleGetTimeByTimeZone(
      formatDateTime2,
      currentTimeZone,
      endTime
    );

    if (startDate < nowDate) return false;

    if (nowDate < startDate && nowDate < endDate) {
      return true;
    }

    const startHour = moment(start).hour();
    const nowHour = moment(now).hour();

    if (nowHour < startHour) {
      return true;
    }

    return false;
  }

  //now < endDate, now > startDate
  if (con === "th2") {
    return (
      (moment(now).diff(start, measurement) >= 0 &&
        moment(now).diff(end, measurement) < 0) ||
      (moment(now).diff(start, measurement) >= 0 && !endTime)
    );
  }

  // now > endDate, startDate
  return moment(now).diff(end, measurement) >= 0;
};
