import * as React from "react";

function CheckboxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="20" height="20" rx="2" fill={props.fill} />
      <path
        d="M18.7099 7.2101C18.617 7.11638 18.5064 7.04198 18.3845 6.99121C18.2627 6.94044 18.132 6.91431 17.9999 6.91431C17.8679 6.91431 17.7372 6.94044 17.6154 6.99121C17.4935 7.04198 17.3829 7.11638 17.29 7.2101L9.83995 14.6701L6.70995 11.5301C6.61343 11.4369 6.49949 11.3636 6.37463 11.3143C6.24978 11.2651 6.11645 11.241 5.98227 11.2433C5.84809 11.2457 5.71568 11.2744 5.5926 11.3279C5.46953 11.3814 5.35819 11.4586 5.26495 11.5551C5.17171 11.6516 5.0984 11.7656 5.04919 11.8904C4.99999 12.0153 4.97586 12.1486 4.97818 12.2828C4.9805 12.417 5.00923 12.5494 5.06272 12.6725C5.11622 12.7955 5.19343 12.9069 5.28995 13.0001L9.12995 16.8401C9.22291 16.9338 9.33351 17.0082 9.45537 17.059C9.57723 17.1098 9.70794 17.1359 9.83995 17.1359C9.97196 17.1359 10.1027 17.1098 10.2245 17.059C10.3464 17.0082 10.457 16.9338 10.55 16.8401L18.7099 8.68011C18.8115 8.58646 18.8925 8.47281 18.9479 8.34631C19.0033 8.21981 19.0319 8.08321 19.0319 7.94511C19.0319 7.807 19.0033 7.6704 18.9479 7.5439C18.8925 7.4174 18.8115 7.30375 18.7099 7.2101V7.2101Z"
        fill="white"
      />
    </svg>
  );
}

export default CheckboxIcon;
