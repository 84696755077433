import { createApiCall2, MethodType, URI } from "../Api";
import { UserListData } from "models/userlist";
import { CreateUserData } from "models/createuserlist";
import { UpdateUserData } from "models/updateuser";
import { DeleteUserData } from "models/deleteuser";
import { UserAttendanceData } from "models/userAttendance";
import { IUserListDownloadData } from "models/csvdownloaduser";

export const fetchUserList = (payload: UserListData) => {
  return createApiCall2(MethodType.GET, URI.USERS.GETUSERLIST, payload);
};

export const createNewUser = (payload: CreateUserData) => {
  return createApiCall2(MethodType.POST, URI.USERS.CREATE_USER, payload);
};

export const updateUser = (payload: UpdateUserData) => {
  return createApiCall2(MethodType.PUT, URI.USERS.CREATE_USER, payload);
};

export const deleteUser = (payload: DeleteUserData) => {
  return createApiCall2(MethodType.DELETE, URI.USERS.DELETE_USER, payload);
};

export const downloadCSVUser = (payload: IUserListDownloadData) => {
  return createApiCall2(MethodType.GET, URI.USERS.CSV_DOWNLOAD_USER, payload);
};

export const attendanceUser = (payload: UserAttendanceData) => {
  return createApiCall2(MethodType.GET, URI.USERS.USER_ATTENDANCE, payload);
};

export const updatePositionOfColumn = (payload: any) => {
  return createApiCall2(
    MethodType.POST,
    URI.USERS.UPDATE_POSITION_COLUMNS,
    payload
  );
};
