import * as React from "react";

function WindowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.3125 0.125H1.6875C0.755859 0.125 0 0.880859 0 1.8125V14.1875C0 15.1191 0.755859 15.875 1.6875 15.875H16.3125C17.2441 15.875 18 15.1191 18 14.1875V1.8125C18 0.880859 17.2441 0.125 16.3125 0.125ZM16.3125 13.9766C16.3125 14.0926 16.2176 14.1875 16.1016 14.1875H1.89844C1.78242 14.1875 1.6875 14.0926 1.6875 13.9766V5.75H16.3125V13.9766Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default WindowIcon;
