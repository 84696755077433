import styled from "styled-components";

import { theme } from "theme/theme";
import { Table, Modal } from "components";
import { DeleteIcon } from "icons";
import { Checkbox } from "antd";

export const WrapperSection = styled.div`
  position: relative;
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
  padding: 39px 15px 15px;
  width: 100%;
  height: max-content;
  ${theme.media.tablet} {
    padding: 39px 23px 15px;
    width: 50%;
  }
  ${theme.media.desktop} {
    width: max-content;
  }
`;

export const WrapperTemperatureSection = styled.div`
  position: relative;
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
  padding: 39px 15px 15px;
  ${theme.media.tablet} {
    padding: 39px 23px 23px;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -16px;
  left: 9px;
  background: ${theme.colors.white};
  padding: 0 8px;
`;

export const WrapperGeneralForm = styled.div`
  flex-direction: column;
  margin-bottom: 32px;
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
  }
  ${theme.media.desktop} {
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: unset;
  }
`;

export const Label = styled.div`
  color: ${theme.colors.text.primary};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.lg};
  line-height: 18px;
  margin-bottom: 25px;
`;

export const FormTitle = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.lg};
  line-height: 26px;
  color: ${theme.colors.titleText};
`;

export const NotificationSettingContainer = styled.div``;

export const ItemAdditionalSelectionContainer = styled.div`
  margin-bottom: 34px;
  ${theme.media.tablet} {
    margin-bottom: 30px;
  }
`;

export const EmailSettingContainer = styled.div`
  margin-bottom: 25px;
`;

export const WrapperAddBtn = styled.div`
  margin-bottom: 16px;
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    button {
      height: 26px;
    }
    div {
      margin-bottom: 0px;
    }
  }
`;

export const FlexContainer = styled.div`
  margin-bottom: 32px;
  ${theme.media.tablet} {
    margin-bottom: 29px;
    display: flex;
  }
`;

export const LanguageContainer = styled.div`
  margin-bottom: 23px;
  border-top: 1px dashed ${theme.colors.border};
  padding-top: 24px;
  .ant-select {
    width: 100%;
    height: 31px;
  }
  .ant-select-selector {
    width: 100%;
  }
  ${theme.media.desktop} {
    border-top: unset;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-right: 23px;
    .ant-select {
      width: 123px;
    }
    .ant-select-selector {
      width: 123px;
    }
  }
`;

export const PasswordContainer4Setting = styled.div`
  input {
    width: 100%;
  }
  ${theme.media.laptop} {
    input {
      width: 280px;
    }
  }
`;

export const PasswordContainer = styled.div`
  input {
    width: 100%;
  }
  ${theme.media.tablet} {
    margin-bottom: 61px;
  }
  ${theme.media.laptop} {
    margin-bottom: unset;
    input {
      width: 280px;
    }
  }
`;

export const TimeSettingContainer = styled.div`
  border-top: 1px dashed ${theme.colors.border};
  padding-top: 24px;
  .ant-select {
    width: 100%;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
  }
  .ant-select-selector {
    width: 100%;
    height: 31px;
    font-weight: ${theme.fontWeight.bold};
    line-height: 12px;
    font-size: ${theme.sizes.xs};
    color: ${theme.colors.text.primary};
  }
  ${theme.media.tablet} {
    position: relative;
  }
  ${theme.media.desktop} {
    border-top: unset;
    padding-top: 0px;
    border-left: 1px dashed ${theme.colors.border};
    padding-left: 24px;
    .ant-select {
      width: 280px;
    }
    .ant-select-selector {
      width: 280px;
    }
  }
`;

export const LabelItem = styled.div`
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  margin-bottom: 4px;
  color: ${theme.colors.text.secondary};
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 39px;
  ${theme.media.tablet} {
    flex-wrap: unset;
    gap: 16px;
  }
  ${theme.media.desktop} {
    gap: 24px;
  }
`;

export const TimezoneWrap = styled.div`
  margin-bottom: 24px;
  ${theme.media.desktop} {
    margin-bottom: 76px;
  }
`;

export const CurrentLocationWrap = styled.div``;

export const CurrentLocationForm = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.sizes.xs};
  line-height: 12px;
  color: #666666;
  border: 2px solid ${theme.colors.background};
  padding: 8px 8px;
  ${theme.media.laptopL} {
    width: 279px;
  }
`;

export const MessageContainer = styled.div`
  background: #f2f2f2;
  border-radius: 8px;
  margin-bottom: 32px;
  padding: 12px 8px 16px;
  ${theme.media.tablet} {
    padding: 16px 16px 20px;
  }
  ${theme.media.laptopL} {
    padding: 16p;
    margin-bottom: 40px;
  }
`;
export const MessageTitle = styled.h3`
  font-size: ${theme.sizes.md};
  font-weight: 700;
  line-height: 18px;
`;

export const MessageList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const MessageListItem = styled.div`
  list-style-type: none;
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.medium};
  line-height: 21px;
  min-width: fit-content;
`;

export const ItemAdditionalSelection = styled.div`
  padding: 0;
  padding-right: 16px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
  ${theme.media.laptop} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  align-items: center;
  outline: none;
  margin-left: 0px !important;
  margin-bottom: 24px;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.colors.border};
    }
  }
  .ant-checkbox {
    top: unset;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 21px;
    border: 2px solid ${theme.colors.border};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button}!important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.border};
  }
  div {
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
  ${theme.media.tablet} {
    margin-bottom: 0px;
  }
  ${theme.media.laptop} {
    margin-bottom: 0px;
  }
`;

export const LastItemCheckboxStyled = styled(CheckboxStyled)`
  margin-bottom: 0;
`;

export const LastCheckboxStyled = styled(Checkbox)`
  align-items: center;
  outline: none;
  margin-left: 0px !important;
  margin-bottom: 0px;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.colors.border};
    }
  }
  .ant-checkbox {
    top: unset;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 21px;
    border: 2px solid ${theme.colors.border};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button}!important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.border};
  }
  .ant-checkbox + span {
    padding-right: 0;
  }
  div {
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
`;

export const CheckItem = styled.li`
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.sizes.lg};
  line-height: 18px;
  list-style: none;
`;

export const CheckItem2 = styled(CheckItem)`
  ${theme.media.tablet} {
    margin-right: 16px;
  }
`;

export const ReminderSelect = styled.p`
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.sizes.lg};
  list-style: none;
  margin-right: 12px;
  margin-left: 24px;
  ${theme.media.laptop} {
    border-left: 1px dashed #cccccc;
    margin-left: 0px;
    padding-left: 16px;
  }
`;

export const HourWrap = styled.div`
  margin: 0 8px;
`;

export const ReminderFlex = styled.div`
  display: flex;
  .ant-select-selector {
    padding: 0 8px !important;
  }
`;

export const TableContainer = styled.table`
  width: 100%;
`;

export const TimeSelect = styled.div`
  display: flex;
`;

export const TableStyled = styled(Table)`
  margin-bottom: 16px;
  .ant-table-thead > tr > th {
    color: ${theme.colors.titleText};
    padding: 24px 16px;
    line-height: 23px;
  }
  .ant-table-thead > tr > th:nth-child(1) {
    padding-left: 16px !important;
  }
  .ant-table-tbody > tr > td:first-child {
    border: none;
    padding-left: 16px;
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: right;
  }
  .ant-table-thead > tr > th:first-child {
    background: #fbfbfb;
    &:before {
      height: 50% !important;
      background: ${theme.colors.border};
    }
  }
  .ant-table-thead > tr > th:nth-child(2) {
    background: #fbfbfb;
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 9px 16px;
  }
  ${theme.media.laptopL} {
    .ant-table-thead > tr > th {
      padding: 24px 32px;
    }
    .ant-table-thead > tr > th:nth-child(1) {
      padding-left: 32px !important;
    }
    .ant-table-tbody > tr > td:first-child {
      padding-left: 32px;
    }
    .ant-table-tbody > tr > td {
      padding: 9px 32px;
      border: none;
    }
  }
`;

export const ConfirmModalStyled = styled(Modal)`
  width: calc(100vw - 4px) !important;
  .ant-modal-body {
    padding: 32px 25px;
  }
  ${theme.media.tablet} {
    width: 532px !important;
  }
  ${theme.media.laptopL} {
    .ant-modal-body {
      padding: 32px;
    }
  }
`;

export const TextConfirm = styled.div`
  font-size: 22px;
  font-weight: ${theme.fontWeight.bold};
  line-height: 40px;
  color: ${theme.colors.text.primary};
`;

export const WrapperBtnConfirmModal = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  button {
    width: 151px;
    padding: 7px 0;
  }
  button:first-child {
    margin-right: 16px;
  }
  ${theme.media.tablet} {
    display: block;
    justify-content: unset;
    button {
      width: 128px;
      padding: 7px 0;
    }
    button:first-child {
      margin-right: 40px;
    }
  }
  ${theme.media.laptopL} {
  }
`;

export const NoData = styled.div`
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.sizes.lg};
  line-height: 18px;
  color: ${theme.colors.text.primary};
  margin-bottom: 24px;
  padding-left: 32px;
`;

export const TooltipRightStyled = styled.div`
  margin: -6px;
  width: 274px;
  height: auto;
  max-height: 392px;
  overflow-x: hidden;
  overflow-y: auto;
  background: ${theme.colors.white};
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.regular};
  line-height: 16px;
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 8px 0px 8px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

export const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperCheckbox2 = styled(WrapperCheckbox)``;

export const WrapperInforIcon = styled.div`
  position: relative;
  cursor: pointer;
  height: 30px;
  width: 30px;
  padding-top: 3px;
  &:hover {
    .wrapper-tooltip {
      display: block;
    }
  }
`;

export const WrapperMessageSection = styled(WrapperTemperatureSection)`
  padding: 29px 16px 24px 24px;
  margin-top: 40px;
  width: fit-content;
`;

export const ScreenContainer = styled.div`
  margin-bottom: 23px;
  .ant-select {
    width: 100%;
    height: 31px;
  }
  .ant-select-selector {
    width: 100%;
  }
  ${theme.media.desktop} {
    margin-bottom: 0px;
    padding-right: 23px;
    margin-right: 16px;
    border-right: 1px dashed #cccccc;
    .ant-select {
      width: 269px;
    }
    .ant-select-selector {
      width: 269px;
    }
  }
`;

export const WrapperDeseaseOutbreakSection = styled(WrapperTemperatureSection)`
  padding: 29px 24px 24px;
  margin-top: 40px;
  width: fit-content;
  max-width: 564px;
`;

export const WrapperConsultRequestSection = styled(WrapperTemperatureSection)`
  padding: 35px 23px 23px;
  width: 100%;
  max-width: 100%;
`;

export const RequestFormLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .label {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.lg};
    line-height: 26px;
    color: ${theme.colors.titleText};
  }
`;

export const RequestCheckItem = styled.li`
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.sizes.sm};
  line-height: 20px;
  list-style: none;
  color: #3b3b3b;
  margin-top: 2px;
`;

export const RequestSelectGroup = styled.div`
  color: #3b3b3b;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  padding-left: 28px;
  margin-top: 19px;

  .mask {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0.76;
    z-index: 1;
  }
  .left > div:nth-child(2) {
    line-height: 13px;
  }
  .left,
  .right {
    display: flex;
    align-items: center;
    gap: 8px;
    .ant-select-selector {
      padding: 0px 8px !important;
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        padding-right: 8px !important;
      }
    }
    .ant-select-disabled > .ant-select-selector {
      background: #ffffff !important;
      color: #3b3b3b;
    }
  }

  .right {
    margin-right: 24px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const RequestEmailInputGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
  > div {
    margin-bottom: 0px;
  }
  > div:last-child {
    flex: 1;
  }
`;

export const RequestEmailLabelGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
  .left {
    width: 280px;
  }
  .right {
    flex: 1;
    display: flex;
    align-items: flex-end;
    gap: 4px;
    span {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const EmailInputLabel = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 4px;
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
  span {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  > div:first-child {
    flex: 1;
  }
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  transform: rotate(0deg);
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const WrapperDisease = styled(ItemAdditionalSelection)`
  opacity: 0.4;
  margin-bottom: 24px;
  pointer-events: none;
`;

export const DiseaseContainer = styled.div`
  margin-bottom: 24px;
`;

export const OperatingTimeContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

export const Container = styled.div`
  margin-bottom: 24px;
`;

export const AudioWrapper = styled.div`
  display: flex;
  gap: 16px;

  .ant-radio-wrapper {
    height: max-content;
    align-items: center;
    display: flex;
    margin-right: 0;
  }
  .ant-radio-wrapper::after {
    display: none;
  }
  .ant-radio-wrapper > span:not(.ant-radio) {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    padding-left: 6px;
    padding-right: 0;
  }
  .ant-radio {
    top: 0;
  }
  .ant-radio-inner {
    border: 2px solid #cccccc;
    width: 20px;
    height: 20px;
  }
  .ant-radio-checked .ant-radio-inner {
    border: 2px solid #cccccc;
    width: 20px;
    height: 20px;
  }
  .ant-radio-inner::after {
    background-color: #2ac769;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
  }
  .ant-radio:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-checked .ant-radio-inner {
    border-color: #cccccc;
  }
`;
