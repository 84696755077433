import styled from "styled-components";
import { Modal } from "antd";
import { theme } from "theme/theme";

export const ModalForm = styled(Modal)`
  width: 343px !important;
  top: 16px;
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    padding: 23px 16px;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 8px 8px 0 0;
  }
  .ant-modal-body {
    padding: 24px 16px 0;
  }
  .ant-modal-close {
    top: 11px;
    right: -2px;
  }
  .ant-modal-title {
    text-transform: capitalize;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
  }
  ${theme.media.tablet} {
    width: 720px !important;
    top: 46px;
    .ant-modal-header {
      padding: 23px 24px;
    }
    .ant-modal-body {
      padding: 24px 24px 0;
    }
    .ant-modal-footer {
      padding: 32px 24px;
      button {
        font-size: ${theme.sizes.md};
      }
    }
    .ant-modal-close {
      right: 7px;
    }
  }
  ${theme.media.laptopL} {
    top: 100px;
    transform: unset;
  }
`;

export const ModalCustomHeader = styled(Modal)`
  width: 343px !important;
  top: 16px;
  .ant-modal-title {
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    padding: 26px 16px;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 8px 8px 0 0;
  }
  .ant-modal-body {
    padding: 16px 0px 0 16px;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      margin: 16px 0px 13px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
      background: ${theme.colors.background};
      border-radius: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 7px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
    }
  }
  .ant-modal-close {
    top: 3px;
    right: 5px;
  }
  ${theme.media.tablet} {
    width: 720px !important;
    top: 100px;
    .ant-modal-header {
      padding: 18px 16px;
    }
    .ant-modal-body {
      padding: 24px 0px 0 16px;
    }
    .ant-modal-close {
      top: 12px;
      right: 11px;
    }
  }
`;

export const ConfirmModal = styled(Modal)`
  width: 343px !important;
  top: 50%;
  transform: translate(0%, -50%);
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
  ${theme.media.tablet} {
    min-width: 480px !important;
    width: fit-content !important;
  }
`;
