import * as React from "react";

function RectangleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="18"
        height="18"
        rx="1"
        fill="white"
        stroke={props.fill}
        strokeWidth="2"
      />
    </svg>
  );
}

export default RectangleIcon;
