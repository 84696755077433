import React, { useState, useRef, useEffect } from "react";
import { Grid, Tooltip, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  BackIcon2,
  CommentIcon,
  SendCompleteIcon,
  TemperatureBlueIcon,
  TemperatureRedIcon,
  TemperatureYellowIcon,
} from "icons";
import { ScrollToTop } from "components";
import { localStorageHelper, convertDate } from "utils";
import { IOption } from "components/select/Select";
import {
  optionFamilyIllness,
  optionPool,
  optionRegistrationTime,
  optionsMarathon,
  optionSymptoms,
} from "screens/messageDelivery/constants";
import { httpStatus } from "configs/httpStatus";
import { useGetAttendanceOptions } from "shared-hooks";

import {
  GetStudentActionCreators,
  SearchConditionActionCreators,
  QuestionnairesDetailActionCreators,
  CreateQuestionnairesActionCreators,
  UpdateQuestionnaireActionCreators,
  GetDataStatisticsActionCreators,
} from "redux/rootActions";
import { questionnairesDetailDataResponse } from "redux/questionnaires/questionnairesDetail/questionnairesDetailStates";
import { classListFilterDataResponse } from "redux/messagemanagement/classfilterlist/classFilterListStates";
import { getStudentListSuccessResponse } from "redux/messagemanagement/getstudentlist/getStudentListStates";
import { searchConditionDataResponse } from "redux/messagemanagement/searchCondition/searchConditionStates";
import { temperatureListFilterDataResponse } from "redux/messagemanagement/temperaturefilterlist/temperatureFilterListStates";
import { createQuestionnairesDataResponse } from "redux/questionnaires/createQuestionnaires/createQuestionnairesStates";
import {
  updateQuestionnaireDataResponse,
  questionnaireUpdated,
} from "redux/questionnaires/updateQuestionnaires/updateQuestionnairesStates";
import { dataStatisticsResponse } from "redux/bodyTemperature/statistics/statisticsStates";
import {
  FilterStudentData,
  GetStudentList,
} from "models/messagemanagement/getStudentList";
import { BodyTemperatureData } from "models/bodyTemperature/statistics";
import { AllDataForFilters } from "screens/bodyTemperature/constants";

import ConfirmTransmissionModal from "./ConfirmTransmissionModal";
import SearchCondition from "./SearchCondition";
import StudentList from "./StudentList";
import HistoryList from "./HistoryList";
import { WrapperRedirect, Tag2 } from "./Questions.style";
import {
  SearchConditionModalStyled,
  Wrapper,
  WrapperFields,
} from "../messageDelivery/MessageDelivery.style";
import SearchCondition2 from "./SearchCondition2";

const { useBreakpoint } = Grid;

export type FormStatus =
  | "sendMessage"
  | "messageHistory"
  | "searchCondition"
  | "confirmTransmission"
  | "";
const format = "YYYY-MM-DD";

const QuestionsTransmission = ({ setScreen, id }: any) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const questionnaireDetails = useSelector(questionnairesDetailDataResponse);
  const classFilterlistdata = useSelector(classListFilterDataResponse);
  const studentListDataResponse = useSelector(getStudentListSuccessResponse);
  const searchConditionDataApi = useSelector(searchConditionDataResponse);
  const temperatureFilterlistdata = useSelector(
    temperatureListFilterDataResponse
  );
  const createQuestionnairesData = useSelector(
    createQuestionnairesDataResponse
  );
  const updateQuestionnaireData = useSelector(updateQuestionnaireDataResponse);
  const updateQuestionnaireSaved = useSelector(questionnaireUpdated);
  const dataStatistics = useSelector(dataStatisticsResponse);

  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const isJapanLanguage = currentLanguage === "ja";
  const { attendanceOptions } = useGetAttendanceOptions();

  const scrollAbleRef = useRef<any>(undefined);
  const [isVisible, setIsVisible] = useState(false);
  const [formStatus, setFormStatus] = useState<FormStatus>();
  const [selectedRows, setSelectedRows] = useState<GetStudentList[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [allUser, setAllUser] = useState<any[]>([]);
  const [currentPageData, setCurrentPageData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [isSelectCurrentPage, setIsSelectCurrentPage] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSendAll, setIsSendAll] = useState(false);
  const { measurementTimeForFilter, familyIllnessForFilter } =
    AllDataForFilters();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [filterActions, setFilterActions] = useState({
    department_filter: "",
    group_filter: "",
    temperature_filter: "",
    presence_status_filter: "",
    symptons_filter: "",
    am_pm_filter: "",
    family_illness: "",
    pool_filter: "",
    marathon_filter: "",
    full_name_filter: "",
    page: 1,
    per_page: 50,
    date: moment(new Date()).format(format),
  });
  const [filterActions2, setFilterActions2] = useState<any>({
    page: 1,
    per_page: 50,
    app_language_id: currentLanguage,
    aggregate_date: moment(new Date()).format("YYYY/MM/DD"),
  });
  const [filters, setFilters] = useState<any>({
    page: 1,
    per_page: 50,
    app_language_id: currentLanguage,
    aggregate_date: moment(new Date()).format("YYYY/MM/DD"),
  });
  const [datas, setDatas] = useState<any>({
    pools: [],
    attendances: [],
    symptoms: [],
    bodyTemperatures: [],
    times: [],
    familyIllness: [],
    marathons: [],
    activities: [],
    grades: [],
  });

  useEffect(() => {
    if (
      createQuestionnairesData &&
      Object.keys(createQuestionnairesData.result).length
    ) {
      dispatch(
        QuestionnairesDetailActionCreators.getQuestionnaireDetailAction({
          id: createQuestionnairesData?.result?.questionnaire_id,
        })
      );
    }
  }, [createQuestionnairesData]);

  useEffect(() => {
    if (updateQuestionnaireSaved && id) {
      dispatch(
        QuestionnairesDetailActionCreators.getQuestionnaireDetailAction({
          id: id,
        })
      );
    }
  }, [updateQuestionnaireSaved]);

  useEffect(() => {
    if (
      (createQuestionnairesData &&
        createQuestionnairesData.status === httpStatus.StatusOK) ||
      (updateQuestionnaireData &&
        updateQuestionnaireData.status === httpStatus.StatusOK)
    ) {
      notification.open({
        message: moment(new Date()).format("YYYY/MM/DD HH:mm"),
        description: t("draft-is-saved"),
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      dispatch(UpdateQuestionnaireActionCreators.handleResetAction());
    }
  }, [createQuestionnairesData, updateQuestionnaireData]);

  const handleSendToAllUser = (value: boolean) => {
    setIsSendAll(value);
  };

  const showModal = (formStatus: FormStatus) => {
    setFormStatus(formStatus);
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setIsSelectAll(false);
    setIsSendAll(false);
    setIsSelectCurrentPage(false);
  };

  const removeEmptyValues = (object: any) => {
    const keys = Object.keys(object);
    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];
      const value = object[key];
      if (value === null || value === undefined || value === "") {
        delete object[key];
      }
    }
  };

  const initialColumns = [
    {
      title: t1("full-name"),
      dataIndex: "full_name",
      key: "full_name",
      fixed: window.innerWidth < 576 ? "" : "left",
      width: 150,
      render: (text: string, record: any) => {
        return (
          <WrapperFields>
            <div
              onClick={() => {
                setSelectedRows([
                  {
                    id: record.patient_id,
                    name: record.full_name,
                  },
                ] as any);
                setSelectedRowKeys([record.patient_id]);
                showModal("confirmTransmission");
              }}
            >
              <CommentIcon fill="#B7B7B7" />
            </div>
            <div style={{ marginLeft: 12 }}>{text}</div>
          </WrapperFields>
        );
      },
    },
    {
      title: t1("grade"),
      // dataIndex: "class_name",
      // key: "class_name",
      dataIndex: "department_name",
      key: "department_name",
      width: 98,
      render: (text: any, record: any) => {
        return (
          <div>
            {record.parent_department_name}
            {text}
          </div>
        );
      },
    },
    {
      title: t1("attendance"),
      dataIndex: "roll_number",
      key: "roll_number",
      width: isJapanLanguage ? 82 : 130,
      render: (text: string) => {
        if (!text) {
          return (
            <div
              style={{
                overflow: "hidden",
                width: 65,
              }}
            >
              -
            </div>
          );
        }
        return (
          <div
            style={{
              overflow: "hidden",
              width: 65,
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: t1("activities"),
      // dataIndex: "activities",
      // key: "activities",
      dataIndex: "sub_departments",
      key: "sub_departments",
      render: (text: any) => {
        if (!text) return;
        return (
          <div>
            {text
              .map((sd: any) => {
                return sd.name;
              })
              .join(",")}
          </div>
        );
      },
    },
  ];

  const expandColumns: any = {
    temperature: {
      title: t("body-temperature"),
      dataIndex: "temperature",
      key: "temperature",
      width: isJapanLanguage ? 100 : 140,

      render: (text: any) => {
        if (!text) return <div>-</div>;
        if (text < 37) {
          return (
            <WrapperFields>
              <TemperatureBlueIcon />
              <div style={{ marginLeft: 4 }}>{text}°C</div>
            </WrapperFields>
          );
        }
        if (text > 37) {
          return (
            <WrapperFields>
              <TemperatureRedIcon />
              <div
                style={{
                  marginLeft: 4,
                  fontSize: 16,
                  fontWeight: 700,
                  color: "#FB4E4E",
                }}
              >
                {text}°C
              </div>
            </WrapperFields>
          );
        }
        return (
          <WrapperFields>
            <TemperatureYellowIcon />
            <div
              style={{
                marginLeft: 4,
                fontSize: 16,
                fontWeight: 700,
                color: "#EBC300",
              }}
            >
              {text}°C
            </div>
          </WrapperFields>
        );
      },
    },
    symptoms: {
      title: t("symptoms"),
      dataIndex: "symptoms",
      key: "symptoms",
      width: isJapanLanguage ? 100 : 130,
    },
    attendance: {
      title: t("attendance-status"),
      dataIndex: "attendance",
      key: "attendance",
      width: 150,

      render: (text: any) => {
        if (text === "sick") {
          return (
            <WrapperFields>
              <div style={{ fontSize: 16, color: "#FB4E4E", marginRight: 3 }}>
                {text}
              </div>
              <Tooltip
                placement="bottomRight"
                title="病院に行くのでお休みします"
              >
                <span>
                  <CommentIcon fill="#2F8CAE" />
                </span>
              </Tooltip>
            </WrapperFields>
          );
        }
        return <div>{text}</div>;
      },
    },
    time: {
      title: t("registration-time"),
      dataIndex: "time",
      key: "time",
      width: isJapanLanguage ? 80 : 140,
    },
    family_illness: {
      title: () => {
        return (
          <div>
            {t("family-of")}
            <br /> {t("illness")}
          </div>
        );
      },
      dataIndex: "family_illness",
      key: "family_illness",
      width: 106,
    },
    pool_answer: {
      title: t("pool"),
      dataIndex: "pool_answer",
      key: "pool_answer",
      width: 130,
    },
    marathon: {
      title: t("marathon"),
      dataIndex: "marathon",
      key: "marathon",
      width: 130,
    },
  };

  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    if (!studentListDataResponse) {
      setCurrentPageData([]);
      setAllUser([]);
      setTotal(0);
    }
    if (
      studentListDataResponse &&
      Object.keys(studentListDataResponse?.result).length > 0
    ) {
      setAllUser(studentListDataResponse.result.user_detail);
      setCurrentPageData(studentListDataResponse.result.user_with_temperature);
      setTotal(studentListDataResponse.total_record);
    }
  }, [studentListDataResponse]);

  useEffect(() => {
    if (!dataStatistics) {
      setCurrentPageData([]);
      setAllUser([]);
      setTotal(0);
    }
    if (dataStatistics && Object.keys(dataStatistics).length > 0) {
      setAllUser(
        dataStatistics.all_patients.map((i: any) => {
          return {
            id: i.id,
            name: i.full_name,
          };
        })
      );
      setCurrentPageData(
        dataStatistics.answers.map((i: any) => {
          return {
            ...i,
            key: i.patient_id,
          };
        })
      );
      setTotal(dataStatistics.total_number);
    }
  }, [dataStatistics]);

  const handleSelectCurrentPage = () => {
    const rowKeys = currentPageData.map((item: any) => {
      return item.id;
    });
    const rows = currentPageData.map((item: any) => {
      return {
        id: item.id,
        name: item.full_name,
      };
    });
    setSelectedRows(rows as any);
    setSelectedRowKeys(rowKeys);
    setIsSelectCurrentPage(true);
  };

  const handleDeselectAllRecords = () => {
    setSelectedRowKeys([]);
    setIsSelectCurrentPage(false);
    setIsSelectAll(false);
  };

  const onSelectAllRecords = (selected: boolean) => {
    if (selected) {
      const rowKeys = allUser.map((item: any) => {
        return item.id;
      });
      setSelectedRowKeys(rowKeys);
      setSelectedRows(allUser as any);
      setIsSelectAll(true);
    } else {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setIsSelectAll(false);
    }
  };

  const onSelectRecord = (record: any, selected: any) => {
    let selectedRowsClone: any = [...selectedRows];
    let selectedRowKeysClone = [...selectedRowKeys];

    if (selected) {
      selectedRowsClone.push({ id: record.patient_id, name: record.full_name });
      selectedRowKeysClone.push(record.patient_id);
    }
    if (!selected && selectedRows.length) {
      selectedRowsClone = selectedRows.filter(
        (row: any) => row.id !== record.patient_id
      );
      selectedRowKeysClone = selectedRowKeys.filter(
        (key: any) => key !== record.patient_id
      );
    }
    if (selectedRowsClone.length === allUser.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
    setSelectedRows(selectedRowsClone);
    setSelectedRowKeys(selectedRowKeysClone);
    console.log(selectedRowsClone, selectedRowKeysClone);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onSelectAll: onSelectAllRecords,
    onSelect: onSelectRecord,
  };

  const onPageChange = (page: number) => {
    setFilterActions((prevFilter: any) => ({
      ...prevFilter,
      page,
    }));

    const params: FilterStudentData = {
      ...filterActions,
      page,
    };
    dispatch(GetStudentActionCreators.getStudentListAction(params));
    if (
      scrollAbleRef.current &&
      window.scrollY >= scrollAbleRef.current.offsetTop
    ) {
      window.scrollTo(0, 0);
    }
  };

  const onPageChange2 = (page: number) => {
    setFilterActions2((prevFilter: any) => ({
      ...prevFilter,
      page,
    }));
    const params: BodyTemperatureData = {
      ...filterActions2,
      page,
    };
    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(params));
    if (
      scrollAbleRef.current &&
      window.scrollY >= scrollAbleRef.current.offsetTop
    ) {
      window.scrollTo(0, 0);
    }
  };

  const onPerPageItemChange = (per_page: number) => {
    setFilterActions((prevFilter: any) => ({
      ...prevFilter,
      per_page,
      page: 1,
    }));

    const params: FilterStudentData = {
      ...filterActions,
      per_page,
      page: 1,
    };
    dispatch(GetStudentActionCreators.getStudentListAction(params));
  };

  const onPerPageItemChange2 = (per_page: number) => {
    setFilterActions2((prevFilter: any) => ({
      ...prevFilter,
      per_page,
      page: 1,
    }));

    const params: BodyTemperatureData = {
      ...filterActions2,
      per_page,
      page: 1,
    };
    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(params));
  };

  const handleRemoveDestination = (id: number) => {
    setSelectedRows(selectedRows.filter((row: any) => row.id !== id));
  };

  const handleCheckExistedColumn = (columns: any, keyNewColumn: string) => {
    return columns.some((column: any) => {
      return column.key === keyNewColumn;
    });
  };

  const onSubmit = (data: any) => {
    const columnsOfContent: any = [];

    const isBodyTemperatureExisted = handleCheckExistedColumn(
      columns,
      "temperature"
    );

    if (
      !isBodyTemperatureExisted &&
      datas.bodyTemperatures &&
      datas.bodyTemperatures.length > 0
    ) {
      columnsOfContent.push(expandColumns["temperature"]);
    }

    const selectedColumns = Object.keys(data);
    selectedColumns.forEach((item: any) => {
      const isExisted = handleCheckExistedColumn(columns, item);
      if (!isExisted && data[item]) {
        columnsOfContent.push(expandColumns[item]);
      }
    });

    setColumns([...columns, ...columnsOfContent]);

    const class_ids =
      datas.grades.length === classFilterlistdata?.result.length
        ? ""
        : datas.grades.map((a: any) => a.id);

    const activities_ids = datas.activities
      ? datas.activities.map((a: any) => a.id)
      : "";

    const temperature_ids =
      datas.bodyTemperatures &&
      temperatureFilterlistdata?.result &&
      datas.bodyTemperatures.length < temperatureFilterlistdata?.result.length
        ? datas.bodyTemperatures.map((a: any) => a.id).toString()
        : "";
    const symptoms_id =
      datas.symptoms && datas.symptoms.length < optionSymptoms.length
        ? datas.symptoms.map((a: any) => a.id).toString()
        : "";

    const time_id =
      datas.times && datas.times.length < optionRegistrationTime.length
        ? datas.times.map((a: any) => a.id).toString()
        : "";

    const attendance_id =
      searchConditionDataApi?.result &&
      searchConditionDataApi?.result.is_attendance_active &&
      datas.attendances &&
      attendanceOptions.length &&
      datas.attendances.length < attendanceOptions.length
        ? datas.attendances.map((a: any) => a.id).toString()
        : "";

    const pool_answer_id =
      searchConditionDataApi?.result &&
      searchConditionDataApi?.result.is_pool_active &&
      datas.pools &&
      datas.pools.length < optionPool.length
        ? datas.pools.map((a: any) => a.id).toString()
        : "";

    const family_illness_id =
      searchConditionDataApi?.result &&
      searchConditionDataApi?.result.is_family_illness_active &&
      datas.familyIllness &&
      datas.familyIllness.length < optionFamilyIllness.length
        ? datas.familyIllness.map((a: any) => a.id).toString()
        : "";

    const marathon_id =
      searchConditionDataApi?.result &&
      searchConditionDataApi?.result.is_marathon_active &&
      datas.marathons &&
      datas.marathons.length < optionsMarathon.length
        ? datas.marathons.map((a: any) => a.id).toString()
        : "";

    setFilterActions((prevFilters: any) => ({
      ...prevFilters,
      department_filter: class_ids,
      group_filter: activities_ids,
      temperature_filter: temperature_ids,
      presence_status_filter: attendance_id,
      symptons_filter: symptoms_id,
      am_pm_filter: time_id,
      family_illness: family_illness_id,
      pool_filter: pool_answer_id,
      marathon_filter: marathon_id,
    }));

    const params: FilterStudentData = {
      department_filter: class_ids,
      group_filter: activities_ids,
      temperature_filter: temperature_ids,
      presence_status_filter: attendance_id,
      symptons_filter: symptoms_id,
      am_pm_filter: time_id,
      family_illness: family_illness_id,
      pool_filter: pool_answer_id,
      marathon_filter: marathon_id,
      full_name_filter: filterActions.full_name_filter,
      page: filterActions.page,
      per_page: filterActions.per_page,
      date: moment(new Date()).format(format),
    };

    dispatch(GetStudentActionCreators.getStudentListAction(params));
    handleCancel();
  };

  const onSubmit2 = (data: any) => {
    const columnsOfContent: any = [];

    const isBodyTemperatureExisted = handleCheckExistedColumn(
      columns,
      "temperature"
    );

    if (
      !isBodyTemperatureExisted &&
      datas.bodyTemperatures &&
      datas.bodyTemperatures.length > 0
    ) {
      columnsOfContent.push(expandColumns["temperature"]);
    }

    const selectedColumns = Object.keys(data);

    selectedColumns.forEach((item: any) => {
      const isExisted = handleCheckExistedColumn(columns, item);
      if (!isExisted && data[item]) {
        columnsOfContent.push(expandColumns[item]);
      }
    });

    setColumns([...columns, ...columnsOfContent]);

    const class_ids =
      datas.grades.length === classFilterlistdata?.result.length
        ? ""
        : datas.grades.map((a: any) => a.id);

    const activities_ids = datas.activities
      ? datas.activities.map((a: any) => a.id)
      : "";

    const param: BodyTemperatureData = {
      ...filters,
      aggregate_date: convertDate(startDate),
      department_id: class_ids,
      sub_department_id: activities_ids,
      temperature: datas.bodyTemperatures,
      health_check: datas.symptoms,
      checked_at:
        datas?.times && datas?.times.length < measurementTimeForFilter.length
          ? datas?.times
          : "",
      attendance: datas.attendances,
      family_health:
        datas?.familyIllness &&
        datas?.familyIllness.length < familyIllnessForFilter.length
          ? datas?.familyIllness.join(",")
          : "",
      marathon: datas?.marathons,
      pool_join: datas?.pools,
    };

    removeEmptyValues(param);
    setFilters(param);
    setFilterActions2(param);
    dispatch(GetDataStatisticsActionCreators.getStatisticsAction(param));
    handleCancel();
  };

  useEffect(() => {
    setDatas({
      pools: [],
      attendances: [],
      symptoms: [],
      bodyTemperatures: [],
      times: [],
      familyIllness: [],
      marathons: [],
      activities: [],
      grades: [],
    });
  }, []);

  useEffect(() => {
    setAllUser([]);
    setCurrentPageData([]);
    setTotal(0);
    dispatch(GetDataStatisticsActionCreators.handleResetAction());
    dispatch(GetStudentActionCreators.handleResetDataAction());
    dispatch(SearchConditionActionCreators.searchConditionAction());

    return () => {
      dispatch(CreateQuestionnairesActionCreators.handleResetAction());
    };
  }, []);

  const renderSearchCondition = () => (
    <SearchCondition
      showModal={showModal}
      onSubmit={onSubmit}
      handleChangeSendToAllRegistrants={handleSendToAllUser}
      datas={datas}
      setDatas={setDatas}
      bodyTemperatures={temperatureFilterlistdata?.result}
      searchConditionDataApi={searchConditionDataApi?.result}
      attendanceOptions={attendanceOptions}
    />
  );

  const renderSearchCondition2 = () => (
    <SearchCondition2
      showModal={showModal}
      onSubmit={onSubmit2}
      handleSendToAllUser={handleSendToAllUser}
      datas={datas}
      setDatas={setDatas}
      bodyTemperatures={temperatureFilterlistdata?.result}
      searchConditionDataApi={searchConditionDataApi?.result}
      attendanceOptions={attendanceOptions}
    />
  );

  const renderForm = () => {
    switch (formStatus) {
      case "searchCondition":
        return (
          <SearchConditionModalStyled
            type="form"
            title={"検索条件設定"}
            visible={isVisible}
            onCancel={handleCancel}
          >
            {renderSearchCondition2()}
          </SearchConditionModalStyled>
        );
      case "confirmTransmission":
        return (
          <ConfirmTransmissionModal
            isVisible={isVisible}
            isSendAll={isSendAll}
            selectedRowKeys={selectedRowKeys}
            selectedRows={selectedRows}
            handleCancel={handleCancel}
            handleRemoveDestination={handleRemoveDestination}
            setScreen={setScreen}
            record={questionnaireDetails?.result.questionnaire}
          />
        );
      default:
        return;
    }
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      {formStatus === "messageHistory" ? (
        <HistoryList />
      ) : (
        <>
          <WrapperRedirect>
            <div
              className="bor-backicon"
              onClick={() => {
                setScreen("list");
              }}
            >
              <BackIcon2 />
            </div>
            <p>{questionnaireDetails?.result?.questionnaire.title}</p>
            <Tag2 background="#EBC300">{t("draft")}</Tag2>
          </WrapperRedirect>
          <div
            style={{
              padding: "0px 24px 0px 16px",
              background: "#ffffff",
              borderRadius: 8,
            }}
          >
            {renderForm()}
            <Wrapper>
              {screens.xl && renderSearchCondition2()}

              <StudentList
                columns={columns}
                isSelectCurrentPage={isSelectCurrentPage}
                isSelectAll={isSelectAll}
                showModal={showModal}
                rowSelection={rowSelection}
                handleSelectCurrentPage={handleSelectCurrentPage}
                handleDeselectAllRecords={handleDeselectAllRecords}
                selectedRows={selectedRows}
                setRowsSelected={setSelectedRows}
                onPageChange={onPageChange2}
                onPerPageItemChange={onPerPageItemChange2}
                filterActions={filterActions2}
                allStudentsDataResponse={studentListDataResponse}
                setFilterActions={setFilterActions2}
                currentPageData={currentPageData}
                total={total}
                // scrollAbleRef={scrollAbleRef}
              />
            </Wrapper>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default QuestionsTransmission;
