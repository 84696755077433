import * as React from "react";

function InjectionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.8182 28.8687L15.1818 30V24.3434H16.8182V28.8687Z"
        fill={props.fill}
      />
      <path
        d="M14.6364 21.5151H17.3636V23.2121C17.3636 23.3621 17.3062 23.506 17.2039 23.6121C17.1016 23.7182 16.9628 23.7778 16.8182 23.7778H15.1818C15.0371 23.7778 14.8984 23.7182 14.7961 23.6121C14.6938 23.506 14.6364 23.3621 14.6364 23.2121V21.5151Z"
        fill={props.fill}
      />
      <path
        d="M18.7273 10.2586V19.4166C18.7203 19.5617 18.6597 19.6984 18.5582 19.7984C18.4566 19.8984 18.3219 19.9541 18.1818 19.954H13.8182C13.6781 19.9541 13.5433 19.8984 13.4418 19.7984C13.3403 19.6984 13.2797 19.5617 13.2727 19.4166V10.2586H11.6364V20.497C11.6504 20.7871 11.7714 21.0605 11.9745 21.2606C12.1776 21.4607 12.4471 21.5721 12.7273 21.5717H19.2727C19.5528 21.5721 19.8224 21.4607 20.0254 21.2606C20.2285 21.0605 20.3496 20.7871 20.3636 20.497V10.2586H18.7273Z"
        fill={props.fill}
      />
      <path
        d="M18.1818 12.7475H16V13.8788H18.1818V12.7475Z"
        fill={props.fill}
      />
      <path
        d="M18.1818 15.0101H16V16.1414H18.1818V15.0101Z"
        fill={props.fill}
      />
      <path d="M18.1818 17.2727H16V18.404H18.1818V17.2727Z" fill={props.fill} />
      <path
        d="M14.0909 4.54546V6.24243H15.1819V4.54546H14.0909Z"
        fill={props.fill}
      />
      <path
        d="M16.8182 4.54546V6.24243H17.9091V4.54546H16.8182Z"
        fill={props.fill}
      />
      <path
        d="M21.4545 6.80811H10.5455C10.2442 6.80811 10 7.06136 10 7.37376V9.07073C10 9.38314 10.2442 9.63639 10.5455 9.63639H21.4545C21.7558 9.63639 22 9.38314 22 9.07073V7.37376C22 7.06136 21.7558 6.80811 21.4545 6.80811Z"
        fill={props.fill}
      />
      <path
        d="M18.1818 2H13.8182C13.5169 2 13.2727 2.25325 13.2727 2.56566V4.26263C13.2727 4.57503 13.5169 4.82828 13.8182 4.82828H18.1818C18.483 4.82828 18.7273 4.57503 18.7273 4.26263V2.56566C18.7273 2.25325 18.483 2 18.1818 2Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default InjectionIcon;
