import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  CreateAccountResponse,
} from "models/account/createAccount";
import { createAccount } from "services/account";

import { handleSuccesAction, handleErrorAction } from "./createAccountActions";

function* createAccountSaga({ payload }: any) {
  try {
    const response: CreateAccountResponse = yield call(createAccount, payload);
    yield put(handleSuccesAction(response));
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCreateAccountWatcher() {
  yield takeLatest(ActionType.CREATE_ACCOUNT as any, createAccountSaga);
}

export default [fork(onCreateAccountWatcher)];
