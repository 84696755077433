import {
  ActionType,
  DataDetailFormResponse,
} from "models/bodyTemperature/detailForm";

export const getDataDetailFormAction = (payload: any) => {
  return {
    type: ActionType.DATA_DETAIL_FORM,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DATA_DETAIL_FORM_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DataDetailFormResponse) => {
  return {
    type: ActionType.DATA_DETAIL_FORM_SUCCESS,
    payload,
  };
};
