import { Action, ActionType, UserListResponse } from "models/userlist";
import createReducer from "../createReducer";

export interface UserListReducerType {
  user_name: string;
  c_department_id: string;
  page: number;
  loading: boolean;
  error?: string;
  userListResponse?: UserListResponse;
}

const defaultState: UserListReducerType = {
  user_name: "",
  c_department_id: "",
  page: 1,
  loading: false,
  error: undefined,
  userListResponse: undefined,
};

export const userListReducer = createReducer<UserListReducerType>(
  defaultState,
  {
    [ActionType.USER_LIST](state: UserListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.USER_LIST_ERROR](
      state: UserListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        userListResponse: [],
      };
    },

    [ActionType.USER_LIST_SUCCESS](
      state: UserListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        userListResponse: action.payload,
      };
    },

    [ActionType.USER_LIST_RESET_DATA](state: UserListReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
  }
);
