import * as React from "react";

function FaceVeryGoodIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="#54A1C5" />
      <path
        d="M13.2639 12.6433C11.5535 12.6433 10.7322 13.4918 10.2639 14.0483C9.97203 14.3945 9.05573 14.3809 9.41546 13.5936C9.83628 12.6841 11.2209 11.3334 13.2639 11.3334C15.3069 11.3334 16.42 12.6094 16.8273 13.4103C17.1327 14.0144 16.5219 14.347 16.101 13.8311C15.6802 13.3153 14.8454 12.6366 13.2639 12.6366V12.6433Z"
        fill="white"
      />
      <path
        d="M27.0766 12.6705C25.5562 12.6705 24.7825 13.3696 24.2666 13.8786C23.7508 14.3877 23.2689 14.028 23.4793 13.5189C23.8458 12.623 25.1286 11.5031 27.063 11.5031C28.9974 11.5031 30.1988 12.6841 30.6196 13.5528C30.8164 13.9669 30.3617 14.4488 29.8934 13.9465C29.425 13.4442 28.7463 12.6773 27.0834 12.6773L27.0766 12.6705Z"
        fill="white"
      />
      <path
        d="M19.8548 35.0145C19.753 35.0145 19.6444 35.0145 19.5425 35.0145C16.2846 34.9263 11.5674 31.6344 12.633 26.2317C12.7144 25.8108 12.9181 25.424 13.3524 25.39C13.7801 25.3561 15.9724 26.3335 19.8616 26.3335C23.7507 26.3335 26.1263 25.3357 26.5539 25.3968C26.9815 25.4511 27.1648 25.8516 27.2259 26.2792C28.0403 32.3132 23.1534 35.0281 19.848 35.0281L19.8548 35.0145Z"
        fill="white"
      />
    </svg>
  );
}

export default FaceVeryGoodIcon;
