import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  SendNotificationData,
  ActionType,
} from "models/mentalHealth/sendNotification";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { sendNotification } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./sendNotificationActions";

function* sendNotificationSaga({ payload }: { payload: SendNotificationData }) {
  try {
    const response: IResponse = yield call(sendNotification, payload);

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSendNotificationWatcher() {
  yield takeLatest(ActionType.SEND_NOTIFICATION as any, sendNotificationSaga);
}

export default [fork(onSendNotificationWatcher)];
