import { IResponse } from "models";

export enum ActionType {
  // resetpassword
  RESET_PASSWORD = "action/RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS = "action/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR = "action/RESET_PASSWORD_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ResetPasswordData {
  user: {
    reset_password_token: string | null;
    password: string | null;
    password_confirmation: string | null;
    role: number | null;
  };
}

export interface ResetPasswordResponse extends IResponse {
  message: string;
}
