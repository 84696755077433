import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/messagemanagement/updateMessage";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { updateMessage } from "services/messagemanagement";
import { handleSuccesAction, handleErrorAction } from "./updateMessageActions";

function* updateMessageSaga({ payload }: { payload: any }) {
  try {
    const response: IResponse = yield call(updateMessage, payload);
    if (
      response.status === httpStatus.StatusCreated ||
      response.status === httpStatus.StatusOK
    ) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateMessageWatcher() {
  yield takeLatest(ActionType.UPDATE_MESSAGE as any, updateMessageSaga);
}

export default [fork(onUpdateMessageWatcher)];
