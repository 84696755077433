import {
  IUpdateQuestionnairesPayload,
  ActionType,
  UpdateQuestionnairesResponse,
} from "models/questionnaires/updateQuestionnaires";

export const updateQuestionnaireAction = (
  payload: IUpdateQuestionnairesPayload
) => {
  return {
    type: ActionType.UPDATE_QUESTIONNAIRES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_QUESTIONNAIRES_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: UpdateQuestionnairesResponse) => {
  return {
    type: ActionType.UPDATE_QUESTIONNAIRES_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.UPDATE_QUESTIONNAIRES_RESET,
  };
};
