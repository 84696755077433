import {
  Action,
  ActionType,
  FaceScaleResponse,
} from "models/dashboard/faceScale";
import createReducer from "../../createReducer";

export interface FaceScaleReducerType {
  loading: boolean;
  error?: string;
  faceScaleResponse?: FaceScaleResponse;
}

const defaultState: FaceScaleReducerType = {
  loading: false,
  error: undefined,
  faceScaleResponse: undefined,
};

export const getFaceScaleReducer = createReducer<FaceScaleReducerType>(
  defaultState,
  {
    [ActionType.GET_FACE_SCALE_CHART](state: FaceScaleReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_FACE_SCALE_CHART_ERROR](
      state: FaceScaleReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_FACE_SCALE_CHART_SUCCESS](
      state: FaceScaleReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        faceScaleResponse: action.payload,
      };
    },
  }
);
