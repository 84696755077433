import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { notification, Tooltip } from "antd";

import { Button, Select, Input } from "components";
import { InformationIcon, PlusIcon, SendCompleteIcon } from "icons";

import {
  GetConsultationsSettingsActionCreators,
  SaveConsultationsSettingsActionCreators,
} from "redux/rootActions";
import {
  IConsultationSettingsResponse,
  IConsultationSettings,
} from "models/consultationRequest/consultationSettings";
import { ISaveConsultationSettingsPayload } from "models/consultationRequest/saveConsultationSettings";
import { updateDeseaseSettingResponse } from "redux/setting/updateDesease/updateDeseaseStates";
import {
  selectIsLoadingConsultationSettings,
  selectConsultationSettingsErrorMessage,
  consultationSettingsDataResponse,
} from "redux/consultationRequest/consultationSettings/consultationSettingsStates";
import {
  selectIsSavingConsultationSettings,
  selectSaveConsultationSettingsErrorMessage,
  saveConsultationSettingsDataResponse,
} from "redux/consultationRequest/saveConsultationSettings/saveConsultationSettingsStates";
import { settingDataResponse } from "redux/setting/settingData/settingDataStates";
import { httpStatus } from "configs/httpStatus";

import { Checkbox1 } from "./components";
import {
  WrapperConsultRequestSection,
  WrapperTitle,
  FormTitle,
  CheckboxStyled,
  CheckItem2,
  WrapperCheckbox,
  LastCheckboxStyled,
  RequestCheckItem,
  WrapperInforIcon,
  TooltipRightStyled,
  RequestFormLabel,
  CheckItem,
  RequestSelectGroup,
  RequestEmailInputGroup,
  RequestEmailLabelGroup,
  EmailInputLabel,
  InputGroup,
  DeleteIconStyled,
} from "./Setting.style";

type FormStatus = "1" | "2" | "3" | "";
const hourOptions: any[] = [];

for (let i = 0; i <= 23; i++) {
  hourOptions.push({
    id: i,
    value: i < 10 ? `0${i}` : `${i}`,
    name: i < 10 ? `0${i}` : `${i}`,
  });
}

const additionalHour = {
  id: 24,
  name: "24",
  value: "24",
};

export const minutesOptions = [
  {
    id: 0,
    value: "00",
    name: "00",
  },
  {
    id: 15,
    value: "15",
    name: "15",
  },
  {
    id: 30,
    value: "30",
    name: "30",
  },
  {
    id: 45,
    value: "45",
    name: "45",
  },
];

const initSettingsValues: ISaveConsultationSettingsPayload = {
  email: "",
  teacher_exchange_flag: false,
  teacher_exchange_time_flag: false,
  teacher_consult_flag: false,
  teacher_consult_time_flag: false,
  doctor_consult_flag: false,
  doctor_consult_time_flag: false,
  teacher_exchange_start_time: "08:00",
  teacher_exchange_end_time: "16:00",
  teacher_consult_start_time: "08:00",
  teacher_consult_end_time: "16:00",
  doctor_consult_days: null,
  doctor_consult_start_time: "08:00",
  doctor_consult_end_time: "16:00",
  consultation_request_teachers_attributes: [],
  teacher_consult_days: null,
  teacher_exchange_days: null,
  teacher_exchange_notification_flag: false,
  teacher_consult_notification_flag: false,
};

const ConsultationRequestSetting = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "setting" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "account",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "consultation-request",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });

  const dispatch = useDispatch();

  const isLoadingConsultationSettings = useSelector(
    selectIsLoadingConsultationSettings
  );
  const savingConsultationSettings = useSelector(
    selectIsSavingConsultationSettings
  );

  const getConsultationSettingsError = useSelector(
    selectConsultationSettingsErrorMessage
  );
  const saveConsultationSettingsError = useSelector(
    selectSaveConsultationSettingsErrorMessage
  );

  const consultationSettingsData = useSelector(
    consultationSettingsDataResponse
  );
  const saveConsultationSettingsData = useSelector(
    saveConsultationSettingsDataResponse
  );
  const settingData = useSelector(settingDataResponse);

  const updateDeseaseSetting = useSelector(updateDeseaseSettingResponse);
  const [consultationSettings, setConsultationSettings] =
    useState<any>(undefined);
  const [firstFormSettings, setFirstFormSettings] = useState<any>(undefined);
  const [secondFormSettings, setSecondFormSettings] = useState<any>(undefined);
  const [thirdFormSettings, setThirdFormSettings] = useState<any>(undefined);
  const [firstFormError, setFirstFormError] = useState<string>("");
  const [secondFormError, setSecondFormError] = useState<string>("");
  const [secondFormEmailError, setSecondFormEmailError] = useState<string>("");
  const [thirdFormError, setThirdFormError] = useState<string>("");
  const [formStatus, setFormStatus] = useState<FormStatus>("");
  const [firstFormErrors, setFirstFormErrors] = useState<any>(undefined);
  const [deletedEmails, setDeletedEmails] = useState<any>(undefined);

  const returnHour = (time: string) => {
    if (!time || (time && !time.split(":")[0])) {
      return undefined;
    }
    return time.split(":")[0];
  };

  const returnMinute = (time: string) => {
    if (!time || (time && !time.split(":")[1])) {
      return undefined;
    }
    return time.split(":")[1];
  };

  const addFirstFormEmail = () => {
    if (
      firstFormSettings &&
      Object(firstFormSettings) &&
      firstFormSettings.consultation_request_teachers.length &&
      firstFormErrors &&
      firstFormErrors.length
    ) {
      const tempEmailsInfo = [
        ...firstFormSettings.consultation_request_teachers,
      ];
      const tempFormErrors = [...firstFormErrors];
      tempEmailsInfo.push({
        name: "",
        email: "",
      });
      tempFormErrors.push({
        name: "",
        email: "",
      });
      setFirstFormSettings({
        ...firstFormSettings,
        consultation_request_teachers: tempEmailsInfo,
      });
      setFirstFormErrors(tempFormErrors);
    }
  };

  const deleteFirstFormEmail = (idx: number) => {
    if (
      firstFormSettings &&
      Object(firstFormSettings) &&
      firstFormSettings.consultation_request_teachers.length &&
      firstFormErrors &&
      firstFormErrors.length
    ) {
      if (
        consultationSettings.consultation_request_teachers[idx] &&
        consultationSettings.consultation_request_teachers[idx].id
      ) {
        const recordIndex =
          consultationSettings.consultation_request_teachers.findIndex(
            (item: any) =>
              item.id ===
              firstFormSettings.consultation_request_teachers[idx].id
          );
        if (deletedEmails && deletedEmails.length > 0) {
          setDeletedEmails([
            ...deletedEmails,
            {
              ...consultationSettings.consultation_request_teachers[
                recordIndex
              ],
              _destroy: true,
            },
          ]);
        } else {
          setDeletedEmails([
            {
              ...consultationSettings.consultation_request_teachers[
                recordIndex
              ],
              _destroy: true,
            },
          ]);
        }
      }
      const tempEmailsInfo = [
        ...firstFormSettings.consultation_request_teachers,
      ].filter((item: any, index: number) => index !== idx);
      const tempFormErrors = [...firstFormErrors].filter(
        (item: any, index: number) => index !== idx
      );
      setFirstFormSettings({
        ...firstFormSettings,
        consultation_request_teachers: tempEmailsInfo,
      });
      setFirstFormErrors(tempFormErrors);
    }
  };

  const resetFormError = (type: "name" | "email", index: number) => {
    if (
      firstFormSettings &&
      Object(firstFormSettings) &&
      firstFormErrors &&
      firstFormErrors.length
    ) {
      const tempErrs = [...firstFormErrors];
      const tempErr = firstFormErrors[index];
      if (type === "name") {
        tempErr.name = "";
      }
      if (type === "email") {
        tempErr.email = "";
      }
      tempErrs[index] = tempErr;
      setFirstFormErrors(tempErrs);
    }
  };

  const handleChangeFirstFormInput = (
    e: any,
    type: "name" | "email",
    index: number
  ) => {
    if (
      firstFormSettings &&
      Object(firstFormSettings) &&
      firstFormSettings &&
      firstFormSettings.consultation_request_teachers.length
    ) {
      const tempEmailsSetting = [
        ...firstFormSettings.consultation_request_teachers,
      ];
      const tempInput = firstFormSettings.consultation_request_teachers[index];
      if (type === "name") {
        tempInput.name = e.target.value;
        resetFormError("name", index);
      }
      if (type === "email") {
        tempInput.email = e.target.value;
        resetFormError("email", index);
      }
      tempEmailsSetting[index] = tempInput;
      setFirstFormSettings({
        ...firstFormSettings,
        consultation_request_teachers: tempEmailsSetting,
      });
    }
  };

  const changeFirstFormTime = (e: any, type: string) => {
    if (firstFormSettings && Object(firstFormSettings)) {
      const startHour =
        firstFormSettings.teacher_exchange_start_time.split(":")[0];
      const startMinute =
        firstFormSettings.teacher_exchange_start_time.split(":")[1];
      const endHour = firstFormSettings.teacher_exchange_end_time.split(":")[0];
      const endMinute =
        firstFormSettings.teacher_exchange_end_time.split(":")[1];
      switch (type) {
        case "startHour":
          setFirstFormSettings({
            ...firstFormSettings,
            teacher_exchange_start_time: `${e}:${startMinute}`,
          });
          break;
        case "startMinute":
          setFirstFormSettings({
            ...firstFormSettings,
            teacher_exchange_start_time: `${startHour}:${e}`,
          });
          break;
        case "endHour":
          setFirstFormSettings({
            ...firstFormSettings,
            teacher_exchange_end_time: `${e}:${endMinute}`,
          });
          break;
        case "endMinute":
          setFirstFormSettings({
            ...firstFormSettings,
            teacher_exchange_end_time: `${endHour}:${e}`,
          });
          break;
      }
      setSecondFormError("");
    }
  };

  const changeSecondFormTime = (e: any, type: string) => {
    if (secondFormSettings && Object(secondFormSettings)) {
      const startHour =
        secondFormSettings.teacher_consult_start_time.split(":")[0];
      const startMinute =
        secondFormSettings.teacher_consult_start_time.split(":")[1];
      const endHour = secondFormSettings.teacher_consult_end_time.split(":")[0];
      const endMinute =
        secondFormSettings.teacher_consult_end_time.split(":")[1];
      switch (type) {
        case "startHour":
          setSecondFormSettings({
            ...secondFormSettings,
            teacher_consult_start_time: `${e}:${startMinute}`,
          });
          break;
        case "startMinute":
          setSecondFormSettings({
            ...secondFormSettings,
            teacher_consult_start_time: `${startHour}:${e}`,
          });
          break;
        case "endHour":
          setSecondFormSettings({
            ...secondFormSettings,
            teacher_consult_end_time: `${e}:${endMinute}`,
          });
          break;
        case "endMinute":
          setSecondFormSettings({
            ...secondFormSettings,
            teacher_consult_end_time: `${endHour}:${e}`,
          });
          break;
      }
      setSecondFormError("");
    }
  };

  const changeThirdFormTime = (e: any, type: string) => {
    if (thirdFormSettings && Object(thirdFormSettings)) {
      const startHour =
        thirdFormSettings.doctor_consult_start_time.split(":")[0];
      const startMinute =
        thirdFormSettings.doctor_consult_start_time.split(":")[1];
      const endHour = thirdFormSettings.doctor_consult_end_time.split(":")[0];
      const endMinute = thirdFormSettings.doctor_consult_end_time.split(":")[1];
      switch (type) {
        case "startHour":
          setThirdFormSettings({
            ...thirdFormSettings,
            doctor_consult_start_time: `${e}:${startMinute}`,
          });
          break;
        case "startMinute":
          setThirdFormSettings({
            ...thirdFormSettings,
            doctor_consult_start_time: `${startHour}:${e}`,
          });
          break;
        case "endHour":
          setThirdFormSettings({
            ...thirdFormSettings,
            doctor_consult_end_time: `${e}:${endMinute}`,
          });
          break;
        case "endMinute":
          setThirdFormSettings({
            ...thirdFormSettings,
            doctor_consult_end_time: `${endHour}:${e}`,
          });
          break;
      }
      setThirdFormError("");
    }
  };

  const handleValidateFirstFormTime = () => {
    if (firstFormSettings && Object(firstFormSettings)) {
      const startHour = firstFormSettings.teacher_exchange_start_time;
      const endHour = firstFormSettings.teacher_exchange_end_time;
      if (!startHour || !endHour || startHour >= endHour || endHour > "24:00") {
        setFirstFormError("Invalid time.");
        return false;
      } else {
        return true;
      }
    }
  };

  const handleValidateSecondFormTime = () => {
    if (secondFormSettings && Object(secondFormSettings)) {
      const startHour = secondFormSettings.teacher_consult_start_time;
      const endHour = secondFormSettings.teacher_consult_end_time;
      if (!startHour || !endHour || startHour >= endHour || endHour > "24:00") {
        setSecondFormError("Invalid time.");
        return false;
      } else {
        return true;
      }
    }
  };

  const handleValidateFirstForm = () => {
    if (firstFormSettings && Object(firstFormSettings)) {
      if (firstFormSettings.teacher_exchange_flag) {
        const tempErrs: any[] = [];
        const teachersEmails =
          firstFormSettings.consultation_request_teachers || [];
        const regEx =
          /^([\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,})(?:,\s*[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,})*$/; // eslint-disable-line
        if (teachersEmails && teachersEmails.length) {
          teachersEmails.map((info: any) => {
            const tempErrObj = {
              name: "",
              email: "",
            };
            if (
              (!info.name && info.email) ||
              (info.name && !info.name.trim() && info.email)
            ) {
              tempErrObj.name = "表示名を入力してください";
            }
            if (!info.email && info.name && info.name.trim()) {
              tempErrObj.email = "1つ以上のメールアドレスを入力してください";
            }
            if (info.email && !regEx.test(info.email)) {
              tempErrObj.email =
                "英数字、@、,（カンマ）以外の文字が入力されています";
            }
            tempErrs.push(tempErrObj);
          });
        }
        setFirstFormErrors(tempErrs);
        if (tempErrs.every((item: any) => !item.name && !item.email)) {
          return true;
        }
        return false;
      } else {
        const tempErrs: any[] = [];
        const teachersEmails =
          firstFormSettings.consultation_request_teachers || [];
        const regEx =
          /^([\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,})(?:,\s*[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,})*$/; // eslint-disable-line
        if (teachersEmails && teachersEmails.length) {
          teachersEmails.map((info: any) => {
            const tempErrObj = {
              name: "",
              email: "",
            };
            if (
              (!info.name && info.email) ||
              (info.name && !info.name.trim() && info.email)
            ) {
              tempErrObj.name = "表示名を入力してください";
            }
            if (!info.email && info.name && info.name.trim()) {
              tempErrObj.email = "1つ以上のメールアドレスを入力してください";
            }
            if (info.email && !regEx.test(info.email)) {
              tempErrObj.email =
                "英数字、@、,（カンマ）以外の文字が入力されています";
            }
            tempErrs.push(tempErrObj);
          });
        }
        setFirstFormErrors(tempErrs);
        if (tempErrs.every((item: any) => !item.name && !item.email)) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const handleValidateSecondFormEmail = () => {
    if (secondFormSettings && Object(secondFormSettings)) {
      const email = secondFormSettings.email;
      if (!email) {
        if (secondFormSettings.teacher_consult_flag) {
          setSecondFormEmailError("1つ以上のメールアドレスを入力してください");
          return false;
        } else return true;
      }
      const regEx =
        /^([\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,})(?:,\s*[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,})*$/; // eslint-disable-line
      if (!regEx.test(email)) {
        setSecondFormEmailError(
          "英数字、@、,（カンマ）以外の文字が入力されています"
        );
        return false;
      } else {
        return true;
      }
    }
  };

  const handleValidateThirdForm = () => {
    if (thirdFormSettings && Object(thirdFormSettings)) {
      const startHour = thirdFormSettings.doctor_consult_start_time;
      const endHour = thirdFormSettings.doctor_consult_end_time;
      if (!startHour || !endHour || startHour >= endHour || endHour > "24:00") {
        setThirdFormError("Invalid time");
        return false;
      } else {
        return true;
      }
    }
  };

  const handleFilterEmails = () => {
    if (firstFormSettings && Object(firstFormSettings)) {
      const tempEmails = [...firstFormSettings.consultation_request_teachers];
      let filteredEmails: any[] = [];
      if (tempEmails.length > 0) {
        tempEmails.map((email: any) => {
          if (email.id && !email.email && !email.name) {
            filteredEmails.push({
              id: email.id,
              name: email.name,
              email: email.email,
              _destroy: true,
            });
          }
          if (email.id && email.email && email.name) {
            filteredEmails.push({
              id: email.id,
              name: email.name,
              email: email.email,
            });
          }
          if (!email.id && email.email && email.name) {
            filteredEmails.push({
              name: email.name,
              email: email.email,
            });
          }
        });
      } else {
        filteredEmails = [];
      }
      // if (!firstFormSettings.teacher_exchange_flag) {
      // } else {
      //   filteredEmails = tempEmails;
      // }
      return filteredEmails;
    } else return [];
  };

  const handleSubmitSetting1 = () => {
    if (
      consultationSettings &&
      Object(consultationSettings) &&
      firstFormSettings &&
      Object(firstFormSettings)
    ) {
      if (!handleValidateFirstFormTime()) {
        return;
      }
      setFirstFormError("");
      if (!handleValidateFirstForm()) {
        return;
      }
      setFormStatus("1");

      const params: ISaveConsultationSettingsPayload = {
        // id: consultationSettings.id,
        ...consultationSettings,
        email: consultationSettings.email.replaceAll(" ", ""),
        teacher_exchange_flag: firstFormSettings.teacher_exchange_flag,
        teacher_exchange_time_flag:
          firstFormSettings.teacher_exchange_time_flag,
        teacher_exchange_start_time:
          firstFormSettings.teacher_exchange_start_time,
        teacher_exchange_end_time: firstFormSettings.teacher_exchange_end_time,
        teacher_exchange_days: firstFormSettings.teacher_exchange_days
          ? firstFormSettings.teacher_exchange_days.join()
          : "",
        teacher_exchange_notification_flag:
          firstFormSettings.teacher_exchange_notification_flag,
        consultation_request_teachers_attributes: [
          ...handleFilterEmails(),
          // ...firstFormSettings.consultation_request_teachers,
          ...(deletedEmails || []),
        ],
        // teacher_consult_days: consultationSettings.teacher_consult_days,
        // teacher_consult_flag: consultationSettings.teacher_consult_flag,
        // teacher_consult_time_flag:
        //   consultationSettings.teacher_consult_time_flag,
        // teacher_consult_start_time:
        //   consultationSettings.teacher_consult_start_time,
        // teacher_consult_end_time: consultationSettings.teacher_consult_end_time,
        // teacher_consult_notification_flag:
        //   consultationSettings.teacher_consult_notification_flag,
        // doctor_consult_flag: consultationSettings.doctor_consult_flag,
        // doctor_consult_days: consultationSettings.doctor_consult_days,
        // doctor_consult_time_flag: consultationSettings.doctor_consult_time_flag,
        // doctor_consult_start_time:
        //   consultationSettings.doctor_consult_start_time,
        // doctor_consult_end_time: consultationSettings.doctor_consult_end_time,
      };

      dispatch(
        SaveConsultationsSettingsActionCreators.saveConsultationSettingsAction(
          params
        )
      );
    }
  };

  const handleSubmitSetting2 = () => {
    if (
      consultationSettings &&
      Object(consultationSettings) &&
      secondFormSettings &&
      Object(secondFormSettings)
    ) {
      if (!handleValidateSecondFormTime()) {
        return;
      }
      setSecondFormError("");
      if (!handleValidateSecondFormEmail()) {
        return;
      }
      setSecondFormEmailError("");
      setFormStatus("2");

      const params: ISaveConsultationSettingsPayload = {
        // id: consultationSettings.id,
        ...consultationSettings,
        email: secondFormSettings.email.replaceAll(" ", ""),
        teacher_exchange_flag: consultationSettings.teacher_exchange_flag,
        // teacher_exchange_time_flag:
        //   consultationSettings.teacher_exchange_time_flag,
        // teacher_exchange_start_time:
        //   consultationSettings.teacher_exchange_start_time,
        // teacher_exchange_end_time:
        //   consultationSettings.teacher_exchange_end_time,
        // teacher_exchange_days: consultationSettings.teacher_exchange_days,
        // teacher_exchange_notification_flag:
        //   consultationSettings.teacher_exchange_notification_flag,
        // consultation_request_teachers_attributes:
        //   consultationSettings.consultation_request_teachers,
        teacher_consult_days: secondFormSettings.teacher_consult_days
          ? secondFormSettings.teacher_consult_days.join()
          : "",
        teacher_consult_flag: secondFormSettings.teacher_consult_flag,
        teacher_consult_time_flag: secondFormSettings.teacher_consult_time_flag,
        teacher_consult_start_time:
          secondFormSettings.teacher_consult_start_time,
        teacher_consult_end_time: secondFormSettings.teacher_consult_end_time,
        teacher_consult_notification_flag:
          secondFormSettings.teacher_consult_notification_flag,
        // doctor_consult_days: consultationSettings.doctor_consult_days,
        // doctor_consult_flag: consultationSettings.doctor_consult_flag,
        // doctor_consult_time_flag: consultationSettings.doctor_consult_time_flag,
        // doctor_consult_start_time:
        //   consultationSettings.doctor_consult_start_time,
        // doctor_consult_end_time: consultationSettings.doctor_consult_end_time,
      };

      dispatch(
        SaveConsultationsSettingsActionCreators.saveConsultationSettingsAction(
          params
        )
      );
    }
  };

  const handleSubmitSetting3 = () => {
    if (
      consultationSettings &&
      Object(consultationSettings) &&
      thirdFormSettings &&
      Object(thirdFormSettings)
    ) {
      if (!handleValidateThirdForm()) {
        return;
      }
      setThirdFormError("");
      setFormStatus("3");

      const params: ISaveConsultationSettingsPayload = {
        // id: consultationSettings.id,
        // email: consultationSettings.email,
        // teacher_exchange_flag: consultationSettings.teacher_exchange_flag,
        // teacher_exchange_time_flag:
        //   consultationSettings.teacher_exchange_time_flag,
        // teacher_exchange_start_time:
        //   consultationSettings.teacher_exchange_start_time,
        // teacher_exchange_end_time:
        //   consultationSettings.teacher_exchange_end_time,
        // teacher_exchange_days: consultationSettings.teacher_exchange_days,
        // teacher_exchange_notification_flag:
        //   consultationSettings.teacher_exchange_notification_flag,
        // consultation_request_teachers_attributes:
        //   consultationSettings.consultation_request_teachers,
        // teacher_consult_days: consultationSettings.teacher_consult_days,
        // teacher_consult_flag: consultationSettings.teacher_consult_flag,
        // teacher_consult_time_flag:
        //   consultationSettings.teacher_consult_time_flag,
        // teacher_consult_start_time:
        //   consultationSettings.teacher_consult_start_time,
        // teacher_consult_end_time: consultationSettings.teacher_consult_end_time,
        // teacher_consult_notification_flag:
        //   consultationSettings.teacher_consult_notification_flag,
        ...consultationSettings,
        doctor_consult_days: thirdFormSettings.doctor_consult_days
          ? thirdFormSettings.doctor_consult_days.join()
          : "",
        doctor_consult_flag: thirdFormSettings.doctor_consult_flag,
        doctor_consult_time_flag: thirdFormSettings.doctor_consult_time_flag,
        doctor_consult_start_time: thirdFormSettings.doctor_consult_start_time,
        doctor_consult_end_time: thirdFormSettings.doctor_consult_end_time,
      };

      dispatch(
        SaveConsultationsSettingsActionCreators.saveConsultationSettingsAction(
          params
        )
      );
    }
  };

  useEffect(() => {
    if (
      updateDeseaseSetting &&
      updateDeseaseSetting?.status === httpStatus.StatusOK
    ) {
      notification.success({
        message: t("temperature-setting-apply"),
        description: "",
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
    }
  }, [updateDeseaseSetting]);

  // useEffect(() => {
  //   setDeletedEmails(undefined);
  //   setConsultationSettings(undefined);
  //   dispatch(GetConsultationsSettingsActionCreators.handleResetDataAction());
  //   dispatch(SaveConsultationsSettingsActionCreators.handleResetDataAction());
  // }, []);

  const handleGetConsultationSetting = () => {
    dispatch(
      GetConsultationsSettingsActionCreators.getConsultationSettingsAction()
    );
  };

  useEffect(() => {
    handleGetConsultationSetting();
  }, []);

  useEffect(() => {
    if (
      consultationSettingsData &&
      consultationSettingsData.status === httpStatus.StatusOK
    ) {
      const result = consultationSettingsData.result;
      const teachers =
        result.consultation_request_teachers.length > 0
          ? result.consultation_request_teachers
          : [
              {
                name: "",
                email: "",
              },
            ];
      const errsObject: any[] = [];

      setConsultationSettings(result);
      setFirstFormSettings({
        teacher_exchange_flag: result.teacher_exchange_flag,
        teacher_exchange_time_flag: result.teacher_exchange_time_flag,
        teacher_exchange_start_time:
          result.teacher_exchange_start_time || "08:00",
        teacher_exchange_end_time: result.teacher_exchange_end_time || "16:00",
        consultation_request_teachers: teachers,
        teacher_exchange_notification_flag:
          result.teacher_exchange_notification_flag,
        teacher_exchange_days: result.teacher_exchange_days
          ? result.teacher_exchange_days.split(",")
          : [],
      });
      setSecondFormSettings({
        teacher_consult_flag: result.teacher_consult_flag,
        teacher_consult_time_flag: result.teacher_consult_time_flag,
        email: result.email,
        teacher_consult_start_time:
          result.teacher_consult_start_time || "08:00",
        teacher_consult_end_time: result.teacher_consult_end_time || "16:00",
        teacher_consult_notification_flag:
          result.teacher_consult_notification_flag,
        teacher_consult_days: result.teacher_consult_days
          ? result.teacher_consult_days.split(",")
          : [],
      });
      setThirdFormSettings({
        doctor_consult_flag: result.doctor_consult_flag,
        doctor_consult_time_flag: result.doctor_consult_time_flag,
        doctor_consult_start_time: result.doctor_consult_start_time || "08:00",
        doctor_consult_end_time: result.doctor_consult_end_time || "16:00",
        doctor_consult_days: result.doctor_consult_days
          ? result.doctor_consult_days.split(",")
          : [],
      });
      if (teachers && teachers.length) {
        teachers.map((item: any) =>
          errsObject.push({
            name: "",
            email: "",
          })
        );
      } else {
        errsObject.push({
          name: "",
          email: "",
        });
      }
      setFirstFormErrors(errsObject);
      setDeletedEmails(undefined);
      // dispatch(GetConsultationsSettingsActionCreators.handleResetDataAction());
    }
  }, [consultationSettingsData]);

  // useEffect(() => {
  //   if (!savingConsultationSettings && saveConsultationSettingsData) {
  //     const result = saveConsultationSettingsData.result;
  //     // const teachers = result.consultation_request_teachers;
  //     const teachers =
  //       result.consultation_request_teachers.length > 0
  //         ? result.consultation_request_teachers
  //         : [
  //             {
  //               name: "",
  //               email: "",
  //             },
  //           ];
  //     const errsObject: any[] = [];
  //     setConsultationSettings(result);
  //     setFirstFormSettings({
  //       teacher_exchange_flag: result.teacher_exchange_flag,
  //       teacher_exchange_time_flag: result.teacher_exchange_time_flag,
  //       teacher_exchange_start_time: result.teacher_exchange_start_time,
  //       teacher_exchange_end_time: result.teacher_exchange_end_time,
  //       consultation_request_teachers: teachers,
  //       teacher_exchange_notification_flag:
  //         result.teacher_exchange_notification_flag,
  //       teacher_exchange_days: result.teacher_exchange_days
  //         ? result.teacher_exchange_days.split(",")
  //         : [],
  //     });
  //     setSecondFormSettings({
  //       teacher_consult_flag: result.teacher_consult_flag,
  //       teacher_consult_time_flag: result.teacher_consult_time_flag,
  //       email: result.email,
  //       teacher_consult_start_time: result.teacher_consult_start_time,
  //       teacher_consult_end_time: result.teacher_consult_end_time,
  //       teacher_consult_notification_flag:
  //         result.teacher_consult_notification_flag,
  //       teacher_consult_days: result.teacher_consult_days
  //         ? result.teacher_consult_days.split(",")
  //         : [],
  //     });
  //     setThirdFormSettings({
  //       doctor_consult_flag: result.doctor_consult_flag,
  //       doctor_consult_time_flag: result.doctor_consult_time_flag,
  //       doctor_consult_start_time: result.doctor_consult_start_time,
  //       doctor_consult_end_time: result.doctor_consult_end_time,
  //       doctor_consult_days: result.doctor_consult_days
  //         ? result.doctor_consult_days.split(",")
  //         : [],
  //     });
  //     if (teachers && teachers.length) {
  //       teachers.map((item: any) =>
  //         errsObject.push({
  //           name: "",
  //           email: "",
  //         })
  //       );
  //     }
  //     setFirstFormErrors(errsObject);
  //     setFormStatus("");
  //     setDeletedEmails(undefined);
  //     dispatch(SaveConsultationsSettingsActionCreators.handleResetDataAction());
  //   }
  // }, [saveConsultationSettingsData]);

  useEffect(() => {
    if (
      saveConsultationSettingsData &&
      saveConsultationSettingsData?.status === httpStatus.StatusOK
    ) {
      notification.success({
        message: t("general-setting-apply"),
        description: "",
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      handleGetConsultationSetting();
      dispatch(SaveConsultationsSettingsActionCreators.handleResetDataAction());
    }
  }, [saveConsultationSettingsData]);

  useEffect(() => {
    if (
      !isLoadingConsultationSettings &&
      getConsultationSettingsError &&
      getConsultationSettingsError.status === 404
    ) {
      dispatch(
        SaveConsultationsSettingsActionCreators.saveConsultationSettingsAction(
          initSettingsValues
        )
      );
    }
  }, [getConsultationSettingsError]);

  const InfoIcon = (data?: any) => (
    <WrapperInforIcon>
      <Tooltip
        placement="right"
        title={
          <TooltipRightStyled>
            <img src={data.url} />
          </TooltipRightStyled>
        }
      >
        <InformationIcon offset="4" />
      </Tooltip>
    </WrapperInforIcon>
  );

  return (
    <div
      style={{
        display: settingData?.result?.temperature_managerment_info
          ?.is_consultation_request
          ? "block"
          : "none",
      }}
    >
      <WrapperConsultRequestSection>
        <WrapperTitle>
          <RequestFormLabel>
            <div className="label">{t("consult-teacher-button")}</div>
            <WrapperCheckbox>
              {/* <LastCheckboxStyled checked={value} onChange={onChange}> */}
              <LastCheckboxStyled
                checked={
                  firstFormSettings && Object(firstFormSettings)
                    ? firstFormSettings.teacher_exchange_flag
                    : false
                }
                onChange={() => {
                  if (firstFormSettings && Object(firstFormSettings)) {
                    setFirstFormSettings({
                      ...firstFormSettings,
                      teacher_exchange_flag:
                        !firstFormSettings.teacher_exchange_flag,
                    });
                  }
                }}
              >
                <RequestCheckItem>
                  {t("enable-teacher-button")}
                </RequestCheckItem>
              </LastCheckboxStyled>
              <InfoIcon url="images/teacher-consult-button.png" />
            </WrapperCheckbox>
          </RequestFormLabel>
        </WrapperTitle>
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <WrapperCheckbox>
            <LastCheckboxStyled
              checked={
                firstFormSettings && Object(firstFormSettings)
                  ? firstFormSettings.teacher_exchange_time_flag
                  : false
              }
              onChange={() => {
                if (firstFormSettings && Object(firstFormSettings)) {
                  setFirstFormSettings({
                    ...firstFormSettings,
                    teacher_exchange_time_flag:
                      !firstFormSettings.teacher_exchange_time_flag,
                  });
                }
              }}
            >
              <CheckItem>{t2("set-button-time")}</CheckItem>
            </LastCheckboxStyled>
          </WrapperCheckbox>

          <RequestSelectGroup>
            {firstFormSettings &&
              Object(firstFormSettings) &&
              !firstFormSettings.teacher_exchange_time_flag && (
                <div className="mask"></div>
              )}
            <div className="left">
              <Select
                defaultValue={undefined}
                value={
                  firstFormSettings &&
                  Object(firstFormSettings) &&
                  firstFormSettings.teacher_exchange_start_time
                    ? returnHour(firstFormSettings.teacher_exchange_start_time)
                    : undefined
                }
                options={hourOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeFirstFormTime(e, "startHour")}
                error={firstFormError}
              />
              <Select
                defaultValue={undefined}
                value={
                  firstFormSettings &&
                  Object(firstFormSettings) &&
                  firstFormSettings.teacher_exchange_start_time
                    ? returnMinute(
                        firstFormSettings.teacher_exchange_start_time
                      )
                    : undefined
                }
                options={minutesOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeFirstFormTime(e, "startMinute")}
              />
            </div>
            <div>〜</div>
            <div className="right">
              <Select
                defaultValue={undefined}
                value={
                  firstFormSettings &&
                  Object(firstFormSettings) &&
                  firstFormSettings.teacher_exchange_end_time
                    ? returnHour(firstFormSettings.teacher_exchange_end_time)
                    : undefined
                }
                options={hourOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeFirstFormTime(e, "endHour")}
              />
              <Select
                defaultValue={undefined}
                value={
                  firstFormSettings &&
                  Object(firstFormSettings) &&
                  firstFormSettings.teacher_exchange_end_time
                    ? returnMinute(firstFormSettings.teacher_exchange_end_time)
                    : undefined
                }
                options={minutesOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeFirstFormTime(e, "endMinute")}
              />
            </div>
            <Checkbox1
              list={[
                {
                  key: 1,
                  value: "1",
                  name: t3("Mon"),
                },
                {
                  key: 2,
                  value: "2",
                  name: t3("Tue"),
                },
                {
                  key: 3,
                  value: "3",
                  name: t3("Wed"),
                },
                {
                  key: 4,
                  value: "4",
                  name: t3("Thu"),
                },
                {
                  key: 5,
                  value: "5",
                  name: t3("Fri"),
                },
                {
                  key: 6,
                  value: "6",
                  name: t3("Sat"),
                },
                {
                  key: 7,
                  value: "7",
                  name: t3("Sun-Holiday"),
                },
              ]}
              value={
                firstFormSettings &&
                Object(firstFormSettings) &&
                firstFormSettings.teacher_exchange_days
                  ? firstFormSettings.teacher_exchange_days
                  : []
              }
              row
              onChange={(value: string[]) => {
                setFirstFormSettings((prevState: any) => ({
                  ...prevState,
                  teacher_exchange_days: value,
                }));
              }}
            />
          </RequestSelectGroup>
        </div>
        <div
          style={{
            width: "100%",
            border: "1px dashed #cccccc",
            marginBottom: 16,
          }}
        ></div>
        <WrapperCheckbox>
          <LastCheckboxStyled
            checked={
              firstFormSettings && Object(firstFormSettings)
                ? firstFormSettings.teacher_exchange_notification_flag
                : false
            }
            onChange={() => {
              if (firstFormSettings && Object(firstFormSettings)) {
                setFirstFormSettings({
                  ...firstFormSettings,
                  teacher_exchange_notification_flag:
                    !firstFormSettings.teacher_exchange_notification_flag,
                });
              }
            }}
          >
            <CheckItem>{t2("send-confirmation-notification")}</CheckItem>
          </LastCheckboxStyled>
        </WrapperCheckbox>
        <div
          style={{
            width: "100%",
            border: "1px dashed #cccccc",
            marginBottom: 16,
            marginTop: 16,
          }}
        ></div>
        <RequestEmailLabelGroup>
          <div className="left">{t1("display-name")}</div>
          <div className="right">
            {t1("email-address")}
            <span>{t2("register-more-noti")}</span>
          </div>
        </RequestEmailLabelGroup>
        {firstFormSettings &&
          firstFormSettings.consultation_request_teachers &&
          firstFormSettings.consultation_request_teachers.length > 0 &&
          firstFormSettings.consultation_request_teachers.map(
            (item: any, index: number) => (
              <RequestEmailInputGroup key={index}>
                <Input
                  width={"280px"}
                  height={31}
                  value={item.name || ""}
                  placeholder="リーバー先生"
                  error={firstFormErrors[index].name || ""}
                  onChange={(e: any) =>
                    handleChangeFirstFormInput(e, "name", index)
                  }
                />
                <InputGroup>
                  <Input
                    width={"100%"}
                    height={31}
                    value={item.email || ""}
                    placeholder="info@leber.jp, info02@leber.jp"
                    error={firstFormErrors[index].email || ""}
                    onChange={(e: any) =>
                      handleChangeFirstFormInput(e, "email", index)
                    }
                  />
                  {index >= 1 && (
                    <DeleteIconStyled
                      fill="#FB4E4E"
                      onClick={() => deleteFirstFormEmail(index)}
                    />
                  )}
                </InputGroup>
              </RequestEmailInputGroup>
            )
          )}
        {/* <RequestEmailInputGroup>
          <Input
            width={"280px"}
            height={31}
            value={""}
            placeholder="リーバー先生"
          />
          <InputGroup>
            <Input
              width={"100%"}
              height={31}
              value={""}
              placeholder="info@leber.jp, info02@leber.jp"
            />
          </InputGroup>
        </RequestEmailInputGroup>
        <RequestEmailInputGroup>
          <Input
            width={"280px"}
            height={31}
            value={""}
            placeholder="リーバー先生"
          />
          <InputGroup>
            <Input
              width={"100%"}
              height={31}
              value={""}
              placeholder="info@leber.jp, info02@leber.jp"
            />
            <DeleteIconStyled fill="#FB4E4E" />
          </InputGroup>
        </RequestEmailInputGroup> */}
        <Button
          name={t2("add-input-field")}
          background="#2AC769"
          color="#FFFFFF"
          border="none"
          fontSize={14}
          fontWeight={500}
          lineHeight="16px"
          padding="3px 6px 5px 4px"
          marginRight={3}
          icon={
            <PlusIcon
              style={{ position: "relative", top: 2 }}
              width={14}
              height={14}
              fill="#ffffff"
            />
          }
          margin="0px 0px 32px 0px"
          style={{
            height: 24,
          }}
          onClick={addFirstFormEmail}
        />
        <div>
          <Button
            type="submit"
            name={t("keep")}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            lineHeight="23px"
            padding="4px 16px"
            onClick={handleSubmitSetting1}
            disabled={
              savingConsultationSettings && formStatus === "1" ? true : false
            }
          />
        </div>
      </WrapperConsultRequestSection>

      <WrapperConsultRequestSection style={{ marginTop: 40 }}>
        <WrapperTitle>
          <RequestFormLabel>
            <div className="label">{t("consult-counselor-button")}</div>
            <WrapperCheckbox>
              {/* <LastCheckboxStyled checked={value} onChange={onChange}> */}
              <LastCheckboxStyled
                checked={
                  secondFormSettings && Object(secondFormSettings)
                    ? secondFormSettings.teacher_consult_flag
                    : false
                }
                onChange={() => {
                  if (secondFormSettings && Object(secondFormSettings)) {
                    setSecondFormSettings({
                      ...secondFormSettings,
                      teacher_consult_flag:
                        !secondFormSettings.teacher_consult_flag,
                    });
                  }
                }}
              >
                <RequestCheckItem>
                  {t("enable-counselor-button")}
                </RequestCheckItem>
              </LastCheckboxStyled>
              <InfoIcon url="images/counselor-consult-button.png" />
            </WrapperCheckbox>
          </RequestFormLabel>
        </WrapperTitle>
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <WrapperCheckbox>
            <LastCheckboxStyled
              checked={
                secondFormSettings && Object(secondFormSettings)
                  ? secondFormSettings.teacher_consult_time_flag
                  : false
              }
              onChange={() => {
                if (secondFormSettings && Object(secondFormSettings)) {
                  setSecondFormSettings({
                    ...secondFormSettings,
                    teacher_consult_time_flag:
                      !secondFormSettings.teacher_consult_time_flag,
                  });
                }
              }}
            >
              <CheckItem>{t2("set-button-time")}</CheckItem>
            </LastCheckboxStyled>
          </WrapperCheckbox>
          <RequestSelectGroup>
            {secondFormSettings &&
              Object(secondFormSettings) &&
              !secondFormSettings.teacher_consult_time_flag && (
                <div className="mask"></div>
              )}
            <div className="left">
              <Select
                defaultValue={undefined}
                value={
                  secondFormSettings &&
                  Object(secondFormSettings) &&
                  secondFormSettings.teacher_consult_start_time
                    ? returnHour(secondFormSettings.teacher_consult_start_time)
                    : undefined
                }
                options={hourOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeSecondFormTime(e, "startHour")}
                error={secondFormError}
              />
              <Select
                defaultValue={undefined}
                value={
                  secondFormSettings &&
                  Object(secondFormSettings) &&
                  secondFormSettings.teacher_consult_start_time
                    ? returnMinute(
                        secondFormSettings.teacher_consult_start_time
                      )
                    : undefined
                }
                options={minutesOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeSecondFormTime(e, "startMinute")}
              />
            </div>
            <div>〜</div>
            <div className="right">
              <Select
                defaultValue={undefined}
                value={
                  secondFormSettings &&
                  Object(secondFormSettings) &&
                  secondFormSettings.teacher_consult_end_time
                    ? returnHour(secondFormSettings.teacher_consult_end_time)
                    : undefined
                }
                options={hourOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeSecondFormTime(e, "endHour")}
              />
              <Select
                defaultValue={undefined}
                value={
                  secondFormSettings &&
                  Object(secondFormSettings) &&
                  secondFormSettings.teacher_consult_end_time
                    ? returnMinute(secondFormSettings.teacher_consult_end_time)
                    : undefined
                }
                options={minutesOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeSecondFormTime(e, "endMinute")}
              />
            </div>
            <Checkbox1
              list={[
                {
                  key: 1,
                  value: "1",
                  name: t3("Mon"),
                },
                {
                  key: 2,
                  value: "2",
                  name: t3("Tue"),
                },
                {
                  key: 3,
                  value: "3",
                  name: t3("Wed"),
                },
                {
                  key: 4,
                  value: "4",
                  name: t3("Thu"),
                },
                {
                  key: 5,
                  value: "5",
                  name: t3("Fri"),
                },
                {
                  key: 6,
                  value: "6",
                  name: t3("Sat"),
                },
                {
                  key: 7,
                  value: "7",
                  name: t3("Sun-Holiday"),
                },
              ]}
              value={
                secondFormSettings &&
                Object(secondFormSettings) &&
                secondFormSettings.teacher_consult_days
                  ? secondFormSettings.teacher_consult_days
                  : []
              }
              row
              onChange={(value: string[]) => {
                setSecondFormSettings((prevState: any) => ({
                  ...prevState,
                  teacher_consult_days: value,
                }));
              }}
            />
          </RequestSelectGroup>
        </div>
        <div
          style={{
            width: "100%",
            border: "1px dashed #cccccc",
            marginBottom: 16,
          }}
        ></div>
        <WrapperCheckbox>
          <LastCheckboxStyled
            checked={
              secondFormSettings && Object(secondFormSettings)
                ? secondFormSettings.teacher_consult_notification_flag
                : false
            }
            onChange={() => {
              if (secondFormSettings && Object(secondFormSettings)) {
                setSecondFormSettings({
                  ...secondFormSettings,
                  teacher_consult_notification_flag:
                    !secondFormSettings.teacher_consult_notification_flag,
                });
              }
            }}
          >
            <CheckItem>{t2("send-confirmation-notification")}</CheckItem>
          </LastCheckboxStyled>
        </WrapperCheckbox>
        <div
          style={{
            width: "100%",
            border: "1px dashed #cccccc",
            marginBottom: 16,
            marginTop: 16,
          }}
        ></div>
        <EmailInputLabel>
          {t1("email-address")}
          <span>{t2("register-more-noti")}</span>
        </EmailInputLabel>
        <Input
          defaultValue={""}
          width={"100%"}
          height={31}
          marginForm="0px 0px 32px 0px"
          value={
            secondFormSettings && secondFormSettings.email
              ? secondFormSettings.email
              : ""
          }
          onChange={(e: any) => {
            setSecondFormEmailError("");
            setSecondFormSettings({
              ...secondFormSettings,
              email: e.target.value,
            });
          }}
          placeholder="info@leber.jp, info02@leber.jp"
          error={secondFormEmailError}
        />
        <Button
          type="submit"
          name={t("keep")}
          background="#2AC769"
          color="#FFFFFF"
          border="none"
          fontSize={16}
          fontWeight={700}
          lineHeight="23px"
          padding="4px 16px"
          onClick={handleSubmitSetting2}
          disabled={
            savingConsultationSettings && formStatus === "2" ? true : false
          }
        />
      </WrapperConsultRequestSection>

      <WrapperConsultRequestSection style={{ marginTop: 40 }}>
        <WrapperTitle>
          <RequestFormLabel>
            <div className="label">{t("consult-doctor-button")}</div>
            <WrapperCheckbox>
              {/* <LastCheckboxStyled checked={value} onChange={onChange}> */}
              <LastCheckboxStyled
                checked={
                  thirdFormSettings && Object(thirdFormSettings)
                    ? thirdFormSettings.doctor_consult_flag
                    : false
                }
                onChange={() => {
                  if (thirdFormSettings && Object(thirdFormSettings)) {
                    setThirdFormSettings({
                      ...thirdFormSettings,
                      doctor_consult_flag:
                        !thirdFormSettings.doctor_consult_flag,
                    });
                  }
                }}
              >
                <RequestCheckItem>{t("enable-doctor-button")}</RequestCheckItem>
              </LastCheckboxStyled>
              <InfoIcon url="images/doctor-consult-button.png" />
            </WrapperCheckbox>
          </RequestFormLabel>
        </WrapperTitle>
        <div
          style={{
            marginBottom: 32,
          }}
        >
          <WrapperCheckbox>
            <LastCheckboxStyled
              checked={
                thirdFormSettings && Object(thirdFormSettings)
                  ? thirdFormSettings.doctor_consult_time_flag
                  : false
              }
              onChange={() => {
                if (thirdFormSettings && Object(thirdFormSettings)) {
                  setThirdFormSettings({
                    ...thirdFormSettings,
                    doctor_consult_time_flag:
                      !thirdFormSettings.doctor_consult_time_flag,
                  });
                }
              }}
            >
              <CheckItem>{t2("set-button-time")}</CheckItem>
            </LastCheckboxStyled>
          </WrapperCheckbox>

          <RequestSelectGroup>
            {thirdFormSettings &&
              Object(thirdFormSettings) &&
              !thirdFormSettings.doctor_consult_time_flag && (
                <div className="mask"></div>
              )}
            <div className="left">
              <Select
                defaultValue={undefined}
                value={
                  thirdFormSettings &&
                  Object(thirdFormSettings) &&
                  thirdFormSettings.doctor_consult_start_time
                    ? returnHour(thirdFormSettings.doctor_consult_start_time)
                    : undefined
                }
                options={hourOptions}
                width="56px"
                height="30px"
                error={thirdFormError}
                onChange={(e: any) => changeThirdFormTime(e, "startHour")}
              />
              <Select
                defaultValue={undefined}
                value={
                  thirdFormSettings &&
                  Object(thirdFormSettings) &&
                  thirdFormSettings.doctor_consult_start_time
                    ? returnMinute(thirdFormSettings.doctor_consult_start_time)
                    : undefined
                }
                options={minutesOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeThirdFormTime(e, "startMinute")}
              />
            </div>
            <div>〜</div>
            <div className="right">
              <Select
                defaultValue={undefined}
                value={
                  thirdFormSettings &&
                  Object(thirdFormSettings) &&
                  thirdFormSettings.doctor_consult_end_time
                    ? returnHour(thirdFormSettings.doctor_consult_end_time)
                    : undefined
                }
                options={[...hourOptions, additionalHour]}
                width="56px"
                height="30px"
                onChange={(e: any) => changeThirdFormTime(e, "endHour")}
              />
              <Select
                defaultValue={undefined}
                value={
                  thirdFormSettings &&
                  Object(thirdFormSettings) &&
                  thirdFormSettings.doctor_consult_end_time
                    ? returnMinute(thirdFormSettings.doctor_consult_end_time)
                    : undefined
                }
                options={minutesOptions}
                width="56px"
                height="30px"
                onChange={(e: any) => changeThirdFormTime(e, "endMinute")}
              />
            </div>
            <Checkbox1
              list={[
                {
                  key: 1,
                  value: "1",
                  name: t3("Mon"),
                },
                {
                  key: 2,
                  value: "2",
                  name: t3("Tue"),
                },
                {
                  key: 3,
                  value: "3",
                  name: t3("Wed"),
                },
                {
                  key: 4,
                  value: "4",
                  name: t3("Thu"),
                },
                {
                  key: 5,
                  value: "5",
                  name: t3("Fri"),
                },
                {
                  key: 6,
                  value: "6",
                  name: t3("Sat"),
                },
                {
                  key: 7,
                  value: "7",
                  name: t3("Sun-Holiday"),
                },
              ]}
              value={
                firstFormSettings &&
                Object(thirdFormSettings) &&
                thirdFormSettings.doctor_consult_days
                  ? thirdFormSettings.doctor_consult_days
                  : []
              }
              row
              onChange={(value: string[]) => {
                setThirdFormSettings((prevState: any) => ({
                  ...prevState,
                  doctor_consult_days: value,
                }));
              }}
            />
          </RequestSelectGroup>
        </div>
        <Button
          type="submit"
          name={t("keep")}
          background="#2AC769"
          color="#FFFFFF"
          border="none"
          fontSize={16}
          fontWeight={700}
          lineHeight="23px"
          padding="4px 16px"
          onClick={handleSubmitSetting3}
          disabled={
            savingConsultationSettings && formStatus === "3" ? true : false
          }
        />
      </WrapperConsultRequestSection>
    </div>
  );
};

export default ConsultationRequestSetting;
