import { RootState } from "../../rootReducer";

export const selectIsLoading = (state: RootState) =>
  state.pushNotification.loading;
export const selectIsNotificationSent = (state: RootState) =>
  state.pushNotification.saved;
export const selectErrorMessage = (state: RootState) =>
  state.pushNotification.error;
export const pushNotificationResponse = (state: RootState) =>
  state.pushNotification.pushNotificationResponse;
