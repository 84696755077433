import { Action, ActionType } from "models/resetPassword";
import createReducer from "../createReducer";

export interface ResetPasswordReducerType {
  loading: boolean;
  error?: string;
  success?: string;
}

const defaultState: ResetPasswordReducerType = {
  loading: false,
  error: undefined,
  success: undefined,
};

export const resetPasswordReducer = createReducer<ResetPasswordReducerType>(
  defaultState,
  {
    [ActionType.RESET_PASSWORD](state: ResetPasswordReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.RESET_PASSWORD_ERROR](
      state: ResetPasswordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.RESET_PASSWORD_SUCCESS](
      state: ResetPasswordReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        success: action.payload,
      };
    },
  }
);
