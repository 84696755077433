import {
  Action,
  ActionType,
  IGetSchoolClassResponse,
} from "models/classmanagement/classList";
import createReducer from "../../createReducer";

export interface ClassSchoolListReducerType {
  loading: boolean;
  error?: string;
  classesSchoolResponse?: IGetSchoolClassResponse;
}

const defaultState: ClassSchoolListReducerType = {
  loading: false,
  error: undefined,
  classesSchoolResponse: undefined,
};

export const classSchoolListReducer = createReducer<ClassSchoolListReducerType>(
  defaultState,
  {
    [ActionType.GET_CLASS_LIST_SCHOOL](state: ClassSchoolListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_CLASS_LIST_SCHOOL_ERROR](
      state: ClassSchoolListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_CLASS_LIST_SCHOOL_SUCCESS](
      state: ClassSchoolListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        classesSchoolResponse: action.payload,
      };
    },
  }
);
