import { ResetPasswordData, ActionType } from "../../models/resetPassword";

export const resetPasswordUserAction = (payload: ResetPasswordData) => {
  return {
    type: ActionType.RESET_PASSWORD,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.RESET_PASSWORD_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: string) => {
  return {
    type: ActionType.RESET_PASSWORD_SUCCESS,
    payload,
  };
};
