import styled from "styled-components";
import { Upload } from "antd";

import { theme } from "theme/theme";
import { Button } from "components";

const { Dragger } = Upload;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
  span {
    width: 100%;
  }
  .ant-upload {
    width: 100%;
  }
  ${theme.media.tablet} {
    span {
      width: 100%;
    }
    .ant-upload {
      width: 100%;
    }
  }
`;

export const CancelButton = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  cursor: pointer;
  background: #f2f2f2;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 5px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    margin: 0;
    margin-right: 8px;
    width: max-content;
    color: #3b3b3b;
  }
  svg {
    width: 9px;
    height: 10px;
    cursor: pointer;
  }
  ${theme.media.tablet} {
    position: unset;
    width: unset;
  }
`;

export const WrapperUpload = styled.div`
  position: relative;
  padding-bottom: 47px;
  ${theme.media.tablet} {
    padding-bottom: 0;
  }
`;

export const UploadFileBtn = styled(Button)`
  width: 100%;
  ${theme.media.tablet} {
    width: unset;
  }
`;

export const DraggerStyled = styled(Dragger)`
  height: 190px !important;
  overflow: auto;
  background: ${theme.colors.white}!important;
  border: 6px solid ${theme.colors.background}!important;
  border-radius: 12px;
  padding: 3px 19px;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 16px 0px 13px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }

  .ant-upload-drag-container {
    display: unset !important;
  }
  .ant-upload {
    padding: 0 !important;
  }
  ${theme.media.tablet} {
    height: 155px !important;
  }
`;

export const WrapperFileList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const WrapperFile = styled.div`
  display: flex;
  align-items: center;
  width: 257px;
  position: relative;
  margin-bottom: 4px;
  margin-top: 20px;
  img {
    height: 95px;
    width: 95px;
    border-radius: 12px;
  }
  > div {
    background: #eb5757;
    height: 95px;
    width: 95px;
    border-radius: 12px;
    > svg {
      position: absolute;
      top: 21px;
      left: 33px;
      width: 29px;
      height: 36px;
    }
    > div {
      position: absolute;
      top: 59px;
      left: 33px;
      line-height: 24px;
      font-size: 14px;
      color: ${theme.colors.white};
      font-weight: ${theme.fontWeight.medium};
    }
  }
  p {
    margin-left: 16px;
    font-size: ${theme.sizes.sm};
    font-weight: ${theme.fontWeight.medium};
    color: #4f4f4f;
    text-align: left;
  }
  > svg {
    position: absolute;
    top: -9px;
    left: -9px;
  }
  ${theme.media.tablet} {
    width: 300px;
  }
`;

export const PrimaryText = styled.div`
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.icon};
  line-height: 22px;
`;

export const SecondaryText = styled(PrimaryText)`
  font-weight: ${theme.fontWeight.regular};
`;

export const WrapperDecription = styled.div`
  text-align: center;
  svg {
    margin-top: 18px;
    margin-bottom: 8px;
  }
`;
