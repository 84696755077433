import { IResponse } from "models";

export enum ActionType {
  GET_SETTING_DATA = "action/GET_SETTING_DATA",
  GET_SETTING_DATA_SUCCESS = "action/GET_SETTING_DATA_SUCCESS",
  GET_SETTING_DATA_ERROR = "action/GET_SETTING_DATA_ERROR",
  GET_SETTING_DATA_RESET = "action/GET_SETTING_DATA_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGetSettingDataResponse extends IResponse {
  result: {
    app_languages: any;
    city_info: {
      created_at: string;
      global_name: string;
      id: number;
      local_name: string;
      prefecture_id: number;
      updated_at: string;
    };
    is_disease_outbreak_graph_display: boolean;
    is_disease_outbreak_graph_display_for_patients: boolean;
    is_display_user_id: boolean;
    mental_check_infor: {
      is_big_check: boolean;
    };
    questionnaire_info: {
      is_questionnaire: boolean;
    };
    screen_displays: Screens[];
    temperature_managerment_info: ITemperatureManagement;
    message_delivery_infor: IMessageDelivery;
    time_zones: any;
    total_members: number;
    vaccine_info: {
      is_vaccine: boolean;
    };
    user_list_info: {
      user_list_column_positions: any[];
    };
  };
}

interface IMessageDelivery {
  is_auto_reminder_active: boolean;
  number_of_reminder: number;
  reminder_time_in_hours: number;
  reminder_time_in_minutes: number;
}

export interface ITemperatureManagement {
  id: number;
  temperature_show: boolean;
  company_user_send_notifications: any[];
  resend_temperature_notification: boolean;
  resend_notification_time: number;
  attendence_setting: boolean;
  family_condition_setting: boolean;
  pool_setting: boolean;
  is_alert_notify: boolean;
  is_action_check: boolean;
  is_marathon: boolean;
  is_small_check: boolean;
  app_language_id: number;
  time_zone_id: any;
  is_covid_question: boolean;
  is_influenza_queston: boolean;
  is_approve_absence: boolean;
  is_online_question: boolean;
  is_covid_question_close: boolean;
  is_covid_question_family_close: boolean;
  is_covid_question_positive: boolean;
  screen_display_id: any;
  is_consultation_request: boolean;
  dashboard_chart_order: string;
  is_create_user_sso: boolean;
}

interface Screens {
  id: number;
  name: string;
  screen_code: string;
  created_at: string;
  updated_at: string;
}
