import { ActionType, DataChartsResponse } from "models/bodyTemperature/charts";

export const getDataChartsAction = (payload: { date: string }) => {
  return {
    type: ActionType.DATA_CHARTS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DATA_CHARTS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DataChartsResponse) => {
  return {
    type: ActionType.DATA_CHARTS_SUCCESS,
    payload,
  };
};
