import React, { forwardRef } from "react";
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import styled from "styled-components";
import { theme } from "theme/theme";
import { CalendarIcon } from "icons";
import { localStorageHelper } from "utils";

registerLocale("ja", ja);
const dateFormat = "yyyy/MM/dd";

interface IDatePicker extends ReactDatePickerProps {
  type?: string;
  iconPosition?: "left" | "right";
}

const TextStyledFirst = styled.div(({ theme }) => ({
  padding: "5px 35px 7px 10px",
  borderRadius: 6,
  outline: "none",
  background: theme.colors.white,
  border: `1px solid ${theme.colors.border}`,
  height: 32,
  width: 207,
  textAlign: "left",
  "@media (min-width: 768px)": {
    width: 142,
  },
}));

const TextStyledSecond = styled.div(({ theme }) => ({
  padding: "5px 8px 6px 31px",
  background: theme.colors.white,
  border: `1px solid ${theme.colors.border}`,
  color: theme.colors.text.primary,
  borderRadius: 6,
  fontSize: theme.sizes.sm,
  lineHeight: "20px",
  height: 31,
  textAlign: "center",
}));

const WrapperDatePicker = styled.div`
  .react-datepicker {
    width: 264px;
    top: -10px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }
  .react-datepicker__input-container input {
    width: 120px;
    height: 32px;
    letter-spacing: 0.3px;
    border: ${({ theme }) => `1px solid ${theme.colors.border}`};
    border-radius: 8px;
    padding-left: 12px;
    color: ${({ theme }) => theme.colors.text.primary};
    &::placeholder {
      color: ${({ theme }) => theme.colors.icon};
    }
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__day {
    position: relative;
    font-size: ${({ theme }) => theme.sizes.sm};
    height: 22px;
    width: 22px;
    line-height: 22px;
    font-weight: normal;
    width: 36px;
    margin: 2px 0;
  }
  .react-datepicker__day div {
    height: 100%;
    width: 100%;
  }
  .react-datepicker__day--outside-month {
    color: #bdbdbd;
  }
  .react-datepicker__day:not(.react-datepicker__day--outside-month):hover {
    background-color: ${({ theme }) => theme.colors.textLink};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.white};
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    font-size: ${({ theme }) => theme.sizes.xs};
    color: ${({ theme }) => theme.colors.text.primary};
    padding: 0;
    border-bottom: 0;
  }
  .react-datepicker__current-month {
    height: 40px;
    padding: 13px 0px 10px;
    margin: 0px 16px;
    text-align: left;
    font-size: ${({ theme }) => theme.sizes.md};
    line-height: ${({ theme }) => theme.sizes.md};
    font-weight: bold;
    color: #4f4f4f;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.background}`};
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day-names {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font-weight: normal;
    font-size: ${({ theme }) => theme.sizes.sm};
  }
  .react-datepicker__day-name {
    margin: 8px 7px 0px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  .react-datepicker__day-name:first-child {
    color: ${({ theme }) => theme.colors.important};
  }
  .react-datepicker__day-name:last-child {
    color: ${({ theme }) => theme.colors.textLink};
  }
  .react-datepicker__month {
    margin: 0 4px 4px 4px;
  }
  .react-datepicker__week {
  }
  .react-datepicker__week:last-child {
    border-bottom: 0;
  }
  .react-datepicker__day--today {
    font-weight: normal;
  }
  .react-datepicker__navigation {
    top: 10px;
    width: 18px;
    height: 18px;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 50%;
  }
  .react-datepicker__day--highlighted {
    background-color: ${theme.colors.white};
    color: ${theme.colors.text.primary};
  }
  .react-datepicker__day.react-datepicker__day--highlighted::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: 5px;
    right: 6px;
    background: ${({ theme }) => theme.colors.important};
  }
  .react-datepicker__navigation-icon--next::before {
    left: -3px;
  }
  .react-datepicker__navigation-icon--previous::before {
    right: -3px;
  }
  .react-datepicker__navigation-icon::before {
    top: 13px;
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    border-color: ${({ theme }) => theme.colors.textLink};
  }
  .react-datepicker__navigation--previous {
    left: unset;
    right: 43px;
  }

  .react-datepicker__navigation--next {
    right: 16px;
  }
  .react-datepicker__navigation--previous:focus,
  .react-datepicker__navigation--next:focus,
  .react-datepicker__day--keyboard-selected:focus,
  .react-datepicker__day--selected:focus {
    outline: none;
  }
  .react-datepicker__day--selected {
    border-radius: 50% !important;
    background: ${theme.colors.textLink}!important;
    color: ${theme.colors.white}!important;
    width: 22px;
    margin: 0 7px;
  }

  .react-datepicker__day--range-start.react-datepicker__day--highlighted::after {
    right: -1px;
  }
  .react-datepicker__day--range-end.react-datepicker__day--highlighted::after {
    right: -1px;
  }
  .react-datepicker__day--in-selecting-range {
    background-color: ${theme.colors.white};
    color: ${theme.colors.text.primary};
  }
  .react-datepicker__day--in-range {
    background-color: #e0eef3;
    color: #000;
    border-radius: 0;
  }
  .react-datepicker__day--range-start {
    border-radius: 50% !important;
    background: ${theme.colors.textLink}!important;
    color: ${theme.colors.white}!important;
    width: 22px;
    margin: 0 7px;
  }
  .react-datepicker__day--range-start::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: -7px;
    background: ${theme.colors.textLink};
    opacity: 0.15;
  }
  .react-datepicker__day--range-end::before {
    content: "";
    position: absolute;
    width: 65%;
    height: 100%;
    right: 15px;
    background: ${theme.colors.textLink};
    opacity: 0.15;
  }
  .react-datepicker__day--range-end {
    border-radius: 50% !important;
    background: ${theme.colors.textLink}!important;
    color: ${theme.colors.white}!important;
    width: 22px;
    margin: 0 7px;
  }
  .react-datepicker__day--selected.react-datepicker__day--highlighted::after {
    right: -1px;
  }
  .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__month-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__quarter-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__year-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ) {
    background-color: #e0eef3;
  }
  ${theme.media.mobileM} {
    .react-datepicker__input-container input {
      width: 140px;
      font-size: 16px;
    }
  }
  ${theme.media.tablet} {
    .react-datepicker__input-container input {
      width: 145px;
      padding-left: 14px;
    }
  }
`;

export const Flex = styled.div`
  position: relative;
  .react-datepicker__input-container input {
    width: 217px;
    height: 40px;
  }
  ${theme.media.tablet} {
    display: flex;
    .react-datepicker__input-container input {
      width: 142px;
    }
  }
`;

const CalendarIconStyled = styled(CalendarIcon)`
  position: absolute;
  top: 5px;
`;

const CustomInputFirst = forwardRef<any, any>(({ value, onClick }, ref) => {
  return (
    <div style={{ position: "relative" }} onClick={onClick}>
      <TextStyledFirst>{value || "YYYY/MM/DD"}</TextStyledFirst>
      <CalendarIconStyled style={{ right: 8 }} />
    </div>
  );
});

const CustomInputSecond = forwardRef<any, any>(({ value, onClick }, ref) => {
  return (
    <div style={{ position: "relative" }} onClick={onClick}>
      <TextStyledSecond>{value}</TextStyledSecond>
      <CalendarIconStyled style={{ left: 8 }} />
    </div>
  );
});

CustomInputFirst.displayName = "CustomInputFirst";
CustomInputSecond.displayName = "CustomInputSecond";

const DatePickerCustom: React.FC<IDatePicker> = ({ iconPosition, ...rest }) => {
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const returnLocale = () => {
    if (currentLanguage === "ja") {
      return "ja";
    }
    return "en";
  };

  return (
    <WrapperDatePicker>
      <Flex>
        <DatePicker
          {...rest}
          locale={returnLocale()}
          dateFormat={dateFormat}
          placeholderText="YYYY/MM/DD"
          customInput={
            iconPosition === "right" ? (
              <CustomInputFirst />
            ) : (
              <CustomInputSecond />
            )
          }
        />
      </Flex>
    </WrapperDatePicker>
  );
};

export default DatePickerCustom;
