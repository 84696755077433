import { IResponse } from "models";

export enum ActionType {
  COMPLETE_REQUEST = "action/COMPLETE_REQUEST",
  COMPLETE_REQUEST_SUCCESS = "action/COMPLETE_REQUEST_SUCCESS",
  COMPLETE_REQUEST_ERROR = "action/COMPLETE_REQUEST_ERROR",
  COMPLETE_REQUEST_RESET = "action/COMPLETE_REQUEST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICompleteRequestPayload {
  id: number;
  status: number;
}

export interface ICompleteRequestResponse extends IResponse {}
