import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components";
import {
  ModalStyled,
  WrapperBtnFooter,
  WrapperBtnOKFooter,
  FailModalStyled,
} from "./ChangeStudentAffiliation.style";

interface IModal {
  handleCancel?: () => void;
  handleOk?: () => void;
  isVisible?: boolean;
  type?: string;
}

const SecondConfirmModal: React.FC<IModal> = ({
  handleCancel,
  handleOk,
  isVisible,
  children,
  type,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const SuccessModal = () => (
    <ModalStyled
      type="confirm"
      footer={
        <WrapperBtnOKFooter>
          <Button
            onClick={handleOk}
            background="#2AC769"
            color="#fff"
            name="OK"
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />
        </WrapperBtnOKFooter>
      }
      visible={isVisible}
    >
      {children}
    </ModalStyled>
  );

  const FailModal = () => (
    <FailModalStyled
      type="confirm"
      footer={
        <WrapperBtnFooter>
          <Button
            onClick={handleCancel}
            background="#E0E0E0"
            color="#fff"
            name={t("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />
          <Button
            onClick={handleOk}
            background="#FB2121"
            color="#fff"
            name="OK"
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />
        </WrapperBtnFooter>
      }
      visible={isVisible}
    >
      {children}
    </FailModalStyled>
  );

  return type === "success" ? <SuccessModal /> : <FailModal />;
};

export default SecondConfirmModal;
