import { RootState } from "../../rootReducer";

export const questionnaireUpdated = (state: RootState) =>
  state.updateQuestionnaire.saved;
export const selectIsUpdateQuestionnaire = (state: RootState) =>
  state.updateQuestionnaire.loading;
export const updateQuestionnaireErrorMessage = (state: RootState) =>
  state.updateQuestionnaire.error;
export const updateQuestionnaireDataResponse = (state: RootState) =>
  state.updateQuestionnaire.updateQuestionnaireResponse;
