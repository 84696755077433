import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  GetSlotsData,
  ActionType,
  SlotsDataResponse,
} from "models/vaccine/slots";
import { httpStatus } from "configs/httpStatus";
import { fetchSlotsData } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./slotsActions";

function* slotsSaga({ payload }: { payload?: GetSlotsData }) {
  try {
    const response: SlotsDataResponse = yield call(fetchSlotsData, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetSlotsWatcher() {
  yield takeLatest(ActionType.SLOTS as any, slotsSaga);
}

export default [fork(onGetSlotsWatcher)];
