import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "components";

import NamePrincipleSetting from "./NamePrincipleSetting";
import TreeView from "./TreeView";
import { Wrapper } from "./ClassManagement.style";
import {
  CreateBulkClassActionCreators,
  SchoolClassListActionCreators,
} from "redux/rootActions";
import { classListDataResponse } from "redux/classmanagement/getclasses/classesListStates";
import { createdClassListResponse } from "redux/classmanagement/createclasses/createClassStates";
import { ISchoolClasses } from "models/classmanagement/classList";
import { ICreateBulkClassData } from "models/classmanagement/createBulkClasses";
import { createBulkClassDataResponse } from "redux/classmanagement/createbulkclasses/createBulkClassStates";

const ClassManagement: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "layout" });

  const [treeData, setTreeData] = useState<ISchoolClasses[]>([]);
  const dispatch = useDispatch();

  const schoolClassList = useSelector(classListDataResponse);
  const createBulkClassSuccess = useSelector(createBulkClassDataResponse);
  const createClassSuccess = useSelector(createdClassListResponse);

  useEffect(() => {
    dispatch(SchoolClassListActionCreators.classListAction());
  }, []);

  useEffect(() => {
    if (schoolClassList && schoolClassList.result) {
      setTreeData(
        schoolClassList?.result.map((item: any) => {
          return {
            ...item,
            level: "1",
          };
        })
      );
    }
  }, [schoolClassList]);

  useEffect(() => {
    dispatch(SchoolClassListActionCreators.classListAction());
  }, [createBulkClassSuccess, createClassSuccess]);

  const handlePrincipleSetting = (data: { [x: string]: any }) => {
    const params: ICreateBulkClassData = {
      class: {
        school_type: data.schoolClassification,
        class_name_type: data.namePrinciple,
        num_of_section: data.numberOfClasses,
      },
    };
    dispatch(CreateBulkClassActionCreators.createBulkClassAction(params));
  };

  return (
    <Box title={t("class-management-label")}>
      <Wrapper>
        {!treeData?.length ? (
          <NamePrincipleSetting
            handlePrincipleSetting={handlePrincipleSetting}
          />
        ) : (
          <TreeView treeData={treeData} setTreeData={setTreeData} />
        )}
      </Wrapper>
    </Box>
  );
};

export default ClassManagement;
