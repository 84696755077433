import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  SymtomResponse,
  ActionType,
  SymtomPayload,
} from "models/dashboard/symtom";
import { httpStatus } from "configs/httpStatus";
import { fetchSymtomChart } from "services/dashboard";
import { handleSuccesAction, handleErrorAction } from "./symtomActions";

function* getSymtomSaga({ payload }: { payload: SymtomPayload }) {
  try {
    const response: SymtomResponse = yield call(fetchSymtomChart, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetSymtomWatcher() {
  yield takeLatest(ActionType.GET_SYMTOM_CHART as any, getSymtomSaga);
}

export default [fork(onGetSymtomWatcher)];
