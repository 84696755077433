import {
  Action,
  ActionType,
  ICreateActivityResponse,
} from "models/activitymanagement/createActivity";
import createReducer from "../../createReducer";

export interface CreateActivityReducerType {
  loading: boolean;
  error?: string;
  createActivityResponse?: ICreateActivityResponse;
  saved: boolean;
}

const defaultState: CreateActivityReducerType = {
  loading: false,
  error: undefined,
  createActivityResponse: undefined,
  saved: false,
};

export const createActivityReducer = createReducer<CreateActivityReducerType>(
  defaultState,
  {
    [ActionType.CREATE_ACTIVITY](state: CreateActivityReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_ACTIVITY_ERROR](
      state: CreateActivityReducerType,
      action: Action<any>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      };
    },

    [ActionType.CREATE_ACTIVITY_SUCCESS](
      state: CreateActivityReducerType,
      action: Action<any>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        saved: true,
        createActivityResponse: action.payload,
      };
    },

    [ActionType.CREATE_ACTIVITY_RESET](
      state: CreateActivityReducerType,
      action: Action<any>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        saved: false,
        createActivityResponse: action.payload,
      };
    },
  }
);
