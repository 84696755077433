import { put, takeLatest, fork, call } from "redux-saga/effects";
import { httpStatus } from "configs/httpStatus";
import { fetchCSVStudentDataList } from "services/classchangemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./getDownloadCSVStudentListActions";
import {
  ActionType,
  ICSVDownloadStudentListData,
  ICSVDownloadStudentListResponse,
} from "models/classchangemanagement/getCSVStudentListData";

function* getCSVDownloadStudentListSaga({
  payload,
}: {
  payload: ICSVDownloadStudentListData;
}) {
  try {
    const response: ICSVDownloadStudentListResponse = yield call(
      fetchCSVStudentDataList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({ ...response, result: { data: [], headers: [] } })
      );
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetCSVDownloadStudentListWatcher() {
  yield takeLatest(
    ActionType.GET_CSV_DOWNLOAD_STUDENT_LIST as any,
    getCSVDownloadStudentListSaga
  );
}

export default [fork(onGetCSVDownloadStudentListWatcher)];
