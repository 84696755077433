import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { Box, Button, Select, Pagination, Input, Loading } from "components";
import {
  DownloadIcon,
  NextIcon,
  RedAttentionIcon,
  InformationIcon,
} from "icons";
import {
  generateDynamicRecords,
  localStorageHelper,
  generateCSV,
  convertRawDataToOptions,
  convertFilename,
} from "utils";
import { JUST_ENTER_NUMBER_REGEX } from "constant";
import { httpStatus } from "configs/httpStatus";
import { IOption } from "components/select/Select";
import { useScroll } from "shared-hooks";

import { classListDataResponse } from "redux/classlist/classListStates";
import {
  ClassListActionCreators,
  GetDownloadCSVStudentListActionCreators,
  GetStudentClassChangeActionCreators,
  SaveTemporaryStudentListActionCreators,
  GetClassListActionCreators,
} from "redux/rootActions";
import { IStudentListClassChangeData } from "models/classchangemanagement/studentListClassChange";
import {
  studentListClassChangeResponse,
  selectIsLoading as isLoadingStudentList,
} from "redux/classchangemanagement/getStudentData/getStudentListClassChangeStates";
import {
  saveTemporaryClassChangeResponse,
  selectIsSaved,
} from "redux/classchangemanagement/saveTemporary/saveTemporaryStates";
import { ICSVDownloadStudentListData } from "models/classchangemanagement/getCSVStudentListData";
import {
  csvDownloadStudentListResponse,
  selectIsLoading,
} from "redux/classchangemanagement/getCSVDownloadStudentList/getDownloadCSVStudentListStates";
import { ISaveTemporaryClassChangeRequest } from "models/classchangemanagement/saveTemporary";
import { getClassListResponse } from "redux/classchangemanagement/getClassList/getClassListStates";

import {
  WrapperClassSelect,
  CollapseStyled,
  WrapperUpdating,
  CSVUpdating,
  WrapperTable,
  TopTable,
  MiddleTable,
  WrapperSizeChange,
  WrapperPaginationTop,
  WrapperIcon,
  FileUpdating,
  WrapperPaginationBottom,
  TableStyled,
  WrapperBtn,
  TextLink,
  NoDataMessage,
  WrapperErrorMessage,
  WrapText,
  WapperError,
  WrapperProgressBar,
  ContainerProgressBar,
  WrapperTooltipRight,
  TooltipRightStyled,
} from "./ChangeStudentAffiliation.style";
import { FileUploader, LoadingDots } from "./components";
import SecondConfirmModal from "./SecondConfirmModal";
import ConfirmModal from "./ConfirmModal";

const ChangeAffiliation = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });
  const { t: t2 } = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t3 } = useTranslation("translation", {
    keyPrefix: "common",
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const boxShadowBtn = { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)" };
  const saveBtnRef = useRef<any>(undefined);
  const csvDownloadInstance = useRef<any | null>(null);
  const unblockHandle = useRef<any>();
  const scrollAbleRef = useRef<any>(undefined);

  const currentClassListResponse = useSelector(classListDataResponse);
  const newClassListResponse = useSelector(getClassListResponse);
  const isLoading = useSelector(selectIsLoading);
  const isLoadingTable = useSelector(isLoadingStudentList);
  const isSaved = useSelector(selectIsSaved);
  const [downloadAll, setDownloadAll] = useState<boolean>(false);
  const studentListResponse = useSelector(studentListClassChangeResponse);
  const saveTemporaryResponse = useSelector(saveTemporaryClassChangeResponse);
  const csvDataStudentListResponse = useSelector(
    csvDownloadStudentListResponse
  );

  const [target, setTargetLocation] = useState<any>("");
  const [currentClassOptions, setCurrentClassOptions] = useState<IOption[]>([]);
  const [newClassOptions, setNewClassOptions] = useState<IOption[]>([]);
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [isBoxShadow, setIsBoxShadow] = useState(false);
  const [fileCSV, setFileCSV] = useState<any>({});
  const [studentList, setStudentList] = useState<any>([]);
  const [disableInputs, setDisableInputs] = useState<any>([]);
  const [isVisible, setIsVisible] = useState({
    type: "",
    status: false,
  });
  const [studentListParams, setStudenListParams] = useState<any>([]);
  const [isClickedSearch, setIsClickedSearch] = useState<boolean>(false);
  const [isShowNoData, setIsShowNoData] = useState<boolean>(false);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [csvHeaderData, setCSVHeaderData] = useState<any>([]);
  const [csvDownloadData, setCSVDownloadData] = useState<any>([]);
  const [nameCSVFile, setNameCSVFile] = useState("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<any>({});
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);
  const [valueSelectForAllGrade, setValueSelectForAllGrade] =
    useState<any>(null);
  const [input, setInput] = useState({
    page: 1,
    sizeChange: 50,
    classID: 0,
  });
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  useScroll(saveBtnRef, setIsBoxShadow, 133);

  useEffect(() => {
    setIsVisible({
      status: false,
      type: "",
    });
    setDownloadAll(false);
    setIsShowLoader(false);
    dispatch(
      GetClassListActionCreators.getClassListAction({
        locale: currentLanguage,
      })
    );
  }, []);

  useEffect(() => {
    setDownloadAll(isLoading);
  }, [isLoading]);

  const [isVisibleThirdModal, setIsVisibleThirdModal] =
    useState<boolean>(false);
  const studentNewClassId = useMemo(() => {
    const newClassId = {} as any;

    if (!studentListParams.length) return newClassId;

    for (const student of studentListParams) {
      newClassId[student.patient_id] = student.new_class_id;
    }

    return newClassId;
  }, [studentListParams]);

  const handleOnChangeNewGrade =
    (patientID: number, newRollNumber: number | null) => (value: number) => {
      const isStudentExisted = studentListParams.find(
        (student: any) => student.patient_id === patientID
      );
      if (isStudentExisted) {
        setStudenListParams(
          studentListParams.map((student: any) => {
            if (student.patient_id === patientID) {
              return {
                ...student,
                patient_id: patientID,
                new_class_id: Number(value),
              };
            }
            return student;
          })
        );
      } else {
        setStudenListParams((prevState: any) => [
          ...prevState,
          {
            patient_id: patientID,
            new_class_id: Number(value),
            new_roll_number: newRollNumber,
          },
        ]);
      }

      setStudentList(
        studentList.map((student: any) => {
          if (student.id === patientID) {
            return {
              ...student,
              new_class_id: Number(value),
            };
          }
          return student;
        })
      );
      if (disableInputs.indexOf(patientID) == -1) {
        setDisableInputs((prevData: any) => [...prevData, patientID]);
      }
    };

  const handleChangeNewRollNumber =
    (patientID: number, newClassId: number | null) => (e: any) => {
      if (
        e.target.value === "" ||
        JUST_ENTER_NUMBER_REGEX.test(e.target.value)
      ) {
        const valueConverted = !e.target.value ? "" : Number(e.target.value);
        studentList.forEach((item: any) => {
          if (item.id === patientID) {
            item.new_roll_number = valueConverted;
          }
        });

        const isStudentExisted = studentListParams.find(
          (student: any) => student.patient_id === patientID
        );
        if (isStudentExisted) {
          setStudenListParams(
            studentListParams.map((student: any) => {
              if (student.patient_id === patientID) {
                return {
                  ...student,
                  patient_id: patientID,
                  new_roll_number: valueConverted,
                };
              }
              return student;
            })
          );
        } else {
          setStudenListParams((prevState: any) => [
            ...prevState,
            {
              patient_id: patientID,
              new_roll_number: valueConverted,
              new_class_id: newClassId,
            },
          ]);
        }
      }
    };

  const handleOnChangeAllGrade = (value: any) => {
    setValueSelectForAllGrade(value);
    const studentListClone = [...studentListParams];

    setStudentList(
      studentList.map((student: any) => {
        return {
          ...student,
          new_class_id: Number(value),
        };
      })
    );

    for (let i = 0; i < studentList.length; i++) {
      if (studentList[i].id === studentListClone[i]?.patient_id) {
        studentListClone[i] = {
          ...studentListClone[i],
          new_class_id: value,
        };
      } else {
        studentListClone.push({
          patient_id: studentList[i].id,
          new_class_id: value,
          new_roll_number: studentList[i].new_roll_number,
        });
      }
    }

    setStudenListParams(studentListClone);
    setDisableInputs(
      studentList.map((student: any) => {
        return student.id;
      })
    );
    setShowErrors(
      studentList.map((student: any) => {
        return {
          [student.id]: true,
        };
      })
    );
  };

  const handlerClickInput = (value: any) => {
    setShowErrors((prevData: any) => ({ ...prevData, [value]: true }));
    setTimeout(() => {
      setShowErrors((prevData: any) => ({ ...prevData, [value]: false }));
    }, 2000);
  };

  const columns = [
    {
      title: t("full-name"),
      dataIndex: "full_name",
      key: "displayName",
      width: 191,
      fixed: window.innerWidth < 576 ? "" : "left",
    },
    {
      title: () => {
        return (
          <div>
            {t1("current")}
            <br /> {t("grade")}
          </div>
        );
      },
      dataIndex: "current_class",
      key: "current_class",
      width: 128,
    },
    {
      title: () => {
        return (
          <div>
            {t1("current")}
            <br /> {t("attendance")}
          </div>
        );
      },
      dataIndex: "current_roll_number",
      key: "current_roll_number",
      width: 116,
    },
    {
      title: () => {
        return (
          <div
            style={{
              position: "relative",
              color: "#2F8CAE",
              fontWeight: "bold",
            }}
          >
            <WrapperIcon>
              <NextIcon fill={"#2F8CAE"} />
            </WrapperIcon>
            <p style={{ margin: "0px 0px 10px" }}>
              {t1("after-change")}
              <br /> {t("grade")}
            </p>
            <Select
              value={valueSelectForAllGrade}
              placeholder={t("please-select")}
              options={newClassOptions}
              width="156px"
              height="30px"
              onSelect={handleOnChangeAllGrade}
            />
          </div>
        );
      },
      dataIndex: "new_class_id",
      key: "new_class_id",
      width: 220,
      render: (text: any, record: any) => {
        return (
          <div style={{ width: "max-content", margin: "0px auto" }}>
            <Select
              value={text}
              placeholder={t("please-select")}
              options={newClassOptions}
              width="156px"
              height="30px"
              onChange={handleOnChangeNewGrade(
                record.id,
                record.new_roll_number
              )}
            />
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <TextLink>
            {t1("after-change")}
            <br /> {t("attendance")}
          </TextLink>
        );
      },
      dataIndex: "new_roll_number",
      key: "new_roll_number",
      width: 188,
      render: (text: any, record: any) => (
        <div onClick={() => handlerClickInput(record.id)}>
          <Input
            value={text === null ? "" : text}
            width={156}
            height={30}
            fw={400}
            disabled={
              disableInputs.indexOf(record.id) == -1 && !record.new_class_id
            }
            onChange={handleChangeNewRollNumber(record.id, record.new_class_id)}
            placeholder={t1("please-enter-half-width-number")}
          />
          {showErrors[record.id] &&
            disableInputs.indexOf(record.id) == -1 &&
            !record.new_class_id && (
              <WapperError>
                <p
                  className={
                    studentList[studentList.length - 1].id == record.id
                      ? "last-p"
                      : ""
                  }
                >
                  {t1("please-select-the-grade-class-first")}
                </p>
              </WapperError>
            )}
        </div>
      ),
    },
    {
      title: t1("last-modified"),
      dataIndex: "last_updated_at",
      key: "last_updated_at",
      render: (text: any) => {
        const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
        return moment
          .utc(text)
          .add(currentTimeZone, "hours")
          .format("YYYY/MM/DD  HH:mm:ss");
      },
    },
  ];

  const getClassList = useCallback(() => {
    const params: IStudentListClassChangeData = {
      class_id: input.classID,
      page: input.page,
      per_page: input.sizeChange,
      locale: currentLanguage,
    };
    dispatch(
      GetStudentClassChangeActionCreators.getStudentListClassChangeAction(
        params
      )
    );
    setIsSearching(false);
  }, [input]);

  useEffect(() => {
    if (isSaved) {
      setIsShowLoader(false);
    }
  }, [isSaved]);

  useEffect(() => {
    if (isSearching) {
      getClassList();
    }
  }, [getClassList]);

  useEffect(() => {
    if (isSaved) {
      setIsShowLoader(false);
    }
  }, [isSaved]);

  const handleShowThirdModal = () => {
    setIsVisibleThirdModal(true);
  };

  const handleOk = () => {
    setIsVisible({
      status: false,
      type: "",
    });
    setStudenListParams([]);
    setIsShowLoader(false);
    dispatch(SaveTemporaryStudentListActionCreators.handleResetDataAction());
    getClassList();
  };

  const handleCancel = () => {
    setIsVisible({
      status: false,
      type: "",
    });
    setStudenListParams([]);
    setIsShowLoader(false);
  };

  const handleConfirm = () => {
    if (unblockHandle) {
      unblockHandle.current();
    }
    history.push(target);
  };

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
    setIsSearching(true);
    if (
      scrollAbleRef.current &&
      window.scrollY >= scrollAbleRef.current.offsetTop
    ) {
      window.scrollTo(0, scrollAbleRef.current.offsetTop);
    }
  };

  const handleOnChangeClass = (classID: number) => {
    setInput((prevState: any) => ({ ...prevState, classID }));
  };

  const handleOnChangeSizeChange = (sizeChange: number) => {
    setInput((prevState: any) => ({ ...prevState, sizeChange, page: 1 }));
    setIsSearching(true);
  };

  const handleCSVDownload = () => {
    if (input.classID) {
      const params: ICSVDownloadStudentListData = {
        class_id: input.classID,
        locale: currentLanguage,
      };
      const filename = `${convertFilename("student", new Date())}.csv`;
      setNameCSVFile(filename);
      dispatch(
        GetDownloadCSVStudentListActionCreators.getDownloadCSVStudentListAction(
          params
        )
      );
    }
  };

  const handleAllGradeCSVDownload = () => {
    const filename = `${convertFilename("all-student", new Date())}.csv`;
    setNameCSVFile(filename);
    dispatch(
      GetDownloadCSVStudentListActionCreators.getDownloadCSVStudentListAction({
        locale: currentLanguage,
      })
    );
  };

  const handleSaveTemporary = () => {
    const params: ISaveTemporaryClassChangeRequest = {
      student_list: studentListParams,
    };
    dispatch(
      SaveTemporaryStudentListActionCreators.saveTemporaryStudentClassChangeAction(
        params
      )
    );
    setIsShowLoader(true);
  };
  const handleSearch = () => {
    setValueSelectForAllGrade(null);
    const params: IStudentListClassChangeData = {
      class_id: input.classID,
      page: 1,
      per_page: 50,
      locale: currentLanguage,
    };
    setInput((prevState: any) => ({ ...prevState, page: 1, per_page: 50 }));
    setIsClickedSearch(true);
    dispatch(
      GetStudentClassChangeActionCreators.getStudentListClassChangeAction(
        params
      )
    );
  };

  const handleOnChangeFile = async (csv: any) => {
    const isExisted =
      fileCSV.name === csv.fileList[csv.fileList.length - 1].name;
    if (!isExisted) {
      setFileCSV({
        file: csv.fileList[csv.fileList.length - 1].originFileObj,
        name: csv.fileList[csv.fileList.length - 1].name,
      });
    }
  };

  useEffect(() => {
    if (studentListParams.length > 0) {
      unblockHandle.current = history.block((targetLocation: any) => {
        handleShowThirdModal();
        setTargetLocation(targetLocation);
        return false;
      });
    }

    return () => {
      unblockHandle.current && unblockHandle.current();
    };
  }, [studentListParams]);

  useEffect(() => {
    if (studentListParams.length && studentListResponse?.result) {
      setStudentList(
        studentListResponse.result.map((student) => {
          if (studentNewClassId[student.id]) {
            return {
              ...student,
              new_class_id: studentNewClassId[student.id],
            };
          }
          return student;
        })
      );
    } else {
      setStudentList(studentListResponse?.result || []);
    }
    setTotalRecord(studentListResponse?.total_record || 0);

    let recordOptionsClone: IOption[] = [];
    recordOptionsClone = generateDynamicRecords(
      studentListResponse?.total_record,
      t
    );
    setRecordOptions([...recordOptionsClone]);

    if (!studentListResponse?.result && isClickedSearch && input.classID) {
      setIsShowNoData(true);
    } else {
      setIsShowNoData(false);
    }
  }, [studentListResponse]);

  useEffect(() => {
    if (
      csvHeaderData.length > 0 &&
      csvDownloadData.length > 0 &&
      csvDownloadInstance &&
      csvDownloadInstance.current &&
      csvDownloadInstance.current.link
    ) {
      setTimeout(() => {
        csvDownloadInstance.current.link.click();
        setCSVHeaderData([]);
        setCSVDownloadData([]);
        dispatch(
          GetDownloadCSVStudentListActionCreators.handleResetDataAction()
        );
      });
    }
  }, [csvHeaderData, csvDownloadData]);

  useEffect(() => {
    if (csvDataStudentListResponse && csvDataStudentListResponse.result) {
      generateCSV(
        nameCSVFile,
        csvDataStudentListResponse.result.headers,
        csvDataStudentListResponse.result.data
      );

      dispatch(GetDownloadCSVStudentListActionCreators.handleResetDataAction());
    }
  }, [csvDataStudentListResponse]);

  useEffect(() => {
    if (saveTemporaryResponse?.status === httpStatus.StatusCreated) {
      setIsVisible({
        type: "success",
        status: true,
      });
    }
    if (
      saveTemporaryResponse?.status === httpStatus.StatusUnprocessableEntity
    ) {
      setIsVisible({
        type: "fail",
        status: true,
      });
      dispatch(SaveTemporaryStudentListActionCreators.handleResetDataAction());
    }
  }, [saveTemporaryResponse]);

  useEffect(() => {
    if (currentClassListResponse && currentClassListResponse?.result) {
      const classOptionsClone = convertRawDataToOptions(
        currentClassListResponse?.result
      );
      setCurrentClassOptions(classOptionsClone.filter((item) => item.id));
    }
  }, [currentClassListResponse]);

  useEffect(() => {
    if (newClassListResponse && newClassListResponse?.result) {
      const classOptionsClone = convertRawDataToOptions(
        newClassListResponse?.result
      );
      setNewClassOptions(classOptionsClone);
    }
  }, [newClassListResponse]);

  useEffect(() => {
    dispatch(GetStudentClassChangeActionCreators.handleResetDataAction());
    dispatch(
      ClassListActionCreators.classListAction({ locale: currentLanguage })
    );
  }, []);

  const RenderPagination = () => {
    return (
      <Pagination
        current={input.page}
        onChange={handleChangePage}
        pageSize={input.sizeChange}
        total={studentListResponse?.total_record}
        showSizeChanger={false}
      />
    );
  };

  const InfoIcon = (
    <div style={{ position: "relative", cursor: "pointer" }}>
      <InformationIcon
        offset="4"
        onClick={() => {
          window.open("https://youtu.be/CQPqAR96WlI", "_blank");
        }}
        onMouseOver={() => setIsShowTooltip(true)}
        onMouseOut={() => setIsShowTooltip(false)}
      />
      {isShowTooltip && (
        <WrapperTooltipRight>
          <TooltipRightStyled>{t3("how-to-operate")}</TooltipRightStyled>
        </WrapperTooltipRight>
      )}
    </div>
  );

  return (
    <Box title={t1("change-affiliation-title")} icon={InfoIcon} padding="16px">
      {isShowLoader && (
        <WrapperProgressBar>
          <ContainerProgressBar>
            <Loading />
          </ContainerProgressBar>
        </WrapperProgressBar>
      )}
      <WrapperClassSelect>
        <p>{t1("select-grade-you-want-to-change")}</p>
        <div>
          <Select
            placeholder={t("please-select")}
            options={currentClassOptions}
            width="156px"
            onChange={handleOnChangeClass}
          />
          <Button
            name={t1("display")}
            padding="3.5px 16px 4.5px"
            background="#2AC769"
            bdr="6px"
            border="none"
            color="#FFFFFF"
            fontSize={16}
            lineHeight="23px"
            fontWeight={700}
            onClick={handleSearch}
          />
        </div>
      </WrapperClassSelect>
      <CollapseStyled
        header={t1("update-use-csv-file")}
        expandIconPosition="right"
        defaultActiveKey={["1"]}
      >
        <WrapperUpdating>
          <CSVUpdating>
            <p>{t1("download-edit-upload-description")}</p>
            <Button
              icon={
                <DownloadIcon
                  width="20px"
                  height="20px"
                  fill="currentColor"
                  style={{ position: "relative", top: 3, marginRight: 3 }}
                />
              }
              name={t1("display-data-download")}
              background="#2AC769"
              bdr="8px"
              border="none"
              color="#FFFFFF"
              fontSize={16}
              lineHeight="16px"
              fontWeight={700}
              onClick={handleCSVDownload}
              disabled={!studentList.length}
            />
            <div
              style={{ position: "relative" }}
              onClick={handleAllGradeCSVDownload}
            >
              <span style={{ opacity: downloadAll ? 0.16 : 1 }}>
                {t1("download-data-all-grades")}
              </span>
              {downloadAll && <LoadingDots />}
              {downloadAll && (
                <div className="download-all-tooltip">
                  {t1("download-all-tooltip")}
                </div>
              )}
            </div>
          </CSVUpdating>
          <FileUpdating>
            <FileUploader
              fileCSV={fileCSV}
              setFileCSV={setFileCSV}
              handleOnChangeFile={handleOnChangeFile}
            />
          </FileUpdating>
        </WrapperUpdating>
      </CollapseStyled>
      <WrapperTable ref={scrollAbleRef}>
        <TopTable>
          <div>{t1("update-from-display-data")}</div>
        </TopTable>
        <WrapperBtn ref={saveBtnRef}>
          <div style={isBoxShadow ? { ...boxShadowBtn } : {}}>
            <Button
              name={t1("temporary-save-changes")}
              background="#2AC769"
              bdr="6px"
              border="none"
              color="#FFFFFF"
              fontSize={16}
              lineHeight="16px"
              fontWeight={700}
              disabled={studentListParams.length == 0}
              onClick={handleSaveTemporary}
            />
          </div>
        </WrapperBtn>
        <MiddleTable>
          <WrapperSizeChange>
            <div style={{ fontWeight: 500 }}>{t("record")}</div>
            <Select
              defaultValue={50}
              options={recordOptions}
              onChange={handleOnChangeSizeChange}
            />
          </WrapperSizeChange>
          <WrapperPaginationTop>
            {totalRecord > input.sizeChange && <RenderPagination />}
          </WrapperPaginationTop>
        </MiddleTable>
        <TableStyled
          columns={columns}
          dataSource={studentList}
          scroll={{ x: "max-content" }}
          loading={isLoadingTable}
        />
        {(!input.classID || !isClickedSearch) && (
          <NoDataMessage>{t1("no-data-message")}</NoDataMessage>
        )}
        {isShowNoData && (
          <NoDataMessage>{t1("no-data-message-list")}</NoDataMessage>
        )}
        <WrapperPaginationBottom>
          {totalRecord > input.sizeChange && <RenderPagination />}
        </WrapperPaginationBottom>
      </WrapperTable>
      {isVisible.status && (
        <SecondConfirmModal
          type={isVisible.type}
          isVisible={isVisible.status}
          handleOk={handleOk}
          handleCancel={handleCancel}
        >
          {isVisible.type === "success" ? (
            <div>
              データを保存しました。保存したデータは、「一時保存データの確認」ページで確認いただけます。
            </div>
          ) : (
            <WrapperErrorMessage>
              <div>
                <RedAttentionIcon width="42px" height="42px" />
              </div>
              <WrapText>
                <span>
                  一時保存したデータから、変更された データがあります。
                </span>
                <span>
                  元のデータを確認・ 修正後、再度一時保存をしてください。
                </span>
              </WrapText>
            </WrapperErrorMessage>
          )}
        </SecondConfirmModal>
      )}
      {isVisibleThirdModal && (
        <ConfirmModal
          isVisible={isVisibleThirdModal}
          handleCancel={() => setIsVisibleThirdModal(false)}
          handleOk={handleConfirm}
        >
          <div>{t1("leave-page-before-save")}</div>
        </ConfirmModal>
      )}
    </Box>
  );
};

export default ChangeAffiliation;
