import {
  ActionType,
  DeleteMentalCheckData,
  IDeleteMentalCheckResponse,
} from "models/mentalHealth/deleteMentalCheck";

export const deleteMentalCheckAction = (payload: DeleteMentalCheckData) => {
  return {
    type: ActionType.DELETE_MENTAL_CHECK,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_MENTAL_CHECK_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IDeleteMentalCheckResponse) => {
  return {
    type: ActionType.DELETE_MENTAL_CHECK_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.RESET_DELETE_MENTAL_CHECK,
  };
};
