import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { updateDeseaseSetting } from "services/setting";
import {
  ActionType,
  IUpdateDeseasePayload,
  IUpdateDeseaseResponse,
} from "models/setting/updateDeseaseOutbreak";

import { handleSuccessAction, handleErrorAction } from "./updateDeseaseActions";

function* updateDeseaseSettingSaga({
  payload,
}: {
  payload: IUpdateDeseasePayload;
}) {
  try {
    const response: IUpdateDeseaseResponse = yield call(
      updateDeseaseSetting,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateDeseaseSettingWatcher() {
  yield takeLatest(
    ActionType.UPDATE_DESEASE_SETTING as any,
    updateDeseaseSettingSaga
  );
}

export default [fork(onUpdateDeseaseSettingWatcher)];
