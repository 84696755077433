import {
  ActionType,
  IAddRequestResponse,
  IAddRequestPayload,
} from "models/consultationRequest/addRequest";

export const addRequestAction = (payload: IAddRequestPayload) => {
  return {
    type: ActionType.ADD_REQUEST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.ADD_REQUEST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IAddRequestResponse) => {
  return {
    type: ActionType.ADD_REQUEST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.ADD_REQUEST_RESET,
  };
};
