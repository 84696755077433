import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType, IVenuesListResponse } from "models/vaccine/venuesList";
import { httpStatus } from "configs/httpStatus";
import { fetchVenuesList } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./listActions";

function* venuesListSaga() {
  try {
    const response: IVenuesListResponse = yield call(fetchVenuesList);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onVenuesListWatcher() {
  yield takeLatest(ActionType.VACCINE_VENUES_LIST as any, venuesListSaga);
}

export default [fork(onVenuesListWatcher)];
