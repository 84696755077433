import { IResponse } from "models";

export enum ActionType {
  // download csv file TypeInfo list
  CSV_DOWNLOAD_TYPE_INFO = "action/CSV_DOWNLOAD_TYPE_INFO",
  CSV_DOWNLOAD_TYPE_INFO_SUCCESS = "action/CSV_DOWNLOAD_TYPE_INFO_SUCCESS",
  CSV_DOWNLOAD_TYPE_INFO_ERROR = "action/CSV_DOWNLOAD_TYPE_INFO_ERROR",
  CSV_DOWNLOAD_TYPE_INFO_RESET_DATA = "action/CSV_DOWNLOAD_TYPE_INFO_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface CSVDownloadTypeInfoResponse extends IResponse {
  result?: ICSVDownloadResult;
}

export interface ICSVDownloadResult {
  data: ICSVDownloadTypeInfoItem[];
  headers: IHeaderCSVDownloadTypeInfoItem[];
}
export interface ITypeInfoDownloadData {
  mental_check_type_id: number;
  mental_check_request_id: number;
  grade: number;
  refine_criteria_type: any;
}

export interface IHeaderCSVDownloadTypeInfoItem {
  label: string;
  key: string;
}

export interface ICSVDownloadTypeInfoItem {
  id: number;
  name: string;
  grade: string;
  date_of_implementation: string;
  status: string;
  mental_physical_status: string;
  mood: string;
  concentration: string;
  behaviour: string;
  relation_ship: string;
  gender: string;
}
