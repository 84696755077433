import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType, ICreateVenuesResponse } from "models/vaccine/createVenues";
import { httpStatus } from "configs/httpStatus";
import { createVenues } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./createVenuesActions";

function* createVenuesSaga({ payload }: { payload: any }) {
  try {
    const response: ICreateVenuesResponse = yield call(createVenues, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCreateVenuesWatcher() {
  yield takeLatest(ActionType.VACCINE_CREATE_VENUES as any, createVenuesSaga);
}

export default [fork(onCreateVenuesWatcher)];
