import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  TypeInfoData,
  ActionType,
  IMentalCheckTypeInfoResponse,
} from "models/mentalHealth/typeInformation";
import { httpStatus } from "configs/httpStatus";
import { getMentalCheckTypeInfoRequests } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./typeInformationActions";

function* checkRequestTypeInfoSaga({ payload }: { payload: TypeInfoData }) {
  try {
    const response: IMentalCheckTypeInfoResponse = yield call(
      getMentalCheckTypeInfoRequests,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetCheckRequestTypeInfoWatcher() {
  yield takeLatest(
    ActionType.MENTAL_CHECK_TYPE_INFO as any,
    checkRequestTypeInfoSaga
  );
}

export default [fork(onGetCheckRequestTypeInfoWatcher)];
