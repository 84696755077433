import { ActionType, AccountListResponse } from "models/account/accountList";

export const getAccountListAction = (payload: any) => {
  return {
    type: ActionType.ACCOUNT_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.ACCOUNT_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: AccountListResponse) => {
  return {
    type: ActionType.ACCOUNT_LIST_SUCCESS,
    payload,
  };
};
