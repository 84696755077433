import styled from "styled-components";
import { theme } from "theme/theme";
import { CalendarIcon } from "icons";

export const WrapperDateRangePicker = styled.div`
  > div {
    width: 100%;
  }
  width: 100%;
  .react-datepicker {
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background: #f2f2f2;
  }
  .react-datepicker__input-container input {
    width: 120px;
    height: 32px;
    letter-spacing: 0.3px;
    border: ${({ theme }) => `1px solid ${theme.colors.border}`};
    border-radius: 8px;
    padding-left: 12px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  .react-datepicker__month-container {
    width: 50%;
  }
  .react-datepicker__day {
    font-size: ${({ theme }) => theme.sizes.sm};
    line-height: 22px;
    font-weight: normal;
    background: #fff;
    float: left;
    margin: 0;
  }
  .react-datepicker__day div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
  .react-datepicker__day:not(.react-datepicker__day--outside-month):hover {
    background: linear-gradient(
        0deg,
        rgba(47, 140, 174, 0.15),
        rgba(47, 140, 174, 0.15)
      ),
      #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  }
  .react-datepicker__day.react-datepicker__day--selected:hover {
    background: #2f8cae;
    color: #ffffff !important;
    border: none;
  }
  .react-datepicker__day {
    position: relative;
    border-radius: 4px;
    p {
      display: none;
    }
  }
  .react-datepicker__day.react-datepicker__day--today::after {
    content: "";
    position: absolute;
    font-size: 6px !important;
    width: 6px !important;
    height: 6px !important;
    background: #fb4e4e;
    border-radius: 50%;
    top: 3px;
    left: 3px;
  }
  .react-datepicker__day.react-datepicker__day--highlighted-appointment-less-100 {
    border: 1px solid #ebc300;
    border-radius: 4px;
    p {
      display: block;
      color: #ebc300;
    }
  }
  .react-datepicker__day.react-datepicker__day--highlighted-appointment-less-100::before {
    content: "△";
    position: absolute;
    color: #ebc300;
  }
  .react-datepicker__day.react-datepicker__day--highlighted-appointment-less-80 {
    border: 1px solid #2ac769;
    border-radius: 4px;
    p {
      display: block;
      color: #2ac769;
    }
  }
  .react-datepicker__day.react-datepicker__day--highlighted-appointment-less-80::before {
    content: "◎";
    position: absolute;
    color: #2ac769;
  }
  .react-datepicker__day.react-datepicker__day--highlighted-appointment-full {
    border: 1px solid #fb4e4e;
    border-radius: 4px;
    p {
      display: block;
      color: #fb4e4e;
    }
  }
  .react-datepicker__day.react-datepicker__day--highlighted-appointment-full::before {
    content: "×";
    position: absolute;
    color: #fb4e4e;
  }
  .react-datepicker__day.react-datepicker__day--highlighted-appointment-past {
    border-radius: 4px;
    p {
      display: block;
      color: #b7b7b7;
    }
  }
  .react-datepicker__day.react-datepicker__day--disabled {
    color: #b7b7b7;
  }
  .react-datepicker__day.react-datepicker__day--disabled:hover {
    background: linear-gradient(
        0deg,
        rgba(47, 140, 174, 0.15),
        rgba(47, 140, 174, 0.15)
      ),
      #ffffff;
    opacity: 1;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  }
  .react-datepicker__header {
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    font-size: ${({ theme }) => theme.sizes.xs};
    color: ${({ theme }) => theme.colors.text.primary};
    padding: 0;
    border-bottom: 0;
    position: inherit;
  }
  .react-datepicker__current-month {
    height: 40px;
    padding: 10px 0px 18px;
    margin: 0px 8px 8px;
    text-align: left;
    font-size: ${({ theme }) => theme.sizes.xl};
    line-height: ${({ theme }) => theme.sizes.xl};
    font-weight: normal;
    color: #4f4f4f;
    text-align: center;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day-names {
    margin: 0px 8px 6px;
    font-weight: normal;
    font-size: ${({ theme }) => theme.sizes.sm};
    display: flex;
    justify-content: center;
    gap: 4px;
  }
  .react-datepicker__day-name {
    line-height: 25px;
    color: ${({ theme }) => theme.colors.text.primary};
    background: #ffffff;
    border-radius: 4px;
    font-size: 16px;
    margin: 0;
  }
  .react-datepicker__day-name:first-child {
    color: ${({ theme }) => theme.colors.important};
  }
  .react-datepicker__day-name:last-child {
    color: ${({ theme }) => theme.colors.textLink};
  }
  .react-datepicker__month {
    margin: 0 8px 12px 8px;
  }
  .react-datepicker__week {
    display: flex;
    gap: 4px;
    margin-bottom: 4px;
    justify-content: center;
  }
  .react-datepicker__week:last-child {
    border-bottom: 0;
  }
  .react-datepicker__day--selected {
    background: #2f8cae !important;
    color: #ffffff !important;
    font-weight: bold !important;
    border: none !important;
  }
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-less-100,
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-less-80,
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-full,
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-past {
    p {
      color: #ffffff !important;
    }
  }
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-less-100,
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-less-80,
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-full,
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-past,
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-less-100::before,
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-less-80::before,
  .react-datepicker__day--selected.react-datepicker__day.react-datepicker__day--highlighted-appointment-full::before {
    color: #ffffff !important;
    font-weight: normal;
  }
  .react-datepicker__day--outside-month {
    color: #bdbdbd;
    float: left;
    background: #ffffff;
    border: none !important;
    p {
      display: none !important;
    }
  }
  .react-datepicker__day--outside-month::before,
  .react-datepicker__day--outside-month::after {
    display: none;
  }
  .react-datepicker__day--outside-month.react-datepicker__day--selected {
    background: #ffffff !important;
  }
  .react-datepicker__day--today {
    font-weight: normal;
  }
  .react-datepicker__navigation {
    top: 8px;
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
  }
  .react-datepicker__day--highlighted {
    position: relative;
  }
  .react-datepicker__day--highlighted::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: 5px;
    right: 3px;
    background: ${({ theme }) => theme.colors.important};
  }
  .react-datepicker__navigation-icon--next::before {
    left: -6px;
  }
  .react-datepicker__navigation-icon--previous::before {
    right: -6px;
  }
  .react-datepicker__navigation-icon::before {
    top: 5px;
    border-width: 2px 2px 0 0;
    height: 12px;
    width: 12px;
    border-color: ${({ theme }) => theme.colors.textLink};
  }
  .react-datepicker__navigation--previous {
    left: 8px;
  }
  .react-datepicker__navigation--next {
    right: 8px;
  }
  .react-datepicker__day--keyboard-selected {
    color: unset;
  }
  .react-datepicker__navigation--previous:focus,
  .react-datepicker__navigation--next:focus,
  .react-datepicker__day--keyboard-selected:focus,
  .react-datepicker__day--selected:focus {
    outline: none;
  }
  ${theme.media.mobileM} {
    .react-datepicker__month-container {
      width: 100%;
    }
    .react-datepicker {
      width: 100%;
      max-width: 100%;
    }
    .react-datepicker__input-container input {
      width: 140px;
    }
    .react-datepicker__day-name {
      height: 25px;
      width: 14.25%;
    }
    .react-datepicker__day {
      height: 76px;
      width: 14.25%;
    }
    .react-datepicker__day.react-datepicker__day--highlighted-appointment-less-100::before,
    .react-datepicker__day.react-datepicker__day--highlighted-appointment-less-80::before,
    .react-datepicker__day.react-datepicker__day--highlighted-appointment-full::before {
      font-size: 19px;
      line-height: 16px;
      width: 16px;
      height: 16px;
      top: calc(50% - 8px);
      left: calc(50% - 8px);
    }
    .react-datepicker__day.react-datepicker__day--highlighted-appointment-full::before {
      font-size: 32px;
      left: calc(50% - 9px);
    }
  }
  ${theme.media.tablet} {
    .react-datepicker__input-container input {
      width: 145px;
      padding-left: 14px;
    }
    .react-datepicker__day {
      padding-top: calc(14.23% - 3px); /* 1:1 Aspect Ratio */
      position: relative;
      width: 14.25%;
    }
    .react-datepicker__day:after {
      content: "";
      float: left;
    }
    .react-datepicker__day-name {
      width: 14.25%;
      height: 25px;
    }
    .react-datepicker__day.react-datepicker__day--highlighted-appointment-less-100::before,
    .react-datepicker__day.react-datepicker__day--highlighted-appointment-less-80::before,
    .react-datepicker__day.react-datepicker__day--highlighted-appointment-full::before {
      font-size: 24px;
      line-height: 18px;
      width: 18px;
      height: 18px;
      top: calc(50% - 9px);
      left: calc(50% - 10px);
    }
    .react-datepicker__day.react-datepicker__day--highlighted-appointment-full::before {
      font-size: 40px;
      line-height: 18px;
      width: 18px;
      height: 18px;
      top: calc(50% - 9px);
      left: calc(50% - 12px);
    }
  }
  ${theme.media.laptop} {
    .react-datepicker {
      width: 100%;
    }
  }
  ${theme.media.desktop} {
    .react-datepicker__month-container {
      width: 50%;
    }
    ${theme.media.desktopL} {
      .react-datepicker__day-name {
        height: 35px;
        line-height: 35px;
      }
    }
  }
`;

export const WrapperCalendarDay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 0px 8px;
  span {
    font-size: 18px;
    line-height: 16px;
  }
  p {
    font-weight: normal !important;
    margin: 0;
    font-size: 10px;
    line-height: 12px;
  }
  ${theme.media.tablet} {
    p {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const WrapperDateRangePickerSecond = styled(WrapperDateRangePicker)`
  ${theme.media.tablet} {
    .react-datepicker__navigation-icon {
      top: 0px;
    }
  }
`;

export const Text = styled.p(({ theme }) => ({
  color: theme.colors.text.primary,
  fontSize: 24,
  fontWeight: 400,
  lineHeight: "16px",
  alignSelf: "center",
  margin: "0 8px",
}));

export const CalendarIconStyled = styled(CalendarIcon)`
  position: absolute;
  right: 10px;
  top: 5px;
`;
