import { Action, ActionType, GetCitiesResponse } from "models/dashboard/cities";
import createReducer from "../../createReducer";

export interface GetCitiesReducerType {
  loading: boolean;
  error?: string;
  getCitiesResponse?: GetCitiesResponse;
}

const defaultState: GetCitiesReducerType = {
  loading: false,
  error: undefined,
  getCitiesResponse: undefined,
};

export const getCitiesReducer = createReducer<GetCitiesReducerType>(
  defaultState,
  {
    [ActionType.GET_CITIES](state: GetCitiesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_CITIES_ERROR](
      state: GetCitiesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_CITIES_SUCCESS](
      state: GetCitiesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        getCitiesResponse: action.payload,
      };
    },
  }
);
