import React, { useState, useEffect } from "react";
import { Grid, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { Button, DateRangePicker, Pagination, Select } from "components";
import { WindowIcon } from "icons";
import {
  localStorageHelper,
  handleConvertDateYear,
  generateDynamicRecords,
  handleCondition,
} from "utils";
import { IOption } from "components/select/Select";

import {
  IQuestionHistoryData,
  IQuestionHistory,
} from "models/questionnaires/sendQuestionHistory";
import {
  IQuestionHistoryDetailData,
  IQuestionHistoryDetail,
  IUserInformation,
} from "models/questionnaires/questionHistoryDetail";
import {
  questionHistoryActionCreators,
  questionHistoryDetailActionCreators,
} from "redux/rootActions";
import { sendQuestionHistoryDataResponse } from "redux/questionnaires/sendQuestionHistory/sendQuestionHistoryStates";
import { questionHistoryDetailResponse } from "redux/questionnaires/questionHistoryDetail/questionHistoryDetailStates";

import {
  Text,
  CollapseStyled,
  Container,
  WrapperAddressList,
  DestinationsModalStyled,
} from "screens/messageDelivery/MessageDelivery.style";
import {
  HistoryTitleWrapper,
  HistoryContainer,
  HistoryTitle,
  PaginationWrapper,
  WrapperChangeSize,
  WrapperTextContent,
  PaginationBottom,
  TextDateCollapseHistory,
  WrapperDateStatus,
  WrapperHeaderCollapseHistory,
  Question,
} from "./Questions.style";
import ConfirmStatusModal from "./ConfirmStatusModal";

interface Question {
  question: string;
  answer: string;
}

const { Panel } = Collapse;
const { useBreakpoint } = Grid;

interface IHistoryList {
  handleCancel?: (show: boolean) => void;
}

const HistoryList: React.FC<IHistoryList> = () => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "questions",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const isJapanLanguage = currentLanguage === "ja";

  const sendHistoryList = useSelector(sendQuestionHistoryDataResponse);
  const questionHistoryDetails = useSelector(questionHistoryDetailResponse);

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "M").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [isVisibleDestinationList, setIsVisibleDestinationList] =
    useState<boolean>(false);
  const [confirmStatusVisible, setConfirmStatusVisible] =
    useState<boolean>(false);
  const [questionHistoryDetail, setQuestionHistoryDetailResult] =
    useState<IQuestionHistoryDetail>();
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [input, setInput] = useState({
    limit: 50,
    page: 1,
  });
  const [sentId, setSentId] = useState<number>(0);
  const messageHistoryList: any = [];

  const dateFormat = "YYYY-MM-DD";
  const params: IQuestionHistoryData = {
    locale: currentLanguage,
    from_date: moment(startDate, dateFormat).format(dateFormat),
    to_date: moment(endDate, dateFormat).format(dateFormat),
  };

  useEffect(() => {
    dispatch(questionHistoryActionCreators.handleResetHistoryListAction());
    dispatch(
      questionHistoryActionCreators.sendQuestionHistoryListAction({
        ...params,
        limit: input.limit,
        page: input.page,
      })
    );
  }, [startDate, endDate, input]);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (sendHistoryList && Object.keys(sendHistoryList).length) {
      let recordOptionsClone: IOption[] = [];
      recordOptionsClone = generateDynamicRecords(
        sendHistoryList?.result?.total_record,
        t2
      );
      setRecordOptions([...recordOptionsClone]);
      setTotal(sendHistoryList?.result?.total_record);
    }
  }, [sendHistoryList]);

  const handleShowDestinationList = () => {
    setIsVisibleDestinationList(true);
  };

  const handleCancelDestinationList = () => {
    setIsVisibleDestinationList(false);
  };

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  const handleOnChangeSizeChange = (limit: number) => {
    setInput((prevState: any) => ({ ...prevState, limit, page: 1 }));
  };

  const ShowPagination = () => {
    return total > input.limit ? (
      <Pagination
        current={input.page}
        onChange={handleChangePage}
        pageSize={input.limit}
        total={total}
        showSizeChanger={false}
        showLessItems={!screens.xl}
      />
    ) : (
      <></>
    );
  };

  useEffect(() => {
    questionHistoryDetails
      ? setQuestionHistoryDetailResult(questionHistoryDetails?.result)
      : setQuestionHistoryDetailResult(undefined);
  }, [questionHistoryDetails]);

  const historyDetailAPICalling = (question_id: string | string[]) => {
    dispatch(
      questionHistoryDetailActionCreators.handleResetHistoryDetailAction()
    );
    const params: IQuestionHistoryDetailData = {
      id: question_id,
    };
    dispatch(
      questionHistoryDetailActionCreators.questionHistoryDetailAction(params)
    );
  };

  const LabelTag = ({ startTime, endTime }: any) => {
    if (handleCondition("th1", startTime, endTime)) {
      return (
        <div
          className="badge"
          style={{
            background: "#2AC769",
            padding: "4px 0px",
          }}
        >
          {t1("before-answer")}
        </div>
      );
    }

    if (handleCondition("th2", startTime, endTime)) {
      return (
        <div
          className="badge"
          style={{
            background: "#83BCE2",
            padding: "4px 0px",
          }}
        >
          {t1("answering")}
        </div>
      );
    }
    return (
      <div
        className="badge"
        style={{
          background: "#CCCCCC",
          padding: "4px 0px",
        }}
      >
        {t1("end-of-answer")}
      </div>
    );
  };

  const renderMessagesList = () => {
    return (
      <div>
        <CollapseStyled
          accordion
          expandIconPosition="right"
          onChange={(key: string | string[]) => {
            if (!key) return;
            historyDetailAPICalling(key);
          }}
        >
          {sendHistoryList &&
            sendHistoryList.result &&
            sendHistoryList.result.histories
              .filter((message: IQuestionHistory) =>
                handleCondition(undefined, undefined, message.end_time)
              )
              .map((message: IQuestionHistory, index: any) => (
                <Panel
                  header={
                    <WrapperHeaderCollapseHistory
                      background={index % 2 ? "#FFFFFF" : "#F2F2F2"}
                    >
                      <WrapperDateStatus>
                        <LabelTag
                          startTime={message.start_time}
                          endTime={message.end_time}
                        />
                        <TextDateCollapseHistory>
                          {handleConvertDateYear(
                            moment(message.created_at).format("YYYY-MM-DD"),
                            currentLanguage
                          )}{" "}
                          {moment(message.created_at).format("HH:mm")}
                        </TextDateCollapseHistory>
                      </WrapperDateStatus>
                      <WrapperTextContent>
                        <div className="content">{message.subject}</div>
                        <div
                          className="badge"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setSentId(message.id);
                            setConfirmStatusVisible(true);
                          }}
                        >
                          {t1("confirm-answer-status")}
                        </div>
                      </WrapperTextContent>
                    </WrapperHeaderCollapseHistory>
                  }
                  key={message.id}
                  className="site-collapse-custom-panel"
                >
                  <Container
                    pd="8px 0px 0px 0px"
                    bdr="none"
                    wrap="wrap"
                    width="100%"
                    style={{ borderTop: "1px solid #fbfbfb" }}
                    background={index % 2 ? "#FFFFFF" : "#F2F2F2"}
                  >
                    <Container
                      pd="0px 0px 7px 0px"
                      style={{ borderBottom: "1px solid #fbfbfb" }}
                    >
                      <Text
                        size={16}
                        lnHeight="18px"
                        weight={700}
                        color="#3B3B3B"
                        width={isJapanLanguage ? "32px" : "58px"}
                        minWidth={isJapanLanguage ? "32px" : "58px"}
                        mg={isJapanLanguage ? "0 16px 0 16px" : "0 12px 0 16px"}
                      >
                        {t("address")}
                      </Text>
                      <WrapperAddressList>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 4,
                          }}
                        >
                          {questionHistoryDetail?.user_information.map(
                            (item: IUserInformation, indexAddress: number) => {
                              return (
                                <div
                                  key={indexAddress}
                                  style={{
                                    background:
                                      index % 2 ? "#F2F2F2" : "#FFFFFF",
                                    padding: "3px 4px 4px",
                                    borderRadius: 4,
                                    fontSize: 14,
                                    lineHeight: "14px",
                                    color: "#3B3B3B",
                                  }}
                                >
                                  {item.full_name}
                                </div>
                              );
                            }
                          )}
                        </div>
                        {questionHistoryDetail?.user_information &&
                          questionHistoryDetail?.user_information.length >=
                            50 && (
                            <Button
                              icon={<WindowIcon fill="white" />}
                              background="#2F8CAE"
                              color="#fff"
                              name={t("show-all")}
                              border="none"
                              fontWeight={700}
                              fontSize={16}
                              bdr="6px"
                              padding="3px 8px 3px 10px"
                              margin="4px 0 5px 0"
                              onClick={handleShowDestinationList}
                            />
                          )}
                      </WrapperAddressList>
                    </Container>
                    <div style={{ padding: 16 }}>
                      {questionHistoryDetail?.questionnaire_questions &&
                        questionHistoryDetail?.questionnaire_questions.map(
                          (item: any, index) => (
                            <Question key={index}>
                              <div className="question">
                                {t1("question")}
                                {item[0]}：
                              </div>
                              {item[1]}
                            </Question>
                          )
                        )}
                    </div>
                  </Container>
                </Panel>
              ))}
        </CollapseStyled>
      </div>
    );
  };

  const renderDestinationListModal = () => (
    <DestinationsModalStyled
      type="search"
      title={
        <>
          {t("display-all-destinations")}
          <span style={{ marginLeft: 10 }}>
            ({questionHistoryDetail?.user_information.length}件)
          </span>
        </>
      }
      visible={isVisibleDestinationList}
      onCancel={handleCancelDestinationList}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {questionHistoryDetail?.user_information.map(
          (item: IUserInformation, index: number) => {
            return (
              <div
                key={index}
                style={{
                  background: "#F2F2F2",
                  padding: 4,
                  marginRight: 5,
                  marginBottom: 4,
                  borderRadius: 4,
                  fontSize: 12,
                  lineHeight: "12px",
                  color: "#3B3B3B",
                }}
              >
                {item.full_name}
              </div>
            );
          }
        )}
      </div>
    </DestinationsModalStyled>
  );

  return (
    <>
      <HistoryTitleWrapper>
        <div
          style={{
            fontSize: 16,
            lineHeight: "23px",
            fontWeight: "bold",
            marginLeft: 2,
          }}
        >
          {t1("history-list")}
        </div>
      </HistoryTitleWrapper>
      <HistoryTitle>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          locale={currentLanguage}
        />
        <div className="title">{t1("display-period")}</div>
      </HistoryTitle>
      <HistoryContainer>
        <PaginationWrapper>
          <WrapperChangeSize>
            <div style={{ fontWeight: 500 }}>{t2("record")}</div>
            <Select
              defaultValue={50}
              options={recordOptions}
              onChange={handleOnChangeSizeChange}
            />
          </WrapperChangeSize>
          <ShowPagination />
        </PaginationWrapper>
        {messageHistoryList ? (
          renderMessagesList()
        ) : (
          <Text
            size={16}
            lnHeight="24px"
            weight={700}
            width="max-content"
            color="#666666"
            mg={screens.xs ? "-8px 0px 0px" : "0px"}
          >
            {t1("no-survey-submitted")}
          </Text>
        )}
        <PaginationBottom>
          <ShowPagination />
        </PaginationBottom>
      </HistoryContainer>
      {confirmStatusVisible && (
        <ConfirmStatusModal
          id={sentId}
          isVisible={confirmStatusVisible}
          handleCancel={setConfirmStatusVisible}
        />
      )}
      {isVisibleDestinationList && renderDestinationListModal()}
    </>
  );
};

export default HistoryList;
