export enum ActionType {
  // delete activity/group for school
  DELETE_ACTIVITY = "action/DELETE_ACTIVITY",
  DELETE_ACTIVITY_SUCCESS = "action/DELETE_ACTIVITY_SUCCESS",
  DELETE_ACTIVITY_ERROR = "action/DELETE_ACTIVITY_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDeleteActivityData {
  id: number;
}
