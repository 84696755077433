import { Action, ActionType, ClassListResponse } from "models/classList";
import createReducer from "../createReducer";

export interface ClassListReducerType {
  loading: boolean;
  error?: string;
  classListResponse?: ClassListResponse;
}

const defaultState: ClassListReducerType = {
  loading: false,
  error: undefined,
  classListResponse: undefined,
};

export const classListReducer = createReducer<ClassListReducerType>(
  defaultState,
  {
    [ActionType.CLASS_LIST](state: ClassListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CLASS_LIST_ERROR](
      state: ClassListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CLASS_LIST_SUCCESS](
      state: ClassListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        classListResponse: action.payload,
      };
    },
  }
);
