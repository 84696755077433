import {
  ActionType,
  Action,
  DuplicateQuestionResponse,
} from "models/questionnaires/duplicateQuestion";
import createReducer from "../../createReducer";

export interface DuplicateQuestionReducerType {
  loading: boolean;
  error?: string;
  duplicateQuestionResponse?: DuplicateQuestionResponse;
}

const defaultState: DuplicateQuestionReducerType = {
  loading: false,
  error: undefined,
  duplicateQuestionResponse: undefined,
};

export const duplicateQuestionReducer =
  createReducer<DuplicateQuestionReducerType>(defaultState, {
    [ActionType.DUPLICATE_QUESTION](state: DuplicateQuestionReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DUPLICATE_QUESTION_ERROR](
      state: DuplicateQuestionReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        duplicateQuestionResponse: undefined,
      };
    },

    [ActionType.DUPLICATE_QUESTION_SUCCESS](
      state: DuplicateQuestionReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        duplicateQuestionResponse: action.payload,
      };
    },

    [ActionType.DUPLICATE_QUESTION_RESET](state: DuplicateQuestionReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        duplicateQuestionResponse: undefined,
      };
    },
  });
