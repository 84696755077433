import React, { ReactElement } from "react";
import styled from "styled-components";
import { Layout } from "antd";
import { Logo } from "icons";
import { theme } from "theme/theme";

const { Footer } = Layout;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  margin: 0 auto;
`;

const WrapperLogo = styled.div`
  position: absolute;
  top: 80px;
  transform: translateX(50%);
  ${theme.media.tablet} {
    position: unset;
    transform: unset;
    text-align: center;
    margin-bottom: 32px;
  }
`;

const WrapperContent = styled.div`
  position: relative;
  background: ${theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  width: 300px;
  padding: 32px 16px;
  ${theme.media.mobileM} {
    width: 343px;
  }
  ${theme.media.tablet} {
    width: 450px;
    padding: 32px 40px;
  }
`;

const CopyRight = styled.div(({ theme }) => ({
  color: theme.colors.border,
  fontSize: theme.sizes.xs,
  fontWeight: theme.fontWeight.medium,
}));

const FooterStyled = styled(Footer)`
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 16px 0;
  ${theme.media.desktop} {
    padding: 24px 0;
  }
`;

const MainLayout: React.FC = ({ children }): ReactElement => {
  return (
    <Wrapper>
      <Container>
        <WrapperLogo>
          <Logo />
        </WrapperLogo>
        <WrapperContent>{children}</WrapperContent>
      </Container>
      <FooterStyled>
        <CopyRight>© 2020 by LEBER, Inc. All Rights Reserved.</CopyRight>
      </FooterStyled>
    </Wrapper>
  );
};

export default MainLayout;
