import { IResponse } from "models";

export enum ActionType {
  TIME_SLOT = "action/TIME_SLOT",
  TIME_SLOT_SUCCESS = "action/TIME_SLOT_SUCCESS",
  TIME_SLOT_ERROR = "action/TIME_SLOT_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface GetTimeSlotData {
  locale?: string;
  selected_slot: string;
}

export interface TimeSlotDataResponse extends IResponse {
  result: {
    schedule_sections: string[];
  };
}
