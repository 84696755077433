import React, { useEffect, useRef, useState } from "react";
import { Col } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

import { CloseIcon, DownloadIcon, NoDataIcon } from "icons";
import { generateImg } from "utils";

import {
  BoxItem,
  WrapperCloseIcon,
  BoxHeader,
  BoxTitle,
  LightDivider,
  BoxContent,
  WrapperLegendLineChart,
  RedDot,
  WrapperChart,
  ContainerChart,
  YellowDot2,
  WrapperLoadingChart,
  WrapperNoData,
} from "../Dashboard.style";
import { IChart } from "../BodyTemperatureResult";
import { useDispatch, useSelector } from "react-redux";
import {
  symtomDataResponse,
  selectIsLoading,
} from "redux/dashboard/symtom/symtomStates";
import { SymtomActionCreators } from "redux/rootActions";
import moment from "moment";
import { Loading } from "components";

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={0} dy={18} fill="#3B3B3B">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={3} fill="#3B3B3B" textAnchor="end">
        {payload.value} {t("people")}
      </text>
    </g>
  );
};

const LineChartCustom = ({ data }: any) => {
  return (
    <ContainerChart>
      <ResponsiveContainer width="100%" height={178}>
        <LineChart
          margin={{
            top: 10,
            right: 20,
            left: 0,
            bottom: 0,
          }}
          data={data}
        >
          <CartesianGrid vertical={false} strokeDasharray="2 2" />
          <XAxis
            dy={4}
            tickLine={false}
            dataKey="date"
            minTickGap={-100}
            tick={<CustomizedXAxisTick />}
            stroke="#CCCCCC"
          />
          <YAxis
            type="number"
            tickLine={false}
            interval={0}
            width={55}
            allowDecimals={false}
            tick={<CustomizedYAxisTick />}
            stroke="#CCCCCC"
          />
          <Line
            dataKey="high"
            stroke="#FB4E4E"
            strokeDasharray="3 3"
            isAnimationActive={false}
            dot={{
              stroke: "#FB4E4E",
              strokeWidth: 1,
              r: 4,
              fill: "#FB4E4E",
              strokeDasharray: "",
            }}
          />
          <Line
            dataKey="low"
            stroke="#EBC300"
            strokeDasharray="3 3"
            isAnimationActive={false}
            dot={{
              stroke: "#EBC300",
              strokeWidth: 1,
              r: 4,
              fill: "#EBC300",
              strokeDasharray: "",
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </ContainerChart>
  );
};

const Symptom: React.FC<IChart> = ({ handleCloseChart }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "dashboard",
  });

  const dispatch = useDispatch();
  const symtomData = useSelector(symtomDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const imgRef = useRef<any>();
  const [data, setData] = useState([]);

  const handleConvertDate = (date: string) => {
    return moment(new Date(date)).format("M/D");
  };

  useEffect(() => {
    dispatch(
      SymtomActionCreators.getSymtomAction({
        aggregate_date_to: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
  }, []);

  useEffect(() => {
    if (symtomData && Object.keys(symtomData).length) {
      setData(
        symtomData.amounts.map((item: any) => {
          return {
            date: handleConvertDate(item.aggregate_date),
            high: item.amount,
          };
        })
      );
    }
  }, [symtomData]);

  return (
    // <Col xs={24} md={24} xl={12} >
    <BoxItem ref={imgRef}>
      <WrapperCloseIcon>
        <CloseIcon
          fill="#2F8CAE"
          height="14px"
          width="14px"
          onClick={() => handleCloseChart("9")}
        />
      </WrapperCloseIcon>
      <BoxHeader>
        <BoxTitle>{t1("symptom-label")}</BoxTitle>
        <DownloadIcon
          fill="#3B3B3B"
          style={{ marginTop: 4, cursor: "pointer" }}
          onClick={(event: any) => {
            if (!event.detail || event.detail == 1) {
              generateImg("symtoms", imgRef.current);
            }
          }}
        />
      </BoxHeader>
      <LightDivider />
      {isLoading ? (
        <WrapperLoadingChart>
          <div className="container">
            <Loading width={70} height={70} />
          </div>
        </WrapperLoadingChart>
      ) : data.length > 0 ? (
        <BoxContent>
          <div style={{ display: "flex", height: "max-content" }}>
            <WrapperLegendLineChart>
              <RedDot />
              <span>...</span>
              <p>{t("fever")}</p>
            </WrapperLegendLineChart>
          </div>
          <WrapperChart>
            <LineChartCustom data={data} />
          </WrapperChart>
        </BoxContent>
      ) : (
        <WrapperNoData>
          <NoDataIcon width={140} height={140} />
        </WrapperNoData>
      )}
    </BoxItem>
    // </Col>
  );
};

export default Symptom;
