import { IResponse } from "models";

export enum ActionType {
  ACCOUNT_DEPARTMENT_LIST = "action/ACCOUNT_DEPARTMENT_LIST",
  ACCOUNT_DEPARTMENT_LIST_SUCCESS = "action/ACCOUNT_DEPARTMENT_LIST_SUCCESS",
  ACCOUNT_DEPARTMENT_LIST_ERROR = "action/ACCOUNT_DEPARTMENT_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

interface IManager {
  id: number;
  email: string;
  display_name: any;
  company_id: number;
  created_at: any;
  updated_at: any;
}

export interface IDepartment {
  id: number;
  name: string;
  delete_flag: boolean;
  checked?: boolean;
  sub_departments: IDepartment[];
}

export interface IActivites {
  id: number;
  name: string;
  delete_flag: boolean;
  checked?: boolean;
  sub_activities: IActivites[];
}

export interface DepartmentListResponse extends IResponse {
  result: {
    company_manager: IManager;
    checked_c_department_ids: number[];
    checked_company_sub_department_ids: number[];
    selectable_departments: IDepartment[];
    selectable_sub_departments: IActivites[];
  };
}
