import {
  NewMentalCheckData,
  ActionType,
} from "models/mentalHealth/newMentalCheck";
import { IResponse } from "models";

export const newMentalCheckAction = (payload: NewMentalCheckData) => {
  return {
    type: ActionType.NEW_MENTAL_CHECK,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.NEW_MENTAL_CHECK_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.NEW_MENTAL_CHECK_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.RESET_NEW_MENTAL_CHECK,
  };
};
