import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Input, Button, Select } from "components";
import { SendCompleteIcon } from "icons";
import { localStorageHelper } from "utils";
import { PASSWORD_REGEX } from "constant";
import { httpStatus } from "configs/httpStatus";
import { WrapperErrorMessage, ErrorMessage } from "theme/CommonStyle";

import { settingDataResponse } from "redux/setting/settingData/settingDataStates";
import {
  updateGeneralSettingResponse,
  selectErrorMessage,
} from "redux/setting/updateGeneral/updateGeneralStates";
import {
  UpdateGeneralSettingActionCreators,
  SettingDataActionCreators,
} from "redux/rootActions";

import {
  WrapperSection,
  WrapperTitle,
  WrapperGeneralForm,
  Label,
  FormTitle,
  LanguageContainer,
  PasswordContainer4Setting,
  TimeSettingContainer,
  TimezoneWrap,
  CurrentLocationForm,
  CurrentLocationWrap,
  LabelItem,
  FormWrapper,
  ScreenContainer,
} from "./Setting.style";
import { languageName } from "./constant";

const schema = yup.object().shape({
  oldPassword: yup.string().required("current-password-required"),
  newPassword: yup
    .string()
    .required("new-password-required")
    .matches(PASSWORD_REGEX, "password-requirements"),
  confirmPassword: yup
    .string()
    .required("confirm-password-required")
    .oneOf([yup.ref("newPassword")], "not-match"),
});

const GeneralSetting = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "setting" });
  const { t: t1 }: any = useTranslation("translation", { keyPrefix: "layout" });
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const dispatch = useDispatch();
  const settingData = useSelector(settingDataResponse);
  const updateGeneralSettingData = useSelector(updateGeneralSettingResponse);
  const errorMessage = useSelector(selectErrorMessage);
  const { watch, handleSubmit, control, setValue } = useForm();

  const { handleSubmit: handleSubmit2, control: control2 } = useForm({
    resolver: yupResolver(schema),
  });
  const [generalSettingData, setGeneralSettingData] = useState<any>({});
  const [currentLocation, setCurrentLocation] = useState("");

  const watchTimeZone = watch("timezone");
  const watchLanguage = watch("language");

  const onSubmitGeneralSetting = (data: any) => {
    const params = {
      screen_display_id: data.screen,
      app_language_id: data.language,
      time_zone_id: data.timezone,
      locale: currentLanguage,
    };
    dispatch(UpdateGeneralSettingActionCreators.updateGeneralAction(params));
  };

  const onSubmitPasswords = (data: any) => {
    const params = {
      current_password: data.oldPassword,
      new_password: data.newPassword,
      confirm_new_password: data.confirmPassword,
      app_language_id: watchLanguage,
      time_zone_id: watchTimeZone,
      locale: currentLanguage,
    };
    dispatch(UpdateGeneralSettingActionCreators.updateGeneralAction(params));
  };

  // useEffect(() => {
  //   dispatch(UpdateGeneralSettingActionCreators.handleResetAction());
  // }, []);

  const pad = (value: any) => {
    return value < 10 ? "0" + value : value;
  };

  const createOffset = (date: any) => {
    const sign = date.getTimezoneOffset() > 0 ? "-" : "+";
    const offset = Math.abs(date.getTimezoneOffset());
    const hours = pad(Math.floor(offset / 60));
    const minutes = pad(offset % 60);
    return sign + hours + ":" + minutes;
  };

  useEffect(() => {
    if (settingData && Object.keys(settingData.result).length) {
      const { temperature_managerment_info, time_zones, screen_displays } =
        settingData?.result;

      const languageOptions = languageName[
        temperature_managerment_info.app_language_id
      ].map((name: string, idx: number) => {
        return {
          id: idx + 1,
          value: idx + 1,
          name: name,
        };
      });
      languageOptions.splice(2, languageOptions.length);

      const timezoneBrowser = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeZoneOptions = time_zones.map((timeZone: any) => {
        setCurrentLocation(
          "(UTC" + createOffset(new Date()) + ") " + timezoneBrowser
        );
        return {
          id: timeZone.id,
          value: timeZone.id,
          name: timeZone.name,
        };
      });
      const screenJa: any = {
        dashboard: t1("dashboard-label"),
        "communication_from_students/heathcheck": t("contact-student"),
        message_delivery: t1("message-delivery-label"),
        questionaire: t1("questions"),
        heathcheck: t1("mental-health-label"),
      };
      const screenOptions = screen_displays.map((sc) => {
        return {
          id: sc.id,
          value: sc.id,
          name: screenJa[sc.screen_code],
        };
      });

      setValue("timezone", temperature_managerment_info.time_zone_id);
      setValue("language", temperature_managerment_info.app_language_id);
      setValue("screen", temperature_managerment_info.screen_display_id);
      setGeneralSettingData({
        timeZones: timeZoneOptions,
        languages: languageOptions,
        screens: screenOptions,
      });
    }
  }, [settingData]);

  useEffect(() => {
    if (
      updateGeneralSettingData &&
      updateGeneralSettingData?.status === httpStatus.StatusOK
    ) {
      notification.success({
        message: t("general-setting-apply"),
        description: "",
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      dispatch(SettingDataActionCreators.getSettingDataAction());
      dispatch(UpdateGeneralSettingActionCreators.handleResetAction());
    }
  }, [updateGeneralSettingData]);

  return (
    <FormWrapper>
      <WrapperSection>
        <form onSubmit={handleSubmit(onSubmitGeneralSetting)}>
          <WrapperTitle>
            <FormTitle>{t("general")}</FormTitle>
          </WrapperTitle>
          <WrapperGeneralForm>
            <ScreenContainer>
              <Label>{t("screen-label")}</Label>
              <Controller
                control={control}
                name="screen"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    placeholder={t("not-set")}
                    options={generalSettingData.screens}
                    onChange={onChange}
                  />
                )}
              />
            </ScreenContainer>
            <LanguageContainer>
              <Label>{t("language-switching")}</Label>
              <Controller
                control={control}
                name="language"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    placeholder={t("japanese")}
                    options={generalSettingData.languages}
                    onChange={onChange}
                  />
                )}
              />
            </LanguageContainer>
            <TimeSettingContainer>
              <TimezoneWrap>
                <Label>{t("time-setting")}</Label>
                <Controller
                  control={control}
                  name="timezone"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      placeholder={t("current-time")}
                      options={generalSettingData.timeZones}
                      onChange={onChange}
                    />
                  )}
                />
              </TimezoneWrap>

              <CurrentLocationWrap>
                <LabelItem>{<>{t("current-location")}</>}</LabelItem>
                <CurrentLocationForm>{currentLocation}</CurrentLocationForm>
              </CurrentLocationWrap>
            </TimeSettingContainer>
          </WrapperGeneralForm>
          <Button
            type="submit"
            name={t("keep")}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            lineHeight="23px"
            padding="4px 16px"
          />
        </form>
      </WrapperSection>
      <WrapperSection>
        <form onSubmit={handleSubmit2(onSubmitPasswords)}>
          <WrapperTitle>
            <FormTitle>{t("change-password")}</FormTitle>
          </WrapperTitle>
          <PasswordContainer4Setting>
            <div style={{ position: "relative" }}>
              <Controller
                control={control2}
                name="oldPassword"
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <Input
                    label={<>{t("old-password")}</>}
                    height={31}
                    fsLabel={16}
                    marginForm="0 0 16px 0"
                    lhLabel="16px"
                    onChange={onChange}
                    error={error && t(error.message)}
                    type="password"
                  />
                )}
              />
              {errorMessage && (
                <WrapperErrorMessage style={{ position: "relative", top: -10 }}>
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </WrapperErrorMessage>
              )}
            </div>
            <Controller
              control={control2}
              name="newPassword"
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Input
                  label={<>{t("new-password")}</>}
                  height={31}
                  fsLabel={16}
                  lhLabel="16px"
                  marginForm="0 0 16px 0"
                  onChange={onChange}
                  error={error && t(error.message)}
                  type="password"
                />
              )}
            />
            <Controller
              control={control2}
              name="confirmPassword"
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Input
                  label={<>{t("confirm-password")}</>}
                  height={31}
                  fsLabel={16}
                  lhLabel="16px"
                  marginForm="0 0 0 0"
                  onChange={onChange}
                  error={error && t(error.message)}
                  type="password"
                />
              )}
            />
          </PasswordContainer4Setting>
          <Button
            type="submit"
            name={t("keep")}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            lineHeight="23px"
            padding="4px 16px"
            margin="16px 0px 0px"
          />
        </form>
      </WrapperSection>
    </FormWrapper>
  );
};

export default GeneralSetting;
