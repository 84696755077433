import * as React from "react";

function ArrowNextIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6652 11.2279L9.77913 5.30983C9.68245 5.21166 9.56743 5.13373 9.44071 5.08056C9.31398 5.02738 9.17805 5 9.04077 5C8.90348 5 8.76756 5.02738 8.64083 5.08056C8.5141 5.13373 8.39908 5.21166 8.30241 5.30983C8.10872 5.50609 8 5.77156 8 6.04829C8 6.32501 8.10872 6.59049 8.30241 6.78674L13.4501 12.024L8.30241 17.2089C8.10872 17.4051 8 17.6706 8 17.9473C8 18.224 8.10872 18.4895 8.30241 18.6858C8.39872 18.7847 8.51358 18.8635 8.64032 18.9174C8.76707 18.9714 8.90318 18.9994 9.04077 19C9.17836 18.9994 9.31447 18.9714 9.44121 18.9174C9.56796 18.8635 9.68281 18.7847 9.77913 18.6858L15.6652 12.7677C15.7708 12.6696 15.855 12.5505 15.9126 12.418C15.9703 12.2855 16 12.1425 16 11.9978C16 11.8531 15.9703 11.7101 15.9126 11.5776C15.855 11.4451 15.7708 11.326 15.6652 11.2279V11.2279Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}

export default ArrowNextIcon;
