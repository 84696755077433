import {
  SendInterviewRequestPayload,
  ActionType,
} from "models/mentalHealth/sendInterviewRequest";
import { IResponse } from "models";

export const sendInterviewRequestAction = (
  payload: SendInterviewRequestPayload
) => {
  return {
    type: ActionType.SEND_INTERVIEW_REQUEST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SEND_INTERVIEW_REQUEST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.SEND_INTERVIEW_REQUEST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.RESET_SEND_INTERVIEW_REQUEST,
  };
};
