import * as React from "react";

function DashboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 12.4099L17.65 9.16991C18.36 8.64991 19.12 8.40991 20 8.40991V12.4099Z"
        fill={props.fill}
      />
      <path
        d="M20 12.4099L16.01 12.1999C16.07 10.9599 16.65 9.89992 17.65 9.16992L20 12.4099Z"
        fill={props.fill}
      />
      <path
        d="M20 12.41L17.65 15.65C16.51 14.82 15.93 13.62 16.01 12.2L20 12.41Z"
        fill={props.fill}
      />
      <path
        d="M20 12.4099V8.40991C22.21 8.40991 24 10.1999 24 12.4099C24 14.6199 22.21 16.4099 20 16.4099C19.12 16.4099 18.36 16.1699 17.65 15.6499L20 12.4099Z"
        fill={props.fill}
      />
      <path
        d="M24 27H8C6.35 27 5 25.65 5 24V8C5 6.35 6.35 5 8 5H24C25.65 5 27 6.35 27 8V24C27 25.65 25.65 27 24 27ZM8 7C7.45 7 7 7.45 7 8V24C7 24.55 7.45 25 8 25H24C24.55 25 25 24.55 25 24V8C25 7.45 24.55 7 24 7H8Z"
        fill={props.fill}
      />
      <path
        d="M9.62997 8.40991H8.33997V15.9299H9.62997V8.40991Z"
        fill={props.fill}
      />
      <path d="M11.29 10.29H10V15.93H11.29V10.29Z" fill={props.fill} />
      <path d="M12.96 12.1699H11.67V15.9299H12.96V12.1699Z" fill={props.fill} />
      <path d="M14.62 11.4099H13.33V15.9299H14.62V11.4099Z" fill={props.fill} />
      <path
        d="M15.31 15.8401H7.65002V16.0301H15.31V15.8401Z"
        fill={props.fill}
      />
      <path d="M23.74 22.74H7.65002V23.12H23.74V22.74Z" fill={props.fill} />
      <path
        d="M8.67001 20.15L8.51001 19.83L8.73001 19.71L8.90001 20.04L8.67001 20.15Z"
        fill={props.fill}
      />
      <path
        d="M9.42001 19.7699L9.26001 19.4399L9.79001 19.1699L9.95001 19.4999L9.42001 19.7699Z"
        fill={props.fill}
      />
      <path
        d="M10.76 19.2301L10.62 19.1601L10.48 19.2301L10.31 18.9001L10.53 18.7901C10.58 18.7601 10.65 18.7601 10.7 18.7901L10.92 18.9001L10.75 19.2301H10.76Z"
        fill={props.fill}
      />
      <path
        d="M12.65 20.1699C12.65 20.1699 12.59 20.1699 12.57 20.1499L12.24 19.9799L12.41 19.6499L12.67 19.7799L12.78 19.7499L12.88 20.0999L12.7 20.1499C12.7 20.1499 12.67 20.1499 12.65 20.1499V20.1699ZM13.42 19.9699L13.32 19.6199L13.86 19.4699L13.96 19.8199L13.42 19.9699ZM11.75 19.7299L11.26 19.4799L11.43 19.1499L11.92 19.3999L11.75 19.7299Z"
        fill={props.fill}
      />
      <path
        d="M14.79 19.72L14.65 19.63L14.49 19.67L14.39 19.32L14.63 19.25C14.63 19.25 14.73 19.25 14.77 19.27L14.98 19.4L14.79 19.71V19.72Z"
        fill={props.fill}
      />
      <path
        d="M17.91 21.05L17.35 20.97L17.4 20.61L17.96 20.69L17.91 21.05ZM16.8 20.89L16.69 20.87C16.69 20.87 16.64 20.86 16.62 20.84L16.24 20.6L16.43 20.29L16.78 20.51H16.85L16.8 20.88V20.89ZM15.76 20.31L15.28 20.01L15.47 19.7L15.95 20L15.76 20.31Z"
        fill={props.fill}
      />
      <path
        d="M18.74 21.1699C18.74 21.1699 18.72 21.1699 18.71 21.1699L18.46 21.1299L18.51 20.7699L18.66 20.7899L18.77 20.6799L19.04 20.9299L18.87 21.1099C18.87 21.1099 18.79 21.1699 18.74 21.1699Z"
        fill={props.fill}
      />
      <path
        d="M19.39 20.56L19.12 20.31L19.47 19.94L19.74 20.19L19.39 20.56ZM20.09 19.82L19.82 19.57L20.17 19.2L20.44 19.45L20.09 19.82ZM20.79 19.08L20.52 18.83L20.64 18.71C20.64 18.71 20.69 18.67 20.72 18.66L21.05 18.57L21.15 18.92L20.87 19L20.79 19.09V19.08ZM21.63 18.78L21.53 18.43L22.02 18.29L22.12 18.64L21.63 18.78Z"
        fill={props.fill}
      />
      <path
        d="M22.6 18.5201L22.5 18.1701L22.74 18.1001L22.84 18.4501L22.6 18.5201Z"
        fill={props.fill}
      />
      <path
        d="M22.79 18.74C23.0496 18.74 23.26 18.5296 23.26 18.27C23.26 18.0105 23.0496 17.8 22.79 17.8C22.5304 17.8 22.32 18.0105 22.32 18.27C22.32 18.5296 22.5304 18.74 22.79 18.74Z"
        fill={props.fill}
      />
      <path
        d="M20.77 19.3101C21.0296 19.3101 21.24 19.0997 21.24 18.8401C21.24 18.5805 21.0296 18.3701 20.77 18.3701C20.5104 18.3701 20.3 18.5805 20.3 18.8401C20.3 19.0997 20.5104 19.3101 20.77 19.3101Z"
        fill={props.fill}
      />
      <path
        d="M18.74 21.46C18.9996 21.46 19.21 21.2496 19.21 20.99C19.21 20.7304 18.9996 20.52 18.74 20.52C18.4804 20.52 18.27 20.7304 18.27 20.99C18.27 21.2496 18.4804 21.46 18.74 21.46Z"
        fill={props.fill}
      />
      <path
        d="M16.71 21.17C16.9696 21.17 17.18 20.9596 17.18 20.7C17.18 20.4404 16.9696 20.23 16.71 20.23C16.4504 20.23 16.24 20.4404 16.24 20.7C16.24 20.9596 16.4504 21.17 16.71 21.17Z"
        fill={props.fill}
      />
      <path
        d="M14.68 19.9C14.9395 19.9 15.15 19.6895 15.15 19.43C15.15 19.1704 14.9395 18.96 14.68 18.96C14.4204 18.96 14.21 19.1704 14.21 19.43C14.21 19.6895 14.4204 19.9 14.68 19.9Z"
        fill={props.fill}
      />
      <path
        d="M12.65 20.46C12.9096 20.46 13.12 20.2496 13.12 19.99C13.12 19.7304 12.9096 19.52 12.65 19.52C12.3904 19.52 12.18 19.7304 12.18 19.99C12.18 20.2496 12.3904 20.46 12.65 20.46Z"
        fill={props.fill}
      />
      <path
        d="M10.62 19.42C10.8796 19.42 11.09 19.2096 11.09 18.95C11.09 18.6904 10.8796 18.48 10.62 18.48C10.3605 18.48 10.15 18.6904 10.15 18.95C10.15 19.2096 10.3605 19.42 10.62 19.42Z"
        fill={props.fill}
      />
      <path
        d="M8.59 20.46C8.84957 20.46 9.06 20.2496 9.06 19.99C9.06 19.7304 8.84957 19.52 8.59 19.52C8.33042 19.52 8.12 19.7304 8.12 19.99C8.12 20.2496 8.33042 20.46 8.59 20.46Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default DashboardIcon;
