import styled from "styled-components";
import { theme } from "theme/theme";
import { Table } from "components";
import { Select, Modal } from "antd";

export const TextTitle = styled.div(({ theme }) => ({
  fontSize: theme.sizes.lg,
  lineHeight: theme.sizes.lg,
  fontWeight: theme.fontWeight.bold,
  color: theme.colors.text.primary,
}));

export const SelectStyled = styled(Select)<any>(({ theme }) => ({
  width: "100%",
  ".ant-select-selector": {
    alignItems: "center",
    boxShadow: "none",
    border: "none!important",
    padding: "0 12px!important",
  },
  ".ant-select-arrow": {
    top: "50%",
  },
  ".ant-select-selection-overflow .ant-select-selection-item": {
    border: "none",
    borderRadius: 4,
    background: theme.colors.background,
    fontSize: theme.sizes.xs,
    fontWeight: theme.fontWeight.medium,
    lineHeight: "16px",
    padding: "3px 4px 4px",
    marginRight: 8,
  },
  ".ant-select-selection-item": {
    "&span": {
      display: "none",
    },
  },
}));

export const PaginationWrapper = styled.div`
  text-align: center;
  ${theme.media.tablet} {
    display: flex;
    justify-content: end;
    text-align: unset;
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 24px;
  ${theme.media.laptopL} {
    margin-bottom: 32px;
  }
`;

export const TableStyled = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 20px 16px;
  }
  .ant-table-thead > tr > th:first-child {
    padding-left: 24px;
  }
  .ant-table-thead > tr > th:nth-child(8) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-thead > tr > th:nth-child(7) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-tbody > tr > td:first-child {
    padding-left: 24px;
    border-right: none;
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: right;
  }
  .ant-table-tbody > tr > td {
    background: ${theme.colors.white} !important;
  }
  .ant-table-tbody
    > tr.ant-table-row:hover
    > td.ant-table-cell:last-child
    path {
    fill: ${theme.colors.textLink};
    transition: fill 0.3s ease;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #cde3ec !important;
    cursor: pointer;
  }
`;

export const SituationTableStyled = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 25px 16px 24px;
  }
  .ant-table-thead > tr > th:nth-child(5) {
    padding: 17px 23px 17px 12px;
  }
  .ant-table-tbody > tr > td {
    font-size: 16px;
    line-height: 16px;
    padding: 6px 16px 5px;
  }
  .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr > th:first-child {
    padding-left: 14px;
    padding-right: 14px;
  }
  .ant-table-tbody
    > tr.ant-table-row:hover
    > td.ant-table-cell:last-child
    path {
    fill: ${theme.colors.textLink};
    transition: fill 0.3s ease;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #cde3ec !important;
  }
  button {
    width: max-content;
  }
`;

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MentalHealthStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  background: #f2f2f2;
  border-radius: 17px;
  min-width: 92px;
  div {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
  }
`;

export const WrapperSizeChange = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  .ant-select-arrow {
    top: calc(50% - 4px);
  }
`;

export const WrapperChangeSize = styled.div`
  gap: 4px;
  .left,
  .right {
    width: 100%;
  }
  ${theme.media.tablet} {
    display: flex;
    gap: 24px;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .right {
      width: max-content;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  gap: 24px;
  ${theme.media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
  }
`;

export const TitleContainerCustom = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  gap: 24px;
  button {
    min-width: 160px;
  }
  ${theme.media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TitleContainerDetails = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  ${theme.media.tablet} {
    align-items: center;
  }
`;

export const TitleContainerClose = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  ${theme.media.tablet} {
    align-items: center;
    gap: 0;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  gap: 24px;
  ${theme.media.tablet} {
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
  }
`;

export const TransmissionTitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  gap: 8px;
  flex-direction: column;
  div {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.sm};
    line-height: ${theme.sizes.sm};
    color: ${theme.colors.text.primary};
    order: 2;
    width: 100%;
  }
  button {
    order: 1;
  }
  ${theme.media.tablet} {
    gap: 8px;
    div {
      order: 1;
      width: max-content;
    }
    button {
      order: 2;
    }
  }
  ${theme.media.desktop} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const TransmissionTitleContainerReverse = styled.div`
  display: flex;
  margin-bottom: 24px;
  gap: 8px;
  flex-direction: column;
  div {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.sm};
    line-height: ${theme.sizes.sm};
    color: ${theme.colors.text.primary};
    order: 2;
    width: 100%;
  }
  button {
    order: 1;
  }
  ${theme.media.tablet} {
    flex-direction: column-reverse;
    gap: 8px;
    div {
      order: 1;
      width: max-content;
    }
    button {
      order: 2;
    }
  }
  ${theme.media.desktop} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const LeftItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  max-width: 114px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  ${theme.media.tablet} {
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    max-width: 154px;
  }
  ${theme.media.desktop} {
    max-width: 214px;
  }
`;

export const RightItem = styled.div`
  flex: 1;
  width: 100%;
  .red-text {
    font-weight: 500;
    font-size: 12;
    line-height: 12px;
    color: #fb2121;
    margin-top: 4px;
  }
`;

export const DateRangeItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  .red-text {
    font-weight: 500;
    font-size: 12;
    line-height: 12px;
    color: #fb2121;
  }
  ${theme.media.tablet} {
    flex-direction: row;
    align-items: center;
    gap: 0px;
    .red-text {
      margin-left: 12px;
    }
  }
`;

export const CheckboxItem = styled.div`
  display: flex;
  width: 224px;
  align-items: center;
  padding: 7px 12px 8px;
  gap: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  div {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
`;

export const CheckboxBlock = styled.div`
  margin-left: 2px;
  .left,
  .right {
    display: flex;
    align-items: center;
    gap: 18px;
    width: 100%;
  }
  .left {
    margin-bottom: 20px;
  }
  ${theme.media.tablet} {
    display: flex;
    gap: 18px;
    .left,
    .right {
      width: unset;
    }
    .left {
      margin-bottom: 0px;
    }
  }
`;

export const CheckboxItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  div {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
`;

export const AudioContainer = styled.div`
  display: flex;
  padding: 12px 0px 11px;
  border-bottom: 1px solid #f2f2f2;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  ${theme.media.tablet} {
    align-items: center;
    gap: 0px;
    flex-direction: row;
    padding: 12px 16px 11px;
  }
`;

export const AudioWrapper = styled.div`
  flex: 1;
  display: flex;
  gap: 20px;
  flex-direction: column;
  .ant-radio-wrapper {
    height: max-content;
    align-items: center;
    display: flex;
  }
  .ant-radio-wrapper::after {
    display: none;
  }
  .ant-radio-wrapper > span:not(.ant-radio) {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    padding-left: 6px;
    padding-right: 0;
  }
  .ant-radio {
    top: 0;
  }
  .ant-radio-inner {
    border: 2px solid #cccccc;
    width: 20px;
    height: 20px;
  }
  .ant-radio-checked .ant-radio-inner {
    border: 2px solid #cccccc;
    width: 20px;
    height: 20px;
  }
  .ant-radio-inner::after {
    background-color: #2ac769;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
  }
  .ant-radio:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-checked .ant-radio-inner {
    border-color: #cccccc;
  }
  ${theme.media.tablet} {
    gap: 18px;
    flex-direction: row;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  padding: 12px 0px 11px;
  border-bottom: 1px solid #f2f2f2;
  > div:last-child {
    width: 100%;
  }
  ${theme.media.tablet} {
    gap: 0px;
    align-items: center;
    flex-direction: row;
  }
  ${theme.media.tablet} {
    padding: 12px 16px 11px;
  }
`;

export const ItemsContainerNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 0px 11px;
  border-bottom: 1px solid #f2f2f2;
  ${theme.media.tablet} {
    align-items: center;
  }
  ${theme.media.tablet} {
    padding: 12px 16px 11px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 32px;
  width: 100%;
  flex-direction: column-reverse;
  ${theme.media.tablet} {
    flex-direction: row;
    width: unset;
    justify-content: flex-end;
    gap: 24px;
  }
  ${theme.media.laptop} {
    button:first-child {
      :hover {
        opacity: 0.65;
      }
    }
    button:last-child {
      :hover {
        opacity: 0.65;
      }
    }
  }
`;

export const DownloadButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  button {
    width: 50%;
  }
  ${theme.media.tablet} {
    width: max-content;
    gap: 8px;
    button {
      width: unset;
    }
  }
`;

export const SingleButtonWrapper = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 100%;
  }
  div {
    margin-top: 32px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #fb4e4e;
    text-decoration: underline;
    cursor: pointer;
  }
  ${theme.media.tablet} {
    button {
      width: unset;
    }
  }
`;

export const DetailsTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
  .alert {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border-radius: 8px;
    text-align: center;
    /* background: rgba(235, 195, 0, 0.15);
    color: #997f00; */
    padding: 7px 8px 8px;
    min-height: 55px;
    display: flex;
    div {
      flex: 1;
      align-self: center;
    }
  }
  ${theme.media.tablet} {
    margin-bottom: 16px;
    justify-content: initial;
    gap: 76px;
    .alert {
      flex: 1;
    }
  }
  ${theme.media.desktop} {
    margin-bottom: 12px;
    gap: 148px;
  }
`;

export const Tooltip4AlertMobile = styled.div`
  position: absolute;
  top: 0;
  right: 98px;
  height: max-content;
  width: 173px;
  background: ${theme.colors.white};
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.bold};
  line-height: 20px;
  padding: 8px;
  z-index: 100;
  border-radius: 8px;
  border: 1px solid ${theme.colors.border};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: 11px;
    right: -4px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 11px;
    right: -1px;
    width: 6px;
    height: 10px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;
export const Tooltip4AlertTabletDesktop = styled.div`
  position: absolute;
  top: 0;
  left: 98px;
  height: max-content;
  width: 173px;
  background: ${theme.colors.white};
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.bold};
  line-height: 20px;
  padding: 8px;
  z-index: 100;
  border-radius: 8px;
  border: 1px solid ${theme.colors.border};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: 11px;
    left: -4px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: -1px;
    width: 6px;
    height: 10px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;
export const WapperSelectTimeForItem = styled.div`
  ${theme.media.mobileM} {
    padding-left: 0px;
    .dleft,
    .dright {
      display: flex;
      width: 100%;
    }
    .dright {
      margin-top: 10px;
    }
  }
  ${theme.media.tablet} {
    .bor-div-time {
      align-items: center;
      display: flex;
    }
    .dleft,
    .dright {
      display: flex;
      width: auto;
    }
    .dright {
      margin: auto;
      margin-top: 0px;
    }
  }
  ${theme.media.laptop} {
    .ant-select-selection-item {
      padding-right: 18px;
    }
  }
`;

export const NotifyWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  flex-direction: column;
  width: 100%;
  svg {
    display: none;
  }
  .notify-title {
    color: ${theme.colors.text.primary};
    width: 100%;
    margin-bottom: 10px;
  }
  .button-block {
    width: 100%;
    display: flex;
    button {
      width: 50%;
      height: 23px;
    }
  }
  button:disabled {
    background: #e0e0e0;
  }
  ${theme.media.tablet} {
    margin-left: 30px;
    flex-direction: row;
    width: unset;
    .notify-title {
      margin-right: 4px;
      margin-bottom: 0px;
      min-width: 132px;
    }
    svg {
      display: block;
      position: absolute;
      left: -24px;
      bottom: -4px;
    }
    .button-block {
      button {
        width: max-content;
      }
    }
  }
`;

export const CheckboxText = styled.div`
  display: flex;
  gap: 14px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TagsContainer = styled.div`
  width: 100%;
  height: 89px;
  max-height: 89px;
  padding: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 16px 0px 13px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }
  ${theme.media.tablet} {
    height: 62px;
    max-height: 62px;
  }
`;

export const WrapperBtnForm = styled.div`
  padding: 104px 0px 24px;
  border-top: 0;

  button {
    width: 100%;
    padding: 11px 0 13px;
  }

  ${theme.media.tablet} {
    padding: 0px 0px 24px;
    button {
      width: 128px;
      padding: 11px 24px 13px;
    }
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-bottom: 32px;
  ${theme.media.desktop} {
    flex-direction: row;
    gap: 32px;
  }
`;

export const FirstTable = styled.div`
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #cccccc;
  border-bottom: none;
  .first-line {
    padding: 8px 16px;
    border-bottom: 1px solid #cccccc;
    background: #f2f2f2;
    font-weight: 700;
  }
  .second-line,
  .third-line {
    display: flex;
    border-bottom: 1px solid #cccccc;
    div {
      padding: 8px 16px;
    }
    > div:first-child {
      border-right: 1px solid #cccccc;
      width: 60%;
    }
  }
  .fourth-line {
    padding: 8px 16px;
    line-height: 24px;
  }
  ${theme.media.tablet} {
    width: 100%;
    .second-line,
    .third-line {
      > div:first-child {
        width: 35%;
      }
    }
  }
  ${theme.media.desktop} {
    width: 50%;
  }
`;

export const SecondTable = styled.div`
  font-size: 16px;
  line-height: 16px;
  height: max-content;
  border: 1px solid #cccccc;
  .first-line {
    border-bottom: 1px solid #cccccc;
    background: #f2f2f2;
    font-weight: 700;
    > div:last-child {
      text-align: right;
    }
  }
  .line {
    display: flex;
    position: relative;
    border-bottom: 1px solid #cccccc;
    div {
      padding: 8px 16px;
    }
    > div:first-child {
      border-right: 1px solid #cccccc;
      width: 50%;
    }
    > div:last-child {
      text-align: right;
      width: 50%;
    }
  }
  .line:last-child {
    border-bottom: none;
  }

  ${theme.media.tablet} {
    width: 100%;
    .line {
      > div:first-child {
        width: 50%;
      }
    }
  }
  ${theme.media.desktop} {
    width: 50%;
    .line {
      > div:first-child {
        width: 60%;
      }
      > div:last-child {
        width: 40%;
      }
    }
  }
`;

export const TableStatisticsWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const TableContainer = styled.div`
  border: 1px solid #cccccc;
  border-right: none;
  border-bottom: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-top: 16px;
  margin-bottom: 8px;
  min-width: 770px;
  .header {
    background: #f2f2f2;
    height: max-content;
    ${theme.media.desktop} {
      min-height: 52px;
      height: auto;
    }
  }
  .line {
    display: flex;
    border-bottom: 1px solid #cccccc;
    .cell {
      position: relative;
      &::after {
        content: "";
        min-height: 52px;
        height: auto;
        width: 1px;
        background: #cccccc;
        right: -1px;
        top: calc(50% - 26px);
        position: absolute;
      }
    }
    div:first-child {
      width: 35%;
      border-right: 1px solid #cccccc;
      padding: 17px 16px;
    }
    > div:last-child {
      width: 76px;
      text-align: center;
      ${theme.media.desktop} {
        width: 96px;
      }
    }
    div:nth-child(2) {
      display: flex;
      flex: 1;
      text-align: center;
      div {
        width: 33.33%;
        line-height: 18px;
        margin: auto 0;
        padding: 0;
        text-align: center;
        border-right: 1px solid #cccccc;
        justify-content: center;
        flex-direction: column;
        padding: 17px 16px;
        ${theme.media.desktop} {
          margin: 0;
        }
      }
    }
  }
  .title {
    background: #e0e0e0;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    width: 100%;
    padding: 8px 16px 8px 8px;
    border-bottom: 1px solid #cccccc;
  }
  .evaluation {
    display: flex;
    border-bottom: 1px solid #cccccc;
    font-weight: 700;
    font-size: 16px;
    height: max-content;
    line-height: 31px;
    color: #ffffff;
    div:first-child {
      width: 35%;
      border-right: 1px solid #cccccc;
      > div {
        min-width: 80px;
        background: #f2f2f2;
        margin-left: auto;
        border-right: none;
        font-weight: 500;
        padding: 8px 16px 12px 15px;
        color: #3b3b3b;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
      }
    }
    > div:last-child {
      border-right: none;
    }
    div:nth-child(2) {
      display: flex;
      flex: 1;
      div {
        width: 33.33%;
        padding: 0;
        border-right: 1px solid #cccccc;
        justify-content: center;
      }
    }
  }
  .rating {
    display: flex;
    border-bottom: 1px solid #cccccc;
    font-weight: 500;
    font-size: 16px;
    min-height: 32px;
    height: max-content;
    color: #3b3b3b;
    text-align: center;
    > div svg {
      position: relative;
      top: 2px;
    }
    > div div.background {
      background: #e0e0e0 !important;
    }
    > div div.background-blur {
      background: #f2f2f2 !important;
    }
    > div:first-child {
      width: 35%;
      border-right: 1px solid #cccccc;
      text-align: left;
      padding: 8px 16px;
      line-height: 20px;
      > div {
        width: 80px;
        margin-left: auto;
        border-right: none;
      }
      ${theme.media.desktop} {
        line-height: 16px;
      }
    }
    > div:last-child {
      width: 76px;
      font-weight: 700;
      ${theme.media.desktop} {
        width: 96px;
      }
    }
    > div:nth-child(2) {
      display: flex;
      flex: 1;
      div {
        width: 33.33%;
        padding: 0;
        border-right: 1px solid #cccccc;
        justify-content: center;
      }
    }
  }
  ${theme.media.desktop} {
    .rating {
      > div:first-child {
        width: 50%;
      }
    }
    .evaluation {
      div:first-child {
        width: 50%;
      }
    }
    .line {
      div:first-child {
        width: 50%;
      }
      div:first-child {
        padding: 17px 16px;
      }
    }
  }
`;

export const NoteText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  color: #666666;
  ${theme.media.tablet} {
    width: unset;
  }
`;

export const MentalHealthSituationTitleWrapper = styled.div`
  display: flex;
  justify-content: initial;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
  .back {
    border-radius: 50%;
    border: 1px solid #fbfbfb;
    box-shadow: 1px 1px 8px rgb(0 0 0 / 12%);
    cursor: pointer;
  }
  ${theme.media.tablet} {
    margin-bottom: 21px;
  }
  ${theme.media.desktop} {
  }
`;

export const MentalHealthSituationContentWrapper = styled.div`
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  button {
    position: absolute;
    right: 16px;
  }
  ${theme.media.desktop} {
    margin-bottom: 24px;
  }
`;

export const FlagSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    margin-bottom: 2px;
  }
`;

export const BoxContentChart = styled.div`
  display: flex;
  gap: 37px;
  width: 100%;
`;

export const BoxItem = styled.div`
  background: #fbfbfb;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  ${theme.media.tablet} {
    height: 220px;
  }
`;

export const ChartDivider = styled.div`
  padding: 16px 0px;
  margin: 0px 32px;
  background: #cccccc;
  width: 1px;
  height: 100%;
`;

export const ContainerChartText = styled.div`
  transform: rotateY(180deg);
  text-align: center;
  div {
    font-weight: ${theme.fontWeight.bold};
    font-size: 44px;
    line-height: 44px;
    color: #2ac769;
    span {
      font-weight: ${theme.fontWeight.regular};
      font-size: ${theme.sizes.md};
      line-height: 16px;
      padding-left: 1px;
    }
  }
`;

export const Square = styled.div`
  width: 10px;
  height: 10px;
  margin-top: 1px;
`;

export const RadarChartWrapper = styled.div`
  width: 100%;
  p {
    width: 100%;
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }
`;

export const RadarChartItem = styled.div`
  width: 100%;
  height: 280px;
`;

export const HeaderSection = styled.div`
  display: flex;
  gap: 16px;
  .right {
    display: flex;
    height: auto;
    div {
      width: 46px;
      padding: 5px 9px 16px;
      color: #666666;
      background: #f2f2f2;
      border-radius: 2px 2px 0px 0px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    > div:first-child {
      border-right: 1px solid #ffffff;
      width: 47px;
    }
  }
`;

export const ChartContentContainer = styled.div`
  display: flex;
  gap: 24px;
  .chart-section {
    flex: 1;
  }
  .checkbox-section {
    width: 328px;
    height: 41px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    .right {
      display: flex;
      height: auto;
      div {
        width: 46px;
        padding: 13px 12px 15px 11px;
        height: max-content;
        color: #666666;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 2px 2px 0px 0px;
        font-weight: 500;
        border-bottom: 1px solid #f2f2f2;
      }
      > div:first-child {
        border-right: 1px solid #ffffff;
        padding: 10px 13px;
        line-height: 8px;
        width: 47px;
      }
    }
  }
`;

export const CheckboxChartItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .dot-text {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    .circle {
      width: 17px;
      height: 17px;
      margin: 1px;
      margin-left: 0px;
      border-radius: 50%;
    }
    .square {
      width: 16px;
      height: 16px;
      margin: 2px;
      margin-left: 0px;
    }
    .triangle {
      width: 0px;
      height: 0px;
      border-right: 9px solid transparent;
      border-left: 9px solid transparent;
      border-bottom: 15.5884572px solid transparent;
    }
  }
`;

export const ClassSelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${theme.media.tablet} {
    flex-direction: row;
  }
`;
export const WapperMentalHealthTotal = styled.div`
  .p-title-chart {
    color: #3b3b3b;
    font-weight: 700;
    margin-top: 20px;
    span {
      color: #b7b7b7;
    }
  }
  .header-health-total {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    h3 {
      margin-top: 4px;
    }
  }
  .content-health-total {
    padding: 16px 8px;
    .left {
      width: 100%;
      margin-bottom: 24px;
    }
    .right {
      border-top: 1px dashed #cccccc;
    }
  }
  ${theme.media.tablet} {
    .header-health-total {
      flex-direction: row;
      align-items: center;
    }
    .content-health-total {
      padding: 16px 16px 16px 24px;
      display: flex;
      .left {
        margin-bottom: 0px;
        width: max-content;
      }
      .right {
        border-left: 1px dashed #cccccc;
        padding-left: 34px;
        border-top: none;
      }
    }
  }
`;

export const WrapperChartText = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
`;

export const ContainerPieChartText = styled.div`
  transform: rotateY(180deg);
  text-align: center;
  span {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.md};
    line-height: 16px;
  }
  div:first-child {
    font-weight: ${theme.fontWeight.medium};
    font-size: 28px;
    line-height: 28px;
  }
  div:last-child {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.xs};
    line-height: 16px;
    margin-top: 0px;
  }
  .text-big {
    font-weight: ${theme.fontWeight.medium};
    font-size: 28px;
  }
  .text-small {
    font-weight: 400;
    font-size: ${theme.sizes.xs};
    line-height: 12px;
    color: #3b3b3b;
  }
`;

export const PieChartColorScheme = styled.div`
  position: absolute;
  right: 32px;
  top: 105px;
  .item {
    display: flex;
    gap: 4px;
    align-items: center;
    font-weight: 500;
    .square {
      width: 10px;
      height: 10px;
      background: #e0e0e0;
    }
  }
  .item:last-child {
    margin-top: 10px;
  }
  .item div:last-child {
    font-size: 10px;
    line-height: 9px;
  }
  ${theme.media.tablet} {
    right: -44px;
  }
`;

export const PieChartColorSchemeSecond = styled.div`
  position: absolute;
  right: 48px;
  top: 166px;
  .item {
    display: flex;
    gap: 4px;
    align-items: center;
    font-weight: 500;
    .square {
      width: 10px;
      height: 10px;
      background: #e0e0e0;
    }
  }
  .item div:last-child {
    font-size: 10px;
    line-height: 9px;
  }
  ${theme.media.tablet} {
    right: -30px;
    top: 148px;
  }
`;

export const PieChartColorSchemeThird = styled.div`
  position: absolute;
  right: 48px;
  top: 125px;
  .item {
    display: flex;
    gap: 4px;
    align-items: center;
    font-weight: 500;
    .square {
      width: 10px;
      height: 10px;
      background: #e0e0e0;
    }
  }
  .item div:last-child {
    font-size: 10px;
    line-height: 9px;
  }
  ${theme.media.tablet} {
    right: -30px;
    top: 126px;
  }
`;

export const WrapperSizeTypeChange = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  .left {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    .ant-select-arrow {
      top: calc(50% - 4px);
    }
  }
  .right {
    order: 1;
    width: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 24px;
    .item {
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
  ${theme.media.tablet} {
    flex-direction: row;
    width: unset;
    .left {
      order: 1;
      width: unset;
    }
    .right {
      order: 2;
      width: unset;
      justify-content: flex-start;
    }
  }
`;

export const TitleTestContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${theme.media.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 16px;
  .filter-block {
    display: flex;
    width: 100%;
    gap: 26px;
    flex-direction: column;
  }
  .button-block {
    button {
      display: none;
    }
  }
  .third-block {
    width: 100%;
    button {
      width: 100%;
      height: 30px;
    }
  }
  ${theme.media.tablet} {
    gap: 0px;
    justify-content: space-between;
    flex-direction: row;
    .filter-block {
      width: max-content;
    }
    .button-block {
      display: flex;
      justify-content: space-between;
    }
    .third-block {
      width: unset;
      height: auto;
      display: flex;
      align-items: center;
    }
  }
  ${theme.media.desktop} {
    .filter-block {
      gap: 32px;
      flex-direction: row;
    }
    .button-block {
      display: flex;
      justify-content: initial;
      align-items: flex-end;
      gap: 48px;
      button {
        display: block;
        height: 30px;
      }
    }
    .third-block {
      display: none;
    }
  }
`;

export const ExportButtonsWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 24px;
  button {
    height: 23px;
    width: 50%;
  }
  ${theme.media.tablet} {
    display: none;
  }
`;

export const ActionSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 8px 8px;
  button {
    height: 23px;
    width: 50%;
  }
  ${theme.media.tablet} {
    padding: 8px 8px 9px;
  }
`;

export const NewMentalCheckNoti = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px !important;
  span {
    color: #fb4e4e;
  }
`;

export const TotalBadge = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 3px 6px;
  color: #ffffff;
  background: #83bce2;
  border-radius: 10px;
`;

export const PieChartWrapperTotal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 16px;
  ${theme.media.tablet} {
    margin-right: 68px;
  }
`;

export const PieChartWrapperSymptom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px dashed #cccccc;
  .label {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: #666666;
    text-align: center;
    margin-top: 16px;
  }
  .badge {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    padding: 3px 6px;
    color: #ffffff;
    background: #83bce2;
    border-radius: 10px;
    margin-top: 4px;
  }
  ${theme.media.tablet} {
    padding-bottom: 0px;
    border-bottom: none;
    margin-right: 55px;
    .label {
      margin-top: 0px;
    }
  }
`;

export const PieChartWrapperThird = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  ${theme.media.tablet} {
    margin-right: 68px;
  }
  ${theme.media.desktop} {
  }
`;

export const WrapperPieChartGroup = styled.div`
  width: 100%;
  border-top: 1px dashed #cccccc;
  .first {
    .title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      text-align: center;
      margin-top: 24px;
    }
  }
  .second {
    border-top: 1px dashed #cccccc;
    width: 100%;
  }
  ${theme.media.tablet} {
    width: unset;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-left: 1px dashed #cccccc;
    padding-left: 24px;
    border-top: none;
    .second {
      border-top: none;
      width: max-content;
    }
    .first {
      border-bottom: 1px solid #cccccc;
      padding-bottom: 24px;
      .title {
        text-align: left;
        margin-bottom: 32px;
        margin-top: 0px;
      }
    }
  }
  ${theme.media.desktop} {
    flex-direction: row;
    .first {
      border-bottom: none;
      border-right: 1px dashed #cccccc;
      padding-bottom: 0px;
      .title {
        text-align: left;
        margin-bottom: 24px;
      }
    }
    .second {
      padding-left: 24px;
    }
  }
`;

export const SymptomTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
  ${theme.media.tablet} {
    text-align: left;
    margin-bottom: 58px;
  }
  ${theme.media.desktop} {
    margin-bottom: 48px;
  }
`;

export const SymptomTitleThird = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: center;
  ${theme.media.tablet} {
    text-align: left;
    margin-bottom: 32px;
  }
  ${theme.media.desktop} {
    margin-top: 0px;
    margin-bottom: 48px;
  }
`;

export const PieChartsGroupWrapper = styled.div`
  ${theme.media.tablet} {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    > div:nth-child(2) {
      margin-left: 16px;
    }
  }
  ${theme.media.desktop} {
    margin-right: 16px;
    gap: 48px;
    > div:nth-child(2) {
      margin-left: 0px;
    }
  }
`;

export const NameGroupWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  .button-group {
    background: #ffffff;
    border-radius: 4px;
    height: 40px;
    width: max-content;
    .badge {
      background: #fb4e4e;
      border-radius: 4px;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      padding: 2px 4px 2px;
    }
    .button {
      display: flex;
      justify-content: center;
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
      color: #2ac769;
      padding: 4px 3px 5px 4px;
      gap: 1px;
      cursor: pointer;
      svg {
        width: 12px;
        height: 10px;
      }
    }
  }
`;

export const ModalStyled = styled(Modal)`
  width: 343px !important;
  top: 50%;
  transform: translate(0%, -50%);
  .ant-modal-title {
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    padding: 26px 16px;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 8px 8px 0 0;
  }
  .ant-modal-body {
    padding: 24px 16px;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      margin: 16px 0px 13px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
      background: ${theme.colors.background};
      border-radius: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 7px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
    }
  }
  .ant-modal-close {
    top: 12px;
    right: -4px;
  }
  .ant-modal-footer {
    padding: 0px 16px 20px;
    border: unset;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    button {
      width: 50%;
      min-width: max-content;
    }
    button:last-child {
      padding: 12px 26px;
    }
  }
  ${theme.media.tablet} {
    min-width: 720px !important;
    width: fit-content !important;
    .ant-modal-header {
      padding: 26px 24px;
    }
    .ant-modal-body {
      padding: 32px 24px 24px;
    }
    .ant-modal-close {
      right: 5px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
      justify-content: flex-end;
      button {
        width: unset;
      }
      button:last-child {
        padding: 12px 32px;
      }
    }
  }
  div {
    line-height: 16px;
  }
`;
