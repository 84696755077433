import { IResponse } from "models";

export enum ActionType {
  DATA_STATISTICS = "action/DATA_STATISTICS",
  DATA_STATISTICS_SUCCESS = "action/DATA_STATISTICS_SUCCESS",
  DATA_STATISTICS_ERROR = "action/DATA_STATISTICS_ERROR",
  DATA_STATISTICS_RESET = "action/DATA_STATISTICS_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DataStatisticsResponse extends IResponse {
  answers: any;
  total_number: number;
  all_member_amount: number;
  all_patients: {
    id: number;
    full_name: string;
  }[];
}

export interface BodyTemperatureData {
  aggregate_date?: string;
  department_id?: number[];
  sub_department_id?: number[];
  temperature_filter?: number;
  temperature?: string[];
  symptons_filter?: number;
  checked_at?: string[];
  attendance?: number[];
  family_health?: number[];
  app_language_id?: string;
  marathon_filter?: number;
  health_check?: number[];
  small_mental_check?: number[];
  page?: number;
  per_page?: number;
}
