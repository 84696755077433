import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ProgressResponse,
  ActionType,
  ProgressPayload,
} from "models/dashboard/progress";
import { httpStatus } from "configs/httpStatus";
import { fetchProgressChart } from "services/dashboard";
import { handleSuccesAction, handleErrorAction } from "./progressActions";

function* getProgressSaga({ payload }: { payload: ProgressPayload }) {
  try {
    const response: ProgressResponse = yield call(fetchProgressChart, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetProgressWatcher() {
  yield takeLatest(ActionType.GET_PROGRESS_CHART as any, getProgressSaga);
}

export default [fork(onGetProgressWatcher)];
