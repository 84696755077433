import * as React from "react";

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1751 9.99997L16.4251 4.7583C16.582 4.60139 16.6702 4.38856 16.6702 4.16664C16.6702 3.94472 16.582 3.73189 16.4251 3.57497C16.2682 3.41805 16.0554 3.3299 15.8334 3.3299C15.6115 3.3299 15.3987 3.41805 15.2418 3.57497L10.0001 8.82497L4.75845 3.57497C4.60153 3.41805 4.3887 3.3299 4.16678 3.3299C3.94486 3.3299 3.73203 3.41805 3.57511 3.57497C3.41819 3.73189 3.33004 3.94472 3.33004 4.16664C3.33004 4.38856 3.41819 4.60139 3.57511 4.7583L8.82511 9.99997L3.57511 15.2416C3.497 15.3191 3.43501 15.4113 3.3927 15.5128C3.3504 15.6144 3.32861 15.7233 3.32861 15.8333C3.32861 15.9433 3.3504 16.0522 3.3927 16.1538C3.43501 16.2553 3.497 16.3475 3.57511 16.425C3.65258 16.5031 3.74475 16.5651 3.8463 16.6074C3.94785 16.6497 4.05677 16.6715 4.16678 16.6715C4.27679 16.6715 4.38571 16.6497 4.48726 16.6074C4.58881 16.5651 4.68098 16.5031 4.75845 16.425L10.0001 11.175L15.2418 16.425C15.3192 16.5031 15.4114 16.5651 15.513 16.6074C15.6145 16.6497 15.7234 16.6715 15.8334 16.6715C15.9435 16.6715 16.0524 16.6497 16.1539 16.6074C16.2555 16.5651 16.3476 16.5031 16.4251 16.425C16.5032 16.3475 16.5652 16.2553 16.6075 16.1538C16.6498 16.0522 16.6716 15.9433 16.6716 15.8333C16.6716 15.7233 16.6498 15.6144 16.6075 15.5128C16.5652 15.4113 16.5032 15.3191 16.4251 15.2416L11.1751 9.99997Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default CloseIcon;
