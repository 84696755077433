import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Input, Button, Select } from "components";
import i18n from "configs/i18n";
import { WrapperErrorMessage, ErrorMessage } from "theme/CommonStyle";

import { LinkStyled } from "theme/CommonStyle";
import { ActionCreators } from "redux/rootActions";
import { LoginData } from "models/authentication";
import {
  selectIsLoading,
  selectErrorMessage,
} from "redux/authentication/authenticationStates";

const WrapperBottom = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  a: {
    ...LinkStyled(theme),
  },
}));

const schema = yup.object().shape({
  email: yup.string().trim().required("email-required"),
  password: yup.string().required("password-required"),
});

const Login: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "auth" });

  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const errorMessage = useSelector(selectErrorMessage);

  const { watch, handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const watchEmailField = watch("email");
  const watchPasswordField = watch("password");
  const watchRoleField = watch("role");

  const options = [
    {
      id: 1,
      value: 1,
      name: t("administrator"),
    },
    {
      id: 2,
      value: 2,
      name: t("editor"),
    },
  ];

  useEffect(() => {
    dispatch(ActionCreators.handleErrorAction(""));
  }, [watchEmailField, watchPasswordField, watchRoleField]);

  useEffect(() => {
    setValue("role", options[0].value);
    i18n.changeLanguage("ja");
  }, []);

  const onSubmit = (data: any) => {
    const params: LoginData = {
      user: {
        ...data,
      },
      locale: "ja",
    };
    dispatch(ActionCreators.loginUserAction(params));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange }, fieldState: { error } }) => (
            <Input
              label={t("email")}
              type="email"
              onChange={onChange}
              error={t(error?.message)}
              marginForm="0 0 20px 0"
              fs={16}
              fwLabel={500}
            />
          )}
        />
        <div style={{ position: "relative" }}>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange }, fieldState: { error } }) => (
              <Input
                label={t("password")}
                type="password"
                onChange={onChange}
                error={t(error?.message)}
                marginForm="0 0 20px 0"
                fs={16}
                fwLabel={500}
              />
            )}
          />
          {errorMessage && (
            <WrapperErrorMessage style={{ position: "relative", top: -14 }}>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </WrapperErrorMessage>
          )}
        </div>
        <Controller
          control={control}
          name="role"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              value={value}
              label={t("role")}
              onChange={onChange}
              error={error?.message}
              marginForm="0 0 20px 0"
              options={options}
              height="40px"
              fs={16}
              fwLabel={500}
            />
          )}
        />
        <Button
          name={t("login")}
          type="submit"
          background="#2F8CAE"
          color="#fff"
          bdr="6px"
          width="100%"
          margin="4px 0 24px 0"
          padding="8px 0px"
          border="none"
          fontWeight={700}
          fontSize={16}
          disabled={isLoading}
        />
      </form>
      <WrapperBottom>
        <Link to="/forgot-password">{t("forgot-password")}</Link>
      </WrapperBottom>
    </>
  );
};

export default Login;
