import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  IApproveAbsenceResponse,
  IApproveAbsencePayload,
} from "models/bodyTemperature/approveAbsence";
import { httpStatus } from "configs/httpStatus";
import { approveAbsence } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./approveAbsenceActions";

function* approveAbsenceSaga({ payload }: { payload: IApproveAbsencePayload }) {
  try {
    const response: IApproveAbsenceResponse = yield call(
      approveAbsence,
      payload
    );
    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDataChartsWatcher() {
  yield takeLatest(ActionType.APPROVE_ABSENCE as any, approveAbsenceSaga);
}

export default [fork(onGetDataChartsWatcher)];
