import { IResponse } from "models";

export enum ActionType {
  SEND_HEALTH_CHECK_ANSWER = "action/SEND_HEALTH_CHECK_ANSWER",
  SEND_HEALTH_CHECK_ANSWER_SUCCESS = "action/SEND_HEALTH_CHECK_ANSWER_SUCCESS",
  SEND_HEALTH_CHECK_ANSWER_ERROR = "action/SEND_HEALTH_CHECK_ANSWER_ERROR",
  SEND_HEALTH_CHECK_ANSWER_RESET = "action/SEND_HEALTH_CHECK_ANSWER_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface SendHealthCheckAnswersParams {
  payload: any;
}

export interface SendHealthCheckAnswersResponse extends IResponse {
  answer: any;
}
