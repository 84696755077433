import React, { useEffect, useState, Fragment, useRef, useMemo } from "react";
import { Grid, Dropdown, Menu, notification, TableProps } from "antd";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ReactDragListView from "react-drag-listview";

import { Input, Button, Box, Pagination, Select } from "components";
import { PlusIcon, DownloadIcon, EditIcon, MoreIcon } from "icons";
import { IOption } from "components/select/Select";
import {
  localStorageHelper,
  generateDynamicRecords,
  generateCSV,
  mplusFont,
  convertFilename,
} from "utils";

import {
  UserListActionCreators,
  ClassListActionCreators,
  DepartmentListActionCreators,
  ActivitiesListActionCreators,
  CSVDownloadActionCreators,
  SubDepartmentListActionCreators,
  UpdatePositionColumnActionCreators,
  SettingDataActionCreators,
} from "redux/rootActions";
import {
  userListDataResponse,
  selectErrorMessage,
  selectIsLoading,
} from "redux/userlist/userListStates";
import { UserListData, UserListResultData } from "models/userlist";
import { classListDataResponse } from "redux/classlist/classListStates";
import { departmentListDataResponse } from "redux/department/departmentListStates";
import { activitiesListDataResponse } from "redux/activitieslist/activitiesListStates";
import { csvDownloadSuccessResponse } from "redux/csvdownloaduser/csvdownloaduserStates";
import { IUserListDownloadData } from "models/csvdownloaduser";
import { settingDataResponse } from "redux/setting/settingData/settingDataStates";
import { updatePositionColumnDataResponse } from "redux/updatePositionColumn/updatePositionColumnStates";

import {
  TaskWrapper,
  TaskWrapperLeft,
  TaskWrapperRight,
  PaginationWrapper,
  ContentWrapper,
  Text,
  TextLink,
  SearchWrapper,
  SearchWrapperLeft,
  WrapperSelectAndBtn,
  WrapperBtnForm,
  RowCellWrapper,
  UserListTableStyled,
  BadgeContainer,
  SelectWrapper,
} from "./UserList.style";
import UpdatingModal from "./UpdatingModal";
import CreatingModal from "./CreatingModal";
import AttendanceModal from "./AttendanceModal";
import BatchModal from "./BatchModal";
import { httpStatus } from "configs/httpStatus";
import setting from "screens/setting";

type FormStatus = "creating" | "updating" | "batch" | "attendance" | "";

interface ISoftFileds {
  furi_first_name: string;
  class_name: string;
  roll_number: string;
  gender: string;
}

interface ISoftOrder {
  descend: string;
  ascend: string;
}

interface DataType extends ISoftFileds {
  first_name: string;
  group_list: string;
}

const softFields: ISoftFileds = {
  furi_first_name: "other_name_type_sort",
  class_name: "grade_sort",
  roll_number: "attendance_number_sort",
  gender: "gender_sort",
};

const softOrder: ISoftOrder = {
  descend: "desc",
  ascend: "asc",
};

const { useBreakpoint } = Grid;

const UserList: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const { t: t1 }: any = useTranslation("translation", { keyPrefix: "layout" });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const { watch, handleSubmit, control, setValue } = useForm();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const userlistdata = useSelector(userListDataResponse);
  const classlistdata = useSelector(classListDataResponse);
  const errorMessageUserList = useSelector(selectErrorMessage);
  const departmentlistdata = useSelector(departmentListDataResponse);
  const activitieslistdata = useSelector(activitiesListDataResponse);
  const isLoading = useSelector(selectIsLoading);
  const csvDownloadSuccess = useSelector(csvDownloadSuccessResponse);
  const settingData = useSelector(settingDataResponse);
  const updatePositionColumnData = useSelector(
    updatePositionColumnDataResponse
  );

  const scrollAbleRef = useRef<any>(undefined);
  const [visible, setVisible] = useState(false);
  const [formStatus, setFormStatus] = useState<FormStatus>("");
  const [selectedItem, setSelectedItem] = useState<UserListResultData>();
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [schoolYearOptions, setSchoolYearOptions] = useState<any>([]);
  const [fileType, setFileType] = useState<any>();
  const [input, setInput] = useState<UserListData>({
    page: 1,
    per_page: 50,
    locale: currentLanguage,
  });
  const [oldName, setOldName] = useState("");
  const [oldGrade, setOldGrade] = useState("");
  const [data, setData] = useState<any>([]);

  const totalRecord = userlistdata?.total_record || 0;
  const classListOptions: any = [
    ...[{ id: -1, value: "", name: t("select-grade-or-class") }],
    ...(classlistdata?.result || []),
  ];

  const watchClassField = watch("grade");
  const watchSearchNameField = watch("name");

  const registerBtnName = screens.md ? t("user-registration") : t("register");
  const batchRegisterBtnName = screens.md
    ? t("user-batch-registration")
    : t("batch-registration");

  useEffect(() => {
    const params = {
      locale: currentLanguage,
    };

    setValue("grade", "");
    dispatch(ClassListActionCreators.classListAction(params));
    dispatch(ActivitiesListActionCreators.activitiesListAction(params));
    dispatch(
      DepartmentListActionCreators.departmentListAction({
        ...params,
        role: localStorageHelper.getItem("role"),
      })
    );
  }, []);

  const handleGetUserList = () => {
    const params: UserListData = {
      ...input,
    };

    dispatch(UserListActionCreators.userListAction(params));
  };

  useEffect(() => {
    handleGetUserList();
  }, [input.per_page, input.page]);

  const genderOptions = [
    {
      id: 0,
      name: t("male"),
      value: 0,
    },
    {
      id: 1,
      name: t("female"),
      value: 1,
    },
    {
      id: 2,
      name: t("no-answer"),
      value: 2,
    },
  ];

  const handleFileDownload = () => {
    const params: IUserListDownloadData = {
      c_department_id: watchClassField ? watchClassField : "",
      user_name: watchSearchNameField || "",
      locale: currentLanguage,
    };
    dispatch(CSVDownloadActionCreators.csvDownloadUserAction(params));
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          icon={
            <DownloadIcon
              width="14px"
              height="14px"
              fill="currentColor"
              style={{ position: "absolute", left: 4, top: 6 }}
            />
          }
          name={t("excel-output")}
          background="#2AC769"
          color="#FFFFFF"
          border="none"
          fontSize={12}
          fontWeight={700}
          padding="3px 7px 3px 16px"
          onClick={() => {
            setFileType("csv");
            handleFileDownload();
          }}
          margin="0 8px 0"
        />
        <Button
          icon={
            <DownloadIcon
              width="14px"
              height="14px"
              fill="currentColor"
              style={{ position: "absolute", left: 4, top: 6 }}
            />
          }
          name={t("pdf-output")}
          background="#2AC769"
          color="#FFFFFF"
          border="none"
          fontSize={12}
          fontWeight={700}
          padding="3px 7px 3px 16px"
          onClick={() => {
            setFileType("pdf");
            handleFileDownload();
          }}
        />
      </Menu.Item>
    </Menu>
  );

  const showModal = (formStatus: FormStatus) => {
    setFormStatus(formStatus);
    setVisible(true);
  };

  const genderGroup: any = {
    0: t("male"),
    1: t("female"),
    2: t("no-answer"),
  };

  const columns = [
    {
      title: t("full-name"),
      dataIndex: ["last_name", "first_name"],
      key: "first_name",
      width: 175,
      fixed: window.innerWidth < 576 ? "" : "left",
      className: "disabled-drag-column",
      columnName: "full_name",
      render: (name: string, row: any) => (
        <div style={{ display: "flex" }}>
          <div style={{ marginTop: 3 }}>
            <EditIcon
              onClick={() => {
                showModal("updating");
                const subDepartmentparams = {
                  locale: currentLanguage,
                  department_id: row.department_id,
                  role: localStorageHelper.getItem("role"),
                };
                dispatch(
                  SubDepartmentListActionCreators.subDepartmentListAction(
                    subDepartmentparams
                  )
                );
              }}
              fill="currentColor"
              style={{ cursor: "pointer" }}
              width="16px"
              height="16px"
            />
          </div>
          <TextLink
            normal
            // onClick={() => showModal("attendance")}
            isBgUdl={settingData?.result.is_display_user_id && !row["user_id"]}
          >
            {row["last_name"] + " " + row["first_name"]}
          </TextLink>
        </div>
      ),
    },
    {
      title: t("other-name-type"),
      dataIndex: ["furi_last_name", "furi_first_name"],
      key: "furi_first_name",
      width: 155,
      sorter: true,
      columnName: "other_name_type",
      render: (name: string, row: any) => (
        <div style={{ display: "flex" }}>
          <Text style={{ marginLeft: 1 }}>
            {row["furi_last_name"] + " " + row["furi_first_name"]}
          </Text>
        </div>
      ),
    },
    {
      title: t("grade"),
      dataIndex: "class_name",
      key: "class_name",
      columnName: "grade",
      width: 94,
      sorter: true,
    },
    {
      title: t("activities"),
      dataIndex: "group_list",
      key: "group_list",
      columnName: "affiliation",
      width: 188,
      render: (name: string, record: any) => (
        <BadgeContainer>
          {record.group_list &&
            record.group_list.length > 0 &&
            record.group_list.map((activity: any, index: number) => (
              <p key={index}>{activity.name}</p>
            ))}
        </BadgeContainer>
      ),
    },
    {
      title: t("attendance"),
      dataIndex: "roll_number",
      key: "roll_number",
      columnName: "attendance",
      width: 110,
      sorter: true,
    },
    {
      title: t("gender"),
      dataIndex: "gender",
      key: "gender",
      columnName: "gender",
      width: 78,
      sorter: true,
      render: (gender: number) => (
        <RowCellWrapper>{genderGroup[gender]}</RowCellWrapper>
      ),
    },
    // {
    //   title: t("birthday"),
    //   dataIndex: "date_of_birth",
    //   key: "date_of_birth",
    //   width: 130,
    //   // sorter: {},
    // },
    {
      title: "",
      dataIndex: "",
      columnName: "",
      key: "8",
      width: 250,
    },
  ];

  const onSubmit = (data: { [x: string]: any }) => {
    setOldGrade(data.grade);
    setOldName(data.name);

    let page = input.per_page;
    if (oldGrade !== data.grade || oldName !== data.name) {
      page = 1;
    }

    const params: UserListData = {
      ...input,
      page,
      c_department_id: data.grade || "",
      user_name: data.name || "",
    };

    setInput(params);
    dispatch(UserListActionCreators.userListAction(params));
  };

  useEffect(() => {
    if (errorMessageUserList) {
      notification.error({
        placement: "bottomRight",
        message: errorMessageUserList,
      });
      dispatch(UserListActionCreators.handleResetDataAction());
    }
  }, [errorMessageUserList]);

  useEffect(() => {
    if (csvDownloadSuccess && csvDownloadSuccess.result) {
      const convertData = csvDownloadSuccess.result.data;

      if (fileType === "csv") {
        const filename = `${convertFilename("user", new Date())}.csv`;
        generateCSV(filename, csvDownloadSuccess.result.headers, convertData);
      } else {
        const hdrs: any = {
          氏名: t("full-name"),
          クラス: t("class"),
          性別: t("gender"),
        };
        const headers = csvDownloadSuccess.result.headers.map((header: any) => {
          return hdrs[header.label] || header.label;
        });
        const data: any = convertData.map((d: any) => {
          return Object.values(d);
        });

        const doc = new jsPDF();
        doc.addFileToVFS("mplus.ttf", mplusFont);
        doc.addFont("mplus.ttf", "mplus", "normal");
        doc.setFont("mplus", "normal");

        const myTable = {
          head: [[...headers]],
          body: data,
          headStyles: {
            font: "mplus",
          },
          bodyStyles: {
            font: "mplus",
          },
          margin: { top: 15, left: 8, right: 8 },
        };

        autoTable(doc, myTable);
        const filename = `${convertFilename("user", new Date())}.pdf`;
        doc.save(filename);
      }
      dispatch(CSVDownloadActionCreators.handleResetDataAction());
    }
  }, [csvDownloadSuccess]);

  useEffect(() => {
    if (userlistdata && Object.keys(userlistdata).length) {
      let recordOptionsClone: IOption[] = [];
      recordOptionsClone = generateDynamicRecords(
        userlistdata?.total_record,
        t
      );
      setRecordOptions([...recordOptionsClone]);
      setData(userlistdata.result);
    }
  }, [userlistdata]);

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));

    if (
      scrollAbleRef.current &&
      window.scrollY >= scrollAbleRef.current.offsetTop
    ) {
      window.scrollTo(0, 0);
    }
  };

  const handleOnChangeSizeChange = (limit: number) => {
    setInput((prevState: any) => ({ ...prevState, per_page: limit, page: 1 }));
  };

  const handleCancel = () => {
    setVisible(false);
    handleGetUserList();
  };

  const handleSubmitOK = () => {
    setVisible(false);
    handleGetUserList();
  };

  const handleOrderByKey = (arr: any[], order: number, name: string) => {
    const idxItem = arr.findIndex((item: any) => {
      return item.columnName === name.trim();
    });

    if (idxItem > -1) {
      if (idxItem != order) {
        const splicedItem = arr.splice(idxItem, 1);
        arr.splice(order, 0, splicedItem[0]);
      }
    }
  };

  useEffect(() => {
    if (settingData && Object.keys(settingData?.result).length) {
      if (
        !settingData?.result.user_list_info.user_list_column_positions.length
      ) {
        dispatch(
          UpdatePositionColumnActionCreators.updatePositionColumnAction({
            user_list_column_positions_attributes: [
              {
                column_name: "full_name",
                position: 0,
              },
              {
                column_name: "other_name_type",
                position: 1,
              },
              {
                column_name: "grade",
                position: 2,
              },
              {
                column_name: "affiliation",
                position: 3,
              },
              {
                column_name: "attendance",
                position: 4,
              },
              {
                column_name: "gender",
                position: 5,
              },
            ],
          })
        );
      }
    }
  }, [settingData]);

  useEffect(() => {
    if (
      updatePositionColumnData &&
      updatePositionColumnData.status === httpStatus.StatusOK
    ) {
      dispatch(UpdatePositionColumnActionCreators.handleResetAction());
      dispatch(SettingDataActionCreators.getSettingDataAction());
    }
  }, [updatePositionColumnData]);

  const handleSortList = (arr: any[]) => {
    return arr.sort((a: any, b: any) => a.position - b.position);
  };

  const handleDragColumnEnd = (fromIndex: number, toIndex: number) => {
    const settings: any =
      settingData?.result.user_list_info.user_list_column_positions;

    if (fromIndex < 0 || toIndex < 0 || !settings.length) {
      return;
    }

    let reorderSettings: any = [];
    reorderSettings = Array.from(handleSortList(settings));

    const idxItem = reorderSettings.findIndex((item: any) => {
      return item.position === fromIndex;
    });
    const [removed] = reorderSettings.splice(idxItem, 1);

    reorderSettings.splice(toIndex, 0, removed);

    dispatch(
      UpdatePositionColumnActionCreators.updatePositionColumnAction({
        user_list_column_positions_attributes: reorderSettings.map(
          (s: any, position: number) => ({ ...s, position })
        ),
      })
    );
  };

  const tableColumns = useMemo(() => {
    const tableColumns = [...columns];
    const settings: any =
      settingData?.result.user_list_info.user_list_column_positions;

    if (!settings.length) {
      return columns;
    }

    for (const setting of handleSortList(settings)) {
      handleOrderByKey(tableColumns, setting.position, setting.column_name);
    }

    return tableColumns;
  }, [settingData]);

  const ActionSection = () => {
    return (
      <TaskWrapper>
        <TaskWrapperLeft>
          <Button
            onClick={() => showModal("creating")}
            icon={<PlusIcon fill="currentColor" />}
            name={registerBtnName}
            color="#2AC769"
            background="#FFFFFF"
            border="1px solid #1AB759"
            fontSize={14}
            fontWeight={500}
            padding="0px 5px"
          />

          {/* <Button
            onClick={() => showModal("batch")}
            icon={<PlusIcon fill="currentColor" />}
            name={batchRegisterBtnName}
            color="#2AC769"
            background="#FFFFFF"
            border="1px solid #1AB759"
            fontSize={14}
            fontWeight={500}
            padding="0px 5px"
          /> */}
        </TaskWrapperLeft>
        <TaskWrapperRight>
          <Button
            icon={
              <DownloadIcon
                width="14px"
                height="14px"
                fill="currentColor"
                style={{ position: "absolute", left: 4, top: 6 }}
              />
            }
            name={t("excel-output")}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={12}
            fontWeight={700}
            padding="3px 7px 3px 16px"
            onClick={() => {
              setFileType("csv");
              handleFileDownload();
            }}
            margin="0 8px 0"
          />
          <Button
            icon={
              <DownloadIcon
                width="14px"
                height="14px"
                fill="currentColor"
                style={{ position: "absolute", left: 4, top: 6 }}
              />
            }
            name={t("pdf-output")}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={12}
            fontWeight={700}
            padding="3px 7px 3px 16px"
            onClick={() => {
              setFileType("pdf");
              handleFileDownload();
            }}
          />
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            <MoreIcon fill="#B7B7B7" />
          </Dropdown>
        </TaskWrapperRight>
      </TaskWrapper>
    );
  };

  const FooterModal = (
    color: string,
    backgroundBtnLeft: string,
    backgroundBtnRight: string,
    padding: string,
    nameBtnLeft: string,
    nameBtnRight: string,
    onCancel?: () => void,
    onOk?: () => void
  ) => {
    const border = `1px solid ${backgroundBtnLeft}`;
    return (
      <WrapperBtnForm>
        <Button
          type="reset"
          color={color}
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          background={backgroundBtnLeft}
          padding={padding}
          name={nameBtnLeft}
          border={border}
          onClick={onCancel}
        />
        <Button
          type="submit"
          color={color}
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          background={backgroundBtnRight}
          padding={padding}
          name={nameBtnRight}
          border={border}
          onClick={onOk}
        />
      </WrapperBtnForm>
    );
  };

  const ShowPagination = () => {
    return totalRecord > input.per_page ? (
      <PaginationWrapper>
        <Pagination
          current={input.page}
          onChange={handleChangePage}
          pageSize={input.per_page}
          total={totalRecord}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </PaginationWrapper>
    ) : (
      <Fragment></Fragment>
    );
  };

  useEffect(() => {
    if (departmentlistdata?.result) {
      const dataClones = departmentlistdata?.result.map(
        (option: { id: number; name: string }) => {
          return {
            id: option.id,
            value: option.id,
            name: option.name,
          };
        }
      );
      setSchoolYearOptions(dataClones);
    }
  }, [departmentlistdata]);

  const renderForm = () => {
    switch (formStatus) {
      case "updating":
        return (
          visible && (
            <UpdatingModal
              visible={visible}
              handleCancel={handleCancel}
              handleSubmitOK={handleSubmitOK}
              FooterModal={FooterModal}
              activityGroupOptions={activitieslistdata?.result}
              departmentOptions={schoolYearOptions}
              genderOptions={genderOptions}
              selectedUserItem={selectedItem}
            />
          )
        );
      case "creating":
        return (
          visible && (
            <CreatingModal
              visible={visible}
              handleCancel={handleCancel}
              FooterModal={FooterModal}
              activityGroupOptions={activitieslistdata?.result}
              departmentOptions={schoolYearOptions}
              genderOptions={genderOptions}
            />
          )
        );
      case "attendance":
        return (
          <AttendanceModal
            visible={visible}
            handleCancel={handleCancel}
            selectedUserItem={selectedItem}
          />
        );
      case "batch":
        return (
          <BatchModal
            visible={visible}
            handleCancel={handleCancel}
            FooterModal={FooterModal}
          />
        );
      default:
        return;
    }
  };

  const [oldSorter, setOldSorter] = useState("");

  const handleOnChange: TableProps<DataType>["onChange"] = (
    _,
    __,
    sorter: any
  ) => {
    setOldSorter(sorter.columnKey);

    const params = {
      ...input,
      [softFields[sorter.columnKey as keyof ISoftFileds]]:
        softOrder[sorter.order as keyof ISoftOrder],
    };

    if (oldSorter && oldSorter !== sorter.columnKey) {
      delete params[
        softFields[oldSorter as keyof ISoftFileds] as keyof UserListData
      ];
    }

    setInput(params);
    dispatch(UserListActionCreators.userListAction(params));
  };

  return (
    <>
      {renderForm()}
      <Box
        title={t1("user-management-label")}
        subTitle={`${t("total-number-of-people")}
        ${totalRecord}${t2("people")}`}
        padding="24px 16px"
        action={
          <SelectWrapper>
            <div
              style={{
                fontSize: 14,
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {t("record")}
            </div>
            <Select
              defaultValue={50}
              options={recordOptions}
              onChange={handleOnChangeSizeChange}
            />
          </SelectWrapper>
        }
      >
        <SearchWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SearchWrapperLeft>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange } }) => (
                  <Input
                    placeholder={t("you-can-search-for")}
                    height={31}
                    onChange={onChange}
                  />
                )}
              />

              <WrapperSelectAndBtn>
                <Controller
                  control={control}
                  name="grade"
                  render={({ field: { onChange } }) => (
                    <Select
                      placeholder={t("select-grade-or-class")}
                      options={classListOptions.filter(function (
                        option: IOption
                      ) {
                        return !(option.id == 0); //「卒業・退職」は不要
                      })}
                      onChange={onChange}
                    />
                  )}
                />

                <Button
                  type="submit"
                  name={t("search")}
                  background="#2AC769"
                  color="#FFFFFF"
                  border="none"
                  fontSize={16}
                  fontWeight={700}
                  padding="1px 16px"
                />
              </WrapperSelectAndBtn>
            </SearchWrapperLeft>
          </form>

          <ShowPagination />
        </SearchWrapper>

        <ContentWrapper ref={scrollAbleRef}>
          <ReactDragListView.DragColumn
            nodeSelector="th:not(.disabled-drag-column, .ant-table-cell-scrollbar)"
            onDragEnd={handleDragColumnEnd}
          >
            <UserListTableStyled
              dataSource={data || []}
              columns={tableColumns}
              actionSection={<ActionSection />}
              scroll={!data ? {} : { x: "max-content" }}
              onRow={(record: UserListResultData) => ({
                onClick: () => {
                  setSelectedItem(record);
                },
              })}
              loading={isLoading}
              onChange={handleOnChange}
            />
          </ReactDragListView.DragColumn>
        </ContentWrapper>
        <ShowPagination />
      </Box>
    </>
  );
};

export default UserList;
