import {
  Action,
  ActionType,
  UserAttendanceResponse,
} from "models/userAttendance";
import createReducer from "../createReducer";

export interface UserAttendanceReducerType {
  loading: boolean;
  error?: string;
  userAttendanceResponse?: UserAttendanceResponse;
}

const defaultState: UserAttendanceReducerType = {
  loading: false,
  error: undefined,
  userAttendanceResponse: undefined,
};

export const userAttendanceReducer = createReducer<UserAttendanceReducerType>(
  defaultState,
  {
    [ActionType.USER_ATTENDANCE](state: UserAttendanceReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.USER_ATTENDANCE_ERROR](
      state: UserAttendanceReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.USER_ATTENDANCE_SUCCESS](
      state: UserAttendanceReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        userAttendanceResponse: action.payload,
      };
    },
  }
);
