import {
  Action,
  ActionType,
  IMentalCheckTypeInfoResponse,
} from "models/mentalHealth/typeInformation";
import createReducer from "redux/createReducer";

export interface MentalCheckTypeInfoReducerType {
  loading: boolean;
  error?: string;
  mentalCheckTypeInfoResponse?: IMentalCheckTypeInfoResponse;
}

const defaultState: MentalCheckTypeInfoReducerType = {
  loading: false,
  error: undefined,
  mentalCheckTypeInfoResponse: undefined,
};

export const mentalCheckTypeInfoReducer =
  createReducer<MentalCheckTypeInfoReducerType>(defaultState, {
    [ActionType.MENTAL_CHECK_TYPE_INFO](state: MentalCheckTypeInfoReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MENTAL_CHECK_TYPE_INFO_ERROR](
      state: MentalCheckTypeInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        mentalCheckTypeInfoResponse: undefined,
      };
    },

    [ActionType.MENTAL_CHECK_TYPE_INFO_SUCCESS](
      state: MentalCheckTypeInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        mentalCheckTypeInfoResponse: action.payload,
      };
    },

    [ActionType.MENTAL_CHECK_TYPE_INFO_RESET_DATA](
      state: MentalCheckTypeInfoReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        mentalCheckTypeInfoResponse: undefined,
      };
    },
  });
