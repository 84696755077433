export const dayTimeOptions = [
  {
    id: 1,
    value: "AM",
    name: "AM",
  },
  {
    id: 2,
    value: "PM",
    name: "PM",
  },
];

export const hourOptions = [
  {
    id: 0,
    value: "00",
    name: "00",
  },
  {
    id: 1,
    value: "01",
    name: "01",
  },
  {
    id: 2,
    value: "02",
    name: "02",
  },
  {
    id: 3,
    value: "03",
    name: "03",
  },
  {
    id: 4,
    value: "04",
    name: "04",
  },
  {
    id: 5,
    value: "05",
    name: "05",
  },
  {
    id: 6,
    value: "06",
    name: "06",
  },
  {
    id: 7,
    value: "07",
    name: "07",
  },
  {
    id: 8,
    value: "08",
    name: "08",
  },
  {
    id: 9,
    value: "09",
    name: "09",
  },
  {
    id: 10,
    value: "10",
    name: "10",
  },
  {
    id: 11,
    value: "11",
    name: "11",
  },
];

export const minutesOptions = [
  {
    id: 0,
    value: "00",
    name: "00",
  },
  {
    id: 15,
    value: "15",
    name: "15",
  },
  {
    id: 30,
    value: "30",
    name: "30",
  },
  {
    id: 45,
    value: "45",
    name: "45",
  },
];

export const languageName: any = {
  1: [
    "日本語",
    "英語",
    "ベトナム語",
    "ヒンディー語",
    "ポルトガル語",
    "中国語",
    "アラビア語",
  ],
  2: [
    "Japanese",
    "English",
    "Vietnamese",
    "Hindi",
    "Portuguese",
    "Chinese",
    "Arbic",
  ],
  3: [
    "Japanese",
    "English",
    "Vietnamese",
    "Hindi",
    "Portuguese",
    "Chinese",
    "Arbic",
  ],
  4: [
    "Japanese",
    "English",
    "Vietnamese",
    "Hindi",
    "Portuguese",
    "Chinese",
    "Arbic",
  ],
  5: [
    "Japanese",
    "English",
    "Vietnamese",
    "Hindi",
    "Portuguese",
    "Chinese",
    "Arbic",
  ],
  6: [
    "Japanese",
    "English",
    "Vietnamese",
    "Hindi",
    "Portuguese",
    "Chinese",
    "Arbic",
  ],
  7: [
    "Japanese",
    "English",
    "Vietnamese",
    "Hindi",
    "Portuguese",
    "Chinese",
    "Arbic",
  ],
};
