import { RootState } from "../../rootReducer";

export const selectIsLoading = (state: RootState) =>
  state.saveTemporaryStudentList.loading;
export const selectIsSaved = (state: RootState) =>
  state.saveTemporaryStudentList.saved;
export const selectErrorMessage = (state: RootState) =>
  state.saveTemporaryStudentList.error;
export const saveTemporaryClassChangeResponse = (state: RootState) =>
  state.saveTemporaryStudentList.saveTemporaryStudentClassChangeResponse;
