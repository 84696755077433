import {
  Action,
  ActionType,
  SendHealthCheckAnswersResponse,
} from "models/bodyTemperature/sendHealthCheckAnswers";
import createReducer from "redux/createReducer";

export interface SendHealthCheckAnswersReducerType {
  loading: boolean;
  error?: string;
  sendHealthCheckAnswersResponse?: SendHealthCheckAnswersResponse;
  sent: boolean;
}

const defaultState: SendHealthCheckAnswersReducerType = {
  loading: false,
  error: undefined,
  sendHealthCheckAnswersResponse: undefined,
  sent: false,
};

export const sendHealthCheckAnswersReducer =
  createReducer<SendHealthCheckAnswersReducerType>(defaultState, {
    [ActionType.SEND_HEALTH_CHECK_ANSWER](
      state: SendHealthCheckAnswersReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.SEND_HEALTH_CHECK_ANSWER_ERROR](
      state: SendHealthCheckAnswersReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        sent: false,
        error: action.payload,
        sendHealthCheckAnswersResponse: undefined,
      };
    },

    [ActionType.SEND_HEALTH_CHECK_ANSWER_SUCCESS](
      state: SendHealthCheckAnswersReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        sent: true,
        sendHealthCheckAnswersResponse: action.payload,
      };
    },

    [ActionType.SEND_HEALTH_CHECK_ANSWER_RESET](
      state: SendHealthCheckAnswersReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        sent: false,
        sendHealthCheckAnswersResponse: undefined,
      };
    },
  });
