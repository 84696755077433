import { Action, ActionType } from "models/messagemanagement/deleteMessage";
import { IResponse } from "models";
import createReducer from "../../createReducer";

export interface DeleteMessageReducerType {
  loading: boolean;
  error?: string;
  deleteMessageResponse?: IResponse;
}

const defaultState: DeleteMessageReducerType = {
  loading: false,
  error: undefined,
  deleteMessageResponse: undefined,
};

export const deleteMessageReducer = createReducer<DeleteMessageReducerType>(
  defaultState,
  {
    [ActionType.DELETE_MESSAGE](state: DeleteMessageReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_MESSAGE_ERROR](
      state: DeleteMessageReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DELETE_MESSAGE_SUCCESS](
      state: DeleteMessageReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteMessageResponse: action.payload,
      };
    },

    [ActionType.RESET_DELETE_MESSAGE](state: DeleteMessageReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteMessageResponse: undefined,
      };
    },
  }
);
