import Encoding from "encoding-japanese";
import Papa from "papaparse";

interface IHeaders {
  label: string;
  key: string;
}

export const generateCSV = (
  nameFile: string,
  headers: IHeaders[],
  data: any[]
) => {
  const convertedData: any = {
    fields: [],
    data: [],
  };

  headers.forEach((header) => {
    convertedData.fields.push(header.label);
  });
  data.forEach((item: any) => {
    const results = [];
    for (const key in item) {
      results.push(item[key]);
    }
    convertedData.data.push(results);
  });

  const csv = Papa.unparse(convertedData);
  const utf8Array = new TextEncoder().encode(csv);
  const sjisArray = Encoding.convert(utf8Array, "SJIS", "UTF8");
  const blob = new Blob([new Uint8Array(sjisArray)]);
  const a = document.createElement("a");

  a.download = "Shift-JIS.csv";
  a.href = URL.createObjectURL(blob);
  a.textContent = "download";
  a.setAttribute("href", URL.createObjectURL(blob));
  a.setAttribute("download", nameFile);
  document.body.appendChild(a);
  a.click();
};
