import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ISendQuestionnaireData,
  ActionType,
  SendQuestionnaireResponse,
} from "models/questionnaires/sendQuestionnaires";
import { httpStatus } from "configs/httpStatus";
import { sendQuestionnaire } from "services/questionnaires/index";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./sendQuestionnaireActions";
import { history } from "../../configureStore";

function* sendQuestionnaireSaga({
  payload,
}: {
  payload: ISendQuestionnaireData;
}) {
  try {
    const response: SendQuestionnaireResponse = yield call(
      sendQuestionnaire,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
      history.push("/login");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSendQuestionnaireWatcher() {
  yield takeLatest(ActionType.SEND_QUESTIONNAIRE as any, sendQuestionnaireSaga);
}

export default [fork(onSendQuestionnaireWatcher)];
