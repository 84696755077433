import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  TodaySchedulesData,
  TodaySchedulessResponse,
} from "models/vaccine/schedules";
import { httpStatus } from "configs/httpStatus";
import { fetchTodaySchedules } from "services/vaccine/index";
import { handleSuccesAction, handleErrorAction } from "./todaySchedulesActions";
import { history } from "../../configureStore";

function* todaySchedulesSaga({ payload }: { payload: TodaySchedulesData }) {
  try {
    const response: TodaySchedulessResponse = yield call(
      fetchTodaySchedules,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({
          ...response,
          result: [],
        })
      );
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
      history.push("/login");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onTodaySchedulesWatcher() {
  yield takeLatest(ActionType.TODAY_SCHEDULES as any, todaySchedulesSaga);
}

export default [fork(onTodaySchedulesWatcher)];
