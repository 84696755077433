import * as React from "react";

function RemoveFileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="-1 -1 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="white"
      />
      <g clipPath="url(#clip0_657_32189)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2903 7.72207C13.5699 7.44251 13.5699 6.98924 13.2903 6.70967C13.0108 6.43011 12.5575 6.43011 12.2779 6.70967L9.99996 8.9876L7.72209 6.70975C7.44252 6.43019 6.98925 6.43019 6.70969 6.70976C6.43012 6.98932 6.43012 7.44259 6.70969 7.72215L8.98756 10L6.70968 12.2779C6.43011 12.5574 6.43011 13.0107 6.70968 13.2903C6.98924 13.5698 7.44251 13.5698 7.72208 13.2903L9.99996 11.0124L12.2779 13.2903C12.5575 13.5699 13.0108 13.5699 13.2903 13.2903C13.5699 13.0108 13.5699 12.5575 13.2903 12.2779L11.0124 10L13.2903 7.72207Z"
          fill="#FB4E4E"
        />
      </g>
      <path
        d="M10 19C5.02944 19 1 14.9706 1 10H-1C-1 16.0751 3.92487 21 10 21V19ZM19 10C19 14.9706 14.9706 19 10 19V21C16.0751 21 21 16.0751 21 10H19ZM10 1C14.9706 1 19 5.02944 19 10H21C21 3.92487 16.0751 -1 10 -1V1ZM10 -1C3.92487 -1 -1 3.92487 -1 10H1C1 5.02944 5.02944 1 10 1V-1Z"
        fill="#FB4E4E"
      />
      <defs>
        <clipPath id="clip0_657_32189">
          <rect
            width="9"
            height="9"
            fill="white"
            transform="translate(5.5 4.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RemoveFileIcon;
