import { Action, ActionType, SymtomResponse } from "models/dashboard/symtom";
import createReducer from "../../createReducer";

export interface SymtomReducerType {
  loading: boolean;
  error?: string;
  symtomResponse?: SymtomResponse;
}

const defaultState: SymtomReducerType = {
  loading: false,
  error: undefined,
  symtomResponse: undefined,
};

export const getSymtomReducer = createReducer<SymtomReducerType>(defaultState, {
  [ActionType.GET_SYMTOM_CHART](state: SymtomReducerType) {
    return {
      ...state,
      loading: true,
    };
  },

  [ActionType.GET_SYMTOM_CHART_ERROR](
    state: SymtomReducerType,
    action: Action<number>
  ) {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  },

  [ActionType.GET_SYMTOM_CHART_SUCCESS](
    state: SymtomReducerType,
    action: Action<number>
  ) {
    return {
      ...state,
      loading: false,
      error: null,
      symtomResponse: action.payload,
    };
  },
});
