export enum ActionType {
  // sent message to user with/without files
  UPDATE_MESSAGE = "action/UPDATE_MESSAGE",
  UPDATE_MESSAGE_SUCCESS = "action/UPDATE_MESSAGE_SUCCESS",
  UPDATE_MESSAGE_ERROR = "action/UPDATE_MESSAGE_ERROR",
  RESET_UPDATE_MESSAGE = "action/RESET_UPDATE_MESSAGE",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateMessagePayload {
  title: string;
  description: string;
  patient_id?: string;
  message_documents?: any;
  is_send_all: boolean;
  is_send_immediately: boolean;
  start_time: string;
}
