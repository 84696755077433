import {
  ActionType,
  PDFDownloadTypeInfoResponse,
  ITypeInfoDownloadData,
} from "models/mentalHealth/pdfDownloadTypeInfo";

export const pdfDownloadTypeInfoAction = (payload: ITypeInfoDownloadData) => {
  return {
    type: ActionType.PDF_DOWNLOAD_TYPE_INFO,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.PDF_DOWNLOAD_TYPE_INFO_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: PDFDownloadTypeInfoResponse) => {
  return {
    type: ActionType.PDF_DOWNLOAD_TYPE_INFO_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.PDF_DOWNLOAD_TYPE_INFO_RESET_DATA,
  };
};
