import { Action, ActionType } from "models/updateuser";
import { IResponse } from "models";
import createReducer from "../createReducer";

export interface UpdateUserReducerType {
  loading: boolean;
  error?: string;
  updateUserResponse?: IResponse;
}

const defaultState: UpdateUserReducerType = {
  loading: false,
  error: undefined,
  updateUserResponse: undefined,
};

export const updateUserReducer = createReducer<UpdateUserReducerType>(
  defaultState,
  {
    [ActionType.UPDATE_USER](state: UpdateUserReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_USER_ERROR](
      state: UpdateUserReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_USER_SUCCESS](
      state: UpdateUserReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        updateUserResponse: action.payload,
      };
    },

    [ActionType.UPDATE_USER_RESET](state: UpdateUserReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        updateUserResponse: undefined,
      };
    },
  }
);
