import { IResponse } from "models";

export enum ActionType {
  MENTAL_CHECK_LIST = "action/MENTAL_CHECK_LIST",
  MENTAL_CHECK_LIST_SUCCESS = "action/MENTAL_CHECK_LIST_SUCCESS",
  MENTAL_CHECK_LIST_ERROR = "action/MENTAL_CHECK_LIST_ERROR",
  MENTAL_CHECK_LIST_RESET_DATA = "action/MENTAL_CHECK_LIST_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface CheckListData {
  page: number;
  per_page: number;
}

export interface IMentalCheckListResponse extends IResponse {
  total_record: number;
  current_page: number;
  next_page: number;
  result?: IMentalCheck[];
}

export interface IMentalCheck {
  id: number;
  mental_check_type: string;
  mental_check_type_id: number;
  test_name: string;
  examination_period: string;
  grade: string;
  status: string;
}
