import { createApiCall2, MethodType, URI } from "../Api";
import { ICreateClassData } from "models/classmanagement/createClass";
import { IUpdateClassData } from "models/classmanagement/updateClass";
import { ISchoolTypeData } from "models/classmanagement/schoolType";
import { ICreateBulkClassData } from "models/classmanagement/createBulkClasses";
import { IDeleteClassData } from "models/classmanagement/deleteClass";

export const fetchSchoolClasses = () => {
  return createApiCall2(MethodType.GET, URI.CLASS_MANAGEMENT.GET_CLASSES);
};

export const createSchoolClasses = (payload: ICreateClassData) => {
  return createApiCall2(
    MethodType.POST,
    URI.CLASS_MANAGEMENT.GET_CLASSES,
    payload
  );
};

export const updateSchoolClass = (payload: IUpdateClassData) => {
  return createApiCall2(
    MethodType.PUT,
    URI.CLASS_MANAGEMENT.GET_CLASSES,
    payload
  );
};

// there are same api
export const getSchoolType = (payload: ISchoolTypeData) => {
  return createApiCall2(
    MethodType.GET,
    URI.CLASS_MANAGEMENT.GET_SCHOOL_TYPE,
    payload
  );
};

export const getClassType = (payload: ISchoolTypeData) => {
  return createApiCall2(
    MethodType.GET,
    URI.CLASS_MANAGEMENT.GET_SCHOOL_TYPE,
    payload
  );
};
//--------

export const createBulkClassesType = (payload: ICreateBulkClassData) => {
  return createApiCall2(
    MethodType.POST,
    URI.CLASS_MANAGEMENT.CREATE_BULK_CLASSES,
    payload
  );
};

export const deleteClassesType = (payload: IDeleteClassData) => {
  return createApiCall2(
    MethodType.DELETE,
    URI.CLASS_MANAGEMENT.DELETE_CLASS,
    payload
  );
};
