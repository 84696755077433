import { IResponse } from "models";

export enum ActionType {
  RESERVATIONS = "action/RESERVATIONS",
  RESERVATIONS_SUCCESS = "action/RESERVATIONS_SUCCESS",
  RESERVATIONS_ERROR = "action/RESERVATIONS_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ReservationData {
  start: string;
  locale?: string;
}

interface IReservation {
  date: string;
  count: number;
  sum: number;
}

export interface ReservationsResponse extends IResponse {
  result: {
    schedule_section_json: {
      this_month: IReservation[];
      next_month: IReservation[];
    };
  };
}
