import { Action, ActionType, ActivitiesListResponse } from "models/activities";
import createReducer from "../createReducer";

export interface ActivitiesListReducerType {
  loading: boolean;
  error?: string;
  activitiesListResponse?: ActivitiesListResponse;
}

const defaultState: ActivitiesListReducerType = {
  loading: false,
  error: undefined,
  activitiesListResponse: undefined,
};

export const activitiesListReducer = createReducer<ActivitiesListReducerType>(
  defaultState,
  {
    [ActionType.ACTIVITIES_LIST](state: ActivitiesListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.ACTIVITIES_LIST_ERROR](
      state: ActivitiesListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.ACTIVITIES_LIST_SUCCESS](
      state: ActivitiesListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        activitiesListResponse: action.payload,
      };
    },
  }
);
