import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  BodyTemperatureFileResponse,
} from "models/bodyTemperature/file";
import { httpStatus } from "configs/httpStatus";
import { fetchFileData } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./fileActions";

function* getBodyTemperatureFileDataSaga({ payload }: { payload: any }) {
  try {
    const response: BodyTemperatureFileResponse = yield call(
      fetchFileData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetBodyTemperaturePDFDataWatcher() {
  yield takeLatest(
    ActionType.BODY_TEMPERATURE_FILE_DATA as any,
    getBodyTemperatureFileDataSaga
  );
}

export default [fork(onGetBodyTemperaturePDFDataWatcher)];
