import React, { ReactElement, Suspense } from "react";
import { Router } from "react-router-dom";

import { RestrictedRoute, Loading, ScrollToTop } from "components";
import { GlobalStyle } from "theme/GlobalStyles";
import ThemeProvider from "theme/ThemeProvider";
import { configRoutes } from "./configs";
import { history } from "redux/configureStore";

const IndexRouter: React.FC = (): ReactElement => {
  return (
    <Router history={history}>
      <ScrollToTop />
      {configRoutes.map(({ appRoutes, isPrivate, layout: MainLayout }) => {
        return appRoutes.map(({ children, path, layout, ...props }, idx) => {
          return (
            <RestrictedRoute
              key={idx}
              exact
              isPrivate={isPrivate}
              path={path}
              layout={layout || MainLayout}
              {...props}
            >
              {children}
            </RestrictedRoute>
          );
        });
      })}
    </Router>
  );
};

const App = () => {
  return (
    <Suspense fallback={<Loading width="80" height="80" />}>
      <GlobalStyle />
      <ThemeProvider>
        <IndexRouter />
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
