import React, { useState, useRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { MoreIcon, PlusIcon } from "icons";
import { uuid } from "utils";
import { useOnClickOutside } from "shared-hooks";

import {
  CreateClassActionCreators,
  DeleteClassActionCreators,
  UpdateClassActionCreators,
} from "redux/rootActions";
import { ICreateClassData } from "models/classmanagement/createClass";
import { IUpdateClassData } from "models/classmanagement/updateClass";
import {
  ISchoolClasses,
  IChildrenData,
} from "models/classmanagement/classList";
import { IDeleteClassData } from "models/classmanagement/deleteClass";
import { classCreated } from "redux/classmanagement/createclasses/createClassStates";

import EditDeleteDropdown from "./EditDeleteDropdown";
import DeleteModal from "./DeleteModal";
import {
  AddButton,
  WrapperItem,
  ItemName,
  DropdownIconStyled,
  ItemWrapper,
  BtnGroup,
  PlusCircleIconStyled,
  SaveBtnStyled,
  AddText,
  InputStyled,
  Form,
  WrapperTreeView,
  TooltipErrorMessage,
  DropdownIconOpenStyled,
  PlusIconStyled,
  DeleteIconStyled,
} from "./ClassManagement.style";
import AddInput from "./AddInput";

interface ITreeView {
  treeData: ISchoolClasses[];
  setTreeData: React.Dispatch<React.SetStateAction<ISchoolClasses[]>>;
}

interface IItemActivated {
  [key: string]: boolean;
}

const schemaItem = yup.object().shape({
  name: yup
    .string()
    .max(30, "max-text")
    .test("empty-check", "enter-grade-name", (text: any) => text.trim() !== ""),
});

const CreateButton = ({ handleAddColumn }: any) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });

  const dispatch = useDispatch();
  const classSaved = useSelector(classCreated);

  const ref = useRef<any>(null);
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);
  useOnClickOutside(ref, () => setIsComponentVisible(false));

  useEffect(() => {
    if (classSaved) {
      setIsComponentVisible(false);
      dispatch(CreateClassActionCreators.handleResetAction());
    }
  }, [classSaved]);

  return (
    <AddButton ref={ref}>
      {isComponentVisible && (
        <PlusIconStyled
          fill="#FB4E4E"
          style={{ marginTop: 3 }}
          onClick={() => setIsComponentVisible(false)}
        />
      )}
      <AddText onClick={() => setIsComponentVisible(true)}>
        {!isComponentVisible && <PlusIcon fill="#1AB759" />}
        <span style={{ marginLeft: 5, color: "#1ab759" }}>{t("add-item")}</span>
      </AddText>
      {isComponentVisible && <AddInput handleAddColumn={handleAddColumn} />}
    </AddButton>
  );
};

const TreeView: React.FC<ITreeView> = ({ treeData, setTreeData }) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });
  const dispatch = useDispatch();
  const [expandItem, setExpandItem] = useState<IItemActivated>({});
  const [displayAdds, setDisplayAdds] = useState<IItemActivated>({});
  const [isShowBtnDelete, setIsShowBtnDelete] = useState<any>({});
  const [displayEditDeletes, setDisplayEditDeletes] = useState<IItemActivated>(
    {}
  );
  const [idEdit, setIdEdit] = useState("");
  const [idDelete, setIdDelete] = useState<any>("");
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isChildren, setIsChildren] = useState(false);
  const { handleSubmit, control, setValue, reset } = useForm({
    resolver: yupResolver(schemaItem),
    defaultValues: {
      name: "",
    },
  });

  const handleExpandItem = (id: string, value: boolean) => {
    setExpandItem({ ...expandItem, [id]: value });
  };

  const handleShowMenuDropdown = (id: string, value = true) => {
    setDisplayEditDeletes({ [id]: value });
  };

  const handleCloseMenuDropdown = (props: IChildrenData) => {
    setDisplayAdds({});
    handleShowMenuDropdown(props.id, false);
  };

  const handleAddColumn = (data: any) => {
    const params: ICreateClassData = {
      class: {
        name: data.newGrade,
      },
    };

    dispatch(CreateClassActionCreators.createClassAction(params));
  };

  const handleShowAddInput = (id: string, value = true) => {
    setDisplayAdds({ [id]: value });
    setValue("name", "");
    setIdEdit("");
  };

  const handleAddNewItem = (item: any) => (data: { name: string }) => {
    const parentId = isNaN(item.id) ? item.id.split("-")[1] : item.id;
    if (idEdit == "") {
      item.sub_departments.push({
        name: data.name,
        id: uuid(),
        sub_departments: [],
      });
      const params: ICreateClassData = {
        class: {
          name: data.name,
          parent_id: parentId,
        },
      };
      dispatch(CreateClassActionCreators.createClassAction(params));
    } else {
      item.name = data.name;
      const updateParams: IUpdateClassData = {
        class: {
          name: data.name,
          id: parentId,
        },
      };
      dispatch(UpdateClassActionCreators.updateClassAction(updateParams));
    }
    reset();
    setTreeData(treeData);
    setIdEdit("");
    setDisplayAdds({});
    setIsShowBtnDelete({});
  };

  const handleEdit = (props: IChildrenData) => {
    const { id, name } = props;
    handleShowMenuDropdown(id, false);
    setDisplayAdds({});
    setIsShowBtnDelete({});
    setValue("name", name);
    setIdEdit(id);
  };

  const handleConfirmleDeleteItem = (props: any) => {
    const childrenOfItem = props?.sub_departments || props?.groupIds;
    if (childrenOfItem.length) {
      setIsChildren(true);
    } else {
      setIsChildren(false);
    }
    setIsVisibleDeleteModal(true);
    setIdDelete(props.id);
  };

  const handleCancel = () => {
    setIsVisibleDeleteModal(false);
    setDisplayEditDeletes({});
  };

  const handleDeleteChildrenItem = (items: IChildrenData[], id: string) => {
    items.forEach((item, index) => {
      if (item.id == id) {
        items.splice(index, 1);
      }
      if (item.sub_departments.length) {
        handleDeleteChildrenItem(item.sub_departments, id);
      }
    });
  };

  const handleDelete = () => {
    const params: IDeleteClassData = {
      id: idDelete,
    };

    handleDeleteChildrenItem(treeData, idDelete);
    dispatch(DeleteClassActionCreators.deleteClassAction(params));
    setTreeData(treeData.length ? treeData : []);
    setIdDelete("");
    setIsVisibleDeleteModal(false);
  };

  const ItemInput = ({ item, type = "add" }: { item: any; type?: string }) => {
    return (
      <Form
        style={{ margin: "8px 0px", display: "flex" }}
        onSubmit={handleSubmit(handleAddNewItem(item))}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div style={{ position: "relative", marginRight: 8 }}>
              <InputStyled
                value={value}
                height={31}
                fs={16}
                fwLabel={500}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
              {error?.message && (
                <TooltipErrorMessage>
                  <div style={{ position: "relative" }}>
                    <div>{t(error?.message)}</div>
                  </div>
                </TooltipErrorMessage>
              )}
            </div>
          )}
        />
        <SaveBtnStyled
          type="submit"
          background="#2AC769"
          color="#fff"
          name={type !== "add" ? t("keep") : t("create")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
        />
      </Form>
    );
  };

  const CompItem = ({ item, children }: any) => {
    const { name, id } = item;

    if (id == idEdit) {
      return (
        <>
          <ItemInput item={item} type="edit" />
          {children}
        </>
      );
    }

    return (
      <>
        <ItemWrapper>
          {!!item.sub_departments.length && (
            <>
              {expandItem[id] ? (
                <DropdownIconOpenStyled
                  fill="#B7B7B7"
                  onClick={() => {
                    handleExpandItem(id, false);
                  }}
                />
              ) : (
                <DropdownIconStyled
                  fill="#B7B7B7"
                  onClick={() => {
                    handleExpandItem(id, true);
                  }}
                />
              )}
            </>
          )}

          <Tooltip placement="topLeft" title={name}>
            <ItemName
              style={item.sub_departments.length ? { marginLeft: 20 } : {}}
            >
              {name}
            </ItemName>
          </Tooltip>
          <BtnGroup>
            <div style={{ position: "relative", height: 24 }}>
              <MoreIcon
                fill="#B7B7B7"
                onClick={() => {
                  handleShowMenuDropdown(id, true);
                }}
              />
              {displayEditDeletes[id] && (
                <EditDeleteDropdown
                  item={item}
                  handleEdit={handleEdit}
                  handleConfirmleDeleteItem={handleConfirmleDeleteItem}
                  handleCloseMenuDropdown={handleCloseMenuDropdown}
                />
              )}
            </div>
            {isShowBtnDelete[id] && (
              <DeleteIconStyled
                fill="#FB4E4E"
                onClick={() => {
                  setIsShowBtnDelete({ [id]: false });
                  setDisplayAdds({});
                }}
              />
            )}
            {!isShowBtnDelete[id] && item?.level && (
              <PlusCircleIconStyled
                fill="#2AC769"
                onClick={() => {
                  handleShowAddInput(id);
                  setIsShowBtnDelete({ [id]: true });
                }}
              />
            )}
          </BtnGroup>
        </ItemWrapper>
        {children}
      </>
    );
  };

  const Item = ({ item }: any) => {
    const { id } = item;
    return (
      <WrapperItem>
        <CompItem item={item}>
          <div style={{ marginLeft: 32 }}>
            {!expandItem[id] && (
              <>
                {item.sub_departments.length > 0 &&
                  item.sub_departments.map((i: IChildrenData) => (
                    <div style={{ marginLeft: 32 }} key={i.id}>
                      <CompItem item={i}>
                        {!expandItem[i.id] && (
                          <>
                            {i.sub_departments.map((a: IChildrenData) => (
                              <div style={{ marginLeft: 32 }} key={a.id}>
                                <Item item={a} />
                              </div>
                            ))}
                          </>
                        )}
                        {displayAdds[i.id] && <ItemInput item={i} />}
                      </CompItem>
                    </div>
                  ))}
              </>
            )}
            {displayAdds[id] && <ItemInput item={item} />}
          </div>
        </CompItem>
      </WrapperItem>
    );
  };

  return (
    <>
      <div style={{ marginBottom: 32 }}>
        <CreateButton handleAddColumn={handleAddColumn} />
      </div>

      <WrapperTreeView>
        {treeData.map((item: any) => {
          return <Item item={item} key={item.id} />;
        })}
      </WrapperTreeView>

      <div style={{ marginBottom: 8, marginTop: 32 }}>
        <CreateButton handleAddColumn={handleAddColumn} />
      </div>
      {isVisibleDeleteModal && (
        <DeleteModal
          isVisibleDeleteModal={isVisibleDeleteModal}
          isChildren={isChildren}
          handleCancel={handleCancel}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
};

export default TreeView;
