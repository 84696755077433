import {
  ActionType,
  DataChildDepartmentResponse,
} from "models/bodyTemperature/childDepartment";

export const getDataChildDepartment = (payload: { dept_id: number }) => {
  return {
    type: ActionType.DATA_CHILD_DEPARTMENT,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DATA_CHILD_DEPARTMENT_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DataChildDepartmentResponse) => {
  return {
    type: ActionType.DATA_CHILD_DEPARTMENT_SUCCESS,
    payload,
  };
};
