import { Action, ActionType, AbsenceResponse } from "models/dashboard/absence";
import createReducer from "../../createReducer";

export interface AbsenceReducerType {
  loading: boolean;
  error?: string;
  absenceResponse?: AbsenceResponse;
}

const defaultState: AbsenceReducerType = {
  loading: false,
  error: undefined,
  absenceResponse: undefined,
};

export const getAbsenceReducer = createReducer<AbsenceReducerType>(
  defaultState,
  {
    [ActionType.GET_ABSENCE_CHART](state: AbsenceReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_ABSENCE_CHART_ERROR](
      state: AbsenceReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_ABSENCE_CHART_SUCCESS](
      state: AbsenceReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        absenceResponse: action.payload,
      };
    },
  }
);
