import {
  Action,
  ActionType,
  DataDetailFormResponse,
} from "models/bodyTemperature/detailForm";
import createReducer from "../../createReducer";

export interface DataDetailFormReducerType {
  loading: boolean;
  error?: string;
  dataDetailFormResponse?: DataDetailFormResponse;
}

const defaultState: DataDetailFormReducerType = {
  loading: false,
  error: undefined,
  dataDetailFormResponse: undefined,
};

export const dataDetailFormReducer = createReducer<DataDetailFormReducerType>(
  defaultState,
  {
    [ActionType.DATA_DETAIL_FORM](state: DataDetailFormReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DATA_DETAIL_FORM_ERROR](
      state: DataDetailFormReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DATA_DETAIL_FORM_SUCCESS](
      state: DataDetailFormReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        dataDetailFormResponse: action.payload,
      };
    },
  }
);
