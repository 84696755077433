import React from "react";
import { IItems } from "./index";
import {
  UserIcon,
  HouseIcon,
  CircleIcon,
  LetterIcon,
  InjectionIcon,
  ContactIcon,
  StudentAssociationIcon,
  QRCodeIcon,
  SettingIcon,
  QuestionIcon,
  ResourcesDownloadIcon,
  MentalHealthIcon,
  HeartSpeakIcon,
  DashboardIcon,
  ConsultationRequestIcon,
  ImportSSOUsersIcon,
} from "icons";

export const MenuItems: IItems[] = [
  {
    key: "0",
    icon: <DashboardIcon />,
    label: "dashboard-label",
    path: "/dashboard",
  },
  {
    key: "1",
    icon: <HeartSpeakIcon />,
    label: "health-observation-label",
    path: "/body-temperature-management",
  },
  {
    key: "14",
    icon: <ConsultationRequestIcon />,
    label: "consultation-request-label",
    path: "/consultations-list",
  },
  {
    key: "2",
    icon: <LetterIcon />,
    label: "message-delivery-label",
    path: "/message-delivery",
  },
  {
    key: "sub4",
    icon: <QuestionIcon fill="#3B3B3B" />,
    label: "questions",
    path: "/questions",
    children: [
      {
        key: "3.1",
        label: "questions-list",
        path: "/questions-list",
      },
      {
        key: "3.2",
        label: "history-list",
        path: "/history-list",
      },
    ],
  },
  {
    key: "sub5",
    icon: <MentalHealthIcon />,
    label: "mental-health-label",
    path: "/mental-health-observation",
    children: [
      {
        key: "4.1",
        label: "management",
        path: "/mental-health-management",
      },
      {
        key: "4.2",
        label: "implemented-status-25-elementary",
        path: "/elementary-25-questions",
      },
      {
        key: "4.3",
        label: "implemented-status-13-elementary",
        path: "/elementary-13-questions",
      },
      {
        key: "4.4",
        label: "implemented-status-25-junior",
        path: "/junior-25-questions",
      },
      {
        key: "4.5",
        label: "implemented-status-13-junior",
        path: "/junior-13-questions",
      },
      {
        key: "4.6",
        label: "totalling",
        path: "/mental-health-total",
      },
    ],
  },
  {
    key: "4",
    icon: <QRCodeIcon />,
    label: "qr-code-label",
    path: "/qr-code",
  },
  {
    key: "5",
    icon: <ResourcesDownloadIcon />,
    label: "resources-download-label",
    path: "/resources-download",
  },
  {
    key: "6",
    icon: <UserIcon />,
    label: "user-management-label",
    path: "/user-list",
  },
  {
    key: "7",
    icon: <HouseIcon />,
    label: "class-management-label",
    path: "/class-management",
  },
  {
    key: "8",
    icon: <CircleIcon />,
    label: "activity-management-label",
    path: "/activity-management",
  },
  {
    key: "sub1",
    icon: <InjectionIcon />,
    label: "injection-management-label",
    path: "/injection-management",
    children: [
      {
        key: "9.1",
        label: "inoculation-record-label",
        path: "/vaccination-record",
      },
      {
        key: "9.2",
        label: "inoculation-reservation-label",
        path: "/vaccination-appointment",
      },
      {
        key: "9.3",
        label: "list-of-reservations-label",
        path: "/reservation-list",
      },
      {
        key: "9.4",
        label: "venue-setting-label",
        path: "/venue-setting",
      },
    ],
  },
  {
    key: "sub2",
    icon: <StudentAssociationIcon />,
    label: "change-student-association-label",
    path: "/change-student-association",
    children: [
      {
        key: "10.1",
        label: "change-affiliation-label",
        path: "/change-class",
      },
      {
        key: "10.2",
        label: "confirm-data-label",
        path: "/confirm-data",
      },
      {
        key: "10.3",
        label: "reflect-change-label",
        path: "/reflection-changes",
      },
    ],
  },
  {
    key: "sub3",
    icon: <ContactIcon />,
    label: "account-label",
    path: "/account",
    children: [
      {
        key: "11.1",
        label: "account-list-label",
        path: "/account-list",
      },
      {
        key: "11.2",
        label: "register-account-label",
        path: "/account-registration",
      },
    ],
  },
  {
    key: "15",
    icon: <ImportSSOUsersIcon fill="#3B3B3B" />,
    label: "import-sso-users-label",
    path: "/import-sso-users",
  },
  {
    key: "13",
    icon: <SettingIcon />,
    label: "setting-label",
    path: "/setting",
  },
];

export const LimitedMenuItems: IItems[] = [
  {
    key: "1",
    icon: <HeartSpeakIcon />,
    label: "health-observation-label",
    path: "/body-temperature-management",
  },
  {
    key: "14",
    icon: <ConsultationRequestIcon />,
    label: "consultation-request-label",
    path: "/consultations-list",
  },
  {
    key: "2",
    icon: <LetterIcon />,
    label: "message-delivery-label",
    path: "/message-delivery",
  },
  {
    key: "sub4",
    icon: <QuestionIcon fill="#3B3B3B" />,
    label: "questions",
    path: "/questions",
    children: [
      {
        key: "3.1",
        label: "questions-list",
        path: "/questions-list",
      },
      {
        key: "3.2",
        label: "history-list",
        path: "/history-list",
      },
    ],
  },
  {
    key: "4",
    icon: <UserIcon />,
    label: "user-management-label",
    path: "/user-list",
  },
  {
    key: "5",
    icon: <QRCodeIcon />,
    label: "qr-code-label",
    path: "/qr-code",
  },
  {
    key: "5",
    icon: <ResourcesDownloadIcon />,
    label: "resources-download-label",
    path: "/resources-download",
  },
  {
    key: "sub2",
    icon: <StudentAssociationIcon />,
    label: "change-student-association-label",
    path: "/change-student-association",
    children: [
      {
        key: "6.1",
        label: "change-affiliation-label",
        path: "/change-class",
      },
      {
        key: "6.2",
        label: "confirm-data-label",
        path: "/confirm-data",
      },
    ],
  },
];

export const VACCINE_INJECTION_URL = [
  "/vaccination-record",
  "/vaccination-appointment",
  "/reservation-list",
  "/venue-setting",
];
export const ACCOUNT_URL = ["/account-list", "/account-registration"];
export const CHANGE_CLASS_URL = [
  "/change-class",
  "/confirm-data",
  "/reflection-changes",
];
export const MENTAL_HEALTH_URL = [
  "/mental-health-management",
  "/elementary-25-questions",
  "/elementary-13-questions",
  "/junior-25-questions",
  "/junior-13-questions",
  "/mental-health-total",
];
export const QUESTIONS_URL = ["/questions-list", "/history-list"];
export const MESSAGE_URL = ["/messages", "/message-list"];
