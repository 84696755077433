import { httpStatus } from "configs/httpStatus";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getHealthCheckSettingResponse } from "redux/setting/getHealthCheckSetting/getHealthCheckSettingStates";

export const useGetAttendanceOptions = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });

  const getHealthCheckSetting = useSelector(getHealthCheckSettingResponse);

  const [attendanceOptions, setAttendanceOptions] = useState<any>([
    {
      id: 2,
      name: t("attendance"),
      value: "-10",
    },
    {
      id: 3,
      name: t("attendance-have-contact"),
      value: "-20",
    },
    {
      id: 17,
      name: t("absent-after-school-club"),
      value: "262",
    },
    {
      id: 5,
      name: t("absent"),
      value: "157",
    },
    {
      id: 257,
      name: t("absent-bereavement"),
      value: "257",
    },
    {
      id: 6,
      name: t("absent-other"),
      value: "158",
    },
    // {
    //   id: 13,
    //   name: t("absent-corona-positive"),
    //   value: "-30",
    // },
    {
      id: 14,
      name: t("absent-close-contact"),
      value: "218",
    },
    {
      id: 15,
      name: t("absent-family-close-contact"),
      value: "219",
    },
    // {
    //   id: 16,
    //   name: t("absent-influenza"),
    //   value: "-40",
    // },
    {
      id: 7,
      name: t("tardiness"),
      value: "160",
    },
    {
      id: 8,
      name: t("early-leave"),
      value: "163",
    },
    {
      id: 12,
      name: t("online"),
      value: "179",
    },
    {
      id: 9,
      name: t("holiday"),
      value: "159",
    },
  ]);

  useEffect(() => {
    if (
      getHealthCheckSetting &&
      getHealthCheckSetting.status === httpStatus.StatusOK &&
      getHealthCheckSetting?.health_check_setting?.absent_type &&
      Object.keys(getHealthCheckSetting.health_check_setting.absent_type)
        .length > 0
    ) {
      const removeItems: any = [];

      const {
        covid_close,
        covid_family_close,
        covid_positive,
        influenza,
        online,
        mourning,
      } = getHealthCheckSetting.health_check_setting.absent_type;
      const { can_use_absent_children_club } =
        getHealthCheckSetting.health_check_setting;

      if (!covid_close) {
        removeItems.push(14);
      }
      // if (!influenza) {
      //   removeItems.push(16);
      // }
      if (!covid_family_close) {
        removeItems.push(15);
      }
      // if (!covid_positive) {
      //   removeItems.push(13);
      // }
      if (!online) {
        removeItems.push(12);
      }
      if (!can_use_absent_children_club) {
        removeItems.push(17);
      }
      if (!mourning) {
        removeItems.push(257);
      }

      setAttendanceOptions(
        attendanceOptions.filter((item: any) => !removeItems.includes(item.id))
      );
    }
  }, [getHealthCheckSetting]);

  return {
    attendanceOptions,
  };
};
