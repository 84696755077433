import { ActionType, TimeSlotDataResponse } from "models/vaccine/timeSlot";

export const getTimeSlotAction = (payload?: any) => {
  return {
    type: ActionType.TIME_SLOT,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.TIME_SLOT_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: TimeSlotDataResponse) => {
  return {
    type: ActionType.TIME_SLOT_SUCCESS,
    payload,
  };
};
