import { IResponse } from "models";

export enum ActionType {
  LAST_SEND_NOTIFICATION_TIME = "action/LAST_SEND_NOTIFICATION_TIME",
  LAST_SEND_NOTIFICATION_TIME_SUCCESS = "action/LAST_SEND_NOTIFICATION_TIME_SUCCESS",
  LAST_SEND_NOTIFICATION_TIME_ERROR = "action/LAST_SEND_NOTIFICATION_TIME_ERROR",
  LAST_SEND_NOTIFICATION_TIME_RESET = "action/LAST_SEND_NOTIFICATION_TIME_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ILastNotificationTimePayload {
  mental_check_request_id: any;
}

export interface ILastNotificationTimeResponse extends IResponse {
  result: {
    last_reminder_notification_at: string;
  };
}
