import {
  ActionType,
  Action,
  RemindPushNotificationResponse,
} from "models/questionnaires/remindPushNotification";
import createReducer from "redux/createReducer";

export interface RemindPushNotificationReducerType {
  loading: boolean;
  error?: string;
  saved: boolean;
  remindPushNotificationResponse?: RemindPushNotificationResponse;
}

const defaultState: RemindPushNotificationReducerType = {
  loading: false,
  error: undefined,
  saved: false,
  remindPushNotificationResponse: undefined,
};

export const remindPushNotificationReducer =
  createReducer<RemindPushNotificationReducerType>(defaultState, {
    [ActionType.REMIND_PUSH_NOTIFICATION](
      state: RemindPushNotificationReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.REMIND_PUSH_NOTIFICATION_ERROR](
      state: RemindPushNotificationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
        remindPushNotificationResponse: undefined,
      };
    },

    [ActionType.REMIND_PUSH_NOTIFICATION_SUCCESS](
      state: RemindPushNotificationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        saved: true,
        remindPushNotificationResponse: action.payload,
      };
    },

    [ActionType.REMIND_PUSH_NOTIFICATION_RESET](
      state: RemindPushNotificationReducerType
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        remindPushNotificationResponse: undefined,
      };
    },
  });
