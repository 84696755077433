import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button } from "components";
import { TooltipErrorMessage, AddInputStyled } from "./ClassManagement.style";

interface IAddInput {
  handleAddColumn: (data: any) => void;
}

const schema = yup.object().shape({
  newGrade: yup
    .string()
    .max(30, "max-text")
    .test("empty-check", "enter-grade-name", (text: any) => text.trim() !== ""),
});

const AddInput = ({ handleAddColumn }: IAddInput) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });
  const inputRef = useRef<any>(null);
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      newGrade: "",
    },
  });

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  return (
    <form onSubmit={handleSubmit(handleAddColumn)} style={{ display: "flex" }}>
      <Controller
        control={control}
        name="newGrade"
        render={({ field: { onChange }, fieldState: { error } }) => (
          <div style={{ position: "relative" }}>
            <AddInputStyled
              ref={inputRef}
              spellCheck={false}
              onChange={onChange}
            />
            {error && (
              <TooltipErrorMessage>
                <div style={{ position: "relative" }}>
                  <div>{t(error?.message)}</div>
                </div>
              </TooltipErrorMessage>
            )}
          </div>
        )}
      />
      <Button
        type="submit"
        background="#2AC769"
        color="#fff"
        margin="0 0 0 8px"
        name={t("create")}
        border="none"
        bdr="6px"
        fontSize={16}
        fontWeight={700}
      />
    </form>
  );
};

export default AddInput;
