import * as React from "react";

function CheckedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="33"
      height="24"
      viewBox="0 0 33 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.2441 0.694526C32.0258 0.474454 31.7661 0.299778 31.4799 0.180575C31.1938 0.0613712 30.8869 0 30.5769 0C30.2669 0 29.96 0.0613712 29.6739 0.180575C29.3877 0.299778 29.128 0.474454 28.9098 0.694526L11.4163 18.2104L4.06674 10.8378C3.8401 10.6188 3.57255 10.4467 3.27938 10.3312C2.98621 10.2156 2.67315 10.159 2.35808 10.1644C2.04301 10.1699 1.73209 10.2373 1.44309 10.3629C1.15409 10.4885 0.89266 10.6698 0.673725 10.8965C0.454791 11.1231 0.282642 11.3906 0.167105 11.6838C0.051569 11.9769 -0.00509199 12.29 0.000359069 12.605C0.00581013 12.9201 0.0732662 13.231 0.198875 13.52C0.324483 13.8089 0.505785 14.0704 0.732428 14.2893L9.74917 23.3055C9.96746 23.5255 10.2272 23.7002 10.5133 23.8194C10.7994 23.9386 11.1064 24 11.4163 24C11.7263 24 12.0332 23.9386 12.3194 23.8194C12.6055 23.7002 12.8652 23.5255 13.0835 23.3055L32.2441 4.14604C32.4824 3.92617 32.6726 3.65932 32.8027 3.3623C32.9328 3.06529 33 2.74454 33 2.42028C33 2.09602 32.9328 1.77528 32.8027 1.47826C32.6726 1.18125 32.4824 0.914395 32.2441 0.694526V0.694526Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default CheckedIcon;
