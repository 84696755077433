import { Action, ActionType } from "models/messagemanagement/searchCondition";
import createReducer from "../../createReducer";

export interface SearchConditionReducerType {
  loading: boolean;
  error?: string;
  searchConditionResponse?: any;
}

const defaultState: SearchConditionReducerType = {
  loading: false,
  error: undefined,
  searchConditionResponse: undefined,
};

export const searchConditionReducer = createReducer<SearchConditionReducerType>(
  defaultState,
  {
    [ActionType.SEARCH_CONDITION](state: SearchConditionReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.SEARCH_CONDITION_ERROR](
      state: SearchConditionReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.SEARCH_CONDITION_SUCCESS](
      state: SearchConditionReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        searchConditionResponse: action.payload,
      };
    },
  }
);
