import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { Box, Button, Select, Loading } from "components";
import { DownloadIcon, RedAttentionIcon } from "icons";
import { localStorageHelper, generateCSV, convertFilename } from "utils";
import { httpStatus } from "configs/httpStatus";
import { IOption } from "components/select/Select";
import { useScroll } from "shared-hooks";

import {
  GetDownloadCSVStudentListActionCreators,
  SaveTemporaryStudentListActionCreators,
} from "redux/rootActions";
import {
  saveTemporaryClassChangeResponse,
  selectIsSaved,
} from "redux/classchangemanagement/saveTemporary/saveTemporaryStates";
import { csvDownloadStudentListResponse } from "redux/classchangemanagement/getCSVDownloadStudentList/getDownloadCSVStudentListStates";

import {
  FileUpdating,
  WrapperErrorMessage,
  WrapText,
  WrapperProgressBar,
  ContainerProgressBar,
} from "./ImportSSOUsers.style";
import { LoadingDots } from "screens/changeStudentAffiliation/components";
import { FileUploader } from "./components";
import SecondConfirmModal from "screens/changeStudentAffiliation/SecondConfirmModal";
import ConfirmModal from "screens/changeStudentAffiliation/ConfirmModal";

const ImportSSOUsers = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "import-sso-users",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });
  const { t: t2 } = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t3 } = useTranslation("translation", {
    keyPrefix: "common",
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const unblockHandle = useRef<any>();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const boxShadowBtn = { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)" };
  const saveBtnRef = useRef<any>(undefined);
  const csvDownloadInstance = useRef<any | null>(null);

  const isSaved = useSelector(selectIsSaved);
  const saveTemporaryResponse = useSelector(saveTemporaryClassChangeResponse);
  const csvDataStudentListResponse = useSelector(
    csvDownloadStudentListResponse
  );

  const [target, setTargetLocation] = useState<any>("");
  const [isBoxShadow, setIsBoxShadow] = useState(false);
  const [fileCSV, setFileCSV] = useState<any>({});
  const [isVisible, setIsVisible] = useState({
    type: "",
    status: false,
  });
  const [csvHeaderData, setCSVHeaderData] = useState<any>([]);
  const [csvDownloadData, setCSVDownloadData] = useState<any>([]);
  const [nameCSVFile, setNameCSVFile] = useState("");
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);

  useScroll(saveBtnRef, setIsBoxShadow, 133);

  useEffect(() => {
    setIsVisible({
      status: false,
      type: "",
    });
    setIsShowLoader(false);
  }, []);

  const [isVisibleThirdModal, setIsVisibleThirdModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (isSaved) {
      setIsShowLoader(false);
    }
  }, [isSaved]);

  useEffect(() => {
    if (isSaved) {
      setIsShowLoader(false);
    }
  }, [isSaved]);

  const handleOk = () => {
    setIsVisible({
      status: false,
      type: "",
    });
    setIsShowLoader(false);
    dispatch(SaveTemporaryStudentListActionCreators.handleResetDataAction());
  };

  const handleCancel = () => {
    setIsVisible({
      status: false,
      type: "",
    });
    setIsShowLoader(false);
  };

  const handleConfirm = () => {
    if (unblockHandle) {
      unblockHandle.current();
    }
    history.push(target);
  };

  const handleOnChangeFile = async (csv: any) => {
    const isExisted =
      fileCSV.name === csv.fileList[csv.fileList.length - 1].name;
    if (!isExisted) {
      setFileCSV({
        file: csv.fileList[csv.fileList.length - 1].originFileObj,
        name: csv.fileList[csv.fileList.length - 1].name,
      });
    }
  };

  useEffect(() => {
    if (
      csvHeaderData.length > 0 &&
      csvDownloadData.length > 0 &&
      csvDownloadInstance &&
      csvDownloadInstance.current &&
      csvDownloadInstance.current.link
    ) {
      setTimeout(() => {
        csvDownloadInstance.current.link.click();
        setCSVHeaderData([]);
        setCSVDownloadData([]);
        dispatch(
          GetDownloadCSVStudentListActionCreators.handleResetDataAction()
        );
      });
    }
  }, [csvHeaderData, csvDownloadData]);

  useEffect(() => {
    if (csvDataStudentListResponse && csvDataStudentListResponse.result) {
      generateCSV(
        nameCSVFile,
        csvDataStudentListResponse.result.headers,
        csvDataStudentListResponse.result.data
      );

      dispatch(GetDownloadCSVStudentListActionCreators.handleResetDataAction());
    }
  }, [csvDataStudentListResponse]);

  useEffect(() => {
    if (saveTemporaryResponse?.status === httpStatus.StatusCreated) {
      setIsVisible({
        type: "success",
        status: true,
      });
    }
    if (
      saveTemporaryResponse?.status === httpStatus.StatusUnprocessableEntity
    ) {
      setIsVisible({
        type: "fail",
        status: true,
      });
      dispatch(SaveTemporaryStudentListActionCreators.handleResetDataAction());
    }
  }, [saveTemporaryResponse]);

  return (
    <Box title={t2("import-sso-users-label")} padding="16px">
      {isShowLoader && (
        <WrapperProgressBar>
          <ContainerProgressBar>
            <Loading />
          </ContainerProgressBar>
        </WrapperProgressBar>
      )}
      <FileUploader
        fileCSV={fileCSV}
        setFileCSV={setFileCSV}
        handleOnChangeFile={handleOnChangeFile}
      />
      {isVisible.status && (
        <SecondConfirmModal
          type={isVisible.type}
          isVisible={isVisible.status}
          handleOk={handleOk}
          handleCancel={handleCancel}
        >
          {isVisible.type === "success" ? (
            <div>
              データを保存しました。保存したデータは、「一時保存データの確認」ページで確認いただけます。
            </div>
          ) : (
            <WrapperErrorMessage>
              <div>
                <RedAttentionIcon width="42px" height="42px" />
              </div>
              <WrapText>
                <span>
                  一時保存したデータから、変更された データがあります。
                </span>
                <span>
                  元のデータを確認・ 修正後、再度一時保存をしてください。
                </span>
              </WrapText>
            </WrapperErrorMessage>
          )}
        </SecondConfirmModal>
      )}
      {isVisibleThirdModal && (
        <ConfirmModal
          isVisible={isVisibleThirdModal}
          handleCancel={() => setIsVisibleThirdModal(false)}
          handleOk={handleConfirm}
        >
          <div>{t1("leave-page-before-save")}</div>
        </ConfirmModal>
      )}
    </Box>
  );
};

export default ImportSSOUsers;
