import {
  ActionType,
  DataStatisticsResponse,
  BodyTemperatureData,
} from "models/bodyTemperature/statistics";

export const getStatisticsAction = (payload?: BodyTemperatureData) => {
  return {
    type: ActionType.DATA_STATISTICS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DATA_STATISTICS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DataStatisticsResponse) => {
  return {
    type: ActionType.DATA_STATISTICS_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.DATA_STATISTICS_RESET,
  };
};
