import React, { useEffect, useRef, useState } from "react";
import { Upload, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import httpClient from "configs/httpClient";
import { httpStatus } from "configs/httpStatus";
import {
  UploadIcon,
  PDFIcon,
  SendCompleteIcon,
  ConfirmFailIcon,
  CircleDownloadIcon,
} from "icons";
import { Loading, Button, TextArea } from "components";
import {
  WrapperUpload,
  UploadFileBtn,
  DraggerStyled,
  WrapperFile,
  PrimaryText,
  SecondaryText,
  WrapperDecription,
  RemoveFileIconStyled,
  UpdatingBtn,
  WrapperFileUpload,
  UploadIconStyled,
  WrapperUploadedResult,
  Result,
  WrapperUploadedError,
  ThirdText,
  WrapperUpdatingBtn,
  ContainerProgressBar,
  WrapperProgressBar,
  WrapperButtonFooter,
  HeaderTitleWrapper,
  ResizeWrapper,
  ConfirmCancelModal,
} from "./FileUploader.style";
import ConfirmModal from "screens/changeStudentAffiliation/ConfirmModal";
import Modal from "../modal";

interface IValidatedMessage {
  is_valid: boolean;
  message: string;
}

interface IValidateCSVResult {
  failed_records: number;
  messages: IValidatedMessage[];
  success_records: number;
  total_records: number;
}

interface IValidateCSVResponse {
  result: IValidateCSVResult;
  status: number;
  message: string;
}

const FileUploader = ({ fileCSV, setFileCSV, handleOnChangeFile }: any) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "import-sso-users",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const isFileCSV = Object.keys(fileCSV).length > 0;
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isShowModal, changeModalVisibility] = useState<boolean>(false);
  const [target, setTargetLocation] = useState<any>("");
  const [uploadResult, setUploadResult] = useState<any>({});
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
  const history = useHistory();
  const unblockHandle = useRef<any>();
  const [visibleUpdloadModal, setVisibleUploadModal] = useState<boolean>(false);
  const [validatedMessages, setValidatedMessages] = useState<
    IValidatedMessage[]
  >([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [downloadingTemplate, setDownloadingTemplate] =
    useState<boolean>(false);

  useEffect(() => {
    setIsShowLoader(false);
  }, []);

  const handleRemoveFile = () => {
    setFileCSV({});
    setIsVisible(false);
    setUploadResult({});
    setDownloadInfo({
      progress: 0,
      completed: false,
      total: 0,
      loaded: 0,
    });
  };
  function handleShowModal() {
    changeModalVisibility(true);
  }
  function onCancel() {
    changeModalVisibility(false);
  }

  function handleConfirm() {
    if (unblockHandle) {
      unblockHandle.current();
    }
    history.push(target);
  }

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleResponse = (data: {
    status: number;
    result: any;
    message: string;
  }) => {
    const { status, result, message } = data;
    if (status === httpStatus.StatusOK) {
      setDownloadInfo((info) => ({
        ...info,
        progress: 0,
        completed: true,
      }));
      setIsShowLoader(false);
      setFileCSV({});
      setUploadResult({});
      notification.success({
        placement: "bottomRight",
        message: "",
        description: (
          <div style={{ marginRight: 20 }}>
            <div>{t2("data-saved")}</div>
          </div>
        ),
        icon: <SendCompleteIcon />,
      });
    } else {
      setIsShowLoader(false);
      setUploadResult(result || {});
      setFileCSV({});
      setDownloadInfo({
        total: 0,
        loaded: 0,
        progress: 0,
        completed: false,
      });

      if (status === httpStatus.StatusUnprocessableEntity) {
        if (
          result?.errors &&
          result.errors.length &&
          result.errors.length > 0
        ) {
          notification.open({
            placement: "bottomRight",
            message: "",
            icon: <ConfirmFailIcon height="53px" width="53px" />,
            description: (
              <div style={{ marginRight: 20 }}>
                <div>{result.errors[0]}</div>
              </div>
            ),
          });
        } else {
          notification.open({
            placement: "bottomRight",
            message: "",
            icon: <ConfirmFailIcon height="53px" width="53px" />,
            description: (
              <div style={{ marginRight: 20 }}>
                <div>{t2("fail-to-save")}</div>
                <div>{t2("please-check-csv")}</div>
              </div>
            ),
          });
        }
      }
      if (status >= httpStatus.StatusInternalServerError) {
        notification.open({
          placement: "bottomRight",
          message: "",
          icon: <ConfirmFailIcon height="53px" width="53px" />,
          description:
            message === "これは無効なCSVフォーマットです。" ? (
              <div style={{ marginRight: 20 }}>
                <div>{t1("error-upload-1")}</div>
                <div>{t1("error-upload-2")}</div>
              </div>
            ) : (
              message
            ),
        });
      }
    }
  };

  const handleValidateFile = async () => {
    const formData = new FormData();
    formData.append("file", fileCSV.file);
    const { data }: { data: IValidateCSVResponse } = await httpClient.post(
      "web_api_v1/school/user_ssos/csv_validate",
      formData
      // {
      //   ...options,
      // }
    );

    if (data.status === 200) {
      setValidatedMessages(data.result.messages);
      setTotalRecords(data.result.total_records);
      setIsError(false);
    } else if (data.status === 422) {
      setValidatedMessages(data.result.messages);
      setTotalRecords(data.result.total_records);
      setIsError(true);
    } else if (data.status === 500) {
      setValidatedMessages([
        {
          is_valid: false,
          message: data.message,
        },
      ]);
      setTotalRecords(0);
      setIsError(true);
    } else {
      setValidatedMessages([]);
      setIsError(false);
    }
    // handleResponse(data);
  };

  const downloadCSVTemplate = async () => {
    const {
      data,
    }: {
      data: {
        status: number;
        message: string;
        result: string;
      };
    } = await httpClient.get(
      "web_api_v1/school/user_ssos/download_csv_template"
    );

    if (data.status === 200) {
      setDownloadingTemplate(false);
      window.open(data.result, "_self");
    } else {
      setDownloadingTemplate(false);
    }
  };

  const handleUploadFile = async () => {
    setIsShowLoader(true);

    const options = {
      onDownloadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };

    const formData = new FormData();
    formData.append("file", fileCSV.file);
    const { data } = await httpClient.post(
      "web_api_v1/school/user_ssos/csv_upload",
      formData,
      {
        ...options,
      }
    );

    handleResponse(data);
  };

  // useEffect(() => {
  //   unblockHandle.current = history.block((targetLocation: any) => {
  //     if (isFileCSV) {
  //       handleShowModal();
  //       setTargetLocation(targetLocation);
  //       return false;
  //     }
  //   });
  //   return function () {
  //     unblockHandle.current.current && unblockHandle.current.current();
  //   };
  // }, [fileCSV]);

  return (
    <WrapperFileUpload>
      {isShowLoader && (
        <WrapperProgressBar>
          <ContainerProgressBar>
            <Loading />
          </ContainerProgressBar>
        </WrapperProgressBar>
      )}
      <WrapperUpdatingBtn>
        <div
          className="link-wrapper"
          style={{
            cursor: "pointer",
            pointerEvents: downloadingTemplate ? "none" : "auto",
            opacity: downloadingTemplate ? 0.5 : 1,
          }}
          onClick={() => {
            setDownloadingTemplate(true);
            downloadCSVTemplate();
          }}
        >
          <CircleDownloadIcon />
          <div className="text-link">{t2("download-csv-template")}</div>
        </div>
        <UpdatingBtn
          disabled={!isFileCSV}
          name={t2("check-upload-content")}
          background="#2AC769"
          bdr="6px"
          border="none"
          color="#FFFFFF"
          fontSize={16}
          lineHeight="16px"
          fontWeight={700}
          onClick={() => {
            handleValidateFile();
            setVisibleUploadModal(true);
          }}
          padding="13px 24px 11px"
        />
        {/* {Object.keys(uploadResult).length > 0 && (
          <WrapperUploadedResult>
            <Result>
              <span>全件：{uploadResult.total_records}件</span>{" "}
              <SplashStyled>/</SplashStyled>
              <span>成功：{uploadResult.success_records}件</span>{" "}
              <SplashStyled>/</SplashStyled>
              <span>失敗：{uploadResult.failed_records}件</span>
            </Result>
            <WrapperUploadedError>
              {uploadResult.errors.map((error: string, idx: number) => (
                <div key={idx}>{error}</div>
              ))}
            </WrapperUploadedError>
          </WrapperUploadedResult>
        )} */}
      </WrapperUpdatingBtn>
      <WrapperUpload>
        <Upload
          accept=".csv"
          onChange={handleOnChangeFile}
          beforeUpload={(file: any) => {
            return false;
          }}
        >
          <DraggerStyled
            onChange={handleOnChangeFile}
            openFileDialogOnClick={false}
            beforeUpload={(file: any) => {
              return false;
            }}
          >
            {!isFileCSV && (
              <WrapperDecription>
                <UploadIcon />
                <div style={{ position: "relative", top: -4 }}>
                  <PrimaryText>{t("file-upload-csv")}</PrimaryText>
                  <SecondaryText>{t1("file-here")}</SecondaryText>
                  <ThirdText>{t("drag-and-drop")}</ThirdText>
                </div>
              </WrapperDecription>
            )}
            {isFileCSV && (
              <WrapperFile>
                <PDFIcon fill="#FB4E4E" />
                <div>{fileCSV.name}</div>
                <RemoveFileIconStyled
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleOpenModal();
                  }}
                />
              </WrapperFile>
            )}
          </DraggerStyled>
        </Upload>
        {/* <Upload
          accept=".csv"
          onChange={handleOnChangeFile}
          beforeUpload={(file: any) => {
            return false;
          }}
        >
          <UploadFileBtn
            background="#2F8CAE"
            color="#fff"
            name={t("select-a-file-and-upload")}
            border="none"
            bdr="6px"
            fontWeight={700}
            lineHeight="20px"
            icon={<UploadIconStyled width="22px" height="22px" />}
            disabled={isFileCSV}
          />
        </Upload> */}
      </WrapperUpload>
      {isVisible && (
        <ConfirmModal
          isVisible={isVisible}
          handleCancel={handleCancel}
          handleOk={handleRemoveFile}
        >
          {t2("confirm-delete")}
          {t("are-you-ok")}
        </ConfirmModal>
      )}
      {isShowModal && (
        <ConfirmCancelModal
          isVisible={isShowModal}
          handleCancel={onCancel}
          handleOk={() => {
            changeModalVisibility(false);
            setVisibleUploadModal(false);
            setIsError(false);
            setTotalRecords(0);
            setValidatedMessages([]);
            handleRemoveFile();
          }}
        >
          {t2("cancel-warning")}
        </ConfirmCancelModal>
      )}
      <Modal
        visible={visibleUpdloadModal}
        title={
          <HeaderTitleWrapper>
            <div className="title">
              {totalRecords > 0
                ? `${t2("number-of-data")}${totalRecords}${t2("subject")}`
                : `${t2("number-of-data")}-${t2("subject")}`}
            </div>
            {isError && <div className="error">{t2("data-error-warning")}</div>}
          </HeaderTitleWrapper>
        }
        onCancel={handleShowModal}
        footer={
          <WrapperButtonFooter>
            <Button
              background="#E0E0E0"
              color="#fff"
              name={t3("cancel")}
              border="none"
              bdr="6px"
              fontSize={16}
              lineHeight="16px"
              fontWeight={700}
              padding="13px 24px 11px"
              onClick={handleShowModal}
            />
            <Button
              background="#2AC769"
              color="#fff"
              name={t2("register")}
              border="none"
              bdr="6px"
              fontSize={16}
              lineHeight="16px"
              fontWeight={700}
              padding="13px 40px 11px"
              disabled={isError}
              onClick={() => {
                handleUploadFile();
                setVisibleUploadModal(false);
              }}
            />
          </WrapperButtonFooter>
        }
      >
        <ResizeWrapper>
          {validatedMessages &&
            validatedMessages.length > 0 &&
            validatedMessages.map((item: IValidatedMessage, index: number) => {
              if (item.is_valid) {
                return <div key={index}>{item.message}</div>;
              }
              if (!item.is_valid) {
                return (
                  <div key={index} className="error">
                    {item.message}
                  </div>
                );
              }
            })}
          {validatedMessages &&
            validatedMessages.length > 0 &&
            validatedMessages.every(
              (entry: IValidatedMessage) => entry.is_valid
            ) && (
              <div className="bold">
                読み込みが完了しました。データを登録するには、右下の登録ボタンを押してください。
              </div>
            )}
          {/* <div className="error">3行目：の読み込みでエラーがありました。</div> */}
        </ResizeWrapper>
      </Modal>
    </WrapperFileUpload>
  );
};

export default FileUploader;
