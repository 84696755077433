import {
  ActionType,
  ICreateActivityData,
  ICreateActivityResponse,
} from "../../../models/activitymanagement/createActivity";

export const createActivityAction = (payload: ICreateActivityData) => {
  return {
    type: ActionType.CREATE_ACTIVITY,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_ACTIVITY_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ICreateActivityResponse) => {
  return {
    type: ActionType.CREATE_ACTIVITY_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_ACTIVITY_RESET,
  };
};
