import styled from "styled-components";
import { Checkbox } from "antd";
import { theme } from "theme/theme";
import {
  Input,
  Button,
  Select,
  Table,
  Modal,
  Collapse as CollapseCustom,
} from "components";
import { Collapse } from "./components";

interface IText {
  size?: number;
  weight?: number;
  lnHeight?: string;
  width?: string;
  minWidth?: string;
  color?: string;
  mg?: string;
  align?: any;
}

interface IContainer {
  pd?: string;
  justify?: string;
  align?: string;
  mg?: string;
  width?: any;
  wrap?: any;
  bdr?: any;
  border?: string;
  background?: string;
}
interface IHeaderCollapse {
  background?: string;
}

interface DayContentsProps {
  hasSendMessage: boolean;
}

export const Image = styled.img`
  display: inline-block;
  margin-right: 8px;
  width: 59px;
  height: 58px;
  background: #ffffff;
  border-radius: 8px 0px 0px 8px;
`;

export const Wrapper = styled.div`
  display: flex;
`;
export const WrapperSearchCondition = styled.div`
  ${theme.media.laptopL} {
    width: 312px;
    min-width: 312px;
    margin-right: 24px;
    padding: 24px 0px;
  }
`;
export const TopSearchCondition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: none;
    font-size: ${theme.sizes.lg};
    font-weight: ${theme.fontWeight.bold};
    line-height: 40.8px;
    color: ${theme.colors.text.primary};
  }
  button {
    position: absolute;
    bottom: 40px;
    right: 0px;
    padding: 8px 24px;
  }
  ${theme.media.tablet} {
    button {
      bottom: 32px;
    }
  }
  ${theme.media.laptopL} {
    margin-bottom: 25px;
    button {
      position: unset;
    }
    div {
      display: block;
    }
  }
`;
export const ContentSearchCondition = styled.div`
  padding-bottom: 32px;
  ${theme.media.tablet} {
    padding-bottom: 32px;
  }
  ${theme.media.laptopL} {
    padding-top: 9px;
    padding-bottom: 0;
    margin-bottom: 32px;
    border-bottom: unset;
  }
  .div-select .ant-select-selection-item-remove {
    display: none;
  }
`;

export const WrapperTable = styled.div`
  max-width: 100%;
  width: 100%;
  position: relative;
  padding: 24px 0 16px 0;
  ${theme.media.tablet} {
    padding: 24px 0 24px 0;
  }
  ${theme.media.laptopL} {
    max-width: calc(100% - 336px);
    border-left: 1px solid ${theme.colors.border};
    padding: 24px 0px 32px 24px;
  }
`;
export const ContentTable = styled.div``;
export const TopTable = styled.div`
  margin-bottom: 24px;

  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${theme.media.laptopL} {
    justify-content: flex-end;
  }
`;

export const SelectStyled = styled(Select)`
  .ant-select-selection-overflow .ant-select-selection-item {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    letter-spacing: 1px;
    background: ${theme.colors.white}!important;
    padding: 0 auto !important;
    height: 19px;
    line-height: 12px;
  }
`;

export const SelectMultipleStyled = styled(Select)`
  .ant-select-selection-item {
    height: 20px;
  }
`;

export const WrapperCollapse = styled.div`
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
  .ant-collapse-item {
    padding: 0 8px;
  }
  .ant-collapse-header {
    padding: 16px 8px !important;
    font-size: ${theme.sizes.md};
  }
  .ant-collapse-content {
    padding: 0 8px;
    border-top: 2px solid ${theme.colors.background}!important;
  }
  .ant-collapse-arrow {
    top: 26px !important;
  }
  ${theme.media.tablet} {
  }
`;

export const SendAllStudentBtn = styled(Button)`
  width: 100%;
  height: 31px;
  font-size: ${theme.sizes.md};
  margin-bottom: 8px;
  ${theme.media.tablet} {
    width: unset;
    padding: 3px 44px 3px;
    margin-bottom: 0;
  }
  ${theme.media.laptopL} {
    width: 100%;
    padding: 3px 16px 4px 16px;
    font-size: 16px;
  }
`;

export const SendAllStudentBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchButton = styled(Button)`
  width: 100%;
  height: 40px;
  font-size: ${theme.sizes.md};
  margin-bottom: 32px;
`;

export const SearchButtonContainer = styled.div`
  // border-bottom: 1px solid ${theme.colors.border};
  margin-bottom: 24px;
`;

export const NotifyText = styled.div`
  font-size: 16px;
  color: ${theme.colors.text.secondary};
  margin-top: 40px;
`;

export const WrapperFields = styled.div`
  display: flex;
  svg {
    margin-top: 4px;
    cursor: pointer;
  }
`;

export const BadgeContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  div {
    background: #f2f2f2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 3px 5px 4px 4px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    margin-right: 3px;
    width: max-content;
  }
  svg {
    width: 9px;
    height: 10px;
    cursor: pointer;
  }
`;

export const SectionContainer = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.div(({ theme }) => ({
  color: theme.colors.text.secondary,
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.bold,
  lineHeight: "22px",
  marginBottom: 8,
  span: {
    color: "#EB5757",
  },
}));

export const TagsContainer = styled.div`
  width: 100%;
  height: 89px;
  max-height: 89px;
  padding: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 16px 0px 13px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }
  ${theme.media.tablet} {
    height: 62px;
    max-height: 62px;
  }
`;

export const WrapperBtnForm = styled.div`
  padding: 104px 0px 24px;
  border-top: 0;

  button {
    width: 100%;
    padding: 11px 0 13px;
  }

  ${theme.media.tablet} {
    padding: 24px 0px;
    display: flex;
    justify-content: flex-end;
    button {
      width: 256px;
      padding: 11px 24px 13px;
    }
  }
`;

export const ConfirmContainer = styled.div`
  text-align: center;
  padding: 32px 25px;
  width: 367px;
  height: max-content;
  min-height: 208px;
  position: absolute;
  left: calc(50% - 184px);
  top: calc(50% - 225px);
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #b7b7b7;
  box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.12);
  z-index: 999;
  ${theme.media.tablet} {
    width: 532px;
    left: calc(50% - 266px);
    top: calc(50% - 104px);
    padding: 32px 35px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  button {
    width: 151px;
  }
  ${theme.media.tablet} {
    gap: 40px;
    button {
      width: unset;
    }
  }
`;

export const TextConfirm = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: 22px;
  line-height: 28px;
  color: ${theme.colors.text.primary};
  margin-bottom: 23px;
  div:first-child {
    margin-bottom: 15px;
  }
`;

export const TextConfirm2 = styled(TextConfirm)`
  padding: 0 24px;
  ${theme.media.tablet} {
    padding: 0;
    .text-color {
      color: ${theme.colors.textLink};
    }
    > div:last-child {
      margin-top: 15px;
    }
  }
`;

export const ConfirmBtnStyled = styled(Button)`
  margin-bottom: 24px;
  width: 100%;
  ${theme.media.tablet} {
    margin-bottom: 48px;
    width: unset;
  }
`;

export const Text = styled.div<IText>(
  ({ size, weight, lnHeight, width, minWidth, color, mg, align, theme }) => ({
    color: color || theme.colors.text.primary,
    fontSize: size || "16px",
    lineHeight: lnHeight || "24px",
    width: width,
    minWidth: minWidth,
    fontWeight: weight || theme.fontWeight.regular,
    margin: mg,
    textAlign: align,
  })
);

export const Container = styled.div<IContainer>(
  ({ pd, width, justify, wrap, mg, align, bdr, border, background }) => ({
    display: "flex",
    flexWrap: wrap ? wrap : "initial",
    justifyContent: justify ? justify : "initial",
    alignItems: align ? align : "initial",
    padding: pd ? pd : "0px",
    width: width ? width : "100%",
    margin: mg ? mg : "0px",
    height: "max-content",
    background: background,
    borderRadius: bdr ? bdr : 8,
    border: border ? border : "none",
    "> svg": {
      marginTop: "48px",
    },
  })
);

export const CollapseStyled = styled(Collapse)`
  .ant-collapse-content-box {
    padding-top: unset !important;
  }
  .ant-collapse-arrow {
    top: unset !important;
    right: calc(50% - 6px) !important;
    bottom: 13px;
  }
  ${theme.media.tablet} {
    .ant-collapse-arrow {
      top: 20px !important;
      right: 16px !important;
    }
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-header {
    background: #fbfbfb;
    padding: 16px;
  }
  .ant-modal-body {
    background: #fbfbfb;
    padding: 16px 16px 0;
    border-radius: 12px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 16px 24px 0;
    }
    .ant-modal-header {
      padding: 23px 24px;
    }
  }
`;

export const MessageHistoryModalStyled = styled(ModalStyled)`
  .ant-modal-header {
    padding: 16px !important;
  }
  .ant-modal-body {
    background: #fbfbfb;
    padding: 16px !important;
    border-radius: 12px;
  }

  ${theme.media.tablet} {
    zoom: 80%;
    .ant-modal-body {
      padding: 16px 12px 18px 24px !important;
    }
    .ant-modal-header {
      padding: 18px 24px !important;
    }
  }
`;

export const InputStyled = styled(Input)`
  padding: 0 16px;
`;

export const TableStyled = styled(Table)`
  .ant-table-thead > tr > th:nth-child(2) {
    text-align: center;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${theme.colors.button};
  }
  th.ant-table-selection-column.ant-table-cell-fix-left {
    z-index: 2;
  }
`;

export const ConfirmTableStyled = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 26px 32px 27px;
  }
  .ant-table-thead > tr > th:first-child {
    padding: 26px 24px 27px;
  }
`;

export const WrapperTextLink = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: ${theme.colors.textLink};
  }
`;

export const WrapperTextLinkBold = styled(WrapperTextLink)`
  margin-bottom: 41px;
  ${theme.media.tablet} {
    margin-bottom: 0;
  }
  ${theme.media.laptopL} {
    display: none;
  }
`;

export const TextLink = styled.div`
  font-size: ${theme.sizes.md};
  color: ${theme.colors.textLink};
  text-decoration: underline;
  margin-right: 8px;
  cursor: pointer;
`;

export const TextLinkBold = styled(TextLink)`
  font-weight: ${theme.fontWeight.bold};
`;

export const WrapperActionSection = styled.div`
  background: ${theme.colors.background};
  display: flex;
  justify-content: end;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 8px;
  padding: 9px 12px;

  input {
    width: 187px;
  }
  ${theme.media.tablet} {
    input {
      width: 371px;
    }
  }
`;

export const SendMessageBtn = styled(Button)`
  width: 100%;
  padding: 7px 24px;
  ${theme.media.tablet} {
    width: unset;
  }
`;

export const ConfirmModalStyled = styled(Modal)`
  width: 375px !important;
  max-width: calc(100vw - 4px);
  top: 50%;
  transform: translate(0%, -50%);
  ${theme.media.tablet} {
    width: 720px !important;
  }
`;

export const WrapperCustomHeaderModal = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const WrapperDatePicker = styled.div`
  position: absolute;
  bottom: -67px;
`;

export const WrapperAddressList = styled.div`
  max-height: 88px;
  margin-right: 4px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    margin: 0px 8px 0px;
    border-left: 4px solid ${theme.colors.background};
    border-right: 4px solid ${theme.colors.background};
    background: ${theme.colors.white};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.background};
    border-right: 4px solid ${theme.colors.background};
  }
  ${theme.media.tablet} {
    max-height: 100px;
    margin-right: 16px;
  }
`;

export const DestinationsModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 16px 3px 16px 16px;
    max-height: 728px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      margin: 16px 0px 13px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
      background: ${theme.colors.background};
      border-radius: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 7px;
      border-left: 4px solid ${theme.colors.white};
      border-right: 4px solid ${theme.colors.white};
    }
  }
  .ant-modal-header {
    padding: 16px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 16px 16px 16px 24px;
      max-height: 590px;
    }
    .ant-modal-header {
      padding: 26px 24px;
    }
  }
`;

export const TextTitleCollapse = styled.div`
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.md};
  line-height: 18px;
  font-weight: ${theme.fontWeight.bold};
  ${theme.media.tablet} {
    width: 421px;
    margin-top: 1px;
  }
`;

export const TextDateCollapse = styled.div`
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  line-height: 20px;
  font-weight: ${theme.fontWeight.regular};
  margin: 2px 0px 8px 0px;
  span {
    margin-right: 5px;
  }
  ${theme.media.tablet} {
    width: 146px;
    margin: 0px 40px 0px 0px;
  }
`;

export const WrapperFile = styled.div<IHeaderCollapse>(
  ({ theme, background }) => ({
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 16,
    margin: "0px 16px",
    background: background,
    borderTop: `2px dashed ${theme.colors.border}`,
    width: "100%",
    "@media (min-width: 768px)": {
      gap: 16,
    },
  })
);

export const ItemFile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${theme.colors.border};
  margin-bottom: 16px;
  cursor: pointer;
  > div {
    background: #eb5757;
    height: 58px;
    width: 59px;
    border-radius: 7px 0px 0px 7px;
    margin-right: 8px;
    position: relative;
    > svg {
      position: absolute;
      top: 11px;
      left: 20px;
      width: 18px;
      height: 23px;
    }
    > div {
      position: absolute;
      top: 39px;
      left: 18px;
      line-height: 12px;
      font-size: ${theme.sizes.xs};
      color: ${theme.colors.white};
      font-weight: ${theme.fontWeight.medium};
    }
  }
  p {
    font-size: 12px;
    font-weight: ${theme.fontWeight.medium};
    line-height: 12px;
    color: #4f4f4f;
    text-align: left;
    word-wrap: break-word;
  }
  ${theme.media.tablet} {
    width: 203px;
    p {
      width: 125px;
    }
  }
`;

export const SearchConditionModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 16px !important;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 24px !important;
    }
  }
`;

export const WrapperHeaderCollapse = styled.div<IHeaderCollapse>(
  ({ background }) => ({
    display: "flex",
    flexWrap: "wrap",
    background: background,
    padding: "10px 16px 34px 16px",
    width: "100%",
    "@media (min-width: 768px)": {
      flexWrap: "unset",
      padding: "14px 12px 14px 16px",
    },
  })
);

export const WrapperTextDate = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${theme.media.tablet} {
    justify-content: unset;
    width: unset;
    svg {
      position: absolute;
      left: 172px;
    }
  }
`;

export const FailText = styled(Text)`
  padding: 0 11px;
  ${theme.media.tablet} {
    padding: 0;
  }
`;

export const WrapperSizeChange = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 20px;
  ${theme.media.tablet} {
    position: relative;
    top: unset;
  }
`;

export const WrapperPagination = styled.div`
  text-align: center;
  ${theme.media.mobileS} {
    width: 100%;
  }
  ${theme.media.tablet} {
    text-align: right;
    width: auto;
  }
`;

export const WrapperPaginationBottom = styled.div`
  margin-top: 8px;
  ${theme.media.tablet} {
    margin-top: 24px;
  }
`;

export const WrapperActionTable = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  ${theme.media.tablet} {
    justify-content: space-between;
  }
`;

export const WrapperMessage = styled.div`
  padding: 12px 16px;
  background: ${theme.colors.background};
`;

export const Message = styled.div`
  a {
    color: ${theme.colors.textLink};
    text-decoration: underline;
  }
  ${theme.media.tablet} {
    display: flex;
    p {
      margin-left: 16px;
    }
  }
`;

export const NoDataMessage = styled.div`
  margin-top: 40px;
  margin-left: 16px;
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.sizes.md};
  line-height: 18px;
`;

export const SendMessageModalStyled = styled(ModalStyled)`
  .ant-modal-body {
    position: relative;
    padding: 16px 24px;
  }
`;

export const SendAllBadge = styled.p`
  position: absolute;
  top: 7px;
  left: 8px;
  width: 112px;
  height: 32px;
  background: ${theme.colors.important};
  border-radius: 6px;
  padding: 4px 16px 5px;
  line-height: 23px !important;
  font-size: ${theme.sizes.md}!important;
  font-weight: ${theme.fontWeight.bold}!important;
  color: ${theme.colors.white};
`;

export const WrapperSendMessageBtn = styled.div`
  position: absolute;
  bottom: 88px;
  width: calc(100% - 32px);
  button {
    width: 100%;
    padding: 9px 0 8px;
  }
  ${theme.media.tablet} {
    bottom: 24px;
    right: 24px;
    width: unset;
    button {
      width: unset;
      padding: 8px 88px 9px;
    }
  }
`;

export const DayContents = styled.span<DayContentsProps>`
  position: relative;
  &::after {
    content: "";
    width: 4px;
    height: 4px;
    background-color: ${theme.colors.important};
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    top: 2px;
    right: -5px;
    ${({ hasSendMessage }) =>
      !hasSendMessage &&
      `
        display: none;
      `}
  }
`;

export const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
  alignItems: "unset",
  outline: "none",
  "&:hover": {
    ".ant-checkbox-inner": {
      borderColor: theme.colors.border,
    },
  },
  ".ant-checkbox": {
    top: "unset",
  },
  ".ant-checkbox-inner": {
    width: 20,
    height: 21,
    border: `2px solid ${theme.colors.border}`,
  },
  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.colors.button,
    borderColor: `${theme.colors.button}!important`,
  },
  ".ant-checkbox-checked::after": {
    border: "none",
  },
  ".ant-checkbox-input:focus + .ant-checkbox-inner": {
    borderColor: theme.colors.border,
  },
  ".ant-checkbox-disabled + span": {
    color: "#3B3B3B",
  },
  ".ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after": {
    borderColor: "#fff !important",
  },
  ".ant-checkbox-indeterminate .ant-checkbox-inner::after": {
    backgroundColor: theme.colors.button,
  },
}));

export const HistoryTitleWrapper = styled.div`
  display: flex;
  justify-content: initial;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
  > a {
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    border: 1px solid #fbfbfb;
    position: relative;
    cursor: pointer;
  }
  .back {
    height: 14px;
    width: 13px;
    position: absolute;
    top: 4px;
    left: 3px;
  }
  ${theme.media.tablet} {
    margin-bottom: 21px;
  }
  ${theme.media.desktop} {
  }
`;

export const HistoryContainer = styled.div`
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  padding: 24px 16px 32px;
  ${theme.media.tablet} {
    padding: 24px 24px 32px;
  }
`;

export const HistoryTitle = styled.div`
  padding: 16px 16px 23px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #f2f2f2;
  .title {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.md};
    line-height: ${theme.sizes.md};
    margin-bottom: 24px;
  }
  ${theme.media.tablet} {
    padding: 24px 24px 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin-bottom: 0;
    }
  }
  ${theme.media.desktop} {
    padding-top: 16px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
  gap: 32px;
  ${theme.media.tablet} {
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
  }
`;

export const PaginationBottom = styled.div`
  width: max-content;
  margin-top: 32px;
  ${theme.media.tablet} {
    margin-left: auto;
    margin-top: 16px;
  }
`;

export const ConfirmPaginationBottom = styled.div`
  width: 100%;
  margin-top: 24px;
  ${theme.media.tablet} {
    margin-left: auto;
    margin-top: 8px;
    padding-right: 16px;
    width: max-content;
  }
`;

export const WrapperChangeSize = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const WrapperTextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .content {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.sm};
    line-height: ${theme.sizes.xl};
    order: 2;
    width: 100%;
  }
  .badge {
    width: 100%;
    color: ${theme.colors.white};
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.sizes.xs};
    line-height: ${theme.sizes.xs};
    padding: 4px 8px;
    background: #2f8cae;
    border-radius: 6px;
    text-align: center;
    order: 1;
  }
  ${theme.media.tablet} {
    margin-right: 68px;
    gap: 32px;
    flex-direction: row;
    .content {
      flex: 1;
      width: unset;
      order: 1;
    }
    .badge {
      width: unset;
      order: 2;
    }
  }
`;

export const WrapperTextContent2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .status {
    padding: 4px 0px 3px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: ${theme.colors.white};
    background: ${theme.colors.text.secondary};
    border-radius: 9px;
    margin-top: 16px;
    margin-bottom: 12px;
    width: 100%;
  }
  .content {
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.sm};
    line-height: ${theme.sizes.xl};
    order: 2;
    width: 100%;
  }

  button {
    width: 100%;
    margin-bottom: 16px;
  }
  ${theme.media.tablet} {
    margin-right: 30px;
    gap: 16px;
    flex-direction: row;
    .content {
      flex: 1;
      width: unset;
      order: 1;
    }
    .status {
      padding: 4px 8px 3px;
      margin-bottom: 6px;
      margin-top: 0;
      width: max-content;
    }
    button {
      margin-bottom: 0;
    }
  }
  ${theme.media.laptop} {
    button {
      width: fit-content;
    }
  }
`;

export const ConfirmStatusModalStyled = styled(Modal)`
  padding: 8px 0px;
  top: 0;
  .ant-modal-body {
    padding: 16px 16px 32px;
    height: max-content;
  }
  .ant-modal-header {
    padding: 16px;
  }
  .ant-modal-close {
    top: 0;
    right: -5px;
  }
  ${theme.media.tablet} {
    padding: 24px 0px;
    .ant-modal-body {
      padding: 16px 24px;
    }
    .ant-modal-header {
      padding: 24px 24px;
    }
    .ant-modal-close {
      top: 11px;
      right: 5px;
    }
  }
`;

export const ComfirmStatusTitle = styled.div`
  .status {
    padding: 4px 0px 3px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: ${theme.colors.white};
    background: ${theme.colors.text.secondary};
    border-radius: 9px;
    margin-top: 16px;
    margin-bottom: 32px;
    width: 100%;
  }
  button {
    width: 100%;
  }
  ${theme.media.tablet} {
    display: flex;
    align-items: center;
    gap: 24px;
    .status {
      padding: 4px 8px 3px;
      margin: 0;
      width: max-content;
    }
    button {
      width: fit-content;
    }
  }
`;

export const FilterContainer = styled.div`
  margin-bottom: 24px;
  .filter {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 32px;
    .title {
      color: #666666;
      margin-right: 16px;
    }
    .item {
      display: flex;
      gap: 4px;
      align-items: center;
      .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        border: 2px solid ${theme.colors.border};
      }
      .ant-checkbox-inner::after {
        left: 20%;
      }
    }
    .item:last-child {
      margin-left: 20px;
    }
  }
  ${theme.media.tablet} {
    gap: 0;
    margin-bottom: 8px;
    padding: 0px 16px 0px 24px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    .filter {
      margin-bottom: 0;
      width: unset;
    }
  }
`;

export const WrapperGroup = styled.div`
  ${theme.media.tablet} {
    order: 2;
  }
  ${theme.media.laptop} {
    display: flex;
    align-items: center;
    gap: 16px;
    height: max-content;
    .status {
      margin-bottom: 0;
    }
  }
`;

export const ModalStyled2 = styled(Modal)`
  max-width: calc(100vw - 6px);
  width: 100% !important;
  ${theme.media.tablet} {
    width: 532px !important;
  }
`;

export const WrapperSelectPeriod = styled.div`
  background: ${theme.colors.background};
  border-radius: 8px;
  padding: 16px;
  margin-left: 24px;
  button {
    width: 100%;
  }
  ${theme.media.tablet} {
    display: flex;
    gap: 16px;
    padding: 12px 16px;
    width: 642px;
    button {
      width: 274px;
      height: 40px;
      margin-top: 18px;
      margin-left: 40px;
    }
    .react-datepicker__input-container input {
      width: 142px;
    }
    .ant-select-selector {
      background-color: #ffffff !important;
    }
    .ant-select {
      width: 142px;
    }
  }
`;

export const Line = styled.div`
  border: 1px solid #cccccc;
  margin: 8px 0;
  ${theme.media.tablet} {
    width: 100%;
  }
`;

export const WrapperSendImmediately = styled.div`
  .ant-radio-group {
    margin-bottom: 32px;
  }
  button {
    width: 100%;
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 208px;
    }
    .ant-radio-group {
      margin-bottom: 0;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  position: relative;
  > div:first-child {
    margin: 0;
  }
`;

export const WrapperConfirmChangeDelivery = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  margin-bottom: 32px;
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    .react-datepicker__input-container > div > div {
      width: 100%;
    }
    > div:first-child {
      width: 75px;
    }
    > div:last-child {
      width: 100%;
    }
  }
  ${theme.media.tablet} {
    flex-direction: row;
    button {
      position: absolute;
      right: 18px;
      bottom: -4px;
    }
    .ant-select {
      width: 105px;
    }
    > div {
      > div:first-child {
        width: 100%;
      }
    }
  }
`;

export const CollapseSendImmediatelyButton = styled(CollapseCustom)`
  position: relative;
  .ant-collapse-header {
    width: fit-content;
  }
  .ant-collapse-header svg {
    top: 40% !important;
    right: -32px !important;
  }
  button {
    width: 100%;
  }
  ${theme.media.tablet} {
    width: fit-content;
  }
`;

export const UpdateStatusModalStyled = styled(Modal)`
  .ant-modal-header {
    background: #fbfbfb;
    padding: 16px;
  }
  .ant-modal-body {
    background: #fbfbfb;
    padding: 32px;
    border-radius: 12px;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 16px 24px 24px;
    }
    .ant-modal-header {
      padding: 23px 24px;
    }
  }
`;

export const DeleteMessage = styled.div`
  margin: 40px auto 16px;
  font-family: Noto Sans JP;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${theme.colors.error};
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;

  ${theme.media.tablet} {
    margin: 40px 0 0;
  }
`;

export const WrapperDateAndTime = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;

  ${theme.media.tablet} {
    margin-bottom: 0;
  }
`;

export const WrapperDateAndTimeContainer = styled.div`
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }
`;

export const WrapperDateAndTimeContent = styled.div`
  ${theme.media.tablet} {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  ${theme.media.tablet} {
    width: fit-content;
  }
`;
