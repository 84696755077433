import {
  ActionType,
  IHealthCheckSettingPayload,
  IHealthCheckSettingResponse,
} from "models/setting/healthCheckSetting";

export const setHealthCheckSettingAction = (
  payload: IHealthCheckSettingPayload
) => {
  return {
    type: ActionType.HEALTH_CHECK_SETTING,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.HEALTH_CHECK_SETTING_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: IHealthCheckSettingResponse) => {
  return {
    type: ActionType.HEALTH_CHECK_SETTING_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.HEALTH_CHECK_SETTING_RESET,
  };
};
