import { IResponse } from "models";

export enum ActionType {
  CONSULTATION_SETTINGS = "action/CONSULTATION_SETTINGS",
  CONSULTATION_SETTINGS_SUCCESS = "action/CONSULTATION_SETTINGS_SUCCESS",
  CONSULTATION_SETTINGS_ERROR = "action/CONSULTATION_SETTINGS_ERROR",
  CONSULTATION_SETTINGS_RESET = "action/CONSULTATION_SETTINGS_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ITeacherInfo {
  id: number;
  name: string;
  email: string;
}

export interface IConsultationSettings {
  id: number;
  email: string;
  teacher_exchange_flag: boolean;
  teacher_exchange_time_flag: boolean;
  teacher_consult_flag: boolean;
  teacher_consult_time_flag: boolean;
  doctor_consult_flag: boolean;
  doctor_consult_time_flag: boolean;
  teacher_exchange_days: any;
  teacher_exchange_start_time: string;
  teacher_exchange_end_time: string;
  teacher_exchange_notification_flag: boolean;
  teacher_consult_days: any;
  teacher_consult_start_time: string;
  teacher_consult_end_time: string;
  teacher_consult_notification_flag: boolean;
  doctor_consult_days: any;
  doctor_consult_start_time: string;
  doctor_consult_end_time: string;
  consultation_request_teachers: ITeacherInfo[];
}

export interface IConsultationSettingsResponse extends IResponse {
  result: IConsultationSettings;
}
