import { IResponse } from "models";

export enum ActionType {
  GET_QUESTIONNAIRES_DETAIL = "action/GET_QUESTIONNAIRES_DETAIL",
  GET_QUESTIONNAIRES_DETAIL_SUCCESS = "action/GET_QUESTIONNAIRES_DETAIL_SUCCESS",
  GET_QUESTIONNAIRES_DETAIL_ERROR = "action/GET_QUESTIONNAIRES_DETAIL_ERROR",
  GET_QUESTIONNAIRES_DETAIL_RESET = "action/GET_QUESTIONNAIRES_DETAIL_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface QuestionnairesQuestion {
  id: number;
  answer_type: string;
  question_content: string;
  question_summary: string;
  is_free_text: boolean;
  is_required: boolean;
  question_position: number;
  _destroy?: boolean;
  copy_idx?: number;
  questionnaire_answers: {
    id: number;
    answer_text: string;
    _destroy?: boolean;
  }[];
}

export interface IQuestionnaire {
  id?: number;
  title?: string;
  explanation?: string;
  start_time?: string;
  end_time?: any;
  is_draft?: boolean;
  created_at?: string;
  updated_at?: string;
  questionnaire_questions?: QuestionnairesQuestion[];
}

export interface QuestionnairesDetailResponse extends IResponse {
  result: {
    questionnaire: IQuestionnaire;
  };
}
