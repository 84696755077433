import * as React from "react";

function SendIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5 7.49988L4.16669 16.6665L18.3334 22.4999M32.5 7.49988L25.8334 35.8332L18.3334 22.4999M32.5 7.49988L18.3334 22.4999"
        stroke={props.fill}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SendIcon;
