import {
  Action,
  ActionType,
  IMentalCheckDetailsResponse,
} from "models/mentalHealth/mentalCheckDetails";
import createReducer from "redux/createReducer";

export interface MentalCheckDetailsReducerType {
  loading: boolean;
  error?: string;
  mentalCheckDetailsResponse?: IMentalCheckDetailsResponse;
}

const defaultState: MentalCheckDetailsReducerType = {
  loading: false,
  error: undefined,
  mentalCheckDetailsResponse: undefined,
};

export const mentalCheckDetailsReducer =
  createReducer<MentalCheckDetailsReducerType>(defaultState, {
    [ActionType.MENTAL_CHECK_DETAILS](state: MentalCheckDetailsReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MENTAL_CHECK_DETAILS_ERROR](
      state: MentalCheckDetailsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        mentalCheckDetailsResponse: undefined,
      };
    },

    [ActionType.MENTAL_CHECK_DETAILS_SUCCESS](
      state: MentalCheckDetailsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        mentalCheckDetailsResponse: action.payload,
      };
    },

    [ActionType.MENTAL_CHECK_DETAILS_RESET_DATA](
      state: MentalCheckDetailsReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
  });
