import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DataDetailFormResponse,
} from "models/bodyTemperature/detailForm";
import { httpStatus } from "configs/httpStatus";
import { fetchDetailFormData } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./detailFormActions";

function* getDataDetailFormSaga({ payload }: { payload: { locale: string } }) {
  try {
    const response: DataDetailFormResponse = yield call(
      fetchDetailFormData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDataDetailFormWatcher() {
  yield takeLatest(ActionType.DATA_DETAIL_FORM as any, getDataDetailFormSaga);
}

export default [fork(onGetDataDetailFormWatcher)];
