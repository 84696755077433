import { put, takeLatest, fork, call } from "redux-saga/effects";
import { DeleteUserData, ActionType } from "models/deleteuser";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { deleteUser } from "services/userList";
import { handleSuccesAction, handleErrorAction } from "./deleteuserActions";

function* deleteUserSaga({ payload }: { payload: DeleteUserData }) {
  try {
    const response: IResponse = yield call(deleteUser, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDeleteUserListWatcher() {
  yield takeLatest(ActionType.DELETE_USER as any, deleteUserSaga);
}

export default [fork(onDeleteUserListWatcher)];
