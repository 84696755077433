import {
  ActionType,
  DataFeverPercentageResponse,
} from "models/bodyTemperature/feverPercentage";

export const getDataFeverPercentage = (payload: { date: string }) => {
  return {
    type: ActionType.DATA_FEVER_PERCENTAGE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DATA_FEVER_PERCENTAGE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DataFeverPercentageResponse) => {
  return {
    type: ActionType.DATA_FEVER_PERCENTAGE_SUCCESS,
    payload,
  };
};
