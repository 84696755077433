import { RootState } from "../../rootReducer";

export const selectIsUpdatingMentalCheck = (state: RootState) =>
  state.updateMentalCheck.loading;
export const selectIsUpdated = (state: RootState) =>
  state.updateMentalCheck.saved;
export const selectUpdateErrorMessage = (state: RootState) =>
  state.updateMentalCheck.error;
export const updateMentalCheckDataResponse = (state: RootState) =>
  state.updateMentalCheck.updateMentalCheckResponse;
