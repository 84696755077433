import * as React from "react";

function PlusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.08325 4.41665H5.58325V0.916646C5.58325 0.761937 5.52179 0.613564 5.4124 0.504167C5.303 0.394771 5.15463 0.333313 4.99992 0.333313C4.84521 0.333313 4.69684 0.394771 4.58744 0.504167C4.47804 0.613564 4.41658 0.761937 4.41658 0.916646V4.41665H0.916585C0.761876 4.41665 0.613503 4.4781 0.504106 4.5875C0.39471 4.6969 0.333252 4.84527 0.333252 4.99998C0.333252 5.15469 0.39471 5.30306 0.504106 5.41246C0.613503 5.52185 0.761876 5.58331 0.916585 5.58331H4.41658V9.08331C4.41658 9.23802 4.47804 9.38639 4.58744 9.49579C4.69684 9.60519 4.84521 9.66664 4.99992 9.66664C5.15463 9.66664 5.303 9.60519 5.4124 9.49579C5.52179 9.38639 5.58325 9.23802 5.58325 9.08331V5.58331H9.08325C9.23796 5.58331 9.38633 5.52185 9.49573 5.41246C9.60513 5.30306 9.66658 5.15469 9.66658 4.99998C9.66658 4.84527 9.60513 4.6969 9.49573 4.5875C9.38633 4.4781 9.23796 4.41665 9.08325 4.41665Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default PlusIcon;
