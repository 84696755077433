import {
  ActionType,
  IGetSchoolClassResponse,
} from "../../../models/classmanagement/classList";

export const classListAction = () => {
  return {
    type: ActionType.GET_CLASS_LIST_SCHOOL,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_CLASS_LIST_SCHOOL_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IGetSchoolClassResponse) => {
  return {
    type: ActionType.GET_CLASS_LIST_SCHOOL_SUCCESS,
    payload,
  };
};
