import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/classmanagement/createBulkClasses";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { createBulkClassesType } from "services/schoolclasses";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./createBulkClassActions";
import { ICreateBulkClassData } from "models/classmanagement/createBulkClasses";

function* createBulkClassSaga({ payload }: { payload: ICreateBulkClassData }) {
  try {
    const response: IResponse = yield call(createBulkClassesType, payload);
    if (
      response.status === httpStatus.StatusCreated ||
      response.status === httpStatus.StatusOK
    ) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCreateBulkClassWatcher() {
  yield takeLatest(ActionType.CREATE_BULK_CLASSES as any, createBulkClassSaga);
}

export default [fork(onCreateBulkClassWatcher)];
