import React from "react";
import { Grid } from "antd";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import {
  WrapperChart,
  ContainerChart,
  WrapperTooltipPieChart,
} from "../Questions.style";
const { useBreakpoint } = Grid;

const BarChartCustom = ({ data, colors, unit }: any) => {
  const screens = useBreakpoint();
  const size = screens.xl ? 500 : 250;
  const sizeHeight = screens.xl ? 500 : 200;

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={5}
          fill="#666"
          textAnchor="start"
          transform="rotate(90)"
        >
          {payload.value.length > 11
            ? payload.value.slice(0, 10) + "..."
            : payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <WrapperTooltipPieChart>
          <div>{payload[0].payload.name}</div>
          <div>{`値: ${payload[0].value}%`}</div>
        </WrapperTooltipPieChart>
      );
    }

    return null;
  };

  const CustomizedYAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={5} fill="#3B3B3B" textAnchor="end">
          {payload.value}%
        </text>
      </g>
    );
  };

  return (
    <WrapperChart>
      <ContainerChart>
        <ResponsiveContainer width="100%" height={360}>
          <BarChart
            width={size}
            height={sizeHeight}
            data={data}
            margin={{
              top: 15,
              right: 24,
              left: -20,
              bottom: 0,
            }}
          >
            <Tooltip content={<CustomTooltip />} />
            <CartesianGrid
              vertical={false}
              strokeDasharray="2 2"
              stroke="#CCCCCC"
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              tick={<CustomizedAxisTick />}
              height={140}
              padding={{ left: -10, right: -10 }}
              stroke="#CCCCCC"
              interval={0}
            />
            <YAxis
              dataKey="percent"
              unit={unit || ""}
              tickLine={false}
              stroke="#CCCCCC"
              tick={<CustomizedYAxisTick />}
            />
            <Bar
              dataKey="value"
              fill="#8884d8"
              barSize={24}
              radius={[4, 4, 0, 0]}
            >
              {data.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </ContainerChart>
    </WrapperChart>
  );
};

export default BarChartCustom;
