import {
  Action,
  ActionType,
  DownloadQrCodeResponse,
} from "models/downloadQrCode";
import createReducer from "../createReducer";

export interface DownloadQrcodeReducerType {
  loading: boolean;
  error?: string;
  downloadQrResponse?: DownloadQrCodeResponse;
}

const defaultState: DownloadQrcodeReducerType = {
  loading: false,
  error: undefined,
  downloadQrResponse: undefined,
};

export const downloadQrCodeReducer = createReducer<DownloadQrcodeReducerType>(
  defaultState,
  {
    [ActionType.DOWNLOAD_QR_CODE](state: DownloadQrcodeReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DOWNLOAD_QR_CODE_ERROR](
      state: DownloadQrcodeReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        downloadQrResponse: [],
      };
    },

    [ActionType.DOWNLOAD_QR_CODE_SUCCESS](
      state: DownloadQrcodeReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        downloadQrResponse: action.payload,
      };
    },

    [ActionType.DOWNLOAD_QR_CODE_RESET_DATA](state: DownloadQrcodeReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        downloadQrResponse: [],
      };
    },
  }
);
