import { IResponse } from "models";

export enum ActionType {
  REMIND_PUSH_NOTIFICATION = "action/REMIND_PUSH_NOTIFICATION",
  REMIND_PUSH_NOTIFICATION_SUCCESS = "action/REMIND_PUSH_NOTIFICATION_SUCCESS",
  REMIND_PUSH_NOTIFICATION_ERROR = "action/REMIND_PUSH_NOTIFICATION_ERROR",
  REMIND_PUSH_NOTIFICATION_RESET = "action/REMIND_PUSH_NOTIFICATION_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IRemindPushNotificationPayload {
  id?: number;
}

export interface RemindPushNotificationResponse extends IResponse {}
