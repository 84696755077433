import {
  ActionType,
  FaceScalePayload,
  FaceScaleResponse,
} from "models/dashboard/faceScale";

export const getFaceScaleAction = (payload: FaceScalePayload) => {
  return {
    type: ActionType.GET_FACE_SCALE_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_FACE_SCALE_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: FaceScaleResponse) => {
  return {
    type: ActionType.GET_FACE_SCALE_CHART_SUCCESS,
    payload,
  };
};
