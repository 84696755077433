import styled from "styled-components";
import { Tooltip, Checkbox } from "antd";

import { theme } from "theme/theme";
import { Modal, Select, Button, Collapse, TextArea, Table } from "components";

export const WrapperBodyTemperature = styled.div`
  position: relative;
`;

export const WapperButtonStyled = styled.div`
  display: flex;
  ${theme.media.mobileS} {
    justify-content: center;
  }
  ${theme.media.tablet} {
    justify-content: flex-start;
  }
`;
export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  height: 34px;
  padding: 18px 16px 16px 32px;
  ${theme.media.mobileS} {
    width: 45%;
    justify-content: center;
  }
  ${theme.media.tablet} {
    width: auto;
    justify-content: flex-start;
  }
`;

export const WrapperAdditionContent = styled.div(({ theme }) => ({
  background: theme.colors.white,
  padding: "8px 16px 16px",
  width: "100%",
  borderRadius: 6,
}));

export const BodyTemperatureHeader = styled.div(({ theme }) => ({
  color: theme.colors.titleText,
  fontSize: 17,
  fontWeight: theme.fontWeight.bold,
  lineHeight: "24px",
  marginBottom: 8,
}));

export const MediumDivider = styled.div(({ theme }) => ({
  height: 2,
  width: "100%",
  backgroundColor: theme.colors.border,
  marginTop: 4,
}));

export const WrapperTemperatureInfo = styled.div(({ theme }) => ({
  background: theme.colors.background,
  borderRadius: 8,
  padding: "8px 16px",
  marginBottom: 8,
}));

export const WrapperTemperatureItem = styled.div(({ theme }) => ({
  padding: "4px 0",
  borderBottom: `1px solid ${theme.colors.border}`,
  div: {
    fontSize: 14,
    fontWeight: theme.fontWeight.regular,
  },
  p: {
    fontSize: 14,
    fontWeight: theme.fontWeight.bold,
  },
  "&:last-child": {
    borderBottom: "unset",
  },
}));

export const ButtonDisabledStyled = styled(Button)`
  &:disabled {
    background: ${theme.colors.white};
    color: ${theme.colors.textFieldBackground};
    border-color: ${theme.colors.textFieldBackground};
    svg path {
      fill: ${theme.colors.textFieldBackground};
    }
  }
`;

export const ButtonDisabledStyledWithBackground = styled(Button)`
  &:disabled {
    background: ${theme.colors.textFieldBackground};
    color: ${theme.colors.white};
    border-color: ${theme.colors.textFieldBackground};
  }
`;

export const WrapperBtnForm = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  border-top: 0;

  button {
    width: 148px;
    padding: 11px 0 13px;
  }

  ${theme.media.tablet} {
    padding: 32px 0px 24px;
    button:first-child {
      width: unset;
      padding: 11px 24px 13px;
    }
    button:last-child {
      width: unset;
      padding: 8px 32px 9px;
    }
  }
`;

export const WrapperBtnWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  border-top: 0;

  button {
    width: 148px;
    padding: 11px 0 13px;
  }

  button:last-child {
    width: 148px;
    padding: 11px 0 13px 20px;
  }

  ${theme.media.tablet} {
    padding: 32px 0px 24px;
    button:first-child {
      width: unset;
      padding: 11px 24px 13px;
    }
    button:last-child {
      width: unset;
      padding: 11px 24px 13px 32px;
    }
  }
`;

export const AdditionalModalStyled = styled(Modal)`
  .ant-modal-header {
    background: #fbfbfb;
  }
  .ant-modal-body {
    border-radius: 8px;
    background: #fbfbfb;
  }
  ${theme.media.tablet} {
    top: 100px;
    transform: unset;
    height: 1300px;
  }
  ${theme.media.laptopL} {
    height: unset;
  }
`;

export const DetailModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 16px;
  }
  .ant-modal-close {
    top: 9px;
    right: -9px;
  }
  .ant-modal-header {
    padding: 16px 8px 16px 16px;
  }
  ${theme.media.tablet} {
    .ant-modal-header {
      padding: 18px 24px;
    }
    .ant-modal-body {
      padding: 24px;
    }
    .ant-modal-close {
      top: 9px;
      right: 5px;
    }
  }
`;

export const WrapperSelectStyled = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;

export const BoxItem = styled.div`
  background: #fbfbfb;
  border-radius: 8px;
  border: 1px solid ${theme.colors.background};
  padding: 16px 16px 24px;
  position: relative;
  ${theme.media.tablet} {
    height: 274px;
  }
`;

export const BoxItemStyled = styled(BoxItem)`
  padding: 16px 0 24px 16px;
`;

export const BoxItem4Chart = styled(BoxItem)`
  padding: 16px 16px 20px;
  ${theme.media.laptopL} {
    padding: 16px 8px 20px;
  }
  @media (min-width: 1280px) {
    padding: 16px 16px 20px;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BoxTitle = styled.div(({ theme }) => ({
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.bold,
  lineHeight: "24px",
  color: theme.colors.text.primary,
}));

export const BoxContent = styled.div`
  & > div {
    margin-bottom: 16px;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const BoxContent4Chart = styled.div``;

export const WrapperSelect = styled.div`
  position: absolute;
  top: -3px;
  left: 189px;
  .ant-select {
    width: 123px;
  }
  ${theme.media.tablet} {
    left: 226px;
    .ant-select {
      width: 156px;
    }
  }
  ${theme.media.laptopL} {
    .ant-select {
      width: 220px;
    }
  }
`;

export const WrapperCloseIcon = styled.div`
  position: absolute;
  background: ${theme.colors.white};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: -9px;
  left: -10px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);
  font-size: ${theme.sizes.sm};
  padding-left: 2px;
  cursor: pointer;
`;

export const CollapseStyled = styled(Collapse)`
  position: relative;
  margin-bottom: 24px;
  .ant-collapse-header {
    width: fit-content;
  }
  .ant-collapse-header svg {
    top: 40% !important;
    right: -32px !important;
  }
  ${theme.media.laptopL} {
    margin-bottom: 32px;
  }
`;

export const SelectStyled = styled(Select)`
  .ant-select-selection-item {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    letter-spacing: 1px;
  }
`;

export const WrapperSearchCondition = styled.div`
  ${theme.media.tablet} {
    display: flex;
  }
`;

export const SearchConditionContainer = styled.div`
  ${theme.media.tablet} {
    display: flex;
  }
`;

export const WrapperDatePicker = styled.div`
  ${theme.media.tablet} {
    margin-right: 14px;
  }
`;

export const WrapperSearchConditionAction = styled.div`
  margin-top: 8px;
  border-bottom: 1px solid ${theme.colors.textFieldBackground};
  margin-bottom: 16px;
  ${theme.media.tablet} {
    padding-left: 16px;
    margin-right: 16px;
    margin-bottom: 0;
    border-left: 1px solid ${theme.colors.textFieldBackground};
    border-right: 1px solid ${theme.colors.textFieldBackground};
    border-bottom: none;
    width: 364px;
  }
  ${theme.media.laptopL} {
    width: unset;
    max-width: 740px;
  }
`;

export const WrapperSearchConditionButton = styled.div`
  display: flex;
  justify-content: flex-end;
  ${theme.media.tablet} {
    width: 140px;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const WrapperTableAction = styled.div`
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  ${theme.media.laptopL} {
    margin-bottom: 16px;
  }
`;

export const WrapperSizeChange = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const WrapperPaginationTop = styled.div`
  text-align: center;
  margin: 24px 0;
  ${theme.media.tablet} {
    text-align: right;
    margin: 0;
  }
`;

export const WrapperPaginationBottom = styled.div`
  text-align: center;
  margin: 24px 0;
  ${theme.media.tablet} {
    text-align: right;
  }
  ${theme.media.laptopL} {
    margin: 16px 0 24px;
  }
`;

export const AndCondition = styled.div`
  margin-top: 7px;
  margin-right: 8px;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.sizes.sm};
  line-height: 16px;
  color: ${theme.colors.icon};
`;

export const TitleDetailModal = styled.div`
  display: flex;
  flex-direction: column;
  .patient-name {
    max-width: 98px;
    font-size: 14px;
  }
  ${theme.media.tablet} {
    flex-direction: row;
    .patient-name {
      max-width: unset;
    }
  }
`;

export const WrapperCheckbox = styled.div`
  .ant-checkbox-wrapper {
    align-items: center;
  }
  .ant-checkbox-inner {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 4px;
  }
  .ant-checkbox-inner::after {
    left: 25.5%;
  }
`;

export const WrapperChartText = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
`;

export const ContainerChartText = styled.div`
  transform: rotateY(180deg);
  text-align: center;
  span {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    padding-left: 4px;
  }
  div:first-child {
    font-weight: ${theme.fontWeight.bold};
    font-size: 44px;
    line-height: 44px;
  }
  div:last-child {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.xs};
    line-height: 16px;
    margin-top: 5px;
  }
`;

export const WrapperDatePickerDetail = styled.div`
  width: 118px;
  .react-datepicker__navigation-icon::before {
    top: 5px !important;
  }
`;

export const TooltipRightStyled = styled.div`
  position: absolute;
  display: flex;
  width: max-content;
  background: ${theme.colors.white};
  color: ${theme.colors.textLink};
  font-size: ${theme.sizes.xs};
  font-weight: ${theme.fontWeight.regular};
  line-height: 14px;
  padding: 2px 4px;
  z-index: 100;
  border-radius: 2px;
  border: 1px solid ${theme.colors.border};
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: 6px;
    left: -4px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 7px;
    left: -2px;
    width: 5px;
    height: 5px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const TextAreaStyled = styled(TextArea)`
  height: 93px;
  padding: 4px 4px 12px 12px;
  ${theme.media.tablet} {
    height: 62px;
  }
`;

export const TooltipStyled = styled(Tooltip)`
  color: red;
`;

export const TableStyled = styled(Table)`
  .ant-table-tbody > tr > td {
    vertical-align: top;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #cde3ec;
  }
  .ant-table-thead > tr > th:nth-child(3) {
    background: #fbfbfb;
    &:before {
      height: 100% !important;
    }
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    border-right: 2px solid ${theme.colors.background};
  }
  .ant-table-thead > tr > th:first-child {
    &:before {
      height: 1.6em !important;
    }
  }
  .ant-table-tbody > tr > td:first-child {
    border-right: unset !important;
  }
`;

export const AdditionalBtn = styled(Button)`
  height: 24px;
`;

export const WrapperFaceDetail = styled.div`
  width: 670px;
  display: flex;
  justify-content: space-between;
  p {
    text-align: center;
  }
  div {
    width: 60px;
    text-align: center;
  }
`;

export const WrapperThreeDot = styled.div`
  position: absolute;
  right: 0px;
  bottom: -2px;
`;

export const WrapperChart = styled.div`
  overflow-x: auto;
  width: 100%;
  ${theme.media.tablet} {
    overflow: unset;
  }
`;

export const ContainerChart = styled.div`
  height: auto;
  width: 770px;
  font-size: 12px;
  line-height: 12px;
  .customized-tooltip-content {
    width: 192px;
    padding: 5px 6px 6px;
    background: #ffffff;
    border: 1px solid #b7b7b7;
    box-sizing: border-box;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    font-size: 10px;
    line-height: 10px;
    font-weight: ${theme.fontWeight.regular};
    .list {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .label {
      color: ${theme.colors.text.secondary};
      font-style: normal;
      margin-bottom: 4px;
    }
    .tooltip-item-wrapper {
      display: flex;
      justify-content: space-between;
      .item-left {
        display: flex;
        line-height: 11px;
        color: ${theme.colors.text.primary};
        span {
          margin-left: 4px;
        }
      }
      .item-right {
        color: ${theme.colors.text.secondary};
      }
    }
  }
  .recharts-active-dot {
    display: none;
  }
  .recharts-tooltip-cursor {
    stroke: #ffffff;
  }
  .icon-descriptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  .icon-descriptions-stacked {
    .description {
      display: flex;
      align-items: center;
      margin-right: 4px;
    }
    .description:last-child {
      margin-right: 0px;
    }
  }
  ${theme.media.tablet} {
    width: 100%;
  }
  .recharts-tooltip-cursor {
    fill: transparent !important;
    stroke: transparent !important;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  align-items: center;
  outline: none;
  margin-right: 3px;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.colors.border};
    }
  }
  .ant-checkbox {
    top: unset;
  }
  .ant-checkbox-inner {
    width: 10px;
    height: 10px;
    border-radius: 2px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button}!important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.border};
  }
  div {
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
`;

export const CheckboxStyled2 = styled(CheckboxStyled)`
  .ant-checkbox-inner::after {
    left: 10.5%;
    width: 5px;
    height: 7px;
  }
`;

export const CheckboxStyled3 = styled(CheckboxStyled)`
  .ant-checkbox-inner {
    width: 20px;
    height: 21px;
    border: 2px solid ${theme.colors.border};
  }
`;

export const WrapperFace = styled.div`
  margin: 2px 5px 8px 15px;
  position: relative;
`;

export const ScrollableWrapper = styled.div`
  position: sticky;
  height: 0;
  left: 700px;
  top: 0;
  z-index: 1;
  > svg {
    position: absolute;
    top: 135px;
    left: 60%;
    ${theme.media.desktop} {
      top: 175px;
    }
  }
`;

export const WrapperBodyTemperatureHeader = styled.div`
  p {
    color: ${theme.colors.important};
    font-size: ${theme.sizes.md};
    line-height: 20px;
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const WrapperItem = styled.div`
  min-width: 200px;
  max-width: 350px;
`;

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.border};
  height: 38px;
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.bold};
  padding-left: 10px;
  background: ${theme.colors.white};
`;

export const CheckboxStyled4 = styled(Checkbox)`
  align-items: center;
  outline: none;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.colors.border};
    }
  }
  .ant-checkbox {
    top: unset;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 21px;
    border: 2px solid ${theme.colors.border};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button}!important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.border};
  }

  div {
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
`;

export const AbsenceButton = styled.div<{ background: string }>`
  background: ${(props) => props.background};
  border-radius: 6px;
  padding: 2px 4px;
  font-size: ${theme.sizes.sm};
  line-height: 22px;
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.colors.white};
  margin-right: 5px;
  cursor: pointer;
`;

export const AbsentModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 16px 24px 24px;
  }
  .ant-modal-footer {
    padding: 0px 16px 24px;
    border: unset;
  }
  div {
    font-size: 14px;
    line-height: 22px;
  }
  div.bold {
    font-weight: 700;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 24px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 144px;
    line-height: 23px;
    padding: 3.5px 15px 4.5px;
  }
  button:last-child {
    width: 151px;
  }
  ${theme.media.tablet} {
    justify-content: flex-end;
    button:last-child {
      margin-left: 16px;
    }
    button {
      width: 112px !important;
    }
  }
`;

export const SmallMentalCheckComment = styled.div`
  border: 1px solid #2f8cae;
  max-width: 144px;
  height: auto;
  padding: 3px 7px 5px;
  background: #ffffff;
  position: relative;
  border-radius: 8px;
  color: #3b3b3b;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-left: -32px;
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: -7px;
    left: 10px;
    height: 12px;
    width: 12px;
    border: 1px solid #2f8cae;
    background: #ffffff;
    transform: rotate(67.5deg) skewX(45deg) scaleY(cos(45deg));
  }
  &::before {
    content: "";
    width: 8px;
    height: 1px;
    background: #ffffff;
    position: absolute;
    top: -1px;
    left: 12px;
  }
`;

export const AttendanceText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;
