import * as React from "react";

function LetterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 8H9C8.20435 8 7.44129 8.31607 6.87868 8.87868C6.31607 9.44129 6 10.2044 6 11V21C6 21.7956 6.31607 22.5587 6.87868 23.1213C7.44129 23.6839 8.20435 24 9 24H23C23.7956 24 24.5587 23.6839 25.1213 23.1213C25.6839 22.5587 26 21.7956 26 21V11C26 10.2044 25.6839 9.44129 25.1213 8.87868C24.5587 8.31607 23.7956 8 23 8ZM22.59 10L16.71 15.88C16.617 15.9737 16.5064 16.0481 16.3846 16.0989C16.2627 16.1497 16.132 16.1758 16 16.1758C15.868 16.1758 15.7373 16.1497 15.6154 16.0989C15.4936 16.0481 15.383 15.9737 15.29 15.88L9.41 10H22.59ZM24 21C24 21.2652 23.8946 21.5196 23.7071 21.7071C23.5196 21.8946 23.2652 22 23 22H9C8.73478 22 8.48043 21.8946 8.29289 21.7071C8.10536 21.5196 8 21.2652 8 21V11.41L13.88 17.29C14.4425 17.8518 15.205 18.1674 16 18.1674C16.795 18.1674 17.5575 17.8518 18.12 17.29L24 11.41V21Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default LetterIcon;
