import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  FeverResponse,
  ActionType,
  FeverPayload,
} from "models/dashboard/fever";
import { httpStatus } from "configs/httpStatus";
import { fetchFeverChart } from "services/dashboard";
import { handleSuccesAction, handleErrorAction } from "./feverActions";

function* getFeverSaga({ payload }: { payload: FeverPayload }) {
  try {
    const response: FeverResponse = yield call(fetchFeverChart, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetFeverWatcher() {
  yield takeLatest(ActionType.GET_FEVER_CHART as any, getFeverSaga);
}

export default [fork(onGetFeverWatcher)];
