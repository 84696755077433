import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IConsultationListPayload,
  ActionType,
  IConsultationListResponse,
} from "models/consultationRequest/consultationList";
import { httpStatus } from "configs/httpStatus";
import { fetchConsultationList } from "services/consultationRequest";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./consultationListActions";

function* consultationListSaga({
  payload,
}: {
  payload: IConsultationListPayload;
}) {
  try {
    const response: IConsultationListResponse = yield call(
      fetchConsultationList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetConsultationListWatcher() {
  yield takeLatest(ActionType.CONSULTATION_LIST as any, consultationListSaga);
}

export default [fork(onGetConsultationListWatcher)];
