import * as React from "react";

function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.19661 2.1967C1.14772 3.24559 0.433414 4.58196 0.144024 6.03682C-0.145365 7.49168 0.0031598 8.99968 0.570817 10.3701C1.13847 11.7406 2.09977 12.9119 3.33314 13.736C4.5665 14.5601 6.01655 15 7.49991 15C8.98327 15 10.4333 14.5601 11.6667 13.736C12.9001 12.9119 13.8614 11.7406 14.429 10.3701C14.9967 8.99968 15.1452 7.49168 14.8558 6.03682C14.5664 4.58196 13.8521 3.24559 12.8032 2.1967C12.1068 1.50026 11.28 0.947811 10.37 0.570901C9.4601 0.193991 8.48483 -7.12663e-07 7.49991 -1.04984e-06C6.515 -6.2837e-07 5.53973 0.193992 4.62979 0.570902C3.71985 0.947812 2.89305 1.50026 2.19661 2.1967ZM11.7426 11.7426C10.9034 12.5818 9.83434 13.1532 8.67045 13.3847C7.50657 13.6162 6.30017 13.4974 5.20381 13.0433C4.10746 12.5891 3.17038 11.8201 2.5111 10.8334C1.85181 9.84672 1.49991 8.68668 1.49991 7.5C1.49991 6.31331 1.85181 5.15327 2.5111 4.16658C3.17038 3.17988 4.10746 2.41085 5.20381 1.95672C6.30017 1.5026 7.50657 1.38378 8.67045 1.61529C9.83434 1.8468 10.9034 2.41824 11.7426 3.25736C12.8678 4.38258 13.4999 5.9087 13.4999 7.5C13.4999 9.09129 12.8678 10.6174 11.7426 11.7426ZM9.0909 4.84835L7.49991 6.43934L5.90892 4.84835C5.76827 4.7077 5.5775 4.62868 5.37859 4.62868C5.17968 4.62868 4.98891 4.70769 4.84826 4.84835C4.70761 4.989 4.62859 5.17976 4.62859 5.37868C4.62859 5.57759 4.70761 5.76835 4.84826 5.90901L6.43925 7.5L4.84826 9.09099C4.70761 9.23164 4.62859 9.4224 4.62859 9.62132C4.62859 9.82023 4.70761 10.011 4.84826 10.1516C4.98891 10.2923 5.17968 10.3713 5.37859 10.3713C5.5775 10.3713 5.76827 10.2923 5.90892 10.1516L7.49991 8.56066L9.0909 10.1516C9.23155 10.2923 9.42232 10.3713 9.62123 10.3713C9.82014 10.3713 10.0109 10.2923 10.1516 10.1516C10.2922 10.011 10.3712 9.82023 10.3712 9.62132C10.3712 9.4224 10.2922 9.23164 10.1516 9.09099L8.56057 7.5L10.1516 5.90901C10.2922 5.76835 10.3712 5.57759 10.3712 5.37868C10.3712 5.17976 10.2922 4.989 10.1516 4.84835C10.0109 4.70769 9.82014 4.62868 9.62123 4.62868C9.42232 4.62868 9.23155 4.70769 9.0909 4.84835Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default DeleteIcon;
