import { IResponse } from "models";

export enum ActionType {
  DATA_CHARTS = "action/DATA_CHARTS",
  DATA_CHARTS_SUCCESS = "action/DATA_CHARTS_SUCCESS",
  DATA_CHARTS_ERROR = "action/DATA_CHARTS_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DataChartsResponse extends IResponse {
  result: any;
}
