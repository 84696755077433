import React from "react";
import styled from "styled-components";
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { localStorageHelper } from "utils";

import { theme } from "theme/theme";
import { WrapperErrorMessage, ErrorMessage } from "theme/CommonStyle";

registerLocale("ja", ja);
const dateFormat = "yyyy/MM/dd";
interface IDatePicker extends ReactDatePickerProps {
  label?: React.ReactNode;
  error?: string;
}

const FormControl = styled.div`
  position: relative;
  margin: 0 0 22px 0;
`;

const Label = styled.div`
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.regular};
  line-height: 12px;
  margin-bottom: 8px;
`;

const WrapperDatePicker = styled.div`
  .react-datepicker {
    width: 264px;
    top: -10px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }

  .react-datepicker__input-container input {
    letter-spacing: 0.3px;
    padding-left: 12px;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day {
    margin: 4px 7px;
    font-size: ${({ theme }) => theme.sizes.sm};
    height: 22px;
    width: 22px;
    line-height: 22px;
    font-weight: normal;
  }

  .react-datepicker__day div {
    height: 100%;
    width: 100%;
  }

  .react-datepicker__day--outside-month {
    color: #bdbdbd;
  }

  .react-datepicker__day:not(.react-datepicker__day--outside-month):hover {
    background-color: ${({ theme }) => theme.colors.textLink};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    width: 22px;
    margin: 0 7px;
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.white};
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    font-size: ${({ theme }) => theme.sizes.xs};
    color: ${({ theme }) => theme.colors.text.primary};
    padding: 0;
    border-bottom: 0;
  }

  .react-datepicker__current-month {
    height: 40px;
    padding: 13px 0px 10px;
    margin: 0px 16px;
    text-align: left;
    font-size: ${({ theme }) => theme.sizes.md};
    line-height: ${({ theme }) => theme.sizes.md};
    font-weight: bold;
    color: #4f4f4f;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.background}`};
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day-names {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font-weight: normal;
    font-size: ${({ theme }) => theme.sizes.sm};
  }

  .react-datepicker__day-name {
    margin: 8px 7px 0px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  .react-datepicker__day-name:first-child {
    color: ${({ theme }) => theme.colors.important};
  }
  .react-datepicker__day-name:last-child {
    color: ${({ theme }) => theme.colors.textLink};
  }

  .react-datepicker__month {
    margin: 0 4px 4px 4px;
  }

  .react-datepicker__week {
  }

  .react-datepicker__week:last-child {
    border-bottom: 0;
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: ${({ theme }) => theme.colors.textLink};
    color: ${({ theme }) => theme.colors.white};
  }

  .react-datepicker__day--today {
    font-weight: normal;
  }

  .react-datepicker__navigation {
    top: 12px;
    width: 18px;
    height: 18px;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 50%;
  }

  .react-datepicker__day--highlighted {
    position: relative;
  }
  .react-datepicker__day--highlighted::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: 5px;
    right: 3px;
    background: ${({ theme }) => theme.colors.important};
  }

  .react-datepicker__navigation-icon--next::before {
    left: -3px;
  }

  .react-datepicker__navigation-icon--previous::before {
    right: -3px;
  }

  .react-datepicker__navigation-icon::before {
    top: 7px;
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    border-color: ${({ theme }) => theme.colors.textLink};
  }

  .react-datepicker__navigation--previous {
    left: unset;
    right: 43px;
  }

  .react-datepicker__navigation--next {
    right: 16px;
  }

  .react-datepicker__navigation--previous:focus,
  .react-datepicker__navigation--next:focus,
  .react-datepicker__day--keyboard-selected:focus,
  .react-datepicker__day--selected:focus {
    outline: none;
  }
  ${theme.media.tablet} {
    .react-datepicker__input-container input {
      padding-left: 14px;
    }
  }
`;
const DatePickerStyled = styled(DatePicker)`
  height: 31px;
  width: 100%;
  border-radius: 6px;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.09);
  border: 1px solid ${theme.colors.border};
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.regular};
  line-height: 31px;
  padding: 4px 12px;
  outline: none;
  :focus {
    border: 1px solid ${theme.colors.outline};
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.icon};
  }
`;

const handleClick = (fn: any) => (e: any) => {
  e.preventDefault();
  fn();
};

const CustomHeader = ({ monthDate, decreaseMonth, increaseMonth }: any) => {
  return (
    <>
      <button
        aria-label="Previous Month"
        className={
          "react-datepicker__navigation react-datepicker__navigation--previous"
        }
        onClick={handleClick(decreaseMonth)}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
          }
        >
          {"<"}
        </span>
      </button>
      <div className="react-datepicker__current-month">
        {monthDate.getFullYear()}年{monthDate.getMonth() + 1}月
      </div>
      <button
        aria-label="Next Month"
        className={
          "react-datepicker__navigation react-datepicker__navigation--next"
        }
        onClick={handleClick(increaseMonth)}
      >
        <span
          className={
            "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
          }
        >
          {">"}
        </span>
      </button>
    </>
  );
};

const DatePickerCustom: React.FC<IDatePicker> = ({ label, error, ...rest }) => {
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const returnLocale = () => {
    if (currentLanguage === "ja") {
      return "ja";
    }
    return "en";
  };
  return (
    <FormControl>
      {label && <Label>{label}</Label>}
      <WrapperDatePicker>
        <DatePickerStyled
          locale={returnLocale()}
          dateFormat={dateFormat}
          renderCustomHeader={CustomHeader}
          {...rest}
        />
      </WrapperDatePicker>

      {error && (
        <WrapperErrorMessage>
          <ErrorMessage>{error}</ErrorMessage>
        </WrapperErrorMessage>
      )}
    </FormControl>
  );
};

export default DatePickerCustom;
