export enum ActionType {
  // delete user
  DELETE_USER = "action/DELETE_USER",
  DELETE_USER_SUCCESS = "action/DELETE_USER_SUCCESS",
  DELETE_USER_ERROR = "action/DELETE_USER_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DeleteUserData {
  id?: number;
}
