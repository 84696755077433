import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  AttendanceStatusResponse,
  ActionType,
  AttendanceStatusPayload,
} from "models/dashboard/attendanceStatus";
import { httpStatus } from "configs/httpStatus";
import { fetchAttendanceStatusChart } from "services/dashboard";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./attendanceStatusActions";

function* getAttendanceStatusSaga({
  payload,
}: {
  payload: AttendanceStatusPayload;
}) {
  try {
    const response: AttendanceStatusResponse = yield call(
      fetchAttendanceStatusChart,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetAttendanceStatusWatcher() {
  yield takeLatest(
    ActionType.GET_ATTENDANCE_STATUS_CHART as any,
    getAttendanceStatusSaga
  );
}

export default [fork(onGetAttendanceStatusWatcher)];
