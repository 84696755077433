import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DeleteSlotData,
  DeleteSlotResponse,
} from "models/vaccine/schedules";
import { httpStatus } from "configs/httpStatus";
import { deleteSlot } from "services/vaccine";

import { handleSuccesAction, handleErrorAction } from "./deleteSlotActions";

function* deleteSlotSaga({ payload }: { payload: DeleteSlotData }) {
  try {
    const response: DeleteSlotResponse = yield call(deleteSlot, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDeleteSlotWatcher() {
  yield takeLatest(ActionType.DELETE_SLOT as any, deleteSlotSaga);
}

export default [fork(onDeleteSlotWatcher)];
