import { HealthCheckProgressPayload } from "models/dashboard/healthCheckProgress";
import { TemperatureAvgPayload } from "models/dashboard/temperatureAvg";
import { createApiCall, MethodType, URI, createApiCall2 } from "../Api";
import { DetailSymtomPayload } from "models/dashboard/detailSymtom";
import { AbsencePayload } from "models/dashboard/absence";
import { FeverPayload } from "models/dashboard/fever";
import { SymtomPayload } from "models/dashboard/symtom";
import { TemperatureAmountsPayload } from "models/dashboard/temperatureAmounts";
import { ProgressPayload } from "models/dashboard/progress";
import { FaceScalePayload } from "models/dashboard/faceScale";
import { AttendanceStatusPayload } from "models/dashboard/attendanceStatus";
import { localStorageHelper } from "utils";
import { DeseaseOutbreakPayload } from "models/dashboard/deseaseOutbreak";
import { GetCitiesPayload } from "models/dashboard/cities";
import { GetPrefecturePayload } from "models/dashboard/prefecture";

export const fetchTemperatureAvgChart = (payload: TemperatureAvgPayload) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/temperature_avg`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchDetailSymtomChart = (payload: DetailSymtomPayload) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/symptom_statuses`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchAbsenceChart = (payload: AbsencePayload) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/absences`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchFeverChart = (payload: FeverPayload) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/fevers`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchSymtomChart = (payload: SymtomPayload) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/symptom`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchTemperatureAmountsChart = (
  payload: TemperatureAmountsPayload
) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/temperature_amounts`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchProgressChart = (payload: ProgressPayload) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/progress`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchFaceScaleChart = (payload: FaceScalePayload) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/facescales`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchHealthCheckProgressChart = (
  payload: HealthCheckProgressPayload
) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/health_check_progress`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchAttendanceStatusChart = (
  payload: AttendanceStatusPayload
) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/dash_boards/attendance_status`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchDeseaseOutbreakChart = (payload: DeseaseOutbreakPayload) => {
  const url = "/web_api_v1/school/body_temperatures/get_onset_of_desease_chart";
  return createApiCall2(MethodType.GET, url, payload);
};

export const fetchPrefecture = (payload: GetPrefecturePayload) => {
  const url = "/web_api_v1/school/body_temperatures/get_prefectures";
  return createApiCall2(MethodType.GET, url, payload);
};

export const fetchCities = (payload: GetCitiesPayload) => {
  const url = "/web_api_v1/school/body_temperatures/get_citys";
  return createApiCall2(MethodType.GET, url, payload);
};
