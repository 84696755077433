import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  ReservationData,
  ReservationsResponse,
} from "models/vaccine/reservations";
import { httpStatus } from "configs/httpStatus";
import { fetchReservations } from "services/vaccine/index";
import { handleSuccesAction, handleErrorAction } from "./reservationsActions";
import { history } from "../../configureStore";

function* reservationsSaga({ payload }: { payload: ReservationData }) {
  try {
    const response: ReservationsResponse = yield call(
      fetchReservations,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({
          ...response,
          result: { schedule_section_json: { this_month: [], next_month: [] } },
        })
      );
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
      history.push("/login");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onReservationsWatcher() {
  yield takeLatest(ActionType.RESERVATIONS as any, reservationsSaga);
}

export default [fork(onReservationsWatcher)];
