import { RootState } from "../../rootReducer";

export const selectIsLoading = (state: RootState) =>
  state.remindPushNotification.loading;
export const selectIsSentReminder = (state: RootState) =>
  state.remindPushNotification.saved;
export const remindPushNotificationErrorMessage = (state: RootState) =>
  state.remindPushNotification.error;
export const remindPushNotificationResponseData = (state: RootState) =>
  state.remindPushNotification.remindPushNotificationResponse;
