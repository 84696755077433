import {
  ActionType,
  IClearTempRegisterData,
} from "models/classchangemanagement/clearTempRegisterClassChange";
import { IResponse } from "models";

export const clearTempRegisterAction = (payload: IClearTempRegisterData) => {
  return {
    type: ActionType.CLEAR_TEMP_REGISTERS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CLEAR_TEMP_REGISTERS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.CLEAR_TEMP_REGISTERS_SUCCESS,
    payload,
  };
};
