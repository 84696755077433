import {
  Action,
  ActionType,
  ISaveConsultationSettingsResponse,
} from "models/consultationRequest/saveConsultationSettings";
import createReducer from "redux/createReducer";

export interface SaveConsultationSettingsReducerType {
  loading: boolean;
  error?: string;
  saveConsultationSettingsResponse?: ISaveConsultationSettingsResponse;
}

const defaultState: SaveConsultationSettingsReducerType = {
  loading: false,
  error: undefined,
  saveConsultationSettingsResponse: undefined,
};

export const saveConsultationSettingsReducer =
  createReducer<SaveConsultationSettingsReducerType>(defaultState, {
    [ActionType.SAVE_CONSULTATION_SETTINGS](
      state: SaveConsultationSettingsReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.SAVE_CONSULTATION_SETTINGS_ERROR](
      state: SaveConsultationSettingsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        saveConsultationSettingsResponse: undefined,
      };
    },

    [ActionType.SAVE_CONSULTATION_SETTINGS_SUCCESS](
      state: SaveConsultationSettingsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        saveConsultationSettingsResponse: action.payload,
      };
    },

    [ActionType.SAVE_CONSULTATION_SETTINGS_RESET](
      state: SaveConsultationSettingsReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        saveConsultationSettingsResponse: undefined,
      };
    },
  });
