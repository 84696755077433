import {
  ActionType,
  UpdateTemperatureRequest,
} from "models/setting/updateTemperature";

export const updateTemperatureAction = (payload: UpdateTemperatureRequest) => {
  return {
    type: ActionType.UPDATE_TEMPERATURE_SETTING,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_TEMPERATURE_SETTING_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: any) => {
  return {
    type: ActionType.UPDATE_TEMPERATURE_SETTING_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.RESET_TEMPERATURE_SETTING,
  };
};
