import {
  Action,
  ActionType,
  ISendNotificationConsultationResponse,
} from "models/consultationRequest/sendNotification";
import createReducer from "redux/createReducer";

export interface SendNotificationConsultationReducerType {
  loading: boolean;
  error?: string;
  sendNotificationConsultationResponse?: ISendNotificationConsultationResponse;
}

const defaultState: SendNotificationConsultationReducerType = {
  loading: false,
  error: undefined,
  sendNotificationConsultationResponse: undefined,
};

export const sendNotificationConsultationReducer =
  createReducer<SendNotificationConsultationReducerType>(defaultState, {
    [ActionType.SEND_NOTIFICATION_CONSULTATION](
      state: SendNotificationConsultationReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.SEND_NOTIFICATION_CONSULTATION_ERROR](
      state: SendNotificationConsultationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        sendNotificationConsultationResponse: undefined,
      };
    },

    [ActionType.SEND_NOTIFICATION_CONSULTATION_SUCCESS](
      state: SendNotificationConsultationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        sendNotificationConsultationResponse: action.payload,
      };
    },

    [ActionType.SEND_NOTIFICATION_CONSULTATION_RESET](
      state: SendNotificationConsultationReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
  });
