import * as React from "react";

function HeartSpeakIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3503 14.5401C12.9203 14.1201 12.3503 13.8601 11.7503 13.8101C11.1403 13.7601 10.5403 13.9301 10.0503 14.2801C9.54028 13.9001 8.89028 13.7301 8.26028 13.8001C7.62028 13.8701 7.04028 14.1801 6.62028 14.6501C6.20028 15.1401 5.98028 15.7601 6.00028 16.4001C6.03028 17.0301 6.29028 17.6301 6.75028 18.0801L9.26028 20.5601C9.47028 20.7601 9.75028 20.8801 10.0503 20.8801C10.3503 20.8801 10.6303 20.7701 10.8403 20.5601L13.3503 18.0801C13.8203 17.6101 14.0903 16.9801 14.0903 16.3201C14.0903 15.6601 13.8203 15.0201 13.3503 14.5601V14.5401Z"
        fill={props.fill}
      />
      <path
        d="M16.2203 21.07C15.9503 21.07 15.6803 20.97 15.4703 20.76C15.0503 20.34 15.0503 19.67 15.4703 19.26C16.1803 18.55 16.5703 17.6 16.5703 16.59C16.5703 15.58 16.1803 14.64 15.4703 13.93C15.0503 13.51 15.0503 12.84 15.4703 12.43C15.8903 12.01 16.5603 12.01 16.9703 12.43C18.0803 13.54 18.7003 15.02 18.7003 16.6C18.7003 18.18 18.0903 19.66 16.9703 20.77C16.7603 20.98 16.4903 21.08 16.2203 21.08V21.07Z"
        fill={props.fill}
      />
      <path
        d="M18.9996 23.0101C18.7296 23.0101 18.4596 22.9101 18.2496 22.7001C17.8296 22.2801 17.8296 21.6101 18.2496 21.2001C20.7896 18.6601 20.7896 14.5401 18.2496 12.0001C17.8296 11.5801 17.8296 10.9101 18.2496 10.5001C18.6696 10.0801 19.3396 10.0801 19.7496 10.5001C23.1196 13.8701 23.1196 19.3401 19.7496 22.7101C19.5396 22.9201 19.2696 23.0201 18.9996 23.0201V23.0101Z"
        fill={props.fill}
      />
      <path
        d="M21.8101 25.1799C21.5401 25.1799 21.2701 25.0799 21.0601 24.8699C20.6401 24.4499 20.6401 23.7799 21.0601 23.3699C22.8701 21.5599 23.8701 19.1499 23.8701 16.5899C23.8701 14.0299 22.8701 11.6299 21.0601 9.81988C20.6401 9.39988 20.6401 8.72988 21.0601 8.31988C21.4801 7.89988 22.1501 7.89988 22.5601 8.31988C24.7701 10.5299 25.9901 13.4699 25.9901 16.5999C25.9901 19.7299 24.7701 22.6699 22.5601 24.8799C22.3501 25.0899 22.0801 25.1899 21.8101 25.1899V25.1799Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default HeartSpeakIcon;
