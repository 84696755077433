import {
  IAnswerInfoPayload,
  ActionType,
  AnswerInfoResponse,
} from "models/questionnaires/answerInfo";

export const getAnswerInfoAction = (payload: IAnswerInfoPayload) => {
  return {
    type: ActionType.GET_ANSWER_INFO,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_ANSWER_INFO_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: AnswerInfoResponse) => {
  return {
    type: ActionType.GET_ANSWER_INFO_SUCCESS,
    payload,
  };
};
