import { Action, ActionType } from "models/mentalHealth/sendInterviewRequest";
import { IResponse } from "models";

import createReducer from "redux/createReducer";

export interface SendInterviewRequestReducerType {
  loading: boolean;
  error?: any;
  saved: boolean;
  sendInterviewRequestResponse?: IResponse;
}

const defaultState: SendInterviewRequestReducerType = {
  loading: false,
  error: null,
  saved: false,
  sendInterviewRequestResponse: undefined,
};

export const sendInterviewRequestReducer =
  createReducer<SendInterviewRequestReducerType>(defaultState, {
    [ActionType.SEND_INTERVIEW_REQUEST](
      state: SendInterviewRequestReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.SEND_INTERVIEW_REQUEST_ERROR](
      state: SendInterviewRequestReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      };
    },

    [ActionType.SEND_INTERVIEW_REQUEST_SUCCESS](
      state: SendInterviewRequestReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: true,
        sendInterviewRequestResponse: action.payload,
      };
    },

    [ActionType.RESET_SEND_INTERVIEW_REQUEST](
      state: SendInterviewRequestReducerType
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        sendInterviewRequestResponse: undefined,
      };
    },
  });
