import { Action, ActionType } from "models/classmanagement/createClass";
import { IResponse } from "models";
import createReducer from "../../createReducer";

export interface CreateClassReducerType {
  loading: boolean;
  error?: string;
  saved: boolean;
  createClassResponse?: IResponse;
}

const defaultState: CreateClassReducerType = {
  loading: false,
  error: undefined,
  saved: false,
  createClassResponse: undefined,
};

export const createClassReducer = createReducer<CreateClassReducerType>(
  defaultState,
  {
    [ActionType.CREATE_CLASS](state: CreateClassReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_CLASS_ERROR](
      state: CreateClassReducerType,
      action: Action<any>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      };
    },

    [ActionType.CREATE_CLASS_SUCCESS](
      state: CreateClassReducerType,
      action: Action<any>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        saved: true,
        createClassResponse: action.payload,
      };
    },

    [ActionType.CREATE_CLASS_RESET](
      state: CreateClassReducerType,
      action: Action<any>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        saved: false,
        createClassResponse: action.payload,
      };
    },
  }
);
