import React from "react";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { useTranslation } from "react-i18next";

import { WrapperChartText, ContainerChartText } from "../Vaccination.style";

const PieChartCustom = ({ data, colors, dataCenter }: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "vaccination",
  });

  const attempt: any = {
    1: t("first-time"),
    2: t("second-time"),
    3: t("third-time"),
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        transform: "rotateY(180deg)",
      }}
    >
      <div style={{ width: 240, height: 240, transform: "rotate(-90deg)" }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              fill="#8884d8"
              innerRadius={80}
              outerRadius={100}
              dataKey="value"
            >
              {data.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <WrapperChartText>
        <ContainerChartText>
          <div>{attempt[dataCenter.attempt]}</div>
          <div>
            {dataCenter.percentage}
            <span>%</span>
          </div>
        </ContainerChartText>
      </WrapperChartText>
    </div>
  );
};

export default PieChartCustom;
