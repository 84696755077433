import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/messagemanagement/messageHistoryDetail";
import { httpStatus } from "configs/httpStatus";
import { fetchHistoryDetail } from "services/messagemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./messageHistoryDetailActions";
import {
  IMessageHistoryDetailData,
  IMessageHistoryDetailResponse,
} from "models/messagemanagement/messageHistoryDetail";

function* messageHistoryDetailSaga({
  payload,
}: {
  payload: IMessageHistoryDetailData;
}) {
  try {
    const response: IMessageHistoryDetailResponse = yield call(
      fetchHistoryDetail,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({
          ...response,
          result: {
            content: "",
            user_information: [],
            message_documents: [],
            start_time: "",
          },
        })
      );
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onMessageHistoryDetailWatcher() {
  yield takeLatest(
    ActionType.MESSAGE_HISTORY_DETAIL as any,
    messageHistoryDetailSaga
  );
}

export default [fork(onMessageHistoryDetailWatcher)];
