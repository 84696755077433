import { IResponse } from "models";

export enum ActionType {
  // message history detail
  MESSAGE_HISTORY_DETAIL = "action/MESSAGE_HISTORY_DETAIL",
  MESSAGE_HISTORY_DETAIL_SUCCESS = "action/MESSAGE_HISTORY_DETAIL_SUCCESS",
  MESSAGE_HISTORY_DETAIL_ERROR = "action/MESSAGE_HISTORY_DETAIL_ERROR",
  RESET_MESSAGE_HISTORY_DETAIL = "action/RESET_MESSAGE_HISTORY_DETAIL",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IMessageHistoryDetailData {
  id: string | string[];
  locale?: string;
}

export interface IMessageHistoryDetailResponse extends IResponse {
  result: IMessageHistoryDetail;
}

export interface IMessageHistoryDetail {
  content: string;
  user_information: IUserInformation[];
  message_documents: IMessageDocuments[];
  start_time: string;
}

export interface IUserInformation {
  id: number;
  full_name: string;
}

export interface IMessageDocuments {
  file_path: string;
  file_name: string;
}
