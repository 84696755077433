import {
  IIndividualAnswerPayload,
  ActionType,
  IIndividualAnswerResponse,
} from "models/questionnaires/individualAnswer";

export const getIndividualAnswerAction = (
  payload: IIndividualAnswerPayload
) => {
  return {
    type: ActionType.GET_INDIVIDUAL_ANSWER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_INDIVIDUAL_ANSWER_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: IIndividualAnswerResponse) => {
  return {
    type: ActionType.GET_INDIVIDUAL_ANSWER_SUCCESS,
    payload,
  };
};
