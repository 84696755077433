import React from "react";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";

import { Button } from "components";
import { ModalStyled, WrapperBtnFooter } from "./ActivityManagement.style";

const { useBreakpoint } = Grid;

interface IDeleteModal {
  handleCancel: () => void;
  handleDelete: () => void;
  isChildren: boolean;
  isVisibleDeleteModal: boolean;
}

const DeleteModal: React.FC<IDeleteModal> = ({
  handleCancel,
  handleDelete,
  isVisibleDeleteModal,
  isChildren,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const screens = useBreakpoint();

  return (
    <ModalStyled
      type="confirm"
      footer={
        <WrapperBtnFooter>
          <Button
            onClick={handleCancel}
            background="#E0E0E0"
            color="#fff"
            name={t1("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />
          <Button
            onClick={handleDelete}
            background="#2AC769"
            color="#fff"
            name="OK"
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
          />
        </WrapperBtnFooter>
      }
      visible={isVisibleDeleteModal}
    >
      <div style={screens.xs ? { textAlign: "center" } : {}}>
        {isChildren ? (
          <>{t("class-management-children-delete-message")}</>
        ) : (
          <>{t("class-management-item-delete-message")}</>
        )}
      </div>
    </ModalStyled>
  );
};

export default DeleteModal;
