import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  UpdatePositionColumnResponse,
  UpdatePositionColumnPayload,
} from "models/updatePositionColumn";
import { httpStatus } from "configs/httpStatus";
import { updatePositionOfColumn } from "services/userList";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./updatePositionColumnActions";

function* updatePositionColumnSaga({
  payload,
}: {
  payload: UpdatePositionColumnPayload;
}) {
  try {
    const response: UpdatePositionColumnResponse = yield call(
      updatePositionOfColumn,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdatePositionColumnWatcher() {
  yield takeLatest(
    ActionType.UPDATE_POSITION_COLUMNS as any,
    updatePositionColumnSaga
  );
}

export default [fork(onUpdatePositionColumnWatcher)];
