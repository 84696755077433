import {
  ActionType,
  FilterStudentData,
  GetStudentListResponse,
} from "models/messagemanagement/getStudentList";

export const getStudentListAction = (payload: FilterStudentData) => {
  return {
    type: ActionType.GET_STUDENT_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_STUDENT_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: GetStudentListResponse) => {
  return {
    type: ActionType.GET_STUDENT_LIST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.RESET_STUDENT_LIST,
  };
};
