import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "antd";
import { Button, Box, Loading } from "components";
import {
  DownloadIcon,
  ListViewIcon,
  GalleryViewIcon,
  MiniPDFIcon,
  MiniWordIcon,
  MiniExcelIcon,
} from "icons";
import {
  ChangeViewIcons,
  FlexBox,
  Wrapper,
  WrapperProgressBar,
} from "./ResourcesDownload.style";
import GalleryView from "./GalleryView";
import ListView from "./ListView";
import axios from "axios";

const { useBreakpoint } = Grid;

export type FileType =
  | "application/pdf"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  | "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export type ResourceType = {
  id: string;
  mimeType: FileType;
  modifiedTime: string;
  name: string;
  size: number;
  key: number;
  downloadLink: string;
  thumbnailLink: string;
};

export const fileTypeIcon = (fileType: FileType) => {
  switch (fileType) {
    case "application/pdf":
      return <MiniPDFIcon style={{ cursor: "pointer" }} />;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return (
        <MiniExcelIcon
          style={{ cursor: "pointer" }}
          width="24px"
          height="24px"
        />
      );
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return (
        <MiniWordIcon
          style={{ cursor: "pointer" }}
          width="24px"
          height="24px"
        />
      );
  }
};

export const downloadFunc = (downloadLink: string) => {
  document.location.href = downloadLink;
};

const ResourcesDownload: React.FC = () => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "resources-download",
  });
  const screens = useBreakpoint();
  const [isListView, setIsListView] = useState(true);
  const [selectedResources, setSelectedResources] = useState<ResourceType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [resources, setResources] = useState<ResourceType[]>([]);
  const fetchFileList = async () => {
    setIsLoading(true);
    let fileList: ResourceType[] = [];
    await axios
      .get(
        "https://tvs49tjsql.execute-api.ap-northeast-1.amazonaws.com/default/google-drive-api-node"
      )
      .then((res) => {
        if (res.data) {
          fileList = res.data as ResourceType[];
        }
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
    if (fileList) {
      setResources(fileList);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFileList();
  }, []);

  const disabled: boolean = useMemo(() => {
    if (isLoading) {
      return true;
    }
    if (selectedResources.length == 0) {
      return true;
    }
    return false;
  }, [isLoading, selectedResources]);

  const onChangeView = () => {
    setIsListView((prev) => !prev);
  };

  const onChangeSelected = (
    _: React.Key[],
    selectedResources: ResourceType[]
  ) => {
    setSelectedResources(selectedResources);
  };

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const onSubmit = async () => {
    selectedResources.length > 1
      ? setIsDownloading(true)
      : setIsDownloading(false);
    for (const resource of selectedResources) {
      if (resource.downloadLink !== undefined) {
        downloadFunc(resource.downloadLink);
        selectedResources.length <= 1 ? await sleep(0) : await sleep(3500);
      }
    }
    setSelectedResources([]);
    setIsDownloading(false);
  };

  return (
    <Box title={t("resource-download")} padding={screens.xl ? "32px" : "16px"}>
      <Wrapper style={{ paddingLeft: screens.lg ? 8 : screens.xl ? 32 : 0 }}>
        <FlexBox>
          <Button
            onClick={onSubmit}
            background="#2AC769"
            color="#fff"
            padding="15px 15px"
            name={
              <span style={{ marginLeft: 20 }}>
                {resources.length === selectedResources.length
                  ? t("download-all-resource")
                  : t("download-resource")}
              </span>
            }
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            disabled={disabled}
            icon={
              <>
                <DownloadIcon
                  width="20px"
                  height="20px"
                  fill="currentColor"
                  style={{
                    position: "absolute",
                    left: 12,
                    top: 13,
                    marginRight: 10,
                  }}
                />
              </>
            }
          />
          <ChangeViewIcons>
            <ListViewIcon
              color={isListView ? "#2F8CAE" : "#E0E0E0"}
              onClick={isListView ? undefined : onChangeView}
              style={{
                marginRight: 5,
                cursor: "pointer",
              }}
            />
            <GalleryViewIcon
              color={!isListView ? "#2F8CAE" : "#E0E0E0"}
              onClick={!isListView ? undefined : onChangeView}
              style={{ marginLeft: 5, cursor: "pointer" }}
            />
          </ChangeViewIcons>
        </FlexBox>
        {isListView ? (
          <ListView
            resources={resources}
            selectedResources={selectedResources}
            onChangeSelected={onChangeSelected}
            isLoading={isLoading}
          />
        ) : (
          <GalleryView
            resources={resources}
            selectedResources={selectedResources}
            onChangeSelected={onChangeSelected}
          />
        )}
        {isDownloading && (
          <WrapperProgressBar>
            <Loading />
          </WrapperProgressBar>
        )}
      </Wrapper>
    </Box>
  );
};

export default ResourcesDownload;
