import { createApiCall2, MethodType, URI } from "../Api";
import { IExchangeListPayload } from "models/consultationRequest/exchangeList";
import { IConsultationListPayload } from "models/consultationRequest/consultationList";
import { ICompleteRequestPayload } from "models/consultationRequest/completeRequest";
import { IAddRequestPayload } from "models/consultationRequest/addRequest";
import { ISaveConsultationSettingsPayload } from "models/consultationRequest/saveConsultationSettings";

export const fetchExchangeList = (payload: IExchangeListPayload) => {
  return createApiCall2(
    MethodType.GET,
    URI.CONSULTATION_REQUEST.EXCHANGE_LIST,
    payload
  );
};

export const fetchConsultationList = (payload: IConsultationListPayload) => {
  return createApiCall2(
    MethodType.GET,
    URI.CONSULTATION_REQUEST.CONSULTATION_LIST,
    payload
  );
};

export const postCompleteRequest = (payload: ICompleteRequestPayload) => {
  return createApiCall2(
    MethodType.POST,
    URI.CONSULTATION_REQUEST.COMPLETE_REQUEST,
    payload
  );
};

export const handleAddRequest = (payload: IAddRequestPayload) => {
  return createApiCall2(
    MethodType.POST,
    URI.CONSULTATION_REQUEST.ADD_REQUEST,
    payload
  );
};

export const fetchConsultationSettings = () => {
  return createApiCall2(MethodType.GET, URI.CONSULTATION_REQUEST.GET_SETTINGS);
};

export const updateInsertSettings = (
  payload: ISaveConsultationSettingsPayload
) => {
  return createApiCall2(
    MethodType.POST,
    URI.CONSULTATION_REQUEST.UPDATE_INSERT_SETTINGS,
    payload
  );
};

export const sendNotification = (payload: any) => {
  return createApiCall2(
    MethodType.POST,
    URI.CONSULTATION_REQUEST.SEND_NOTIFICATION,
    payload
  );
};
