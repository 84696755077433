import { IResponse } from "models";

export enum ActionType {
  SLOTS = "action/SLOTS",
  SLOTS_SUCCESS = "action/SLOTS_SUCCESS",
  SLOTS_ERROR = "action/SLOTS_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface GetSlotsData {
  locale?: string;
}

export interface SlotsDataResponse extends IResponse {
  result: {
    hours_array: string[];
    minutes_array: string[];
    venue_options: any;
  };
}
