import {
  ActionType,
  ICreateBulkClassData,
} from "../../../models/classmanagement/createBulkClasses";
import { IResponse } from "models";

export const createBulkClassAction = (payload: ICreateBulkClassData) => {
  return {
    type: ActionType.CREATE_BULK_CLASSES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_BULK_CLASSES_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.CREATE_BULK_CLASSES_SUCCESS,
    payload,
  };
};
