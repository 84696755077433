import React, { useRef, useEffect, useState } from "react";
import { Col } from "antd";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { TemperatureAvgActionCreators } from "redux/rootActions";
import {
  temperatureAvgDataResponse,
  selectIsLoading,
} from "redux/dashboard/temperatureAvg/temperatureAvgStates";

import { CloseIcon, DownloadIcon, NoDataIcon } from "icons";
import { generateImg } from "utils";

import {
  BoxItem,
  WrapperCloseIcon,
  BoxHeader,
  BoxTitle,
  LightDivider,
  BoxContent,
  WrapperChart,
  ContainerChart,
  WrapperLoadingChart,
  WrapperNoData,
} from "../Dashboard.style";
import { IChart } from "../BodyTemperatureResult";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "components";

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={0} dy={18} fill="#3B3B3B">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={3} fill="#3B3B3B" textAnchor="end">
        {payload.value}°C
      </text>
    </g>
  );
};

const LineChartCustom = ({ data }: any) => {
  return (
    <ContainerChart
      style={{
        marginTop: 37,
      }}
    >
      <ResponsiveContainer width="100%" height={178}>
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 10,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="2 2" />
          <XAxis
            dataKey="date"
            tickLine={false}
            dy={8}
            tick={<CustomizedXAxisTick />}
            stroke="#CCCCCC"
          />
          <YAxis
            type="number"
            interval={0}
            domain={[35, 40]}
            tickLine={false}
            tickCount={6}
            width={35}
            tick={<CustomizedYAxisTick />}
            stroke="#CCCCCC"
          />
          <Line
            connectNulls
            dataKey="value"
            strokeDasharray="3 3"
            stroke="#2F8CAE"
            fill="#2F8CAE"
            isAnimationActive={false}
            dot={{
              stroke: "#2F8CAE",
              strokeWidth: 1,
              r: 4,
              fill: "#2F8CAE",
              strokeDasharray: "",
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </ContainerChart>
  );
};

const AverageBodyTemperature: React.FC<IChart> = ({ handleCloseChart }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });

  const dispatch = useDispatch();
  const temperatureAvgData = useSelector(temperatureAvgDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const imgRef = useRef<any>();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(
      TemperatureAvgActionCreators.getTemperatureAvgAction({
        aggregate_date_to: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
  }, []);

  const handleConvertDate = (date: string) => {
    return moment(new Date(date)).format("M/D");
  };

  useEffect(() => {
    if (temperatureAvgData && Object.keys(temperatureAvgData).length) {
      setData(
        temperatureAvgData.averages.map((item: any) => {
          return {
            date: handleConvertDate(item.aggregate_date),
            value: item.average,
          };
        })
      );
    }
  }, [temperatureAvgData]);

  return (
    // <Col xs={24} md={24} xl={12} >
    <BoxItem ref={imgRef}>
      <WrapperCloseIcon>
        <CloseIcon
          fill="#2F8CAE"
          height="14px"
          width="14px"
          onClick={() => {
            handleCloseChart("6");
          }}
        />
      </WrapperCloseIcon>
      <BoxHeader>
        <BoxTitle>{t("average-body-temperature-7-days")}</BoxTitle>
        <DownloadIcon
          fill="#3B3B3B"
          style={{ marginTop: 4, cursor: "pointer" }}
          onClick={(event: any) => {
            if (!event.detail || event.detail == 1) {
              generateImg("average-body-temperature", imgRef.current);
            }
          }}
        />
      </BoxHeader>
      <LightDivider />
      {isLoading ? (
        <WrapperLoadingChart>
          <div className="container">
            <Loading width={70} height={70} />
          </div>
        </WrapperLoadingChart>
      ) : data.length > 0 ? (
        <BoxContent>
          <WrapperChart>
            <LineChartCustom data={data} />
          </WrapperChart>
        </BoxContent>
      ) : (
        <WrapperNoData>
          <NoDataIcon width={140} height={140} />
        </WrapperNoData>
      )}
    </BoxItem>
    // </Col>
  );
};

export default AverageBodyTemperature;
