import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { localStorageHelper, generateDynamicRecords } from "utils";
import { Button, Box, Pagination, Select, ScrollToTop } from "components";
import {
  PlusIcon,
  ArrowNextIcon,
  ElementaryIcon,
  ElementaryEngIcon,
  JuniorHighIcon,
  JuniorHighEngIcon,
} from "icons";
import { IOption } from "components/select/Select";

import { CheckListData } from "models/mentalHealth/checkList";
import {
  MentalCheckListActionCreators,
  NewMentalCheckActionCreators,
  UpdateMentalCheckActionCreators,
} from "redux/rootActions";
import {
  selectIsLoading,
  mentalCheckListDataResponse,
} from "redux/mentalHealth/checkList/checkListStates";

import {
  PaginationWrapper,
  ContentWrapper,
  TableStyled,
  Dot,
  StatusWrapper,
  TextTitle,
  TitleContainer,
  WrapperSizeTypeChange,
} from "./MentalHealth.style";
import NewMentalHealthCheck from "./NewMentalHealthCheck";
import MentalHealthDetails from "./MentalHealthDetails";
import EditDraft from "./EditDraft";

export type ScreenStatus = "create" | "details" | "list" | "draft" | "";

const { useBreakpoint } = Grid;

const MentalHealthManagement: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "mental-health",
  });
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const mentalCheckListData = useSelector(mentalCheckListDataResponse);

  const [screenStatus, setScreenStatus] = useState<ScreenStatus>("list");
  const [testId, setTestId] = useState();
  const [input, setInput] = useState({
    page: 1,
    limit: 50,
  });
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);

  const totalRecord = mentalCheckListData?.total_record || 0;

  const handleElementaryIcon = (value: number) => {
    return currentLanguage === "ja" ? (
      <ElementaryIcon height={value} width={value} />
    ) : (
      <ElementaryEngIcon height={value} width={value} />
    );
  };

  const handleJuniorHighIcon = (value: number) => {
    return currentLanguage === "ja" ? (
      <JuniorHighIcon height={value} width={value} />
    ) : (
      <JuniorHighEngIcon height={value} width={value} />
    );
  };

  const getMentalCheckList = () => {
    const paramsMentalCheckList: CheckListData = {
      page: input.page,
      per_page: input.limit,
    };
    dispatch(
      MentalCheckListActionCreators.checkListAction(paramsMentalCheckList)
    );
  };

  useEffect(() => {
    if (screenStatus === "list") {
      getMentalCheckList();
    }
  }, [input.page, input.limit, screenStatus]);

  useEffect(() => {
    if (mentalCheckListData && Object.keys(mentalCheckListData).length) {
      let recordOptionsClone: IOption[] = [];
      recordOptionsClone = generateDynamicRecords(
        mentalCheckListData?.total_record,
        t
      );
      setRecordOptions([...recordOptionsClone]);
    }
  }, [mentalCheckListData]);

  useEffect(() => {
    dispatch(MentalCheckListActionCreators.handleResetDataAction());
    dispatch(NewMentalCheckActionCreators.handleResetDataAction());
    dispatch(UpdateMentalCheckActionCreators.handleResetDataAction());
    setTestId(undefined);
  }, []);

  const returnColor = (status: string) => {
    if (status) {
      switch (status) {
        case "実施中":
          return "#2AC769";
          break;
        case "実施待ち":
          return "#EBC300";
          break;
        case "実施完了":
        case "下書き":
          return "#B7B7B7";
          break;
        default:
          return "#2AC769";
      }
    }
  };

  const returnMentalCheckType = (type: number) => {
    if (type === 1 || type === 2) {
      return handleElementaryIcon(26);
    }

    return handleJuniorHighIcon(26);
  };

  const columns = [
    {
      title: t1("inspection-type"),
      dataIndex: "mental_check_type",
      key: "mental_check_type",
      width: 100,
      render: (text: any, record: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "10px",
          }}
        >
          {returnMentalCheckType(record?.mental_check_type_id)}
        </div>
      ),
    },
    {
      title: t1("test-name"),
      dataIndex: "test_name",
      key: "test_name",
      width: 500,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t1("grade"),
      dataIndex: "grade",
      key: "grade",
      width: 150,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t1("examination-period"),
      dataIndex: "examination_period",
      key: "examination_period",
      width: 300,
    },
    {
      title: t1("implementation-status"),
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <StatusWrapper>
            <Dot style={{ background: returnColor(record?.status) }} />
            {text}
          </StatusWrapper>
          <ArrowNextIcon />
        </div>
      ),
    },
  ];

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  const handleOnChangeSizeChange = (limit: number) => {
    setInput((prevState: any) => ({ ...prevState, limit, page: 1 }));
  };

  const handleCancel = () => {
    setScreenStatus("list");
    setInput((prevState: any) => ({ ...prevState, page: 1 }));
  };

  const ShowPagination = () => {
    return totalRecord > input.limit ? (
      <PaginationWrapper>
        <Pagination
          current={input.page}
          onChange={handleChangePage}
          pageSize={input.limit}
          total={totalRecord}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </PaginationWrapper>
    ) : (
      <Fragment></Fragment>
    );
  };

  const renderContents = () => {
    switch (screenStatus) {
      case "list":
        return (
          <>
            <ScrollToTop />
            <Box
              title={t1("mental-health-observation-management")}
              padding="16px 16px 32px"
            >
              <TitleContainer
                style={{
                  marginBottom: 32,
                }}
              >
                <TextTitle>{t1("mental-health-observation-list")}</TextTitle>
                <Button
                  color="#FFFFFF"
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="16px"
                  background={"#2AC769"}
                  padding="10px 24px 11px 11px"
                  name={t1("create")}
                  bdr="6px"
                  border="none"
                  icon={
                    <PlusIcon
                      width="16px"
                      height="16px"
                      fill="#FFFFFF"
                      transform="translate(0 1)"
                      style={{ marginRight: 2 }}
                    />
                  }
                  onClick={() => {
                    setScreenStatus("create");
                  }}
                />
              </TitleContainer>
              <TitleContainer
                style={{
                  alignItems: "flex-start",
                }}
              >
                <WrapperSizeTypeChange>
                  <div className="left">
                    <div style={{ fontWeight: 500 }}>{t("record")}</div>
                    <Select
                      defaultValue={50}
                      options={recordOptions}
                      onChange={handleOnChangeSizeChange}
                    />
                  </div>
                  <div className="right">
                    <div className="item">
                      {handleElementaryIcon(20)}
                      <div>{t1("for-elementary-student")}</div>
                    </div>
                    <div className="item">
                      {handleJuniorHighIcon(20)}
                      <div>{t1("for-middle-high-student")}</div>
                    </div>
                  </div>
                </WrapperSizeTypeChange>
                <ShowPagination />
              </TitleContainer>
              <ContentWrapper>
                <TableStyled
                  dataSource={mentalCheckListData?.result}
                  columns={columns}
                  scroll={{ x: "max-content" }}
                  loading={isLoading}
                  onRow={(record: any) => {
                    return {
                      onClick: () => {
                        if (record?.status === "下書き") {
                          setScreenStatus("draft");
                        } else {
                          setScreenStatus("details");
                        }
                        setTestId(record?.id);
                      },
                    };
                  }}
                />
                {!mentalCheckListData?.result?.length && (
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: 18,
                      lineHeight: "26px",
                      marginTop: 32,
                    }}
                  >
                    <div>{t1("no-observation-registered")}</div>
                    <div>{t1("press-new-to-create")}</div>
                  </div>
                )}
              </ContentWrapper>
              <ShowPagination />
            </Box>
          </>
        );
      case "create":
        return <NewMentalHealthCheck handleCancel={handleCancel} />;
      case "draft":
        return <EditDraft handleCancel={handleCancel} id={testId} />;
      case "details":
        return <MentalHealthDetails handleCancel={handleCancel} id={testId} />;
      default:
        return;
    }
  };

  return <>{renderContents()}</>;
};

export default MentalHealthManagement;
