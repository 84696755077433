import { IResponse } from "models";
import { Action, ActionType } from "models/classchangemanagement/saveTemporary";
import createReducer from "../../createReducer";

export interface SaveTemporaryStudentClassChangeReducerType {
  loading: boolean;
  saved: boolean;
  error?: string;
  saveTemporaryStudentClassChangeResponse?: IResponse;
}

const defaultState: SaveTemporaryStudentClassChangeReducerType = {
  loading: false,
  saved: false,
  error: undefined,
  saveTemporaryStudentClassChangeResponse: undefined,
};
export const saveTemporaryStudentClassChangeReducer =
  createReducer<SaveTemporaryStudentClassChangeReducerType>(defaultState, {
    [ActionType.SAVE_TEMPORARY_CLASS_CHANGE](
      state: SaveTemporaryStudentClassChangeReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },
    [ActionType.SAVE_TEMPORARY_CLASS_CHANGE_ERROR](
      state: SaveTemporaryStudentClassChangeReducerType,
      action: Action<string>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [ActionType.SAVE_TEMPORARY_CLASS_CHANGE_SUCCESS](
      state: SaveTemporaryStudentClassChangeReducerType,
      action: Action<IResponse>
    ) {
      return {
        ...state,
        loading: false,
        saved: true,
        error: null,
        saveTemporaryStudentClassChangeResponse: action.payload,
      };
    },
    [ActionType.SAVE_TEMPORARY_CLASS_CHANGE_RESET](
      state: SaveTemporaryStudentClassChangeReducerType
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        saveTemporaryStudentClassChangeResponse: {},
      };
    },
  });
