import {
  ActionType,
  Action,
  DoneQuestionResponse,
} from "models/questionnaires/doneQuestion";
import createReducer from "../../createReducer";

export interface DoneQuestionReducerType {
  loading: boolean;
  error?: string;
  doneQuestionResponse?: DoneQuestionResponse;
}

const defaultState: DoneQuestionReducerType = {
  loading: false,
  error: undefined,
  doneQuestionResponse: undefined,
};

export const doneQuestionReducer = createReducer<DoneQuestionReducerType>(
  defaultState,
  {
    [ActionType.DONE_QUESTION](state: DoneQuestionReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DONE_QUESTION_ERROR](
      state: DoneQuestionReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        doneQuestionResponse: undefined,
      };
    },

    [ActionType.DONE_QUESTION_SUCCESS](
      state: DoneQuestionReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        doneQuestionResponse: action.payload,
      };
    },

    [ActionType.DONE_QUESTION_RESET](state: DoneQuestionReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        doneQuestionResponse: undefined,
      };
    },
  }
);
