import React, { useState, useEffect } from "react";
import { Tooltip, notification } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";

import { PASSWORD_REGEX } from "constant";
import { Input, Button, Box } from "components";
import { httpStatus } from "configs/httpStatus";
import { SendCompleteIcon, ConfirmFailIcon } from "icons";
import { EMAIL_REGEX } from "constant";

import {
  AccountRegistrationListActionCreators,
  CreateAccountActionCreators,
} from "redux/rootActions";
import { registrationListDataResponse } from "redux/account/registrationList/registrationListStates";
import {
  createAccountDataResponse,
  selectIsLoading,
} from "redux/account/createAccount/createAccountStates";
import { IActivites, IDepartment } from "models/account/departmentList";

import {
  WrapperPermistion,
  WrapperRegistrationForm,
  WrapperItem,
  ItemContainer,
  WrapperTreeView,
  CheckboxStyled,
  WrapperInputs,
  Label,
  Important,
} from "./Account.style";

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("email-address-required")
    .matches(EMAIL_REGEX, "wrong-format"),
  password: yup
    .string()
    .required("password-validation")
    .matches(PASSWORD_REGEX, "password-requirements"),
  password_confirmation: yup
    .string()
    .required("confirm-password-required")
    .oneOf([yup.ref("password")], "password-not-match"),
});

const AccountRegistration: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "account" });
  const { t: t1 }: any = useTranslation("translation", { keyPrefix: "auth" });
  const { t: t2 }: any = useTranslation("translation", { keyPrefix: "layout" });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const dispatch = useDispatch();
  const registrationListData = useSelector(registrationListDataResponse);
  const createAccountData = useSelector(createAccountDataResponse);

  const [treeData, setTreeData] = useState<IDepartment[]>([]);
  const [treeData2, setTreeData2] = useState<IActivites[]>([]);
  const isLoading = useSelector(selectIsLoading);

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (
      createAccountData &&
      createAccountData.status === httpStatus.StatusInternalServerError
    ) {
      let message: any = t("email-registered");
      if (
        createAccountData?.message &&
        createAccountData?.message?.email &&
        createAccountData?.message?.email[0]
      ) {
        message = createAccountData?.message.email[0];
      } else if (
        createAccountData?.message &&
        createAccountData?.message?.password &&
        createAccountData?.message?.password[0]
      ) {
        message = createAccountData?.message.password[0];
      }
      notification.open({
        message: Moment(new Date()).format("YYYY/MM/DD HH:mm"),
        description: message,
        placement: "bottomRight",
        icon: <ConfirmFailIcon height="53px" width="53px" />,
      });
      dispatch(CreateAccountActionCreators.handleResetAction());
    }
    if (createAccountData && createAccountData.status === httpStatus.StatusOK) {
      notification.open({
        message: Moment(new Date()).format("YYYY/MM/DD HH:mm"),
        description: t("registration-complete"),
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      setValue("display_name", "");
      setValue("email", "");
      setValue("password", "");
      setValue("password_confirmation", "");
      dispatch(CreateAccountActionCreators.handleResetAction());
    }
  }, [createAccountData]);

  useEffect(() => {
    dispatch(AccountRegistrationListActionCreators.getRegistrationListAction());
  }, []);

  useEffect(() => {
    if (
      registrationListData &&
      Object.keys(registrationListData.result).length
    ) {
      const { selectable_departments, selectable_sub_departments } =
        registrationListData.result;
      setTreeData(selectable_departments);
      setTreeData2(selectable_sub_departments);
    }
  }, [registrationListData]);

  const handleCheckedItem = (item: any, itemType: string, e: any) => {
    item.checked = e;
    if (item[itemType].length) {
      item[itemType].map((newItem: any) => {
        handleCheckedItem(newItem, itemType, item.checked);
      });
    }
  };

  const handleGetCheckedItemValue = (
    arr: any[],
    checkedArr: number[],
    itemType: string
  ) => {
    arr.forEach((item) => {
      if (item.checked) {
        checkedArr.push(item.id);
      } else {
        if (item[itemType].length) {
          handleGetCheckedItemValue(item[itemType], checkedArr, itemType);
        }
      }
    });
  };

  const handleRegisterAccount = (data: { [x: string]: any }) => {
    const { email, password, display_name, password_confirmation } = data;
    const checkedArr: number[] = [];
    const checkedArr2: number[] = [];

    handleGetCheckedItemValue(treeData, checkedArr, "sub_departments");
    handleGetCheckedItemValue(treeData2, checkedArr2, "sub_activities");
    dispatch(
      CreateAccountActionCreators.createAccountAction({
        email,
        password,
        display_name,
        password_confirmation,
        company_manager_c_departments: {
          c_department_ids: checkedArr,
        },
        company_manager_company_sub_departments: {
          company_sub_department_ids: checkedArr2,
        },
      })
    );
  };

  const onChangeCheckbox =
    (item: IDepartment, itemType: string) => (e: any) => {
      handleCheckedItem(item, itemType, e.target.checked);
      if (itemType === "sub_departments") {
        setTreeData([...treeData]);
      } else {
        setTreeData2([...treeData2]);
      }
    };

  const CompItem = ({
    item,
    itemType,
    children,
  }: {
    item: any;
    itemType: string;
    children: any;
  }) => {
    const { name, checked } = item;

    const handleCheckParent = (item: IDepartment, checked: boolean) => {
      item.checked = checked;
      if (itemType === "sub_departments") {
        setTreeData([...treeData]);
      } else {
        setTreeData2([...treeData2]);
      }
    };

    useEffect(() => {
      if (item[itemType].length > 0) {
        if (
          !item[itemType].some(
            (d: any) => d.checked === false || d.checked === undefined
          )
        ) {
          if (!item.checked) {
            handleCheckParent(item, true);
          }
        } else {
          if (item.checked) {
            handleCheckParent(item, false);
          }
        }
      }
    }, [item[itemType]]);

    return (
      <>
        <ItemContainer>
          <CheckboxStyled
            checked={checked}
            onChange={onChangeCheckbox(item, itemType)}
          >
            <Tooltip placement="topLeft" title={name}>
              <div style={{ marginLeft: 10 }}>{name}</div>
            </Tooltip>
          </CheckboxStyled>
        </ItemContainer>
        {children}
      </>
    );
  };

  const Item = ({ item, itemType }: { item: any; itemType: string }) => {
    return (
      <CompItem item={item} itemType={itemType}>
        {item[itemType].length > 0 &&
          item[itemType].map((i: any) => (
            <div style={{ marginLeft: 32 }} key={i.id}>
              <CompItem item={i} itemType={itemType}>
                {i[itemType].map((a: any) => (
                  <div style={{ marginLeft: 32 }} key={a.id}>
                    <Item item={a} itemType={itemType} />
                  </div>
                ))}
              </CompItem>
            </div>
          ))}
      </CompItem>
    );
  };

  return (
    <Box title={t2("register-account-label")} padding="24px 16px 16px 16px">
      <React.Fragment>
        <WrapperRegistrationForm
          onSubmit={handleSubmit(handleRegisterAccount)}
          noValidate
        >
          <WrapperInputs>
            <Controller
              control={control}
              name="display_name"
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t("display-name")}
                  value={value}
                  height={31}
                  fsLabel={16}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  label={
                    <div>
                      {t("email-address")}
                      <Important>*</Important>
                    </div>
                  }
                  value={value}
                  type="email"
                  height={31}
                  fsLabel={16}
                  onChange={onChange}
                  error={t(error?.message)}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  label={
                    <div>
                      {t1("password")}
                      <Important>*</Important>
                    </div>
                  }
                  value={value}
                  type="password"
                  height={31}
                  fsLabel={16}
                  onChange={onChange}
                  error={t(error?.message)}
                  autoComplete="new-password"
                />
              )}
            />
            <Controller
              control={control}
              name="password_confirmation"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  label={
                    <div>
                      {t("confirm-password")}
                      <Important>*</Important>
                    </div>
                  }
                  value={value}
                  type="password"
                  height={31}
                  fsLabel={16}
                  onChange={onChange}
                  error={t(error?.message)}
                />
              )}
            />
          </WrapperInputs>
          <Button
            type="submit"
            name={t("registration")}
            background="#2AC769"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            width="128px"
            disabled={isLoading}
          />
        </WrapperRegistrationForm>
        <WrapperPermistion>
          <p>{t("view-and-edit-description")}</p>
          <div style={{ marginBottom: 24 }}>
            <Label>{t3("grade")}</Label>
            <WrapperTreeView>
              {treeData.map((item) => {
                return (
                  <WrapperItem key={item.id}>
                    <Item item={item} itemType="sub_departments" />
                  </WrapperItem>
                );
              })}
            </WrapperTreeView>
          </div>

          <div>
            <Label>{t3("activities")}</Label>
            <WrapperTreeView>
              {treeData2.map((item) => {
                return (
                  <WrapperItem key={item.id}>
                    <Item item={item} itemType="sub_activities" />
                  </WrapperItem>
                );
              })}
            </WrapperTreeView>
          </div>
        </WrapperPermistion>
      </React.Fragment>
    </Box>
  );
};

export default AccountRegistration;
