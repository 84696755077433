import * as React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 17h17v3H4l-4-3Z" fill="#2569AD" />
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.757 0H3a1 1 0 0 0-1 1v19a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V5.243L13.757 0Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.757 0H3a1 1 0 0 0-1 1v19a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V5.243L13.757 0Z"
      fill="#F2F2F2"
    />
    <path
      d="m13.757 0 .707-.707L14.172-1h-.415v1ZM19 5.243h1v-.415l-.293-.292-.707.707ZM3 1h10.757v-2H3v2Zm0 0v-2a2 2 0 0 0-2 2h2Zm0 19V1H1v19h2Zm0 0H1a2 2 0 0 0 2 2v-2Zm15 0H3v2h15v-2Zm0 0v2a2 2 0 0 0 2-2h-2Zm0-14.757V20h2V5.243h-2Zm1.707-.707L14.464-.707 13.05.707l5.243 5.243 1.414-1.414Z"
      fill="#2D80D3"
      mask="url(#a)"
    />
    <path fill="#2D80D3" d="M0 12h17v5H0z" />
    <path
      d="M13.5 1 18 5.5h-3c-1 0-1.5-.5-1.5-1.5V1ZM3.94 15.549l.566-2.393h.316l.073.399L4.29 16h-.34l-.012-.451Zm-.292-2.393.47 2.393-.04.451H3.7l-.63-2.844h.58Zm1.745 2.383.462-2.383h.58L5.808 16h-.38l-.034-.46ZM5 13.156l.57 2.403-.015.441h-.34l-.608-2.447.079-.397H5Zm4.29 1.356v.134c0 .217-.03.41-.088.582a1.28 1.28 0 0 1-.248.44c-.107.12-.234.212-.383.275a1.22 1.22 0 0 1-.49.096c-.179 0-.342-.032-.49-.096a1.099 1.099 0 0 1-.383-.275 1.287 1.287 0 0 1-.252-.44 1.798 1.798 0 0 1-.088-.582v-.134c0-.218.03-.412.088-.582a1.28 1.28 0 0 1 .248-.44c.108-.12.236-.213.383-.277.148-.064.312-.096.49-.096.18 0 .343.032.49.096.149.064.276.156.383.277.108.121.191.268.25.44.06.17.09.364.09.582Zm-.592.134v-.138c0-.151-.014-.284-.041-.399a.864.864 0 0 0-.121-.289.523.523 0 0 0-.459-.236.528.528 0 0 0-.457.236.89.89 0 0 0-.12.29 1.728 1.728 0 0 0-.04.398v.139c0 .15.013.282.04.398a.886.886 0 0 0 .122.29.54.54 0 0 0 .195.179.56.56 0 0 0 .264.06.524.524 0 0 0 .457-.238.914.914 0 0 0 .119-.291c.027-.116.041-.249.041-.399Zm1.214-1.49h1.06c.218 0 .405.033.56.098a.779.779 0 0 1 .364.289.833.833 0 0 1 .127.47.905.905 0 0 1-.078.393.735.735 0 0 1-.217.272 1.125 1.125 0 0 1-.326.172l-.186.097h-.921l-.004-.457h.685a.535.535 0 0 0 .256-.055.358.358 0 0 0 .152-.152.47.47 0 0 0 .053-.226.514.514 0 0 0-.05-.237.342.342 0 0 0-.155-.152.569.569 0 0 0-.26-.053h-.474V16h-.586v-2.844ZM11.502 16l-.649-1.268.62-.003.655 1.244V16h-.626Zm2.045 0h-.619l.004-.457h.615a.695.695 0 0 0 .389-.103.641.641 0 0 0 .238-.307c.055-.134.082-.296.082-.486v-.14c0-.145-.015-.273-.047-.384a.766.766 0 0 0-.134-.28.564.564 0 0 0-.221-.17.73.73 0 0 0-.3-.058h-.638v-.459h.637c.19 0 .364.033.522.098a1.176 1.176 0 0 1 .68.705c.063.165.095.35.095.553v.134c0 .202-.032.387-.096.553a1.187 1.187 0 0 1-.68.705 1.403 1.403 0 0 1-.527.096Zm-.287-2.844V16h-.586v-2.844h.586Z"
      fill="#fff"
    />
    <path
      d="m12.724 4.269-1.558 6.462H9.67L8.531 5.763l-1.139 4.968H5.834L4.277 4.269h1.299l1.059 4.84 1.172-4.84h1.452l1.14 4.695 1.025-4.695h1.3Z"
      fill="#2D80D3"
    />
  </svg>
);

export default SvgComponent;
