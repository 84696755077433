import { combineReducers } from "redux";
import { routerReducer, RouterState } from "react-router-redux";
import {
  authenticationReducer,
  AuthenticationReducerType,
} from "../redux/authentication/authenticationReducer";
import {
  forgotPasswordReducer,
  ForgotPasswordReducerType,
} from "../redux/forgotpassword/forgotpasswordReducer";
import {
  resetPasswordReducer,
  ResetPasswordReducerType,
} from "../redux/resetpassword/resetpasswordReducer";
import {
  userListReducer,
  UserListReducerType,
} from "../redux/userlist/userListReducer";
import {
  classListReducer,
  ClassListReducerType,
} from "../redux/classlist/classListReducer";
import {
  departmentListReducer,
  DepartmentListReducerType,
} from "../redux/department/departmentListReducer";
import {
  subDepartmentListReducer,
  SubDepartmentListReducerType,
} from "../redux/subdepartment/subdepartmentListReducer";
import {
  activitiesListReducer,
  ActivitiesListReducerType,
} from "../redux/activitieslist/activitiesListReducer";
import {
  createUserReducer,
  CreateUserReducerType,
} from "../redux/createuserlist/createuserReducer";
import {
  updateUserReducer,
  UpdateUserReducerType,
} from "../redux/updateuser/updateuserReducer";
import {
  deleteUserReducer,
  DeleteUserReducerType,
} from "../redux/deleteuser/deleteuserReducer";
import {
  csvDownloadUserReducer,
  CSVDownloadUserReducerType,
} from "../redux/csvdownloaduser/csvdownloaduserReducer";
import {
  userAttendanceReducer,
  UserAttendanceReducerType,
} from "../redux/userattendance/userAttendanceReducer";
import {
  classSchoolListReducer,
  ClassSchoolListReducerType,
} from "./classmanagement/getclasses/classesListReducer";
import {
  createClassReducer,
  CreateClassReducerType,
} from "./classmanagement/createclasses/createClassListReducer";
import {
  updateClassReducer,
  UpdateClassReducerType,
} from "./classmanagement/updateclasses/updateClassReducer";
import {
  schoolTypeReducer,
  SchoolTypeReducerType,
} from "./classmanagement/schooltype/schoolTypeReducer";
import {
  classTypeReducer,
  ClassTypeReducerType,
} from "./classmanagement/classtype/classTypeReducer";
import {
  createBulkClassReducer,
  CreateBulkClassReducerType,
} from "./classmanagement/createbulkclasses/createBulkClassListReducer";
import {
  deleteClassReducer,
  DeleteClassReducerType,
} from "./classmanagement/deleteclass/deleteClassReducer";
import {
  activityListSchoolReducer,
  ActivityListSchoolReducerType,
} from "./activitymanagement/getactivities/activitiesListReducer";
import {
  createActivityReducer,
  CreateActivityReducerType,
} from "./activitymanagement/createactivity/createActivityReducer";
import {
  updateActivityReducer,
  UpdateActivityReducerType,
} from "./activitymanagement/updateactivity/updateActivityReducer";
import {
  deleteActivityReducer,
  DeleteActivityReducerType,
} from "./activitymanagement/deleteactivity/deleteActivityReducer";
import {
  activityListFilterReducer,
  ActivityFilterListReducerType,
} from "./messagemanagement/activitiesfilterlist/activitiesFilterListReducer";
import {
  classListFilterReducer,
  GetClassListFilterReducerType,
} from "./messagemanagement/classfilterlist/classFilterListReducer";
import {
  getStudentListReducer,
  GetStudentListReducerType,
} from "./messagemanagement/getstudentlist/getStudentListReducer";
import {
  dataChartsReducer,
  DataChartsReducerType,
} from "./bodyTemperature/charts/chartReducer";
import {
  dataStatisticsReducer,
  DataStatisticsReducerType,
} from "./bodyTemperature/statistics/statisticsReducer";
import {
  dataDestinationReducer,
  DataDestinationReducerType,
} from "./bodyTemperature/destinations/destinationReducer";
import {
  dataGroupClassReducer,
  DataGroupClassReducerType,
} from "./bodyTemperature/groupClass/groupClassReducer";
import {
  dataChildDepartmentReducer,
  DataChildDepartmentReducerType,
} from "./bodyTemperature/childDepartment/childDepartmentReducer";
import {
  dataPatientChildDepartmentReducer,
  DataPatientChildDepartmentReducerType,
} from "./bodyTemperature/patientChildDepartment/patientChildReducer";
import {
  createPatientProfileReducer,
  PatientProfileReducerType,
} from "./bodyTemperature/createPatientProfile/patientProfileReducer";
import {
  dataDetailFormReducer,
  DataDetailFormReducerType,
} from "./bodyTemperature/detailForm/detailFormReducer";
import {
  dataPatientDetailReducer,
  DataPatientDetailReducerType,
} from "./bodyTemperature/patientDetail/patientDetailReducer";
import {
  dataFeverPercentageReducer,
  DataFeverPercentageReducerType,
} from "./bodyTemperature/feverPercentage/feverPercentageReducer";
import {
  getBodyTemperatureSettingFormDataReducer,
  BodyTemperatureSettingFormReducerType,
} from "./bodyTemperature/settingForm/settingFormReducer";
import {
  dataProgressBarReducer,
  DataProgressBarReducerType,
} from "./bodyTemperature/progressBar/progressBarReducer";
import {
  getBodyTemperatureFileDataReducer,
  BodyTemperatureFileReducerType,
} from "./bodyTemperature/file/fileReducer";
import {
  approveAbsenceReducer,
  ApproveAbsenceReducerType,
} from "./bodyTemperature/approveAbsence/approveAbsenceReducer";
import {
  getStudentListClassChangeReducer,
  StudentListClassChangeReducerType,
} from "./classchangemanagement/getStudentData/getStudentListClassChangeReducer";
import {
  getConfirmStudentListReducer,
  ConfirmStudentListReducerType,
} from "./classchangemanagement/getConfirmStudentData/getConfirmStudentListReducer";
import {
  clearTempRegisterReducer,
  ClearTempRegisterReducerType,
} from "./classchangemanagement/clearTempRegisterData/clearTempRegisterReducer";
import {
  putConfirmStudentClassChangeReducer,
  PutConfirmStudentClassChangeReducerType,
} from "./classchangemanagement/putConfirmStudentData/putConfirmStudentClassChangeReducer";
import {
  temperatureListFilterReducer,
  GetTemperatureListFilterReducerType,
} from "./messagemanagement/temperaturefilterlist/temperatureFilterListReducer";
import {
  messageHistoryListReducer,
  MessageHistoryListReducerType,
} from "./messagemanagement/messagehistory/messageHistoryListReducer";
import {
  attendanceFilterListReducer,
  AttendanceFilterListReducerType,
} from "./messagemanagement/attendancefilterlist/attendanceFilterListReducer";
import {
  messageHistoryDetailReducer,
  MessageHistoryDetailReducerType,
} from "./messagemanagement/messagehistorydetail/messageHistoryDetailReducer";
import {
  sendMessageReducer,
  SendMessageReducerType,
} from "./messagemanagement/sendmessage/sendMessageReducer";
import {
  getCSVDownloadStudentListReducer,
  CSVDownloadStudentListReducerType,
} from "./classchangemanagement/getCSVDownloadStudentList/getDownloadCSVStudentListReducer";
import {
  uploadCSVClassChangeReducer,
  UploadCSVClassChangeReducerType,
} from "./classchangemanagement/uploadCSV/uploadCSVReducer";
import {
  saveTemporaryStudentClassChangeReducer,
  SaveTemporaryStudentClassChangeReducerType,
} from "./classchangemanagement/saveTemporary/saveTemporaryReducer";
import {
  downloadQrCodeReducer,
  DownloadQrcodeReducerType,
} from "./qrcode/qrcodeReducer";
import {
  searchConditionReducer,
  SearchConditionReducerType,
} from "./messagemanagement/searchCondition/searchConditionReducer";
import {
  accountListReducer,
  AccountListReducerType,
} from "./account/accountList/accountListReducer";
import {
  deleteAccountReducer,
  DeleteAccountReducerType,
} from "./account/deleteAccount/deleteAccountReducer";
import {
  departmentListReducer as accountDepartmentList,
  DepartmentListReducerType as AccountDepartmentListReducerType,
} from "./account/departmentList/departmentListReducer";
import {
  editAccountReducer,
  EditAccountReducerType,
} from "./account/editAccount/editAccountReducer";
import {
  registrationListReducer,
  RegistrationListReducerType,
} from "./account/registrationList/registrationListReducer";
import {
  createAccountReducer,
  CreateAccountReducerType,
} from "./account/createAccount/createAccountReducer";
import {
  settingDataReducer,
  SettingDataReducerType,
} from "./setting/settingData/settingDataReducer";
import {
  updateTempertureSettingReducer,
  UpdateTemperatureSettingReducerType,
} from "./setting/updateTemperature/updateTemperatureReducer";
import {
  updateGeneralSettingReducer,
  UpdateGeneralSettingReducerType,
} from "./setting/updateGeneral/updateGeneralReducer";
import {
  deleteNotificationReducer,
  DeleteNotificationReducerType,
} from "./setting/deleteNotification/deleteNotificationReducer";
import {
  updateMessageSettingReducer,
  UpdateMessageSettingReducerType,
} from "./setting/updateMessage/updateMessageReducer";
import {
  getClassListReducer,
  ClassListEndReducerType,
} from "./classchangemanagement/getClassList/getClassListReducer";
import {
  readHistoryListReducer,
  ReadHistoryListReducerType,
} from "./messagemanagement/readHistory/readHistoryListReducer";
import {
  recordListReducer,
  RecordListReducerType,
} from "./vaccine/records/recordsReducer";
import {
  graphDataReducer,
  GraphDataReducerType,
} from "./vaccine/graph/graphReducer";
import {
  createVenuesReducer,
  CreateVenuesReducerType,
} from "./vaccine/venues/createVenues/createVenuesReducer";
import {
  venuesListReducer,
  VenuesListReducerType,
} from "./vaccine/venues/list/listReducer";
import {
  deleteVenuesReducer,
  DeleteVenuesReducerType,
} from "./vaccine/venues/deleteVenues/deleteVenuesReducer";
import {
  updateVenuesReducer,
  UpdateVenuesReducerType,
} from "./vaccine/venues/updateVenues/updateVenuesReducer";
import {
  reservationsReducer,
  ReservationsReducerType,
} from "./vaccine/reservations/reservationsReducer";
import {
  todaySchedulesReducer,
  TodaySchedulesReducerType,
} from "./vaccine/todaySchedules/todaySchedulesReducer";
import {
  saveScheduleReducer,
  SaveScheduleReducerType,
} from "./vaccine/saveSchedule/saveScheduleReducer";
import {
  updateScheduleReducer,
  UpdateScheduleReducerType,
} from "./vaccine/updateSchedule/updateScheduleReducer";
import {
  venuesReducer,
  VenuesReducerType,
} from "./vaccine/venues/venuesReducer";
import { slotsReducer, SlotsReducerType } from "./vaccine/slots/slotsReducer";
import {
  deleteSlotReducer,
  DeleteSlotReducerType,
} from "./vaccine/deleteSlot/deleteSlotReducer";
import {
  scheduleFilterReducer,
  ScheduleFilterDatasReducerType,
} from "./vaccine/getScheduleFilter/getScheduleFilterReducer";
import {
  timeSlotReducer,
  TimeSlotReducerType,
} from "./vaccine/timeSlot/timeSlotReducer";
import {
  mentalCheckListReducer,
  MentalCheckListReducerType,
} from "./mentalHealth/checkList/checkListReducer";
import {
  mentalCheckDetailsReducer,
  MentalCheckDetailsReducerType,
} from "./mentalHealth/checkDetails/checkDetailsReducer";
import {
  gradeListReducer,
  GradeListReducerType,
} from "./mentalHealth/gradesList/gradeListReducer";
import {
  newMentalCheckReducer,
  NewMentalCheckReducerType,
} from "./mentalHealth/newMentalCheck/newMentalCheckReducer";
import {
  updateMentalCheckReducer,
  UpdateMentalCheckReducerType,
} from "./mentalHealth/updateMentalCheck/updateMentalCheckReducer";
import {
  deleteMentalCheckReducer,
  DeleteMentalCheckReducerType,
} from "./mentalHealth/deleteMentalCheck/deleteMentalCheckReducer";
import {
  questionnairesReducer,
  QuestionnairesReducerType,
} from "./questionnaires/questionnairesByType/questionnairesByTypeReducer";
import {
  questionnaireDetailReducer,
  QuestionnairesDetailReducerType,
} from "./questionnaires/questionnairesDetail/questionnairesDetailReducer";
import {
  createQuestionnairesReducer,
  CreateQuestionnairesReducerType,
} from "./questionnaires/createQuestionnaires/createQuestionnairesReducer";
import {
  answerInfoReducer,
  AnswerInfoReducerType,
} from "./questionnaires/answerInfo/answerInfoReducer";
import {
  individualAnswerReducer,
  IndividualAnswerReducerType,
} from "./questionnaires/individualAnswer/individualAnswerReducer";
import {
  questionnaireResponseDetailReducer,
  QuestionnairesResponseDetailReducerType,
} from "./questionnaires/questionResponseDetail/questionResponseDetailReducer";
import {
  duplicateQuestionReducer,
  DuplicateQuestionReducerType,
} from "./questionnaires/duplicateQuestion/duplicateQuestionReducer";
import {
  doneQuestionReducer,
  DoneQuestionReducerType,
} from "./questionnaires/doneQuestion/doneQuestionReducer";
import {
  sendQuestionnaireReducer,
  SendQuestionnaireReducerType,
} from "./questionnaires/sendQuestionnaire/sendQuestionnaireReducer";
import {
  questionHistoryReducer,
  QuestionHistoryReducerType,
} from "./questionnaires/sendQuestionHistory/sendQuestionHistoryReducer";
import {
  questionHistoryDetailReducer,
  QuestionHistoryDetailReducerType,
} from "./questionnaires/questionHistoryDetail/questionHistoryDetailReducer";
import {
  answerHistoryListReducer,
  AnswerHistoryListReducerType,
} from "./questionnaires/answerHistory/answerHistoryReducer";
import {
  updateQuestionnaireReducer,
  UpdateQuestionnaireReducerType,
} from "./questionnaires/updateQuestionnaires/updateQuestionnairesReducer";
import {
  deleteQuestionnairesReducer,
  DeleteQuestionnairesReducerType,
} from "./questionnaires/deleteQuestionnaires/deleteQuestionnairesReducer";
import {
  mentalCheckRequestsReducer,
  MentalCheckRequestsReducerType,
} from "./mentalHealth/mentalCheckRequests/mentalCheckRequestsReducer";
import {
  mentalCheckTypeInfoReducer,
  MentalCheckTypeInfoReducerType,
} from "./mentalHealth/typeInformation/typeInformationReducer";
import {
  sendNotificationReducer,
  SendNotificationReducerType,
} from "./mentalHealth/sendNotification/sendNotificationReducer";
import {
  csvDownloadTypeInfoReducer,
  CSVDownloadTypeInfoReducerType,
} from "./mentalHealth/downloadTypeCSV/downloadTypeCSVReducer";
import {
  pdfDownloadTypeInfoReducer,
  PDFDownloadTypeInfoReducerType,
} from "./mentalHealth/downloadTypePDF/downloadTypePDFReducer";
import {
  scoringInformationReducer,
  ScoringInformationReducerType,
} from "./mentalHealth/scoringInformation/scoringInformationReducer";
import {
  mentalCheckTotalReducer,
  MentalCheckTotalReducerType,
} from "./mentalHealth/mentalHealthTotal/mentalHealthTotalReducer";
import {
  typeListReducer,
  TypeListReducerType,
} from "./mentalHealth/typeList/typeListReducer";
import {
  lastNotificationTimeReducer,
  LastNotificationTimeReducerType,
} from "./mentalHealth/lastNotificationTime/lastNotificationTimeReducer";
import {
  mentalClassListReducer,
  MentalClassListReducerType,
} from "./mentalHealth/classList/classListReducer";
import {
  pdfDownloadScoringInfoReducer,
  PDFDownloadScoringInfoReducerType,
} from "./mentalHealth/downloadScoringPDF/downloadScoringPDFReducer";
import {
  PushNotificationReducerType,
  pushNotificationReducer,
} from "./messagemanagement/pushNotification/pushNotificationReducer";
import {
  sendInterviewRequestReducer,
  SendInterviewRequestReducerType,
} from "./mentalHealth/sendInterviewRequest/sendInterviewRequestReducer";
import {
  DownloadCSVQuestionReducerType,
  downloadCSVQuestionReducer,
} from "./questionnaires/downloadCSVQuestion/downloadCSVQuestionReducer";
import {
  DownloadCSVAllQuestionsReducerType,
  downloadCSVAllQuestionsReducer,
} from "./questionnaires/downloadCSVAllQuestions/downloadCSVAllQuestionsReducer";
import {
  RemindPushNotificationReducerType,
  remindPushNotificationReducer,
} from "./questionnaires/remindPushNotification/remindPushNotificationReducer";
import {
  CancelQuestionnaireReducerType,
  cancelQuestionnaireReducer,
} from "./questionnaires/cancelQuestionnaire/cancelQuestionnaireReducer";
import {
  TemperatureAvgReducerType,
  getTemperatureAvgReducer,
} from "./dashboard/temperatureAvg/temperatureAvgReducer";
import {
  DetailSymtomReducerType,
  getDetailSymtomReducer,
} from "./dashboard/detailSymtom/detailSymtomReducer";
import {
  AbsenceReducerType,
  getAbsenceReducer,
} from "./dashboard/absence/absenceReducer";
import {
  FeverReducerType,
  getFeverReducer,
} from "./dashboard/fever/feverReducer";
import {
  SymtomReducerType,
  getSymtomReducer,
} from "./dashboard/symtom/symtomReducer";
import {
  TemperatureAmountsReducerType,
  getTemperatureAmountsReducer,
} from "./dashboard/temperatureAmounts/temperatureAmountsReducer";
import {
  ProgressReducerType,
  getProgressReducer,
} from "./dashboard/progress/progressReducer";
import {
  FaceScaleReducerType,
  getFaceScaleReducer,
} from "./dashboard/faceScale/faceScaleReducer";
import {
  HealthCheckQuestionsReducerType,
  healthCheckQuestionsReducer,
} from "./bodyTemperature/healthCheckQuestions/healthCheckQuestionsReducer";
import {
  HealthCheckAnswersReducerType,
  healthCheckAnswersReducer,
} from "./bodyTemperature/healthCheckAnswers/healthCheckAnswersReducer";
import {
  SendHealthCheckAnswersReducerType,
  sendHealthCheckAnswersReducer,
} from "./bodyTemperature/sendHealthCheckAnswers/sendHealthCheckAnswersReducer";
import {
  SetHealthCheckSettingReducerType,
  setHealthCheckSettingReducer,
} from "./setting/healthCheckSetting/healthCheckSettingReducer";
import {
  GetHealthCheckSettingReducerType,
  getHealthCheckSettingReducer,
} from "./setting/getHealthCheckSetting/getHealthCheckSettingReducer";
import {
  UpdatePositionColumnReducerType,
  updatePositionColumnReducer,
} from "./updatePositionColumn/updatePositionColumnReducer";
import {
  HealthCheckProgressReducerType,
  getHealthCheckProgressReducer,
} from "./dashboard/healthCheckProgress/healthCheckProgressReducer";
import {
  AttendanceStatusReducerType,
  getAttendanceStatusReducer,
} from "./dashboard/attendanceStatus/attendanceStatusReducer";
import {
  DeseaseOutbreakReducerType,
  getDeseaseOutbreakReducer,
} from "./dashboard/deseaseOutbreak/deseaseOutbreakReducer";
import {
  PrefectureReducerType,
  getPrefectureReducer,
} from "./dashboard/prefecture/prefectureReducer";
import {
  GetCitiesReducerType,
  getCitiesReducer,
} from "./dashboard/cities/citiesReducer";
import {
  UpdateDeseaseSettingReducerType,
  updateDeseaseSettingReducer,
} from "./setting/updateDesease/updateDeseaseReducer";
import {
  ExchangeListReducerType,
  exchangeListReducer,
} from "./consultationRequest/exchangeList/exchangeListReducer";
import {
  ConsultationListReducerType,
  consultationListReducer,
} from "./consultationRequest/consultationList/consultationListReducer";
import {
  CompleteRequestReducerType,
  completeRequestReducer,
} from "./consultationRequest/completeRequest/completeRequestReducer";
import {
  AddRequestReducerType,
  addRequestReducer,
} from "./consultationRequest/addRequest/addRequestReducer";
import {
  ConsultationSettingsReducerType,
  consultationSettingsReducer,
} from "./consultationRequest/consultationSettings/consultationSettingsReducer";
import {
  SaveConsultationSettingsReducerType,
  saveConsultationSettingsReducer,
} from "./consultationRequest/saveConsultationSettings/saveConsultationSettingsReducer";
import {
  UpdateChartOrderReducerType,
  updateChartOrderReducer,
} from "./setting/updateChartOrder/updateChartOrderReducer";
import {
  UpdateMessageReducerType,
  updateMessageReducer,
} from "./messagemanagement/updateMessage/updateMessageReducer";
import {
  DeleteMessageReducerType,
  deleteMessageReducer,
} from "./messagemanagement/deleteMessage/deleteMessageReducer";
import {
  UpdateUserRegistrationSettingReducerType,
  updateUserRegistrationSettingReducer,
} from "./setting/updateUserRegistration/updateUserRegistrationReducer";
import {
  SendNotificationConsultationReducerType,
  sendNotificationConsultationReducer,
} from "./consultationRequest/sendNotification/sendNotificationReducer";

export interface RootState {
  loginForm: AuthenticationReducerType;
  forgotPassword: ForgotPasswordReducerType;
  resetPassword: ResetPasswordReducerType;
  userList: UserListReducerType;
  classList: ClassListReducerType;
  departmentList: DepartmentListReducerType;
  subDepartmentList: SubDepartmentListReducerType;
  activitiesList: ActivitiesListReducerType;
  createUser: CreateUserReducerType;
  updateUser: UpdateUserReducerType;
  deleteUser: DeleteUserReducerType;
  csvDownloadUser: CSVDownloadUserReducerType;
  userAttendance: UserAttendanceReducerType;
  classSchoolList: ClassSchoolListReducerType;
  createClassSchool: CreateClassReducerType;
  updateClassSchool: UpdateClassReducerType;
  schoolType: SchoolTypeReducerType;
  classType: ClassTypeReducerType;
  createBulkClasses: CreateBulkClassReducerType;
  deleteClass: DeleteClassReducerType;
  activityList: ActivityListSchoolReducerType;
  createActivity: CreateActivityReducerType;
  updateActivity: UpdateActivityReducerType;
  deleteActivity: DeleteActivityReducerType;
  activitiesListFilter: ActivityFilterListReducerType;
  classListFilter: GetClassListFilterReducerType;
  getStudentList: GetStudentListReducerType;
  dataCharts: DataChartsReducerType;
  dataStatistics: DataStatisticsReducerType;
  dataDestination: DataDestinationReducerType;
  dataGroupClass: DataGroupClassReducerType;
  dataChildDepartment: DataChildDepartmentReducerType;
  dataPatientChildDepartment: DataPatientChildDepartmentReducerType;
  dataDetailForm: DataDetailFormReducerType;
  createPatientProfile: PatientProfileReducerType;
  dataPatientDetail: DataPatientDetailReducerType;
  dataFeverPercentage: DataFeverPercentageReducerType;
  bodyTemperatureSettingForm: BodyTemperatureSettingFormReducerType;
  bodyTemperatureFile: BodyTemperatureFileReducerType;
  dataProgressBar: DataProgressBarReducerType;
  getStudentListClassChange: StudentListClassChangeReducerType;
  getConfirmStudentList: ConfirmStudentListReducerType;
  clearTempRegister: ClearTempRegisterReducerType;
  putConfirmStudentList: PutConfirmStudentClassChangeReducerType;
  temperatureListFilter: GetTemperatureListFilterReducerType;
  messageHistoryList: MessageHistoryListReducerType;
  attendanceListFilter: AttendanceFilterListReducerType;
  messageHistoryDetail: MessageHistoryDetailReducerType;
  sendMessage: SendMessageReducerType;
  getCSVDownloadStudentList: CSVDownloadStudentListReducerType;
  uploadCSVStudentList: UploadCSVClassChangeReducerType;
  saveTemporaryStudentList: SaveTemporaryStudentClassChangeReducerType;
  downloadQrcode: DownloadQrcodeReducerType;
  searchCondition: SearchConditionReducerType;
  accountList: AccountListReducerType;
  deleteAccount: DeleteAccountReducerType;
  accountDepartmentList: AccountDepartmentListReducerType;
  editAccount: EditAccountReducerType;
  accountRegistrationList: RegistrationListReducerType;
  createAccount: CreateAccountReducerType;
  settingData: SettingDataReducerType;
  updateTemperatureSetting: UpdateTemperatureSettingReducerType;
  updateGeneralSetting: UpdateGeneralSettingReducerType;
  updateMessageSetting: UpdateMessageSettingReducerType;
  deleteNotification: DeleteNotificationReducerType;
  classListEnd: ClassListEndReducerType;
  readHistory: ReadHistoryListReducerType;
  recordList: RecordListReducerType;
  graphData: GraphDataReducerType;
  createVenues: CreateVenuesReducerType;
  venuesList: VenuesListReducerType;
  deleteVenues: DeleteVenuesReducerType;
  updateVenues: UpdateVenuesReducerType;
  reservations: ReservationsReducerType;
  todaySchedules: TodaySchedulesReducerType;
  saveSchedule: SaveScheduleReducerType;
  updateSchedule: UpdateScheduleReducerType;
  venues: VenuesReducerType;
  slots: SlotsReducerType;
  deleteSlot: DeleteSlotReducerType;
  scheduleFilter: ScheduleFilterDatasReducerType;
  timeSlot: TimeSlotReducerType;
  mentalCheckList: MentalCheckListReducerType;
  mentalCheckDetails: MentalCheckDetailsReducerType;
  gradeList: GradeListReducerType;
  newMentalCheck: NewMentalCheckReducerType;
  updateMentalCheck: UpdateMentalCheckReducerType;
  deleteMentalCheck: DeleteMentalCheckReducerType;
  questionnaires: QuestionnairesReducerType;
  questionnairesDetail: QuestionnairesDetailReducerType;
  createQuestionnaires: CreateQuestionnairesReducerType;
  answerInfo: AnswerInfoReducerType;
  individualAnswer: IndividualAnswerReducerType;
  questionResponseDetail: QuestionnairesResponseDetailReducerType;
  duplicateQuestion: DuplicateQuestionReducerType;
  doneQuestion: DoneQuestionReducerType;
  sendQuestionnaire: SendQuestionnaireReducerType;
  questionHistory: QuestionHistoryReducerType;
  questionHistoryDetail: QuestionHistoryDetailReducerType;
  answerHistory: AnswerHistoryListReducerType;
  updateQuestionnaire: UpdateQuestionnaireReducerType;
  deleteQuestionnaire: DeleteQuestionnairesReducerType;
  mentalCheckRequests: MentalCheckRequestsReducerType;
  mentalCheckTypeInfo: MentalCheckTypeInfoReducerType;
  sendNotification: SendNotificationReducerType;
  csvDownloadTypeInfo: CSVDownloadTypeInfoReducerType;
  pdfDownloadTypeInfo: PDFDownloadTypeInfoReducerType;
  scoringInformation: ScoringInformationReducerType;
  mentalCheckTotal: MentalCheckTotalReducerType;
  mentalCheckTypeList: TypeListReducerType;
  lastSendNotificationTime: LastNotificationTimeReducerType;
  mentalClassList: MentalClassListReducerType;
  pdfDownloadScoringInfo: PDFDownloadScoringInfoReducerType;
  pushNotification: PushNotificationReducerType;
  sendInterviewRequest: SendInterviewRequestReducerType;
  downloadCSVQuestion: DownloadCSVQuestionReducerType;
  downloadCSVAllQuestions: DownloadCSVAllQuestionsReducerType;
  remindPushNotification: RemindPushNotificationReducerType;
  cancelQuestionnaire: CancelQuestionnaireReducerType;
  approveAbsence: ApproveAbsenceReducerType;
  temperatureAvg: TemperatureAvgReducerType;
  detailSymtom: DetailSymtomReducerType;
  absence: AbsenceReducerType;
  fever: FeverReducerType;
  symtom: SymtomReducerType;
  temperatureAmounts: TemperatureAmountsReducerType;
  progress: ProgressReducerType;
  faceScale: FaceScaleReducerType;
  healthCheckQuestions: HealthCheckQuestionsReducerType;
  healthCheckAnswers: HealthCheckAnswersReducerType;
  sendHealthCheckAnswers: SendHealthCheckAnswersReducerType;
  setHealthCheckSetting: SetHealthCheckSettingReducerType;
  getHealthCheckSetting: GetHealthCheckSettingReducerType;
  updatePositionColumn: UpdatePositionColumnReducerType;
  healthCheckProgress: HealthCheckProgressReducerType;
  attendanceStatus: AttendanceStatusReducerType;
  deseaseOutbreak: DeseaseOutbreakReducerType;
  prefecture: PrefectureReducerType;
  getCities: GetCitiesReducerType;
  updateDeseaseSetting: UpdateDeseaseSettingReducerType;
  exchangeList: ExchangeListReducerType;
  consultationList: ConsultationListReducerType;
  completeRequest: CompleteRequestReducerType;
  addRequest: AddRequestReducerType;
  consultationSettings: ConsultationSettingsReducerType;
  saveConsultationSettings: SaveConsultationSettingsReducerType;
  updateChartOrder: UpdateChartOrderReducerType;
  updateMessage: UpdateMessageReducerType;
  deleteMessage: DeleteMessageReducerType;
  updateUserRegistration: UpdateUserRegistrationSettingReducerType;
  sendNotificationConsultation: SendNotificationConsultationReducerType;
  routerReducer: RouterState;
}

const appReducer = combineReducers({
  loginForm: authenticationReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  userList: userListReducer,
  classList: classListReducer,
  departmentList: departmentListReducer,
  subDepartmentList: subDepartmentListReducer,
  activitiesList: activitiesListReducer,
  createUser: createUserReducer,
  updateUser: updateUserReducer,
  deleteUser: deleteUserReducer,
  csvDownloadUser: csvDownloadUserReducer,
  userAttendance: userAttendanceReducer,
  classSchoolList: classSchoolListReducer,
  createClassSchool: createClassReducer,
  updateClassSchool: updateClassReducer,
  schoolType: schoolTypeReducer,
  classType: classTypeReducer,
  createBulkClasses: createBulkClassReducer,
  deleteClass: deleteClassReducer,
  activityList: activityListSchoolReducer,
  createActivity: createActivityReducer,
  updateActivity: updateActivityReducer,
  deleteActivity: deleteActivityReducer,
  activitiesListFilter: activityListFilterReducer,
  classListFilter: classListFilterReducer,
  getStudentList: getStudentListReducer,
  dataCharts: dataChartsReducer,
  dataStatistics: dataStatisticsReducer,
  dataDestination: dataDestinationReducer,
  dataGroupClass: dataGroupClassReducer,
  dataChildDepartment: dataChildDepartmentReducer,
  dataPatientChildDepartment: dataPatientChildDepartmentReducer,
  createPatientProfile: createPatientProfileReducer,
  dataDetailForm: dataDetailFormReducer,
  dataPatientDetail: dataPatientDetailReducer,
  dataFeverPercentage: dataFeverPercentageReducer,
  bodyTemperatureSettingForm: getBodyTemperatureSettingFormDataReducer,
  bodyTemperatureFile: getBodyTemperatureFileDataReducer,
  dataProgressBar: dataProgressBarReducer,
  getStudentListClassChange: getStudentListClassChangeReducer,
  getConfirmStudentList: getConfirmStudentListReducer,
  clearTempRegister: clearTempRegisterReducer,
  putConfirmStudentList: putConfirmStudentClassChangeReducer,
  temperatureListFilter: temperatureListFilterReducer,
  messageHistoryList: messageHistoryListReducer,
  attendanceListFilter: attendanceFilterListReducer,
  messageHistoryDetail: messageHistoryDetailReducer,
  sendMessage: sendMessageReducer,
  getCSVDownloadStudentList: getCSVDownloadStudentListReducer,
  uploadCSVStudentList: uploadCSVClassChangeReducer,
  saveTemporaryStudentList: saveTemporaryStudentClassChangeReducer,
  downloadQrcode: downloadQrCodeReducer,
  searchCondition: searchConditionReducer,
  accountList: accountListReducer,
  deleteAccount: deleteAccountReducer,
  accountDepartmentList: accountDepartmentList,
  editAccount: editAccountReducer,
  accountRegistrationList: registrationListReducer,
  createAccount: createAccountReducer,
  settingData: settingDataReducer,
  updateTemperatureSetting: updateTempertureSettingReducer,
  updateGeneralSetting: updateGeneralSettingReducer,
  updateMessageSetting: updateMessageSettingReducer,
  deleteNotification: deleteNotificationReducer,
  classListEnd: getClassListReducer,
  readHistory: readHistoryListReducer,
  recordList: recordListReducer,
  graphData: graphDataReducer,
  createVenues: createVenuesReducer,
  venuesList: venuesListReducer,
  deleteVenues: deleteVenuesReducer,
  updateVenues: updateVenuesReducer,
  reservations: reservationsReducer,
  todaySchedules: todaySchedulesReducer,
  saveSchedule: saveScheduleReducer,
  updateSchedule: updateScheduleReducer,
  venues: venuesReducer,
  slots: slotsReducer,
  deleteSlot: deleteSlotReducer,
  scheduleFilter: scheduleFilterReducer,
  timeSlot: timeSlotReducer,
  mentalCheckList: mentalCheckListReducer,
  mentalCheckDetails: mentalCheckDetailsReducer,
  gradeList: gradeListReducer,
  newMentalCheck: newMentalCheckReducer,
  updateMentalCheck: updateMentalCheckReducer,
  deleteMentalCheck: deleteMentalCheckReducer,
  questionnaires: questionnairesReducer,
  questionnairesDetail: questionnaireDetailReducer,
  createQuestionnaires: createQuestionnairesReducer,
  answerInfo: answerInfoReducer,
  individualAnswer: individualAnswerReducer,
  questionResponseDetail: questionnaireResponseDetailReducer,
  duplicateQuestion: duplicateQuestionReducer,
  doneQuestion: doneQuestionReducer,
  sendQuestionnaire: sendQuestionnaireReducer,
  questionHistory: questionHistoryReducer,
  questionHistoryDetail: questionHistoryDetailReducer,
  answerHistory: answerHistoryListReducer,
  updateQuestionnaire: updateQuestionnaireReducer,
  deleteQuestionnaire: deleteQuestionnairesReducer,
  mentalCheckRequests: mentalCheckRequestsReducer,
  mentalCheckTypeInfo: mentalCheckTypeInfoReducer,
  sendNotification: sendNotificationReducer,
  csvDownloadTypeInfo: csvDownloadTypeInfoReducer,
  pdfDownloadTypeInfo: pdfDownloadTypeInfoReducer,
  scoringInformation: scoringInformationReducer,
  mentalCheckTotal: mentalCheckTotalReducer,
  mentalCheckTypeList: typeListReducer,
  lastSendNotificationTime: lastNotificationTimeReducer,
  mentalClassList: mentalClassListReducer,
  pdfDownloadScoringInfo: pdfDownloadScoringInfoReducer,
  pushNotification: pushNotificationReducer,
  sendInterviewRequest: sendInterviewRequestReducer,
  downloadCSVQuestion: downloadCSVQuestionReducer,
  downloadCSVAllQuestions: downloadCSVAllQuestionsReducer,
  remindPushNotification: remindPushNotificationReducer,
  cancelQuestionnaire: cancelQuestionnaireReducer,
  approveAbsence: approveAbsenceReducer,
  temperatureAvg: getTemperatureAvgReducer,
  detailSymtom: getDetailSymtomReducer,
  absence: getAbsenceReducer,
  fever: getFeverReducer,
  symtom: getSymtomReducer,
  temperatureAmounts: getTemperatureAmountsReducer,
  progress: getProgressReducer,
  faceScale: getFaceScaleReducer,
  healthCheckQuestions: healthCheckQuestionsReducer,
  healthCheckAnswers: healthCheckAnswersReducer,
  sendHealthCheckAnswers: sendHealthCheckAnswersReducer,
  setHealthCheckSetting: setHealthCheckSettingReducer,
  getHealthCheckSetting: getHealthCheckSettingReducer,
  updatePositionColumn: updatePositionColumnReducer,
  healthCheckProgress: getHealthCheckProgressReducer,
  attendanceStatus: getAttendanceStatusReducer,
  deseaseOutbreak: getDeseaseOutbreakReducer,
  prefecture: getPrefectureReducer,
  getCities: getCitiesReducer,
  updateDeseaseSetting: updateDeseaseSettingReducer,
  exchangeList: exchangeListReducer,
  consultationList: consultationListReducer,
  completeRequest: completeRequestReducer,
  addRequest: addRequestReducer,
  consultationSettings: consultationSettingsReducer,
  saveConsultationSettings: saveConsultationSettingsReducer,
  updateChartOrder: updateChartOrderReducer,
  updateMessage: updateMessageReducer,
  deleteMessage: deleteMessageReducer,
  updateUserRegistration: updateUserRegistrationSettingReducer,
  sendNotificationConsultation: sendNotificationConsultationReducer,
  routerReducer,
});

export default (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
