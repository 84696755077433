import { IResponse } from "models";

export enum ActionType {
  DATA_FEVER_PERCENTAGE = "action/DATA_FEVER_PERCENTAGE",
  DATA_FEVER_PERCENTAGE_SUCCESS = "action/DATA_FEVER_PERCENTAGE_SUCCESS",
  DATA_FEVER_PERCENTAGE_ERROR = "action/DATA_FEVER_PERCENTAGE_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DataFeverPercentageResponse extends IResponse {
  result: any;
}
