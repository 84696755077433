import { IResponse } from "models";

export enum ITypeInputPhoneNumberProps {
  LOGIN,
  FORGOT_PASSWORD,
}

export interface Users {
  id?: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  authentication_token?: string;
}

export enum ActionType {
  // login
  LOGIN_USER = "action/LOGIN_USER",
  LOGIN_USER_SUCCESS = "action/LOGIN_USER_SUCCESS",
  LOGIN_USER_ERROR = "action/LOGIN_USER_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface LoginData {
  user: {
    email: string | null;
    password: string | null;
    role: number | null;
  };
  locale?: string;
}
export interface LoginResponse extends IResponse {
  result: {
    auth_token: string;
    id: number;
    name: string;
    account_type: string;
  };
}
