export const handleCheckValue = (value: any) => {
  return typeof value !== "undefined" && value !== null;
};

export const numberWithCommas = (x: number) => {
  if (!x) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const screens: any = {
  dashboard: "/dashboard",
  "communication_from_students/heathcheck": "/body-temperature-management",
  message_delivery: "/message-delivery",
  questionaire: "/questions-list",
  heathcheck: "/mental-health-management",
};

export const getDefaultScreen = (screen: any) => {
  if (screen !== undefined) {
    return screens[screen.screen_code];
  }

  return "/message-delivery";
};

export const handleTimeOptions = (hour: any) => {
  const time = [];

  for (let i = 0; i <= 23; i++) {
    if (i <= 9) {
      time.push({
        id: i,
        value: `0${i}:00`,
        name: `0${i}:00`,
        disabled: i < hour ? true : false,
      });
    } else {
      time.push({
        id: i,
        value: `${i}:00`,
        name: `${i}:00`,
        disabled: i < hour ? true : false,
      });
    }
  }

  return time;
};
