import { IResponse } from "models";

export enum ActionType {
  EXCHANGE_LIST = "action/EXCHANGE_LIST",
  EXCHANGE_LIST_SUCCESS = "action/EXCHANGE_LIST_SUCCESS",
  EXCHANGE_LIST_ERROR = "action/EXCHANGE_LIST_ERROR",
  EXCHANGE_LIST_RESET = "action/EXCHANGE_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IExchangeListPayload {
  is_uncompleted: boolean;
  page: number;
  per_page: number;
}

export interface IExchangeItem {
  id: number;
  first_name: string;
  last_name: string;
  furi_first_name: string;
  furi_last_name: string;
  roll_number: number;
  class_name: string;
  content: string;
  status: number;
  request_type: number;
  password: string;
  created_at: string;
  updated_at: string;
  request_techers: string;
  is_latest_created_at: boolean;
}

export interface IExchangeListResponse extends IResponse {
  result: IExchangeItem[];
  total_record: number;
  current_page: number;
  next_page: number;
}
