import { IResponse } from "models";

export enum ActionType {
  VACCINE_DELETE_VENUES = "action/VACCINE_DELETE_VENUES",
  VACCINE_DELETE_VENUES_SUCCESS = "action/VACCINE_DELETE_VENUES_SUCCESS",
  VACCINE_DELETE_VENUES_ERROR = "action/VACCINE_DELETE_VENUES_ERROR",
  VACCINE_DELETE_VENUES_RESET = "action/VACCINE_DELETE_VENUES_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDeleteVenuesResponse extends IResponse {
  result: any;
}
