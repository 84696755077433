import { IResponse } from "models";

export enum ActionType {
  READ_HISTORY_LIST = "action/READ_HISTORY_LIST",
  READ_HISTORY_LIST_SUCCESS = "action/READ_HISTORY_LIST_SUCCESS",
  READ_HISTORY_LIST_ERROR = "action/READ_HISTORY_LIST_ERROR",
  RESET_READ_HISTORY_LIST = "action/RESET_READ_HISTORY_LIST",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IReadHistoryData {
  id: number;
  is_read: string | boolean;
  locale: string;
  page: number;
  limit: number;
}

export interface IReadHistory {
  id: number;
  full_name: string;
  is_read: boolean;
  roll_number: number;
  classes_name: string;
}

export interface IReadHistoryResponse extends IResponse {
  result: {
    total: number;
    total_read: number;
    total_unread: number;
    total_record: number;
    histories: IReadHistory[];
  };
}
