import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DataChildDepartmentResponse,
} from "models/bodyTemperature/childDepartment";
import { httpStatus } from "configs/httpStatus";
import { fetchChildDepartmentData } from "services/bodyTemperature";

import {
  handleSuccesAction,
  handleErrorAction,
} from "./childDepartmentActions";

function* getDataChildDepartmentSaga({
  payload,
}: {
  payload: { dept_id: number };
}) {
  try {
    const response: DataChildDepartmentResponse = yield call(
      fetchChildDepartmentData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDataChildDepartmentWatcher() {
  yield takeLatest(
    ActionType.DATA_CHILD_DEPARTMENT as any,
    getDataChildDepartmentSaga
  );
}

export default [fork(onGetDataChildDepartmentWatcher)];
