import { Action, ActionType } from "models/mentalHealth/newMentalCheck";
import { IResponse } from "models";

import createReducer from "redux/createReducer";

export interface NewMentalCheckReducerType {
  loading: boolean;
  error?: any;
  saved: boolean;
  newMentalCheckResponse?: IResponse;
}

const defaultState: NewMentalCheckReducerType = {
  loading: false,
  error: null,
  saved: false,
  newMentalCheckResponse: undefined,
};

export const newMentalCheckReducer = createReducer<NewMentalCheckReducerType>(
  defaultState,
  {
    [ActionType.NEW_MENTAL_CHECK](state: NewMentalCheckReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.NEW_MENTAL_CHECK_ERROR](
      state: NewMentalCheckReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      };
    },

    [ActionType.NEW_MENTAL_CHECK_SUCCESS](
      state: NewMentalCheckReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: true,
        newMentalCheckResponse: action.payload,
      };
    },

    [ActionType.RESET_NEW_MENTAL_CHECK](state: NewMentalCheckReducerType) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        newMentalCheckResponse: undefined,
      };
    },
  }
);
