import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/messagemanagement/deleteMessage";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { deleteMessage } from "services/messagemanagement";
import { handleSuccesAction, handleErrorAction } from "./deleteMessageActions";

function* deleteMessageSaga({ payload }: { payload: any }) {
  try {
    const response: IResponse = yield call(deleteMessage, payload);

    if (
      response.status === httpStatus.StatusCreated ||
      response.status === httpStatus.StatusOK
    ) {
      console.log(response, "response");
      yield put(handleSuccesAction(response));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDeleteMessageWatcher() {
  yield takeLatest(ActionType.DELETE_MESSAGE as any, deleteMessageSaga);
}

export default [fork(onDeleteMessageWatcher)];
