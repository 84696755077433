import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  // get department list
  DEPARTMENT_LIST = "action/DEPARTMENT_LIST",
  DEPARTMENT_LIST_SUCCESS = "action/DEPARTMENT_LIST_SUCCESS",
  DEPARTMENT_LIST_ERROR = "action/DEPARTMENT_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DepartmentListData {
  locale?: string | null;
  role?: number | null;
}

export interface DepartmentListResponse extends IResponse {
  result?: IOption[];
}
