import React from "react";

import styled from "styled-components";
import { WarningIcon } from "icons";

interface AlertProps {
  text?: string;
}

const AlertWrapper = styled.div`
  min-height: 66px;
  width: 870px;
  border-radius: 8px;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6a609;
`;

const Text = styled.p`
  margin: 0px 0px 0px 8px;
  width: max-content;
  font-size: ${({ theme }) => theme.sizes.xl};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-style: normal;
`;
const CustomAlert: React.FC<AlertProps> = ({ text }) => {
  return (
    <AlertWrapper>
      <WarningIcon />
      <Text>{text}</Text>
    </AlertWrapper>
  );
};

export default CustomAlert;
