import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ScheduleData,
  ActionType,
  SaveScheduleResponse,
} from "models/vaccine/schedules";
import { httpStatus } from "configs/httpStatus";
import { saveSchedule } from "services/vaccine";
import { handleSuccesAction, handleErrorAction } from "./saveScheduleActions";
import { history } from "../../configureStore";

function* saveScheduleSaga({ payload }: { payload: ScheduleData }) {
  try {
    const response: SaveScheduleResponse = yield call(saveSchedule, payload);

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
      history.push("/login");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSaveScheduleWatcher() {
  yield takeLatest(ActionType.SAVE_SCHEDULE as any, saveScheduleSaga);
}

export default [fork(onSaveScheduleWatcher)];
