import {
  Action,
  ActionType,
  DeseaseOutbreakResponse,
} from "models/dashboard/deseaseOutbreak";
import createReducer from "../../createReducer";

export interface DeseaseOutbreakReducerType {
  loading: boolean;
  error?: string;
  deseaseOutbreakResponse?: DeseaseOutbreakResponse;
}

const defaultState: DeseaseOutbreakReducerType = {
  loading: false,
  error: undefined,
  deseaseOutbreakResponse: undefined,
};

export const getDeseaseOutbreakReducer =
  createReducer<DeseaseOutbreakReducerType>(defaultState, {
    [ActionType.GET_DESEASE_OUTBREAK_CHART](state: DeseaseOutbreakReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_DESEASE_OUTBREAK_CHART_ERROR](
      state: DeseaseOutbreakReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_DESEASE_OUTBREAK_CHART_SUCCESS](
      state: DeseaseOutbreakReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deseaseOutbreakResponse: action.payload,
      };
    },
  });
