import React, { useState, Fragment, useEffect } from "react";
import { Grid, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { useLocation } from "react-router-dom";

import { Button, Box, Pagination, Select, ScrollToTop } from "components";
import {
  DownloadIcon,
  DownArrow,
  ConfirmFailIcon,
  CheckedIcon,
  SendCompleteIcon,
  SuicideWarningIconRed,
  SuicideWarningIconYellow,
} from "icons";
import {
  handleConvertDateYear,
  localStorageHelper,
  generateDynamicRecords,
  generateCSV,
  mplusFont,
  convertFilename,
} from "utils";
import { CheckboxStyled } from "screens/account/Account.style";
import { IOption } from "components/select/Select";
import ConfirmModal from "screens/changeStudentAffiliation/ConfirmModal";

import { ITypeInfoDownloadData } from "models/mentalHealth/csvDownloadTypeInfo";
import { TypeInfoData } from "models/mentalHealth/typeInformation";
import { IRequest } from "models/mentalHealth/mentalCheckRequests";
import { SendInterviewRequestPayload } from "models/mentalHealth/sendInterviewRequest";
import {
  mentalCheckTypeInfoActionCreators,
  mentalCheckRequestsActionCreators,
  downloadTypeInfoCSVActionCreators,
  downloadTypeInfoPDFActionCreators,
  GetScoringInformationActionCreators,
  GetLastNotificationTimeActionCreators,
  GetMentalClassListActionCreators,
  sendInterviewRequestActionCreators,
} from "redux/rootActions";
import {
  selectIsLoading,
  selectErrorMessage,
  mentalCheckTypeInfoDataResponse,
} from "redux/mentalHealth/typeInformation/typeInformationStates";
import { mentalCheckRequestsDataResponse } from "redux/mentalHealth/mentalCheckRequests/mentalCheckRequestsStates";
import { csvDownloadSuccessResponse } from "redux/mentalHealth/downloadTypeCSV/downloadTypeCSVStates";
import { pdfDownloadSuccessResponse } from "redux/mentalHealth/downloadTypePDF/downloadTypePDFStates";
import { lastSendNotificationTimeResponse } from "redux/mentalHealth/lastNotificationTime/lastNotificationTimeStates";
import { mentalClassListDataResponse } from "redux/mentalHealth/classList/classListStates";
import {
  selectIsSaved,
  selectSendErrorMessage,
  sendInterviewRequestDataResponse,
} from "redux/mentalHealth/sendInterviewRequest/sendInterviewRequestStates";

import {
  PaginationWrapper,
  ContentWrapper,
  SituationTableStyled,
  WrapperSizeChange,
  TextTitle,
  DownloadButtonsContainer,
  TransmissionTitleContainer,
  WapperSelectTimeForItem,
  CheckboxBlock,
  CheckboxItemWrapper,
  PaginationContainer,
  NotifyWrapper,
  CheckboxText,
  FiltersWrapper,
  TransmissionTitleContainerReverse,
  NameGroupWrapper,
} from "./MentalHealth.style";
import ConfirmInterviewModal from "./ConfirmInterviewModal";
import MentalHealthSituationDetails from "./MentalHealthSituationDetails";

type ScreenStatus = "details" | "list" | "";

interface IDepartment {
  id: number;
  class_name: string;
}

interface RequestOptions extends IOption {
  department_id: number;
}

const { useBreakpoint } = Grid;

const ImplementStatus: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "mental-health",
  });
  const { t: t4 }: any = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });
  const { t: t5 }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t6 }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });

  const implementType: any = {
    "/elementary-25-questions": {
      typeID: 1,
      title: t5("implemented-status-25-elementary"),
      fileName: "25_Elementary",
    },
    "/elementary-13-questions": {
      typeID: 2,
      title: t5("implemented-status-13-elementary"),
      fileName: "13_Elementary",
    },
    "/junior-25-questions": {
      typeID: 3,
      title: t5("implemented-status-25-junior"),
      fileName: "25_Junior",
    },
    "/junior-13-questions": {
      typeID: 4,
      title: t5("implemented-status-13-junior"),
      fileName: "13_Junior",
    },
  };

  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const screens = useBreakpoint();
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const ClassDatas = useSelector(mentalClassListDataResponse);
  const MentalCheckRequestsData = useSelector(mentalCheckRequestsDataResponse);
  const MentalCheckTypeInformation = useSelector(
    mentalCheckTypeInfoDataResponse
  );
  const TypeInformationError = useSelector(selectErrorMessage);
  const LastNotificationTimeData = useSelector(
    lastSendNotificationTimeResponse
  );
  const csvDownloadSuccess = useSelector(csvDownloadSuccessResponse);
  const pdfDownloadSuccess = useSelector(pdfDownloadSuccessResponse);
  const sendInterviewRequestSuccess = useSelector(selectIsSaved);
  const sendInterviewRequestError = useSelector(selectSendErrorMessage);
  const sendInterviewRequestResponse = useSelector(
    sendInterviewRequestDataResponse
  );

  const [screenStatus, setScreenStatus] = useState<ScreenStatus>("list");
  const [classOptions, setClassOptions] = useState<IOption[]>([]);
  const [requestOptions, setRequestOptions] = useState<RequestOptions[]>([]);
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [selectValue, setSelectValue] = useState<any>();
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [checkFilters, setCheckFilters] = useState<any>({
    not_implemented: false,
    be_careful: false,
  });
  const [confirmModalVisible, setConfirmModalVisible] =
    useState<boolean>(false);
  const [input, setInput] = useState({
    limit: 50,
    page: 1,
  });
  const [data, setData] = useState<any>({
    currentPageData: [],
    list: [],
    checkUsers: [],
    users: [],
    total: 0,
  });
  const [checkBoxList, setCheckBoxList] = useState<any>([]);
  const [sendUserList, setSendUserList] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [sendAllNonUsers, setSendAllNonUsers] = useState<boolean>(false);
  const [confirmSendInterview, setConfirmSendInterview] =
    useState<boolean>(false);
  const [requestPatientId, setRequestPatientId] = useState<any>();

  const handleCancel = () => {
    setConfirmModalVisible(false);
    setSendAllNonUsers(false);
    setSendUserList([]);
    setCheckBoxList([]);
  };

  const handleReturn = () => {
    setScreenStatus("list");
    setConfirmModalVisible(false);
  };

  const returnStatus = (status: string) => {
    switch (status) {
      case "未実施":
        return "#B7B7B7";
      case "配慮":
        return "#EBC300";
    }
  };

  const returnFilterCondition = () => {
    if (checkFilters.not_implemented && checkFilters.be_careful) {
      return [1, 2].join(",");
    } else if (checkFilters.not_implemented && !checkFilters.be_careful) {
      return 1;
    } else if (!checkFilters.not_implemented && checkFilters.be_careful) {
      return 2;
    } else {
      return "";
    }
  };

  const onChangeCheckAll = (e: any) => {
    setIsCheckAll(e.target.checked);
    if (e.target.checked) {
      setCheckBoxList(
        data.checkUsers
          .filter((item: any) => item.is_attended === false)
          .map((i: any) => {
            return {
              patient_id: i.patient_id,
              full_name: i.full_name,
            };
          })
      );
    } else {
      setCheckBoxList([]);
    }
  };

  const onChangeCheckbox = (id: string, name: string) => (e: any) => {
    if (e.target.checked) {
      //add item
      setCheckBoxList((prevState: any) => [
        ...prevState,
        {
          patient_id: id,
          full_name: name,
        },
      ]);
    } else {
      //remove item
      setCheckBoxList(
        checkBoxList.filter((item: any) => item.patient_id !== id)
      );
    }
  };

  const handleShow = () => {
    if (
      location.pathname === "/elementary-25-questions" ||
      location.pathname === "/junior-25-questions"
    ) {
      return true;
    }
    return false;
  };

  const renderSuicideIcon = (alarm: any) => {
    switch (alarm) {
      case 1:
        return <SuicideWarningIconYellow />;
      case 2:
        return <SuicideWarningIconRed />;
      default:
        return;
    }
  };

  const columns = [
    {
      title: () => {
        return (
          <CheckboxText>
            <CheckboxStyled checked={isCheckAll} onChange={onChangeCheckAll} />
            <div>{t("full-name")}</div>
          </CheckboxText>
        );
      },
      dataIndex: "full_name",
      key: "full_name",
      width: 268,
      fixed: window.innerWidth < 576 ? "" : "left",
      show: true,
      render: (text: string, record: any) => {
        return (
          <NameGroupWrapper>
            <CheckboxText>
              {record?.mental_condition === "未実施" ? (
                <CheckboxStyled
                  checked={checkBoxList.some(
                    (item: any) => item.patient_id === record.patient_id
                  )}
                  onChange={onChangeCheckbox(
                    record.patient_id,
                    record.full_name
                  )}
                />
              ) : (
                <div style={{ width: 20, height: 20 }}></div>
              )}
              <div style={{ maxWidth: 168, lineHeight: "21px", minWidth: 102 }}>
                {text}
              </div>
            </CheckboxText>
            {record?.send_interview_request && (
              <div className="button-group">
                <div
                  className="badge"
                  style={{ width: "max-content", minWidth: "100%" }}
                >
                  {t2("request-interview")}
                </div>
                <div
                  className="button"
                  onClick={() => {
                    setConfirmSendInterview(true);
                    setRequestPatientId(
                      record?.mental_check_request_patient_id
                    );
                  }}
                  style={{ width: "max-content" }}
                >
                  <CheckedIcon fill="#2AC769" />
                  {t2("complete")}
                </div>
              </div>
            )}
          </NameGroupWrapper>
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            {t1("grade-1")}
            <div>{t1("grade-2")}</div>
          </div>
        );
      },
      dataIndex: "grade",
      key: "grade",
      width: 98,
      show: true,
    },
    {
      title: t2("implementation-date"),
      dataIndex: "date_of_implementation",
      key: "date_of_implementation",
      width: 122,
      show: true,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: () => {
        return (
          <div>
            {t2("interview-1")}
            <div>{t2("interview-2")}</div>
          </div>
        );
      },
      dataIndex: "consult_status",
      key: "consult_status",
      width: 98,
      show: true,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t2("mental-physical-condition"),
      dataIndex: "mental_condition",
      key: "mental_condition",
      width: 106,
      show: true,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              color: returnStatus(record?.mental_condition),
              fontWeight:
                record?.mental_condition === "配慮" ? "bold" : "normal",
            }}
          >
            {record?.mental_condition}
          </div>
        );
      },
    },
    {
      title: () => {
        return <div style={{ fontWeight: 700 }}>{t2("feeling-mood")}</div>;
      },
      dataIndex: "feelings",
      key: "feelings",
      width: 90,
      show: true,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              color: record?.feelings >= 10 ? "#EBC300" : "#3B3B3B",
              fontWeight: record?.feelings >= 10 ? "bold" : "normal",
              display: "flex",
              gap: 4,
              alignItems: "center",
            }}
          >
            {record?.mental_condition === "未実施" ? "" : text}
            {record?.mental_condition === "未実施"
              ? ""
              : renderSuicideIcon(record?.suicide_alarm)}
          </div>
        );
      },
    },
    {
      title: () => {
        return <div style={{ fontWeight: 700 }}>{t2("concentration")}</div>;
      },
      dataIndex: "concentrations",
      key: "concentrations",
      width: 90,
      show: handleShow(),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              color: record?.concentrations >= 4 ? "#EBC300" : "#3B3B3B",
              fontWeight: record?.concentrations >= 4 ? "bold" : "normal",
            }}
          >
            {record?.mental_condition === "未実施" ? "" : text}
          </div>
        );
      },
    },
    {
      title: () => {
        return <div style={{ fontWeight: 700 }}>{t2("action")}</div>;
      },
      dataIndex: "behaviour",
      key: "behaviour",
      width: 74,
      show: handleShow(),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              color: record?.behaviour >= 4 ? "#EBC300" : "#3B3B3B",
              fontWeight: record?.behaviour >= 4 ? "bold" : "normal",
            }}
          >
            {record?.mental_condition === "未実施" ? "" : text}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div style={{ fontWeight: 700 }}>
            {t2("human")}
            <div>{t2("relationship")}</div>
          </div>
        );
      },
      dataIndex: "relationship",
      key: "relationship",
      width: 81,
      show: handleShow(),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              color: record?.relationship >= 4 ? "#EBC300" : "#3B3B3B",
              fontWeight: record?.relationship >= 4 ? "bold" : "normal",
            }}
          >
            {record?.mental_condition === "未実施" ? "" : text}
          </div>
        );
      },
    },
    {
      title: t("gender"),
      dataIndex: "gender",
      key: "gender",
      show: true,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 162,
      show: true,
      render: (record: any) =>
        record?.mental_condition === "未実施" ? (
          <div style={{ height: 30 }}></div>
        ) : (
          <Button
            color="#FFFFFF"
            fontSize={16}
            fontWeight={700}
            lineHeight="23px"
            background="#2AC769"
            padding="4px 16px 3px"
            name={t2("scoring-information")}
            bdr="6px"
            border="none"
            onClick={() => {
              dispatch(
                GetScoringInformationActionCreators.getMentalCheckScoringAction(
                  {
                    mental_check_type_id:
                      implementType[location.pathname].typeID,
                    patient_id: record.patient_id,
                    mental_check_request_id: selectedRequest,
                  }
                )
              );
              setScreenStatus("details");
            }}
          />
        ),
    },
  ].filter((item: any) => item.show);

  const getMentalTypeInformation = () => {
    const paramsMentalTypeInfo: TypeInfoData = {
      page: input.page,
      per_page: input.limit,
      mental_check_type_id: implementType[location.pathname].typeID,
      mental_check_request_id: selectedRequest || "",
      refine_criteria_type: returnFilterCondition(),
      grade: selectValue || "",
    };
    dispatch(
      mentalCheckTypeInfoActionCreators.mentalCheckTypeInfoAction(
        paramsMentalTypeInfo
      )
    );
  };

  const handleTypeInfoDownloadFile = (type: string) => {
    const params: ITypeInfoDownloadData = {
      mental_check_type_id: implementType[location.pathname].typeID,
      mental_check_request_id: selectedRequest || "",
      refine_criteria_type: returnFilterCondition(),
      grade: selectValue || "",
    };
    if (type === "csv") {
      dispatch(
        downloadTypeInfoCSVActionCreators.csvDownloadTypeInfoAction(params)
      );
    } else {
      dispatch(
        downloadTypeInfoPDFActionCreators.pdfDownloadTypeInfoAction(params)
      );
    }
  };

  const handleSendInterviewRequest = () => {
    if (requestPatientId) {
      const params: SendInterviewRequestPayload = {
        mental_check_request_patient_id: requestPatientId,
        consult_flag: 2,
      };

      dispatch(
        sendInterviewRequestActionCreators.sendInterviewRequestAction(params)
      );
    }
    setConfirmSendInterview(false);
  };

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  const handleOnChangeSizeChange = (limit: number) => {
    setInput((prevState: any) => ({ ...prevState, limit, page: 1 }));
  };

  const onChangeSelect = (value: any) => {
    setSelectValue(value);
  };

  const onChangeSelectRequest = (value: any) => {
    setSelectedRequest(value);
  };

  useEffect(() => {
    if (data.list.length) {
      const numberUserNonSend = data.checkUsers.filter(
        (item: any) => item.is_attended === false
      ).length;
      //if check all, set true, else false
      if (checkBoxList.length === numberUserNonSend) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    }
  }, [checkBoxList]);

  useEffect(() => {
    if (
      MentalCheckTypeInformation &&
      Object.keys(MentalCheckTypeInformation).length
      // Object.keys(MentalCheckTypeInformation?.result).length
    ) {
      setData({
        //   currentPageData: list.slice(0, 5),
        list: MentalCheckTypeInformation?.result?.mental_check_details,
        total: MentalCheckTypeInformation?.total_record,
        users: MentalCheckTypeInformation?.result?.user_detail,
        checkUsers: MentalCheckTypeInformation?.result?.mental_check_users,
      });
    }
  }, [MentalCheckTypeInformation]);

  useEffect(() => {
    if (
      MentalCheckTypeInformation &&
      Object.keys(MentalCheckTypeInformation).length
    ) {
      let recordOptionsClone: IOption[] = [];
      recordOptionsClone = generateDynamicRecords(
        MentalCheckTypeInformation?.total_record,
        t
      );
      setRecordOptions([...recordOptionsClone]);
    }
  }, [MentalCheckTypeInformation]);

  useEffect(() => {
    if (ClassDatas) {
      const tempGrade: IOption[] = [{ id: -1, value: "", name: t6("all") }];
      ClassDatas?.result?.map((item: IDepartment) =>
        tempGrade.push({
          id: item.id,
          name: item.class_name,
          value: item.id,
        })
      );
      setClassOptions(tempGrade);
    }
  }, [ClassDatas]);

  useEffect(() => {
    if (selectedRequest) {
      const department_id = requestOptions.find(
        (item: any) => item.id === selectedRequest
      )?.department_id;
      dispatch(
        GetMentalClassListActionCreators.classListAction({
          parent_class_id: department_id,
        })
      );
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (MentalCheckRequestsData) {
      const tempRequests: RequestOptions[] = [];
      MentalCheckRequestsData?.result?.map((item: IRequest) =>
        tempRequests.push({
          id: item.mental_check_request_id,
          name: item.mental_check_request_name,
          value: item.mental_check_request_id,
          department_id: item.c_department_id,
        })
      );
      setRequestOptions(tempRequests);
    }
  }, [MentalCheckRequestsData]);

  useEffect(() => {
    if (selectedRequest) {
      getMentalTypeInformation();
    }
  }, [selectedRequest, input.limit, input.page]);

  useEffect(() => {
    if (pdfDownloadSuccess && pdfDownloadSuccess.result) {
      const headers = pdfDownloadSuccess.result.headers.map((header: any) => {
        return header.label;
      });
      const data: any = pdfDownloadSuccess.result.data
        .map((item: any) => {
          return {
            ...item,
            mental_condition: item.mental_condition,
          };
        })
        .map((d: any) => {
          return Object.values(d);
        });

      const doc = new jsPDF();
      doc.addFileToVFS("mplus.ttf", mplusFont);
      doc.addFont("mplus.ttf", "mplus", "normal");
      doc.setFont("mplus", "normal");

      const myTable = {
        head: [[...headers]],
        body: data,
        headStyles: {
          font: "mplus",
        },
        bodyStyles: {
          font: "mplus",
        },
        margin: { top: 15, left: 8, right: 8 },
      };

      autoTable(doc, myTable);
      const filename = `${convertFilename(
        implementType[location.pathname].fileName,
        new Date()
      )}.pdf`;
      doc.save(filename);
      dispatch(downloadTypeInfoPDFActionCreators.handleResetDataAction());
    }
  }, [pdfDownloadSuccess]);

  useEffect(() => {
    if (csvDownloadSuccess && csvDownloadSuccess.result) {
      const filename = `${convertFilename(
        implementType[location.pathname].fileName,
        new Date()
      )}.csv`;
      generateCSV(
        filename,
        csvDownloadSuccess.result.headers,
        csvDownloadSuccess.result.data.map((item: any) => {
          return {
            ...item,
            mental_condition: item.mental_condition,
          };
        })
      );
      dispatch(downloadTypeInfoCSVActionCreators.handleResetDataAction());
    }
  }, [csvDownloadSuccess]);

  useEffect(() => {
    if (selectedRequest) {
      dispatch(
        GetLastNotificationTimeActionCreators.getLastNotificationTimeAction({
          mental_check_request_id: selectedRequest,
        })
      );
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (selectedRequest && TypeInformationError) {
      notification.error({
        placement: "bottomRight",
        message: TypeInformationError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(mentalCheckTypeInfoActionCreators.handleResetDataAction());
      setData({
        currentPageData: [],
        list: [],
        users: [],
        checkUsers: [],
        total: 0,
      });
    }
  }, [TypeInformationError]);

  useEffect(() => {
    if (sendInterviewRequestSuccess && sendInterviewRequestResponse) {
      notification.success({
        placement: "bottomRight",
        message: sendInterviewRequestResponse?.message,
        icon: <SendCompleteIcon />,
      });
      dispatch(sendInterviewRequestActionCreators.handleResetDataAction());
      getMentalTypeInformation();
    }
  }, [sendInterviewRequestSuccess, sendInterviewRequestResponse]);

  useEffect(() => {
    if (sendInterviewRequestError) {
      notification.error({
        placement: "bottomRight",
        message: sendInterviewRequestError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(sendInterviewRequestActionCreators.handleResetDataAction());
    }
  }, [sendInterviewRequestError]);

  useEffect(() => {
    dispatch(GetScoringInformationActionCreators.handleResetAction());
    dispatch(
      mentalCheckRequestsActionCreators.mentalCheckRequestsAction({
        mental_check_type_id: implementType[location.pathname].typeID,
      })
    );
    dispatch(mentalCheckTypeInfoActionCreators.handleResetDataAction());
    dispatch(GetLastNotificationTimeActionCreators.handleResetDataAction());
    dispatch(sendInterviewRequestActionCreators.handleResetDataAction());
    setClassOptions([]);
    setRecordOptions([]);
    setSelectValue(undefined);
    setSelectedRequest(undefined);
    setIsCheckAll(false);
    setSendAllNonUsers(false);
    setCheckBoxList([]);
    setSendUserList([]);
    setRequestPatientId(undefined);
    setRequestOptions([]);
  }, []);

  useEffect(() => {
    setData({
      currentPageData: [],
      list: [],
      users: [],
      checkUsers: [],
      total: 0,
    });
  }, [location.pathname]);

  const DownloadMenu = () => (
    <DownloadButtonsContainer>
      <Button
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="currentColor"
            style={{ position: "absolute", left: 4, top: 4 }}
          />
        }
        name={t("excel-output")}
        background="#2AC769"
        color="#FFFFFF"
        border="none"
        fontSize={12}
        lineHeight="17px"
        fontWeight={700}
        padding="3px 8px 3px 14px"
        onClick={() => handleTypeInfoDownloadFile("csv")}
      />
      <Button
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="currentColor"
            style={{ position: "absolute", left: 4, top: 4 }}
          />
        }
        name={t("pdf-output")}
        background="#2AC769"
        color="#FFFFFF"
        border="none"
        fontSize={12}
        lineHeight="17px"
        fontWeight={700}
        padding="3px 8px 3px 14px"
        onClick={() => handleTypeInfoDownloadFile("pdf")}
      />
    </DownloadButtonsContainer>
  );

  const ActionSection = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 8px 9px",
        }}
      >
        <NotifyWrapper>
          <DownArrow />
          <div className="notify-title">{t2("checked-user")}</div>
          <div className="button-block">
            <Button
              color="#FFFFFF"
              fontSize={12}
              fontWeight={700}
              lineHeight="12px"
              background="#2AC769"
              padding="6px 12px 5px"
              name={t2("send-a-reminder")}
              bdr="6px"
              border="none"
              disabled={!checkBoxList.length}
              onClick={() => {
                setConfirmModalVisible(true);
              }}
            />
          </div>
        </NotifyWrapper>
        {!screens.xs && <DownloadMenu />}
      </div>
    );
  };

  const ShowPagination = () => {
    return data.total > input.limit ? (
      <PaginationWrapper>
        <Pagination
          current={input.page}
          onChange={handleChangePage}
          pageSize={input.limit}
          total={data.total}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </PaginationWrapper>
    ) : (
      <Fragment></Fragment>
    );
  };

  const renderContents = () => {
    switch (screenStatus) {
      case "list":
        return (
          <Box
            title={implementType[location.pathname].title}
            padding="24px 16px 32px"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 16,
              }}
            >
              <TransmissionTitleContainer>
                <div
                  style={{
                    fontSize: 18,
                    lineHeight: "18px",
                    fontWeight: 700,
                  }}
                >
                  {t2("selection-of-examination")}
                </div>
                <Select
                  placeholder={t2("select-test")}
                  width="278px"
                  value={selectedRequest}
                  options={requestOptions}
                  onChange={onChangeSelectRequest}
                />
              </TransmissionTitleContainer>
              {!screens.xs && (
                <TransmissionTitleContainerReverse>
                  <div>
                    {t2("last-reminder-time")}
                    {LastNotificationTimeData?.result
                      ?.last_reminder_notification_at &&
                      `${handleConvertDateYear(
                        moment(
                          LastNotificationTimeData?.result
                            ?.last_reminder_notification_at
                        ).format("YYYY-MM-DD"),
                        currentLanguage
                      )} ${moment(
                        LastNotificationTimeData?.result
                          ?.last_reminder_notification_at
                      ).format("HH:MM")}`}
                  </div>
                  <Button
                    color="#FFFFFF"
                    fontSize={14}
                    fontWeight={700}
                    lineHeight="14px"
                    background={"#666666"}
                    padding="8px 16px 7px"
                    name={t2("send-implementation-reminder-all")}
                    bdr="6px"
                    border="none"
                    disabled={
                      !MentalCheckTypeInformation?.result?.mental_check_details
                        ?.length
                    }
                    onClick={() => {
                      setConfirmModalVisible(true);
                      setSendAllNonUsers(true);
                      setSendUserList(
                        data.users
                          .filter((item: any) => item.is_attended === false)
                          .map((i: any) => {
                            return {
                              patient_id: i.id,
                              full_name: i.name,
                            };
                          })
                      );
                    }}
                  />
                </TransmissionTitleContainerReverse>
              )}
            </div>
            <FiltersWrapper>
              <div className="filter-block">
                <div>
                  <TextTitle style={{ marginBottom: 16 }}>
                    {t2("filter-condition")}
                  </TextTitle>
                  <CheckboxBlock>
                    <div className="left">
                      <CheckboxItemWrapper>
                        <CheckboxStyled
                          checked={checkFilters.not_implemented}
                          onChange={() =>
                            setCheckFilters({
                              ...checkFilters,
                              not_implemented: !checkFilters.not_implemented,
                            })
                          }
                        />
                        <div>{t1("not-attempt")}</div>
                      </CheckboxItemWrapper>
                      <CheckboxItemWrapper>
                        <CheckboxStyled
                          checked={checkFilters.be_careful}
                          onChange={() =>
                            setCheckFilters({
                              ...checkFilters,
                              be_careful: !checkFilters.be_careful,
                            })
                          }
                        />
                        <div>{t2("be-careful")}</div>
                      </CheckboxItemWrapper>
                    </div>
                  </CheckboxBlock>
                </div>

                <div className="button-block">
                  <WapperSelectTimeForItem>
                    <TextTitle style={{ marginBottom: 10 }}>
                      {t("class")}
                    </TextTitle>
                    <div className="bor-div-time">
                      <div className="dleft">
                        <Select
                          value={selectValue}
                          options={classOptions}
                          onChange={onChangeSelect}
                          width={"140px"}
                          placeholder={t2("select-class")}
                          height="30px"
                        />
                      </div>
                    </div>
                  </WapperSelectTimeForItem>
                  {!screens.xs && (
                    <Button
                      color="#FFFFFF"
                      fontSize={16}
                      fontWeight={700}
                      lineHeight="23px"
                      background={"#2AC769"}
                      padding="4px 34px 3px"
                      name={t("search")}
                      bdr="6px"
                      border="none"
                      onClick={getMentalTypeInformation}
                    />
                  )}
                </div>
              </div>

              <div className="third-block">
                <Button
                  color="#FFFFFF"
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="23px"
                  background={"#2AC769"}
                  padding="4px 34px 3px"
                  name={t("search")}
                  bdr="6px"
                  border="none"
                  onClick={getMentalTypeInformation}
                />
              </div>
            </FiltersWrapper>
            {screens.xs && (
              <TransmissionTitleContainerReverse>
                <div style={{ textAlign: "center" }}>
                  {t2("last-reminder-time")}
                  {LastNotificationTimeData?.result
                    ?.last_reminder_notification_at &&
                    `${handleConvertDateYear(
                      moment(
                        LastNotificationTimeData?.result
                          ?.last_reminder_notification_at
                      ).format("YYYY-MM-DD"),
                      currentLanguage
                    )} ${moment(
                      LastNotificationTimeData?.result
                        ?.last_reminder_notification_at
                    ).format("HH:MM")}`}
                </div>
                <Button
                  color="#FFFFFF"
                  fontSize={14}
                  fontWeight={700}
                  lineHeight="14px"
                  background={"#666666"}
                  padding="8px 16px 7px"
                  name={t2("send-implementation-reminder-all")}
                  bdr="6px"
                  border="none"
                />
              </TransmissionTitleContainerReverse>
            )}
            <PaginationContainer
              style={{
                alignItems: "flex-start",
              }}
            >
              <WrapperSizeChange>
                <div style={{ fontWeight: 500 }}>{t("record")}</div>
                <Select
                  defaultValue={50}
                  options={recordOptions}
                  onChange={handleOnChangeSizeChange}
                />
              </WrapperSizeChange>
              <ShowPagination />
            </PaginationContainer>
            {screens.xs && (
              <div style={{ marginBottom: 24 }}>
                <DownloadMenu />
              </div>
            )}
            <ContentWrapper>
              <SituationTableStyled
                dataSource={data.list}
                columns={columns}
                actionSection={<ActionSection />}
                scroll={{ x: "max-content" }}
                loading={isLoading}
              />
              {!MentalCheckTypeInformation?.result?.mental_check_details
                ?.length && (
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: "26px",
                    marginTop: 32,
                  }}
                >
                  <div>{t4("no-data-reflection-list")}</div>
                </div>
              )}
            </ContentWrapper>
            <ShowPagination />
            {confirmModalVisible && (
              <ConfirmInterviewModal
                isVisible={confirmModalVisible}
                handleCancel={handleCancel}
                checkBoxList={sendAllNonUsers ? sendUserList : checkBoxList}
                setCheckBoxList={
                  sendAllNonUsers ? setSendUserList : setCheckBoxList
                }
                isCheckAll={sendAllNonUsers ? sendAllNonUsers : isCheckAll}
                selectedRequest={selectedRequest}
              />
            )}
            {confirmSendInterview && (
              <ConfirmModal
                isVisible={confirmSendInterview}
                handleCancel={() => setConfirmSendInterview(false)}
                handleOk={handleSendInterviewRequest}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: "20px",
                  }}
                >
                  {t2("complete-interview")}
                </div>
              </ConfirmModal>
            )}
          </Box>
        );
      case "details":
        return <MentalHealthSituationDetails handleCancel={handleReturn} />;
      default:
        return;
    }
  };

  return (
    <>
      <ScrollToTop />
      {renderContents()}
    </>
  );
};

export default ImplementStatus;
