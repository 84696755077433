import { IResponse } from "models";

export enum ActionType {
  CREATE_QUESTIONNAIRES = "action/CREATE_QUESTIONNAIRES",
  CREATE_QUESTIONNAIRES_SUCCESS = "action/CREATE_QUESTIONNAIRES_SUCCESS",
  CREATE_QUESTIONNAIRES_ERROR = "action/CREATE_QUESTIONNAIRES_ERROR",
  CREATE_QUESTIONNAIRES_RESET = "action/CREATE_QUESTIONNAIRES_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

interface IQuestionnaireAnswerAttributes {
  answer_text: string;
}

interface IQuestionnaireAttributes {
  answer_type: string;
  question_content: string;
  question_summary: string;
  is_free_text: boolean;
  is_required: boolean;
  question_position: number;
  questionnaire_answers_attributes?: IQuestionnaireAnswerAttributes[];
}

interface IQuestionnaire {
  title?: string;
  explanation?: string;
  questionnaire_questions_attributes: IQuestionnaireAttributes[];
}

export interface ICreateQuestionnairesPayload {
  questionnaire: IQuestionnaire;
}

export interface CreateQuestionnairesResponse extends IResponse {
  result: {
    questionnaire_id: number;
  };
}
