import { IResponse } from "models";

export enum ActionType {
  // get class list school
  GET_CLASS_LIST_SCHOOL = "action/GET_CLASS_LIST_SCHOOL",
  GET_CLASS_LIST_SCHOOL_SUCCESS = "action/GET_CLASS_LIST_SCHOOL_SUCCESS",
  GET_CLASS_LIST_SCHOOL_ERROR = "action/GET_CLASS_LIST_SCHOOL_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGetSchoolClassResponse extends IResponse {
  result: ISchoolClasses[];
}

export interface ISchoolClasses {
  id: any;
  name: string;
  sub_departments: IChildrenData[];
  delete_flag?: boolean;
  level?: string;
}

export interface IChildrenData {
  name: string;
  id: any;
  sub_departments: IChildrenData[];
  delete_flag?: boolean;
}
