import { ICreateActivityData } from "models/activitymanagement/createActivity";
import { IDeleteActivityData } from "models/activitymanagement/deleteActivity";
import { IUpdateActivityData } from "models/activitymanagement/updateActivity";
import { createApiCall2, MethodType, URI } from "../Api";

export const fetchActivitesList = () => {
  return createApiCall2(MethodType.GET, URI.ACTIVITY_MANAGEMENT.GET_ACTIVITY);
};

export const createActivity = (payload: ICreateActivityData) => {
  return createApiCall2(
    MethodType.POST,
    URI.ACTIVITY_MANAGEMENT.CREATE_ACTIVITY,
    payload
  );
};

export const updateActivity = (payload: IUpdateActivityData) => {
  return createApiCall2(
    MethodType.PUT,
    URI.ACTIVITY_MANAGEMENT.UPDATE_ACTIVITY,
    payload
  );
};

export const deleteActivity = (payload: IDeleteActivityData) => {
  return createApiCall2(
    MethodType.DELETE,
    URI.ACTIVITY_MANAGEMENT.DELETE_ACTIVITY,
    payload
  );
};
