import {
  Action,
  ActionType,
  IUpdateChartOrderResponse,
} from "models/setting/updateChartOrder";
import createReducer from "../../createReducer";

export interface UpdateChartOrderReducerType {
  loading: boolean;
  error?: string;
  updateChartOrderResponse?: IUpdateChartOrderResponse;
}

const defaultState: UpdateChartOrderReducerType = {
  loading: false,
  error: undefined,
  updateChartOrderResponse: undefined,
};

export const updateChartOrderReducer =
  createReducer<UpdateChartOrderReducerType>(defaultState, {
    [ActionType.UPDATE_CHART_ORDER](state: UpdateChartOrderReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_CHART_ORDER_ERROR](
      state: UpdateChartOrderReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_CHART_ORDER_SUCCESS](
      state: UpdateChartOrderReducerType,
      action: Action<IUpdateChartOrderResponse>
    ) {
      return {
        ...state,
        loading: false,
        updateChartOrderResponse: action.payload,
      };
    },

    [ActionType.UPDATE_CHART_ORDER_RESET](state: UpdateChartOrderReducerType) {
      return {
        ...state,
        loading: false,
        error: undefined,
        updateChartOrderResponse: undefined,
      };
    },
  });
