import { IResponse } from "models";

export enum ActionType {
  HEALTH_CHECK_QUESTION = "action/HEALTH_CHECK_QUESTION",
  HEALTH_CHECK_QUESTION_SUCCESS = "action/HEALTH_CHECK_QUESTION_SUCCESS",
  HEALTH_CHECK_QUESTION_ERROR = "action/HEALTH_CHECK_QUESTION_ERROR",
  HEALTH_CHECK_QUESTION_RESET = "action/HEALTH_CHECK_QUESTION_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface HealthCheckQuestionParams {
  is_all?: boolean;
  app_language_id?: string;
}

export interface HealthCheckQuestionResponse extends IResponse {
  questions: any;
}
