import { ActionType, IGraphDataResponse } from "models/vaccine/graph";

export const getGraphDataAction = (payload: { date: string }) => {
  return {
    type: ActionType.VACCINE_RECORD_GRAPH,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.VACCINE_RECORD_GRAPH_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IGraphDataResponse) => {
  return {
    type: ActionType.VACCINE_RECORD_GRAPH_SUCCESS,
    payload,
  };
};
