import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/activitymanagement/updateActivity";
import { httpStatus } from "configs/httpStatus";
import { updateActivity } from "services/activitymanagement";
import { handleSuccesAction, handleErrorAction } from "./updateActivityActions";
import {
  IUpdateActivityData,
  IUpdateActivityResponse,
} from "models/activitymanagement/updateActivity";

function* updateActivitySaga({ payload }: { payload: IUpdateActivityData }) {
  try {
    const response: IUpdateActivityResponse = yield call(
      updateActivity,
      payload
    );
    if (
      response.status === httpStatus.StatusCreated ||
      response.status === httpStatus.StatusOK
    ) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateActivityWatcher() {
  yield takeLatest(ActionType.UPDATE_ACTIVITY as any, updateActivitySaga);
}

export default [fork(onUpdateActivityWatcher)];
