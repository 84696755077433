export enum ActionType {
  // creatte user
  UPDATE_MENTAL_CHECK = "action/UPDATE_MENTAL_CHECK",
  UPDATE_MENTAL_CHECK_SUCCESS = "action/UPDATE_MENTAL_CHECK_SUCCESS",
  UPDATE_MENTAL_CHECK_ERROR = "action/UPDATE_MENTAL_CHECK_ERROR",
  RESET_UPDATE_MENTAL_CHECK = "action/RESET_UPDATE_MENTAL_CHECK",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface UpdateMentalCheckData {
  id?: number;
  mental_check_type_id: number;
  test_name: string;
  start_time: string;
  end_time: string;
  grade: any;
  temp_save_to_permant_save?: string;
}
