import React, { useState, useEffect, useRef } from "react";
import { Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { CloseIcon, DownloadIcon, NoDataIcon } from "icons";
import { ProgressBar, Loading } from "components";
import { numberWithCommas, generateImg } from "utils";
import { ProgressActionCreators } from "redux/rootActions";
import { progressDataResponse } from "redux/dashboard/progress/progressStates";
import { selectIsLoading } from "redux/dashboard/progress/progressStates";

import {
  BoxHeader,
  BoxTitle,
  BoxContent,
  BoxItem,
  LightDivider,
  Top,
  Middle,
  Bottom,
  NotImplementText,
  NoHeatText,
  FeverText,
  HighFeverText,
  WrapperLoadingChart,
  WrapperCloseIcon,
  WrapperNoData,
} from "../Dashboard.style";

const LoadingComponent = ({ isNoData }: { isNoData: boolean }) => {
  return !isNoData ? (
    <WrapperLoadingChart>
      <div className="container">
        <Loading width={70} height={70} />
      </div>
    </WrapperLoadingChart>
  ) : (
    <div>No Data</div>
  );
};

const Statistical = ({ handleCloseChart }: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });

  const imgRef = useRef<any>();

  const dispatch = useDispatch();
  const progressData = useSelector(progressDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const [isNoData, setIsNoData] = useState(false);
  const [data, setData] = useState<any>({});

  const handleNumberPercentage = (num: number) => {
    if (!num || num === 100 || num.toString().length <= 2) {
      return `${num}.0`;
    }
    return num.toString().match(/^-?\d+(?:\.\d{0,1})?/);
  };

  useEffect(() => {
    setInterval(() => {
      setIsNoData(true);
    }, 15000);
  }, []);

  useEffect(() => {
    dispatch(
      ProgressActionCreators.getProgressAction({
        aggregate_date: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
  }, []);

  useEffect(() => {
    if (progressData && Object.keys(progressData).length) {
      setData(progressData.progress);
    }
  }, [progressData]);

  return (
    // <Col xs={24} md={24} xl={12} >
    <BoxItem ref={imgRef}>
      <WrapperCloseIcon>
        <CloseIcon
          fill="#2F8CAE"
          height="14px"
          width="14px"
          onClick={() => handleCloseChart("4")}
        />
      </WrapperCloseIcon>
      <BoxHeader>
        <BoxTitle>{t("number-people-attend-body-temperature-check")}</BoxTitle>
        <DownloadIcon
          fill="#3B3B3B"
          style={{ marginTop: 4, cursor: "pointer" }}
          onClick={(event: any) => {
            if (!event.detail || event.detail == 1) {
              generateImg("people-attend", imgRef.current);
            }
          }}
        />
      </BoxHeader>
      <LightDivider />
      {!isLoading ? (
        Object.keys(data).length > 0 ? (
          <BoxContent>
            <Top>
              <p>{t("all-day")}</p>
              <div>
                <ProgressBar
                  implementedTotal={numberWithCommas(
                    data?.attended_patients?.total?.amount
                  )}
                  total={numberWithCommas(data?.total_patients)}
                  completed={data?.attended_patients?.total?.rate}
                  bg="#2A98C7"
                />
              </div>
              <p>
                {t("implementation-rate")}
                {handleNumberPercentage(data?.attended_patients?.total?.rate)}%
              </p>
            </Top>
            <Middle>
              <div>
                <span>
                  {t("morning")}
                  {numberWithCommas(data?.attended_patients?.am?.amount)}
                </span>
                /{numberWithCommas(data?.total_patients)}（
                {handleNumberPercentage(data?.attended_patients?.am?.rate)}%）
              </div>
              <div>
                <span>
                  {t("afternoon")}
                  {numberWithCommas(data?.attended_patients?.pm?.amount)}
                </span>
                /{numberWithCommas(data?.total_patients)}（
                {handleNumberPercentage(data?.attended_patients?.pm?.rate)}%）
              </div>
            </Middle>
            <Bottom>
              <div>
                <NotImplementText>{t("not-attempt")}</NotImplementText>
                <NotImplementText>
                  {numberWithCommas(data?.temperatures?.not_answered?.amount)}
                  {t("people")}
                </NotImplementText>
                <span>
                  (
                  {handleNumberPercentage(
                    data?.temperatures?.not_answered?.rate
                  )}
                  %)
                </span>
              </div>
              <div>
                <NoHeatText>{t("no-fever")}</NoHeatText>
                <NoHeatText>
                  {numberWithCommas(data?.temperatures?.normal?.amount)}
                  {t("people")}
                </NoHeatText>
                <span>
                  ({handleNumberPercentage(data?.temperatures?.normal?.rate)}
                  %)
                </span>
              </div>
              <div>
                <FeverText>{t("low-grade-fever")}</FeverText>
                <FeverText>
                  {numberWithCommas(data?.temperatures?.slight_fever?.amount)}
                  {t("people")}
                </FeverText>
                <span>
                  (
                  {handleNumberPercentage(
                    data?.temperatures?.slight_fever?.rate
                  )}
                  %)
                </span>
              </div>
              <div>
                <HighFeverText>{t("high-fever")}</HighFeverText>
                <HighFeverText>
                  {numberWithCommas(data?.temperatures?.high_fever?.amount)}
                  {t("people")}
                </HighFeverText>
                <span>
                  (
                  {handleNumberPercentage(data?.temperatures?.high_fever?.rate)}
                  %)
                </span>
              </div>
            </Bottom>
          </BoxContent>
        ) : (
          <WrapperNoData>
            <NoDataIcon width={140} height={140} />
          </WrapperNoData>
        )
      ) : (
        <LoadingComponent isNoData={isNoData} />
      )}
    </BoxItem>
    // </Col>
  );
};

export default Statistical;
