import {
  ActionType,
  IAllQuestionDownloadData,
  CSVDownloadAllQuestionResponse,
} from "models/questionnaires/downloadCSVAllQuestions";

export const downloadCSVAllQuestionsAction = (
  payload: IAllQuestionDownloadData
) => {
  return {
    type: ActionType.CSV_DOWNLOAD_ALL_QUESTION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CSV_DOWNLOAD_ALL_QUESTION_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: CSVDownloadAllQuestionResponse) => {
  return {
    type: ActionType.CSV_DOWNLOAD_ALL_QUESTION_SUCCESS,
    payload,
  };
};
export const handleResetDownloadCSVQuestionAction = () => {
  return {
    type: ActionType.CSV_DOWNLOAD_ALL_QUESTION_RESET_DATA,
  };
};
