import React from "react";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";

import { TextLink, TableStyled, RowItem } from "./ResourcesDownload.style";
import { downloadFunc, fileTypeIcon, ResourceType } from "./ResourcesDownload";

type ListViewProps = {
  resources: ResourceType[];
  selectedResources: ResourceType[];
  onChangeSelected: (_: React.Key[], selectedResources: ResourceType[]) => void;
  isLoading: boolean;
};

const ListView: React.FC<ListViewProps> = ({
  resources,
  selectedResources,
  onChangeSelected,
  isLoading,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "resources-download",
  });

  const rowSelection = {
    selectedRowKeys: [
      ...selectedResources.map((resource) => resource.key.toString()),
    ],
    onChange: onChangeSelected,
  };

  const columns: ColumnsType<ResourceType> = [
    {
      title: t("file-name"),
      dataIndex: "name",
      render: (_: string, resource: ResourceType) => (
        <RowItem>
          {fileTypeIcon(resource.mimeType)}
          <TextLink
            style={{ marginLeft: "8px" }}
            onClick={() => {
              downloadFunc(resource.downloadLink);
            }}
          >
            {resource.name}
          </TextLink>
        </RowItem>
      ),
    },
    {
      title: t("update-date"),
      dataIndex: "modifiedTime",
    },
    {
      title: t("resource-size"),
      dataIndex: "size",
    },
  ];

  return (
    <>
      <TableStyled
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={resources}
        scroll={{ x: 550 }}
        loading={isLoading}
      />
    </>
  );
};

export default ListView;
