import {
  ActionType,
  SendHealthCheckAnswersResponse,
  SendHealthCheckAnswersParams,
} from "models/bodyTemperature/sendHealthCheckAnswers";

export const sendHealthCheckAnswersAction = (
  payload: SendHealthCheckAnswersParams
) => {
  return {
    type: ActionType.SEND_HEALTH_CHECK_ANSWER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SEND_HEALTH_CHECK_ANSWER_ERROR,
    payload,
  };
};

export const handleSuccessAction = (
  payload: SendHealthCheckAnswersResponse
) => {
  return {
    type: ActionType.SEND_HEALTH_CHECK_ANSWER_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.SEND_HEALTH_CHECK_ANSWER_RESET,
  };
};
