import { put, takeLatest, fork, call } from "redux-saga/effects";
import { httpStatus } from "configs/httpStatus";
import { fetchClassList } from "services/classchangemanagement";
import { handleSuccesAction, handleErrorAction } from "./getClassListActions";
import {
  ActionType,
  IClassListData,
} from "models/classchangemanagement/getClassList";
import { IResponse } from "models";

function* getClassListSaga({ payload }: { payload: IClassListData }) {
  try {
    const response: IResponse = yield call(fetchClassList, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetClassListWatcher() {
  yield takeLatest(ActionType.GET_CLASS_LIST_END as any, getClassListSaga);
}

export default [fork(onGetClassListWatcher)];
