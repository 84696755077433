import {
  ActionType,
  IUpdateChartOrderResponse,
  IUpdateChartOrderPayload,
} from "models/setting/updateChartOrder";

export const updateChartOrderAction = (payload: IUpdateChartOrderPayload) => {
  return {
    type: ActionType.UPDATE_CHART_ORDER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_CHART_ORDER_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: IUpdateChartOrderResponse) => {
  return {
    type: ActionType.UPDATE_CHART_ORDER_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.UPDATE_CHART_ORDER_RESET,
  };
};
