import { IResponse } from "../../models";
import { ActionType, DeleteUserData } from "models/deleteuser";

export const deleteUserAction = (payload: DeleteUserData) => {
  return {
    type: ActionType.DELETE_USER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DELETE_USER_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.DELETE_USER_SUCCESS,
    payload,
  };
};
