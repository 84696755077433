import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  NewMentalCheckData,
  ActionType,
} from "models/mentalHealth/newMentalCheck";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { saveNewMentalCheck } from "services/mentalHealth";
import { handleSuccesAction, handleErrorAction } from "./newMentalCheckActions";

function* saveNewMentalCheckSaga({ payload }: { payload: NewMentalCheckData }) {
  try {
    const response: IResponse = yield call(saveNewMentalCheck, payload);

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSaveNewMentalCheckWatcher() {
  yield takeLatest(ActionType.NEW_MENTAL_CHECK as any, saveNewMentalCheckSaga);
}

export default [fork(onSaveNewMentalCheckWatcher)];
