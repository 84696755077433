import {
  ActionType,
  IQuestionHistoryData,
  IQuestionHistoryResponse,
} from "models/questionnaires/sendQuestionHistory";

export const sendQuestionHistoryListAction = (
  payload: IQuestionHistoryData
) => {
  return {
    type: ActionType.SEND_QUESTION_HISTORY_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SEND_QUESTION_HISTORY_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IQuestionHistoryResponse) => {
  return {
    type: ActionType.SEND_QUESTION_HISTORY_LIST_SUCCESS,
    payload,
  };
};
export const handleResetHistoryListAction = () => {
  return {
    type: ActionType.RESET_SEND_QUESTION_HISTORY_LIST,
  };
};
