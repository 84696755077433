import {
  ActionType,
  HealthCheckQuestionResponse,
  HealthCheckQuestionParams,
} from "models/bodyTemperature/healthCheckQuestions";

export const getHealthCheckQuestionsAction = (
  payload: HealthCheckQuestionParams
) => {
  return {
    type: ActionType.HEALTH_CHECK_QUESTION,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.HEALTH_CHECK_QUESTION_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: HealthCheckQuestionResponse) => {
  return {
    type: ActionType.HEALTH_CHECK_QUESTION_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.HEALTH_CHECK_QUESTION_RESET,
  };
};
