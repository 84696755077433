import React from "react";
import { useTranslation } from "react-i18next";

import { Input as Input, Modal, Button } from "components";
import { Label, WrapperCSVAction } from "./UserList.style";
import { IModal } from "./UserList.modal";

const BatchModal = ({ visible, handleCancel, FooterModal }: IModal) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const handleOk = () => {
    //handleOk
  };
  return (
    <Modal
      type="form"
      title={t("user-batch-registration")}
      visible={visible}
      onCancel={handleCancel}
    >
      <div>
        <Label>{t("select-csv-file")}</Label>
        <WrapperCSVAction>
          <Input height={31} />
          <Button
            name={t("reference")}
            background="#2F8CAE"
            color="#FFFFFF"
            width="64px"
            border="none"
            fontSize={16}
            fontWeight={700}
            padding="3px 16px"
          />
        </WrapperCSVAction>
        {FooterModal(
          "#FFFFFF",
          "#E0E0E0",
          "#2AC769",
          "11px 24px 11px",
          t("cancel"),
          t("upload"),
          handleCancel,
          handleOk
        )}
      </div>
    </Modal>
  );
};

export default BatchModal;
