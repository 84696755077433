import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "antd";
import { ConfirmSuccessIcon } from "icons";

import {
  ConfirmBtnStyled,
  ConfirmModalStyled,
  TextModal,
} from "./UserList.style";
import { Text } from "../messageDelivery/MessageDelivery.style";

const { useBreakpoint } = Grid;

const SuccessModal = ({ visible, message, handleCancel }: any) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });

  const screens = useBreakpoint();
  return (
    <ConfirmModalStyled
      type="form"
      title=""
      visible={visible}
      onCancel={handleCancel}
    >
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <ConfirmSuccessIcon />
        <TextModal
          size={screens.xs ? 22 : 36}
          lnHeight="36px"
          weight={700}
          color="#2ac769"
          mg="20px 0px 24px"
          align="center"
        >
          {message}
        </TextModal>
        <Text size={16} lnHeight="24px" weight={400} mg="0px 0px 24px">
          {t("close-window")}
        </Text>
        <ConfirmBtnStyled
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 96px 13px"
          name={t("close-up")}
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          onClick={handleCancel}
        />
      </div>
    </ConfirmModalStyled>
  );
};

export default SuccessModal;
