import {
  Action,
  ActionType,
  CreateAccountResponse,
} from "models/account/createAccount";
import createReducer from "../../createReducer";

export interface CreateAccountReducerType {
  loading: boolean;
  error?: string;
  createAccountResponse?: CreateAccountResponse;
}

const defaultState: CreateAccountReducerType = {
  loading: false,
  error: undefined,
  createAccountResponse: undefined,
};

export const createAccountReducer = createReducer<CreateAccountReducerType>(
  defaultState,
  {
    [ActionType.CREATE_ACCOUNT](state: CreateAccountReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CREATE_ACCOUNT_ERROR](
      state: CreateAccountReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CREATE_ACCOUNT_SUCCESS](
      state: CreateAccountReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        createAccountResponse: action.payload,
      };
    },

    [ActionType.RESET_CREATE_ACCOUNT](state: CreateAccountReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        createAccountResponse: {},
      };
    },
  }
);
