import {
  ActionType,
  ICreateClassData,
} from "../../../models/classmanagement/createClass";
import { IResponse } from "models";

export const createClassAction = (payload: ICreateClassData) => {
  return {
    type: ActionType.CREATE_CLASS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_CLASS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.CREATE_CLASS_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.CREATE_CLASS_RESET,
  };
};
