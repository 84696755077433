import { ActionType, IVenuesListResponse } from "models/vaccine/venuesList";

export const venueListAction = () => {
  return {
    type: ActionType.VACCINE_VENUES_LIST,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.VACCINE_VENUES_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IVenuesListResponse) => {
  return {
    type: ActionType.VACCINE_VENUES_LIST_SUCCESS,
    payload,
  };
};
