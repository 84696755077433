import {
  ActionType,
  IClassListResponse,
  ClassListData,
} from "models/mentalHealth/classList";

export const classListAction = (payload: ClassListData) => {
  return {
    type: ActionType.MENTAL_CLASS_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.MENTAL_CLASS_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IClassListResponse) => {
  return {
    type: ActionType.MENTAL_CLASS_LIST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.MENTAL_CLASS_LIST_RESET_DATA,
  };
};
