import { Action, ActionType } from "models/mentalHealth/deleteMentalCheck";
import { IResponse } from "models";

import createReducer from "redux/createReducer";

export interface DeleteMentalCheckReducerType {
  loading: boolean;
  error?: any;
  deleted: boolean;
  deleteMentalCheckResponse?: IResponse;
}

const defaultState: DeleteMentalCheckReducerType = {
  loading: false,
  error: null,
  deleted: false,
  deleteMentalCheckResponse: undefined,
};

export const deleteMentalCheckReducer =
  createReducer<DeleteMentalCheckReducerType>(defaultState, {
    [ActionType.DELETE_MENTAL_CHECK](state: DeleteMentalCheckReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_MENTAL_CHECK_ERROR](
      state: DeleteMentalCheckReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.payload,
      };
    },

    [ActionType.DELETE_MENTAL_CHECK_SUCCESS](
      state: DeleteMentalCheckReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        deleted: true,
        deleteMentalCheckResponse: action.payload,
      };
    },

    [ActionType.RESET_DELETE_MENTAL_CHECK](
      state: DeleteMentalCheckReducerType
    ) {
      return {
        ...state,
        loading: false,
        deleted: false,
        error: null,
        deleteMentalCheckResponse: undefined,
      };
    },
  });
