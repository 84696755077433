import {
  ActionType,
  DeseaseOutbreakPayload,
  DeseaseOutbreakResponse,
} from "models/dashboard/deseaseOutbreak";

export const getDeseaseOutbreakAction = (payload: DeseaseOutbreakPayload) => {
  return {
    type: ActionType.GET_DESEASE_OUTBREAK_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_DESEASE_OUTBREAK_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: DeseaseOutbreakResponse) => {
  return {
    type: ActionType.GET_DESEASE_OUTBREAK_CHART_SUCCESS,
    payload,
  };
};
