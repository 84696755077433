import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IIndividualAnswerPayload,
  ActionType,
  IIndividualAnswerResponse,
} from "models/questionnaires/individualAnswer";
import { httpStatus } from "configs/httpStatus";
import { fetchIndividualAnswer } from "services/questionnaires";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./individualAnswerActions";

function* getIndividualAnswerSaga({
  payload,
}: {
  payload: IIndividualAnswerPayload;
}) {
  try {
    const response: IIndividualAnswerResponse = yield call(
      fetchIndividualAnswer,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetIndividualAnswerSagaSubmitWatcher() {
  yield takeLatest(
    ActionType.GET_INDIVIDUAL_ANSWER as any,
    getIndividualAnswerSaga
  );
}

export default [fork(onGetIndividualAnswerSagaSubmitWatcher)];
