import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  SubDepartmentListData,
  ActionType,
  SubDepartmentListResponse,
} from "models/subdepartmentList";
import { httpStatus } from "configs/httpStatus";
import { fetchSubDepartmentList } from "services/subdepartmentList";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./subdepartmentListActions";

function* subDepartmentListSaga({
  payload,
}: {
  payload: SubDepartmentListData;
}) {
  try {
    const response: SubDepartmentListResponse = yield call(
      fetchSubDepartmentList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetSubDepartmentListWatcher() {
  yield takeLatest(
    ActionType.SUB_DEPARTMENT_LIST as any,
    subDepartmentListSaga
  );
}

export default [fork(onGetSubDepartmentListWatcher)];
