import * as React from "react";

function ImportSSOUsersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="import-sso-svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style={{ fill: "transparent" }}
      xmlns="http://www.w3.org/2000/svg "
      {...props}
    >
      <path
        className="stroke"
        d="M6.73999 11.51C7.10999 9.42999 9.61999 7.92999 13.54 7.92999C17.76 7.92999 20.02 9.54999 20.34 11.51"
        stroke={props.fill}
        strokeWidth="1.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M20.09 22.11C19.6 23.35 17.43 24.67 13.54 24.67C9.64999 24.67 7.36999 23.43 6.98999 22.11C6.98999 21.26 6.73999 11.51 6.73999 11.51C6.73999 13.32 8.68999 15.23 13.54 15.23C18.39 15.23 20.34 12.91 20.34 11.51C20.34 11.51 20.09 21.24 20.09 22.11Z"
        stroke={props.fill}
        strokeWidth="1.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6.90991 19.19C6.90991 19.19 7.85991 21.44 13.5399 21.44C19.2199 21.44 20.1699 19.19 20.1699 19.19"
        stroke={props.fill}
        strokeWidth="1.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M6.90991 16.05C6.90991 16.05 7.85991 18.3 13.5399 18.3C19.2199 18.3 20.1699 16.05 20.1699 16.05"
        stroke={props.fill}
        strokeWidth="1.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fill"
        d="M17.6799 17.49L24.7199 12.15V14.91H27.6199V20.07H24.7199V22.84L17.6799 17.49Z"
        fill={props.fill}
      />
      <path
        d="M23.9301 13.75V15.71H26.8301V19.28H23.9301V21.24L19.0001 17.5L23.9301 13.76M25.5201 10.55L22.9701 12.48L18.0401 16.22L16.3701 17.48L18.0401 18.74L22.9701 22.48L25.5201 24.41V20.84H28.4201V14.09H25.5201V10.52V10.55Z"
        fill="white"
      />
    </svg>
  );
}

export default ImportSSOUsersIcon;
