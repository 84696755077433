import { IResponse } from "models";

export enum ActionType {
  GET_ABSENCE_CHART = "action/GET_ABSENCE_CHART",
  GET_ABSENCE_CHART_SUCCESS = "action/GET_ABSENCE_CHART_SUCCESS",
  GET_ABSENCE_CHART_ERROR = "action/GET_ABSENCE_CHART_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface AbsencePayload {
  aggregate_date_to: string;
  department_id: number | string;
}

export interface AbsenceResponse extends IResponse {
  absences: any;
  total_amount: number;
}
