import {
  ActionType,
  HealthCheckProgressPayload,
  HealthCheckProgressResponse,
} from "models/dashboard/healthCheckProgress";

export const getHealthCheckProgressAction = (
  payload: HealthCheckProgressPayload
) => {
  return {
    type: ActionType.GET_HEALTH_CHECK_PROGRESS_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_HEALTH_CHECK_PROGRESS_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: HealthCheckProgressResponse) => {
  return {
    type: ActionType.GET_HEALTH_CHECK_PROGRESS_CHART_SUCCESS,
    payload,
  };
};
