import * as React from "react";

function CloseMenuIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V38C40 39.1046 39.1046 40 38 40H2C0.895431 40 0 39.1046 0 38V2Z"
        fill="#CCCCCC"
      />
      <path d="M10 29.799L29.799 9.99999" stroke="white" strokeWidth="2" />
      <path d="M10 10L29.799 29.799" stroke="white" strokeWidth="2" />
    </svg>
  );
}

export default CloseMenuIcon;
