import {
  ActionType,
  ISaveConsultationSettingsResponse,
  ISaveConsultationSettingsPayload,
} from "models/consultationRequest/saveConsultationSettings";

export const saveConsultationSettingsAction = (
  payload: ISaveConsultationSettingsPayload
) => {
  return {
    type: ActionType.SAVE_CONSULTATION_SETTINGS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SAVE_CONSULTATION_SETTINGS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (
  payload: ISaveConsultationSettingsResponse
) => {
  return {
    type: ActionType.SAVE_CONSULTATION_SETTINGS_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.SAVE_CONSULTATION_SETTINGS_RESET,
  };
};
