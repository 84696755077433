import {
  Action,
  ActionType,
  DepartmentListResponse,
} from "models/departmentList";
import createReducer from "../createReducer";

export interface DepartmentListReducerType {
  loading: boolean;
  error?: string;
  departmentListResponse?: DepartmentListResponse;
}

const defaultState: DepartmentListReducerType = {
  loading: false,
  error: undefined,
  departmentListResponse: undefined,
};

export const departmentListReducer = createReducer<DepartmentListReducerType>(
  defaultState,
  {
    [ActionType.DEPARTMENT_LIST](state: DepartmentListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DEPARTMENT_LIST_ERROR](
      state: DepartmentListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DEPARTMENT_LIST_SUCCESS](
      state: DepartmentListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        departmentListResponse: action.payload,
      };
    },
  }
);
