import * as React from "react";

function ElementaryEngIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_25526_97310)">
        <path
          d="M26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13Z"
          fill="#2AC769"
        />
        <path
          d="M9.13501 20.0001V6.66211H13.509C16.461 6.66211 18.729 7.70611 18.729 10.8561C18.729 13.8981 16.443 15.2661 13.581 15.2661H11.799V20.0001H9.13501ZM11.799 13.1601H13.401C15.237 13.1601 16.119 12.3861 16.119 10.8561C16.119 9.30811 15.147 8.78611 13.311 8.78611H11.799V13.1601Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_25526_97310">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ElementaryEngIcon;
