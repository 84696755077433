import React, { useEffect, useState, Fragment, useRef, useMemo } from "react";
import { Grid, notification } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Input, Button, Box, Pagination, Select } from "components";
import { PlusIcon, CheckedIcon, ConfirmFailIcon } from "icons";
import { IOption } from "components/select/Select";
import { localStorageHelper, generateDynamicRecords } from "utils";

import {
  GetConsultationListActionCreators,
  GetExchangeListActionCreators,
  CompleteRequestActionCreators,
  AddRequestActionCreators,
  SendNotificationConsultationActionCreators,
  GetConsultationsSettingsActionCreators,
} from "redux/rootActions";
import {
  IExchangeListPayload,
  IExchangeItem,
} from "models/consultationRequest/exchangeList";
import {
  IConsultationListPayload,
  IConsultationItem,
} from "models/consultationRequest/consultationList";
import { ICompleteRequestPayload } from "models/consultationRequest/completeRequest";
import {
  selectIsLoadingExchangeList,
  exchangeListDataResponse,
} from "redux/consultationRequest/exchangeList/exchangeListStates";
import {
  selectIsLoadingConsultationList,
  consultationListDataResponse,
} from "redux/consultationRequest/consultationList/consultationListStates";
import {
  selectIsCompletingRequest,
  selectCompleteRequestErrorMessage,
  completeRequestDataResponse,
} from "redux/consultationRequest/completeRequest/completeRequestStates";
import {
  selectIsAddingRequest,
  selectAddRequestErrorMessage,
  addRequestDataResponse,
} from "redux/consultationRequest/addRequest/addRequestStates";
import { sendNotificationConsultationResponse } from "redux/consultationRequest/sendNotification/sendNotificationStates";
import { consultationSettingsDataResponse } from "redux/consultationRequest/consultationSettings/consultationSettingsStates";

import {
  PaginationWrapper,
  ContentWrapper,
  Text,
  TableStyled,
  CheckboxStyled,
  CheckboxLabelGroup,
  PaginationGroup,
  CounselorTableStyled,
  FlexNameWrapper,
  ConfirmModalStyled,
  WrapperBtnFooter,
  TextModalConfirm,
  ContentWrapper2,
  EnterPasswordWrapper,
} from "./Consultations.style";
import { httpStatus } from "configs/httpStatus";

const { useBreakpoint } = Grid;

type IRequestType = "exchange" | "consult" | "";

const ConsultationsList: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const { t: t1 }: any = useTranslation("translation", { keyPrefix: "layout" });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "consultation-request",
  });
  const { t: t4 }: any = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });
  const { t: t5 }: any = useTranslation("translation", {
    keyPrefix: "auth",
  });

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const { watch, handleSubmit, control, setValue } = useForm();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const role = localStorageHelper.getItem("role")
    ? localStorageHelper.getItem("role")
    : "1";

  const isLoadingConsultationList = useSelector(
    selectIsLoadingConsultationList
  );
  const isLoadingExchangeList = useSelector(selectIsLoadingExchangeList);
  const isCompletingRequest = useSelector(selectIsCompletingRequest);
  const isAddingRequest = useSelector(selectIsAddingRequest);

  const completeRequestError = useSelector(selectCompleteRequestErrorMessage);
  const addRequestError = useSelector(selectAddRequestErrorMessage);

  const exchangeListData = useSelector(exchangeListDataResponse);
  const consultationListData = useSelector(consultationListDataResponse);
  const completeRequestResponse = useSelector(completeRequestDataResponse);
  const addRequestResponse = useSelector(addRequestDataResponse);
  const consultationSettingsData = useSelector(
    consultationSettingsDataResponse
  );
  const sendNotificationConsultation = useSelector(
    sendNotificationConsultationResponse
  );

  const scrollAbleRef = useRef<any>(undefined);
  const [exchangeInput, setExchangeInput] = useState<IExchangeListPayload>({
    page: 1,
    per_page: 10,
    is_uncompleted: true,
  });
  const [consultInput, setConsultInput] = useState<IConsultationListPayload>({
    page: 1,
    per_page: 10,
    is_uncompleted: true,
  });
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [requestType, setRequestType] = useState<IRequestType>("");
  const [inputVal, setInputVal] = useState<string>("");
  const [shrinkCol, setShrinkCol] = useState<boolean>(false);
  const [isVisibleConfirmNotification, setIsVisibleConfirmNotification] =
    useState<boolean>(false);

  const totalExchangeRecord = exchangeListData?.total_record || 0;
  const totalConsultRecord = consultationListData?.total_record || 0;

  const handleGetExchangeList = () => {
    const params: IExchangeListPayload = {
      ...exchangeInput,
    };

    dispatch(GetExchangeListActionCreators.getExchangeListAction(params));
  };

  const handleGetConsultationList = () => {
    const params: IConsultationListPayload = {
      ...consultInput,
    };

    dispatch(
      GetConsultationListActionCreators.getConsultationListAction(params)
    );
  };

  const handleCompleteRequest = () => {
    if (selectedItem && selectedItem.id) {
      dispatch(
        CompleteRequestActionCreators.completeRequestAction({
          id: selectedItem.id,
          status: selectedItem.status === 1 ? 0 : 1,
        })
      );
    }
  };

  const handleSendNotification = () => {
    dispatch(
      SendNotificationConsultationActionCreators.sendNotificationAction({
        id: selectedItem.id,
        request_type: selectedItem.request_type,
      })
    );
  };

  useEffect(() => {
    if (
      sendNotificationConsultation &&
      sendNotificationConsultation.status === httpStatus.StatusOK
    ) {
      if (requestType === "exchange") {
        if (exchangeInput.page !== 1) {
          setExchangeInput({
            ...exchangeInput,
            page: 1,
          });
        } else {
          handleGetExchangeList();
        }
        setRequestType("");
      }
      if (requestType === "consult") {
        if (consultInput.page !== 1) {
          setConsultInput({
            ...consultInput,
            page: 1,
          });
        } else {
          handleGetConsultationList();
        }
        setRequestType("");
      }
      dispatch(SendNotificationConsultationActionCreators.handleResetAction());
    }
  }, [sendNotificationConsultation]);

  useEffect(() => {
    if (!isCompletingRequest && completeRequestResponse) {
      if (requestType === "exchange") {
        if (exchangeInput.page !== 1) {
          setExchangeInput({
            ...exchangeInput,
            page: 1,
          });
        } else {
          handleGetExchangeList();
        }
        setRequestType("");
      }
      if (requestType === "consult") {
        if (consultInput.page !== 1) {
          setConsultInput({
            ...consultInput,
            page: 1,
          });
        } else {
          handleGetConsultationList();
        }
        setRequestType("");
      }
      dispatch(CompleteRequestActionCreators.handleResetDataAction());
    }
  }, [isCompletingRequest, completeRequestResponse]);

  useEffect(() => {
    if (!isAddingRequest && addRequestResponse) {
      if (exchangeInput.page !== 1) {
        setExchangeInput({
          ...exchangeInput,
          page: 1,
        });
      } else {
        handleGetExchangeList();
      }
      setInputVal("");
      dispatch(AddRequestActionCreators.handleResetDataAction());
    }
  }, [addRequestResponse]);

  useEffect(() => {
    handleGetExchangeList();
  }, [
    exchangeInput.per_page,
    exchangeInput.page,
    exchangeInput.is_uncompleted,
  ]);

  useEffect(() => {
    handleGetConsultationList();
  }, [consultInput.per_page, consultInput.page, consultInput.is_uncompleted]);

  useEffect(() => {
    if (
      !isLoadingExchangeList &&
      exchangeListData &&
      exchangeListData.result.length
    ) {
      if (
        exchangeListData.result.some(
          (item: IExchangeItem) =>
            item && item.content && item.content.length >= 30
        )
      ) {
        setShrinkCol(false);
      } else {
        setShrinkCol(true);
      }
    }
  }, [exchangeListData]);

  useEffect(() => {
    if (!isCompletingRequest && completeRequestError) {
      notification.error({
        placement: "bottomRight",
        message: completeRequestError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      setRequestType("");
      dispatch(CompleteRequestActionCreators.handleResetDataAction());
    }
  }, [completeRequestError]);

  useEffect(() => {
    if (!isAddingRequest && addRequestError) {
      notification.error({
        placement: "bottomRight",
        message: addRequestError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      setRequestType("");
      dispatch(AddRequestActionCreators.handleResetDataAction());
    }
  }, [addRequestError]);

  useEffect(() => {
    dispatch(
      GetConsultationsSettingsActionCreators.getConsultationSettingsAction()
    );
  }, []);

  const handleIsShowGreenBtn4ExchangeList = (row: any) => {
    return (
      (!consultationSettingsData?.result.teacher_exchange_notification_flag &&
        !row.status) ||
      row.status === 2
    );
  };

  const handleIsShowGreenBtn4ConsultList = (row: any) => {
    return (
      (!consultationSettingsData?.result.teacher_consult_notification_flag &&
        !row.status) ||
      row.status === 2
    );
  };

  const returnFormatedText = (str: string) => {
    if (!str || (str && str.trim() === ",")) {
      return "";
    } else {
      // replace consecutive commas with single comma
      // remove commas at the start(^) or end($) of the string
      // trim to remove white space
      const filterResult = str
        .replace(/,+/g, ",")
        .replace(/^,+|,+$/g, "")
        .trim();

      if (!filterResult) {
        return "";
      } else {
        return filterResult;
      }
    }
  };

  const studentColumns = [
    {
      title: t("full-name"),
      dataIndex: ["last_name", "first_name"],
      key: "first_name",
      width: 204,
      fixed: window.innerWidth < 576 ? "" : "left",
      className: "disabled-drag-column",
      columnName: "full_name",
      render: (name: string, row: IExchangeItem) => (
        <FlexNameWrapper>
          <div>{row["last_name"] + " " + row["first_name"]}</div>
          {handleIsShowGreenBtn4ExchangeList(row) && (
            <Button
              name={t3("complete-response")}
              background="#2AC769"
              color="#ffffff"
              border="none"
              fontSize={11}
              fontWeight={500}
              padding="5px 3px 4px"
              lineHeight="11px"
              width="max-content"
              margin="2px 0px 0px"
              onClick={() => {
                setRequestType("exchange");
                setSelectedItem(row);
                setIsVisible(true);
              }}
              style={{ minWidth: 78 }}
            />
          )}

          {consultationSettingsData?.result
            .teacher_exchange_notification_flag &&
            !row.status && (
              <Button
                name={t3("send-confirmation-notification-btn")}
                background="#2F8CAE"
                color="#ffffff"
                border="none"
                fontSize={11}
                fontWeight={500}
                padding="5px 3px 4px"
                lineHeight="11px"
                width="max-content"
                margin="2px 0px 0px"
                onClick={() => {
                  setRequestType("exchange");
                  setSelectedItem(row);
                  setIsVisibleConfirmNotification(true);
                }}
                style={{ minWidth: 78 }}
              />
            )}

          {row.status === 1 && (
            <Button
              name={t3("response-completed")}
              background="#B7B7B7"
              color="#ffffff"
              border="none"
              fontSize={11}
              fontWeight={500}
              padding="4px 4px 5px"
              lineHeight="11px"
              width="max-content"
              margin="2px 14px 0px 0px"
              icon={<CheckedIcon fill="#ffffff" width={12} height={10} />}
              onClick={() => {
                setRequestType("exchange");
                setSelectedItem(row);
                setIsVisible(true);
              }}
              style={{ minWidth: 64 }}
              disabled={row && row.is_latest_created_at ? false : true}
            />
          )}
        </FlexNameWrapper>
      ),
    },
    {
      title: () => {
        if (currentLanguage === "ja") {
          return (
            <div>
              {t2("grade-1")}
              <br /> {t2("grade-2")}
            </div>
          );
        }
        return <div>{t("grade")}</div>;
      },
      dataIndex: "class_name",
      key: "class_name",
      width: 128,
      columnName: "other_name_type",
      render: (text: string, row: any) => <div>{text}</div>,
    },
    {
      title: () => {
        if (currentLanguage === "ja") {
          return (
            <div>
              {t2("attendance-1")}
              <br /> {t2("attendance-2")}
            </div>
          );
        }
        return <div>{t("attendance")}</div>;
      },
      dataIndex: "roll_number",
      key: "roll_number",
      columnName: "roll_number",
      width: 96,
    },
    {
      title: t3("consult-content"),
      dataIndex: "content",
      key: "content",
      columnName: "content",
      width: 524,
      render: (text: string, row: any) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: t3("report-time"),
      dataIndex: "created_at",
      key: "created_at",
      columnName: "created_at",
      width: 156,
      render: (text: string, row: IExchangeItem) => (
        <div>{moment(row.created_at).format("YYYY年MM月DD日 HH:mm")}</div>
      ),
    },
    {
      title: role === "1" ? t3("consult-destination") : t5("password"),
      dataIndex: role === "1" ? "request_techers" : "password",
      // columnName: role === "1" ? "consult_destination" : "password",
      width: 112,
      render: (text: string) => {
        if (role === "1") {
          return <div>{returnFormatedText(text)}</div>;
        } else {
          return <div>{text}</div>;
        }
      },
    },
  ];

  const counselorColumns = [
    {
      title: t("full-name"),
      dataIndex: ["last_name", "first_name"],
      key: "first_name",
      width: 204,
      fixed: window.innerWidth < 576 ? "" : "left",
      className: "disabled-drag-column",
      columnName: "full_name",
      render: (name: string, row: IConsultationItem) => (
        <FlexNameWrapper>
          <div>{row["last_name"] + " " + row["first_name"]}</div>
          {handleIsShowGreenBtn4ConsultList(row) && (
            <Button
              name={t3("complete-response")}
              background="#2AC769"
              color="#ffffff"
              border="none"
              fontSize={11}
              fontWeight={500}
              padding="5px 3px 4px"
              lineHeight="11px"
              width="max-content"
              margin="2px 0px 0px"
              onClick={() => {
                setRequestType("consult");
                setSelectedItem(row);
                setIsVisible(true);
              }}
              style={{ minWidth: 78 }}
            />
          )}

          {consultationSettingsData?.result.teacher_consult_notification_flag &&
            !row.status && (
              <Button
                name={t3("send-confirmation-notification-btn")}
                background="#2F8CAE"
                color="#ffffff"
                border="none"
                fontSize={11}
                fontWeight={500}
                padding="5px 3px 4px"
                lineHeight="11px"
                width="max-content"
                margin="2px 0px 0px"
                onClick={() => {
                  setRequestType("consult");
                  setSelectedItem(row);
                  setIsVisibleConfirmNotification(true);
                }}
                style={{ minWidth: 78 }}
              />
            )}

          {row.status === 1 && (
            <Button
              name={t3("response-completed")}
              background="#B7B7B7"
              color="#ffffff"
              border="none"
              fontSize={11}
              fontWeight={500}
              padding="4px 4px 5px"
              lineHeight="11px"
              width="max-content"
              margin="2px 14px 0px 0px"
              icon={<CheckedIcon fill="#ffffff" width={12} height={10} />}
              onClick={() => {
                setRequestType("consult");
                setSelectedItem(row);
                setIsVisible(true);
              }}
              style={{ minWidth: 64 }}
              disabled={row && row.is_latest_created_at ? false : true}
            />
          )}
        </FlexNameWrapper>
      ),
    },
    {
      title: () => {
        if (currentLanguage === "ja") {
          return (
            <div>
              {t2("grade-1")}
              <br /> {t2("grade-2")}
            </div>
          );
        }
        return <div>{t("grade")}</div>;
      },
      dataIndex: "class_name",
      key: "class_name",
      width: 128,
      columnName: "other_name_type",
      render: (text: string, row: any) => <div>{text}</div>,
    },
    {
      title: () => {
        if (currentLanguage === "ja") {
          return (
            <div>
              {t2("attendance-1")}
              <br /> {t2("attendance-2")}
            </div>
          );
        }
        return <div>{t("attendance")}</div>;
      },
      dataIndex: "roll_number",
      key: "roll_number",
      columnName: "roll_number",
      width: 96,
      render: (text: string, row: any) => <div>{text}</div>,
    },
    // {
    //   title: t3("consult-content"),
    //   dataIndex: "consult_content",
    //   key: "consult_content",
    //   columnName: "consult_content",
    //   width: 792,
    // },
    {
      title: t3("report-time"),
      dataIndex: "created_at",
      key: "created_at",
      columnName: "created_at",
      width: 792,
      render: (text: string, row: IConsultationItem) => (
        <div>{moment(row.created_at).format("YYYY年MM月DD日 HH:mm")}</div>
      ),
      // width: 156,
    },
    // {
    //   title: role === 1 ? t3("consult-destination") : t5("password"),
    //   dataIndex: "",
    //   columnName: "",
    // },
  ];

  const handleChangeExchangePage = (page: number) => {
    setExchangeInput((prevState: any) => ({ ...prevState, page }));

    if (
      scrollAbleRef.current &&
      window.scrollY >= scrollAbleRef.current.offsetTop
    ) {
      window.scrollTo(0, 0);
    }
  };

  const handleChangeConsultPage = (page: number) => {
    setConsultInput((prevState: any) => ({ ...prevState, page }));

    if (
      scrollAbleRef.current &&
      window.scrollY >= scrollAbleRef.current.offsetTop
    ) {
      window.scrollTo(0, 0);
    }
  };

  const ShowExchangePagination = ({
    showMargin = false,
  }: {
    showMargin?: boolean;
  }) => {
    return totalExchangeRecord > exchangeInput.per_page ? (
      <PaginationWrapper style={{ margin: showMargin ? "16px 0px 24px" : 0 }}>
        <Pagination
          current={exchangeInput.page}
          onChange={handleChangeExchangePage}
          pageSize={exchangeInput.per_page}
          total={totalExchangeRecord}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </PaginationWrapper>
    ) : (
      <Fragment></Fragment>
    );
  };

  const ShowConsultationPagination = ({
    showMargin = false,
  }: {
    showMargin?: boolean;
  }) => {
    return totalConsultRecord > consultInput.per_page ? (
      <PaginationWrapper style={{ margin: showMargin ? "16px 0px 24px" : 0 }}>
        <Pagination
          current={consultInput.page}
          onChange={handleChangeConsultPage}
          pageSize={consultInput.per_page}
          total={totalConsultRecord}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </PaginationWrapper>
    ) : (
      <Fragment></Fragment>
    );
  };

  return (
    <>
      <Box title={t1("consultation-request-label")} padding="16px">
        <PaginationGroup>
          <CheckboxLabelGroup>
            <div className="label">{t3("student-consultations")}</div>
            <CheckboxStyled
              checked={exchangeInput.is_uncompleted}
              onChange={() =>
                setExchangeInput({
                  ...exchangeInput,
                  page: 1,
                  is_uncompleted: !exchangeInput.is_uncompleted,
                })
              }
              // onChange={onChangeCheckbox(item, itemType)}
            >
              <div style={{ marginLeft: -4 }}>
                {t3("show-unsupported-items")}
              </div>
            </CheckboxStyled>
          </CheckboxLabelGroup>
          <ShowExchangePagination />
        </PaginationGroup>
        {role !== "1" && (
          <div style={{ marginBottom: 16 }}>
            <div
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: "#000000",
                lineHeight: "14px",
                marginBottom: 10,
              }}
            >
              {t3("please-enter-password")}
            </div>
            <EnterPasswordWrapper>
              <Input
                value={inputVal}
                width="100px"
                height={31}
                onChange={(e: any) => {
                  const { value } = e.target;
                  // const reg = /^[a-z0-9]$/;
                  // console.log(value, reg.test(value));
                  // if (
                  //   (value && reg.test(value) && value.length < 9) ||
                  //   value === ""
                  // ) {
                  // }
                  setInputVal(value);
                }}
              />
              <Button
                name={t4("display")}
                background="#ffffff"
                color="#2AC769"
                border="1px solid #2AC769"
                fontSize={14}
                fontWeight={500}
                padding="7px 12px 6px"
                lineHeight="16px"
                disabled={isAddingRequest}
                onClick={() => {
                  if (inputVal.length > 0) {
                    dispatch(
                      AddRequestActionCreators.addRequestAction({
                        password: inputVal,
                      })
                    );
                  }
                }}
              />
            </EnterPasswordWrapper>
          </div>
        )}
        <ContentWrapper
          showMargin={totalExchangeRecord > exchangeInput.per_page}
          ref={scrollAbleRef}
          shrink={shrinkCol}
        >
          <TableStyled
            dataSource={exchangeListData?.result || []}
            columns={studentColumns}
            // scroll={{ x: "max-content" }}
            // rowClassName={(record: IExchangeItem) =>
            //   record.status ? "line-disabled" : ""
            // }
            scroll={
              !exchangeListData?.result.length
                ? {}
                : exchangeListData.result.length > 10
                ? { x: "max-content", y: 396 }
                : { x: "max-content" }
            }
            loading={isLoadingExchangeList}
          />
          {!exchangeListData?.result.length && (
            <div
              style={{
                fontSize: 16,
                lineHeight: "24px",
                color: "#3B3B3B",
                fontWeight: 400,
                width: "100%",
                padding: "16px 16px 0px",
              }}
            >
              {t3("no-student-consultations")}
            </div>
          )}
        </ContentWrapper>
        <ShowExchangePagination
          showMargin={totalExchangeRecord > exchangeInput.per_page}
        />
        <div
          style={{
            width: "100%",
            border: "1px dashed #cccccc",
            marginBottom: 24,
          }}
        ></div>
        <PaginationGroup>
          <CheckboxLabelGroup>
            <div className="label">{t3("counselor-hope")}</div>
            <CheckboxStyled
              checked={consultInput.is_uncompleted}
              onChange={() =>
                setConsultInput({
                  ...consultInput,
                  page: 1,
                  is_uncompleted: !consultInput.is_uncompleted,
                })
              }
              // onChange={onChangeCheckbox(item, itemType)}
            >
              <div style={{ marginLeft: -4 }}>
                {t3("show-unsupported-items")}
              </div>
            </CheckboxStyled>
          </CheckboxLabelGroup>
          <ShowConsultationPagination />
        </PaginationGroup>
        <ContentWrapper2
          showMargin={totalConsultRecord > consultInput.per_page}
          ref={scrollAbleRef}
        >
          <CounselorTableStyled
            dataSource={consultationListData?.result || []}
            columns={counselorColumns}
            // scroll={{ x: "max-content" }}
            // rowClassName={(record: IConsultationItem) =>
            //   record.status ? "line-disabled" : ""
            // }
            scroll={
              !consultationListData?.result.length
                ? {}
                : consultationListData.result.length > 10
                ? { x: "max-content", y: 396 }
                : { x: "max-content" }
            }
            loading={isLoadingConsultationList}
          />
          {!consultationListData?.result.length && (
            <div
              style={{
                fontSize: 16,
                lineHeight: "24px",
                color: "#3B3B3B",
                fontWeight: 400,
                width: "100%",
                padding: "16px 16px 0px",
              }}
            >
              {t3("dont-want-counselor")}
            </div>
          )}
        </ContentWrapper2>
        <ShowConsultationPagination
          showMargin={totalConsultRecord > consultInput.per_page}
        />
        <ConfirmModalStyled
          type="confirm"
          footer={
            <WrapperBtnFooter>
              <Button
                onClick={() => setIsVisible(false)}
                background="#E0E0E0"
                color="#fff"
                name={t("cancel")}
                border="none"
                bdr="6px"
                fontSize={16}
                lineHeight="23px"
                fontWeight={700}
              />
              <Button
                onClick={() => {
                  handleCompleteRequest();
                  setIsVisible(false);
                }}
                background="#2AC769"
                color="#fff"
                name="OK"
                border="none"
                bdr="6px"
                fontSize={16}
                lineHeight="23px"
                fontWeight={700}
                disabled={isCompletingRequest}
              />
            </WrapperBtnFooter>
          }
          visible={isVisible && selectedItem && selectedItem.id ? true : false}
        >
          <TextModalConfirm>
            {selectedItem &&
            selectedItem.status &&
            selectedItem.status === 1 ? (
              <Trans i18nKey="consultation-request.comfirm-set-unsupported">
                <span>
                  {{
                    userName: selectedItem
                      ? `${selectedItem.last_name} ${selectedItem.first_name}`
                      : "",
                  }}
                </span>
                さんの対応を完了としますか？
                {/* {{ userName: selectedPatient.name }}さんの登録を解除します。 */}
              </Trans>
            ) : (
              <Trans i18nKey="consultation-request.comfirm-complete-response">
                <span>
                  {{
                    userName: selectedItem
                      ? `${selectedItem.last_name} ${selectedItem.first_name}`
                      : "",
                  }}
                </span>
                さんの対応を完了としますか？
                {/* {{ userName: selectedPatient.name }}さんの登録を解除します。 */}
              </Trans>
            )}
          </TextModalConfirm>
        </ConfirmModalStyled>

        {isVisibleConfirmNotification && (
          <ConfirmModalStyled
            type="confirm"
            footer={
              <WrapperBtnFooter>
                <Button
                  onClick={() => setIsVisibleConfirmNotification(false)}
                  background="#E0E0E0"
                  color="#fff"
                  name={t("cancel")}
                  border="none"
                  bdr="6px"
                  fontSize={16}
                  lineHeight="23px"
                  fontWeight={700}
                />
                <Button
                  onClick={() => {
                    handleSendNotification();
                    setIsVisibleConfirmNotification(false);
                  }}
                  background="#2F8CAE"
                  color="#fff"
                  name="OK"
                  border="none"
                  bdr="6px"
                  fontSize={16}
                  lineHeight="23px"
                  fontWeight={700}
                  disabled={isCompletingRequest}
                />
              </WrapperBtnFooter>
            }
            visible={
              isVisibleConfirmNotification && selectedItem && selectedItem.id
                ? true
                : false
            }
          >
            <TextModalConfirm>
              <Trans i18nKey="consultation-request.comfirm-set-presupported">
                <span>
                  {{
                    userName: selectedItem
                      ? `${selectedItem.last_name} ${selectedItem.first_name}`
                      : "",
                  }}
                </span>
                さんに確認通知を送ります。
              </Trans>
            </TextModalConfirm>
          </ConfirmModalStyled>
        )}
      </Box>
    </>
  );
};

export default ConsultationsList;
