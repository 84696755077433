import {
  ActionType,
  IConfirmStudentListResponse,
  IConfirmStudentListData,
} from "../../../models/classchangemanagement/confirmStudentListClassChange";

export const getConfirmStudentListAction = (
  payload: IConfirmStudentListData
) => {
  return {
    type: ActionType.CONFIRM_STUDENT_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CONFIRM_STUDENT_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IConfirmStudentListResponse) => {
  return {
    type: ActionType.CONFIRM_STUDENT_LIST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.CONFIRM_STUDENT_LIST_RESET,
  };
};
