export enum ActionType {
  // creatte user
  NEW_MENTAL_CHECK = "action/NEW_MENTAL_CHECK",
  NEW_MENTAL_CHECK_SUCCESS = "action/NEW_MENTAL_CHECK_SUCCESS",
  NEW_MENTAL_CHECK_ERROR = "action/NEW_MENTAL_CHECK_ERROR",
  RESET_NEW_MENTAL_CHECK = "action/RESET_NEW_MENTAL_CHECK",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface NewMentalCheckData {
  mental_check_type_id: number;
  test_name: string;
  start_time: string;
  end_time: string;
  grade: any;
  is_temp_save?: string;
}
