import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  ISchoolTypeData,
  ISchoolTypeResponse,
} from "models/classmanagement/schoolType";
import { httpStatus } from "configs/httpStatus";
import { getSchoolType } from "services/schoolclasses";
import { handleSuccesAction, handleErrorAction } from "./schoolTypeActions";

function* schoolClassTypeSaga({ payload }: { payload: ISchoolTypeData }) {
  try {
    const response: ISchoolTypeResponse = yield call(getSchoolType, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetSchoolClassTypeWatcher() {
  yield takeLatest(ActionType.GET_SCHOOL_TYPE as any, schoolClassTypeSaga);
}

export default [fork(onGetSchoolClassTypeWatcher)];
