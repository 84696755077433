import { put, takeLatest, fork, call } from "redux-saga/effects";
import { CreateUserData, ActionType } from "models/createuserlist";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { createNewUser } from "services/userList";
import { handleSuccesAction, handleErrorAction } from "./createuserActions";

function* createUserSaga({ payload }: { payload: CreateUserData }) {
  try {
    const response: IResponse = yield call(createNewUser, payload);
    if (
      response.status === httpStatus.StatusOK ||
      response.status === httpStatus.StatusCreated
    ) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCreateUserListWatcher() {
  yield takeLatest(ActionType.CREATE_USER as any, createUserSaga);
}

export default [fork(onCreateUserListWatcher)];
