import { IResponse } from "models";

export enum ActionType {
  UPDATE_USER_REGISTRATION_SETTING = "action/UPDATE_USER_REGISTRATION_SETTING",
  UPDATE_USER_REGISTRATION_SETTING_SUCCESS = "action/UPDATE_USER_REGISTRATION_SETTING_SUCCESS",
  UPDATE_USER_REGISTRATION_SETTING_ERROR = "action/UPDATE_USER_REGISTRATION_SETTING_ERROR",
  RESET_USER_REGISTRATION_SETTING = "action/RESET_USER_REGISTRATION_SETTING",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateUserRegistrationResponse extends IResponse {}

export interface IUpdateUserRegistrationPayload {
  is_display_user_id: boolean;
}
