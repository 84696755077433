import {
  ActionType,
  ILastNotificationTimeResponse,
  ILastNotificationTimePayload,
} from "models/mentalHealth/lastNotificationTime";

export const getLastNotificationTimeAction = (
  payload: ILastNotificationTimePayload
) => {
  return {
    type: ActionType.LAST_SEND_NOTIFICATION_TIME,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.LAST_SEND_NOTIFICATION_TIME_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ILastNotificationTimeResponse) => {
  return {
    type: ActionType.LAST_SEND_NOTIFICATION_TIME_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.LAST_SEND_NOTIFICATION_TIME_RESET,
  };
};
