import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Trans, useTranslation } from "react-i18next";
import { WrapperChart, ContainerChart } from "../Questions.style";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const LineChartCustom = ({ data, color, unit }: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  return (
    <WrapperChart>
      <ContainerChart>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={data}
            margin={{
              top: 10,
              right: 24,
              left: -20,
              bottom: 0,
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              padding={{ left: 30, right: 30 }}
              tickLine={false}
            />
            <YAxis dataKey="percent" unit={unit || ""} tickLine={false} />
            <Tooltip />
            <Line
              dataKey="value"
              stroke={color}
              strokeDasharray="3 3"
              strokeWidth={3}
              dot={{
                stroke: "#2ac769",
                strokeWidth: 1,
                r: 5,
                fill: "#2ac769",
                strokeDasharray: "",
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </ContainerChart>
    </WrapperChart>
  );
};

export default LineChartCustom;
