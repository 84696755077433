import {
  ActionType,
  PatientProfileResponse,
  CreatePatientProfileRequestData,
} from "models/bodyTemperature/patientProfile";

export const createPatientProfile = (
  payload: CreatePatientProfileRequestData
) => {
  return {
    type: ActionType.CREATE_PATIENT_PROFILE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_PATIENT_PROFILE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: PatientProfileResponse) => {
  return {
    type: ActionType.CREATE_PATIENT_PROFILE_SUCCESS,
    payload,
  };
};
