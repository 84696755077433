import {
  Action,
  ActionType,
  IAddRequestResponse,
} from "models/consultationRequest/addRequest";
import createReducer from "redux/createReducer";

export interface AddRequestReducerType {
  loading: boolean;
  error?: string;
  addRequestResponse?: IAddRequestResponse;
}

const defaultState: AddRequestReducerType = {
  loading: false,
  error: undefined,
  addRequestResponse: undefined,
};

export const addRequestReducer = createReducer<AddRequestReducerType>(
  defaultState,
  {
    [ActionType.ADD_REQUEST](state: AddRequestReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.ADD_REQUEST_ERROR](
      state: AddRequestReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        addRequestResponse: undefined,
      };
    },

    [ActionType.ADD_REQUEST_SUCCESS](
      state: AddRequestReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        addRequestResponse: action.payload,
      };
    },

    [ActionType.ADD_REQUEST_RESET](state: AddRequestReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
  }
);
