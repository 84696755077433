import {
  Action,
  ActionType,
  IMentalCheckTotalResponse,
} from "models/mentalHealth/mentalHealthTotal";

import createReducer from "redux/createReducer";

export interface MentalCheckTotalReducerType {
  loading: boolean;
  error?: any;
  mentalCheckTotalResponse?: IMentalCheckTotalResponse;
}

const defaultState: MentalCheckTotalReducerType = {
  loading: false,
  error: null,
  mentalCheckTotalResponse: undefined,
};

export const mentalCheckTotalReducer =
  createReducer<MentalCheckTotalReducerType>(defaultState, {
    [ActionType.MENTAL_CHECK_TOTAL](state: MentalCheckTotalReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.MENTAL_CHECK_TOTAL_ERROR](
      state: MentalCheckTotalReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.MENTAL_CHECK_TOTAL_SUCCESS](
      state: MentalCheckTotalReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        mentalCheckTotalResponse: action.payload,
      };
    },

    [ActionType.MENTAL_CHECK_TOTAL_RESET](state: MentalCheckTotalReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        mentalCheckTotalResponse: undefined,
      };
    },
  });
