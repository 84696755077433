import * as React from "react";

function LogoMonotone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="78"
      height="40"
      viewBox="0 0 78 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg "
      {...props}
    >
      <path
        d="M58.1516 0C52.6551 0 47.6739 2.25 44.0859 5.89423L30.2111 20L44.0859 34.1058C47.6834 37.7404 52.6551 40 58.1516 40C69.1159 40 78 31.0481 78 20C78 8.95192 69.1159 0 58.1516 0Z"
        fill="#F2F2F2"
      />
      <path
        d="M33.685 34.1058L19.8102 20L33.685 5.89423C33.7232 5.85577 33.7614 5.81731 33.7995 5.77885C30.2116 2.21154 25.2876 0 19.8484 0C8.88407 0 0 8.95192 0 20C0 31.0481 8.88407 40 19.8484 40C25.2876 40 30.2116 37.7981 33.7995 34.2212C33.7614 34.1827 33.7232 34.1442 33.685 34.1058Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export default LogoMonotone;
