import * as React from "react";

function NextIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="31"
      viewBox="0 0 40 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4266_50183)">
        <path
          d="M3.07692 15.4053H36.9231"
          stroke={props.fill}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M24.5949 3.07715L36.9231 15.4054"
          stroke={props.fill}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M24.5949 27.7335L36.9231 15.4053"
          stroke={props.fill}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4266_50183">
          <rect width="40" height="30.8103" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NextIcon;
