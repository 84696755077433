import {
  ActionType,
  ProgressResponse,
  ProgressPayload,
} from "models/dashboard/progress";

export const getProgressAction = (payload: ProgressPayload) => {
  return {
    type: ActionType.GET_PROGRESS_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_PROGRESS_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ProgressResponse) => {
  return {
    type: ActionType.GET_PROGRESS_CHART_SUCCESS,
    payload,
  };
};
