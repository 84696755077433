const colors = {
  white: "#FFFFFF",
  textLink: "#2F8CAE",
  titleText: "#1A2F62",
  warning: "#EBC300",
  important: "#FB4E4E",
  error: "#FB2121",
  border: "#CCCCCC",
  icon: "#B7B7B7",
  textFieldBackground: "#E0E0E0",
  background: "#F2F2F2",
  button: "#2AC769",
  outline: "#83BCE2",
  text: {
    primary: "#3B3B3B",
    secondary: "#666666",
  },
};

const sizes = {
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "20px",
  xxl: "24px",
};

const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 700,
};

const media = {
  mobileS: "@media (min-width: 320px)",
  mobileM: "@media (min-width: 375px)",
  mobileL: "@media (min-width: 425px)",
  tablet: "@media (min-width: 768px)",
  laptop: "@media (min-width: 992px)",
  laptopL: "@media (min-width: 1200px)",
  desktop: "@media (min-width: 1400px)",
  desktopL: "@media (min-width: 1600px)",
};

export const theme = {
  colors,
  sizes,
  fontWeight,
  media,
};

export type Theme = typeof theme;
