import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IDeleteActivityData,
  ActionType,
} from "models/activitymanagement/deleteActivity";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { deleteActivity } from "services/activitymanagement";
import { handleSuccesAction, handleErrorAction } from "./deleteActivityActions";

function* deleteActivitySaga({ payload }: { payload: IDeleteActivityData }) {
  try {
    const response: IResponse = yield call(deleteActivity, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDeleteActivityWatcher() {
  yield takeLatest(ActionType.DELETE_ACTIVITY as any, deleteActivitySaga);
}

export default [fork(onDeleteActivityWatcher)];
