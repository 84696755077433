import React, { useEffect, useState } from "react";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { CircleCarrotIcon } from "icons";
import {
  Select,
  Button,
  Input,
  TextArea,
  Collapse,
  Radio,
  Checkbox1,
} from "components";

import {
  QuestionResponseDetailActionCreators,
  IndividualAnswerActionCreators,
} from "redux/rootActions";
import { individualAnswerDataResponse } from "redux/questionnaires/individualAnswer/individualAnswerStates";
import { questionnairesResponseDetailDataResponse } from "redux/questionnaires/questionResponseDetail/questionResponseDetailStates";
import {
  IQuestionnaire,
  IPatientAnswered,
} from "models/questionnaires/individualAnswer";

import {
  RadioStyled,
  WrapperCollapse,
  WrapperCircleCarrot,
  WrapperIndividualAnswer,
  WrapperSearching,
  WrapperQuestion,
  QuestionDraftTitle,
  QuestionIndividualAnswerBody,
  WrapperIndividualAnswerList,
  DescriptionStyled,
  DescriptionStyled2,
} from "./Questions.style";

const { useBreakpoint } = Grid;

interface IData {
  patientAnswered: IPatientAnswered[];
  questionnaire: IQuestionnaire;
  totalPatientAnswered: number;
}

interface IIndividualAnswer {
  questionID?: number;
  status: string;
}

const Question = ({ question, index }: any) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });

  const dataList = question.questionnaire_answers.map((ans: any) => {
    return {
      key: ans.id,
      value: ans.id,
      name: ans.answer_text,
    };
  });

  const items: any = {
    radio: (
      <>
        <RadioStyled>
          <Radio list={dataList} value={question?.value} />
        </RadioStyled>
        {question?.textValue && (
          <DescriptionStyled>{question?.textValue}</DescriptionStyled>
        )}
      </>
    ),
    checkbox: (
      <>
        <Checkbox1 list={dataList} value={question?.value} />
        {question?.textValue && (
          <DescriptionStyled>{question?.textValue}</DescriptionStyled>
        )}
      </>
    ),
    text: (
      <React.Fragment>
        <Input
          height={40}
          fsLabel={16}
          disabled
          marginForm="0 0 8px"
          value={question?.textValue || ""}
        />
      </React.Fragment>
    ),
    multiLine: (
      <React.Fragment>
        <TextArea
          height={"120px"}
          fsLabel={16}
          disabled
          value={question?.textValue || ""}
        />
      </React.Fragment>
    ),
    pulldown: (
      <>
        <Select
          width="156px"
          options={dataList}
          value={question?.value}
          disabled
        />
      </>
    ),
  };

  const handleRenderItem = (answerType: string) => {
    return items[answerType];
  };

  return (
    <WrapperQuestion>
      <QuestionDraftTitle>{t("question") + index}</QuestionDraftTitle>

      <QuestionIndividualAnswerBody>
        <div>{question.question_content}</div>
        {question?.question_summary && (
          <DescriptionStyled2>{question.question_summary}</DescriptionStyled2>
        )}
        {handleRenderItem(question.answer_type)}
      </QuestionIndividualAnswerBody>
    </WrapperQuestion>
  );
};

const IndividualAnswer = ({ questionID, status }: IIndividualAnswer) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const individualAnswerResponse = useSelector(individualAnswerDataResponse);
  const questionnairesResponseDetail = useSelector(
    questionnairesResponseDetailDataResponse
  );

  const [data, setData] = useState<IData>({
    patientAnswered: [],
    questionnaire: {},
    totalPatientAnswered: 0,
  });
  const [fullName, setFullName] = useState("");
  const [selectedPatient, setSelectedPatient] = useState<IPatientAnswered>({});

  useEffect(() => {
    if (
      individualAnswerResponse &&
      Object.keys(individualAnswerResponse.result).length
    ) {
      const { patients_answered, questionnaire, total_patient_answered } =
        individualAnswerResponse.result;

      setData({
        patientAnswered: patients_answered,
        questionnaire,
        totalPatientAnswered: total_patient_answered,
      });
    }
  }, [individualAnswerResponse]);

  useEffect(() => {
    if (
      questionnairesResponseDetail &&
      Object.keys(questionnairesResponseDetail.result).length
    ) {
      const { questionaire_response_details } =
        questionnairesResponseDetail.result;
      const questionsClone = data?.questionnaire?.questionnaire_questions?.map(
        (question) => {
          const questionDetailInfo = questionaire_response_details.find(
            (item) => item.questionnaire_question_id === question.id
          );

          if (!questionDetailInfo) return question;

          return {
            ...question,
            value:
              question.answer_type === "radio" ||
              question.answer_type === "pulldown"
                ? questionDetailInfo.questionnaire_answer_id[0]
                : questionDetailInfo.questionnaire_answer_id,
            textValue: questionDetailInfo.text,
          };
        }
      );

      if (questionsClone) {
        setData((prevState: any) => ({
          ...prevState,
          questionnaire: {
            ...prevState.questionnaire,
            questionnaire_questions: questionsClone,
          },
        }));
      }
    }
  }, [questionnairesResponseDetail]);

  useEffect(() => {
    if (status === "create") {
      setData({
        patientAnswered: [],
        questionnaire: {},
        totalPatientAnswered: 0,
      });
    }
  }, [status]);

  const handleSearch = () => {
    dispatch(
      IndividualAnswerActionCreators.getIndividualAnswerAction({
        id: questionID,
        full_name: fullName,
      })
    );
  };

  return (
    <React.Fragment>
      <WrapperIndividualAnswer>
        <WrapperSearching>
          <Input
            value={fullName}
            placeholder={t1("you-can-search-for")}
            height={31}
            fsLabel={16}
            width={screens.xs ? "100%" : "325px"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFullName(e.target.value)
            }
          />
          <div>
            <Button
              type="submit"
              name={t1("search")}
              background="#2AC769"
              color="#FFFFFF"
              border="none"
              fontSize={16}
              fontWeight={700}
              padding="3px 16px"
              width="80px"
              onClick={handleSearch}
            />
          </div>
        </WrapperSearching>

        <WrapperCollapse>
          <Collapse
            header={t("list-of-respondents")}
            expandIconPosition="right"
            defaultActiveKey={["1"]}
          >
            {status !== "create" && (
              <WrapperIndividualAnswerList>
                {data.totalPatientAnswered > 0 && (
                  <div>
                    {data.patientAnswered.map((patient) => (
                      <div
                        key={patient.row_num}
                        onClick={() => {
                          setSelectedPatient(patient);
                          dispatch(
                            QuestionResponseDetailActionCreators.getQuestionnaireResponseDetailAction(
                              { id: patient.questionnaire_and_patients_id }
                            )
                          );
                        }}
                      >
                        {patient.full_name}
                      </div>
                    ))}
                  </div>
                )}
              </WrapperIndividualAnswerList>
            )}
          </Collapse>
        </WrapperCollapse>
      </WrapperIndividualAnswer>

      {Object.keys(selectedPatient).length > 0 && (
        <WrapperCircleCarrot>
          {/*<CircleCarrotIcon fill="#2F8CAE" />*/}
          <div>
            <div className="student-name">{selectedPatient?.full_name}</div>
          </div>
          {/* <span style={{ transform: "rotate(180deg)", height: "40px" }}>
            <CircleCarrotIcon fill="#2F8CAE" />
          </span>*/}
        </WrapperCircleCarrot>
      )}

      {Object.keys(selectedPatient).length > 0 &&
        Object.keys(data.questionnaire).length > 0 &&
        data?.questionnaire?.questionnaire_questions?.map((question, index) => {
          return <Question key={index} index={index + 1} question={question} />;
        })}
    </React.Fragment>
  );
};

export default IndividualAnswer;
