import { IResponse } from "models";

export enum ActionType {
  CANCEL_QUESTIONNAIRE = "action/CANCEL_QUESTIONNAIRE",
  CANCEL_QUESTIONNAIRE_SUCCESS = "action/CANCEL_QUESTIONNAIRE_SUCCESS",
  CANCEL_QUESTIONNAIRE_ERROR = "action/CANCEL_QUESTIONNAIRE_ERROR",
  CANCEL_QUESTIONNAIRE_RESET = "action/CANCEL_QUESTIONNAIRE_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICancelQuestionnairePayload {
  id: number;
}

export interface ICancelQuestionnaireResponse extends IResponse {
  result: any;
}
