import {
  Action,
  ActionType,
  IGetActivityListResponse,
} from "models/activitymanagement/activityList";
import createReducer from "../../createReducer";

export interface ActivityListSchoolReducerType {
  loading: boolean;
  error?: string;
  activitySchoolResponse?: IGetActivityListResponse;
}

const defaultState: ActivityListSchoolReducerType = {
  loading: false,
  error: undefined,
  activitySchoolResponse: undefined,
};

export const activityListSchoolReducer =
  createReducer<ActivityListSchoolReducerType>(defaultState, {
    [ActionType.ACTIVITY_LIST_SCHOOL](state: ActivityListSchoolReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.ACTIVITY_LIST_SCHOOL_ERROR](
      state: ActivityListSchoolReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.ACTIVITY_LIST_SCHOOL_SUCCESS](
      state: ActivityListSchoolReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        activitySchoolResponse: action.payload,
      };
    },
  });
