import React, { ReactElement } from "react";
import styled from "styled-components";
import { Pagination as OriginalPagination, PaginationProps } from "antd";

import { theme } from "theme/theme";

const Pagination = styled(OriginalPagination)`
  li {
    min-width: 30px;
    height: 30px;
  }
  ${theme.media.mobileM} {
    display: flex;
    justify-content: space-between;
    ::after {
      display: none;
    }
    .ant-pagination-item-after-jump-prev,
    .ant-pagination-item-before-jump-next {
      display: inline-block !important;
    }
  }
  ${theme.media.tablet} {
    display: block;
    ::after {
      display: block;
    }
  }
  .ant-pagination-item,
  .ant-pagination-item-active,
  .ant-pagination-next,
  .ant-pagination-prev {
    border-radius: 4px;
    a {
      font-weight: ${theme.fontWeight.bold};
      font-size: 15px;
    }
  }
  .ant-pagination-item,
  .ant-pagination-item-link {
    line-height: 28px;
    border-color: ${theme.colors.button};
    background: ${theme.colors.white};
    a {
      color: ${theme.colors.button};
    }
  }
  .ant-pagination-item-link {
    border-radius: 4px;
    .anticon {
      color: ${theme.colors.button};
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .anticon-double-right {
      svg {
        fill: ${theme.colors.button};
      }
    }
  }
  .ant-pagination-item-link:hover {
    .anticon-right,
    .anticon-left {
      color: ${theme.colors.white};
    }
    .anticon-double-right {
      svg {
        fill: ${theme.colors.button};
      }
    }
  }
  .ant-pagination-item-active {
    border-color: #cccccc;
    background: #f2f2f2;
    a {
      color: #b7b7b7;
    }
  }
  .ant-pagination-disabled,
  .ant-pagination-disabled:hover {
    button {
      border-color: #cccccc;
      background: #f2f2f2;
      span {
        color: #b7b7b7;
      }
      svg {
        fill: #b7b7b7;
      }
    }
  }
  .ant-pagination-item:hover:not(.ant-pagination-item-active),
  .ant-pagination-item-link:hover {
    border-color: ${theme.colors.button};
    background: ${theme.colors.button};
    a {
      color: ${theme.colors.white};
    }
  }
  .ant-pagination-prev,
  .ant-pagination-item {
    margin-right: 4px;
  }
  .ant-pagination-item-container {
    .ant-pagination-item-ellipsis {
      color: ${theme.colors.button};
      top: 9px;
    }
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin-right: 5px;
    min-width: 20px;
  }
  .ant-pagination-prev {
    margin-right: 5px;
  }
  ${theme.media.desktop} {
    .ant-pagination-item {
      margin-right: 8px;
      margin-left: 0;
    }
    .ant-pagination-prev {
      margin-right: 12px;
    }
    .ant-pagination-next {
      margin-left: 4px;
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin-right: 8px;
    }
  }
`;

const MobilePaginationWrapper = styled.div`
  .ant-pagination {
    justify-content: center;
    gap: 12px;
    position: relative;
    .ant-pagination-prev,
    .ant-pagination-next {
      margin-right: 0px;
      position: absolute;
    }
    .ant-pagination-item {
      margin-right: 0px;
    }
    .ant-pagination-prev {
      top: 0;
      left: 0;
    }
    .ant-pagination-next {
      right: 0;
      top: 0;
    }
  }
  ${theme.media.tablet} {
    .ant-pagination {
      justify-content: space-between;
      .ant-pagination-prev,
      .ant-pagination-next {
        position: unset;
      }
      .ant-pagination-next {
        margin-left: 1px;
      }
      .ant-pagination-prev {
        margin-right: 5px;
      }
      .ant-pagination-item {
        margin-right: 4px;
      }
    }
  }
  ${theme.media.desktop} {
    .ant-pagination {
      .ant-pagination-next {
        margin-left: 4px;
      }
      .ant-pagination-prev {
        margin-right: 12px;
      }
      .ant-pagination-item {
        margin-right: 8px;
      }
    }
  }
`;

const CustomPagination: React.FC<PaginationProps> = ({
  ...props
}): ReactElement => {
  const { total, pageSize } = props;

  const showSmallChanger = () => {
    if (total && pageSize) {
      return total / pageSize < 6;
    }
  };

  return (
    <>
      {showSmallChanger() ? (
        <MobilePaginationWrapper>
          <Pagination {...props} showLessItems />
        </MobilePaginationWrapper>
      ) : (
        <Pagination {...props} showLessItems />
      )}
    </>
  );
};

export default CustomPagination;
