import { all } from "redux-saga/effects";
import authenticationSagas from "redux/authentication/authenticationSaga";
import forgotPasswordSagas from "redux/forgotpassword/forgotpasswordSaga";
import resetPasswordSagas from "redux/resetpassword/resetpasswordSaga";
import userListSagas from "redux/userlist/userListSaga";
import classListSagas from "redux/classlist/classListSaga";
import departmentListSagas from "redux/department/departmentListSaga";
import subDepartmentListSagas from "redux/subdepartment/subdepartmentListSaga";
import activitiesListSagas from "redux/activitieslist/activitiesListSaga";
import createUserSagas from "redux/createuserlist/createuserSaga";
import updateUserSagas from "redux/updateuser/updateuserSaga";
import deleteUserSagas from "redux/deleteuser/deleteuserSaga";
import csvDownloadSagas from "redux/csvdownloaduser/csvdownloaduserSaga";
import userAttendanceSagas from "redux/userattendance/userAttendanceSaga";
import schoolClassListSagas from "redux/classmanagement/getclasses/classesListSaga";
import createClassSagas from "redux/classmanagement/createclasses/createClassSagas";
import updateClassSagas from "redux/classmanagement/updateclasses/updateClassSagas";
import schoolTypeSagas from "redux/classmanagement/schooltype/schoolTypeSaga";
import classTypeSagas from "redux/classmanagement/classtype/classTypeSaga";
import createBulkClassTypeSagas from "redux/classmanagement/createbulkclasses/createBulkClassSagas";
import deleteClassTypeSagas from "redux/classmanagement/deleteclass/deleteClassSagas";
import activityListSagas from "redux/activitymanagement/getactivities/activitiesListSaga";
import createActivitySagas from "redux/activitymanagement/createactivity/createActivitySagas";
import updateActivitySagas from "redux/activitymanagement/updateactivity/updateActivitySagas";
import deleteActivitySagas from "redux/activitymanagement/deleteactivity/deleteActivitySaga";
import activitiesListFilterSagas from "redux/messagemanagement/activitiesfilterlist/activitiesFilterListSaga";
import classListFilterSagas from "redux/messagemanagement/classfilterlist/classFilterListSaga";
import studentListSagas from "redux/messagemanagement/getstudentlist/getStudentListSaga";
import chartSagas from "redux/bodyTemperature/charts/chartSaga";
import statisticsSagas from "redux/bodyTemperature/statistics/statisticsSaga";
import destinationSagas from "redux/bodyTemperature/destinations/destinationSaga";
import groupClassSagas from "redux/bodyTemperature/groupClass/groupClassSaga";
import childDepartmentSagas from "redux/bodyTemperature/childDepartment/childDepartmentSaga";
import patientChildDepartmentSagas from "redux/bodyTemperature/patientChildDepartment/patientChildSaga";
import createPatientProfileSagas from "redux/bodyTemperature/createPatientProfile/patientProfileSaga";
import detailFormSagas from "redux/bodyTemperature/detailForm/detailFormSaga";
import patientDetailSagas from "redux/bodyTemperature/patientDetail/patientDetailSaga";
import feverPercentageSagas from "redux/bodyTemperature/feverPercentage/feverPercentageSaga";
import getBodyTemperatureCSVSagas from "redux/bodyTemperature/settingForm/settingFormSaga";
import getBodyTemperaturePDFSagas from "redux/bodyTemperature/file/fileSaga";
import progressBarSagas from "redux/bodyTemperature/progressBar/progressBarSaga";
import approveAbsenceSagas from "redux/bodyTemperature/approveAbsence/approveAbsenceSaga";
import studentListClassChangeSagas from "redux/classchangemanagement/getStudentData/getStudentListClassChangeSagas";
import confirmStudentListSagas from "redux/classchangemanagement/getConfirmStudentData/getConfirmStudentListSagas";
import clearTempRegisterSagas from "redux/classchangemanagement/clearTempRegisterData/clearTempRegisterSagas";
import putConfirmStudentSagas from "redux/classchangemanagement/putConfirmStudentData/putConfirmStudentClassChangeSagas";
import temperatureListFilterSagas from "redux/messagemanagement/temperaturefilterlist/temperatureFilterListSaga";
import messageHistoryListSagas from "redux/messagemanagement/messagehistory/messageHistoryListSagas";
import messageHistoryDetailSagas from "redux/messagemanagement/messagehistorydetail/messageHistoryDetailSagas";
import attendanceListFilterSagas from "redux/messagemanagement/attendancefilterlist/attendanceFilterListSaga";
import sendMessageSagas from "redux/messagemanagement/sendmessage/sendMessageSagas";
import getCSVDownloadStudentListSagas from "redux/classchangemanagement/getCSVDownloadStudentList/getDownloadCSVStudentListSagas";
import uploadCSVStudentListSagas from "redux/classchangemanagement/uploadCSV/uploadCSVSaga";
import saveTemporaryStudentListSagas from "redux/classchangemanagement/saveTemporary/saveTemporarySaga";
import searchConditionSagas from "redux/messagemanagement/searchCondition/searchConditionSaga";
import downloadQrCodeSagas from "redux/qrcode/qrcodeSaga";
import accountListSagas from "redux/account/accountList/accountListSaga";
import deleteAccountSagas from "redux/account/deleteAccount/deleteAccountSaga";
import accountDepartmentListSagas from "redux/account/departmentList/departmentListSaga";
import editAccountSagas from "redux/account/editAccount/editAccountSaga";
import accountRegistrationListSagas from "redux/account/registrationList/registrationListSaga";
import createAccountSagas from "redux/account/createAccount/createAccountSaga";
import settingDataSagas from "redux/setting/settingData/settingDataSaga";
import updateTemperatureSagas from "redux/setting/updateTemperature/updateTemperatureSaga";
import updateGeneralSagas from "redux/setting/updateGeneral/updateGeneralSaga";
import deleteNotificationSagas from "redux/setting/deleteNotification/deleteNotificationSaga";
import updateMessageSagas from "redux/setting/updateMessage/updateMessageSaga";
import getClassListEndSagas from "redux/classchangemanagement/getClassList/getClassListSagas";
import readHistoryListSaga from "redux/messagemanagement/readHistory/readHistoryListSaga";
import getRecordListSagas from "redux/vaccine/records/recordSaga";
import getVaccineGraphDataSagas from "redux/vaccine/graph/graphSaga";
import createVenuesSagas from "redux/vaccine/venues/createVenues/createVenuesSaga";
import venuesListSagas from "redux/vaccine/venues/list/listSaga";
import updateVenueSagas from "redux/vaccine/venues/updateVenues/updateVenuesSaga";
import deleteVenueSagas from "redux/vaccine/venues/deleteVenues/deleteVenuesSaga";
import reservationsSaga from "redux/vaccine/reservations/reservationsSaga";
import todaySchedulesSaga from "redux/vaccine/todaySchedules/todaySchedulesSaga";
import saveScheduleSaga from "redux/vaccine/saveSchedule/saveScheduleSaga";
import updateScheduleSaga from "redux/vaccine/updateSchedule/updateScheduleSaga";
import venuesSaga from "redux/vaccine/venues/venuesSaga";
import slotsSaga from "redux/vaccine/slots/slotsSaga";
import deleteSlotSaga from "redux/vaccine/deleteSlot/deleteSlotSaga";
import getScheduleFilterSaga from "redux/vaccine/getScheduleFilter/getScheduleFilterSaga";
import timeSlotSaga from "redux/vaccine/timeSlot/timeSlotSaga";
import checkListSaga from "redux/mentalHealth/checkList/checkListSaga";
import checkDetailsSaga from "redux/mentalHealth/checkDetails/checkDetailsSaga";
import gradeListSaga from "redux/mentalHealth/gradesList/gradeListSaga";
import newMentalCheckSaga from "redux/mentalHealth/newMentalCheck/newMentalCheckSaga";
import updateMentalCheckSaga from "redux/mentalHealth/updateMentalCheck/updateMentalCheckSaga";
import deleteMentalCheckSaga from "redux/mentalHealth/deleteMentalCheck/deleteMentalCheckSaga";
import questionnairesSaga from "redux/questionnaires/questionnairesByType/questionnairesByTypeSaga";
import questionnairesDetailSaga from "redux/questionnaires/questionnairesDetail/questionnairesDetailSaga";
import createQuestionnairesSaga from "redux/questionnaires/createQuestionnaires/createQuestionnairesSaga";
import answerInfoSaga from "redux/questionnaires/answerInfo/answerInfoSaga";
import individualAnswerSaga from "redux/questionnaires/individualAnswer/individualAnswerSaga";
import questionResponseDetailSaga from "redux/questionnaires/questionResponseDetail/questionResponseDetailSaga";
import duplicateQuestionSaga from "redux/questionnaires/duplicateQuestion/duplicateQuestionSaga";
import doneQuestionSaga from "redux/questionnaires/doneQuestion/doneQuestionSaga";
import sendQuestionnaireSaga from "redux/questionnaires/sendQuestionnaire/sendQuestionnaireSaga";
import updateQuestionnaireSaga from "redux/questionnaires/updateQuestionnaires/updateQuestionnairesSaga";
import deleteQuestionnaireSaga from "redux/questionnaires/deleteQuestionnaires/deleteQuestionnairesSaga";
import sendQuestionHistorySaga from "redux/questionnaires/sendQuestionHistory/sendQuestionHistorySaga";
import questionHistoryDetailSaga from "redux/questionnaires/questionHistoryDetail/questionHistoryDetailSaga";
import answerHistorySaga from "redux/questionnaires/answerHistory/answerHistorySaga";
import mentalCheckRequestsSaga from "redux/mentalHealth/mentalCheckRequests/mentalCheckRequestsSaga";
import typeInformationSaga from "redux/mentalHealth/typeInformation/typeInformationSaga";
import sendNotificationSaga from "redux/mentalHealth/sendNotification/sendNotificationSaga";
import downloadTypeCSVSaga from "redux/mentalHealth/downloadTypeCSV/downloadTypeCSVSaga";
import downloadTypePDFSaga from "redux/mentalHealth/downloadTypePDF/downloadTypePDFSaga";
import scoringInformationSaga from "redux/mentalHealth/scoringInformation/scoringInformationSaga";
import mentalHealthTotalSaga from "redux/mentalHealth/mentalHealthTotal/mentalHealthTotalSaga";
import typeListSaga from "redux/mentalHealth/typeList/typeListSaga";
import lastNotificationTimeSaga from "redux/mentalHealth/lastNotificationTime/lastNotificationTimeSaga";
import classListSaga from "redux/mentalHealth/classList/classListSaga";
import downloadScoringPDFSaga from "redux/mentalHealth/downloadScoringPDF/downloadScoringPDFSaga";
import pushNotificationSaga from "redux/messagemanagement/pushNotification/pushNotificationSagas";
import sendInterviewRequestSaga from "redux/mentalHealth/sendInterviewRequest/sendInterviewRequestSaga";
import downloadCSVQuestionSaga from "redux/questionnaires/downloadCSVQuestion/downloadCSVQuestionSaga";
import downloadCSVAllQuestionsSaga from "redux/questionnaires/downloadCSVAllQuestions/downloadCSVAllQuestionsSaga";
import remindPushNotificationSaga from "redux/questionnaires/remindPushNotification/remindPushNotificationSaga";
import cancelQuestionnaireSaga from "redux/questionnaires/cancelQuestionnaire/cancelQuestionnaireSaga";
import temperatureAvgSaga from "redux/dashboard/temperatureAvg/temperatureAvgSaga";
import detailSymtomSaga from "redux/dashboard/detailSymtom/detailSymtomSaga";
import absenceSaga from "redux/dashboard/absence/absenceSaga";
import feverSaga from "redux/dashboard/fever/feverSaga";
import symtomSaga from "redux/dashboard/symtom/symtomSaga";
import temperatureAmountsSaga from "redux/dashboard/temperatureAmounts/temperatureAmountsSaga";
import progressSaga from "redux/dashboard/progress/progressSaga";
import faceScaleSaga from "redux/dashboard/faceScale/faceScaleSaga";
import healthCheckQuestionsSaga from "redux/bodyTemperature/healthCheckQuestions/healthCheckQuestionsSaga";
import healthCheckAnswersSaga from "redux/bodyTemperature/healthCheckAnswers/healthCheckAnswersSaga";
import sendHealthCheckAnswersSaga from "redux/bodyTemperature/sendHealthCheckAnswers/sendHealthCheckAnswersSaga";
import setHealthCheckSettingSaga from "redux/setting/healthCheckSetting/healthCheckSettingSaga";
import getHealthCheckSettingSaga from "redux/setting/getHealthCheckSetting/getHealthCheckSettingSaga";
import updatePositionColumnSaga from "redux/updatePositionColumn/updatePositionColumnSaga";
import healthCheckProgressSaga from "redux/dashboard/healthCheckProgress/healthCheckProgressSaga";
import attendanceStatusSaga from "redux/dashboard/attendanceStatus/attendanceStatusSaga";
import deseaseOutbreakSaga from "redux/dashboard/deseaseOutbreak/deseaseOutbreakSaga";
import prefectureSaga from "redux/dashboard/prefecture/prefectureSaga";
import citiesSaga from "redux/dashboard/cities/citiesSaga";
import updateDeseaseSaga from "redux/setting/updateDesease/updateDeseaseSaga";
import exchangeListSaga from "redux/consultationRequest/exchangeList/exchangeListSaga";
import consultationListSaga from "redux/consultationRequest/consultationList/consultationListSaga";
import completeRequestSaga from "redux/consultationRequest/completeRequest/completeRequestSaga";
import addRequestSaga from "redux/consultationRequest/addRequest/addRequestSaga";
import consultationSettingsSaga from "redux/consultationRequest/consultationSettings/consultationSettingsSaga";
import saveConsultationSettingsSaga from "redux/consultationRequest/saveConsultationSettings/saveConsultationSettingsSaga";
import updateChartOrderSaga from "redux/setting/updateChartOrder/updateChartOrderSaga";
import updateMessageDeliverySaga from "redux/messagemanagement/updateMessage/updateMessageSaga";
import deleteMessageDeliverySaga from "redux/messagemanagement/deleteMessage/deleteMessageSaga";
import updateRegistrationSaga from "redux/setting/updateUserRegistration/updateUserRegistrationSaga";
import sendNotificationConsultationSaga from "redux/consultationRequest/sendNotification/sendNotificationSaga";

export default function* startForman() {
  yield all([
    ...authenticationSagas,
    ...forgotPasswordSagas,
    ...resetPasswordSagas,
    ...userListSagas,
    ...classListSagas,
    ...departmentListSagas,
    ...subDepartmentListSagas,
    ...activitiesListSagas,
    ...createUserSagas,
    ...updateUserSagas,
    ...deleteUserSagas,
    ...csvDownloadSagas,
    ...userAttendanceSagas,
    ...schoolClassListSagas,
    ...createClassSagas,
    ...updateClassSagas,
    ...schoolTypeSagas,
    ...classTypeSagas,
    ...createBulkClassTypeSagas,
    ...deleteClassTypeSagas,
    ...activityListSagas,
    ...createActivitySagas,
    ...updateActivitySagas,
    ...deleteActivitySagas,
    ...activitiesListFilterSagas,
    ...classListFilterSagas,
    ...studentListSagas,
    ...chartSagas,
    ...statisticsSagas,
    ...destinationSagas,
    ...groupClassSagas,
    ...childDepartmentSagas,
    ...patientChildDepartmentSagas,
    ...createPatientProfileSagas,
    ...detailFormSagas,
    ...patientDetailSagas,
    ...feverPercentageSagas,
    ...getBodyTemperatureCSVSagas,
    ...getBodyTemperaturePDFSagas,
    ...progressBarSagas,
    ...studentListClassChangeSagas,
    ...confirmStudentListSagas,
    ...clearTempRegisterSagas,
    ...putConfirmStudentSagas,
    ...temperatureListFilterSagas,
    ...messageHistoryListSagas,
    ...messageHistoryDetailSagas,
    ...attendanceListFilterSagas,
    ...sendMessageSagas,
    ...getCSVDownloadStudentListSagas,
    ...uploadCSVStudentListSagas,
    ...saveTemporaryStudentListSagas,
    ...downloadQrCodeSagas,
    ...searchConditionSagas,
    ...accountListSagas,
    ...deleteAccountSagas,
    ...accountDepartmentListSagas,
    ...editAccountSagas,
    ...accountRegistrationListSagas,
    ...createAccountSagas,
    ...settingDataSagas,
    ...updateTemperatureSagas,
    ...updateGeneralSagas,
    ...deleteNotificationSagas,
    ...getClassListEndSagas,
    ...readHistoryListSaga,
    ...getRecordListSagas,
    ...getVaccineGraphDataSagas,
    ...createVenuesSagas,
    ...venuesListSagas,
    ...updateVenueSagas,
    ...deleteVenueSagas,
    ...reservationsSaga,
    ...todaySchedulesSaga,
    ...saveScheduleSaga,
    ...updateScheduleSaga,
    ...venuesSaga,
    ...slotsSaga,
    ...deleteSlotSaga,
    ...getScheduleFilterSaga,
    ...timeSlotSaga,
    ...checkListSaga,
    ...checkDetailsSaga,
    ...gradeListSaga,
    ...newMentalCheckSaga,
    ...updateMentalCheckSaga,
    ...deleteMentalCheckSaga,
    ...questionnairesSaga,
    ...questionnairesDetailSaga,
    ...createQuestionnairesSaga,
    ...answerInfoSaga,
    ...individualAnswerSaga,
    ...questionResponseDetailSaga,
    ...duplicateQuestionSaga,
    ...doneQuestionSaga,
    ...sendQuestionnaireSaga,
    ...updateQuestionnaireSaga,
    ...deleteQuestionnaireSaga,
    ...sendQuestionHistorySaga,
    ...questionHistoryDetailSaga,
    ...answerHistorySaga,
    ...mentalCheckRequestsSaga,
    ...typeInformationSaga,
    ...sendNotificationSaga,
    ...downloadTypeCSVSaga,
    ...downloadTypePDFSaga,
    ...scoringInformationSaga,
    ...mentalHealthTotalSaga,
    ...typeListSaga,
    ...lastNotificationTimeSaga,
    ...classListSaga,
    ...downloadScoringPDFSaga,
    ...pushNotificationSaga,
    ...sendInterviewRequestSaga,
    ...downloadCSVQuestionSaga,
    ...downloadCSVAllQuestionsSaga,
    ...remindPushNotificationSaga,
    ...cancelQuestionnaireSaga,
    ...approveAbsenceSagas,
    ...updateMessageSagas,
    ...temperatureAvgSaga,
    ...detailSymtomSaga,
    ...absenceSaga,
    ...feverSaga,
    ...symtomSaga,
    ...temperatureAmountsSaga,
    ...progressSaga,
    ...faceScaleSaga,
    ...healthCheckQuestionsSaga,
    ...healthCheckAnswersSaga,
    ...sendHealthCheckAnswersSaga,
    ...setHealthCheckSettingSaga,
    ...getHealthCheckSettingSaga,
    ...updatePositionColumnSaga,
    ...healthCheckProgressSaga,
    ...attendanceStatusSaga,
    ...deseaseOutbreakSaga,
    ...prefectureSaga,
    ...citiesSaga,
    ...updateDeseaseSaga,
    ...exchangeListSaga,
    ...consultationListSaga,
    ...completeRequestSaga,
    ...addRequestSaga,
    ...consultationSettingsSaga,
    ...saveConsultationSettingsSaga,
    ...updateChartOrderSaga,
    ...updateMessageDeliverySaga,
    ...deleteMessageDeliverySaga,
    ...updateRegistrationSaga,
    ...sendNotificationConsultationSaga,
  ]);
}
