import React, { useState } from "react";
import { Upload } from "antd";
import { useTranslation } from "react-i18next";

import { CloseIcon, RemoveFileIcon, UploadIcon, PDFIcon } from "icons";
import {
  ButtonContainer,
  CancelButton,
  WrapperUpload,
  UploadFileBtn,
  DraggerStyled,
  WrapperFileList,
  WrapperFile,
  PrimaryText,
  SecondaryText,
  WrapperDecription,
} from "./ImageUploader.style";

interface IImageUpload {
  onUploadFileChange?: any;
  fileList: any;
  setFileList: any;
}

const ImageUploader: React.FC<IImageUpload> = (props) => {
  const { onUploadFileChange, fileList, setFileList } = props;

  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });

  // const [fileList, setFileList] = useState<any>([]);

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleOnChange = async (info: any) => {
    const isFileExisted = fileList.some(
      (file: any) => file.file.name === info.file.name
    );
    if (!isFileExisted) {
      const src = await getBase64(info.file.originFileObj);
      const params = [
        ...fileList,
        {
          src,
          ...info,
        },
      ];
      setFileList(params);
      onUploadFileChange(params);
    }
  };

  const handleRemoveFile = (name: string) => {
    const newFileList = fileList.map((file: any) => {
      if (file.file.name === name) {
        return {
          ...file,
          isDelete: true,
        };
      }
      return file;
    });
    setFileList(newFileList);
    onUploadFileChange(newFileList);
  };

  const handleRemoveAllFile = () => {
    setFileList([]);
    onUploadFileChange([]);
  };

  return (
    <WrapperUpload>
      <ButtonContainer>
        <Upload accept=".pdf,.jpg,.png,.jpeg" onChange={handleOnChange}>
          <UploadFileBtn
            background={"#2F8CAE"}
            color={"#fff"}
            padding={"3.5px 14px 4.5px 15px"}
            name={t("select-a-file-and-upload")}
            border={"none"}
            bdr={"6px"}
            fontSize={16}
            fontWeight={700}
            lineHeight={"23px"}
          />
        </Upload>
        <CancelButton onClick={handleRemoveAllFile}>
          <p>{t("delete-uploaded-file")}</p>
          <CloseIcon />
        </CancelButton>
      </ButtonContainer>
      <DraggerStyled
        onChange={handleOnChange}
        openFileDialogOnClick={false}
        multiple
      >
        {!fileList.length && (
          <WrapperDecription>
            <UploadIcon />
            <PrimaryText>{t("file-upload")}</PrimaryText>
            <SecondaryText>{t("the-file-you-want-to-attach")}</SecondaryText>
            <SecondaryText>{t("drag-and-drop")}</SecondaryText>
          </WrapperDecription>
        )}
        <WrapperFileList>
          {fileList.map((item: any, index: any) => {
            if (item?.isDelete) return;
            return (
              <WrapperFile key={index}>
                {item.file.name.substr(item.file.name.lastIndexOf(".") + 1) ===
                "pdf" ? (
                  <div>
                    <PDFIcon fill="#FFFFFF" />
                    <div>PDF</div>
                  </div>
                ) : (
                  <img src={item.src} alt={item.file.name} />
                )}
                <p>{item.file.name}</p>
                <RemoveFileIcon
                  onClick={() => handleRemoveFile(item.file.name)}
                />
              </WrapperFile>
            );
          })}
        </WrapperFileList>
      </DraggerStyled>
    </WrapperUpload>
  );
};

export default ImageUploader;
