import {
  ActionType,
  IUpdateUserRegistrationPayload,
} from "models/setting/updateUserRegistration";

export const updateUserRegistration = (
  payload: IUpdateUserRegistrationPayload
) => {
  return {
    type: ActionType.UPDATE_USER_REGISTRATION_SETTING,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_USER_REGISTRATION_SETTING_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: any) => {
  return {
    type: ActionType.UPDATE_USER_REGISTRATION_SETTING_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.RESET_USER_REGISTRATION_SETTING,
  };
};
