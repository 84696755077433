import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  CheckListData,
  ActionType,
  IMentalCheckListResponse,
} from "models/mentalHealth/checkList";
import { httpStatus } from "configs/httpStatus";
import { fetchMentalCheckList } from "services/mentalHealth";
import { handleSuccesAction, handleErrorAction } from "./checkListActions";

function* checkListSaga({ payload }: { payload: CheckListData }) {
  try {
    const response: IMentalCheckListResponse = yield call(
      fetchMentalCheckList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetCheckListWatcher() {
  yield takeLatest(ActionType.MENTAL_CHECK_LIST as any, checkListSaga);
}

export default [fork(onGetCheckListWatcher)];
