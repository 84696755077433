import { IResponse } from "models";

export enum ActionType {
  // message history detail
  QUESTION_HISTORY_DETAIL = "action/QUESTION_HISTORY_DETAIL",
  QUESTION_HISTORY_DETAIL_SUCCESS = "action/QUESTION_HISTORY_DETAIL_SUCCESS",
  QUESTION_HISTORY_DETAIL_ERROR = "action/QUESTION_HISTORY_DETAIL_ERROR",
  RESET_QUESTION_HISTORY_DETAIL = "action/RESET_QUESTION_HISTORY_DETAIL",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IQuestionHistoryDetailData {
  id: string | string[];
  locale?: string;
}

export interface IQuestionHistoryDetailResponse extends IResponse {
  result: IQuestionHistoryDetail;
}

export interface IQuestionHistoryDetail {
  user_information: IUserInformation[];
  questionnaire_questions: any[];
}

export interface IUserInformation {
  patient_id: number;
  full_name: string;
}
