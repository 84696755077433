import { IResponse } from "models";

export enum ActionType {
  DATA_GROUP_CLASS = "action/DATA_GROUP_CLASS",
  DATA_GROUP_CLASS_SUCCESS = "action/DATA_GROUP_CLASS_SUCCESS",
  DATA_GROUP_CLASS_ERROR = "action/DATA_GROUP_CLASS_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DataGroupClassResponse extends IResponse {
  result: any;
}
