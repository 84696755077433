import {
  Action,
  ActionType,
  PDFDownloadScoringInfoResponse,
} from "models/mentalHealth/pdfDownloadScoringInfo";
import createReducer from "redux/createReducer";

export interface PDFDownloadScoringInfoReducerType {
  loading: boolean;
  error?: string;
  pdfDownloadScoringInfoResponse?: PDFDownloadScoringInfoResponse;
}

const defaultState: PDFDownloadScoringInfoReducerType = {
  loading: false,
  error: undefined,
  pdfDownloadScoringInfoResponse: undefined,
};

export const pdfDownloadScoringInfoReducer =
  createReducer<PDFDownloadScoringInfoReducerType>(defaultState, {
    [ActionType.PDF_DOWNLOAD_SCORING_INFO](
      state: PDFDownloadScoringInfoReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.PDF_DOWNLOAD_SCORING_INFO_ERROR](
      state: PDFDownloadScoringInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.PDF_DOWNLOAD_SCORING_INFO_SUCCESS](
      state: PDFDownloadScoringInfoReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        pdfDownloadScoringInfoResponse: action.payload,
      };
    },

    [ActionType.PDF_DOWNLOAD_SCORING_INFO_RESET_DATA](
      state: PDFDownloadScoringInfoReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        pdfDownloadScoringInfoResponse: [],
      };
    },
  });
