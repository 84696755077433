import {
  Action,
  ActionType,
  IConsultationSettingsResponse,
} from "models/consultationRequest/consultationSettings";
import createReducer from "redux/createReducer";

export interface ConsultationSettingsReducerType {
  loading: boolean;
  error?: any;
  consultationSettingsResponse?: IConsultationSettingsResponse;
}

const defaultState: ConsultationSettingsReducerType = {
  loading: false,
  error: undefined,
  consultationSettingsResponse: undefined,
};

export const consultationSettingsReducer =
  createReducer<ConsultationSettingsReducerType>(defaultState, {
    [ActionType.CONSULTATION_SETTINGS](state: ConsultationSettingsReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CONSULTATION_SETTINGS_ERROR](
      state: ConsultationSettingsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        consultationSettingsResponse: undefined,
      };
    },

    [ActionType.CONSULTATION_SETTINGS_SUCCESS](
      state: ConsultationSettingsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        consultationSettingsResponse: action.payload,
      };
    },

    [ActionType.CONSULTATION_SETTINGS_RESET](
      state: ConsultationSettingsReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        consultationSettingsResponse: undefined,
      };
    },
  });
