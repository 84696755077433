import * as React from "react";

function CircleNoBackgroundIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="9"
        fill="white"
        stroke={props.fill}
        strokeWidth="2"
      />
    </svg>
  );
}

export default CircleNoBackgroundIcon;
