import { IResponse } from "models";

export enum ActionType {
  CONSULTATION_LIST = "action/CONSULTATION_LIST",
  CONSULTATION_LIST_SUCCESS = "action/CONSULTATION_LIST_SUCCESS",
  CONSULTATION_LIST_ERROR = "action/CONSULTATION_LIST_ERROR",
  CONSULTATION_LIST_RESET = "action/CONSULTATION_LIST_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IConsultationListPayload {
  is_uncompleted: boolean;
  page: number;
  per_page: number;
}

export interface IConsultationItem {
  id: number;
  first_name: string;
  last_name: string;
  furi_first_name: string;
  furi_last_name: string;
  roll_number: number;
  class_name: string;
  content: string;
  status: number;
  request_type: number;
  created_at: string;
  updated_at: string;
  is_latest_created_at: boolean;
}

export interface IConsultationListResponse extends IResponse {
  result: IConsultationItem[];
  total_record: number;
  current_page: number;
  next_page: number;
}
