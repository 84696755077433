import React from "react";
import { Checkbox } from "antd";
import styled from "styled-components";

interface ICheckboxItem {
  value?: string | number;
  key?: number;
  name?: string;
}

interface ICheckbox {
  row?: boolean;
  onChange?: (e: any) => void;
  list: ICheckboxItem[];
  defaultValue?: string[];
  value?: string[] | number[];
}

const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
  alignItems: "unset",
  outline: "none",
  "&:hover": {
    ".ant-checkbox-inner": {
      borderColor: theme.colors.border,
    },
  },
  ".ant-checkbox": {
    top: "unset",
  },
  ".ant-checkbox-inner": {
    width: 20,
    height: 21,
    border: `2px solid ${theme.colors.border}`,
  },
  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.colors.button,
    borderColor: `${theme.colors.button}!important`,
  },
  ".ant-checkbox-checked::after": {
    border: "none",
  },
  ".ant-checkbox-input:focus + .ant-checkbox-inner": {
    borderColor: theme.colors.border,
  },
}));

const CheckboxCustom: React.FC<ICheckbox> = ({
  list,
  onChange,
  defaultValue,
  value,
}) => {
  return (
    <Checkbox.Group
      style={{ width: "100%" }}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
    >
      <div style={{ display: "flex", flexWrap: "wrap", gap: 28 }}>
        {list.map((item) => (
          <div key={item.key}>
            <CheckboxStyled value={item.value}>
              <div style={{ fontSize: 18, lineHeight: "18px" }}>
                {item.name}
              </div>
            </CheckboxStyled>
          </div>
        ))}
      </div>
    </Checkbox.Group>
  );
};

export default CheckboxCustom;
