import React from "react";
import { Grid } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

import {
  BoxItem,
  BoxHeader,
  BoxTitle,
  LightDivider,
  BoxContent,
  WrapperLegendLineChart,
  GreenDot,
  BlueDot,
  PurpleDot,
  WrapperChart,
  ContainerChart,
  WrapperChartFever,
} from "../Vaccination.style";

const { useBreakpoint } = Grid;

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={0} dy={18} fill="#3B3B3B">
        {payload.value}
      </text>
    </g>
  );
};

const LineChartCustom = ({ data }: any) => {
  const DotCustom = (color: string) => {
    return {
      stroke: color,
      strokeWidth: 1,
      r: 4,
      fill: color,
      strokeDasharray: "",
    };
  };
  return (
    <ContainerChart>
      <ResponsiveContainer width="100%" height={240}>
        <LineChart
          margin={{ top: -10, right: 10, left: -24, bottom: 0 }}
          data={data}
        >
          <CartesianGrid vertical={false} strokeDasharray="2 2" />
          <XAxis
            dy={4}
            tickLine={false}
            dataKey="date"
            minTickGap={-100}
            tick={<CustomizedXAxisTick />}
            stroke="#CCCCCC"
            padding={{ left: 16, right: 16 }}
          />
          <YAxis
            type="number"
            tickLine={false}
            interval={0}
            width={55}
            ticks={[0, 20, 40, 60, 80, 100, 110]}
            domain={[0, 110]}
            tick={{ fill: "#3B3B3B" }}
            stroke="#CCCCCC"
          />
          <Line
            isAnimationActive={false}
            dataKey="firstTime"
            stroke="#2AC769"
            strokeDasharray="3 3"
            dot={DotCustom("#2AC769")}
          />
          <Line
            isAnimationActive={false}
            dataKey="secondTime"
            stroke="#2A98C7"
            strokeDasharray="3 3"
            dot={DotCustom("#2A98C7")}
          />
          <Line
            isAnimationActive={false}
            dataKey="thirdTime"
            stroke="#C72AB7"
            strokeDasharray="3 3"
            dot={DotCustom("#C72AB7")}
          />
        </LineChart>
      </ResponsiveContainer>
    </ContainerChart>
  );
};

const Fever = ({ data }: any) => {
  const screens = useBreakpoint();
  const { t } = useTranslation("translation", {
    keyPrefix: "vaccination",
  });

  return (
    <WrapperChartFever>
      <BoxItem className="chart-one" style={{ paddingBottom: 16 }}>
        <BoxHeader>
          <BoxTitle>{t("inoculation-rate-title")}</BoxTitle>
          {screens.md && (
            <div
              style={{
                fontWeight: 700,
                fontSize: 14,
                lineHeight: "14px",
              }}
            >
              [{t("all")} {data.inoculated}/{data.total}]
            </div>
          )}
        </BoxHeader>
        <LightDivider />
        <BoxContent>
          <div style={{ display: "flex", marginBottom: 23 }}>
            <WrapperLegendLineChart style={{ marginRight: 16 }}>
              <GreenDot />
              <span>...</span>
              <p>{t("first-time")}</p>
            </WrapperLegendLineChart>
            <WrapperLegendLineChart style={{ marginRight: 16 }}>
              <BlueDot />
              <span>...</span>
              <p>{t("second-time")}</p>
            </WrapperLegendLineChart>
            <WrapperLegendLineChart>
              <PurpleDot />
              <span>...</span>
              <p>{t("third-time")}</p>
            </WrapperLegendLineChart>
          </div>

          <WrapperChart>
            <div
              style={{
                fontSize: 12,
                lineHeight: "12px",
                color: "#666666",
                margin: "0px 0px 4px 4px",
              }}
            >
              (%)
            </div>
            <LineChartCustom data={data.lineChartData} />
          </WrapperChart>
        </BoxContent>
      </BoxItem>
    </WrapperChartFever>
  );
};

export default Fever;
