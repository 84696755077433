import { Action, ActionType } from "models/activitymanagement/deleteActivity";
import { IResponse } from "models";
import createReducer from "../../createReducer";

export interface DeleteActivityReducerType {
  loading: boolean;
  error?: string;
  deleteActivityResponse?: IResponse;
}

const defaultState: DeleteActivityReducerType = {
  loading: false,
  error: undefined,
  deleteActivityResponse: undefined,
};

export const deleteActivityReducer = createReducer<DeleteActivityReducerType>(
  defaultState,
  {
    [ActionType.DELETE_ACTIVITY](state: DeleteActivityReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_ACTIVITY_ERROR](
      state: DeleteActivityReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DELETE_ACTIVITY_SUCCESS](
      state: DeleteActivityReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteActivityResponse: action.payload,
      };
    },
  }
);
