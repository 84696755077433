import { ActionType, GradeListResponse } from "models/mentalHealth/gradeList";

export const gradeListAction = () => {
  return {
    type: ActionType.GRADE_LIST,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GRADE_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: GradeListResponse) => {
  return {
    type: ActionType.GRADE_LIST_SUCCESS,
    payload,
  };
};
