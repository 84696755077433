import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  AccountRegistrationListResponse,
} from "models/account/registrationList";
import { httpStatus } from "configs/httpStatus";
import { fetchDataRegistrationList } from "services/account";

import {
  handleSuccesAction,
  handleErrorAction,
} from "./registrationListActions";

function* getRegistrationListSaga() {
  try {
    const response: AccountRegistrationListResponse = yield call(
      fetchDataRegistrationList
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetRegistrationListWatcher() {
  yield takeLatest(
    ActionType.ACCOUNT_REGISTRATION_LIST as any,
    getRegistrationListSaga
  );
}

export default [fork(onGetRegistrationListWatcher)];
