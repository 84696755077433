import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  // get sub department list
  SUB_DEPARTMENT_LIST = "action/SUB_DEPARTMENT_LIST",
  SUB_DEPARTMENT_LIST_SUCCESS = "action/SUB_DEPARTMENT_LIST_SUCCESS",
  SUB_DEPARTMENT_LIST_ERROR = "action/SUB_DEPARTMENT_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface SubDepartmentListData {
  locale?: string | null;
  department_id: number | [] | (string | undefined)[];
  role?: number | null;
}

export interface SubDepartmentListResponse extends IResponse {
  result?: IOption[];
}
