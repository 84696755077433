import { ActionType, VenuesListResponse } from "models/vaccine/venues";

export const getVenuesAction = (payload?: any) => {
  return {
    type: ActionType.VENUES_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.VENUES_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: VenuesListResponse) => {
  return {
    type: ActionType.VENUES_LIST_SUCCESS,
    payload,
  };
};
