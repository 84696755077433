import {
  ScheduleData,
  ActionType,
  SaveScheduleResponse,
} from "models/vaccine/schedules";

export const saveScheduleAction = (payload: ScheduleData) => {
  return {
    type: ActionType.SAVE_SCHEDULE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SAVE_SCHEDULE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: SaveScheduleResponse) => {
  return {
    type: ActionType.SAVE_SCHEDULE_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.SAVE_SCHEDULE_RESET,
  };
};
