import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PASSWORD_REGEX } from "constant";
import { Input, Button } from "components";
import {
  selectIsLoading,
  selectErrorMessage,
} from "redux/resetpassword/resetpasswordStates";
import { ResetPasswordData } from "models/resetPassword";
import { ResetPasswordActionCreators } from "redux/rootActions";

const schema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required("password-required")
    .matches(PASSWORD_REGEX, "password-requirements"),
  confirmPassword: yup
    .string()
    .required("confirm-password-required")
    .oneOf([yup.ref("password")], "password-not-match"),
});

const ResetPassword: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "auth" });
  const errorMessage = useSelector(selectErrorMessage);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const { watch, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const watchPasswordField = watch("password");
  const watchConfirmPasswordField = watch("confirmPassword");

  const params = new URLSearchParams(window.location.search);
  const reset_password_token = params.get("reset_password_token");
  const role = params.get("role");

  useEffect(() => {
    dispatch(ResetPasswordActionCreators.handleErrorAction(""));
  }, [watchPasswordField, watchConfirmPasswordField]);

  const onSubmit = (data: { [x: string]: any }) => {
    const params: ResetPasswordData = {
      user: {
        password: data.password,
        password_confirmation: data.confirmPassword,
        reset_password_token: reset_password_token,
        role: Number(role),
      },
    };
    dispatch(ResetPasswordActionCreators.resetPasswordUserAction(params));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="password"
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Input
            label={t("new-password")}
            type="password"
            onChange={onChange}
            error={t(error?.message)}
            marginForm="0 0 20px 0"
            fs={16}
            fwLabel={500}
          />
        )}
      />
      <Controller
        control={control}
        name="confirmPassword"
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Input
            label={t("confirm-password")}
            type="password"
            onChange={onChange}
            error={t(error?.message)}
            marginForm="0 0 20px 0"
            fs={16}
            fwLabel={500}
          />
        )}
      />
      <div style={{ position: "relative" }}>
        {errorMessage && (
          <div
            style={{
              color: "#FB2121",
              fontSize: 12,
              fontWeight: 500,
              lineHeight: "12px",
              position: "absolute",
              top: -15,
            }}
          >
            {errorMessage === "は不正な値です"
              ? t("enter-value-valid")
              : errorMessage}
          </div>
        )}
        <Button
          name={t("change-password")}
          type="submit"
          background="#2F8CAE"
          color="#fff"
          bdr="6px"
          width="100%"
          margin="4px 0 0 0"
          padding="8px 0px"
          border="none"
          fontWeight={700}
          fontSize={16}
          disabled={isLoading}
        />
      </div>
    </form>
  );
};

export default ResetPassword;
