import { ActionType, EditAccountResponse } from "models/account/editAccount";

export const editAccountAction = (payload: any) => {
  return {
    type: ActionType.EDIT_ACCOUNT,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.EDIT_ACCOUNT_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: EditAccountResponse) => {
  return {
    type: ActionType.EDIT_ACCOUNT_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.RESET_EDIT_ACCOUNT,
  };
};
