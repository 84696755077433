import * as React from "react";

function PullDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="10"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="10" rx="1" fill={props.fill} />
      <path d="M16 1L17.7321 4H14.2679L16 1Z" fill="white" />
      <path d="M16 9L14.2679 6L17.7321 6L16 9Z" fill="white" />
    </svg>
  );
}

export default PullDownIcon;
