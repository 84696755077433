import { createApiCall2, MethodType, URI } from "../Api";

export const fetchDataList = (payload: any) => {
  return createApiCall2(MethodType.GET, URI.ACCOUNT.GET_DATA_LIST, payload);
};

export const fetchDepartmentList = (payload: any) => {
  return createApiCall2(
    MethodType.GET,
    URI.ACCOUNT.GET_DEPARTMENT_LIST,
    payload
  );
};

export const deleteAccount = (payload: any) => {
  return createApiCall2(MethodType.DELETE, URI.ACCOUNT.DELETE_ACCOUNT, payload);
};

export const editAccount = (payload: any) => {
  return createApiCall2(MethodType.PUT, URI.ACCOUNT.EDIT_ACCOUNT, payload);
};

export const fetchDataRegistrationList = () => {
  return createApiCall2(MethodType.GET, URI.ACCOUNT.REGISTRATION_LIST);
};

export const createAccount = (payload: any) => {
  return createApiCall2(MethodType.POST, URI.ACCOUNT.CREATE_ACCOUNT, payload);
};
