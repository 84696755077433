import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";

import { Button } from "components";
import {
  UpdateDeseaseSettingActionCreators,
  SettingDataActionCreators,
  GetHealthCheckSettingActionCreators,
} from "redux/rootActions";
import { settingDataResponse } from "redux/setting/settingData/settingDataStates";
import { updateDeseaseSettingResponse } from "redux/setting/updateDesease/updateDeseaseStates";
import { SendCompleteIcon } from "icons";
import { httpStatus } from "configs/httpStatus";

import {
  WrapperDeseaseOutbreakSection,
  WrapperTitle,
  FormTitle,
  CheckboxStyled,
  CheckItem2,
} from "./Setting.style";

const DeseaseOutbreakSetting = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "setting" });

  const dispatch = useDispatch();
  const settingData = useSelector(settingDataResponse);
  const updateDeseaseSetting = useSelector(updateDeseaseSettingResponse);

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (settingData && Object.keys(settingData.result).length) {
      const { is_disease_outbreak_graph_display_for_patients } =
        settingData?.result;
      setIsChecked(is_disease_outbreak_graph_display_for_patients);
    }
  }, [settingData]);

  useEffect(() => {
    if (
      updateDeseaseSetting &&
      updateDeseaseSetting?.status === httpStatus.StatusOK
    ) {
      notification.success({
        message: t("temperature-setting-apply"),
        description: "",
        icon: <SendCompleteIcon />,
        placement: "bottomRight",
      });
      dispatch(UpdateDeseaseSettingActionCreators.handleResetAction());
      dispatch(SettingDataActionCreators.getSettingDataAction());
      dispatch(
        GetHealthCheckSettingActionCreators.getHealthCheckSettingAction()
      );
    }
  }, [updateDeseaseSetting]);

  const handleUpdateDeseaseSetting = () => {
    const param = {
      is_disease_outbreak_graph_display_for_patients: isChecked,
    };

    dispatch(UpdateDeseaseSettingActionCreators.updateDeseaseAction(param));
  };

  return (
    <WrapperDeseaseOutbreakSection>
      <WrapperTitle>
        <FormTitle>{t("desease-outbreak-setting")}</FormTitle>
      </WrapperTitle>
      <div style={{ fontSize: 14, lineHeight: "20px", marginBottom: 16 }}>
        {t("desease-outbreak-description")}
      </div>
      <div
        style={{
          marginBottom: 24,
          display: "flex",
          height: 24,
          alignItems: "center",
        }}
      >
        <CheckboxStyled
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        >
          <CheckItem2>{t("indicate")}</CheckItem2>
        </CheckboxStyled>
      </div>
      <Button
        type="submit"
        name={t("keep")}
        background="#2AC769"
        color="#FFFFFF"
        border="none"
        fontSize={16}
        fontWeight={700}
        lineHeight="23px"
        padding="4px 16px"
        onClick={handleUpdateDeseaseSetting}
      />
    </WrapperDeseaseOutbreakSection>
  );
};

export default DeseaseOutbreakSetting;
