import {
  Action,
  ActionType,
  HealthCheckAnswersResponse,
} from "models/bodyTemperature/healthCheckAnswers";
import createReducer from "redux/createReducer";

export interface HealthCheckAnswersReducerType {
  loading: boolean;
  error?: string;
  healthCheckAnswersResponse?: HealthCheckAnswersResponse;
}

const defaultState: HealthCheckAnswersReducerType = {
  loading: false,
  error: undefined,
  healthCheckAnswersResponse: undefined,
};

export const healthCheckAnswersReducer =
  createReducer<HealthCheckAnswersReducerType>(defaultState, {
    [ActionType.HEALTH_CHECK_ANSWER](state: HealthCheckAnswersReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.HEALTH_CHECK_ANSWER_ERROR](
      state: HealthCheckAnswersReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        healthCheckAnswersResponse: undefined,
      };
    },

    [ActionType.HEALTH_CHECK_ANSWER_SUCCESS](
      state: HealthCheckAnswersReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        healthCheckAnswersResponse: action.payload,
      };
    },

    [ActionType.HEALTH_CHECK_ANSWER_RESET](
      state: HealthCheckAnswersReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        healthCheckAnswersResponse: undefined,
      };
    },
  });
