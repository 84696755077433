import * as React from "react";

function CloseSidebarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.8 5.99597H9.2C8.35131 5.99597 7.53737 6.33311 6.93726 6.93323C6.33714 7.53334 6 8.34728 6 9.19597V22.796C6 23.6447 6.33714 24.4586 6.93726 25.0587C7.53737 25.6588 8.35131 25.996 9.2 25.996H22.8C23.6487 25.996 24.4626 25.6588 25.0627 25.0587C25.6628 24.4586 26 23.6447 26 22.796V9.19597C26 8.34728 25.6628 7.53334 25.0627 6.93323C24.4626 6.33311 23.6487 5.99597 22.8 5.99597ZM23.8 22.532C23.8 22.8672 23.6668 23.1887 23.4298 23.4258C23.1927 23.6628 22.8712 23.796 22.536 23.796H9.46399C9.12908 23.7949 8.80818 23.6614 8.57136 23.4246C8.33454 23.1878 8.20105 22.8669 8.2 22.532V9.45997C8.20105 9.12506 8.33454 8.80417 8.57136 8.56735C8.80818 8.33053 9.12908 8.19703 9.46399 8.19597H22.536C22.8712 8.19597 23.1927 8.32914 23.4298 8.56619C23.6668 8.80323 23.8 9.12474 23.8 9.45997V22.532Z"
        fill="#3B3B3B"
      />
      <path
        d="M18.352 22.648C18.0338 22.6477 17.7288 22.5211 17.504 22.296L12.004 16.796C11.7793 16.571 11.6531 16.266 11.6531 15.948C11.6531 15.63 11.7793 15.325 12.004 15.1L17.396 9.70397C17.621 9.47925 17.926 9.35303 18.244 9.35303C18.562 9.35303 18.867 9.47925 19.092 9.70397C19.2039 9.81546 19.2927 9.94792 19.3532 10.0938C19.4138 10.2396 19.445 10.396 19.445 10.554C19.445 10.7119 19.4138 10.8683 19.3532 11.0142C19.2927 11.16 19.2039 11.2925 19.092 11.404L14.564 15.948L19.216 20.6C19.4407 20.825 19.567 21.13 19.567 21.448C19.567 21.766 19.4407 22.071 19.216 22.296C19.1028 22.4094 18.968 22.4989 18.8196 22.5594C18.6712 22.6198 18.5122 22.65 18.352 22.648Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}

export default CloseSidebarIcon;
