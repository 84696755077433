import { IResponse } from "models";

export enum ActionType {
  HEALTH_CHECK_ANSWER = "action/HEALTH_CHECK_ANSWER",
  HEALTH_CHECK_ANSWER_SUCCESS = "action/HEALTH_CHECK_ANSWER_SUCCESS",
  HEALTH_CHECK_ANSWER_ERROR = "action/HEALTH_CHECK_ANSWER_ERROR",
  HEALTH_CHECK_ANSWER_RESET = "action/HEALTH_CHECK_ANSWER_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface HealthCheckAnswersParams {
  patient_id: number;
  payload: {
    created_at_to?: string;
    app_language_id?: string;
  };
}

export interface HealthCheckAnswersResponse extends IResponse {
  answers: any;
  facescales: any;
  temperature: any;
}
