import { IResponse } from "models";

export enum ActionType {
  // get user list
  GRADE_LIST = "action/GRADE_LIST",
  GRADE_LIST_SUCCESS = "action/GRADE_LIST_SUCCESS",
  GRADE_LIST_ERROR = "action/GRADE_LIST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGrade {
  dept_id: number;
  dept_name: string;
}

export interface GradeListResponse extends IResponse {
  result?: IGrade[];
}
