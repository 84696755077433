import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { fetchDataSetting } from "services/setting";
import {
  ActionType,
  IGetSettingDataResponse,
} from "models/setting/settingData";
import { localStorageHelper } from "utils";
import i18n from "configs/i18n";

import { handleSuccesAction, handleErrorAction } from "./settingDataActions";

function* getSettingDataSaga() {
  try {
    const response: IGetSettingDataResponse = yield call(fetchDataSetting);
    if (response.status === httpStatus.StatusOK) {
      const { app_languages, temperature_managerment_info, time_zones } =
        response?.result;
      app_languages.forEach((language: any) => {
        if (language.id === temperature_managerment_info.app_language_id) {
          i18n.changeLanguage(language.language_code);
          localStorageHelper.setItem("i18nextLng", language.language_code);
        }
      });
      time_zones.forEach((timeZone: any) => {
        if (timeZone.id === temperature_managerment_info.time_zone_id) {
          localStorageHelper.setItem("currentTimeZone", timeZone.offset);
        }
      });
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetSettingDataWatcher() {
  yield takeLatest(ActionType.GET_SETTING_DATA as any, getSettingDataSaga);
}

export default [fork(onGetSettingDataWatcher)];
