import {
  ActionType,
  UserListResponse,
  UserListData,
} from "../../models/userlist";

export const userListAction = (payload: UserListData) => {
  return {
    type: ActionType.USER_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.USER_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: UserListResponse) => {
  return {
    type: ActionType.USER_LIST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.USER_LIST_RESET_DATA,
  };
};
