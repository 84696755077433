import { IResponse } from "models";

export enum ActionType {
  BODY_TEMPERATURE_SETTING_FORM_DATA = "action/BODY_TEMPERATURE_SETTING_FORM_DATA",
  BODY_TEMPERATURE_SETTING_FORM_DATA_SUCCESS = "action/BODY_TEMPERATURE_SETTING_FORM_DATA_SUCCESS",
  BODY_TEMPERATURE_SETTING_FORM_DATA_ERROR = "action/BODY_TEMPERATURE_SETTING_FORM_DATA_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface BodyTemperatureSettingFormResponse extends IResponse {
  result: any;
}
