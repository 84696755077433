import { ActionType, SlotsDataResponse } from "models/vaccine/slots";

export const getSlotsAction = (payload?: any) => {
  return {
    type: ActionType.SLOTS,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SLOTS_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: SlotsDataResponse) => {
  return {
    type: ActionType.SLOTS_SUCCESS,
    payload,
  };
};
