import {
  ActionType,
  ProgressBarResponse,
} from "models/bodyTemperature/progressBar";

export const getDataProgressBar = (payload: { date: string }) => {
  return {
    type: ActionType.PROGRESS_BAR,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.PROGRESS_BAR_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ProgressBarResponse) => {
  return {
    type: ActionType.PROGRESS_BAR_SUCCESS,
    payload,
  };
};
