export enum ActionType {
  SEND_INTERVIEW_REQUEST = "action/SEND_INTERVIEW_REQUEST",
  SEND_INTERVIEW_REQUEST_SUCCESS = "action/SEND_INTERVIEW_REQUEST_SUCCESS",
  SEND_INTERVIEW_REQUEST_ERROR = "action/SEND_INTERVIEW_REQUEST_ERROR",
  RESET_SEND_INTERVIEW_REQUEST = "action/RESET_SEND_INTERVIEW_REQUEST",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface SendInterviewRequestPayload {
  mental_check_request_patient_id?: string;
  consult_flag?: number;
}
