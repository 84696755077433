import styled from "styled-components";
import { Tooltip, Checkbox } from "antd";

import { theme } from "theme/theme";
import { Modal, Select, Button, Collapse, TextArea, Table } from "components";

export const Alert = styled.div<{ background: string }>`
  ${theme.media.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 13px;
    height: 77px;
    background: ${(props) => props.background};
    color: ${theme.colors.white};
    position: absolute;
    right: 0;
    top: -21px;
    border-radius: 8px;
    font-size: 20px;
    line-height: 20px;
    font-weight: ${theme.fontWeight.bold};
    max-width: 68%;
  }
  ${theme.media.laptop} {
    max-width: none;
  }
`;

export const AlertMobile = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 71px;
  height: 77px;
  padding-left: 8px;
  background: ${(props) => props.background};
  color: ${theme.colors.white};
  position: absolute;
  right: 0;
  top: -21px;
  border-radius: 8px;
  font-size: 20px;
  line-height: 20px;
  font-weight: ${theme.fontWeight.bold};
  z-index: 2;
`;

export const Tooltip4AlertMobile = styled.div`
  position: absolute;
  top: -21px;
  right: 75px;
  height: 77px;
  width: 173px;
  background: ${theme.colors.white};
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.bold};
  line-height: 20px;
  padding: 8px;
  z-index: 100;
  border-radius: 8px;
  border: 1px solid ${theme.colors.border};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: 45%;
    right: -4px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 45%;
    right: -1px;
    width: 6px;
    height: 10px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const WrapperBodyTemperature = styled.div`
  position: relative;
`;

export const WapperButtonStyled = styled.div`
  display: flex;
  ${theme.media.mobileS} {
    justify-content: center;
  }
  ${theme.media.tablet} {
    justify-content: flex-start;
  }
`;
export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  height: 34px;
  padding: 18px 16px 16px 32px;
  ${theme.media.mobileS} {
    width: 45%;
    justify-content: center;
  }
  ${theme.media.tablet} {
    width: auto;
    justify-content: flex-start;
  }
`;

export const WrapperAdditionContent = styled.div(({ theme }) => ({
  background: theme.colors.white,
  padding: "8px 16px 16px",
  width: "100%",
  borderRadius: 6,
}));

export const BodyTemperatureHeader = styled.div(({ theme }) => ({
  color: theme.colors.titleText,
  fontSize: 17,
  fontWeight: theme.fontWeight.bold,
  lineHeight: "24px",
  marginBottom: 8,
}));

export const LightDivider = styled.div(({ theme }) => ({
  height: 1,
  width: "100%",
  background: theme.colors.background,
  margin: "9px 0 9px",
}));

export const LightDivider4Chart = styled.div`
  height: 1px;
  width: 100%;
  background: ${theme.colors.background};
  margin: 9px 0 17px;
  ${theme.media.laptopL} {
    margin: 9px 0 8px;
  }
`;

export const LightDivider4SymtomsDetailChart = styled(LightDivider4Chart)`
  width: calc(100% - 16px);
`;

export const MediumDivider = styled.div(({ theme }) => ({
  height: 2,
  width: "100%",
  backgroundColor: theme.colors.border,
  marginTop: 4,
}));

export const WrapperTemperatureInfo = styled.div(({ theme }) => ({
  background: theme.colors.background,
  borderRadius: 8,
  padding: "8px 16px",
  marginBottom: 8,
}));

export const WrapperTemperatureItem = styled.div(({ theme }) => ({
  padding: "4px 0",
  borderBottom: `1px solid ${theme.colors.border}`,
  div: {
    fontSize: 14,
    fontWeight: theme.fontWeight.regular,
  },
  p: {
    fontSize: 14,
    fontWeight: theme.fontWeight.bold,
  },
  "&:last-child": {
    borderBottom: "unset",
  },
}));

export const ButtonDisabledStyled = styled(Button)`
  &:disabled {
    background: ${theme.colors.white};
    color: ${theme.colors.textFieldBackground};
    border-color: ${theme.colors.textFieldBackground};
    svg path {
      fill: ${theme.colors.textFieldBackground};
    }
  }
`;

export const ButtonDisabledStyledWithBackground = styled(Button)`
  &:disabled {
    background: ${theme.colors.textFieldBackground};
    color: ${theme.colors.white};
    border-color: ${theme.colors.textFieldBackground};
  }
`;

export const WrapperBtnForm = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  border-top: 0;

  button {
    width: 148px;
    padding: 11px 0 13px;
  }

  ${theme.media.tablet} {
    padding: 32px 0px 24px;
    button:first-child {
      width: unset;
      padding: 11px 24px 13px;
    }
    button:last-child {
      width: unset;
      padding: 8px 32px 9px;
    }
  }
`;

export const WrapperBtnWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  border-top: 0;

  button {
    width: 148px;
    padding: 11px 0 13px;
  }

  button:last-child {
    width: 148px;
    padding: 11px 0 13px 20px;
  }

  ${theme.media.tablet} {
    padding: 32px 0px 24px;
    button:first-child {
      width: unset;
      padding: 11px 24px 13px;
    }
    button:last-child {
      width: unset;
      padding: 11px 24px 13px 32px;
    }
  }
`;

export const AdditionalModalStyled = styled(Modal)`
  .ant-modal-header {
    background: #fbfbfb;
  }
  .ant-modal-body {
    border-radius: 8px;
    background: #fbfbfb;
  }
  ${theme.media.tablet} {
    top: 100px;
    transform: unset;
    height: 1300px;
  }
  ${theme.media.laptopL} {
    height: unset;
  }
`;

export const DetailModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-close {
    top: 9px;
    right: 5px;
  }
`;

export const WrapperSelectStyled = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;

export const BoxItem = styled.div`
  background: #fbfbfb;
  border-radius: 8px;
  border: 1px solid ${theme.colors.background};
  padding: 16px 16px 24px;
  position: relative;
  z-index: 1;
  ${theme.media.tablet} {
    height: 274px;
  }
`;

export const BoxItemStyled = styled(BoxItem)`
  padding: 16px 0 24px 16px;
`;

export const BoxItem4Chart = styled(BoxItem)`
  padding: 16px 16px 20px;
  ${theme.media.laptopL} {
    padding: 16px 8px 20px;
  }
  @media (min-width: 1280px) {
    padding: 16px 16px 20px;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BoxTitle = styled.div(({ theme }) => ({
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.bold,
  lineHeight: "24px",
  color: theme.colors.text.primary,
}));

export const BoxContent = styled.div`
  & > div {
    margin-bottom: 16px;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const BoxContent4Chart = styled.div``;

export const WrapperSelect = styled.div`
  .ant-select {
    width: 123px;
  }
  margin-bottom: 24px;
  display: flex;
  gap: 12px;
  ${theme.media.tablet} {
    left: 226px;
    .ant-select {
      width: 156px;
    }
  }
  ${theme.media.laptopL} {
    .ant-select {
      width: 220px;
    }
  }
`;

export const WrapperCloseIcon = styled.div`
  position: absolute;
  background: ${theme.colors.white};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: -9px;
  left: -10px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.12);
  font-size: ${theme.sizes.sm};
  padding-left: 2px;
  cursor: pointer;
`;

export const CollapseStyled = styled(Collapse)`
  position: relative;
  margin-bottom: 24px;
  .ant-collapse-header {
    width: fit-content;
  }
  .ant-collapse-header svg {
    top: 40% !important;
    right: -32px !important;
  }
  ${theme.media.laptopL} {
    margin-bottom: 32px;
  }
`;

export const SelectStyled = styled(Select)`
  .ant-select-selection-item {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    letter-spacing: 1px;
  }
`;

export const WrapperSearchCondition = styled.div`
  ${theme.media.tablet} {
    display: flex;
  }
`;

export const SearchConditionContainer = styled.div`
  ${theme.media.tablet} {
    display: flex;
  }
`;

export const WrapperDatePicker = styled.div`
  ${theme.media.tablet} {
    margin-right: 14px;
  }
`;

export const WrapperSearchConditionAction = styled.div`
  margin-top: 8px;
  border-bottom: 1px solid ${theme.colors.textFieldBackground};
  margin-bottom: 16px;
  ${theme.media.tablet} {
    padding-left: 16px;
    margin-right: 16px;
    margin-bottom: 0;
    border-left: 1px solid ${theme.colors.textFieldBackground};
    border-right: 1px solid ${theme.colors.textFieldBackground};
    border-bottom: none;
    width: 364px;
  }
  ${theme.media.laptopL} {
    width: unset;
    max-width: 740px;
  }
`;

export const WrapperSearchConditionButton = styled.div`
  display: flex;
  justify-content: flex-end;
  ${theme.media.tablet} {
    width: 140px;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const WrapperTableAction = styled.div`
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  ${theme.media.laptopL} {
    margin-bottom: 16px;
  }
`;

export const WrapperSizeChange = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const WrapperPaginationTop = styled.div`
  text-align: center;
  margin: 24px 0;
  ${theme.media.tablet} {
    text-align: right;
    margin: 0;
  }
`;

export const WrapperPaginationBottom = styled.div`
  text-align: center;
  margin: 24px 0;
  ${theme.media.tablet} {
    text-align: right;
  }
  ${theme.media.laptopL} {
    margin: 16px 0 24px;
  }
`;

export const AndCondition = styled.div`
  margin-top: 7px;
  margin-right: 8px;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.sizes.sm};
  line-height: 16px;
  color: ${theme.colors.icon};
`;

export const TitleDetailModal = styled.div`
  display: flex;
  flex-direction: column;
  ${theme.media.tablet} {
    flex-direction: row;
  }
`;

export const WrapperCheckbox = styled.div`
  .ant-checkbox-wrapper {
    align-items: center;
  }
  .ant-checkbox-inner {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 4px;
  }
  .ant-checkbox-inner::after {
    left: 25.5%;
  }
`;

export const WrapperChartText = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
`;

export const ContainerChartText = styled.div`
  transform: rotateY(180deg);
  text-align: center;
  span {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.md};
    line-height: 16px;
    padding-left: 4px;
  }
  div:first-child {
    font-weight: ${theme.fontWeight.bold};
    font-size: 44px;
    line-height: 44px;
  }
  div:last-child {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.xs};
    line-height: 16px;
    margin-top: 5px;
  }
`;

export const WrapperLegend = styled.div`
  display: flex;
  align-items: center;
  ${theme.media.laptopL} {
    p {
      font-weight: ${theme.fontWeight.bold};
      font-size: 11px;
      line-height: 14px;
    }
    span {
      font-weight: ${theme.fontWeight.medium};
    }
  }
  ${theme.media.desktop} {
    p {
      font-size: ${theme.sizes.sm};
    }
  }
`;

const RectangleStyled = styled.div`
  border: 1px solid ${theme.colors.border};
  height: 12px;
  width: 20px;
  margin-right: 4px;
`;

export const RedRectangle = styled(RectangleStyled)`
  background: ${theme.colors.important};
`;

export const YellowRectangle = styled(RectangleStyled)`
  background: ${theme.colors.warning};
`;

export const SilverRectangle = styled(RectangleStyled)`
  background: ${theme.colors.textFieldBackground};
`;

export const WrapperLegendLineChart = styled.div`
  display: flex;
  align-items: center;
  height: max-content;
  span {
    height: 5px;
    margin: -24px 4px 0;
  }
  p {
    font-size: 10px;
    line-height: 10px;
    margin-left: 3px;
    margin-top: 1px;
  }
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 1px;
`;

export const RedDot = styled(Dot)`
  background: rgb(251, 78, 78);
`;

export const PinkDot = styled(Dot)`
  background: rgba(251, 78, 78, 0.6);
`;

export const YellowDot = styled(Dot)`
  background: rgba(235, 195, 0, 0.6);
`;

export const GreenDot = styled(Dot)`
  background: rgba(42, 199, 105, 0.6);
`;

export const VeryGoodDot = styled(Dot)`
  background: #54a1c5;
`;

export const GoodDot = styled(Dot)`
  background: #54c5c5;
`;

export const AsUsualDot = styled(Dot)`
  background: #54c588;
`;
export const BadDot = styled(Dot)`
  background: #c6da4a;
`;
export const VeryBadDot = styled(Dot)`
  background: #d95c4b;
`;

export const SilverDot = styled(Dot)`
  background: rgba(242, 242, 242, 0.6);
`;

export const PinkDot2 = styled(Dot)`
  background: #f39393;
`;

export const YellowDot2 = styled(Dot)`
  background: #ebdb65;
`;

export const BlueDot2 = styled(Dot)`
  background: #8ab7cd;
`;

export const WrapperDatePickerDetail = styled.div`
  width: 118px;
  .react-datepicker__navigation-icon::before {
    top: 5px !important;
  }
`;

export const TopChart = styled.div`
  display: flex;
  column-gap: 6px;
  margin-bottom: 8px;
  padding-right: 16px;
  div {
    background: ${theme.colors.background};
    border-radius: 2px;
    color: ${theme.colors.important};
    text-align: center;
    padding: 2px 0;
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.sizes.xs};
    line-height: 12px;
    width: calc(100% / 7);
  }
`;

export const MiddleChart = styled.div`
  display: flex;
  column-gap: 6px;
  margin-bottom: 4px;
  height: auto;
  ${theme.media.laptopL} {
    column-gap: 4.5px;
  }
  ${theme.media.desktop} {
    column-gap: 6px;
  }
`;

export const SymtomStyled = styled.div`
  background: ${theme.colors.textLink};
  border-radius: 2px;
  color: ${theme.colors.white};
  text-align: center;
  padding: 3px 7px 2px;
  font-weight: ${theme.fontWeight.bold};
  font-size: 11px;
  line-height: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BottomChart = styled.div`
  display: flex;
  column-gap: 6px;
  border-top: 1px solid ${theme.colors.border};
  padding-top: 6px;
  div {
    background: ${theme.colors.white};
    border-radius: 2px;
    color: ${theme.colors.text.primary};
    text-align: center;
    padding: 2px 0;
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.xs};
    line-height: 12px;
    width: calc(100% / 7);
  }
`;

export const TooltipRightStyled = styled.div`
  position: absolute;
  display: flex;
  width: max-content;
  background: ${theme.colors.white};
  color: ${theme.colors.textLink};
  font-size: ${theme.sizes.xs};
  font-weight: ${theme.fontWeight.regular};
  line-height: 14px;
  padding: 2px 4px;
  z-index: 100;
  border-radius: 2px;
  border: 1px solid ${theme.colors.border};
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: 6px;
    left: -4px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 7px;
    left: -2px;
    width: 5px;
    height: 5px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const TooltipLeftStyled = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  width: max-content;
  background: ${theme.colors.white};
  color: ${theme.colors.textLink};
  font-size: ${theme.sizes.xs};
  font-weight: ${theme.fontWeight.regular};
  line-height: 14px;
  padding: 2px 4px;
  z-index: 100;
  border-radius: 2px;
  border: 1px solid ${theme.colors.border};
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: 5px;
    right: -4px;
    height: 7px;
    width: 7px;
    background: ${theme.colors.border};
    transform: rotate(45deg);
  }
  &::before {
    content: "";
    position: absolute;
    top: 5px;
    right: -1px;
    width: 6px;
    height: 6px;
    background: ${theme.colors.white};
    transform: rotate(45deg);
    z-index: 1;
  }
  span {
    z-index: 1;
  }
`;

export const WrapperTooltipRight = styled.div`
  position: absolute;
  top: -4px;
  right: -2px;
`;

export const WrapperTooltipLeft = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
`;

export const WrapperSymtoms = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 4px;
  width: calc(95% / 7);
`;

export const WrapperMiddleChart = styled.div`
  height: 160px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 13px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    margin: 1px 0px 1px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
    background: ${theme.colors.background};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid ${theme.colors.white};
    border-right: 4px solid ${theme.colors.white};
  }
`;

export const TextAreaStyled = styled(TextArea)`
  height: 93px;
  padding: 4px 4px 12px 12px;
  ${theme.media.tablet} {
    height: 62px;
  }
`;

export const TooltipStyled = styled(Tooltip)`
  color: red;
`;

export const TableStyled = styled(Table)`
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #cde3ec;
  }
  .ant-table-thead > tr > th:nth-child(3) {
    background: #fbfbfb;
    &:before {
      height: 100% !important;
    }
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    border-right: 2px solid ${theme.colors.background};
  }
  .ant-table-thead > tr > th:first-child {
    &:before {
      height: 1.6em !important;
    }
  }
  .ant-table-tbody > tr > td:first-child {
    border-right: unset !important;
  }
`;

export const AdditionalBtn = styled(Button)`
  height: 24px;
`;

export const WrapperFaceDetail = styled.div`
  width: 670px;
  display: flex;
  justify-content: space-between;
  p {
    text-align: center;
  }
  div {
    width: 60px;
    text-align: center;
  }
`;

export const WrapperThreeDot = styled.div`
  position: absolute;
  right: 0px;
  bottom: -2px;
`;

export const WrapperChart = styled.div`
  overflow-x: auto;
  width: 100%;
  ${theme.media.tablet} {
    overflow: unset;
  }
`;

export const ContainerChart = styled.div`
  height: auto;
  width: 770px;
  font-size: 12px;
  line-height: 12px;
  .customized-tooltip-content {
    z-index: 1000;
    width: 192px;
    height: 128px;
    padding: 5px 0px 6px 6px;
    background: #ffffff;
    border: 1px solid #b7b7b7;
    box-sizing: border-box;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    font-size: 10px;
    line-height: 10px;
    font-weight: ${theme.fontWeight.regular};
    .list {
      display: flex;
      flex-direction: column;
      gap: 4px;
      height: 102px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 13px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        margin: 1px 0px 1px;
        border-left: 4px solid ${theme.colors.white};
        border-right: 4px solid ${theme.colors.white};
        background: ${theme.colors.background};
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        width: 8px;
        background: #bdbdbd;
        border-radius: 7px;
        border-left: 4px solid ${theme.colors.white};
        border-right: 4px solid ${theme.colors.white};
      }
    }
    .label {
      color: ${theme.colors.text.secondary};
      font-style: normal;
      margin-bottom: 4px;
    }
    .tooltip-item-wrapper {
      display: flex;
      justify-content: space-between;
      .item-left {
        display: flex;
        line-height: 11px;
        color: ${theme.colors.text.primary};
        span {
          margin-left: 4px;
        }
      }
      .item-right {
        color: ${theme.colors.text.secondary};
      }
    }
  }
  .recharts-active-dot {
    display: none;
  }
  .recharts-tooltip-cursor {
    stroke: #ffffff;
  }
  .icon-descriptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  .icon-descriptions-stacked {
    .description {
      display: flex;
      align-items: center;
      margin-right: 4px;
    }
    .description:last-child {
      margin-right: 0px;
    }
  }
  .icon-descriptions-outbreak {
    display: flex;
    height: max-content;
    gap: 8px;
    flex-wrap: wrap;
    .description {
      display: flex;
      align-items: center;
      cursor: pointer;
      .ant-checkbox {
        width: 12px;
        height: 12px;
        .ant-checkbox-inner {
          width: 12px;
          height: 12px;
        }
        .ant-checkbox-inner::after {
          left: 1px;
          width: 6px;
          height: 8px;
        }
      }
    }
    .description:last-child {
      margin-right: 0px;
    }
  }
  ${theme.media.tablet} {
    width: 100%;
  }
  .recharts-tooltip-cursor {
    fill: transparent !important;
    stroke: transparent !important;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  align-items: center;
  outline: none;
  margin-right: 3px;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.colors.border};
    }
  }
  .ant-checkbox {
    top: unset;
  }
  .ant-checkbox-inner {
    width: 10px;
    height: 10px;
    border-radius: 2px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button}!important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.border};
  }
  div {
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
`;

export const CheckboxStyled2 = styled(CheckboxStyled)`
  .ant-checkbox-inner::after {
    left: 10.5%;
    width: 5px;
    height: 7px;
  }
`;

export const CheckboxStyled3 = styled(CheckboxStyled)`
  .ant-checkbox-inner {
    width: 20px;
    height: 21px;
    border: 2px solid ${theme.colors.border};
  }
`;

export const WrapperFace = styled.div`
  margin: 15px 5px 8px 15px;
  position: relative;
`;
export const ContainerSymtomDetailChart = styled.div`
  width: 770px;
  ${theme.media.tablet} {
    width: 100%;
  }
`;

export const Top = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 66px !important;
  display: flex;
  justify-content: space-between;
  > div:nth-child(2) {
    position: absolute;
    bottom: -53px;
    width: 100%;
  }
  p:first-child {
    font-weight: ${theme.fontWeight.bold};
    font-size: 24px;
    line-height: 24px;
    color: ${theme.colors.text.primary};
  }
  p:last-child {
    font-weight: ${theme.fontWeight.regular};
    font-size: ${theme.sizes.sm};
    line-height: 18px;
    margin-top: 5px;
  }
  ${theme.media.tablet} {
    align-items: center;
    margin-bottom: 16px !important;
    p:first-child {
      min-width: 64px;
      font-size: 32px;
      line-height: 32px;
      margin-right: 9px;
    }
    p:last-child {
      margin-left: 8px;
    }
    > div:nth-child(2) {
      position: unset;
    }
  }
`;

export const Middle = styled.div`
  text-align: center;
  font-size: ${theme.sizes.lg};
  margin-bottom: 32px !important;
  span {
    color: ${theme.colors.text.primary};
    font-weight: ${theme.fontWeight.bold};
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: center;
    margin-bottom: 24px !important;
    div:first-child {
      margin-right: 24px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  padding: 0 32px;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 32px 55px;
  font-size: ${theme.sizes.lg};
  font-weight: ${theme.fontWeight.bold};
  line-height: 26px;
  > div {
    text-align: center;
  }
  span {
    font-weight: ${theme.fontWeight.regular};
  }
  ${theme.media.tablet} {
    padding: unset;
  }
`;

export const NotImplementText = styled.div`
  color: ${theme.colors.text.primary};
`;

export const NoHeatText = styled.div`
  color: ${theme.colors.button};
`;

export const FeverText = styled.div`
  color: ${theme.colors.warning};
`;

export const HighFeverText = styled.div`
  color: ${theme.colors.important};
`;

export const WrapperLoadingChart = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    > div {
      position: inherit !important;
    }
  }
`;

export const ScrollableWrapper = styled.div`
  position: sticky;
  height: 0;
  left: 700px;
  top: 0;
  z-index: 1;
  > svg {
    position: absolute;
    top: 135px;
    left: 60%;
    ${theme.media.desktop} {
      top: 175px;
    }
  }
`;

export const WrapperBodyTemperatureHeader = styled.div`
  p {
    color: ${theme.colors.important};
    font-size: ${theme.sizes.md};
    line-height: 20px;
  }
  ${theme.media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const WrapperItem = styled.div`
  min-width: 200px;
  max-width: 350px;
`;

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.border};
  height: 38px;
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.bold};
  padding-left: 10px;
  background: ${theme.colors.white};
`;

export const CheckboxStyled4 = styled(Checkbox)`
  align-items: center;
  outline: none;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.colors.border};
    }
  }
  .ant-checkbox {
    top: unset;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 21px;
    border: 2px solid ${theme.colors.border};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button}!important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.border};
  }

  div {
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.bold};
    line-height: 16px;
  }
`;

export const AbsenceButton = styled.div<{ background: string }>`
  background: ${(props) => props.background};
  border-radius: 6px;
  padding: 2px 4px;
  font-size: ${theme.sizes.sm};
  line-height: 22px;
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.colors.white};
  margin-right: 5px;
  cursor: pointer;
`;

export const AbsentModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 16px 24px 24px;
  }
  .ant-modal-footer {
    padding: 0px 16px 24px;
    border: unset;
  }
  div {
    font-size: 14px;
    line-height: 22px;
  }
  div.bold {
    font-weight: 700;
  }
  ${theme.media.tablet} {
    .ant-modal-body {
      padding: 24px;
    }
    .ant-modal-footer {
      padding: 0px 24px 16px;
    }
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 144px;
    line-height: 23px;
    padding: 3.5px 15px 4.5px;
  }
  button:last-child {
    width: 151px;
  }
  ${theme.media.tablet} {
    justify-content: flex-end;
    button:last-child {
      margin-left: 16px;
    }
    button {
      width: 112px !important;
    }
  }
`;

export const CustomizedTooltip = styled.div`
  padding: 5px 6px 6px;
  background: #ffffff;
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  font-size: 10px;
  line-height: 10px;
  font-weight: ${theme.fontWeight.regular};
  .list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .label {
    color: ${theme.colors.text.secondary};
    font-style: normal;
    margin-bottom: 4px;
  }
  .tooltip-item-wrapper {
    display: flex;
    gap: 8px;
    .item-left {
      display: flex;
      line-height: 11px;
      color: ${theme.colors.text.primary};
    }
    .item-right {
      color: ${theme.colors.text.secondary};
    }
  }
`;

export const DeseaseOutbreakChartContainer = styled.div`
  background: #fbfbfb;
  border-radius: 8px;
  border: 1px solid ${theme.colors.background};
  padding: 9px 16px 16px;
  position: relative;
  ${theme.media.tablet} {
    min-height: 300px;
  }
`;

export const CheckCountry = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0px 8px 5px 0px;
  div {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  .ant-checkbox {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    .ant-checkbox-inner {
      width: 14px;
      height: 14px;
    }
    .ant-checkbox-inner::after {
      left: 2px;
      width: 6px;
      height: 8px;
    }
  }
`;

export const ChartTooltipRightStyled = styled.div`
  position: absolute;
  display: flex;
  width: 524px;
  background: ${theme.colors.white};
  color: ${theme.colors.text.primary};
  font-size: ${theme.sizes.sm};
  font-weight: ${theme.fontWeight.regular};
  line-height: 18px;
  padding: 8px;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid ${theme.colors.border};
  transform-origin: 50% 89.9943px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  &::after {
    content: "";
    position: absolute;
    top: 26px;
    left: -6px;
    height: 10px;
    width: 10px;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.border};
    transform: rotate(-22.5deg) skewX(45deg) scaleY(cos(45deg));
    border-bottom: none;
    border-right: none;
  }
`;

export const WrapperNoData = styled.div`
  display: flex;
  justify-content: center;
`;
