import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { setHealthCheckSetting } from "services/setting";
import {
  ActionType,
  IHealthCheckSettingResponse,
  IHealthCheckSettingPayload,
} from "models/setting/healthCheckSetting";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./healthCheckSettingActions";

function* setHealthCheckSettingSaga({
  payload,
}: {
  payload: IHealthCheckSettingPayload;
}) {
  try {
    const response: IHealthCheckSettingResponse = yield call(
      setHealthCheckSetting,
      payload
    );
    if (response.status === httpStatus.StatusCreated) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onSetHealthCheckSettingWatcher() {
  yield takeLatest(
    ActionType.HEALTH_CHECK_SETTING as any,
    setHealthCheckSettingSaga
  );
}

export default [fork(onSetHealthCheckSettingWatcher)];
