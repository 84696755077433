import {
  Action,
  ActionType,
  CSVDownloadUserResponse,
} from "models/csvdownloaduser";
import createReducer from "../createReducer";

export interface CSVDownloadUserReducerType {
  loading: boolean;
  error?: string;
  csvDownloadUserResponse?: CSVDownloadUserResponse;
}

const defaultState: CSVDownloadUserReducerType = {
  loading: false,
  error: undefined,
  csvDownloadUserResponse: undefined,
};

export const csvDownloadUserReducer = createReducer<CSVDownloadUserReducerType>(
  defaultState,
  {
    [ActionType.CSV_DOWNLOAD_USER](state: CSVDownloadUserReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CSV_DOWNLOAD_USER_ERROR](
      state: CSVDownloadUserReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.CSV_DOWNLOAD_USER_SUCCESS](
      state: CSVDownloadUserReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        csvDownloadUserResponse: action.payload,
      };
    },

    [ActionType.CSV_DOWNLOAD_RESET_DATA](state: CSVDownloadUserReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
        csvDownloadUserResponse: [],
      };
    },
  }
);
