import { Action, ActionType, SlotsDataResponse } from "models/vaccine/slots";
import createReducer from "../../createReducer";

export interface SlotsReducerType {
  loading: boolean;
  error?: string;
  slotsResponse?: SlotsDataResponse;
}

const defaultState: SlotsReducerType = {
  loading: false,
  error: undefined,
  slotsResponse: undefined,
};

export const slotsReducer = createReducer<SlotsReducerType>(defaultState, {
  [ActionType.SLOTS](state: SlotsReducerType) {
    return {
      ...state,
      loading: true,
    };
  },

  [ActionType.SLOTS_ERROR](state: SlotsReducerType, action: Action<number>) {
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  },

  [ActionType.SLOTS_SUCCESS](state: SlotsReducerType, action: Action<number>) {
    return {
      ...state,
      loading: false,
      error: null,
      slotsResponse: action.payload,
    };
  },
});
