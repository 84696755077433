import * as React from "react";

function MultiLineIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="11"
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="3" width="20" height="2" fill={props.fill} />
      <rect width="20" height="2" fill={props.fill} />
      <rect y="6" width="20" height="2" fill={props.fill} />
      <rect y="9" width="11" height="2" fill={props.fill} />
    </svg>
  );
}

export default MultiLineIcon;
