export enum ActionType {
  // submit student data for class change
  PUT_STUDENT_CLASS_CHANGE = "action/PUT_STUDENT_CLASS_CHANGE",
  PUT_STUDENT_CLASS_CHANGE_SUCCESS = "action/PUT_STUDENT_CLASS_CHANGE_SUCCESS",
  PUT_STUDENT_CLASS_CHANGE_ERROR = "action/PUT_STUDENT_CLASS_CHANGE_ERROR",
  PUT_STUDENT_CLASS_CHANGE_RESET = "action/PUT_STUDENT_CLASS_CHANGE_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IPutConfirmStudentData {
  locale?: string;
  current_class_id?: any;
}
