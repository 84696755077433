import {
  ActionType,
  Action,
  ICancelQuestionnaireResponse,
} from "models/questionnaires/cancelQuestionnaire";
import createReducer from "../../createReducer";

export interface CancelQuestionnaireReducerType {
  loading: boolean;
  error?: string;
  cancelQuestionnaireResponse?: ICancelQuestionnaireResponse;
}

const defaultState: CancelQuestionnaireReducerType = {
  loading: false,
  error: undefined,
  cancelQuestionnaireResponse: undefined,
};

export const cancelQuestionnaireReducer =
  createReducer<CancelQuestionnaireReducerType>(defaultState, {
    [ActionType.CANCEL_QUESTIONNAIRE](state: CancelQuestionnaireReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.CANCEL_QUESTIONNAIRE_ERROR](
      state: CancelQuestionnaireReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        cancelQuestionnaireResponse: undefined,
      };
    },

    [ActionType.CANCEL_QUESTIONNAIRE_SUCCESS](
      state: CancelQuestionnaireReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        cancelQuestionnaireResponse: action.payload,
      };
    },

    [ActionType.CANCEL_QUESTIONNAIRE_RESET](
      state: CancelQuestionnaireReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        cancelQuestionnaireResponse: undefined,
      };
    },
  });
