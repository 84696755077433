import React, { forwardRef } from "react";
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import styled from "styled-components";
import { theme } from "theme/theme";
import { CalendarIcon } from "icons";
import { localStorageHelper } from "utils";

registerLocale("ja", ja);

interface IDatePicker extends ReactDatePickerProps {
  type?: string;
  iconPosition?: "left" | "right";
}

const TextStyledFirst = styled.div(({ theme }) => ({
  padding: "5px 8px 6px 12px",
  borderRadius: 6,
  outline: "none",
  background: theme.colors.background,
  color: theme.colors.icon,
}));

const TextStyledSecond = styled.div(({ theme }) => ({
  padding: "5px 8px 6px 31px",
  background: theme.colors.white,
  border: `1px solid ${theme.colors.border}`,
  color: theme.colors.text.primary,
  borderRadius: 6,
  fontSize: theme.sizes.sm,
  lineHeight: "20px",
  height: 31,
  textAlign: "center",
}));

const WrapperDatePickerInline = styled.div`
  .react-datepicker {
    width: 100%;
    border: unset;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__navigation {
    top: 14px;
    height: 24px;
    width: 24px;
    background: ${theme.colors.background};
    border-radius: 50%;
  }
  .react-datepicker__navigation-icon::before {
    border-color: ${theme.colors.textLink};
    top: 12px;
  }
  .react-datepicker__navigation-icon--next::before {
    left: -3px;
  }
  .react-datepicker__navigation-icon--previous::before {
    right: -4px;
  }
  .react-datepicker__header {
    background-color: ${theme.colors.white};
    border-bottom: unset;
  }
  .react-datepicker__current-month {
    color: ${theme.colors.text.primary};
    line-height: 16px;
    font-size: ${theme.sizes.md};
    font-weight: ${theme.fontWeight.bold};
    border-bottom: 1px solid ${theme.colors.background};
    padding: 10px;
  }
  .react-datepicker__day {
    margin: 0 8.5px;
    width: 28px;
    font-size: ${theme.sizes.sm};
    &:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__day-name {
    margin-bottom: 0;
    width: 40px;
    font-size: 14px;
  }
  .react-datepicker__day-name:first-child {
    color: ${theme.colors.important};
  }
  .react-datepicker__day-name:last-child {
    color: ${theme.colors.textLink};
  }
  .react-datepicker__day--outside-month {
    color: ${theme.colors.border};
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker-wrapper {
    width: unset;
  }
  .react-datepicker__input-container {
    width: unset;
  }
  .react-datepicker__day--today {
    font-weight: normal;
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
    background-color: ${theme.colors.textLink};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${theme.colors.white};
    color: ${theme.colors.text.primary};
  }
  ${theme.media.tablet} {
    .react-datepicker__day {
      margin: 2px 6px;
      width: 22px;
      height: 22px;
      line-height: 22px;
    }
    .react-datepicker__day-name {
      width: 16px;
      margin: 0 9px;
    }
  }
`;

const WrapperDatePicker = styled.div`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    width: 264px;
    top: -10px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
  }
  .react-datepicker__input-container input {
    letter-spacing: 0.3px;
    padding-left: 12px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__day {
    margin: 4px 7px;
    font-size: ${({ theme }) => theme.sizes.sm};
    height: 22px;
    width: 22px;
    line-height: 22px;
    font-weight: normal;
  }
  .react-datepicker__day div {
    height: 100%;
    width: 100%;
  }
  .react-datepicker__day--outside-month {
    color: #bdbdbd;
  }
  .react-datepicker__day:not(.react-datepicker__day--outside-month):hover {
    background-color: ${({ theme }) => theme.colors.textLink};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    width: 22px;
    margin: 0 7px;
  }
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.white};
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    font-size: ${({ theme }) => theme.sizes.xs};
    color: ${({ theme }) => theme.colors.text.primary};
    padding: 0;
    border-bottom: 0;
  }
  .react-datepicker__current-month {
    height: 40px;
    padding: 13px 0px 10px;
    margin: 0px 16px;
    text-align: left;
    font-size: ${({ theme }) => theme.sizes.md};
    line-height: ${({ theme }) => theme.sizes.md};
    font-weight: bold;
    color: #4f4f4f;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.background}`};
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day-names {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font-weight: normal;
    font-size: ${({ theme }) => theme.sizes.sm};
  }
  .react-datepicker__day-name {
    margin: 8px 7px 0px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  .react-datepicker__day-name:first-child {
    color: ${({ theme }) => theme.colors.important};
  }
  .react-datepicker__day-name:last-child {
    color: ${({ theme }) => theme.colors.textLink};
  }
  .react-datepicker__month {
    margin: 0 4px 4px 4px;
  }
  .react-datepicker__week {
  }
  .react-datepicker__week:last-child {
    border-bottom: 0;
  }
  .react-datepicker__day--today {
    font-weight: normal;
  }
  .react-datepicker__navigation {
    top: 12px;
    width: 18px;
    height: 18px;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 50%;
  }
  .react-datepicker__day--highlighted {
    position: relative;
  }
  .react-datepicker__day--highlighted::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: 5px;
    right: 3px;
    background: ${({ theme }) => theme.colors.important};
  }
  .react-datepicker__navigation-icon--next::before {
    left: -3px;
  }
  .react-datepicker__navigation-icon--previous::before {
    right: -3px;
  }
  .react-datepicker__navigation-icon::before {
    top: 13px;
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    border-color: ${({ theme }) => theme.colors.textLink};
  }
  .react-datepicker__navigation--previous {
    left: unset;
    right: 43px;
  }
  .react-datepicker__navigation--next {
    right: 16px;
  }
  .react-datepicker__navigation--previous:focus,
  .react-datepicker__navigation--next:focus,
  .react-datepicker__day--keyboard-selected:focus,
  .react-datepicker__day--selected:focus {
    outline: none;
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
    background-color: ${theme.colors.textLink};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${theme.colors.white};
    color: ${theme.colors.text.primary};
  }
  ${theme.media.tablet} {
    .react-datepicker__input-container input {
      padding-left: 14px;
    }
  }
`;

const CalendarIconStyled = styled(CalendarIcon)`
  position: absolute;
  top: 5px;
  cursor: pointer;
`;

const dateFormat = "yyyy/MM/dd";

const CustomInputFirst = forwardRef<any, any>(({ value, onClick }, ref) => {
  return (
    <div style={{ position: "relative" }}>
      <TextStyledFirst>{value || "日付を選択してください"}</TextStyledFirst>
      <CalendarIconStyled onClick={onClick} style={{ right: 8 }} />
    </div>
  );
});

const CustomInputSecond = forwardRef<any, any>(({ value, onClick }, ref) => {
  return (
    <div style={{ position: "relative" }}>
      <TextStyledSecond>{value}</TextStyledSecond>
      <CalendarIconStyled onClick={onClick} style={{ left: 8 }} />
    </div>
  );
});

CustomInputFirst.displayName = "CustomInputFirst";
CustomInputSecond.displayName = "CustomInputSecond";

const DatePickerCustom: React.FC<IDatePicker> = ({
  type,
  iconPosition,
  ...rest
}) => {
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const returnLocale = () => {
    if (currentLanguage === "ja") {
      return "ja";
    }
    return "en";
  };

  if (type === "inline") {
    const CustomHeader = ({ monthDate, decreaseMonth, increaseMonth }: any) => {
      return (
        <>
          <button
            aria-label="Previous Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--previous"
            }
            onClick={decreaseMonth}
          >
            <span
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
              }
            >
              {"<"}
            </span>
          </button>
          <div className="react-datepicker__current-month">
            {monthDate.getFullYear()}年{monthDate.getMonth() + 1}月
          </div>
          <button
            aria-label="Next Month"
            className={
              "react-datepicker__navigation react-datepicker__navigation--next"
            }
            onClick={increaseMonth}
          >
            <span
              className={
                "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
              }
            >
              {">"}
            </span>
          </button>
        </>
      );
    };

    return (
      <WrapperDatePickerInline>
        {currentLanguage === "ja" ? (
          <DatePicker
            inline
            locale="ja"
            dateFormat={dateFormat}
            renderCustomHeader={CustomHeader}
            {...rest}
          />
        ) : (
          <DatePicker inline locale="en" dateFormat={dateFormat} {...rest} />
        )}
      </WrapperDatePickerInline>
    );
  }

  return (
    <WrapperDatePicker>
      <DatePicker
        {...rest}
        locale={returnLocale()}
        dateFormat={dateFormat}
        customInput={
          iconPosition === "right" ? (
            <CustomInputFirst />
          ) : (
            <CustomInputSecond />
          )
        }
      />
    </WrapperDatePicker>
  );
};

export default DatePickerCustom;
