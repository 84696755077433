import React from "react";
import { useTranslation } from "react-i18next";

import { Input, TextArea, Checkbox1, Radio } from "components";

import {
  WrapperTitleInputTextarea,
  RadioStyled,
  WrapperQuestionDetail,
  Label,
  QuestionName,
  QuestionExplanation,
  WrapperQuestion,
  QuestionDraftTitle,
  QuestionIndividualAnswerBody,
  DescriptionStyled2,
} from "./Questions.style";
import { IQuestionnaire } from "models/questionnaires/questionnairesDetail";

const Question = ({ question, index }: any) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });

  const dataList = question.questionnaire_answers.map((ans: any) => {
    return {
      key: ans.id,
      value: ans.id,
      name: ans.answer_text,
    };
  });

  const items: any = {
    radio: (
      <RadioStyled>
        <Radio list={dataList} />
      </RadioStyled>
    ),
    checkbox: <Checkbox1 list={dataList} />,
    text: (
      <React.Fragment>
        <Input
          height={40}
          fsLabel={16}
          disabled
          marginForm="0 0 8px"
          value={question?.questionnaire_answers[0]?.answer_text || ""}
        />
        <WrapperTitleInputTextarea>
          {t("text-description-formula")}
        </WrapperTitleInputTextarea>
      </React.Fragment>
    ),
    multiLine: (
      <React.Fragment>
        <TextArea
          height={"120px"}
          fsLabel={16}
          disabled
          value={question?.questionnaire_answers[0]?.answer_text || ""}
        />
        <WrapperTitleInputTextarea>
          {t("text-description-formula-textarea")}
        </WrapperTitleInputTextarea>
      </React.Fragment>
    ),
    pulldown: (
      <div style={{ marginTop: "24px" }}>
        {question.questionnaire_answers.map((ans: any) => {
          return (
            <Input
              key={ans.id}
              value={ans.answer_text}
              height={31}
              fsLabel={16}
              width={243}
              marginForm="10px 0px"
              disabled
            />
          );
        })}
      </div>
    ),
  };

  const handleRenderItem = (answerType: string) => {
    return items[answerType];
  };

  return (
    <WrapperQuestion>
      <QuestionDraftTitle>{t("question") + index}</QuestionDraftTitle>

      <QuestionIndividualAnswerBody>
        {question.question_content && <div>{question.question_content}</div>}
        {question.question_summary && (
          <DescriptionStyled2>{question.question_summary}</DescriptionStyled2>
        )}
        {handleRenderItem(question.answer_type)}
      </QuestionIndividualAnswerBody>
    </WrapperQuestion>
  );
};

const Questionnaire = ({
  questionnaires,
}: {
  questionnaires: IQuestionnaire;
}) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });

  return (
    <React.Fragment>
      <WrapperQuestionDetail>
        <Label>{t("questionnaire-name")}</Label>
        <QuestionName>{questionnaires.title}</QuestionName>
        <Label>{t("questionnaire-explanation")}</Label>
        <QuestionExplanation>{questionnaires.explanation}</QuestionExplanation>
      </WrapperQuestionDetail>

      {(questionnaires?.questionnaire_questions || []).map((question, idx) => {
        return (
          <Question key={question.id} question={question} index={idx + 1} />
        );
      })}
    </React.Fragment>
  );
};

export default Questionnaire;
