import { IResponse } from "models";
import { QuestionnairesQuestion } from "./questionnairesDetail";

export enum ActionType {
  GET_INDIVIDUAL_ANSWER = "action/GET_INDIVIDUAL_ANSWER",
  GET_INDIVIDUAL_ANSWER_SUCCESS = "action/GET_INDIVIDUAL_ANSWER_SUCCESS",
  GET_INDIVIDUAL_ANSWER_ERROR = "action/GET_INDIVIDUAL_ANSWER_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IQuestionnaire {
  id?: number;
  company_id?: number;
  title?: string;
  explanation?: string;
  start_time?: string;
  end_time?: any;
  is_draft?: boolean;
  created_at?: string;
  updated_at?: string;
  questionnaire_questions?: QuestionnairesQuestion[];
}

export interface IPatientAnswered {
  row_num?: string;
  patient_id?: string;
  questionnaire_and_patients_id?: string;
  full_name?: string;
}

export interface IIndividualAnswerPayload {
  id?: number;
  full_name?: string;
}

export interface IIndividualAnswerResponse extends IResponse {
  result: {
    questionnaire: IQuestionnaire;
    total_patient_answered: number;
    patients_answered: IPatientAnswered[];
  };
}
