import {
  ActionType,
  CreateAccountResponse,
} from "models/account/createAccount";

export const createAccountAction = (payload: any) => {
  return {
    type: ActionType.CREATE_ACCOUNT,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.CREATE_ACCOUNT_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: CreateAccountResponse) => {
  return {
    type: ActionType.CREATE_ACCOUNT_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.RESET_CREATE_ACCOUNT,
  };
};
