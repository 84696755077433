import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  SendInterviewRequestPayload,
  ActionType,
} from "models/mentalHealth/sendInterviewRequest";
import { IResponse } from "models";
import { httpStatus } from "configs/httpStatus";
import { sendInterviewRequest } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./sendInterviewRequestActions";

function* sendInterviewRequestSaga({
  payload,
}: {
  payload: SendInterviewRequestPayload;
}) {
  try {
    const response: IResponse = yield call(sendInterviewRequest, payload);

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSendInterviewRequestWatcher() {
  yield takeLatest(
    ActionType.SEND_INTERVIEW_REQUEST as any,
    sendInterviewRequestSaga
  );
}

export default [fork(onSendInterviewRequestWatcher)];
