import { IResponse } from "models";

export enum ActionType {
  PROGRESS_BAR = "action/PROGRESS_BAR",
  PROGRESS_BAR_SUCCESS = "action/PROGRESS_BAR_SUCCESS",
  PROGRESS_BAR_ERROR = "action/PROGRESS_BAR_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ProgressBarResponse extends IResponse {
  result: any;
}
