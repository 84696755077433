export enum ActionType {
  // sent message to user with/without files
  SEND_MESSAGE = "action/SEND_MESSAGE",
  SEND_MESSAGE_SUCCESS = "action/SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_ERROR = "action/SEND_MESSAGE_ERROR",
  RESET_SEND_MESSAGE_DATA = "action/RESET_SEND_MESSAGE_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ISendMessageData {
  title: string;
  description: string;
  patient_id?: string;
  message_documents?: any;
  is_send_all: string;
}
