import { IResponse } from "models";

export enum ActionType {
  VACCINE_RECORD_GRAPH = "action/VACCINE_RECORD_GRAPH",
  VACCINE_RECORD_GRAPH_SUCCESS = "action/VACCINE_RECORD_GRAPH_SUCCESS",
  VACCINE_RECORD_GRAPH_ERROR = "action/VACCINE_RECORD_GRAPH_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IGraphDataResponse extends IResponse {
  result: any;
}
