import {
  ActionType,
  BodyTemperatureSettingFormResponse,
} from "models/bodyTemperature/settingForm";

export const getSettingFormData = (payload: { locale: string }) => {
  return {
    type: ActionType.BODY_TEMPERATURE_SETTING_FORM_DATA,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.BODY_TEMPERATURE_SETTING_FORM_DATA_ERROR,
    payload,
  };
};

export const handleSuccesAction = (
  payload: BodyTemperatureSettingFormResponse
) => {
  return {
    type: ActionType.BODY_TEMPERATURE_SETTING_FORM_DATA_SUCCESS,
    payload,
  };
};
