import { IResponse } from "models";

export enum ActionType {
  HEALTH_CHECK_SETTING = "action/HEALTH_CHECK_SETTING",
  HEALTH_CHECK_SETTING_SUCCESS = "action/HEALTH_CHECK_SETTING_SUCCESS",
  HEALTH_CHECK_SETTING_ERROR = "action/HEALTH_CHECK_SETTING_ERROR",
  HEALTH_CHECK_SETTING_RESET = "action/HEALTH_CHECK_SETTING_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IHealthCheckSettingPayload {
  operation_setting: {
    can_use_health_check: boolean;
    can_use_heart_and_health: boolean;
    can_set_active_operation_hours: boolean;
    operation_start_at: string;
    operation_finish_at: string;
  };
  health_check_setting: {
    is_health_check: boolean;
    is_temperature: boolean;
    is_attendance: boolean;
    is_family_health_check: boolean;
    is_pool: boolean;
    is_marathon: boolean;
    is_action_check: boolean;
    is_mental_check: boolean;
    is_influenza_type: boolean;
    is_attendance_stop_period: boolean;
    absent_type: {
      influenza: boolean;
      covid_positive: boolean;
      covid_close: boolean;
      covid_family_close: boolean;
      mourning: boolean;
      other: boolean;
      online: boolean;
    };
    notifications: {
      is_remind: boolean;
      remind_send_interval: number;
      is_fever_alert: boolean;
      is_attendance_confirmation: boolean;
      temperature_notification_times: string[];
      notification_messages?: {
        am: string;
        pm: string;
        night: string;
      };
    };
  };
  usable_absent_reasons: {
    sick_absent_reason: {
      id: number;
      description: string;
    }[];
    mourning_absent_reason: any;
    other_absent_reason: any;
  };
}

export interface IHealthCheckSettingResponse extends IResponse {}
