import { RootState } from "../../rootReducer";

export const selectIsLoading = (state: RootState) =>
  state.putConfirmStudentList.loading;
export const selectIsSaved = (state: RootState) =>
  state.putConfirmStudentList.saved;
export const selectErrorMessage = (state: RootState) =>
  state.putConfirmStudentList.error;
export const putStudentClassChangeResponse = (state: RootState) =>
  state.putConfirmStudentList.putConfirmStudentClassChangeResponse;
