import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DataPatientChildDepartmentResponse,
} from "models/bodyTemperature/patientChildDepartment";
import { httpStatus } from "configs/httpStatus";
import { fetchPatient4ChildDepartmentData } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./patientChildActions";

function* getDataPatientChildDepartmentSaga({
  payload,
}: {
  payload: { sub_dept_id: number };
}) {
  try {
    const response: DataPatientChildDepartmentResponse = yield call(
      fetchPatient4ChildDepartmentData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDataPatientChildDepartmentWatcher() {
  yield takeLatest(
    ActionType.DATA_PATIENT_CHILD_DEPARTMENT as any,
    getDataPatientChildDepartmentSaga
  );
}

export default [fork(onGetDataPatientChildDepartmentWatcher)];
