export enum ActionType {
  // create class for school
  CREATE_BULK_CLASSES = "action/CREATE_BULK_CLASSES",
  CREATE_BULK_CLASSES_SUCCESS = "action/CREATE_BULK_CLASSES_SUCCESS",
  CREATE_BULK_CLASSES_ERROR = "action/CREATE_BULK_CLASSES_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface ICreateBulkClassData {
  class: {
    school_type: number;
    class_name_type: number;
    num_of_section: number;
  };
}
