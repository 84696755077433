import { createApiCall2, MethodType, URI, createApiCall } from "../Api";
import { BodyTemperatureData } from "models/bodyTemperature/statistics";
import { CreatePatientProfileRequestData } from "models/bodyTemperature/patientProfile";
import { DataPatientDetailRequest } from "models/bodyTemperature/patientDetail";
import { IApproveAbsencePayload } from "models/bodyTemperature/approveAbsence";
import { HealthCheckQuestionParams } from "models/bodyTemperature/healthCheckQuestions";
import { HealthCheckAnswersParams } from "models/bodyTemperature/healthCheckAnswers";
import { SendHealthCheckAnswersParams } from "models/bodyTemperature/sendHealthCheckAnswers";
import { localStorageHelper } from "utils";

export const fetchDataCharts = (payload: { date: string }) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_DATA_CHARTS,
    payload
  );
};

export const fetchStatisticsData = (payload: BodyTemperatureData) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/daily_answers`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchDestinationData = (payload: { locale: string }) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_DATA_ACTIVITY,
    payload
  );
};

export const fetchClassData = (payload: { locale: string }) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_DATA_CLASS,
    payload
  );
};

export const fetchChildDepartmentData = (payload: { dept_id: number }) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_DATA_CHILD_DEPARTMENT,
    payload
  );
};

export const fetchPatient4ChildDepartmentData = (payload: {
  sub_dept_id: number;
}) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_DATA_PATIENT_CHILD_DEPARTMENT,
    payload
  );
};

export const fetchDetailFormData = (payload: { locale: string }) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_DATA_DETAIL_FORM,
    payload
  );
};

export const fetchHealthCheckQuestions = (
  payload: HealthCheckQuestionParams
) => {
  const id = localStorageHelper.getItem("id");
  const url = `/v10/company_admin/${id}/health_check_questions`;
  return createApiCall(MethodType.GET, url, payload);
};

export const createPatientProfile = (
  payload: CreatePatientProfileRequestData
) => {
  return createApiCall2(
    MethodType.POST,
    URI.BODY_TEMPERATURE.CREATE_PATIENT_BODY_TEMPERATURE_CHECK,
    payload
  );
};

export const fetchPatientDetailData = (payload: DataPatientDetailRequest) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_DATA_PATIENT_DETAIL,
    payload
  );
};

export const fetchFeverPercentData = (payload: { date: string }) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_DATA_FEVER_PERCENTAGE,
    payload
  );
};

export const fetchSettingFormData = (payload: { locale: string }) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_DATA_SETTING_FORM,
    payload
  );
};

export const fetchFileData = (payload: any) => {
  const id = localStorageHelper.getItem("id");
  const url = `v10/company_admin/${id}/daily_answers/for_download`;
  return createApiCall(MethodType.GET, url, payload);
};

export const fetchProgressBarData = (payload: { date: string }) => {
  return createApiCall2(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_PROGESS_BAR_DATA,
    payload
  );
};

export const approveAbsence = (payload: IApproveAbsencePayload) => {
  return createApiCall(
    MethodType.POST,
    URI.BODY_TEMPERATURE.APPROVE_ABSENCE(payload.id)
  );
};

export const fetchHealthCheckAnswer = (payload: HealthCheckAnswersParams) => {
  return createApiCall(
    MethodType.GET,
    URI.BODY_TEMPERATURE.GET_HEALTH_CHECK_ANSWER(payload.patient_id),
    payload.payload
  );
};

export const postHealthCheckAnswer = (
  payload: SendHealthCheckAnswersParams
) => {
  return createApiCall(
    MethodType.POST,
    URI.BODY_TEMPERATURE.GET_HEALTH_CHECK_ANSWER(payload.payload.patient_id),
    payload.payload.temp_answers_attributes
  );
};
