import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ForgotPasswordData,
  ActionType,
  ForgotPasswordResponse,
} from "../../models/forgotPassword";
import { httpStatus } from "configs/httpStatus";
import { fetchForgotPassword } from "services/forgotpassword";
import { handleSuccesAction, handleErrorAction } from "./forgotpasswordActions";
import { history } from "../configureStore";
import { localStorageHelper } from "utils";

// forgot password
function* forgotPasswordSaga({ payload }: { payload: ForgotPasswordData }) {
  try {
    const response: ForgotPasswordResponse = yield call(
      fetchForgotPassword,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      const message = response.message;
      yield put(handleSuccesAction(message));
      localStorageHelper.setItem("email", payload.user.email);
      localStorageHelper.setItem("role", payload.user.role);
      history.push("/forgot-password-notification");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onForgotPasswordSubmitWatcher() {
  yield takeLatest(ActionType.FORGOT_PASSWORD as any, forgotPasswordSaga);
}

export default [fork(onForgotPasswordSubmitWatcher)];
