/**
 * Static Data for
 * Symptoms
 * Pool
 * Marathon
 */
export const optionSymptoms = [
  {
    id: 1,
    name: "no-symptoms",
  },
  {
    id: 2,
    name: "with-symptoms",
  },
];

export const optionPool = [
  {
    id: 1,
    name: "pool-yes",
  },
  {
    id: 2,
    name: "pool-no",
  },
];

export const optionsMarathon = [
  {
    id: 1,
    name: "participate",
  },
  {
    id: 2,
    name: "not-participate",
  },
];

export const optionRegistrationTime = [
  {
    id: 1,
    name: "AM",
  },
  {
    id: 2,
    name: "PM",
  },
];

export const optionFamilyIllness = [
  {
    id: 1,
    name: "family-illness-yes",
  },
  {
    id: 2,
    name: "family-illness-no",
  },
];

export const timeValueDefaults: any = [];
for (let i = 0; i <= 23; i++) {
  if (i <= 9) {
    timeValueDefaults.push({
      id: i,
      value: `0${i}:00`,
      name: `0${i}:00`,
    });
  } else {
    timeValueDefaults.push({
      id: i,
      value: `${i}:00`,
      name: `${i}:00`,
    });
  }
}

export const format = "YYYY/MM/DD";
export const formatDateTime = "YYYY/MM/DD HH:mm";
