import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  AbsenceResponse,
  ActionType,
  AbsencePayload,
} from "models/dashboard/absence";
import { httpStatus } from "configs/httpStatus";
import { fetchAbsenceChart } from "services/dashboard";
import { handleSuccesAction, handleErrorAction } from "./absenceActions";

function* getAbsenceSaga({ payload }: { payload: AbsencePayload }) {
  try {
    const response: AbsenceResponse = yield call(fetchAbsenceChart, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetAbsenceWatcher() {
  yield takeLatest(ActionType.GET_ABSENCE_CHART as any, getAbsenceSaga);
}

export default [fork(onGetAbsenceWatcher)];
