import {
  Action,
  ActionType,
  GradeListResponse,
} from "models/mentalHealth/gradeList";
import createReducer from "redux/createReducer";

export interface GradeListReducerType {
  loading: boolean;
  error?: string;
  gradeListResponse?: GradeListResponse;
}

const defaultState: GradeListReducerType = {
  loading: false,
  error: undefined,
  gradeListResponse: undefined,
};

export const gradeListReducer = createReducer<GradeListReducerType>(
  defaultState,
  {
    [ActionType.GRADE_LIST](state: GradeListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GRADE_LIST_ERROR](
      state: GradeListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        gradeListResponse: undefined,
      };
    },

    [ActionType.GRADE_LIST_SUCCESS](
      state: GradeListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        gradeListResponse: action.payload,
      };
    },
  }
);
