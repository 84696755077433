import React from "react";
import styled from "styled-components";
import { theme } from "theme/theme";

const Wrapper = styled.div`
  height: 47px;
  width: 100%;
  background-color: #aeaeae;
  border-radius: 4px;
  position: relative;
`;

const Filler = styled.div<{ completed: string; bg: string }>`
  height: 100%;
  width: ${(props) => props?.completed};
  background-color: ${(props) => props?.bg};
  border-radius: inherit;
  text-align: right;
`;

const Label = styled.div`
  padding: 5px;
  color: ${theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-weight: ${theme.fontWeight.regular};
  font-size: ${theme.sizes.lg};
  line-height: 32px;
  letter-spacing: 0.8px;
  span {
    font-weight: ${theme.fontWeight.bold};
    font-size: 24px;
  }
`;

interface IProgressBar {
  completed: number;
  total: string;
  implementedTotal: string;
  bg: string;
}

const ProgressBar: React.FC<IProgressBar> = ({
  completed,
  total,
  implementedTotal,
  bg,
}) => {
  return (
    <Wrapper>
      <Filler completed={`${completed}%`} bg={bg}>
        <Label>
          <span>{implementedTotal}</span>/{total}
        </Label>
      </Filler>
    </Wrapper>
  );
};

export default ProgressBar;
