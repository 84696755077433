import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IClassTypeData,
  IClassTypeResponse,
} from "../../../models/classmanagement/classType";
import { httpStatus } from "configs/httpStatus";
import { getClassType } from "services/schoolclasses";
import { handleSuccesAction, handleErrorAction } from "./classTypeActions";

function* classTypeSaga({ payload }: { payload: IClassTypeData }) {
  try {
    const response: IClassTypeResponse = yield call(getClassType, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetClassTypeWatcher() {
  yield takeLatest(ActionType.GET_CLASS_TYPE as any, classTypeSaga);
}

export default [fork(onGetClassTypeWatcher)];
