import * as React from "react";

function QuestionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="question-svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "transparent" }}
      {...props}
    >
      <path
        className="stroke"
        d="M14 18H10V22H14V18Z"
        stroke={props.fill}
        strokeMiterlimit="10"
      />
      <circle
        className="stroke"
        cx="12"
        cy="12"
        r="2.5"
        fill="white"
        stroke={props.fill}
      />
      <circle
        className="fill"
        cx="12"
        cy="12"
        r="1.75"
        fill={props.fill}
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        className="stroke"
        d="M16 19H22"
        stroke={props.fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16 21H22"
        stroke={props.fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16 11L22 11"
        stroke={props.fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke"
        d="M16 13L22 13"
        stroke={props.fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fill"
        d="M11.694 21.5754C11.4468 21.5754 11.2193 21.4775 11.0413 21.3113L9.13251 19.4235C8.77647 19.0714 8.77647 18.4845 9.13251 18.1324C9.31053 17.9564 9.538 17.8683 9.78526 17.8683C10.0325 17.8683 10.26 17.9661 10.438 18.1324L11.694 19.3746L14.5819 16.5185C14.76 16.3425 14.9874 16.2545 15.2347 16.2545C15.4819 16.2545 15.7094 16.3523 15.8874 16.5185C16.0655 16.6946 16.1545 16.9196 16.1545 17.1641C16.1545 17.4086 16.0556 17.6336 15.8874 17.8096L12.3468 21.3113C12.1688 21.4873 11.9413 21.5754 11.694 21.5754Z"
        fill={props.fill}
      />
      <path
        d="M15.2346 16.4988C15.4028 16.4988 15.5808 16.5673 15.7094 16.6945C15.9764 16.9585 15.9764 17.3791 15.7094 17.6432L12.1687 21.1448C12.0401 21.272 11.8621 21.3405 11.694 21.3405C11.5259 21.3405 11.3478 21.272 11.2193 21.1448L9.31048 19.2571C9.04344 18.993 9.04344 18.5724 9.31048 18.3083C9.43905 18.1812 9.61707 18.1127 9.7852 18.1127C9.95333 18.1127 10.1314 18.1812 10.2599 18.3083L11.6841 19.7168L14.75 16.6847C14.8786 16.5575 15.0566 16.4891 15.2247 16.4891L15.2346 16.4988ZM15.2247 16C14.9083 16 14.6215 16.1174 14.394 16.3423L11.6841 19.0223L10.6061 17.9562C10.3885 17.741 10.0918 17.6139 9.77531 17.6139C9.45883 17.6139 9.17201 17.7312 8.94454 17.9562C8.71707 18.1812 8.59839 18.4648 8.59839 18.7778C8.59839 19.0908 8.71707 19.3745 8.94454 19.5994L10.8533 21.4872C11.0709 21.7024 11.3676 21.8295 11.6841 21.8295C12.0006 21.8295 12.2874 21.7121 12.5149 21.4872L16.0555 17.9856C16.2731 17.7704 16.4017 17.4769 16.4017 17.1639C16.4017 16.851 16.283 16.5673 16.0555 16.3423C15.8379 16.1272 15.5412 16 15.2247 16Z"
        fill="white"
      />
      <rect
        className="stroke"
        x="7"
        y="7"
        width="18"
        height="18"
        rx="2"
        stroke={props.fill}
        strokeWidth="2"
      />
    </svg>
  );
}

export default QuestionIcon;
