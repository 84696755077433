import {
  ActionType,
  Action,
  IQuestionnaireResponseDetailResponse,
} from "models/questionnaires/questionnaireResponseDetail";
import createReducer from "../../createReducer";

export interface QuestionnairesResponseDetailReducerType {
  loading: boolean;
  error?: string;
  questionnairesResponseDetailResponse?: IQuestionnaireResponseDetailResponse;
}

const defaultState: QuestionnairesResponseDetailReducerType = {
  loading: false,
  error: undefined,
  questionnairesResponseDetailResponse: undefined,
};

export const questionnaireResponseDetailReducer =
  createReducer<QuestionnairesResponseDetailReducerType>(defaultState, {
    [ActionType.GET_QUESTIONNAIRE_RESPONSE_DETAIL](
      state: QuestionnairesResponseDetailReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_QUESTIONNAIRE_RESPONSE_DETAIL_ERROR](
      state: QuestionnairesResponseDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        questionnairesResponseDetailResponse: undefined,
      };
    },

    [ActionType.GET_QUESTIONNAIRE_RESPONSE_DETAIL_SUCCESS](
      state: QuestionnairesResponseDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        questionnairesResponseDetailResponse: action.payload,
      };
    },
  });
