import React, { useEffect, useState, useMemo } from "react";
import { Grid, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button, DateRangePicker, Pagination, Select } from "components";
import { WindowIcon, PDFIcon, BackIcon2 } from "icons";
import {
  localStorageHelper,
  generateDynamicRecords,
  handleGetTimeByTimeZone,
} from "utils";
import { IOption } from "components/select/Select";

import {
  GetMessageHistoryListFilterActionCreators,
  GetMessageHistoryDetailActionCreators,
} from "redux/rootActions";
import { IMessageHistoryData } from "models/messagemanagement/messageHistoryList";
import { messageHistoryListDataResponse } from "redux/messagemanagement/messagehistory/messageHistoryListStates";
import {
  IMessageDocuments,
  IMessageHistoryDetail,
  IMessageHistoryDetailData,
  IUserInformation,
} from "models/messagemanagement/messageHistoryDetail";
import { messageHistoryDetailResponse } from "redux/messagemanagement/messagehistorydetail/messageHistoryDetailStates";

import {
  Text,
  CollapseStyled,
  WrapperHeaderCollapse,
  WrapperTextDate,
  TextDateCollapse,
  Container,
  WrapperAddressList,
  WrapperFile,
  ItemFile,
  Image,
  DestinationsModalStyled,
  DayContents,
  HistoryTitleWrapper,
  HistoryContainer,
  HistoryTitle,
  PaginationWrapper,
  WrapperChangeSize,
  WrapperTextContent,
  PaginationBottom,
} from "./MessageDelivery.style";
import ConfirmStatusModal from "./ConfirmStatusModal";
import { httpStatus } from "configs/httpStatus";
import SendIndividualMessage from "./SendIndividualMessage";
import UpdateMessage from "./UpdateMessage";

const { Panel } = Collapse;
const { useBreakpoint } = Grid;

interface IHistoryList {
  handleCancel?: (show: boolean) => void;
}

const HistoryList: React.FC<IHistoryList> = () => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
  const isJapanLanguage = currentLanguage === "ja";

  const messageHistoryList = useSelector(messageHistoryListDataResponse);
  const messageHistoryDetail = useSelector(messageHistoryDetailResponse);

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "M").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [isVisibleDestinationList, setIsVisibleDestinationList] =
    useState<boolean>(false);
  const [confirmStatusVisible, setConfirmStatusVisible] =
    useState<boolean>(false);
  const [messageHistoryResult, setMessageHistoryResult] =
    useState<IMessageHistoryDetail>();
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [input, setInput] = useState({
    limit: 50,
    page: 1,
  });
  const [messageInfor, setMessageInfor] = useState({});
  const [isSendMessageModal, setIsSendMessageModal] = useState<boolean>(false);
  const [messageID, setMessageID] = useState<any>();
  const [individualInfor, setIndividualInfor] = useState<any>({});

  const totalRecords = messageHistoryList?.result.total || 0;
  const dateFormat = "YYYY-MM-DD";
  const params: IMessageHistoryData = {
    locale: currentLanguage,
    from_date: moment(startDate, dateFormat).format(dateFormat),
    to_date: moment(endDate, dateFormat).format(dateFormat),
  };

  useEffect(() => {
    dispatch(
      GetMessageHistoryDetailActionCreators.handleResetHistoryDetailAction()
    );
  }, []);

  useEffect(() => {
    dispatch(
      GetMessageHistoryListFilterActionCreators.handleResetHistoryListAction()
    );
    dispatch(
      GetMessageHistoryListFilterActionCreators.messageHistoryListAction({
        ...params,
        page: 1,
      })
    );
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      messageHistoryDetail &&
      messageHistoryDetail.status === httpStatus.StatusOK
    ) {
      setMessageHistoryResult(messageHistoryDetail.result);

      const userInfor: any =
        messageHistoryList?.result.message_histories.filter(
          (item: any) => item.id === messageID
        );

      if (userInfor.length) {
        setIndividualInfor({
          message_documents: messageHistoryDetail.result.message_documents,
          content: messageHistoryDetail.result.content,
          is_doc_available: userInfor[0].is_doc_available,
          subject: userInfor[0].subject,
          address: messageHistoryDetail.result.user_information,
          startDate: messageHistoryDetail.result.start_time,
        });
      }
    }
  }, [messageHistoryDetail]);

  const messageHistoryMapper = useMemo(() => {
    if (!messageHistoryList || !messageHistoryList.result) {
      return {};
    }

    const mapper: any = {};
    for (const message of messageHistoryList.result.message_histories) {
      mapper[moment(message.date).format(dateFormat)] = true;
    }

    return mapper;
  }, [messageHistoryList]);

  const historyDetailAPICalling = (message_id: string | string[]) => {
    dispatch(
      GetMessageHistoryDetailActionCreators.handleResetHistoryDetailAction()
    );
    const params: IMessageHistoryDetailData = {
      id: message_id,
    };
    dispatch(
      GetMessageHistoryDetailActionCreators.messageHistoryListAction(params)
    );
  };

  const handleShowDestinationList = () => {
    setIsVisibleDestinationList(true);
  };

  const handleCancelDestinationList = () => {
    setIsVisibleDestinationList(false);
  };

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  const handleOnChangeSizeChange = (limit: number) => {
    setInput((prevState: any) => ({ ...prevState, limit, page: 1 }));
  };

  useEffect(() => {
    dispatch(
      GetMessageHistoryListFilterActionCreators.messageHistoryListAction({
        ...params,
        ...input,
      })
    );
  }, [input.page, input.limit]);

  useEffect(() => {
    if (messageHistoryList && Object.keys(messageHistoryList?.result).length) {
      let recordOptionsClone: IOption[] = [];
      recordOptionsClone = generateDynamicRecords(
        messageHistoryList?.result.total,
        t2
      );
      setRecordOptions([...recordOptionsClone]);
    }
  }, [messageHistoryList]);

  const ShowPagination = () => {
    return totalRecords > input.limit ? (
      <Pagination
        current={input.page}
        onChange={handleChangePage}
        pageSize={input.limit}
        total={totalRecords}
        showSizeChanger={false}
        showLessItems={!screens.xl}
      />
    ) : (
      <></>
    );
  };

  const handleBackgroundBadge = (status: any) => {
    if (!status) return "#666666";

    if (status === 1) return "#2AC769";

    return "#2F8CAE";
  };

  const handleBadgeText = (status: any) => {
    if (!status) return t("delivery-setting");

    if (status === 1) return t("confirm-delivery-reservation");

    return t("confirm-open-status");
  };

  const renderMessagesList = () => {
    return (
      <div>
        <CollapseStyled
          accordion
          expandIconPosition="right"
          onChange={(key: string | string[]) => {
            if (!key) return;
            historyDetailAPICalling(key);
          }}
        >
          {messageHistoryList &&
            messageHistoryList.result.message_histories.map(
              (message, index) => (
                <Panel
                  header={
                    <WrapperHeaderCollapse
                      background={index % 2 ? "#FFFFFF" : "#F2F2F2"}
                    >
                      <WrapperTextDate>
                        {message.start_time ? (
                          <TextDateCollapse>
                            <span>
                              {handleGetTimeByTimeZone(
                                "YYYY年MM月DD日",
                                currentTimeZone,
                                message.start_time
                              )}
                            </span>
                            {handleGetTimeByTimeZone(
                              "HH:mm",
                              currentTimeZone,
                              message.start_time
                            )}
                          </TextDateCollapse>
                        ) : (
                          <TextDateCollapse>
                            <span>
                              {handleGetTimeByTimeZone(
                                "YYYY年MM月DD日",
                                currentTimeZone,
                                message.created_at
                              )}
                            </span>
                            {handleGetTimeByTimeZone(
                              "HH:mm",
                              currentTimeZone,
                              message.created_at
                            )}
                          </TextDateCollapse>
                        )}
                        {message.is_doc_available ? (
                          <PDFIcon fill="#FB4E4E" />
                        ) : (
                          <></>
                        )}
                      </WrapperTextDate>
                      <WrapperTextContent>
                        <div className="content">{message.subject}</div>
                        <div
                          className="badge"
                          style={{
                            background: handleBackgroundBadge(message.status),
                            minWidth: 100,
                          }}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setMessageInfor(message);
                            if (message.status === 2) {
                              setConfirmStatusVisible(true);
                            } else {
                              historyDetailAPICalling(message.id as any);
                              setMessageID(message.id);
                              setIsSendMessageModal(true);
                            }
                          }}
                        >
                          {handleBadgeText(message.status)}
                        </div>
                      </WrapperTextContent>
                    </WrapperHeaderCollapse>
                  }
                  key={message.id}
                  className="site-collapse-custom-panel"
                >
                  <Container
                    pd="8px 0px 0px 0px"
                    bdr="none"
                    wrap="wrap"
                    width="100%"
                    style={{ borderTop: "1px solid #fbfbfb" }}
                    background={index % 2 ? "#FFFFFF" : "#F2F2F2"}
                  >
                    <Container
                      pd="0px 0px 7px 0px"
                      style={{ borderBottom: "1px solid #fbfbfb" }}
                    >
                      <Text
                        size={16}
                        lnHeight="18px"
                        weight={700}
                        color="#3B3B3B"
                        width={isJapanLanguage ? "32px" : "58px"}
                        minWidth={isJapanLanguage ? "32px" : "58px"}
                        mg={isJapanLanguage ? "0 16px 0 16px" : "0 12px 0 16px"}
                      >
                        {t("address")}
                      </Text>
                      <WrapperAddressList>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 4,
                          }}
                        >
                          {messageHistoryResult?.user_information.map(
                            (item: IUserInformation, indexAddress: number) => {
                              return (
                                <div
                                  key={indexAddress}
                                  style={{
                                    background:
                                      index % 2 ? "#F2F2F2" : "#FFFFFF",
                                    padding: "3px 4px 4px",
                                    borderRadius: 4,
                                    fontSize: 14,
                                    lineHeight: "14px",
                                    color: "#3B3B3B",
                                  }}
                                >
                                  {item.full_name}
                                </div>
                              );
                            }
                          )}
                        </div>
                        {messageHistoryResult &&
                          messageHistoryResult?.user_information.length >=
                            50 && (
                            <Button
                              icon={<WindowIcon fill="white" />}
                              background="#2F8CAE"
                              color="#fff"
                              name={t("show-all")}
                              border="none"
                              fontWeight={700}
                              fontSize={16}
                              bdr="6px"
                              padding="3px 8px 3px 10px"
                              margin="4px 0 5px 0"
                              onClick={handleShowDestinationList}
                            />
                          )}
                      </WrapperAddressList>
                    </Container>
                    <Text
                      size={16}
                      lnHeight="18px"
                      weight={400}
                      color="#3b3b3b"
                      width="100%"
                      align="left"
                      mg="16px"
                    >
                      {messageHistoryResult?.content}
                    </Text>
                    {message.is_doc_available && (
                      <WrapperFile
                        background={index % 2 ? "#FFFFFF" : "#F2F2F2"}
                      >
                        {messageHistoryResult?.message_documents.map(
                          (file: IMessageDocuments, index: number) => {
                            if (
                              !file.file_name ||
                              file.file_name.includes(".pdf")
                            ) {
                              return (
                                <ItemFile
                                  key={index}
                                  onClick={() => {
                                    window.open(file.file_path, "_blank");
                                  }}
                                >
                                  <div>
                                    <PDFIcon fill="#FFFFFF" />
                                    <div>PDF</div>
                                  </div>
                                  <p>{file.file_name}</p>
                                </ItemFile>
                              );
                            }
                            return (
                              <ItemFile
                                key={index}
                                onClick={() => {
                                  window.open(file.file_path, "_blank");
                                }}
                              >
                                <Image src={file.file_path} alt="msg-img" />
                                <p>{file.file_name}</p>
                              </ItemFile>
                            );
                          }
                        )}
                      </WrapperFile>
                    )}
                  </Container>
                </Panel>
              )
            )}
        </CollapseStyled>
      </div>
    );
  };

  const renderDayContents = (day: any, date: any) => {
    const hasSentMessage =
      !!messageHistoryMapper[moment(date).format(dateFormat)];

    return <DayContents hasSendMessage={hasSentMessage}>{day}</DayContents>;
  };

  const renderDestinationListModal = () => (
    <DestinationsModalStyled
      type="search"
      title={
        <>
          {t("display-all-destinations")}
          <span style={{ marginLeft: 10 }}>
            ({messageHistoryResult?.user_information.length}件)
          </span>
        </>
      }
      visible={isVisibleDestinationList}
      onCancel={handleCancelDestinationList}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {messageHistoryResult?.user_information.map(
          (item: IUserInformation, index: number) => {
            return (
              <div
                key={index}
                style={{
                  background: "#F2F2F2",
                  padding: 4,
                  marginRight: 5,
                  marginBottom: 4,
                  borderRadius: 4,
                  fontSize: 12,
                  lineHeight: "12px",
                  color: "#3B3B3B",
                }}
              >
                {item.full_name}
              </div>
            );
          }
        )}
      </div>
    </DestinationsModalStyled>
  );

  return (
    <>
      <HistoryTitleWrapper>
        <Link to="/message-delivery">
          <BackIcon2 className="back" />
        </Link>
        <div
          style={{
            fontSize: 16,
            lineHeight: "23px",
            fontWeight: "bold",
            marginLeft: 2,
          }}
        >
          {t1("message-delivery-label")}
        </div>
      </HistoryTitleWrapper>
      <HistoryTitle>
        <div className="title">{t("list-of-sent-messages")}</div>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          locale={currentLanguage}
          renderDayContents={renderDayContents}
        />
      </HistoryTitle>
      <HistoryContainer>
        <PaginationWrapper>
          <WrapperChangeSize>
            <div style={{ fontWeight: 500 }}>{t2("record")}</div>
            <Select
              defaultValue={50}
              options={recordOptions}
              onChange={handleOnChangeSizeChange}
            />
          </WrapperChangeSize>
          <ShowPagination />
        </PaginationWrapper>
        {messageHistoryList?.result.message_histories.length ? (
          renderMessagesList()
        ) : (
          <Text
            size={16}
            lnHeight="24px"
            weight={700}
            width="max-content"
            color="#666666"
            mg={screens.xs ? "-8px 0px 0px" : "0px"}
          >
            {t("no-message-was-sent.")}
          </Text>
        )}
        {totalRecords > input.limit && (
          <PaginationBottom>
            <ShowPagination />
          </PaginationBottom>
        )}
      </HistoryContainer>
      {confirmStatusVisible && (
        <ConfirmStatusModal
          messageContent={messageHistoryResult?.content}
          messageInfor={messageInfor}
          isVisible={confirmStatusVisible}
          handleCancel={setConfirmStatusVisible}
        />
      )}
      {isSendMessageModal && (
        <UpdateMessage
          isVisible={isSendMessageModal}
          handleCancel={() => setIsSendMessageModal(false)}
          individualInfor={individualInfor}
          params={params}
          messageID={messageID}
        />
      )}
      {isVisibleDestinationList && renderDestinationListModal()}
    </>
  );
};

export default HistoryList;
