import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  PDFDownloadScoringInfoResponse,
  ActionType,
  IMentalCheckScoringDownloadPayload,
} from "models/mentalHealth/pdfDownloadScoringInfo";
import { httpStatus } from "configs/httpStatus";
import { downloadPDFScoringInfo } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./downloadScoringPDFActions";

function* pdfDownloadScoringInfoSaga({
  payload,
}: {
  payload: IMentalCheckScoringDownloadPayload;
}) {
  try {
    const response: PDFDownloadScoringInfoResponse = yield call(
      downloadPDFScoringInfo,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDownloadPDFScoringInfoWatcher() {
  yield takeLatest(
    ActionType.PDF_DOWNLOAD_SCORING_INFO as any,
    pdfDownloadScoringInfoSaga
  );
}

export default [fork(onDownloadPDFScoringInfoWatcher)];
