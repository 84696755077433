import {
  ActionType,
  AccountRegistrationListResponse,
} from "models/account/registrationList";

export const getRegistrationListAction = () => {
  return {
    type: ActionType.ACCOUNT_REGISTRATION_LIST,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.ACCOUNT_REGISTRATION_LIST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (
  payload: AccountRegistrationListResponse
) => {
  return {
    type: ActionType.ACCOUNT_REGISTRATION_LIST_SUCCESS,
    payload,
  };
};
