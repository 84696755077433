import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  IMentalCheckScoringPayload,
  ActionType,
} from "models/mentalHealth/scoringInformation";
import { IMentalCheckScoringResponse } from "models/mentalHealth/scoringInformation";
import { httpStatus } from "configs/httpStatus";
import { getMentalCheckScoringInformation } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./scoringInformationActions";

function* getScoringInformationSaga({
  payload,
}: {
  payload: IMentalCheckScoringPayload;
}) {
  try {
    const response: IMentalCheckScoringResponse = yield call(
      getMentalCheckScoringInformation,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusUnauthorized) {
      yield put(handleErrorAction(response.message || ""));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetScoringInformationWatcher() {
  yield takeLatest(
    ActionType.MENTAL_CHECK_SCORING as any,
    getScoringInformationSaga
  );
}

export default [fork(onGetScoringInformationWatcher)];
