import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Box, Button, Select, Pagination } from "components";
import { NextIcon } from "icons";
import {
  convertRawDataToOptions,
  generateDynamicRecords,
  localStorageHelper,
} from "utils";
import { IOption } from "components/select/Select";

import { classListDataResponse } from "redux/classlist/classListStates";
import {
  ClassListActionCreators,
  GetConfirmStudentListActionCreators,
  ClearTempRegisterActionCreators,
} from "redux/rootActions";
import { ClassListData } from "models/classList";
import {
  IConfirmStudentListData,
  IConfirmStudentListItem,
} from "models/classchangemanagement/confirmStudentListClassChange";
import { IClearTempRegisterData } from "models/classchangemanagement/clearTempRegisterClassChange";
import {
  confirmStudentListResponse,
  selectIsLoading,
} from "redux/classchangemanagement/getConfirmStudentData/getConfirmStudentListStates";

import {
  WrapperTable,
  MiddleTable,
  WrapperSizeChange,
  WrapperPaginationTop,
  WrapperIcon,
  WrapperPaginationBottom,
  WrapperAction,
  TextLink,
  SubTableStyled,
  NoDataMessage,
  ConfirmModalStyled,
  TextConfirm,
  WrapperBtnConfirmModal,
} from "./ChangeStudentAffiliation.style";

const ConfirmData = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "change-student-affiliation",
  });
  const { t: t2 } = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });

  const dispatch = useDispatch();
  const { watch, handleSubmit, control } = useForm();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const classListResponse = useSelector(classListDataResponse);
  const studentListResponse = useSelector(confirmStudentListResponse);
  const isLoading = useSelector(selectIsLoading);

  const [isVisible, setIsVisible] = useState(false);
  const [recordOptions, setRecordOptions] = useState<IOption[]>([]);
  const [confirmStudentList, setConfirmStudentList] = useState<
    IConfirmStudentListItem[]
  >([]);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [idItemDelete, setIdItemDelete] = useState<number>(0);
  const [input, setInput] = useState({
    page: 1,
    per_page: 50,
  });
  const [classListOptions, setClassListOptions] = useState<IOption[]>([]);

  const watchCurrentClass = watch("select-current-class");
  const watchChangedClass = watch("select-changed-class");

  useEffect(() => {
    if (classListResponse && classListResponse?.result) {
      const classOptionsClone = convertRawDataToOptions(
        classListResponse?.result
      );
      setClassListOptions([
        ...[{ id: -1, value: "", name: "選択してください" }],
        ...classOptionsClone,
      ]);
    }
  }, [classListResponse]);

  useEffect(() => {
    dispatch(GetConfirmStudentListActionCreators.handleResetDataAction());
    const classListparams: ClassListData = { locale: currentLanguage };
    dispatch(ClassListActionCreators.classListAction(classListparams));
  }, []);

  useEffect(() => {
    studentListResponse
      ? setConfirmStudentList(studentListResponse?.result)
      : setConfirmStudentList([]);
    studentListResponse
      ? setTotalRecord(studentListResponse?.total_record)
      : setTotalRecord(0);

    let recordOptionsClone: IOption[] = [];
    if (studentListResponse && studentListResponse.total_record > 0) {
      recordOptionsClone = generateDynamicRecords(
        studentListResponse.total_record,
        t
      );
    } else {
      recordOptionsClone = generateDynamicRecords(0, t);
    }

    setRecordOptions([...recordOptionsClone]);
  }, [studentListResponse]);

  useEffect(() => {
    const studentListParams: IConfirmStudentListData = {
      current_class_id: watchCurrentClass,
      new_class_id: watchChangedClass,
      ...input,
      locale: currentLanguage,
    };

    dispatch(
      GetConfirmStudentListActionCreators.getConfirmStudentListAction(
        studentListParams
      )
    );
  }, [input.page, input.per_page]);

  const onSubmit = () => {
    const studentListParams: IConfirmStudentListData = {
      current_class_id: watchCurrentClass,
      new_class_id: watchChangedClass,
      page: 1,
      per_page: input.per_page,
      locale: currentLanguage,
    };

    dispatch(
      GetConfirmStudentListActionCreators.getConfirmStudentListAction(
        studentListParams
      )
    );
  };

  const handleShowDeleteModal = (id: number) => {
    setIsVisible(true);
    setIdItemDelete(id);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleDelete = () => {
    const params: IClearTempRegisterData = {
      id: idItemDelete,
      locale: currentLanguage,
    };
    dispatch(ClearTempRegisterActionCreators.clearTempRegisterAction(params));
    setIsVisible(false);
    const datas: any = [];
    confirmStudentList.forEach((studentList: any) => {
      if (studentList.id !== idItemDelete) {
        datas.push(studentList);
      }
    });
    setConfirmStudentList(datas);
  };

  const DeleteModal = () => (
    <ConfirmModalStyled
      type="confirm"
      footer={null}
      visible={isVisible}
      onCancel={handleCancel}
    >
      <div style={{ textAlign: "center" }}>
        <TextConfirm>{t3("class-management-item-delete-message")}</TextConfirm>
        <WrapperBtnConfirmModal>
          <Button
            name={t("cancel")}
            background="#E0E0E0"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            onClick={handleCancel}
          />
          <Button
            name="OK"
            background="#FB4E4E"
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            onClick={handleDelete}
          />
        </WrapperBtnConfirmModal>
      </div>
    </ConfirmModalStyled>
  );

  const handleOnChangeSizeChange = (per_page: number) => {
    setInput((prevState: any) => ({ ...prevState, per_page, page: 1 }));
  };

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  const RenderPagination = () => {
    return totalRecord > input.per_page ? (
      <Pagination
        current={input.page}
        onChange={handleChangePage}
        pageSize={input.per_page}
        total={studentListResponse?.total_record}
        showSizeChanger={false}
      />
    ) : (
      <Fragment></Fragment>
    );
  };

  const columns = [
    {
      title: t("full-name"),
      dataIndex: "full_name",
      key: "displayName",
      width: 191,
      fixed: window.innerWidth < 576 ? "" : "left",
    },
    {
      title: () => {
        return (
          <div>
            {t1("current")}
            <br /> {t("grade")}
          </div>
        );
      },
      dataIndex: "old_class",
      key: "current_class",
      width: 128,
    },
    {
      title: () => {
        return (
          <div>
            {t1("current")}
            <br /> {t("attendance")}
          </div>
        );
      },
      dataIndex: "current_roll_number",
      key: "current_attendance_number",
      width: 116,
    },
    {
      title: () => {
        return (
          <div
            style={{
              position: "relative",
              color: "#2F8CAE",
              fontWeight: "bold",
            }}
          >
            <WrapperIcon>
              <NextIcon fill={"#2F8CAE"} />
            </WrapperIcon>
            <p>
              {t1("after-change")}
              <br /> {t("grade")}
            </p>
          </div>
        );
      },
      dataIndex: "new_class",
      key: "change_class",
      width: 148,
      render: (text: any) => <TextLink>{text}</TextLink>,
    },
    {
      title: () => {
        return (
          <TextLink>
            {t1("after-change")}
            <br /> {t("attendance")}
          </TextLink>
        );
      },
      dataIndex: "new_roll_number",
      key: "affer_attendance_number",
      width: 96,
      render: (text: any) => <TextLink>{text}</TextLink>,
    },
    {
      title: t1("last-modified"),
      dataIndex: "updated_at",
      key: "last_modified",
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (text: any, item: any) => (
        <Button
          name={t1("clear")}
          type="submit"
          background="#E0E0E0"
          color="#fff"
          bdr="6px"
          padding="2px 8px"
          border="none"
          fontWeight={700}
          fontSize={16}
          onClick={() => handleShowDeleteModal(item?.id)}
        />
      ),
    },
  ];

  return (
    <Box title={t2("confirm-data-label")} padding="16px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrapperAction>
          <Controller
            control={control}
            name="select-current-class"
            render={({ field: { value, onChange } }) => (
              <Select
                placeholder={t("please-select")}
                options={classListOptions.filter((item) => item.id)}
                width="156px"
                value={value}
                onChange={onChange}
                label={t1("current")}
              />
            )}
          />
          <Controller
            control={control}
            name="select-changed-class"
            render={({ field: { value, onChange } }) => (
              <Select
                placeholder={t("please-select")}
                options={classListOptions}
                width="156px"
                value={value}
                onChange={onChange}
                label={t1("after-change")}
              />
            )}
          />
          <Button
            name={t1("display")}
            padding="3.5px 16px 4.5px"
            background="#2AC769"
            bdr="6px"
            border="none"
            color="#FFFFFF"
            fontSize={16}
            lineHeight="23px"
            fontWeight={700}
          />
        </WrapperAction>
      </form>
      <WrapperTable>
        <MiddleTable>
          <WrapperSizeChange>
            <div style={{ fontWeight: 500 }}>{t("record")}</div>
            <Select
              defaultValue={50}
              options={recordOptions}
              onChange={handleOnChangeSizeChange}
            />
          </WrapperSizeChange>
          <WrapperPaginationTop>
            <RenderPagination />
          </WrapperPaginationTop>
        </MiddleTable>
        <SubTableStyled
          columns={columns}
          dataSource={confirmStudentList}
          scroll={{ x: "max-content" }}
          loading={isLoading}
        />
        {!confirmStudentList && (
          <NoDataMessage>{t1("confirm_no-data-message")}</NoDataMessage>
        )}
        {confirmStudentList && confirmStudentList.length === 0 && (
          <NoDataMessage>{t1("confirm_no-data-message-list")}</NoDataMessage>
        )}
        {isVisible && <DeleteModal />}
        <WrapperPaginationBottom>
          <RenderPagination />
        </WrapperPaginationBottom>
      </WrapperTable>
    </Box>
  );
};

export default ConfirmData;
