import {
  Action,
  ActionType,
  AttendanceFilterListResponse,
} from "models/messagemanagement/attendanceFilterList";
import createReducer from "../../createReducer";

export interface AttendanceFilterListReducerType {
  loading: boolean;
  error?: string;
  attendanceFilterListResponse?: AttendanceFilterListResponse;
}

const defaultState: AttendanceFilterListReducerType = {
  loading: false,
  error: undefined,
  attendanceFilterListResponse: undefined,
};

export const attendanceFilterListReducer =
  createReducer<AttendanceFilterListReducerType>(defaultState, {
    [ActionType.ATTENDANCE_FILTER_LIST](
      state: AttendanceFilterListReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.ATTENDANCE_FILTER_LIST_ERROR](
      state: AttendanceFilterListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.ATTENDANCE_FILTER_LIST_SUCCESS](
      state: AttendanceFilterListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        attendanceFilterListResponse: action.payload,
      };
    },
  });
