import { IResponse } from "models";

export enum ActionType {
  PDF_DOWNLOAD_SCORING_INFO = "action/PDF_DOWNLOAD_SCORING_INFO",
  PDF_DOWNLOAD_SCORING_INFO_SUCCESS = "action/PDF_DOWNLOAD_SCORING_INFO_SUCCESS",
  PDF_DOWNLOAD_SCORING_INFO_ERROR = "action/PDF_DOWNLOAD_SCORING_INFO_ERROR",
  PDF_DOWNLOAD_SCORING_INFO_RESET_DATA = "action/PDF_DOWNLOAD_SCORING_INFO_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface PDFDownloadScoringInfoResponse extends IResponse {
  result?: IPDFDownloadResult;
}

export interface IMentalCheckScoringDownloadPayload {
  mental_check_type_id: string;
  mental_check_request_id: string;
  patient_id: string;
}

export interface IHeaderPDFDownloadScoringInfoItem {
  label: string;
  key: string;
}

interface IProlems {
  emotional_disturbance: boolean;
  attention_care: boolean;
  normal: boolean;
  question_name: string;
}

interface IUserInfoResponse {
  name: string;
  grade_class: string;
  age: string;
  gender: string;
}

export interface IPDFDownloadResult {
  result_set: {
    user_information?: IUserInfoResponse[];
    feelings_mood_problem?: IProlems[];
    concentrations_problem?: IProlems[];
    behavioral_problem?: IProlems[];
    relations_problem?: IProlems[];
  };
  headers: {
    user_information: IHeaderPDFDownloadScoringInfoItem[];
    feelings_mood_problem?: IHeaderPDFDownloadScoringInfoItem[];
    concentrations_problem?: IHeaderPDFDownloadScoringInfoItem[];
    behavioral_problem?: IHeaderPDFDownloadScoringInfoItem[];
    relations_problem?: IHeaderPDFDownloadScoringInfoItem[];
  };
}
