import { put, takeLatest, fork, call } from "redux-saga/effects";
import { ActionType } from "models/questionnaires/questionHistoryDetail";
import { httpStatus } from "configs/httpStatus";
import { fetchHistoryDetail } from "services/questionnaires";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./questionHistoryDetailActions";
import {
  IQuestionHistoryDetailData,
  IQuestionHistoryDetailResponse,
} from "models/questionnaires/questionHistoryDetail";

function* questionHistoryDetailSaga({
  payload,
}: {
  payload: IQuestionHistoryDetailData;
}) {
  try {
    const response: IQuestionHistoryDetailResponse = yield call(
      fetchHistoryDetail,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(
        handleSuccesAction({
          ...response,
          result: { user_information: [], questionnaire_questions: [] },
        })
      );
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onQuestionHistoryDetailWatcher() {
  yield takeLatest(
    ActionType.QUESTION_HISTORY_DETAIL as any,
    questionHistoryDetailSaga
  );
}

export default [fork(onQuestionHistoryDetailWatcher)];
