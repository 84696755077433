import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DataFeverPercentageResponse,
} from "models/bodyTemperature/feverPercentage";
import { httpStatus } from "configs/httpStatus";
import { fetchFeverPercentData } from "services/bodyTemperature";

import {
  handleSuccesAction,
  handleErrorAction,
} from "./feverPercentageActions";

function* getDataFeverPercentageSaga({
  payload,
}: {
  payload: { date: string };
}) {
  try {
    const response: DataFeverPercentageResponse = yield call(
      fetchFeverPercentData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDataFeverPercentageWatcher() {
  yield takeLatest(
    ActionType.DATA_FEVER_PERCENTAGE as any,
    getDataFeverPercentageSaga
  );
}

export default [fork(onGetDataFeverPercentageWatcher)];
