import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "antd";
import moment from "moment";

import { Button, TextArea, ImageUploader, Input, Select } from "components";
import {
  CloseIcon,
  ConfirmSuccessIcon,
  ConfirmFailIcon,
  ConfirmDeliveryIcon,
} from "icons";
import {
  handleGetTimeByTimeZone,
  handleTimeOptions,
  localStorageHelper,
} from "utils";

import { SendMessageActionCreators } from "redux/rootActions";
import {
  sendMessageDataResponse,
  sendMessageErrorMessage,
  selectIsLoading,
} from "redux/messagemanagement/sendmessage/sendMessageStates";
import { httpStatus } from "configs/httpStatus";

import {
  Text,
  ConfirmContainer,
  TextConfirm2,
  ButtonContainer,
  SectionContainer,
  Label,
  TagsContainer,
  BadgeContainer,
  WrapperBtnForm,
  ConfirmModalStyled,
  ConfirmBtnStyled,
  FailText,
  SendAllBadge,
  SendMessageModalStyled,
  WrapperSelectPeriod,
  Line,
  WrapperSendImmediately,
  Flex,
} from "./MessageDelivery.style";
import { DatePicker } from "./components";
import { format, timeValueDefaults } from "./constants";

interface ISendMessageModal {
  isVisible: boolean;
  isSendAll: boolean;
  selectedRowKeys: number[];
  selectedRows: any;
  handleCancel: () => void;
  handleRemoveDestination: (id: number) => void;
}

const schema = yup.object().shape({
  message_title: yup.string().trim().required("message-title-required"),
  message: yup.string().required("message-required"),
});

const SendMessageModal: React.FC<ISendMessageModal> = ({
  isVisible,
  isSendAll,
  handleCancel,
  selectedRowKeys,
  selectedRows,
  handleRemoveDestination,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });
  const { handleSubmit, control, watch, reset, clearErrors } = useForm({
    resolver: yupResolver(schema),
  });

  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const dispatch = useDispatch();
  const sendMessageSuccess = useSelector(sendMessageDataResponse);
  const sendMessageError = useSelector(sendMessageErrorMessage);
  const sendMessageLoading = useSelector(selectIsLoading);
  const watchmessageTitle = watch("message_title");
  const watchmessage = watch("message");

  const [isDisabled, setIsDisabled] = useState(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isVisibleNotification, setIsVisibleNotification] = useState({
    type: "",
    status: false,
  });
  const [fileList, setFileList] = useState<any>([]);
  const [value, setValue] = useState("future");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [timeOptions, setTimeOptions] = useState([]);
  const [timeValue, setTimeValue] = useState<string>();

  useEffect(() => {
    dispatch(SendMessageActionCreators.handleResetDataAction());
  }, []);

  useEffect(() => {
    const currentTimeZone = localStorageHelper.getItem("currentTimeZone");
    const startDateConverted = handleGetTimeByTimeZone(
      format,
      currentTimeZone,
      startDate
    );
    const currentDate = handleGetTimeByTimeZone(
      format,
      currentTimeZone,
      new Date()
    );

    if (startDateConverted === currentDate) {
      const hour =
        moment(
          handleGetTimeByTimeZone(
            "YYYY/MM/DD HH:mm",
            currentTimeZone,
            new Date()
          )
        ).hour() + 1;

      const timeOptions: any = handleTimeOptions(hour);

      setTimeOptions(timeOptions);
      setTimeValue(`${hour}:00`);
    } else {
      setTimeOptions(timeValueDefaults);
      setTimeValue("00:00");
    }
  }, [startDate]);

  const handleDisabledBtn = (id: number) => {
    const selectedRowKeysClone = selectedRowKeys.filter(
      (key: number) => key !== id
    );
    if (!selectedRowKeysClone.length) {
      setIsDisabled(true);
    }
  };

  const onUploadFileChange = (someArg: any) => {
    setFileList(someArg);
  };

  const onSendMessageAPI = () => {
    const formData = new FormData();
    formData.append("title", watchmessageTitle);
    formData.append("description", watchmessage);
    formData.append("patient_id", selectedRowKeys.join(",") || "");
    formData.append("is_send_all", isSendAll as any);
    if (value === "immediately") {
      formData.append("is_send_immediately", true as any);
    }
    if (value === "future") {
      const start = moment(startDate).format(format) + " " + timeValue;
      formData.append("is_send_immediately", false as any);
      formData.append("start_time", start);
    }
    fileList.map((item: any) => {
      if (!item.isDelete) {
        formData.append(
          `message_documents[][document]`,
          item.file.originFileObj
        );
      }
    });

    dispatch(SendMessageActionCreators.sendMessageAction(formData));
  };

  const handleCancelSendMessageModal = () => {
    reset();
    clearErrors();
    setFileList([]);
    handleCancel();
  };

  const handleConfirmMessage = () => {
    setIsVisibleNotification({
      ...isVisibleNotification,
      type: "success",
      status: true,
    });
  };

  const handleFailMessage = () => {
    setIsVisibleNotification({
      ...isVisibleNotification,
      type: "fail",
      status: true,
    });
  };

  const handleCancelNotication = () => {
    setIsVisibleNotification({ type: "", status: false });
    dispatch(SendMessageActionCreators.handleResetDataAction());
    handleCancelSendMessageModal();
  };

  const handleCancelConfirmMessage = () => {
    setShowConfirm(false);
  };

  useEffect(() => {
    if (
      sendMessageSuccess &&
      sendMessageSuccess.status === httpStatus.StatusOK
    ) {
      handleConfirmMessage();
    } else if (sendMessageError) {
      handleFailMessage();
    } else {
      handleCancelConfirmMessage();
    }
  }, [sendMessageSuccess, sendMessageError]);

  useEffect(() => {
    setIsVisibleNotification({ type: "", status: false });
  }, []);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const SuccessModal = () => (
    <ConfirmModalStyled
      type="form"
      title=""
      visible={isVisibleNotification.status}
      onCancel={handleCancelNotication}
    >
      <div style={{ textAlign: "center", marginTop: 24 }}>
        {value === "immediately" ? (
          <ConfirmSuccessIcon />
        ) : (
          <ConfirmDeliveryIcon
            style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" }}
          />
        )}
        <Text
          size={36}
          lnHeight="36px"
          weight={700}
          color="#2ac769"
          mg="12px 0px 24px"
        >
          {value === "immediately"
            ? t("send-completed")
            : t("delivery-reservation-completed")}
        </Text>
        <Text size={16} lnHeight="24px" weight={400}>
          {value === "immediately"
            ? t("delivered-succesfully")
            : t("delivery-reservation-has-completed")}
        </Text>
        <Text size={16} lnHeight="24px" weight={400} mg="0px 0px 24px">
          {t("close-window")}
        </Text>
        <ConfirmBtnStyled
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 96px 13px"
          name={t("close-up")}
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          onClick={handleCancelNotication}
        />
      </div>
    </ConfirmModalStyled>
  );

  const FailureModal = () => (
    <ConfirmModalStyled
      type="form"
      title=""
      visible={isVisibleNotification.status}
      onCancel={handleCancelNotication}
    >
      <div style={{ textAlign: "center", marginTop: 24 }}>
        <ConfirmFailIcon />
        <Text
          size={36}
          lnHeight="36px"
          weight={700}
          color="#fb4e4e"
          mg="20px 0px 24px"
        >
          {t("transmission-failure")}
        </Text>
        <FailText size={16} lnHeight="24px" weight={400}>
          {t("send-message-failed")}
        </FailText>
        <FailText size={16} lnHeight="24px" weight={400} mg="0px 0px 24px">
          {t("sorry-please-try-again")}
        </FailText>
        <ConfirmBtnStyled
          background="#E0E0E0"
          color="#FFFFFF"
          padding="11px 96px 13px"
          name={t("close-up")}
          border="none"
          bdr="6px"
          fontSize={16}
          lineHeight="16px"
          onClick={handleCancelNotication}
        />
      </div>
    </ConfirmModalStyled>
  );

  const renderNotificationModal = () => {
    if (isVisibleNotification.type === "success") {
      return <SuccessModal />;
    }
    return <FailureModal />;
  };

  const ConfirmModal = () => {
    if (value === "future") {
      return (
        <ConfirmContainer>
          <TextConfirm2>
            <div className="text-color">
              {moment(startDate).format("YYYY/MM/DD")}
              {currentLanguage !== "ja" && (
                <span style={{ marginLeft: 5, color: "#333333" }}>at</span>
              )}
              <span style={{ marginLeft: 5 }}>{timeValue}</span>
              {currentLanguage === "ja" && (
                <span style={{ color: "#333333" }}>で</span>
              )}
            </div>
            <div>{t("schedule-message")}</div>
            <div>{t("are-you-ok")}</div>
          </TextConfirm2>
          <ButtonContainer>
            <Button
              background="#E0E0E0"
              color="#FFFFFF"
              padding="11px 24px 13px"
              name={t1("cancel")}
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              lineHeight="16px"
              onClick={handleCancelConfirmMessage}
            />
            <Button
              background="#2AC769"
              color="#FFFFFF"
              padding="11px 48px 13px"
              name={t("yes")}
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              lineHeight="16px"
              disabled={sendMessageLoading}
              onClick={onSendMessageAPI}
            />
          </ButtonContainer>
        </ConfirmContainer>
      );
    }

    if (value === "immediately") {
      return (
        <ConfirmContainer>
          <TextConfirm2>
            <div>{t("deliver-the-message")}</div>
            <div>{t("are-you-ok")}</div>
          </TextConfirm2>
          <ButtonContainer>
            <Button
              background="#E0E0E0"
              color="#FFFFFF"
              padding="11px 24px 13px"
              name={t1("cancel")}
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              lineHeight="16px"
              onClick={handleCancelConfirmMessage}
            />
            <Button
              background="#2AC769"
              color="#FFFFFF"
              padding="11px 48px 13px"
              name={t("yes")}
              border="none"
              bdr="6px"
              fontSize={16}
              fontWeight={700}
              lineHeight="16px"
              disabled={sendMessageLoading}
              onClick={onSendMessageAPI}
            />
          </ButtonContainer>
        </ConfirmContainer>
      );
    }

    return (
      <ConfirmContainer>
        <TextConfirm2>
          <div>{t("save-and-close-window")}</div>
          <div>{t("are-you-ok")}</div>
        </TextConfirm2>
        <ButtonContainer>
          <Button
            background="#E0E0E0"
            color="#FFFFFF"
            padding="11px 24px 13px"
            name={t1("cancel")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            onClick={handleCancelConfirmMessage}
          />
          <Button
            background="#2AC769"
            color="#FFFFFF"
            padding="11px 48px 13px"
            name={t("yes")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            disabled={sendMessageLoading}
            onClick={onSendMessageAPI}
          />
        </ButtonContainer>
      </ConfirmContainer>
    );
  };

  const onChangeTime = (value: any) => {
    setTimeValue(value);
  };

  const renderSendMessageModal = () => (
    <SendMessageModalStyled
      type="form"
      title={t("send-message")}
      visible={isVisible}
      onCancel={() => {
        handleCancelSendMessageModal();
        setIsDisabled(false);
      }}
    >
      {showConfirm && <ConfirmModal />}
      <form onSubmit={handleSubmit(() => setShowConfirm(true))}>
        <SectionContainer>
          <Label>{t("address")}</Label>
          <TagsContainer>
            <BadgeContainer>
              {isSendAll ? (
                <SendAllBadge>{t("all-registrants")}</SendAllBadge>
              ) : (
                <>
                  {selectedRows.map((user: any) => (
                    <div key={user.id}>
                      <p>{user.name}</p>
                      <CloseIcon
                        fill="#3B3B3B"
                        onClick={() => {
                          handleRemoveDestination(user.id);
                          handleDisabledBtn(user.id);
                        }}
                      />
                    </div>
                  ))}
                </>
              )}
            </BadgeContainer>
          </TagsContainer>
        </SectionContainer>
        <Controller
          control={control}
          name="message_title"
          render={({ field: { onChange }, fieldState: { error } }) => (
            <Input
              label={
                <>
                  {t("subject")}
                  <span style={{ color: "#EB5757", marginLeft: 5 }}>*</span>
                </>
              }
              height={54}
              fw={700}
              fsLabel={16}
              fwLabel={700}
              marginForm="0 0 16px"
              lhLabel="22px"
              bdr={12}
              onChange={onChange}
              error={t(error?.message)}
            />
          )}
        />
        <Controller
          control={control}
          name="message"
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextArea
              height="248px"
              fsLabel={16}
              fwLabel={700}
              lhLabel="22px"
              marginForm="0 0 16px"
              onChange={onChange}
              error={t(error?.message)}
              label={
                <>
                  {t("message")}
                  <span style={{ color: "#EB5757", marginLeft: 5 }}>*</span>
                </>
              }
            />
          )}
        />
      </form>

      <div>
        <Label>{t("attachment")}</Label>
        <ImageUploader
          onUploadFileChange={onUploadFileChange}
          fileList={fileList}
          setFileList={setFileList}
        />
      </div>
      <Radio.Group onChange={onChange} value={value}>
        <Radio value="future">{t("specify-date-time")}</Radio>
      </Radio.Group>
      {value === "future" && (
        <WrapperSelectPeriod>
          <div>
            <div>配信日</div>
            <Flex>
              <DatePicker
                onChange={(date: any) => setStartDate(date)}
                selected={startDate}
                iconPosition="right"
                minDate={startDate}
                placeholderText="YYYY/MM/DD"
              />
            </Flex>
          </div>
          <div>
            <div>配信時間</div>
            <Select
              options={timeOptions}
              onChange={onChangeTime}
              value={timeValue}
            />
          </div>
          <Button
            onClick={() => setShowConfirm(true)}
            type="submit"
            name={t("save-date-time")}
            background={"#2AC769"}
            color="#FFFFFF"
            border="none"
            fontSize={16}
            fontWeight={700}
            padding="7px 24px"
            // style={
            //   screens.md
            //     ? currentLanguage === "ja"
            //       ? { marginLeft: 74 }
            //       : { marginLeft: 160 }
            //     : {}
            // }
          />
        </WrapperSelectPeriod>
      )}
      <Line />
      <WrapperSendImmediately>
        <Radio.Group onChange={onChange} value={value}>
          <Radio value="immediately">{t("send-now")}</Radio>
        </Radio.Group>
        {value === "immediately" && (
          <Button
            background="#2AC769"
            color="#FFFFFF"
            name={t2("message-delivery-label")}
            border="none"
            bdr="6px"
            fontSize={16}
            fontWeight={700}
            lineHeight="16px"
            padding="11px 24px 13px"
            onClick={(e: any) => {
              e.preventDefault();
              setShowConfirm(true);
            }}
          />
        )}
      </WrapperSendImmediately>
      {/* <WrapperBtnForm>
        <Button
          background="#E0E0E0"
          color="#FFFFFF"
          name={t("save-and-close")}
          border="none"
          bdr="6px"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          onClick={() => {
            setValue("");
            setShowConfirm(true);
          }}
        />
      </WrapperBtnForm> */}
    </SendMessageModalStyled>
  );

  return (
    <>
      {isVisibleNotification.status
        ? renderNotificationModal()
        : renderSendMessageModal()}
    </>
  );
};

export default SendMessageModal;
