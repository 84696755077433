import * as React from "react";

function StudentAssociationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.12157 12.2923C2.39173 13.431 2.00278 14.7573 2.00122 16.1129C2.00082 16.346 2.09052 16.5703 2.2513 16.7379C2.41725 16.9019 2.63914 16.9956 2.87149 17C3.10406 16.9966 3.32632 16.9027 3.4917 16.7379C3.57494 16.657 3.64093 16.5599 3.68565 16.4524C3.73037 16.345 3.75287 16.2294 3.75177 16.1129C3.75074 15.4179 3.88634 14.7295 4.15075 14.0876C4.41515 13.4457 4.80311 12.863 5.29225 12.373C5.77779 11.8792 6.35595 11.4876 6.99308 11.2211C7.63021 10.9546 8.31358 10.8184 9.00342 10.8205C9.69309 10.8195 10.3761 10.9562 11.0131 11.2226C11.6501 11.4891 12.2284 11.8801 12.7146 12.373C13.6988 13.3652 14.2527 14.7099 14.2551 16.1129C14.2547 16.346 14.3444 16.5703 14.5051 16.7379C14.6711 16.9019 14.893 16.9956 15.1253 17C15.3579 16.9966 15.5802 16.9027 15.7455 16.7379C15.8288 16.657 15.8948 16.5599 15.9395 16.4524C15.9842 16.345 16.0067 16.2294 16.0056 16.1129C16.0041 14.7573 15.6151 13.431 14.8853 12.2923C14.154 11.1526 13.112 10.2496 11.8843 9.6915C12.3551 9.28943 12.731 8.78668 12.9847 8.21977C13.2415 7.65341 13.3746 7.038 13.3748 6.4153C13.3752 5.24391 12.9148 4.12016 12.0944 3.29032C11.2746 2.46417 10.1628 2 9.00342 1.99997C7.84104 1.99954 6.7259 2.46358 5.90244 3.29032C5.08205 4.12016 4.62161 5.24391 4.62204 6.4153C4.62707 7.03747 4.7599 7.65189 5.01216 8.21977C5.26922 8.78466 5.64461 9.28671 6.11251 9.6915C4.8885 10.2512 3.85018 11.1541 3.12157 12.2923ZM7.12283 8.26007C6.63193 7.76522 6.35508 7.09489 6.35259 6.39518C6.35537 5.87207 6.50821 5.36092 6.79272 4.92338C7.07841 4.47695 7.48924 4.12609 7.9731 3.91532C8.45407 3.71504 8.98304 3.66241 9.49358 3.76408C10.003 3.86356 10.4705 4.11671 10.834 4.48992C11.2009 4.85886 11.4514 5.32882 11.5542 5.84075C11.6551 6.35525 11.6029 6.88827 11.4042 7.37297C11.1961 7.86122 10.8477 8.27551 10.4039 8.56248C9.97118 8.85242 9.46301 9.00676 8.9434 9.00603C8.2734 9.00398 7.62942 8.74442 7.14284 8.28025L7.12283 8.26007Z"
        fill={props.fill}
      />
      <path
        d="M17.116 25.2993C16.3861 26.4387 15.9972 27.766 15.9956 29.1224C15.9956 29.2388 16.0187 29.3541 16.0633 29.4615C16.108 29.5689 16.1734 29.6662 16.2557 29.7478C16.4221 29.91 16.6445 30.0004 16.8759 30C17.1071 29.9994 17.3291 29.9091 17.4961 29.7478C17.656 29.5794 17.7455 29.3555 17.7462 29.1224C17.7485 27.7185 18.3024 26.3728 19.2866 25.38C19.7729 24.8867 20.3511 24.4955 20.9881 24.2289C21.6251 23.9622 22.3081 23.8255 22.9978 23.8265C23.6875 23.8255 24.3705 23.9622 25.0075 24.2289C25.6445 24.4955 26.2228 24.8867 26.709 25.38C27.1981 25.8703 27.5861 26.4535 27.8505 27.0958C28.1149 27.7381 28.2505 28.4269 28.2495 29.1224C28.2495 29.2388 28.2725 29.3541 28.3172 29.4615C28.3618 29.5689 28.4272 29.6662 28.5095 29.7478C28.6759 29.91 28.8984 30.0004 29.1297 30C29.3609 29.9994 29.583 29.9091 29.7499 29.7478C29.9098 29.5794 29.9994 29.3555 30 29.1224C29.9984 27.766 29.6095 26.4387 28.8797 25.2993C28.1484 24.1588 27.1064 23.2551 25.8787 22.6967C26.3448 22.286 26.7197 21.7807 26.979 21.2139C27.2333 20.6463 27.3662 20.031 27.3692 19.4082C27.3691 18.2391 26.9086 17.1179 26.0888 16.2912C25.2653 15.4639 24.1502 14.9996 22.9878 15C21.8284 15 20.7166 15.4645 19.8968 16.2912C19.077 17.1179 18.6165 18.2391 18.6164 19.4082C18.6144 20.0316 18.7476 20.6479 19.0066 21.2139C19.2622 21.7802 19.6337 22.2856 20.0969 22.6967C18.8766 23.2584 17.8419 24.1617 17.116 25.2993ZM21.1172 21.2643C20.8711 21.0192 20.6762 20.727 20.5439 20.4048C20.4117 20.0825 20.3447 19.7368 20.347 19.3881C20.3462 18.8641 20.4994 18.3516 20.7871 17.9153C21.0719 17.4677 21.483 17.1164 21.9675 16.9065C22.445 16.7064 22.9707 16.6537 23.4779 16.7553C23.9897 16.8565 24.4599 17.1093 24.8284 17.4815C25.1987 17.8481 25.4499 18.3195 25.5486 18.8333C25.6495 19.3481 25.5973 19.8815 25.3986 20.3665C25.1894 20.8545 24.8412 21.2687 24.3982 21.5568C23.9641 21.8437 23.4569 21.9979 22.9378 22.0007C22.2699 21.9933 21.6297 21.7301 21.1472 21.2643H21.1172Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6161 8.4265C21.9537 7.58375 21.1527 6.87199 20.249 6.32849C18.9534 5.54924 17.4909 5.14245 16.0044 5.14791C15.738 5.14791 15.4825 5.03481 15.2942 4.83341C15.1058 4.63201 15 4.3588 15 4.07398C15 3.78916 15.1058 3.51601 15.2942 3.31461C15.4825 3.11321 15.738 3.00005 16.0044 3.00005C17.8359 2.99429 19.6376 3.49578 21.2341 4.4556C22.5812 5.26549 23.7434 6.37845 24.6425 7.71344L26.4703 7.07031C26.538 7.04867 26.6099 7.04603 26.6789 7.06271C26.7479 7.07939 26.8118 7.11483 26.8643 7.16556C26.9167 7.21628 26.9561 7.28057 26.9786 7.3523C27.0011 7.42403 27.0059 7.50074 26.9926 7.57503L25.7973 13.6535C25.7836 13.728 25.7516 13.7973 25.7047 13.8543C25.6578 13.9113 25.5976 13.954 25.5302 13.978C25.4628 14.002 25.3907 14.0064 25.3212 13.991C25.2517 13.9755 25.1872 13.9405 25.1344 13.8897L20.7954 9.74439C20.7425 9.69357 20.7031 9.62861 20.6814 9.5562C20.6596 9.4838 20.6562 9.40656 20.6715 9.33229C20.6869 9.25803 20.7204 9.18939 20.7686 9.13349C20.8168 9.07759 20.8781 9.03643 20.9461 9.01413L22.6161 8.4265Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.87043 18.1102L9.20182 22.2556C9.25492 22.3057 9.29469 22.3699 9.31712 22.4416C9.33956 22.5134 9.34385 22.5902 9.32958 22.6644C9.3153 22.7385 9.28296 22.8072 9.23582 22.8637C9.18868 22.9201 9.12841 22.9623 9.06113 22.9859L7.38564 23.5708C8.04731 24.4148 8.8482 25.1275 9.75222 25.6717C11.047 26.4511 12.509 26.8578 13.995 26.8521C14.2616 26.8521 14.5172 26.9652 14.7057 27.1666C14.8941 27.368 15 27.6412 15 27.926C15 28.2108 14.8941 28.484 14.7057 28.6854C14.5172 28.8868 14.2616 28.9999 13.995 28.9999C12.1638 29.006 10.3624 28.5045 8.76662 27.5446C7.41767 26.7331 6.25461 25.6175 5.35618 24.2793L3.52378 24.919C3.45713 24.9444 3.38527 24.9501 3.31584 24.9354C3.24641 24.9207 3.18199 24.8862 3.12944 24.8356C3.07688 24.7849 3.03813 24.72 3.01733 24.6477C2.99652 24.5754 2.99441 24.4984 3.01124 24.425L4.20715 18.3465C4.22089 18.272 4.25286 18.2028 4.29981 18.1458C4.34677 18.0888 4.40702 18.0461 4.47443 18.0221C4.54184 17.9981 4.614 17.9935 4.68356 18.009C4.75312 18.0245 4.81761 18.0594 4.87043 18.1102Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default StudentAssociationIcon;
