import { ActionType, IRecordVaccineListResponse } from "models/vaccine/records";

export const recordListAction = (payload: any) => {
  return {
    type: ActionType.VACCINE_RECORD_LIST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.VACCINE_RECORD_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IRecordVaccineListResponse) => {
  return {
    type: ActionType.VACCINE_RECORD_SUCCESS,
    payload,
  };
};
