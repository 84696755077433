import { createGlobalStyle } from "styled-components";
import "antd/dist/antd.css";
import { theme } from "./theme";

export const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        font-family: "Roboto", "Noto Sans JP", sans-serif;
        background: #f2f2f2;
    }
    * {
        box-sizing: border-box;
    }
    p {
        margin-bottom: 0;
    }

    .ant-select-dropdown {
        border-radius: 6px!important;
        border: 1px solid ${theme.colors.border};
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
        width: unset!important;
        z-index: 9;
    }
    .rc-virtual-list-holder {
        overflow-y: auto !important;
        overscroll-behavior: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .rc-virtual-list-holder::-webkit-scrollbar {
        display: none;
    }
    .ant-select-item {
        padding: 12px 16px 14px;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        border-bottom: 1px solid ${theme.colors.background};
    }
    .ant-select-item:last-child {
        border-bottom: unset;
    }
    .ant-picker-dropdown {
        width: 300px;
        position: unset;
        z-index: unset;
    }

    .ant-dropdown-menu {
        border-radius: 6px;
    }
    .ant-drawer-content-wrapper {
        width: 320px!important;
        ${theme.media.mobileM} {
            width: 375px!important;
        }
    }
    .ant-layout-header {
        ${theme.media.mobileM} {
            line-height: 60px;
        }
    }
    .ant-drawer-right {
        top: 60px;
    }
    .ant-drawer-close {
        display: none;
    }
    .ant-drawer-header {
        border-bottom: 4px solid ${theme.colors.textFieldBackground};
        padding: 12px 8px 12px 30px;
    }
    .ant-drawer-body {
        padding: 25px 0px 0px;
        ${theme.media.desktop} {
            padding: 25px 0px 77px;
        }
    }

    .ant-menu {
        background: ${theme.colors.white};
        font-size: ${theme.sizes.xs}
    }
    .ant-menu-item {
        font-weight: ${theme.fontWeight.medium};
        font-size: ${theme.sizes.xs};
        line-height: 17px;
        color: ${theme.colors.text.primary};
        margin: 0px;
        margin-bottom: 0px!important;
        height: auto !important;
        padding-left: 30px!important;
    }
    .ant-menu-item-selected {
        svg {
            fill: ${theme.colors.textLink}!important;
        }
        a {
            color: ${theme.colors.textLink}!important;
            font-weight: ${theme.fontWeight.bold};
        }
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: unset;
    }
    .ant-menu-inline .ant-menu-item::after {
        border-right: unset;
    }
    .ant-menu-light .ant-menu-item svg {
        fill: ${theme.colors.text.primary};
    }
    #question-svg, .ant-menu-item-selected #question-svg {
        fill:none !important;
    }
    .ant-menu-submenu-selected #question-svg {
            .fill{
                fill: #2F8CAE!important;
            }
            .stroke{
                stroke: #2F8CAE!important;
            }
    }
     .ant-menu-item-selected #qrcode-svg {
            .stroke{
                stroke: #2F8CAE!important;
            }
    } 
     .ant-menu-item-selected #import-sso-svg {
        fill:none !important;
        .stroke{
            stroke: #2F8CAE!important;
        }
        .fill{
            fill: #2F8CAE!important;
        }
    } 
    .ant-menu-light .ant-menu-item:hover {
        background: #ecf5fb;
        a {
            color: ${theme.colors.text.primary}
        }
    }
    .ant-menu-light .ant-menu-submenu-title:hover {
        color: ${theme.colors.text.primary};
        background: #ecf5fb;
        svg {
            fill: ${theme.colors.text.primary};
        }
    }
    .ant-menu-title-content {
        transition: unset;
        font-weight: ${theme.fontWeight.medium};
        margin: 4px 0px;
        white-space: break-spaces;
        line-height: 1.3;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .ant-menu-item .ant-menu-item-icon + span{
        transition: unset;
    }
    .ant-menu-submenu svg {
        fill: ${theme.colors.text.primary};
    }
    .ant-menu-sub li span {
        padding-left: 72px !important;
        ${theme.media.laptopL} {
            padding-left: 16px !important;
        }
    }
    .ant-menu-sub {
        padding-top: 0!important;
        border-right: unset;
    }
    .ant-menu-item-only-child {
        padding-left: 0px!important;
    }
    .ant-menu-submenu-title {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }
    .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: ${theme.colors.text.primary};
    }
    .ant-menu-inline {
        border-right: unset!important;
        background: unset!important;
    }
    .ant-menu-submenu-title {
        padding-left: 30px!important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .ant-menu-inline-collapsed .ant-menu-title-content {
        white-space: nowrap;
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        right: 5px;
    }

    .ant-tooltip {
        max-width: unset;
        z-index: 1000;
    }
    .ant-tooltip-inner {
        font-size: 14px;
        line-height: 14px;
        min-height: 27px;
        max-width: 300px;
        background-color: #FFFFFF;
        color: #3B3B3B;
        padding:6px 8px 1px;
        box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        border: 1px solid #2F8CAE;
        > a {
            color: #3B3B3B !important;
        }
    }
    .ant-tooltip-arrow-content {
        background-color: #2F8CAE;
        width: 6px;
        height: 6px;
        --antd-arrow-background-color: unset;
    }
    .ant-upload-list {
        display: none;
    }
    .ant-select-tree-title {
        font-weight: ${theme.fontWeight.bold};
        font-size: ${theme.sizes.xs};
        line-height: 17px;
    }
    .ant-select-tree-node-selected {
        background-color: #e6f7ff!important;
    }
    .check-all {
        .ant-select-selection-item {
            color: #2AC769!important;
        }
    }
    .ant-notification {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 32px;
        font-size: ${theme.sizes.sm};
        line-height: ${theme.sizes.xl};
        font-weight: ${theme.fontWeight.regular};
        .ant-notification-notice {
            min-width: 291px;
            width: max-content;
            background: ${theme.colors.white};
            padding: 13px 16px 16px;
            margin-bottom: 0px;
            margin: 0px auto;
            box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.18);
            .ant-notification-notice-content {
                width: max-content;
            }
            .ant-notification-notice-with-icon {
                height: 53px;
            }
            > div {
                width: max-content;
            }
        }
        .ant-notification-notice-success,
        .ant-notification-notice-error {
            .ant-notification-notice-message {
                padding-top: 14px;
            }
        }
        .ant-notification-notice-icon {
            margin-left: 0px;
        }
        .ant-notification-notice-message {
            margin-left: 69px;
            margin-bottom: 0px;
            margin-top: 9px;
        }
        .ant-notification-notice-description {
            margin-left: 69px;
        }
        ${theme.media.tablet} {
            left: unset;
            bottom: 0;
            margin-right: 32px;
        }
    }
    .ant-tooltip-placement-right, .ant-tooltip-placement-left {
        .ant-tooltip-inner {
            border: 1px solid ${theme.colors.textLink};
            padding: 8px 8px 6px;
            line-height: 16px;
            font-size: 14px;
            max-width: 430px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 13px;
                height: 5px;
            }
            &::-webkit-scrollbar-track {
                margin: 8px 0px 8px;
                border-left: 4px solid ${theme.colors.white};
                border-right: 4px solid ${theme.colors.white};
                background: ${theme.colors.background};
                border-radius: 7px;
            }
            &::-webkit-scrollbar-thumb {
                background: #bdbdbd;
                border-radius: 7px;
                border-left: 4px solid ${theme.colors.white};
                border-right: 4px solid ${theme.colors.white};
            }
        }
        .ant-tooltip-arrow-content {
            background-color: ${theme.colors.textLink};
        }
        .ant-tooltip-content::after {
            content: '';
            position: absolute;
            top: calc(50% - 2.5px);
            left: 7px;
            display: block;
            width: 5px;
            height: 5px;
            margin: auto;
            background: white;
            transform: rotate(45deg);
        }
    }
    .ant-tooltip-placement-left {
         .ant-tooltip-content::after {
            right: 7px;
            left: auto;
        }
    }
    .ant-table-column-title {
        z-index: 0;
    }
    .fix-dropdown .ant-select-dropdown {
        z-index: 1000;
    }
    .react-datepicker__day--selected .date-active {
        color: #ffffff!important;
    }

    input[type="number"]::-webkit-outer-spin-button, 
    input[type="number"]::-webkit-inner-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
        } 
`;
