import * as React from "react";

function RadioIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="9"
        fill="white"
        stroke={props.fill}
        strokeWidth="2"
      />
      <circle r="6" transform="matrix(1 0 0 -1 12 12)" fill="#B7B7B7" />
    </svg>
  );
}

export default RadioIcon;
