import { IResponse } from "models";

export enum ActionType {
  CSV_DOWNLOAD_QUESTION = "action/CSV_DOWNLOAD_QUESTION",
  CSV_DOWNLOAD_QUESTION_SUCCESS = "action/CSV_DOWNLOAD_QUESTION_SUCCESS",
  CSV_DOWNLOAD_QUESTION_ERROR = "action/CSV_DOWNLOAD_QUESTION_ERROR",
  CSV_DOWNLOAD_QUESTION_RESET_DATA = "action/CSV_DOWNLOAD_QUESTION_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface CSVDownloadQuestionResponse extends IResponse {
  result?: ICSVDownloadResult;
}

export interface ICSVDownloadResult {
  data: ICSVDownloadQuestionItem[];
  header: string[];
}
export interface IQuestionDownloadData {
  id?: number;
}

export interface ICSVDownloadQuestionItem {
  class_name: string;
  roll_number: string;
  full_name: string;
}
