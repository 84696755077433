import * as React from "react";

function SendCompleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="2"
        y="2"
        width="49"
        height="49"
        rx="24.5"
        fill="white"
        stroke="#2AC769"
        strokeWidth="4"
      />
      <path
        d="M42.2538 15.9847C42.0415 15.7714 41.7889 15.6021 41.5106 15.4865C41.2323 15.371 40.9338 15.3115 40.6324 15.3115C40.3309 15.3115 40.0324 15.371 39.7541 15.4865C39.4758 15.6021 39.2233 15.7714 39.011 15.9847L21.9976 32.9613L14.8497 25.8156C14.6293 25.6034 14.3691 25.4366 14.0839 25.3246C13.7988 25.2127 13.4943 25.1577 13.1879 25.163C12.8815 25.1683 12.5791 25.2337 12.298 25.3554C12.017 25.4771 11.7627 25.6529 11.5498 25.8725C11.3368 26.0922 11.1694 26.3515 11.0571 26.6356C10.9447 26.9197 10.8896 27.2231 10.8949 27.5285C10.9002 27.8338 10.9658 28.1351 11.0879 28.4152C11.2101 28.6953 11.3864 28.9487 11.6069 29.1609L20.3762 37.8995C20.5885 38.1128 20.8411 38.2821 21.1193 38.3976C21.3976 38.5132 21.6961 38.5726 21.9976 38.5726C22.2991 38.5726 22.5975 38.5132 22.8758 38.3976C23.1541 38.2821 23.4067 38.1128 23.619 37.8995L42.2538 19.3299C42.4856 19.1168 42.6706 18.8582 42.7971 18.5703C42.9237 18.2824 42.989 17.9716 42.989 17.6573C42.989 17.343 42.9237 17.0321 42.7971 16.7443C42.6706 16.4564 42.4856 16.1978 42.2538 15.9847Z"
        fill="#2AC769"
      />
    </svg>
  );
}

export default SendCompleteIcon;
