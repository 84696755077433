import {
  ActionType,
  HealthCheckAnswersResponse,
  HealthCheckAnswersParams,
} from "models/bodyTemperature/healthCheckAnswers";

export const getHealthCheckAnswersAction = (
  payload: HealthCheckAnswersParams
) => {
  return {
    type: ActionType.HEALTH_CHECK_ANSWER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.HEALTH_CHECK_ANSWER_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: HealthCheckAnswersResponse) => {
  return {
    type: ActionType.HEALTH_CHECK_ANSWER_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.HEALTH_CHECK_ANSWER_RESET,
  };
};
