import { Action, ActionType } from "models/classmanagement/deleteClass";
import { IResponse } from "models";
import createReducer from "../../createReducer";

export interface DeleteClassReducerType {
  loading: boolean;
  error?: string;
  deleteClassResponse?: IResponse;
}

const defaultState: DeleteClassReducerType = {
  loading: false,
  error: undefined,
  deleteClassResponse: undefined,
};

export const deleteClassReducer = createReducer<DeleteClassReducerType>(
  defaultState,
  {
    [ActionType.DELETE_CLASS](state: DeleteClassReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_CLASS_ERROR](
      state: DeleteClassReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DELETE_CLASS_SUCCESS](
      state: DeleteClassReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteClassResponse: action.payload,
      };
    },
  }
);
