import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";

import { Input, Button } from "components";
import { DownloadIcon, CircleDelete } from "icons";

import { updateVenuesResponse } from "redux/vaccine/venues/updateVenues/updateVenuesStates";
import { IVenue } from "models/vaccine/venuesList";
import {
  UpdateVenuesActionCreators,
  DeleteVenuesActionCreators,
} from "redux/rootActions";

import {
  WrapperItem,
  WrapperItemBottom,
  HorizontalInputWrapper,
  ItemLeft,
  QrWrapper,
  InfoWrapper,
  ButtonWrapper,
} from "./Vaccination.style";
import { httpStatus } from "configs/httpStatus";

const schema = yup.object().shape({
  venueCode: yup
    .string()
    .required("venue-code-required")
    .min(8, "venue-code-short"),
  venueName: yup.string().required("venue-name-required"),
});

const VenueItem = ({ venue }: { venue: IVenue }) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "account",
  });
  const { t: t2 }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });
  const { t: t3 }: any = useTranslation("translation", {
    keyPrefix: "vaccination",
  });

  const dispatch = useDispatch();
  const updateVenues = useSelector(updateVenuesResponse);
  const { handleSubmit, control, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const [type, setType] = useState("");

  const onSubmit = async (data: { [x: string]: any }) => {
    const params = {
      id: venue.id,
      value: {
        venue: {
          name: data.venueName,
          venue_code: data.venueCode,
        },
      },
    };
    dispatch(UpdateVenuesActionCreators.updateVenuesAction(params));
  };

  const handleDeleteItem = () => {
    dispatch(DeleteVenuesActionCreators.deleteVenuesAction({ id: venue.id }));
  };

  const downloadQRCode = () => {
    const venueId = getValues("venueCode");
    const canvas = document.getElementById(venueId) as HTMLCanvasElement;
    const image = canvas.toDataURL("image/png");
    const anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `${venueId}.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    setValue("venueCode", venue.venue_code);
    setValue("venueName", venue.name);
  });

  useEffect(() => {
    if (
      updateVenues &&
      Object.keys(updateVenues).length &&
      updateVenues.status === httpStatus.StatusOK
    ) {
      setType("");
      dispatch(UpdateVenuesActionCreators.handleResetAction());
    }
  }, [updateVenues]);

  if (type === "edit") {
    return (
      <WrapperItemBottom>
        <form
          key={venue.id}
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%" }}
        >
          <HorizontalInputWrapper>
            <div>
              <div
                style={{ fontSize: 16, lineHeight: "16px", marginBottom: 5 }}
              >
                {t3("venue-code")}：
              </div>
              <Controller
                control={control}
                name="venueCode"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    fsLabel={16}
                    lhLabel={"16px"}
                    bdr={8}
                    height={32}
                    onChange={onChange}
                    value={value}
                    error={t3(error?.message)}
                  />
                )}
              />
            </div>
            <div>
              <div
                style={{ fontSize: 16, lineHeight: "16px", marginBottom: 5 }}
              >
                {t3("venue-name")}：
              </div>
              <Controller
                control={control}
                name="venueName"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Input
                    fsLabel={16}
                    lhLabel={"16px"}
                    bdr={8}
                    height={32}
                    onChange={onChange}
                    value={value}
                    error={t3(error?.message)}
                  />
                )}
              />
            </div>
          </HorizontalInputWrapper>
          <ButtonWrapper>
            <Button
              background="#2AC769"
              color="#FFFFFF"
              padding="9px 40px 7px"
              fontSize={16}
              lineHeight="16px"
              name={t1("update")}
              border="none"
              bdr="6px"
              width="112px"
            />
            <Button
              type="button"
              background="#E0E0E0"
              color="#FFFFFF"
              padding="9px 16px 7px"
              fontSize={16}
              lineHeight="16px"
              name={t("cancel")}
              border="none"
              bdr="6px"
              width="112px"
              onClick={() => setType("")}
            />
          </ButtonWrapper>
        </form>
      </WrapperItemBottom>
    );
  }
  return (
    <WrapperItem style={{ padding: "8px 16px 8px 8px" }} key={venue.id}>
      <ItemLeft>
        <QrWrapper>
          <div
            style={{
              width: "max-content",
              maxHeight: "88px",
              padding: "4px",
              background: "#F2F2F2",
              borderRadius: "4px",
            }}
          >
            <QRCode
              id={venue.venue_code}
              value={venue.venue_code}
              size={80}
              bgColor="#FFFFFF"
              includeMargin={false}
            />
          </div>
          <Button
            background={"#2AC769"}
            color={"#FFFFFF"}
            padding={"9px 28px 7px"}
            fontSize={16}
            lineHeight={"16px"}
            name={t2("edit")}
            border={"none"}
            bdr={"6px"}
            width={"88px"}
            onClick={() => setType("edit")}
          />
        </QrWrapper>
        <InfoWrapper>
          <p>
            {t3("venue-code")}：<span>{venue.venue_code}</span>
          </p>
          <p>
            {t3("venue-name")}：<span>{venue.name}</span>
          </p>
          <div style={{ position: "relative" }}>
            <Button
              icon={
                <DownloadIcon
                  width="14px"
                  height="14px"
                  fill="currentColor"
                  style={{ position: "absolute", top: 5, left: 5 }}
                />
              }
              name={t3("download-qr-code")}
              background="#2AC769"
              color="#FFFFFF"
              border="none"
              fontSize={12}
              lineHeight="16px"
              fontWeight={700}
              padding="4px 7px 3px 16px"
              width="100%"
              bdr="4px"
              onClick={downloadQRCode}
            />
          </div>
        </InfoWrapper>
      </ItemLeft>
      <CircleDelete onClick={handleDeleteItem} />
    </WrapperItem>
  );
};

export default VenueItem;
