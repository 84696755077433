import {
  ActionType,
  TemperatureAvgPayload,
  TemperatureAvgResponse,
} from "models/dashboard/temperatureAvg";

export const getTemperatureAvgAction = (payload: TemperatureAvgPayload) => {
  return {
    type: ActionType.GET_TEMPERATURE_AVG_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_TEMPERATURE_AVG_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: TemperatureAvgResponse) => {
  return {
    type: ActionType.GET_TEMPERATURE_AVG_CHART_SUCCESS,
    payload,
  };
};
