import {
  ActionType,
  BodyTemperatureFileResponse,
} from "models/bodyTemperature/file";

export const getBodyTemperatureFileData = (payload: any) => {
  return {
    type: ActionType.BODY_TEMPERATURE_FILE_DATA,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.BODY_TEMPERATURE_FILE_DATA_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: BodyTemperatureFileResponse) => {
  return {
    type: ActionType.BODY_TEMPERATURE_FILE_DATA_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.BODY_TEMPERATURE_FILE_DATA_RESET,
  };
};
