import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ConfirmModal from "./ConfirmModal";
import { LinkStyled } from "theme/CommonStyle";
import { useDispatch } from "react-redux";
import { ForgotPasswordActionCreators } from "redux/rootActions";
import { ForgotPasswordData } from "models/forgotPassword";
import { localStorageHelper } from "utils";

const Message = styled.p(({ theme }) => ({
  fontSize: theme.sizes.sm,
  fontWeight: theme.fontWeight.medium,
  color: theme.colors.text.primary,
  lineHeight: "20px",
  marginBottom: 6,
}));

const NoteText = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 18px;
  margin-bottom: 24px;
  font-weight: normal;
`;

const WrapperLink = styled.div(({ theme }) => ({
  textAlign: "center",
  cursor: "pointer",
  div: {
    ...LinkStyled(theme),
  },
}));

const ForgotPasswordNotification: React.FC = () => {
  const { t }: any = useTranslation("translation", { keyPrefix: "auth" });
  const { push } = useHistory();
  const dispatch = useDispatch();

  // const handleResendEmail = () => {
  //   const params: ForgotPasswordData = {
  //     user: {
  //       email: localStorageHelper.getItem("email"),
  //       role: parseInt(localStorageHelper.getItem("role")),
  //     },
  //   };
  //   dispatch(ForgotPasswordActionCreators.forgotPasswordUserAction(params));
  // };

  return (
    <>
      <Message>{t("forgot-password-notification")}</Message>
      <NoteText>{t("check-email-note")}</NoteText>
      <WrapperLink onClick={() => push("/forgot-password")}>
        <div>{t("not-receive-email")}</div>
      </WrapperLink>
    </>
  );
};

export default ForgotPasswordNotification;
