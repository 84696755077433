import {
  Action,
  ActionType,
  TemperatureAvgResponse,
} from "models/dashboard/temperatureAvg";
import createReducer from "../../createReducer";

export interface TemperatureAvgReducerType {
  loading: boolean;
  error?: string;
  temperatureAvgResponse?: TemperatureAvgResponse;
}

const defaultState: TemperatureAvgReducerType = {
  loading: false,
  error: undefined,
  temperatureAvgResponse: undefined,
};

export const getTemperatureAvgReducer =
  createReducer<TemperatureAvgReducerType>(defaultState, {
    [ActionType.GET_TEMPERATURE_AVG_CHART](state: TemperatureAvgReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_TEMPERATURE_AVG_CHART_ERROR](
      state: TemperatureAvgReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_TEMPERATURE_AVG_CHART_SUCCESS](
      state: TemperatureAvgReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        temperatureAvgResponse: action.payload,
      };
    },
  });
