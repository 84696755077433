import { IResponse } from "models";

export enum ActionType {
  // download csv file user list
  CSV_DOWNLOAD_USER = "action/CSV_DOWNLOAD_USER",
  CSV_DOWNLOAD_USER_SUCCESS = "action/CSV_DOWNLOAD_USER_SUCCESS",
  CSV_DOWNLOAD_USER_ERROR = "action/CSV_DOWNLOAD_USER_ERROR",
  CSV_DOWNLOAD_RESET_DATA = "action/CSV_DOWNLOAD_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface CSVDownloadUserResponse extends IResponse {
  result?: ICSVDownloadResult;
}

export interface ICSVDownloadResult {
  data: ICSVDownloadUserItem[];
  headers: IHeaderCSVDownloadUserItem[];
}
export interface IUserListDownloadData {
  c_department_id: number;
  user_name: string;
  locale?: string;
}

export interface IUserListDownloadData {
  c_department_id: number;
  user_name: string;
  locale?: string;
}

export interface IUserListDownloadData {
  c_department_id: number;
  user_name: string;
  locale?: string;
}

export interface IHeaderCSVDownloadUserItem {
  label: string;
  key: string;
}

export interface ICSVDownloadUserItem {
  id: number;
  class_name: string;
  gender: string;
  name: string;
}
