import { IResponse } from "models";

export enum ActionType {
  MENTAL_CHECK_TYPE_INFO = "action/MENTAL_CHECK_TYPE_INFO",
  MENTAL_CHECK_TYPE_INFO_SUCCESS = "action/MENTAL_CHECK_TYPE_INFO_SUCCESS",
  MENTAL_CHECK_TYPE_INFO_ERROR = "action/MENTAL_CHECK_TYPE_INFO_ERROR",
  MENTAL_CHECK_TYPE_INFO_RESET_DATA = "action/MENTAL_CHECK_TYPE_INFO_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface TypeInfoData {
  page: number;
  per_page: number;
  mental_check_type_id: number;
  mental_check_request_id: any;
  refine_criteria_type: any;
  grade: any;
}

export interface IMentalCheckTypeInfoResponse extends IResponse {
  total_record: number;
  current_page: number;
  next_page: number;
  result?: {
    mental_check_details: IMentalTypeInfo[];
    user_detail: IUserDetail[];
    mental_check_users: ICheckUser[];
  };
}

export interface IMentalTypeInfo {
  patient_id: number;
  full_name: string;
  grade: string;
  date_of_implementation: string;
  mental_condition: string;
  feelings: number;
  concentrations: number;
  behaviour: number;
  relationship: number;
  gender: string;
  consult_status: string;
  send_interview_request: boolean;
  mental_check_request_patient_id: string;
  suicide_alarm: any;
}

export interface IUserDetail {
  id: number;
  name: string;
  is_attended?: boolean;
}

export interface ICheckUser {
  patient_id: number;
  full_name: string;
  is_attended?: boolean;
}
