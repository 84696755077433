import React, { ReactElement, useEffect } from "react";
import { ModalProps } from "antd";

import { CloseIcon } from "icons";
import { ModalForm, ModalCustomHeader, ConfirmModal } from "./Modal.style";

type ModalOptions = "form" | "confirm" | "search";

interface IModal extends ModalProps {
  type: ModalOptions;
}

const ModalFormCustom = ({ ...props }) => {
  useEffect(() => {
    document.body.classList.add("fix-dropdown");
    return () => {
      document.body.classList.remove("fix-dropdown");
    };
  }, []);

  return (
    <ModalForm
      {...props}
      closeIcon={<CloseIcon fill="#3B3B3B" />}
      footer={null}
    />
  );
};

const CustomModal: React.FC<IModal> = ({
  footer,
  type,
  ...props
}): ReactElement => {
  if (type === "confirm") {
    return <ConfirmModal {...props} closeIcon={<></>} footer={footer} />;
  }

  if (type === "search") {
    return (
      <ModalCustomHeader
        {...props}
        closeIcon={<CloseIcon fill="#3B3B3B" />}
        footer={null}
      />
    );
  }

  return <ModalFormCustom {...props} />;
};

export default CustomModal;
