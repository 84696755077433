import * as React from "react";

function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="166"
      height="28"
      viewBox="0 0 166 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg "
      {...props}
    >
      <g clipPath="url(#clip0_359:10509)">
        <path
          d="M41.1129 0C37.2132 0 33.7055 1.5838 31.158 4.1257L21.3599 14L31.158 23.8743C33.7055 26.4162 37.2132 28 41.0933 28C48.8338 28 55.1242 21.7235 55.1242 14C55.1438 6.27654 48.8534 0 41.1129 0Z"
          fill="url(#paint0_linear_359:10509)"
        />
        <path
          d="M23.8095 23.8743L14.0113 14L23.8095 4.1257C23.8291 4.10615 23.8683 4.06704 23.8879 4.04749C21.3599 1.54469 17.8718 0 14.0309 0C6.2904 0 0 6.27654 0 14C0 21.7235 6.2904 28 14.0309 28C17.8718 28 21.3599 26.4553 23.8879 23.9525C23.8683 23.933 23.8291 23.8939 23.8095 23.8743Z"
          fill="url(#paint1_linear_359:10509)"
        />
        <path
          d="M81.3442 23.1527H70.8994C70.723 23.1527 70.5859 23.0159 70.5859 22.8399V0.686245C70.5859 0.568926 70.4879 0.471161 70.3703 0.471161H65.7456C65.628 0.471161 65.53 0.568926 65.53 0.686245V27.3175C65.53 27.4348 65.628 27.5326 65.7456 27.5326H81.3442C81.4618 27.5326 81.5598 27.4348 81.5598 27.3175V23.3678C81.5598 23.2505 81.4618 23.1527 81.3442 23.1527Z"
          fill="#1A2F62"
        />
        <path
          d="M101.43 0.469177H87.2034C87.0858 0.469177 86.9878 0.566943 86.9878 0.684261V27.3156C86.9878 27.4329 87.0858 27.5306 87.2034 27.5306H101.43C101.548 27.5306 101.646 27.4329 101.646 27.3156V23.3658C101.646 23.2485 101.548 23.1507 101.43 23.1507H92.3572C92.1808 23.1507 92.0436 23.0139 92.0436 22.8379V16.444C92.0436 16.2681 92.1808 16.1312 92.3572 16.1312H100.901C101.019 16.1312 101.117 16.0334 101.117 15.9161V12.0642C101.117 11.9468 101.019 11.8491 100.901 11.8491H92.3572C92.1808 11.8491 92.0436 11.7122 92.0436 11.5362V5.18147C92.0436 5.00549 92.1808 4.86862 92.3572 4.86862H101.43C101.548 4.86862 101.646 4.77085 101.646 4.65354V0.684261C101.646 0.566943 101.548 0.469177 101.43 0.469177Z"
          fill="#1A2F62"
        />
        <path
          d="M120.439 13.6284C120.341 13.5697 120.263 13.472 120.263 13.3547C120.263 13.2373 120.321 13.1396 120.419 13.0809C122.438 11.9077 123.496 10.0893 123.496 7.86025C123.496 3.46081 119.42 0.488739 113.365 0.488739H107.231C107.114 0.488739 107.016 0.586504 107.016 0.703823V27.3351C107.016 27.4524 107.114 27.5502 107.231 27.5502H113.365C119.91 27.5502 124.3 24.3435 124.3 19.5725C124.3 17.0697 122.928 14.9776 120.439 13.6284ZM118.577 8.40773C118.577 10.4608 116.637 11.8491 113.757 11.8491H112.346C112.169 11.8491 112.032 11.7122 112.032 11.5362V5.18148C112.032 5.0055 112.169 4.86863 112.346 4.86863H113.796C116.657 4.84907 118.577 6.27645 118.577 8.40773ZM112.013 16.444C112.013 16.2681 112.15 16.1312 112.326 16.1312H113.776C117.245 16.1312 119.4 17.4217 119.4 19.4748C119.4 21.7234 117.108 23.1899 113.541 23.1899H112.326C112.15 23.1899 112.013 23.053 112.013 22.877V16.444Z"
          fill="#1A2F62"
        />
        <path
          d="M142.563 0.469177H128.336C128.218 0.469177 128.12 0.566943 128.12 0.684261V27.3156C128.12 27.4329 128.218 27.5306 128.336 27.5306H142.563C142.68 27.5306 142.778 27.4329 142.778 27.3156V23.3658C142.778 23.2485 142.68 23.1507 142.563 23.1507H133.49C133.313 23.1507 133.176 23.0139 133.176 22.8379V16.444C133.176 16.2681 133.313 16.1312 133.49 16.1312H142.034C142.151 16.1312 142.249 16.0334 142.249 15.9161V12.0642C142.249 11.9468 142.151 11.8491 142.034 11.8491H133.49C133.313 11.8491 133.176 11.7122 133.176 11.5362V5.18147C133.176 5.00549 133.313 4.86862 133.49 4.86862H142.563C142.68 4.86862 142.778 4.77085 142.778 4.65354V0.684261C142.778 0.566943 142.68 0.469177 142.563 0.469177Z"
          fill="#1A2F62"
        />
        <path
          d="M165.961 27.1787L158.24 17.2066C158.181 17.1284 158.162 17.0111 158.181 16.9133C158.22 16.8155 158.299 16.7373 158.397 16.6982C162.14 15.525 164.276 12.7094 164.276 8.97476C164.276 3.81275 160.258 0.469177 154.027 0.469177H148.344C148.226 0.469177 148.128 0.566943 148.128 0.684261V27.3156C148.128 27.4329 148.226 27.5306 148.344 27.5306H152.93C153.047 27.5306 153.145 27.4329 153.145 27.3156V18.6731C153.145 18.5362 153.243 18.4189 153.361 18.3602C153.4 18.3407 153.419 18.3407 153.459 18.3407C153.557 18.3407 153.655 18.3798 153.713 18.4776L160.18 27.4329C160.219 27.4915 160.278 27.5306 160.356 27.5306H165.785C165.863 27.5306 165.941 27.4915 165.981 27.4133C166.02 27.3351 166.02 27.2569 165.961 27.1787ZM159.357 8.97476C159.357 11.4385 157.201 13.1396 154.125 13.1396H153.478C153.302 13.1396 153.165 13.0027 153.165 12.8267V5.12281C153.165 4.94683 153.302 4.80996 153.478 4.80996H154.125C157.26 4.80996 159.357 6.47197 159.357 8.97476Z"
          fill="#1A2F62"
        />
        <path
          d="M81.3442 23.1527H70.8994C70.723 23.1527 70.5859 23.0159 70.5859 22.8399V0.686245C70.5859 0.568926 70.4879 0.471161 70.3703 0.471161H65.7456C65.628 0.471161 65.53 0.568926 65.53 0.686245V27.3175C65.53 27.4348 65.628 27.5326 65.7456 27.5326H81.3442C81.4618 27.5326 81.5598 27.4348 81.5598 27.3175V23.3678C81.5598 23.2505 81.4618 23.1527 81.3442 23.1527Z"
          fill="#1A2F62"
        />
        <path
          d="M101.43 0.469177H87.2034C87.0858 0.469177 86.9878 0.566943 86.9878 0.684261V27.3156C86.9878 27.4329 87.0858 27.5306 87.2034 27.5306H101.43C101.548 27.5306 101.646 27.4329 101.646 27.3156V23.3658C101.646 23.2485 101.548 23.1507 101.43 23.1507H92.3572C92.1808 23.1507 92.0436 23.0139 92.0436 22.8379V16.444C92.0436 16.2681 92.1808 16.1312 92.3572 16.1312H100.901C101.019 16.1312 101.117 16.0334 101.117 15.9161V12.0642C101.117 11.9468 101.019 11.8491 100.901 11.8491H92.3572C92.1808 11.8491 92.0436 11.7122 92.0436 11.5362V5.18147C92.0436 5.00549 92.1808 4.86862 92.3572 4.86862H101.43C101.548 4.86862 101.646 4.77085 101.646 4.65354V0.684261C101.646 0.566943 101.548 0.469177 101.43 0.469177Z"
          fill="#1A2F62"
        />
        <path
          d="M120.439 13.6284C120.341 13.5697 120.263 13.472 120.263 13.3547C120.263 13.2373 120.321 13.1396 120.419 13.0809C122.438 11.9077 123.496 10.0893 123.496 7.86025C123.496 3.46081 119.42 0.488739 113.365 0.488739H107.231C107.114 0.488739 107.016 0.586504 107.016 0.703823V27.3351C107.016 27.4524 107.114 27.5502 107.231 27.5502H113.365C119.91 27.5502 124.3 24.3435 124.3 19.5725C124.3 17.0697 122.928 14.9776 120.439 13.6284ZM118.577 8.40773C118.577 10.4608 116.637 11.8491 113.757 11.8491H112.346C112.169 11.8491 112.032 11.7122 112.032 11.5362V5.18148C112.032 5.0055 112.169 4.86863 112.346 4.86863H113.796C116.657 4.84907 118.577 6.27645 118.577 8.40773ZM112.013 16.444C112.013 16.2681 112.15 16.1312 112.326 16.1312H113.776C117.245 16.1312 119.4 17.4217 119.4 19.4748C119.4 21.7234 117.108 23.1899 113.541 23.1899H112.326C112.15 23.1899 112.013 23.053 112.013 22.877V16.444Z"
          fill="#1A2F62"
        />
        <path
          d="M142.563 0.469177H128.336C128.218 0.469177 128.12 0.566943 128.12 0.684261V27.3156C128.12 27.4329 128.218 27.5306 128.336 27.5306H142.563C142.68 27.5306 142.778 27.4329 142.778 27.3156V23.3658C142.778 23.2485 142.68 23.1507 142.563 23.1507H133.49C133.313 23.1507 133.176 23.0139 133.176 22.8379V16.444C133.176 16.2681 133.313 16.1312 133.49 16.1312H142.034C142.151 16.1312 142.249 16.0334 142.249 15.9161V12.0642C142.249 11.9468 142.151 11.8491 142.034 11.8491H133.49C133.313 11.8491 133.176 11.7122 133.176 11.5362V5.18147C133.176 5.00549 133.313 4.86862 133.49 4.86862H142.563C142.68 4.86862 142.778 4.77085 142.778 4.65354V0.684261C142.778 0.566943 142.68 0.469177 142.563 0.469177Z"
          fill="#1A2F62"
        />
        <path
          d="M165.961 27.1787L158.24 17.2066C158.181 17.1284 158.162 17.0111 158.181 16.9133C158.22 16.8155 158.299 16.7373 158.397 16.6982C162.14 15.525 164.276 12.7094 164.276 8.97476C164.276 3.81275 160.258 0.469177 154.027 0.469177H148.344C148.226 0.469177 148.128 0.566943 148.128 0.684261V27.3156C148.128 27.4329 148.226 27.5306 148.344 27.5306H152.93C153.047 27.5306 153.145 27.4329 153.145 27.3156V18.6731C153.145 18.5362 153.243 18.4189 153.361 18.3602C153.4 18.3407 153.419 18.3407 153.459 18.3407C153.557 18.3407 153.655 18.3798 153.713 18.4776L160.18 27.4329C160.219 27.4915 160.278 27.5306 160.356 27.5306H165.785C165.863 27.5306 165.941 27.4915 165.981 27.4133C166.02 27.3351 166.02 27.2569 165.961 27.1787ZM159.357 8.97476C159.357 11.4385 157.201 13.1396 154.125 13.1396H153.478C153.302 13.1396 153.165 13.0027 153.165 12.8267V5.12281C153.165 4.94683 153.302 4.80996 153.478 4.80996H154.125C157.26 4.80996 159.357 6.47197 159.357 8.97476Z"
          fill="#1A2F62"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_359:10509"
          x1="31.6657"
          y1="4.06049"
          x2="51.2607"
          y2="25.7129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#83BCE2" />
          <stop offset="0.4343" stopColor="#6EB0D5" />
          <stop offset="0.7054" stopColor="#54A1C5" />
          <stop offset="1" stopColor="#2F8CAE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_359:10509"
          x1="23.8879"
          y1="28"
          x2="12.9"
          y2="11.1198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8381B" />
          <stop offset="1" stopColor="#EC5A42" />
        </linearGradient>
        <clipPath id="clip0_359:10509">
          <rect width="166" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
