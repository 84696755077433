import { put, takeLatest, fork, call } from "redux-saga/effects";
import { UserListData, ActionType, UserListResponse } from "models/userlist";
import { httpStatus } from "configs/httpStatus";
import { fetchUserList } from "services/userList";
import { handleSuccesAction, handleErrorAction } from "./userListActions";

function* userListSaga({ payload }: { payload: UserListData }) {
  try {
    const response: UserListResponse = yield call(fetchUserList, payload);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetUserListWatcher() {
  yield takeLatest(ActionType.USER_LIST as any, userListSaga);
}

export default [fork(onGetUserListWatcher)];
