import {
  IQuestionnairesPayload,
  ActionType,
  QuestionnairesResponse,
} from "models/questionnaires/questionnairesByType";

export const getQuestionnairesAction = (payload?: IQuestionnairesPayload) => {
  return {
    type: ActionType.GET_QUESTIONNAIRES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_QUESTIONNAIRES_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: QuestionnairesResponse) => {
  return {
    type: ActionType.GET_QUESTIONNAIRES_SUCCESS,
    payload,
  };
};
