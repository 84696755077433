import {
  Action,
  ActionType,
  HealthCheckProgressResponse,
} from "models/dashboard/healthCheckProgress";
import createReducer from "../../createReducer";

export interface HealthCheckProgressReducerType {
  loading: boolean;
  error?: string;
  healthCheckProgressResponse?: HealthCheckProgressResponse;
}

const defaultState: HealthCheckProgressReducerType = {
  loading: false,
  error: undefined,
  healthCheckProgressResponse: undefined,
};

export const getHealthCheckProgressReducer =
  createReducer<HealthCheckProgressReducerType>(defaultState, {
    [ActionType.GET_HEALTH_CHECK_PROGRESS_CHART](
      state: HealthCheckProgressReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_HEALTH_CHECK_PROGRESS_CHART_ERROR](
      state: HealthCheckProgressReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_HEALTH_CHECK_PROGRESS_CHART_SUCCESS](
      state: HealthCheckProgressReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        healthCheckProgressResponse: action.payload,
      };
    },
  });
