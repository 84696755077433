import {
  ActionType,
  IGetActivityFilterListResponse,
} from "../../../models/messagemanagement/activityFilterList";

export const activitiesFilterListAction = () => {
  return {
    type: ActionType.ACTIVITY_FILTER_LIST,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.ACTIVITY_FILTER_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IGetActivityFilterListResponse) => {
  return {
    type: ActionType.ACTIVITY_FILTER_SUCCESS,
    payload,
  };
};
