import {
  Action,
  ActionType,
  AccountListResponse,
} from "models/account/accountList";
import createReducer from "../../createReducer";

export interface AccountListReducerType {
  loading: boolean;
  error?: string;
  accountListResponse?: AccountListResponse;
}

const defaultState: AccountListReducerType = {
  loading: false,
  error: undefined,
  accountListResponse: undefined,
};

export const accountListReducer = createReducer<AccountListReducerType>(
  defaultState,
  {
    [ActionType.ACCOUNT_LIST](state: AccountListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.ACCOUNT_LIST_ERROR](
      state: AccountListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.ACCOUNT_LIST_SUCCESS](
      state: AccountListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        accountListResponse: action.payload,
      };
    },
  }
);
