import React from "react";
import styled from "styled-components";
import { Modal, ModalProps } from "antd";
import { theme } from "theme/theme";

import { CloseIcon } from "icons";

const ModalStyled = styled(Modal)`
  width: 343px !important;
  top: 16px;

  .ant-modal-content {
    border-radius: 8px;
    border: 1px solid #cccccc;
  }

  .ant-modal-header {
    padding: 24px 16px 16px;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #f2f2f2;
    /* box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12); */
  }

  .ant-modal-close {
    top: 9px;
    right: -4px;
  }

  .ant-modal-title {
    font-weight: ${theme.fontWeight.bold};
    font-size: 12px;
    line-height: 16px;
  }

  .ant-modal-body {
    padding: 16px 16px 24px;
  }

  .ant-modal-footer {
    padding: 0px 16px 24px;
    border: unset;
  }

  ${theme.media.tablet} {
    width: 720px !important;
    top: calc(50% + 45px);
    transform: translate(0%, -50%);

    .ant-modal-footer {
      padding: 0px 24px 24px;
      button {
        font-size: ${theme.sizes.md};
      }
    }
    .ant-modal-header {
      padding: 11px 60px 11px 24px;
    }
    .ant-modal-close {
      top: 10px;
      right: 0px;
    }
    .ant-modal-body {
      padding: 16px 24px 32px;
    }
  }

  ${theme.media.laptopL} {
    top: 100px;
    transform: unset;
  }
`;

interface IModal extends ModalProps {}

const ModalCustom: React.FC<IModal> = ({ children, ...props }) => {
  return (
    <ModalStyled {...props} closeIcon={<CloseIcon fill="#3B3B3B" />}>
      {children}
    </ModalStyled>
  );
};

export default ModalCustom;
