import { IResponse } from "models";

export enum ActionType {
  CSV_DOWNLOAD_ALL_QUESTION = "action/CSV_DOWNLOAD_ALL_QUESTION",
  CSV_DOWNLOAD_ALL_QUESTION_SUCCESS = "action/CSV_DOWNLOAD_ALL_QUESTION_SUCCESS",
  CSV_DOWNLOAD_ALL_QUESTION_ERROR = "action/CSV_DOWNLOAD_ALL_QUESTION_ERROR",
  CSV_DOWNLOAD_ALL_QUESTION_RESET_DATA = "action/CSV_DOWNLOAD_ALL_QUESTION_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface CSVDownloadAllQuestionResponse extends IResponse {
  result?: ICSVDownloadResult;
}

export interface ICSVDownloadResult {
  data: ICSVDownloadAllQuestionItem[];
  header: string[];
}
export interface IAllQuestionDownloadData {
  id?: number;
}

export interface ICSVDownloadAllQuestionItem {
  class_name: string;
  roll_number: string;
  full_name: string;
}
