import { IResponse } from "../../models";
import { ActionType, UpdateUserData } from "models/updateuser";

export const createUserAction = (payload: UpdateUserData) => {
  return {
    type: ActionType.UPDATE_USER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_USER_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.UPDATE_USER_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.UPDATE_USER_RESET,
  };
};
