import {
  Action,
  ActionType,
  IExchangeListResponse,
} from "models/consultationRequest/exchangeList";
import createReducer from "redux/createReducer";

export interface ExchangeListReducerType {
  loading: boolean;
  error?: string;
  exchangeListResponse?: IExchangeListResponse;
}

const defaultState: ExchangeListReducerType = {
  loading: false,
  error: undefined,
  exchangeListResponse: undefined,
};

export const exchangeListReducer = createReducer<ExchangeListReducerType>(
  defaultState,
  {
    [ActionType.EXCHANGE_LIST](state: ExchangeListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.EXCHANGE_LIST_ERROR](
      state: ExchangeListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        exchangeListResponse: undefined,
      };
    },

    [ActionType.EXCHANGE_LIST_SUCCESS](
      state: ExchangeListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        exchangeListResponse: action.payload,
      };
    },

    [ActionType.EXCHANGE_LIST_RESET](state: ExchangeListReducerType) {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
  }
);
