import {
  ActionType,
  GetCitiesPayload,
  GetCitiesResponse,
} from "models/dashboard/cities";

export const getCitiesAction = (payload: GetCitiesPayload) => {
  return {
    type: ActionType.GET_CITIES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_CITIES_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: GetCitiesResponse) => {
  return {
    type: ActionType.GET_CITIES_SUCCESS,
    payload,
  };
};
