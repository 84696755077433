import * as React from "react";

function ElementaryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_25526_97320)">
        <path
          d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
          fill="#2AC769"
        />
        <path
          d="M6.9969 4.21582L8.35075 4.6589C8.03075 5.4589 7.64921 6.29582 7.20613 7.08351V15.662H5.81536V9.22505C5.50767 9.6189 5.19998 9.98813 4.87998 10.3204C4.74459 9.96351 4.33844 9.17582 4.09229 8.8189C5.2369 7.7112 6.3569 5.96351 6.9969 4.21582ZM9.98767 6.01274V7.66197H13.5938V6.01274H9.98767ZM15.6615 11.3912H13.2C13.9261 12.462 14.9846 13.4712 16.0184 14.0743C15.6861 14.3574 15.2184 14.8743 14.9846 15.2435C14.0861 14.6035 13.1877 13.6066 12.4861 12.5112V15.6866H11.0092V12.5727C10.2584 13.6558 9.32306 14.5912 8.35075 15.2189C8.12921 14.862 7.67382 14.3327 7.34152 14.0497C8.44921 13.4712 9.54459 12.4743 10.3077 11.3912H7.78459V10.062H11.0092V8.95428H8.5969V4.72044H15.0584V8.95428H12.4861V10.062H15.6615V11.3912Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_25526_97320">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ElementaryIcon;
