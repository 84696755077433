import { IResponse } from "models";

export enum ActionType {
  DATA_DETAIL_FORM = "action/DATA_DETAIL_FORM",
  DATA_DETAIL_FORM_SUCCESS = "action/DATA_DETAIL_FORM_SUCCESS",
  DATA_DETAIL_FORM_ERROR = "action/DATA_DETAIL_FORM_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DataDetailFormResponse extends IResponse {
  result: any;
}
