export enum ActionType {
  // creatte user
  UPDATE_USER = "action/UPDATE_USER",
  UPDATE_USER_SUCCESS = "action/UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR = "action/UPDATE_USER_ERROR",
  UPDATE_USER_RESET = "action/UPDATE_USER_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface UpdateUserData {
  user: {
    id?: number;
    last_name: string;
    first_name: string;
    furi_first_name: string;
    furi_last_name: string;
    gender: number;
    date_of_birth?: string;
    dept_id: number | null;
    sub_dept_id: number | null;
    company_sub_department_id: number[];
    roll_number?: number;
  };
  locale: string | null;
}
