import * as React from "react";

function FaceGoodIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="#54C5C5" />
      <path
        d="M5.4689 7.41873C5.28804 7.02084 5.28804 6.58678 5.4689 6.18889C5.79444 5.53779 6.69874 5.1399 7.42218 5.42927C7.5307 5.46545 7.63922 5.53779 7.71156 5.61013C7.82008 5.71865 7.92859 5.89951 8.00094 6.00803C8.21797 6.44209 8.29031 7.05701 8.00094 7.4549C7.42218 8.28686 5.9753 8.39538 5.4689 7.41873Z"
        fill="white"
      />
      <path
        d="M11.9438 7.41873C11.7629 7.02084 11.7629 6.58678 11.9438 6.18889C12.2693 5.53779 13.1736 5.1399 13.897 5.42927C14.0056 5.46545 14.1141 5.53779 14.1864 5.61013C14.2949 5.71865 14.4034 5.89951 14.4758 6.00803C14.6928 6.44209 14.7652 7.05701 14.4758 7.4549C13.8609 8.28686 12.3778 8.39538 11.9438 7.41873Z"
        fill="white"
      />
      <path
        d="M9.8818 15.268C9.80946 15.268 9.73712 15.268 9.6286 15.268C8.00086 15.1957 6.69867 14.5084 6.15609 13.4594C6.04757 13.2424 6.15609 12.9892 6.33695 12.9168C6.55398 12.8083 6.80718 12.9168 6.87953 13.0977C7.27742 13.8935 8.29024 14.3999 9.6286 14.4361C11.1478 14.5084 12.5585 13.9296 13.1011 13.0615C13.2096 12.8807 13.4628 12.8083 13.6799 12.9168C13.8607 13.0253 13.9331 13.2786 13.8245 13.4956C13.1011 14.7254 11.3287 15.268 9.8818 15.268Z"
        fill="white"
      />
    </svg>
  );
}

export default FaceGoodIcon;
