import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ResetPasswordData,
  ActionType,
  ResetPasswordResponse,
} from "../../models/resetPassword";
import { httpStatus } from "configs/httpStatus";
import { fetchResetPassword } from "services/resetpassword";
import { handleSuccesAction, handleErrorAction } from "./resetpasswordActions";
import { history } from "../configureStore";

// reset password
function* resetPasswordSaga({ payload }: { payload: ResetPasswordData }) {
  try {
    const response: ResetPasswordResponse = yield call(
      fetchResetPassword,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      const message = response.message;
      yield put(handleSuccesAction(message));
      history.push("/reset-password-notification");
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onResetPasswordSubmitWatcher() {
  yield takeLatest(ActionType.RESET_PASSWORD as any, resetPasswordSaga);
}

export default [fork(onResetPasswordSubmitWatcher)];
