import { IResponse } from "models";

export enum ActionType {
  GET_CLASS_LIST_END = "action/GET_CLASS_LIST_END",
  GET_CLASS_LIST_END_SUCCESS = "action/GET_CLASS_LIST_END_SUCCESS",
  GET_CLASS_LIST_END_ERROR = "action/GET_CLASS_LIST_END_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IClassListData {
  locale?: string;
}

export interface IClassListResponse extends IResponse {
  result?: any;
}
