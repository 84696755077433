import React from "react";
import { Checkbox } from "antd";
import styled from "styled-components";
import { IOption } from "components/select/Select";

interface ICheckbox {
  row?: boolean;
  onChange?: (e: any) => void;
  list?: IOption[];
  defaultValue?: string[];
  value?: string[];
}

const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
  alignItems: "unset",
  outline: "none",
  "&:hover": {
    ".ant-checkbox-inner": {
      borderColor: theme.colors.border,
    },
  },
  ".ant-checkbox": {
    top: "unset",
  },
  ".ant-checkbox-inner": {
    width: 20,
    height: 21,
    border: `2px solid ${theme.colors.border}`,
  },
  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.colors.button,
    borderColor: `${theme.colors.button}!important`,
  },
  ".ant-checkbox-checked::after": {
    border: "none",
  },
  ".ant-checkbox-input:focus + .ant-checkbox-inner": {
    borderColor: theme.colors.border,
  },
}));

const CheckboxCustom: React.FC<ICheckbox> = ({
  row,
  list,
  onChange,
  defaultValue,
  value,
}) => {
  const styles = row
    ? { display: "flex", flexFlow: "row wrap", gap: "25px 28px" }
    : "";
  const spaceItem = row ? {} : { marginBottom: 12 };
  return (
    <Checkbox.Group
      style={{ width: "100%" }}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
    >
      <div style={{ ...styles }}>
        {(list || []).map((item: IOption) => (
          <div key={item.key} style={{ ...spaceItem }}>
            <CheckboxStyled value={item}>{item.name}</CheckboxStyled>
          </div>
        ))}
      </div>
    </Checkbox.Group>
  );
};

export default CheckboxCustom;
