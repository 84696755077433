import { LoginData, ActionType } from "../../models/authentication";

export const loginUserAction = (payload: LoginData) => {
  return {
    type: ActionType.LOGIN_USER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.LOGIN_USER_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: string) => {
  return {
    type: ActionType.LOGIN_USER_SUCCESS,
    payload,
  };
};
