import {
  ActionType,
  ScheduleFilterDatasResponse,
} from "models/vaccine/schedules";

export const getScheduleFilterAction = (payload: any) => {
  return {
    type: ActionType.SCHEDULE_FILTER,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SCHEDULE_FILTER_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ScheduleFilterDatasResponse) => {
  return {
    type: ActionType.SCHEDULE_FILTER_SUCCESS,
    payload,
  };
};
