import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ISendNotificationConsultationPayload,
  ActionType,
  ISendNotificationConsultationResponse,
} from "models/consultationRequest/sendNotification";
import { httpStatus } from "configs/httpStatus";
import { sendNotification } from "services/consultationRequest";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./sendNotificationActions";

function* sendNotificationConsultationSaga({
  payload,
}: {
  payload: ISendNotificationConsultationPayload;
}) {
  try {
    const response: ISendNotificationConsultationResponse = yield call(
      sendNotification,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onSendNotificationConsultationWatcher() {
  yield takeLatest(
    ActionType.SEND_NOTIFICATION_CONSULTATION as any,
    sendNotificationConsultationSaga
  );
}

export default [fork(onSendNotificationConsultationWatcher)];
