import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { deleteNotificationSetting } from "services/setting";
import {
  ActionType,
  IDeleteNotificationResponse,
} from "models/setting/deleteNotification";

import {
  handleSuccesAction,
  handleErrorAction,
} from "./deleteNotificationActions";

function* deleteNotificationSaga({
  payload,
}: {
  payload: {
    company_user_send_notification_id: number;
  };
}) {
  try {
    const response: IDeleteNotificationResponse = yield call(
      deleteNotificationSetting,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDeleteNotificationWatcher() {
  yield takeLatest(
    ActionType.DELETE_NOTIFICATION as any,
    deleteNotificationSaga
  );
}

export default [fork(onDeleteNotificationWatcher)];
