import {
  ActionType,
  Action,
  QuestionnairesDetailResponse,
} from "models/questionnaires/questionnairesDetail";
import createReducer from "../../createReducer";

export interface QuestionnairesDetailReducerType {
  loading: boolean;
  error?: string;
  questionnairesDetailResponse?: QuestionnairesDetailResponse;
}

const defaultState: QuestionnairesDetailReducerType = {
  loading: false,
  error: undefined,
  questionnairesDetailResponse: undefined,
};

export const questionnaireDetailReducer =
  createReducer<QuestionnairesDetailReducerType>(defaultState, {
    [ActionType.GET_QUESTIONNAIRES_DETAIL](
      state: QuestionnairesDetailReducerType
    ) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_QUESTIONNAIRES_DETAIL_ERROR](
      state: QuestionnairesDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        questionnairesDetailResponse: undefined,
      };
    },

    [ActionType.GET_QUESTIONNAIRES_DETAIL_SUCCESS](
      state: QuestionnairesDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        questionnairesDetailResponse: action.payload,
      };
    },

    [ActionType.GET_QUESTIONNAIRES_DETAIL_RESET](
      state: QuestionnairesDetailReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        questionnairesDetailResponse: undefined,
      };
    },
  });
