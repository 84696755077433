import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  CSVDownloadTypeInfoResponse,
  ActionType,
  ITypeInfoDownloadData,
} from "models/mentalHealth/csvDownloadTypeInfo";
import { httpStatus } from "configs/httpStatus";
import { downloadCSVTypeInfo } from "services/mentalHealth";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./downloadTypeCSVActions";

function* csvDownloadTypeInfoSaga({
  payload,
}: {
  payload: ITypeInfoDownloadData;
}) {
  try {
    const response: CSVDownloadTypeInfoResponse = yield call(
      downloadCSVTypeInfo,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onDownloadCSVTypeInfoWatcher() {
  yield takeLatest(
    ActionType.CSV_DOWNLOAD_TYPE_INFO as any,
    csvDownloadTypeInfoSaga
  );
}

export default [fork(onDownloadCSVTypeInfoWatcher)];
