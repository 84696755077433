import { IResponse } from "models";

export enum ActionType {
  UPDATE_MESSAGE_SETTING = "action/UPDATE_MESSAGE_SETTING",
  UPDATE_MESSAGE_SETTING_SUCCESS = "action/UPDATE_MESSAGE_SETTING_SUCCESS",
  UPDATE_MESSAGE_SETTING_ERROR = "action/UPDATE_MESSAGE_SETTING_ERROR",
  RESET_MESSAGE_SETTING = "action/RESET_MESSAGE_SETTING",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateMessageResponse extends IResponse {}

export interface IUpdateMessagePayload {
  is_active: boolean;
  number_of_reminder: number;
  reminder_time_in_minutes: number;
}
