import {
  ActionType,
  UserAttendanceData,
  UserAttendanceResponse,
} from "models/userAttendance";

export const userAttendanceAction = (payload: UserAttendanceData) => {
  return {
    type: ActionType.USER_ATTENDANCE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.USER_ATTENDANCE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: UserAttendanceResponse) => {
  return {
    type: ActionType.USER_ATTENDANCE_SUCCESS,
    payload,
  };
};
