import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ICreateQuestionnairesPayload,
  ActionType,
  CreateQuestionnairesResponse,
} from "models/questionnaires/createQuestionnaires";
import { httpStatus } from "configs/httpStatus";
import { createQuestionnaries } from "services/questionnaires";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./createQuestionnairesActions";

function* createQuestionnariesSaga({
  payload,
}: {
  payload: ICreateQuestionnairesPayload;
}) {
  try {
    const response: CreateQuestionnairesResponse = yield call(
      createQuestionnaries,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onCreateQuestionnariesSagaSubmitWatcher() {
  yield takeLatest(
    ActionType.CREATE_QUESTIONNAIRES as any,
    createQuestionnariesSaga
  );
}

export default [fork(onCreateQuestionnariesSagaSubmitWatcher)];
