import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IRemindPushNotificationPayload,
  RemindPushNotificationResponse,
} from "models/questionnaires/remindPushNotification";
import { httpStatus } from "configs/httpStatus";
import { sendRemindPushNotification } from "services/questionnaires";
import {
  handleSuccessAction,
  handleErrorAction,
} from "./remindPushNotificationActions";

function* remindPushNotificationSaga({
  payload,
}: {
  payload: IRemindPushNotificationPayload;
}) {
  try {
    const response: RemindPushNotificationResponse = yield call(
      sendRemindPushNotification,
      payload
    );

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onRemindPushNotificationSagaWatcher() {
  yield takeLatest(
    ActionType.REMIND_PUSH_NOTIFICATION as any,
    remindPushNotificationSaga
  );
}

export default [fork(onRemindPushNotificationSagaWatcher)];
