import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "components";
import { localStorageHelper, uuid } from "utils";
import { PlusIcon } from "icons";
import { useOnClickOutside } from "shared-hooks";

import {
  ActivityListActionCreators,
  CreateActivityActionCreators,
} from "redux/rootActions";
import { IActivityList } from "models/activitymanagement/activityList";
import { ICreateActivityData } from "models/activitymanagement/createActivity";
import {
  createActivityDataResponse,
  activityCreated,
} from "redux/activitymanagement/createactivity/createActivityStates";
import { activityListDataResponse } from "redux/activitymanagement/getactivities/activitiesListStates";

import AddInput from "./AddInput";
import TreeView from "./TreeView";
import {
  Wrapper,
  AddButton,
  AddText,
  PlusIconStyled,
} from "./ActivityManagement.style";

const CreateButton = ({ handleAddColumn }: any) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "class-and-activity-management",
  });

  const dispatch = useDispatch();
  const activitySaved = useSelector(activityCreated);
  const ref = useRef<any>(null);
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);
  useOnClickOutside(ref, () => setIsComponentVisible(false));

  useEffect(() => {
    if (activitySaved) {
      setIsComponentVisible(false);
      dispatch(CreateActivityActionCreators.handleResetAction());
    }
  }, [activitySaved]);

  return (
    <AddButton
      ref={ref}
      style={{
        padding: isComponentVisible ? "8px" : "4px 6px",
      }}
    >
      {isComponentVisible && (
        <div>
          <PlusIconStyled
            fill="#FB4E4E"
            style={{ marginTop: 6 }}
            onClick={() => setIsComponentVisible(false)}
          />
        </div>
      )}
      <AddText
        onClick={() => {
          setIsComponentVisible(true);
        }}
        style={{
          marginTop: isComponentVisible ? "5px" : "",
        }}
      >
        {!isComponentVisible && <PlusIcon fill="#1AB759" />}
        <span
          style={{
            marginLeft: 5,
            color: "#1ab759",
          }}
        >
          {t("add-item")}
        </span>
      </AddText>
      {isComponentVisible && <AddInput handleAddColumn={handleAddColumn} />}
    </AddButton>
  );
};

const ActivityManagement: React.FC = () => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "layout",
  });

  const [treeData, setTreeData] = useState<IActivityList[]>([]);

  const dispatch = useDispatch();
  const activitiesList = useSelector(activityListDataResponse);
  const createActivitySuccess = useSelector(createActivityDataResponse);
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  useEffect(() => {
    dispatch(CreateActivityActionCreators.handleResetAction());
    dispatch(ActivityListActionCreators.activitiesListAction());
  }, []);

  useEffect(() => {
    dispatch(ActivityListActionCreators.activitiesListAction());
  }, [createActivitySuccess]);

  useEffect(() => {
    if (activitiesList && activitiesList.result) {
      setTreeData(
        activitiesList?.result.map((item: any) => {
          return {
            ...item,
            level: "1",
          };
        })
      );
    }
  }, [activitiesList]);

  const handleAddColumn = (data: any) => {
    const params: ICreateActivityData = {
      activity: {
        name: data.newActivity,
        is_required: data.check,
      },
      locale: currentLanguage,
    };

    dispatch(CreateActivityActionCreators.createActivityAction(params));
  };

  return (
    <Box title={t("activity-management-label")}>
      <Wrapper>
        {!treeData.length ? (
          <CreateButton handleAddColumn={handleAddColumn} />
        ) : (
          <>
            <div style={{ marginBottom: 32 }}>
              <CreateButton handleAddColumn={handleAddColumn} />
            </div>
            <TreeView treeData={treeData} setTreeData={setTreeData} />
            <div style={{ marginTop: 32 }}>
              <CreateButton handleAddColumn={handleAddColumn} />
            </div>
          </>
        )}
      </Wrapper>
    </Box>
  );
};

export default ActivityManagement;
