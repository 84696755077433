import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  IDoneQuestionPayload,
  DoneQuestionResponse,
} from "models/questionnaires/doneQuestion";
import { httpStatus } from "configs/httpStatus";
import { doneQuestion } from "services/questionnaires";
import { handleSuccessAction, handleErrorAction } from "./doneQuestionActions";

function* doneQuestionSaga({ payload }: { payload: IDoneQuestionPayload }) {
  try {
    const response: DoneQuestionResponse = yield call(doneQuestion, payload);

    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onDoneQuestionSagaSubmitWatcher() {
  yield takeLatest(ActionType.DONE_QUESTION as any, doneQuestionSaga);
}

export default [fork(onDoneQuestionSagaSubmitWatcher)];
