import {
  Action,
  ActionType,
  HealthCheckQuestionResponse,
} from "models/bodyTemperature/healthCheckQuestions";
import createReducer from "redux/createReducer";

export interface HealthCheckQuestionsReducerType {
  loading: boolean;
  error?: string;
  healthCheckQuestionsResponse?: HealthCheckQuestionResponse;
}

const defaultState: HealthCheckQuestionsReducerType = {
  loading: false,
  error: undefined,
  healthCheckQuestionsResponse: undefined,
};

export const healthCheckQuestionsReducer =
  createReducer<HealthCheckQuestionsReducerType>(defaultState, {
    [ActionType.HEALTH_CHECK_QUESTION](state: HealthCheckQuestionsReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.HEALTH_CHECK_QUESTION_ERROR](
      state: HealthCheckQuestionsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        healthCheckQuestionsResponse: undefined,
      };
    },

    [ActionType.HEALTH_CHECK_QUESTION_SUCCESS](
      state: HealthCheckQuestionsReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        healthCheckQuestionsResponse: action.payload,
      };
    },

    [ActionType.HEALTH_CHECK_QUESTION_RESET](
      state: HealthCheckQuestionsReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        healthCheckQuestionsResponse: undefined,
      };
    },
  });
