import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActionType,
  ISearchConditionResponse,
} from "models/messagemanagement/searchCondition";
import { httpStatus } from "configs/httpStatus";
import { fetchSearchCondition } from "services/messagemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./searchConditionActions";

function* searchConditionSaga() {
  try {
    const response: ISearchConditionResponse = yield call(fetchSearchCondition);
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: {} }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetSearchConditionWatcher() {
  yield takeLatest(ActionType.SEARCH_CONDITION as any, searchConditionSaga);
}

export default [fork(onGetSearchConditionWatcher)];
