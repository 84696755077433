import {
  ActionType,
  IUpdateMessagePayload,
} from "models/setting/updateMessage";

export const updateMessageAction = (payload: IUpdateMessagePayload) => {
  return {
    type: ActionType.UPDATE_MESSAGE_SETTING,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_MESSAGE_SETTING_ERROR,
    payload,
  };
};

export const handleSuccessAction = (payload: any) => {
  return {
    type: ActionType.UPDATE_MESSAGE_SETTING_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.RESET_MESSAGE_SETTING,
  };
};
