import { ActionType, IUpdateVenuesResponse } from "models/vaccine/updateVenues";

export const updateVenuesAction = (payload: { id: number; value: any }) => {
  return {
    type: ActionType.VACCINE_UPDATE_VENUES,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.VACCINE_UPDATE_VENUES_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IUpdateVenuesResponse) => {
  return {
    type: ActionType.VACCINE_UPDATE_VENUES_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.VACCINE_UPDATE_VENUES_RESET,
  };
};
