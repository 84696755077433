import { put, takeLatest, fork, call } from "redux-saga/effects";
import { httpStatus } from "configs/httpStatus";
import { fetchConfirmStudentList } from "services/classchangemanagement";
import {
  handleSuccesAction,
  handleErrorAction,
} from "./getConfirmStudentListActions";
import {
  ActionType,
  IConfirmStudentListData,
  IConfirmStudentListResponse,
} from "models/classchangemanagement/confirmStudentListClassChange";

function* getConfirmStudentListSaga({
  payload,
}: {
  payload: IConfirmStudentListData;
}) {
  try {
    const response: IConfirmStudentListResponse = yield call(
      fetchConfirmStudentList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else if (response.status === httpStatus.StatusNotFound) {
      yield put(handleSuccesAction({ ...response, result: [] }));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetConfirmStudentListWatcher() {
  yield takeLatest(
    ActionType.CONFIRM_STUDENT_LIST as any,
    getConfirmStudentListSaga
  );
}

export default [fork(onGetConfirmStudentListWatcher)];
