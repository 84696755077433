import { IResponse } from "models";

export enum ActionType {
  // get user list
  MENTAL_CHECK_REQUEST = "action/MENTAL_CHECK_REQUEST",
  MENTAL_CHECK_REQUEST_SUCCESS = "action/MENTAL_CHECK_REQUEST_SUCCESS",
  MENTAL_CHECK_REQUEST_ERROR = "action/MENTAL_CHECK_REQUEST_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface MentalCheckRequestsData {
  mental_check_type_id?: any;
}

export interface IRequest {
  c_department_id: number;
  mental_check_request_id: number;
  mental_check_request_name: string;
}

export interface MentalCheckRequestsResponse extends IResponse {
  result?: IRequest[];
}
