import { IOption } from "components/select/Select";
import { IResponse } from "models";

export enum ActionType {
  USER_LIST = "action/USER_LIST",
  USER_LIST_SUCCESS = "action/USER_LIST_SUCCESS",
  USER_LIST_ERROR = "action/USER_LIST_ERROR",
  USER_LIST_RESET_DATA = "action/USER_LIST_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface UserListData {
  page: number;
  user_name?: string | null;
  c_department_id?: string | null;
  locale: string | null;
  per_page: number;
  other_name_type_sort?: string;
  grade_sort?: string;
  attendance_number_sort?: string;
  gender_sort?: string;
}

export interface UserListResponse extends IResponse {
  result?: UserListResultData[];
  total_record: number;
  current_page: number;
  next_page: number;
}

export interface UserListResultData {
  id: number;
  first_name: string;
  last_name: string;
  furi_first_name: string;
  furi_last_name: string;
  roll_number?: string;
  gender: number;
  date_of_birth: string;
  class_name: string;
  group_list?: IOption[];
  department_id: number;
  c_department_id: number;
}
