import { IResponse } from "models";
import { ICreateQuestionnairesPayload } from "./createQuestionnaires";

export enum ActionType {
  UPDATE_QUESTIONNAIRES = "action/UPDATE_QUESTIONNAIRES",
  UPDATE_QUESTIONNAIRES_SUCCESS = "action/UPDATE_QUESTIONNAIRES_SUCCESS",
  UPDATE_QUESTIONNAIRES_ERROR = "action/UPDATE_QUESTIONNAIRES_SUCCESS",
  UPDATE_QUESTIONNAIRES_RESET = "action/UPDATE_QUESTIONNAIRES_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUpdateQuestionnairesPayload {
  id?: number;
  param: ICreateQuestionnairesPayload;
}

export interface UpdateQuestionnairesResponse extends IResponse {}
