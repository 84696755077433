import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { httpStatus } from "configs/httpStatus";
import { SendCompleteIcon } from "icons";

import {
  QuestionnairesActionCreators,
  DuplicateQuestionActionCreators,
  DoneQuestionActionCreators,
  DeleteQuestionnaireActionCreators,
  QuestionnairesDetailActionCreators,
  CreateQuestionnairesActionCreators,
} from "redux/rootActions";
import { createQuestionnairesDataResponse } from "redux/questionnaires/createQuestionnaires/createQuestionnairesStates";
import { questionnairesDataResponse } from "redux/questionnaires/questionnairesByType/questionnairesByTypeStates";
import { duplicateQuestionResponseData } from "redux/questionnaires/duplicateQuestion/duplicateQuestionStates";
import { doneQuestionResponseData } from "redux/questionnaires/doneQuestion/doneQuestionStates";
import { deleteQuestionnairesDataResponse } from "redux/questionnaires/deleteQuestionnaires/deleteQuestionnairesStates";

import {
  Title,
  SubTitle,
  WrapperTab,
  ItemTab,
  WrapperTabs,
} from "./Questions.style";
import QuestionsTransmission from "./QuestionsTransmission";
import CreateQuestion from "./CreateQuestion";
import QuestionList from "./QuestionList";
import RecentlyUsedForms from "./RecentlyUsedForms";
import { localStorageHelper } from "utils";

export interface dataTable {
  key: number;
  title: string;
  answer_rate?: string;
  implementation_period?: string;
  created_date?: string;
  modification_date?: string;
  author?: string;
}

export type ScreenState = "create" | "update" | "transmission" | "";

interface ISurveyList {
  setScreen: React.Dispatch<React.SetStateAction<ScreenState>>;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}

const SurveyList = ({ setScreen, setStatus }: ISurveyList) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "questions" });

  const dispatch = useDispatch();
  const questionnairesResponse = useSelector(questionnairesDataResponse);
  const duplicateQuestionResponse = useSelector(duplicateQuestionResponseData);
  const doneQuestionResponse = useSelector(doneQuestionResponseData);
  // const deleteQuestionnairesData = useSelector(
  //   deleteQuestionnairesDataResponse
  // );

  const [tab, setTab] = useState(1);
  const [input, setInput] = useState({
    limit: 25,
    page: 1,
  });
  const [questionnaires, setQuestionnaires] = useState<any>({
    total: 0,
    totalDraft: 0,
    totalEndOfResponse: 0,
    totalInprogress: 0,
    totalRecord: 0,
    totalBeforeAnswer: 0,
    data: [],
  });

  const Tabs: {
    key: number;
    name: string;
  }[] = [
    {
      name: t("recently-used-form"),
      key: 1,
    },
    {
      name: t("end-of-answer"),
      key: 2,
    },
  ];

  const handleGetLastestUsedForm = () => {
    dispatch(QuestionnairesActionCreators.getQuestionnairesAction());
  };

  const handleGetEndOfAnswer = () => {
    dispatch(
      QuestionnairesActionCreators.getQuestionnairesAction({
        ...input,
        is_end_of_answer: true,
      })
    );
  };

  const handleShowSuccessMessage = (message: string) => {
    notification.success({
      message: t(message),
      description: "",
      icon: <SendCompleteIcon />,
      placement: "bottomRight",
    });
  };

  useEffect(() => {
    if (duplicateQuestionResponse) {
      if (duplicateQuestionResponse.status === httpStatus.StatusOK) {
        if (tab === 1) {
          handleGetLastestUsedForm();
        }
        handleShowSuccessMessage("duplicate-message");
        dispatch(DuplicateQuestionActionCreators.handleResetAction());
      }
    }
    if (doneQuestionResponse) {
      if (doneQuestionResponse.status === httpStatus.StatusOK) {
        handleGetLastestUsedForm();
        handleShowSuccessMessage("done-message");
        dispatch(DoneQuestionActionCreators.handleResetAction());
      }
    }
    // if (deleteQuestionnairesData) {
    //   if (deleteQuestionnairesData.status === httpStatus.StatusOK) {
    //     handleGetLastestUsedForm();
    //     handleShowSuccessMessage();
    //     dispatch(DeleteQuestionnaireActionCreators.handleResetAction());
    //   }
    // }
  }, [
    duplicateQuestionResponse,
    doneQuestionResponse,
    // deleteQuestionnairesData,
  ]);

  useEffect(() => {
    if (tab === 1) {
      handleGetLastestUsedForm();
    } else {
      handleGetEndOfAnswer();
    }
  }, [tab, input.page, input.limit]);

  useEffect(() => {
    if (
      questionnairesResponse &&
      Object.keys(questionnairesResponse.result).length
    ) {
      const {
        total,
        total_draft,
        total_inprogress,
        total_end_of_response,
        total_record,
        total_before_answer,
        questionnaires,
      } = questionnairesResponse.result;

      setQuestionnaires({
        total,
        totalDraft: total_draft,
        totalInprogress: total_inprogress,
        totalEndOfResponse: total_end_of_response,
        totalRecord: total_record,
        totalBeforeAnswer: total_before_answer,
        data: questionnaires,
      });
    }
  }, [questionnairesResponse]);

  return (
    <React.Fragment>
      <Title>{t("questions-list")}</Title>
      <WrapperTabs>
        <SubTitle>
          {t("number-of-creations")}：{questionnaires.total}
          {t("piece")} / {t("draft")}：{questionnaires.totalDraft}
          {t("piece")} / {t("before-answer")}：
          {questionnaires.totalBeforeAnswer}
          {t("piece")} / {t("in-progress")}：{questionnaires.totalInprogress}
          {t("piece")} / {t("end-of-answer")}：
          {questionnaires.totalEndOfResponse}
          {t("piece")}
        </SubTitle>
        <WrapperTab>
          {Tabs.map((tabItem) => (
            <ItemTab key={tabItem.key}>
              <div
                className={tab === tabItem.key ? "tab-active" : ""}
                onClick={() => {
                  setTab(tabItem.key);
                }}
              >
                {tabItem.name}
              </div>
            </ItemTab>
          ))}
        </WrapperTab>
      </WrapperTabs>

      {tab === 1 ? (
        <RecentlyUsedForms
          setScreen={setScreen}
          setStatus={setStatus}
          data={questionnaires.data}
        />
      ) : (
        <QuestionList
          setScreen={setScreen}
          setStatus={setStatus}
          data={questionnaires.data}
          total={questionnaires?.totalRecord}
          setInput={setInput}
          input={input}
        />
      )}
    </React.Fragment>
  );
};

const Questions: React.FC = () => {
  const dispatch = useDispatch();

  const createQuestionnairesData = useSelector(
    createQuestionnairesDataResponse
  );

  const [screen, setScreen] = useState<ScreenState>("");
  const [status, setStatus] = useState("");
  const [id, setId] = useState<any>();

  useEffect(() => {
    if (screen !== "transmission") {
      dispatch(QuestionnairesDetailActionCreators.handleResetAction());
    }
    dispatch(CreateQuestionnairesActionCreators.handleResetAction());
    setId(undefined);
  }, [screen]);

  useEffect(() => {
    if (
      createQuestionnairesData &&
      createQuestionnairesData.result &&
      createQuestionnairesData.result.questionnaire_id
    ) {
      setId(createQuestionnairesData.result.questionnaire_id);
    }
  }, [createQuestionnairesData]);

  const renderScreen = () => {
    switch (screen) {
      case "create":
      case "update":
        return (
          <CreateQuestion
            setScreen={setScreen}
            setStatus={setStatus}
            status={status}
            setId={setId}
            id={id}
          />
        );
      case "transmission":
        return <QuestionsTransmission setScreen={setScreen} id={id} />;
      default:
        return <SurveyList setScreen={setScreen} setStatus={setStatus} />;
    }
  };

  return <React.Fragment>{renderScreen()}</React.Fragment>;
};

export default Questions;
