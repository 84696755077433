import { put, takeLatest, fork, call } from "redux-saga/effects";

import { httpStatus } from "configs/httpStatus";
import { updateChartOrder } from "services/setting";
import {
  ActionType,
  IUpdateChartOrderPayload,
  IUpdateChartOrderResponse,
} from "models/setting/updateChartOrder";

import {
  handleSuccessAction,
  handleErrorAction,
} from "./updateChartOrderActions";

function* updateChartOrderSaga({
  payload,
}: {
  payload: IUpdateChartOrderPayload;
}) {
  try {
    const response: IUpdateChartOrderResponse = yield call(
      updateChartOrder,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccessAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onUpdateChartOrderWatcher() {
  yield takeLatest(ActionType.UPDATE_CHART_ORDER as any, updateChartOrderSaga);
}

export default [fork(onUpdateChartOrderWatcher)];
