import {
  Action,
  ActionType,
  IGetSettingDataResponse,
} from "models/setting/settingData";
import createReducer from "../../createReducer";

export interface SettingDataReducerType {
  loading: boolean;
  error?: string;
  settingDataResponse?: IGetSettingDataResponse;
}

const defaultState: SettingDataReducerType = {
  loading: false,
  error: undefined,
  settingDataResponse: undefined,
};

export const settingDataReducer = createReducer<SettingDataReducerType>(
  defaultState,
  {
    [ActionType.GET_SETTING_DATA](state: SettingDataReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.GET_SETTING_DATA_ERROR](
      state: SettingDataReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.GET_SETTING_DATA_SUCCESS](
      state: SettingDataReducerType,
      action: Action<IGetSettingDataResponse>
    ) {
      return {
        ...state,
        loading: false,
        settingDataResponse: action.payload,
      };
    },

    [ActionType.GET_SETTING_DATA_RESET](state: SettingDataReducerType) {
      return {
        ...state,
        loading: false,
        settingDataResponse: undefined,
      };
    },
  }
);
