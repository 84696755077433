import React, { useState, useEffect, useRef } from "react";
import { Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { CloseIcon, DownloadIcon, NoDataIcon } from "icons";
import { ProgressBar, Loading } from "components";
import { numberWithCommas, generateImg } from "utils";
import { HealthCheckProgressActionCreators } from "redux/rootActions";
import { healthCheckProgressDataResponse } from "redux/dashboard/healthCheckProgress/healthCheckProgressStates";
import { selectIsLoading } from "redux/dashboard/progress/progressStates";

import {
  BoxHeader,
  BoxTitle,
  BoxContent,
  BoxItem,
  LightDivider,
  Top,
  Middle,
  Bottom,
  NotImplementText,
  NoHeatText,
  HighFeverText,
  WrapperLoadingChart,
  WrapperCloseIcon,
  WrapperNoData,
} from "../Dashboard.style";

const LoadingComponent = ({ isNoData }: { isNoData: boolean }) => {
  return !isNoData ? (
    <WrapperLoadingChart>
      <div className="container">
        <Loading width={70} height={70} />
      </div>
    </WrapperLoadingChart>
  ) : (
    <div>No Data</div>
  );
};

const HealthCheckProgress = ({ handleCloseChart }: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const { t: t1 } = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t2 } = useTranslation("translation", {
    keyPrefix: "dashboard",
  });

  const imgRef = useRef<any>();

  const dispatch = useDispatch();
  const healthCheckProgressData = useSelector(healthCheckProgressDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const [isNoData, setIsNoData] = useState(false);
  const [data, setData] = useState<any>({});

  const handleNumberPercentage = (num: number) => {
    if (!num || num === 100 || num.toString().length <= 2) {
      return `${num}.0`;
    }
    return num.toString().match(/^-?\d+(?:\.\d{0,1})?/);
  };

  useEffect(() => {
    setInterval(() => {
      setIsNoData(true);
    }, 15000);
  }, []);

  useEffect(() => {
    dispatch(
      HealthCheckProgressActionCreators.getHealthCheckProgressAction({
        aggregate_date: moment(new Date()).format("YYYY-MM-DD"),
      })
    );
  }, []);

  useEffect(() => {
    if (
      healthCheckProgressData &&
      Object.keys(healthCheckProgressData).length
    ) {
      setData(healthCheckProgressData.progress);
    }
  }, [healthCheckProgressData]);

  return (
    // <Col xs={24} md={24} xl={12} >
    <BoxItem ref={imgRef}>
      <WrapperCloseIcon>
        <CloseIcon
          fill="#2F8CAE"
          height="14px"
          width="14px"
          onClick={() => handleCloseChart("3")}
        />
      </WrapperCloseIcon>
      <BoxHeader>
        <BoxTitle>{t2("number-people-attend-physical-check")}</BoxTitle>
        <DownloadIcon
          fill="#3B3B3B"
          style={{ marginTop: 4, cursor: "pointer" }}
          onClick={(event: any) => {
            if (!event.detail || event.detail == 1) {
              generateImg("health-check-progress", imgRef.current);
            }
          }}
        />
      </BoxHeader>
      <LightDivider />
      {!isLoading ? (
        Object.keys(data).length > 0 ? (
          <BoxContent>
            <Top>
              <p>{t("all-day")}</p>
              <div>
                <ProgressBar
                  implementedTotal={numberWithCommas(
                    data?.attended_patients?.total?.amount
                  )}
                  total={numberWithCommas(data?.total_patients)}
                  completed={data?.attended_patients?.total?.rate}
                  bg="#8B2AC7"
                />
              </div>
              <p>
                {t("implementation-rate")}
                {handleNumberPercentage(data?.attended_patients?.total?.rate)}%
              </p>
            </Top>
            <Middle>
              <div>
                <span>
                  {t("morning")}
                  {numberWithCommas(data?.attended_patients?.am?.amount)}
                </span>
                /{numberWithCommas(data?.total_patients)}（
                {handleNumberPercentage(data?.attended_patients?.am?.rate)}%）
              </div>
              <div>
                <span>
                  {t("afternoon")}
                  {numberWithCommas(data?.attended_patients?.pm?.amount)}
                </span>
                /{numberWithCommas(data?.total_patients)}（
                {handleNumberPercentage(data?.attended_patients?.pm?.rate)}%）
              </div>
            </Middle>
            <Bottom>
              <div>
                <NotImplementText>{t("not-attempt")}</NotImplementText>
                <NotImplementText>
                  {numberWithCommas(data?.health_checks?.not_answered?.amount)}
                  {t("people")}
                </NotImplementText>
                <span>
                  (
                  {handleNumberPercentage(
                    data?.health_checks?.not_answered?.rate
                  )}
                  %)
                </span>
              </div>
              <div>
                <NoHeatText>{t1("no-symptoms")}</NoHeatText>
                <NoHeatText>
                  {numberWithCommas(data?.health_checks?.good?.amount)}
                  {t("people")}
                </NoHeatText>
                <span>
                  ({handleNumberPercentage(data?.health_checks?.good?.rate)}%)
                </span>
              </div>
              <div>
                <HighFeverText>{t1("with-symptoms")}</HighFeverText>
                <HighFeverText>
                  {numberWithCommas(data?.health_checks?.bad?.amount)}
                  {t("people")}
                </HighFeverText>
                <span>
                  ({handleNumberPercentage(data?.health_checks?.bad?.rate)}
                  %)
                </span>
              </div>
            </Bottom>
          </BoxContent>
        ) : (
          <WrapperNoData>
            <NoDataIcon width={140} height={140} />
          </WrapperNoData>
        )
      ) : (
        <LoadingComponent isNoData={isNoData} />
      )}
    </BoxItem>
    // </Col>
  );
};

export default HealthCheckProgress;
