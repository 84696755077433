import {
  ActionType,
  Action,
  DeleteQuestionnairesResponse,
} from "models/questionnaires/deleteQuestionnaire";
import createReducer from "../../createReducer";

export interface DeleteQuestionnairesReducerType {
  loading: boolean;
  error?: string;
  deleteQuestionnairesResponse?: DeleteQuestionnairesResponse;
}

const defaultState: DeleteQuestionnairesReducerType = {
  loading: false,
  error: undefined,
  deleteQuestionnairesResponse: undefined,
};

export const deleteQuestionnairesReducer =
  createReducer<DeleteQuestionnairesReducerType>(defaultState, {
    [ActionType.DELETE_QUESTIONNAIRES](state: DeleteQuestionnairesReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DELETE_QUESTIONNAIRES_ERROR](
      state: DeleteQuestionnairesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        deleteQuestionnairesResponse: undefined,
      };
    },

    [ActionType.DELETE_QUESTIONNAIRES_SUCCESS](
      state: DeleteQuestionnairesReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteQuestionnairesResponse: action.payload,
      };
    },

    [ActionType.DELETE_QUESTIONNAIRES_RESET](
      state: DeleteQuestionnairesReducerType
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        deleteQuestionnairesResponse: undefined,
      };
    },
  });
