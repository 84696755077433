import { IResponse } from "models";

export enum ActionType {
  DATA_DESTINATION = "action/DATA_DESTINATION",
  DATA_DESTINATION_SUCCESS = "action/DATA_DESTINATION_SUCCESS",
  DATA_DESTINATION_ERROR = "action/DATA_DESTINATION_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DataDestinationResponse extends IResponse {
  result: any;
}
