import {
  Action,
  ActionType,
  UpdateScheduleResponse,
} from "models/vaccine/schedules";
import createReducer from "../../createReducer";

export interface UpdateScheduleReducerType {
  loading: boolean;
  error?: any;
  saved: boolean;
  updateScheduleResponse?: UpdateScheduleResponse;
}

const defaultState: UpdateScheduleReducerType = {
  loading: false,
  error: null,
  saved: false,
  updateScheduleResponse: undefined,
};

export const updateScheduleReducer = createReducer<UpdateScheduleReducerType>(
  defaultState,
  {
    [ActionType.UPDATE_SCHEDULE](state: UpdateScheduleReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.UPDATE_SCHEDULE_ERROR](
      state: UpdateScheduleReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      };
    },

    [ActionType.UPDATE_SCHEDULE_SUCCESS](
      state: UpdateScheduleReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: true,
        updateScheduleResponse: action.payload,
      };
    },

    [ActionType.UPDATE_SCHEDULE_RESET](state: UpdateScheduleReducerType) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        updateVenuesResponse: undefined,
      };
    },
  }
);
