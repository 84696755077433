import { IQuestionnairesPayload } from "models/questionnaires/questionnairesByType";
import { ICreateQuestionnairesPayload } from "models/questionnaires/createQuestionnaires";
import { IQuestionnaireResponseDetailPayload } from "models/questionnaires/questionnaireResponseDetail";
import { IDuplicateQuestionPayload } from "models/questionnaires/duplicateQuestion";
import { ISendQuestionnaireData } from "models/questionnaires/sendQuestionnaires";
import { IUpdateQuestionnairesPayload } from "models/questionnaires/updateQuestionnaires";
import { IDeleteQuestionnairesPayload } from "models/questionnaires/deleteQuestionnaire";
import { IQuestionHistoryData } from "models/questionnaires/sendQuestionHistory";
import { IDoneQuestionPayload } from "models/questionnaires/doneQuestion";
import { IQuestionHistoryDetailData } from "models/questionnaires/questionHistoryDetail";
import { IAnswerHistoryData } from "models/questionnaires/answerHistory";
import { IAnswerInfoPayload } from "models/questionnaires/answerInfo";
import { IIndividualAnswerPayload } from "models/questionnaires/individualAnswer";
import { IQuestionDownloadData } from "models/questionnaires/downloadCSVQuestion";
import { IAllQuestionDownloadData } from "models/questionnaires/downloadCSVAllQuestions";
import { IRemindPushNotificationPayload } from "models/questionnaires/remindPushNotification";
import { ICancelQuestionnairePayload } from "models/questionnaires/cancelQuestionnaire";

import { createApiCall2, MethodType, URI } from "../Api";

export const fetchQuestionnaries = (payload: IQuestionnairesPayload) => {
  return createApiCall2(MethodType.GET, URI.QUESTION.QUESTIONNAIRES, payload);
};

export const fetchQuestionnariesDetail = (payload: { id: number }) => {
  return createApiCall2(
    MethodType.GET,
    `${URI.QUESTION.QUESTIONNAIRES}/${payload.id}`
  );
};

export const createQuestionnaries = (payload: ICreateQuestionnairesPayload) => {
  return createApiCall2(MethodType.POST, URI.QUESTION.QUESTIONNAIRES, payload);
};

export const fetchAnswerInfo = (payload: IAnswerInfoPayload) => {
  return createApiCall2(MethodType.GET, URI.QUESTION.GET_ANSWER_INFO, payload);
};

export const fetchIndividualAnswer = (payload: IIndividualAnswerPayload) => {
  return createApiCall2(
    MethodType.GET,
    URI.QUESTION.GET_INDIVIDUAL_ANSWER,
    payload
  );
};

export const fetchQuestionnaireResponseDetail = (
  payload: IQuestionnaireResponseDetailPayload
) => {
  return createApiCall2(
    MethodType.GET,
    URI.QUESTION.GET_QUESTIONNAIRE_RESPONSE_DETAIL,
    payload
  );
};

export const duplicateQuestion = (payload: IDuplicateQuestionPayload) => {
  return createApiCall2(
    MethodType.POST,
    URI.QUESTION.DUPLICATE_QUESTION,
    payload
  );
};

export const doneQuestion = (payload: IDoneQuestionPayload) => {
  return createApiCall2(MethodType.POST, URI.QUESTION.DONE_QUESTION, payload);
};

export const sendQuestionnaire = (payload: ISendQuestionnaireData) => {
  return createApiCall2(
    MethodType.POST,
    URI.QUESTION.SEND_QUESTIONNAIRES,
    payload
  );
};

export const updateQuestionnaire = (payload: IUpdateQuestionnairesPayload) => {
  return createApiCall2(
    MethodType.PUT,
    `${URI.QUESTION.QUESTIONNAIRES}/${payload.id}`,
    payload.param
  );
};

export const deleteQuestionnaire = (payload: IDeleteQuestionnairesPayload) => {
  return createApiCall2(
    MethodType.DELETE,
    `${URI.QUESTION.QUESTIONNAIRES}/${payload.id}`
  );
};

export const fetchHistoryList = (payload: IQuestionHistoryData) => {
  return createApiCall2(MethodType.GET, URI.QUESTION.SEND_HISTORIES, payload);
};

export const fetchHistoryDetail = (payload: IQuestionHistoryDetailData) => {
  return createApiCall2(
    MethodType.GET,
    URI.QUESTION.SEND_HISTORY_DETAIL,
    payload
  );
};

export const fetchAnswerHistoryList = (payload: IAnswerHistoryData) => {
  return createApiCall2(MethodType.GET, URI.QUESTION.ANSWER_HISTORY, payload);
};

export const downloadCSVQuestion = (payload: IQuestionDownloadData) => {
  return createApiCall2(
    MethodType.GET,
    URI.QUESTION.DOWNLOAD_CSV_QUESTION,
    payload
  );
};

export const downloadCSVAllQuestions = (payload: IAllQuestionDownloadData) => {
  return createApiCall2(
    MethodType.GET,
    URI.QUESTION.DOWNLOAD_CSV_ALL_QUESTIONS,
    payload
  );
};

export const sendRemindPushNotification = (
  payload: IRemindPushNotificationPayload
) => {
  return createApiCall2(
    MethodType.POST,
    URI.QUESTION.REMIND_PUSH_NOTIFICATION,
    payload
  );
};

export const cancelQuestionnaire = (payload: ICancelQuestionnairePayload) => {
  return createApiCall2(
    MethodType.POST,
    URI.QUESTION.CANCEL_QUESTIONNAIRE,
    payload
  );
};
