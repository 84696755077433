import React, { useEffect, useState } from "react";
import { Row, Col, notification, Checkbox, Grid } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Input, Select, Modal, Button } from "components";
import { httpStatus } from "configs/httpStatus";
import { localStorageHelper, convertRawDataToOptions } from "utils";

import { IGroupListOption } from "models/activities";
import { SubDepartmentListData } from "models/subdepartmentList";
import { subDepartmentListDataResponse } from "redux/subdepartment/subdepartmentListStates";
import { UpdateUserData } from "models/updateuser";
import {
  UpdateUserActionCreators,
  SubDepartmentListActionCreators,
  DeleteUserActionCreators,
} from "redux/rootActions";
import {
  updateUserSuccessResponse,
  updateUserErrorMessage,
} from "redux/updateuser/updateuserStates";
import {
  deleteUserSuccessResponse,
  deleteUserErrorMessage,
} from "redux/deleteuser/deleteuserStates";

import {
  DeleteButton,
  WrapperBtnUpdatingForm,
  GroupBtn,
  Label,
  ImportantStyled,
  ActivityGroupLabelContainer,
  CompulsoryGroupLabel,
  SelectStyled,
  WapperActivity,
  CheckboxStyled,
  WapperError,
  WrapperBirthdayInput,
  BirthdayInput,
  DivActivity,
  ActivitiesStyle,
} from "./UserList.style";
import { IModal } from "./UserList.modal";
import ConfirmModal from "./ConfirmModal";
import SuccessModal from "./SuccessModal";
import FailureModal from "./FailureModal";

const { useBreakpoint } = Grid;

const schema = yup.object().shape({
  lastName: yup.string().trim().required("last-name-required"),
  firstName: yup.string().trim().required("first-name-required"),
  gender: yup.string().required("gender-required"),
  schoolYear: yup.string().required("school-year-required"),
  class: yup.string().required("class-required").nullable(),
  attendance: yup
    .number()
    .typeError("pls-enter-number")
    .nullable()
    .transform((value, originalValue) =>
      String(originalValue).trim() === "" ? null : value
    ),
  activities: yup
    .mixed()
    .test(
      "check-activities",
      "activities-required",
      (currentActivities: any, context: any) => {
        const { activities, compulsoryGroup } = context.options.context;

        if (!compulsoryGroup) {
          return true;
        }

        const groups = compulsoryGroup.split(",");
        if (
          !currentActivities ||
          !currentActivities.length ||
          currentActivities.length < groups.length
        ) {
          return false;
        }

        const activitiesMapper: any = {};
        for (const activity of activities) {
          if (activity.children.length) {
            activitiesMapper[activity.text as any] = activity.children.map(
              (c: any) => c.id
            );
          } else {
            activitiesMapper[activity.text as any] = [activity.id];
          }
        }
        for (const group of groups) {
          const children = activitiesMapper[group];
          const arrayIntersection = children.filter((value: any) =>
            currentActivities.includes(value)
          );
          if (!arrayIntersection.length) return false;
        }

        return true;
      }
    ),
});

const UpdatingModal = ({
  visible,
  handleCancel,
  handleSubmitOK,
  activityGroupOptions,
  genderOptions,
  departmentOptions,
  selectedUserItem,
}: IModal) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "user-list" });
  const currentLanguage = localStorageHelper.getItem("i18nextLng");

  const dispatch = useDispatch();
  const subdepartmentOptions = useSelector(subDepartmentListDataResponse);
  const updateUserSuccess = useSelector(updateUserSuccessResponse);
  const deleteUserSuccess = useSelector(deleteUserSuccessResponse);
  const deleteUserError = useSelector(deleteUserErrorMessage);

  const [isVisibleMessage, setIsVisibleMessage] = useState({
    type: "",
    status: false,
  });
  const [message, setMessage] = React.useState<any>("");
  const [activities, setActivities] = useState<IGroupListOption[]>();
  const [classOptions, setClassOptions] = useState<any>([]);
  const [birthdayError, setBirthdayError] = React.useState<string>("");
  const [isVisible, setIsVisible] = useState(false);
  const [activitySelectValue, setActivitySelectValue] = React.useState<any>([]);
  const [activityValue, setActivityValue] = React.useState<any>([]);
  const [groupNames, setGroupNames] = React.useState<any>([]);
  const [activityDatas, setActivityDatas] = React.useState<any>({});
  const userName = `${selectedUserItem?.last_name} ${selectedUserItem?.first_name}`;
  const { watch, handleSubmit, control, setValue }: any = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      lastName: selectedUserItem?.last_name,
      firstName: selectedUserItem?.first_name,
      lastNameFurigana: selectedUserItem?.furi_last_name,
      firstNameFurigana: selectedUserItem?.furi_first_name,
      gender: selectedUserItem?.gender,
      birthYear:
        selectedUserItem && selectedUserItem.date_of_birth
          ? Moment(selectedUserItem.date_of_birth).year().toString()
          : Moment(new Date()).year().toString(),
      birthMonth:
        selectedUserItem && selectedUserItem.date_of_birth
          ? Moment(selectedUserItem.date_of_birth).format("MM").toString()
          : Moment(new Date()).format("MM").toString(),
      birthDay:
        selectedUserItem && selectedUserItem.date_of_birth
          ? Moment(selectedUserItem.date_of_birth).format("DD").toString()
          : Moment(new Date()).format("DD").toString(),
      attendance: selectedUserItem?.roll_number,
      schoolYear: selectedUserItem?.department_id,
      class: selectedUserItem?.class_name
        ? selectedUserItem?.c_department_id
        : undefined,
      activities: selectedUserItem?.group_list?.map((group) => group.id),
    },
    resolver: yupResolver(schema),
    context: {
      compulsoryGroup: activityGroupOptions?.compulsory_group,
      activities,
    },
  });

  const screens = useBreakpoint();
  const watchSchoolYearField = watch("schoolYear");

  useEffect(() => {
    if (subdepartmentOptions?.result) {
      const classOptionClone = convertRawDataToOptions(
        subdepartmentOptions?.result
      );
      setClassOptions(classOptionClone);
    }
  }, [subdepartmentOptions]);

  useEffect(() => {
    setActivities(
      activityGroupOptions && activityGroupOptions?.group_list !== undefined
        ? activityGroupOptions?.group_list
        : []
    );
    if (selectedUserItem && selectedUserItem?.group_list) {
      setActivityValue(
        selectedUserItem?.group_list.map((item: any) => {
          return item.id;
        })
      );
      setActivitySelectValue(
        selectedUserItem?.group_list.map((item: any) => {
          return item.name;
        })
      );
    }
    if (activityGroupOptions && activityGroupOptions.group_list) {
      const datas = [];
      for (const activity of activityGroupOptions.group_list) {
        if (activity.children.length) {
          for (const item of activity.children) {
            datas.push(item);
          }
        } else {
          datas.push(activity);
        }
      }
      setActivityDatas(datas);
    }
    if (activityGroupOptions?.compulsory_group) {
      setGroupNames(activityGroupOptions?.compulsory_group.split(","));
    }
    setBirthdayError("");
  }, []);

  useEffect(() => {
    if (selectedUserItem && selectedUserItem.department_id !== undefined) {
      const subDepartmentparams: SubDepartmentListData = {
        locale: currentLanguage,
        department_id: selectedUserItem.department_id,
        role: localStorageHelper.getItem("role"),
      };
      dispatch(
        SubDepartmentListActionCreators.subDepartmentListAction(
          subDepartmentparams
        )
      );
    }
  }, [selectedUserItem?.department_id]);

  useEffect(() => {
    if (
      watchSchoolYearField &&
      watchSchoolYearField !== selectedUserItem?.department_id
    ) {
      const subDepartmentparams: SubDepartmentListData = {
        locale: currentLanguage,
        department_id: Number(watchSchoolYearField),
        role: localStorageHelper.getItem("role"),
      };
      dispatch(
        SubDepartmentListActionCreators.subDepartmentListAction(
          subDepartmentparams
        )
      );
      setValue("class", "");
    }
  }, [watchSchoolYearField]);

  const handleCheckbox = (value: any) => {
    if (value) {
      const names = [];
      for (const item of value) {
        for (const activity of activityDatas) {
          if (activity.id == item) names.push(activity.text);
        }
      }
      setActivitySelectValue(names);
      setActivityValue(value);
    }
  };

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    const updateUserParams: UpdateUserData = {
      user: {
        id: selectedUserItem?.id,
        first_name: data.firstName,
        last_name: data.lastName,
        furi_first_name: data.firstNameFurigana,
        furi_last_name: data.lastNameFurigana,
        gender: Number(data.gender),
        date_of_birth: selectedUserItem?.date_of_birth,
        dept_id: Number(data.schoolYear),
        sub_dept_id: Number(data.class),
        roll_number: data.attendance || "",
        company_sub_department_id: activityValue,
      },
      locale: currentLanguage,
    };
    dispatch(UpdateUserActionCreators.createUserAction(updateUserParams));
    setBirthdayError("");
  };

  useEffect(() => {
    if (updateUserSuccess && updateUserSuccess.status === httpStatus.StatusOK) {
      setMessage(updateUserSuccess.message);
      setIsVisibleMessage({ type: "success", status: true });
    }
    if (
      updateUserSuccess &&
      updateUserSuccess.status === httpStatus.StatusUnprocessableEntity
    ) {
      setMessage(updateUserSuccess.message);
      setIsVisibleMessage({ type: "", status: true });
    }
  }, [updateUserSuccess]);

  const onDelete = () => {
    if (selectedUserItem) {
      dispatch(
        DeleteUserActionCreators.deleteUserAction({ id: selectedUserItem.id })
      );
      if (deleteUserSuccess && deleteUserSuccess.message) {
        notification.success({
          placement: "bottomRight",
          message: deleteUserSuccess.message,
        });
      } else if (deleteUserError) {
        notification.error({
          placement: "bottomRight",
          message: deleteUserError,
        });
      }
      handleCancel();
    }
  };
  const renderMessageModal = () => {
    if (isVisibleMessage.type === "success") {
      return (
        <SuccessModal
          visible={isVisibleMessage.status}
          message={message}
          handleCancel={() => {
            dispatch(UpdateUserActionCreators.handleResetAction());
            handleCancel();
          }}
        />
      );
    }
    return (
      <FailureModal
        visible={isVisibleMessage.status}
        message={message}
        handleCancel={() => {
          dispatch(UpdateUserActionCreators.handleResetAction());
          handleCancel();
        }}
      />
    );
  };
  return (
    <>
      {isVisibleMessage.status ? (
        renderMessageModal()
      ) : (
        <Modal
          type="form"
          title={t("user-information-editing")}
          visible={visible}
          onCancel={handleCancel}
          onOk={handleSubmit(onSubmit)}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutter={16}>
              <Col xs={12} xl={12}>
                <Controller
                  control={control}
                  name="lastName"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      label={
                        <>
                          {t("last-name")}
                          <ImportantStyled>*</ImportantStyled>
                        </>
                      }
                      height={31}
                      fsLabel={16}
                      marginForm="0 0 22px 0"
                      onChange={onChange}
                      error={t(error?.message)}
                    />
                  )}
                />
              </Col>
              <Col xs={12} xl={12}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      label={
                        <>
                          {t("first-name")}
                          <ImportantStyled>*</ImportantStyled>
                        </>
                      }
                      height={31}
                      fsLabel={16}
                      marginForm="0 0 22px 0"
                      onChange={onChange}
                      error={t(error?.message)}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={12} xl={12}>
                <Controller
                  control={control}
                  name="lastNameFurigana"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      label={t("last-name-furigana")}
                      height={31}
                      fsLabel={16}
                      marginForm="0 0 22px 0"
                      onChange={onChange}
                      error={t(error?.message)}
                    />
                  )}
                />
              </Col>
              <Col xs={12} xl={12}>
                <Controller
                  control={control}
                  name="firstNameFurigana"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value}
                      label={t("first-name-furigana")}
                      height={31}
                      fsLabel={16}
                      marginForm="0 0 22px 0"
                      onChange={onChange}
                      error={t(error?.message)}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={12} md={12} xl={12}>
                <Controller
                  control={control}
                  name="gender"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      value={value}
                      label={
                        <>
                          {t("gender")}
                          <ImportantStyled>*</ImportantStyled>
                        </>
                      }
                      placeholder={t("please-select")}
                      options={genderOptions}
                      fsLabel={16}
                      marginForm="0 0 22px 0"
                      onChange={onChange}
                      error={t(error?.message)}
                    />
                  )}
                />
              </Col>
              {!screens.sm && (
                <Col xs={12} md={12} xl={12}>
                  <Controller
                    control={control}
                    name="attendance"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        label={t("attendance")}
                        height={31}
                        fsLabel={16}
                        marginForm="0 0 22px 6px"
                        onChange={onChange}
                        error={t(error?.message)}
                        placeholder={"1"}
                      />
                    )}
                  />
                </Col>
              )}
              {/* <Col xs={24} md={8} xl={8}>
                <WrapperBirthdayInput>
                  <p>{t("birthday")}</p>
                  <BirthdayInput className="enter-year">
                    <Controller
                      control={control}
                      name="birthYear"
                      render={({ field: { onChange, value } }) => (
                        <Input
                          value={value}
                          height={31}
                          fsLabel={16}
                          onChange={onChange}
                          fs={16}
                          marginForm="0 0 22px 0"
                          placeholder={"2000"}
                          maxLength="4"
                        />
                      )}
                    />
                    <span>{t("birth-year")}</span>
                  </BirthdayInput>
                  <BirthdayInput>
                    <Controller
                      control={control}
                      name="birthMonth"
                      render={({ field: { onChange, value } }) => (
                        <Input
                          value={value}
                          height={31}
                          fsLabel={16}
                          onChange={onChange}
                          fs={16}
                          marginForm="0 0 22px 0"
                          placeholder={"01"}
                          maxLength="2"
                        />
                      )}
                    />
                    <span>{t("birth-month")}</span>
                  </BirthdayInput>
                  <BirthdayInput>
                    <Controller
                      control={control}
                      name="birthDay"
                      render={({ field: { onChange, value } }) => (
                        <Input
                          value={value}
                          height={31}
                          fsLabel={16}
                          onChange={onChange}
                          fs={16}
                          marginForm="0 0 22px 0"
                          placeholder={"01"}
                          maxLength="2"
                        />
                      )}
                    />
                    <span>{t("birth-day")}</span>
                  </BirthdayInput>
                  {birthdayError && (
                    <div className="birthday-error">{t(birthdayError)}</div>
                  )}
                </WrapperBirthdayInput>
              </Col> */}
              {screens.sm && (
                <Col xs={12} md={12} xl={12}>
                  <Controller
                    control={control}
                    name="attendance"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        label={t("attendance")}
                        height={31}
                        fsLabel={16}
                        marginForm="0 0 22px 0"
                        onChange={onChange}
                        error={t(error?.message)}
                      />
                    )}
                  />
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12} xl={12}>
                <Controller
                  control={control}
                  name="schoolYear"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      value={value}
                      label={
                        <>
                          {t("school-year")}
                          <ImportantStyled>*</ImportantStyled>
                        </>
                      }
                      placeholder={t("please-select")}
                      options={departmentOptions}
                      fsLabel={16}
                      marginForm="0 0 22px 0"
                      onChange={onChange}
                      error={t(error?.message)}
                    />
                  )}
                />
              </Col>
              <Col xs={24} md={12} xl={12}>
                <Controller
                  control={control}
                  name="class"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Select
                      value={value}
                      label={
                        <>
                          {t("class")}
                          <ImportantStyled>*</ImportantStyled>
                        </>
                      }
                      placeholder={t("please-select")}
                      options={classOptions}
                      fsLabel={16}
                      disabled={
                        selectedUserItem?.department_id !== undefined
                          ? false
                          : true
                      }
                      marginForm="0 0 22px 0"
                      onChange={onChange}
                      error={t(error?.message)}
                    />
                  )}
                />
              </Col>
            </Row>

            <ActivityGroupLabelContainer>
              <Label>
                {t("activities")}
                {activityGroupOptions?.compulsory_group && (
                  <ImportantStyled>*</ImportantStyled>
                )}
              </Label>
              {activityGroupOptions?.compulsory_group ? (
                <CompulsoryGroupLabel>
                  <Trans i18nKey="user-list.select-group-required">
                    <strong>
                      「
                      {{
                        requiredGroup: activityGroupOptions?.compulsory_group,
                      }}
                      」
                    </strong>{" "}
                    の選択は必須です
                  </Trans>
                </CompulsoryGroupLabel>
              ) : (
                ""
              )}
            </ActivityGroupLabelContainer>
            <Controller
              control={control}
              name="activities"
              render={({ field: { onChange }, fieldState: { error } }) => (
                <div style={{ position: "relative" }}>
                  <SelectStyled
                    value={activitySelectValue}
                    mode="multiple"
                    marginForm="0 0 24px"
                    fs={14}
                    fsLabel={16}
                    height={"62px"}
                    placeholder={t("click-to-select-activities")}
                    showSearch={false}
                    dropdownRender={() => (
                      <Checkbox.Group
                        value={activityValue || []}
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          onChange(value);
                          handleCheckbox(value);
                        }}
                      >
                        <ActivitiesStyle>
                          <DivActivity>
                            {activities &&
                              activities.length > 0 &&
                              activities.map((item: any, index: number) => {
                                return (
                                  <WapperActivity key={index}>
                                    {item.children.length ? (
                                      <p>
                                        {item.text}
                                        {groupNames.indexOf(item.text) > -1 && (
                                          <ImportantStyled>*</ImportantStyled>
                                        )}
                                      </p>
                                    ) : (
                                      <CheckboxStyled value={item.id}>
                                        <p>
                                          {item.text}
                                          {groupNames.indexOf(item.text) >
                                            -1 && (
                                            <ImportantStyled>*</ImportantStyled>
                                          )}
                                        </p>
                                      </CheckboxStyled>
                                    )}

                                    {item.children.map(
                                      (a: any, index1: number) => {
                                        return (
                                          <div
                                            style={{
                                              marginBottom:
                                                index1 <
                                                item.children.length - 1
                                                  ? "6px"
                                                  : "0px",
                                            }}
                                            key={a.id}
                                          >
                                            <CheckboxStyled value={a.id}>
                                              {a.text}
                                            </CheckboxStyled>
                                          </div>
                                        );
                                      }
                                    )}
                                  </WapperActivity>
                                );
                              })}
                          </DivActivity>
                        </ActivitiesStyle>
                      </Checkbox.Group>
                    )}
                  />
                  {error?.message ? (
                    <WapperError>
                      <p>
                        <CompulsoryGroupLabel
                          style={{
                            marginBottom: 0,
                            marginLeft: 0,
                            lineHeight: "16px",
                          }}
                        >
                          <Trans i18nKey="user-list.activities-required">
                            <strong>
                              「
                              {{
                                requiredGroup:
                                  activityGroupOptions?.compulsory_group,
                              }}
                              」
                            </strong>{" "}
                            を選択してください
                          </Trans>
                        </CompulsoryGroupLabel>
                      </p>
                    </WapperError>
                  ) : undefined}
                </div>
              )}
            />
            <WrapperBtnUpdatingForm>
              <GroupBtn>
                <Button
                  type="reset"
                  color="#FFFFFF"
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="16px"
                  background="#E0E0E0"
                  padding="11px 24px 11px"
                  name={t("cancel")}
                  border="none"
                  onClick={handleCancel}
                />
                <Button
                  type="submit"
                  color="#FFFFFF"
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="16px"
                  background="#2AC769"
                  padding="11px 24px 11px"
                  name={t("save")}
                  border="none"
                  onClick={() => handleSubmitOK}
                />
              </GroupBtn>
              <DeleteButton
                onClick={() => {
                  setIsVisible(true);
                }}
              >
                {t("delete-user")}
              </DeleteButton>
              {isVisible && (
                <ConfirmModal
                  isVisible={isVisible}
                  handleOk={onDelete}
                  handleCancel={() => {
                    setIsVisible(false);
                  }}
                >
                  <Trans i18nKey="user-list.delete-user-description">
                    「{{ userName }}」を削除します。
                  </Trans>
                  <div>{t("are-you-sure")}</div>
                </ConfirmModal>
              )}
            </WrapperBtnUpdatingForm>
          </form>
        </Modal>
      )}
    </>
  );
};

export default UpdatingModal;
