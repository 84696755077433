import * as React from "react";

function RedAttentionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.86">
        <path
          d="M19.4787 24.2006H22.5213L23.3267 13.972L23.4758 9.51562H18.5241L18.6733 13.972L19.4787 24.2006ZM21 32.1562C22.4915 32.1562 23.625 31.0795 23.625 29.5243C23.625 27.9392 22.4915 26.8625 21 26.8625C19.5085 26.8625 18.375 27.9392 18.375 29.5243C18.375 31.0795 19.4787 32.1562 21 32.1562Z"
          fill="#FB2121"
        />
        <circle cx="21" cy="21" r="19.5" stroke="#FB2121" strokeWidth="3" />
      </g>
    </svg>
  );
}

export default RedAttentionIcon;
