import { IResponse } from "models";
import {
  ActionType,
  ISaveTemporaryClassChangeRequest,
} from "models/classchangemanagement/saveTemporary";

export const saveTemporaryStudentClassChangeAction = (
  payload: ISaveTemporaryClassChangeRequest
) => {
  return {
    type: ActionType.SAVE_TEMPORARY_CLASS_CHANGE,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.SAVE_TEMPORARY_CLASS_CHANGE_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.SAVE_TEMPORARY_CLASS_CHANGE_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.SAVE_TEMPORARY_CLASS_CHANGE_RESET,
  };
};
