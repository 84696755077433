import styled from "styled-components";

import { theme } from "theme/theme";
import { Table, Button, Modal, Select } from "components";
import { Checkbox } from "antd";

export const TableStyled = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 17px 16px !important;
    border-bottom: none;
    max-height: 71px;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    padding: 17px 16px !important;
    border-bottom: none;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    padding-left: 24px;
  }
  /* .ant-table-thead > tr > th:nth-child(5) {
    &:before {
      width: 0px !important;
    }
  } */
  .ant-table-thead > tr > th:not(:nth-child(5)) {
    &:before {
      width: 2px !important;
    }
  }
  .ant-table-thead > tr > th:first-child {
    /* position: relative; */
    &:before {
      background: #fbfbfb !important;
    }
    &:after {
      content: "";
      width: 2px;
      background-color: #f2f2f2;
      height: 39px;
      position: absolute;
      top: 16px;
      right: 1px;
    }
  }
  .ant-table-thead > tr > th {
    &:before {
      height: 39px !important;
    }
  }
  /* .ant-table-thead > tr > th:nth-child(7), */
  .ant-table-thead > tr > th:nth-child(7) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-tbody > tr > td {
    vertical-align: top;
    padding: 9px 16px;
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: left;
  }
  .ant-table-tbody > tr > td:first-child {
    border-right: none;
  }
  .ant-table-tbody > tr.line-disabled {
    opacity: 0.5;
  }
`;

export const CounselorTableStyled = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 17px 16px !important;
    border-bottom: none;
    max-height: 71px;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    padding: 17px 16px !important;
    border-bottom: none;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    padding-left: 24px;
  }
  /* .ant-table-thead > tr > th:nth-child(5) {
    &:before {
      width: 0px !important;
    }
  } */
  .ant-table-thead > tr > th:not(:nth-child(5)) {
    &:before {
      width: 2px !important;
    }
  }
  .ant-table-thead > tr > th:first-child {
    /* position: relative; */
    &:before {
      background: #fbfbfb !important;
    }
    &:after {
      content: "";
      width: 2px;
      background-color: #f2f2f2;
      height: 39px;
      position: absolute;
      top: 16px;
      right: 1px;
    }
  }
  .ant-table-thead > tr > th {
    &:before {
      height: 39px !important;
    }
  }
  /* .ant-table-thead > tr > th:nth-child(7), */
  .ant-table-thead > tr > th:nth-child(7) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-tbody > tr > td {
    vertical-align: top;
    padding: 9px 16px;
  }
  .ant-table-tbody > tr > td:last-child {
    text-align: left;
  }
  .ant-table-tbody > tr > td:first-child {
    border-right: none;
  }
  .ant-table-tbody > tr.line-disabled {
    opacity: 0.5;
  }
`;

export const Text = styled.div(({ theme }) => ({
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.regular,
  color: theme.colors.titleText,
  marginLeft: 17,
}));

export const ContentWrapper = styled.div<{
  showMargin: boolean;
  shrink: boolean;
}>`
  margin-bottom: ${(props) => (!props.showMargin ? "24px" : "0px")};
  .ant-table-container .ant-table-content {
    .ant-table-tbody > tr > td:nth-child(1) {
      width: 17.8%;
    }
    .ant-table-tbody > tr > td:nth-child(2) {
      /* min-width: 164px; */
      /* width: calc(23.2% - 2px); */
      width: 7.3%;
    }
    .ant-table-tbody > tr > td:nth-child(3) {
      width: 5.6%;
      min-width: 64px;
      max-width: 64px;
      text-align: right;
    }
    .ant-table-tbody > tr > td:nth-child(4) {
      width: ${(props) => (props.shrink ? "10%" : "38%")};
    }
    .ant-table-tbody > tr > td:nth-child(5) {
      width: 10%;
    }
    .ant-table-tbody > tr > td:nth-child(6) {
      width: ${(props) => (props.shrink ? "49.3%" : "21.3%")};
      /* width: 21.7%; */
    }
    .ant-table-thead > tr > th:nth-child(1) {
      width: 17.8%;
    }
    .ant-table-thead > tr > th:nth-child(2) {
      /* min-width: 164px; */
      width: 7.3%;
    }
    .ant-table-thead > tr > th:nth-child(3) {
      width: 5.6%;
      min-width: 64px;
      max-width: 64px;
    }
    .ant-table-thead > tr > th:nth-child(4) {
      width: ${(props) => (props.shrink ? "10%" : "38%")};
    }
    .ant-table-thead > tr > th:nth-child(5) {
      width: 10%;
    }
    .ant-table-thead > tr > th:nth-child(6) {
      width: ${(props) => (props.shrink ? "49.3%" : "21.3%")};
      /* width: 21.7%; */
    }
  }
`;

export const ContentWrapper2 = styled.div<{ showMargin: boolean }>`
  margin-bottom: ${(props) => (!props.showMargin ? "24px" : "0px")};
`;

export const PaginationWrapper = styled.div`
  text-align: center;
  ${theme.media.tablet} {
    display: flex;
    justify-content: end;
    text-align: unset;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  align-items: flex-start;
  outline: none;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${theme.colors.border};
    }
  }
  .ant-checkbox {
    top: unset;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 2px solid ${theme.colors.border};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.colors.button};
    border-color: ${theme.colors.button}!important;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.colors.border};
  }

  div {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
`;

export const CheckboxLabelGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  .label {
    color: #3b3b3b;
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
  }
`;

export const PaginationGroup = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-bottom: 16px;
  ${theme.media.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
`;

export const FlexNameWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  ${theme.media.tablet} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }
  > div:first-child {
    flex: 1;
  }
  button {
    height: 20px;
    min-width: max-content;
    letter-spacing: -1px;
    font-weight: 400;
    span {
      margin-right: 3px !important;
    }
    svg {
      height: 10px;
      width: 12px;
      position: relative;
      top: 1px;
    }
  }
`;

export const ConfirmModalStyled = styled(Modal)`
  width: 343px !important;
  top: calc(50% - 69px);
  .ant-modal-content {
    width: 343px !important;
  }
  .ant-modal-body {
    padding: 32px 0px 24px;
    text-align: center;
  }
  .ant-modal-footer {
    padding-top: 0;
    padding-bottom: 24px;
    border: unset;
  }
  ${theme.media.tablet} {
    width: 390px !important;
    .ant-modal-content {
      width: 390px !important;
    }
    .ant-modal-body {
      padding: 32px 24px 40px 24px;
    }
    .ant-modal-footer {
      padding-bottom: 16px;
    }
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: center;
  button {
    width: calc(50% - 8px);
    padding: 4px 0px 3px;
  }
  button:first-child {
    margin-right: 16px;
  }
  ${theme.media.tablet} {
    justify-content: center;
    button {
      width: 112px;
    }
  }
`;

export const TextModalConfirm = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #3b3b3b;
  span {
    font-weight: 700;
  }
`;

export const EnterPasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  button {
    &:focus {
      border: 1px solid rgb(42, 199, 105);
    }
  }
`;
