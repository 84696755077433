import { IResponse } from "models";

export enum ActionType {
  // get student list
  DOWNLOAD_QR_CODE = "action/DOWNLOAD_QR_CODE",
  DOWNLOAD_QR_CODE_SUCCESS = "action/DOWNLOAD_QR_CODE_SUCCESS",
  DOWNLOAD_QR_CODE_ERROR = "action/DOWNLOAD_QR_CODE_ERROR",
  DOWNLOAD_QR_CODE_RESET_DATA = "action/DOWNLOAD_QR_CODE_RESET_DATA",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface DownloadQrCodeData {
  number_of_qrcodes: number;
}

export interface DownloadQrCodeResponse extends IResponse {
  result: DownloadQrCodeResult;
}

export interface DownloadQrCodeResult {
  image_1: string;
  image_2: string;
  qrcodes: QrCode[];
  company_name: string;
  expire_time: string;
}

export interface QrCode {
  qr_code_img: string;
  qr_code: string;
}
