import * as React from "react";

function EditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.16669 14H6.70002C6.80969 14.0007 6.91841 13.9796 7.01994 13.9382C7.12147 13.8967 7.21382 13.8356 7.29169 13.7584L13.0584 7.98336L15.425 5.6667C15.5031 5.58923 15.5651 5.49706 15.6074 5.39551C15.6497 5.29396 15.6715 5.18504 15.6715 5.07503C15.6715 4.96502 15.6497 4.8561 15.6074 4.75455C15.5651 4.653 15.5031 4.56083 15.425 4.48336L11.8917 0.908364C11.8142 0.830257 11.7221 0.768262 11.6205 0.725954C11.519 0.683647 11.41 0.661865 11.3 0.661865C11.19 0.661865 11.0811 0.683647 10.9795 0.725954C10.878 0.768262 10.7858 0.830257 10.7084 0.908364L8.35835 3.2667L2.57502 9.0417C2.49779 9.11956 2.43668 9.21191 2.39521 9.31344C2.35374 9.41497 2.33272 9.52369 2.33335 9.63336V13.1667C2.33335 13.3877 2.42115 13.5997 2.57743 13.756C2.73371 13.9122 2.94567 14 3.16669 14ZM11.3 2.67503L13.6584 5.03336L12.475 6.2167L10.1167 3.85836L11.3 2.67503ZM4.00002 9.97503L8.94169 5.03336L11.3 7.3917L6.35835 12.3334H4.00002V9.97503ZM16.5 15.6667H1.50002C1.27901 15.6667 1.06704 15.7545 0.910765 15.9108C0.754484 16.0671 0.666687 16.279 0.666687 16.5C0.666687 16.721 0.754484 16.933 0.910765 17.0893C1.06704 17.2456 1.27901 17.3334 1.50002 17.3334H16.5C16.721 17.3334 16.933 17.2456 17.0893 17.0893C17.2456 16.933 17.3334 16.721 17.3334 16.5C17.3334 16.279 17.2456 16.0671 17.0893 15.9108C16.933 15.7545 16.721 15.6667 16.5 15.6667Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default EditIcon;
