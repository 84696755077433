import { Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { settingDataResponse } from "redux/setting/settingData/settingDataStates";
import styled from "styled-components";
import { theme } from "theme/theme";

interface IBox {
  title?: string;
  icon?: React.ReactNode;
  subTitle?: string;
  padding?: string;
  children?: React.ReactNode;
  action?: React.ReactNode;
  page?: string;
  setPage?: any;
}

const Wrapper = styled.div``;

const Title = styled.h1(({ theme }) => ({
  color: theme.colors.text.primary,
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.sizes.md,
  lineHeight: "23px",
  marginBottom: 24,
  marginRight: 4,
}));

const Container = styled.div(({ theme }) => ({
  background: theme.colors.white,
  padding: "35px 16px 46px",
  borderRadius: 8,
}));

const WrapperTitle = styled.div`
  position: relative;
`;

const WrapperPage = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

export const Page = styled.div<{ active?: boolean }>`
  background: ${(props) =>
    props.active ? theme.colors.textLink : theme.colors.white};
  padding: 3px 16px 5px;
  color: ${(props) =>
    props.active ? theme.colors.white : theme.colors.titleText};
  font-family: Noto Sans JP;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.27px;
  border-radius: 14px;
  cursor: pointer;
`;

const Box: React.FC<IBox> = ({ title, children, page, setPage }) => {
  const { t }: any = useTranslation("translation", { keyPrefix: "setting" });
  const settingData = useSelector(settingDataResponse);

  const [isShowConsultation, setIsShowConsultation] = useState(false);

  useEffect(() => {
    if (settingData && Object.keys(settingData.result).length) {
      const { temperature_managerment_info } = settingData?.result;

      setIsShowConsultation(
        temperature_managerment_info.is_consultation_request
      );
    }
  }, [settingData]);

  return (
    <Wrapper>
      <WrapperTitle>
        <Row>{title && <Title>{title}</Title>}</Row>
      </WrapperTitle>

      <WrapperPage>
        <Page active={page === "general"} onClick={() => setPage("general")}>
          {t("general")}
        </Page>
        <Page
          active={page === "temperature"}
          onClick={() => setPage("temperature")}
        >
          {t("health-observation-setting")}
        </Page>
        {isShowConsultation && (
          <Page
            active={page === "consultation"}
            onClick={() => setPage("consultation")}
          >
            {t("consult-with-school-teacher")}
          </Page>
        )}
      </WrapperPage>

      <Container>{children}</Container>
    </Wrapper>
  );
};

export default Box;
