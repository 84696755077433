import React, { useEffect, useRef, useState } from "react";
import { Col } from "antd";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
} from "recharts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  CloseIcon,
  DownloadIcon,
  HeartSpeakIcon,
  NextIcon,
  NoDataIcon,
} from "icons";
import { convertRawDataToOptions, generateImg } from "utils";
import { Loading, Select } from "components";

import {
  AbsenceActionCreators,
  ClassListFilterActionCreators,
} from "redux/rootActions";
import {
  absenceDataResponse,
  selectIsLoading,
} from "redux/dashboard/absence/absenceStates";
import { classListFilterDataResponse } from "redux/messagemanagement/classfilterlist/classFilterListStates";

import {
  BoxItem,
  WrapperCloseIcon,
  BoxHeader,
  BoxTitle,
  LightDivider,
  BoxContent,
  WrapperChart,
  ContainerChart,
  WrapperLegendLineChart,
  PinkDot,
  YellowDot2,
  BlueDot2,
  CustomizedTooltip,
  WrapperLoadingChart,
  WrapperNoData,
} from "../Dashboard.style";
import { IChart } from "../BodyTemperatureResult";
import { IOption } from "components/select/Select";

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={0} dy={18} fill="#3B3B3B">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={3} fill="#3B3B3B" textAnchor="end">
        {payload.value}
      </text>
    </g>
  );
};

const lb: any = {
  ab: "abDetail",
  bh: "bhDetail",
  le: "leDetail",
};

let tooltip: any;
const CustomTooltip = ({ active, payload }: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });

  if (!active || !tooltip) return null;
  for (const bar of payload) {
    if (bar.dataKey === tooltip)
      return (
        <CustomizedTooltip>
          <p className="label">{bar.payload.date}</p>
          <div className="list">
            {bar.payload[lb[bar.dataKey]].map((i: any, id: number) => {
              return (
                <div className="tooltip-item-wrapper" key={id}>
                  <div className="item-left">
                    <span>{i.name}</span>
                  </div>
                  <div className="item-right">
                    <span>
                      {i.amount}
                      {t("people")}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </CustomizedTooltip>
      );
  }

  return null;
};

const BarChartCustom = ({ data }: any) => {
  return (
    <ContainerChart
      style={{
        marginTop: 10,
      }}
    >
      <ResponsiveContainer width="100%" height={178}>
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: -30,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="2 2" />
          <XAxis
            dataKey="date"
            tickLine={false}
            tick={<CustomizedXAxisTick />}
            stroke="#CCCCCC"
          />
          <YAxis
            type="number"
            interval={0}
            tickLine={false}
            tick={<CustomizedYAxisTick />}
            stroke="#CCCCCC"
          />
          <Bar
            dataKey="ab"
            stackId="a"
            fill="#f39393"
            onMouseOver={() => (tooltip = "ab")}
          />
          <Bar
            dataKey="bh"
            stackId="a"
            fill="#ebdb65"
            onMouseOver={() => (tooltip = "bh")}
          />
          <Bar
            dataKey="le"
            stackId="a"
            fill="#8ab7cd"
            onMouseOver={() => (tooltip = "le")}
          />
          <Tooltip content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
    </ContainerChart>
  );
};

const Absence: React.FC<IChart> = ({ handleCloseChart }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "dashboard",
  });

  const imgRef = useRef<any>();
  const dispatch = useDispatch();

  const absenceData = useSelector(absenceDataResponse);
  const classFilterListData = useSelector(classListFilterDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const [newClassOptions, setNewClassOptions] = useState<IOption[]>([]);
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const handleConvertDate = (date: string) => {
    return moment(new Date(date)).format("M/D");
  };

  useEffect(() => {
    dispatch(ClassListFilterActionCreators.classFilterListAction());
  }, []);

  useEffect(() => {
    if (
      classFilterListData &&
      Object.keys(classFilterListData?.result).length
    ) {
      const classOptionsClone = convertRawDataToOptions(
        classFilterListData?.result
      );
      setNewClassOptions([
        {
          name: t("whole-school"),
          value: "",
          id: 0,
        },
        ...classOptionsClone,
      ]);
    }
  }, [classFilterListData]);

  useEffect(() => {
    if (absenceData && absenceData?.absences.length) {
      setData(
        absenceData?.absences.map((item: any) => {
          return {
            date: handleConvertDate(item.aggregate_date),
            ab: item.absence_amount.total,
            abDetail: item.absence_amount.details,
            bh: item.tardy_amount.total,
            bhDetail: item.tardy_amount.details,
            le: item.early_withdrawal_amount.total,
            leDetail: item.early_withdrawal_amount.details,
          };
        })
      );
    }
  }, [absenceData]);

  useEffect(() => {
    dispatch(
      AbsenceActionCreators.getAbsenceAction({
        aggregate_date_to: moment(new Date()).format("YYYY-MM-DD"),
        department_id: "",
      })
    );
  }, []);

  const handleOnChangeAllGrade = (value: any) => {
    setSelectedValue(value);
    dispatch(
      AbsenceActionCreators.getAbsenceAction({
        aggregate_date_to: moment(new Date()).format("YYYY-MM-DD"),
        department_id: value,
      })
    );
  };

  return (
    // <Col xs={24} md={24} xl={12} >
    <BoxItem ref={imgRef}>
      <WrapperCloseIcon>
        <CloseIcon
          fill="#2F8CAE"
          height="14px"
          width="14px"
          onClick={() => {
            handleCloseChart("2");
          }}
        />
      </WrapperCloseIcon>
      <BoxHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BoxTitle>{t("absence-label")}</BoxTitle>
          <div style={{ margin: "0 8px" }}>
            {t("total-absence")}
            {absenceData?.total_amount}人
          </div>
          {/* <NextIcon
              fill={"#2F8CAE"}
              style={{ width: "13px", height: "12px" }}
            />
            <HeartSpeakIcon fill="#2F8CAE" style={{ marginBottom: 2 }} /> */}
        </div>
        <div style={{ display: "flex", gap: 20, position: "relative" }}>
          <Select
            width="104px"
            options={newClassOptions}
            onSelect={handleOnChangeAllGrade}
            defaultValue={selectedValue}
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
            }}
          />
          <DownloadIcon
            fill="#3B3B3B"
            style={{ cursor: "pointer" }}
            onClick={(event: any) => {
              if (!event.detail || event.detail == 1) {
                generateImg("average-body-temperature", imgRef.current);
              }
            }}
          />
        </div>
      </BoxHeader>
      <LightDivider />
      {isLoading ? (
        <WrapperLoadingChart>
          <div className="container">
            <Loading width={70} height={70} />
          </div>
        </WrapperLoadingChart>
      ) : data.length > 0 ? (
        <BoxContent>
          <div style={{ display: "flex", height: "max-content" }}>
            <WrapperLegendLineChart style={{ marginRight: 15 }}>
              <PinkDot />
              <p>欠席</p>
            </WrapperLegendLineChart>
            <WrapperLegendLineChart style={{ marginRight: 15 }}>
              <YellowDot2 />
              <p>遅刻</p>
            </WrapperLegendLineChart>
            <WrapperLegendLineChart style={{ marginRight: 15 }}>
              <BlueDot2 />
              <p>早退</p>
            </WrapperLegendLineChart>
          </div>
          <WrapperChart>
            <BarChartCustom data={data} />
          </WrapperChart>
        </BoxContent>
      ) : (
        <WrapperNoData>
          <NoDataIcon width={140} height={140} />
        </WrapperNoData>
      )}
    </BoxItem>
    // </Col>
  );
};

export default Absence;
