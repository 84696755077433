import {
  Action,
  ActionType,
  DataPatientDetailResponse,
} from "models/bodyTemperature/patientDetail";
import createReducer from "../../createReducer";

export interface DataPatientDetailReducerType {
  loading: boolean;
  error?: string;
  dataPatientDetailResponse?: DataPatientDetailResponse;
}

const defaultState: DataPatientDetailReducerType = {
  loading: false,
  error: undefined,
  dataPatientDetailResponse: undefined,
};

export const dataPatientDetailReducer =
  createReducer<DataPatientDetailReducerType>(defaultState, {
    [ActionType.DATA_PATIENT_DETAIL](state: DataPatientDetailReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.DATA_PATIENT_DETAIL_ERROR](
      state: DataPatientDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    [ActionType.DATA_PATIENT_DETAIL_SUCCESS](
      state: DataPatientDetailReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        dataPatientDetailResponse: action.payload,
      };
    },
  });
