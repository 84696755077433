import {
  ActionType,
  IGetActivityListResponse,
} from "../../../models/activitymanagement/activityList";

export const activitiesListAction = () => {
  return {
    type: ActionType.ACTIVITY_LIST_SCHOOL,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.ACTIVITY_LIST_SCHOOL_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IGetActivityListResponse) => {
  return {
    type: ActionType.ACTIVITY_LIST_SCHOOL_SUCCESS,
    payload,
  };
};
