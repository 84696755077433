import { IResponse } from "models";

export enum ActionType {
  MENTAL_CHECK_TOTAL = "action/MENTAL_CHECK_TOTAL",
  MENTAL_CHECK_TOTAL_SUCCESS = "action/MENTAL_CHECK_TOTAL_SUCCESS",
  MENTAL_CHECK_TOTAL_ERROR = "action/MENTAL_CHECK_TOTAL_ERROR",
  MENTAL_CHECK_TOTAL_RESET = "action/MENTAL_CHECK_TOTAL_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

interface IProlems {
  total_request: number;
  total_response: number;
  total_precentage: number;
}

export interface IMentalCheckTotalPayload {
  mental_check_request_id: string;
  child_grade_id: any;
}

export interface IMentalCheckTotal {
  response_status?: {
    total_resonse_recieved: number;
    total_request_sent: number;
  };
  developmental_characteristics_pai_chart?: {
    concentration_problem: IProlems;
    relationship_problems: IProlems;
    behavioral_problems: IProlems;
  };
  feelings_and_moods_pie_chart?: IProlems;
}

export interface IMentalCheckTotalResponse extends IResponse {
  result: IMentalCheckTotal;
}
