import { ActionType, UpdateGeneralRequest } from "models/setting/updateGeneral";

export const updateGeneralAction = (payload: UpdateGeneralRequest) => {
  return {
    type: ActionType.UPDATE_GENERAL_SETTING,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_GENERAL_SETTING_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: any) => {
  return {
    type: ActionType.UPDATE_GENERAL_SETTING_SUCCESS,
    payload,
  };
};

export const handleResetAction = () => {
  return {
    type: ActionType.RESET_GENERAL_SETTING,
  };
};
