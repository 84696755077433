import React, { useState, useEffect } from "react";
import { Radio, notification } from "antd";
import type { RadioChangeEvent } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { subDays } from "date-fns";

import { SendCompleteIcon, ConfirmFailIcon } from "icons";
import {
  Button,
  Input,
  DateRangePicker,
  Select,
  ScrollToTop,
} from "components";
import { localStorageHelper, handleConvertMonthYear } from "utils";
import { IOption } from "components/select/Select";
import ConfirmModal from "screens/changeStudentAffiliation/ConfirmModal";
import SecondConfirmModal from "screens/changeStudentAffiliation/SecondConfirmModal";

import { Details } from "models/mentalHealth/mentalCheckDetails";
import { UpdateMentalCheckData } from "models/mentalHealth/updateMentalCheck";
import {
  GradeListActionCreators,
  UpdateMentalCheckActionCreators,
  DeleteMentalCheckActionCreators,
  MentalCheckDetailsActionCreators,
} from "redux/rootActions";
import { gradeListDataResponse } from "redux/mentalHealth/gradesList/gradeListStates";
import {
  selectIsDeletingMentalCheck,
  selectIsDeleted,
  selectDeleteErrorMessage,
} from "redux/mentalHealth/deleteMentalCheck/deleteMentalCheckStates";
import {
  selectIsUpdatingMentalCheck,
  selectIsUpdated,
  selectUpdateErrorMessage,
} from "redux/mentalHealth/updateMentalCheck/updateMentalCheckStates";

import {
  AudioContainer,
  ItemsContainer,
  LeftItem,
  RightItem,
  TextTitle,
  AudioWrapper,
  TitleContainer,
  DateRangeItem,
  SingleButtonWrapper,
  ItemsContainerNumber,
} from "./MentalHealth.style";

interface IMentalHealthEdit {
  detailsData: Details;
  handleCancel: () => void;
  setEdit: (edit: boolean) => void;
}

interface IDepartment {
  dept_id: number;
  dept_name: string;
}

export const MentalHealthEdit: React.FC<IMentalHealthEdit> = ({
  detailsData,
  handleCancel,
  setEdit,
}) => {
  const { t }: any = useTranslation("translation", {
    keyPrefix: "mental-health",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const dispatch = useDispatch();
  const GradeDatas = useSelector(gradeListDataResponse);
  const NewMentalCheckUpdated = useSelector(selectIsUpdated);
  const MentalCheckDeleted = useSelector(selectIsDeleted);
  const IsDeletingMentalCheck = useSelector(selectIsDeletingMentalCheck);
  const DeleteMentalCheckError = useSelector(selectDeleteErrorMessage);
  const UpdateMentalCheckError = useSelector(selectUpdateErrorMessage);
  const UpdatingMentalCheck = useSelector(selectIsUpdatingMentalCheck);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment().add(1, "M").toDate());
  const [examinationPassed, setExaminationPassed] = useState<boolean>(false);
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] =
    useState<boolean>(false);
  const [testName, setTestName] = useState<string>("");
  const [selectValue, setSelectValue] = useState<any>();
  const [gradeOptions, setGradeOptions] = useState<IOption[]>([]);
  const [radioValue, setRadioValue] = useState<number>(1);
  const [error, setError] = useState({
    status: "",
    error: "",
  });

  const onChangeRadio = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const onChangeTestName = (e: any) => {
    setTestName(e.target.value);
    if (error.status === "test-name") {
      setError({
        status: "",
        error: "",
      });
    }
  };

  const onChangeSelect = (value: any) => {
    setSelectValue(value);
    if (error.status === "select") {
      setError({
        status: "",
        error: "",
      });
    }
  };

  const onUpdateTest = () => {
    if (!testName) {
      setError({
        status: "test-name",
        error: "enter-test-name",
      });
      return;
    }
    if (!selectValue) {
      setError({
        status: "select",
        error: "please-select-grade",
      });
      return;
    }
    if (examinationPassed) {
      setError({
        status: "passed",
        error: "exam-period-passed",
      });
      return;
    }
    const updateMentalCheckParams: UpdateMentalCheckData = {
      id: detailsData.id,
      mental_check_type_id: radioValue,
      test_name: testName,
      start_time: moment(startDate).toString(),
      end_time: moment(endDate).toString(),
      grade: selectValue,
      temp_save_to_permant_save: "true",
    };
    dispatch(
      UpdateMentalCheckActionCreators.updateMentalCheckAction(
        updateMentalCheckParams
      )
    );
  };

  const deleteMentalCheck = () => {
    dispatch(
      DeleteMentalCheckActionCreators.deleteMentalCheckAction({
        id: detailsData.id,
      })
    );
    setIsVisibleDeleteModal(false);
  };

  useEffect(() => {
    if (moment(endDate).diff(moment(subDays(new Date(), 1))) < 0) {
      setExaminationPassed(true);
    } else {
      setExaminationPassed(false);
      setError({
        status: "",
        error: "",
      });
    }
  }, [endDate]);

  useEffect(() => {
    if (GradeDatas) {
      const tempGrade: IOption[] = [];
      GradeDatas?.result?.map((item: IDepartment) =>
        tempGrade.push({
          id: item.dept_id,
          name: item.dept_name,
          value: item.dept_id,
        })
      );
      setGradeOptions(tempGrade);
    }
  }, [GradeDatas]);

  useEffect(() => {
    if (NewMentalCheckUpdated) {
      notification.success({
        placement: "bottomRight",
        message: t("saved"),
        icon: <SendCompleteIcon />,
      });
      dispatch(UpdateMentalCheckActionCreators.handleResetDataAction());
      handleCancel();
    }
  }, [NewMentalCheckUpdated]);

  useEffect(() => {
    if (MentalCheckDeleted) {
      notification.success({
        placement: "bottomRight",
        message: t("deleted"),
        icon: <SendCompleteIcon />,
      });
      dispatch(DeleteMentalCheckActionCreators.handleResetDataAction());
      handleCancel();
    }
  }, [MentalCheckDeleted]);

  useEffect(() => {
    if (DeleteMentalCheckError) {
      notification.error({
        placement: "bottomRight",
        message: DeleteMentalCheckError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(DeleteMentalCheckActionCreators.handleResetDataAction());
    }
  }, [DeleteMentalCheckError]);

  useEffect(() => {
    if (UpdateMentalCheckError) {
      notification.error({
        placement: "bottomRight",
        message: UpdateMentalCheckError,
        icon: <ConfirmFailIcon width={53} height={53} />,
      });
      dispatch(UpdateMentalCheckActionCreators.handleResetDataAction());
    }
  }, [UpdateMentalCheckError]);

  useEffect(() => {
    if (detailsData) {
      const examinationPeriod: string[] =
        detailsData.examination_period.split("〜");
      setRadioValue(detailsData.mental_check_type_id);
      setTestName(detailsData.test_name);
      if (examinationPeriod.length) {
        setStartDate(moment(examinationPeriod[0]).toDate());
        setEndDate(moment(examinationPeriod[1]).toDate());
      }
    }
  }, [detailsData]);

  useEffect(() => {
    if (detailsData && gradeOptions) {
      setSelectValue(
        gradeOptions.find((item: IOption) => item.name === detailsData.grade)
          ?.id
      );
    }
  }, [detailsData, gradeOptions]);

  useEffect(() => {
    dispatch(GradeListActionCreators.gradeListAction());
    dispatch(DeleteMentalCheckActionCreators.handleResetDataAction());
    setExaminationPassed(false);
    setIsVisibleModal(false);
    setError({
      status: "",
      error: "",
    });
  }, []);

  return (
    <>
      <ScrollToTop />
      <TitleContainer
        style={{
          paddingBottom: 12,
          marginBottom: 0,
          borderBottom: "1px solid #F2F2F2",
        }}
      >
        <TextTitle>{t("mental-health-edit")}</TextTitle>
        <Button
          color="#FFFFFF"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          background={"#3B3B3B"}
          padding="11px 24px 13px"
          name={t("stop-editing")}
          bdr="8px"
          border="none"
          onClick={() => setIsVisibleModal(true)}
        />
      </TitleContainer>
      <ItemsContainerNumber
        style={{
          paddingTop: 22,
          paddingBottom: 22,
        }}
      >
        <LeftItem>{t("action-item-number")}</LeftItem>
        <Radio.Group onChange={onChangeRadio} value={radioValue}>
          <AudioWrapper>
            <Radio value={1}>{t("lmc-25-elementary")}</Radio>
            <Radio value={2}>{t("lmc-13-elementary")}</Radio>
            <Radio value={3}>{t("lmc-25-junior")}</Radio>
            <Radio value={4}>{t("lmc-13-junior")}</Radio>
          </AudioWrapper>
        </Radio.Group>
      </ItemsContainerNumber>
      <AudioContainer>
        <LeftItem>{t("test-name")}</LeftItem>
        <RightItem>
          <Input
            height={40}
            value={testName}
            onChange={onChangeTestName}
            placeholder={`${handleConvertMonthYear(
              moment(startDate).format("YYYY-MM-DD"),
              currentLanguage
            )}${t1("mental-health-placeholder")}`}
          />
          {error.status === "test-name" && error.error && (
            <div className="red-text">{t(error.error)}</div>
          )}
        </RightItem>
      </AudioContainer>
      <ItemsContainer>
        <LeftItem>{t("implemented-grade-selection")}</LeftItem>
        <DateRangeItem>
          <Select
            placeholder={t("select-grade")}
            options={gradeOptions}
            value={selectValue}
            onChange={onChangeSelect}
            width="124px"
          />
          {error.status === "select" && error.error && (
            <div className="red-text">{t(error.error)}</div>
          )}
        </DateRangeItem>
      </ItemsContainer>
      <ItemsContainer>
        <LeftItem>{t("examination-period")}</LeftItem>
        <DateRangeItem>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            locale={currentLanguage}
            maxDate={false}
            minDate={true}
          />
          {error.status === "passed" && error.error && (
            <div className="red-text">{t(error.error)}</div>
          )}
        </DateRangeItem>
      </ItemsContainer>

      <SingleButtonWrapper>
        <Button
          color="#FFFFFF"
          fontSize={16}
          fontWeight={700}
          lineHeight="16px"
          background={"#2AC769"}
          padding="11px 40px 13px"
          name={t("save")}
          bdr="6px"
          border="none"
          onClick={onUpdateTest}
          disabled={UpdatingMentalCheck}
        />
        <div
          onClick={() => setIsVisibleDeleteModal(true)}
          style={{
            width: "max-content",
            cursor: IsDeletingMentalCheck ? "not-allowed" : "pointer",
          }}
        >
          {t("delete-action")}
        </div>
      </SingleButtonWrapper>
      {isVisibleModal && (
        <ConfirmModal
          isVisible={isVisibleModal}
          handleCancel={() => setIsVisibleModal(false)}
          handleOk={() => {
            setEdit(false);
            dispatch(
              MentalCheckDetailsActionCreators.mentalCheckDetailsAction({
                id: detailsData.id,
                app_language_id: currentLanguage === "ja" ? 2 : 1,
              })
            );
          }}
        >
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "20px",
            }}
          >
            {t("confirm-leaving")}
          </div>
        </ConfirmModal>
      )}
      {isVisibleDeleteModal && (
        <SecondConfirmModal
          type="delete"
          isVisible={isVisibleDeleteModal}
          handleCancel={() => setIsVisibleDeleteModal(false)}
          handleOk={deleteMentalCheck}
        >
          <div
            style={{
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "20px",
            }}
          >
            {t("confirm-delete")}
            {t1("are-you-ok")}
          </div>
        </SecondConfirmModal>
      )}
    </>
  );
};

export default MentalHealthEdit;
