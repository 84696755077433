import { IResponse } from "models";

export enum ActionType {
  // message history listing
  SEND_QUESTION_HISTORY_LIST = "action/SEND_QUESTION_HISTORY_LIST",
  SEND_QUESTION_HISTORY_LIST_SUCCESS = "action/SEND_QUESTION_HISTORY_LIST_SUCCESS",
  SEND_QUESTION_HISTORY_LIST_ERROR = "action/SEND_QUESTION_HISTORY_LIST_ERROR",
  RESET_SEND_QUESTION_HISTORY_LIST = "action/RESET_SEND_QUESTION_HISTORY_LIST",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IQuestionHistoryData {
  from_date?: string;
  to_date?: string;
  locale?: string;
  page?: number;
  limit?: number;
}

export interface IQuestionHistoryResponse extends IResponse {
  result: {
    histories: IQuestionHistory[];
    total_record: number;
  };
}

export interface IQuestionHistory {
  id: number;
  is_draft: boolean;
  start_time: string;
  end_time: any;
  created_at: string;
  subject: string;
}
