import {
  ActionType,
  DataPatientChildDepartmentResponse,
} from "models/bodyTemperature/patientChildDepartment";

export const getDataPatientChildDepartment = (payload: {
  sub_dept_id: number;
}) => {
  return {
    type: ActionType.DATA_PATIENT_CHILD_DEPARTMENT,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.DATA_PATIENT_CHILD_DEPARTMENT_ERROR,
    payload,
  };
};

export const handleSuccesAction = (
  payload: DataPatientChildDepartmentResponse
) => {
  return {
    type: ActionType.DATA_PATIENT_CHILD_DEPARTMENT_SUCCESS,
    payload,
  };
};
