export enum ActionType {
  DELETE_MESSAGE = "action/DELETE_MESSAGE",
  DELETE_MESSAGE_SUCCESS = "action/DELETE_MESSAGE_SUCCESS",
  DELETE_MESSAGE_ERROR = "action/DELETE_MESSAGE_ERROR",
  RESET_DELETE_MESSAGE = "action/RESET_DELETE_MESSAGE",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}
