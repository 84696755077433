import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DataGroupClassResponse,
} from "models/bodyTemperature/groupClass";
import { httpStatus } from "configs/httpStatus";
import { fetchClassData } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./groupClassActions";

function* getDataDestinationSaga({ payload }: { payload: { locale: string } }) {
  try {
    const response: DataGroupClassResponse = yield call(
      fetchClassData,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetDataDestinationWatcher() {
  yield takeLatest(ActionType.DATA_GROUP_CLASS as any, getDataDestinationSaga);
}

export default [fork(onGetDataDestinationWatcher)];
