import styled from "styled-components";
import { Checkbox } from "antd";
import { theme } from "theme/theme";
import { Table } from "components";

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;

  ${theme.media.mobileL} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TableStyled = styled(Table)`
  margin-top: 20px;
  padding-bottom: 300px;
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #cde3ec;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${theme.colors.button};
  }
`;

export const TextLink = styled.div`
  background: transparent;
  font-size: ${theme.sizes.md};
  text-decoration: underline;
  cursor: pointer;
  color: black;
  text-underline-offset: 0.3em;
  text-align: left;
  margin-left: 10px;
`;
export const RowItem = styled.div`
  display: flex;
  align-items: center;
`;

export const ChangeViewIcons = styled.div`
  display: flex;
`;

export const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
  padding: "0 150px 150px 0",
  alignItems: "unset",
  outline: "none",
  "&:hover": {
    ".ant-checkbox-inner": {
      borderColor: theme.colors.border,
    },
  },
  ".ant-checkbox": {
    top: "unset",
  },
  ".ant-checkbox-inner": {
    width: 20,
    height: 21,
    border: `2px solid ${theme.colors.border}`,
  },
  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.colors.button,
    borderColor: `${theme.colors.button}!important`,
  },
  ".ant-checkbox-checked::after": {
    border: "none",
  },
  ".ant-checkbox-input:focus + .ant-checkbox-inner": {
    borderColor: theme.colors.border,
  },
  ".ant-checkbox-disabled + span": {
    color: "#3B3B3B",
  },
  ".ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after": {
    borderColor: "#fff !important",
  },
}));

export const GalleryContent = styled.div`
  width: 100%;
  height: 50vh;
  box-sizing: border-box;
  border: 2px solid #cccccc;
  border-radius: 12px;
  position: relative;
  background-size: cover;
  ${theme.media.tablet} {
    width: 251px;
    height: 300px;
  }
`;

export const GalleryCaption = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  width: 100%;
  border-radius: 0 0 12px 12px;
  position: absolute;
  bottom: 0;
`;

export const GalleryItems = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

export const WrapperProgressBar = styled.div`
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  z-index: 1000;
  background-color: #000000b7;
`;
