import {
  ActionType,
  FeverPayload,
  FeverResponse,
} from "models/dashboard/fever";

export const getFeverAction = (payload: FeverPayload) => {
  return {
    type: ActionType.GET_FEVER_CHART,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.GET_FEVER_CHART_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: FeverResponse) => {
  return {
    type: ActionType.GET_FEVER_CHART_SUCCESS,
    payload,
  };
};
