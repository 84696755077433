import { IResponse } from "models";

export enum ActionType {
  GET_PREFECTURE = "action/GET_PREFECTURE",
  GET_PREFECTURE_SUCCESS = "action/GET_PREFECTURE_SUCCESS",
  GET_PREFECTURE_ERROR = "action/GET_PREFECTURE_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface GetPrefecturePayload {
  country_id: number;
}

export interface PrefectureItem {
  id: number;
  local_name: string;
  global_name: string;
}

export interface PrefectureResponse extends IResponse {
  result: PrefectureItem[];
}
