import * as React from "react";

function CircleDelete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.09484 6.35945C7.61557 5.88018 6.83852 5.88018 6.35925 6.35945C5.87998 6.83871 5.87998 7.61576 6.35925 8.09502L10.2649 12.0006L6.35919 15.9063C5.87992 16.3856 5.87992 17.1626 6.35919 17.6419C6.83846 18.1211 7.61551 18.1211 8.09477 17.6419L12.0005 13.7362L15.905 17.6407C16.3843 18.1199 17.1613 18.1199 17.6406 17.6407C18.1199 17.1614 18.1199 16.3844 17.6406 15.9051L13.7361 12.0006L17.6405 8.09622C18.1198 7.61696 18.1198 6.83991 17.6405 6.36065C17.1612 5.88138 16.3842 5.88138 15.9049 6.36065L12.0005 10.2651L8.09484 6.35945Z"
        fill="#FB4E4E"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#FB4E4E" />
    </svg>
  );
}

export default CircleDelete;
