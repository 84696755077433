import React from "react";

import { RouteProps } from "react-router-dom";

import {
  Login,
  ForgotPassword,
  ForgotPasswordNotification,
  ResetPassword,
  ResetPasswordNotification,
  UserList,
  MessageDelivery,
  ClassManagement,
  QRCode,
  ActivityManagement,
  AccountList,
  AccountRegistration,
  BodyTemperature,
  ChangeClass,
  ConfirmData,
  ReflectionChanges,
  Setting,
  HistoryList,
  VaccinationRecord,
  VaccinationAppointment,
  ReservationList,
  VenueSetting,
  Questions,
  QuestionHistoryList,
  ResourcesDownload,
  MentalHealthManagement,
  MentalHealthSituationNotify,
  MentalHealthTotal,
  ImplementStatus,
  Dashboard,
  ConsultationsList,
  ImportSSOUsers,
} from "screens";
import { MainLayout, DashboardLayout } from "layouts";

export interface IRoute extends RouteProps {
  restricted?: boolean;
  layout?: React.ComponentType;
}

interface IConfigRoutes {
  appRoutes: IRoute[];
  isPrivate?: boolean;
  layout?: React.ComponentType;
}

const privateRoutes: IRoute[] = [
  {
    children: <UserList />,
    path: "/user-list",
    layout: DashboardLayout,
  },
  {
    children: <ClassManagement />,
    path: "/class-management",
    layout: DashboardLayout,
  },
  {
    children: <MessageDelivery />,
    path: "/message-delivery",
    layout: DashboardLayout,
  },
  {
    children: <HistoryList />,
    path: "/message-delivery/history",
    layout: DashboardLayout,
  },
  {
    children: <QRCode />,
    path: "/qr-code",
    layout: DashboardLayout,
  },
  {
    children: <ResourcesDownload />,
    path: "/resources-download",
    layout: DashboardLayout,
  },
  {
    children: <ActivityManagement />,
    path: "/activity-management",
    layout: DashboardLayout,
  },
  {
    children: <AccountList />,
    path: "/account-list",
    layout: DashboardLayout,
  },
  {
    children: <AccountRegistration />,
    path: "/account-registration",
    layout: DashboardLayout,
  },
  {
    children: <BodyTemperature />,
    path: "/body-temperature-management",
    layout: DashboardLayout,
  },
  {
    children: <ChangeClass />,
    path: "/change-class",
    layout: DashboardLayout,
  },
  {
    children: <ConfirmData />,
    path: "/confirm-data",
    layout: DashboardLayout,
  },
  {
    children: <ReflectionChanges />,
    path: "/reflection-changes",
    layout: DashboardLayout,
  },
  {
    children: <VaccinationRecord />,
    path: "/vaccination-record",
    layout: DashboardLayout,
  },
  {
    children: <VaccinationAppointment />,
    path: "/vaccination-appointment",
    layout: DashboardLayout,
  },
  {
    children: <ReservationList />,
    path: "/reservation-list",
    layout: DashboardLayout,
  },
  {
    children: <VenueSetting />,
    path: "/venue-setting",
    layout: DashboardLayout,
  },
  {
    children: <Setting />,
    path: "/setting",
    layout: DashboardLayout,
  },
  {
    children: <MentalHealthManagement />,
    path: "/mental-health-management",
    layout: DashboardLayout,
  },
  {
    children: <ImplementStatus />,
    path: "/elementary-25-questions",
    layout: DashboardLayout,
  },
  {
    children: <ImplementStatus />,
    path: "/elementary-13-questions",
    layout: DashboardLayout,
  },
  {
    children: <ImplementStatus />,
    path: "/junior-25-questions",
    layout: DashboardLayout,
  },
  {
    children: <ImplementStatus />,
    path: "/junior-13-questions",
    layout: DashboardLayout,
  },
  {
    children: <MentalHealthTotal />,
    path: "/mental-health-total",
    layout: DashboardLayout,
  },
  {
    children: <Questions />,
    path: "/questions-list",
    layout: DashboardLayout,
  },
  {
    children: <QuestionHistoryList />,
    path: "/history-list",
    layout: DashboardLayout,
  },
  {
    children: <Dashboard />,
    path: "/dashboard",
    layout: DashboardLayout,
  },
  {
    children: <ConsultationsList />,
    path: "/consultations-list",
    layout: DashboardLayout,
  },
  {
    children: <ImportSSOUsers />,
    path: "/import-sso-users",
    layout: DashboardLayout,
  },
];

const publicRoutes: IRoute[] = [
  {
    children: <Login />,
    path: "/login",
    layout: MainLayout,
    restricted: true,
  },
  {
    children: <ForgotPassword />,
    path: "/forgot-password",
    layout: MainLayout,
    restricted: true,
  },
  {
    children: <ForgotPasswordNotification />,
    path: "/forgot-password-notification",
    layout: MainLayout,
    restricted: true,
  },
  {
    children: <ResetPassword />,
    path: "/reset-password",
    layout: MainLayout,
    restricted: false,
  },
  {
    children: <ResetPasswordNotification />,
    path: "/reset-password-notification",
    layout: MainLayout,
    restricted: true,
  },
];

export const configRoutes: IConfigRoutes[] = [
  {
    appRoutes: privateRoutes,
    isPrivate: true,
    layout: MainLayout,
  },
  {
    appRoutes: publicRoutes,
    layout: MainLayout,
  },
];
