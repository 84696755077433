import { IResponse } from "models";

export enum ActionType {
  DONE_QUESTION = "action/DONE_QUESTION",
  DONE_QUESTION_SUCCESS = "action/DONE_QUESTION_SUCCESS",
  DONE_QUESTION_ERROR = "action/DONE_QUESTION_ERROR",
  DONE_QUESTION_RESET = "action/DONE_QUESTION_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IDoneQuestionPayload {
  id?: number;
}

export interface DoneQuestionResponse extends IResponse {}
