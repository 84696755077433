import {
  ActionType,
  ICompleteRequestResponse,
  ICompleteRequestPayload,
} from "models/consultationRequest/completeRequest";

export const completeRequestAction = (payload: ICompleteRequestPayload) => {
  return {
    type: ActionType.COMPLETE_REQUEST,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.COMPLETE_REQUEST_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: ICompleteRequestResponse) => {
  return {
    type: ActionType.COMPLETE_REQUEST_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.COMPLETE_REQUEST_RESET,
  };
};
