import {
  Action,
  ActionType,
  IPushNotificationResponse,
} from "models/messagemanagement/pushNotification";
import createReducer from "../../createReducer";

export interface PushNotificationReducerType {
  loading: boolean;
  error?: string;
  saved: boolean;
  pushNotificationResponse?: IPushNotificationResponse;
}

const defaultState: PushNotificationReducerType = {
  loading: false,
  error: undefined,
  saved: false,
  pushNotificationResponse: undefined,
};

export const pushNotificationReducer =
  createReducer<PushNotificationReducerType>(defaultState, {
    [ActionType.PUSH_NOTIFICATION](state: PushNotificationReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.PUSH_NOTIFICATION_ERROR](
      state: PushNotificationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: action.payload,
      };
    },

    [ActionType.PUSH_NOTIFICATION_SUCCESS](
      state: PushNotificationReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        saved: true,
        pushNotificationResponse: action.payload,
      };
    },

    [ActionType.PUSH_NOTIFICATION_RESET](state: PushNotificationReducerType) {
      return {
        ...state,
        loading: false,
        saved: false,
        error: null,
        pushNotificationResponse: undefined,
      };
    },
  });
