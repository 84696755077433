import {
  Action,
  ActionType,
  IVenuesListResponse,
} from "models/vaccine/venuesList";
import createReducer from "../../../createReducer";

export interface VenuesListReducerType {
  loading: boolean;
  error?: string;
  venuesListResponse?: IVenuesListResponse;
}

const defaultState: VenuesListReducerType = {
  loading: false,
  error: undefined,
  venuesListResponse: undefined,
};

export const venuesListReducer = createReducer<VenuesListReducerType>(
  defaultState,
  {
    [ActionType.VACCINE_VENUES_LIST](state: VenuesListReducerType) {
      return {
        ...state,
        loading: true,
      };
    },

    [ActionType.VACCINE_VENUES_LIST_ERROR](
      state: VenuesListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        venuesListResponse: undefined,
      };
    },

    [ActionType.VACCINE_VENUES_LIST_SUCCESS](
      state: VenuesListReducerType,
      action: Action<number>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        venuesListResponse: action.payload,
      };
    },
  }
);
