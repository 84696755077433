import * as React from "react";

function ConsultationRequestIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.1429 4H10.8571C7.07005 4 4 7.07005 4 10.8571V21.1429C4 24.93 7.07005 28 10.8571 28H21.1429C24.93 28 28 24.93 28 21.1429V10.8571C28 7.07005 24.93 4 21.1429 4Z"
        fill={props.fill}
      />
      <path
        d="M16.2105 11.2229C16.8162 10.4972 19.1877 9.55437 20.3934 9.70866C21.7591 9.88008 23.2905 11.0915 23.8048 12.1144C24.6105 13.7258 23.6962 19.5201 21.0048 20.7829C20.0734 21.2229 16.7305 22.5258 15.6219 23.0172C14.2905 22.4115 12.8219 22.0058 11.1248 20.2687C9.42766 18.5315 8.55338 16.4229 8.4848 14.9944C8.39909 13.1887 9.19338 10.5601 10.6391 9.98866C12.3134 9.32008 15.6448 10.2172 16.2105 11.2287V11.2229Z"
        fill="white"
      />
    </svg>
  );
}

export default ConsultationRequestIcon;
