import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  PatientProfileResponse,
  CreatePatientProfileRequestData,
} from "models/bodyTemperature/patientProfile";
import { httpStatus } from "configs/httpStatus";
import { createPatientProfile } from "services/bodyTemperature";

import { handleSuccesAction, handleErrorAction } from "./patientProfileActions";

function* createPatientProfileSaga({
  payload,
}: {
  payload: CreatePatientProfileRequestData;
}) {
  try {
    const response: PatientProfileResponse = yield call(
      createPatientProfile,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onCreatePatientProfileWatcher() {
  yield takeLatest(
    ActionType.CREATE_PATIENT_PROFILE as any,
    createPatientProfileSaga
  );
}

export default [fork(onCreatePatientProfileWatcher)];
