import { AttendanceFilterData } from "models/messagemanagement/attendanceFilterList";
import { FilterStudentData } from "models/messagemanagement/getStudentList";
import { IMessageHistoryDetailData } from "models/messagemanagement/messageHistoryDetail";
import { IMessageHistoryData } from "models/messagemanagement/messageHistoryList";
import { TemperatureFilterData } from "models/messagemanagement/temperatureFilterList";
import { IReadHistoryData } from "models/messagemanagement/messageReadHistory";
import { IPushNotificationPayload } from "models/messagemanagement/pushNotification";
import { createApiCall2, MethodType, URI } from "../Api";

export const fetchActivitiesFilterList = () => {
  return createApiCall2(
    MethodType.GET,
    URI.MESSAGE_MANAGEMENT.GET_ACTIVITY_FILTER
  );
};

export const fetchClassFilterList = () => {
  return createApiCall2(
    MethodType.GET,
    URI.MESSAGE_MANAGEMENT.GET_CLASS_FILTER
  );
};

export const fetchTemperatureFilterList = (payload: TemperatureFilterData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MESSAGE_MANAGEMENT.GET_TEMPERATURE_FILTER,
    payload
  );
};

export const fetchAttendanceFilterList = (payload: AttendanceFilterData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MESSAGE_MANAGEMENT.GET_ATTENDANCE_FILTER,
    payload
  );
};

export const fetchStudentList = (payload: FilterStudentData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MESSAGE_MANAGEMENT.GET_STUDENT_LIST,
    payload
  );
};

export const fetchHistoryList = (payload: IMessageHistoryData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MESSAGE_MANAGEMENT.GET_MESSAGE_HISTORY_LIST,
    payload
  );
};

export const fetchHistoryDetail = (payload: IMessageHistoryDetailData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MESSAGE_MANAGEMENT.GET_MESSAGE_HISTORY_DETAIL,
    payload
  );
};

export const postSendMessage = (payload: any) => {
  return createApiCall2(
    MethodType.POST,
    URI.MESSAGE_MANAGEMENT.SEND_MESSAGE,
    payload
  );
};

export const fetchSearchCondition = () => {
  return createApiCall2(
    MethodType.GET,
    URI.MESSAGE_MANAGEMENT.SEARCH_CONDITION
  );
};

export const fetchReadHistoryList = (payload: IReadHistoryData) => {
  return createApiCall2(
    MethodType.GET,
    URI.MESSAGE_MANAGEMENT.GET_READ_HISTORY_LIST,
    payload
  );
};

export const pushNotification = (payload: IPushNotificationPayload) => {
  return createApiCall2(
    MethodType.POST,
    URI.MESSAGE_MANAGEMENT.PUSH_NOTIFICATION,
    payload
  );
};

export const updateMessage = (payload: any) => {
  return createApiCall2(
    MethodType.PUT,
    URI.MESSAGE_MANAGEMENT.UPDATE_MESSAGE(payload.id),
    payload.param
  );
};

export const deleteMessage = (payload: any) => {
  return createApiCall2(
    MethodType.DELETE,
    URI.MESSAGE_MANAGEMENT.DELETE_MESSAGE(payload.id)
  );
};
