import styled from "styled-components";

import { theme } from "theme/theme";
import { Table, Button, Modal, Select } from "components";
import { Checkbox } from "antd";

interface IText {
  size?: number;
  weight?: number;
  lnHeight?: string;
  width?: string;
  minWidth?: string;
  color?: string;
  mg?: string;
  align?: any;
}

export const Label = styled.div(({ theme }) => ({
  color: theme.colors.text.primary,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  marginBottom: 4,
}));

export const AttendanceLabel = styled.p`
  font-style: normal;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.sizes.md};
  line-height: 24px;
  margin: 0px;
  width: 225px;
  margin-right: 16px;
  overflow-wrap: break-word;
  ${theme.media.tablet} {
    width: 100%;
    line-height: 16px;
  }
`;

export const TaskWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 8px 8px 0px 0px;
`;

export const TaskWrapperLeft = styled.div`
  display: flex;
  gap: 8px;
  button {
    height: 23px;
  }
`;

export const TaskWrapperRight = styled.div`
  button {
    display: none;
  }
  ${theme.media.tablet} {
    button {
      display: unset;
    }
    > svg {
      display: none;
    }
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  .ant-select-selector {
    background-color: #ffffff !important;
  }
`;

export const PaginationWrapper = styled.div`
  margin-bottom: 16px;
  text-align: center;
  ${theme.media.tablet} {
    display: flex;
    justify-content: end;
    text-align: unset;
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 32px;
`;

export const RowCellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 17px;
  svg {
    cursor: pointer;
  }
`;

export const DataRow = styled.div`
  display: flex;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  align-self: center;
  p {
    flex: 1;
    padding: 10px 0px 13px 8px;
    border-bottom: 1px dashed #cccccc;
  }
  span {
    flex: 1.2;
    padding: 10px 0px 13px 8px;
    border-right: 2px solid #cccccc;
    border-bottom: 1px dashed #cccccc;
  }
  :last-child {
    margin-bottom: 16px;
  }
  ${theme.media.mobileM} {
    p {
      flex: 2;
    }
    span {
      flex: 1.6;
    }
  }
  ${theme.media.tablet} {
    p {
      flex: 4;
      padding: 10px 0px 13px 16px;
    }
    span {
      flex: 1;
      padding: 10px 16px 13px 8px;
    }
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  p {
    background: #f2f2f2;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    padding: 3px 4px 4px;
    margin: 0;
    margin: 0px 4px 4px 0px;
    width: max-content;
  }
`;

export const TextLink = styled.div<{ isBgUdl: any; normal?: boolean }>`
  font-size: ${theme.sizes.md};
  font-weight: ${theme.fontWeight.regular};
  color: ${(props) => (props.normal ? "#4F4F4F" : `${theme.colors.textLink}`)};
  position: relative;
  cursor: ${(props) => (props.normal ? "default" : "pointer")};
  margin-left: 17px;

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    background: ${(props) =>
      props.normal ? "none" : `${theme.colors.textLink}`};
    position: absolute;
    bottom: 7px;
    left: 0px;
  }
  &:before {
    content: "";
    width: 100%;
    height: 8px;
    background: ${(props) => props.isBgUdl && theme.colors.warning};
    position: absolute;
    bottom: 4px;
    left: 0px;
    z-index: -1;
  }
`;

export const Text = styled.div(({ theme }) => ({
  fontSize: theme.sizes.md,
  fontWeight: theme.fontWeight.regular,
  color: theme.colors.titleText,
  marginLeft: 17,
}));

export const SearchWrapper = styled.div`
  ${theme.media.tablet} {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const SearchWrapperLeft = styled.div`
  margin-bottom: 24px;
  input {
    width: 100%;
    margin-bottom: 8px;
  }
  ${theme.media.tablet} {
    display: flex;
    input {
      width: 215px;
      margin-right: 16px;
    }
  }
  ${theme.media.laptopL} {
    input {
      width: 292px;
    }
  }
`;

export const WrapperSelectAndBtn = styled.div`
  display: flex;
  ${theme.media.mobileM} {
    justify-content: space-between;
    .ant-select {
      width: 231px;
    }
    .ant-select-selector {
      width: 231px;
    }
  }
  ${theme.media.mobileL} {
    justify-content: initial;
    .ant-select {
      margin-right: 16px;
    }
  }
  ${theme.media.tablet} {
    .ant-select {
      width: 170px;
    }
    .ant-select-selector {
      width: 170px;
    }
    button {
      height: 31px;
    }
  }
  ${theme.media.laptop} {
    .ant-select {
      width: 175px;
      margin-right: 16px;
    }
    .ant-select-selector {
      width: 175px;
    }
  }
`;

export const WrapperCSVAction = styled.div`
  display: flex;
  input {
    width: 190px;
    margin-right: 16px;
  }
  ${theme.media.mobileM} {
    input {
      width: 231px;
    }
  }
  ${theme.media.tablet} {
    input {
      width: 592px;
    }
  }
`;

export const WrapperBtnForm = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 0px 24px;
  border-top: 0;
  button {
    font-size: ${theme.sizes.sm};
    width: 148px;
  }
  ${theme.media.tablet} {
    padding: 10px 0px 24px;
  }
`;

export const WrapperBirthdayInput = styled.div`
  display: flex;
  width: max-content;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 22px;
  position: relative;
  p {
    width: 100%;
    margin: 0;
    font-size: ${theme.sizes.md};
    line-height: 12px;
    font-weight: ${theme.fontWeight.regular};
  }
  .enter-year {
    input {
      width: 52px;
      padding: 0px 7px;
      line-height: 18px;
      font-weight: ${theme.fontWeight.regular};
    }
  }
  .birthday-error {
    position: absolute;
    bottom: -42px;
    left: 0;
    display: block;
    background: #ffffff;
    color: #fb2121;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 9px 8px;
    z-index: 100;
    border-radius: 8px;
    border: 1px solid #fb2121;
    transform-origin: 50% 89.9943px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
  }
  .birthday-error::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 18px;
    height: 7px;
    width: 7px;
    background: #fb2121;
    transform: rotate(45deg);
  }
  .birthday-error::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 17px;
    width: 6px;
    height: 10px;
    background: #ffffff;
    transform: rotate(45deg);
    z-index: 1;
  }
`;

export const BirthdayInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    margin-bottom: 0;
  }
  input {
    width: 42px;
    height: 31px;
  }
  span {
    margin-left: 6px;
    font-size: ${theme.sizes.md};
    line-height: 24px;
    font-weight: ${theme.fontWeight.regular};
  }
  :first-child {
    input {
      width: 52px;
    }
  }
`;

export const TableStyled = styled(Table)`
  .ant-table-thead > tr > th:first-child {
    text-align: center;
  }
  .ant-table-thead > tr > th:nth-child(8) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-thead > tr > th:nth-child(7),
  .ant-table-thead > tr > th:nth-child(6) {
    &:before {
      width: 0px !important;
    }
  }
`;

export const UserListTableStyled = styled(Table)`
  .ant-table-thead {
    height: 71px;
  }
  .ant-table-thead > tr > th:first-child {
    text-align: left;
    padding-left: 44px;
  }
  .ant-table-thead > tr > th:nth-child(8) {
    &:before {
      width: 0px !important;
    }
  }
  .ant-table-thead > tr > th:nth-child(7),
  .ant-table-thead > tr > th:nth-child(6) {
    &:before {
      width: 0px !important;
    }
  }
`;

export const WrapperBtnUpdatingForm = styled.div`
  padding: 2px 0px 24px;

  button {
    width: 148px;
  }
  ${theme.media.tablet} {
    display: flex;
    padding: 10px 0px 24px;
    justify-content: space-between;
    align-items: center;
    p {
      order: 1;
    }
    div {
      order: 2;
    }
  }
`;

export const DeleteButton = styled.p`
  color: ${theme.colors.important};
  text-decoration: underline;
  cursor: pointer;
  font-size: ${theme.sizes.xs};
  line-height: 12px;
  cursor: pointer;
`;

export const GroupBtn = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  button {
    width: 148px;
  }
  button:first-child {
    margin-right: 12px;
  }
  ${theme.media.tablet} {
    justify-content: unset;
    margin-bottom: 0px;
    button {
      width: 128px;
    }
    button:first-child {
      margin-right: 12px;
    }
  }
`;

export const ImportantStyled = styled.span`
  color: ${theme.colors.important};
`;

export const WrapperBodyTop = styled.div`
  display: flex;
  border-bottom: 2px solid #cccccc;
  padding: 16px 0;
  ${theme.media.tablet} {
    padding: 0 0 12px;
    align-items: center;
  }
`;

export const AttendanceDownloadBtn = styled(Button)`
  height: 23px;
  margin-top: 25px;
  margin-right: 24px;
  width: 78px;
  ${theme.media.tablet} {
    margin-top: unset;
    width: 87px;
  }
`;

export const DataNotFound = styled.div`
  text-align: center;
  padding: 16px;
  font-size: 16px;
  text-transform: capitalize;
`;

export const ModalStyled = styled(Modal)`
  width: 367px !important;
  max-width: calc(100vw - 8px);
  height: 208px !important;
  .ant-modal-body {
    padding: 32px 0px 24px;
    text-align: center;
    font-size: 21px;
    line-height: 40px;
  }
  .ant-modal-footer {
    padding-top: 0;
    padding-bottom: 32px;
    border: unset;
  }
  ${theme.media.tablet} {
    width: 532px !important;
  }
`;

export const WrapperBtnFooter = styled.div`
  display: flex;
  justify-content: center;
  button {
    width: 128px;
    line-height: 16px;
    padding: 11px 0px 13px;
  }
  button:first-child {
    margin-right: 16px;
  }
  ${theme.media.tablet} {
    justify-content: center;
    button:first-child {
      margin-right: 40px;
    }
  }
`;

export const CompulsoryGroupLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.important};
  font-size: 12px;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ActivityGroupLabelContainer = styled.div`
  display: flex;
  align-items: baseline;

  & > div {
    white-space: nowrap;
  }

  span {
    margin-left: 2px;
  }
`;
export const ConfirmModalStyled = styled(Modal)`
  width: 375px !important;
  max-width: calc(100vw - 4px);
  top: 50%;
  transform: translate(0%, -50%);
  ${theme.media.tablet} {
    width: 720px !important;
  }
`;
export const ConfirmBtnStyled = styled(Button)`
  margin-bottom: 24px;
  width: 100%;
  ${theme.media.tablet} {
    margin-bottom: 48px;
    width: unset;
  }
`;
export const TextModal = styled.div<IText>(
  ({ size, weight, lnHeight, width, minWidth, color, mg, align, theme }) => ({
    color: color || theme.colors.text.primary,
    fontSize: size || "16px",
    lineHeight: lnHeight || "24px",
    width: width,
    minWidth: minWidth,
    fontWeight: weight || theme.fontWeight.regular,
    margin: mg,
    textAlign: align,
  })
);
export const FailText = styled(TextModal)`
  padding: 0 11px;
  ${theme.media.tablet} {
    padding: 0;
  }
`;

export const SelectStyled = styled(Select)`
  .ant-select-selection-overflow .ant-select-selection-item {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
    letter-spacing: 1px;
    background: ${theme.colors.white}!important;
    padding: 0 auto !important;
    height: 21px;
    background: #f2f2f2 !important;
    border-radius: 4px;
  }
  .ant-select-selection-item-remove {
    display: none;
  }
  .ant-select-selector {
    overflow: auto;
  }
`;

export const DivActivity = styled.div`
  display: flex;
  > div:last-child {
    border-right: none;
  }
`;

export const WapperActivity = styled.div`
  padding: 6px 13px;
  border-right: 1px solid #f2f2f2;

  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    margin-bottom: 10px;
    width: 100px;
  }
`;

export const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
  alignItems: "unset",
  outline: "none",
  "&:hover": {
    ".ant-checkbox-inner": {
      borderColor: theme.colors.border,
    },
  },
  ".ant-checkbox": {
    top: "unset",
  },
  ".ant-checkbox-inner": {
    width: 20,
    height: 21,
    border: `2px solid ${theme.colors.border}`,
  },
  ".ant-checkbox-checked .ant-checkbox-inner": {
    backgroundColor: theme.colors.button,
    borderColor: `${theme.colors.button}!important`,
  },
  ".ant-checkbox-checked::after": {
    border: "none",
  },
  ".ant-checkbox-input:focus + .ant-checkbox-inner": {
    borderColor: theme.colors.border,
  },
  ".ant-checkbox-disabled + span": {
    color: "#3B3B3B",
  },
  ".ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after": {
    borderColor: "#fff !important",
  },
  ".ant-checkbox + span": {
    width: 102,
  },
}));

export const WapperError = styled.div`
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #fb2121;
    border: 1px solid #fb2121;
    box-sizing: border-box;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    padding: 9px 8px;
    text-align: center;
    position: absolute;
    z-index: 99;
    top: 70px;
    background: #fff;
    ::before {
      content: "";
      position: absolute;
      top: -4px;
      left: 17px;
      width: 7px;
      height: 10px;
      background: #ffffff;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: 1;
    }
    ::after {
      content: "";
      position: absolute;
      top: -5px;
      left: 18px;
      height: 7px;
      width: 7px;
      background: #fb2121;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;

export const ActivitiesStyle = styled.div`
  padding: 4px 0px;
  max-height: 312px;
  max-width: 305px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    border-radius: 7px;
  }
  &::-webkit-scrollbar-track {
    margin: 6px;
    border-left: 4px solid ${theme.colors.background};
    border-right: 4px solid ${theme.colors.background};
    background: ${theme.colors.background};
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 7px;
    border-left: 4px solid #bdbdbd;
    border-right: 4px solid #bdbdbd;
  }
  ${theme.media.tablet} {
    max-height: 328px;
    max-width: 665px;
  }
`;
