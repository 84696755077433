import { put, takeLatest, fork, call } from "redux-saga/effects";

import {
  ActionType,
  DepartmentListResponse,
} from "models/account/departmentList";
import { httpStatus } from "configs/httpStatus";
import { fetchDepartmentList } from "services/account";

import { handleSuccesAction, handleErrorAction } from "./departmentListActions";

function* getDepartmentListSaga({ payload }: any) {
  try {
    const response: DepartmentListResponse = yield call(
      fetchDepartmentList,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}

function* onGetDepartmentListWatcher() {
  yield takeLatest(
    ActionType.ACCOUNT_DEPARTMENT_LIST as any,
    getDepartmentListSaga
  );
}

export default [fork(onGetDepartmentListWatcher)];
