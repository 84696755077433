import {
  UpdateMentalCheckData,
  ActionType,
} from "models/mentalHealth/updateMentalCheck";
import { IResponse } from "models";

export const updateMentalCheckAction = (payload: UpdateMentalCheckData) => {
  return {
    type: ActionType.UPDATE_MENTAL_CHECK,
    payload,
  };
};

export const handleErrorAction = (payload: string) => {
  return {
    type: ActionType.UPDATE_MENTAL_CHECK_ERROR,
    payload,
  };
};

export const handleSuccesAction = (payload: IResponse) => {
  return {
    type: ActionType.UPDATE_MENTAL_CHECK_SUCCESS,
    payload,
  };
};

export const handleResetDataAction = () => {
  return {
    type: ActionType.RESET_UPDATE_MENTAL_CHECK,
  };
};
