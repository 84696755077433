import { IResponse } from "models";

export enum ActionType {
  // student list data for class change
  UPLOAD_CSV_CLASS_CHANGE = "action/UPLOAD_CSV_CLASS_CHANGE",
  UPLOAD_CSV_CLASS_CHANGE_SUCCESS = "action/UPLOAD_CSV_CLASS_CHANGE_SUCCESS",
  UPLOAD_CSV_CLASS_CHANGE_ERROR = "action/UPLOAD_CSV_CLASS_CHANGE_ERROR",
  UPLOAD_CSV_CLASS_CHANGE_RESET = "action/UPLOAD_CSV_CLASS_CHANGE_RESET",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

export interface IUploadCSVClassChangeResponse extends IResponse {
  result?: {
    errors: string[];
    failed_records: number;
    success_records: number;
    total_records: number;
  };
}
