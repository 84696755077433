import * as React from "react";

function JuniorHighIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_25526_97324)">
        <path
          d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
          fill="#2F8CAE"
        />
        <path
          d="M10.7631 12.117H12.72C11.9816 11.3293 11.3169 10.4062 10.7631 9.40928V12.117ZM7.29233 12.117H9.22464V9.4339C8.67079 10.4185 8.01848 11.3293 7.29233 12.117ZM15.4277 8.05543H11.6493C12.6093 9.90159 14.1846 11.6 15.9446 12.5231C15.5754 12.8185 15.0708 13.4339 14.8123 13.8524C14.0985 13.4093 13.4216 12.8554 12.8062 12.2031V13.6062H10.7631V15.6862H9.22464V13.6062H7.10771V12.3139C6.51694 12.9293 5.86464 13.4585 5.18771 13.8893C4.92925 13.52 4.46156 12.9539 4.11694 12.6708C5.85233 11.7231 7.4031 9.95082 8.35079 8.05543H4.59694V6.57851H9.22464V4.20312H10.7631V6.57851H15.4277V8.05543Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_25526_97324">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default JuniorHighIcon;
