import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  ActivitiesListData,
  ActionType,
  ActivitiesListResponse,
} from "../../models/activities";
import { httpStatus } from "configs/httpStatus";
import { fetchActivites } from "services/activitygrouplist";
import { handleSuccesAction, handleErrorAction } from "./activitiesListActions";

function* activitiesListSaga({ payload }: { payload: ActivitiesListData }) {
  try {
    const response: ActivitiesListResponse = yield call(
      fetchActivites,
      payload
    );
    if (response.status === httpStatus.StatusOK) {
      yield put(handleSuccesAction(response));
    } else {
      yield put(handleErrorAction(response.message || ""));
    }
  } catch (error) {
    yield put(handleErrorAction("error"));
  }
}
function* onGetActivitiesListWatcher() {
  yield takeLatest(ActionType.ACTIVITIES_LIST as any, activitiesListSaga);
}

export default [fork(onGetActivitiesListWatcher)];
