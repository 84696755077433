import { IResponse } from "models";

export enum ActionType {
  VACCINE_RECORD_LIST = "action/VACCINE_RECORD_LIST",
  VACCINE_RECORD_SUCCESS = "action/VACCINE_RECORD_SUCCESS",
  VACCINE_RECORD_ERROR = "action/VACCINE_RECORD_ERROR",
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}

interface IManufacturers {
  interval_day: number;
  manufacturer_id: number;
  manufacturer_name_text: string;
}

interface IVaccinationRecord {
  department_name: string;
  patient_id: number;
  patient_name: string;
  vaccination_date: string;
  vaccination_frequency: number;
  vaccination_record_id: number;
  vaccination_record_status: number;
  vaccine_id: number;
}

export interface IRecordVaccineListResponse extends IResponse {
  result: {
    total: number;
    vaccination_records: IVaccinationRecord[];
    manufacturers: IManufacturers[];
  };
}
