import * as React from "react";

function AddPlusCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="8" fill="white" />
      <path
        d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3ZM12 19.2C10.576 19.2 9.18393 18.7777 7.9999 17.9866C6.81586 17.1954 5.89302 16.0709 5.34807 14.7553C4.80312 13.4397 4.66054 11.992 4.93835 10.5953C5.21616 9.19868 5.9019 7.91577 6.90883 6.90883C7.91577 5.90189 9.19869 5.21616 10.5954 4.93835C11.992 4.66053 13.4397 4.80312 14.7553 5.34807C16.071 5.89302 17.1954 6.81586 17.9866 7.99989C18.7777 9.18393 19.2 10.576 19.2 12C19.2 13.9096 18.4414 15.7409 17.0912 17.0912C15.7409 18.4414 13.9096 19.2 12 19.2ZM15.6 11.1H12.9V8.4C12.9 8.1613 12.8052 7.93239 12.6364 7.7636C12.4676 7.59482 12.2387 7.5 12 7.5C11.7613 7.5 11.5324 7.59482 11.3636 7.7636C11.1948 7.93239 11.1 8.1613 11.1 8.4V11.1H8.4C8.16131 11.1 7.93239 11.1948 7.76361 11.3636C7.59482 11.5324 7.5 11.7613 7.5 12C7.5 12.2387 7.59482 12.4676 7.76361 12.6364C7.93239 12.8052 8.16131 12.9 8.4 12.9H11.1V15.6C11.1 15.8387 11.1948 16.0676 11.3636 16.2364C11.5324 16.4052 11.7613 16.5 12 16.5C12.2387 16.5 12.4676 16.4052 12.6364 16.2364C12.8052 16.0676 12.9 15.8387 12.9 15.6V12.9H15.6C15.8387 12.9 16.0676 12.8052 16.2364 12.6364C16.4052 12.4676 16.5 12.2387 16.5 12C16.5 11.7613 16.4052 11.5324 16.2364 11.3636C16.0676 11.1948 15.8387 11.1 15.6 11.1Z"
        fill="#2AC769"
      />
    </svg>
  );
}

export default AddPlusCircleIcon;
